import { masterApi } from 'api/completeApi';
import { GeneralSavedRecordDto } from 'api/completeApiInterfaces';
import { useCallback, useEffect, useState } from 'react';
import { messageError } from 'utils';
import { useApiData } from './useApiData';
import { useIntl } from './useIntl';

export enum SavedRecordTypeEnum {
  ProjectsInRealizationSavedViews = 'ProjectsInRealizationSavedViews',
  MasterDashboardPanelConfigurations = 'MasterDashboardPanelConfigurations',
  SharedDashboardPanelConfigurations = 'SharedDashboardPanelConfigurations',
  KPIFinancialOverviewEsticonProjectsByUnitConfigurations = 'KPIFinancialOverviewEsticonProjectsByUnitConfigurations',
  KPIFinancialOverviewConstructionConfigurations = 'KPIFinancialOverviewConstructionConfigurations',
}

type NotArrayObject = object & {
  length?: never;
  [key: string]: any;
};

/**
 * Manage generic saved data used for frontend visual settings persistency
 * @param primaryId Primary key used to save data. For example UserID for user data or OrgId for organization data
 * @param identifier Category type of saved data.
 * @param secondaryID supplementary ID to distinguish saved data in same category. Not required.
 * @param orderNum supplementary number to distinguish saved data in same category. Not required.
 * @param organizationIdAuthorization Organization ID provided in case saved record require organization permission for save
 * @returns Saved record api reposnse, status and method to save saved record
 */
export const useSavedRecordsApi = <P extends NotArrayObject>(
  primaryId: Guid,
  identifier: SavedRecordTypeEnum,
  secondaryId?: Guid,
  orderNum: number = 0,
  organizationIdAuthorization?: Guid
) => {
  const [savedRecordSaving, setSaving] = useState<boolean>(false);
  const intl = useIntl();

  const [savedRecords, savedRecordsError, savedRecordsLoading, loadSavedRecords, setSavedRecords] = useApiData(
    (ct) =>
      masterApi.projects.reports.generalsavedrecord.get.post(
        {
          id1: primaryId,
          id2: secondaryId,
          iden: identifier,
          number: orderNum,
        },
        ct
      ),
    { autoload: false }
  );

  useEffect(() => {
    if (primaryId) {
      loadSavedRecords();
    }
  }, [primaryId, secondaryId, identifier, orderNum]);

  const handleSavedRecordsSet = useCallback(
    async (recordsData: P) => {
      if (Array.isArray(recordsData)) {
        throw new Error('Saving array data as saved records is not supported');
      }

      setSaving(true);
      const newSavedRecord = {
        id1: primaryId,
        id2: secondaryId,
        iden: identifier,
        number: orderNum,
        data: recordsData,
        organizationAuth: organizationIdAuthorization,
      };
      const [err] = await masterApi.projects.reports.generalsavedrecord.set.post(newSavedRecord);

      if (err) {
        messageError(err, intl);
      } else {
        setSavedRecords(newSavedRecord);
      }
      setSaving(false);
    },
    [primaryId, secondaryId, identifier, orderNum, organizationIdAuthorization, intl, setSavedRecords]
  );

  const deleteSavedRecords = useCallback(async () => {
    setSaving(true);
    const newSavedRecord = {
      id1: primaryId,
      id2: secondaryId,
      iden: identifier,
      number: orderNum,
      organizationAuth: organizationIdAuthorization,
    };
    const [err] = await masterApi.projects.reports.generalsavedrecord.delete.post(newSavedRecord);

    if (err) {
      messageError(err, intl);
    } else {
      setSavedRecords(undefined);
    }
    setSaving(false);
  }, [primaryId, secondaryId, identifier, orderNum, organizationIdAuthorization, intl, setSavedRecords]);

  return [
    savedRecords as Pick<GeneralSavedRecordDto, 'id1' | 'id2' | 'iden' | 'number'> & { data: P },
    savedRecordsError,
    savedRecordsLoading,
    savedRecordSaving,
    handleSavedRecordsSet,
    deleteSavedRecords,
  ] as const;
};
