import { Form, Typography } from 'antd';
import { DocumentSubscriberEnumsWatchDocumentEnum } from 'api/completeApiInterfaces';
import DocumentMultipleActionError from 'components/DocumentMultipleActionError';
import { DocumentWatchSettings } from 'components/DocumentWatchSettings/DocumentWatchSettings';
import { Fmt } from 'locale';
import React, { FC } from 'react';

export interface BulkEditDocumentsWatchFormData {
  watchTypes: DocumentSubscriberEnumsWatchDocumentEnum[];
}

type Props = {
  errors: JSX.Element[];
};

const BulkEditDocumentsWatchForm: FC<Props> = ({ errors }) => {
  return (
    <>
      <Form.Item
        name="watchTypes"
        initialValue={[]}
        label={
          <Typography.Text strong>
            <Fmt id="BulkEditDocumentsWatchForm.info" />
          </Typography.Text>
        }
      >
        <DocumentWatchSettings />
      </Form.Item>
      <DocumentMultipleActionError errors={errors} titleId="BulkEditDocumentsWatchForm.impossibleChanges" />
    </>
  );
};

export default BulkEditDocumentsWatchForm;
