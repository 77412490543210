import { Dropdown, MenuProps } from 'antd';
import { DropDownProps } from 'antd/lib/dropdown';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import styles from './HeaderDropdown.module.less';

export interface HeaderDropdownProps extends DropDownProps {
  overlayClassName?: string;
  menu: MenuProps;
  placement?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topCenter' | 'topRight' | 'bottomCenter';
}

const HeaderDropdown: FunctionComponent<HeaderDropdownProps> = ({ overlayClassName: cls, ...restProps }) => (
  <Dropdown overlayClassName={classNames(styles.container, cls)} {...restProps} />
);

export { HeaderDropdown };
