import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { JSONVariableTypeEnum } from 'api/completeApiInterfaces';
import { DefaultEditableTreeNode, useEditableTreeItemContext } from 'components/EditableTree/EditableTreeItemContext';
import React from 'react';

type EditMode = 'childNode' | 'sisterNode';

type Props = {
  onClick: (mode: EditMode, id: string) => void;
  mode: EditMode;
  icon?: React.ReactNode;
};

export const EditableTreeAddNode = ({ onClick, mode, icon }: Props) => {
  const { item } = useEditableTreeItemContext<DefaultEditableTreeNode & { type: JSONVariableTypeEnum }>();

  if (!item || (mode === 'childNode' && item.type !== JSONVariableTypeEnum.empty)) return null;

  return (
    <Button
      size="small"
      type="link"
      icon={icon || <PlusOutlined />}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(mode, item.id);
      }}
    />
  );
};
