import { DEBUG } from 'config/env';
import { SIGNAL_R_MD_CONNECTION } from 'config/signalRConnection';
import { useJoinSignalGroup } from 'pages/DocumentDetailPage/features/useJoinSignalGroup';
import { useCallback, useEffect } from 'react';
import { useAggregateEvent } from './useAggregateEvent';
import { useIntl } from './useIntl';

export const SIGNAL_R_DEBOUNCE_DURATION_MS = 500;

export type MDSignalRData = {
  mdProjectId: Guid;
  message: string;
};

export const useMDProjectsChangedSignal = (onMdProjectChange: (messages: MDSignalRData[]) => void) => {
  const intl = useIntl();

  const mapSignal = useCallback(
    (projectId: Guid, mdProjectId: Guid): MDSignalRData => {
      return {
        mdProjectId,
        message: intl.formatMessage({ id: 'SignalR.md.HubProjectAdded.message' }),
      };
    },
    [intl]
  );

  useHandleAggregatedMDSignal('HubProjectAdded', onMdProjectChange, mapSignal);
};

export const useMDSignal = (organizationId: Guid) => {
  useEffect(() => {
    DEBUG && console.log('useMDSignals: start', SIGNAL_R_MD_CONNECTION.hubName);
    void SIGNAL_R_MD_CONNECTION.start();

    return () => {
      DEBUG && console.log('useMDSignals: stop', SIGNAL_R_MD_CONNECTION.hubName);
      void SIGNAL_R_MD_CONNECTION.stop();
    };
  }, []);

  useJoinSignalGroup(SIGNAL_R_MD_CONNECTION, organizationId);
};

const useHandleAggregatedMDSignal = <T extends unknown[]>(
  signalName: string,
  onEventAction: (messages: MDSignalRData[]) => void,
  signalMapper: (...values: T) => MDSignalRData | undefined
) => {
  const onDebounceFunc = useCallback(
    (messages: MDSignalRData[]) => {
      const filteredMessages = messages.filter(Boolean);
      if (!!messages.length) onEventAction(filteredMessages);
    },
    [onEventAction]
  );

  const handleSignal = useAggregateEvent(onDebounceFunc, SIGNAL_R_DEBOUNCE_DURATION_MS, signalMapper);

  useEffect(() => {
    SIGNAL_R_MD_CONNECTION.on(signalName, handleSignal);
    return () => SIGNAL_R_MD_CONNECTION.off(signalName, handleSignal);
  }, [handleSignal]);
};
