import { Form, Input, InputRef, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { OrganizationDto, OrgExtendedPermissionEnum, OrgExtendedPermissionValueEnum } from 'api/completeApiInterfaces';
import { Serializable } from 'components/filters/filterTypes';
import StackPanel from 'components/StackPanel';
import { MAX_ITEM_NAME_LENGTH } from 'config/constants';
import { useCurrentAppUser, useIntl } from 'hooks';
import { RequestCacheApiCallback } from 'hooks/useCachedApiRequest';
import { Fmt } from 'locale';
import { uniqBy } from 'lodash';
import { ProjectsInRealisationOverviewReportData } from 'pages/ReportDetailPage/ReportDetails/ProjectsInRealisationOverviewReport/ProjectsInRealizationOverviewReportUtils';
import React, { FC, useEffect, useMemo } from 'react';
import { maxLengthRule, requiredRule, simpleSelectFilter } from 'utils/formHelpers';
import ProjectsInRealizationConfigurationForm from './ReportWidgetConfigurations/ProjectsInRealizationConfigurationForm';
import { ProjectsInRealizationReportConfigurationColumnFormItem } from './ReportWidgetConfigurations/ProjectsInRealizationReportConfigurationColumnFormItem';
import { ProjectsInRealizationFiltersFormItem } from './ReportWidgetFilterInputs/ProjectsInRealizationFilterFormItem';
import styles from './ReportWidgetForm.module.less';
import {
  getDecimalUnitOptions,
  getLabelAlignOptions,
  ReportWidgetColumnConfiguration,
  ReportWidgetDecimalUnitEnum,
  ReportWidgetLabelAlignEnum,
  supportedReportWidgets,
} from './ReportWidgetForm.utils';

export type ReportWidgetCreateFormData = {
  organizationId: Guid;
  name: string;
  viewDecimalUnit: ReportWidgetDecimalUnitEnum;
  filters: Record<string, Serializable>;
  labelAlign: ReportWidgetLabelAlignEnum;
} & {
  reportType: OrgExtendedPermissionEnum.projectsInRealisationOverview;
  estiConnId: Guid;
  esticonFirmId: Guid;
  reportDate: string;
  startYear: number;
  columnConfigurations: ReportWidgetColumnConfiguration<ProjectsInRealisationOverviewReportData>[];
};

type Props = {
  organizations: OrganizationDto[];
  defaults: ReportWidgetCreateFormData;
  setRef: (ref: InputRef) => void;
  requestCacheApiData: RequestCacheApiCallback;
};

const ReportWidgetCreateForm: FC<Props> = ({ organizations, defaults, requestCacheApiData, setRef }) => {
  const intl = useIntl();
  const form = useFormInstance();
  const currentAppUser = useCurrentAppUser();

  const organizationId = useWatch('organizationId');
  const reportType = useWatch('reportType');
  const reportDate = useWatch('reportDate');
  const startYear = useWatch('startYear');
  const estiConnId = useWatch('estiConnId');
  const esticonFirmId = useWatch('esticonFirmId');

  const decimalUnitOptions = getDecimalUnitOptions(intl);
  const labelAlignOptions = getLabelAlignOptions(intl);

  const selectedOrganizationId = useMemo((): Guid => organizationId || (organizations?.length && organizations[0].id), [
    organizations,
    organizationId,
  ]);
  const selectedReportType = useMemo((): OrgExtendedPermissionEnum => reportType, [reportType]);
  const orgUserPermissions = useMemo(
    () =>
      currentAppUser.organizationUsers.find((user) => user.organization.id === selectedOrganizationId)
        ?.extendedPermissions || [],
    [currentAppUser, selectedOrganizationId]
  );

  useEffect(() => {
    if (
      !currentAppUser.organizationUsers
        .find((user) => user.organization.id === selectedOrganizationId)
        ?.extendedPermissions.some((permission) => permission.permissionType === selectedReportType)
    ) {
      form.setFieldsValue({ reportType: defaults?.reportType });
    }
  }, [selectedOrganizationId]);

  const reportTypeOptions = useMemo(
    () =>
      uniqBy(
        orgUserPermissions.filter(
          (permission) =>
            permission.permission !== OrgExtendedPermissionValueEnum.none &&
            supportedReportWidgets.some((supportedType) => supportedType === permission.permissionType)
        ),
        (permission) => permission.permissionType
      ).map((permission) => (
        <Select.Option key={permission.permissionType} value={permission.permissionType}>
          <Fmt id={`Reporting.reportType.name.${permission.permissionType}`} />
        </Select.Option>
      )),
    [orgUserPermissions]
  );

  return (
    <>
      <StackPanel className={styles.directionColumn}>
        <StackPanel vertical className={styles.generalConfig}>
          <Form.Item
            label={intl.formatMessage({ id: 'forms.items.name.label' })}
            name="name"
            rules={[
              requiredRule('ProjectCreateForm.form.items.name.rules.required', true),
              maxLengthRule('general.maxNameLength', MAX_ITEM_NAME_LENGTH),
            ]}
            initialValue={defaults?.name}
          >
            <Input
              placeholder={intl.formatMessage({ id: 'ProjectCreateForm.form.items.name.placeholder' })}
              autoFocus
              ref={setRef}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ id: 'general.organizations' })}
            name="organizationId"
            initialValue={defaults?.organizationId || (organizations?.length && organizations[0].id)}
            rules={[requiredRule('ReportWidgetCreateFormModalForm.organization.required', true)]}
          >
            <Select showSearch allowClear={false} filterOption={simpleSelectFilter}>
              {organizations?.map((organization) => (
                <Select.Option key={organization.id} value={organization.id}>
                  {organization.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ id: 'ReportWidgetCreateFormModalForm.unit' })}
            name="viewDecimalUnit"
            initialValue={defaults?.viewDecimalUnit}
            rules={[requiredRule('ReportWidgetCreateFormModalForm.unit.required', true)]}
          >
            <Select options={decimalUnitOptions} />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ id: 'ReportWidgetCreateFormModalForm.alignLabel' })}
            name="labelAlign"
            initialValue={defaults?.labelAlign}
            rules={[requiredRule('ReportWidgetCreateFormModalForm.alignLabel.required', true)]}
          >
            <Select options={labelAlignOptions} />
          </Form.Item>

          {selectedOrganizationId && (
            <Form.Item
              label={intl.formatMessage({ id: 'ReportWidgetCreateFormModalForm.columnConfigurations' })}
              name="reportType"
              rules={[requiredRule('ReportWidgetCreateFormModalForm.reportType.required', true)]}
              initialValue={defaults?.reportType}
            >
              <Select showSearch allowClear={false} filterOption={simpleSelectFilter}>
                {reportTypeOptions}
              </Select>
            </Form.Item>
          )}
          {selectedReportType === OrgExtendedPermissionEnum.projectsInRealisationOverview && (
            <>
              <ProjectsInRealizationConfigurationForm
                organizationId={selectedOrganizationId}
                defaultValues={defaults}
              />
            </>
          )}
        </StackPanel>
        <StackPanel vertical>
          {selectedReportType === OrgExtendedPermissionEnum.projectsInRealisationOverview && (
            <>
              <Form.Item
                label={<Fmt id="ReportWidgetCreateFormModalForm.filters" />}
                name="filters"
                initialValue={defaults?.filters || {}}
              >
                <ProjectsInRealizationFiltersFormItem
                  requestCacheApiData={requestCacheApiData}
                  estiConnId={estiConnId}
                  esticonFirmId={esticonFirmId}
                  reportDate={reportDate}
                  startYear={startYear}
                  organizationId={organizationId}
                />
              </Form.Item>
              <Form.Item
                label={<Fmt id="ReportWidgetCreateFormModalForm.columnConfigurations" />}
                name="columnConfigurations"
                rules={[requiredRule('ReportWidgetCreateFormModalForm.columnConfigurations.required', false)]}
                initialValue={defaults?.columnConfigurations || []}
              >
                <ProjectsInRealizationReportConfigurationColumnFormItem intl={intl} columnCountLimit={6} />
              </Form.Item>
            </>
          )}
        </StackPanel>
      </StackPanel>
    </>
  );
};

export default ReportWidgetCreateForm;
