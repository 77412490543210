import { DropDownProps } from 'antd/lib/dropdown';
import { LanguageEnum } from 'locale/messages/interfaces';

export const DEFAULT_LOCALE = LanguageEnum.cs;

// eslint-disable-next-line no-control-regex
export const FILE_AND_FOLDER_NAME_REGEX = /^(?!^(PRN|AUX|NUL|CON|COM\d|LPT\d)(\..*)?$)[^\x00-\x1f\\?*:"|/<>]*[^.\x00-\x1f\\?*:"|/<>]$/i;

// TODO: remove these and replace them with api-generated constraints
export const MAX_PROJECT_NAME_LENGTH = 100;
export const MAX_PROJECT_DESCRIPTION_LENGTH = 1000;
export const MAX_ITEM_NAME_LENGTH = 255;
export const MAX_ITEM_DESCRIPTION_LENGTH = 1000;
export const DEFAULT_PROJECT_TIMEZONE_WINDOWS = 'Central Europe Standard Time';
export const DEFAULT_PROJECT_TIMEZONE_LINUX = 'Europe/Prague';

export const MAX_UPLOAD_PART_SIZE = 1024 * 1024 * 50; // 50 MB

export const PREVIEW_ORIGINAL_URL_EXPIRATION = 240; // seconds

export const EMPTY_GUID: Guid = '00000000-0000-0000-0000-000000000000';
export const ESTICON_USER_GUID: Guid = '00000000-0000-0000-0000-000000000001';
export const EMPTY_DATE: Guid = '0001-01-01T00:00:00Z';

export const SIGNED_DOCUMENT_ACCEPTED_CONTENT_TYPES = ['image/*', 'application/pdf'];
export const MAX_COMMENT_TEXT_ATTACHMENT_COUNT: number = 50;

export const TOOLTIP_MOUSE_ENTER_DELAY: number = 0.5; // seconds
export const MAXIMUM_INPUT_DURATION_DAYS: number = 4000;

export const DEFAULT_SETTINGS_PANEL_WIDTH: number = 800;

export const USER_TRANSFER_MODAL_WIDTH = 700;

export const COMMON_SETTING_MODAL_WIDTH = 800;

export const LARGE_SETTING_MODAL_WIDTH = 1000;

export const DATE_FORMAT_FOR_FILE_EXPORT = 'YYYY_MM_DD';

export const DROPDOWN_TRIGGER_CLICK: DropDownProps['trigger'] = ['click'];

export const HIDE_BUTTON_PROPS = { style: { display: 'none' } };
export const DEFAULT_SIGNAL_DEBOUNCE_WAIT = 300;

export const FULL_WIDTH_FORM_LABEL = { span: 24 }; // TODO: Migrate to Ant Form v4 with styles for form item labels
