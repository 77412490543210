import { ProjectOutlined } from '@ant-design/icons';
import { List, Space, Typography } from 'antd';
import { masterApi } from 'api/completeApi';
import { MessageListDto, MsgOrderTypeEnum, MsgStatusEnum, SortOrder } from 'api/completeApiInterfaces';
import classNames from 'classnames';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { ReportWidgetConfiguration } from 'components/Reports/contexts/ReportWidgetsContextProvider';
import { useApiData, useIntl, useSelectorDispatch } from 'hooks';
import { useDirtyStoreReload, useStoreSelector } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import moment from 'moment';
import { MessageCategoryIcons } from 'pages/MessageCenterPage/Constants';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { projectsMapSelector } from 'store/selectors';
import styles from './UserNotificationStatisticsReport.module.less';

type Props = { configuration: ReportWidgetConfiguration & { type: 'notificationStatistics' } };

const UserNotificationStatisticsReport: React.FC<Props> = ({ configuration }) => {
  const intl = useIntl();
  const history = useHistory();
  const projects = useSelectorDispatch(projectsMapSelector, (dispatch) =>
    dispatch.allProjects.loadData({ reload: false })
  );

  useDirtyStoreReload(
    (state) => state.allProjects,
    (dispatch) => dispatch.allProjects
  );

  const [userNotifications, userNotificationsError, userNotificationsLoading, loadUserNotificaitons] = useApiData(
    (ct) =>
      masterApi.projects.msgcenter.post({
        from: 0,
        size: 30,
        order: [{ property: MsgOrderTypeEnum.createTime, direction: SortOrder.desc }],
        statusFilter: [MsgStatusEnum.new, MsgStatusEnum.read],
      })
  );

  const messageStatistics = useStoreSelector((state) => state.userSummary.data?.messages);

  useEffect(() => {
    loadUserNotificaitons();
  }, [configuration, messageStatistics]);

  const renderItem = useCallback(
    (item: MessageListDto) => (
      <List.Item key={item.id} className={classNames(item.status === MsgStatusEnum.new && styles.unreaded)}>
        <FlowLayout className={classNames(styles.message)}>
          <Typography.Text
            ellipsis
            className={styles.link}
            onClick={() => history.push(`/messagecenter/messages/${item.id}`)}
          >
            <Space>
              {MessageCategoryIcons[item.categoryType]}
              <Fmt id={`MessageCenterPage.message.category.${item.category}`} />
            </Space>
          </Typography.Text>
          <Typography.Text ellipsis className={styles.date}>
            {moment(item.createdDate)
              .locale(intl.locale)
              .format('LLL')}
          </Typography.Text>
          <CommonHubTooltip title={projects?.[item.projectId]?.name}>
            <ProjectOutlined />
          </CommonHubTooltip>
        </FlowLayout>
      </List.Item>
    ),
    [intl, projects]
  );

  return (
    <ContentGate
      loading={!userNotifications?.messages && userNotificationsLoading}
      error={userNotificationsError}
      empty={!userNotificationsLoading && !userNotifications?.messages?.length}
      emptyIntlId="UserNotificationStatisticReport.empty"
    >
      {!!userNotifications && (
        <div className={styles.listContainer}>
          <List
            itemLayout="vertical"
            size="small"
            pagination={{
              pageSize: 6,
              hideOnSinglePage: true,
            }}
            dataSource={userNotifications.messages}
            renderItem={renderItem}
          />
        </div>
      )}
    </ContentGate>
  );
};

export default React.memo(UserNotificationStatisticsReport);
