import { Checkbox, Form, Input } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { apiConstraints } from 'api/completeApiConstraints';
import {
  MdCkIdentificationEnum,
  MdDivisionDto,
  OrgUserDto,
  ProjectUserProfileListDto,
} from 'api/completeApiInterfaces';
import { UserTransferFormItem } from 'components/UserTransfer/UserTransfer';
import { useIntl } from 'hooks';
import { AutoSizeType } from 'rc-textarea/lib/ResizableTextArea';
import React, { FC, useMemo } from 'react';
import { duplicateNameRuleCallback, maxLengthRule, minLengthRule, requiredRule } from 'utils/formHelpers';

export type MDDivisionFormData = {
  name: string;
  description: string;
  organizationName?: string;
  headsOrganizationUserIds: Guid[];
  ckIdentification: MdCkIdentificationEnum[];
  isProposerOnly: boolean;
  users: Guid[];
};

const DESCRIPTION_AUTOSIZE: AutoSizeType = { minRows: 2 };

type Props = {
  validateUniqueName: (name: string) => boolean;
  defaults?: MdDivisionDto;
  organizationUsers: OrgUserDto[];
  showUserTransfer: boolean;
};

const mapUserToTransfer = (user: OrgUserDto): ProjectUserProfileListDto => ({
  id: user.id,
  username: user.appUserProfile.username,
  firstname: user.firstname,
  lastname: user.lastname,
  isAdmin: user.isAdmin,
  status: undefined,
});

export const MDDivisionForm: FC<Props> = ({ validateUniqueName, organizationUsers, showUserTransfer, defaults }) => {
  const intl = useIntl();
  const divisionUsers = useWatch('users');
  const divisionHeadUsers = useWatch('headsOrganizationUserIds');

  const availableHeadList = useMemo(() => {
    const memberUsers: Guid[] = divisionUsers || [];
    return (
      organizationUsers
        .filter((user) => !memberUsers.some((memberId) => user.id === memberId))
        .map(mapUserToTransfer) || []
    );
  }, [divisionUsers, organizationUsers]);

  const availableUserList = useMemo(() => {
    const heads: Guid[] = divisionHeadUsers || [];
    return organizationUsers.filter((user) => !heads.some((headId) => headId === user.id)).map(mapUserToTransfer) || [];
  }, [divisionHeadUsers, organizationUsers]);

  const initialValues = useMemo(() => {
    return {
      name: defaults?.name,
      description: defaults?.description || '',
      organizationName: defaults?.organizationName || '',
      headsOrganizationUserIds: defaults?.mdUsers.filter((user) => user.isHead)?.map((user) => user.orgUser.id) || [],
      users: defaults?.mdUsers.map((user) => user.orgUser.id) || [],
      mdCkIdentifications: defaults?.mdCkIdentifications || [],
      isProposerOnly: defaults?.isProposerOnly,
    };
  }, [defaults]);

  const ckIdentificationOptions = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: 'MD.MDcreateProject.ckIdentification.railway' }),
        value: MdCkIdentificationEnum.railway,
      },
      {
        label: intl.formatMessage({ id: 'MD.MDcreateProject.ckIdentification.road' }),
        value: MdCkIdentificationEnum.road,
      },
      {
        label: intl.formatMessage({ id: 'MD.MDcreateProject.ckIdentification.water' }),
        value: MdCkIdentificationEnum.water,
      },
      {
        label: intl.formatMessage({ id: 'MD.MDcreateProject.ckIdentification.other' }),
        value: MdCkIdentificationEnum.other,
      },
    ],
    [intl]
  );

  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: 'MD.Organization.divisions.modal.input.name' })}
        name="name"
        rules={[
          requiredRule('forms.items.name.rules.required', true),
          maxLengthRule('general.maxNameLength', apiConstraints.mdDivisionCreateDto.name.maxLength),
          minLengthRule('general.minNameLength', apiConstraints.mdDivisionCreateDto.name.minLength),
          duplicateNameRuleCallback(validateUniqueName),
        ]}
        initialValue={initialValues.name}
      >
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'MD.Organization.divisions.modal.input.description' })}
        name="description"
        rules={[
          maxLengthRule('general.maxDescriptionLength', apiConstraints.mdDivisionCreateDto.description.maxLength),
        ]}
        initialValue={initialValues.description}
      >
        <Input.TextArea rows={2} autoSize={DESCRIPTION_AUTOSIZE} />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'MD.Organization.divisions.modal.input.organization' })}
        name="organizationName"
        rules={[
          {
            whitespace: true,
            message: intl.formatMessage({ id: 'forms.items.name.rules.empty' }),
          },
          maxLengthRule('general.maxNameLength', apiConstraints.mdDivisionCreateDto.mdOrganizationName.maxLength),
        ]}
        initialValue={initialValues.organizationName}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'MD.ProjectVariableEnum.ckIdentificaion.name' })}
        name="ckIdentification"
        initialValue={initialValues?.mdCkIdentifications}
      >
        <Checkbox.Group options={ckIdentificationOptions} />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'MD.Organization.divisions.modal.input.isProposerOnlyCheck' })}
        name="isProposerOnly"
        valuePropName="checked"
        initialValue={initialValues?.isProposerOnly}
      >
        <Checkbox />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'MD.Organization.divisions.modal.input.leader' })}
        name="headsOrganizationUserIds"
        rules={[requiredRule('MD.Organization.divisions.modal.input.leader.required', false)]}
        initialValue={initialValues.headsOrganizationUserIds}
      >
        <UserTransferFormItem users={availableHeadList} />
      </Form.Item>
      {showUserTransfer && (
        <Form.Item label={intl.formatMessage({ id: 'general.users' })} name="users" initialValue={initialValues.users}>
          <UserTransferFormItem users={availableUserList} />
        </Form.Item>
      )}
    </>
  );
};
