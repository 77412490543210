import { ProjectMetadataDefinitionCreateDto, ProjectMetadataDefinitionDto } from 'api/completeApiInterfaces';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper } from 'components/forms/FormModalWrapper/FormModalWrapper';
import { COMMON_SETTING_MODAL_WIDTH } from 'config/constants';
import { useActiveProject } from 'hooks';
import { useSameCallback } from 'hooks/useSameCallback';
import React, { FunctionComponent } from 'react';
import { MetadataForm, MetadataFormData } from './MetadataForm';

type Props = FormModalProps<MetadataFormData> & {
  onSubmit: (value: ProjectMetadataDefinitionCreateDto) => void;
  onClose: () => void;
  selectedMetadataItem: ProjectMetadataDefinitionDto;
  metadataDefinitions: ProjectMetadataDefinitionDto[];
};

export const MetadataFormModal: FunctionComponent<Props> = ({
  onSubmit,
  onClose,
  open,
  selectedMetadataItem,
  metadataDefinitions = [],
  ...restProps
}) => {
  const isInProject = useActiveProject() !== null;

  const handleSubmit = useSameCallback(async (values: MetadataFormData) => {
    if (!values) {
      return null;
    }

    await onSubmit(values);
    return null;
  });

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      onClose={onClose}
      titleId={!!selectedMetadataItem ? 'Metadata.editProperty.title' : 'Metadata.newProperty.title'}
      width={COMMON_SETTING_MODAL_WIDTH}
      open={open && isInProject}
      {...restProps}
    >
      <MetadataForm selectedMetadataItem={selectedMetadataItem} usedItems={metadataDefinitions} />
    </FormModalWrapper>
  );
};
