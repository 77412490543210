import { message } from 'antd';
import { api } from 'api';
import {
  AccessLevelEnum,
  DocumentLinkDto,
  DocumentMoveResultDto,
  DownloadUrl,
  SharedSharedOutputTypeEnum,
} from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import DirectoryForbiddenError from 'components/DirectoryForbiddenErrorBox';
import { mapDocumentLinkDtoToCommonLinkedDocument } from 'components/DocumentLinkRow/DocumentLinkRow';
import { DocumentSelectDocumentState } from 'components/DocumentSelect/DocumentSelect';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { FiltersContext } from 'components/filters/FiltersContextProvider';
import { FilterToolbarStyled } from 'components/filters/render/FilterToolbar/FilterToolbarStyled';
import { AppUserShareFormModal } from 'components/forms/AppUserShareForm';
import { AssignmentFormModal } from 'components/forms/AssignmentForm/AssignmentFormModal';
import BulkEditDocumentsLabelsFormModal from 'components/forms/BulkEditDocumentsLabelsForm/BulkEditDocumentsLabelsFormModal';
import BulkEditDocumentsStateFormModal from 'components/forms/BulkEditDocumentsStateForm/BulkEditDocumentsStateFormModal';
import BulkEditDocumentsWatchFormModal from 'components/forms/BulkEditDocumentsWatchForm/BulkEditDocumentsWatchFormModal';
import { DocumentsDiscardFormModal } from 'components/forms/DiscardForm';
import DocumentLinkRenameFormModal from 'components/forms/DocumentLinkRenameForm/DocumentLinkRenameFormModal';
import DocumentsCreateLinksFormModal from 'components/forms/DocumentLinksCreateForm/DocumentLinksCreateFormModal';
import { DocumentsDownloadFormModal } from 'components/forms/DocumentsDownloadForm';
import { DocumentsMoveFormModal, showDocumentsMoveRenameInfo } from 'components/forms/DocumentsMoveForm';
import { ShareDownloadFormModal } from 'components/forms/ShareDownloadForm';
import ServiceErrorBox from 'components/ServiceErrorBox';
import { useActiveProject, useApiData, useBoolean, useCurrentProjectUser, useIntl } from 'hooks';
import { useDocuments } from 'hooks/useDocuments';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { uniqBy } from 'lodash';
import { SelectedItemsContext } from 'pages/AllDocumentsPage/AllDocumentsPage.SelectedItemsContextProvider';
import BulkAddToModelsModal from 'pages/ModelPage/BulkAddToModelsModal';
import BulkRemoveFromModelsModal from 'pages/ModelPage/BulkRemoveFromModelsModal';
import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { ConnectedDirectory } from 'utils/typeMappings/directories/directoryTypes';
import FileToolbar from './AllDocumentsPage.FileToolbar';
import styles from './AllDocumentsPage.module.less';
import AllDocumentsPageDocumentsGrid from './AllDocumentsPageDocumentsGrid';
import DocumentsGate from './DocumentsGate';

type Props = {
  directory: ConnectedDirectory;
  directoryToolbar: ReactNode;
};

type State = {
  createDocumentLinksModalVisible: boolean;
  downloadZipLoading: boolean;
  signDocumentsModalVisible: boolean;
  signDocumentsNotSignable: boolean;
};

export const AllDocumentsPageContent: FunctionComponent<Props> = ({ directory, directoryToolbar }) => {
  const [state, setState] = useState<State>({
    createDocumentLinksModalVisible: false,
    downloadZipLoading: false,
    signDocumentsModalVisible: false,
    signDocumentsNotSignable: false,
  });

  const projectId = useActiveProject().id;
  const intl = useIntl();

  const [moveDocumentsModalVisible, showMoveDocumentsModal, hideMoveDocumentsModal] = useBoolean(false);
  const [downloadDocumentsModalVisible, showDownloadDocumentsModal, hideDownloadDocumentsModal] = useBoolean(false);
  const [discardDocumentsModalVisible, showDiscardDocumentsModal, hideDiscardDocumentsModal] = useBoolean(false);
  const [
    bulkEditDocumentsStatesModalVisible,
    showBulkEditDocumentsStatesModal,
    hideBulkEditDocumentsStatesModal,
  ] = useBoolean(false);
  const [
    bulkEditDocumentsLabelsModalVisible,
    showBulkEditDocumentsLabelsModal,
    hideBulkEditDocumentsLabelsModal,
  ] = useBoolean(false);
  const [
    bulkEditDocumentsWatchModalVisible,
    showBulkEditDocumentsWatchModal,
    hideBulkEditDocumentsWatchModal,
  ] = useBoolean(false);
  const [createAssigmentModalVisible, showAssigmentCreateModal, hideAssigmentCreateModal] = useBoolean(false);

  const [shareAppUserModalVisible, showShareAppUserModal, hideShareAppUserModal] = useBoolean(false);
  const [shareDownloadModalVisible, showShareDownloadModal, hideShareDownloadModal] = useBoolean(false);

  const [renameDocumentLinkModalVisible, showRenameDocumentLinkModal, hideRenameDocumentLinkModal] = useBoolean(false);
  const [
    removeDocumentsFromModelsModalVisible,
    showRemoveDocumentsFromModelsModal,
    hideRemoveDocumentsFromModelsModal,
  ] = useBoolean(false);
  const [
    addDocumentsFromModelsModalVisible,
    showAddDocumentsFromModelsModal,
    hideAddDocumentsFromModelsModal,
  ] = useBoolean(false);
  const [focusedDocumentLink, setFocusedDocumentLink] = useState<DocumentLinkDto>(undefined);
  const dispatch = useDispatch<Dispatch>();

  const currentUser = useCurrentProjectUser();

  useEffect(() => {
    dispatch.projectUsers.loadData({ reload: false });
    dispatch.groups.loadData({ reload: false });
  }, []);

  useDirtyStoreReload(
    (store) => store.projectUsers,
    (dispatch) => dispatch.projectUsers
  );

  useDirtyStoreReload(
    (store) => store.groups,
    (dispatch) => dispatch.groups
  );

  const [documents, documentLinks, documentsLoading, documentsError, reloadDocuments] = useDocuments(
    directory.id,
    projectId
  );

  const commonDocuments = useMemo(
    () =>
      documents
        ?.map((document) => ({ ...document, revision: document.currentRevision }))
        .concat(documentLinks?.map(mapDocumentLinkDtoToCommonLinkedDocument) || []),
    [documents, documentLinks]
  );

  const { filteredSortedItems, setItems, hasFilteredOutItems, clearFilters, isFilterActive } = useContext(
    FiltersContext
  );

  useEffect(() => {
    setItems(commonDocuments);
  }, [commonDocuments]);

  const listRef = useRef();
  const { selectedItemsIds, selectItem, isAnyItemSelected, setConfig } = useContext(SelectedItemsContext);

  useEffect(() => {
    if (!filteredSortedItems) return;
    setConfig({
      listRef: listRef,
      items: filteredSortedItems,
    });
  }, [filteredSortedItems]);

  const selectedDocuments = useMemo(() => documents?.filter((document) => selectedItemsIds.has(document.id)) || [], [
    documents,
    selectedItemsIds,
  ]);
  const selectedLinks = useMemo(() => documentLinks?.filter((link) => selectedItemsIds.has(link.id)) || [], [
    documentLinks,
    selectedItemsIds,
  ]);

  const selectedDocumentIds = useMemo(() => selectedDocuments.map((document) => document.id), [selectedDocuments]);
  const selectedDocumentLinkIds = useMemo(() => selectedLinks.map((link) => link.id), [selectedLinks]);

  const selectedAssignmentDocuments = useMemo(
    (): DocumentSelectDocumentState[] =>
      uniqBy(
        [
          ...selectedDocuments.map((document) => ({
            id: document.id,
            directoryId: document.directoryId,
            name: document.name,
            directoryPath: document.directoryPath,
          })),
          ...selectedLinks
            .filter((link) => !!link.linkedDocument)
            .map((link) => ({
              id: link.linkedDocument.id,
              directoryId: link.linkedDocument.directoryId,
              name: link.linkedDocument.name,
              directoryPath: link.linkedDocument.directoryPath,
            })),
        ],
        'id'
      ),
    [selectedDocuments, selectedLinks]
  );

  const [availableDocumentsStates, error, loadingAvailableDocumentStates, loadAvailableDocumentStates] = useApiData(
    (ct) =>
      api.project.documents.bulkEditDocumentsStatesCheck(
        { documentsIds: selectedDocumentIds, documentLinksIds: selectedDocumentLinkIds },
        ct
      ),
    { autoload: false }
  );
  useEffect(() => {
    if (bulkEditDocumentsStatesModalVisible) loadAvailableDocumentStates();
  }, [bulkEditDocumentsStatesModalVisible]);

  // documents and links
  const selectedTargetDocumentIds = useMemo(
    () => [...selectedDocumentIds, ...selectedLinks.map((link) => link.linkedDocument?.id).filter(Boolean)],
    [selectedDocumentIds, selectedLinks]
  );

  const newLinkDefaultName = useMemo(() => {
    // TODO: we could *really* use a unified variant for documents and links here
    if (selectedDocuments.length === 1 && selectedLinks.length === 0) {
      return selectedDocuments[0].name;
    }
    if (selectedDocuments.length === 0 && selectedLinks.length === 1) {
      return selectedLinks[0].linkedDocument?.name;
    }
    return undefined;
  }, [selectedDocuments, selectedLinks]);

  const handleMoveDocumentFormSubmit = async (data: DocumentMoveResultDto) => {
    reloadDocuments();
    if (data.renamedDocuments.length > 0) {
      showDocumentsMoveRenameInfo(data, intl);
    }
    hideMoveDocumentsModal();
  };

  const handleDownloadDocumentFormSubmit = async (data: DownloadUrl) => {
    window.open(data.url, '_blank');
    hideDownloadDocumentsModal();
  };

  const handleDocumentLinksCreate = () => {
    setState(
      (state): State => ({
        ...state,
        createDocumentLinksModalVisible: true,
      })
    );
  };

  const handleDiscardDocumentFormSubmit = async () => {
    reloadDocuments();
    hideDiscardDocumentsModal();
  };

  const handleCreateDocumentLinksFormSubmit = (createdLinksCount: number) => {
    message.success(
      intl.formatMessage({ id: 'DocumentLinksCreateFormModal.result.success' }, { count: createdLinksCount })
    );
    setState((state): State => ({ ...state, createDocumentLinksModalVisible: false }));
  };

  const handleCloseCreateDocumentLinksModal = () => {
    setState((state): State => ({ ...state, createDocumentLinksModalVisible: false }));
  };

  const handleRemoveFromModelsModalSubmit = useCallback(() => {
    hideRemoveDocumentsFromModelsModal();
    void reloadDocuments();
  }, []);

  const handleRemoveFromModelsModalClose = useCallback(() => {
    hideRemoveDocumentsFromModelsModal();
  }, []);

  const handleAddFromModelsModalSubmit = useCallback(() => {
    hideAddDocumentsFromModelsModal();
    void reloadDocuments();
  }, []);

  const handleAddFromModelsModalClose = useCallback(() => {
    hideAddDocumentsFromModelsModal();
  }, []);

  const handleBulkEditDocumentsStatesFormSubmit = async () => {
    reloadDocuments();
    hideBulkEditDocumentsStatesModal();
  };

  const handleBulkEditDocumentsLabelsFormSubmit = async () => {
    reloadDocuments();
    hideBulkEditDocumentsLabelsModal();
  };

  const handleAssigmentCreateFormSubmit = async () => {
    message.success(intl.formatMessage({ id: 'AssignmentFormModal.result.success' }));
    hideAssigmentCreateModal();
  };

  const onLinkIconClick = useCallback(
    (linkId: Guid) => {
      setFocusedDocumentLink(documentLinks.find((link) => link.id === linkId));
      showRenameDocumentLinkModal();
    },
    [documentLinks]
  );

  const handleRenameDocumentLinkFormSubmit = () => {
    reloadDocuments();
    hideRenameDocumentLinkModal();
  };

  // when permissions change from None to "Some", we might need to load documents
  useEffect(() => {
    const hasSomePermissions = directory.currentAccessLevel !== AccessLevelEnum.none;
    const couldNotLoadDocuments = documentsError?.referenceErrorCode === ServiceErrorEnum.DirectoryForbiddenError;
    if (hasSomePermissions && couldNotLoadDocuments) {
      reloadDocuments();
    }
  }, [directory.currentAccessLevel, documentsError]);

  const dir = directory;

  const moveDocumentDisabled: boolean =
    !dir || (dir.currentAccessLevel !== AccessLevelEnum.admin && dir.currentAccessLevel !== AccessLevelEnum.write);
  const downloadDocumentDisabled: boolean = !dir || dir.currentAccessLevel === AccessLevelEnum.none;
  const discardDocumentsDisabled: boolean =
    !dir || (dir.currentAccessLevel !== AccessLevelEnum.admin && dir.currentAccessLevel !== AccessLevelEnum.write);

  return (
    <>
      <DocumentsGate
        loadingTip={intl.formatMessage({ id: 'DocumentsGate.loading' })}
        documentsLoading={documentsLoading}
        documentsError={
          documentsError?.referenceErrorCode !== ServiceErrorEnum.DirectoryForbiddenError && documentsError
        }
        documents={documents}
      >
        <>
          {directory.currentAccessLevel !== AccessLevelEnum.none && (
            <div className={styles.docsPane}>
              {!isAnyItemSelected ? (
                directoryToolbar
              ) : (
                <FileToolbar
                  className={styles.fileToolbar}
                  selectedFilesIds={selectedItemsIds}
                  discardDisabled={discardDocumentsDisabled}
                  downloadDisabled={downloadDocumentDisabled}
                  shareDownloadDisabled={downloadDocumentDisabled}
                  moveDisabled={moveDocumentDisabled}
                  bulkChangeStateDisabled={discardDocumentsDisabled}
                  directory={directory}
                  documents={filteredSortedItems}
                  onDocumentsMove={showMoveDocumentsModal}
                  onDocumentsDownload={showDownloadDocumentsModal}
                  onDocumentsDiscard={showDiscardDocumentsModal}
                  onShareDownload={showShareDownloadModal}
                  onAppUserShare={showShareAppUserModal}
                  onDocumentLinksCreate={handleDocumentLinksCreate}
                  onBulkEditChangeState={showBulkEditDocumentsStatesModal}
                  onBulkEditSetLabels={showBulkEditDocumentsLabelsModal}
                  onBulkEditDocumentWatch={showBulkEditDocumentsWatchModal}
                  onCreateAssignment={showAssigmentCreateModal}
                  onBulkRemoveFromModels={showRemoveDocumentsFromModelsModal}
                  onBulkAddToModels={showAddDocumentsFromModelsModal}
                />
              )}

              <div className={styles.content}>
                <ErrorBoundary>
                  <div className={styles.documentGridWrap}>
                    <FilterToolbarStyled />
                    {documentsError && <ServiceErrorBox noFlex error={documentsError} />}
                    {filteredSortedItems && (
                      <AllDocumentsPageDocumentsGrid
                        documents={filteredSortedItems}
                        selectedFilesIds={selectedItemsIds}
                        listRef={listRef}
                        directory={directory}
                        projectId={projectId}
                        currentUser={currentUser}
                        onSelectFile={selectItem}
                        reloadDocuments={reloadDocuments}
                        clearFilters={clearFilters}
                        hasFilteredOutItems={hasFilteredOutItems}
                        onLinkIconClick={onLinkIconClick}
                      />
                    )}
                  </div>
                </ErrorBoundary>
              </div>
            </div>
          )}
          {(documentsError?.referenceErrorCode === ServiceErrorEnum.DirectoryForbiddenError ||
            directory.currentAccessLevel === AccessLevelEnum.none) && (
            <DirectoryForbiddenError showRequestDialog selectedDirectoryId={directory.id} />
          )}
        </>
      </DocumentsGate>
      <ErrorBoundary>
        <DocumentsMoveFormModal
          selectedDocumentIds={selectedDocumentIds}
          selectedDocumentLinkIds={selectedDocumentLinkIds}
          selectedDirectory={directory}
          open={moveDocumentsModalVisible}
          onSubmit={handleMoveDocumentFormSubmit}
          onClose={hideMoveDocumentsModal}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <DocumentsDiscardFormModal
          selectedDocumentIds={selectedDocumentIds}
          selectedDocumentLinkIds={selectedDocumentLinkIds}
          selectedDirectory={directory}
          open={discardDocumentsModalVisible}
          onSubmit={handleDiscardDocumentFormSubmit}
          onClose={hideDiscardDocumentsModal}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <DocumentsCreateLinksFormModal
          selectedDocumentIds={selectedDocumentIds}
          selectedDocumentLinkIds={selectedDocumentLinkIds}
          selectedDirectory={directory}
          defaultDocumentLinkName={newLinkDefaultName}
          open={state.createDocumentLinksModalVisible}
          onSubmit={handleCreateDocumentLinksFormSubmit}
          onClose={handleCloseCreateDocumentLinksModal}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <DocumentLinkRenameFormModal
          focusedDocumentLink={focusedDocumentLink}
          open={renameDocumentLinkModalVisible}
          onSubmit={handleRenameDocumentLinkFormSubmit}
          onClose={hideRenameDocumentLinkModal}
          showTargetPath
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <DocumentsDownloadFormModal
          selectedDocumentIds={selectedDocumentIds}
          selectedDocumentLinkIds={selectedDocumentLinkIds}
          firstDocumentName={directory.name}
          open={downloadDocumentsModalVisible}
          onSubmit={handleDownloadDocumentFormSubmit}
          onClose={hideDownloadDocumentsModal}
          includingDirectories={false}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <AppUserShareFormModal
          shareDocument={true}
          directoryId={directory.id}
          documentIds={selectedDocumentIds}
          documentLinkIds={selectedDocumentLinkIds}
          open={shareAppUserModalVisible}
          onSubmit={hideShareAppUserModal}
          onClose={hideShareAppUserModal}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <ShareDownloadFormModal
          documentsIds={selectedTargetDocumentIds}
          revisionIds={[]}
          sharingType={
            selectedDocumentIds?.length > 1 ? SharedSharedOutputTypeEnum.zip : SharedSharedOutputTypeEnum.file
          }
          open={shareDownloadModalVisible}
          onSubmit={hideShareDownloadModal}
          onClose={hideShareDownloadModal}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <BulkEditDocumentsStateFormModal
          documentsIds={selectedDocumentIds}
          documentLinksIds={selectedDocumentLinkIds}
          availableDocumentsStates={availableDocumentsStates}
          open={bulkEditDocumentsStatesModalVisible}
          onSubmit={handleBulkEditDocumentsStatesFormSubmit}
          onClose={hideBulkEditDocumentsStatesModal}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <BulkEditDocumentsLabelsFormModal
          documentsIds={selectedDocumentIds}
          documentLinksIds={selectedDocumentLinkIds}
          open={bulkEditDocumentsLabelsModalVisible}
          onSubmit={handleBulkEditDocumentsLabelsFormSubmit}
          onClose={hideBulkEditDocumentsLabelsModal}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <BulkEditDocumentsWatchFormModal
          documentsIds={selectedDocumentIds}
          documentLinksIds={selectedDocumentLinkIds}
          open={bulkEditDocumentsWatchModalVisible}
          onSubmit={hideBulkEditDocumentsWatchModal}
          onClose={hideBulkEditDocumentsWatchModal}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <AssignmentFormModal
          open={createAssigmentModalVisible}
          onClose={hideAssigmentCreateModal}
          onSubmit={handleAssigmentCreateFormSubmit}
          initialDocuments={selectedAssignmentDocuments}
          intl={intl}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <BulkRemoveFromModelsModal
          open={removeDocumentsFromModelsModalVisible}
          onSubmit={handleRemoveFromModelsModalSubmit}
          onClose={handleRemoveFromModelsModalClose}
          selectedModelIds={selectedTargetDocumentIds}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <BulkAddToModelsModal
          open={addDocumentsFromModelsModalVisible}
          onSubmit={handleAddFromModelsModalSubmit}
          onClose={handleAddFromModelsModalClose}
          selectedDocumentsIds={selectedTargetDocumentIds}
        />
      </ErrorBoundary>
    </>
  );
};
