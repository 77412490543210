import { api } from 'api';
import {
  MessageListDto,
  MsgCenterIntervalListRequestDto,
  MsgCenterIntervalResponseDto,
  MsgOrderTypeEnum,
  MsgStatusEnum,
  ProjectListDto,
  SortOrder,
} from 'api/completeApiInterfaces';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import StackPanel from 'components/StackPanel';
import { useIntl, useIsMounted, useStoreSelector } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Dictionary, min } from 'lodash';
import { MessageDetail } from 'pages/MessageCenterPage/messages/MessageDetail';
import React, { FunctionComponent, useEffect, useMemo, useReducer, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { projectsListSelector } from 'store/selectors';
import { DEFAULT_PAGE_SIZE, MARK_AS_READ_DELAY } from '../Constants';
import { MessageGrid, ProjectMessageNames } from '../messages/MessageGrid';

type Props = {
  defaultProjectId?: Guid;
  messageId?: Guid;
  defaultFromFilter?: string;
  defaultToFilter?: string;
  projectList: ProjectListDto[];
  setMessageState?: (selectedMessages: Guid[], status: MsgStatusEnum) => Promise<boolean>;
};

type MessagesState = {
  selectedMessageId: Guid;
  intervalMessages: MessageListDto[][];
  pageCount: number;
  total: number;
  currentPageIndex: number;
  pageSize: number;
};

export type SetMessagesInterval = {
  type: 'setMessagesInterval';
  data: MsgCenterIntervalResponseDto;
  messageId: Guid;
};

export type SelectMessage = {
  type: 'selectMessage';
  messageId: Guid;
};

export type SetMessageState = {
  type: 'setMessagesState';
  messages: Guid[];
  state: MsgStatusEnum;
};

export type ChangePage = {
  type: 'changePage';
  pageSize: number;
  currentPageIndex: number;
};

export type ReportsReducerActions = SetMessagesInterval | SelectMessage | SetMessageState | ChangePage;

const reducer = (prevState: MessagesState, action: ReportsReducerActions): MessagesState => {
  switch (action.type) {
    case 'setMessagesInterval':
      // eslint-disable-next-line no-case-declarations
      const currentPageIndex = action.data.currentPage >= 0 ? action.data.currentPage : 0; // negative page in case of not found selected message from report email. Show first page
      return {
        ...prevState,
        intervalMessages: action.data.messages,
        pageCount: action.data.pageCount,
        total: action.data.total,
        pageSize: action.data.defaultPageSize,
        currentPageIndex: currentPageIndex,
        selectedMessageId: action.messageId,
      };
    case 'setMessagesState':
      // eslint-disable-next-line no-case-declarations
      const updatedMessages = prevState.intervalMessages[prevState.currentPageIndex].map((message) => ({
        ...message,
        status: action.messages.some((messageId) => messageId === message.id) ? action.state : message.status,
      }));

      return {
        ...prevState,
        intervalMessages: [
          ...prevState.intervalMessages.map((messagePage, pageIndex) =>
            pageIndex === prevState.currentPageIndex ? updatedMessages : messagePage
          ),
        ],
      };
    case 'selectMessage':
      return {
        ...prevState,
        selectedMessageId: action.messageId,
      };
    case 'changePage':
      return {
        ...prevState,
        pageSize: action.pageSize,
        currentPageIndex: min([action.currentPageIndex, prevState.intervalMessages.length - 1]),
      };
  }
};

const EMPTY_FUNCTION = () => {};

const getDefaultMessageList = (): MessagesState => {
  return {
    intervalMessages: [[]],
    total: 0,
    pageCount: 1,
    selectedMessageId: null,
    currentPageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  };
};

export const MessageCenterReports: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const { url } = useRouteMatch();
  const { defaultProjectId, messageId, defaultFromFilter, defaultToFilter, setMessageState } = props;

  const isMounted = useIsMounted();
  const [messagesList, messagesDispatch] = useReducer(reducer, getDefaultMessageList());
  const [intervalMessages, setIntervalMessages] = useState<MsgCenterIntervalResponseDto>();

  const selectedMessage = useMemo(
    () =>
      messagesList.intervalMessages[messagesList.currentPageIndex]?.find(
        (m) => messagesList.selectedMessageId === m.id
      ),
    [messagesList.intervalMessages, messagesList.currentPageIndex, messagesList.selectedMessageId]
  );

  useDebounce(
    async () => {
      if (selectedMessage?.status !== MsgStatusEnum.new) return;

      if (await setMessageState([selectedMessage.id], MsgStatusEnum.read)) {
        if (!isMounted.current) {
          return;
        }

        messagesDispatch({
          type: 'setMessagesState',
          messages: [selectedMessage.id],
          state: MsgStatusEnum.read,
        });
      }
    },
    MARK_AS_READ_DELAY,
    [selectedMessage]
  );

  useEffect(() => {
    intervalMessages && messagesDispatch({ type: 'setMessagesInterval', data: intervalMessages, messageId: messageId });
  }, [intervalMessages, messageId]);

  useEffect(() => {
    const load = async () => {
      const requestData: MsgCenterIntervalListRequestDto = {
        projectId: defaultProjectId,
        createTimeFromFilter: defaultFromFilter,
        createTimeToFilter: defaultToFilter,
        requestedMessage: messageId,
        size: messagesList.pageSize,
        order: [{ property: MsgOrderTypeEnum.createTime, direction: SortOrder.asc }],
      };
      const [error, response] = await api.master.messageCenter.getIntervalMessage(requestData);

      if (!isMounted.current) {
        return;
      }
      if (!error) {
        setIntervalMessages(response.data);
      }
    };
    void load();
  }, [messagesList.pageSize, defaultProjectId, defaultFromFilter, defaultToFilter]);

  const projects = useStoreSelector(projectsListSelector);
  useDirtyStoreReload(
    (state) => state.allProjects,
    (dispatch) => dispatch.allProjects
  );

  const projectNames = useMemo(
    (): Dictionary<ProjectMessageNames> =>
      projects?.reduce(
        (acc, project) => ({
          ...acc,
          [project.id]: { name: project.name, organizationName: project.organization.name },
        }),
        {}
      ) || {},
    [projects]
  );

  // render
  return (
    <>
      <MasterComponent
        url={url}
        maxWidth={800}
        title={intl.formatMessage({ id: 'MessageCenterPage.tabs.report' })}
        children={(onClick, selectedItemId) => (
          <MessageGrid
            messageList={messagesList?.intervalMessages[messagesList.currentPageIndex]}
            currentPageIndex={messagesList?.currentPageIndex}
            totalMessages={messagesList?.total}
            pageSize={messagesList?.pageSize}
            messagesDispatch={messagesDispatch}
            clearFilters={EMPTY_FUNCTION}
            onClick={onClick}
            selectedItemId={selectedItemId}
          />
        )}
      />
      <Switch>
        <Route
          path={`${url}/:itemId`}
          render={(props) => (
            <MasterComponent
              key={props.match.params.itemId}
              url={props.match.url}
              maxWidth={800}
              title={
                !!selectedMessage &&
                intl.formatMessage({ id: `MessageCenterPage.message.category.${selectedMessage.category}` })
              }
              children={() => (
                <StackPanel vertical scrollable>
                  <MessageDetail
                    messageId={props.match.params.itemId}
                    projectNames={projectNames}
                    setMessageState={setMessageState}
                    messagesDispatch={messagesDispatch}
                  />
                </StackPanel>
              )}
            />
          )}
        />
      </Switch>
    </>
  );
};
