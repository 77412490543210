import { ArrowsAltOutlined, CloseOutlined, ShrinkOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useIntl } from 'hooks';
import { useCloseOnEscape } from 'hooks/useCloseOnEscape';
import { useSetOrientation } from 'hooks/useSetOrientation';
import isMobile from 'is-mobile';
import React, { FunctionComponent, MouseEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import styles from './Modal.module.less';

type Props = {
  visible?: boolean;
  onCancel?: (e?: MouseEvent<HTMLElement> | KeyboardEvent) => void;
  isFullscreenDefault?: boolean;
  title?: ReactNode;
  tabs?: ReactNode;
  additionalButtons?: ReactNode;
  onFullscreenChange?: (isFullscreen: boolean) => void;
  width?: number;
  closeOnBackdrop?: boolean;
};

const Modal: FunctionComponent<Props> = ({
  children,
  visible,
  onCancel,
  isFullscreenDefault,
  title,
  tabs,
  onFullscreenChange,
  additionalButtons,
  width,
  closeOnBackdrop,
}) => {
  const isMobileDevice = isMobile();
  const [fullscreen, setFullscreen] = useState(
    isFullscreenDefault !== undefined ? isFullscreenDefault : isMobileDevice
  );

  const intl = useIntl();

  const changeFullscreen = useCallback(() => {
    setFullscreen((f) => {
      onFullscreenChange && onFullscreenChange(!f);
      return !f;
    });
  }, [onFullscreenChange]);

  useSetOrientation(isMobileDevice && fullscreen ? 'landscape' : null);

  useCloseOnEscape(visible, onCancel);

  const style = !fullscreen ? { maxWidth: `${width}px` } : undefined;

  const onBackdropClick = useCallback(() => {
    if (closeOnBackdrop) {
      onCancel && onCancel();
    }
  }, [onCancel]);

  const backdropRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (visible) backdropRef.current?.focus();
  }, [visible]);

  return (
    <>
      {visible && <div className={styles.backdrop} onClick={onBackdropClick} />}
      <div
        className={classNames(styles.modal, fullscreen && styles.fullscreen, visible && styles.visible)}
        style={style}
        tabIndex={1}
        ref={backdropRef}
      >
        <div className={styles.header}>
          <FlowLayout>{tabs}</FlowLayout>
          <div className={styles.title}>{title}</div>
          <FlowLayout className={styles.buttons}>
            {additionalButtons}
            <Button
              icon={fullscreen ? <ShrinkOutlined /> : <ArrowsAltOutlined />}
              onClick={changeFullscreen}
              type="link"
              title={
                fullscreen
                  ? intl.formatMessage({ id: 'fullscreen.minimize' })
                  : intl.formatMessage({ id: 'fullscreen.maximize' })
              }
            />
            <Button
              icon={<CloseOutlined />}
              onClick={onCancel}
              type="link"
              title={`${intl.formatMessage({ id: 'general.close' })} (${intl.formatMessage({ id: 'general.escape' })})`}
            />
          </FlowLayout>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </>
  );
};

export default Modal;
