import { DatePicker, Form, Select } from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import { DefaultOptionType } from 'antd/lib/select';
import { masterApi } from 'api/completeApi';
import { useApiData } from 'hooks';
import { Fmt } from 'locale';
import moment from 'moment';
import { FunctionComponent, default as React, useEffect, useMemo } from 'react';
import { requiredRule } from 'utils/formHelpers';
import { ReportWidgetCreateFormData } from '../ReportWidgetCreateForm';
import { ReportWidgetEditFormData } from '../ReportWidgetEditForm';

const REPORT_FROM_START_YEAR = 2022; // TODO: Unify this value and whole form with report settings on report detail page for ProjectsInRealization report

type FormPropsData = ReportWidgetCreateFormData | ReportWidgetEditFormData;
type Props = {
  organizationId: Guid;
  defaultValues?: FormPropsData;
};

const ProjectsInRealizationConfigurationForm: FunctionComponent<Props> = ({ organizationId, defaultValues }) => {
  const [orgEsticonReport, orgEsticonReportError, orgEsticonReportLoading, loadOrgEsticonReport] = useApiData((ct) =>
    masterApi.projects.reports.firmlist.post({ organizationId: organizationId }, ct)
  );

  useEffect(() => {
    loadOrgEsticonReport();
  }, [organizationId]);

  const esticonConnsOptions = useMemo(() => {
    return orgEsticonReport?.esticonConns.map(
      (estiConn): DefaultOptionType => ({ value: estiConn.conn.id, label: estiConn.conn.name })
    );
  }, [orgEsticonReport]);

  const estiConnIdValue = useWatch('estiConnId');

  const esticonFirmsOption = useMemo(() => {
    return (
      orgEsticonReport?.esticonConns
        ?.find((conn) => conn.conn.id === estiConnIdValue)
        ?.esticonFirms?.map(
          (estiFirm): DefaultOptionType => ({ value: estiFirm.firm.id, label: estiFirm.firm.nazev })
        ) || []
    );
  }, [orgEsticonReport, estiConnIdValue]);

  const availableReportYears = useMemo(() => {
    const endYear = moment().year();
    return Array.from({ length: endYear - REPORT_FROM_START_YEAR + 1 }, (_, i) => REPORT_FROM_START_YEAR + i).map(
      (year): DefaultOptionType => ({ value: year, label: year })
    );
  }, []);

  return (
    <>
      <Form.Item
        label={<Fmt id="ProjectsInRealizationOverviewReport.reportSelection.esticonApp" />}
        name="estiConnId"
        rules={[requiredRule('ProjectsInRealizationOverviewReport.reportSelection.required.esticonApp', true)]}
        initialValue={defaultValues?.estiConnId}
      >
        <Select options={esticonConnsOptions} loading={orgEsticonReportLoading} />
      </Form.Item>
      <Form.Item
        label={<Fmt id="ProjectsInRealizationOverviewReport.reportSelection.esticonFirm" />}
        name="esticonFirmId"
        rules={[requiredRule('ProjectsInRealizationOverviewReport.reportSelection.required.unit', true)]}
        initialValue={defaultValues?.esticonFirmId}
      >
        <Select options={esticonFirmsOption} loading={orgEsticonReportLoading} />
      </Form.Item>
      <Form.Item
        label={<Fmt id="ProjectsInRealizationOverviewReport.reportSelection.reportingPeriod" />}
        name="startYear"
        rules={[requiredRule('ProjectsInRealizationOverviewReport.reportSelection.required.forYear', false)]}
        initialValue={defaultValues?.startYear}
      >
        <Select options={availableReportYears} />
      </Form.Item>
      <Form.Item
        label={<Fmt id="ProjectsInRealizationOverviewReport.reportSelection.date" />}
        name="reportDate"
        rules={[requiredRule('ProjectsInRealizationOverviewReport.reportSelection.required.date', false)]}
        initialValue={defaultValues?.reportDate && moment(defaultValues?.reportDate)}
      >
        <DatePicker />
      </Form.Item>
    </>
  );
};

export default ProjectsInRealizationConfigurationForm;
