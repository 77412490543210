import { InboxOutlined } from '@ant-design/icons';
import { Alert, Col, Collapse, Form, Input, InputRef, Row, Select, Tag, Typography, Upload } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { UploadChangeParam } from 'antd/lib/upload';
import { projectApi } from 'api/completeApi';
import { WorkflowStateEnum } from 'api/completeApiInterfaces';
import { PreferredDirectory } from 'components/DirectoriesTreeSelect/DirectoriesPreferredSelect';
import { DirectoriesTreeSelectFormItem } from 'components/DirectoriesTreeSelect/DirectoriesTreeSelect';
import FilenameErrorMessage from 'components/FilenameErrorMessage';
import { FileAddIcon } from 'components/Icons/HubActionsIcons';
import { LabelsInput } from 'components/LabelsInput/LabelsInput';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { MaskItemSimpleDisplay } from 'components/MaskInput/MaskItemSimpleDisplay';
import PrimaryFileInput from 'components/PrimaryFileInput';
import SingleFileInput from 'components/PrimaryFileInput/SingleFileInput';
import RevisionNumberTag from 'components/RevisionNumberTag';
import { RoleSelect } from 'components/RoleSelect/RoleSelect';
import {
  FILE_AND_FOLDER_NAME_REGEX,
  MAX_ITEM_DESCRIPTION_LENGTH,
  SIGNED_DOCUMENT_ACCEPTED_CONTENT_TYPES,
} from 'config/constants';
import { useApiData, useIntl } from 'hooks';
import { Fmt } from 'locale';
import { canWriteInDirectory } from 'pages/AllDocumentsPage/AllDocumentsPage';
import { FC, default as React, useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import {
  duplicateNameRuleCallback,
  justByMasksValidDocumentNameRule,
  maxLengthRule,
  requiredRule,
} from 'utils/formHelpers';
import { DocumentCategoryFormFc } from '../DocumentCategoryForm/DocumentCategoryFormFc';
import styles from './DocumentCreateForm.module.less';
import { useFolderValidationMasksContext } from './FolderValidationMasksContext';

const { Option } = Select;

export const useRequiredCategories = (directoryId: Guid | undefined) => {
  const [detail, _error, _loading, loadDetail] = useApiData((ct) =>
    projectApi.directories.primary.id.get(directoryId, ct)
  );

  useEffect(() => {
    if (directoryId) {
      loadDetail();
    }
  }, [directoryId]);

  return detail?.requiredCategoryTrees || {};
};

export type DocumentCreateFormData = {
  name: string;
  description: string;
  labels: Guid[];
  primaryFile: File;
  signedDocument?: File;
  secondaryFiles: UploadChangeParam;
  categories: { nodeId: Guid; categoryId: Guid }[];
  destinationDirectory: Guid;
  ownedById: Guid;
  state: WorkflowStateEnum;
};

type Props = {
  initialPrimaryFile?: File;
  validateUniqueName: (name: string) => boolean;
  destinationDirectory?: Guid;
  preferredDirectories: PreferredDirectory[];
  showDestinationInput?: boolean;
  allowedStates?: WorkflowStateEnum[];
  setRef: (ref: InputRef) => void;
  usefulNewDocumentStatuses: WorkflowStateEnum[];
  allowSignedDocumentAttachment: boolean;
};

const DocumentCreateForm: FC<Props> = ({
  validateUniqueName,
  initialPrimaryFile,
  destinationDirectory,
  preferredDirectories,
  showDestinationInput,
  allowedStates,
  setRef,
  usefulNewDocumentStatuses,
  allowSignedDocumentAttachment,
}) => {
  const intl = useIntl();
  const [lastFileName, setLastFileName] = useState(initialPrimaryFile?.name || '');
  const currentName = useWatch('name');
  const form = useFormInstance();

  const handleFileChange = useCallback(
    (file: File) => {
      if (file) {
        const newName = file.name;
        if (currentName === '' || currentName === lastFileName) {
          form.setFieldValue('name', newName);
        }
        setLastFileName(newName);
      }
    },
    [currentName, form, lastFileName]
  );

  const currentDirectoryId = useWatch('destinationDirectory');
  const requiredCategories = useRequiredCategories(currentDirectoryId);

  const [resultCheck, errCheck, loadingCheck, loadCheck] = useApiData(
    (ct) =>
      projectApi.uploads.checkmultiupload.post(
        { targetDirectoryId: currentDirectoryId, files: [{ name: currentName }], directories: [] },
        ct
      ),
    {
      autoload: false,
    }
  );

  useDebounce(
    () => {
      if (!currentDirectoryId || !currentName) return;
      loadCheck();
    },
    250,
    [currentDirectoryId, currentName]
  );

  const { getValidationMaskForDirectory, setDestinationDirectoryId } = useFolderValidationMasksContext();

  const documentMasks = getValidationMaskForDirectory(currentDirectoryId, 'files');

  useEffect(() => {
    setDestinationDirectoryId(currentDirectoryId);
  }, [currentDirectoryId]);

  useEffect(() => {
    form.validateFields(['name']);
  }, [currentDirectoryId]);

  return (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            name="primaryFile"
            initialValue={initialPrimaryFile}
            rules={[requiredRule('forms.items.primaryFile.rules.required')]}
          >
            <PrimaryFileInput onChange={handleFileChange} />
          </Form.Item>
          {!!documentMasks?.length && (
            <Form.Item
              label={intl.formatMessage({ id: 'DocumentCreateMultipleForm.form.items.validationMaskFiles.label' })}
            >
              <FlowLayout wrap style={{ margin: '0 0.5rem 0.5rem' }}>
                {documentMasks.map((item) => (
                  <Tag key={item.id}>
                    <MaskItemSimpleDisplay item={item} />
                  </Tag>
                ))}
              </FlowLayout>
            </Form.Item>
          )}
          <Form.Item
            label={intl.formatMessage({ id: 'DocumentCreateForm.form.items.destinationDirectory.label' })}
            name="destinationDirectory"
            initialValue={destinationDirectory}
            rules={[requiredRule('DocumentCreateForm.form.items.destinationDirectory.rules.required')]}
          >
            <DirectoriesTreeSelectFormItem
              disabled={!showDestinationInput}
              isItemDisabled={(item) => !canWriteInDirectory(item)}
              showSearch
              preferredDirectories={preferredDirectories}
              placeholder={intl.formatMessage({
                id: 'DocumentCreateForm.form.items.destinationDirectory.placeholder',
              })}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ id: 'forms.items.name.label' })}
            name="name"
            initialValue={initialPrimaryFile ? initialPrimaryFile.name : ''}
            rules={[
              requiredRule('DocumentCreateForm.form.items.name.rules.required', true),
              maxLengthRule('general.maxNameLength'),
              {
                pattern: FILE_AND_FOLDER_NAME_REGEX,
                message: <FilenameErrorMessage />,
              },
              duplicateNameRuleCallback(validateUniqueName, 'DocumentCreateForm.form.items.name.rules.nameExists'),
              justByMasksValidDocumentNameRule(
                'DocumentCreateForm.form.items.name.rules.noValidByMask',
                documentMasks || []
              ),
            ]}
          >
            <Input
              placeholder={intl.formatMessage({ id: 'DocumentCreateForm.form.items.name.placeholder' })}
              autoFocus
              ref={setRef}
            />
          </Form.Item>
          {!!resultCheck?.duplicateDocuments.length && (
            <Alert
              message={intl.formatMessage({ id: 'DocumentCreateForm.form.items.name.rules.nameDuplicity' })}
              type="warning"
              showIcon
            />
          )}
          <Form.Item
            label={intl.formatMessage({ id: 'forms.items.description.label' })}
            name="description"
            rules={[
              {
                whitespace: true,
                message: intl.formatMessage({ id: 'forms.items.name.rules.empty' }),
              },
              {
                max: MAX_ITEM_DESCRIPTION_LENGTH,
                message: intl.formatMessage(
                  { id: 'general.maxDescriptionLength' },
                  { max: MAX_ITEM_DESCRIPTION_LENGTH }
                ),
              },
            ]}
          >
            <Input.TextArea rows={2} autoSize={{ minRows: 2 }} />
          </Form.Item>
          <Collapse>
            {allowSignedDocumentAttachment && (
              <Collapse.Panel
                header={
                  <>
                    <FileAddIcon className={styles.secondaryFileIcon} />
                    <span>{intl.formatMessage({ id: 'SignedDocumentInput.collapse.header' })}</span>
                  </>
                }
                key="signedDocument"
              >
                <Form.Item name="signedDocument" noStyle>
                  <SingleFileInput
                    inputText="SignedDocumentInput.collapse.text"
                    inputHint="SignedDocumentInput.collapse.hint"
                    acceptedContentType={SIGNED_DOCUMENT_ACCEPTED_CONTENT_TYPES}
                  />
                </Form.Item>
              </Collapse.Panel>
            )}
            <Collapse.Panel
              header={
                <>
                  <FileAddIcon className={styles.secondaryFileIcon} />
                  <span>{intl.formatMessage({ id: 'SecondaryFilesInput.collapse.header' })}</span>
                </>
              }
              key="attachments"
            >
              <Form.Item name="secondaryFiles" noStyle>
                <Upload.Dragger multiple={true} beforeUpload={() => false} customRequest={() => {}}>
                  <p className="ant-upload-text">{intl.formatMessage({ id: 'SecondaryFilesInput.collapse.text' })}</p>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Collapse.Panel>
          </Collapse>
        </Col>
        <Col span={12}>
          <Form.Item
            label={intl.formatMessage({ id: 'DocumentCreateForm.form.items.state.label' })}
            name="state"
            initialValue={allowedStates?.length === 1 ? allowedStates[0] : null}
            rules={[requiredRule('DocumentCreateForm.form.items.state.rules.required')]}
          >
            <Select>
              {(allowedStates || usefulNewDocumentStatuses).map((state) => (
                <Option key={state}>
                  <div className={styles.stateOption}>
                    <RevisionNumberTag state={state} showTitle />
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label={<Fmt id="general.manager" />} name="ownedById">
            <RoleSelect allowClear />
          </Form.Item>

          <Form.Item label={intl.formatMessage({ id: 'forms.items.labels.label' })} name="labels" initialValue={[]}>
            <LabelsInput />
          </Form.Item>

          <Typography.Title level={4}>
            <Fmt id="DocumentCreateForm.section.categories" />
          </Typography.Title>

          <DocumentCategoryFormFc requiredCategories={requiredCategories} autoSelectDefault={true} />
        </Col>
      </Row>
    </>
  );
};

export default DocumentCreateForm;
