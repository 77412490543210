import { AssignAttachmentListData } from 'components/DiscussionAttachments/AttachmentHeader/AttachmentHeader';
import { useSameCallback } from 'hooks/useSameCallback';
import { InjectedIntlProps } from 'locale/Fmt';
import React, { FunctionComponent, ReactNode } from 'react';
import { DiscussionRelationOption } from '../DiscussionAttachmentCreateForm/DiscussionAttachmentCreateForm';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper } from '../FormModalWrapper';
import DiscussionAssignAttachmentForm, { DiscussionAssignAttachmentFormData } from './DiscussionAssignAttachmentForm';

type Props = FormModalProps<AssignAttachmentListData> &
  InjectedIntlProps & { discussionRelationOptions?: DiscussionRelationOption<string | number>[]; title: ReactNode };

export const DiscussionAssignAttachmentFormModal: FunctionComponent<Props> = ({
  onSubmit,
  onClose,
  intl,
  title,
  open,
  ...formConfig
}) => {
  const handleSubmit = useSameCallback(async (values: DiscussionAssignAttachmentFormData) => {
    if (!values) {
      return null;
    }

    const assignAttachmentListData: AssignAttachmentListData = {
      assignedDocuments: values.selectedDocuments.map((document) => ({
        documentId: document.id,
        revisionId: document.currentRevision.id,
        name: document.name,
      })),
      discussionRelation: values.discussionRelation,
    };
    onSubmit && (await onSubmit(assignAttachmentListData));

    return null;
  });

  return (
    <FormModalWrapper onSubmit={handleSubmit} onClose={onClose} title={title} open={open} width={800}>
      <DiscussionAssignAttachmentForm {...formConfig} />
    </FormModalWrapper>
  );
};
