import { CheckOutlined, CloseOutlined, InboxOutlined, ShareAltOutlined, ToolOutlined } from '@ant-design/icons';
import { BadgeProps } from 'antd/lib/badge';
import { WorkflowStateEnum } from 'api/completeApiInterfaces';
import classNames from 'classnames';
import { InjectedIntl, InjectedIntlProps } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import { TooltipProps } from 'rc-tooltip/lib/Tooltip';
import React, { FunctionComponent, ReactNode } from 'react';
import { injectIntl } from 'react-intl';
import styles from './RevisionNumberTag.module.less';
import { StateIcon } from './StateIcon';

type Props = Omit<BadgeProps, 'count'> &
  InjectedIntlProps & {
    no?: number;
    date?: string;
    state?: WorkflowStateEnum;
    showTitle?: boolean;
    trigger?: TooltipProps['trigger'];
  };

export type RevisionNumberTagOption = {
  icon: ReactNode;
  className: keyof typeof styles;
  title: IntlMessageId;
};

export const iconsAndColorsMap: Record<WorkflowStateEnum, RevisionNumberTagOption> = {
  [WorkflowStateEnum.workinprogress]: {
    icon: <ToolOutlined />,
    className: 'workInProgress',
    title: 'general.state.workInProgress',
  },
  [WorkflowStateEnum.shared]: {
    icon: <ShareAltOutlined />,
    className: 'shared',
    title: 'general.state.shared',
  },
  [WorkflowStateEnum.rejected]: {
    icon: <CloseOutlined />,
    className: 'rejected',
    title: 'general.state.rejected',
  },
  [WorkflowStateEnum.published]: {
    icon: <CheckOutlined />,
    className: 'published',
    title: 'general.state.published',
  },
  [WorkflowStateEnum.archived]: {
    icon: <InboxOutlined />,
    className: 'archived',
    title: 'general.state.archived',
  },
};

const getRevisionTooltip = (intl: InjectedIntl, no?: number, title?: string, showTitle?: boolean): string => {
  if (no == null && showTitle) return undefined;
  const noText = no != undefined ? intl.formatMessage({ id: 'RevisionNumberTag.title' }, { number: no }) : '';
  const titleText = title ? `(${title})` : '';
  return [noText, titleText].filter(Boolean).join(' ');
};

const RevisionNumberTag: FunctionComponent<Props> = ({
  intl,
  no,
  style,
  state,
  className,
  showTitle,
  trigger,
  ...restProps
}) => {
  const stateData = iconsAndColorsMap[state];
  if (no == null && !stateData) return null;

  const title = stateData && intl.formatMessage({ id: stateData.title });
  const tooltip = getRevisionTooltip(intl, no, title, showTitle);

  const classNameAll = classNames(styles.badge, styles[stateData?.className], className);

  return (
    <StateIcon title={tooltip} className={classNameAll} trigger={trigger}>
      {stateData !== undefined ? (
        <span {...restProps}>
          {!!no && <span className={styles.number}>{`${no}. `}</span>}
          <span className={styles.icon}>{stateData.icon}</span>
          {showTitle && title && <span className={styles.stateTitle}>{title}</span>}
        </span>
      ) : (
        <span style={style} {...restProps}>
          {!!no && <span className={styles.number}>{`${no}. `}</span>}
        </span>
      )}
    </StateIcon>
  );
};

export default injectIntl(RevisionNumberTag);
