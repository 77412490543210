import { Form, TreeSelect } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';

import {
  BuildingIdentificationArealDto,
  BuildingIdentificationRegionDto,
  JSONVariableTypeEnum,
} from 'api/completeApiInterfaces';
import { useIntl, useSelectorDispatch } from 'hooks';
import { Fmt } from 'locale';
import { Dictionary } from 'lodash';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { buildingIdentificationsSortedSelector } from 'store/selectors/buildingIdentificationsSelector';
import { BuildingIdentificationDetail, JSONVariableBuildingIdentification } from '../JSONVariableTypes';

export type JSONVariableBuildingIdentificationProps = {
  dataType: JSONVariableTypeEnum.buildingIdentification;
  value?: JSONVariableBuildingIdentification;
  onChange?: (value: JSONVariableBuildingIdentification) => void;
  disabled?: boolean;
};

const getFlatDataRecord = (entry: BuildingIdentificationRegionDto[]): Record<string, BuildingIdentificationDetail> => {
  if (!entry?.length) return {};
  const dataRecord: Record<string, BuildingIdentificationDetail> = {};
  entry.forEach((region) => {
    const regionName = region.name;
    Object.values(region.areals).forEach((areal) => {
      const arealName = areal.name;
      Object.values(areal.properties).forEach((property) => {
        dataRecord[property.code] = {
          code: property.code,
          region: regionName,
          areal: arealName,
          property: property.name,
        };
      });
    });
  });
  return dataRecord;
};

const getArealsValues = (
  isDisable: boolean,
  areals?: Dictionary<BuildingIdentificationArealDto>
): Omit<DefaultOptionType, 'label'>[] => {
  if (!areals) return [];
  return Object.values(areals).map((areal) => ({
    title: `${areal.name} (${areal.code})`,
    value: areal.code,
    disabled: isDisable,
    children: getArealsValues(false, areal.properties),
  }));
};

const getTreeData = (entry: BuildingIdentificationRegionDto[]): Omit<DefaultOptionType, 'label'>[] => {
  const items: Omit<DefaultOptionType, 'label'>[] = entry.map((region) => ({
    title: `${region.name} (${region.code})`,
    value: region.code,
    disabled: true,
    children: getArealsValues(true, region.areals),
  }));
  return items;
};

const JSONVariableBuildingIdentificationFormItem: FunctionComponent<JSONVariableBuildingIdentificationProps> = ({
  value,
  onChange,
  disabled,
  dataType,
}) => {
  const intl = useIntl();
  const buildingIdentifications = useSelectorDispatch(buildingIdentificationsSortedSelector, (dispatch) =>
    dispatch.buildingIdentifications.loadData({ reload: false })
  );

  const flatRecordIdentifications = useMemo(() => {
    return !!buildingIdentifications && getFlatDataRecord(buildingIdentifications);
  }, [buildingIdentifications]);

  const treeData = useMemo(() => {
    if (!buildingIdentifications?.length) return undefined;

    return getTreeData(buildingIdentifications);
  }, [buildingIdentifications]);

  const handleFormValueChange = useCallback(
    (formValues?: string[]) => {
      if (!formValues?.length) {
        onChange && onChange({ type: dataType, value: [] });
        return;
      }
      const value = formValues.map((formValue) => ({ ...flatRecordIdentifications[formValue] }));
      onChange && onChange({ type: dataType, value });
    },
    [flatRecordIdentifications, onChange, dataType]
  );

  return (
    <Form.Item
      label={<Fmt id="JSONVariableType.buildingIdentification" />}
      rules={[{ required: true, message: intl.formatMessage({ id: 'forms.items.rules.required' }) }]}
    >
      <TreeSelect
        showSearch
        allowClear
        treeData={treeData}
        disabled={disabled}
        value={value?.value?.map((value) => value.code)}
        onChange={handleFormValueChange}
        treeDefaultExpandAll
        treeNodeFilterProp="title"
        multiple
      />
    </Form.Item>
  );
};

export default React.memo(JSONVariableBuildingIdentificationFormItem);
