import { FileOutlined, FolderOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Alert, Collapse } from 'antd';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { FileUploadError } from 'components/PrimaryFileInput/CommonFilesInputTypes';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React from 'react';

export type Props = {
  droppedFilesErrors: FileUploadError[];
  treeLoadingErrorMessageId?: IntlMessageId;
  treeLoadingErrorHelpMessageId?: IntlMessageId;
  treeLoadingErrorListTitleMessageId?: IntlMessageId;
};

const DroppedFilesErrorAlert = ({
  droppedFilesErrors,
  treeLoadingErrorMessageId,
  treeLoadingErrorHelpMessageId,
  treeLoadingErrorListTitleMessageId,
}: Props) => {
  return (
    <Alert
      message={
        <>
          <p>
            <strong>
              <FolderOutlined /> <Fmt id={treeLoadingErrorMessageId || 'CommonFilesInput.treeLoading.error'} />
            </strong>
          </p>
          <p>
            <InfoCircleOutlined />{' '}
            <Fmt id={treeLoadingErrorHelpMessageId || 'CommonFilesInput.treeLoading.error.help'} />
          </p>
          <Collapse bordered={false}>
            <Collapse.Panel
              header={
                <>
                  <Fmt id={treeLoadingErrorListTitleMessageId || 'CommonFilesInput.treeLoading.error.listTitle'} />
                </>
              }
              key="allErrors"
            >
              <ul>
                {droppedFilesErrors.map((error) => (
                  <li key={error.fullPath}>
                    <CommonHubTooltip title={error.fullPath}>
                      {error.type === 'file' ? <FileOutlined /> : <FolderOutlined />} {error.name}
                    </CommonHubTooltip>
                  </li>
                ))}
              </ul>
            </Collapse.Panel>
          </Collapse>
        </>
      }
      type="error"
      banner
    />
  );
};

export default DroppedFilesErrorAlert;
