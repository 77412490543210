import { StarFilled, StarOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent, useCallback } from 'react';

type Props = {
  selected: boolean;
  onChange: (selected: boolean) => void;
  disabled?: boolean;
};

export const FavoriteFilterButton: FunctionComponent<Props> = ({ selected, disabled, onChange }) => {
  const favoriteDescId: IntlMessageId = selected ? 'FavoriteSwitch.showSelected' : 'FavoriteSwitch.hideSelected';

  const onChangeHandler = useCallback(() => {
    !!onChange && onChange(!selected);
  }, [onChange, selected]);

  return (
    <CommonHubTooltip placement="topRight" title={<Fmt id={favoriteDescId} />}>
      <Button type="default" size="middle" onClick={onChangeHandler} disabled={disabled}>
        {selected ? <StarFilled /> : <StarOutlined />}
      </Button>
    </CommonHubTooltip>
  );
};
