import { UserAddOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormListFieldData, FormListOperation, Input, InputRef, Row, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { DeleteButton } from 'components/ActionButtons';
import MultipleInputModal from 'components/MultipleInputModal';
import ServiceErrorBox from 'components/ServiceErrorBox';
import { useBoolean, useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FC, ReactNode, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch, RootState } from 'store';
import { legacyMapDispatchToProps } from 'store/models/storeModelinterfaces';
import { groupOrderedListSelector } from 'store/selectors/groupSelectors';
import { includesDiacriticsRule, requiredRule, simpleSelectFilter } from 'utils/formHelpers';
import uuid from 'uuid';

export type ProjectUserInviteFormData = {
  emails: { email: string; id: Guid }[];
  message: string;
  groups: Guid[];
};

type MailData = {
  email: string;
  id: Guid;
};

const mapStateToProps = (state: RootState) => ({
  currentUser: state.currentProjectUser.data,
  groupsList: groupOrderedListSelector(state),
  groupsMapError: state.groups.error,
  groupsLoading: state.groups.loading,
});

type PropsFromState = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadGroups: dispatch.groups.loadData,
});

type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

type Props = PropsFromState &
  PropsFromDispatch & {
    sentUsers: string[];
    initialUsers?: string[];
    ignoreGroups?: boolean;
    setRef: (ref: InputRef) => void;
  };

const ProjectUserInviteForm: FC<Props> = ({
  sentUsers,
  setRef,
  ignoreGroups,
  initialUsers,
  loadGroups,
  groupsList,
  currentUser,
  groupsMapError,
  groupsLoading,
}) => {
  const intl = useIntl();
  const form = useFormInstance();
  const [isMultiMail, setIsMultiMail, clearIsMultiMail] = useBoolean(false);

  const emails = useWatch('emails');

  useEffect(() => {
    if (!!initialUsers?.length) {
      form.setFieldsValue(initialUsers.map((userName) => ({ id: uuid.v4(), email: userName })));
    } else {
      if (!ignoreGroups) {
        loadGroups({ reload: false });
      }
    }
  }, []);

  useEffect(() => {
    const values = form.getFieldsValue();
    form.setFieldsValue({
      ...values,
      emails: values.emails.filter((e: MailData) => !sentUsers?.find((u) => u == e.email)),
    });
  }, [sentUsers]);

  const handleMultiMailAdd = () => {
    setIsMultiMail();
  };

  const handleMultiEmailCancel = () => {
    clearIsMultiMail();
  };

  const handleEmailsAdd = (values: string[]) => {
    const valuesEmails = values.reduce<MailData[]>((acc, email) => {
      return [...acc, { id: uuid.v4(), email: email }];
    }, []);

    form.setFieldsValue({ emails: [...emails, ...valuesEmails] });
    clearIsMultiMail();
  };

  const itemsConverter = (value: string): string[] => {
    const matches: RegExpMatchArray = value.match(
      /([\w\-!#$%&'*+/=?^_`{|}~](\.?[\w\-!#$%&'*+/=?^_`{|}~])*@([\w\-!#$%&'*+/=?^_`{|}~]+\.)+[^\d\W]{2,})/gi
    );
    if (!matches || !matches.length) return [];
    const uniqueEmails: Set<string> = new Set(
      matches.filter((match) => !!match).map((match) => match.toString().toLowerCase())
    );
    return Array.from(uniqueEmails);
  };

  const emailDuplicateValidator = (value: MailData): Promise<void | any> | void => {
    const tValue: string = value.email.toLowerCase().trim();

    const result = emails.some((e: MailData) => {
      return e.email === tValue && e.id !== value.id;
    });

    if (result) {
      return Promise.reject(intl.formatMessage({ id: 'forms.items.rules.duplicateEmail' }));
    }
    return Promise.resolve();
  };

  return (
    <>
      <Form.List name={'emails'} initialValue={[{ id: uuid.v4(), email: '' }] as MailData[]}>
        {(
          fields: FormListFieldData[],
          { add, remove }: FormListOperation,
          meta: { errors: ReactNode[]; warnings: ReactNode[] }
        ) => (
          <>
            {fields.map(({ key, name, ...restField }, i, data) => {
              return (
                <Form.Item
                  name={name}
                  key={key}
                  label={i > 0 ? undefined : intl.formatMessage({ id: 'ProjectUserInviteFormData.form.emails' })}
                  style={{ marginBottom: '4px' }}
                  rules={[{ validator: (_, value) => emailDuplicateValidator(value) }]}
                >
                  <Form.Item name={[name, 'id']} hidden noStyle />
                  <Row gutter={24}>
                    <Col span={fields.length > 1 ? 22 : 24}>
                      <Form.Item
                        {...restField}
                        noStyle
                        name={[name, 'email']}
                        validateFirst
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          includesDiacriticsRule('forms.items.rules.email.noDiacritics'),
                          requiredRule('forms.items.rules.required'),
                          { type: 'email', message: intl.formatMessage({ id: 'forms.items.rules.email' }) },
                        ]}
                      >
                        <Input
                          placeholder={intl.formatMessage({ id: 'forms.items.email.placeholder' })}
                          autoFocus
                          ref={setRef}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2}>{fields.length > 1 && <DeleteButton onClick={() => remove(name)} />}</Col>
                  </Row>
                </Form.Item>
              );
            })}
            <Form.Item>
              <Row gutter={24}>
                <Col span={12}>
                  <Button onClick={handleMultiMailAdd} style={{ width: '100%' }}>
                    <UsergroupAddOutlined /> <Fmt id="ProjectUserInviteFormData.form.addMultiMail" />
                  </Button>
                </Col>
                {!isMultiMail && (
                  <Col span={12}>
                    <Button onClick={() => add({ id: uuid.v4(), email: '' })} style={{ width: '100%' }}>
                      <UserAddOutlined /> <Fmt id="ProjectUserInviteFormData.form.addEmail" />
                    </Button>
                  </Col>
                )}
              </Row>
            </Form.Item>
          </>
        )}
      </Form.List>
      {!initialUsers && (
        <Form.Item
          label={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.items.message.label' })}
          name="message"
          initialValue={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.items.message.initialValue' })}
        >
          <Input.TextArea rows={3} autoSize={{ minRows: 3 }} />
        </Form.Item>
      )}
      {!ignoreGroups && (
        <Form.Item
          label={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.items.groups.label' })}
          name="groups"
          style={{ marginBottom: '1px' }}
        >
          <Select
            loading={groupsLoading}
            mode="multiple"
            style={{ width: '100%' }}
            placeholder={intl.formatMessage({ id: 'general.groups' })}
            filterOption={simpleSelectFilter}
            options={groupsList
              .filter((group) => currentUser?.isAdmin || !group.isAdminGroup)
              .map((group) => ({ label: group.name, value: group.id }))}
          />
          {groupsMapError && <ServiceErrorBox error={groupsMapError} />}
        </Form.Item>
      )}
      <MultipleInputModal
        visible={isMultiMail}
        onSubmit={handleEmailsAdd}
        onClose={handleMultiEmailCancel}
        title={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.multipleInput.label' })}
        itemsFinder={itemsConverter}
        submitText={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.multipleInput.addEmails' })}
        cancelText={intl.formatMessage({ id: 'forms.button.cancel' })}
        placeholder={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.multipleInput.placeholder' })}
        errorText={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.multipleInput.error' })}
        inputText={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.multipleInput.inputText' })}
        resultText={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.multipleInput.resultText' })}
      />
    </>
  );
};

export default connect(mapStateToProps, legacyMapDispatchToProps(mapDispatchToProps))(ProjectUserInviteForm);
