import DocumentsGridHeader from 'components/DocumentsGridHeader/DocumentsGridHeader';
import { useFiltersContext } from 'components/filters/FiltersContextProvider';
import { FilterToolbar } from 'components/filters/render/FilterToolbar/FilterToolbar';
import { useActiveProject } from 'hooks';
import React, { FunctionComponent } from 'react';
import { FilterPreset } from '../FilterPreset/FilterPreset';

const FilterToolbarStyledComponent: FunctionComponent = ({}) => {
  const {
    hasFilteredOutItems,
    clearFilters,
    filters,
    setFilterValue,
    itemCounts,
    isFilterActive,
    persistentKey,
  } = useFiltersContext();

  const activeProject = useActiveProject();
  if (!isFilterActive) return null;

  return (
    <DocumentsGridHeader
      filters={
        <FilterToolbar
          filters={filters}
          setFilterValue={setFilterValue}
          clearFilters={clearFilters}
          hasFilteredOutItems={hasFilteredOutItems}
          itemCounts={itemCounts}
        />
      }
      additionalContent={
        !!activeProject && <FilterPreset filterKey={persistentKey} filters={filters} setFilterValue={setFilterValue} />
      }
    ></DocumentsGridHeader>
  );
};

export const FilterToolbarStyled = React.memo(FilterToolbarStyledComponent);
