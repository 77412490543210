import { EditOutlined } from '@ant-design/icons';
import { ProjectCardDataDto } from 'api/completeApiInterfaces';
import { useActiveProject, useIntl } from 'hooks';
import { mapDataToTreeData } from 'pages/ProjectSettingsPage/ProjectSettingsPageGeneral/ProjectSettingsProjectCardDataTree';
import { useEditProjectCardData } from 'pages/ProjectSettingsPage/ProjectSettingsPageGeneral/useEditProjectCardData';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { filterTreeByName, findInTreeByFunc } from 'utils/tree/treeHelpers';

export const useProjectAdditionalData = (projectCardData: ProjectCardDataDto[], nodeKey?: string) => {
  const intl = useIntl();
  const history = useHistory();
  const projectId = useActiveProject()?.id;
  const canEditProjectCardData = useEditProjectCardData();

  const treeData = useMemo(() => {
    if (!projectCardData) return [];
    const tree = mapDataToTreeData(projectCardData, intl);
    const node = nodeKey ? findInTreeByFunc(tree, (node) => node.name == nodeKey) : null;
    if (!node) return tree || [];
    return node.children || [];
  }, [projectCardData, intl, nodeKey]);

  const [search, setSearch] = useState<string>('');

  const filteredTreeData = useMemo(() => {
    return filterTreeByName(treeData, search);
  }, [treeData, search]);

  const editCardDataLink = useMemo(() => {
    return {
      key: 'editProject',
      icon: <EditOutlined />,
      label: intl.formatMessage({ id: 'ProjectDashboard.ProjectAdditionalData.editButtonLabel' }),
      onClick: () => {
        history.push(`/projects/${projectId}/settings/general/projectCardData`);
      },
    };
  }, [projectId, history, intl]);

  return [filteredTreeData, search, setSearch, editCardDataLink, canEditProjectCardData] as const;
};
