import {
  FormProcessActorTypeEnum,
  FormProcessMetadataTypeEnum,
  FormProcessStartDurationrTypeEnum,
  FormProcessTaskTemplateDto,
  FormProcessVoteTypeEnum,
  JSONVariableTypeEnum,
} from 'api/completeApiInterfaces';
import moment from 'moment';
import { FormProcessDisplayData, FormProcessLayout, FormProcessMetadataType } from './FormProcessTemplate.types';

// TODO: Move initial actor names into template and builder
export const TEMP_SZ_ACTOR_ROLE_FORM_GUARANTOR = 'vrcholový koordinátor';
export const TEMP_SZ_ACTOR_ROLE_FORM_EXPERT_GUARANTOR = 'odborný garant';
export const TEMP_SZ_ACTOR_ROLE_HIS = 'his';
export const TEMP_SZ_ACTOR_ROLE_PROCESSOR = 'zpracovatel';

const taskLayout: FormProcessLayout = {
  type: 'grid',
  version: 1,
  rows: [
    [{ type: 'static', value: 'Identifikace připomínkovaného dokumentu', span: 24, style: { fontWeight: 'bold' } }],
    [{ type: 'metadata', metadataId: '1fdaf5bb-aa7c-44b0-9c4c-039697e1a5db', nameSpan: 6, valueSpan: 18 }],
    [{ type: 'metadata', metadataId: '27a80ae0-a6cb-4a54-a1a0-cd14c77bf006', nameSpan: 6, valueSpan: 18 }],
    [{ type: 'metadata', metadataId: '3f1ea4c1-1316-4bd6-97aa-9156844e8b9b', nameSpan: 6, valueSpan: 18 }],
    [{ type: 'metadata', metadataId: '48f4a653-62d7-4a11-bfbd-22762ef15b99', nameSpan: 6, valueSpan: 18 }],
    [{ type: 'metadata', metadataId: '587e9750-8f7a-4576-9bc6-0aef12752061', nameSpan: 6, valueSpan: 18 }],
    [{ type: 'metadata', metadataId: '6f4ddf12-2ca9-4456-afe4-223cdcd7270f', nameSpan: 6, valueSpan: 18 }],
    [{ type: 'metadata', metadataId: '7dc48e1b-244c-4bbb-81d1-1df2558cd38f', nameSpan: 6, valueSpan: 18 }],
    [{ type: 'metadata', metadataId: '8daea2e8-2c16-4c07-8e37-620d9f619a0d', nameSpan: 6, valueSpan: 18 }],
    [{ type: 'metadata', metadataId: '993e8648-3bd1-4db3-b4c1-3b7e77498bc2', nameSpan: 6, valueSpan: 18 }],
  ],
};

const firstPhaseLayout: FormProcessLayout = {
  type: 'grid',
  version: 1,
  rows: [
    [
      { type: 'static', value: 'Připomínka:', span: 16, style: { fontWeight: 'bold' } },
      { type: 'metadata', metadataId: '8b536579-5963-4d70-b2d7-7003c9bd6364', nameSpan: 4, valueSpan: 4 },
    ],
    [{ type: 'metadata', metadataId: '663be2d7-9b79-4687-8a1a-9797035f58f1', nameSpan: 6, valueSpan: 18 }],
    [{ type: 'metadata', metadataId: '4e480bf6-0436-457b-8f67-5f19263a4ded', nameSpan: 6, valueSpan: 18 }],
    [
      { type: 'metadata', metadataId: '8d29ba15-c45f-4190-ae8c-c2534c3035a4', nameSpan: 6, valueSpan: 5 },
      { type: 'metadata', metadataId: '531ede09-a768-43e9-8c08-e657faefe7c7', nameSpan: 5, valueSpan: 8 },
    ],
    [
      { type: 'static', value: 'Odpovědnostní role SŽ:', span: 6, style: { fontWeight: 'bold' } },
      { type: 'static', value: 'Jméno ', span: 5, style: { fontWeight: 'bold' } },
      { type: 'static', value: 'Organizační jednotka', span: 9, style: { fontWeight: 'bold' } },
      { type: 'static', value: 'Stanovisko: ', span: 4, style: { fontWeight: 'bold' } },
    ],
    [
      {
        type: 'actor',
        actorId: '4ada5a94-4df6-42f3-ae32-16bf88e45d4c',
        nameSpan: 6,
        userSpan: 5,
        organizationSignSpan: 5,
        organizationStructureSpan: 4,
        voteSpan: 4,
      },
    ],
    [
      {
        type: 'actor',
        actorId: 'f2c53805-ea45-49da-80be-e6156d2f06c7',
        nameSpan: 6,
        userSpan: 5,
        organizationSignSpan: 5,
        organizationStructureSpan: 4,
        voteSpan: 4,
      },
    ],
    [
      {
        type: 'actor',
        actorId: '2b6201fd-b926-4d6f-a2b5-cb3e405529e9',
        nameSpan: 6,
        userSpan: 5,
        organizationSignSpan: 5,
        organizationStructureSpan: 4,
        voteSpan: 4,
      },
    ],
    [
      {
        type: 'actor',
        actorId: '8e8315e3-30ed-40b7-96ac-46c637de89bb',
        nameSpan: 6,
        userSpan: 5,
        organizationSignSpan: 5,
        organizationStructureSpan: 4,
        voteSpan: 4,
      },
    ],
    [{ type: 'metadata', metadataId: '197460e0-1bab-4537-9296-1aa04177929a', nameSpan: 20, valueSpan: 4 }],
  ],
};

const secondPhaseLayout: FormProcessLayout = {
  type: 'grid',
  version: 1,
  rows: [
    [
      { type: 'static', value: 'Stanovisko zpracovatele: ', span: 16, style: { fontWeight: 'bold' } },
      { type: 'metadata', metadataId: '4c7ddb42-48b0-4d76-b410-416b0c93830c', nameSpan: 4, valueSpan: 4 },
    ],
    [{ type: 'metadata', metadataId: '783e73f3-6f90-4bd7-93c7-469f3b78325f', nameSpan: 6, valueSpan: 18 }],
    [
      { type: 'metadata', metadataId: 'dd2ac827-0352-4c43-93f7-d66c4c3ba0d3', nameSpan: 6, valueSpan: 5 },
      {
        type: 'actor',
        actorId: '62808b17-3d86-40ae-bc84-945b5e615f88',
        nameSpan: 5,
        organizationSignSpan: 0,
        organizationStructureSpan: 0,
        userSpan: 0,
        voteSpan: 4,
      },
    ],
  ],
};

const thirdPhaseLayout: FormProcessLayout = {
  type: 'grid',
  version: 1,
  rows: [
    [
      { type: 'static', value: 'Stanovisko SŽ k zapracování připomínky: ', span: 16, style: { fontWeight: 'bold' } },
      { type: 'metadata', metadataId: '8b536579-5963-4d70-b2d7-7003c9bd6364', nameSpan: 4, valueSpan: 4 },
    ],
    [
      {
        type: 'actor',
        actorId: 'ce8b3680-f53d-4333-89fd-e342a4a05152',
        nameSpan: 6,
        userSpan: 5,
        organizationSignSpan: 5,
        organizationStructureSpan: 4,
        voteSpan: 4,
      },
    ],
    [
      {
        type: 'actor',
        actorId: '8fe7ab35-1db3-4895-ae24-bb600e113929',
        nameSpan: 6,
        userSpan: 5,
        organizationSignSpan: 5,
        organizationStructureSpan: 4,
        voteSpan: 4,
      },
    ],
    [
      {
        type: 'actor',
        actorId: 'b5dd6c24-7910-43fd-a5c1-d697af605307',
        nameSpan: 6,
        userSpan: 5,
        organizationSignSpan: 5,
        organizationStructureSpan: 4,
        voteSpan: 4,
      },
    ],
  ],
};

export const SZ_FORM_TEMPLATE: FormProcessTaskTemplateDto = {
  id: '00000000-0000-0000-0000-000000000001',
  actionData: {},
  displayData: { layout: taskLayout } as FormProcessDisplayData,
  phases: [
    {
      id: '00000000-0000-0000-0000-000000000001',
      duration: 30,
      startDuration: 0,
      startDurationrType: FormProcessStartDurationrTypeEnum.fromStartProcess,
      displayData: {
        layout: firstPhaseLayout,
        visibleForRoles: [
          { role: FormProcessActorTypeEnum.submitter },
          { role: FormProcessActorTypeEnum.guarantor },
          { role: FormProcessActorTypeEnum.expertGuarantor },
          { role: FormProcessActorTypeEnum.his },
          { role: FormProcessActorTypeEnum.processor, visibleInPhases: [2, 3] },
        ],
      } as FormProcessDisplayData,
      metadata: [
        {
          id: '663be2d7-9b79-4687-8a1a-9797035f58f1',
          displayData: { responsibleRoles: [FormProcessActorTypeEnum.submitter] } as FormProcessDisplayData,
          source: { type: 'userInput', format: JSONVariableTypeEnum.string } as FormProcessMetadataType,
          name: 'Název připomínky',
          createdDate: moment().toISOString(),
          metadataType: FormProcessMetadataTypeEnum.variable,
          permittedPhasses: [1],
        },
        {
          id: '4e480bf6-0436-457b-8f67-5f19263a4ded',
          displayData: {
            input: 'textarea',
            responsibleRoles: [FormProcessActorTypeEnum.submitter],
          } as FormProcessDisplayData,
          source: { type: 'userInput', format: JSONVariableTypeEnum.string } as FormProcessMetadataType,
          name: 'Připomínka',
          createdDate: moment().toISOString(),
          metadataType: FormProcessMetadataTypeEnum.variable,
          permittedPhasses: [1],
        },
        {
          id: '8d29ba15-c45f-4190-ae8c-c2534c3035a4',
          displayData: { responsibleRoles: [FormProcessActorTypeEnum.submitter] } as FormProcessDisplayData,
          source: {
            type: 'userInput',
            format: JSONVariableTypeEnum.enum,
            allowedValues: [
              {
                label: 'Formální',
                value: 'formal',
              },
              {
                label: 'Koncepční',
                value: 'concept',
              },
              {
                label: 'Technická',
                value: 'technical',
              },
              {
                label: 'Kritická',
                value: 'critical',
              },
            ],
          } as FormProcessMetadataType,
          name: 'Kategorie připomínky',
          createdDate: moment().toISOString(),
          metadataType: FormProcessMetadataTypeEnum.variable,
          permittedPhasses: [1],
        },
        {
          id: '531ede09-a768-43e9-8c08-e657faefe7c7',
          displayData: { responsibleRoles: [FormProcessActorTypeEnum.submitter] } as FormProcessDisplayData,
          source: {
            type: 'userInput',
            format: JSONVariableTypeEnum.enum,
            allowedValues: [
              {
                label: 'Zpracováno',
                value: 'processed',
              },
              {
                label: 'Nezpracováno',
                value: 'notProcessed',
              },
              {
                label: 'Zamítnuto',
                value: 'rejected',
              },
              {
                label: 'Schváleno',
                value: 'approved',
              },
            ],
          } as FormProcessMetadataType,
          name: 'Stav připomínky',
          createdDate: moment().toISOString(),
          metadataType: FormProcessMetadataTypeEnum.variable,
          permittedPhasses: [1],
        },
        {
          id: '197460e0-1bab-4537-9296-1aa04177929a',
          displayData: {
            responsibleRoles: [
              FormProcessActorTypeEnum.submitter,
              FormProcessActorTypeEnum.guarantor,
              FormProcessActorTypeEnum.expertGuarantor,
              FormProcessActorTypeEnum.his,
              FormProcessActorTypeEnum.processor,
            ],
          } as FormProcessDisplayData,
          source: {
            type: 'userInput',
            format: JSONVariableTypeEnum.boolean,
          } as FormProcessMetadataType,
          name: 'Požadavek na interní konferenční projednání připomínky',
          createdDate: moment().toISOString(),
          metadataType: FormProcessMetadataTypeEnum.variable,
          permittedPhasses: [1],
        },
        {
          id: '8b536579-5963-4d70-b2d7-7003c9bd6364',
          displayData: {} as FormProcessDisplayData,
          source: {
            type: 'sourced',
            source: 'processFormMetadata',
            sourceKey: 'lastChangeDate',
          } as FormProcessMetadataType,
          name: 'Datum vydání',
          createdDate: moment().toISOString(),
          metadataType: FormProcessMetadataTypeEnum.variable,
          permittedPhasses: [1],
        },
      ],
      actors: [
        {
          id: '4ada5a94-4df6-42f3-ae32-16bf88e45d4c',
          duration: 15,
          startDuration: 0,
          startDurationrType: FormProcessStartDurationrTypeEnum.fromStartProcess,
          formProcessActorType: FormProcessActorTypeEnum.submitter,
          vote: FormProcessVoteTypeEnum.notVoted,
          allovedVotes: [
            FormProcessVoteTypeEnum.processed,
            FormProcessVoteTypeEnum.notProcessed,
            FormProcessVoteTypeEnum.rejected,
            FormProcessVoteTypeEnum.approved,
          ],
          displayData: {},
          metadata: [
            {
              id: '4835c900-e3a8-44ca-921c-7416480e9fb8',
              displayData: {},
              source: {
                type: 'sourced',
                source: 'taskActorRole',
                sourceKey: FormProcessActorTypeEnum.submitter,
              } as FormProcessMetadataType,
              name: '',
              createdDate: moment().toISOString(),
              metadataType: FormProcessMetadataTypeEnum.variable,
              permittedPhasses: [1],
            },
          ],
        },
        {
          id: 'f2c53805-ea45-49da-80be-e6156d2f06c7',
          duration: 10,
          startDuration: 15,
          startDurationrType: FormProcessStartDurationrTypeEnum.fromStartProcess,
          formProcessActorType: FormProcessActorTypeEnum.expertGuarantor,
          vote: FormProcessVoteTypeEnum.notVoted,
          allovedVotes: [
            FormProcessVoteTypeEnum.rejected,
            FormProcessVoteTypeEnum.approved,
            FormProcessVoteTypeEnum.forwarded,
          ],
          displayData: {},
          metadata: [
            {
              id: '52fc27ce-d226-4e6f-ae9f-0d5a63ab4f5f',
              displayData: {},
              source: {
                type: 'sourced',
                source: 'taskActorRole',
                sourceKey: FormProcessActorTypeEnum.expertGuarantor,
              } as FormProcessMetadataType,
              name: '',
              createdDate: moment().toISOString(),
              metadataType: FormProcessMetadataTypeEnum.variable,
              permittedPhasses: [1],
            },
          ],
        },
        {
          id: '2b6201fd-b926-4d6f-a2b5-cb3e405529e9',
          duration: 5,
          startDuration: 25,
          startDurationrType: FormProcessStartDurationrTypeEnum.fromStartProcess,
          formProcessActorType: FormProcessActorTypeEnum.guarantor,
          vote: FormProcessVoteTypeEnum.notVoted,
          allovedVotes: [
            FormProcessVoteTypeEnum.rejected,
            FormProcessVoteTypeEnum.approved,
            FormProcessVoteTypeEnum.forwarded,
          ],
          displayData: {},
          metadata: [
            {
              id: '656b056d-3879-42f7-9f1d-984dc47a7800',
              displayData: {},
              source: {
                type: 'sourced',
                source: 'taskActorRole',
                sourceKey: FormProcessActorTypeEnum.guarantor,
              } as FormProcessMetadataType,
              name: '',
              createdDate: moment().toISOString(),
              metadataType: FormProcessMetadataTypeEnum.variable,
              permittedPhasses: [1],
            },
          ],
        },
        {
          id: '8e8315e3-30ed-40b7-96ac-46c637de89bb',
          duration: 15,
          startDuration: 15,
          startDurationrType: FormProcessStartDurationrTypeEnum.fromStartProcess,
          formProcessActorType: FormProcessActorTypeEnum.his,
          vote: FormProcessVoteTypeEnum.notVoted,
          allovedVotes: [
            FormProcessVoteTypeEnum.rejected,
            FormProcessVoteTypeEnum.approved,
            FormProcessVoteTypeEnum.forwarded,
          ],
          displayData: {},
          metadata: [
            {
              id: '7ec3397b-f15b-4ba9-9b13-2800de8cb7ff',
              displayData: {},
              source: {
                type: 'sourced',
                source: 'taskActorRole',
                sourceKey: FormProcessActorTypeEnum.his,
              } as FormProcessMetadataType,
              name: '',
              createdDate: moment().toISOString(),
              metadataType: FormProcessMetadataTypeEnum.variable,
              permittedPhasses: [1],
            },
          ],
        },
      ],
      order: 1,
    },
    {
      id: '00000000-0000-0000-0000-000000000002',
      duration: 10,
      startDuration: 30,
      startDurationrType: FormProcessStartDurationrTypeEnum.fromStartProcess,
      displayData: {
        layout: secondPhaseLayout,
        visibleForRoles: [
          { role: FormProcessActorTypeEnum.guarantor },
          { role: FormProcessActorTypeEnum.expertGuarantor },
          { role: FormProcessActorTypeEnum.his },
          { role: FormProcessActorTypeEnum.processor, visibleInPhases: [2, 3] },
        ],
      } as FormProcessDisplayData,
      metadata: [
        {
          id: '783e73f3-6f90-4bd7-93c7-469f3b78325f',
          displayData: { input: 'textarea', responsibleRoles: [FormProcessActorTypeEnum.processor] },
          source: { type: 'userInput', format: JSONVariableTypeEnum.string } as FormProcessMetadataType,
          name: 'Stanovisko k připomínce',
          createdDate: moment().toISOString(),
          metadataType: FormProcessMetadataTypeEnum.variable,
          permittedPhasses: [2],
        },
        {
          id: 'dd2ac827-0352-4c43-93f7-d66c4c3ba0d3',
          displayData: { responsibleRoles: [FormProcessActorTypeEnum.processor] } as FormProcessDisplayData,
          source: {
            type: 'userInput',
            format: JSONVariableTypeEnum.string,
          } as FormProcessMetadataType,
          name: 'Zpracovatel',
          createdDate: moment().toISOString(),
          metadataType: FormProcessMetadataTypeEnum.variable,
          permittedPhasses: [2],
        },
        {
          id: '4c7ddb42-48b0-4d76-b410-416b0c93830c',
          displayData: { responsibleRoles: [FormProcessActorTypeEnum.processor] } as FormProcessDisplayData,
          source: {
            type: 'sourced',
            source: 'processFormMetadata',
            sourceKey: 'lastChangeDate',
          } as FormProcessMetadataType,
          name: 'Datum vydání',
          createdDate: moment().toISOString(),
          metadataType: FormProcessMetadataTypeEnum.variable,
          permittedPhasses: [2],
        },
      ],
      actors: [
        {
          id: '62808b17-3d86-40ae-bc84-945b5e615f88',
          duration: 10,
          startDuration: 30,
          startDurationrType: FormProcessStartDurationrTypeEnum.fromStartProcess,
          formProcessActorType: FormProcessActorTypeEnum.processor,
          vote: FormProcessVoteTypeEnum.notVoted,
          allovedVotes: [FormProcessVoteTypeEnum.processed, FormProcessVoteTypeEnum.notProcessed],
          displayData: {} as FormProcessDisplayData,
          metadata: [],
        },
      ],
      order: 2,
    },
    {
      id: '00000000-0000-0000-0000-000000000003',
      duration: 10,
      startDuration: 40,
      startDurationrType: FormProcessStartDurationrTypeEnum.fromStartProcess,
      displayData: {
        layout: thirdPhaseLayout,
        visibleForRoles: [
          { role: FormProcessActorTypeEnum.guarantor },
          { role: FormProcessActorTypeEnum.expertGuarantor },
          { role: FormProcessActorTypeEnum.his },
          { role: FormProcessActorTypeEnum.processor, phases: [2, 3] },
        ],
      },
      metadata: [],
      actors: [
        {
          id: 'ce8b3680-f53d-4333-89fd-e342a4a05152',
          duration: 15,
          startDuration: 0,
          startDurationrType: FormProcessStartDurationrTypeEnum.fromStartProcess,
          formProcessActorType: FormProcessActorTypeEnum.submitter,
          vote: FormProcessVoteTypeEnum.notVoted,
          allovedVotes: [
            FormProcessVoteTypeEnum.processed,
            FormProcessVoteTypeEnum.notProcessed,
            FormProcessVoteTypeEnum.rejected,
            FormProcessVoteTypeEnum.approved,
          ],
          displayData: {},
          metadata: [
            {
              id: '184c83ea-f9d4-4602-87b7-9d0814f3dae7',
              displayData: {},
              source: {
                type: 'sourced',
                source: 'taskActorRole',
                sourceKey: FormProcessActorTypeEnum.submitter,
              } as FormProcessMetadataType,
              name: '',
              createdDate: moment().toISOString(),
              metadataType: FormProcessMetadataTypeEnum.variable,
              permittedPhasses: [3],
            },
          ],
        },
        {
          id: '8fe7ab35-1db3-4895-ae24-bb600e113929',
          duration: 10,
          startDuration: 15,
          startDurationrType: FormProcessStartDurationrTypeEnum.fromStartProcess,
          formProcessActorType: FormProcessActorTypeEnum.expertGuarantor,
          vote: FormProcessVoteTypeEnum.notVoted,
          allovedVotes: [
            FormProcessVoteTypeEnum.rejected,
            FormProcessVoteTypeEnum.approved,
            FormProcessVoteTypeEnum.forwarded,
          ],
          displayData: {},
          metadata: [
            {
              id: '29ef69fd-c314-444d-a410-0e69779f1ea3',
              displayData: {},
              source: {
                type: 'sourced',
                source: 'taskActorRole',
                sourceKey: FormProcessActorTypeEnum.expertGuarantor,
              } as FormProcessMetadataType,
              name: '',
              createdDate: moment().toISOString(),
              metadataType: FormProcessMetadataTypeEnum.variable,
              permittedPhasses: [3],
            },
          ],
        },
        {
          id: 'b5dd6c24-7910-43fd-a5c1-d697af605307',
          duration: 15,
          startDuration: 15,
          startDurationrType: FormProcessStartDurationrTypeEnum.fromStartProcess,
          formProcessActorType: FormProcessActorTypeEnum.his,
          vote: FormProcessVoteTypeEnum.notVoted,
          allovedVotes: [
            FormProcessVoteTypeEnum.rejected,
            FormProcessVoteTypeEnum.approved,
            FormProcessVoteTypeEnum.forwarded,
          ],
          displayData: {},
          metadata: [
            {
              id: '333279ab-d37e-4040-b3fb-d898c4ba0f9a',
              displayData: {},
              source: {
                type: 'sourced',
                source: 'taskActorRole',
                sourceKey: FormProcessActorTypeEnum.his,
              } as FormProcessMetadataType,
              name: '',
              createdDate: moment().toISOString(),
              metadataType: FormProcessMetadataTypeEnum.variable,
              permittedPhasses: [3],
            },
          ],
        },
      ],
      order: 3,
    },
  ],
  templateName: 'Připomínkování SŽ',
  metadata: [
    {
      id: '1fdaf5bb-aa7c-44b0-9c4c-039697e1a5db',
      displayData: { highlight: 'bold' },
      source: { type: 'sourced', source: 'project', sourceKey: 'name' } as FormProcessMetadataType,
      name: 'Stavba',
      createdDate: moment().toISOString(),
      metadataType: FormProcessMetadataTypeEnum.variable,
      permittedPhasses: [1],
    },
    {
      id: '27a80ae0-a6cb-4a54-a1a0-cd14c77bf006',
      displayData: {},
      source: {
        type: 'sourced',
        source: 'projectCard',
        sourceKey: 'marking',
      } as FormProcessMetadataType,
      name: 'Označení projektu',
      createdDate: moment().toISOString(),
      metadataType: FormProcessMetadataTypeEnum.variable,
      permittedPhasses: [1],
    },
    {
      id: '3f1ea4c1-1316-4bd6-97aa-9156844e8b9b',
      displayData: {},
      source: {
        type: 'sourced',
        source: 'entityMetadata',
        sourceKey: undefined,
        sourceName: 'Označení části',
      } as FormProcessMetadataType,
      name: 'Označení části',
      createdDate: moment().toISOString(),
      metadataType: FormProcessMetadataTypeEnum.variable,
      permittedPhasses: [1],
    },
    {
      id: '48f4a653-62d7-4a11-bfbd-22762ef15b99',
      displayData: {},
      source: {
        type: 'sourced',
        source: 'entityMetadata',
        sourceKey: undefined,
        sourceName: 'Název části',
      } as FormProcessMetadataType,
      name: 'Název části',
      createdDate: moment().toISOString(),
      metadataType: FormProcessMetadataTypeEnum.variable,
      permittedPhasses: [1],
    },
    {
      id: '587e9750-8f7a-4576-9bc6-0aef12752061',
      displayData: {},
      source: {
        type: 'sourced',
        source: 'entityMetadata',
        sourceKey: undefined,
        sourceName: 'Číslo objektu/komplexu',
      } as FormProcessMetadataType,
      name: 'Číslo objektu/komplexu',
      createdDate: moment().toISOString(),
      metadataType: FormProcessMetadataTypeEnum.variable,
      permittedPhasses: [1],
    },
    {
      id: '6f4ddf12-2ca9-4456-afe4-223cdcd7270f',
      displayData: {},
      source: {
        type: 'sourced',
        source: 'entityMetadata',
        sourceKey: undefined,
        sourceName: 'Název objektu/dílčí části',
      } as FormProcessMetadataType,
      name: 'Název objektu/dílčí části',
      createdDate: moment().toISOString(),
      metadataType: FormProcessMetadataTypeEnum.variable,
      permittedPhasses: [1],
    },
    {
      id: '7dc48e1b-244c-4bbb-81d1-1df2558cd38f',
      displayData: {},
      source: {
        type: 'sourced',
        source: 'entityMetadata',
        sourceKey: undefined,
        sourceName: 'Číslo přílohy typ',
      } as FormProcessMetadataType,
      name: 'Číslo přílohy typ',
      createdDate: moment().toISOString(),
      metadataType: FormProcessMetadataTypeEnum.variable,
      permittedPhasses: [1],
    },
    {
      id: '8daea2e8-2c16-4c07-8e37-620d9f619a0d',
      displayData: {},
      source: {
        type: 'sourced',
        source: 'entityMetadata',
        sourceKey: undefined,
        sourceName: 'Název přílohy',
      } as FormProcessMetadataType,
      name: 'Název přílohy',
      createdDate: moment().toISOString(),
      metadataType: FormProcessMetadataTypeEnum.variable,
      permittedPhasses: [1],
    },
    {
      id: '993e8648-3bd1-4db3-b4c1-3b7e77498bc2',
      displayData: {},
      source: {
        type: 'sourced',
        source: 'entityMetadata',
        sourceKey: undefined,
        sourceName: 'Název dílčí části přílohy',
      } as FormProcessMetadataType,
      name: 'Název dílčí části přílohy',
      createdDate: moment().toISOString(),
      metadataType: FormProcessMetadataTypeEnum.variable,
      permittedPhasses: [1],
    },
  ],
};
