import { Collapse, Modal, Typography } from 'antd';
import { api } from 'api';
import {
  ProjectCardDocumentationLevelEnum,
  ProjectListDto,
  ResultFileFormatEnum,
  SortOrder,
} from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import CommonDocumentsGrid from 'components/DocumentsGrid/CommonDocumentsGrid';
import { EmptyGate } from 'components/EmptyGate/EmptyGate';
import {
  BooleanOption,
  createFrontendBooleanFilter,
} from 'components/filters/components/RadioFilter/variants/BooleanFilter/BooleanFilter';
import {
  createFrontendSingleSelectFilter,
  IOption,
  SelectFilterValue,
} from 'components/filters/components/SelectFilter/SelectFilter';
import { createFrontendDocumentationLevelFilter } from 'components/filters/components/SelectFilter/variants/DocumentationLevelFilter/DocumentationLevelFilter';
import { createFrontendOrganizationLabelsFilter } from 'components/filters/components/SelectFilter/variants/OrganizationLabelsFilter/OrganizationLabelsFilter';
import { createFrontendMultiTextFilter } from 'components/filters/components/TextFilter/TextFilter';
import { FiltersContext } from 'components/filters/FiltersContextProvider';
import { FiltersPersistentKey, FrontendFilter } from 'components/filters/filterTypes';
import { FrontendOrderOption } from 'components/filters/orderTypes';
import { FilterToolbarStyled } from 'components/filters/render/FilterToolbar/FilterToolbarStyled';
import CommonExportFormModal from 'components/forms/CommonExportFormModal/CommonExportFormModal';
import { ExportEntityTypeEnum, mapProjectsDataToDto } from 'components/forms/CommonExportFormModal/CommonExportUtils';
import { ProjectCreateFormModal } from 'components/forms/ProjectCreateForm';
import StackPanel from 'components/StackPanel';
import { HIDE_BUTTON_PROPS } from 'config/constants';
import { useApiData, useBoolean, useCurrentAppUser, useIntl, useStoreSelector } from 'hooks';
import { useDirtyStoreReload, useDirtyStoreReloadCallback, useDispatchEffect } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import { uniqBy } from 'lodash';
import { useSelectedItems } from 'pages/AllDocumentsPage/AllDocumentsPage.SelectedItemsContextProvider';
import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { organizationLabelsMapSelector } from 'store/selectors/organizationLabelsSelectors';
import { dateComparer, textComparer } from 'utils/comparators';
import ProjectListPageRow from './ProjectListPageRow';
import styles from './ProjectsListPage.module.less';
import ProjectListPageToolbar, { ProjectListViewTypeEnum } from './ProjectsListToolbar';

const NO_SET_VALUE_LAST_ACCESS_DAY = '0001-01-01T00:00:00Z';

export const PROJECT_LIST_ORDER_OPTIONS: FrontendOrderOption<ProjectListDto>[] = [
  {
    key: 'name',
    label: <Fmt id="SearchSortTypeItem.projectName" />,
    compare: textComparer.map((project) => project.name),
    defaultOrder: SortOrder.asc,
  },
  {
    key: 'organization',
    label: <Fmt id="SearchSortTypeItem.organization" />,
    compare: textComparer.map((project) => project.organization.name),
  },
  {
    key: 'createdDate',
    label: <Fmt id="SearchSortTypeItem.projectCreated" />,
    compare: dateComparer.reverse().map((project) => project.createdDate),
  },
  {
    key: 'lastAccessDate',
    label: <Fmt id="SearchSortTypeItem.lastAccessDate" />,
    compare: dateComparer.reverse().map((project) => project.lastAccessDate),
  },
];

const ProjectsList: FunctionComponent = () => {
  const currentAppUser = useCurrentAppUser();
  const dispatch = useDispatch<Dispatch>();
  const [infoModalText, setInfoModalText] = useState<JSX.Element>(null);
  const [organizationsAdminReport] = useApiData((ct) => api.master.organization.getOrganizationsAdminReport(ct), {
    autoload: true,
  });
  const [exportModalVisible, showExportModal, hideExportModal] = useBoolean(false);
  const intl = useIntl();
  const organizations = useMemo(
    () => organizationsAdminReport?.organizations.filter((org) => org.isOrganizationAdmin),
    [organizationsAdminReport]
  );

  useDispatchEffect(
    (dispatch) =>
      dispatch.organizationLabels.loadData({
        reload: true,
        data: currentAppUser.organizationUsers?.map((orgUser) => orgUser.organization.id),
      }),
    [currentAppUser]
  );

  useDirtyStoreReloadCallback(
    (state) => state.organizationLabels,
    (dispatch) =>
      dispatch.organizationLabels.loadData({
        reload: true,
        data: currentAppUser.organizationUsers?.map((orgUser) => orgUser.organization.id),
      })
  );

  const organizationsLabels = useStoreSelector(organizationLabelsMapSelector);

  useEffect(() => {
    dispatch.allProjects.loadData({ reload: false });
  }, []);

  useDirtyStoreReload(
    (state) => state.allProjects,
    (dispatch) => dispatch.allProjects
  );

  const reloadProjects = useCallback(() => {
    dispatch.allProjects.loadData({ reload: true });
  }, [dispatch]);

  const [createModalVisible, showCreateModal, hideCreateModal] = useBoolean();
  const [viewType, setViewType] = useState<ProjectListViewTypeEnum>(ProjectListViewTypeEnum.filter);

  const handleProjectCreate = useCallback(async () => {
    hideCreateModal();
    reloadProjects();
  }, [hideCreateModal, reloadProjects]);

  const projectsState = useStoreSelector((state) => state.allProjects);

  const organizationNameOptions = useMemo<IOption<string>[]>(
    () =>
      uniqBy(
        projectsState.data?.projects?.map((project) => ({
          id: project.organization.id,
          title: project.organization.name,
        })),
        (option) => option.id
      ).sort(textComparer.map((option) => option.title)),
    [projectsState]
  );
  const showViewTypeButton = useMemo(() => organizationNameOptions?.length > 1, [organizationNameOptions]);

  const filtersOptions: FrontendFilter<ProjectListDto>[] = useMemo(
    () => [
      createFrontendMultiTextFilter(
        'projectNameAndProjectDescription',
        (project) => [project.name, project.description],
        {
          label: <Fmt id="general.project" />,
        }
      ),
      createFrontendSingleSelectFilter(
        'organizationName',
        {
          label: <Fmt id="SearchSortTypeItem.organization" />,
          options: organizationNameOptions,
        },
        (project) => project.organization.id
      ),
      createFrontendOrganizationLabelsFilter('organizationLabels', (project) => project.labels || []),
      createFrontendDocumentationLevelFilter(
        'documentationLevel',
        (project) => project.projectCard?.documentationLevel || ProjectCardDocumentationLevelEnum.undefined
      ),
      createFrontendBooleanFilter(
        'isNewProject',
        {
          label: <Fmt id="BooleanFilter.isNewProject" />,
          title: <Fmt id="BooleanFilter.isNewProject.description" />,
          trueLabel: <Fmt id="BooleanFilter.isNewProject.yes" />,
          falseLabel: <Fmt id="BooleanFilter.isNewProject.no" />,
        },
        (project: ProjectListDto) =>
          project.lastAccessDate === NO_SET_VALUE_LAST_ACCESS_DAY ? BooleanOption.True : BooleanOption.False
      ),
    ],
    [organizationNameOptions]
  );

  const {
    filteredSortedItems,
    setFilterValue,
    hasFilteredOutItems,
    setFiltersConfig,
    filters,
    clearFilters,
    itemCounts,
    isFilterActive,
  } = useContext(FiltersContext);

  useEffect(() => {
    setFiltersConfig({
      filtersOptions: filtersOptions,
    });
  }, [filtersOptions, setFiltersConfig]);

  useEffect(() => {
    setFiltersConfig({
      filtersOptions: filtersOptions,
      orderOptions: PROJECT_LIST_ORDER_OPTIONS,
      persistentKey: FiltersPersistentKey.ProjectsAll,
      items: projectsState.data?.projects,
    });
  }, [filteredSortedItems, filtersOptions, projectsState.data, setFiltersConfig]);

  const handleOrganizationTagClick = useCallback(
    (organizationId: Guid) => {
      const projectsFilterValue: SelectFilterValue<Guid> = {
        values: [organizationId],
        notSet: false,
        useAndOperator: false,
      };
      setFilterValue('organizationName', projectsFilterValue);
    },
    [setFilterValue]
  );

  const filteredOrganizations = useMemo(
    () =>
      uniqBy(
        filteredSortedItems?.map((project) => project.organization),
        (org) => org.id
      ),
    [filteredSortedItems]
  );

  const hasProjectAddPermission = useMemo(
    () =>
      organizations?.some(
        (organiation) =>
          organiation.organizationUsers.find((user) => user.appUserProfileId === currentAppUser.id)?.isAdmin
      ) || currentAppUser.isAdmin,
    [organizations]
  );

  const exportProjectListDisabled = useMemo(() => {
    return !filteredSortedItems.length;
  }, [filteredSortedItems]);

  const [{ selectedItemsIds, selectItem }, listRef] = useSelectedItems(filteredSortedItems);

  const projectListForExport = useMemo(() => {
    return filteredSortedItems?.map((projectData) => {
      const projectLabelIds: Guid[] = projectData.labels;
      return {
        ...projectData,
        labels: !!organizationsLabels ? projectLabelIds?.map((id) => organizationsLabels[id]?.name) : [],
      };
    });
  }, [filteredSortedItems, organizationsLabels]);

  const handleExport = useCallback(
    async (exportFileType: ResultFileFormatEnum) => {
      return await api.master.projects.exportProjects(mapProjectsDataToDto(projectListForExport, intl, exportFileType));
    },
    [projectListForExport, intl]
  );

  return (
    <>
      <ProjectListPageToolbar
        selectedFilesIds={selectedItemsIds}
        addProjectDisabled={!hasProjectAddPermission || !organizations?.length}
        onProjectUpload={showCreateModal}
        reloadProjects={reloadProjects}
        setViewType={setViewType}
        viewType={viewType}
        showViewTypeButton={showViewTypeButton}
        exportProjectListDisabled={exportProjectListDisabled}
        showExportModal={showExportModal}
      />
      <FilterToolbarStyled />
      <div className={styles.results}>
        <ContentGate error={projectsState.error} loading={projectsState.loading && !projectsState.data}>
          {viewType === ProjectListViewTypeEnum.filter && (
            <CommonDocumentsGrid
              listRef={listRef}
              documents={filteredSortedItems}
              rowRenderer={({
                index, // Index of row within collection
                style, // Style object to be applied to row (to position it)
              }) => {
                const item = filteredSortedItems[index];
                return (
                  <ProjectListPageRow
                    style={style}
                    key={item.id}
                    name={item.name}
                    organizationName={item.organization.name}
                    organizationId={item.organization.id}
                    onChangeFavorites={reloadProjects}
                    selected={selectedItemsIds.has(item.id)}
                    onClickCheckbox={() => selectItem(item.id, index)}
                    handleOrganizationTagClick={handleOrganizationTagClick}
                    organizationLabels={organizationsLabels}
                    projectLabels={item.labels}
                    {...item}
                  />
                );
              }}
              emptyDescription={<EmptyGate empty clearSearch={clearFilters} hasUnfilteredItems={hasFilteredOutItems} />}
            />
          )}
          {viewType === ProjectListViewTypeEnum.collapse && (
            <StackPanel vertical scrollable>
              <Collapse>
                {filteredOrganizations.map((org, index) => (
                  <Collapse.Panel
                    key={index}
                    header={
                      <>
                        <Typography.Text strong>{org.name}</Typography.Text>
                        <br />
                        <Typography.Text>{org.description}</Typography.Text>
                      </>
                    }
                  >
                    {filteredSortedItems
                      .filter((proj) => proj.organization.id === org.id)
                      .map((proj, index) => (
                        <ProjectListPageRow
                          key={proj.id}
                          name={proj.name}
                          organizationName={proj.organization.name}
                          organizationId={proj.organization.id}
                          onChangeFavorites={reloadProjects}
                          selected={selectedItemsIds.has(proj.id)}
                          onClickCheckbox={() => selectItem(proj.id, index)}
                          handleOrganizationTagClick={handleOrganizationTagClick}
                          organizationLabels={organizationsLabels}
                          projectLabels={proj.labels}
                          {...proj}
                        />
                      ))}
                  </Collapse.Panel>
                ))}
              </Collapse>
            </StackPanel>
          )}
        </ContentGate>
      </div>

      <ProjectCreateFormModal
        organizations={organizations}
        open={createModalVisible}
        onSubmit={handleProjectCreate}
        onClose={hideCreateModal}
        setInfoModalText={setInfoModalText}
        currentUserName={currentAppUser?.username}
      />
      <Modal
        title={<Fmt id="general.newProject" />}
        open={!!infoModalText}
        onOk={() => setInfoModalText(null)}
        onCancel={() => setInfoModalText(null)}
        cancelButtonProps={HIDE_BUTTON_PROPS}
      >
        {infoModalText}
      </Modal>
      <CommonExportFormModal
        onSubmit={hideExportModal}
        onClose={hideExportModal}
        visible={exportModalVisible}
        handleExport={handleExport}
        exportEntityType={ExportEntityTypeEnum.projects}
      />
    </>
  );
};

export default React.memo(ProjectsList);
