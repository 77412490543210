import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { masterApi } from 'api/completeApi';
import { CalendarDto, CalendarTemplateDto } from 'api/completeApiInterfaces';
import { CalendarSettingsFormData } from 'components/forms/CalendarSettingsForm/CalendarSettingsForm';
import { useApiData, useBoolean, useIntl, useSameCallback } from 'hooks';
import React, { FunctionComponent, useEffect } from 'react';
import { ProjectTemplateCalendarSettingsFormModal } from './ProjectTemplateCalendarSettingsFormModal';

type Props = {
  calendar?: CalendarTemplateDto;
  disabled?: boolean;
  editDisabled?: boolean;
  title?: string;
  onSuccess?: (data: CalendarSettingsFormData, response: void) => void;
  buttonType?: ButtonType;
  organizationId: Guid;
  projectId?: Guid;
};

export const ProjectTemplateCalendarSettingsFormButton: FunctionComponent<Props> = ({
  calendar,
  disabled,
  onSuccess,
  children,
  organizationId,
  projectId,
  buttonType = 'link',
}) => {
  const [modalFormVisible, modalFormShow, modalFormHide] = useBoolean(false);

  const intl = useIntl();

  const [calendars, error, loading, loadCalendars] = useApiData(
    (ct) => masterApi.projects.admin.calendar.get.post({ organizationId, projectId }, ct),
    { autoload: false }
  );

  const handleSubmit = useSameCallback((data: CalendarSettingsFormData) => {
    onSuccess && onSuccess(data);
    modalFormHide();
  });

  useEffect(() => {
    loadCalendars();
  }, [organizationId]);

  const currentCalendar = (calendar as CalendarDto) || calendars?.orgCalendar;

  return (
    <>
      <Button
        type={buttonType}
        icon={<EditOutlined />}
        disabled={disabled || !currentCalendar}
        onClick={modalFormShow}
        loading={loading}
      >
        {children}
      </Button>

      {currentCalendar && modalFormVisible && (
        <ProjectTemplateCalendarSettingsFormModal
          onSubmit={handleSubmit}
          open={modalFormVisible}
          onClose={modalFormHide}
          initialCalendar={currentCalendar}
          intl={intl}
        />
      )}
    </>
  );
};
