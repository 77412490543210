import { Form, Input, InputRef } from 'antd';
import { GroupPatchDto } from 'api/completeApiInterfaces';
import { groupSelectUserFooter, UserTransferFormItem } from 'components/UserTransfer/UserTransfer';
import { MAX_ITEM_DESCRIPTION_LENGTH } from 'config/constants';
import { useIntl } from 'hooks';
import { AutoSizeType } from 'rc-textarea/lib/ResizableTextArea';
import React, { FC, useMemo } from 'react';
import { duplicateNameRuleCallback, maxLengthRule, requiredRule } from 'utils/formHelpers';
import { useLoadUsersAndGroups } from 'utils/useLoadUsersAndGroups';

export type GroupFormData = {
  name: string;
  description: string;
  userIds: Guid[];
};

const DESCRIPTION_AUTOSIZE: AutoSizeType = { minRows: 2 };

type Props = {
  validateUniqueName: (name: string) => boolean;
  defaults?: GroupPatchDto;
  setRef: (ref: InputRef) => void;
};

const GroupFormComponent: FC<Props> = ({ validateUniqueName, defaults, setRef }) => {
  const intl = useIntl();
  const [users, groups] = useLoadUsersAndGroups();

  const createFooter = useMemo(() => groupSelectUserFooter(groups, users), [groups, users]);

  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.name.label' })}
        name="name"
        initialValue={defaults?.name}
        rules={[
          requiredRule('forms.items.name.rules.required', true),
          maxLengthRule('general.maxNameLength'),
          duplicateNameRuleCallback(validateUniqueName),
        ]}
      >
        <Input placeholder={intl.formatMessage({ id: 'GroupForm.items.name.placeholder' })} autoFocus ref={setRef} />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.description.label' })}
        name="description"
        initialValue={defaults?.description}
        rules={[
          {
            whitespace: true,
            message: intl.formatMessage({ id: 'forms.items.name.rules.empty' }),
          },
          {
            max: MAX_ITEM_DESCRIPTION_LENGTH,
            message: intl.formatMessage({ id: 'general.maxDescriptionLength' }, { max: MAX_ITEM_DESCRIPTION_LENGTH }),
          },
        ]}
      >
        <Input.TextArea rows={2} autoSize={DESCRIPTION_AUTOSIZE} />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.userIds.label' })}
        name="userIds"
        initialValue={defaults?.userIds || []}
      >
        <UserTransferFormItem users={users} createFooter={createFooter} />
      </Form.Item>
    </>
  );
};

export const GroupForm = React.memo(GroupFormComponent);
