import { InputRef } from 'antd';
import { CommentProcedureCategoryDto } from 'api/completeApiInterfaces';
import { StagedAttachment } from 'components/DiscussionChat/DiscussionInputAttachments/DiscussionInputAttachments.utils';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper } from 'components/forms/FormModalWrapper';
import { useFocus } from 'hooks/useFocus';
import { useSameCallback } from 'hooks/useSameCallback';
import { InjectedIntlProps } from 'locale/Fmt';
import { endsWith } from 'lodash';
import React, { FunctionComponent } from 'react';
import uuid from 'uuid';
import {
  DiscussionInputAttachmentNameForm,
  DiscussionInputAttachmentNameFormData,
} from './DiscussionInputAttachmentNameForm';

type Props = FormModalProps<DiscussionInputAttachmentNameFormData, CommentProcedureCategoryDto> &
  InjectedIntlProps & {
    imageFile: File;
    disabled?: boolean;
  };

export const DiscussionInputAttachmentNameFormModal: FunctionComponent<Props> = ({
  onSubmit,
  onClose,
  intl,
  disabled,
  imageFile,
  ...restProps
}) => {
  const handleSubmit = useSameCallback(async (values: DiscussionInputAttachmentNameFormData) => {
    if (!values || disabled) {
      return null;
    }

    const name = endsWith(values.name, '.png') ? values.name : `${values.name}.png`;

    const stagedAttachment: StagedAttachment = {
      id: uuid(),
      file: imageFile,
      fileUrl: URL.createObjectURL(imageFile),
      name,
    };

    onSubmit && (await onSubmit(stagedAttachment));
    return null;
  });

  const { setInputRef } = useFocus<InputRef>(restProps.open);

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      onClose={onClose}
      titleId="DiscussionLinkAttachmentForm.title"
      layout="vertical"
      {...restProps}
    >
      <DiscussionInputAttachmentNameForm imageFile={imageFile} disabled={disabled} setRef={setInputRef} />
    </FormModalWrapper>
  );
};
