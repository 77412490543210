import { WarningTwoTone } from '@ant-design/icons';
import { Alert, Button, Modal, Typography } from 'antd';
import { userManager } from 'config';
import { Fmt } from 'locale';
import React, { FunctionComponent, useState } from 'react';
import styles from './AccessTokenExpiredModal.module.less';

type Props = {
  visible: boolean;
};

const AccessTokenExpiredModal: FunctionComponent<Props> = ({ visible }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const signinSilent = () => {
    setLoading(true);

    userManager
      .signinSilent()
      .then(() => {
        setError(false);
        setLoading(false);
      })
      .catch(() => signinPopup());
  };

  const signinPopup = () => {
    setLoading(true);

    userManager
      .signinPopup()
      .then(() => setError(false))
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  return (
    <Modal open={visible} width={550} title={null} footer={null} closable={false} maskClosable={false}>
      <div className={styles.modal}>
        <Typography.Title level={3}>
          <WarningTwoTone twoToneColor="#f5222d" className={styles.icon} />
          <Fmt id="AccessTokenExpiredModal.title" />
        </Typography.Title>
        <Typography.Paragraph>
          <Fmt id="AccessTokenExpiredModal.detail" />
        </Typography.Paragraph>
        <Button type="primary" loading={loading} onClick={signinSilent}>
          <Fmt id="general.signIn" />
        </Button>
        {error && (
          <Alert
            className={styles.error}
            message={<Fmt id="general.error" />}
            description={<Fmt id="AccessTokenExpiredModal.error" />}
            type="error"
            showIcon
          />
        )}
      </div>
    </Modal>
  );
};

export default AccessTokenExpiredModal;
