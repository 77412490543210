import { Form, Input } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { apiConstraints } from 'api/completeApiConstraints';
import FilenameErrorMessage from 'components/FilenameErrorMessage';
import PathDisplay from 'components/PathDisplay';
import { FILE_AND_FOLDER_NAME_REGEX } from 'config/constants';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import moment from 'moment';
import React, { FC, useEffect, useMemo } from 'react';
import { maxLengthRule, requiredRule } from 'utils/formHelpers';
import { DocumentLinkForEdit, SubmitButtonTextsEnum } from './DocumentLinkRenameFormModal';

export type DocumentsLinkRenameFormData = {
  documentLinkName: string;
};

type Props = {
  focusedDocumentLink: DocumentLinkForEdit;
  setSubmitButtonText: React.Dispatch<React.SetStateAction<SubmitButtonTextsEnum>>;
  showTargetPath?: boolean;
  showLinkLocation?: boolean;
};

const DocumentLinkRenameForm: FC<Props> = ({
  focusedDocumentLink,
  setSubmitButtonText,
  showTargetPath,
  showLinkLocation,
}) => {
  const intl = useIntl();
  const documentLinkName = useWatch('documentLinkName');

  useEffect(() => {
    if (focusedDocumentLink.name === documentLinkName?.trim()) setSubmitButtonText(SubmitButtonTextsEnum.ok);
    else setSubmitButtonText(SubmitButtonTextsEnum.save);
  }, [documentLinkName]);

  const linkedDocumentPath = useMemo(() => {
    return focusedDocumentLink.linkedDocument?.directoryPath || undefined;
  }, [focusedDocumentLink]);

  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: 'DocumentLinkRenameForm.linkName' })}
        name="documentLinkName"
        initialValue={focusedDocumentLink.name}
        rules={[
          requiredRule('forms.items.rules.noEmpty'),
          maxLengthRule('general.maxNameLength', apiConstraints.documentLinkPatchDto.name.maxLength),
          {
            pattern: FILE_AND_FOLDER_NAME_REGEX,
            message: <FilenameErrorMessage />,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <div>
          <Fmt id="DocumentLinkRenameForm.createdDate" />
        </div>
        {moment(focusedDocumentLink.createdDate)
          .locale(intl.locale)
          .format('lll')}
      </Form.Item>
      {showTargetPath && !!linkedDocumentPath && (
        <Form.Item>
          <div>
            <Fmt id="DocumentLinkRenameForm.targetPath" />
          </div>
          {<PathDisplay alignLeft path={linkedDocumentPath} />}
        </Form.Item>
      )}
      {showLinkLocation && (
        <Form.Item>
          <div>
            <Fmt id="DocumentLinkRenameForm.linkLocation" />
          </div>
          {<PathDisplay alignLeft path={focusedDocumentLink.directory.path} />}
        </Form.Item>
      )}
      <Form.Item>
        <div>
          <Fmt id="DocumentLinkRenameForm.linkAuthor" />
        </div>
        {focusedDocumentLink.createdBy.username}
      </Form.Item>
    </>
  );
};

export default DocumentLinkRenameForm;
