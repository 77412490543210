import { Form, Input } from 'antd';
import { apiConstraints } from 'api/completeApiConstraints';
import { DirectoryListDto } from 'api/completeApiInterfaces';
import { DirectoriesTreeSelectFormItem } from 'components/DirectoriesTreeSelect/DirectoriesTreeSelect';
import DocumentMultipleActionError from 'components/DocumentMultipleActionError';
import PathDisplay from 'components/PathDisplay';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import { canWriteInDirectory } from 'pages/AllDocumentsPage/AllDocumentsPage';
import React, { FC } from 'react';
import { maxLengthRule, requiredRule } from 'utils/formHelpers';
import styles from './DocumentLinksCreateForm.module.less';

export type DocumentsLinksCreateFormData = {
  destinationDirectory: Guid;
  documentLinkName: string;
};

type Props = {
  selectedDocumentsCount: number;
  defaultDocumentLinkName?: string;
  selectedDirectory: DirectoryListDto;
  destinationDirectory: Guid;
  errors: JSX.Element[];
};

const DocumentLinksCreateForm: FC<Props> = ({
  selectedDirectory,
  destinationDirectory,
  selectedDocumentsCount,
  defaultDocumentLinkName,
  errors,
}) => {
  const intl = useIntl();

  return (
    <>
      <div className={styles.documentLinkWrap}>
        <div className={styles.movingDocumentsTitle}>
          {<Fmt id="DocumentLinksCreateForm.bindingsToDocuments" values={{ count: selectedDocumentsCount }} />}
        </div>
        {<PathDisplay path={selectedDirectory.path} className={styles.sourceDocumentsPath} />}
      </div>
      {selectedDocumentsCount === 1 && (
        <Form.Item
          label={intl.formatMessage({ id: 'DocumentLinksCreateForm.documentLinkName.label' })}
          name="documentLinkName"
          initialValue={defaultDocumentLinkName}
          rules={[
            requiredRule('forms.items.rules.noEmpty'),
            maxLengthRule('general.maxNameLength', apiConstraints.documentLinkCreateDto.name.maxLength),
          ]}
        >
          <Input />
        </Form.Item>
      )}
      <Form.Item
        className={styles.item}
        label={intl.formatMessage({ id: 'DocumentLinksCreateForm.destinationDirectoryId.label' })}
        name="destinationDirectory"
        initialValue={destinationDirectory}
        rules={[requiredRule('forms.items.rules.noEmpty')]}
      >
        <DirectoriesTreeSelectFormItem
          isItemDisabled={(item) => !canWriteInDirectory(item)}
          autoFocus
          dropdownStyle={{ maxHeight: 400, overflow: 'auto', maxWidth: 300 }}
          placeholder={intl.formatMessage({ id: 'DirectoryMoveFormModal.destinationDirectoryId.placeholder' })}
        />
      </Form.Item>
      <DocumentMultipleActionError errors={errors} titleId="DocumentLinksCreateForm.errors" />
    </>
  );
};

export default DocumentLinksCreateForm;
