import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, message, Space } from 'antd';
import { api } from 'api';
import { masterApi } from 'api/completeApi';
import { AppUserDto, OrgUserDto, ServiceError } from 'api/completeApiInterfaces';
import AppUsersAddFormModal from 'components/forms/AppUsersAddFormModal/AppUserAddFormModal';
import OrganizationUsersEditFormModal from 'components/forms/OrganizationUserEditForm/OrganizationUserEditFormModal';
import GeneralSettingsUsersList from 'components/GeneralSettingsUsersList/GeneralSettingsUsersList';
import ServiceErrorBox from 'components/ServiceErrorBox';
import SpinBox from 'components/SpinBox';
import { useBoolean, useIntl, useStoreSelector } from 'hooks';
import { Fmt, InjectedIntlProps } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import { ProjectUserProfileListExtended } from 'pages/OrganizationsSettingPage/OrganizationsDetailPanel';
import Panel from 'pages/ProjectSettingsPage/Panel/Panel';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { messageError } from 'utils';

type Props = InjectedIntlProps & {
  appUsers?: AppUserDto[];
  loading: boolean;
  error: ServiceError;
  titleId?: IntlMessageId;
  selectedId?: Guid;
  onSelect?: (userId: Guid) => void;
  reloadUsers?: () => void;
  panelWidth?: number | string;
  organizationUsers?: OrgUserDto[];
  loadOrganizationsAdminReport?: () => void;
  userList: ProjectUserProfileListExtended[];
};

const AspeHubUsersListPanel: FunctionComponent<Props> = ({
  appUsers,
  loading,
  error,
  selectedId,
  reloadUsers,
  onSelect,
  panelWidth,
  organizationUsers,
  loadOrganizationsAdminReport,
  userList,
}) => {
  const [search, setSearch] = useState<string>('');
  const [addUsersFormModalVisible, showAddUsersFormModal, hideAddUsersFormModal] = useBoolean(false);
  const [userToEditUserName, setUserToEditUserName] = useState<string>(undefined);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const intl = useIntl();

  const handleSubmit = () => {
    hideAddUsersFormModal();
    reloadUsers();
  };

  const toEditUser = useMemo(
    () => organizationUsers?.find((user) => user.appUserProfile.username === userToEditUserName),
    [userToEditUserName]
  );

  const reinviteAppUser = useCallback(
    async (appUserId: Guid) => {
      const [err] = await api.master.organization.reinvite({
        appUserId,
      });
      if (err) {
        messageError(err, intl);
      }
    },
    [intl]
  );

  const appSettings = useStoreSelector((state) => state.appSettings.data);

  const handleSynchronize = useCallback(async () => {
    setIsProcessing(true);
    const [err, resp] = await masterApi.projects.admin.Vysocina.IdmSync.post();

    if (err) {
      messageError(err, intl);
      setIsProcessing(false);
      return null;
    }
    void message.info(intl.formatMessage({ id: 'general.synchronized' }));
    setIsProcessing(false);
    return null;
  }, [intl]);

  const handleEditUser = () => {
    setUserToEditUserName(undefined);
    reloadUsers();
    loadOrganizationsAdminReport();
  };

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const toAddAppUsers = useMemo(() => {
    const orgUsersUserNames = organizationUsers?.map((orgUser) => orgUser.appUserProfile.username);
    return appUsers.filter((appUser) => !orgUsersUserNames.includes(appUser.username));
  }, [appUsers, organizationUsers]);

  const renderContent = () => {
    if (error) return <ServiceErrorBox error={error} />;
    if (loading && !userList) return <SpinBox />;
    if (userList === null) return null;
    return (
      <GeneralSettingsUsersList
        data={userList}
        search={search}
        selectedId={selectedId}
        onSelect={onSelect}
        onClearSearch={clearSearch}
        onEdit={!!organizationUsers ? setUserToEditUserName : undefined}
        onReinvite={reinviteAppUser}
      />
    );
  };

  return (
    <Panel
      noMargin
      panelWidth={panelWidth}
      onSearch={setSearch}
      searchValue={search}
      toolbarTitle={
        <Space>
          <Button type="primary" onClick={showAddUsersFormModal} icon={<PlusOutlined />}>
            <Fmt id="Panel.addUser.tooltip" />
          </Button>
          {!!appSettings.idmActive && (
            <Button type="primary" onClick={handleSynchronize} icon={<SyncOutlined />} loading={isProcessing}>
              {'Sync IDM'}
            </Button>
          )}
        </Space>
      }
    >
      {renderContent()}
      <AppUsersAddFormModal
        onSubmit={handleSubmit}
        onClose={hideAddUsersFormModal}
        open={addUsersFormModalVisible}
        toAddAppUsers={toAddAppUsers}
        titleId={'AspeHubUsersListPanel.addModal.title'}
      />
      <OrganizationUsersEditFormModal
        open={!!userToEditUserName}
        onSubmit={handleEditUser}
        onClose={() => setUserToEditUserName(undefined)}
        toEditUser={toEditUser}
      />
    </Panel>
  );
};

export default AspeHubUsersListPanel;
