import { RedoOutlined } from '@ant-design/icons';
import { Alert, Button } from 'antd';
import {
  AccessLevelEnum,
  DirectoryContentDto,
  DirectoryListDto,
  DiscardedDocumentDto,
  DocumentLinkDto,
  DownloadUrl,
  ProjectUserProfileDto,
} from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import { AxiosResponse } from 'axios';
import CommonDocumentRow, { DocumentRowType } from 'components/CommonDocumentRow/CommonDocumentRow';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { CommonDocument } from 'components/DocumentCompleteList/DocumentCompleteList';
import { DocumentName } from 'components/DocumentRow/DocumentName';
import DocumentRow, { DocumentRowProps } from 'components/DocumentRow/DocumentRow';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import RestoreDocumentsFormModal from 'components/forms/RestoreForm/RestoreDocumentsFormModal';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useBoolean, useIntl } from 'hooks';
import { Fmt, InjectedIntlProps, memoizeWithIntl } from 'locale';
import { isAccessLevelAtLeast } from 'pages/AllDocumentsPage/AllDocumentsPage';
import React, { FunctionComponent } from 'react';

export type CommonLinkedDocument = CommonDocument & DirectoryContentDto & DocumentLinkDto;

export const mapDocumentLinkDtoToCommonLinkedDocument = (documentLink: DocumentLinkDto): CommonLinkedDocument => ({
  ...documentLink.linkedDocument,
  revision: documentLink.linkedDocument?.currentRevision,
  ...documentLink,
});

export type DocumentLinkRowProps = Omit<
  DocumentRowProps,
  'getDownloadUrl' | 'revisionNo' | 'revisionDate' | 'primaryFileContentType' | 'state'
> & {
  projectId: Guid;
  currentUser: ProjectUserProfileDto;
  linkedDocument?: DirectoryContentDto;
  linkedDiscardedDocument?: DiscardedDocumentDto;
  linkDirectory: DirectoryListDto;
  documentAccessLevel: AccessLevelEnum;
  getDownloadUrl: (documentId: Guid) => Promise<[ApiError, AxiosResponse<DownloadUrl>]>;
  reloadDocuments: () => void;
};

const DocumentLinkRow: FunctionComponent<InjectedIntlProps & DocumentLinkRowProps> = ({
  id,
  onThumbnailClick,
  getDownloadUrl,
  style,
  onReservationChange,
  onSignatureClick,
  disableReserve,
  onDocumentLinkClick,
  linkedDocument,
  linkedDiscardedDocument,
  reloadDocuments,
  linkDirectory,
  documentAccessLevel,
  projectId,
  currentUser,
  ...restProps
}) => {
  const [restoreDocumentForm, showRestoreDocumentForm, hideRestoreDocumentForm] = useBoolean(false);
  const hasDocumentAccess = isAccessLevelAtLeast(documentAccessLevel, AccessLevelEnum.read);
  const canEditLink = isAccessLevelAtLeast(linkDirectory.currentAccessLevel, AccessLevelEnum.write);
  const linkPath = `${!linkedDocument?.directoryPath.endsWith('/') ? linkedDocument?.directoryPath : ''}/${
    linkedDocument?.name
  }`;
  const intl = useIntl();

  const handleRestoreDocumentSubmit = () => {
    hideRestoreDocumentForm();
    reloadDocuments();
  };

  if (!linkedDocument) {
    return (
      <CommonDocumentRow
        id={id}
        style={style}
        onThumbnailClick={() => onThumbnailClick(linkedDocument.id)}
        documentRowType={DocumentRowType.DeletedDocumentLink}
        onDocumentLinkClick={onDocumentLinkClick}
        {...restProps}
      >
        <DocumentName
          documentName={restProps.name}
          tooltip={linkedDiscardedDocument.directoryPath + '/' + linkedDiscardedDocument.name}
        />
        <FlowLayout alignRight>
          <Alert type="warning" message={<Fmt id="DocumentLinkRow.missingDocument.error" />} showIcon />
          <CommonHubTooltip
            title={
              currentUser.isAdmin
                ? intl.formatMessage({ id: 'DocumentLinkRow.missingDocument.restoreTooltip' })
                : intl.formatMessage({ id: 'DocumentLinkRow.missingDocument.contactAdminTooltip' })
            }
          >
            <Button
              icon={<RedoOutlined />}
              size="small"
              type="default"
              shape="circle"
              onClick={showRestoreDocumentForm}
              disabled={!currentUser.isAdmin}
            />
          </CommonHubTooltip>
        </FlowLayout>
        <ErrorBoundary>
          <RestoreDocumentsFormModal
            selectedDocuments={[linkedDiscardedDocument.id]}
            open={restoreDocumentForm}
            onSubmit={handleRestoreDocumentSubmit}
            onClose={hideRestoreDocumentForm}
          />
        </ErrorBoundary>
      </CommonDocumentRow>
    );
  }

  return (
    <DocumentRow
      id={id}
      style={style}
      onThumbnailClick={() => onThumbnailClick(linkedDocument.id)}
      nameLink={(hasDocumentAccess && `/projects/${projectId}/documents/${linkedDocument.id}`) || undefined} //TODO:wished Standa, to find out why, possibly to adjust
      labels={linkedDocument.labels}
      revisionNo={linkedDocument.currentRevision.number}
      revisionDate={linkedDocument.currentRevision.createdDate}
      primaryFileContentType={linkedDocument.primaryFile?.contentType}
      disableDownload={!hasDocumentAccess}
      getDownloadUrl={() => getDownloadUrl(linkedDocument.id)}
      disableReserve={disableReserve}
      reservedBy={!linkedDocument.reservedBy ? undefined : linkedDocument.reservedBy}
      reservedDate={linkedDocument.reservedDate}
      reservationDisabled={
        !currentUser.isAdmin &&
        ((!!linkedDocument.reservedDate && currentUser.id !== linkedDocument.reservedBy.id) ||
          !linkDirectory ||
          !isAccessLevelAtLeast(documentAccessLevel, AccessLevelEnum.write))
      }
      revisionUser={{ ...linkedDocument.currentRevision.createdBy }}
      state={linkedDocument.state}
      size={linkedDocument.primaryFile?.size}
      esticonObjectLink={linkedDocument.esticonObjectLink}
      documentOwner={linkedDocument.ownedBy}
      documentRowType={hasDocumentAccess ? DocumentRowType.Link : DocumentRowType.UnaccessibleLink}
      documentLinkTooltip={linkPath}
      onDocumentLinkClick={canEditLink && onDocumentLinkClick}
      {...restProps}
    />
  );
};

export default memoizeWithIntl(DocumentLinkRow);
