import {
  BookOutlined,
  ControlOutlined,
  NumberOutlined,
  PhoneOutlined,
  QuestionCircleOutlined,
  TeamOutlined,
  TrademarkOutlined,
} from '@ant-design/icons';

import { Button, Descriptions, MenuProps, Modal, Tag } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { api } from 'api';
import { masterApi } from 'api/completeApi';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import EmptyStyled from 'components/Empty/EmptyStyled';
import { HeaderDropdown } from 'components/HeaderDropdown';
import { LinkExternalUnderscored } from 'components/LinkUnderscored/LinkExternalUnderscored';
import HubModal from 'components/Modal/Modal';
import ServiceErrorBox from 'components/ServiceErrorBox';
import SpinBoxCenter from 'components/SpinBoxCenter';
import StackPanel from 'components/StackPanel';
import { ENV } from 'config/env';
import { useApiData, useCurrentAppUser, useIntl } from 'hooks';
import { Fmt } from 'locale';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './HeaderInfoDropdown.module.less';

const VERSION_URL = '/_version.json';
const USER_DOC_SRC = 'https://aspehubdocs.blob.core.windows.net/help/AspeHub_Uzivatelska_dokumentace.pdf';
const TECHNICAL_DOC_SRC = 'https://aspehubdocs.blob.core.windows.net/help/AspeHub_Administratorska_dokumentace.pdf';
const MD_MANUAL_DOC_SRC = 'https://aspehubdocs.blob.core.windows.net/help/AspeHub_MD_uzivatelska_prirucka.pdf';

export type HeaderInfoDropdownProps = { className?: string };

const KeyTag: FunctionComponent = ({ children }) => <Tag className={styles.noMargin}>{children}</Tag>;
const KeyTagsGroup: FunctionComponent = ({ children }) => <div className={styles.keyTagsGroup}>{children}</div>;
const KeyShortcutItem: FunctionComponent = ({ children }) => <div className={styles.keyShortcutItem}>{children}</div>;

const HeaderInfoDropdown: FunctionComponent<HeaderInfoDropdownProps> = ({ className }) => {
  const [licenceVisible, setLicenceVisible] = useState<boolean>(false);
  const [userDocumentationVisible, setUserDocumentationVisible] = useState(false);
  const [technicalDocumentationVisible, setTechnicalDocumentationVisible] = useState(false);
  const [mdDocumentationVisible, setMdDocumentationVisible] = useState(false);
  const [versionVisible, setVersionVisible] = useState(false);
  const [shortCutsVisible, setShortCutsVisible] = useState(false);
  const [gdprVisible, setGdprVisible] = useState(false);
  const [contactsVisible, setContactsVisible] = useState(false);

  const handleCancelLicence = useCallback(() => setLicenceVisible(false), []);
  const handleCancelUserDocumentation = useCallback(() => setUserDocumentationVisible(false), []);
  const handleCancelTechnicalDocumentation = useCallback(() => setTechnicalDocumentationVisible(false), []);
  const handleCancelMDDocumentation = useCallback(() => setMdDocumentationVisible(false), []);
  const handleCancelVersion = useCallback(() => setVersionVisible(false), []);
  const handleCancelShortCuts = useCallback(() => setShortCutsVisible(false), []);
  const handleCancelGdpr = useCallback(() => setGdprVisible(false), []);
  const handleCancelContacts = useCallback(() => setContactsVisible(false), []);

  const [versionJson, setVersionJson] = useState({ version: '', releaseDate: '' });
  const intl = useIntl();

  const [licenceInfo, licenceInfoError, licenceInfoLoading, loadLicenceInfo] = useApiData(
    api.master.projects.getUsersLicenceInfo
  );

  const handleClickLicence = () => {
    setLicenceVisible(true);
    loadLicenceInfo();
  };
  const handleClickUserDocumentation = () => setUserDocumentationVisible(true);
  const handleClickTechnicalDocumentation = () => setTechnicalDocumentationVisible(true);
  const handleClickMdDocumentation = () => setMdDocumentationVisible(true);
  const handleClickVersion = () => setVersionVisible(true);
  const handleClickShortCuts = () => setShortCutsVisible(true);
  const handleClickGdpr = () => setGdprVisible(true);
  const handleClickContacts = () => setContactsVisible(true);

  const currentAppUser = useCurrentAppUser();
  const currentUserMdOrganizationId = useMemo(
    () => currentAppUser.organizationUsers?.find((orgUser) => orgUser.organization.hasMdModule)?.organization.id,
    [currentAppUser]
  );
  const [mdCurrentUser, mdCurrentUserError, mdCurrentUserLoading, loadMdCurrentUser] = useApiData(
    (ct) => masterApi.projects.md.user.id.me.get(currentUserMdOrganizationId, ct),
    { autoload: false }
  );

  useEffect(() => {
    currentUserMdOrganizationId && loadMdCurrentUser();
  }, [currentUserMdOrganizationId]);

  const menu = useMemo((): MenuProps => {
    const items: ItemType[] = [
      {
        key: 'documentationInfo',
        label: intl.formatMessage({ id: 'HeaderInfoDropdown.Documentation.title' }),
        icon: <BookOutlined />,
        children: [
          {
            key: 'userDocumentationInfo',
            label: intl.formatMessage({ id: 'HeaderInfoDropdown.UserDocumentation.title' }),
            onClick: handleClickUserDocumentation,
          },
          {
            key: 'technicalDocumentationInfo',
            label: intl.formatMessage({ id: 'HeaderInfoDropdown.TechnicalDocumentation.title' }),
            onClick: handleClickTechnicalDocumentation,
          },
          !!mdCurrentUser && {
            key: 'mdDocumentationInfo',
            label: intl.formatMessage({ id: 'HeaderInfoDropdown.MDDocumentation.title' }),
            onClick: handleClickMdDocumentation,
          },
        ].filter(Boolean),
      },
      {
        key: 'contactInfo',
        label: intl.formatMessage({ id: 'HeaderInfoDropdown.Contacts.title' }),
        icon: <PhoneOutlined />,
        onClick: handleClickContacts,
      },
      {
        key: 'versionInfo',
        label: intl.formatMessage({ id: 'HeaderInfoDropdown.Version.title' }),
        icon: <NumberOutlined />,
        onClick: handleClickVersion,
      },
      {
        key: 'licenceInfo',
        label: intl.formatMessage({ id: 'general.help.licence' }),
        icon: <TrademarkOutlined />,
        onClick: handleClickLicence,
      },
      {
        key: 'keyboardShortcuts',
        label: intl.formatMessage({ id: 'HeaderInfoDropdown.ShortCuts.title' }),
        icon: <ControlOutlined />,
        onClick: handleClickShortCuts,
      },
      {
        key: 'gdpr',
        label: intl.formatMessage({ id: 'HeaderInfoDropdown.GDPR.title' }),
        icon: <TeamOutlined />,
        onClick: handleClickGdpr,
      },
    ];
    return { items };
  }, [handleClickLicence, intl, mdCurrentUser]);

  const loading = licenceInfoLoading || (!licenceInfoError && !licenceInfo);

  useEffect(() => {
    if (ENV.NODE_ENV !== 'development') {
      fetch(VERSION_URL, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then((response) => response.json())
        .then(setVersionJson);
    }
  }, []);

  return (
    <>
      <HeaderDropdown menu={menu} placement="bottomRight" trigger={['click']}>
        <CommonHubTooltip title={intl.formatMessage({ id: 'general.help' })} placement="bottomLeft">
          <Button type="link" icon={<QuestionCircleOutlined />} />
        </CommonHubTooltip>
      </HeaderDropdown>

      <Modal
        title={<Fmt id="HeaderInfoDropdown.Licence.title" />}
        centered
        open={licenceVisible}
        onCancel={handleCancelLicence}
        footer={[
          <Button type="primary" key="ok" onClick={handleCancelLicence}>
            <Fmt id="general.close" />
          </Button>,
        ]}
      >
        {loading && (
          <SpinBoxCenter tip={intl.formatMessage({ id: 'general.loading' })}>
            <EmptyStyled />
          </SpinBoxCenter>
        )}
        {licenceInfoError && !loading && <ServiceErrorBox error={licenceInfoError} />}
        {!licenceInfoError && !loading && (
          <Descriptions column={1}>
            <Descriptions.Item label={<Fmt id="HeaderInfoDropdown.Licence.used" />}>
              {licenceInfo.licences - licenceInfo.freeLicences}
            </Descriptions.Item>
            <Descriptions.Item label={<Fmt id="HeaderInfoDropdown.Licence.remaining" />}>
              {licenceInfo.freeLicences}
            </Descriptions.Item>
            <Descriptions.Item label={<Fmt id="HeaderInfoDropdown.Licence.validTo" />}>
              {moment(licenceInfo.validTo).format('L')}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Modal>
      <HubModal
        title={
          <LinkExternalUnderscored href={USER_DOC_SRC} target="_blank">
            <Fmt id="HeaderInfoDropdown.UserDocumentation.title" />
          </LinkExternalUnderscored>
        }
        visible={userDocumentationVisible}
        onCancel={handleCancelUserDocumentation}
      >
        <StackPanel stretch>
          <embed className={styles.stretch} src={USER_DOC_SRC} datatype="application/pdf" />
        </StackPanel>
      </HubModal>
      <HubModal
        title={
          <LinkExternalUnderscored href={TECHNICAL_DOC_SRC} target="_blank">
            <Fmt id="HeaderInfoDropdown.TechnicalDocumentation.title" />
          </LinkExternalUnderscored>
        }
        visible={technicalDocumentationVisible}
        onCancel={handleCancelTechnicalDocumentation}
      >
        <StackPanel stretch>
          <embed className={styles.stretch} src={TECHNICAL_DOC_SRC} datatype="application/pdf" />
        </StackPanel>
      </HubModal>
      {mdDocumentationVisible && !!mdCurrentUser && (
        <HubModal
          title={
            <LinkExternalUnderscored href={MD_MANUAL_DOC_SRC} target="_blank">
              <Fmt id="HeaderInfoDropdown.MDDocumentation.title" />
            </LinkExternalUnderscored>
          }
          visible={mdDocumentationVisible}
          onCancel={handleCancelMDDocumentation}
        >
          <StackPanel stretch>
            <embed className={styles.stretch} src={MD_MANUAL_DOC_SRC} datatype="application/pdf" />
          </StackPanel>
        </HubModal>
      )}
      <Modal
        title={<Fmt id="HeaderInfoDropdown.Version.title" />}
        centered
        open={versionVisible}
        onCancel={handleCancelVersion}
        footer={[
          <Button type="primary" key="ok" onClick={handleCancelVersion}>
            <Fmt id="general.close" />
          </Button>,
        ]}
      >
        {versionJson && (
          <Descriptions column={1}>
            <Descriptions.Item label={<Fmt id="HeaderInfoDropdown.Version.number" />}>
              {versionJson.version ? versionJson.version : <Fmt id="HeaderInfoDropdown.Version.NoData" />}
            </Descriptions.Item>
            <Descriptions.Item label={<Fmt id="HeaderInfoDropdown.Version.builtDate" />}>
              {moment(versionJson.releaseDate).isValid() ? (
                `${moment(versionJson.releaseDate)
                  .locale(intl.locale)
                  .format('LLL')} (${versionJson.releaseDate})`
              ) : (
                <Fmt id="HeaderInfoDropdown.Version.NoData" />
              )}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Modal>
      <Modal
        title={<Fmt id="HeaderInfoDropdown.ShortCuts.title" />}
        centered
        open={shortCutsVisible}
        onCancel={handleCancelShortCuts}
        footer={[
          <Button type="primary" key="ok" onClick={handleCancelShortCuts}>
            <Fmt id="general.close" />
          </Button>,
        ]}
      >
        <h4>
          <Fmt id="HeaderInfoDropdown.ShortCuts.DocumentPreview" />
        </h4>
        <div>
          <KeyShortcutItem>
            <KeyTagsGroup>
              <KeyTag>Esc</KeyTag>
            </KeyTagsGroup>
            <Fmt id="HeaderInfoDropdown.ShortCuts.ESC" />
          </KeyShortcutItem>
          <KeyShortcutItem>
            <KeyTagsGroup>
              <KeyTag>Shift</KeyTag> + <KeyTag>→</KeyTag>
            </KeyTagsGroup>
            <Fmt id="HeaderInfoDropdown.ShortCuts.next" />
          </KeyShortcutItem>
          <KeyShortcutItem>
            <KeyTagsGroup>
              <KeyTag>Shift</KeyTag> + <KeyTag>←</KeyTag>
            </KeyTagsGroup>
            <Fmt id="HeaderInfoDropdown.ShortCuts.previous" />
          </KeyShortcutItem>
        </div>
        <h4>
          <Fmt id="HeaderInfoDropdown.ShortCuts.DocumentsList" />
        </h4>
        <div>
          <KeyShortcutItem>
            <KeyTagsGroup>
              <KeyTag>Shift</KeyTag> +{' '}
              <KeyTag>
                <Fmt id="HeaderInfoDropdown.ShortCuts.MouseLeftClick" />
              </KeyTag>
            </KeyTagsGroup>
            <Fmt id="HeaderInfoDropdown.ShortCuts.ContinuousSelection" />
          </KeyShortcutItem>
        </div>
        <h4>
          <Fmt id="HeaderInfoDropdown.ShortCuts.ModelWorking" />
        </h4>
        <div>
          <KeyShortcutItem>
            <KeyTagsGroup>
              <KeyTag>Shift</KeyTag> +{' '}
              <KeyTag>
                <Fmt id="HeaderInfoDropdown.ShortCuts.MouseLeftClick" />
              </KeyTag>
            </KeyTagsGroup>
            <Fmt id="HeaderInfoDropdown.ShortCuts.MultipleModelElementsSelect" />
          </KeyShortcutItem>
        </div>
      </Modal>
      <Modal
        title={<Fmt id="HeaderInfoDropdown.GDPR.title" />}
        centered
        open={gdprVisible}
        onCancel={handleCancelGdpr}
        footer={[
          <Button type="primary" key="ok" onClick={handleCancelGdpr}>
            <Fmt id="general.close" />
          </Button>,
        ]}
      >
        <p>
          <Fmt id="HeaderInfoDropdown.GDPR.text" />
        </p>
      </Modal>
      <Modal
        title={<Fmt id="HeaderInfoDropdown.Contacts.title" />}
        centered
        open={contactsVisible}
        onCancel={handleCancelContacts}
        footer={[
          <Button type="primary" key="ok" onClick={handleCancelContacts}>
            <Fmt id="general.close" />
          </Button>,
        ]}
      >
        <h4>
          <Fmt id="HeaderInfoDropdown.Contacts.webPageTitle" />
        </h4>
        <p>
          <a href="https://www.aspe.cz/cs" target="_blank" rel="noreferrer">
            www.aspe.cz
          </a>
        </p>
        <h4>
          <Fmt id="HeaderInfoDropdown.Contacts.technicalSupport" />
        </h4>
        <address>
          <ul>
            <li>
              <Fmt id="HeaderInfoDropdown.Contacts.phone" /> +420 603 807 181
            </li>
            <li>
              <Fmt id="HeaderInfoDropdown.Contacts.mail" /> servis@aspe.cz
            </li>
          </ul>
        </address>
      </Modal>
    </>
  );
};
export default HeaderInfoDropdown;
