import { Form, Input, InputRef } from 'antd';
import StackPanel from 'components/StackPanel';
import { MAX_ITEM_NAME_LENGTH } from 'config/constants';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FC } from 'react';
import { maxLengthRule, requiredRule } from 'utils/formHelpers';
import { KPIConfiguration, KpiRule } from './KpiConfigurationForm.utils';
import { KpiRuleConfigurationFormItem } from './KpiRuleConfigurationFormItem';

export type KpiConfigurationFormData = {
  title: string;
  rules: Record<string, KpiRule>;
};

type Props = {
  defaultConfiguration?: KPIConfiguration;
  availableColumns: Record<string, IntlMessageId>;
  setRef: (ref: InputRef) => void;
};

const KpiConfigurationForm: FC<Props> = ({ availableColumns, defaultConfiguration, setRef }) => {
  const intl = useIntl();

  return (
    <>
      <StackPanel vertical>
        <Form.Item
          label={intl.formatMessage({ id: 'KpiConfigurationForm.title' })}
          name="title"
          rules={[
            requiredRule('KpiConfigurationForm.title.required', true),
            maxLengthRule('general.maxNameLength', MAX_ITEM_NAME_LENGTH),
          ]}
          initialValue={defaultConfiguration?.title}
        >
          <Input autoFocus ref={setRef} />
        </Form.Item>
      </StackPanel>
      <Form.Item
        label={<Fmt id="KpiConfigurationForm.columnRules" />}
        name="rules"
        initialValue={defaultConfiguration?.rules || {}}
      >
        <KpiRuleConfigurationFormItem intl={intl} availableColumns={availableColumns} />
      </Form.Item>
    </>
  );
};

export default KpiConfigurationForm;
