import { Alert, Button, Divider, Form, Progress, Typography } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { Margin } from 'components/Margin/Margin';
import SingleFileInput from 'components/PrimaryFileInput/SingleFileInput';
import StackPanel from 'components/StackPanel';
import { SIGNED_DOCUMENT_ACCEPTED_CONTENT_TYPES } from 'config/constants';
import { Fmt } from 'locale';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { getSignatures } from './RevisionSignedDocument.utils';

export type RevisionSignedDocumentFileCreateFormData = {
  signedDocument: File;
};

type Props = {
  signedDocumentLoading: boolean;
  signedDocumentBlob?: Blob;
  handleSignedFiledDownload: () => void;
  setSignedFileAnalyzed: (isAnalyzed: boolean) => void;
};

const RevisionSignedDocumentFileCreateForm: FC<Props> = ({
  signedDocumentLoading,
  signedDocumentBlob,
  handleSignedFiledDownload,
  setSignedFileAnalyzed,
}) => {
  const [analyzeProgress, setAnalyzeProgress] = useState<number>(undefined);
  const [showInvalidFileType, setShowInvalidFileType] = useState<boolean>();
  const [showMissingSignatureWarning, setMissingSignatureWarning] = useState<boolean>();
  const [showUnexpectedCountWarning, setUnexpectedCountWarning] = useState<boolean>();

  const signedDocument = useWatch<File>('signedDocument');

  const compareSignatures = useCallback(
    async (signedFile: File) => {
      if (!signedDocumentBlob) {
        setAnalyzeProgress(100);
        setSignedFileAnalyzed(true);
        return;
      }
      setAnalyzeProgress(1);
      const fileBuffer = await signedFile.arrayBuffer();
      setAnalyzeProgress(50);
      const originalSignedFileBuffer = await signedDocumentBlob.arrayBuffer();
      const newSignatures = getSignatures(Buffer.from(fileBuffer));
      const existingSignatures = getSignatures(Buffer.from(originalSignedFileBuffer));

      setMissingSignatureWarning(
        existingSignatures.some(
          (signature) =>
            !newSignatures.find((newSignature) => newSignature.signatureString === signature.signatureString)
        )
      );
      setUnexpectedCountWarning(newSignatures.length <= existingSignatures.length);
      setAnalyzeProgress(100);
      setSignedFileAnalyzed(true);
    },
    [setSignedFileAnalyzed, signedDocumentBlob]
  );

  useEffect(() => {
    setSignedFileAnalyzed(false);
    if (signedDocument) {
      void compareSignatures(signedDocument);
    } else {
      setAnalyzeProgress(undefined);
      setMissingSignatureWarning(false);
      setUnexpectedCountWarning(false);
    }
  }, [compareSignatures, signedDocument]);

  const handleInvalidFileDrop = useCallback(() => {
    setShowInvalidFileType(true);
    setTimeout(() => setShowInvalidFileType(false), 3000);
  }, []);

  return (
    <>
      <StackPanel justifyContent="splitContent">
        <Typography.Paragraph>
          <Fmt id="RevisionSignedDocumentAddFormModal.downloadSignedDocumentMessage" />
        </Typography.Paragraph>
        <Button
          type="primary"
          loading={signedDocumentLoading}
          disabled={!signedDocumentBlob}
          onClick={handleSignedFiledDownload}
        >
          <Fmt id="general.download" />
        </Button>
      </StackPanel>
      <Divider />
      <Form.Item name="signedDocument">
        <SingleFileInput
          inputText="SignedDocumentInput.collapse.text"
          acceptedContentType={SIGNED_DOCUMENT_ACCEPTED_CONTENT_TYPES}
          onInvalidFileDrop={handleInvalidFileDrop}
        />
      </Form.Item>
      {analyzeProgress && (
        <Margin bottom>
          <StackPanel vertical>
            {analyzeProgress === 100 ? (
              <Fmt id="RevisionSignedDocumentAddFormModal.analyzeFinished" />
            ) : (
              <Fmt id="RevisionSignedDocumentAddFormModal.analyzeRunning" />
            )}
            <Progress percent={analyzeProgress} />
          </StackPanel>
        </Margin>
      )}
      {showMissingSignatureWarning ? (
        <Margin bottom>
          <Alert message={<Fmt id="RevisionSignedDocumentAddFormModal.missingSignatureWarning" />} type="warning" />
        </Margin>
      ) : (
        showUnexpectedCountWarning && (
          <Margin bottom>
            <Alert message={<Fmt id="RevisionSignedDocumentAddFormModal.unexpectedCountWraning" />} type="warning" />
          </Margin>
        )
      )}
      {showInvalidFileType && (
        <Margin bottom>
          <Alert message={<Fmt id="RevisionSignedDocumentAddFormModal.invalidFileType" />} type="error" />
        </Margin>
      )}
      {!!signedDocumentBlob && (
        <Margin bottom>
          <Alert message={<Fmt id="RevisionSignedDocumentAddFormModal.replaceNotification" />} type="warning" />
        </Margin>
      )}
    </>
  );
};

export default RevisionSignedDocumentFileCreateForm;
