import { InputRef } from 'antd';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import { useFocus } from 'hooks/useFocus';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent, useCallback } from 'react';
import uuid from 'uuid';
import KpiConfigurationForm, { KpiConfigurationFormData } from './KpiConfigurationForm';
import { KPIConfiguration } from './KpiConfigurationForm.utils';

type Props = FormModalProps<KPIConfiguration> & {
  configuration?: KPIConfiguration;
  availableColumns: Record<string, IntlMessageId>;
};

const KpiConfigurationFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, configuration, availableColumns, ...restProps } = props;

  const handleSubmit: FormSubmitHandler<KpiConfigurationFormData> = useCallback(
    async (values) => {
      const data: KPIConfiguration = {
        id: configuration?.id || uuid(),
        title: values.title,
        rules: values.rules,
      };

      await onSubmit(data);
      return null;
    },
    [onSubmit, configuration]
  );

  const { setInputRef } = useFocus<InputRef>(restProps.open);

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId={configuration?.id ? 'KpiConfigurationFormModal.title.edit' : 'KpiConfigurationFormModal.title.create'}
      submitTextId={configuration?.id ? 'general.edit' : 'general.create'}
      {...restProps}
    >
      <KpiConfigurationForm
        defaultConfiguration={configuration}
        availableColumns={availableColumns}
        setRef={setInputRef}
      />
    </FormModalWrapper>
  );
};

export default KpiConfigurationFormModal;
