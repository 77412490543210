import { masterApi } from 'api/completeApi';
import { OrgEstiConnDto, OrgUserDto } from 'api/completeApiInterfaces';
import { EditButton } from 'components/ActionButtons';
import { DeleteButton } from 'components/DeleteButton/DeleteButton';
import { FormModalWrapper } from 'components/forms/FormModalWrapper';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import StackPanel from 'components/StackPanel';
import { EMPTY_GUID } from 'config/constants';
import { useBoolean, useDeleteItems, useIntl } from 'hooks';
import { IntlMessageId } from 'locale/messages/cs';
import Panel from 'pages/ProjectSettingsPage/Panel/Panel';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { messageError, smartFilter } from 'utils';
import AspeEsticonAddForm, { AspeEsticonFormData } from './AspeEsticonAddForm';
import AspeEsticonPermissionPanel from './AspeEsticonPermissionPanel';

type Props = {
  titleId?: IntlMessageId;
  panelWidth?: number | string;
  organizationUsers?: OrgUserDto[];
  selectedOrganizationId: Guid;
  setOrgEsticons: React.Dispatch<React.SetStateAction<OrgEstiConnDto[]>>;
  orgEsticons: OrgEstiConnDto[];
  isAspeHubAdmin: boolean;
};

const AspeEsticonListPanel: FunctionComponent<Props> = ({
  selectedOrganizationId,
  orgEsticons = [],
  setOrgEsticons,
  titleId,
  isAspeHubAdmin,
}) => {
  const [search, setSearch] = useState<string>('');
  const { url } = useRouteMatch();
  const intl = useIntl();
  const [aspeEsticonFormModalVisible, showAspeEsticonFormModal, hideAspeEsticonFormModal] = useBoolean(false);
  const [editingAspeEsticon, setEditingAspeEsticon] = useState<OrgEstiConnDto>(null);

  const addAspeEsticon = useCallback(
    async (values: AspeEsticonFormData) => {
      if (!!values.id) {
        const [error, resp] = await masterApi.EsticonReports.org.id.estiConn.id.firms.id.getallfirmss.get(
          editingAspeEsticon.id,
          editingAspeEsticon.organization.id,
          editingAspeEsticon.defaultFirmId
        );

        if (error) {
          messageError(error, intl);
          return null;
        } else {
          const [err, data] = await masterApi.projects.admin.esticonn.patch({
            id: values.id,
            name: values.name,
            isDefaultUrl: true,
            defaultFirmId: editingAspeEsticon.defaultFirmId,
            permittedFirmIds: null,
          });
          if (err) {
            hideAspeEsticonFormModal();
            return err;
          }

          setOrgEsticons(data.data);
          hideAspeEsticonFormModal();
          return null;
        }
      }

      const [err, data] = await masterApi.projects.admin.esticonn.post({
        organizationId: selectedOrganizationId,
        name: values.name,
        isDefaultUrl: true,
        defaultFirmId: EMPTY_GUID,
        url: values.url,
      });
      if (err) return err;
      hideAspeEsticonFormModal();
      setOrgEsticons(data.data);
      return null;
    },

    [selectedOrganizationId, editingAspeEsticon]
  );

  const onEdit = useCallback(
    (aspeEsticonId: Guid) => {
      const aspeEsticon = orgEsticons.find((item) => item.id === aspeEsticonId) || null;
      setEditingAspeEsticon(aspeEsticon);

      showAspeEsticonFormModal();
    },
    [orgEsticons]
  );

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const handleDelete = (id: Guid) => {
    setOrgEsticons((data) => data.filter((item) => item.id !== id));
  };

  const [deletingLinks, deleteLink] = useDeleteItems(
    intl,
    (id) => masterApi.projects.admin.esticonn.id.id.delete(selectedOrganizationId, id),
    handleDelete,
    true,
    'AspeEsticonListPanel.delete.confirmTitle'
  );

  const renderContent = (onSelect: (itemId: Guid) => void, selectedId: Guid) => {
    return (
      <GeneralSettingsContainer>
        <List<OrgEstiConnDto>
          data={orgEsticons || []}
          search={search}
          renderEmpty={(total, filtered) => <ListEmpty filtered={filtered} total={total} onClearSearch={clearSearch} />}
          filterItem={(item) => item && smartFilter(item?.name, search)}
          renderItem={(item) => (
            <GeneralSettingsItem
              title={item.name}
              key={item.id}
              description={
                <>
                  <p>{item.url}</p>
                </>
              }
              onClick={onSelect ? () => onSelect(item.id) : null}
              selected={item.id === selectedId}
              loading={!!deletingLinks.has(item.id)}
              additionalActions={
                <>
                  <EditButton onClick={() => onEdit(item.id)} />
                  <DeleteButton type="link" onDelete={() => deleteLink(item.id)} disabled={!isAspeHubAdmin} />
                </>
              }
              selectable
            />
          )}
        />
      </GeneralSettingsContainer>
    );
  };

  return (
    <>
      <MasterComponent
        maxWidth={600}
        url={url}
        title={intl.formatMessage({ id: 'AspeEsticonListPanel.title' })}
        children={(onSelect, selectedItemId) => (
          <StackPanel>
            <Panel
              noMargin
              onSearch={setSearch}
              searchValue={search}
              addButtonText={intl.formatMessage({ id: 'AspeEsticonListPanel.add.title' })}
              addButtonOnClick={() => {
                setEditingAspeEsticon(null);
                showAspeEsticonFormModal();
              }}
              addButtonDisabled={!isAspeHubAdmin}
            >
              {!!titleId && intl.formatMessage({ id: titleId })}
              {renderContent(onSelect, selectedItemId)}
            </Panel>
          </StackPanel>
        )}
      />
      <Switch>
        <Route
          path={`${url}/:orgEsticonId`}
          render={() => (
            <AspeEsticonPermissionPanel
              intl={undefined}
              organizationId={selectedOrganizationId}
              orgEsticons={orgEsticons}
              setOrgEsticons={setOrgEsticons}
              isAspeHubAdmin={isAspeHubAdmin}
            />
          )}
        />
      </Switch>
      {aspeEsticonFormModalVisible && (
        <FormModalWrapper
          title={intl.formatMessage({
            id: !!editingAspeEsticon ? 'AspeEsticonListPanel.edit.title' : 'AspeEsticonListPanel.add.title',
          })}
          open={aspeEsticonFormModalVisible}
          onClose={hideAspeEsticonFormModal}
          onSubmit={addAspeEsticon}
        >
          <AspeEsticonAddForm editingItem={editingAspeEsticon} isAspeHubAdmin={isAspeHubAdmin} />
        </FormModalWrapper>
      )}
    </>
  );
};

AspeEsticonListPanel.displayName = 'AspeEsticonListPanel';

export default AspeEsticonListPanel;
