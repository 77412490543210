import { CheckOutlined, ClockCircleOutlined, FieldTimeOutlined, TagOutlined } from '@ant-design/icons';
import { Space, Tag, Typography } from 'antd';
import { AssignmentMessageDto, AssignmentStateEnum, ProjectUserProfileStatusEnum } from 'api/completeApiInterfaces';
import { AssignmentState } from 'components/AssignmentState/AssignmentState';
import { UserIcon } from 'components/avatars/UserIcon/UserIcon';
import { CommonHeader } from 'components/CommonHeader/CommonHeader';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { ProjectIcon } from 'components/Icons/HubEntitiesIcons';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import { SimpleOrderId } from 'components/SimpleOrderId/SimpleOrderId';
import StackPanel from 'components/StackPanel';
import { StyledBox } from 'components/StyledBox/StyledBox';
import { UserProfile } from 'components/UserDisplay/UserDisplay';
import { useCurrentProjectUser, useIntl } from 'hooks';
import { Fmt } from 'locale';
import moment from 'moment';
import { AssignmentNameField } from 'pages/MessageCenterPage/assignments/AssignmentDetail/AssignmentFields/AssignmentNameField';
import { useIsAfterDeadline } from 'pages/MessageCenterPage/assignments/useIsAfterDeadline';
import React, { FunctionComponent } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { textComparer } from 'utils/comparators';
import styles from './AssignmentPreview.module.less';

type Props = {
  assignment: AssignmentMessageDto;
  projectName: string;
};

export const AssignmentPreview: FunctionComponent<Props> = ({ assignment, projectName }) => {
  const currentProjectUserId = useCurrentProjectUser()?.id;
  const isAuthor =
    currentProjectUserId === assignment.createdBy.id ||
    assignment.associates.some((associate) => associate.id === currentProjectUserId) ||
    assignment.taskGiver?.id == currentProjectUserId;
  const isClosed = assignment.state === AssignmentStateEnum.closed || assignment.state === AssignmentStateEnum.solved;
  const intl = useIntl();
  const { url } = useRouteMatch();
  const isAfterDeadline = useIsAfterDeadline(assignment);

  const content = assignment?.data ? JSON.parse(assignment?.data)?.Content : undefined;

  return (
    <>
      <MasterComponent
        url={url}
        title={assignment?.name}
        maxWidth={null}
        children={(onClick, selectedItemId) => (
          <StackPanel vertical scrollable className={styles.container}>
            <StackPanel vertical autoHeight>
              <GeneralSettingsContainer itemsLargeGap>
                <GeneralSettingsContainer>
                  <StyledBox>
                    <CommonHeader
                      padding="all"
                      title={
                        <>
                          <SimpleOrderId orderId={assignment.orderId} />
                          <div className={styles.assignmentName}>
                            <AssignmentNameField canEdit={isAuthor} assignment={assignment} />
                          </div>
                        </>
                      }
                      buttons={
                        <Space>
                          <AssignmentState state={assignment?.state} />
                        </Space>
                      }
                    />

                    <FlowLayout wrap className={styles.projectName}>
                      <Typography.Text>
                        <ProjectIcon /> {projectName}
                      </Typography.Text>

                      <Tag>
                        <TagOutlined /> <Fmt id={`AssignmentTypeEnum.${assignment.type}`} />
                      </Tag>
                    </FlowLayout>
                  </StyledBox>
                  <div className={styles.itemsContainer}>
                    <GeneralSettingsItem
                      title={<Fmt id="AssignmentSourceEnum.taskGiver" />}
                      icon={<UserIcon user={assignment.taskGiver || assignment.createdBy} />}
                      description={
                        <Typography.Text ellipsis>
                          {assignment.taskGiver?.username || assignment.createdBy.username}
                        </Typography.Text>
                      }
                    />
                    <GeneralSettingsItem
                      title={<Fmt id="MessageCenterAssignments.createdDate" />}
                      icon={<ClockCircleOutlined />}
                      description={moment(assignment.createdDate)
                        .locale(intl.locale)
                        .format('LL')}
                    />
                  </div>
                  <div className={styles.itemsContainer}>
                    <GeneralSettingsItem
                      title={
                        <Typography.Text strong type={isAfterDeadline ? 'danger' : undefined}>
                          <Fmt id="MessageCenterAssignments.deadlineDate" />:
                        </Typography.Text>
                      }
                      icon={<FieldTimeOutlined />}
                      description={moment(assignment.deadlineDate)
                        .locale(intl.locale)
                        .format('LL')}
                    />
                    {assignment.resolveDate && (
                      <GeneralSettingsItem
                        title={<Fmt id="MessageCenterAssignments.resolvedDate" />}
                        icon={<CheckOutlined />}
                        description={moment(assignment.resolveDate)
                          .locale(intl.locale)
                          .format('LL')}
                      />
                    )}
                  </div>
                </GeneralSettingsContainer>

                <GeneralSettingsContainer title={<Fmt id="AssignmentForm.content" />}>
                  {content ? (
                    <GeneralSettingsItem
                      title={
                        <p
                          style={{
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word',
                            overflowWrap: 'break-word',
                          }}
                        >
                          {content}
                        </p>
                      }
                    />
                  ) : (
                    <Typography.Text italic type="secondary">
                      {intl.formatMessage({ id: 'general.emptyItemValue' })}
                    </Typography.Text>
                  )}
                </GeneralSettingsContainer>

                {(!isClosed || !!assignment.associates?.length) && (
                  <GeneralSettingsContainer
                    title={
                      <FlowLayout growFirst>
                        <Fmt id="AssignmentForm.associateIds" />
                      </FlowLayout>
                    }
                  >
                    {assignment.associates
                      .sort(textComparer.map((associate) => associate.username))
                      .map((associate) => (
                        <GeneralSettingsItem
                          key={associate.id}
                          title={<UserProfile user={{ ...associate, status: ProjectUserProfileStatusEnum.active }} />}
                        />
                      ))}
                  </GeneralSettingsContainer>
                )}

                <GeneralSettingsContainer
                  title={
                    <FlowLayout growFirst>
                      <Fmt id="AssignmentForm.solverIds" />
                    </FlowLayout>
                  }
                >
                  {assignment.solvers.sort(textComparer.map((solver) => solver.username)).map((solver) => (
                    <GeneralSettingsItem
                      key={solver.id}
                      title={<UserProfile user={{ ...solver, status: ProjectUserProfileStatusEnum.active }} />}
                      additionalActions={
                        <>
                          {solver.id === assignment.primarySolver?.id && (
                            <Typography.Text strong>
                              <Fmt id="AssignmentSolversField.primarySolver" />
                            </Typography.Text>
                          )}
                        </>
                      }
                    />
                  ))}
                </GeneralSettingsContainer>
              </GeneralSettingsContainer>
            </StackPanel>
          </StackPanel>
        )}
      />
    </>
  );
};
