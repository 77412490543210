import { Button, Checkbox, Col, DatePicker, Form, Input, Row } from 'antd';
import { CalendarDto, JSONVariableTypeEnum } from 'api/completeApiInterfaces';
import { DeleteButton } from 'components/ActionButtons';
import { getNextWorkingDay, isWorkingDay } from 'components/forms/CalendarSettingsForm/CalendarSettingsForm.utils';
import { Margin } from 'components/Margin/Margin';
import { Fmt } from 'locale';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { JSONVariableCkApprovalConditions } from '../JSONVariableTypes';
import styles from './JSONVariableConditionsFormItem.module.less';

export type JSONVariableCkConditionsProps = {
  dataType: JSONVariableTypeEnum.ckApprovalConditions;
  value?: JSONVariableCkApprovalConditions;
  onChange?: (value: JSONVariableCkApprovalConditions) => void;
  canEditAsCKOrganizer: boolean;
  canEditAsGuarantor: boolean;
  activeCalendar: CalendarDto;
  disabled?: boolean;
  hideGuarantorResponse?: boolean;
};

export type MDCkApprovalCondition = {
  Condition: string;
  Deadline?: IsoDateTime;
  ApprovalDone?: boolean;
  ApprovalDescription?: string;
  DeadlineSetAt?: IsoDateTime; // for Be evaluation of messages, set to now() when deadline changes
  MsgSentInfo?: number; // for Be evaluation of messages, reset to 0 after deadline change
  OrderId?: number;
};

const defaultCondition: MDCkApprovalCondition = {
  Condition: '',
  ApprovalDescription: '',
  ApprovalDone: undefined,
  Deadline: '',
  DeadlineSetAt: undefined,
  MsgSentInfo: 0,
  OrderId: 0,
};

const MAX_TEXTAREA_ROWS = { maxRows: 6 };

const JSONVariableCkConditionsFormItem: FunctionComponent<JSONVariableCkConditionsProps> = ({
  value,
  onChange,
  canEditAsCKOrganizer,
  canEditAsGuarantor,
  activeCalendar,
  disabled,
  hideGuarantorResponse,
}) => {
  const [currentOrder, setCurrentOrder] = useState<number>(
    value?.value?.length ? Math.max(...value.value.map((condition) => condition.OrderId || 0)) : 0
  );
  const handleCreateNewCondition = useCallback(() => {
    onChange &&
      onChange({
        type: JSONVariableTypeEnum.ckApprovalConditions,
        value: [...(value?.value || []), { ...defaultCondition, OrderId: currentOrder + 1 }],
      });
    setCurrentOrder((currentOrder) => currentOrder + 1);
  }, [value, onChange, currentOrder]);

  const rowChangeHandle = useCallback(
    (property: keyof MDCkApprovalCondition, rowIndex: number, newValue: any) => {
      const isDeadlineChanged = property === 'Deadline' && value?.value[rowIndex]?.Deadline !== newValue;
      const notificationData: Pick<MDCkApprovalCondition, 'DeadlineSetAt' | 'MsgSentInfo'> = {
        DeadlineSetAt: isDeadlineChanged
          ? moment()
              .utc()
              .toISOString()
          : value?.value[rowIndex]?.DeadlineSetAt,
        MsgSentInfo: isDeadlineChanged ? 0 : value?.value[rowIndex]?.MsgSentInfo,
      };

      onChange({
        type: JSONVariableTypeEnum.ckApprovalConditions,
        value: [
          ...(value?.value.map((condition, index) =>
            rowIndex === index ? { ...condition, [property]: newValue, ...notificationData } : condition
          ) || []),
        ],
      });
    },
    [onChange, value]
  );

  const rowDeleteHandle = useCallback(
    (rowIndex: number) => {
      onChange({
        type: JSONVariableTypeEnum.ckApprovalConditions,
        value: [...(value?.value.filter((condition, index) => index !== rowIndex) || [])],
      });
    },
    [onChange, value]
  );

  return (
    <>
      {value?.value.map((condition, index) => (
        <Margin bottom key={index}>
          <Row gutter={8}>
            <Col span={16}>
              <Form.Item
                label={<Fmt id="MD.ProjectVariableCondition.condition.title" />}
                labelAlign="left"
                className={styles.conditionLabel}
              >
                <Input.TextArea
                  value={condition.Condition}
                  onChange={(e) => rowChangeHandle('Condition', index, e.target.value)}
                  disabled={!canEditAsCKOrganizer || disabled}
                  autoSize={MAX_TEXTAREA_ROWS}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={<Fmt id="MD.ProjectVariableCondition.deadline.title" />}
                labelAlign="left"
                className={styles.conditionLabel}
              >
                <DatePicker
                  value={condition.Deadline && moment(condition.Deadline)}
                  onChange={(date) =>
                    rowChangeHandle(
                      'Deadline',
                      index,
                      getNextWorkingDay(date, activeCalendar)
                        ?.endOf('day')
                        .utc()
                        .toISOString()
                    )
                  }
                  className={styles.wideSelect}
                  disabled={!canEditAsCKOrganizer || disabled}
                  disabledDate={(current) => !isWorkingDay(current, activeCalendar)}
                />
              </Form.Item>
            </Col>
            <Col span={2} className={styles.deleteButton}>
              <DeleteButton onClick={() => rowDeleteHandle(index)} disabled={!canEditAsCKOrganizer || disabled} />
            </Col>
          </Row>
          {!hideGuarantorResponse && (
            <Row gutter={8}>
              <Col span={16}>
                <Form.Item
                  label={<Fmt id="MD.ProjectVariableCondition.approvalDescription.title" />}
                  labelAlign="left"
                  className={styles.conditionLabel}
                >
                  <Input.TextArea
                    value={condition.ApprovalDescription}
                    onChange={(e) => rowChangeHandle('ApprovalDescription', index, e.target.value)}
                    disabled={!canEditAsGuarantor || disabled}
                    autoSize={MAX_TEXTAREA_ROWS}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={<Fmt id="MD.ProjectVariableCondition.approvalDone.title" />}
                  labelAlign="left"
                  className={styles.conditionLabel}
                >
                  <Checkbox
                    checked={condition.ApprovalDone}
                    onChange={(e) => rowChangeHandle('ApprovalDone', index, e.target.checked)}
                    disabled={!canEditAsGuarantor || disabled}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Margin>
      ))}
      <Margin top>
        <Row gutter={24}>
          <Col span={14}>
            <Button
              onClick={handleCreateNewCondition}
              type="primary"
              block
              disabled={!canEditAsCKOrganizer || disabled}
            >
              <Fmt id="MD.ProjectVariableCondition.addNewRule.button" />
            </Button>
          </Col>
        </Row>
      </Margin>
    </>
  );
};

export default JSONVariableCkConditionsFormItem;
