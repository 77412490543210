import { masterApi } from 'api/completeApi';
import { OrgWorkingPositionDto } from 'api/completeApiInterfaces';
import { useIntl } from 'hooks';
import { useEffect, useState } from 'react';
import { messageError } from 'utils';

export const useJobPositions = (selectedOrganizationId: Guid) => {
  const intl = useIntl();

  const [jobPositions, setJobPositions] = useState<OrgWorkingPositionDto[]>([]);

  useEffect(() => {
    const loadJobPositions = async () => {
      if (!selectedOrganizationId) return;
      const [err, data] = await masterApi.projects.reports.workingpositions.id.get(selectedOrganizationId);
      if (err) {
        messageError(err, intl);
      } else {
        if (data?.data?.orgWorkingPositions) setJobPositions(data.data.orgWorkingPositions);
      }
    };
    void loadJobPositions();
  }, [intl, selectedOrganizationId]);

  return [jobPositions, setJobPositions] as const;
};
