import { Form, Input } from 'antd';
import { DirectoryListDto } from 'api/completeApiInterfaces';
import { DirectoriesTreeSelectFormItem } from 'components/DirectoriesTreeSelect/DirectoriesTreeSelect';
import DocumentMultipleActionError from 'components/DocumentMultipleActionError';
import FilenameErrorMessage from 'components/FilenameErrorMessage';
import PathDisplay from 'components/PathDisplay';
import { FILE_AND_FOLDER_NAME_REGEX } from 'config/constants';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import { canWriteInDirectory } from 'pages/AllDocumentsPage/AllDocumentsPage';
import React, { FC } from 'react';

import { maxLengthRule, requiredRule } from 'utils/formHelpers';
import styles from './DirectoryCreateLinkForm.module.less';

type Props = {
  selectedDirectory: DirectoryListDto;
  errors: JSX.Element[];
};

const DirectoryCreateLinkForm: FC<Props> = ({ selectedDirectory, errors }) => {
  const intl = useIntl();

  return (
    <>
      <div className={styles.movingDirectoryWrap}>
        <div className={styles.movingDirectoryTitle}>{<Fmt id="DirectoryCreateLinkForm.source" />}</div>
        <PathDisplay path={selectedDirectory.path} className={styles.movingDirectoryPath} />
      </div>
      <Form.Item
        name="directoryId"
        initialValue={undefined}
        className={styles.item}
        label={intl.formatMessage({ id: 'DirectoryCreateLinkForm.label' })}
        rules={[requiredRule('forms.items.rules.noEmpty')]}
      >
        <DirectoriesTreeSelectFormItem
          isItemDisabled={(item) => !canWriteInDirectory(item)}
          autoFocus
          dropdownStyle={{ maxHeight: 400, overflow: 'auto', maxWidth: 300 }}
          placeholder={intl.formatMessage({ id: 'DirectoryCreateLinkForm.placeholder' })}
        />
      </Form.Item>
      <Form.Item
        name="name"
        initialValue={selectedDirectory.name}
        className={styles.item}
        label={<Fmt id="DirectoryCreateLinkForm.linkName" />}
        rules={[
          requiredRule('DirectoryEditForm.form.items.name.rules.required', true),
          maxLengthRule('general.maxNameLength'),
          {
            pattern: FILE_AND_FOLDER_NAME_REGEX,
            message: <FilenameErrorMessage />,
          },
        ]}
      >
        <Input type="text" />
      </Form.Item>
      <DocumentMultipleActionError errors={errors} titleId="DocumentsMoveFormModal.errorsTitle.missed" />
    </>
  );
};

export default DirectoryCreateLinkForm;
