import { message } from 'antd';
import { api } from 'api';
import {
  DirectoryListDto,
  DocumentMoveDto,
  DocumentMoveResultDto,
  DocumentsMultipleMoveStrategyEnum,
} from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import { transformError } from 'components/DocumentMultipleActionError';
import { IMultipleDocumentErrorData } from 'components/DocumentMultipleActionError/DocumentMultipleActionError';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';

import { processApiError } from 'utils';
import { ConnectedDirectory } from 'utils/typeMappings/directories/directoryTypes';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import DocumentsMoveForm, { DocumentsMoveFormData } from './DocumentsMoveForm';

type Props = InjectedIntlProps &
  FormModalProps<DocumentMoveResultDto> & {
    disabled?: (node: ConnectedDirectory) => boolean;
    selectedDirectory: DirectoryListDto;
    destinationDirectory?: Guid;
    selectedDocumentIds: Guid[];
    selectedDocumentLinkIds?: Guid[];
  };

const DocumentsMoveFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, onClose, selectedDirectory, selectedDocumentIds, selectedDocumentLinkIds, ...restProps } = props;
  const [strategy, setStrategy] = useState<DocumentsMultipleMoveStrategyEnum>(null);
  const [errors, setErrors] = useState<JSX.Element[]>([]);
  const [linkErrors, setLinkErrors] = useState<JSX.Element[]>([]);
  const [selectedOkDocuments, setSelectedOkDocuments] = useState<Guid[]>(null);
  const [selectedOkLinks, setSelectedOkLinks] = useState<Guid[]>(null);

  const handleSubmit: FormSubmitHandler<DocumentsMoveFormData> = async (values) => {
    const documentMoveDto: DocumentMoveDto = {
      destinationDirectoryId: values.destinationDirectory,
      sourceDirectoryId: selectedDirectory.id,
      documentsIds: !!selectedOkDocuments ? selectedOkDocuments : selectedDocumentIds,
      documentLinksIds: !!selectedOkLinks ? selectedOkLinks : selectedDocumentLinkIds,
      strategy: strategy,
    };
    const [err, res] = await api.project.documents.moveDocuments(documentMoveDto);
    if (err) {
      processApiError(err, (error) => {
        if (error != null && !!error.errorData) {
          switch (error.referenceErrorCode) {
            case ServiceErrorEnum.DocumentMultipleMoveObstacleError:
              // eslint-disable-next-line no-case-declarations
              const errData = error.errorData as IMultipleDocumentErrorData;
              setSelectedOkDocuments(
                documentMoveDto.documentsIds.filter(
                  (doc) => !errData.documentsErrors.some((errDoc) => errDoc.objectId === doc)
                )
              );
              setSelectedOkLinks(
                documentMoveDto.documentLinksIds.filter(
                  (doc) => !errData.documentsErrors.some((errDoc) => errDoc.objectId === doc)
                )
              );
              setErrors(errData.documentsErrors.map(transformError));
              return null;
            case ServiceErrorEnum.DocumentMoveDuplicateError:
              // eslint-disable-next-line no-case-declarations
              const duplicities = error.errorData as IMultipleDocumentErrorData;
              setStrategy(DocumentsMultipleMoveStrategyEnum.rename);
              setErrors(
                duplicities.documentsErrors
                  .filter((docErr) => docErr.isDocument)
                  .map((dup) => <strong key={dup.objectId}>{dup.objectName}</strong>)
              );
              setLinkErrors(
                duplicities.documentsErrors
                  .filter((docErr) => !docErr.isDocument)
                  .map((dup) => <strong key={dup.objectId}>{dup.objectName}</strong>)
              );
              return null;
          }
        }
        message.error(props.intl.formatMessage({ id: `serviceError.${error.referenceErrorCode}` }));
      });
      return null;
    }
    await onSubmit(res.data);
    resetDialog();
    return null;
  };

  const handleClose = () => {
    resetDialog();
    onClose();
  };

  const resetDialog = () => {
    setStrategy(null);
    setErrors([]);
    setLinkErrors([]);
    setSelectedOkDocuments(null);
    setSelectedOkLinks(null);
  };

  const moveableEntitiesCount = useMemo(
    () =>
      (!!selectedOkDocuments ? selectedOkDocuments.length : selectedDocumentIds?.length) +
      (!!selectedOkLinks ? selectedOkLinks.length : selectedDocumentLinkIds?.length),
    [selectedDocumentIds, selectedDocumentLinkIds, selectedOkDocuments, selectedOkLinks]
  );

  const submitTextId = useMemo(() => {
    if (!moveableEntitiesCount) return 'general.ok';
    return errors.length === 0
      ? 'DocumentsMoveFormModal.button.move'
      : !!strategy && strategy === DocumentsMultipleMoveStrategyEnum.rename
      ? 'DocumentsMoveFormModal.button.moveAndRename'
      : 'DocumentsMoveFormModal.button.moveNext';
  }, [moveableEntitiesCount, errors, strategy]);

  const { destinationDirectory } = props;
  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      onClose={handleClose}
      titleId="DocumentsMoveFormModal.title"
      confirmClose={false}
      {...restProps}
      submitTextId={submitTextId}
      // TODO: form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
      layout="horizontal"
    >
      <DocumentsMoveForm
        selectedDirectory={selectedDirectory}
        selectedDocuments={!!selectedOkDocuments ? selectedOkDocuments : selectedDocumentIds}
        selectedLinks={!!selectedOkLinks ? selectedOkLinks : selectedDocumentLinkIds}
        destinationDirectory={destinationDirectory}
        strategy={strategy}
        errors={errors}
        linkErrors={linkErrors}
      />
    </FormModalWrapper>
  );
};

export default injectIntl(DocumentsMoveFormModal);
