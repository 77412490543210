import { createSelector } from 'reselect';
import { RootState } from 'store';
import { textComparer } from 'utils/comparators';

const buildingIdentificationsSelector = (state: RootState) => state.buildingIdentifications.data;

export const buildingIdentificationsSortedSelector = createSelector(
  [buildingIdentificationsSelector],
  (identifications) =>
    identifications && [...identifications].sort(textComparer.map((identification) => identification.code))
);
