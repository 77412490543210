import { Form, Input, InputRef } from 'antd';
import { MAX_ITEM_DESCRIPTION_LENGTH } from 'config/constants';
import { useIntl } from 'hooks';
import React, { FC } from 'react';
import { duplicateNameRuleCallback, maxLengthRule, requiredRule } from 'utils/formHelpers';

export type DocumentCategoryTreeNodeCreateFormData = {
  name: string;
  description: string;
};

type Props = {
  validateUniqueName: (name: string) => boolean;
  defaults?: Partial<DocumentCategoryTreeNodeCreateFormData>;
  setRef: (ref: InputRef) => void;
};

const DocumentCategoryTreeNodeCreateForm: FC<Props> = ({ validateUniqueName, setRef, defaults }) => {
  const intl = useIntl();

  return (
    <>
      <Form.Item
        initialValue={defaults?.name}
        label={intl.formatMessage({ id: 'forms.items.name.label' })}
        name="name"
        rules={[
          requiredRule('forms.items.name.rules.required', true),
          maxLengthRule('general.maxNameLength'),
          duplicateNameRuleCallback(validateUniqueName),
        ]}
      >
        <Input autoFocus ref={setRef} />
      </Form.Item>
      <Form.Item
        initialValue={defaults?.description}
        label={intl.formatMessage({ id: 'forms.items.description.label' })}
        name="description"
        rules={[
          {
            whitespace: true,
            message: intl.formatMessage({ id: 'forms.items.name.rules.empty' }),
          },
          {
            max: MAX_ITEM_DESCRIPTION_LENGTH,
            message: intl.formatMessage({ id: 'general.maxDescriptionLength' }, { max: MAX_ITEM_DESCRIPTION_LENGTH }),
          },
        ]}
      >
        <Input.TextArea rows={2} autoSize={{ minRows: 2 }} />
      </Form.Item>
    </>
  );
};

export default DocumentCategoryTreeNodeCreateForm;
