import { FileDoneOutlined, FileSearchOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { masterApi } from 'api/completeApi';
import { MdRoleDto } from 'api/completeApiInterfaces';
import { MdProjectListWithUnpackedData } from 'components/JSONVariableFormItems/dataUnpackers/mdProjectUnpacker.utils';
import { useApiData, useBoolean } from 'hooks';
import { Fmt } from 'locale';
import React, { useCallback, useMemo, useState } from 'react';
import MDProjectApprovalFormModal, { MdMeetingAprovalDefault } from './MDProjectApprovalFormModal';
import MDProjectApprovalListModal from './MDProjectApprovalListModal';

type Props = {
  organizationId: Guid;
  overheadProjectId: Guid;
  mdProjectList: MdProjectListWithUnpackedData[];
  mdUserRoles: MdRoleDto[];
  reloadProjectList: () => void;
};

const MDProjectApprovalButtons: React.FC<Props> = ({
  organizationId,
  mdProjectList,
  mdUserRoles,
  overheadProjectId,
  reloadProjectList,
}) => {
  const [isMdCkApprovalFormOpen, showMdCkFormApproval, hideMdCkFormApproval] = useBoolean(false);
  const [isMdCkApprovalListOpen, showMdCkApprovalList, hideMdCkApprovalList] = useBoolean(false);
  const [editedMeetingId, setEditedMeetingId] = useState<Guid>();

  const [meetingApprovals, meetingApprovalsError, meetingApprovalsLoading, loadMeetingApprovals] = useApiData(
    (ct) => masterApi.projects.md.approvalmeeting.id.get(organizationId, ct),
    { autoload: true }
  );

  const defaultMeetingBackground = useMemo((): MdMeetingAprovalDefault => {
    return meetingApprovals?.mdMeetingApprovalBackgrounds.find((meeting) => meeting.id === editedMeetingId);
  }, [editedMeetingId, meetingApprovals]);

  const reloadMeetingsData = useCallback(() => {
    loadMeetingApprovals();
    reloadProjectList();
  }, []);

  const handleMdCkMeetingApprovalChange = () => {
    setEditedMeetingId(undefined);
    loadMeetingApprovals();
    hideMdCkFormApproval();
  };

  const handleMdCkMeetingApprovalModalClose = () => {
    setEditedMeetingId(undefined);
    hideMdCkFormApproval();
  };

  const handleEditMeeting = (meetingId: Guid) => {
    setEditedMeetingId(meetingId);
    showMdCkFormApproval();
  };

  return (
    <>
      <Button type="link" icon={<FileDoneOutlined />} onClick={showMdCkFormApproval}>
        <Fmt id="MDApprovedProjectsReport.prepareCkApprovals.create" />
      </Button>
      <Button type="link" icon={<FileSearchOutlined />} onClick={showMdCkApprovalList}>
        <Fmt id="MdApprovalProjectsReport.ckApprovalMeetings" />
      </Button>
      {isMdCkApprovalFormOpen && (
        <MDProjectApprovalFormModal
          open={isMdCkApprovalFormOpen}
          onClose={handleMdCkMeetingApprovalModalClose}
          onSubmit={handleMdCkMeetingApprovalChange}
          organizationId={organizationId}
          projectsList={mdProjectList}
          defaultMeetingBackground={defaultMeetingBackground}
          mdUserCkOrganizerRoles={mdUserRoles}
        />
      )}
      {meetingApprovals && (
        <MDProjectApprovalListModal
          open={isMdCkApprovalListOpen}
          meetingApprovals={meetingApprovals?.mdMeetingApprovalBackgrounds}
          onClose={hideMdCkApprovalList}
          onEditMeeting={handleEditMeeting}
          organizationId={organizationId}
          reloadMeetingApprovals={reloadMeetingsData}
          mdProjectList={mdProjectList}
          overheadProjectId={overheadProjectId}
        />
      )}
    </>
  );
};

export default React.memo(MDProjectApprovalButtons);
