import { SearchOutlined } from '@ant-design/icons';
import { Input, Space } from 'antd';
import classNames from 'classnames';
import { HeaderSearchContext } from 'components/HeaderSearch/HeaderSearchContextProvider';
import { useOnKeyUp } from 'hooks/useKeyPress';
import { InjectedIntlProps, memoizeWithIntl } from 'locale';
import React, { FunctionComponent, MouseEventHandler, useContext, useLayoutEffect } from 'react';
import styles from './HeaderSearch.module.less';

type Props = InjectedIntlProps;

const HeaderSearchComponent: FunctionComponent<Props> = ({ intl }) => {
  const { enterSearchMode, inputRef, searchText, focused, onChange, handleSearch, leaveSearchMode } = useContext(
    HeaderSearchContext
  );

  useLayoutEffect(() => {
    if (focused) inputRef?.current.focus();
  }, [focused]);

  useOnKeyUp('Escape', leaveSearchMode);

  const handleInputSuffixClick: MouseEventHandler<HTMLSpanElement> = (e) => {
    handleSearch();
    e.stopPropagation();
  };

  return (
    <span className={classNames(styles.headerSearch, focused && styles.show)} onClick={enterSearchMode}>
      <Space.Compact>
        <Input
          suffix={<SearchOutlined onClick={!!searchText ? handleInputSuffixClick : undefined} />}
          aria-hidden={!focused}
          disabled={!focused}
          ref={inputRef}
          value={searchText}
          className={classNames(styles.input)}
          aria-label={intl.formatMessage({ id: 'HeaderSearch.placeholder' })}
          placeholder={intl.formatMessage({ id: 'HeaderSearch.placeholder' })}
          onChange={onChange}
          onPressEnter={handleSearch}
        />
      </Space.Compact>
    </span>
  );
};

export const HeaderSearch = memoizeWithIntl(HeaderSearchComponent);
