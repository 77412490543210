import { Button } from 'antd';
import { api } from 'api';
import { ExtendedPermissionEnum, ExtendedPermissionsPatchDto, ServiceError } from 'api/completeApiInterfaces';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import ServiceErrorBox from 'components/ServiceErrorBox';
import StackPanel from 'components/StackPanel';
import { useItemsSet } from 'hooks';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { FunctionComponent, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { messageError } from 'utils';
import { PermissionsDrawer } from './PermissionsDrawer';

type Props = InjectedIntlProps & {
  userId: Guid;
  userPermissions: Set<ExtendedPermissionEnum>;
  error?: ServiceError;
  onChange?: (userId: Guid, groupId: Guid, checked: boolean) => void;
  handleSetProjectDefaultUserPermissions?: () => void;
};

const PermissionsTabComponent: FunctionComponent<Props> = ({
  intl,
  userId,
  userPermissions: userPermissionsSet,
  error,
  onChange,
  handleSetProjectDefaultUserPermissions,
}) => {
  const [savingItems, addSavingItem, deleteSavingItem] = useItemsSet<ExtendedPermissionEnum>();

  const { url } = useRouteMatch();

  useEffect(() => () => deleteSavingItem(), [userId]);

  const handleSave = async (permission: ExtendedPermissionEnum, checked: boolean) => {
    addSavingItem(permission);

    const data: ExtendedPermissionsPatchDto = { settings: [{ permission: checked, permissionType: permission }] };
    const [err] = await api.project.projectUser.updateUserExtendedPermissions(userId, data);

    if (err) {
      messageError(err, intl);
    } else {
      onChange && onChange(userId, permission, checked);
    }

    deleteSavingItem(permission);
  };

  return (
    <MasterComponent
      url={url}
      title={intl.formatMessage({ id: 'UserDetailPanel.PermisionsTab' })}
      children={() => (
        <StackPanel vertical scrollable>
          {!!handleSetProjectDefaultUserPermissions && (
            <Button type="primary" onClick={handleSetProjectDefaultUserPermissions}>
              <Fmt id={'UserDetailPanel.PermisionsTab.setDefaultProjectPermission'} />
            </Button>
          )}
          {!!error ? (
            <ServiceErrorBox error={error} />
          ) : (
            <PermissionsDrawer
              userPermissionsSet={userPermissionsSet}
              savingItems={savingItems}
              handlePermissionChange={handleSave}
            />
          )}
        </StackPanel>
      )}
    />
  );
};

export const PermissionsTab = React.memo(PermissionsTabComponent);
