import { Typography } from 'antd';
import { api } from 'api';
import { DocumentBulkActionSetResultDto, DocumentBulkLabelsSetDto } from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import { transformError } from 'components/DocumentMultipleActionError';
import {
  IMultipleDocumentErrorData,
  MultipleDocumentActionErrorDataItem,
} from 'components/DocumentMultipleActionError/DocumentMultipleActionError';
import { useBoolean, useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { messageError, processApiError } from 'utils';
import BulkEditActionResultModal from '../BulkActionResultReportModal/BulkEditActionResultModal';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import BulkEditDocumentsLabelsForm, { BulkEditDocumentsLabelsFormData } from './BulkEditDocumentsLabelsForm';

type Props = FormModalProps<void> & {
  documentsIds: Guid[];
  documentLinksIds?: Guid[];
};

const BulkEditDocumentsLabelsFormModal: FunctionComponent<Props> = ({
  onSubmit,
  onClose,
  documentsIds,
  documentLinksIds,
  ...restProps
}) => {
  const intl = useIntl();
  const [errors, setErrors] = useState<JSX.Element[]>([]);
  const [ignoredEntities, setIgnoreEntities] = useState<MultipleDocumentActionErrorDataItem[]>([]);
  const [resultModalVisible, showSuccessResultModal, hideSuccessResultModal] = useBoolean(false);
  const [result, setResult] = useState<DocumentBulkActionSetResultDto>(undefined);

  const handleSubmit: FormSubmitHandler<BulkEditDocumentsLabelsFormData> = async (values) => {
    const data: DocumentBulkLabelsSetDto = {
      selectedLabels: values.documentsLabels,
      labelsSetMode: values.setMode,
      documentsIds: documentsIds,
      documentLinksIds: documentLinksIds || [],
      ignoredDocumentIds: ignoredEntities.filter((item) => item.isDocument).map((item) => item.objectId) || [],
      ignoredDocumentLinksIds: ignoredEntities.filter((item) => !item.isDocument).map((item) => item.objectId) || [],
    };

    const [err, res] = await api.project.documents.bulkEditDocumentsLabelsPatch(data);
    if (err) {
      processApiError(err, (error) => {
        if (error.referenceErrorCode === ServiceErrorEnum.DocumentMultipleStateChangeObstacleError) {
          const errData = error.errorData as IMultipleDocumentErrorData;
          setErrors(errData.documentsErrors.map(transformError));
          setIgnoreEntities((prevState) => prevState.concat(errData.documentsErrors));
          return null;
        }
        messageError(error, intl);
        onClose();
      });
      return null;
    }
    setResult(res.data);
    onSubmit();
    showSuccessResultModal();
    handleClose();
    return null;
  };

  const handleClose = () => {
    onClose();
    setErrors([]);
    setIgnoreEntities([]);
  };

  const handleCloseActionResultModal = () => {
    hideSuccessResultModal();
    setResult(undefined);
  };

  const submitTextId = useMemo(() => {
    return errors.length > 0
      ? 'BulkEditDocumentsStateForm.executeChangesToOthers'
      : 'BulkEditDocumentsStateForm.executeChanges';
  }, [errors]);

  return (
    <>
      <FormModalWrapper
        onSubmit={handleSubmit}
        onClose={handleClose}
        titleId="BulkEditDocumentsLabelsForm.title"
        confirmClose={false}
        {...restProps}
        submitTextId={submitTextId}
        layout="vertical"
      >
        <>
          <Typography.Paragraph strong>
            <Fmt id="BulkEditDocumentsStateForm.documentsCount" values={{ count: documentsIds.length }} />
          </Typography.Paragraph>
          <BulkEditDocumentsLabelsForm errors={errors} />
        </>
      </FormModalWrapper>
      <BulkEditActionResultModal
        visible={resultModalVisible}
        onClose={handleCloseActionResultModal}
        result={result}
        title={<Fmt id="BulkEditDocumentsLabelsForm.resultModal.title" />}
        showReportLink
      />
    </>
  );
};

export default BulkEditDocumentsLabelsFormModal;
