import { Button, Typography } from 'antd';

import { ExportOutlined } from '@ant-design/icons';
import { to } from 'api/await-to';
import { DirectoryContentsExDto, DirectoryListsExDto, JSONVariableTypeEnum } from 'api/completeApiInterfaces';
import { baseMasterApi } from 'api/master/baseMasterApi';
import axios, { CancelToken } from 'axios';
import { CommonDocument } from 'components/DocumentCompleteList/DocumentCompleteList';
import StackPanel from 'components/StackPanel';
import { useIntl, useIsMounted } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { messageError } from 'utils';
import { JSONVariableHubLinkData, JSONVariableLinkData } from '../JSONVariableTypes';
import JSONVariableProjectDirectorySelectModal from './JSONVariableProjectDirectorySelectModal';
import JSONVariableProjectDocumentSelectModal from './JSONVariableProjectDocumentSelectModal';
import styles from './JSONVariableProjectLinkSelectorFormItem.module.less';

export type JSONVariableProjectLinkProps = {
  dataType: JSONVariableTypeEnum.hubLink;
  linkType: 'directory' | 'document';
  projectId: Guid;
  value?: JSONVariableLinkData;
  onChange?: (value: JSONVariableLinkData | JSONVariableHubLinkData) => void;
  disabled?: boolean;
  loading?: boolean;
  disableDocumentSelect?: (documet: CommonDocument) => boolean;
};

const JSONVariableProjectLinkSelectorFormItem: FunctionComponent<JSONVariableProjectLinkProps> = ({
  value,
  onChange,
  projectId,
  linkType,
  disabled,
  dataType,
  loading,
  disableDocumentSelect,
}) => {
  const isMounted = useIsMounted();
  const [directories, setDirectories] = useState<DirectoryListsExDto>();
  const [directoryModalOpen, setDirectoryModalOpen] = useState(false);
  const [documentModalOpen, setDocumentModalOpen] = useState(false);
  const intl = useIntl();

  const projectApi = useMemo(
    () =>
      projectId &&
      axios.create({
        baseURL: `${baseMasterApi.defaults.baseURL}/projects/${projectId}`,
        headers: {
          ...baseMasterApi.defaults.headers,
        },
      }),
    [projectId]
  );

  const showSelectModal = useCallback(async () => {
    if (!projectApi) return;
    const [err, res] = await to(projectApi.get<DirectoryListsExDto>(`/directories/primary/listdirectoriesex`));
    if (!isMounted.current) return;
    if (err) {
      void messageError(err, intl);
      return;
    }

    setDirectories(res.data);
    linkType === 'directory' ? setDirectoryModalOpen(true) : setDocumentModalOpen(true);
  }, [projectApi, linkType, intl]);

  const buttonText =
    linkType === 'directory'
      ? 'JSONVariableProjectLinkSelectorFormItem.button.selectDirectory'
      : 'JSONVariableProjectLinkSelectorFormItem.button.selectDocument';

  const handleDirectorySelect = (directoryId: Guid) => {
    onChange?.({
      label: directories.directories.find((dir) => dir.id === directoryId)?.name,
      value: `projects/${projectId}/directories/${directoryId}`,
      type: dataType,
      directoryId,
      projectId,
    });
    setDirectoryModalOpen(false);
  };

  const handleDocumentSelect = (documentId: Guid, documentName: string) => {
    onChange?.({
      label: documentName,
      value: `projects/${projectId}/documents/${documentId}`,
      type: dataType,
      documentId,
      projectId,
    });
    setDocumentModalOpen(false);
  };

  const handleModalClose = () => {
    setDirectoryModalOpen(false);
    setDocumentModalOpen(false);
  };
  const getDocumentDownloadUrl = useCallback(async (documentId: Guid) => {
    const [err, res] = await to(projectApi.get<string>(`/documents/${documentId}/download`));
    if (err) {
      return undefined;
    }
    return res.data;
  }, []);

  const getDirectoryContent = (directoryId: Guid, ct: CancelToken) =>
    to(
      projectApi.get<DirectoryContentsExDto>(`/directories/primary/${directoryId}/contentex`, { cancelToken: ct })
    );

  return (
    <>
      <StackPanel stretch>
        <Typography.Paragraph ellipsis className={styles.linkLabel}>
          {value?.value ? (
            <Link to={value.value}>
              {value?.label} <ExportOutlined />
            </Link>
          ) : (
            value?.label || <Fmt id="general.notSet" />
          )}
        </Typography.Paragraph>
        <Button onClick={showSelectModal} disabled={disabled} size="small" loading={loading}>
          <Fmt id={buttonText} />
        </Button>
      </StackPanel>
      {directoryModalOpen && (
        <JSONVariableProjectDirectorySelectModal
          directories={directories}
          open={directoryModalOpen}
          onCancel={handleModalClose}
          onDirectorySelect={handleDirectorySelect}
        />
      )}
      {documentModalOpen && (
        <JSONVariableProjectDocumentSelectModal
          open={documentModalOpen}
          hubProjectId={projectId}
          directories={directories}
          onDocumentSelect={handleDocumentSelect}
          fetchDocumentsFn={getDirectoryContent}
          onCancel={handleModalClose}
          getOriginalUrl={getDocumentDownloadUrl}
          disableSelect={disableDocumentSelect}
        />
      )}
    </>
  );
};

export default React.memo(JSONVariableProjectLinkSelectorFormItem);
