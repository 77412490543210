import { InputRef } from 'antd';
import { OrganizationDto, OrgExtendedPermissionEnum } from 'api/completeApiInterfaces';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import { ReportWidgetConfiguration } from 'components/Reports/contexts/ReportWidgetsContextProvider';
import { RequestCacheApiCallback } from 'hooks/useCachedApiRequest';
import { useFocus } from 'hooks/useFocus';
import React, { FunctionComponent, useCallback } from 'react';
import uuid from 'uuid';
import ReportWidgetCreateForm, { ReportWidgetCreateFormData } from './ReportWidgetCreateForm';

type Props = FormModalProps<ReportWidgetConfiguration> & {
  organizations: OrganizationDto[];
  defaults?: ReportWidgetCreateFormData;
  requestCacheApiData: RequestCacheApiCallback;
};

const ReportWidgetCreateFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, organizations, defaults, requestCacheApiData, ...restProps } = props;

  const handleSubmit: FormSubmitHandler<ReportWidgetCreateFormData> = useCallback(
    async (values) => {
      if (values.reportType === OrgExtendedPermissionEnum.projectsInRealisationOverview) {
        const data: ReportWidgetConfiguration = {
          id: uuid(),
          title: values.name,
          order: 0,
          type: 'personal',
          data: {
            name: values.name,
            estiConnId: values.estiConnId,
            esticonFirmId: values.esticonFirmId,
            reportDate: values.reportDate,
            reportType: values.reportType,
            startYear: values.startYear,
            filters: values.filters,
            organizationId: values.organizationId,
            labelAlign: values.labelAlign,
            viewDecimalUnit: values.viewDecimalUnit,
            columnConfigurations: values.columnConfigurations,
          },
        };
        await onSubmit(data);
      }
      return null;
    },
    [onSubmit]
  );

  const { setInputRef } = useFocus<InputRef>(restProps.open);

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId="ReportWidgetCreateFormModal.title"
      submitTextId="ReportWidgetCreateFormModal.addButton"
      submitTextLoadingId={null}
      width={1000}
      {...restProps}
      // TODO: form className={styles.formWrapper}
    >
      <ReportWidgetCreateForm
        organizations={organizations}
        defaults={defaults}
        setRef={setInputRef}
        requestCacheApiData={requestCacheApiData}
      />
    </FormModalWrapper>
  );
};

export default ReportWidgetCreateFormModal;
