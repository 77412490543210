import { Alert, Modal } from 'antd';
import { Margin } from 'components/Margin/Margin';
import { useIntl } from 'hooks';
import React, { FunctionComponent } from 'react';

type Props = { open: boolean; handleAlertClose: () => void };

export const NoApplicableNewDocumentStateAlert: FunctionComponent<Props> = ({ open, handleAlertClose }) => {
  const intl = useIntl();

  return (
    <Modal open={open} footer={null} onCancel={handleAlertClose}>
      <Margin top>
        <Alert
          message={intl.formatMessage({
            id: `NoApplicableNewDocumentStateAlert.title`,
          })}
          showIcon
          description={intl.formatMessage({
            id: `NoApplicableNewDocumentStateAlert.description`,
          })}
          type="error"
        />
      </Margin>
    </Modal>
  );
};
