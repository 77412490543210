import { Form } from 'antd';
import { RoleDto } from 'api/completeApiInterfaces';
import { RoleSelect } from 'components/RoleSelect/RoleSelect';
import { useIntl } from 'hooks';
import React, { FC } from 'react';
import { requiredRule } from 'utils/formHelpers';

export type RoleAssignFormData = {
  roleId: string;
};

type Props = {
  userRolesSet: Set<Guid>;
};

const RoleAssignForm: FC<Props> = ({ userRolesSet }) => {
  const intl = useIntl();

  const disabledRoles = (role: RoleDto) => {
    return userRolesSet?.has(role.id);
  };

  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.roleId.label' })}
        name="roleId"
        rules={[requiredRule('forms.items.roleId.rules.required')]}
      >
        <RoleSelect hiddenRoles={disabledRoles} />
      </Form.Item>
    </>
  );
};

export default RoleAssignForm;
