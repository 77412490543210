import { Checkbox, Form, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { DefaultOptionType } from 'antd/lib/select';
import {
  MdCkIdentificationEnum,
  MdDivisionDto,
  MdRoleDto,
  MdRoleEnum,
  MdUserDto,
  ProjectUserProfileListDto,
} from 'api/completeApiInterfaces';
import { UserTransferFormItem } from 'components/UserTransfer/UserTransfer';
import { useIntl } from 'hooks';
import { difference, uniqBy } from 'lodash';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import { smartFilter } from 'utils';
import { requiredRule } from 'utils/formHelpers';

export type MDRoleFormData = {
  headMdUserId: Guid;
  mdUserIds?: Guid[];
  ckIdentification?: MdCkIdentificationEnum[];
  divisionId?: Guid;
};

type Props = {
  defaults?: MdRoleDto;
  divisions: MdDivisionDto[];
  usedRoleUsers: MdUserDto[];
  existingRoleLeaderIds: Guid[];
  disableHeadUserEdit?: boolean;
  roleType: MdRoleEnum;
};

const headUserSelectFilter = (input: string, option?: DefaultOptionType) => {
  return smartFilter(option.label.toString(), input);
};

export const MDRoleForm: FC<Props> = ({
  divisions,
  existingRoleLeaderIds,
  usedRoleUsers,
  disableHeadUserEdit,
  defaults,
  roleType,
}) => {
  const intl = useIntl();
  const form = useFormInstance();

  const headMdUserId = useWatch('headMdUserId');
  const formValueDivisionId = useWatch('divisionId');
  const [selectedHeadUserId, setSelectedHeadUserId] = useState<Guid>(defaults?.head.id);
  const [selectedDivision, setSelectedDivision] = useState<MdDivisionDto>(defaults?.division);
  const [leadersForOptions, setLeadersForOptions] = useState<MdUserDto[]>();
  const [moreDivisionLeadersIds, setMoreDivisionLeadersIds] = useState<Guid[]>();

  const ckIdentificationOptions = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: 'MD.MDcreateProject.ckIdentification.railway' }),
        value: MdCkIdentificationEnum.railway,
      },
      {
        label: intl.formatMessage({ id: 'MD.MDcreateProject.ckIdentification.road' }),
        value: MdCkIdentificationEnum.road,
      },
      {
        label: intl.formatMessage({ id: 'MD.MDcreateProject.ckIdentification.water' }),
        value: MdCkIdentificationEnum.water,
      },
      {
        label: intl.formatMessage({ id: 'MD.MDcreateProject.ckIdentification.other' }),
        value: MdCkIdentificationEnum.other,
      },
    ],
    [intl]
  );

  useEffect(() => {
    form.setFieldsValue({
      headMdUserId: defaults?.head.id,
      mdUserIds: defaults?.mdUsers.map((user) => user.id),
      divisionId: defaults?.division.id,
    });
  }, [defaults]);

  useDebounce(
    () => {
      setSelectedHeadUserId(headMdUserId);
      const headUserDivision = divisions.find((division) => division.mdUsers.some((user) => user.id === headMdUserId));
      if (headUserDivision?.id !== selectedDivision?.id || !headMdUserId) {
        setSelectedDivision(headUserDivision);
        form.setFieldsValue({ mdUserIds: [] });
      }
    },
    100,
    [divisions, headMdUserId]
  );

  useEffect(() => {
    if (moreDivisionLeadersIds?.includes(selectedHeadUserId) || !selectedHeadUserId) return;
    const selectedDivision = divisions.find((division) =>
      division.mdUsers.some((user) => user.id === selectedHeadUserId)
    );
    form.setFieldValue('divisionId', selectedDivision.id);
  }, [selectedHeadUserId, divisions, moreDivisionLeadersIds]);

  const availableUserList = useMemo(
    () =>
      divisions
        .filter((division) => division.id === formValueDivisionId)
        .flatMap((division) => division.mdUsers)
        .filter((mdUser) => mdUser.id !== headMdUserId)
        .filter(
          (mdUser) =>
            !usedRoleUsers.some(
              (usedUser) => usedUser.id === mdUser.id && !defaults?.mdUsers.some((user) => user.id === usedUser.id)
            )
        )
        .map(
          (mdUser): ProjectUserProfileListDto => ({
            id: mdUser.id,
            username: mdUser.orgUser.appUserProfile.username,
            firstname: mdUser.orgUser.firstname,
            lastname: mdUser.orgUser.lastname,
            isAdmin: mdUser.orgUser.isAdmin,
            status: undefined,
          })
        ),
    [divisions, formValueDivisionId, usedRoleUsers, headMdUserId, defaults]
  );

  useEffect(() => {
    const availableLeaders = divisions
      .filter((division) => !formValueDivisionId || division.id === formValueDivisionId)
      .flatMap((division) => division.mdUsers)
      .filter((mdUser) => !existingRoleLeaderIds.includes(mdUser.id) || mdUser.id === defaults?.head.id);
    const uniqueLeaders = uniqBy(availableLeaders, 'id');
    const moreDivisionsLeaders = difference(availableLeaders, uniqueLeaders);
    setLeadersForOptions(uniqueLeaders);
    setMoreDivisionLeadersIds(moreDivisionsLeaders?.map((leader) => leader.orgUser.id));
  }, [defaults?.head.id, divisions, existingRoleLeaderIds, formValueDivisionId]);

  const leaderOptions = useMemo((): DefaultOptionType[] => {
    return leadersForOptions?.map((mdUser) => ({
      label: mdUser.orgUser.appUserProfile.username,
      value: mdUser.id,
    }));
  }, [leadersForOptions]);

  const divisionsOptions = useMemo(() => {
    if (moreDivisionLeadersIds?.includes(headMdUserId)) return null;
    return divisions
      .filter((division) => division.mdUsers.some((mdUser) => moreDivisionLeadersIds?.includes(mdUser.orgUser.id)))
      ?.map((division) => ({ label: division.name, value: division.id }));
  }, [divisions, headMdUserId, moreDivisionLeadersIds]);

  const textWayDivisionIdItem = useMemo(() => {
    if (!selectedHeadUserId)
      return intl.formatMessage({ id: 'MD.Organization.roles.modal.selectedDivision.notSelected' });
    if (!divisionsOptions?.length) return divisions.find((division) => division.id === formValueDivisionId)?.name;
    return null;
  }, [divisions, divisionsOptions, formValueDivisionId, intl, selectedHeadUserId]);

  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: 'MD.Organization.roles.modal.selectedDivision' })}
        name="divisionId"
        rules={[requiredRule('forms.items.rules.required')]}
      >
        {textWayDivisionIdItem ? (
          textWayDivisionIdItem
        ) : (
          <Select
            options={divisionsOptions}
            placeholder={intl.formatMessage({ id: 'MD.Organization.roles.modal.selectedDivision.moreDivisions' })}
          />
        )}
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'MD.Organization.roles.modal.input.leader' })}
        name="headMdUserId"
        initialValue={defaults?.head.id}
        rules={[requiredRule('MD.Organization.roles.modal.input.leader.required', true)]}
      >
        <Select
          options={leaderOptions}
          showSearch
          filterOption={headUserSelectFilter}
          allowClear
          disabled={disableHeadUserEdit}
        />
      </Form.Item>
      {divisions && (
        <Form.Item
          label={intl.formatMessage({ id: 'MD.Organization.roles.modal.input.users' })}
          name="mdUserIds"
          initialValue={defaults?.mdUsers.map((user) => user.id)}
        >
          <UserTransferFormItem users={availableUserList} />
        </Form.Item>
      )}
      {roleType === MdRoleEnum.ck_organizer && (
        <Form.Item
          label={intl.formatMessage({ id: 'MD.ProjectVariableEnum.ckIdentificaion.name' })}
          name="ckIdentification"
          initialValue={defaults?.mdCkIdentifications}
        >
          <Checkbox.Group options={ckIdentificationOptions} />
        </Form.Item>
      )}
    </>
  );
};
