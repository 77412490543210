import { Form, Input } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { apiConstraints } from 'api/completeApiConstraints';
import { OrgWorkingPositionDto } from 'api/completeApiInterfaces';
import { useIntl } from 'hooks';
import React, { FC, useEffect } from 'react';

export type JobPositionAddFormData = {
  name: string;
  id: Guid | null;
};

type Props = {
  editingItem?: OrgWorkingPositionDto;
};

const JobPositionAddForm: FC<Props> = ({ editingItem }) => {
  const intl = useIntl();
  const form = useFormInstance();

  useEffect(() => {
    if (editingItem) {
      form.setFieldsValue({
        name: editingItem.name,
        id: editingItem.id,
      });
    } else {
      form.resetFields();
    }
  }, [editingItem]);

  return (
    <>
      <Form.Item
        name="name"
        label={intl.formatMessage({ id: 'general.name' })}
        rules={[
          { required: true, message: intl.formatMessage({ id: 'JobPositions.add.rules.name' }) },
          {
            max: apiConstraints.orgWorkingPositionSetDto.name.maxLength,
            message: intl.formatMessage(
              { id: 'JobPositions.add.rules.nameLength' },
              { maxLength: apiConstraints.orgWorkingPositionSetDto.name.maxLength }
            ),
          },
        ]}
        initialValue={editingItem?.name}
      >
        <Input />
      </Form.Item>
      <Form.Item hidden name="id" initialValue={editingItem?.id} />
    </>
  );
};

JobPositionAddForm.displayName = 'JobPositionAddForm';

export default JobPositionAddForm;
