import { EllipsisOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import classnames from 'classnames';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { DROPDOWN_TRIGGER_CLICK } from 'config/constants';
import React, { FunctionComponent, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { Link, useHistory } from 'react-router-dom';
import styles from './ShrinkablePath.module.less';

type ShrinkablePathProps = {
  parts: PathPart[];
  offset?: number;
  rootNode?: { name?: string; id: string };
  defaultPathSplitterIcon?: React.ReactNode;
};

export type PathPart = {
  title?: string;
  description?: string;
  path?: string;
  label: string;
  key: string;
  icon?: React.ReactNode;
  link?: string;
  strong?: boolean;
};

const DEFAULT_PATH_SPLITTER_ICON: React.ReactNode = <RightOutlined />;

export const ShrinkablePath: FunctionComponent<ShrinkablePathProps> = ({
  parts,
  offset = 60,
  defaultPathSplitterIcon = DEFAULT_PATH_SPLITTER_ICON,
}) => {
  const { width, ref } = useResizeDetector();
  const innerRef = useRef<HTMLDivElement>();
  const [breakIndex, setBreakIndex] = useState<number | null>(null);
  const [itemsWidths, setItemsWidths] = useState<number[]>([]);
  const [totalItemsWidth, setTotalItemsWidth] = useState<number>(0);
  const [initialized, setInitialized] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    setInitialized(false);
    setBreakIndex(null);
    setItemsWidths([]);
  }, [parts]);

  useLayoutEffect(() => {
    if ((initialized && breakIndex !== null) || !!itemsWidths?.length) {
      return;
    }

    const items = innerRef.current.children as HTMLCollectionOf<HTMLElement>;
    if (!items?.length) return;

    const computedItemsWidths = Array.from(items).map((item) => item.offsetWidth);
    const totalWidth = computedItemsWidths.reduce((acc, value) => acc + value, 0) - items[items.length - 1].offsetWidth;

    if (totalWidth > 0 && computedItemsWidths.length > 0) {
      setItemsWidths(computedItemsWidths);
      setInitialized(true);
      setTotalItemsWidth(totalWidth);
    }
  }, [breakIndex]);

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    const rightMargin = Number.parseInt(window.getComputedStyle(ref.current)?.marginRight) || 0;
    const leftMargin = Number.parseInt(window.getComputedStyle(ref.current)?.marginLeft) || 0;
    const availableWidth = width - rightMargin - leftMargin - offset;

    let w = 0;
    for (let i = itemsWidths.length - 1; i >= 0; i--) {
      w += itemsWidths[i];
      if (w > availableWidth) {
        setBreakIndex(Math.min(i, itemsWidths.length - 1));
        break;
      }
    }
    if (w < availableWidth) {
      setBreakIndex(0);
    }
  }, [width, itemsWidths, totalItemsWidth, offset]);

  const shownParts = useMemo(() => (breakIndex !== null ? parts.slice(breakIndex) : parts), [breakIndex, parts]);

  const allItemsMenu = useMemo((): MenuProps => {
    const items: ItemType[] = parts
      .map((item) => ({
        key: item.key,
        label: item.label,
        icon: item.icon === null ? undefined : item.icon || defaultPathSplitterIcon,
        onClick: () => {
          if (item.link) {
            history.push(item.link);
          }
        },
      }))
      .filter(Boolean);
    return { items };
  }, [parts, history, defaultPathSplitterIcon]);

  return (
    <>
      <span>
        <Dropdown trigger={DROPDOWN_TRIGGER_CLICK} menu={allItemsMenu}>
          <Button type="link" icon={<EllipsisOutlined />} />
        </Dropdown>
      </span>
      <div className={styles.pathContainer} ref={ref}>
        <div className={styles.toolbar} ref={innerRef}>
          {shownParts?.map((part, index) => {
            const isLast = index === shownParts.length - 1;
            const hasLink = !!part.link;
            const content = (
              <span
                className={classnames(styles.item, hasLink && styles.hasLink, initialized && styles.overflow)}
                key={part.key}
              >
                {part.icon === null ? null : part.icon || defaultPathSplitterIcon}
                {hasLink ? (
                  <Link className={classnames(styles.label, styles.link, part.strong && styles.strong)} to={part.link}>
                    {part.label}
                  </Link>
                ) : (
                  <span className={classnames(styles.label, part.strong && styles.strong)}>{part.label}</span>
                )}
              </span>
            );

            return (
              <CommonHubTooltip
                key={part.title}
                title={
                  <>
                    <strong>{part.title}</strong>
                    <br />
                    {part.path}
                  </>
                }
              >
                {content}
              </CommonHubTooltip>
            );
          })}
        </div>
      </div>
    </>
  );
};
