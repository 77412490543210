import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Col, Empty, Input, Row, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { DeleteButton } from 'components/ActionButtons';
import ColorPickerInput from 'components/ColorPickerInput';
import { DragHook, DragIdentifier } from 'components/DragTile/DragTile';
import { DragableGrid } from 'components/DragableGrid/DragableGrid';
import { Margin } from 'components/Margin/Margin';
import { Fmt, InjectedIntl, InjectedIntlProps } from 'locale';
import moment from 'moment';
import { getRealizationOverviewReportColumns } from 'pages/ReportDetailPage/ReportDetails/ProjectsInRealisationOverviewReport/ProjectsInRealizationOverviewReport.columns';
import { ProjectsInRealisationOverviewReportData } from 'pages/ReportDetailPage/ReportDetails/ProjectsInRealisationOverviewReport/ProjectsInRealizationOverviewReportUtils';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { ignoreRef, smartFilter } from 'utils';
import { getNumberFormatSettings } from 'utils/buildingGridUtils';
import uuid from 'uuid';
import {
  ReportPrecision,
  ReportWidgetColumnConfiguration,
  ReportWidgetColumnConfigurationType,
} from '../ReportWidgetForm.utils';
import styles from './ProjectsInRealizationReportConfigurationColumnFormItem.module.less';

type ColumnConfiguration = ReportWidgetColumnConfiguration<ProjectsInRealisationOverviewReportData>;
type Props = InjectedIntlProps & {
  value: ColumnConfiguration[];
  onChange: (value: ColumnConfiguration[]) => void;
  columnCountLimit: number;
};

const getDefaultColumnConfiguration = (
  columnType: ReportWidgetColumnConfigurationType,
  intl: InjectedIntl
): ColumnConfiguration => {
  const commonDefaults = {
    id: uuid(),
    color: '#193d71',
    name: intl.formatMessage({ id: 'ProjectsInRealizationReportConfigurationColumnForm.defaultColumnName' }),
  };
  switch (columnType) {
    case ReportWidgetColumnConfigurationType.Constant:
      return { ...commonDefaults, type: columnType, constant: '0' };
    case ReportWidgetColumnConfigurationType.Sum:
      return { ...commonDefaults, type: columnType, property: 'aktualniCena' };
  }
};

const ProjectsInRealizationReportConfigurationColumn: FunctionComponent<Props> = ({
  intl,
  columnCountLimit,
  value,
  onChange,
}) => {
  const [newItemType, setNewItemType] = useState<ReportWidgetColumnConfigurationType>(
    ReportWidgetColumnConfigurationType.Sum
  );

  const availableValueColumnsOptions = useMemo(() => {
    const availableColumns = getRealizationOverviewReportColumns(
      moment().year(),
      intl,
      getNumberFormatSettings(ReportPrecision)
    ).filter((config) => config.dataType === 'number');
    return (
      availableColumns?.map((column): DefaultOptionType => ({ value: column.dataField, label: column.caption })) || []
    );
  }, [intl]);

  const newItemTypeOptions: DefaultOptionType[] = [
    {
      value: ReportWidgetColumnConfigurationType.Sum,
      label: intl.formatMessage({ id: 'ProjectsInRealizationReportConfigurationColumnForm.columnType.property' }),
    },
    {
      value: ReportWidgetColumnConfigurationType.Constant,
      label: intl.formatMessage({ id: 'ProjectsInRealizationReportConfigurationColumnForm.columnType.constant' }),
    },
  ];

  const handleCreateNewConfiguration = useCallback(() => {
    onChange && onChange([...value, getDefaultColumnConfiguration(newItemType, intl)]);
  }, [value, newItemType, intl, onChange]);

  const handleOrderChange = useCallback(
    async (from: DragIdentifier, to: DragIdentifier) => {
      const newConfigurationOrder = value ? [...value] : [];
      newConfigurationOrder.splice(to.itemId, 0, ...newConfigurationOrder.splice(from.itemId, 1));
      await onChange(newConfigurationOrder);
    },
    [onChange, value]
  );

  const rowChangeHandle = useCallback(
    (rowProperty: keyof ColumnConfiguration | 'constant' | 'property', rowId: Guid) => (newValue: any) => {
      onChange([...value.map((config) => (config.id === rowId ? { ...config, [rowProperty]: newValue } : config))]);
    },
    [onChange, value]
  );

  const rowDeleteHandle = useCallback(
    (rowId: Guid) => {
      onChange([...value.filter((config) => config.id !== rowId)]);
    },
    [onChange, value]
  );

  const propertyFilter = (input: string, option: DefaultOptionType) => {
    return smartFilter(option.label.toString(), input);
  };

  const drawCard = (dragRef: DragHook, rowData: ColumnConfiguration) => (
    <Row gutter={8}>
      <Col span={1}>
        <EllipsisOutlined rotate={90} ref={dragRef} className={styles.dragIcon} />
      </Col>
      <Col span={9}>
        <Input value={rowData.name} onChange={(value) => rowChangeHandle('name', rowData.id)(value.target.value)} />
      </Col>
      <Col span={3}>
        <ColorPickerInput value={rowData.color} onChange={rowChangeHandle('color', rowData.id)} />
      </Col>
      {rowData.type === ReportWidgetColumnConfigurationType.Constant && (
        <Col span={8}>
          <Input
            value={rowData.constant}
            onChange={(value) => rowChangeHandle('constant', rowData.id)(value.target.value)}
            type="number"
            pattern="[0-9]{3} "
          />
        </Col>
      )}
      {rowData.type === ReportWidgetColumnConfigurationType.Sum && (
        <Col span={8}>
          <Select
            options={availableValueColumnsOptions}
            value={rowData.property}
            onChange={rowChangeHandle('property', rowData.id)}
            showSearch
            filterOption={propertyFilter}
          />
        </Col>
      )}
      <Col span={2}>
        <DeleteButton onClick={() => rowDeleteHandle(rowData.id)} />
      </Col>
    </Row>
  );

  const addButtonDissabled = value?.length >= columnCountLimit;

  return (
    <>
      <Row gutter={8}>
        <Col span={1}></Col>
        <Col span={9}>
          <Fmt id="ProjectsInRealizationReportConfigurationColumnForm.columnName.name" />
        </Col>
        <Col span={3}>
          <Fmt id="general.color" />
        </Col>
        <Col span={8}>
          <Fmt id="ProjectsInRealizationReportConfigurationColumnForm.columnName.value" />
        </Col>
        <Col span={2}></Col>
      </Row>
      <DragableGrid cardItems={value} drawCard={drawCard} onMove={handleOrderChange} className={styles.dragCard}>
        {!value?.length && <Empty className={styles.empty} />}
      </DragableGrid>
      <Margin top>
        <Row gutter={8}>
          <Col span={6}>
            <Fmt id="ProjectsInRealizationReportConfigurationColumnForm.addColumn" />
          </Col>
          <Col span={10}>
            <Select options={newItemTypeOptions} onChange={setNewItemType} value={newItemType} />
          </Col>
          <Col span={8}>
            <Button onClick={handleCreateNewConfiguration} type="primary" block disabled={addButtonDissabled}>
              <Fmt id="general.add" />
            </Button>
          </Col>
        </Row>
      </Margin>
    </>
  );
};

export const ProjectsInRealizationReportConfigurationColumnFormItem = ignoreRef(
  ProjectsInRealizationReportConfigurationColumn
) as FunctionComponent<Omit<Props, 'value' | 'onChange'>>;
