import React, { FC } from 'react';
import styles from './HeightMeasureWrap.module.less';

type Props = {
  innerRef?: React.RefObject<HTMLDivElement>;
};

export const HeightMeasureWrap: FC<Props> = ({ children, innerRef }) => {
  return (
    <div className={styles.fullHeightWrap} ref={innerRef}>
      {children}
    </div>
  );
};
