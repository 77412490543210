import { Form, Select } from 'antd';
import { AccessLevelEnum, DirectoryContentDto } from 'api/completeApiInterfaces';
import { DocumentsSelectMultipleFormItem } from 'components/DocumentSelect/FormItem/DocumentSelectMultipleFormItem';
import { useCurrentProjectUser, useIntl } from 'hooks';
import React, { FC, useCallback } from 'react';
import { requiredRule } from 'utils/formHelpers';
import { isUserInRole } from 'utils/roles/isUserInRole';
import { ConnectedDirectory } from 'utils/typeMappings/directories/directoryTypes';
import { DiscussionRelationOption } from '../DiscussionAttachmentCreateForm/DiscussionAttachmentCreateForm';

export type DiscussionAssignAttachmentFormData = {
  selectedDocuments: DirectoryContentDto[];
  discussionRelation?: string | number;
};

type Props = {
  discussionRelationOptions?: DiscussionRelationOption<string | number>[];
};

const DiscussionAssignAttachmentForm: FC<Props> = ({ discussionRelationOptions }) => {
  const intl = useIntl();
  const currentUser = useCurrentProjectUser();

  const disabledDocuments = useCallback(
    (document: DirectoryContentDto, directory: ConnectedDirectory) => {
      const currentAccessLevel = directory.currentAccessLevel;
      if (currentAccessLevel !== AccessLevelEnum.admin && currentAccessLevel !== AccessLevelEnum.write) {
        return intl.formatMessage({ id: 'DocumentSelect.disabledMessage.noWriteAccess' });
      }
      if (document.reservedBy && document.reservedBy.id !== currentUser.id) {
        return intl.formatMessage({ id: 'DocumentSelect.disabledMessage.notUnlocked' });
      }
      if (document.ownedBy && !isUserInRole(currentUser.id, document.ownedBy)) {
        return intl.formatMessage({ id: 'DocumentSelect.disabledMessage.notOwner' });
      }
      return false;
    },
    [currentUser, intl]
  );

  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: 'DiscussionAssignAttachmentForm.assignedDocuments' })}
        name="selectedDocuments"
        initialValue={[]}
      >
        <DocumentsSelectMultipleFormItem
          disabledDocuments={disabledDocuments}
          titleId="DiscussionAssignAttachmentForm.assignedDocuments.modalTitle"
        />
      </Form.Item>

      {discussionRelationOptions?.length && (
        <Form.Item
          label={intl.formatMessage({ id: 'AttachmentCreateFormModal.form.item.relation.label' })}
          name="discussionRelation"
          rules={[requiredRule('DocumentCreateForm.form.items.relation.rules.required', true)]}
        >
          <Select dropdownMatchSelectWidth={false}>
            {discussionRelationOptions.map((option) => (
              <Select.Option key={option.key} value={option.key}>
                {option.icon}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </>
  );
};

export default DiscussionAssignAttachmentForm;
