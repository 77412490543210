import moment, { MomentInput } from 'moment';

export type Comparator<T> = (a: T, b: T) => number;

export const compareText: Comparator<string> = (a, b) => {
  if (a == null) {
    return b == null ? 0 : 1;
  }
  if (b == null) {
    return -1;
  }
  return a.localeCompare(b);
};

const compareNumber: Comparator<number> = (a, b) => {
  if (a == null) {
    return b == null ? 0 : 1;
  }
  if (b == null) {
    return -1;
  }
  return a - b;
};

export const compareDate: Comparator<MomentInput> = (a, b) => {
  if (a == null) {
    return b == null ? 0 : 1;
  }
  if (b == null) {
    return -1;
  }
  const momentA = moment(a);
  const momentB = moment(b);
  return momentA > momentB ? 1 : momentA < momentB ? -1 : 0;
};

const compareStringAsNumber: Comparator<string> = (a, b) => {
  if (a == null) {
    return b == null ? 0 : 1;
  }
  if (b == null) {
    return -1;
  }
  const diff = +a.trim() - +b.trim();
  if (!isNaN(diff)) {
    return diff;
  }
  return a == b ? 0 : a < b ? -1 : 1;
};

interface Comparer<T> extends Comparator<T> {
  /**
   * Returns a new comparer which will use this comparator, and if the result is *equal*, it will use the `other` comparator instead.
   * @param other Comparator to be used if the items are equal according to this comparator
   */
  andThen(other: Comparator<T>): Comparer<T>;

  /**
   * Returns a new comparer which will compare object by mapping them to a value using the `mapper` function,
   * and then compare those values using the comparator that the `map` function val called on.
   * @param mapper
   */
  map<S>(mapper: (value: S) => T): Comparer<S>;

  /**
   * Returns a new comparer which will switch the arguments before passing them to this comparator.
   */
  reverse(): Comparer<T>;
}

function andThen<T>(this: Comparer<T>, other: Comparator<T>) {
  return injectComparer((item1: T, item2: T) => this(item1, item2) || other(item1, item2));
}

function map<T, S>(this: Comparer<T>, mapper: (value: S) => T) {
  return injectComparer((item1: S, item2: S) => this(mapper(item1), mapper(item2)));
}

function reverse<T>(this: Comparer<T>) {
  return injectComparer((item1: T, item2: T) => this(item2, item1));
}

const injectComparer = <T>(comparator: Comparator<T>): Comparer<T> =>
  Object.assign(comparator, { andThen, map, reverse });

export const textComparer = injectComparer(compareText);
export const numberComparer = injectComparer(compareNumber);
export const dateComparer = injectComparer(compareDate);
export const stringAsNumberComparer = injectComparer(compareStringAsNumber);

// Common comparators
// TODO: make name not nullable after interface definitions get fixed
export const compareByName: Comparator<{ name?: string }> = textComparer.map((item) => item.name);
export const compareByCreatedDate: Comparator<{ createdDate: IsoDateTime }> = dateComparer.map(
  (item) => item.createdDate
);
