import { Alert, Form, List, Radio, Typography } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { DocumentCategoryNodeRemoveStrategyEnum, ServiceError } from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import { useIntl } from 'hooks';
import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { requiredRule } from 'utils/formHelpers';
import styles from './DocumentCategoryTreeNodeDeleteForm.module.less';

interface IAdditionalDataDocuments {
  directoryPath: string;
  documentId: Guid;
  documentName: string;
}

interface IAdditionalDataDirectories {
  directoryPath: string;
  directoryId: Guid;
}

interface IErrorData {
  directories: IAdditionalDataDirectories[];
  documents: IAdditionalDataDocuments[];
}

export type DocumentCategoryTreeNodeDeleteFormData = {
  removeStrategy: DocumentCategoryNodeRemoveStrategyEnum;
  removeChildren: boolean;
  nodeId: Guid;
};

type Props = {
  askRemoveStrategy: boolean;
  error: ServiceError;
  removeChildren?: boolean;
  projectId: Guid;
  defaults?: DocumentCategoryTreeNodeDeleteFormData;
};

export const DocumentCategoryTreeNodeDeleteForm: FC<Props> = ({
  askRemoveStrategy,
  error,
  removeChildren,
  projectId,
  defaults,
}) => {
  const intl = useIntl();
  const form = useFormInstance();

  useEffect(() => {
    form.resetFields();
  }, [askRemoveStrategy]);

  const errorData = error ? (error.errorData as IErrorData) : undefined;
  const errorDataDirectories = errorData ? errorData.directories : [];
  const errorDataDocuments = errorData ? errorData.documents : [];

  return (
    <>
      <Form.Item name="nodeId" hidden initialValue={defaults?.nodeId} noStyle />
      <Form.Item
        label={intl.formatMessage({ id: 'DocumentCategoryTreeNodeDeleteModal.removeChildren.label' })}
        name="removeChildren"
        initialValue={removeChildren || defaults?.removeChildren}
        rules={[requiredRule('forms.items.rules.required')]}
      >
        <Radio.Group buttonStyle="solid" disabled={error && askRemoveStrategy}>
          <Radio.Button value={true}>
            {intl.formatMessage({ id: 'DocumentCategoryTreeNodeDeleteModal.removeChildren.remove' })}
          </Radio.Button>
          <Radio.Button value={false}>
            {intl.formatMessage({ id: 'DocumentCategoryTreeNodeDeleteModal.removeChildren.preserve' })}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>

      {error && askRemoveStrategy && (
        <>
          <Alert
            className={styles.errorMessageWrap}
            type="error"
            message={intl.formatMessage({ id: 'serviceError.DocumentCategoryNodeRemoveObstacleError' })}
          />

          <Form.Item
            label={intl.formatMessage({ id: 'DocumentCategoryTreeNodeDeleteModal.removeStrategy.label' })}
            name="removeStrategy"
            initialValue={defaults?.removeStrategy}
            rules={[requiredRule('forms.items.rules.required')]}
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="replaceParent">
                {intl.formatMessage({ id: 'DocumentCategoryTreeNodeDeleteModal.removeStrategy.replaceParent' })}
              </Radio.Button>
              <Radio.Button value="replaceRoot">
                {intl.formatMessage({ id: 'DocumentCategoryTreeNodeDeleteModal.removeStrategy.replaceRoot' })}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>

          {errorDataDocuments.length > 0 &&
            error.referenceErrorCode === ServiceErrorEnum.DocumentCategoryNodeRemoveObstacleError && (
              <List<IAdditionalDataDocuments>
                size="small"
                header={
                  <Typography.Text strong>
                    {intl.formatMessage({ id: 'DocumentCategoryTreeNodeDeleteForm.documentsWithNode' })}
                  </Typography.Text>
                }
                dataSource={errorDataDocuments}
                renderItem={(item) => (
                  <List.Item>
                    <Link
                      target="_blank"
                      to={`/projects/${projectId}/documents/${item.documentId}`}
                      title={`${item.directoryPath}/${item.documentName}`}
                    >
                      {item.directoryPath}/{item.documentName}
                    </Link>
                  </List.Item>
                )}
              />
            )}

          {errorDataDirectories.length > 0 &&
            error.referenceErrorCode === ServiceErrorEnum.DocumentCategoryNodeRemoveObstacleError && (
              <List<IAdditionalDataDirectories>
                size="small"
                header={
                  <Typography.Text strong>
                    {intl.formatMessage({ id: 'DocumentCategoryTreeNodeDeleteForm.directoriesWithNode' })}
                  </Typography.Text>
                }
                dataSource={errorDataDirectories}
                renderItem={(item) => (
                  <List.Item>
                    <Link
                      target="_blank"
                      to={`/projects/${projectId}/directories/${item.directoryId}`}
                      title={`${item.directoryPath}`}
                    >
                      {item.directoryPath}
                    </Link>
                  </List.Item>
                )}
              />
            )}
        </>
      )}
    </>
  );
};

export default DocumentCategoryTreeNodeDeleteForm;
