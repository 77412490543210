import { api } from 'api';
import {
  AccessLevelEnum,
  DirectoryContentDto,
  DirectoryListDto,
  DocumentLinkDto,
  ProjectUserProfileDto,
} from 'api/completeApiInterfaces';
import DocumentLinkRow from 'components/DocumentLinkRow/DocumentLinkRow';
import DocumentRow from 'components/DocumentRow';
import CommonDocumentsGrid from 'components/DocumentsGrid/CommonDocumentsGrid';
import { EmptyGate } from 'components/EmptyGate/EmptyGate';
import CommonDerivativesFileViewer, {
  CommonDerivedFileViewerItem,
} from 'components/FileViewer/CommonDerivativesFileViewer';
import PrimaryFileViewerTitle from 'components/PrimaryFileViewerTitle/PrimaryFileViewerTitle';
import { PREVIEW_ORIGINAL_URL_EXPIRATION } from 'config/constants';
import { Fmt } from 'locale';
import React, { Component, RefObject } from 'react';
import { List, ListRowRenderer } from 'react-virtualized';
import { isUserInRole } from 'utils/roles/isUserInRole';
import { DisabledWithReason } from 'utils/types';

export type CommonDirectoryContentDto = DirectoryContentDto & DocumentLinkDto;

export function isDocumentLink(
  commonDocument: DirectoryContentDto | CommonDirectoryContentDto
): commonDocument is CommonDirectoryContentDto {
  return (commonDocument as CommonDirectoryContentDto).linkedDocument !== undefined;
}

const getOriginalUrl = async (previewId: Guid) => {
  const [err, result] = await api.project.documents.getDocumentDownloadUrlById(
    previewId,
    PREVIEW_ORIGINAL_URL_EXPIRATION
  );
  if (!err) {
    return result.data.url;
  }
  return undefined;
};

type OwnProps = {
  documents: (DirectoryContentDto | CommonDirectoryContentDto)[];
  selectedFilesIds: Set<Guid>;
  listRef: RefObject<List>;
  directory: DirectoryListDto;
  projectId: Guid;
  currentUser: ProjectUserProfileDto;
  disableReserve?: boolean;
  onSelectFile: (id: Guid, index: number) => void;
  getOriginalUrl?: (previewId: Guid) => Promise<string>;
  disableSelect?: boolean;
  disabledDocuments?: (file: DirectoryContentDto) => DisabledWithReason;
  rowRenderer?: ListRowRenderer;
  selectItemOnClickEnabled?: boolean;
  onSignatureClick?: (documentId: Guid) => void;
  signatureDisabled?: boolean;
  reloadDocuments?: () => void;
  clearFilters?: () => void;
  hasFilteredOutItems?: boolean;
  handleEditLinkClick?: () => void;
  onLinkIconClick?: (id: Guid) => void;
};

type Props = OwnProps;

type State = {
  previewId: Guid | null;
  previewVisible?: boolean;
  previewableDocuments: DirectoryContentDto[];
};

class AllDocumentsPageDocumentsGrid extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      previewId: undefined,
      previewVisible: false,
      previewableDocuments: this.computePreviewableDocuments(props),
    };
  }

  static defaultProps = {
    getOriginalUrl: getOriginalUrl,
  };

  computePreviewableDocuments(props: Props) {
    return props.documents?.filter((doc) => doc.primaryFile);
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.documents !== this.props.documents || prevProps.selectedFilesIds !== this.props.selectedFilesIds) {
      this.props.listRef?.current?.forceUpdateGrid();
    }
    if (prevProps.documents !== this.props.documents) {
      this.setState({ previewableDocuments: this.computePreviewableDocuments(this.props) });
    }
    if (prevProps.directory?.id !== this.props.directory?.id) {
      this.setState({ previewId: null });
    }
  }

  selectFile = (documentId: Guid, index: number) => this.props.onSelectFile(documentId, index);

  handleHidePreview = () => this.setState({ previewVisible: false });

  handleThumbnailClick = (id: Guid) => this.setState({ previewId: id, previewVisible: true });

  transformItem = (item: DirectoryContentDto): CommonDerivedFileViewerItem => ({
    id: item.id,
    title: (
      <PrimaryFileViewerTitle
        revisionNo={item.currentRevision?.number}
        state={item.currentRevision?.revisionState}
        fileName={item.name}
      />
    ),
    blobToken: item.primaryFile?.blobToken,
    getOriginalUrl: () => this.props.getOriginalUrl(this.state.previewId),
  });

  setPreviewId = (previewId: Guid) => {
    if (previewId) this.setState({ previewId });
  };

  render() {
    const { listRef, documents } = this.props;
    const { previewId, previewVisible, previewableDocuments } = this.state;

    return (
      <>
        <CommonDocumentsGrid
          listRef={listRef}
          documents={documents}
          rowRenderer={this.props.rowRenderer || this.rowRenderer}
          emptyDescription={
            <EmptyGate
              empty
              clearSearch={this.props.clearFilters}
              hasUnfilteredItems={this.props.hasFilteredOutItems}
              noItemsMessage={<Fmt id="general.noDocuments" />}
              noFilteredItemsMessage={<Fmt id="ListEmpty.noDocumentsFound" />}
            />
          }
        />

        <CommonDerivativesFileViewer
          items={previewableDocuments}
          previewId={previewId}
          visible={previewVisible}
          onCancel={this.handleHidePreview}
          transform={this.transformItem}
          setPreviewDocumentId={this.setPreviewId}
        />
      </>
    );
  }

  rowRenderer: ListRowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    style, // Style object to be applied to row (to position it)
  }) => {
    const { projectId, currentUser, directory, documents, selectItemOnClickEnabled, reloadDocuments } = this.props;
    const doc: DirectoryContentDto = documents[index];
    const disabled = this.props.disabledDocuments && this.props.disabledDocuments(doc);
    const isSignatureDisabled =
      this.props.signatureDisabled ||
      (!!doc?.reservedDate && currentUser.id !== doc?.reservedBy.id) ||
      (!!doc?.ownedBy && !isUserInRole(currentUser.id, doc.ownedBy)) ||
      !directory ||
      directory.currentAccessLevel === AccessLevelEnum.read;

    const commonDocumentRowProps = {
      id: doc.id,
      style: style,
      name: doc.name,
      thumbnailUrl: doc?.primaryFile?.thumbnail,
      createdDate: doc.createdDate,
      createdBy: doc.createdBy,
      selected: this.props.selectedFilesIds.has(doc.id),
      onClick: selectItemOnClickEnabled ? () => this.selectFile(doc.id, index) : undefined,
      onClickCheckbox: () => this.selectFile(doc.id, index),
      disableSelect: (this.props.disableSelect && this.props.selectedFilesIds.size === 0) || disabled,
      signatureDisabled: isSignatureDisabled,
      onSignatureClick: this.props.onSignatureClick,
      onReservationChange: this.props.reloadDocuments,
      onThumbnailClick: () => this.handleThumbnailClick(doc.id),
      disableReserve: this.props.disableReserve,
      isModel: doc.isModel,
    };

    return isDocumentLink(doc) ? (
      <DocumentLinkRow
        key={key}
        currentUser={currentUser}
        projectId={projectId}
        description={doc.description}
        linkedDocument={doc.linkedDocument}
        linkedDiscardedDocument={doc.linkedDiscardedDocument}
        linkDirectory={doc.directory}
        getDownloadUrl={api.project.documents.getDocumentDownloadUrlById}
        documentAccessLevel={doc.documentAccessLevel}
        onDocumentLinkClick={this.props.onLinkIconClick}
        reloadDocuments={reloadDocuments}
        {...commonDocumentRowProps}
      />
    ) : (
      <DocumentRow
        key={key}
        nameLink={`/projects/${projectId}/documents/${doc.id}`}
        labels={doc.labels}
        description={doc.description}
        revisionNo={doc.currentRevision.number}
        revisionDate={doc.currentRevision.createdDate}
        disableReserve={this.props.disableReserve}
        primaryFileContentType={doc.primaryFile.contentType}
        getDownloadUrl={() => api.project.documents.getDocumentDownloadUrlById(doc.id)}
        reservedBy={!doc.reservedBy ? undefined : doc.reservedBy}
        reservedDate={doc.reservedDate}
        reservationDisabled={
          !currentUser.isAdmin &&
          ((!!doc.reservedDate && currentUser.id !== doc.reservedBy.id) ||
            !directory ||
            directory.currentAccessLevel === AccessLevelEnum.read)
        }
        revisionUser={{ ...doc.currentRevision.createdBy }}
        state={doc.state}
        size={doc.primaryFile.size}
        esticonObjectLink={doc.esticonObjectLink}
        documentOwner={doc.ownedBy}
        releaseReservedDate={doc.releaseReservedDate}
        {...commonDocumentRowProps}
      />
    );
  };
}

export default AllDocumentsPageDocumentsGrid;
