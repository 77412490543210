import { SelectProps } from 'antd';
import { JSONVariableTypeEnum } from 'api/completeApiInterfaces';
import JSONVariableSelectFormItem from 'components/JSONVariableFormItems/Items/JSONVariableSelectFormItem';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { useMemo } from 'react';
import { MdProjectDataSubmittedForEnum } from './MdProposedForFormItem';

export enum MdProjectDataStatementEnum {
  approved = 'approved',
  approvedWithConditions = 'approvedWithConditions',
  rejected = 'rejected',
  returnedForRework = 'returnedForRework',
  acknowledged = 'acknowledged',
  acknowledgedWithSuggestion = 'acknowledgedWithSuggestion',
  interrupted = 'interrupted',
}

export const CK_STATEMENT_FOR_APPROVAL_WAY: MdProjectDataStatementEnum[] = [
  MdProjectDataStatementEnum.approved,
  MdProjectDataStatementEnum.approvedWithConditions,
  MdProjectDataStatementEnum.rejected,
  MdProjectDataStatementEnum.returnedForRework,
  MdProjectDataStatementEnum.interrupted,
];

export const CK_STATEMENT_FOR_INFORMATION_WAY: MdProjectDataStatementEnum[] = [
  MdProjectDataStatementEnum.acknowledged,
  MdProjectDataStatementEnum.acknowledgedWithSuggestion,
  MdProjectDataStatementEnum.interrupted,
];

const MAP_CK_STATEMENT_TO_INTL_ID: Record<MdProjectDataStatementEnum, IntlMessageId> = {
  [MdProjectDataStatementEnum.acknowledged]: 'MD.Projects.ckStatement.acknowledged',
  [MdProjectDataStatementEnum.acknowledgedWithSuggestion]: 'MD.Projects.ckStatement.acknowledgedWithSuggestion',
  [MdProjectDataStatementEnum.approved]: 'MD.Projects.ckStatement.approved',
  [MdProjectDataStatementEnum.approvedWithConditions]: 'MD.Projects.ckStatement.approvedWithConditions',
  [MdProjectDataStatementEnum.rejected]: 'MD.Projects.ckStatement.rejected',
  [MdProjectDataStatementEnum.returnedForRework]: 'MD.Projects.ckStatement.returnedForRework',
  [MdProjectDataStatementEnum.interrupted]: 'MD.Projects.ckStatement.interrupted',
};

type Props = {
  disabled?: boolean;
  submittedForFormValue: MdProjectDataSubmittedForEnum;
};

const MdCkStatementFormItem: React.FC<Props> = ({ disabled, submittedForFormValue, ...restProps }) => {
  const ckStatementOptions: SelectProps['options'] = useMemo(() => {
    const optionsArray =
      submittedForFormValue === MdProjectDataSubmittedForEnum.forApproval
        ? CK_STATEMENT_FOR_APPROVAL_WAY
        : CK_STATEMENT_FOR_INFORMATION_WAY;

    return optionsArray.map((value) => ({
      value: value,
      label: <Fmt id={MAP_CK_STATEMENT_TO_INTL_ID[value]} />,
    }));
  }, [submittedForFormValue]);

  return (
    <JSONVariableSelectFormItem
      dataType={JSONVariableTypeEnum.enum}
      selectOptions={ckStatementOptions}
      disabled={disabled}
      enumType={nameof(MdProjectDataStatementEnum)}
      {...restProps}
    />
  );
};

export default MdCkStatementFormItem;
