import { Form, Typography } from 'antd';
import { JToken, ProjectCardDataDto } from 'api/completeApiInterfaces';
import { useIntl } from 'hooks';
import { Dictionary } from 'lodash';
import { renderProjectCardDefaultDataVariableFormItem } from 'pages/OrganizationsSettingPage/ProjectCardData/ProjectCardDataAddForm';
import React, { FC, useEffect } from 'react';

export type ProjectCardDataEditFormData = {
  id: Guid;
  data: Dictionary<JToken>;
};

type Props = {
  cardData: ProjectCardDataDto;
};

const ProjectCardDataEditForm: FC<Props> = ({ cardData }) => {
  const intl = useIntl();
  const form = Form.useFormInstance();

  useEffect(() => {
    if (cardData) {
      form.setFieldsValue({
        data: cardData.data,
        id: cardData.id,
      });
    } else {
      form.resetFields();
    }
  }, [cardData]);

  return (
    <>
      <Form.Item label={intl.formatMessage({ id: 'general.name' })}>
        <Typography.Text strong>{cardData.name}</Typography.Text>
        <Typography.Text italic>{cardData.unit ? ` [${cardData.unit}]` : null}</Typography.Text>
      </Form.Item>
      <Form.Item
        name="data"
        label={intl.formatMessage({ id: 'ProjectCardData.form.rules.value' })}
        initialValue={cardData?.data}
      >
        {cardData?.variable && renderProjectCardDefaultDataVariableFormItem(cardData.variable)}
      </Form.Item>
      <Form.Item hidden initialValue={cardData?.id} name="id" />
    </>
  );
};

ProjectCardDataEditForm.displayName = 'ProjectCardDataAddForm';

export default ProjectCardDataEditForm;
