import { SelectProps } from 'antd';
import { JSONVariableTypeEnum } from 'api/completeApiInterfaces';
import JSONVariableSelectFormItem from 'components/JSONVariableFormItems/Items/JSONVariableSelectFormItem';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { useMemo } from 'react';

export enum MdProjectDataSubmittedForEnum {
  forApproval = 'forApproval',
  forInformation = 'forInformation',
}

const MAP_SUBMITED_FOR_TO_INTL_ID: Record<MdProjectDataSubmittedForEnum, IntlMessageId> = {
  [MdProjectDataSubmittedForEnum.forApproval]: 'MD.Projects.submittedFor.forApproval',
  [MdProjectDataSubmittedForEnum.forInformation]: 'MD.Projects.submittedFor.forInformation',
};

const submitedForValues = Object.values(MdProjectDataSubmittedForEnum);

type Props = {
  disabled?: boolean;
};

const MdProposedForFormItem: React.FC<Props> = ({ disabled, ...restProps }) => {
  const submittedForOptions: SelectProps['options'] = useMemo(() => {
    return submitedForValues.map((value) => ({
      value: value,
      label: <Fmt id={MAP_SUBMITED_FOR_TO_INTL_ID[value]} />,
    }));
  }, []);

  return (
    <JSONVariableSelectFormItem
      dataType={JSONVariableTypeEnum.enum}
      selectOptions={submittedForOptions}
      disabled={disabled}
      enumType={nameof(MdProjectDataSubmittedForEnum)}
      {...restProps}
    />
  );
};

export default MdProposedForFormItem;
