import { Form, Input, InputRef } from 'antd';
import FilenameErrorMessage from 'components/FilenameErrorMessage';
import { FILE_AND_FOLDER_NAME_REGEX, MAX_ITEM_DESCRIPTION_LENGTH } from 'config/constants';
import { useIntl } from 'hooks';
import React, { FC } from 'react';
import { duplicateNameRuleCallback, maxLengthRule, requiredRule } from 'utils/formHelpers';

export type DirectoryCreateFormData = {
  name: string;
  description: string;
};

type Props = {
  validateUniqueName: (name: string) => boolean;
  setRef?: (ref: InputRef) => void;
};

const DirectoryCreateForm: FC<Props> = ({ validateUniqueName, setRef }) => {
  const intl = useIntl();

  return (
    <>
      <Form.Item
        name="name"
        label={intl.formatMessage({ id: 'forms.items.name.label' })}
        rules={[
          requiredRule('DirectoryCreateForm.form.items.name.rules.required', true),
          maxLengthRule('general.maxNameLength'),
          {
            pattern: FILE_AND_FOLDER_NAME_REGEX,
            message: <FilenameErrorMessage />,
          },
          duplicateNameRuleCallback(validateUniqueName, 'DirectoryCreateForm.form.items.name.rules.nameExists'),
        ]}
      >
        <Input
          placeholder={intl.formatMessage({ id: 'DirectoryCreateForm.form.items.name.placeholder' })}
          autoFocus
          ref={setRef}
        />
      </Form.Item>
      <Form.Item
        name="description"
        label={intl.formatMessage({ id: 'forms.items.description.label' })}
        rules={[
          {
            whitespace: true,
            message: intl.formatMessage({ id: 'forms.items.name.rules.empty' }),
          },
          {
            max: MAX_ITEM_DESCRIPTION_LENGTH,
            message: intl.formatMessage({ id: 'general.maxDescriptionLength' }, { max: MAX_ITEM_DESCRIPTION_LENGTH }),
          },
        ]}
      >
        <Input.TextArea
          rows={2}
          autoSize={{ minRows: 2 }}
          placeholder={intl.formatMessage({ id: 'DirectoryForm.form.items.description.placeholder' })}
        />
      </Form.Item>
    </>
  );
};

export default DirectoryCreateForm;
