import { message, Typography } from 'antd';
import { api } from 'api';
import { EstiCategoryEnum, EstiProjectSettingsBaseDto, EstiProjectSettingsDto } from 'api/completeApiInterfaces';
import Axios from 'axios';
import { ContentGate } from 'components/ContentGate/ContentGate';
import GeneralSelectSettingsItem from 'components/GeneralSelectSettingsItem/GeneralSelectSettingsItem';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import { SaveOrCancelButton } from 'components/SaveOrCancelButton/SaveOrCancelButton';
import StackPanel from 'components/StackPanel';
import { useActiveProject, useCancelToken, useIntl, useSameCallback, useSelectorDispatch } from 'hooks';
import { useApiData } from 'hooks/useApiData';
import produce from 'immer';
import { Fmt } from 'locale';
import { isEqual, mapValues } from 'lodash';
import React, { FunctionComponent, Reducer, useCallback, useMemo, useReducer, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { messageError } from 'utils';
import { ExternAppDetailProps } from '../../externAppsTypes';
import { ESTICON_CATEGORIES_ORDERED } from './esticonCategoriesOrdered';
import styles from './EsticonDetail.module.less';
import { EsticonDetailDirectory } from './EsticonDetailDirectory';

const EXISTING_CURRENCY_ABBREVIATIONS = {
  AED: 'AED',
  AFN: 'AFN',
  ALL: 'ALL',
  AMD: 'AMD',
  ANG: 'ANG',
  AOA: 'AOA',
  ARS: 'ARS',
  AUD: 'AUD',
  AWG: 'AWG',
  AZN: 'AZN',
  BAM: 'BAM',
  BBD: 'BBD',
  BDT: 'BDT',
  BGN: 'BGN',
  BHD: 'BHD',
  BIF: 'BIF',
  BMD: 'BMD',
  BND: 'BND',
  BOB: 'BOB',
  BOV: 'BOV',
  BRL: 'BRL',
  BSD: 'BSD',
  BTN: 'BTN',
  BWP: 'BWP',
  BYR: 'BYR',
  BZD: 'BZD',
  CAD: 'CAD',
  CDF: 'CDF',
  CLF: 'CLF',
  CLP: 'CLP',
  CNY: 'CNY',
  COP: 'COP',
  COU: 'COU',
  CRC: 'CRC',
  CUC: 'CUC',
  CUP: 'CUP',
  CVE: 'CVE',
  CZK: 'CZK',
  DJF: 'DJF',
  DKK: 'DKK',
  DOP: 'DOP',
  DZD: 'DZD',
  EGP: 'EGP',
  ERN: 'ERN',
  ETB: 'ETB',
  EUR: 'EUR',
  FJD: 'FJD',
  FKP: 'FKP',
  GBP: 'GBP',
  GEL: 'GEL',
  GHS: 'GHS',
  GIP: 'GIP',
  GMD: 'GMD',
  GNF: 'GNF',
  GTQ: 'GTQ',
  GYD: 'GYD',
  HKD: 'HKD',
  HNL: 'HNL',
  HRK: 'HRK',
  HTG: 'HTG',
  HUF: 'HUF',
  CHE: 'CHE',
  CHF: 'CHF',
  CHW: 'CHW',
  IDR: 'IDR',
  ILS: 'ILS',
  INR: 'INR',
  IQD: 'IQD',
  IRR: 'IRR',
  ISK: 'ISK',
  JMD: 'JMD',
  JOD: 'JOD',
  JPY: 'JPY',
  KES: 'KES',
  KGS: 'KGS',
  KHR: 'KHR',
  KMF: 'KMF',
  KPW: 'KPW',
  KRW: 'KRW',
  KWD: 'KWD',
  KYD: 'KYD',
  KZT: 'KZT',
  LAK: 'LAK',
  LBP: 'LBP',
  LKR: 'LKR',
  LRD: 'LRD',
  LSL: 'LSL',
  LYD: 'LYD',
  MAD: 'MAD',
  MDL: 'MDL',
  MGA: 'MGA',
  MKD: 'MKD',
  MMK: 'MMK',
  MNT: 'MNT',
  MOP: 'MOP',
  MRU: 'MRU',
  MUR: 'MUR',
  MVR: 'MVR',
  MWK: 'MWK',
  MXN: 'MXN',
  MXV: 'MXV',
  MYR: 'MYR',
  MZN: 'MZN',
  NAD: 'NAD',
  NGN: 'NGN',
  NIO: 'NIO',
  NOK: 'NOK',
  NPR: 'NPR',
  NZD: 'NZD',
  OMR: 'OMR',
  PAB: 'PAB',
  PEN: 'PEN',
  PGK: 'PGK',
  PHP: 'PHP',
  PKR: 'PKR',
  PLN: 'PLN',
  PYG: 'PYG',
  QAR: 'QAR',
  RON: 'RON',
  RSD: 'RSD',
  RUB: 'RUB',
  RWF: 'RWF',
  SAR: 'SAR',
  SBD: 'SBD',
  SCR: 'SCR',
  SDG: 'SDG',
  SEK: 'SEK',
  SGD: 'SGD',
  SHP: 'SHP',
  SLL: 'SLL',
  SOS: 'SOS',
  SRD: 'SRD',
  SSP: 'SSP',
  STN: 'STN',
  SVC: 'SVC',
  SYP: 'SYP',
  SZL: 'SZL',
  THB: 'THB',
  TJS: 'TJS',
  TMT: 'TMT',
  TND: 'TND',
  TOP: 'TOP',
  TRY: 'TRY',
  TTD: 'TTD',
  TWD: 'TWD',
  TZS: 'TZS',
  UAH: 'UAH',
  UGX: 'UGX',
  USD: 'USD',
  USN: 'USN',
  UYI: 'UYI',
  UYU: 'UYU',
  UZS: 'UZS',
  VEF: 'VEF',
  VND: 'VND',
  VUV: 'VUV',
  WST: 'WST',
  XAF: 'XAF',
  XCD: 'XCD',
  XDR: 'XDR',
  XOF: 'XOF',
  XPF: 'XPF',
  XSU: 'XSU',
  XUA: 'XUA',
  YER: 'YER',
  ZAR: 'ZAR',
  ZMW: 'ZMW',
  ZWL: 'ZWL',
};

export type CurrencyAbbreviation = ObjectValues<typeof EXISTING_CURRENCY_ABBREVIATIONS>;

const currencyOptions = Object.values(EXISTING_CURRENCY_ABBREVIATIONS).map((currency) => ({
  value: currency,
  text: currency,
}));

export type EsticonSettingsWithErrors = EstiProjectSettingsBaseDto & {
  esticonDirectoryErrors: Record<EstiCategoryEnum, string[]>;
  esticonFileErrors: Record<EstiCategoryEnum, string>;
};

const esticonSettingsToCurrentSettings = (settings: EstiProjectSettingsDto): EsticonSettingsWithErrors => ({
  esticonDirectories: mapValues(settings.esticonDirectories, (directory) => directory?.id),
  esticonDirectoryMasks: settings.esticonDirectoryMasks,
  esticonFileMasks: settings.esticonFileMasks,
  projectCurrencySign: settings.projectCurrencySign || undefined,
  // TODO: need to send object keys correctly
  esticonDirectoryErrors: mapValues(settings.esticonDirectoryMasks, (masks) =>
    new Array(masks.length).fill(null)
  ) as any,
  esticonFileErrors: mapValues(settings.esticonFileMasks, (_mask) => null) as any,
});

type SetDataAction = {
  type: 'setData';
  data: EstiProjectSettingsDto;
};

type SetDirectoryAction = {
  type: 'setDirectory';
  directory: EstiCategoryEnum;
  directoryId: Guid;
};

type SetPathMaskAction = {
  type: 'setPathMask';
  directory: EstiCategoryEnum;
  pathIndex: number;
  mask: string;
};

type SetPathErrorAction = {
  type: 'setPathError';
  directory: EstiCategoryEnum;
  pathIndex: number;
  error: string;
};

type AddPathMaskAction = {
  type: 'addPathMask';
  directory: EstiCategoryEnum;
};

type RemovePathMaskAction = {
  type: 'removePathMask';
  directory: EstiCategoryEnum;
  pathIndex: number;
};

type SetFileMaskAction = {
  type: 'setFileMask';
  directory: EstiCategoryEnum;
  mask: string;
};

type SetFileErrorAction = {
  type: 'setFileError';
  directory: EstiCategoryEnum;
  error: string;
};

type SetCurrencyAction = {
  type: 'setCurrency';
  currencySign: CurrencyAbbreviation;
};

export type EsticonSettingsAction =
  | SetDataAction
  | SetDirectoryAction
  | SetPathMaskAction
  | SetPathErrorAction
  | AddPathMaskAction
  | RemovePathMaskAction
  | SetFileMaskAction
  | SetFileErrorAction
  | SetCurrencyAction;

const esticonSettingsReducer: Reducer<EsticonSettingsWithErrors, EsticonSettingsAction> = (prevState, action) => {
  switch (action.type) {
    case 'setData':
      return esticonSettingsToCurrentSettings(action.data);
    case 'setDirectory':
      return produce(prevState, (draft) => {
        draft.esticonDirectories[action.directory] = action.directoryId;
      });
    case 'setPathMask':
      return produce(prevState, (draft) => {
        draft.esticonDirectoryMasks[action.directory][action.pathIndex] = action.mask;
      });
    case 'setPathError':
      return produce(prevState, (draft) => {
        draft.esticonDirectoryErrors[action.directory][action.pathIndex] = action.error;
      });
    case 'addPathMask':
      return produce(prevState, (draft) => {
        draft.esticonDirectoryMasks[action.directory].push('');
        draft.esticonDirectoryErrors[action.directory].push(null);
      });
    case 'removePathMask':
      return produce(prevState, (draft) => {
        draft.esticonDirectoryMasks[action.directory].splice(action.pathIndex, 1);
        draft.esticonDirectoryErrors[action.directory].splice(action.pathIndex, 1);
      });
    case 'setFileMask':
      return produce(prevState, (draft) => {
        draft.esticonFileMasks[action.directory] = action.mask;
      });
    case 'setFileError':
      return produce(prevState, (draft) => {
        draft.esticonFileErrors[action.directory] = action.error;
      });
    case 'setCurrency':
      return produce(prevState, (draft) => {
        draft.projectCurrencySign = action.currencySign;
      });
  }
};

export const EsticonDetail: FunctionComponent<ExternAppDetailProps> = () => {
  const project = useActiveProject();
  const { url } = useRouteMatch();

  const esticonProjectsState = useSelectorDispatch(
    (store) => store.esticonProjects,
    (dispatch) => project?.estiConnId && dispatch.esticonProjects.loadData({ reload: false }),
    [project?.estiConnId]
  );

  const esticonFirmsState = useSelectorDispatch(
    (state) => state.esticonFirms,
    (dispatch) => project?.estiConnId && dispatch.esticonFirms.loadData({ reload: false, data: project.estiConnId }),
    [project?.estiConnId]
  );

  const esticonFirm = useMemo(() => esticonFirmsState.data?.find((firm) => firm.id === project?.esticonFirmId), [
    esticonFirmsState,
    project,
  ]);

  const esticonProject = useMemo(
    () => esticonProjectsState.data?.find((esticon) => esticon.id === project?.esticonProjectId),
    [esticonProjectsState, project?.esticonProjectId]
  );

  const [currentSettings, dispatchCurrentSettings] = useReducer(esticonSettingsReducer, null);

  const [
    esticonSettings,
    esticonSettingsError,
    esticonSettingsLoading,
    _reloadEsticonSettings,
    setEsticonSettings,
  ] = useApiData(api.project.projectSetting.getEsticonSettings, {
    autoload: true,
    fetchCallback: (data) => dispatchCurrentSettings({ type: 'setData', data }),
  });

  const intl = useIntl();

  const isDirty = useMemo(
    () => esticonSettings && !isEqual(esticonSettingsToCurrentSettings(esticonSettings), currentSettings),
    [esticonSettings, currentSettings]
  );

  const [saving, setSaving] = useState<boolean>(false);

  const cleanupToken = useCancelToken('EsticonDetail: unmounting', []);

  const handleSave = useSameCallback(async () => {
    if (saving) return;

    setSaving(true);
    const [err, resp] = await api.project.projectSetting.saveEsticonSettings(currentSettings, cleanupToken);
    if (Axios.isCancel(err)) return;
    setSaving(false);

    if (err) {
      messageError(err, intl);
    } else {
      message.success(intl.formatMessage({ id: 'EsticonDetail.settingsSaveSuccess' }));
      setEsticonSettings(resp.data);
    }
  });

  const handleReset = useSameCallback(
    () => esticonSettings && dispatchCurrentSettings({ type: 'setData', data: esticonSettings })
  );

  const hasAnyError = useMemo(
    () =>
      currentSettings &&
      (Object.values(currentSettings.esticonDirectoryErrors).some((errors) => errors.some((error) => !!error)) ||
        Object.values(currentSettings.esticonFileErrors).some((error) => !!error)),
    [currentSettings]
  );

  const isToDisplay = useCallback(
    (directory: EstiCategoryEnum) => {
      if (
        directory === EstiCategoryEnum.Stavba ||
        (!esticonProject?.sdruzeni && directory === EstiCategoryEnum.CerpaniSdruzeni) ||
        (!esticonProject?.sdruzeni && directory === EstiCategoryEnum.FakturaSdruzeni)
      )
        return false;
      return true;
    },
    [esticonProject]
  );

  const handleCurrencySet = useCallback(
    async (value: string) => {
      await dispatchCurrentSettings({ type: 'setCurrency', currencySign: value });
      return true;
    },
    [dispatchCurrentSettings]
  );

  return (
    <MasterComponent
      url={url}
      maxWidth={1000}
      title={intl.formatMessage({ id: 'ProjectSettingsPage.ExternApps.Esticon.listItemTitle' })}
      children={() => (
        <ContentGate
          error={esticonFirmsState.error || esticonProjectsState.error || esticonSettingsError}
          loading={
            esticonFirmsState.loading || esticonProjectsState.loading || esticonSettingsLoading || !project?.estiConnId
          }
          empty={!esticonProject || !esticonFirm}
        >
          <StackPanel vertical stretch className={styles.mainPanel}>
            <FlowLayout alignRight>
              <SaveOrCancelButton
                onSave={handleSave}
                onCancel={handleReset}
                disabled={!isDirty}
                saveDisabled={hasAnyError}
                loading={saving}
              />
            </FlowLayout>
            <StackPanel vertical stretch scrollable className={styles.mainPanel}>
              <GeneralSettingsContainer itemsLargeGap>
                {esticonProject ? (
                  <GeneralSettingsContainer>
                    <GeneralSettingsItem
                      title={<Fmt id="ProjectSettingsPage.ExternApps.Esticon.projectName" />}
                      description={esticonProject?.name}
                    />
                    <GeneralSettingsItem
                      title={<Fmt id="ProjectSettingsPage.ExternApps.Esticon.firm" />}
                      description={esticonFirm?.nazev}
                    />
                    <GeneralSettingsItem
                      title={<Fmt id="ProjectSettingsPage.ExternApps.Esticon.projectSign" />}
                      description={esticonProject?.sign}
                    />
                    <GeneralSettingsItem
                      title={<Fmt id="ProjectSettingsPage.ExternApps.Esticon.projectId" />}
                      description={esticonProject?.id}
                    />

                    <GeneralSelectSettingsItem
                      value={esticonSettings?.projectCurrencySign}
                      onSave={handleCurrencySet}
                      headline={<Fmt id="ProjectSettingsPage.ExternApps.Esticon.currency" />}
                      options={currencyOptions}
                      disableEdit={false}
                      showSearch
                      showArrow
                      allowClear
                    />
                  </GeneralSettingsContainer>
                ) : (
                  <Typography.Text>
                    {!!esticonProjectsState.data ? (
                      <Fmt id="ProjectSettingsPage.ExternApps.Esticon.projectMissing" />
                    ) : (
                      <Fmt id="ProjectSettingsPage.ExternApps.Esticon.projectNotSet" />
                    )}
                  </Typography.Text>
                )}
                {currentSettings &&
                  ESTICON_CATEGORIES_ORDERED.filter((directory) => isToDisplay(directory)).map((directory) => (
                    <EsticonDetailDirectory
                      key={directory}
                      directory={directory}
                      currentSettings={currentSettings}
                      dispatchCurrentSettings={dispatchCurrentSettings}
                    />
                  ))}
              </GeneralSettingsContainer>
            </StackPanel>
          </StackPanel>
        </ContentGate>
      )}
    />
  );
};
