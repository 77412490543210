import { BellOutlined } from '@ant-design/icons';
import { Button, Form, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { projectApi } from 'api/completeApi';
import { DemandMsgTypeEnum, SendDemandMsgDto } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { useBoolean, useSameCallback } from 'hooks';
import { Fmt, InjectedIntl } from 'locale/Fmt';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent, ReactNode, useMemo, useState } from 'react';

import { messageError } from 'utils';

const MAP_USER_NOTIFICATION_TYPE_TO_INTL_MESSAGE_ID: Record<DemandMsgTypeEnum, IntlMessageId> = {
  [DemandMsgTypeEnum.WorkflowNodeResolveDemand]:
    'UserNotificationButton.resolver.notification.WorkflowNodeResolveDemand',
  [DemandMsgTypeEnum.AssignmentResolveDemand]: 'UserNotificationButton.resolver.notification.AssignmentResolveDemand',
};

type Props = {
  recipientIds: Guid[];
  objectId: Guid;
  demandMsgType: DemandMsgTypeEnum;
  intl: InjectedIntl;
  disabled?: boolean;
  tooltip?: ReactNode;
  className?: string;
};

export const UserNotificationButton: FunctionComponent<Props> = ({
  recipientIds,
  objectId,
  demandMsgType,
  intl,
  disabled,
  tooltip,
  className,
}) => {
  const notificationDefaultText = intl.formatMessage({
    id: MAP_USER_NOTIFICATION_TYPE_TO_INTL_MESSAGE_ID[demandMsgType],
  });
  const [notificationModalVisible, notificationModalShow, notificationModalHide] = useBoolean();
  const [notificationText, setNotificationText] = useState<string>(notificationDefaultText);
  const [loading, setLoading] = useState(false);
  const [wasNotified, setWasNotified] = useState<boolean>(false);
  const cancelConfig = useMemo(() => ({ loading }), [loading]);
  const okNotificationConfig = useMemo(() => ({ disabled: !notificationText }), [notificationText]);

  const handleNotificationModalOk = useSameCallback(async () => {
    if (!!notificationText) {
      setLoading(true);
      const data: SendDemandMsgDto = {
        message: notificationText,
        recipientIds,
        demandMsgType,
        objectId,
      };
      const [err] = await projectApi.users.senddemandmsg.post(data);
      if (err) {
        messageError(err, intl);
      } else {
        setNotificationText(notificationDefaultText);
        setWasNotified(true);
      }
      setLoading(false);
    }
    notificationModalHide();
  });

  return (
    <>
      <CommonHubTooltip
        title={
          !!tooltip ? (
            tooltip
          ) : (
            <Fmt
              id={
                wasNotified
                  ? 'UserNotificationButton.resolver.notification.wasNotified'
                  : 'UserNotificationButton.resolver.notification.title'
              }
            />
          )
        }
      >
        <Button
          onClick={() => notificationModalShow()}
          disabled={disabled || wasNotified}
          type="link"
          icon={<BellOutlined />}
          className={className}
        />
      </CommonHubTooltip>
      <Modal
        key="notification"
        confirmLoading={loading}
        cancelButtonProps={cancelConfig}
        open={notificationModalVisible}
        onCancel={notificationModalHide}
        onOk={handleNotificationModalOk}
        okButtonProps={okNotificationConfig}
        title={intl.formatMessage({ id: 'UserNotificationButton.resolver.notification.title' })}
      >
        <Form layout="vertical">
          <Form.Item
            label={intl.formatMessage({ id: 'UserNotificationButton.resolver.notification.label' })}
            required
            validateStatus={!notificationText && 'error'}
            help={!notificationText && <Fmt id="UserNotificationButton.resolver.notification.noText" />}
          >
            <TextArea value={notificationText} onChange={(e) => setNotificationText(e.currentTarget.value)} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
