import { JSONVariableTypeEnum } from 'api/completeApiInterfaces';
import { InjectedIntl } from 'locale';

export const getMetadataVariableSelectOptions = (intl: InjectedIntl) => {
  return [
    {
      value: JSONVariableTypeEnum.boolean,
      label: intl.formatMessage({ id: `ProjectTemplateMetadataDefinitionsSettingModal.variable.boolean` }),
    },
    {
      value: JSONVariableTypeEnum.date,
      label: intl.formatMessage({ id: `ProjectTemplateMetadataDefinitionsSettingModal.variable.date` }),
    },
    {
      value: JSONVariableTypeEnum.number,
      label: intl.formatMessage({ id: `ProjectTemplateMetadataDefinitionsSettingModal.variable.number` }),
    },
    {
      value: JSONVariableTypeEnum.string,
      label: intl.formatMessage({ id: `ProjectTemplateMetadataDefinitionsSettingModal.variable.string` }),
    },
    {
      value: JSONVariableTypeEnum.orgStructureWithUser,
      label: intl.formatMessage({ id: `ProjectTemplateMetadataDefinitionsSettingModal.variable.orgStructureWithUser` }),
    },
  ];
};

export const getMetadataVariableTypeText = (valueType: JSONVariableTypeEnum, intl: InjectedIntl) => {
  switch (valueType) {
    case JSONVariableTypeEnum.boolean:
      return intl.formatMessage({ id: `ProjectTemplateMetadataDefinitionsSettingModal.variable.boolean` });
    case JSONVariableTypeEnum.date:
      return intl.formatMessage({ id: `ProjectTemplateMetadataDefinitionsSettingModal.variable.date` });
    case JSONVariableTypeEnum.number:
      return intl.formatMessage({ id: `ProjectTemplateMetadataDefinitionsSettingModal.variable.number` });
    case JSONVariableTypeEnum.string:
      return intl.formatMessage({ id: `ProjectTemplateMetadataDefinitionsSettingModal.variable.string` });
    case JSONVariableTypeEnum.orgStructureWithUser:
      return intl.formatMessage({ id: `ProjectTemplateMetadataDefinitionsSettingModal.variable.orgStructureWithUser` });
    default:
      return '';
  }
};
