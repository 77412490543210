import { Column as ColumnChart } from '@ant-design/charts';
import { ColumnConfig, Column as G2plotColumn } from '@antv/g2plot';
import { masterApi } from 'api/completeApi';
import { EstiConProjectsInRealisationReportPOHResponseDto, ServiceError } from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { ChartFormatTypeEnum, exportConfig } from 'components/ProjectWidgets/commonExport';
import { useReportWidgetsContext } from 'components/Reports/contexts/ReportWidgetsContextProvider';

import { Alert } from 'antd';
import Decimal from 'decimal.js';
import { useIsMounted } from 'hooks';
import { Fmt, InjectedIntl, InjectedIntlProps } from 'locale';
import { uniq } from 'lodash';
import moment from 'moment';
import {
  ProjectsInRealizationOverviewSummedPrices,
  createProjectInRealizationRowData,
} from 'pages/ReportDetailPage/ReportDetails/ProjectsInRealisationOverviewReport/ProjectsInRealizationOverviewReportUtils';
import { useProjectsInRealizationFilter } from 'pages/ReportDetailPage/ReportDetails/ProjectsInRealisationOverviewReport/useProjectsInRealizationFilter';
import React, { useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { processApiError } from 'utils';
import { sumValuesByChildren } from 'utils/buildingGridUtils';
import { Currency, DEFAULT_CURRENCY } from 'utils/currencyGridAndDashboardUtils';
import { currencyLong } from 'utils/formatters';
import { ReportWidgetCreateFormData } from '../../../forms/ReportWidgetCreateForm';
import {
  ReportPrecision,
  ReportWidgetColumnConfiguration,
  ReportWidgetColumnConfigurationType,
  ReportWidgetDecimalUnitEnum,
} from '../../../forms/ReportWidgetCreateForm/ReportWidgetForm.utils';
import styles from './POHCharts.module.less';

export type BarData = {
  name: string;
  shortName: string;
  value: number;
  color: string;
};

export type ReportRenderer = (
  configuration: ReportWidgetCreateFormData,
  intl: InjectedIntl,
  currency: Currency
) => Promise<ColumnConfig>;

type Props = InjectedIntlProps & {
  reportConfiguration: ReportWidgetCreateFormData;
  widgetId: Guid;
  chartRef?: React.MutableRefObject<G2plotColumn | undefined>;
  exportTo?: ChartFormatTypeEnum;
};

const POHReportChart = ({ reportConfiguration, intl, chartRef, exportTo, widgetId }: Props) => {
  const isMounted = useIsMounted();

  const [isReportLoading, setReportLoading] = useState<boolean>();
  const [reportError, setReportError] = useState<ServiceError>();
  const [reportData, setReportData] = useState<EstiConProjectsInRealisationReportPOHResponseDto>();

  const { requestCacheApiData } = useReportWidgetsContext();

  const { orderedItems: filteredRows, clearFilters, setFilterValue } = useProjectsInRealizationFilter(reportData?.rows);

  useEffect(() => {
    if (!!reportConfiguration?.filters) {
      clearFilters();
      Object.entries(reportConfiguration?.filters).map((filter) => setFilterValue(filter[0], filter[1]));
    }

    if (reportConfiguration.organizationId && reportConfiguration.estiConnId && reportConfiguration.esticonFirmId) {
      setReportLoading(true);
      void requestCacheApiData(
        masterApi.EsticonReports.org.id.estiConn.id.firms.id.projectsinrealisationpoh.post,
        reportConfiguration.organizationId,
        reportConfiguration.estiConnId,
        reportConfiguration.esticonFirmId,
        {
          utcFrom: moment(reportConfiguration.reportDate)
            .utc()
            .startOf('day')
            .toISOString(),
          processedYear: reportConfiguration.startYear,
        }
      ).then((response) => {
        if (!isMounted.current) return;
        setReportLoading(false);
        const [err, resp] = response;
        if (err) {
          const serviceError = processApiError(err);
          setReportError(serviceError);
        } else {
          setReportError(undefined);
          setReportData(resp.data);
        }
      });
    }
  }, [reportConfiguration]);

  const chartConfig = useMemo(() => {
    const longFormatter = currencyLong(intl.locale, DEFAULT_CURRENCY);

    const magnitureFunction = (value: number) => {
      switch (reportConfiguration.viewDecimalUnit) {
        case ReportWidgetDecimalUnitEnum.Units:
          return value;
        case ReportWidgetDecimalUnitEnum.Thousands:
          return Decimal.div(value || 0, 1000)
            .round()
            .toNumber();
        case ReportWidgetDecimalUnitEnum.Millions:
          return Decimal.div(value || 0, 1000000)
            .round()
            .toNumber();
        case ReportWidgetDecimalUnitEnum.Billions:
          return Decimal.div(value || 0, 1000000000)
            .round()
            .toNumber();
      }
    };

    const sumColumns = reportConfiguration.columnConfigurations
      ?.filter((config) => config.type === ReportWidgetColumnConfigurationType.Sum)
      .map(
        (
          config: ReportWidgetColumnConfiguration<ProjectsInRealizationOverviewSummedPrices> & {
            type: ReportWidgetColumnConfigurationType.Sum;
          }
        ) => config.property
      );

    const projectRows = createProjectInRealizationRowData(filteredRows, ReportPrecision, (value) => value);

    const totalSum = sumValuesByChildren<ProjectsInRealizationOverviewSummedPrices>(
      projectRows,
      ReportPrecision.price,
      sumColumns || []
    );

    const barData =
      reportConfiguration.columnConfigurations?.map(
        (columnConfig): BarData => {
          if (columnConfig.type === ReportWidgetColumnConfigurationType.Sum) {
            const property = columnConfig.property;
            return {
              name: columnConfig.name,
              shortName: columnConfig.name,
              // @ts-ignore
              value: property in totalSum ? totalSum[property] : 0,
              color: columnConfig.color,
            };
          }

          return {
            name: columnConfig.name,
            shortName: columnConfig.name,
            value: parseInt(columnConfig.constant),
            color: columnConfig.color,
          };
        }
      ) || [];

    const formatter = new Intl.NumberFormat([intl.locale], {
      notation: 'standard',
    });

    const valueFormatter = (value: string | number) => {
      if (value === undefined) return undefined;
      return formatter.format(magnitureFunction(+value));
    };

    const barConfig: ColumnConfig = {
      animation: false,
      forceFit: true,
      data: barData,
      padding: [30, 15, 45, 15],
      xField: 'shortName',
      yField: 'value',
      colorField: 'color',
      color: uniq(barData.map((e) => e.color)),
      legend: { visible: false },
      tooltip: {
        formatter: (name: string, value: number) => ({
          name: name,
          value: longFormatter(value) as any,
        }),
        showTitle: false,
        fields: ['name', 'value'],
      },
      xAxis: {
        label: {
          autoRotate: false,
        },
        title: {
          visible: false,
        },
      },
      yAxis: {
        label: {
          formatter: valueFormatter,
        },
        title: {
          visible: false,
        },
        visible: false,
      },
      label: {
        visible: true,
        formatter: valueFormatter,
        style: { fontSize: 12 },
        adjustPosition: true,
        position: reportConfiguration.labelAlign || 'top',
      },
    };

    return barConfig;
  }, [intl, reportConfiguration, filteredRows]);

  return (
    <ContentGate error={reportError} loading={isReportLoading || !chartConfig}>
      <Link
        to={`/reports/${reportConfiguration.organizationId}/projectsInRealisationOverview/${reportConfiguration.estiConnId}/${reportConfiguration.esticonFirmId}/${reportConfiguration.reportDate}/${reportConfiguration.startYear}/${widgetId}`}
      >
        {!!reportConfiguration.estiConnId ? (
          <div className={styles.chart}>
            <ColumnChart key={intl.locale} {...chartConfig} />

            {exportTo && (
              <ColumnChart
                {...chartConfig}
                {...exportConfig}
                renderer={exportTo === ChartFormatTypeEnum.svg ? 'svg' : 'canvas'}
                chartRef={chartRef}
              />
            )}
          </div>
        ) : (
          <Alert
            message={<Fmt id="ReportChard.noValidConfiguration.title" />}
            description={<Fmt id="ReportChard.noValidConfiguration.description" />}
            type="warning"
          />
        )}
      </Link>
    </ContentGate>
  );
};

export default React.memo(injectIntl(POHReportChart));
