import { DownOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Button, Dropdown, DropDownProps, Empty } from 'antd';
import Transfer, { TransferItem, TransferListProps, TransferLocale } from 'antd/lib/transfer';
import { GroupListDto, ProjectUserProfileListDto } from 'api/completeApiInterfaces';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import { Dictionary } from 'lodash';
import React, { FunctionComponent, ReactNode, useCallback, useMemo, useState } from 'react';
import { ignoreRef } from 'utils';
import { Comparator, textComparer } from 'utils/comparators';
import styles from './UserTransfer.module.less';

const DROPDOWN_TRIGGER: DropDownProps['trigger'] = ['click'];

const sortByName: Comparator<ProjectUserProfileListDto> = textComparer.map((user) => user.username);

const userToTransferItem = (user: ProjectUserProfileListDto): TransferItem => ({
  key: user.id,
  title: user.username,
  description: `${user.firstname} ${user.lastname}`.trim(),
});

const renderItem = (item: TransferItem) => item.title;

const filterOption = (inputValue: string, item: TransferItem) => {
  const userName = item.title.toLowerCase();
  return userName.includes(inputValue.toLowerCase());
};

export const groupSelectUserFooter = (
  groups: GroupListDto[] | Record<Guid, GroupListDto> | null,
  users: ProjectUserProfileListDto[] | null
) => (value: Guid[], setSelectedKeys: React.Dispatch<React.SetStateAction<Guid[]>>) => {
  const availableUserIds: Set<Guid> = new Set(users?.map((user) => user.id) || []);
  return (footerProps: TransferListProps<TransferItem>) => (
    <Dropdown
      trigger={DROPDOWN_TRIGGER}
      className={styles.groupMenu}
      menu={{
        items:
          groups &&
          Object.values(groups).map((group) => ({
            key: group.id,
            label: group.name,
            onClick: () => {
              const isTarget = footerProps.direction === 'right';
              const newIds = group.userIds.filter(
                (userId) => availableUserIds.has(userId) && isTarget === (value?.includes(userId) || false)
              );
              setSelectedKeys((keys) => Array.from(new Set([...keys, ...newIds])));
            },
          })),
      }}
    >
      <Button type="link" loading={!groups}>
        <UsergroupAddOutlined />
        <Fmt id="AppUserShareFormModal.form.selectGroup" />
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

type Props = {
  users: ProjectUserProfileListDto[] | Dictionary<ProjectUserProfileListDto> | null;
  value: Guid[];
  onChange: (newValue: Guid[]) => void;
  disabled?: boolean;
  createFooter?: (
    value: Guid[],
    setSelectedKeys: React.Dispatch<React.SetStateAction<Guid[]>>
  ) => (footerProps: TransferListProps<TransferItem>) => ReactNode;
};

const UserTransferComponent: FunctionComponent<Props> = ({ users, value, onChange, disabled, createFooter }) => {
  const intl = useIntl();

  const [selectedKeys, setSelectedKeys] = useState<Guid[]>([]);

  const handleSelectChange = useCallback((sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  }, []);

  const userList = useMemo(() => (users ? Object.values(users) : []).sort(sortByName).map(userToTransferItem), [users]);

  const commonUserTransferLocale: TransferLocale = useMemo(() => {
    if (!users || !value)
      return {
        searchPlaceholder: intl.formatMessage({ id: 'UserTransfer.searchPlaceholder' }),
        itemUnit: intl.formatMessage({ id: 'UserTransfer.itemsUnit' }),
        itemsUnit: intl.formatMessage({ id: 'UserTransfer.itemsUnit' }),
        selectAll: intl.formatMessage({ id: 'UserTransfer.selectAll' }),
        selectInvert: intl.formatMessage({ id: 'UserTransfer.selectInvert' }),
        notFoundContent: <Empty />,
      };
    return {
      searchPlaceholder: intl.formatMessage({ id: 'UserTransfer.searchPlaceholder' }),
      itemUnit:
        value.length !== 1 && users.length !== 1
          ? intl.formatMessage({ id: 'UserTransfer.itemsUnit' })
          : intl.formatMessage({ id: 'UserTransfer.itemUnit' }),
      itemsUnit: intl.formatMessage({ id: 'UserTransfer.itemsUnit' }),
      selectAll: intl.formatMessage({ id: 'UserTransfer.selectAll' }),
      selectInvert: intl.formatMessage({ id: 'UserTransfer.selectInvert' }),
      notFoundContent: <Empty />,
    };
  }, [intl, users, value]);

  const titles = useMemo(
    () => [
      intl.formatMessage({ id: 'UserTransfer.sourceTitle' }),
      intl.formatMessage({ id: 'UserTransfer.targetTitle' }),
    ],
    [intl]
  );

  const footer = useMemo(() => createFooter && createFooter(value, setSelectedKeys), [createFooter, value]);

  return (
    <Transfer
      dataSource={userList}
      disabled={disabled}
      titles={titles}
      targetKeys={value}
      selectedKeys={selectedKeys}
      onChange={onChange}
      onSelectChange={handleSelectChange}
      showSearch
      render={renderItem}
      footer={footer}
      className={styles.transferList}
      filterOption={filterOption}
      locale={commonUserTransferLocale}
    />
  );
};

export const UserTransfer = React.memo(UserTransferComponent);

export const UserTransferFormItem: FunctionComponent<Omit<Props, 'value' | 'onChange'>> = ignoreRef(UserTransfer);
