import { TransferItem } from 'antd/lib/transfer';
import {
  CommentProcedureCommentDiscussionRelationEnum,
  CommentProcedureCommentNoteDto,
  CommentProcedureDto,
  CommentProcedurePhaseEnum,
  CommentProcedureRoleDto,
  CommentProcedureRoleEnum,
  CommentProcedureTeamDto,
  ProjectUserProfileListDto,
  ProjectUserProfileStatusEnum,
} from 'api/completeApiInterfaces';
import { DiscussionNoteData } from 'components/DiscussionChat/DiscussionNote/DiscussionNote';
import { uniqBy } from 'lodash';
import colors from 'styles/colors';

export const PROCESSOR_COLOR = colors['primary-color-dark'];
export const SUBMITTER_COLOR = colors['alternative-color'];

export type UserGroupForSelection = {
  groupName: string;
  mainRoleType: CommentProcedureRoleEnum | null;
  userIds: Guid[];
};

export function getTeamUsers(cpt: CommentProcedureTeamDto[]): ProjectUserProfileListDto[] {
  return cpt.flatMap((i) => i.commentProcedureUsers.filter((j) => !!j.user).map((q) => q.user));
}

export function getRoleUsers(cpr: CommentProcedureRoleDto[]): ProjectUserProfileListDto[] {
  return [
    ...cpr.filter((q) => !!q.role.user).map((q) => q.role.user),
    ...cpr
      .filter((cpRole) => cpRole.role?.roleAssociates?.length)
      .flatMap((cpRole) => cpRole.role.roleAssociates.map((role) => role.user)),
  ];
}

export function getProcessorAvailableUsers(cp: CommentProcedureDto): ProjectUserProfileListDto[] {
  return uniqBy([...getTeamUsers(cp.processorTeams), ...getRoleUsers(cp.processorRoles)], (user) => user.id).filter(
    (user) => user.status !== ProjectUserProfileStatusEnum.suspended
  );
}

export function getSubmitterAvailableUsers(cp: CommentProcedureDto): ProjectUserProfileListDto[] {
  return uniqBy([...getTeamUsers(cp.submitterTeams), ...getRoleUsers(cp.submitterRoles)], (user) => user.id).filter(
    (user) => user.status !== ProjectUserProfileStatusEnum.suspended
  );
}

export function getAllAvailableUsers(cp: CommentProcedureDto): ProjectUserProfileListDto[] {
  const availableUsersWithDuplicities = [...getTeamUsers(cp.submitterTeams), ...getRoleUsers(cp.submitterRoles)];
  if (cp.state !== CommentProcedurePhaseEnum.Commenting) {
    availableUsersWithDuplicities.push(...getTeamUsers(cp.processorTeams), ...getRoleUsers(cp.processorRoles));
  }

  return uniqBy(availableUsersWithDuplicities, (user) => user.id).filter(
    (user) => user.status !== ProjectUserProfileStatusEnum.suspended
  );
}

export function mapUsersToTransferItems(users: ProjectUserProfileListDto[]): TransferItem[] {
  return users.map((user) => {
    return { key: user.id, title: user.username };
  });
}

export function getRoleUsersForGroupSelection(
  cpr: CommentProcedureRoleDto[],
  roleType: CommentProcedureRoleEnum
): UserGroupForSelection[] {
  return [
    {
      groupName: roleType,
      mainRoleType: roleType,
      userIds: cpr.filter((i) => !!i.role.user).map((i) => i.role.user.id),
    },
  ];
}

export function getTeamUsersForGroupSelection(cpt: CommentProcedureTeamDto[]): UserGroupForSelection[] {
  return cpt.map((i) => {
    return { groupName: i.name, mainRoleType: null, userIds: i.commentProcedureUsers.map((u) => u.user.id) };
  });
}

export function getUsersForGroupSelection(cp: CommentProcedureDto): UserGroupForSelection[] {
  const usersForGroupSelection = [
    ...getRoleUsersForGroupSelection(cp.submitterRoles, CommentProcedureRoleEnum.Submitter),
    ...getTeamUsersForGroupSelection(cp.submitterTeams),
  ];
  if (cp.state !== CommentProcedurePhaseEnum.Commenting) {
    usersForGroupSelection.push(
      ...getRoleUsersForGroupSelection(cp.processorRoles, CommentProcedureRoleEnum.Processor),
      ...getTeamUsersForGroupSelection(cp.processorTeams)
    );
  }
  return usersForGroupSelection;
}

const isProcessor = (authorRelation: CommentProcedureCommentDiscussionRelationEnum) =>
  authorRelation === CommentProcedureCommentDiscussionRelationEnum.both ||
  authorRelation === CommentProcedureCommentDiscussionRelationEnum.processor;
const isSubmitter = (authorRelation: CommentProcedureCommentDiscussionRelationEnum) =>
  authorRelation === CommentProcedureCommentDiscussionRelationEnum.both ||
  authorRelation === CommentProcedureCommentDiscussionRelationEnum.submitter;

export const mapDiscussionNoteData = (note: CommentProcedureCommentNoteDto): DiscussionNoteData => {
  const { commentProcedureCommentNoteAttachments, ...rest } = note;
  const authorColors = [
    isProcessor(note.authorRelation) && PROCESSOR_COLOR,
    isSubmitter(note.authorRelation) && SUBMITTER_COLOR,
  ];
  return {
    ...rest,
    attachments: commentProcedureCommentNoteAttachments || [],
    authorColors,
  };
};
