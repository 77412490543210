import { Modal } from 'antd';
import { DirectoryReportSettingsDto } from 'api/completeApiInterfaces';
import { useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useState } from 'react';
import { DirectoryReportExportForm } from './AllDocumentsPage.ExportForm';

type Props = {
  onSubmit: (settings: DirectoryReportSettingsDto) => void;
  onClose: () => void;
  visible: boolean;
  loading?: boolean;
};

export const DirectoryReportExportFormModal: FunctionComponent<Props> = ({ onSubmit, onClose, visible, loading }) => {
  const [settings, setSettings] = useState<DirectoryReportSettingsDto>();

  const handleSubmit = useSameCallback(async () => {
    onSubmit && (await onSubmit(settings));
    onClose();
  });

  return (
    <Modal
      open={visible}
      onOk={handleSubmit}
      onCancel={onClose}
      title={<Fmt id="AllDocumentsPage.directory.report.modalTitle" />}
      okText={<Fmt id="AllDocumentsPage.directory.report.modalOkText" />}
      cancelText={<Fmt id="general.close" />}
      width={800}
      maskClosable={false}
      confirmLoading={loading}
    >
      <DirectoryReportExportForm onChange={setSettings} />
    </Modal>
  );
};

export default DirectoryReportExportFormModal;
