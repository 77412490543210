import { Form, Input, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { apiConstraints } from 'api/completeApiConstraints';
import { ProjectTemplateRoleDto, ProjectTemplateUserDto, ProjectUserProfileListDto } from 'api/completeApiInterfaces';
import { UserTransferFormItem } from 'components/UserTransfer/UserTransfer';
import { useIntl } from 'hooks';
import React, { FC, useEffect, useMemo } from 'react';
import {
  duplicateNameRuleCallback,
  maxLengthRule,
  requiredRule,
  simpleSelectFilter,
  whitespaceRule,
} from 'utils/formHelpers';

export type ProjectTemplateRoleFormData = {
  name: string;
  description: string;
  userId: string;
  associateIds?: Guid[];
};

type Props = {
  validateUniqueName: (name: string) => boolean;
  defaults?: ProjectTemplateRoleDto;
  templateUsers: ProjectTemplateUserDto[];
};

const ProjectTemplateRoleForm: FC<Props> = ({ validateUniqueName, defaults, templateUsers }) => {
  const intl = useIntl();
  const form = useFormInstance();
  const userFormValue = useWatch('userId') as Guid;
  const associateIdsFormValue = useWatch('associateIds') as Guid[];

  const availableUsersToTransfer = useMemo((): ProjectUserProfileListDto[] => {
    return templateUsers
      .filter((user) => user.id !== userFormValue)
      .map((user) => ({
        id: user.id,
        username: user.appUserOrganization.appUserProfile.username,
        firstname: user.appUserOrganization.firstname,
        lastname: user.appUserOrganization.lastname,
        status: undefined,
      }));
  }, [templateUsers, userFormValue]);

  useEffect(() => {
    form.setFieldValue(
      'associateIds',
      associateIdsFormValue?.filter((associated) => associated !== userFormValue)
    );
  }, [userFormValue]);

  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.name.label' })}
        name="name"
        rules={[
          requiredRule('forms.items.name.rules.required', true),
          maxLengthRule('general.maxNameLength'),
          duplicateNameRuleCallback(validateUniqueName),
        ]}
        initialValue={defaults?.name}
      >
        <Input placeholder={intl.formatMessage({ id: 'RoleForm.items.name.placeholder' })} autoFocus />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.description.label' })}
        name="description"
        rules={[
          whitespaceRule('forms.items.name.rules.empty'),
          {
            max: apiConstraints.projectTemplateRoleDto.description.maxLength,
            message: intl.formatMessage(
              { id: 'general.maxDescriptionLength' },
              { max: apiConstraints.projectTemplateRoleDto.description.maxLength }
            ),
          },
        ]}
        initialValue={defaults?.description}
      >
        <Input.TextArea rows={2} autoSize={{ minRows: 2 }} />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.userId.label' })}
        name="userId"
        initialValue={defaults?.templateUserId}
      >
        <Select allowClear showSearch filterOption={simpleSelectFilter}>
          {templateUsers.map((user) => (
            <Select.Option key={user.id}>{user.appUserOrganization.appUserProfile.username}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.associatedIds.label' })}
        name="associateIds"
        initialValue={defaults?.associateIds}
      >
        <UserTransferFormItem users={availableUsersToTransfer} />
      </Form.Item>
    </>
  );
};

export default ProjectTemplateRoleForm;
