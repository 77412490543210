import { CloseCircleOutlined, ContainerOutlined } from '@ant-design/icons';
import { Button, Col, Modal, ModalProps, Row, Typography } from 'antd';
import { masterApi } from 'api/completeApi';
import {
  MdCkIdentificationEnum,
  MdMeetingApprovalBackgroundDto,
  MdMeetingBackgroundMdMeetingStatusEnum,
  ProjectUserProfileStatusEnum,
  SortOrder,
} from 'api/completeApiInterfaces';
import { UserAvatar } from 'components/avatars/UserAvatar/UserAvatar';
import { UserAvatarSize } from 'components/avatars/UserIcon/UserIcon';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { DocumentsGridHeaderStyled } from 'components/DocumentsGridHeader/DocumentsGridHeaderStyled';
import { EditButton } from 'components/EditButton/EditButton';
import { createFrontendDateFilter, DateFrontendFilterType } from 'components/filters/components/DateFilter/DateFilter';
import { createFrontendMdCkIdentificationFilter } from 'components/filters/components/SelectFilter/variants/MdCkIdentificationFilter/MdCkIdentificationFilter';
import { createFrontendMdUserFilter } from 'components/filters/components/SelectFilter/variants/MdUserFilter/MdUserFilter';
import { createFrontendMultiTextFilter } from 'components/filters/components/TextFilter/TextFilter';
import { FrontendFilter } from 'components/filters/filterTypes';
import { FrontendOrderOption } from 'components/filters/orderTypes';
import { OrderFilterToolbar } from 'components/filters/render/OrderFilterToolbar/OrderFilterToolbar';
import { DownloadIcon } from 'components/Icons/HubActionsIcons';
import { MdProjectListWithUnpackedData } from 'components/JSONVariableFormItems/dataUnpackers/mdProjectUnpacker.utils';
import { SettingsBox } from 'components/SettingsBox/SettingsBox';
import StackPanel from 'components/StackPanel';
import { HIDE_BUTTON_PROPS } from 'config/constants';
import { useBoolean, useFrontendFilters, useIntl } from 'hooks';
import { Fmt } from 'locale';
import { uniqBy } from 'lodash';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { messageError } from 'utils';
import { dateComparer, numberComparer, textComparer } from 'utils/comparators';
import { modalConfirm } from 'utils/modalConfirm';
import MDMeetingBackgroundDocumentModal from '../MDMeetingBackgroundDocumentModal/MDMeetingBackgroundDocumentModal';
import MDMeetingResultDistributeFormModal from '../MDMeetingResultDistributeForm/MDMeetingResultDistributeFormModal';
import styles from './MDProjectApprovalListModal.module.less';

type Props = {
  open: boolean;
  organizationId: Guid;
  overheadProjectId: Guid;
  onClose: () => void;
  meetingApprovals: MdMeetingApprovalBackgroundDto[];
  onEditMeeting: (meetingId: Guid) => void;
  reloadMeetingApprovals: () => void;
  mdProjectList: MdProjectListWithUnpackedData[];
} & ModalProps;

const MD_APPROVAL_MEETING_DATE_FILTER_TYPES: DateFrontendFilterType<MdMeetingApprovalBackgroundDto>[] = [
  {
    key: 'meetingDate',
    label: <Fmt id="MDApprovedProjectsReport.prepareCkApprovals.list.date" />,
    valueSelector: (meetingApproval) => meetingApproval.approvalDateTime,
  },
];

const MD_APPROVAL_MEETING_ORDER_OPTIONS: FrontendOrderOption<MdMeetingApprovalBackgroundDto>[] = [
  {
    key: 'state',
    defaultOrder: SortOrder.asc,
    label: <Fmt id="general.state" />,
    compare: (a, b) =>
      (a.status === b.status ? 0 : a.status === MdMeetingBackgroundMdMeetingStatusEnum.inProcess ? -1 : 1) ||
      dateComparer(moment(b.approvalDateTime), moment(a.approvalDateTime)),
  },
  {
    key: 'meetingNumber',
    label: <Fmt id="MDApprovedProjectsReport.prepareCkApprovals.list.number" />,
    compare: numberComparer.map((meetingApproval) => meetingApproval.meetingNumber),
  },
  {
    key: 'meetingDate',
    label: <Fmt id="MDApprovedProjectsReport.prepareCkApprovals.list.date" />,
    compare: dateComparer.map((meetingApproval) => meetingApproval.approvalDateTime),
  },
  {
    key: 'referenceCode',
    label: <Fmt id="MDApprovedProjectsReport.prepareCkApprovals.list.referenceCode" />,
    compare: textComparer.map((meetingApproval) => meetingApproval.referenceCode),
  },
];

export type MeetingDocumentDetail = {
  id: Guid;
  approvalDateTime: IsoDate;
  ckIdentification: MdCkIdentificationEnum;
};

const MDProjectApprovalListModal: FunctionComponent<Props> = (props) => {
  const {
    open,
    organizationId,
    onClose,
    meetingApprovals,
    reloadMeetingApprovals,
    onEditMeeting,
    mdProjectList,
    overheadProjectId,
    ...restProps
  } = props;
  const intl = useIntl();
  const [meetingResultModalVisible, showMeetingResultModal, hideMeetingResultModal] = useBoolean(false);
  const [selectedMeetingDocumentDetail, setSelectedMeetingDocumentDetail] = useState<MeetingDocumentDetail>();
  const [meetingIdForResults, setMeetingIdForResults] = useState<Guid>();

  const mdApprovalMeetingFilters = useMemo(
    (): FrontendFilter<MdMeetingApprovalBackgroundDto>[] => [
      createFrontendMultiTextFilter(
        'text',
        (meetingApproval) => [meetingApproval.meetingNumber.toString(), meetingApproval.referenceCode],
        {
          label: <Fmt id="MDApprovedProjectsReport.filters.referenceCodeOrNumber" />,
        }
      ),
      createFrontendMdCkIdentificationFilter('ckIdentification', (meetingApproval) => meetingApproval.ckIdentification),
      createFrontendDateFilter('date', MD_APPROVAL_MEETING_DATE_FILTER_TYPES),
      createFrontendMdUserFilter(
        'author',
        (meetingApproval) => meetingApproval.createdBy.id,
        uniqBy(meetingApprovals.map((meeting) => meeting.createdBy).filter(Boolean), (user) => user.id),
        'general.createdBy'
      ),
    ],
    [meetingApprovals]
  );

  const handleMeetingResults = useCallback((meetingId: Guid) => {
    showMeetingResultModal();
    setMeetingIdForResults(meetingId);
  }, []);

  const handleMeetingResultClose = useCallback(() => {
    hideMeetingResultModal();
    setSelectedMeetingDocumentDetail(undefined);
  }, []);

  const handleMeetingResultSubmit = useCallback(() => {
    hideMeetingResultModal();
    setMeetingIdForResults(undefined);
    reloadMeetingApprovals();
  }, [reloadMeetingApprovals]);

  const meetingApprovalForResults = useMemo(() => {
    return meetingIdForResults && meetingApprovals?.find((meeting) => meeting.id === meetingIdForResults);
  }, [meetingApprovals, meetingIdForResults]);

  const handleCancel = useCallback(
    async (meetingId: Guid) => {
      !(await modalConfirm({
        title: intl.formatMessage({ id: 'MD.approvalMeetings.listModal.confirm.cancel.title' }),
        content: intl.formatMessage({ id: 'MD.approvalMeetings.listModal.confirm.cancel.description' }),
        onOk: async () => {
          const [err, res] = await masterApi.projects.md.approvalmeeting.id.id.cancel.post(organizationId, meetingId);
          if (err) {
            messageError(err, intl);
          } else {
            reloadMeetingApprovals();
          }
        },
      }));
    },
    [intl, organizationId, reloadMeetingApprovals]
  );

  const { orderedItems, ...filterProps } = useFrontendFilters<MdMeetingApprovalBackgroundDto>(
    mdApprovalMeetingFilters,
    MD_APPROVAL_MEETING_ORDER_OPTIONS,
    meetingApprovals || []
  );

  const meetingApprovalList = useMemo(() => {
    return orderedItems.map((approval, index) => {
      const {
        id,
        meetingNumber,
        approvalDateTime,
        startTime,
        endTime,
        ckIdentification,
        referenceCode,
        createdBy,
      } = approval;
      const formattedDate = moment(approvalDateTime).format('ll');
      const formattedTime =
        startTime &&
        endTime &&
        moment(startTime, 'HH:mm').format('HH:mm') + ' - ' + moment(endTime, 'HH:mm').format('HH:mm');
      const isActive = approval.status === MdMeetingBackgroundMdMeetingStatusEnum.inProcess;

      return (
        <Row key={index} gutter={6} className={styles.meetingRow}>
          <Col span={2}>{meetingNumber}</Col>
          <Col span={3}>{formattedDate}</Col>
          <Col span={4}>{formattedTime}</Col>
          <Col span={3}>
            <Fmt id={`MD.MDcreateProject.ckIdentification.${ckIdentification}`} />
          </Col>
          <Col span={7}>
            <Typography.Text ellipsis>{referenceCode}</Typography.Text>
          </Col>
          <Col span={5} className={styles.toolbar}>
            <UserAvatar
              user={{ ...createdBy, status: ProjectUserProfileStatusEnum.active }}
              size={UserAvatarSize.Small}
            />
            <EditButton onEdit={() => onEditMeeting(id)} tooltip={<Fmt id="general.edit" />} />
            <CommonHubTooltip title={<Fmt id="MD.approvalMeetings.listModal.button.downloadBackground" />}>
              <Button
                onClick={() => setSelectedMeetingDocumentDetail({ id, approvalDateTime, ckIdentification })}
                icon={<DownloadIcon />}
                type="link"
              />
            </CommonHubTooltip>
            <CommonHubTooltip title={<Fmt id="MD.approvalMeetings.listModal.button.applyResult" />}>
              <Button onClick={() => handleMeetingResults(id)} icon={<ContainerOutlined />} type="link" />
            </CommonHubTooltip>
            {isActive ? (
              <CommonHubTooltip title={<Fmt id="general.cancel" />}>
                <Button onClick={() => handleCancel(id)} icon={<CloseCircleOutlined />} type="link" danger />
              </CommonHubTooltip>
            ) : (
              <Fmt id={`MdMeetingBackgroundMdMeetingStatusEnum.${approval.status}`} />
            )}
          </Col>
        </Row>
      );
    });
  }, [handleCancel, handleMeetingResults, orderedItems, onEditMeeting]);

  return (
    <Modal
      title={<Fmt id="MDApprovedProjectsReport.prepareCkApprovals.list" />}
      open={open}
      okButtonProps={HIDE_BUTTON_PROPS}
      cancelText={<Fmt id="general.close" />}
      onCancel={onClose}
      width={1000}
      {...restProps}
    >
      <DocumentsGridHeaderStyled>
        <OrderFilterToolbar alignRight {...filterProps} />
      </DocumentsGridHeaderStyled>
      <SettingsBox>
        <Row gutter={6}>
          <Col span={2}>
            <Fmt id="MDApprovedProjectsReport.prepareCkApprovals.list.number" />
          </Col>
          <Col span={3}>
            <Fmt id="MDApprovedProjectsReport.prepareCkApprovals.list.date" />
          </Col>
          <Col span={4}>
            <Fmt id="MDApprovedProjectsReport.prepareCkApprovals.list.time" />
          </Col>
          <Col span={3}>
            <Fmt id="MDApprovedProjectsReport.prepareCkApprovals.list.ckIdentification" />
          </Col>
          <Col span={7}>
            <Fmt id="MDApprovedProjectsReport.prepareCkApprovals.list.referenceCode" />
          </Col>
          <Col span={5}></Col>
        </Row>
      </SettingsBox>
      <StackPanel vertical scrollable className={styles.meetingList}>
        {meetingApprovalList}
      </StackPanel>
      <MDMeetingResultDistributeFormModal
        organizationId={organizationId}
        meetingApproval={meetingApprovalForResults}
        mdProjectList={mdProjectList}
        onSubmit={handleMeetingResultSubmit}
        onClose={handleMeetingResultClose}
        open={meetingResultModalVisible}
      />
      <MDMeetingBackgroundDocumentModal
        organizationId={organizationId}
        meetingDocumentDetail={selectedMeetingDocumentDetail}
        overheadProjectId={overheadProjectId}
        onClose={handleMeetingResultClose}
        open={!!selectedMeetingDocumentDetail}
      />
    </Modal>
  );
};

export default MDProjectApprovalListModal;
