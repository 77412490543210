import { ConfigProvider } from 'antd';
import { Locale as AntdLocale } from 'antd/lib/locale-provider';
import csAntdLocale from 'antd/lib/locale/cs_CZ';
import deAntdLocale from 'antd/lib/locale/de_DE';
import enAntdLocale from 'antd/lib/locale/en_GB';
import { locale as devExtremeLocale } from 'devextreme/localization';
import { useStoreSelector } from 'hooks';
import { csMessages, IntlMessagesShape } from 'locale/messages/cs';
import { deMessages } from 'locale/messages/de';
import { enMessages } from 'locale/messages/en';
import moment from 'moment';
import 'moment/locale/cs';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import React, { FunctionComponent, useMemo } from 'react';
import { addLocaleData, IntlProvider } from 'react-intl';
import csLocaleData from 'react-intl/locale-data/cs';
import deLocaleData from 'react-intl/locale-data/de';
import enLocaleData from 'react-intl/locale-data/en';
import IntlContextProvider from './IntlContextProvider';
import { LocaleMap } from './messages/interfaces';

const antdLocales: LocaleMap<AntdLocale> = {
  cs: csAntdLocale,
  en: enAntdLocale,
  de: deAntdLocale,
};

const OK = 'OK';
csAntdLocale.Modal.justOkText = OK;
csAntdLocale.Modal.okText = OK;
csAntdLocale.Popconfirm.okText = OK;

const messages: LocaleMap<IntlMessagesShape> = {
  cs: csMessages,
  en: enMessages,
  de: deMessages,
};

addLocaleData(csLocaleData);
addLocaleData(enLocaleData);
addLocaleData(deLocaleData);

export const Locale: FunctionComponent = ({ children }) => {
  const locale = useStoreSelector((state) => state.appConfig.locale);

  // This must be set *synchronously*, so that when children are rendered,
  // they see the updated value immediately, so use memo instead of effect
  useMemo(() => {
    moment.locale(locale);
    // From documentation: "This method should be called only once - at the application's launch.
    // It is necessary to reload the page each time you need to set new locale."
    // https://js.devexpress.com/Documentation/ApiReference/Common/Utils/localization/#localelocale
    devExtremeLocale(locale);
  }, [locale]);

  return (
    <ConfigProvider locale={antdLocales[locale]}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <IntlContextProvider>{children}</IntlContextProvider>
      </IntlProvider>
    </ConfigProvider>
  );
};
