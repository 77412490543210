import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Input, InputRef, Radio, Select, Tabs, TabsProps } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { masterApi } from 'api/completeApi';
import { OrganizationAdminReportDto, ProjectTimezoneListDto } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { OrganizationLabelsInput } from 'components/LabelsInput/OrganizationLabelsInput';
import {
  DEFAULT_LOCALE,
  DEFAULT_PROJECT_TIMEZONE_LINUX,
  DEFAULT_PROJECT_TIMEZONE_WINDOWS,
  MAX_PROJECT_DESCRIPTION_LENGTH,
  MAX_PROJECT_NAME_LENGTH,
} from 'config/constants';
import { useApiData, useIntl, useSelectorDispatch } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import { LanguageEnum } from 'locale/messages/interfaces';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { duplicateNameRule, maxLengthRule, requiredRule, simpleSelectFilter } from 'utils/formHelpers';
import { AppUsersFormItem, SelectableUsersSourceEnum, UserAttributes } from '../AppUsersAddFormModal/AppUsersFormItem';
import { noAdminInAppUsersItem } from '../OrganizationAddFormModal';

export enum ProjectSource {
  NewProject = 'NewProject',
  FromTemplate = 'FromTemplate',
}

export type ProjectCreateFormData = {
  name: string;
  description: string;
  organizationId: Guid;
  storageArea: string;
  labels: Guid[];
  usersToAddWithAttributes: Record<string, UserAttributes>;
  defaultExtendedPermissionId?: Guid;
} & (
  | {
      type: ProjectSource.NewProject;
      language: LanguageEnum;
      timeZoneId: string;
    }
  | {
      type: ProjectSource.FromTemplate;
      templateId: Guid;
    }
);

type Props = {
  defaults?: {
    language?: LanguageEnum;
  };
  timeZones: ProjectTimezoneListDto;
  organizations: OrganizationAdminReportDto[];
  setRef: (ref: InputRef) => void;
};

const ProjectCreateForm: FC<Props> = ({ defaults, timeZones, organizations, setRef }) => {
  const intl = useIntl();
  const form = useFormInstance();
  const [orgStorageAreas, setOrgStorageAreas] = useState<string[]>();

  const projects = useSelectorDispatch(
    (state) => state.allProjects.data,
    (dispatch) => dispatch.allProjects.loadData({ reload: false }),
    []
  );

  useDirtyStoreReload(
    (state) => state.allProjects,
    (dispatch) => dispatch.allProjects
  );

  const selectedOrganizationId = useWatch('organizationId');
  const type = useWatch('type');
  const activeSourceType = type || ProjectSource.NewProject;
  const selectedTemplateId = useWatch('templateId');

  useEffect(() => {
    form.setFieldValue('storageArea', orgStorageAreas?.length === 1 ? orgStorageAreas[0] : undefined);
  }, [orgStorageAreas, selectedOrganizationId]);

  const [orgTemplates, orgTemplatesError, orgTemplatesLoading, loadOrgTemplates] = useApiData(
    (ct) => masterApi.projects.tempates.projecttemplatelist.id.get(selectedOrganizationId, ct),
    { autoload: false }
  );

  const [permissionProfiles, permissionProfilesError, permissionProfilesLoading, loadPermissionProfiles] = useApiData(
    (ct) => masterApi.projects.tempates.extendedpermissiontemplate.id.get(selectedOrganizationId, ct),
    {
      autoload: false,
    }
  );

  useEffect(() => {
    loadPermissionProfiles();
    form.resetFields(['defaultExtendedPermissionId']);
  }, [selectedOrganizationId]);

  const organizationPermissionProfilesOptions = useMemo(() => {
    return (
      permissionProfiles?.map((permissionProfile) => ({
        value: permissionProfile.id,
        label: permissionProfile.name,
      })) || []
    );
  }, [permissionProfiles]);

  useEffect(() => {
    if (!!selectedOrganizationId) {
      loadOrgTemplates();
      form.setFieldsValue({ templateId: undefined });
      const selectedOrgStorageAreas = organizations.find((org) => org.id === selectedOrganizationId).defaultStorageArea;
      setOrgStorageAreas(selectedOrgStorageAreas);
    }
  }, [selectedOrganizationId]);

  const organizationUsers = useMemo(() => {
    if (!selectedOrganizationId)
      return (!!organizations && organizations[0].organizationUsers.map((orgUser) => orgUser.appUserProfile)) || [];
    return organizations
      .find((org) => org.id === selectedOrganizationId)
      ?.organizationUsers.map((orgUser) => orgUser.appUserProfile);
  }, [selectedOrganizationId, organizations]);

  const usedNames = projects?.projects?.map((project) => project.name) || [];

  const usersValidation = useCallback(
    (rule: RuleObject, value: StoreValue) => {
      if (type === ProjectSource.FromTemplate) return Promise.resolve(); // TODO: validate if it is correct to be resolved
      if (noAdminInAppUsersItem(value)) {
        return Promise.reject(intl.formatMessage({ id: 'OrganizationAddForm.required' }));
      }
      return Promise.resolve();
    },
    [intl, type]
  );

  useEffect(() => {
    form.validateFields(['templateId']);
  }, [selectedTemplateId]);

  useEffect(() => {
    form.validateFields(['usersToAddWithAttributes']);
  }, [activeSourceType]);

  const items: TabsProps['items'] = [
    {
      key: ProjectSource.NewProject,
      label: <Fmt id="ProjectCreateForm.form.tabs.newProject" />,
      children: activeSourceType === ProjectSource.NewProject && (
        <>
          <Form.Item
            label={intl.formatMessage({ id: 'general.defaultExtendedPermission' })}
            name="defaultExtendedPermissionId"
          >
            <Select
              showSearch
              allowClear
              loading={permissionProfilesLoading}
              filterOption={simpleSelectFilter}
              options={organizationPermissionProfilesOptions}
            />
          </Form.Item>
          <Form.Item
            label={
              <span>
                <Fmt id="ProjectCreateForm.form.items.language.label" />
                <CommonHubTooltip
                  title={intl.formatMessage({ id: 'ProjectCreateForm.form.items.language.tooltip' })}
                  placement="right"
                >
                  <QuestionCircleOutlined style={{ paddingLeft: '8px' }} />
                </CommonHubTooltip>
              </span>
            }
            name="language"
            initialValue={defaults?.language || LanguageEnum[DEFAULT_LOCALE] || LanguageEnum.cs}
          >
            <Radio.Group>
              <Radio.Button value={LanguageEnum.cs}>
                {intl.formatMessage({ id: 'ProjectCreateForm.form.items.language.cs' })}
              </Radio.Button>
              <Radio.Button value={LanguageEnum.en}>
                {intl.formatMessage({ id: 'ProjectCreateForm.form.items.language.en' })}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ id: 'ProjectCreateForm.form.timeZone' })}
            name="timeZoneId"
            initialValue={
              timeZones.timeZones.some((tz) => tz.id === DEFAULT_PROJECT_TIMEZONE_WINDOWS)
                ? DEFAULT_PROJECT_TIMEZONE_WINDOWS
                : DEFAULT_PROJECT_TIMEZONE_LINUX
            }
            rules={[requiredRule('ProjectCreateForm.form.timeZone.rules.required')]}
          >
            <Select showSearch allowClear filterOption={simpleSelectFilter}>
              {timeZones?.timeZones.map((tz) => (
                <Select.Option key={tz.id} value={tz.id}>
                  {tz.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </>
      ),
    },
    {
      key: ProjectSource.FromTemplate,
      label: <Fmt id="ProjectCreateForm.form.tabs.fromTemplate" />,
      disabled: !orgTemplates?.length || orgTemplatesLoading,
      children: activeSourceType === ProjectSource.FromTemplate && (
        <ContentGate error={orgTemplatesError}>
          <Form.Item
            label={intl.formatMessage({ id: 'ProjectCreateForm.form.template' })}
            name="templateId"
            rules={[requiredRule('ProjectCreateForm.form.template.rules.required')]}
          >
            <Select showSearch allowClear filterOption={simpleSelectFilter}>
              {orgTemplates?.map((template) => (
                <Select.Option key={template.id} value={template.id}>
                  {template.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </ContentGate>
      ),
    },
  ];

  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.name.label' })}
        name="name"
        rules={[
          requiredRule('ProjectCreateForm.form.items.name.rules.required', true),
          maxLengthRule('general.maxNameLength', MAX_PROJECT_NAME_LENGTH),
          duplicateNameRule('forms.items.name.rules.nameExists', usedNames, true),
        ]}
      >
        <Input
          placeholder={intl.formatMessage({ id: 'ProjectCreateForm.form.items.name.placeholder' })}
          autoFocus
          ref={setRef}
        />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.description.label' })}
        name="description"
        rules={[
          {
            max: MAX_PROJECT_DESCRIPTION_LENGTH,
            message: intl.formatMessage(
              { id: 'general.maxDescriptionLength' },
              { max: MAX_PROJECT_DESCRIPTION_LENGTH }
            ),
          },
        ]}
      >
        <Input.TextArea
          rows={3}
          autoSize={{ minRows: 3 }}
          placeholder={intl.formatMessage({ id: 'ProjectCreateForm.form.items.description.placeholder' })}
        />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'general.organizations' })}
        name="organizationId"
        initialValue={organizations?.length && organizations[0].id}
      >
        <Select showSearch allowClear={false} filterOption={simpleSelectFilter}>
          {organizations?.map((organization) => (
            <Select.Option key={organization.id} value={organization.id}>
              {organization.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {!!orgStorageAreas?.length && (
        <Form.Item
          label={intl.formatMessage({ id: 'general.storagearea' })}
          name="storageArea"
          initialValue={orgStorageAreas.length === 1 ? orgStorageAreas[0] : undefined}
          rules={[requiredRule('forms.items.rules.required', true)]}
        >
          <Select>
            {orgStorageAreas.map((area) => (
              <Select.Option key={area}>{area}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item
        label={intl.formatMessage({ id: 'general.labels' })}
        name="labels"
        initialValue={selectedOrganizationId}
      >
        <OrganizationLabelsInput organizationId={selectedOrganizationId} />
      </Form.Item>
      <Form.Item name="type" initialValue={ProjectSource.NewProject} valuePropName="activeKey">
        <Tabs items={items} />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'general.users' })}
        name="usersToAddWithAttributes"
        rules={[
          {
            validator: usersValidation,
          },
        ]}
      >
        <AppUsersFormItem
          selectableAppUsers={organizationUsers}
          selectableUsersToAddSource={SelectableUsersSourceEnum.organization}
        />
      </Form.Item>
    </>
  );
};

export default ProjectCreateForm;
