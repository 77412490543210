import {
  CalendarSettingsForm,
  CalendarSettingsFormConfiguration,
  CalendarSettingsFormData,
} from 'components/forms/CalendarSettingsForm/CalendarSettingsForm';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper } from 'components/forms/FormModalWrapper';
import { useSameCallback } from 'hooks/useSameCallback';
import { InjectedIntlProps } from 'locale/Fmt';
import React, { FunctionComponent } from 'react';

type Props = FormModalProps<CalendarSettingsFormData, void> & InjectedIntlProps & CalendarSettingsFormConfiguration;

export const ProjectTemplateCalendarSettingsFormModal: FunctionComponent<Props> = ({
  onSubmit,
  onClose,
  initialCalendar,
  ...restProps
}) => {
  const handleSubmit = useSameCallback(async (values: CalendarSettingsFormData) => {
    if (!values) {
      return null;
    }

    onSubmit && (await onSubmit(values));
    return null;
  });

  return (
    <>
      <FormModalWrapper
        onSubmit={handleSubmit}
        onClose={onClose}
        titleId={'CalendarSettingsFormModal.title.template'}
        width="700px"
        {...restProps}
      >
        <CalendarSettingsForm initialCalendar={initialCalendar} />
      </FormModalWrapper>
    </>
  );
};
