import { CalendarOutlined, FieldTimeOutlined, HomeOutlined, ProfileOutlined, TagsOutlined } from '@ant-design/icons';
import { api } from 'api';
import { masterApi, projectApi } from 'api/completeApi';
import { apiConstraints } from 'api/completeApiConstraints';
import { ProjectDefaultSettingsDto, ProjectTimezoneDto } from 'api/completeApiInterfaces';
import DisplayName from 'components/DisplayName';
import { CalendarSettingsFormButton } from 'components/forms/CalendarSettingsForm/CalendarSettingsFormEditButton';
import { ERPDataImportButton } from 'components/forms/ERPDataImportButton/ERPDataImportButton';
import { UserActivityReportExportButton } from 'components/forms/UserActivityReportExportForm/UserActivityReportExportButton';
import GeneralSelectSettingsItem from 'components/GeneralSelectSettingsItem/GeneralSelectSettingsItem';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import GeneralTextAreaSettingsItem from 'components/GeneralTextAreaSettingsItem/GeneralTextAreaSettingsItem';
import GeneralTextSettingsItem from 'components/GeneralTextSettingsItem/GeneralTextSettingsItem';
import Label from 'components/Label/Label';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import StackPanel from 'components/StackPanel';
import { MAX_PROJECT_DESCRIPTION_LENGTH, MAX_PROJECT_NAME_LENGTH } from 'config/constants';
import { useActiveProject, useApiData, useCurrentProjectUser, useDispatchEffect } from 'hooks';
import { useDirtyStoreReloadCallback } from 'hooks/useSelectorDispatch';
import { Fmt, InjectedIntlProps } from 'locale';
import { LanguageEnum } from 'locale/messages/interfaces';
import { DocumentationLevelItem } from 'pages/ProjectSettingsPage/ProjectSettingsPageGeneral/DocumentationLevelItem';
import { ProjectCardDatesItem } from 'pages/ProjectSettingsPage/ProjectSettingsPageGeneral/ProjectCardDatesItem';
import { ProjectCardMarkingItem } from 'pages/ProjectSettingsPage/ProjectSettingsPageGeneral/ProjectCardMarkingItem';
import { ProjectSettingsProjectCardData } from 'pages/ProjectSettingsPage/ProjectSettingsPageGeneral/ProjectSettingsProjectCardData';
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Dispatch } from 'store';
import { organizationLabelsListSelector } from 'store/selectors/organizationLabelsSelectors';
import { processApiError } from 'utils';
import AdministrationMetadataExportButton from '../Administration/AdministrationMetadataExportButton';
import Panel from '../Panel';
import { CommentProcedureRolesSettings } from './CommentProcedureRolesSettings';

function checkLength(name: string, maxLength: number): boolean {
  if (name == null) return true;
  return name.length <= maxLength;
}

type Props = InjectedIntlProps & {
  url: string;
  selectedItemId: string;
};

const ProjectSettingsPageGeneral: FunctionComponent<Props> = (props) => {
  const project = useActiveProject();
  const dispatch = useDispatch<Dispatch>();
  const setActiveProject = dispatch.activeProject.setActiveProject;
  const currentUser = useCurrentProjectUser();
  const disableEdit = !currentUser.isAdmin;

  const { intl } = props;
  const lang: LanguageEnum = useMemo(() => intl.locale, [intl]);
  const { url } = useRouteMatch();

  const [permissionProfiles, permissionProfilesError, permissionProfilesLoading, loadPermissionProfiles] = useApiData(
    (ct) => masterApi.projects.tempates.extendedpermissiontemplate.id.get(project.organization.id, ct),
    {
      autoload: true,
    }
  );

  const [
    defaultPermissionProfile,
    defaultPermissionProfileError,
    defaultPermissionProfileLoading,
    loadDefaultPermissionProfile,
    setDefaultPermissionProfile,
  ] = useApiData((ct) => projectApi.projectsetting.projectdefault.get(ct), {
    autoload: true,
  });

  const organizationPermissionProfilesOptions = useMemo(() => {
    return (
      permissionProfiles?.map((permissionProfiles) => ({
        value: permissionProfiles.id,
        text: permissionProfiles.name,
      })) || []
    );
  }, [permissionProfiles]);

  const [timeZones, timeZonesError, timeZonesLoading, loadTimeZones] = useApiData(
    (ct) => api.master.projects.getTimeZones(lang, ct),
    { autoload: false }
  );

  useEffect(() => {
    loadTimeZones();
  }, [lang]);

  useDispatchEffect(
    (dispatch) =>
      project?.organization.id &&
      dispatch.organizationLabels.loadData({ reload: true, data: [project?.organization.id] }),
    [project?.organization.id]
  );
  useDirtyStoreReloadCallback(
    (store) => store.organizationLabels,
    (dispatch) => dispatch.organizationLabels.loadData({ reload: true, data: [project?.organization.id] })
  );
  const orgLabelsList = useSelector(organizationLabelsListSelector);

  const getTimeZoneObject = useCallback(
    (timeZoneId: string): ProjectTimezoneDto => {
      return timeZones?.timeZones.find((tz) => tz.id === timeZoneId);
    },
    [timeZones]
  );

  const selectOptions = useMemo(() => {
    return timeZones?.timeZones.map((tz) => ({ value: tz.id, text: tz.name })) || [];
  }, [timeZones]);

  const labelsSelectOptions = useMemo(() => {
    return (
      orgLabelsList?.map((label) => ({
        value: label.id,
        text: (
          <Label color={label.color}>
            <DisplayName>{label.name}</DisplayName>
          </Label>
        ),
      })) || []
    );
  }, [orgLabelsList]);

  const onEditName = useCallback(
    async (name: string): Promise<boolean | ReactNode> => {
      if (name != null && checkLength(name, MAX_PROJECT_NAME_LENGTH) && name.length > 0) {
        if (name !== project.name) {
          const id = project.id;
          const [err] = await api.master.projects.patchProject(id, {
            name,
          });
          if (!err) {
            setActiveProject({ ...project, name });
            return true;
          } else {
            return props.intl.formatMessage({
              id: `serviceError.${processApiError(err).referenceErrorCode}`,
            });
          }
        }
        return true;
      }
      return !name?.length
        ? props.intl.formatMessage({ id: 'Project.name.empty' })
        : props.intl.formatMessage({ id: 'general.maxNameLength' }, { max: MAX_PROJECT_NAME_LENGTH });
    },
    [setActiveProject, project, intl]
  );

  const onEditDescription = useCallback(
    async (description: string): Promise<boolean | ReactNode> => {
      if (!description && !project.description) return true;
      if (checkLength(description, MAX_PROJECT_DESCRIPTION_LENGTH)) {
        if (description !== project.description) {
          const id = project.id;
          const [err] = await api.master.projects.patchProject(id, {
            description,
          });
          if (!err) {
            setActiveProject({ ...project, description });
            return true;
          } else {
            return props.intl.formatMessage({
              id: `serviceError.${processApiError(err).referenceErrorCode}`,
            });
          }
        }
        return true;
      }
      return props.intl.formatMessage({ id: 'general.maxDescriptionLength' }, { max: MAX_PROJECT_DESCRIPTION_LENGTH });
    },
    [setActiveProject, project, intl]
  );

  const onEditDefaultPermissionProfile = useCallback(
    async (defaultExtendedPermissionId: Guid) => {
      const defaultExtendedPermissionName = permissionProfiles.find(
        (permission) => permission.id === defaultExtendedPermissionId
      )?.name;
      const data: ProjectDefaultSettingsDto = { defaultExtendedPermissionId, defaultExtendedPermissionName };
      const [err] = await projectApi.projectsetting.projectdefault.patch(data);
      if (err) return err;
      setDefaultPermissionProfile(data);
      return null;
    },
    [permissionProfiles, setDefaultPermissionProfile]
  );

  const onEditTimezone = useCallback(
    async (timeZoneId: string): Promise<boolean | ReactNode> => {
      if (timeZoneId !== project.timeZone.id) {
        const id = project.id;
        const [err] = await api.master.projects.patchProject(id, {
          timeZoneId,
        });
        if (!err) {
          const newTimeZone = getTimeZoneObject(timeZoneId);
          setActiveProject({ ...project, timeZone: newTimeZone });
          return true;
        } else {
          return props.intl.formatMessage({
            id: `serviceError.${processApiError(err).referenceErrorCode}`,
          });
        }
      }
      return true;
    },
    [setActiveProject, project, getTimeZoneObject, intl]
  );

  const onEditLabels = useCallback(
    async (labels: Guid[]): Promise<boolean | ReactNode> => {
      const id = project.id;
      const [err] = await api.master.projects.patchProject(id, {
        orgLabels: labels,
      });
      if (!err) {
        setActiveProject({ ...project, labels: labels });
        return true;
      } else {
        return props.intl.formatMessage({
          id: `serviceError.${processApiError(err).referenceErrorCode}`,
        });
      }
    },
    [project]
  );

  return (
    <>
      <MasterComponent
        url={url}
        title={intl.formatMessage({ id: 'general.general' })}
        children={(onSelect, selectedItemId) => (
          <StackPanel vertical scrollable>
            <Panel hideToolbar panelWidth="auto" noMargin>
              <GeneralSettingsContainer itemsLargeGap>
                <GeneralSettingsContainer>
                  <GeneralTextSettingsItem
                    value={project.name}
                    onSave={onEditName}
                    disableEdit={disableEdit}
                    headline={<Fmt id="ProjectSettingsPageGeneral.name" />}
                    maxLength={apiConstraints.projectPatchDto.name.maxLength}
                  />
                  <GeneralTextAreaSettingsItem
                    value={project.description}
                    disableEdit={disableEdit}
                    onSave={onEditDescription}
                    headline={<Fmt id="ProjectSettingsPageGeneral.description" />}
                    maxLength={apiConstraints.projectPatchDto.description.maxLength}
                  />
                  <ProjectCardMarkingItem
                    disableEdit={disableEdit}
                    project={project}
                    setActiveProject={setActiveProject}
                  />
                  <DocumentationLevelItem
                    disableEdit={disableEdit}
                    project={project}
                    setActiveProject={setActiveProject}
                  />
                  <ProjectCardDatesItem
                    disableEdit={disableEdit}
                    project={project}
                    setActiveProject={setActiveProject}
                  />
                  <GeneralSelectSettingsItem
                    value={defaultPermissionProfile?.defaultExtendedPermissionId}
                    onSave={onEditDefaultPermissionProfile}
                    headline={<Fmt id="general.defaultExtendedPermission" />}
                    options={organizationPermissionProfilesOptions}
                    disableEdit={disableEdit}
                    showSearch
                    loading={defaultPermissionProfileLoading}
                    allowClear
                  />
                  {!!project.storageArea && (
                    <GeneralTextAreaSettingsItem
                      icon={<HomeOutlined />}
                      value={project.storageArea}
                      disableEdit // TODO: why is this disabled?
                      headline={<Fmt id="general.storagearea" />}
                    />
                  )}
                  <GeneralSelectSettingsItem
                    icon={<FieldTimeOutlined />}
                    value={project.timeZone?.id}
                    onSave={onEditTimezone}
                    headline={<Fmt id="ProjectCreateForm.form.timeZone" />}
                    options={selectOptions}
                    disableEdit={disableEdit}
                    showSearch
                  />
                  <GeneralSettingsItem
                    title={intl.formatMessage({ id: 'general.workDaysCalendar' })}
                    disabled={disableEdit}
                    icon={<CalendarOutlined />}
                    input={
                      <CalendarSettingsFormButton
                        organizationId={project.organization.id}
                        projectId={project.id}
                        editDisabled={disableEdit}
                        buttonType="link"
                      />
                    }
                  />
                  <GeneralSelectSettingsItem
                    icon={<TagsOutlined />}
                    value={project.labels}
                    onSave={onEditLabels}
                    headline={<Fmt id="general.organizationLabels" />}
                    options={labelsSelectOptions}
                    disableEdit={disableEdit}
                    mode="multiple"
                    showSearch
                    showArrow
                  />

                  <GeneralSettingsItem
                    title={intl.formatMessage({ id: 'OrgProjectCardData.title' })}
                    icon={<ProfileOutlined />}
                    selectable
                    selected={selectedItemId === 'projectCardData'}
                    disabled={disableEdit}
                    onClick={() => {
                      onSelect('projectCardData');
                    }}
                  />
                </GeneralSettingsContainer>
                <CommentProcedureRolesSettings />
                {currentUser.isAdmin && (
                  <>
                    <GeneralSettingsContainer title={<Fmt id="ProjectSettingsPageGeneral.exports" />}>
                      <GeneralSettingsItem
                        title={<Fmt id="ProjectSettingsPageGeneral.exportUsersAndProjectSetting" />}
                        input={<UserActivityReportExportButton currentUser={currentUser} />}
                      />
                      <GeneralSettingsItem
                        title={<Fmt id="Administration.downloadDMSMetaData.buttonTitle" />}
                        input={<AdministrationMetadataExportButton currentUser={currentUser} />}
                      />
                    </GeneralSettingsContainer>
                    <GeneralSettingsContainer title={<Fmt id="ProjectSettingsPageGeneral.imports" />}>
                      <GeneralSettingsItem
                        title={<Fmt id="ProjectSettingsPageGeneral.importConnectedERPData" />}
                        input={<ERPDataImportButton projectId={project.id} intl={intl} />}
                      />
                    </GeneralSettingsContainer>
                  </>
                )}
              </GeneralSettingsContainer>
            </Panel>
          </StackPanel>
        )}
      />
      <Switch>
        <Route path={`${url}/projectCardData`}>
          <ProjectSettingsProjectCardData />
        </Route>
      </Switch>
    </>
  );
};

export default injectIntl(ProjectSettingsPageGeneral);
