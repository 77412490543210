import { Form, Input } from 'antd';
import { FiltersFormItem } from 'components/filters/components/FiltersFormItem/FiltersFormItem';
import { CommonFilterWithValue, Serializable } from 'components/filters/filterTypes';
import { Fmt } from 'locale';
import React, { FC } from 'react';

export type FilterPresetEditFormData = {
  presetName: string;
  filters: Record<string, Serializable>;
};

type Props = {
  presetName: string;
  presetValue: Record<string, Serializable>;
  filters: CommonFilterWithValue[];
};

const EMPTY_FILTER_FUNCTION = (value: any) => (item: any) => true;

const FilterPresetEditFormComponent: FC<Props> = ({ presetName, presetValue, filters }) => {
  return (
    <>
      <Form.Item name="presetName" initialValue={presetName} label={<Fmt id="general.name" />}>
        <Input />
      </Form.Item>
      <Form.Item name="filters" initialValue={presetValue} label={<Fmt id="FilterPresetEditForm.filters.label" />}>
        <FiltersFormItem filters={filters.map((filter) => ({ filter: EMPTY_FILTER_FUNCTION, ...filter }))} />
      </Form.Item>
    </>
  );
};

export default FilterPresetEditFormComponent;
