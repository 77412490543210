import { message } from 'antd';
import { api } from 'api';
import { DirectoryLinkCreateDto, DirectoryListDto } from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import { transformError } from 'components/DocumentMultipleActionError';
import { IMultipleDocumentErrorData } from 'components/DocumentMultipleActionError/DocumentMultipleActionError';
import { memoizeWithIntl } from 'locale';
import { trim } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { processApiError } from 'utils';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import DirectoryCreateLinkForm from './DirectoryCreateLinkForm';

type Props = InjectedIntlProps &
  FormModalProps<DirectoryListDto[]> & {
    selectedDirectory: DirectoryListDto;
    onSubmit: () => void;
  };

const DirectoryCreateLinkFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, selectedDirectory, ...restProps } = props;

  const [errors, setErrors] = useState<JSX.Element[]>([]);

  const handleSubmit: FormSubmitHandler<DirectoryLinkCreateDto> = async (values) => {
    const data: DirectoryLinkCreateDto = {
      name: trim(values.name),
      directoryId: values.directoryId,
      linkedDirectoryId: selectedDirectory.id,
    };
    const [err] = await api.project.directories.createDirectoryLink(data);
    if (err) {
      const error = processApiError(err);
      if (!!error?.errorData) {
        switch (error.referenceErrorCode) {
          case ServiceErrorEnum.DocumentMultipleMoveObstacleError: {
            const errData = error.errorData as IMultipleDocumentErrorData;
            setErrors(errData.documentsErrors.map(transformError));
            return null;
          }
        }
      }
      message.error(props.intl.formatMessage({ id: `serviceError.${error.referenceErrorCode}` }));
      return null;
    }
    await onSubmit();
    return null;
  };

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId="DirectoryCreateLinkFormModal.title"
      confirmClose={false}
      layout="vertical"
      {...restProps}
    >
      <DirectoryCreateLinkForm selectedDirectory={selectedDirectory} errors={errors} />
    </FormModalWrapper>
  );
};

export default memoizeWithIntl(DirectoryCreateLinkFormModal);
