import { Modal, Select, Typography } from 'antd';
import { api } from 'api';
import { masterApi } from 'api/completeApi';
import { JSONVariableTypeEnum, ProjectCardDataDto } from 'api/completeApiInterfaces';
import { SPACE_FOR_SCROLLBAR_COLUMN } from 'components/BudgetGrid/BudgetGrid';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { DocumentsGridHeaderStyled } from 'components/DocumentsGridHeader/DocumentsGridHeaderStyled';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { FiltersContextProvider } from 'components/filters/FiltersContextProvider';
import { FiltersPersistentKey } from 'components/filters/filterTypes';
import { BuildingIdentificationDetail, JSONVariable } from 'components/JSONVariableFormItems/JSONVariableTypes';
import { FlowLayout } from 'components/layouts/FlowLayout';
import SideMenuActivator, { SideMenuKey } from 'components/SideMenuActivator';
import { EMPTY_GUID, LARGE_SETTING_MODAL_WIDTH } from 'config/constants';
import { Template } from 'devextreme-react';
import { FilterRow, HeaderFilter, TreeList } from 'devextreme-react/tree-list';
import { locale as devExtremeLocale, loadMessages } from 'devextreme/localization';
import { dxTreeListColumn } from 'devextreme/ui/tree_list';
import { useApiData, useIntl, useStoreSelector } from 'hooks';
import { useHeight } from 'hooks/useHeight';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt, InjectedIntl } from 'locale';
import { SelectedItemsProvider } from 'pages/AllDocumentsPage/AllDocumentsPage.SelectedItemsContextProvider';
import { PROJECT_LIST_ORDER_OPTIONS } from 'pages/ProjectsListPage/ProjectsList';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'store';
import { projectsListSelector } from 'store/selectors';
import { messageError } from 'utils';
import { orderTree } from 'utils/tree/treeHelpers';
import { v4 as uuid } from 'uuid';
import styles from './ProjectsGridPage.module.less';

type Props = RouteComponentProps;

function formatUnits(unit?: string) {
  return unit ? ` [${unit}]` : '';
}

const COLUMN_FIXING = { enabled: true };

const TWIST_DETAIL_PROPERTY_NAME = 'twistDetail';

type TWISTColumnType = dxTreeListColumn & { dataField?: keyof BuildingIdentificationDetail };

export function formatJsonDataToSimpleText(data: JSONVariable, intl: InjectedIntl) {
  const type = data?.type;
  switch (type) {
    case 'number':
      return data?.value == null ? null : +data?.value;
    case 'numberDiff':
      return {
        oldValue: data?.oldValue ? +data?.oldValue : undefined,
        value: data?.value ? +data?.value : undefined,
      };
    case 'string':
      return data?.value || null;
    case 'stringDiff':
      return {
        oldValue: data?.oldValue ? data?.oldValue : undefined,
        value: data?.value ? data?.value : undefined,
      };
    case 'buildingIdentification':
      return data?.value ? data?.value.map((item) => `(${item.code})`).toString() : null;
    case 'date':
      return data?.value;
    case 'link':
      return data?.value ? (data?.label || '') + ' [' + data?.value + ']' : null;
    case 'hubLink':
      return data?.value ? (data?.label || '') + ' [' + data?.value + ']' : null;
    case 'boolean':
      return intl.formatMessage({ id: data?.value === 'true' ? 'general.yes' : 'general.no' });
    case 'interval':
      return data;
    case 'ckApprovalConditions':
      return data?.value?.join(', ');
    case 'empty':
      return '';
  }
  return null;
}

const mapDataToColumns = (
  data: ProjectCardDataDto[],
  intl: InjectedIntl,
  path: string = '',
  coloringIndex: number = null
): dxTreeListColumn[] => {
  return data.map((item, index) => {
    const key = path + item.name;
    return {
      name: item.name,
      dataField: key,
      key: key,
      caption: item.name,
      title: item.name + formatUnits(item.unit),
      unit: item.unit,
      width: 160,
      headerCellTemplate: 'addTooltip',
      cellTemplate: item.variable === JSONVariableTypeEnum.buildingIdentification ? 'TWIST' : undefined,
      dataType:
        item.variable === JSONVariableTypeEnum.number || item.variable === JSONVariableTypeEnum.numberDiff
          ? 'number'
          : item.variable === JSONVariableTypeEnum.date || item.variable === JSONVariableTypeEnum.interval
          ? 'date'
          : item.variable === JSONVariableTypeEnum.boolean
          ? 'boolean'
          : 'string',
      cssClass: (coloringIndex != null ? coloringIndex : index) % 2 === 0 ? styles.columnA : styles.columnB,
      columns: item.children?.length
        ? mapDataToColumns(item.children, intl, key + '/', coloringIndex == null ? index : coloringIndex)
        : item?.variable === JSONVariableTypeEnum.numberDiff || item?.variable === JSONVariableTypeEnum.stringDiff
        ? [
            {
              name: key + '_old',
              dataField: key + '_old',
              caption: intl.formatMessage({ id: 'ProjectSettingsProjectCardDataTree.oldValue' }),
              width: 80,
              headerCellTemplate: 'addTooltip',
              cssClass: (coloringIndex != null ? coloringIndex : index) % 2 === 0 ? styles.columnA : styles.columnB,
            },
            {
              name: key + '_new',
              dataField: key + '_new',
              caption: intl.formatMessage({ id: 'ProjectSettingsProjectCardDataTree.newValue' }),
              width: 80,
              headerCellTemplate: 'addTooltip',
              cssClass: (coloringIndex != null ? coloringIndex : index) % 2 === 0 ? styles.columnA : styles.columnB,
            },
          ]
        : item?.variable === JSONVariableTypeEnum.interval
        ? [
            {
              name: key + '_from',
              dataField: key + '_from',
              caption: intl.formatMessage({ id: 'general.from' }),
              width: 80,
              dataType: 'date',
              headerCellTemplate: 'addTooltip',
              cssClass: (coloringIndex != null ? coloringIndex : index) % 2 === 0 ? styles.columnA : styles.columnB,
            },
            {
              name: key + '_to',
              dataField: key + '_to',
              caption: intl.formatMessage({ id: 'general.to' }),
              width: 80,
              dataType: 'date',
              headerCellTemplate: 'addTooltip',
              cssClass: (coloringIndex != null ? coloringIndex : index) % 2 === 0 ? styles.columnA : styles.columnB,
            },
          ]
        : [],
    };
  });
};

const mapDataToData = (intl: InjectedIntl, data: ProjectCardDataDto[], path: string = ''): Record<string, any> => {
  return {
    key: uuid(),
    ...data.reduce((acc, item) => {
      const data = formatJsonDataToSimpleText(item.data, intl);
      const hasChildren = item.children?.length;
      // @ts-ignore
      const hasDiffValue = data?.oldValue != undefined || data?.newValue != undefined;
      const isDateInterval = item.variable === JSONVariableTypeEnum.interval;
      const isBuildingIdentification = item.variable === JSONVariableTypeEnum.buildingIdentification;

      const key = path + item.name;

      if (hasChildren) {
        return { ...acc, ...mapDataToData(intl, item.children, key + '/') };
      }

      if (hasDiffValue) {
        return {
          ...acc,
          // @ts-ignore
          [key + '_old']: data?.oldValue,
          // @ts-ignore
          [key + '_new']: data?.value,
        };
      }
      if (isDateInterval) {
        return {
          ...acc,
          // @ts-ignore
          [key + '_from']: data?.valueFrom,
          // @ts-ignore
          [key + '_to']: data?.valueTo,
        };
      }

      if (isBuildingIdentification) {
        return {
          ...acc,
          [key]: data,
          // @ts-ignore
          [TWIST_DETAIL_PROPERTY_NAME]: item?.data?.value,
        };
      }

      return { ...acc, [key]: data };
    }, {} as Record<string, any>),
  };
};

const getTWISTdataColumns = (intl: InjectedIntl): TWISTColumnType[] => {
  return [
    {
      width: 150,
      dataField: 'code',
      caption: intl.formatMessage({ id: 'general.TWIST' }),
      fixed: true,
      allowHiding: false,
      dataType: 'string',
      sortOrder: 'asc',
    },
    {
      width: 150,
      dataField: 'region',
      caption: intl.formatMessage({ id: 'general.region' }),
      fixed: true,
      dataType: 'string',
    },
    {
      width: 350,
      dataField: 'areal',
      caption: intl.formatMessage({ id: 'general.areal' }),
      dataType: 'string',
    },
    {
      width: 350,
      dataField: 'property',
      caption: intl.formatMessage({ id: 'general.property' }),
      dataType: 'string',
    },
    SPACE_FOR_SCROLLBAR_COLUMN,
  ];
};

const ProjectsGridPage: FunctionComponent<Props> = () => {
  const { tableWrapRef, wrapHeight } = useHeight();
  const intl = useIntl();

  const [projectsCardStructure, setProjectsCardStructure] = useState<dxTreeListColumn[]>([]);
  const [buildungIdentificationDetailData, setBuildingIdentificationDetailData] = useState<
    BuildingIdentificationDetail[]
  >();

  const TWISTdataColumns = useMemo(() => {
    if (!buildungIdentificationDetailData?.length) return undefined;
    return getTWISTdataColumns(intl);
  }, [buildungIdentificationDetailData, intl]);

  const TWISTdataData = useMemo(() => {
    if (!buildungIdentificationDetailData?.length) return undefined;
    return buildungIdentificationDetailData.map((item) => ({ key: uuid(), ...item }));
  }, [buildungIdentificationDetailData]);

  useEffect(() => {
    const loadOrgProjectCardData = async () => {
      const selectedOrganizationId = EMPTY_GUID;
      if (!selectedOrganizationId) return;
      const [err, data] = await masterApi.projects.reports.projectcard.id.get(selectedOrganizationId);
      if (err) {
        messageError(err, intl);
      } else {
        if (data?.data?.cardData) setProjectsCardStructure(mapDataToColumns(orderTree(data.data.cardData), intl));
      }
    };
    void loadOrgProjectCardData();
  }, [intl]);

  const dispatch = useDispatch<Dispatch>();

  useDirtyStoreReload(
    (store) => store.allProjects,
    (dispatch) => dispatch.allProjects
  );

  useEffect(() => {
    dispatch.allProjects.loadData({ reload: true });
  }, []);

  const projects = useStoreSelector(projectsListSelector);

  const [organizationsAdminReport, organizationsError, organizationsLoading] = useApiData(
    (ct) => api.master.organization.getOrganizationsAdminReport(ct),
    {
      autoload: true,
    }
  );
  const organizations = useMemo(() => organizationsAdminReport?.organizations.filter((org) => org.hasSZModule), [
    organizationsAdminReport,
  ]);

  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string>(EMPTY_GUID);

  const data = useMemo(
    () =>
      projects
        ? projects
            .filter((project) => project.organization?.id === selectedOrganizationId)
            .map((project) => mapDataToData(intl, project?.projectCard?.projectCardData || []))
        : [],
    [projects, selectedOrganizationId, intl]
  );

  const [extremeLocale, setExtremeLocale] = useState<string>(intl.locale);
  const [messagesLoaded, setMessagesLoaded] = useState<Record<string, boolean>>({});

  useEffect(() => {
    let cancelled = false;
    (async () => {
      if (!messagesLoaded[intl.locale]) {
        const devextremeMessages = await import(`devextreme/localization/messages/${intl.locale}.json`);
        const localeMessages = { [intl.locale]: devextremeMessages[intl.locale] };
        if (cancelled) return;
        loadMessages(localeMessages);
        setMessagesLoaded((s) => ({ ...s, [intl.locale]: true }));
      }
      devExtremeLocale(intl.locale);
      setExtremeLocale(intl.locale);
    })();
    return () => (cancelled = true);
  }, [intl, messagesLoaded]);

  const addTooltip = useCallback((data: any) => {
    return (
      <CommonHubTooltip title={data.column.title}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '0.5rem',
          }}
        >
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              flex: '1',
              minWidth: '40px',
            }}
          >
            {data.column.caption}
          </div>
          <div>{formatUnits(data.column.unit)}</div>
        </div>
      </CommonHubTooltip>
    );
  }, []);

  const addShowDetailWay = useCallback((data: any) => {
    return !!data.row.data?.[TWIST_DETAIL_PROPERTY_NAME]?.length ? (
      <Typography.Link
        underline
        onClick={() => setBuildingIdentificationDetailData(data.row.data?.[TWIST_DETAIL_PROPERTY_NAME])}
      >
        {data.row.data[data.column.caption]}
      </Typography.Link>
    ) : null;
  }, []);

  return (
    <>
      <ErrorBoundary>
        <SideMenuActivator menuItemKey={SideMenuKey.PROJECTS_GRID}>
          <div className={styles.content}>
            <SelectedItemsProvider>
              <FiltersContextProvider
                persistentKey={FiltersPersistentKey.ProjectsAll}
                orderOptions={PROJECT_LIST_ORDER_OPTIONS}
              >
                <div ref={tableWrapRef} className={styles.wrapper}>
                  {!!organizations?.length && (
                    <DocumentsGridHeaderStyled>
                      <FlowLayout>
                        <Typography.Text>
                          <Fmt id="general.organization" />
                        </Typography.Text>
                        {!!organizations && (
                          <Select
                            className={styles.organizationSelect}
                            loading={organizationsLoading}
                            options={organizations?.map((org) => ({
                              value: org.id,
                              label: org.name,
                            }))}
                            value={selectedOrganizationId}
                            onSelect={(value) => {
                              setSelectedOrganizationId(value);
                            }}
                          />
                        )}
                      </FlowLayout>
                    </DocumentsGridHeaderStyled>
                  )}

                  {data !== undefined && messagesLoaded[extremeLocale] && (
                    <TreeList
                      key={extremeLocale}
                      itemsExpr="children"
                      dataStructure="plain"
                      width="auto"
                      columnResizingMode="widget"
                      allowColumnReordering
                      allowColumnResizing
                      columnMinWidth={50}
                      repaintChangesOnly={false}
                      keyExpr="key"
                      dataSource={data}
                      columns={projectsCardStructure}
                      height={wrapHeight}
                      columnFixing={COLUMN_FIXING}
                    >
                      <FilterRow visible={true} />
                      <Template name="addTooltip" render={addTooltip} />
                      <Template name="TWIST" render={addShowDetailWay} />
                    </TreeList>
                  )}
                </div>
              </FiltersContextProvider>
            </SelectedItemsProvider>
          </div>
        </SideMenuActivator>
      </ErrorBoundary>
      {!!buildungIdentificationDetailData && (
        <Modal
          open={!!buildungIdentificationDetailData}
          onCancel={() => setBuildingIdentificationDetailData(undefined)}
          footer={null}
          title={intl.formatMessage({ id: 'general.TWIST.detail' })}
          width={LARGE_SETTING_MODAL_WIDTH}
        >
          <TreeList
            key={extremeLocale}
            itemsExpr="children"
            dataStructure="plain"
            width="auto"
            columnResizingMode="widget"
            allowColumnReordering
            allowColumnResizing
            columnMinWidth={50}
            repaintChangesOnly={false}
            keyExpr="key"
            dataSource={TWISTdataData}
            columns={TWISTdataColumns}
            columnFixing={COLUMN_FIXING}
          >
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
          </TreeList>
        </Modal>
      )}
    </>
  );
};

export default React.memo(ProjectsGridPage);
