import { Form, Input } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { apiConstraints } from 'api/completeApiConstraints';
import { OrgEstiConnDto } from 'api/completeApiInterfaces';
import { useIntl } from 'hooks';
import React, { FC, useEffect } from 'react';
import { maxLengthRule } from 'utils/formHelpers';

export type AspeEsticonFormData = {
  id?: Guid;
  name: string;
  url: string;
};

type Props = {
  editingItem?: OrgEstiConnDto;
  isAspeHubAdmin: boolean;
};

const AspeEsticonAddForm: FC<Props> = ({ editingItem, isAspeHubAdmin }) => {
  const intl = useIntl();
  const form = useFormInstance();

  useEffect(() => {
    if (editingItem) {
      form.setFieldsValue({
        id: editingItem.id,
        name: editingItem.name,
        url: editingItem.url,
      });
    } else {
      form.resetFields();
    }
  }, [editingItem, form]);

  return (
    <Form<AspeEsticonFormData> layout="vertical" form={form}>
      <Form.Item
        name="name"
        label={intl.formatMessage({ id: 'general.name' })}
        rules={[
          { required: true, message: intl.formatMessage({ id: 'forms.items.rules.required' }) },
          maxLengthRule('general.maxNameLength', apiConstraints.orgWorkingPositionSetDto.name.maxLength),
        ]}
        initialValue={editingItem?.name}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="url"
        label={intl.formatMessage({ id: 'AspeEsticonAddForm.serverURL' })}
        rules={[{ required: true, message: intl.formatMessage({ id: 'forms.items.rules.required' }) }]}
        initialValue={editingItem?.url}
      >
        <Input disabled={!isAspeHubAdmin || !!editingItem?.id} />
      </Form.Item>
      <Form.Item hidden name="id" initialValue={editingItem?.id} />
    </Form>
  );
};

AspeEsticonAddForm.displayName = 'AsepEsticonAddForm';

export default AspeEsticonAddForm;
