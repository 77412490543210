import { ExportOutlined } from '@ant-design/icons';
import { Alert, Form } from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import { DefaultOptionType } from 'antd/lib/select';
import {
  CalendarDto,
  JSONVariableTypeEnum,
  MdProjectDataDto,
  MdProjectDto,
  MdProjectPhaseEnum,
  MdProjectStateEnum,
  MdProjectVariableEnum,
  OrgUserClaimDto,
} from 'api/completeApiInterfaces';
import classNames from 'classnames';
import { CommonDocument } from 'components/DocumentCompleteList/DocumentCompleteList';
import { MdProjectWithUnpackedData } from 'components/JSONVariableFormItems/dataUnpackers/mdProjectUnpacker.utils';
import JSONVariableFormItem from 'components/JSONVariableFormItems/JSONVariableFormItem';
import { JSONVariableLinkData, JSONVariableSelectEnumTyped } from 'components/JSONVariableFormItems/JSONVariableTypes';
import { Fmt } from 'locale';
import { MdProjectDataActionCharacterEnum } from 'pages/ReportDetailPage/ReportDetails/MDApprovalProjectsReport/MDApprovalProjectReport.utils';
import React from 'react';
import { Link } from 'react-router-dom';
import MdCkStatementFormItem from '../MdFormItems/MdCkStatementFormItem';
import MdProposedForFormItem, { MdProjectDataSubmittedForEnum } from '../MdFormItems/MdProposedForFormItem';
import MDRoleDisplay from '../MdRoleDisplay/MDRoleDisplay';
import styles from './MDProjectCardModal.module.less';

type Props = {
  mdProject: MdProjectDto;
  fields: Record<MdProjectPhaseEnum, MdProjectVariableEnum[]>;
  canEditAsProposer?: boolean;
  canEditAsGuarantor?: boolean;
  canEditAsCKOrganizer?: boolean;
  canEditCkStatement?: boolean;
  extendedUserClaims?: OrgUserClaimDto[];
  overheadProjectId?: Guid;
  actionCharacterOptions?: DefaultOptionType[];
  submittedForOptions?: DefaultOptionType[];
  organizationCalendar?: CalendarDto;
  isPreparedForCKApproval?: boolean;
  disabled?: boolean;
};

const disableUnsupportedMeetingDocumentTypeSelect = (document: CommonDocument) =>
  document.primaryFile?.contentType !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' &&
  document.primaryFile?.contentType !== 'application/msword' &&
  document.primaryFile?.contentType !== 'application/vnd.oasis.opendocument.text';

const renderFormItem = (
  mdProject: MdProjectWithUnpackedData,
  variableType: MdProjectVariableEnum,
  value: MdProjectDataDto,
  isPreparedForCKApproval: boolean,
  canEditAsProposer: boolean,
  canEditAsGuarantor: boolean,
  canEditAsCKOrganizer: boolean,
  overheadProjectId: Guid,
  canEditCkStatement: boolean,
  submittedForValue?: MdProjectDataSubmittedForEnum,
  extendedUserClaims: OrgUserClaimDto[] = [],
  actionCharacterOptions?: DefaultOptionType[],
  organizationCalendar?: CalendarDto,
  disabled?: boolean
) => {
  switch (variableType) {
    case MdProjectVariableEnum.projectName: {
      return (
        <JSONVariableFormItem
          dataType={JSONVariableTypeEnum.string}
          itemType="input"
          disabled={(!canEditAsProposer && !canEditAsGuarantor) || disabled}
        />
      );
    }
    case MdProjectVariableEnum.constructionRegion: {
      return (
        <JSONVariableFormItem
          dataType={JSONVariableTypeEnum.string}
          itemType="input"
          disabled={(!canEditAsProposer && !canEditAsGuarantor) || disabled}
        />
      );
    }
    case MdProjectVariableEnum.stretch: {
      return (
        <JSONVariableFormItem
          dataType={JSONVariableTypeEnum.string}
          itemType="input"
          disabled={(!canEditAsProposer && !canEditAsGuarantor) || disabled}
        />
      );
    }
    case MdProjectVariableEnum.documentationLink: {
      const linkData = value.data as JSONVariableLinkData;
      if (!linkData) return null;
      return (
        <Link to={linkData.value}>
          {linkData.label} <ExportOutlined />
        </Link>
      );
    }
    case MdProjectVariableEnum.totalCost: {
      return (
        <JSONVariableFormItem
          dataType={JSONVariableTypeEnum.number}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          precision={2}
          max={999999999999999}
          decimalSeparator=","
          controls={false}
          disabled={(!canEditAsProposer && !canEditAsGuarantor) || disabled}
          className={styles.priceInput}
          intlId={'MD.Projects.ProjectCardModal.input.price.suffix'}
        />
      );
    }
    case MdProjectVariableEnum.referenceNumber: {
      return (
        <JSONVariableFormItem
          dataType={JSONVariableTypeEnum.string}
          itemType="input"
          disabled={!canEditAsGuarantor || disabled}
        />
      );
    }
    case MdProjectVariableEnum.expectedRealisationTime: {
      return (
        <JSONVariableFormItem
          dataType={JSONVariableTypeEnum.interval}
          disabled={(!canEditAsProposer && !canEditAsGuarantor) || disabled}
        />
      );
    }
    case MdProjectVariableEnum.commentProcedure: {
      // workflow url
      const linkData = value.data as JSONVariableLinkData;
      if (!linkData) return null;
      return (
        <Link to={linkData.value}>
          {linkData.label} <ExportOutlined />
        </Link>
      );
    }
    case MdProjectVariableEnum.ckMeetingBackground: {
      // podklady pro jednání centrální komise (document url)
      return (
        mdProject.project && (
          <JSONVariableFormItem
            dataType={JSONVariableTypeEnum.hubLink}
            linkType="document"
            projectId={mdProject.project?.id}
            disabled={
              !canEditAsGuarantor ||
              (mdProject.state !== MdProjectStateEnum.projectValidation &&
                mdProject.state !== MdProjectStateEnum.projectProcessing) ||
              disabled
            }
            disableDocumentSelect={disableUnsupportedMeetingDocumentTypeSelect}
          />
        )
      );
    }
    case MdProjectVariableEnum.submittedFor: {
      return (
        <MdProposedForFormItem
          disabled={(!canEditAsProposer && !canEditAsGuarantor) || isPreparedForCKApproval || disabled}
        />
      );
    }
    case MdProjectVariableEnum.ckMeetingMinutes: {
      // zápis z jednání centrální komise (directory url)
      return mdProject.project && overheadProjectId ? (
        <JSONVariableFormItem
          dataType={JSONVariableTypeEnum.hubLink}
          linkType="directory"
          projectId={overheadProjectId}
          disabled={!canEditAsCKOrganizer || disabled}
        />
      ) : (
        <Alert message={<Fmt id="MD.Projects.ProjectCardModal.warning.overviewProjectNotSet" />} type="warning" />
      );
    }
    case MdProjectVariableEnum.ckApprovalDate: {
      return <JSONVariableFormItem dataType={JSONVariableTypeEnum.date} disabled={!canEditAsCKOrganizer || disabled} />;
    }
    case MdProjectVariableEnum.ckApprovalConditions: {
      return (
        <JSONVariableFormItem
          dataType={JSONVariableTypeEnum.ckApprovalConditions}
          canEditAsCKOrganizer={canEditAsCKOrganizer && !disabled}
          canEditAsGuarantor={canEditAsGuarantor && !disabled}
          activeCalendar={organizationCalendar}
        />
      );
    }
    case MdProjectVariableEnum.ckApproved: {
      return (
        <JSONVariableFormItem dataType={JSONVariableTypeEnum.boolean} disabled={!canEditAsCKOrganizer || disabled} />
      );
    }
    case MdProjectVariableEnum.ckStatement: {
      return (
        <MdCkStatementFormItem
          disabled={!canEditCkStatement || !canEditAsCKOrganizer || disabled}
          submittedForFormValue={submittedForValue}
        />
      );
    }
    case MdProjectVariableEnum.preparationUpdate: {
      return (
        <JSONVariableFormItem
          dataType={JSONVariableTypeEnum.boolean}
          disabled={(!canEditAsProposer && !canEditAsGuarantor) || disabled}
        />
      );
    } // Aktualizace Přípravy projektu
    case MdProjectVariableEnum.investorContactPerson: {
      return (
        <JSONVariableFormItem
          dataType={JSONVariableTypeEnum.string}
          itemType="investorContactPerson"
          disabled={(!canEditAsProposer && !canEditAsGuarantor) || disabled}
        />
      );
    } // Kontaktní osoba investora
    case MdProjectVariableEnum.studyType: {
      return (
        <JSONVariableFormItem
          dataType={JSONVariableTypeEnum.string}
          itemType="input"
          disabled={(!canEditAsProposer && !canEditAsGuarantor) || disabled}
        />
      );
    }
    case MdProjectVariableEnum.actionCharacter: {
      return (
        <JSONVariableFormItem
          dataType={JSONVariableTypeEnum.enum}
          selectOptions={actionCharacterOptions}
          disabled={(!canEditAsProposer && !canEditAsGuarantor) || disabled}
          enumType={nameof(MdProjectDataActionCharacterEnum)}
        />
      );
    }

    case MdProjectVariableEnum.hasOpponentsReport: {
      return (
        <JSONVariableFormItem
          dataType={JSONVariableTypeEnum.boolean}
          disabled={(!canEditAsProposer && !canEditAsGuarantor) || disabled}
        />
      );
    }
    case MdProjectVariableEnum.opponentsReport: {
      return (
        mdProject.project && (
          <JSONVariableFormItem
            dataType={JSONVariableTypeEnum.hubLink}
            linkType="document"
            projectId={mdProject.project?.id}
            disabled={(!canEditAsProposer && !canEditAsGuarantor) || disabled}
          />
        )
      );
    }
    case MdProjectVariableEnum.isUPDChangeRequest: {
      // Projekt vyžaduje změnu vymezení v ÚPD
      return (
        <JSONVariableFormItem dataType={JSONVariableTypeEnum.boolean} disabled={!canEditAsCKOrganizer || disabled} />
      );
    }
    case MdProjectVariableEnum.intentionUpdated: {
      // Aktualizace ZP
      return (
        <JSONVariableFormItem
          dataType={JSONVariableTypeEnum.boolean}
          disabled={(!canEditAsProposer && !canEditAsGuarantor) || disabled}
        />
      );
    }
    case MdProjectVariableEnum.mdContactPerson: {
      if (
        !mdProject.guarantor ||
        mdProject.state === MdProjectStateEnum.entering ||
        mdProject.state === MdProjectStateEnum.divisionSetting
      )
        return null;

      return <MDRoleDisplay mdRole={mdProject.guarantor} extendedUserClaim={extendedUserClaims} />;
    }
    case MdProjectVariableEnum.isProFond: {
      return (
        <JSONVariableFormItem
          dataType={JSONVariableTypeEnum.number}
          disabled={(!canEditAsProposer && !canEditAsGuarantor) || disabled}
        />
      );
    }
  }
  return null;
};

const MDProjectCardVariableFields: React.FC<Props> = ({
  mdProject,
  fields,
  canEditAsProposer,
  canEditAsGuarantor,
  canEditAsCKOrganizer,
  canEditCkStatement,
  extendedUserClaims,
  overheadProjectId,
  actionCharacterOptions,
  organizationCalendar,
  isPreparedForCKApproval,
  disabled,
}) => {
  const submittedForFormValue = useWatch<JSONVariableSelectEnumTyped<MdProjectDataSubmittedForEnum>>('submittedFor')
    ?.value;
  if (!fields[mdProject.phase]) return null;

  const projectCardCommisionFields = fields[mdProject.phase].map(
    (field): MdProjectDataDto => ({
      phase: mdProject.phase,
      variable: field,
      data: mdProject.projectData.find((data) => data.variable === field)?.data,
    })
  );

  return (
    <div className={styles.mdFormSectionWrapper}>
      <div className={classNames(styles.mdFormItem)}>
        {projectCardCommisionFields.map((field) => (
          <div key={field.variable} className={classNames(styles.mdFormItem)}>
            <Form.Item
              label={<Fmt id={`MD.ProjectVariableEnum.${field.variable}.name`} />}
              labelAlign="left"
              name={field.variable}
              initialValue={field.data}
            >
              {renderFormItem(
                mdProject,
                field.variable,
                field,
                isPreparedForCKApproval,
                canEditAsProposer,
                canEditAsGuarantor,
                canEditAsCKOrganizer,
                overheadProjectId,
                canEditCkStatement,
                submittedForFormValue,
                extendedUserClaims,
                actionCharacterOptions,
                organizationCalendar,
                disabled
              )}
            </Form.Item>
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(MDProjectCardVariableFields);
