import { ColProps, Collapse, Form, Modal, ModalProps } from 'antd';
import { createCancelToken } from 'api';
import { masterApi } from 'api/completeApi';
import {
  JToken,
  MdCkIdentificationEnum,
  MdMeDto,
  MdProjectDto,
  MdProjectStateEnum,
  MdProjectVariableEnum,
  MdRoleDto,
  MdRoleEnum,
  OrgUserClaimDto,
} from 'api/completeApiInterfaces';
import { CancelTokenSource } from 'axios';
import { ContentGate } from 'components/ContentGate/ContentGate';
import {
  MdProjectListWithUnpackedData,
  mdProjectDataUnpack,
} from 'components/JSONVariableFormItems/dataUnpackers/mdProjectUnpacker.utils';
import { isJSONBooleanValueTrue } from 'components/JSONVariableFormItems/Items/JSONVariableCheckboxFormItem';
import { JSONVariable } from 'components/JSONVariableFormItems/JSONVariableTypes';
import StackPanel from 'components/StackPanel';
import { HIDE_BUTTON_PROPS } from 'config/constants';
import { useApiData, useIntl, useSameCallback } from 'hooks';
import { MDSignalRData, useMDProjectsChangedSignal } from 'hooks/useMDSignalR';
import { Fmt } from 'locale';
import { Dictionary, debounce, isEqual } from 'lodash';
import { FieldData } from 'rc-field-form/lib/interface';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { messageError } from 'utils';
import MDProjectCardCommission from './MDProjectCardCommission';
import MDProjectCardHead from './MDProjectCardHead';
import styles from './MDProjectCardModal.module.less';
import MDProjectCardProjectData from './MDProjectCardProjectData';
import MDProjectCardToolbar from './MDProjectCardToolbar';

type Props = {
  organizationId: Guid;
  overheadProjectId: Guid;
  loading: boolean;
  mdProjectId: Guid;
  mdRoles: MdRoleDto[];
  mdProjects: MdProjectListWithUnpackedData[];
  onClose: () => void;
  onProjectUpdate?: (project: MdProjectDto) => void;
  mdCurrentUser: MdMeDto;
} & Omit<ModalProps, 'onOk' | 'title' | 'onCancel'>;

export const MD_FORM_LABEL_FULL_COL: ColProps = { span: 4 };
export const MD_FORM_LABEL_HALF_COL: ColProps = { span: 8 };
export const MD_FORM_ROW_GUTTER = 10;

export type MDProjectCardFormData = {
  name: string;
  description: string;
  ckIdentification: MdCkIdentificationEnum;
} & Record<MdProjectVariableEnum, JToken>;

const MDProjectCardModal: React.FC<Props> = ({
  mdProjectId,
  organizationId,
  mdRoles,
  mdProjects,
  onClose,
  onProjectUpdate,
  mdCurrentUser,
  overheadProjectId,
  loading,
  ...modalProps
}) => {
  const [form] = Form.useForm();
  const intl = useIntl();

  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [extendedUserClaims, setExtendedUserClaims] = useState<OrgUserClaimDto[]>(null);

  const [projectData, projectDataError, projectDataLoading, loadProjectData, setProjectData] = useApiData(
    (ct) => masterApi.projects.md.project.id.get(mdProjectId, ct),
    { autoload: false }
  );

  const projectDataUnwrapped = useMemo(() => projectData && mdProjectDataUnpack(projectData), [projectData]);

  useEffect(() => {
    loadProjectData();
  }, [mdProjectId]);

  const handleMdProjectSignalR = useCallback((messages: MDSignalRData[]) => {
    if (messages.some((message) => message.mdProjectId === mdProjectId)) {
      loadProjectData();
    }
  }, []);

  useMDProjectsChangedSignal(handleMdProjectSignalR);

  const validateDirty = useMemo(
    () =>
      debounce((_changedFields: FieldData[], allFields: FieldData[]) => {
        if (!projectDataUnwrapped) return;
        const isDirty = allFields.some((fieldData) => {
          const fieldName = Array.isArray(fieldData.name) ? fieldData.name[0] : fieldData.name;
          if (fieldName === 'name') return projectDataUnwrapped.name !== fieldData.value;
          if (fieldName === 'description') return projectDataUnwrapped.description !== fieldData.value;
          return (
            projectDataUnwrapped.projectData.some(
              (data) => data.variable === fieldName && !isEqual(data.data, fieldData.value)
            ) ||
            (!projectDataUnwrapped.projectData.some((data) => data.variable === fieldName) && !!fieldData.value)
          );
        });
        setDirty(isDirty);
      }, 200),
    [projectDataUnwrapped]
  );

  const handleFormReset = useCallback(() => {
    form.resetFields();
    setDirty(false);
  }, [form]);

  useEffect(() => {
    if (!projectDataUnwrapped) return;
    const fetchUserExtras = async (cancelToken: CancelTokenSource) => {
      const userIds = [
        projectDataUnwrapped.proposer?.id,
        projectDataUnwrapped.guarantor?.head.orgUser.id,
        ...(projectDataUnwrapped.guarantor?.mdUsers.map((user) => user.orgUser.id) || []),
      ].filter(Boolean);

      const [err, res] = await masterApi.projects.admin.getorgusers.id.claims.post(
        organizationId,
        { orgUserIds: userIds },
        cancelToken.token
      );
      if (err) {
        messageError(err, intl);
        return;
      }
      setExtendedUserClaims(res.data.userClaims);
    };

    const cancelToken = createCancelToken();
    void fetchUserExtras(cancelToken);
    () => cancelToken.cancel();
  }, [organizationId, projectDataUnwrapped]);

  const handleFormSubmit = useSameCallback(async (values: MDProjectCardFormData) => {
    setLoading(true);
    if (
      values.name !== projectDataUnwrapped.name ||
      values.description !== projectDataUnwrapped.description ||
      values.ckIdentification !== projectDataUnwrapped.ckIdentificaion
    ) {
      const [err, res] = await masterApi.projects.md.project.patchhead.patch({
        id: projectDataUnwrapped.id,
        name: values.name,
        description: values.description,
        ckIdentificaion: values.ckIdentification,
        intentionerId: projectDataUnwrapped.intentionerId,
      });

      if (err) {
        messageError(err, intl);
        setLoading(false);
        return;
      }
      setProjectData(res.data);
      onProjectUpdate(res.data);
    }

    const variableItemValues = Object.keys(MdProjectVariableEnum).reduce(
      (dataRecord, variableType: MdProjectVariableEnum) => {
        if (!(variableType in values)) return dataRecord;
        return { ...dataRecord, [variableType]: values[variableType] };
      },
      {} as Record<MdProjectVariableEnum, Dictionary<JToken>>
    );

    const mappedChangedData = Object.entries(variableItemValues).reduce(
      (dataRecord, variableItemValue: [MdProjectVariableEnum, JSONVariable]) => {
        const [variableType, variableData] = variableItemValue;
        const originalData = projectDataUnwrapped.projectData.find((data) => data.variable === variableType)?.data;

        if (!originalData && !variableData) return dataRecord;
        if (!isEqual(variableData, originalData)) {
          return { ...dataRecord, [variableType]: variableData };
        }

        return dataRecord;
      },
      {} as Dictionary<JSONVariable>
    );

    if (Object.keys(mappedChangedData).length > 0) {
      const [errData, resData] = await masterApi.projects.md.project.setdata.post({
        mdProjectId: projectData.id,
        phase: projectData.phase,
        insertOrUpdateData: mappedChangedData,
      });

      if (errData) {
        messageError(errData, intl);
        setLoading(false);
        return;
      }
      setProjectData(resData.data);
      onProjectUpdate(resData.data);
    }
    validateDirty.cancel();
    setDirty(false);
    setLoading(false);
  });

  const handleDataUpdate = useCallback(
    (project: MdProjectDto) => {
      setProjectData(project);
      onProjectUpdate(project);
    },
    [onProjectUpdate, setProjectData]
  );

  const isProposerRole = useMemo(() => {
    if (!projectDataUnwrapped || !mdCurrentUser) return false;
    const isProposer = mdCurrentUser.mdRoles?.some(
      (role) =>
        projectDataUnwrapped.guarantor &&
        role.mdRoleType === MdRoleEnum.proposer &&
        projectDataUnwrapped.guarantor.id === role.id
    );
    if (!isProposer) return false;
    return isProposer && projectData.state === MdProjectStateEnum.entering;
  }, [projectData, mdCurrentUser]);

  const isO910RoleInDivisionSetting = useMemo(() => {
    if (!projectDataUnwrapped || !mdCurrentUser || projectDataUnwrapped.state != MdProjectStateEnum.divisionSetting)
      return false;
    const isOnO910Role = mdCurrentUser.mdRoles?.some((role) => role.mdRoleType === MdRoleEnum.O910);
    return isOnO910Role;
  }, [mdCurrentUser, projectDataUnwrapped]);

  const isCKOrganizerRole = useMemo(() => {
    if (!projectDataUnwrapped || !mdCurrentUser) return false;
    if (
      projectDataUnwrapped.state === MdProjectStateEnum.entering ||
      projectDataUnwrapped.state === MdProjectStateEnum.divisionSetting ||
      projectDataUnwrapped.state === MdProjectStateEnum.guarantorSetting
    ) {
      return false;
    }
    const isInCkRoles = mdCurrentUser.mdRoles?.some(
      (role) => role.mdRoleType === MdRoleEnum.ck_organizer || role.mdRoleType === MdRoleEnum.ck_head
    );
    return isInCkRoles;
  }, [projectDataUnwrapped, mdCurrentUser]);

  const isPreparedForCKApproval = useMemo(
    () => isJSONBooleanValueTrue(projectDataUnwrapped?.preparedForCkApproval?.value),
    [projectDataUnwrapped]
  );

  const isProjectGuarantorRole = useMemo(() => {
    if (!projectDataUnwrapped || !mdCurrentUser) return false;
    const isInDivisionRole = mdCurrentUser.mdRoles?.some(
      (role) => role.mdRoleType === MdRoleEnum.guarantor && role.id === projectDataUnwrapped.guarantor?.id
    );
    return (
      isInDivisionRole &&
      (projectDataUnwrapped.state == MdProjectStateEnum.projectProcessing ||
        projectDataUnwrapped.state == MdProjectStateEnum.projectValidation)
    );
  }, [projectDataUnwrapped, mdCurrentUser]);

  const existingProjectNames = useMemo(() => mdProjects?.map((project) => project.name) || [], [mdProjects]);

  return (
    <Modal
      title={
        <StackPanel stretch>
          <Fmt id="MD.Projects.ProjectCardModal.title" />
        </StackPanel>
      }
      forceRender
      okButtonProps={HIDE_BUTTON_PROPS}
      onCancel={onClose}
      className={styles.formModal}
      cancelText={<Fmt id="general.close" />}
      {...modalProps}
    >
      <ContentGate loading={projectDataLoading || loading} error={projectDataError} overlay>
        {projectDataUnwrapped && (
          <MDProjectCardToolbar
            mdProject={projectDataUnwrapped}
            mdRoles={mdRoles}
            isDirty={isDirty}
            saving={isLoading}
            form={form}
            onCancelChanges={handleFormReset}
            onProjectUpdate={handleDataUpdate}
            extendedUserClaims={extendedUserClaims}
            isProposerRole={isProposerRole}
            isO910Role={isO910RoleInDivisionSetting}
            isProjectGuarantorRole={isProjectGuarantorRole}
            isPreparedForCKApproval={isPreparedForCKApproval}
          />
        )}
        <Form form={form} onFinish={handleFormSubmit} onFieldsChange={validateDirty}>
          <MDProjectCardHead
            mdProject={projectDataUnwrapped}
            existingProjectNames={existingProjectNames}
            canEditAsGuarantor={isProjectGuarantorRole}
            canEditAsO910={isO910RoleInDivisionSetting}
            canEditAsProposer={isProposerRole}
            extendedUserClaims={extendedUserClaims}
            disabled={isPreparedForCKApproval}
          />
          <Collapse defaultActiveKey={['projectData']} ghost>
            <Collapse.Panel
              header={<Fmt id="MD.Projects.ProjectCardModal.section.projectData.title" />}
              key="projectData"
              className={styles.collapse}
            >
              <MDProjectCardProjectData
                mdProject={projectDataUnwrapped}
                canEditAsProposer={isProposerRole}
                canEditAsGuarantor={isProjectGuarantorRole}
                canEditAsCKOrganizer={isCKOrganizerRole}
                disabled={isPreparedForCKApproval}
              />
            </Collapse.Panel>
            <Collapse.Panel
              header={<Fmt id="MD.Projects.ProjectCardModal.section.commission.title" />}
              key="commission"
              className={styles.collapse}
            >
              <MDProjectCardCommission
                mdProject={projectDataUnwrapped}
                overheadProjectId={overheadProjectId}
                canEditAsProposer={isProposerRole}
                canEditAsGuarantor={isProjectGuarantorRole}
                canEditAsCKOrganizer={isCKOrganizerRole}
                isPreparedForCKApproval={isPreparedForCKApproval}
              />
            </Collapse.Panel>
          </Collapse>
        </Form>
      </ContentGate>
    </Modal>
  );
};

export default MDProjectCardModal;
