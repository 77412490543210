import { IntlMessagesShape } from './cs';

// prettier-ignore
export const deMessages: IntlMessagesShape = {
  'AccessLevel.label': 'Zugriffsebene',
  'AccessLevel.inheritedPermission': 'Geerbt',
  'AccessLevel.notSet': 'Nicht eingelegt',
  'AccessLevel.tooltips.admin': 'Administrator',
  'AccessLevel.tooltips.none': 'Kein',
  'AccessLevel.tooltips.read': 'Lesen',
  'AccessLevel.tooltips.write': 'Eintrag',
  'AccessLevel.tooltips.admin.label': 'Unbeschränkter Zugriff (Admin)',
  'AccessLevel.tooltips.none.label': 'Kein Zugriff',
  'AccessLevel.tooltips.read.label': 'Nur Lesen',
  'AccessLevel.tooltips.write.label': 'Schreiben und Lesen',
  'AccessLevel.tooltips.admin.description': 'Voller Zugriff, Erstellung von Dokumenten und Ordnern, einschließlich Änderung der Ordnerstruktur und Änderung der Zugriffsrechte.',
  'AccessLevel.tooltips.none.description': 'Überhaupt kein Zugriff.',
  'AccessLevel.tooltips.read.description': 'Nur Anzeigen und Herunterladen von Dokumenten.',
  'AccessLevel.tooltips.write.description': 'Anzeigen, Herunterladen und Hochladen von Dokumenten.',
  'AccessTokenExpiredModal.detail': 'Versuchen Sie, sich erneut anzumelden oder kontaktieren Sie den AspeHub-Support.',
  'AccessTokenExpiredModal.error': 'Bei der Login-Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder aktualisieren Sie die Seite.',
  'AccessTokenExpiredModal.title': 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich an.',
  'ActiveProjectGate.backToProjectList.ButtonText': 'Zurück zur Liste der Projekte',
  'ActiveTaskDetail.addRequest': 'Forderung hinzufügen',
  'ActiveTaskDetail.backgroundDocuments': 'Hintergrunddokumente',
  'ActiveTaskDetail.comment': 'Kommentar',
  'ActiveTaskDetail.parallelInitialization.button': 'Laufende Aufgaben auswählen',
  'ActiveTaskDetail.parallelInitialization.subworkflows': 'Parallele Aufgaben',
  'ActiveTaskDetail.parallelInitialization.title': 'Auswahl von laufenden Aufgaben',
  'ActiveTaskDetail.parallelInitialization.validation.error': 'Die Anzahl der laufenden Zweige muss mindestens {value} sein',
  'ActiveTaskDetail.parallelOverview.label': 'Status der parallelen Zweige',
  'ActiveTaskDetail.parallelOverview.subworkflowState.initialized': 'Initialisiert',
  'ActiveTaskDetail.parallelOverview.subworkflowState.wait': 'In Erwartung der Initialisierung',
  'ActiveTaskDetail.parallelOverview.subworkflowState.out': 'Übersprungen',
  'ActiveTaskDetail.parallelOverview.subworkflowState.finished': 'Abgeschlossen',
  'ActiveTaskDetail.voteLockedWarning': 'Es hat eine Änderung in den zugrunde liegenden Dokumenten stattgefunden.',
  'ActiveTaskDetail.voteLockedError': 'Es hat eine Änderung in den zugrundeliegenden Dokumenten stattgefunden. Der Workflow wurde aktualisiert. Bitte überprüfen Sie die Änderungen, bevor Sie erneut abstimmen.',
  'ActiveTaskDetail.votingRoleSelect': 'Auswahl der Rollen für die Abstimmung',
  'ActiveTaskDetail.votingMessage': 'Bericht zur Abstimmung',
  'AdminListPanel.addFormTitle': 'Administrator hinzufügen',
  'Administration.downloadDMSMetaData.buttonTitle': 'DMS mit Protokollen und Metadaten herunterladen',
  'Administration.downloadDMSMetaDataModal.modalTitle': 'DMS herunterladen, einschließlich Protokolle und Metadaten',
  'Administration.downloadDMSMetaDataModal.formRadio.actualRevisions': 'Aktuelle Revisionen',
  'Administration.downloadDMSMetaDataModal.formRadio.allRevisions': 'Alle Revisionen',
  'Administration.downloadDMSMetaDataModal.submit.prepareDocuments': 'Dokumente vorbereiten',
  'Administration.downloadDMSMetaDataModal.submit.processing': 'In Arbeit',
  'Administration.downloadDMSMetaDataModal.file.metadata': 'Metadaten',
  'Administration.downloadDMSMetaDataModal.file.activity': 'Tätigkeit',
  'Administration.downloadDMSMetaDataModal.file.dms': 'DMS',
  'AdminPageStressTest.testName.label': 'Name testen',
  'AdminPageStressTest.testData.label': 'Daten',
  'AdminPageStressTest.Run.button': 'Test',
  'AdminPageStressTest.Result.label': 'Ergebnis',
  'NodePortActionTypeEnum.accept.button': 'Genehmigen',
  'NodePortActionTypeEnum.accept.confirm': 'Diese Aufgabe genehmigen',
  'NodePortActionTypeEnum.accept.vote': 'Genehmigt',
  'NodePortActionTypeEnum.refuse.button': 'Ablehnen',
  'NodePortActionTypeEnum.refuse.confirm': 'Diese Aufgabe ablehnen',
  'NodePortActionTypeEnum.refuse.vote': 'Abgelehnt',
  'NodePortActionTypeEnum.process.button': '{action}',
  'NodePortActionTypeEnum.process.confirm': '{action} wählen',
  'NodePortActionTypeEnum.process.vote': '{action}',
  'NodePortActionTypeEnum.parallel.button': 'Parallele Verarbeitung',
  'NodePortActionTypeEnum.parallel.confirm': 'Parallele Verarbeitung',
  'NodePortActionTypeEnum.parallel.vote': 'Parallele Verarbeitung',
  'NodePortActionTypeEnum.continue': 'Weiter',
  'ActiveTaskDetail.taskDescription': 'Auftragsbeschreibung',
  'ActiveTaskDetail.taskDescription.edit': 'Auftragsbeschreibung bearbeiten',
  'ActiveTaskDetail.resolver': 'Löser',
  'ActiveTaskDetail.resolvers': 'Löser',
  'ActiveTaskDetail.Resolver.delegate': 'Delegieren',
  'ActiveTaskDetail.Resolver.delegate.title': 'Rolle delegieren',
  'ActiveTaskDetail.Resolver.delegate.newResolver': 'Neuer Löser',
  'ActiveTaskDetail.Resolver.delegate.noResolver': 'Wählen Sie einen neuen Löser',
  'ActiveTaskDetail.Resolver.delegate.message': 'Nachricht für neuen Löser',
  'ActiveTaskDetail.resolver.noVote': 'Der Löser hat nicht abgestimmt',
  'ActiveTaskDetail.resolver.noVote.yet': 'Der Löser hat noch nicht abgestimmt',
  'ActiveTaskDetail.schema': 'Schema',
  'ActiveTaskDetail.taskName': 'Name der Aufgabe',
  'ActiveTaskDetail.timeoutAction.title': 'Aktion nach Ablauf des Termins',
  'ActiveTaskDetail.timeoutAction.wait': 'Keine Aktion',
  'ActiveTaskDetail.timeoutAction.selectDefault': 'Spezifische Ausgabe verwenden',
  'ActiveTaskDetail.timeoutAction.output': 'Ausgabe',
  'ActiveTaskDetail.timeoutAction.closeWorkflow': 'Beenden der Workflow-Instanz',
  'ActiveTaskDetail.timeoutAction.evalLateAsAccept': 'Nicht abgestimmt ? gebilligt',
  'ActiveTaskDetail.timeoutAction.evalLateAsRefuse': 'Nicht abgestimmt ? abgelehnt',
  'ActiveTaskDetail.timeoutAction.tooltip': 'Diese Option kann verwendet werden, um anzugeben, dass Löser, die im Termin nicht abgestimmt haben, die Aufgabe genehmigt/abgelehnt haben.',
  'ActiveTaskDetail.timeoutAction.accordingToVote': 'Entsprechend der Abstimmung',
  'ActiveTaskDetail.term': 'Termin',
  'ActiveTaskDetail.term.title': 'Termin: {date}',
  'ActiveTaskDetail.term.maxExpectedTime': '(maximales Original: {maxExpectedDate})',
  'ActiveTaskDetail.term.maxExpectedTime.notDetermined': 'Nicht festgelegt',
  'ActiveTaskDetail.workflowState.started': 'Aktiv',
  'ActiveTaskDetail.workflowState.finished': 'Abgeschlossen',
  'ActiveTaskDetail.workflowState.criticalError': 'Schwerer Fehler',
  'ActiveTaskDetail.workflowState.error': 'Fehler',
  'ActiveTaskDetail.workflowState.warning': 'Warnung',
  'ActiveTaskDetail.workflowState.closed': 'Abgebrochen',
  'ActiveTaskDetail.workflowNodeState': 'Status',
  'ActiveTaskDetail.workflowNodeState.waiting': 'Ausstehend',
  'ActiveTaskDetail.workflowNodeState.current': 'Aktiv',
  'ActiveTaskDetail.workflowNodeState.out': 'Unbenutzt/Unverfügbar',
  'ActiveTaskDetail.workflowNodeState.processed': 'Verarbeitet',
  'ActiveTaskDetail.workflowNodeState.accepted': 'Akzeptiert',
  'ActiveTaskDetail.workflowNodeState.refused': 'Abgelehnt',
  'ActiveTaskDetail.workflowNodeState.over': 'Zurückgegeben',
  'ActiveTaskDetail.workflowNodeState.init': 'Initialisierung',
  'ActiveTaskDetail.workflowNodeState.outOver': 'Unbenutzt, bereits bearbeitet',
  'ActiveTaskDetail.workflowNodeState.closed': 'Geschlossen',
  'ActiveTaskDetail.workflowNodeState.closedIn': 'Bei der Initialisierung geschlossen',
  'ActiveTaskDetail.workflowNodeStateTitle': '{date}',
  'ActiveTaskDetail.notification.title': 'Benachrichtigungstermine',
  'ActiveTaskDetail.notification.recipients': 'Adressaten',
  'ActiveTaskDetail.notification.subject': 'Betreff der Nachricht',
  'ActiveTaskDetail.notification.message': 'Nachrichtentext',
  'ActiveTaskDetail.notification.noData': 'Keine Benachrichtigungstermine',
  'AddedRevisions.title': 'Änderung genehmigter Dokumente',
  'AddedRevisions.addDocuments.title': 'Dokumente hinzufügen',
  'AddedRevisions.buttons.selectDocuments': 'Aus den verfügbaren wählen',
  'AddedRevisions.buttons.uploadDocument': 'Ein neues hochladen',
  'AddedRevisions.filter.signature': 'Unterschrift',
  'AddedRevisions.filter.comment': 'Kommentar',
  'AddedRevisions.message.documentsAssigned': 'Dokumente wurden zu WF hinzugefügt',
  'AddedRevisions.message.documentsAdded': 'Das Dokument wurde hochgeladen und zu WF hinzugefügt',
  'AddedRevisions.message.documentsAddedError': 'Das Hinzufügen von Dokumenten zu WF ist fehlgeschlagen.',
  'AddedRevisions.message.noDerivateData': 'Die ausgewählte Revision des Dokuments kann nicht kommentiert werden, es gibt kein PDF-Derivat',
  'AdditionalInformation.constructionBillingData': 'Bauabrechnungsdaten',
  'AdditionalInformation.contractorContract': 'Vertragsverfertiger',
  'AdditionalInformation.CustomerContract': 'Vertragsbesteller',
  'AdditionalInformation.district': 'Bezirk',
  'AdditionalInformation.firm': 'Firma',
  'AdditionalInformation.forTheContractor': 'Für den Verfertiger',
  'AdditionalInformation.forTheCustomer': 'Für den Besteller',
  'AdditionalInformation.function': 'Funktion',
  'AdditionalInformation.functionDescription': 'Funktionsbeschreibung',
  'AdditionalInformation.name': 'Name',
  'AdditionalInformation.placeOfConstruction': 'Baustelle',
  'AdditionalInformation.placeOfInvoicing': 'Ort der Rechnungsstellung',
  'AdditionalInformation.wipItems': 'Artikel des Schöpfens',
  'AdditionalInformation.thirdPartySignatures': 'Dritte Unterschriften',
  'AdditionalInformation.where': 'Wo',
  'AdditionalInformation.wipReport': 'Discovery-Protokoll',
  'AllDocumentPage.deletedTargetDirectory.error': 'Der Ordner <strong>{name}</strong> wurde entfernt.',
  'AllDocumentPage.deletedTargetDirectory.lastPath': 'Ursprünglicher Speicherort: {path}',
  'AllDocumentsPage.forbiddenFilesWarning': 'Es können nicht alle Dokumente heruntergeladen werden',
  'AllDocumentsPage.downloadOtherFiles': 'Andere herunterladen',
  'AllDocumentsPage.docMenu.annotate': 'Kommentiren',
  'AllDocumentsPage.docMenu.closeSettings': 'Einstellungen schließen',
  'AllDocumentsPage.docMenu.createDirectory': 'Neuer Ordner',
  'AllDocumentsPage.docMenu.createAssignment': 'Neue Aufgabe',
  'AllDocumentsPage.docMenu.discard': 'Aussondern',
  'AllDocumentsPage.docMenu.delete': 'Löschen',
  'AllDocumentsPage.docMenu.displaySubfolders': 'Unterordner anzeigen',
  'AllDocumentsPage.docMenu.documentEditor': 'Dokument bearbeiten',
  'AllDocumentsPage.docMenu.editOnline': 'Bearbeiten',
  'AllDocumentsPage.docMenu.expandAll': 'Alles auspacken',
  'AllDocumentsPage.docMenu.collapseAll': 'Alles verstecken',
  'AllDocumentsPage.docMenu.openSettings': 'Einstellung',
  'AllDocumentsPage.docMenu.signDocument': 'Zeichen',
  'AllDocumentsPage.docMenu.tooltip': 'Ordneroptionen',
  'AllDocumentsPage.docMenu.upload': 'Hochladen',
  'AllDocumentsPage.docMenu.uploadDocument': 'Neues Dokument',
  'AllDocumentsPage.docMenu.uploadMultiple': 'Massen-Hochladen',
  'AllDocumentsPage.favoriteFilterActive': 'Es werden nur Favoritenordner angezeigt.',
  'AllDocumentsPage.searchFilterActive': 'Suche „{text}“ im Ordnernamen.',
  'AllDocumentsPage.docMenu.discard.disabled.byRights': 'Sie verfügen nicht über ausreichende Berechtigungen, um den Ordner zu verwerfen',
  'AllDocumentsPage.docMenu.discard.disabled.byEsticon': 'Der Ordner oder sein Unterordner ist für den Import von Dokumenten aus AspeEsticon reserviert',
  'AllDocumentsPage.directory.report.button.tooltip': 'Liste der Dokumente',
  'AllDocumentsPage.directory.report.modalTitle': 'Liste der Dokumente',
  'AllDocumentsPage.directory.report.modalOkText': 'Exportieren',
  'AllDocumentsPage.directory.report.form.authorOrder': 'Nach  Autor der Revision im Zeitraum',
  'AllDocumentsPage.directory.report.form.createTimeOrder': 'Nach Datum und Uhrzeit des Hochladens der Revision',
  'AllDocumentsPage.directory.report.form.documentNameOrder': 'Nach Titel des Dokuments',
  'AllDocumentsPage.directory.report.form.documentPathOrder': 'Nach dem Pfad zum Dokument',
  'AllDocumentsPage.directory.report.form.authorFilterTitle': 'Revisionsautor',
  'AllDocumentsPage.directory.report.form.revisionChangeDateTitle': 'Datum der Revisionsänderung',
  'AllDocumentsPage.directory.report.form.checkBoxIncludeSubdirectories': 'Unterordner umfassen',
  'AllDocumentsPage.directory.report.form.checkBoxIncludeAllRevisions': 'Alle Revisionen umfassen',
  'AllDocumentsPage.directory.report.form.clearDatepickers': 'Löschen',
  'AllDocumentsPage.downloadErrorsModal.documents': 'Dokumente:',
  'AllDocumentsPage.metadataDefinitions.loading.Error': 'Metadatendefinitionen konnten nicht geladen werden',
  'AllDocumentsPage.metadataDefinitions.noDefinitions.Error': 'Für die Dokumente sind keine Metadatendefinitionen festgelegt',
  'AllDocumentsPageFileToolbar.favoriteChanged.success': 'Sie haben Ihre bevorzugten Einstellungen erfolgreich geändert',
  'AllDocumentsPageFileToolbar.favoriteChanged.successParcial': 'Bei einigen Dokumenten wurde der Status nicht auf Favorit gesetzt',
  'AllDocumentsPageFileToolbar.favoriteChanged.failure': 'Keines der Dokumente wurde als Favorit gesetzt',
  'AllDocumentsPageFileToolbar.favoriteChanged.documentError.NotFound': 'Datei nicht gefunden',
  'AllDocumentsPageFileToolbar.favoriteChanged.documentError.IsDiscarded': 'Die Datei {fileName} wurde entfernt',
  'AllDocumentsPageFileToolbar.favoriteChanged.documentError.BadDirectory': 'Die Datei {fileName} befindet sich nicht mehr im Verzeichnis',
  'AllDocumentsPageFileToolbar.favoriteChanged.documentError.AccessDenied': 'Sie verfügen nicht über ausreichende Berechtigungen, um das Dateiattribut {fileName} zu ändern',
  'AllDocumentsPageFileToolbar.favoriteChanged.documentError.PropertySet': 'Die Datei {fileName} wurde bereits als Favorit gesetzt',
  'AllDocumentsPageFileToolbar.favoriteChanged.documentError.PropertyUnset': 'Die Datei {fileName} wurde bereits aus den Favoriten entfernt',
  'AllDocumentsPageFileToolbar.favoriteChanged.linkError.NotFound': 'Referenziertes Dokument nicht gefunden',
  'AllDocumentsPageFileToolbar.favoriteChanged.linkError.DocumentIsDiscarded': 'Der Link {linkName} führt zur entfernten verknüpften Datei',
  'AllDocumentsPageFileToolbar.favoriteChanged.linkError.LinkAccessDenied': 'Sie verfügen nicht über ausreichende Berechtigungen zum {linkName}',
  'AllDocumentsPageFileToolbar.favoriteChanged.linkError.DocumentAccessDenied': 'Sie verfügen nicht über ausreichende Berechtigungen für das durch den Link {linkName} verknüpfte Dokument.',
  'AllDocumentsPageFileToolbar.favoriteChanged.linkError.PropertySet': 'Das durch den Link {linkName} verknüpfte Dokument wurde bereits als Favorit gesetzt',
  'AllDocumentsPageFileToolbar.favoriteChanged.linkError.PropertyUnset': 'Das durch den Link {linkName} verknüpfte Dokument wurde bereits aus den Favoriten entfernt',
  'AllDocumentsPageDirectoryToolbar.directoryChoice': 'Ordner',
  'AllDocumentsPageDirectoryToolbar.directoryLinkChoice': 'Ordnerlink',
  'AllDocumentsPageDirectoryTree.NoMatchingDirs': 'Keine passenden Ordner.',
  'AllDocumentPageChangeViewButton.commonView': 'Allgemeine Ansichten',
  'AllDocumentPageChangeViewButton.metadata': 'Metadaten',
  'ApprovalDocumentRevisionInfo.cannotChangeEsticonDocument': 'Das Dokument wird aus Esticon importiert und kann nicht geändert werden.',
  'ApprovalDocumentRevisionInfo.noRevisionAdded': 'Die Revision wurde nicht geändert.',
  'ApprovalDocumentRevisionInfo.addRevision': 'Neue Revision hochladen',
  'ApprovalDocumentRevisionInfo.documentPreview': 'Dokumentvorschau',
  'ApprovalDocumentRevisionInfo.edit': 'Bearbeiten',
  'ApprovalDocumentRevisionInfo.annotate': 'Kommentieren',
  'ApprovalDocumentRevisionInfo.removeDocumentFromWf': 'Aus WF entfernen',
  'AppUsersAddFormModal.title': 'AspeHub-Administratoren hinzufügen',
  'AppUsersFormItem.addCurrentUsers.aspeHub': 'Von AspeHub hinzufügen',
  'AppUsersFormItem.addCurrentUsers.organization': 'Aus Organisation hinzufügen',
  'AppUsersFormItem.addCurrentUsers.disable.aspeHub': 'Die Liste der verfügbaren AspeHub-Benutzer ist leer.',
  'AppUsersFormItem.addCurrentUsers.disable.organization': 'Alle Benutzer der Organisation sind bereits im Projekt. Die Liste der verfügbaren Organisationsbenutzer ist leer.',
  'AppUsersFormItem.changeCurrentUsers': 'Liste der vorhandenen Benutzer ändern',
  'AppUsersFormItem.removeCurrentUsers': 'Liste der vorhandenen Benutzer löschen',
  'AppUsersFormItem.selectCurrentUsers': 'Wählen Sie einen vorhandenen Benutzer aus',
  'AppUsersFormItem.addNewUsers': 'Neu hinzufügen',
  'AppUsersFormItem.changeNewUsers': 'Liste der neuen Benutzer ändern',
  'AppUsersFormItem.removeNewUsers': 'Liste der neuen Benutzer löschen',
  'AspeEsticonAddForm.serverURL': 'Server-URL',
  'AspeEsticonAddForm.firmId': 'Datenbank-ID',
  'AspeEsticonListPanel.title': 'AspeEsticon applikation',
  'AspeEsticonListPanel.noRecordInDb': 'Kein Datensatz in der Datenbank stimmt mit dem eingegebenen Wert überein',
  'AspeEsticonListPanel.delete.confirmTitle': 'AspeEsticon applikation entfernen?',
  'AspeEsticonListPanel.add.title': 'AspeEsticon applikation hinzufügen',
  'AspeEsticonListPanel.edit.title': 'AspeEsticon applikation bearbeiten',
  'AspeEsticonPermissionPanel.title': 'AspeEsticon-Datenbank',
  'AspeHubUsersListPanel.addModal.title': 'Benutzer hinzufügen',
  'AppUserShareFormModal.form.selectGroup': 'Benutzer aus der Gruppe markieren',
  'AppUserShareFormModal.form.users': 'Benutzer zum Teilen',
  'AppUserShareFormModal.send': 'Schicken',
  'AppUserShareFormModal.form.items.message.initialValue.document': 'Hallo! Ich möchte das beigefügte Dokument mit Ihnen teilen.',
  'AppUserShareFormModal.form.items.message.initialValue.folder': 'Hallo! Ich möchte die angehängte Datei mit Ihnen teilen.',
  'AppUserShareFormModal.form.items.message.label': 'Nachricht an den Empfänger',
  'AppUserShareFormModal.title.document': 'Teilen vom Dokument',
  'AppUserShareFormModal.title.directory': 'Ordnerteilen',
  'MDApprovedProjectsReport.actionName': 'Aktionsname',
  'MDApprovedProjectsReport.constructionRegion': 'Ort der Realisierung',
  'MDApprovedProjectsReport.projectPhase': 'Zustand des ZK-Materials',
  'MDApprovedProjectsReport.projectState': 'Phase des ZK-Materials',
  'MDApprovedProjectsReport.displayMode.all': 'Alles',
  'MDApprovedProjectsReport.displayMode.justMine': 'Nur meins',
  'MDApprovedProjectsReport.displayMode.justMyDivision': 'Meine abteilung',
  'MDApprovedProjectsReport.prepareCkApprovals.create': 'Basis erstellen',
  'MDApprovedProjectsReport.prepareCkApprovals.create.success': 'Die Verhandlungsbasis wurde erfolgreich geschaffen',
  'MDApprovedProjectsReport.prepareCkApprovals.edit': 'Basis bearbeiten',
  'MDApprovedProjectsReport.prepareCkApprovals.edit.success': 'Die Verhandlungsbasis wurde erfolgreich bearbeitet',
  'MDApprovedProjectsReport.prepareCkApprovals.list': 'Erstellte Basen',
  'MDApprovedProjectsReport.prepareCkApprovals.list.number': 'Nummer',
  'MDApprovedProjectsReport.prepareCkApprovals.list.date': 'Datum',
  'MDApprovedProjectsReport.prepareCkApprovals.list.time': 'Zeit',
  'MDApprovedProjectsReport.prepareCkApprovals.list.ckIdentification': 'Identifizierung von ZK',
  'MDApprovedProjectsReport.prepareCkApprovals.list.referenceCode': 'Referenzcode',
  'MDApprovedProjectsReport.filters.referenceCodeOrNumber': 'Referenzcode, Nummer',
  'MdApprovalProjectsReport.ckApprovalMeetings': 'Dokumente zur Sitzung der ZK',
  'ApprovedProjectReport.menu.projectCard': 'ZK-Materialkarte',
  'ApprovedProjectReport.menu.goToDMS': 'Gehen Sie zu DMS',
  'ApprovedProjectReport.menu.deleteProject': 'ZK-Material löschen',
  'ApprovedProjectReport.menu.newIntention': 'Eine neue Absicht',
  'ApprovedProjectReport.menu.newActualization': 'Neues Update',
  'MDApprovedProjectsReport.lastActualizationDate': 'Aktualisierungsdatum',
  'MDApprovedProjectsReport.termExpired': 'Es ist überfällig',
  'MDApprovedProjectsReport.delete.title': 'ZK-Material löschen',
  'MDApprovedProjectsReport.delete.message': 'Sind Sie sicher, dass Sie das ZK-Material löschen möchten?',
  'MDApprovedProjectsReport.deletingInProgress': 'Aktion – Das Löschen des ZK-Materials wird ausgeführt',
  'MDApprovedProjectsReport.meetingDocument.inProgress': 'Ein Dokument für die Sitzung der Zentralkommission ist in Vorbereitung.',
  'MDMeetingResultModal.title': 'Eingabe des Ergebnisses der Sitzung der ZK',
  'MDMeetingResultModal.item.meetingDate': 'Datum der Diskussion der ZK',
  'MDMeetingResultModal.item.ckMeetingMomentsLink': 'Link zum Protokoll der Sitzung des Zentralkomitees',
  'MDMeetingResultModal.message.success': 'Die Ergebnisse der Sitzungen wurden erfolgreich in die Projekte aufgenommen.',
  'MdMeetingBackgroundMdMeetingStatusEnum.inProcess': 'Es wird diskutiert',
  'MdMeetingBackgroundMdMeetingStatusEnum.resolved': 'Vollendet',
  'MdMeetingBackgroundMdMeetingStatusEnum.canceled': 'Abgesagt',
  'MDMeetingResultModal.button.submit': 'Speichern und Eingabe abschließen',
  'AssignmentDocumentsField.documentIsAlreadySelected': 'Das Dokument ist bereits ausgewählt',
  'AssignmentDocumentsField.unableToAddDocument.content': 'Das Dokument wurde erfolgreich hochgeladen, konnte der Aufgabe jedoch nicht zugeordnet werden. Sie können versuchen, es als vorhandenes Dokument hinzuzufügen.',
  'AssignmentDocumentsField.addExistingDocument': 'Fügen Sie ein vorhandenes Dokument hinzu',
  'AssignmentDocumentsField.uploadNewDocument': 'Laden Sie ein neues Dokument hoch',
  'AssignmentDocumentsField.removeDocument': 'Entfernen Sie das Dokument',
  'AssignmentDocumentsField.selectDocuments': 'Dokumente auswählen',
  'CommonExportFormModal.title.assignments': 'Aufgabenliste exportieren',
  'CommonExportFormModal.title.projects': 'Projektliste exportieren',
  'CommonExportFormModal.title.selectFormat': 'Wählen Sie ein Dateiformat',
  'CommonExportFormModal.columnName.name': 'Name',
  'CommonExportFormModal.columnName.project': 'Projekt',
  'CommonExportFormModal.columnName.type': 'Typ',
  'CommonExportFormModal.columnName.state': 'Zustand',
  'CommonExportFormModal.columnName.taskGiver': 'Der Auftragnehmer',
  'CommonExportFormModal.columnName.representative': 'Vertreter des Auftraggebers (Liste der Vertreter)',
  'CommonExportFormModal.columnName.createDate': 'Erstellungsdatum',
  'CommonExportFormModal.columnName.deadline': 'Datum (Pflichtdatum)',
  'CommonExportFormModal.columnName.resolvedDate': 'Fertigstellungsdatum',
  'CommonExportFormModal.columnName.content': 'Inhalt',
  'CommonExportFormModal.columnName.resolvers': 'Löser (Liste der Löser)',
  'CommonExportFormModal.columnName.projectName': 'Projektname',
  'CommonExportFormModal.columnName.description': 'Beschreibung',
  'CommonExportFormModal.columnName.organization': 'Organisation',
  'CommonExportFormModal.columnName.labels': 'Etiketten',
  'AssignmentForm.error.currentUserRequiredInAssociates': 'Der aktuelle Benutzer ist im Namen des Sponsors zwingend erforderlich, wenn jemand anderes der Sponsor ist',
  'AssignmentForm.error.userDuplicateInAssociates': 'Derselbe Benutzer kann nicht gleichzeitig der Einreicher und der Vertreter des Einreichers sein',
  'AssignmentForm.deadlineDate': 'Gewünschtes Fertigstellungsdatum',
  'AssignmentForm.content': 'Inhalt',
  'AssignmentForm.bcfDetail': 'BCF-Detail',
  'AssignmentForm.solverIds': 'Löser',
  'AssignmentForm.assignmentDocuments': 'Unterlagen',
  'AssignmentForm.taskGiver': 'Der Auftragnehmer',
  'AssignmentForm.associateIds': 'Vertreter des Auftraggebers',
  'AssignmentForm.name': 'Aufgabenname',
  'AssignmentForm.name.required': 'Bitte geben Sie den Namen der Aufgabe ein',
  'AssignmentForm.type': 'Aufgabentyp',
  'AssignmentFormModal.projectSelect': 'Bitte wählen Sie das Projekt aus, in dem Sie die Aufgabe erstellen möchten',
  'AssignmentFormModal.projectSelect.title': 'Projektauswahl',
  'AssignmentFormModal.result.success': 'Aufgabe erfolgreich eingerichtet',
  'AssignmentFormModal.title': 'Erstellen Sie eine neue Aufgabe',
  'AssignmentOverview.created': 'Erstellt',
  'AssignmentOverview.projectNotFound': 'Projekt nicht gefunden',
  'AssignmentProjectCheck.youMustSwitchProject': 'Um mit dieser Aufgabe arbeiten zu können, müssen Sie zu dem Projekt wechseln, in dem die Aufgabe eingetragen ist.',
  'AssignmentProjectCheck.switchProject': 'Zum Projekt wechseln',
  'AssignmentProjectCheck.targetProject': 'Zielprojekt',
  'AssignmentStateEnum.new': 'Neu',
  'AssignmentStateEnum.inProgress': 'Es wird gelöst',
  'AssignmentStateEnum.forApproval': 'Zur Genehmigung',
  'AssignmentStateEnum.solved': 'Vollendet',
  'AssignmentStateEnum.closed': 'Abgesagt',
  'AssignmentStateField.startSolving': 'Beginnen Sie mit der Verarbeitung',
  'AssignmentStateField.submitForApproval': 'Zur Genehmigung einreichen',
  'AssignmentStateField.returnToSolver': 'Zurück zur Überarbeitung',
  'AssignmentStateField.approveAndClose': 'Genehmigen und schließen',
  'AssignmentStateField.continueSolving': 'Weiter lösen',
  'AssignmentStateField.cancel': 'Aufgabe abbrechen',
  'AssignmentSolversField.addAssociate': 'Verknüpfung hinzufügen',
  'AssignmentSolversField.addSolvers': 'Solver hinzufügen',
  'AssignmentSolversField.removeAssociate': 'Verknüpfung entfernen',
  'AssignmentSolversField.removeSolver': 'Solver entfernen',
  'AssignmentSolversField.cannotRemoveAssociate': 'Dieser Proxy kann nicht entfernt werden',
  'AssignmentSolversField.cannotRemoveLastSolver': 'Der letzte Solver kann nicht entfernt werden',
  'AssignmentSolversField.cannotRemovePrimarySolver': 'Der primäre Löser kann nicht entfernt werden',
  'AssignmentSolversField.primarySolver': 'Primärer Löser',
  'AssignmentSolversField.lostAccessWarning': 'Sie haben sich aus den Lösern entfernt und haben daher keinen Zugriff mehr auf die Aufgabe',
  'AssignmentSolversField.makePrimary': 'Legen Sie den primären Resolver fest',
  'AssignmentSourceEnum.createdByUser': 'Erstellt vom Benutzer',
  'AssignmentSourceEnum.assignedToUser': 'Löser',
  'AssignmentSourceEnum.primarySolver': 'Primärer Löser',
  'AssignmentSourceEnum.associate': 'Stellvertretender Löser',
  'AssignmentSourceEnum.taskGiver': 'Der Auftragnehmer',
  'AssignmentTypeEnum.userTask': 'Benutzerrolle',
  'AssignmentTypeEnum.userDirectoryPermission': 'Ordnerberechtigungen festlegen',
  'AssignmentTypeEnum.uploadDocuments': 'Dokumente hochladen',
  'AssignmentTypeEnum.comment': 'Bemerkung',
  'AssignmentTypeEnum.defectSolving': 'Behebung von Mängeln und unerledigten Angelegenheiten',
  'AssignmentTypeEnum.bcfTask': 'Aufgabe am Modell',
  'AttachmentCreateFormModal.title': 'Neuer Anhang',
  'AttachmentCreateFormModal.stagedUpload.title': 'Anhänge hochladen',
  'AttachmentCreateFormModal.form.item.relation.label': 'Sichtweite',
  'AuditLogBuildingModal.categories': 'Baukategorie',
  'AuditLog.assignment.label': 'Aktivitäten zur Aufgabe „{name}“',
  'AuditLog.directory.label': 'Aktivitäten im Ordner „{path}“',
  'AuditLog.detail.title': 'Detail',
  'AuditLog.document.label': 'Aktivitäten für Dokument „{path}“',
  'AuditLog.user.label': 'Aktivitäten auf „{username}“',
  'AuditLog.wfTemplate.label': 'Aktivitäten zur Workflow-Vorlage „{wfTemplateName}“',
  'AuditLog.organization.label': 'Aktivitäten zur Organisation „{orgName}“',
  'AuditLog.project.label': 'Aktivitäten zum Projekt „{projectName}“',
  'AuditLog.mdMaterial.label': 'Aktivitäten zum ZK-Material „{projectName}“',
  'AuditLog.division.label': 'Aktivitäten in der Abteilung „{divisionName}“',
  'AuditLog.mdUser.label': 'Aktivitäten für Benutzer „{mdUserName}“',
  'AuditLog.mdRole.label': 'Rollenspielaktivitäten',
  'AuditLog.projectTemplate.label': 'Aktivitäten für die Vorlage „{projectTemplateName}“',
  'AuditLog.permissionProfile.label': 'Aktivitäten im Berechtigungsprofil „{profileName}“',
  'AuditLog.orgLabel.label': 'Aktivitäten auf Label „{orgLabelName}“',
  'AuditLog.ProjectNote.label': 'Projektnotizen – Aktivitäten',
  'AuditLog.EntityData.Name': 'Name',
  'AuditLog.EntityData.Version': 'Version',
  'AuditLog.EntityData.Description': 'Beschreibung',
  'AuditLog.EntityData.DownloadCounter': 'Anzahl der Downloads',
  'AuditLog.EntityData.ElementId': 'Element-ID',
  'AuditLog.EntityData.AttributeName': 'Der Name des Attributs',
  'AuditLog.EntityData.true': 'Ja',
  'AuditLog.EntityData.false': 'NEIN',
  'AuditLog.EntityData.unset': 'nicht eingestellt',
  'AuditLog.EntityData.Path': 'Pfad',
  'AuditLog.EntityData.PermissionInheritance': 'Berechtigungen erben',
  'AuditLog.EntityData.Username': 'Benutzername',
  'AuditLog.EntityData.Firstname': 'Name',
  'AuditLog.EntityData.Lastname': 'Nachname',
  'AuditLog.EntityData.IsModel': 'Das Dokument ist ein Modell',
  'AuditLog.EntityData.State': 'Dokumentstatus',
  'AuditLog.EntityData.InstanceName': 'Workflowname',
  'AuditLog.EntityData.InstanceDescription': 'Workflow-Beschreibung',
  'AuditLog.EntityData.Content': 'Inhalt des Kommentars',
  'AuditLog.EntityData.ReservedById': 'Gebucht per Ausweis',
  'AuditLog.EntityData.ReservedDate': 'Datum der Reservierung',
  'AuditLog.EventData.orgName': 'Name der Organisation',
  'AuditLog.EventData.projectName': 'Projektname',
  'AuditLog.EventData.orgDescription': 'Organisation - Hinweis',
  'AuditLog.EventData.projectDescription': 'Projekt - Hinweis',
  'AuditLog.EventData.extendedPermission': 'Genehmigung',
  'AuditLog.EventData.isAdmin': 'Der Benutzer ist ein Administrator',
  'AuditLog.EventData.oldValue': 'Ursprünglicher Wert',
  'AuditLog.EventData.newValue': 'Neuer Wert',
  'AuditLog.EventData.mail': 'E-Mail',
  'AuditLog.EventData.orgNameOld': 'Ursprünglicher Name',
  'AuditLog.EventData.old	': 'Originalzustand',
  'AuditLog.EventData.@new': 'Neuwertiger Zustand',
  'AuditLog.EventData.users': 'Benutzer',
  'AuditLog.EventData.user': 'Benutzer',
  'AuditLog.EventData.set': 'Aufstellen',
  'AuditLog.EventData.status': 'Zustand',
  'AuditLog.EventData.oldStatus': 'Originalzustand',
  'AuditLog.EventData.removedLabels  ': 'Etiketten entfernt',
  'AuditLog.EventData.esticonProjectName': 'Projektname Esticon',
  'AuditLog.EventData.destinationProjectName': 'Der Name des Zielprojekts',
  'AuditLog.EventData.destinationProjectSign': 'Zielprojekt-Tag',
  'AuditLog.EventData.itemNote': 'Notiztext',
  'AuditLog.EventData.itemOrderId': 'Seriennummer beachten',
  'AuditLog.EntityData.DefaultTimeZoneId': 'Standardzeitzone',
  'AuditLog.EntityData.DefaultLanguage': 'Standardsprache',
  'AuditLog.EntityData.ICO': 'WIN',
  'AuditLog.EntityData.DefaultStorageArea': 'Standard-Repository',
  'AuditLog.EntityData.ProjectAdminCanInviteNewUser': 'Ein Projektmanager kann einen neuen Benutzer einladen',
  'AuditLog.EntityData.TimeZoneId': 'Zeitzone',
  'AuditLog.EntityData.Language': 'Sprache',
  'AuditLog.EntityData.Color': 'Farbe',
  'AuditLog.EntityType.assignment': 'Aufgabe',
  'AuditLog.EntityType.commentProcedure': 'Mahnverfahren',
  'AuditLog.EntityType.directory': 'Komponente',
  'AuditLog.EntityType.document': 'Dokumentieren',
  'AuditLog.EntityType.label': 'Etikett',
  'AuditLog.EntityType.project': 'Projekt',
  'AuditLog.EntityType.projectUserProfile': 'Projektbenutzer',
  'AuditLog.EntityType.projectNote': 'Projektnotiz',
  'AuditLog.EntityType.projectSetting': 'Projekteinstellungen',
  'AuditLog.EntityType.group': 'Gruppe',
  'AuditLog.EntityType.documentCategoryNode': 'Kategorie',
  'AuditLog.EntityType.documentCategoryTree': 'Kategorie',
  'AuditLog.EntityType.esticonToDocumentLink': 'Link zum Esticon-Dokument',
  'AuditLog.EntityType.role': 'Rolle',
  'AuditLog.EntityType.head': 'Zusammenfassung',
  'AuditLog.EntityType.link': 'Dokumentlink',
  'AuditLog.EntityType.shared': 'Ein Dokument teilen',
  'AuditLog.EntityType.linkedModelElement': 'Link zum Modell',
  'AuditLog.EntityType.workflow': 'Arbeitsablauf',
  'AuditLog.EntityType.workflowTemplate': 'Workflow-Vorlage',
  'AuditLog.EntityType.documentAnnotation': 'Hinweise im Dokument',
  'AuditLog.EntityType.organization': 'Organisation',
  'AuditLog.EntityType.extendedPermissionTemplate': 'Autorisierungsvorlage',
  'AuditLog.EntityType.projectTemplate': 'Projektvorlage',
  'AuditLog.EntityType.orgLabel': 'Organisationsetikett',
  'AuditLog.EntityType.mdRole': 'Rolle',
  'AuditLog.EntityType.mdDivision': 'Abteilung',
  'AuditLog.EntityType.mdProject': 'ZK-Material',
  'AuditLog.EntityType.mdUser': 'Benutzer',
  'AuditLog.EntityType.mdMeetingBackground': 'Grundlage der Verhandlungen',
  'AuditLog.EntityType.orgProjectCardData': 'Zusätzliche Projektdaten',
  'AuditLog.EntityType.projectCard': 'Registerkarte „Projekt“.',
  'AuditLog.EntityType.organizationStructure': 'Organisationsstruktur',
  'AuditLog.EntityTypeParam.assignment': 'Aufgabenparameter',
  'AuditLog.EntityTypeParam.commentProcedure': 'Parameter der Erinnerungsverwaltung',
  'AuditLog.EntityTypeParam.directory': 'Ordnerparameter',
  'AuditLog.EntityTypeParam.document': 'Dokumentparameter',
  'AuditLog.EntityTypeParam.label': 'Beschriftungsparameter',
  'AuditLog.EntityTypeParam.project': 'Projektparameter',
  'AuditLog.EntityTypeParam.projectUserProfile': 'Projektbenutzerparameter',
  'AuditLog.EntityTypeParam.projectNote': 'Projektnotiz',
  'AuditLog.EntityTypeParam.projectSetting': 'Projekteinstellungen',
  'AuditLog.EntityTypeParam.projectCard': 'Registerkarte „Projekt“.',
  'AuditLog.EntityTypeParam.group': 'Gruppenparameter',
  'AuditLog.EntityTypeParam.documentCategoryNode': 'Kategorieknotenparameter',
  'AuditLog.EntityTypeParam.documentCategoryTree': 'Kategorieparameter',
  'AuditLog.EntityTypeParam.esticonToDocumentLink': 'Esticon-Dokument',
  'AuditLog.EntityTypeParam.role': 'Rollenparameter',
  'AuditLog.EntityTypeParam.head': 'Zusammenfassungsparameter',
  'AuditLog.EntityTypeParam.link': 'Dokumentverknüpfungsparameter',
  'AuditLog.EntityTypeParam.shared': 'Parameter für die Dokumentfreigabe',
  'AuditLog.EntityTypeParam.linkedModelElement': 'Modellreferenzparameter',
  'AuditLog.EntityTypeParam.workflow': 'Workflow-Parameter',
  'AuditLog.EntityTypeParam.workflowTemplate': 'Parameter der Workflow-Vorlage',
  'AuditLog.EntityTypeParam.documentAnnotation': 'Kommentare im Dokument',
  'AuditLog.EntityTypeParam.organization': 'Organisation',
  'AuditLog.EntityTypeParam.extendedPermissionTemplate': 'Autorisierungsvorlage',
  'AuditLog.EntityTypeParam.projectTemplate': 'Projektvorlage',
  'AuditLog.EntityTypeParam.orgLabel': 'Organisationsetikett',
  'AuditLog.EntityTypeParam.orgProjectCardData': 'Zusätzliche Daten zu den Projekten der Organisation',
  'AuditLog.EntityTypeParam.mdRole': 'Rolle',
  'AuditLog.EntityTypeParam.mdDivision': 'Abteilung',
  'AuditLog.EntityTypeParam.mdProject': 'Projekt',
  'AuditLog.EntityTypeParam.mdUser': 'Benutzer',
  'AuditLog.EntityTypeParam.mdMeetingBackground': 'Grundlage der Verhandlungen',
  'AuditLog.EntityTypeParam.organizationStructure': 'Organisationsstruktur',
  'AuditLog.EventData.accessLevel': 'Zugriffsebene',
  'AuditLog.EventData.addedExtendedPermissions': 'Erweiterte Berechtigungen hinzugefügt',
  'AuditLog.EventData.addedLabels': 'Beschriftungen hinzugefügt',
  'AuditLog.EventData.annotationContent': 'Inhalt des Kommentars',
  'AuditLog.EventData.approvalDate': 'Datum der Genehmigung',
  'AuditLog.EventData.assignmentName': 'Aufgabenname',
  'AuditLog.EventData.assignmentOrderId': 'Aufgaben-ID',
  'AuditLog.EventData.assignmentState': 'Aufgabenstatus',
  'AuditLog.EventData.attachmentName': 'Anhangsname',
  'AuditLog.EventData.backgroundDocumentDescription': 'Beschreibung der Anfrage',
  'AuditLog.EventData.backgroundDocumentName': 'Der Name der Anfrage',
  'AuditLog.EventData.bulkActionType': 'Massenänderungstyp',
  'AuditLog.EventData.category': 'Kategorie',
  'AuditLog.EventData.categoryDescription': 'Kategoriebeschreibung',
  'AuditLog.EventData.categoryName': 'Kategoriename',
  'AuditLog.EventData.commentProcedureName': 'Name des Mahnverfahrens',
  'AuditLog.EventData.commentProcedureRole': 'Rolle im Kommentarprozess',
  'AuditLog.EventData.commentsCommentAddition': 'Antwort auf die Erinnerung',
  'AuditLog.EventData.commentsCommentCategory': 'Kommentarkategorie',
  'AuditLog.EventData.commentsCommentMsg': 'Erinnerungstext',
  'AuditLog.EventData.commentsCommentName': 'Der Name der Erinnerung',
  'AuditLog.EventData.commentsCommentOrderId': 'Kommentar-ID',
  'AuditLog.EventData.commentsCommentProcessors': 'Ein Team von Prozessoren',
  'AuditLog.EventData.commentsCommentState': 'Erinnerungsstatus',
  'AuditLog.EventData.commentsCommentStateOld': 'Der vorherige Status der Erinnerung',
  'AuditLog.EventData.commentsCommentSubmitters': 'Kommentarteam',
  'AuditLog.EventData.commentsName': 'Name des Mahnverfahrens',
  'AuditLog.EventData.commentsOrderId': 'Id des Mahnvorgangs',
  'AuditLog.EventData.commentsState': 'Stand des Mahnverfahrens',
  'AuditLog.EventData.content': 'Inhalt',
  'AuditLog.EventData.dailyCount': 'Anzahl pro Tag',
  'AuditLog.EventData.dailyLimitExceeded': 'Tageslimit überschritten',
  'AuditLog.EventData.deadlineDate': 'Begriff',
  'AuditLog.EventData.deletedUser': 'Benutzer entfernt',
  'AuditLog.EventData.description': 'Beschreibung',
  'AuditLog.EventData.directoryPath': 'Komponente',
  'AuditLog.EventData.documentName': 'Der Name des Dokuments',
  'AuditLog.EventData.documentState': 'Dokumentstatus',
  'AuditLog.EventData.draftTemplateDescription': 'Beschreibung der in Bearbeitung befindlichen Vorlage',
  'AuditLog.EventData.draftTemplateName': 'Der Name der in Bearbeitung befindlichen Vorlage',
  'AuditLog.EventData.email': 'E-Mail',
  'AuditLog.EventData.esticonId': 'Esticon-ID',
  'AuditLog.EventData.externalShare': 'Teilen außerhalb des Hubs',
  'AuditLog.EventData.false': 'NEIN',
  'AuditLog.EventData.fileName': 'Der Name der Datei',
  'AuditLog.EventData.fromDate': 'Ab dem Datum',
  'AuditLog.EventData.groupName': 'Der Name der Gruppe',
  'AuditLog.EventData.userStatus': 'Benutzerstatus',
  'AuditLog.EventData.oldUserStatus': 'Vorheriger Status des Benutzers',
  'AuditLog.EventData.hourlyCount': 'Zählen Sie pro Stunde',
  'AuditLog.EventData.hourlyLimitExceeded': 'Stundenlimit überschritten',
  'AuditLog.EventData.includeAllRevisions': 'Beinhaltet alle Revisionen',
  'AuditLog.EventData.includeSubdirectories': 'Enthält Unterverzeichnisse',
  'AuditLog.EventData.invitationMessage': 'Einladungsnachricht',
  'AuditLog.EventData.ipAddress': 'IP-Adresse des Benutzers',
  'AuditLog.EventData.isImport': 'Es handelt sich um einen Import',
  'AuditLog.EventData.labelColor': 'Etikettenfarbe',
  'AuditLog.EventData.labelName': 'Labelname',
  'AuditLog.EventData.linkFrom': 'Link vom Dokument',
  'AuditLog.EventData.linkName': 'Der Name des Dokumentlinks',
  'AuditLog.EventData.linkTo': 'Dokumentlink',
  'AuditLog.EventData.managerName': 'Dokumentenmanager',
  'AuditLog.EventData.method': 'Verfahren',
  'AuditLog.EventData.moveFrom': 'Verschoben von',
  'AuditLog.EventData.moveTo': 'Verschoben nach',
  'AuditLog.EventData.name': 'Name',
  'AuditLog.EventData.newCategoryDescription': 'Neue Kategoriebeschreibung',
  'AuditLog.EventData.newCategoryName': 'Neuer Kategoriename',
  'AuditLog.EventData.newDocument': 'Ein neues Dokument',
  'AuditLog.EventData.newLabels': 'Neue Etiketten',
  'AuditLog.EventData.newNodeName': 'Neuer Jobname',
  'AuditLog.EventData.newRoleName': 'Neuer Rollenname',
  'AuditLog.EventData.newStateChangePermission': 'Neue Berechtigung zum Ändern des Kommentarstatus',
  'AuditLog.EventData.newTeamName': 'Neuer Teamname',
  'AuditLog.EventData.newUserPermission': 'Neue Benutzerberechtigung',
  'AuditLog.EventData.nodeName': 'Knotenname',
  'AuditLog.EventData.noteMessage': 'Kommentar',
  'AuditLog.EventData.noteOldMessage': 'Alter Kommentar',
  'AuditLog.EventData.noteTitle': 'Titel der Notiz',
  'AuditLog.EventData.old': 'Alter Wert',
  'AuditLog.EventData.oldAssignmentState': 'Vorheriger Aufgabenstatus',
  'AuditLog.EventData.oldCategoryDescription': 'Vorherige Kategoriebeschreibung',
  'AuditLog.EventData.oldCategoryName': 'Vorheriger Kategoriename',
  'AuditLog.EventData.oldCommentsCommentAddition': 'Vorherige Reaktion auf die Erinnerung',
  'AuditLog.EventData.oldCommentsCommentCategory': 'Vorherige Kommentarkategorie',
  'AuditLog.EventData.oldCommentsCommentMsg': 'Vorheriger Kommentartext',
  'AuditLog.EventData.oldCommentsCommentName': 'Der vorherige Name des Kommentars',
  'AuditLog.EventData.oldCommentsCommentProcessors': 'Bisheriges Bearbeitungsteam',
  'AuditLog.EventData.oldCommentsCommentSubmitters': 'Vorheriges Kommentatorenteam',
  'AuditLog.EventData.oldCommentsState': 'Bisheriger Stand des Mahnverfahrens',
  'AuditLog.EventData.oldDocumentState': 'Der vorherige Status des Dokuments',
  'AuditLog.EventData.oldLinkName': 'Alter Linkname',
  'AuditLog.EventData.oldNodeName': 'Alter Jobname',
  'AuditLog.EventData.oldPrimarySolver': 'Vorheriger primärer Löser',
  'AuditLog.EventData.oldRoleName': 'Alter Rollenname',
  'AuditLog.EventData.oldStateChangePermission': 'Vorherige Berechtigung zum Ändern des Status einer Erinnerung',
  'AuditLog.EventData.oldTeamName': 'Vorheriger Teamname',
  'AuditLog.EventData.oldUserPermission': 'Vorherige Benutzerberechtigungen',
  'AuditLog.EventData.orderId': 'Ausweis',
  'AuditLog.EventData.origNodeName': 'Der ursprüngliche Name des Knotens',
  'AuditLog.EventData.origRoleName': 'Ursprünglicher Rollenname',
  'AuditLog.EventData.path': 'Pfad',
  'AuditLog.EventData.primarySolver': 'Primärer Löser',
  'AuditLog.EventData.reason': 'Grund',
  'AuditLog.EventData.receiver': 'Empfänger',
  'AuditLog.EventData.removedExtendedPermissions': 'Erweiterte Berechtigungen entfernt',
  'AuditLog.EventData.removedLabels': 'Etiketten entfernt',
  'AuditLog.EventData.removedNodes': 'Aufgaben entfernt',
  'AuditLog.EventData.requestAddress': 'Adresse anfordern',
  'AuditLog.EventData.requestedState': 'Gewünschter Status',
  'AuditLog.EventData.reservedById': 'Gebucht per Ausweis',
  'AuditLog.EventData.reservedByName': 'Über den Titel reserviert',
  'AuditLog.EventData.reservedDate': 'Datum der Reservierung',
  'AuditLog.EventData.resultOk': 'Es ist richtig gelaufen',
  'AuditLog.EventData.revisionDescription': 'Revisionsbeschreibung',
  'AuditLog.EventData.revisionNumber': 'Revisionsnummer',
  'AuditLog.EventData.secondRevisionNumber': 'Revisionsnummer zum Vergleichen',
  'AuditLog.EventData.secondRevisionDescription': 'Revisionsbeschreibung zum Vergleich',
  'AuditLog.EventData.roleName': 'Rollenname',
  'AuditLog.EventData.secDocError': 'Sekundärer Dokumentfehler',
  'AuditLog.EventData.secondaryDocumentName': 'Anhangsname',
  'AuditLog.EventData.secundaryDocumentType': 'Sekundärer Dokumenttyp',
  'AuditLog.EventData.state': 'Zustand',
  'AuditLog.EventData.teamName': 'Teamname',
  'AuditLog.EventData.teamRole': 'Die Rolle des Teams',
  'AuditLog.EventData.templateDescription': 'Beschreibung der Vorlage',
  'AuditLog.EventData.templateName': 'Der Name der Vorlage',
  'AuditLog.EventData.toDate': 'Nach Datum',
  'AuditLog.EventData.true': 'Ja',
  'AuditLog.EventData.unset': 'nicht eingestellt',
  'AuditLog.EventData.type': 'Typ',
  'AuditLog.EventData.userAgent': 'Benutzeragent',
  'AuditLog.EventData.userId': 'Benutzer-ID',
  'AuditLog.EventData.username': 'Benutzername',
  'AuditLog.EventData.userName': 'Benutzername',
  'AuditLog.EventData.userPermission': 'Benutzerautorisierung',
  'AuditLog.EventData.version': 'Version',
  'AuditLog.EventData.voteMessage': 'Kommentar',
  'AuditLog.EventData.votePortName': 'Der Name des Abstimmungsereignisses',
  'AuditLog.EventData.workflowDescription': 'Workflow-Beschreibung',
  'AuditLog.EventData.WorkflowName': 'Workflowname',
  'AuditLog.EventData.workflowName': 'Workflowname',
  'AuditLog.EventData.workflowNodeDescription': 'Stellenbeschreibung',
  'AuditLog.EventData.workflowNodeName': 'Jobname',
  'AuditLog.EventData.message': 'Nachricht',
  'AuditLog.EventData.notifiedUser': 'Benachrichtigter Benutzer',
  'AuditLog.EventData.workflowOrderId': 'Workflow-ID',
  'AuditLog.EventData.stateKD': 'Übertragungsstatus',
  'AuditLog.EventData.endTimeKD': 'Ende der Übertragung',
  'AuditLog.EventData.startTimeKD': 'Beginn der Übertragung',
  'AuditLog.EventData.versionName': 'Name',
  'AuditLog.EventData.versionSign': 'Markieren',
  'AuditLog.EventData.refereerName': 'Der Name des Dokuments',
  'AuditLog.EventData.referenceName': 'Referenzname',
  'AuditLog.EventData.menuName': 'Name im Menü',
  'AuditLog.EventData.applicationType': 'Anwendungstyp',
  'AuditLog.EventData.mdProjectName': 'Aktionsname',
  'AuditLog.EventData.mdProjectDescription': 'Beschreibung der Veranstaltung',
  'AuditLog.EventData.justification': 'Rechtfertigung',
  'AuditLog.EventData.hubProjectDataName': 'DMS',
  'AuditLog.EventData.mdCkIdentificaion': 'Identifizierung von ZK',
  'AuditLog.EventData.mdProjectPhase': 'Ereignisstatus',
  'AuditLog.EventData.mdProjectOldState': 'Ursprünglicher Wert',
  'AuditLog.EventData.mdProjectNewState': 'Neuer Wert',
  'AuditLog.EventData.mdProjectProcessorDivisionName': 'Abteilung des Verarbeiters',
  'AuditLog.EventData.mdGuarantor': 'Garant',
  'AuditLog.EventData.mdProposer': 'Moderator',
  'AuditLog.EventData.mdRequestProcessor': 'Kommentarhandler',
  'AuditLog.EventData.oldMdRequestProcessor': 'Ursprünglicher Kommentator',
  'AuditLog.EventData.mdProjectDataName': 'Name des Kartenparameters',
  'AuditLog.EventData.mdRoleName': 'Rollenname',
  'AuditLog.EventData.mdProjectDataOldValue': 'Der ursprüngliche Wert des Parameters',
  'AuditLog.EventData.mdProjectDataNewValue': 'Neuer Parameterwert',
  'AuditLog.EventData.mdApprovalConditionCondition': 'Genehmigungsbedingung',
  'AuditLog.EventData.mdApprovalConditionApprovalDescription': 'Beschreibung des Zustands',
  'AuditLog.EventData.mdApprovalConditionDeadline': 'Begriff',
  'AuditLog.EventData.mdApprovalConditionApprovalDone': 'Es ist erfüllt',
  'AuditLog.EventData.mdApprovalConditionOld': 'Die ursprüngliche genehmigungsbedingung',
  'AuditLog.EventData.mdApprovalConditionOldApprovalDescription': 'Die ursprüngliche Beschreibung des Zustands',
  'AuditLog.EventData.mdApprovalConditionOldDeadline': 'Die ursprüngliche Begriff',
  'AuditLog.EventData.mdApprovalConditionOldApprovalDone': 'Es ist erfüllt – Originalwert',
  'AuditLog.EventData.roleHead': 'Der Hauptbenutzer der Rolle',
  'AuditLog.EventData.division': 'Abteilung',
  'AuditLog.EventData.roleType': 'Rollentyp',
  'AuditLog.EventData.isHead': 'Er ist der Leiter der Abteilung',
  'AuditLog.EventData.isRoleHead': 'Ist der Hauptbenutzer in der Rolle',
  'AuditLog.EventData.roleHeadEmail': 'Der Hauptbenutzer der Rolle',
  'AuditLog.EventData.byDays': 'Anzahl der Tage',
  'AuditLog.EventData.approved': 'Genehmigt',
  'AuditLog.EventData.rejected': 'Abgelehnt',
  'AuditLog.EventName.approvalDocumentAssigned': 'Zuweisen eines genehmigten Dokuments',
  'AuditLog.EventName.approvalDocumentAddedToWorkflow': 'Erstellen Sie ein genehmigtes Dokument und fügen Sie es hinzu',
  'AuditLog.EventName.approvalDocumentRevisionAddedInWorkflow': 'Hinzufügen einer Überarbeitung zu einem genehmigten Dokument',
  'AuditLog.EventName.approvalDocumentRemovedFromWorkflow': 'Löschen eines genehmigten Dokuments aus einem Workflow',
  'AuditLog.EventName.associateUserAddedToAssignment': 'Der Vertreter des Einreichers wurde der Aufgabe hinzugefügt',
  'AuditLog.EventName.associateUserRemovedFromAssignment': 'Der Vertreter des Einreichers wurde aus der Aufgabe entfernt',
  'AuditLog.EventName.bulkAction': 'Massenaktion',
  'AuditLog.EventName.bulkDocumentsChange': 'Massenänderung von Dokumenten',
  'AuditLog.EventName.workflowAssignApprovalDocuments': 'Zuordnung genehmigter Dokumente',
  'AuditLog.EventName.initialised': 'Initialisierung',
  'AuditLog.EventName.patched': 'Ändern',
  'AuditLog.EventName.created': 'Schaffung',
  'AuditLog.EventName.currentRevisionChanged': 'Ändern Sie die aktuelle Revision',
  'AuditLog.EventName.invitationCreated': 'Erstellen Sie eine Einladung',
  'AuditLog.EventName.invitationAccepted': 'Die Einladung annehmen',
  'AuditLog.EventName.statusChanged': 'Statusänderung',
  'AuditLog.EventName.requiredCategoryTreeAdded': 'Hinzufügen einer obligatorischen Kategorie',
  'AuditLog.EventName.requiredCategoryTreeRemoved': 'Löschen einer Pflichtkategorie',
  'AuditLog.EventName.requiredCategoryTreeChanged': 'Änderung der Pflichtkategorie',
  'AuditLog.EventName.groupAccessLevelHasBeenSet': 'Zugriff auf eine Gruppe hinzufügen',
  'AuditLog.EventName.groupAccessLevelHasBeenUnset': 'Zugriff auf eine Gruppe entfernen',
  'AuditLog.EventName.groupUsersAdded': 'Zur Gruppe hinzugefügte Benutzer',
  'AuditLog.EventName.userAccessLevelHasBeenSet': 'Benutzerzugriff hinzufügen',
  'AuditLog.EventName.userAccessLevelHasBeenUnset': 'Benutzerzugriff wird entfernt',
  'AuditLog.EventName.permisssionInheritanceChanged': 'Berechtigungsvererbung ändern',
  'AuditLog.EventName.move': 'Umzug',
  'AuditLog.EventName.labelAdded': 'Etikett hinzufügen',
  'AuditLog.EventName.labelRemoved': 'Ein Etikett löschen',
  'AuditLog.EventName.linkAdded': 'Einen Link zu einem Dokument hinzufügen',
  'AuditLog.EventName.revisionAdded': 'Eine Revision hinzufügen',
  'AuditLog.EventName.categoryTreeNodeAdded': 'Hinzufügen eines Kategorieknotens',
  'AuditLog.EventName.categoryTreeNodeChanged': 'Kategorieknoten ändern',
  'AuditLog.EventName.categoryTreeNodeRemoved': 'Löschen eines Kategorieknotens',
  'AuditLog.EventName.suspended': 'Deaktivierung',
  'AuditLog.EventName.activated': 'Aktivierung',
  'AuditLog.EventName.discarded': 'Beseitigung',
  'AuditLog.EventName.requiredDefaultCategoryTreeNodechanged': 'Ändern des erforderlichen Kategorieknotens',
  'AuditLog.EventName.moved': 'Umzug',
  'AuditLog.EventName.documentsDiscarded': 'Dokumente verwerfen',
  'AuditLog.EventName.documentsRestored': 'Dokumentenwiederherstellung',
  'AuditLog.EventName.documentsMoved': 'Verschieben Sie ein Dokument',
  'AuditLog.EventName.directoriesDiscarded': 'Ordner verwerfen',
  'AuditLog.EventName.directoriesRestored': 'Ordner wiederherstellen',
  'AuditLog.EventName.documentAddedForApproval': 'Hinzufügen eines genehmigten Dokuments zum Workflow',
  'AuditLog.EventName.restored': 'Erneuerung',
  'AuditLog.EventName.used': 'Ausführung',
  'AuditLog.EventName.documentShared': 'Das Dokument wurde geteilt',
  'AuditLog.EventName.sharedDocumentDownloaded': 'Laden Sie ein freigegebenes Dokument herunter',
  'AuditLog.EventName.shareSent': 'Teilen gesendet',
  'AuditLog.EventName.documentDownloaded': 'Dokument-Download',
  'AuditLog.EventName.documentsDownloaded': 'Dokumente herunterladen',
  'AuditLog.EventName.secondaryFileAdded': 'Die Anhangdatei wurde hinzugefügt',
  'AuditLog.EventName.documentAddedToModels': 'Das Dokument wurde den Modellen hinzugefügt',
  'AuditLog.EventName.documentRemovedFromModels': 'Das Dokument wurde aus den Modellen entfernt',
  'AuditLog.EventName.secondaryFileDownloaded': 'Die Anhangdatei wurde heruntergeladen',
  'AuditLog.EventName.linkRemoved': 'Bindung wurde entfernt',
  'AuditLog.EventName.addedToGroup': 'Der Benutzer wurde der Gruppe hinzugefügt',
  'AuditLog.EventName.removedFromGroup': 'Der Benutzer wurde aus der Gruppe entfernt',
  'AuditLog.EventName.usersInvited': 'Der Benutzer wurde eingeladen',
  'AuditLog.EventName.workflowStateChanged': 'Der Status des Dokuments wurde geändert',
  'AuditLog.EventName.reserved': 'Reservierung',
  'AuditLog.EventName.reservationReleased': 'Freigabe der Reservierung',
  'AuditLog.EventName.ownerChanged': 'Der Dokumentenmanager wurde geändert',
  'AuditLog.EventName.userRoleAdded': 'Dem Benutzer wurde eine Rolle zugewiesen',
  'AuditLog.EventName.userRoleDeleted': 'Die Rolle des Benutzers wurde entfernt',
  'AuditLog.EventName.roleAddedToWorkflow': 'Die Rolle wurde dem Workflow hinzugefügt',
  'AuditLog.EventName.roleAddedToNode': 'Die Rolle wurde der Aufgabe hinzugefügt',
  'AuditLog.EventName.roleRemovedFromNode': 'Die Rolle wurde aus dem Job entfernt',
  'AuditLog.EventName.roleAddedToBackgroundDocument': 'Die Rolle wurde der Anfrage hinzugefügt',
  'AuditLog.EventName.finished': 'Vollendet',
  'AuditLog.EventName.backgroundDocumentAssigned': 'Das Dokument wurde der Anfrage zugeordnet',
  'AuditLog.EventName.backgroundDocumentAccepted': 'Der Antrag wurde genehmigt',
  'AuditLog.EventName.backgroundDocumentRefused': 'Der Antrag wurde abgelehnt',
  'AuditLog.EventName.backgroundDocumentRequestAdded': 'Eine neue Anfrage wurde hinzugefügt',
  'AuditLog.EventName.backgroundDocumentRequestChanged': 'Die Anfrage wurde geändert',
  'AuditLog.EventName.backgroundDocumentRequestRemoved': 'Die Anfrage wurde entfernt',
  'AuditLog.EventName.workflowNodeStarted': 'Eine neue Aufgabe wurde gestartet',
  'AuditLog.EventName.workflowNodeRoleDelegation': 'Der Löser hat seine Rolle delegiert',
  'AuditLog.EventName.workflowNodeVote': 'Der Löser stimmte über die Aufgabe ab',
  'AuditLog.EventName.workflowNodeFinish': 'Die Aufgabe ist beendet',
  'AuditLog.EventName.documentAddedAsBackgroundDocument': 'Das Dokument wurde zur Anfrage hochgeladen',
  'AuditLog.EventName.documentRemovedFromBackgroundDocument': 'Das Dokument wurde aus der Anfrage entfernt',
  'AuditLog.EventName.workflowVote': 'Abstimmung für Workflow',
  'AuditLog.EventName.roleRemovedFromBackgroundDocument': 'Die Rolle wurde aus der Anfrage entfernt',
  'AuditLog.EventName.documentRemovedFromComments': 'Das Dokument wurde aus dem Kommentarverfahren entfernt',
  'AuditLog.EventName.documentAddedToComments': 'Das Dokument wurde dem Kommentarverfahren hinzugefügt',
  'AuditLog.EventName.roleAddedToComments': 'Die Rolle wurde zum Erinnerungsprozess hinzugefügt',
  'AuditLog.EventName.roleRemovedFromComments': 'Die Rolle wurde aus dem Erinnerungsprozess entfernt',
  'AuditLog.EventName.userAddedToComments': 'Der Benutzer wurde zum Erinnerungsprozess hinzugefügt',
  'AuditLog.EventName.userRemovedFromComments': 'Der Benutzer wurde aus der Erinnerungsverwaltung entfernt',
  'AuditLog.EventName.userResendActivation': 'Erneutes Senden der Aktivierungs-E-Mail',
  'AuditLog.EventName.commentsUserPatched': 'Der Benutzer wurde im Erinnerungsverfahren geändert',
  'AuditLog.EventName.teamAddedToComments': 'Das Team wurde in das Rückrufverfahren aufgenommen',
  'AuditLog.EventName.teamRemovedFromComments': 'Das Team wurde aus dem Rückrufverfahren zurückgezogen',
  'AuditLog.EventName.commentsTeamPatched': 'Das Team wurde im Mahnverfahren angepasst',
  'AuditLog.EventName.CommentsSetState': 'Status für das Mahnverfahren geändert',
  'AuditLog.EventName.commentsComentCreated': 'Erinnerung erstellt',
  'AuditLog.EventName.commentsComentPatched': 'Bearbeitete Erinnerung',
  'AuditLog.EventName.commentsComentStateSet': 'Kommentarstatus geändert',
  'AuditLog.EventName.attachmentAddedToCommentsComment': 'Anhang für Kommentar hinzugefügt',
  'AuditLog.EventName.commentsComentNoteAdded': 'Kommentar zur Erinnerung hinzugefügt',
  'AuditLog.EventName.documentAddedToCommentsComment': 'Dokument durch Kommentar hinzugefügt',
  'AuditLog.EventName.documentRemovedFromCommentsComment': 'Dokument aus Kommentar entfernt',
  'AuditLog.EventName.commentsStateSet': 'Der Status des Mahnverfahrens ist gesetzt',
  'AuditLog.EventName.commentsComentNoteEdited': 'Der Erinnerungskommentar wurde bearbeitet',
  'AuditLog.EventName.commentsComentNoteDeleted': 'Erinnerungskommentar gelöscht',
  'AuditLog.EventName.commentsComentNoteRestored': 'Kommentarerinnerung wiederhergestellt',
  'AuditLog.EventName.attachmentDiscardedFromCommentsComment': 'Anhang aus Kommentar entfernt',
  'AuditLog.EventName.attachmentRestoredInCommentsComment': 'Anhang im Kommentar wiederhergestellt',
  'AuditLog.EventName.exported': 'Es hat ein Export stattgefunden',
  'AuditLog.EventName.extendedPermissionChanged': 'Benutzerberechtigung geändert',
  'AuditLog.EventName.startEsticonUpload': 'Datei-Upload für Esticon gestartet',
  'AuditLog.EventName.startEsticonDocumentUpload': 'Der Dokumenten-Upload für Esticon wurde gestartet',
  'AuditLog.EventName.esticonDocumentUploaded': 'Esticon-Dokument hochgeladen',
  'AuditLog.EventName.esticonDocumentRejected': 'Esticon-Dokument abgelehnt',
  'AuditLog.EventName.documentApprovalFinished': 'Dokumentgenehmigung abgeschlossen',
  'AuditLog.EventName.closed': 'Beendigung',
  'AuditLog.EventName.documentRemovedFromCommentProcedure': 'Dokument aus dem Kommentierungsverfahren entlassen',
  'AuditLog.EventName.documentAddedToCommentProcedure': 'Dokument zum Kommentarverfahren hinzugefügt',
  'AuditLog.EventName.documentAddedToAssignment': 'Dokument zur Aufgabe hinzugefügt',
  'AuditLog.EventName.documentRemovedFromAssignment': 'Dokument aus der Aufgabe entfernt',
  'AuditLog.EventName.approvalDocumentsAccepted': 'Genehmigte Dokumente wurden genehmigt',
  'AuditLog.EventName.approvalDocumentsRefused': 'Genehmigte Dokumente wurden abgelehnt',
  'AuditLog.EventName.CommentProcedureDocumentsAdded': 'Die Dokumente wurden dem Kommentierungsverfahren hinzugefügt',
  'AuditLog.EventName.commentProcedureRolesPatched': 'Im Kommentarverfahren wurden die Rollen geändert',
  'AuditLog.EventName.usersAddedToComments': 'Benutzer wurden zum Kommentarprozess hinzugefügt',
  'AuditLog.EventName.commentProcedureCategoryAdded': 'Kategorie erstellt',
  'AuditLog.EventName.commentProcedureCategoryPatched': 'Geänderte Kategorie',
  'AuditLog.EventName.commentProcedureCategoryDeleted': 'Kategorie gelöscht',
  'AuditLog.EventName.commentProcedureCategoriesImported': 'Kategorien importiert',
  'AuditLog.EventName.commentProcedureAdded': 'Erinnerungsverfahren hinzugefügt',
  'AuditLog.EventName.commentProcedureRemoved': 'Erinnerungsverfahren entfernt',
  'AuditLog.EventName.commentProcedureDeadlineSet': 'Die Frist für das Mahnverfahren ist festgelegt',
  'AuditLog.EventName.commentsTeamPermissionPatched': 'Teamberechtigungen wurden geändert',
  'AuditLog.EventName.documentDerivatesDownloaded': 'Es werden Dokumentvorschauen angezeigt',
  'AuditLog.EventName.documentSecondaryDerivatesDownloaded': 'Sekundäre Dokumentvorschauen werden angezeigt',
  'AuditLog.EventName.revisionDiscarded': 'Die Überarbeitung des Dokuments wurde verworfen',
  'AuditLog.EventName.revisionRestored': 'Die Überarbeitung des Dokuments wurde wieder aufgenommen',
  'AuditLog.EventName.projectOpened': 'Projekt öffnen',
  'AuditLog.EventName.projectClosed': 'Abschluss des Projekts',
  'AuditLog.EventName.reportCreated': 'Bericht erstellt',
  'AuditLog.EventName.projectAddedToMeetingApproval': 'Hinzufügen von Material zum Besprechungshintergrund',
  'AuditLog.EventName.projectRemovedFromMeetingApproval': 'Entfernung von Material aus der Verhandlungsbasis',
  'AuditLog.EventName.workflowParallelInit': 'Paralleler Workflow initialisiert',
  'AuditLog.EventName.workflowNodeNoteAdded': 'Hinzufügen eines Kommentars zur Workflow-Aufgabendiskussion',
  'AuditLog.EventName.workflowNodeNoteEdited': 'Der Kommentar zur Workflow-Aufgabendiskussion wurde geändert',
  'AuditLog.EventName.workflowNodeNoteDeleted': 'Kommentar aus Diskussion über Workflow-Aufgaben entfernen',
  'AuditLog.EventName.workflowNodeNoteRestored': 'Aktualisieren Sie den Kommentar zur Workflow-Aufgabendiskussion',
  'AuditLog.EventName.attachmentAddedToWorkflowNode': 'Hinzufügen eines Anhangs zur Workflow-Aufgabendiskussion',
  'AuditLog.EventName.attachmentDiscardedFromWorkflowNode': 'Entfernen Sie den Anhang zur Workflow-Aufgabendiskussion',
  'AuditLog.EventName.attachmentRestoredInWorkflowNode': 'Aktualisieren Sie den Anhang zur Workflow-Aufgabendiskussion',
  'AuditLog.EventName.mailLimitExceed': 'Die Anzahl der gesendeten E-Mails wurde überschritten',
  'AuditLog.EventName.managerRoleAddedToWorkflow': 'Die Administratorrolle wurde dem Workflow hinzugefügt',
  'AuditLog.EventName.userAddedToAssignment': 'Der Benutzer wurde zur Aufgabe hinzugefügt',
  'AuditLog.EventName.userRemovedFromAssignment': 'Der Benutzer wurde aus der Aufgabe entfernt',
  'AuditLog.EventName.primarySolverChanged': 'Änderung des primären Lösers',
  'AuditLog.EventName.approvalDocumentAddRevision': 'Überarbeitung zum genehmigten Dokument hochgeladen',
  'AuditLog.EventName.assignmentNoteAdded': 'Hinzufügen eines Kommentars zur Aufgabendiskussion',
  'AuditLog.EventName.assignmentNoteEdited': 'Ändern Sie den Kommentar zur Aufgabendiskussion',
  'AuditLog.EventName.assignmentNoteDeleted': 'Entfernen Sie einen Kommentar zur Aufgabendiskussion',
  'AuditLog.EventName.assignmentNoteRestored': 'Erfrischender Kommentar zur Aufgabendiskussion',
  'AuditLog.EventName.attachmentAddedToAssignment': 'Hinzufügen eines Anhangs zur Aufgabenbesprechung',
  'AuditLog.EventName.attachmentDiscardedFromAssignment': 'Anhang zur Aufgabendiskussion entfernen',
  'AuditLog.EventName.attachmentRestoredInAssignment': 'Erfrischender Anhang zur Aufgabenbesprechung',
  'AuditLog.EventName.directoryShared': 'Der Ordner wurde freigegeben',
  'AuditLog.EventName.documentLinksCreated': 'Links zu erstellten Dokumenten',
  'AuditLog.EventName.documentLinkAdded': 'Dokumentlink hinzugefügt',
  'AuditLog.EventName.documentLinkRemoved': 'Dokumentlink entfernt',
  'AuditLog.EventName.documentLinkChanged': 'Dokumentlink geändert',
  'AuditLog.EventName.documentEsticonMultiupload': 'Massen-Upload von esticon',
  'AuditLog.EventName.documentAnnotationsCreated': 'Kommentare im Dokument erstellt',
  'AuditLog.EventName.documentAnnotationsChanged': 'Kommentare im Dokument geändert',
  'AuditLog.EventName.documentAnnotationAdded': 'Dem Dokument wurde ein Kommentar hinzugefügt',
  'AuditLog.EventName.documentAnnotationChanged': 'Kommentar im Dokument geändert',
  'AuditLog.EventName.annotationRemoved': 'Kommentar aus Dokument entfernt',
  'AuditLog.EventName.documentAnnotationAddedToCommentsComment': 'Dokumentkommentar, der dem Kommentar beigefügt ist',
  'AuditLog.EventName.directoryLinkAdded': 'Verzeichnislink hinzugefügt',
  'AuditLog.EventName.directoryLinkRemoved': 'Verzeichnislink entfernt',
  'AuditLog.EventName.directoryLinkChanged': 'Verzeichnislink bearbeitet',
  'AuditLog.EventName.esticonDocumentReturnForRework': 'Esticon-Dokument zur Überarbeitung zurückgeschickt',
  'AuditLog.EventName.esticonDocumentPublished': 'Esticon-Dokument veröffentlicht',
  'AuditLog.EventName.directoryLinkMoved': 'Verzeichnislink verschoben',
  'AuditLog.EventName.attachmentDownloaded': 'Der Anhang wurde heruntergeladen',
  'AuditLog.EventName.attachmentsDownloaded': 'Anhänge wurden heruntergeladen',
  'AuditLog.EventName.labelReplaced': 'Beschriftungen aktualisiert',
  'AuditLog.EventName.unknown': 'Unbekanntes Ereignis',
  'AuditLog.EventName.esticonDocumentSetApprovalDate': 'Datum der Dokumentfreigabe festgelegt',
  'AuditLog.EventName.transferToKD': 'Projektübertragung in die Steuerungsdatenbank',
  'AuditLog.EventName.attachmentVisibilityChangedInCommentsComment': 'Sichtbarkeit von Anhängen geändert',
  'AuditLog.EventName.docToDocLinksCreated': 'Dokumentverknüpfungen erstellt',
  'AuditLog.EventName.assignmentStateChanged': 'Den Status einer Aufgabe ändern',
  'AuditLog.EventName.organizationDeleted': 'Organisation entfernt',
  'AuditLog.EventName.addOrganizationUser': 'Organisationsbenutzer hinzugefügt',
  'AuditLog.EventName.patchOrganizationUser': 'Organisationsbenutzer geändert',
  'AuditLog.EventName.usersAddedToProject': 'Dem Projekt wurden Benutzer hinzugefügt',
  'AuditLog.EventName.projectUserSetAdmin': 'Benutzer des Projekts wurden geändert und gehören nun zur Administratorgruppe',
  'AuditLog.EventName.projectUserChangeStatus': 'Der Status des Benutzers im Projekt wurde geändert',
  'AuditLog.EventName.addOrganizationProject': 'Der Organisation wurde ein Projekt hinzugefügt',
  'AuditLog.EventName.harmonogramImported': 'Der Projektzeitplan wurde importiert',
  'AuditLog.EventName.removeOrganizationUser': 'Ein Benutzer wurde aus der Organisation entfernt',
  'AuditLog.EventName.projectUserRemoveInvited': 'Ein eingeladener Benutzer wurde aus dem Projekt entfernt',
  'AuditLog.EventName.organizationChanged': 'Die Projektorganisation wurde geändert',
  'AuditLog.EventName.directoryDownloaded': 'Der Ordner wurde heruntergeladen',
  'AuditLog.EventName.bulkUpdateAnnotationsWithAssignment': 'Massenaktualisierung von Kommentaren mit Anhang',
  'AuditLog.EventName.documentDownloadedForDiff': 'Dokument zum Vergleich heruntergeladen',
  'AuditLog.EventName.workflowStateChangedFail': 'Fehler bei der Änderung des Workflow-Status',
  'AuditLog.EventName.approvalDocumentsShared': 'Genehmigte Dokumente werden geteilt',
  'AuditLog.EventName.approvalDocumentSignRevision': 'Überarbeitung des genehmigten Dokuments unterzeichnet',
  'AuditLog.EventName.documentStateChangedFail': 'Ein Versuch, den Status des Dokuments zu ändern, ist fehlgeschlagen',
  'AuditLog.EventName.merged': 'Zusammengeführt',
  'AuditLog.EventName.addItem': 'Artikel wurde hinzugefügt',
  'AuditLog.EventName.patchItem': 'Die Kachel wurde geändert',
  'AuditLog.EventName.deleteItem': 'Der Artikel wurde gelöscht',
  'AuditLog.EventName.documentReferenceAdded': 'Dokumentreferenz hinzugefügt',
  'AuditLog.EventName.documentReferenceMatrixAdded': 'Dokumentverweise hinzugefügt',
  'AuditLog.EventName.documentReferenceRemoved': 'Dokumentverweis entfernt',
  'AuditLog.EventName.userNotified': 'Benutzer alarmiert',
  'AuditLog.EventName.settingsDeleted': 'Einstellungen gelöscht',
  'AuditLog.EventName.deleted': 'Gelöscht',
  'AuditLog.EventName.addedToRole': 'Benutzer zur Rolle hinzugefügt',
  'AuditLog.EventName.deletedFromRole': 'Benutzer aus der Rolle entfernt',
  'AuditLog.EventName.changeMdState': 'Ändern der internen Phase der Aktion',
  'AuditLog.EventName.changeMdProjectCommentingDivision': 'Wechsel der Kommentierungsgewerkschaften',
  'AuditLog.EventName.setMdProcessorDivision': 'Festlegung der Abteilung',
  'AuditLog.EventName.setMdProjectGuarantor': 'Bürgeneinstellungen',
  'AuditLog.EventName.setMdProjectRequestProcessor': 'Kommentar-Handler-Einstellungen',
  'AuditLog.EventName.setMdProjectData': 'Einstellungen der Kartenparameter',
  'AuditLog.EventName.setMdProjectDatas': 'Kartenparameter einstellen',
  'AuditLog.EventName.setMdProjecApprovalConditionAdd': 'Hinzufügen einer Genehmigungsbedingung',
  'AuditLog.EventName.setMdProjecApprovalConditionRemove': 'Aufhebung der Genehmigungsbedingung',
  'AuditLog.EventName.setMdProjecApprovalConditionChange': 'Änderung der Genehmigungsbedingung',
  'AuditLog.EventName.approvalDocumentAnnotationsRevision': 'Ein genehmigtes Dokument zu einer Workflow-Aufgabe ändern',
  'AuditLog.EventName.associateAdded': 'Proxy hinzugefügt',
  'AuditLog.EventName.associateRemoved': 'Vertreter entfernt',
  'AuditLog.EventName.userRoleAssociationAdded': 'Der Benutzer wurde zu den Vertretern für die Rolle hinzugefügt',
  'AuditLog.EventName.userRoleAssociationDeleted': 'Der Benutzer wurde aus den Vertretern der Rolle entfernt',
  'AuditLog.EventName.workflowNodeDeadlineExtendRequested': 'Es wurde eine Verlängerung der Aufgabenfrist beantragt',
  'AuditLog.EventName.workflowNodeDeadlineExtendApproval': 'Der Antrag auf Fristverlängerung für die Aufgabe wurde bearbeitet',
  'AuditLog.EventName.organizationStructureUpdated': 'Die Organisationsstruktur wurde geändert',
  'AuditLog.EventName.resultApplied': 'Das Ergebnis der Sitzung der ZK ist in den Materialien detailliert aufgeführt',
  'AuditLog.EventName.returnMdProjectToO910': 'Rücksendung des ZK-Materials an die Abteilung O910',
  'AuditLog.EventName.returnMdProjectToProposer': 'Rücksendung des Materials an den Einreicher',
  'AuditLog.ParamsTable.EventName': 'Aktionsparameter',
  'AuditLog.ParamsTable.Value': 'Wert',
  'AuditLog.ParamsTable.OriginValue': 'Ursprünglicher Wert',
  'AuditLog.ParamsTable.NewValue': 'Neuer Wert',
  'AuditLog.view': 'Alle zugehörigen Aktivitäten anzeigen',
  'BcfPanel.notSaveAlert': 'BCF-Kommentare werden nicht gespeichert. Wenn Sie fortfahren, gehen die Änderungen verloren.',
  'BcfTopics': 'Aufgaben',
  'BcfTopics.noTopics': 'Es sind noch keine Aufgaben erstellt',
  'BcfAddNewTopic': 'Fügen Sie eine neue Aufgabe hinzu',
  'BcfUploadTopicsFile': 'Importieren Sie Aufgaben aus einer BCF-Datei',
  'BcfDownloadTopicsFile': 'Exportieren Sie Aufgaben in eine BCF-Datei',
  'BcfForm.includeSnapshot': 'Modellvorschau (Screenshot)',
  'BcfForm.includeViewpoint': 'Grafische Ansichtsdaten (z. B. Kameraposition)',
  'BcfForm.viewerIntegration': 'Fügen Sie grafische Informationen hinzu',
  'BcfTopicDetail.title': 'Aufgabendetails',
  'BcfTopicDetail.focusView': 'Zentrieren Sie die Kamera',
  'BcfTopicDetail.automaticFocusView': 'Automatische Kamerazentrierung',
  'BcfTopicDetail.goToAssignment': 'Gehe zur Aufgabe',
  'BcfTopicComments': 'Kommentare',
  'BcfTopicAddComment': 'Fügen Sie einen Kommentar hinzu',
  'BcfTopicAddComment.placeholder': 'Schreiben Sie einen Kommentar zu der Notiz ...',
  'BcfTopicStatus.open': 'OFFEN',
  'BcfTopicStatus.inProgress': 'In Lösung',
  'BcfTopicStatus.reopened': 'Wiedereröffnet',
  'BcfTopicStatus.resolved': 'Eingearbeitet',
  'BcfTopicStatus.closed': 'Geschlossen',
  'BcfTopicTypes.task': 'Aufgabe',
  'BcfTopicTypes.issue': 'Problem',
  'BcfTopicTypes.request': 'Erfordernis',
  'BcfTopicTypes.clash': 'Kollision',
  'BcfImport.success': 'BCF-Datei (Version: {value}) erfolgreich importiert.',
  'BcfImport.error': 'Der Import der BCF-Datei ist fehlgeschlagen.',
  'BcfSave.error': 'Aufgabenänderungen konnten nicht auf dem Server gespeichert werden.',
  'BcfSave.success': 'Geänderte Aufgaben wurden gespeichert.',
  'BcfImportConflictModal.title': 'Konfliktlösung',
  'BcfExportModal.title': 'Notizen exportieren',
  'BcfExportModal.summary': 'Anzahl der ausgewählten Notizen: {count}',
  'BcfImportModal.title': 'Modellnotizen importieren',
  'BcfImportModal.nameTooltip': 'Importieren aktualisiert die Erinnerung',
  'BcfImportModal.conflictTooltip': 'Der importierte Artikel verfügt über keine gültigen Daten und muss im Falle eines Imports korrigiert werden',
  'BcfImportModal.resolveConflict': 'Lösen Sie den Konflikt',
  'BooleanFilter.isModel': 'Modelle',
  'BooleanFilter.isModel.description': 'Dokumente anzeigen, die Modelle sind',
  'BooleanFilter.isModel.yes': 'Ja – nur Modelle',
  'BooleanFilter.isModel.no': 'Nein – nur Nicht-Modelldokumente',
  'BooleanFilter.isNewProject': 'Neues Projekt',
  'BooleanFilter.isNewProject.description': 'Noch nicht geöffnete Projekte anzeigen',
  'BooleanFilter.isNewProject.yes': 'Ja – noch ungeöffnet',
  'BooleanFilter.isNewProject.no': 'Nein – nur Projekte, die bereits geöffnet wurden',
  'BooleanFilter.hasValue': 'Es hat Wert',
  'BooleanFilter.hasValue.description': 'Entitäten mit festgelegtem Wert anzeigen',
  'BooleanFilter.hasValue.yes': 'Ja – mit Wert',
  'BooleanFilter.hasValue.no': 'Nein – kein Wert',
  'BooleanFilter.isSigned': 'Unterzeichnet',
  'BooleanFilter.isSigned.description': 'Sehen Sie sich signierte Dokumente an',
  'BooleanFilter.isSigned.yes': 'Ja – nur signiert',
  'BooleanFilter.isSigned.no': 'Nein, nur unsigniert',
  'BooleanFilter.hasAnnotation': 'Kommentiert',
  'BooleanFilter.hasAnnotation.description': 'Kommentierte Dokumente anzeigen',
  'BooleanFilter.hasAnnotation.yes': 'Ja – nur kommentiert',
  'BooleanFilter.hasAnnotation.no': 'Nein, nur unkommentiert',
  'BudgetItemDetail.price': 'Preis',
  'BudgetItemDetail.margins': 'Marge',
  'BudgetItemDetail.title': 'Details zum Budgetposten',
  'BudgetItemDetail.menu.overview': 'Überblick',
  'BudgetItemDetail.menu.quantitySheet': 'Flächenbericht',
  'BudgetItemDetail.menu.specification': 'Spezifikationen',
  'BudgetItemDetail.menu.realization': 'Realisierung',
  'BudgetItemDetail.menu.cefActivities': 'CEF-Aktivitäten',
  'BudgetItemDetail.tab.specification.description': 'Beschreibung',
  'BudgetItemDetail.tab.specification.techSpecification': 'Technische Spezifikation',
  'BudgetItemDetail.tab.quantitySheet.title': 'Flächenbericht',
  'BudgetItemDetailRealization.column.wipReportSign': 'Marke des Erkennungsprotokolls',
  'BudgetItemDetailRealization.column.issuedDate': 'Ausgabedatum',
  'BudgetItemDetailRealization.column.originName': 'WV/ÄdB',
  'BudgetItemDetailRealization.column.drawedAmount': 'Gepumpt',
  'BudgetItemDetailRealization.column.drawedCost': 'Gezogen [{currency}]',
  'BudgetItemDetailRealization.column.totalAmount': 'Insgesamt gezogen',
  'BudgetItemDetailRealization.column.totalCost': 'Insgesamt gezogen [{currency}]',
  'BudgetItemDetailRealization.column.invoiceSign': 'Rg.-Marke',
  'BudgetItemDetailStatement.column.code': 'Code',
  'BudgetItemDetailStatement.column.description': 'Beschreibung',
  'BudgetItemDetailStatement.column.expression': 'Ausdruck',
  'BudgetItemDetailStatement.column.value': 'Wert',
  'BudgetItemDetailStatement.column.include': 'Berechnen',
  'BudgetItemDetailCefActivities.activity': 'Aktivität',
  'BudgetItemDetailCefActivities.notClassified': 'Nicht kategorisiert',
  'BudgetItemDetailCefActivities.overviewOfChangesInCefActivities': 'Überblick über Änderungen in den CEF-Aktivitäten',
  'BudgetItemDetailCefActivities.rationaleForChangeInCefActivity': 'Begründung für die Änderung der CEF-Aktivität',
  'BudgetItemDetailCefActivities.column.dateOfChange': 'Datum der Änderung',
  'BudgetItemDetailCefActivities.column.validFrom': 'Gültigkeit ab',
  'BudgetItemDetailCefActivities.column.activityBeforeChange': 'Aktivität vor der Änderung',
  'BudgetItemDetailCefActivities.column.activityAfterChange': 'Aktivität nach Veränderung',
  'BudgetItemDetailCefActivities.column.formulatedBy': 'Er hat geklappt',
  'BudgetDetail.amount': 'Menge',
  'BudgetDetail.structuralElementTitle': 'Detail des Erkennungsprotokolls',
  'BudgetDetail.designElementTitle': 'Detail des Strukturelements',
  'BudgetDetail.buildingPartTitle': 'Detail des Bauteils',
  'BudgetDetail.fixed.OC': 'Fix-Verkaufspreis',
  'BudgetDetail.JOC': 'Verkaufspreis pro Einheit',
  'BudgetDetail.margins': 'Marge',
  'BudgetDetail.measureUnit': 'Maßeinheit',
  'BudgetDetail.name': 'Name',
  'BudgetDetail.OC.total': 'Verkaufspreis Gesamt',
  'BudgetDetail.serialNumber': 'OZ',
  'BudgetDetail.costSpent': 'Gepumpt',
  'BudgetDetail.JNC': 'Einzelkosten',
  'BudgetDetail.JNCTotal': 'KP Gesamt',
  'BudgetDetail.totalMargins': 'Gesamtmarge',
  'BudgetDetail.VAT': 'MwSt.',
  'BudgetDetail.withVAT': 'mit MwSt.',
  'BudgetDetail.notConnected': 'Dem Projekt ist kein Budget zugeordnet.',
  'BudgetDetail.priceSystem': 'Preissystem',
  'BudgetDetail.otherProperties': 'Andere Eigenschaften',
  'BudgetDetail.itemIsValorized': 'Der Artikel ist wertgeschätzt',
  'BudgetGrid.noSearchDataText': 'Keine Elemente stimmen mit dem eingegebenen Text überein',
  'BudgetGrid.noDataText': 'Keine Daten',
  'BudgetGrid.preparing': 'Ich bereite mich vor...',
  'BudgetObjectDetail.title': 'Objektdetail',
  'BudgetObjectDetail.costPrices': 'Selbstkostenpreise',
  'BudgetObjectDetail.contacts': 'Kontakte',
  'BudgetObjectDetail.ncTotal': 'Gesamtpreis',
  'BudgetObjectDetail.begin': 'Beginn',
  'BudgetObjectDetail.end': 'Fertigstellung',
  'BudgetObjectDetail.administrator': 'Administrator / Investor',
  'BudgetObjectDetail.contractor': 'Anbieter',
  'BudgetObjectDetail.designer': 'Designer',
  'BudgetObjectDetail.salesPrices': 'Einzelhandelspreise',
  'BudgetProjectDetail.title': 'Projektdetails',
  'BudgetProjectDetail.realized': 'Gepumpt',
  'BudgetProjectDetail.remaining': 'Es bleibt',
  'BudgetProjectDetail.costNow': 'Aktueller Preis',
  'BudgetProjectDetail.costNowShort': 'Akt. Preis',
  'BudgetProjectDetail.costChange': 'Preisunterschied',
  'BudgetProjectDetail.costChangeShort': 'Unterschied',
  'Building.general.amount': 'Menge',
  'Building.general.aspeEsticon': 'AspeEsticon',
  'Building.general.name': 'Name',
  'Building.general.unit': 'Einheit',
  'Building.general.budgetType': 'Budgettyp',
  'Building.general.budgetType.StavebniDily': 'Nach den Bauarbeiten',
  'Building.general.budgetType.Elementy': 'Nach den Elementen',
  'Building.general.stage': 'Projektphase',
  'Building.general.stage.Zp': 'Erkennungsprotokoll',
  'Building.general.stage.Dur': 'WESENTLICH',
  'Building.general.stage.Dsp': 'DSP',
  'Building.general.stage.Dps': '(DPS) Dokumentation für den Hochbau',
  'Building.general.price': 'Preis',
  'Building.general.sign': 'Markieren',
  'Building.general.code': 'Code',
  'Building.general.document': 'Dokumentieren',
  'Building.general.existingAddress': 'Vorhandene Adresse',
  'Building.general.importedAddress': 'Importierte Adresse',
  'Building.general.establish': 'Gründen',
  'Building.general.dontEstablish': 'Nicht etablieren',
  'Building.general.number': 'Nummer',
  'Building.general.spent': 'Gepumpt',
  'Building.general.billed': 'Gepumpt',
  'Building.general.spentPercent': 'Gepumpt [%]',
  'Building.general.variant': 'Variante',
  'Building.general.valorization': 'Aufwertung',
  'Building.general.notValorized': 'Ohne Aufwertung',
  'Building.general.begin': 'Beginn',
  'Building.general.end': 'Fertigstellung',
  'Building.general.scopeOfWork.abbreviation': 'WV',
  'Building.general.changeOrder.abbreviation': 'ÄdB',
  'Building.general.sod': 'WV-Preis',
  'Building.general.zbv': 'ÄdB-Preis',
  'Building.general.totalPrice': 'Gesamtpreis',
  'Building.general.totalPriceWithVat': 'Gesamtpreis inklusive MwSt.',
  'Building.general.partner': 'Partner',
  'Building.general.phase': 'Phase',
  'Building.general.phase.Priprava': 'Vorbereitung',
  'Building.general.phase.Nabidka': 'Angebot',
  'Building.general.phase.Realizace': 'Realisierung',
  'Building.general.phase.Dokonceno': 'Vollendet',
  'Building.general.phase.Neziskano': 'Nicht erhalten',
  'Building.general.short.displayUnit': 'ME',
  'Building.general.short.orderNumber': 'OZ',
  'Building.general.short.unitPrice': 'EP',
  'Building.general.showBreakdown': 'Zeitplan ansehen',
  'Building.general.showDocument': 'Sehen Sie sich das Dokument an',
  'Building.general.returnToRework': 'Zurück zur Überarbeitung',
  'Building.general.returnToReworkAssociated': 'Kehren Sie zur Neugestaltung zurück, einschließlich der Mitglieder',
  'Building.general.displayMode.all': 'Alle anzeigen',
  'Building.general.displayMode.sent': 'Nur gesendet',
  'BuildingApprovalDateForm.documentType.ZjistovaciProtokolSdruzeny': 'Dokumenttyp: Discovery-Protokoll kombiniert',
  'BuildingApprovalDateForm.documentType.ZjistovaciProtokol': 'Dokumenttyp: Discovery-Protokoll',
  'BuildingApprovalDateForm.documentType.ZBV': 'Dokumententyp: ÄdB',
  'BuildingApprovalDateForm.title': 'Änderung des Genehmigungsdatums',
  'BuildingApprovalDateForm.formItemLabel': 'Datum der Genehmigung',
  'BuildingApprovalDateForm.submitText.enter': 'Genehmigungsdatum festlegen',
  'BuildingApprovalDateForm.submitText.remove': 'Kein Genehmigungsdatum',
  'BuildingApprovalDateForm.requiredRuleText': 'Bitte geben Sie ein Genehmigungsdatum ein',
  'Building.general.qualified': 'Berechtigt',
  'Building.general.notQualified': 'Nicht förderfähig',
  'Building.general.qualification.headerLabel': 'Teilnahmeberechtigung',
  'Building.general.sodQualification': 'Berechtigung von WV',
  'Building.general.zbvQualification': 'Berechtigung von ÄdB',
  'BuildingBudget.title': 'Budget',
  'BuildingBudget.uploadProject': 'Aufzeichnen',
  'BuildingBudget.searchPlaceholder': 'Suchen...',
  'BuildingBudget.showColumns': 'Spaltenauswahl',
  'BuildingBudget.accordingTo.KP': 'KP',
  'BuildingBudget.accordingTo.SD': 'SD',
  'BuildingBudget.accordingTo.KP.tooltip': 'Strukturelemente',
  'BuildingBudget.accordingTo.SD.tooltip': 'Konstruktionsteile',
  'BuildingBudget.column.orderNumber': 'OZ',
  'BuildingBudget.column.sign': 'Code',
  'BuildingBudget.column.variant': 'Variante',
  'BuildingBudget.column.name': 'Name',
  'BuildingBudget.column.amount': 'Gesamtmenge',
  'BuildingBudget.column.displayUnit': 'ME',
  'BuildingBudget.column.unitSellingPrice': 'Verkaufspreis pro Einheit',
  'BuildingBudget.column.unitCostPrice': 'Einzelkosten',
  'BuildingBudget.column.sellingPrice': 'Preis',
  'BuildingBudget.column.costPrice': 'Preis (KP)',
  'BuildingBudget.column.sodAmount': 'Menge an WV',
  'BuildingBudget.column.drawnAmount': 'Gepumpte Menge',
  'BuildingBudget.column.remainingAmount': 'Restmenge übrig',
  'BuildingBudget.column.sodSellingPrice': 'WV-Preis',
  'BuildingBudget.column.zbvAmount': 'Menge an ÄdB',
  'BuildingBudget.column.zbvSellingPrice': 'ÄdB-Preis',
  'BuildingBudget.column.spentSellingPrice': 'Gepumpt',
  'BuildingBudget.column.spentPercent': 'Gepumpt [%]',
  'BuildingBudget.column.remainingSellingPrice': 'Es bleibt',
  'BuildingBudget.column.originType': 'Gegründet',
  'BuildingBudget.column.priceSystem': 'Preissystem',
  'BuildingBudget.column.valorized': 'Aufgewertet',
  'BuildingBudget.columnHeaderTooltip.unitSellingPrice': 'Verkaufspreis pro Einheit [CZK]',
  'BuildingBudget.columnHeaderTooltip.unitCostPrice': 'Einzelkosten [CZK]',
  'BuildingBudget.columnHeaderTooltip.sellingPrice': 'Preis [CZK]',
  'BuildingBudget.columnHeaderTooltip.costPrice': 'Preis (KP) [CZK]',
  'BuildingBudget.columnHeaderTooltip.sodAmount': 'Menge an WV',
  'BuildingBudget.columnHeaderTooltip.sodSellingPrice': 'WV-Preis [CZK]',
  'BuildingBudget.columnHeaderTooltip.zbvAmount': 'Menge an ÄdB',
  'BuildingBudget.columnHeaderTooltip.zbvSellingPrice': 'ÄdB-Preis [CZK]',
  'BuildingBudget.columnHeaderTooltip.drawnAmount': 'Gepumpte Menge',
  'BuildingBudget.columnHeaderTooltip.remainingAmount': 'Restmenge übrig',
  'BuildingBudget.columnHeaderTooltip.cenaCelkem': 'Baupreis inklusive ÄdB',
  'BuildingBudget.columnHeaderTooltip.fakturovano': 'In Verkaufspreis in Rechnung gestellt (Erkennungsprotokolle)',
  'BuildingBudget.columnHeaderTooltip.cerpanoNakladyCelkem': 'Geplante Kosten (KP) für die hergestellte Menge',
  'BuildingBudget.columnHeaderTooltip.actualIncome': 'Ausgestellte Rechnungen (aus der Buchhaltung)',
  'BuildingBudget.columnHeaderTooltip.actualTotalCost': 'Erhaltene Rechnungen und sonstige Kosten (aus der Buchhaltung)',
  'BuildingBudget.columnHeaderTooltip.netIncomeToDate': 'Wirtschaftliches Ergebnis',
  'BuildingBudget.columnHeaderTooltip.zbyvaFakturovano': 'Verbleibend zu fakturieren (Verkaufspreis)',
  'BuildingBudget.columnHeaderTooltip.zbyvaNc': 'Kosten der verbleibenden Planmenge (KP)',
  'BuildingBudget.columnHeaderTooltip.expectedNetIncome': 'Geschätztes wirtschaftliches Ergebnis des Baus',
  'BuildingBudget.columnHeaderTooltip.coefficient': 'Koeffizient des erwarteten wirtschaftlichen Ergebnisses',
  'BuildingBudget.columnHeaderTooltip.margin': 'Marge in %',
  'BuildingBudget.columnHeaderTooltip.erpHeaderDrawing': 'Ausgeführte Arbeiten – Erstellung des Budgets',
  'BuildingBudget.columnHeaderTooltip.erpHeaderActual': 'Tatsächliche Einnahmen und Kosten im Rechnungswesen',
  'BuildingBudget.columnHeaderTooltip.erpHeaderRemaining': 'Verbleibende Arbeit – Erstellung des Budgets',
  'BuildingBudget.columnHeaderTooltip.erpHeaderexpectedResult': 'Erwartetes Ergebnis des Baus',
  'BuildingBudget.noProjectContent': 'Das Budget kann nicht angezeigt werden.',
  'BuildingBudget.transferToControlDb': 'Projektübertragung in die Steuerungsdatenbank',
  'BuildingBudget.transferToControlDb.lastSent': 'Zuletzt gesendet:',
  'BuildingBudget.transferToControlDb.transferAuthor': 'Übertragungsautor:',
  'BuildingBudget.transferToControlDb.transferNotYet': 'Die Übertragung in die Kontrolldatenbank ist noch nicht erfolgt',
  'BuildingBudget.transferToControlDb.button': 'Transfer zur KD',
  'BuildingBudget.transferToControlDb.confirm.title': 'Möchten Sie das Projekt in die Kontrolldatenbank einreichen?',
  'BuildingBudget.transferToControlDb.confirm.content': 'Daten von AspeEsticon werden an die Kontrolldatenbank übertragen, die möglicherweise nicht mit den an AspeHub gesendeten Daten übereinstimmt.',
  'BuildingBudget.transferToControlDb.confirm.okText': 'Überweisen',
  'BuildingBudget.transferToControlDb.confirm.cancelText': 'Stornierung',
  'BuildingBudgetModel.colorsTooltip.title': 'Farbzeichnungsschema',
  'BuildingBudgetModel.colorsTooltip.drawed': 'Gepumpt',
  'BuildingBudgetModel.changeModels': 'Ausgewählte Modelle ändern',
  'BuildingBudgetModel.selectModelsTitle': 'Auswahl der anzuzeigenden Modelle',
  'BuildingBudgetModel.selectModelsButton': 'Offene Modelle',
  'BuildingBudgetModel.options.showDrawingColors': 'Ein-/Ausblenden der Farbgebung von Elementen im Modell entsprechend der Zeichnung',
  'BuildingBudgetModel.options.showHighlighted': 'Hervorhebung ausgewählter Elemente im Modell ein-/ausblenden',
  'BuildingBudgetModel.options.showCheckboxes': 'Kontrollkästchen zum Isolieren von Elementen im Modell ein-/ausblenden',
  'BuildingBudgetModel.options': 'Anzeigeoptionen',
  'BuildingDocumentMenu.unsetApprovalDate': 'Entfernen Sie das Genehmigungsdatum',
  'BuildingDrawing.newZPRevision': 'Neue Überarbeitung von Erkennungsprotokoll',
  'BuildingDrawing.addZP': 'Laden Sie Erkennungsprotokoll hoch',
  'BuildingDrawing.addZP.success': 'Die Daten wurden erfolgreich auf AspeEsticon hochgeladen. Nun beginnt der Übertragungsvorgang zu AspeHub.',
  'BuildingDrawing.addZP.disabled': 'Um ein Erkennungsprotokoll hochladen zu können, muss sich das Esticon-Projekt in der Umsetzungsphase befinden.',
  'BuildingDrawing.downloadZP': 'Laden Sie Erkennungsprotokoll herunter',
  'BuildingDrawing.downloadZP.success': 'Das Erkennungsprotokoll wurde erfolgreich heruntergeladen',
  'BuildingDrawing.showBreakdown': 'Zeitplan ansehen',
  'BuildingDrawingDownloadZp.modal.title': 'Erkennungsprotokolle exportieren',
  'BuildingDrawingDownloadZp.modal.okText': 'Export',
  'BuildingDrawingDownloadZp.input.default': 'Erkennungsprotokoll exportieren',
  'BuildingDrawingDownloadZp.input.fileName': 'Der Name der Datei',
  'BuildingDrawingDownloadZp.radioChoice.all': 'Alle',
  'BuildingDrawingDownloadZp.radioChoice.actualMonth': 'Aktueller Monat',
  'BuildingDrawingDownloadZp.radioChoice.ZPinPeriod': 'Erkennungsprotokoll in der Periode',
  'BuildingDrawingDownloadZp.formatRadioChoice.exportFormat': 'Exportformat',
  'BuildingDrawingDownloadZp.checkbox.title': 'Export von gemeinsamen Erkennungsprotokollen einschließlich EP von Mitgliedern',
  'BuildingDrawingDownloadZp.valuesSelectTitle': 'Datenauswahl',
  'BuildingDrawingPartnersRegisterModal.title': 'Adressbuch in AspeEsticon',
  'BuildingDrawingPartnersRegisterModal.name': 'Name',
  'BuildingDrawingPartnersRegisterModal.CIN': 'WIN',
  'BuildingDrawingPartnersRegisterModal.street': 'Straße',
  'BuildingDrawingPartnersRegisterModal.ZIP': 'PLZ',
  'BuildingDrawingPartnersRegisterModal.city': 'Stadt',
  'BuildingDrawingCreatePartnerForm.title': 'Neuer Partner',
  'BuildingDrawingCreatePartnerForm.name': 'Name',
  'BuildingDrawingCreatePartnerForm.branchName': 'Name des Zentrums',
  'BuildingDrawingCreatePartnerForm.CIN': 'WIN',
  'BuildingDrawingCreatePartnerForm.VatDeclaration': 'Mehrwertsteuererklärung',
  'BuildingDrawingCreatePartnerForm.note': 'Notiz',
  'BuildingDrawingCreatePartnerForm.defaultAddress': 'Die Standardadresse',
  'BuildingDrawingCreatePartnerForm.branch': 'Center',
  'BuildingDrawingCreatePartnerForm.VAT': 'USt-IdNr.',
  'BuildingDrawingCreatePartnerForm.street': 'Straße',
  'BuildingDrawingCreatePartnerForm.city': 'Stadt',
  'BuildingDrawingCreatePartnerForm.ZIP': 'PLZ',
  'BuildingDrawingCreatePartnerForm.personType': 'Person',
  'BuildingDrawingCreatePartnerForm.juridicalPerson': 'Legal',
  'BuildingDrawingCreatePartnerForm.naturalPerson': 'Körperlich',
  'BuildingDrawingCreatePartnerForm.VatRegistered': 'Mehrwertsteuerzahler',
  'BuildingDrawingCreatePartnerForm.BusinessRegister': 'Eintragung ins Handelsregister',
  'BuildingDrawingCreatePartnerForm.defaultAddress.branch': 'Standardadresse - Zentrum',
  'BuildingDrawingCreatePartnerForm.defaultAddress.default': 'Standard',
  'BuildingDrawingCreatePartnerForm.sign': 'Abkürzung (Marke)',
  'BuildingDrawingCreatePartnerForm.BIN': 'ID-Nummer',
  'BuildingDrawingCreatePartnerForm.partnerAddresses': 'Partneradressen',
  'BuildingDrawingCreatePartnerForm.poBox': 'Postfach',
  'BuildingDrawingCreatePartnerForm.IBAN': 'IBAN',
  'BuildingDrawingCreatePartnerForm.bicSwift': 'BIC/SWIFT',
  'BuildingDrawingCreatePartnerForm.bankAccount': 'Bankkonto',
  'BuildingDrawingCreatePartnerForm.bankAccountNumberList': 'Code der Bankkonten',
  'BuildingDrawingCreatePartnerForm.bankAccountNumber': 'Kontonummer',
  'BuildingDrawingCreatePartnerForm.bankNumber': 'Flasche',
  'BuildingDrawingCreatePartnerForm.bankName': 'Name',
  'BuildingDrawingCreatePartnerForm.contactPerson': 'Ansprechpartner',
  'BuildingDrawingCreatePartnerForm.contactPersonNumberList': 'Telefonbuch der Ansprechpartner',
  'BuildingDrawingCreatePartnerForm.contactName': 'Name',
  'BuildingDrawingCreatePartnerForm.contactSurname': 'Nachname',
  'BuildingDrawingCreatePartnerForm.contactDegreeBefore': 'Titel vorher',
  'BuildingDrawingCreatePartnerForm.contactDegreeAfter': 'Titel nach',
  'BuildingDrawingCreatePartnerForm.contactMobile': 'Handy',
  'BuildingDrawingCreatePartnerForm.contactPhone': 'Telefon',
  'BuildingDrawingCreatePartnerForm.contactEmail': 'E-Mail',
  'BuildingRealization.addZP': 'Laden Sie Erkennungsprotokoll hoch',
  'BuildingRealization.showBreakdown': 'Zeitplan ansehen',
  'BuildingRealization.SoD': 'WV',
  'BuildingRealization.mode.all': 'Alle',
  'BuildingRealization.mode.realized': 'Nur gepumpt',
  'BuildingRealization.commonProtocolsMode.partial.label': 'Teilweise',
  'BuildingRealization.commonProtocolsMode.common.label': 'Unternehmen',
  'BuildingRealization.commonProtocolsMode.partial.tooltip': 'Teilweise Erkennungsprotokolle',
  'BuildingRealization.commonProtocolsMode.common.tooltip': 'Gemeinsame Discovery-Protokolle',
  'BuildingDrawingBreakdown.title': 'Pumpen – Zeitplan',
  'BuildingDrawingBreakdown.column.totalSpentAmount': 'Insgesamt gezogen',
  'BuildingDrawingBreakdown.column.totalSpent': 'Insgesamt gezogen',
  'BuildingDrawingBreakdown.column.totalSpentPercent': 'Insgesamt gezogen [%]',
  'BuildingDrawingBreakdown.column.remainingAmount': 'Es bleibt',
  'BuildingDrawingBreakdown.column.remainingSellingPrice': 'Es bleibt',
  'BuildingDrawingBreakdown.column.done': 'Erledigt',
  'BuildingDrawingBreakdown.column.totalDone': 'Insgesamt erledigt',
  'BuildingDrawingPage.title': 'Pumpen',
  'BuildingDrawingPage.noProjectContent': 'Pumpen kann nicht angezeigt werden.',
  'BuildingDrawingPage.columns.code': 'Code',
  'BuildingDrawingPage.columns.name': 'Name',
  'BuildingDrawingPage.columns.exposed': 'Belichtung',
  'BuildingDrawingPage.columns.approved': 'Genehmigung',
  'BuildingDrawingPage.columns.cost': 'Preis',
  'BuildingDrawingPage.columns.spentCost': 'Gepumpt',
  'BuildingDrawingPage.columns.spentPercent': 'Gepumpt [%]',
  'BuildingDrawingPage.columns.remainingCost': 'Es bleibt',
  'BuildingDrawingPage.columns.sourceDocument': 'Quelldokument',
  'BuildingDrawingPage.columns.originType': 'Mengentyp',
  'BuildingDrawingPage.columns.contractor': 'Anbieter',
  'BuildingDrawingPage.columns.investor': 'Investor',
  'BuildingDrawingPage.columns.wipType': 'Verein',
  'BuildingDrawingPage.columns.wipSpecies': 'Typ des Erkennungsprotokolls',
  'BuildingDrawingPage.columns.wipSpecies.Klasicky': 'Klassisch',
  'BuildingDrawingPage.columns.wipSpecies.Storno': 'Stornierung',
  'BuildingDrawingPage.columns.wipSpecies.Valorizacni': 'Aufwertung',
  'BuildingDrawingPage.columns.valorizationPeriod': 'Valorisierungszeitraum',
  'BuildingDrawingAnalyzeZpForm.documentId': 'Wählen Sie ein Dokument zum Hochladen aus:',
  'BuildingDrawingAnalyzeZpForm.documentId.placeholder': 'Klicken Sie hier, um ein Dokument aus DMS auszuwählen',
  'BuildingDrawingAnalyzeZpForm.file': 'Oder laden Sie ein neues Dokument hoch:',
  'BuildingDrawingAnalyzeZpForm.justZbvGroups': 'Gruppen aktualisieren (nur ÄdB-Gruppen importieren)',
  'buildingDrawingAnalyzeZpStep.chooseOneWarning': 'Wählen Sie entweder ein vorhandenes Dokument aus oder laden Sie eine neue Datei hoch.',
  'buildingDrawingAnalyzeZpStep.title': 'Dateiauswahl',
  'buildingDrawingAnalyzeZpStep.analyzeError': 'Daten konnten nicht aus der Datei geladen werden. Überprüfen Sie, ob das Erkennungsprotokoll gültig ist.',
  'buildingDrawingImportInvoiceStep.noImportableInvoice': 'Die importierte Datei enthält keine importierbare Rechnung',
  'buildingDrawingImportZpStep.importError': 'Das Erkennungsprotokoll konnte nicht importiert werden.',
  'buildingDrawingImportZpStep.title': 'Einstellungen importieren',
  'buildingDrawingImportZpStep.upload': 'Aufzeichnen',
  'buildingDrawingImportZpStep.pairingNecessaryAlert': 'Gekennzeichnete Lieferanten müssen vor dem Hochladen einem bestehenden Verbandsmitglied zugewiesen werden',
  'buildingDrawingImportZpStep.someDestinationObjectIdNotSetError': 'Vor dem Hochladen müssen alle Erkennungsprotokolle eine angegebene Verbindung zum Objekt haben. Bitte wählen Sie ein Objekt aus dem Menü aus.',
  'buildingDrawingImportZpStep.noIsSelectedZps': 'Die Datei enthält kein importierbares Erkennungsprotokoll',
  'buildingDrawingImportZpStep.noProtocols': 'Es sind keine Erkennungsprotokolle ausgewählt',
  'buildingDrawingImportZpStep.cannotImportSomeProtocols': 'Einige Erkennungsprotokolle können nicht importiert werden',
  'buildingDrawingImportZpStep.hasInvalidZpPartners': 'Erkennungsprotokolle enthalten ungültige Peer-IDs oder ungültige Adressen. Es kann nur Rg. importiert werden, ohne Erkennungsprotokoll einzubeziehen.',
  'buildingDrawingImportZpStep.hasNoAssignedObjectOnSomeInvoice': 'Bei rot markierten Rechnungen müssen neue Objekte abgeglichen werden.',
  'BuildingDrawingImportZpList.zpAssociationMembers': 'Partner im Verein',
  'BuildingDrawingImportZpList.tooltip.error': 'Das Erkennungsprotokoll konnte nicht hochgeladen werden',
  'BuildingDrawingImportZpList.tooltip.new': 'Neues Erkennungsprotokoll',
  'BuildingDrawingImportZpList.tooltip.updatable': 'Vorhandenes Erkennungsprotokoll',
  'BuildingDrawingImportZpList.tooltip.cannotCreate': 'Erkennungsprotokoll kann nicht erstellt werden',
  'BuildingDrawingImportZpList.zpList': 'Zu importierende Erkennungsprotokolle',
  'BuildingDrawingImportZpForm.delegateDph': 'Übertragen Sie die Verpflichtung zur Erfassung und Erklärung der Mehrwertsteuer durch die Einfuhr',
  'BuildingDrawingImportZpForm.includeWiPreports': 'Schließen Sie Erkennungsprotokolle ein',
  'BuildingDrawingImportZpForm.importExistingSettingsLabel': 'Wie doppelte Erkennungsprotokolle lösen',
  'BuildingDrawingImportZpForm.replaceExistingZPs': 'Löschen Sie vorhandene Zeichnungen und ersetzen Sie sie durch den Import',
  'BuildingDrawingImportZpForm.ignoreExistingZPs': 'Importieren Sie Erkennungsprotokoll nicht mit einer doppelten Nummer',
  'BuildingDrawingImportZpForm.partners': 'Partner',
  'BuildingDrawingImportZpForm.associationMembers': 'Mitglieder des Vereins',
  'BuildingDrawingImportZpForm.partnersInvalidCIN': 'Partner mit ungültiger WIN',
  'BuildingDrawingImportZpForm.selectAddress': 'Wählen Sie eine Adresse',
  'BuildingDrawingUploadZpFormWizard.title': 'Import des Erkennungsprotokoll',
  'BuildingDrawingDownloadZp.column.sign': 'Markieren',
  'BuildingDrawingDownloadZp.column.name': 'Name',
  'BuildingDrawingDownloadZp.column.exposed': 'Belichtung',
  'BuildingDrawingDownloadZp.column.realizedQuantityType': 'Art der geförderten Menge',
  'BuildingDrawingDownloadZp.column.VATTransferRegime': 'Mehrwertsteuerübertragungssystem',
  'BuildingDrawingDownloadZp.column.investor': 'Investor',
  'BuildingDrawingDownloadZp.column.supplier': 'Anbieter',
  'BuildingDrawingDownloadZp.columnChooser': 'Spaltenauswahl',
  'BuildingDrawingDownloadZp.successMessage': 'Erfolgreicher Export',
  'BuildingDrawingDownloadZp.maxLengthErrorMessage': 'Der Export ist fehlgeschlagen. Der Text einiger Elemente ist zu lang.',
  'BuildingDrawingDownloadZp.errorMessage': 'Der Export ist fehlgeschlagen',
  'BuildingSchedulePage.title': 'Zeitplan',
  'BuildingSchedulePage.noScheduleNotice': 'Zeitplan nicht verfügbar.',
  'BuildingSchedulePage.uploadHMG': 'Laden Sie HMG hoch',
  'BuildingScheduleUploadHmgFormWizard.title': 'Planen Sie den Import',
  'BuildingScheduleImportHmgForm.hmgVersionPlaceholder': 'Wählen Sie eine Version',
  'BuildingScheduleImportHmgForm.importDataType.all': 'HMG und FP',
  'BuildingScheduleImportHmgForm.importDataType.HmgOnly': 'Nur HMG',
  'BuildingScheduleImportHmgForm.importDataType.FpOnly': 'Nur FP',
  'BuildingScheduleImportHmgForm.importDataStyle.actualization': 'Aktualisieren',
  'BuildingScheduleImportHmgForm.importDataStyle.createNew': 'Neue Version',
  'BuildingScheduleImportHmgForm.importDataStyle.createNew.sign': 'Markieren',
  'BuildingScheduleImportHmgForm.importDataStyle.createNew.name': 'Name',
  'BuildingScheduleImportHmgForm.import.duplicitySign': 'Ein Zeitplan mit dem angegebenen Tag ist bereits vorhanden',
  'BuildingZbvPage.title': 'ÄdB',
  'BuildingZbvPage.noProjectContent': 'ÄdB kann nicht angezeigt werden.',
  'BuildingZbvPage.columns.sign': 'Code / Marke',
  'BuildingZbvPage.columns.name': 'Name',
  'BuildingZbvPage.columns.exposed': 'Belichtung',
  'BuildingZbvPage.columns.approved': 'Genehmigung',
  'BuildingZbvPage.columns.totalSellingPrice': 'Gesamtpreis',
  'BuildingZbvPage.columns.totalCostPrice': 'Gesamtpreis (KP)',
  'BuildingZbvPage.columns.spentPrice': 'Gepumpt',
  'BuildingZbvPage.columns.sodSellingPrice': 'WV-Preis',
  'BuildingZbvPage.columns.sodCostPrice': 'WV-Preis (KP)',
  'BuildingZbvPage.columns.zbvSellingPrice': 'ÄdB-Preis',
  'BuildingZbvPage.columns.zbvCostPrice': 'ÄdB-Preis (PK)',
  'BuildingZbvPage.columns.zbvSpentPrice': 'Gepumpt von ÄdB',
  'BuildingZbvPage.columns.type': 'Typ',
  'BuildingZbvPage.columns.contractAddendum': 'Vertragsnachtrag',
  'BuildingZbvPage.zbvType.Investorsky': 'Investor',
  'BuildingZbvPage.zbvType.Navrh': 'Vorschlag',
  'BuildingZbvPage.zbvType.Interni': 'Intern',
  'BuildingZbvPage.zbvApprove.cannotApprove': 'Sie verfügen nicht über ausreichende Berechtigungen',
  'BuildingZbvPage.zbvApprove.noDocument': 'ÄdB wird nicht an AspeHub gesendet',
  'BuildingZbvUploadInvoiceFormWizard.title': 'Import von ÄdB',
  'BuildingZbv.addZbv': 'ÄdB hochladen',
  'BuildingZbv.addZbv.success': 'Die Daten wurden erfolgreich auf AspeEsticon hochgeladen. Nun beginnt der Übertragungsvorgang zu AspeHub.',
  'BuildingZbv.addZbv.disabled': 'Um ÄdB hochladen zu können, muss sich das Esticon-Projekt in der Umsetzungsphase befinden.',
  'BuildingZbvImportGrid.exists': 'Es existiert',
  'BuildingZbvImportGrid.approved': 'Genehmigt',
  'BuildingZbvImportGrid.drawn': 'Gepumpt',
  'BuildingZbvImportGrid.sentToHub': 'Gepostet auf AspeHub',
  'BuildingZbvImportGrid.existingAddress': 'Vorhandene Adresse',
  'BuildingZbvImportGrid.importedAddress': 'Importierte Adresse',
  'BuildingZbvImportGrid.name': 'Name',
  'BuildingZbvImportGrid.partner': 'Partner',
  'BuildingZbvImportGrid.sign': 'Code',
  'BuildingZbvImportGrid.systemPairing': 'Gepaart',
  'BuildingZbvImportZbv.existingZbv': 'ÄdB mit der gleichen Nummer existiert bereits',
  'BuildingZbvImportZbv.criticalValorizationDifference': 'Aufgrund der unterschiedlichen Valorisierung des Projekts und der importierten ÄdB kann der Import nicht fortgesetzt werden.',
  'BuildingZbvImportZbv.noZbvsInOnlyZbvGroupImport': 'Die Datei enthält keine ÄdB-Gruppen.',
  'BuildingZbvImportZbv.ZbvGroups': 'ÄdB-Gruppen',
  'BuildingZbvSkupinyImportPairing.sourceZbvGroup': 'ÄdB-Gruppe im Import',
  'BuildingZbvSkupinyImportPairing.destinationZbvGroup': 'Bestehende ÄdB-Gruppe',
  'BuildingZbvImportZbv.noAddPartnerPermissionAskAdmin': 'Um einen neuen Partner oder dessen Adresse festzulegen, wenden Sie sich an den Projektmanager.',
  'BuildingZbvImportZbv.protocolWithoutContractor': 'Der Lieferant fehlt im importierten Erkennungsprotokoll.',
  'BuildingZbvImportZbvForm.notifyReplace': 'Beim Import handelt es sich um ÄdBs, die bereits im Projekt vorhanden sind. Beim Fortfahren werden die Daten aus der importierten Datei überschrieben.',
  'BuildingZbvImportZbvList.zbvList': 'Liste der ÄdB zum Import',
  'BuildingZbvImportZbvList.zbvPairing': 'Passende Daten',
  'BuildingZbvBreakdown.title': 'Bettwäsche wechseln',
  'BuildingZbvBreakdown.columns.zbvAmount': 'Menge an ÄdB',
  'BuildingZbvBreakdown.columns.zbvSellingPrice': 'ÄdB-Preis',
  'BuildingZbvBreakdown.columns.zbvCostPrice': 'ÄdB-Preis (KP)',
  'BuildingZbvBreakdown.columns.sodAmount': 'Menge an WV',
  'BuildingZbvBreakdown.columns.sodSellingPrice': 'WV-Preis',
  'BuildingZbvBreakdown.columns.sodCostPrice': 'WV-Preis (KP)',
  'BuildingZbvBreakdown.columns.totalAmount': 'Gesamtmenge',
  'BuildingZbvBreakdown.columns.totalSellingPrice': 'Gesamtpreis',
  'BuildingZbvBreakdown.columns.totalCostPrice': 'Gesamtpreis (KP)',
  'BuildingZbvBreakdown.columns.groups': 'Gruppen',
  'BuildingZbvBreakdown.columns.category': 'Kategorie',
  'BuildingZbvBreakdownHighlight.groupsBreakdown': 'Zeitplan der Gruppen',
  'BuildingZbvBreakdownHighlightToggles.groups.hide': 'Gruppenaufteilung ausblenden',
  'BuildingZbvBreakdownHighlightToggles.groups.show': 'Gruppenaufteilung anzeigen',
  'BuildingInvoicingPage.title': 'Abrechnung',
  'BuildingInvoicePage.columns.sign': 'Code',
  'BuildingInvoicePage.columns.order': 'Befehl',
  'BuildingInvoicePage.columns.timePeriod': 'Zeitraum',
  'BuildingInvoicePage.columns.priceWithoutVat': 'Preis ohne Mehrwertsteuer',
  'BuildingInvoicePage.columns.vatPriceBasic': 'Grundsatz der Mehrwertsteuer',
  'BuildingInvoicePage.columns.vatPriceReduced1': 'Erster ermäßigter Mehrwertsteuersatz',
  'BuildingInvoicePage.columns.vatPriceReduced2': 'Zweiter ermäßigter Mehrwertsteuersatz',
  'BuildingInvoicePage.columns.totalPrice': 'Gesamtpreis',
  'BuildingInvoicePage.columns.administrator': 'Administrator / Investor',
  'BuildingInvoicePage.columns.contractor': 'Anbieter',
  'BuildingInvoicePage.columns.importContractor': 'Importlieferant',
  'BuildingInvoicePage.columns.buildingContractor': 'Bauunternehmer',
  'BuildingInvoicePage.columns.VATTransferRegime': 'Mehrwertsteuerübertragungssystem',
  'BuildingInvoicePage.VATTransferRegime.NeuplatnujeSe': 'Nicht anwendbar',
  'BuildingInvoicePage.VATTransferRegime.ZaplatiPrijemce': 'Zahlungsempfänger zahlt',
  'BuildingInvoicePage.VATTransferRegime.PrenesenoOdPoskytovatele': 'Vom Anbieter übertragen',
  'BuildingInvoicePage.displayModes.partial.label': 'Teilweise',
  'BuildingInvoicePage.displayModes.partial.tooltip': 'Teilrechnungen',
  'BuildingInvoicePage.displayModes.common.label': 'Unternehmen',
  'BuildingInvoicePage.displayModes.common.tooltip': 'Gemeinsame Rechnungen',
  'BuildingInvoicingUploadInvoiceFormWizard.title': 'Rechnungsimport',
  'BuildingInvoicing.addInvoice': 'Laden Sie eine Rechnung hoch',
  'BuildingInvoicing.addInvoice.success': 'Die Daten wurden erfolgreich auf AspeEsticon hochgeladen. Nun beginnt der Übertragungsvorgang zu AspeHub.',
  'BuildingInvoicing.addInvoice.disabled': 'Um eine Rechnung hochladen zu können, muss sich das Esticon-Projekt in der Umsetzungsphase befinden.',
  'BuildingInvoicingImportInvoice.newInvoice': 'Neue Rechnung',
  'BuildingInvoicingImportInvoice.existingInvoice': 'Es existiert bereits eine Rechnung mit derselben Nummer',
  'BuildingInvoicingImportInvoiceForm.delegateDph': 'Übertragen Sie die Verpflichtung zur Erfassung und Erklärung der Mehrwertsteuer durch die Einfuhr',
  'BuildingInvoicingImportInvoiceForm.replaceExistingInvoices': 'Löschen Sie vorhandene Rechnungen und ersetzen Sie sie durch den Import',
  'BuildingInvoicingImportInvoiceList.invoiceList': 'Liste der Rechnungen für den Import',
  'BuildingInvoicingPairingGrid.associationMembers': 'Mitglieder des Vereins',
  'BuildingInvoicingPairingGrid.newObjects': 'Ein neues Objekt einrichten',
  'BuildingInvoicingPairingGrid.CIN': 'WIN',
  'BuildingInvoicingPairingGrid.name': 'Name',
  'BuildingInvoicingPairingGrid.sign': 'Markieren',
  'BuildingInvoicingPairingGrid.street': 'Straße',
  'BuildingInvoicingPairingGrid.city': 'Stadt',
  'BuildingInvoicingPairingGrid.ZIP': 'PLZ',
  'BuildingInvoicingInvoiceItemsList.title': 'Rechnungspositionen',
  'BuildingInvoicingInvoiceItemsList.object': 'Objekt',
  'BuildingInvoicingInvoiceItemsList.objectNew': 'Neues Objekt',
  'BuildingInvoicingInvoiceItemsList.drawn': 'Gepumpt',
  'BuildingInvoicingInvoiceItemsList.valorizationPercent': 'Valorisierung %',
  'BuildingInvoicingInvoiceItemsList.valorizationValue': 'Aufwertung',
  'BuildingInvoicingInvoiceItemsList.discountPercent': 'Rabatt %',
  'BuildingInvoicingInvoiceItemsList.discountValue': 'Rabatt',
  'BulkEditActionResultModal.content.updatedDocuments': 'Anzahl der geänderten Dokumente',
  'BulkEditActionResultModal.content.updatedLinks': 'Anzahl der geänderten Dokumentverknüpfungen',
  'BulkEditActionResultModal.content.unchangedDocuments': 'Anzahl der Dokumente unverändert',
  'BulkEditActionResultModal.content.unchangedLinks': 'Anzahl der Dokumentverknüpfungen unverändert',
  'BulkEditActionResultModal.moveTo': 'Gehen Sie zum Nachrichtencenter',
  'BulkEditDocumentsLabelsForm.documentsCount': 'Anzahl der ausgewählten Dateien',
  'BulkEditDocumentsLabelsForm.title': 'Dokumentbezeichnungen ändern',
  'BulkEditDocumentsLabelsForm.labels': 'Etiketten',
  'BulkEditDocumentsLabelsForm.labels.required': 'Bitte wählen Sie mindestens ein Label aus',
  'BulkEditDocumentsLabelsForm.mode': 'Methode zur Etikettenänderung',
  'BulkEditDocumentsLabelsForm.mode.required': 'Bitte wählen Sie eine Methode zum Ändern von Beschriftungen aus',
  'BulkEditDocumentsLabelsForm.mode.add': 'Fügen Sie Etiketten hinzu',
  'BulkEditDocumentsLabelsForm.mode.replace': 'Ersetzen Sie die Etiketten',
  'BulkEditDocumentsLabelsForm.mode.remove': 'Etiketten entfernen',
  'BulkEditDocumentsLabelsForm.impossibleChanges': 'Für die folgenden Dokumente und Dokumentverknüpfungen können Beschriftungen nicht geändert werden',
  'BulkEditDocumentsLabelsForm.resultModal.title': 'Das Ergebnis der Aktion – ein Massenwechsel der Etiketten',
  'BulkEditDocumentsStateForm.title': 'Dokumentstatus ändern',
  'BulkEditDocumentsStateForm.documentsCount': 'Anzahl ausgewählter Dokumente: {count}',
  'BulkEditDocumentsStateForm.executeChanges': 'Nehmen Sie Änderungen vor',
  'BulkEditDocumentsStateForm.executeChangesToOthers': 'Nehmen Sie Änderungen an anderen vor',
  'BulkEditDocumentsStateForm.newDocumentsState': 'Neuer Dokumentstatus',
  'BulkEditDocumentsStateForm.newDocumentsState.required': 'Bitte legen Sie den Dokumentstatus fest',
  'BulkEditDocumentsStateForm.newDocumentsState.impossibleChanges': 'Die folgenden Dokumente und Dokumentverknüpfungen können nicht in den gewünschten Zustand gebracht werden',
  'BulkEditDocumentsStateForm.newDocumentsState.noAvailableStates': 'Für die ausgewählten Dokumente und Links gibt es keinen gemeinsamen Status',
  'BulkEditDocumentsStateForm.successModal.title': 'Das Ergebnis der Aktion ist eine Massenveränderung der Zustände',
  'BulkEditDocumentsWatchForm.impossibleChanges': 'Die folgenden Dokumente und Dokumentverknüpfungen können nicht nachverfolgt werden',
  'BulkEditDocumentsWatchForm.info': 'Die neue Einstellung überschreibt die vorhandenen Tracking-Einstellungen.',
  'BulkEditDocumentsWatchForm.resultModal.title': 'Aktionsergebnis – Massenverfolgungseinstellungen',
  'BulkEditDocumentsWatchForm.title': 'Dokumentverfolgung ändern',
  'BulkEditDocumentsWatchForm.watches': 'Verfolgung',
  'BulkRemoveFromModelsModal.button.Remove': 'Entfernen',
  'BulkRemoveFromModelsModal.button.RemoveAndDeleteLinks': 'Modelle entfernen und Bindungen löschen',
  'BulkRemoveFromModelsModal.title': 'Entfernen Sie Dokumente von Modellen',
  'BulkRemoveFromModelsModal.RemoveModels': `{count, plural,
    =0 {Möchten Sie wirklich 0 ausgewählte Dokumente aus Modellen entfernen?}
    one {Möchten Sie wirklich 1 ausgewähltes Dokument aus Modellen entfernen?}
    few {Möchten Sie wirklich entfernen # ausgewählte Dokumente aus Modellen?}
    other {Sind Sie sicher, dass Sie # ausgewählte Dokumente aus Modellen entfernen möchten?}
    }`,
  'BulkRemoveFromModelsModal.errorsTitle.missed': `{count, plural,
    =0 {Alles OK}
    one {Bei der Vorbereitung zum Entfernen des Modells ist ein Fehler aufgetreten. Es wurde kein Modell entfernt.}
    few {Beim Vorbereiten der Außerbetriebnahme von Modellen sind Fehler aufgetreten. Es wurde kein Modell entfernt.}
    other {Beim Vorbereiten zum Verwerfen von Modellen sind Fehler aufgetreten. Es wurde kein Modell entfernt.}
    }`,
  'BulkAddToModelsModal.button.Add': 'Hinzufügen',
  'BulkAddToModelsModal.button.AddOthers': 'Weitere hinzufügen',
  'BulkAddToModelsModal.title': 'Fügen Sie Dokumente zu Modellen hinzu',
  'BulkAddToModelsModal.AddModels': `{count, plural,
    =0 {Möchten Sie wirklich 0 ausgewählte zu Modellen hinzufügen?}
    one {Möchten Sie wirklich 1 Ausgewählte zu Modellen hinzufügen?}
    few {Möchten Sie wirklich # Ausgewählte hinzufügen? zu Modellen?}
    other {Möchten Sie # ausgewählt wirklich zu Modellen hinzufügen?}
    }`,
  'BulkAddToModelsModal.errorsTitle.missed': `{count, plural,
    =0 {Alles OK}
    one {Fehler beim Hinzufügen des Modells. Kein Modell hinzugefügt.}
    few {Beim Hinzufügen von Modellen sind Fehler aufgetreten. Kein Modell hinzugefügt.}
    other {Beim Hinzufügen von Modellen sind Fehler aufgetreten. Kein Modell hinzugefügt.}
    }`,
  'CalendarDaySelect.workingDays': 'Arbeitstage',
  'CalendarDaySelect.calendarDays': 'Kalendertage',
  'CalendarExcludedDayImportForm.modal.title': 'Import tschechischer Feiertage in den Kalender',
  'CalendarExcludedDayImportForm.modal.importError': 'Beim Importieren von Feiertagen ist ein Fehler aufgetreten. Der Import ist fehlgeschlagen.',
  'CalendarExcludedDayImportForm.modal.importWarning': 'Der ausgewählte Zeitraum umfasst keine tschechischen Feiertage.',
  'CalendarExcludedDayImportForm.label.endDate': 'Aktuelle Feiertage laden',
  'CalendarExcludedDayForm.title.add': 'Feiertag hinzufügen',
  'CalendarExcludedDayForm.title.edit': 'Feiertag bearbeiten',
  'CalendarExcludedDayForm.repeating.label': 'Wiederholen Sie den Feiertag jedes Jahr am selben Tag',
  'CalendarSettingsDeleteButton.confirmDelete.message': 'Nach dem Löschen des Projektkalenders werden die Organisationseinstellungen verwendet. Projektkalender wirklich löschen?',
  'CalendarSettingsExcludedDaysItem.onlyOnce': 'Nur einmal',
  'CalendarSettingsExcludedDaysItem.repeating': 'Es wiederholt sich',
  'CalendarSettingsExcludedDaysItem.import': 'Import tschechischer Feiertage',
  'CalendarSettingsForm.workingDays': 'Arbeitstage',
  'CalendarSettingsForm.excludedDays': 'Feiertage und freie Tage',
  'CalendarSettingsForm.filterPast.tooltip': 'Nur zukünftige Feiertage anzeigen',
  'CalendarSettingsFormModal.title.organization': 'Einstellungen für den Organisationskalender',
  'CalendarSettingsFormModal.title.project': 'Festlegen des Projektkalenders',
  'CalendarSettingsFormModal.title.template': 'Vorlagenkalendereinstellungen',
  'CategoryList.deleteConfirmModal.description': 'Die Kategorie wirklich löschen und aus allen Ordnern und Dokumenten entfernen?',
  'CategoryList.deleteConfirmModal.delete': 'Löschen',
  'CategoryFilterItem.category.description': 'Kategorie',
  'CategoryFilterItem.category.placeholder': 'Kategoriename...',
  'CategoryFilterItem.category.allUsed': 'Alle Kategorien hinzugefügt',
  'CategoryFilterItem.node.desc': 'Knoten',
  'CategoryFilterItem.node.placeholder': 'Knotenname...',
  'CertificateSignature.title': 'Unterschreiben Sie das Dokument',
  'CertificateSignature.signButtonWithBankID': 'Unterschreiben Sie mit BankID',
  'CertificateSignature.signButtonCertificate': 'Mit Zertifikat unterschreiben',
  'CertificateSignature.derivateWarning': 'Sie sind dabei, das generierte PDF-Dokument anhand des Originals zu signieren. Möglicherweise hat sich die Formatierung während der Konvertierung geändert. Überprüfen Sie das Dokument, bevor Sie es unterschreiben.',
  'CertificateSignature.secondaryFileNotSignable': 'Das Dokument enthält einen Anhang mit einem signierten Dokument in einem Format, das online nicht signiert werden kann.',
  'CertificateSignature.form.signingError': 'Beim Signieren des Dokuments ist ein Fehler aufgetreten.',
  'CertificateSignature.error.invalidContentType.text': 'Dokument {document} ist kein unterstützter Signaturtyp.',
  'CertificateSignature.reason.key': 'AspeHub-Signatur',
  'CertificateSignature.reason.value': 'Vom AspeHub-Benutzer genehmigtes Dokument',
  'CertificateSignature.error.storage': 'Beim Senden der Dokumentsignaturanforderung ist ein Fehler aufgetreten. {error}',
  'CertificateSignature.error.redirectEmpty': 'Beim Signieren des Dokuments ist ein Fehler aufgetreten. Die Adresse für die Anmeldung bei BankID konnte nicht abgerufen werden.',
  'CertificateSignature.verifyModal.title': 'Bearbeitung des signierten Dokuments',
  'CertificateSignature.verifyModal.status.downloading': 'Herunterladen des signierten Dokuments',
  'CertificateSignature.verifyModal.status.finished': 'Dokument erfolgreich signiert',
  'CertificateSignature.verifyModal.status.error': 'Fehler beim Speichern',
  'CertificateSignature.verifyModal.button.hide': 'Verstecken',
  'CertificateSignature.error.GetSignInfoForUploadError': 'Fehler beim Laden der Dokumentinformationen zum Signieren',
  'CertificateSignature.error.DownloadSignedDocumentFromStorageError': 'Fehler beim Herunterladen des Dokuments zum Signieren',
  'CertificateSignature.error.ReadingSignedMetadataError': 'Fehler beim Lesen der Informationen aus dem Dokument zur Signatur',
  'CertificateSignature.error.InitialDocumentRequestError': 'Fehler beim Einrichten der Signaturanforderung in BankID',
  'CertificateSignature.error.RosUrlsError': 'Fehler beim Abrufen der Adresse zum Hochladen des Dokuments zur Unterschrift',
  'CertificateSignature.error.SigningRequestExpiredError': 'Die Anforderung zur Dokumentsignatur ist abgelaufen',
  'CertificateSignature.error.InitialBankIdRequestError': 'Die Anfrage zum Signieren des Dokuments endete mit einem Fehler',
  'CertificateSignature.error.FileUploadBankIDError': 'Fehler beim Hochladen des Dokuments zur Signatur auf BankID',
  'CertificateSignature.error.GetSignInfoForDownloadError': 'Fehler beim Laden der Daten zum Herunterladen des signierten Dokuments',
  'CertificateSignature.error.DownloadSignedDocError': 'Fehler beim Herunterladen des signierten Dokuments von BankID',
  'CertificateSignature.error.UploadSignedDocError': 'Fehler beim Hochladen des signierten Dokuments auf AspeHub',
  'CertificateSignature.error.CreateSignedDocError': 'Fehler beim Festlegen der hochgeladenen Datei als signiertes Dokument',
  'CertificateSignature.error.ClientNotEligible': 'Der Bankidentitätsnutzer ist nicht berechtigt, Dokumente zu unterzeichnen',
  'CertificateSignature.error.AccessDenied': 'Der Benutzer wurde nicht mit einer Bankidentität verifiziert',
  'CertificateSignature.error.DocumentMissingCreateDate': 'Das Dokument enthält nicht das obligatorische Erstellungsdatum',
  'CertificateSignature.error.UnknownError': 'Bei der Verifizierung des Benutzers über die Bankidentität ist ein Fehler aufgetreten',
  'ColorPickerInput.refresh': 'Generieren Sie Farbe automatisch',
  'DiscussionNote.closeEditing': 'Bearbeitung rückgängig machen',
  'DiscussionNote.edit': 'Bearbeiten',
  'DiscussionNote.delete': 'Entfernen',
  'DiscussionNote.addAttachments': 'Hängen Sie einen Anhang an',
  'DiscussionNote.deletedNote': 'Dieser Kommentar wurde entfernt.',
  'DiscussionNote.restore': 'Zurück',
  'DiscussionNote.linkAttachments.title': 'Anhänge anhängen',
  'DiscussionNote.linkAttachments.link': 'Verbinden',
  'DiscussionNote.Notification.noticeWasSentTo': 'Beachten:',
  'DiscussionNote.Notification.sendNoticeTo': 'Warnungen:',
  'DiscussionNote.Notification.tooltipToAddUserButton': 'Wählen Sie den Benutzer aus, an den die Benachrichtigung gesendet werden soll',
  'DiscussionNote.Notification.teamDropdownButton': 'Markieren Sie den Benutzer aus dem Team',
  'DiscussionNote.Notification.roleDropdownButton': 'Markieren Sie den Benutzer aus der Rolle',
  'DiscussionNote.Notification.modalTitleUserSelecting': 'Auswählen von Benutzern, an die Benachrichtigungen gesendet werden sollen',
  'DiscussionNote.Notification.mainProcessor': 'Hauptprozessoren',
  'DiscussionNote.Notification.mainSubmitter': 'Chefkommentator',
  'CommentProcedure.general.processorTeams': 'Bearbeitungsteams',
  'CommentProcedure.general.submitterTeams': 'Kommentarteams',
  'CommentProcedure.general.category': 'Kommentarkategorie',
  'CommentProcedureAddCommentForm.annotationMessageSegment': 'Seite: {page}\nErinnerung: {content}',
  'CommentProcedureAddCommentForm.title': 'Der Name der Erinnerung',
  'CommentProcedureAddCommentForm.title.required': 'Bitte geben Sie den Namen des Kommentars ein',
  'CommentProcedureAddCommentForm.message': 'Erinnerungstext',
  'CommentProcedureAddCommentForm.message.required': 'Bitte füllen Sie den Kommentartext aus',
  'CommentProcedureAddCommentForm.commentProcedureCommentDocuments': 'Referenzierte Dokumente',
  'CommentProcedureAddCommentForm.notSet': 'Nicht festgelegt',
  'CommentProcedureAddCommentForm.commentProcedure': 'Zum Mahnverfahren',
  'CommentProcedureAddCommentFormModal.title': 'Fügen Sie eine neue Erinnerung hinzu',
  'CommentProcedureAddForm.commentedDocuments': 'Dokumente zur Kommentierung',
  'CommentProcedureAddForm.commentedDocuments.required': 'Bitte wählen Sie die Dokumente aus, die Sie kommentieren möchten.',
  'CommentProcedureAddForm.commentedDocuments.modalTitle': 'Wählen Sie Dokumente zum Kommentieren aus',
  'CommentProcedureAddForm.name': 'Titel des Verfahrens',
  'CommentProcedureAddForm.name.required': 'Bitte geben Sie den Namen des Verfahrens ein.',
  'CommentProcedureAddForm.description': 'Managementbeschreibung',
  'CommentProcedureAddForm.headOfSubmitters': 'Chefkommentator',
  'CommentProcedureAddForm.headOfProcessors': 'Hauptprozessoren',
  'CommentProcedureAddForm.role.required': 'Bitte wählen Sie eine Rolle aus.',
  'CommentProcedureAddFormModal.title': 'Erstellen Sie ein neues Mahnverfahren',
  'CommentProcedureCommentsAddAttachment.addAttachment': 'Fügen Sie einen Anhang hinzu',
  'AttachmentHeader.addDocument': 'Wählen Sie aus den verfügbaren',
  'AttachmentHeader.addNewFile': 'Laden Sie eine neue Datei hoch',
  'CommentProcedureCommentsAddAttachment.discadToggleTooltip': 'Ansicht im Ruhestand',
  'AttachmentList.discardAttachment.tooltip': 'Anhang verwerfen',
  'AttachmentList.restoreAttachment.tooltip': 'Anhang wiederherstellen',
  'AttachmentList.insufficientPermissionTooltip': 'Unzureichende Autorisierung',
  'AttachmentList.discardAttachment.confirm': 'Sind Sie sicher, dass Sie diesen Anhang löschen möchten?',
  'AttachmentList.restoreAttachment.confirm': 'Sind Sie sicher, dass Sie diesen Anhang wiederherstellen möchten?',
  'CommentProcedureDeadline.for.Commenting': 'Frist für die Eingabe von Kommentaren',
  'CommentProcedureDeadline.for.InternalNegotiations': 'Frist für interne Diskussion',
  'CommentProcedureDeadline.for.Negotiations': 'Frist für die Berücksichtigung von Kommentaren',
  'CommentProcedureDeadline.for.Incorporation': 'Frist für die Einbindung von Kommentaren',
  'CommentProcedureDeadline.for.Approval': 'Frist für die Genehmigung von Kommentaren',
  'CommentProcedureDeadline.for.Approved': 'Frist für die Genehmigung',
  'CommentProcedureDeadline.for.Closed': 'Kündigungsfrist',
  'CommentProcedureDeadlineForm.reason.required': 'Bitte geben Sie den Grund für die Änderung ein',
  'CommentProceduresPageToolbar.addCommentProcedureSuccess': 'Ein neues Kommentierungsverfahren wurde erfolgreich eingerichtet.',
  'CommentProceduresPageToolbar.export': 'Export von Mahnvorgängen',
  'CommentProceduresPageToolbar.newComment': 'Neues Mahnverfahren',
  'CommentProcedureDetailPage.newComment': 'Neue Erinnerung',
  'CommentProcedureAddDocumentButton.addDocuments': 'Dokumente hinzufügen',
  'CommentProcedureAddDocumentButton.commentDocument.title': 'Dokument zur Erinnerung hinzufügen',
  'CommentProcedureAddDocumentButton.selectDocument': 'Wählen Sie aus den verfügbaren',
  'CommentProcedureAddDocumentButton.selectDocument.title': 'Fügen Sie Dokumente zum Kommentieren hinzu',
  'CommentProcedureAddDocumentButton.uploadDocument': 'Laden Sie ein neues Dokument hoch',
  'CommentProcedureCommentDocuments.removeDocument.tooltip': 'Entfernen Sie das Dokument',
  'CommentProcedureCommentDocuments.removeDocument.confirm': 'Möchten Sie das Dokument wirklich aus der Erinnerung entfernen?',
  'CommentProcedureCommentDocuments.downloadWithAnnotationDocument.error.notFound': 'Dokument zum Herunterladen nicht gefunden',
  'CommentProcedureCommentDiscussion.tabs.common': 'Gemeinsam',
  'CommentProcedureCommentDiscussion.tabs.internalSubmitter': 'Interner Kommentator',
  'CommentProcedureCommentDiscussion.tabs.internalProcessor': 'Interne Prozessoren',
  'CommentProcedureDetailPage.commentName': 'Der Name der Erinnerung',
  'CommentProcedureDetailPage.export': 'Bestellung exportieren',
  'CommentProcedureDetailPage.changeStateMultiple': 'Ändern Sie den Status von Kommentaren',
  'CommentProcedureDetailPage.multipleSelectTitle': 'Massenauswahl von Kommentaren',
  'CommentProcedureDetailPage.filterUserTitle': 'Kommentarautor',
  'CommentProcedureDetailPage.closeProcedure': 'BVP beenden',
  'CommentProcedureDetailPage.closeProcedure.confirm': 'Sind Sie sicher, dass Sie diesen Kommentarvorgang beenden möchten?',
  'CommentProcedureDetailPage.tabs.comments': 'Erinnerungen',
  'CommentProcedureDetailPage.tabs.documents': 'Dokumente ({count})',
  'CommentProcedureDetailPage.tabs.users': 'Benutzer',
  'CommentProcedureDocument.revisionDateLabel': 'Überarbeitung in BVP: {date}',
  'CommentProcedureDocumentDownloadModal.comentStateFilter.text': 'Kommentarstatus mit Download-Kommentaren',
  'CommentProcedureDocumentDownloadModal.finishInBackground.text': 'Schließen (im Hintergrund beenden)',
  'CommentProcedureDocumentTab.annotate.tooltip': 'Kommentar',
  'CommentProcedureDocumentTab.Edit.tooltip': 'Bearbeiten',
  'CommentProcedureDocumentTab.compareRevisions.tooltip': 'Revisionen vergleichen',
  'CommentProcedureDocumentTab.downloadWithAnnotations.tooltip': 'Mit Kommentaren herunterladen',
  'CommentProcedureDocumentTab.remove.confirm': 'Möchten Sie dieses Dokument wirklich aus dem Kommentarprozess entfernen?',
  'CommentProcedureDocumentTab.remove.tooltip': 'Dokument aus BVP entfernen',
  'CommentProcedureDocumentTab.filter.inComment.label': 'Erinnert',
  'CommentProcedureDocumentTab.filter.inComment.tooltip': 'Zeigen Sie Dokumente an, die Teil einiger Kommentare sind.',
  'CommentProcedureDocumentTab.filter.inComment.yes': 'Ja – nur kommentierte Dokumente',
  'CommentProcedureDocumentTab.filter.inComment.no': 'Nein – nur nicht erwähnte Dokumente',
  'CommentProcedureDocumentsTab.revisionsModalTitle': 'Überarbeitung des Dokuments zu diesem Stellungnahmeverfahren',
  'CommentProcedureErrors.noErrors': 'Das Mahnverfahren enthält keine Fehler',
  'CommentProcedureErrors.severity': 'Die Schwere des Fehlers',
  'CommentProcedureErrors.subject': 'Fehlertyp',
  'CommentProcedureErrors.roleOrUserId': 'Betroffene Rolle/Benutzer',
  'CommentProcedureErrors.subjectName': 'Der Name des Objekts',
  'CommentProcedureErrors.commentProcedureRole': 'Teamtyp',
  'CommentProceduresListItem.createdDate': 'Erstellt: {date}',
  'CommentProceduresListItem.createdBy': 'Erstellt vom Benutzer',
  'CommentProceduresListItem.hasWorkflow': 'Angehängte Workflow-Instanz',
  'CommentProcedurePhaseEnum.Commenting': 'Erinnerung',
  'CommentProcedurePhaseEnum.InternalNegotiations': 'Interne Diskussion',
  'CommentProcedurePhaseEnum.Negotiations': 'Diskussion',
  'CommentProcedurePhaseEnum.Incorporation': 'Eingliederung',
  'CommentProcedurePhaseEnum.Approval': 'Genehmigung',
  'CommentProcedurePhaseEnum.Approved': 'Genehmigt',
  'CommentProcedurePhaseEnum.Closed': 'Fertig',
  'CommentProcedureSetState.documentsWarning': 'Einige Dokumente haben den Status „Archiviert“ oder „Abgelehnt“ und können daher nicht genehmigt werden. Möchten Sie alle anderen Dokumente genehmigen?',
  'CommentProcedureSetState.approveDocuments': 'Genehmigen Sie Dokumente im Rahmen des Kommentierungsverfahrens',
  'CommentProcedureSetState.dontApproveDocuments': 'Geben Sie keine Dokumente im Rahmen des Kommentierungsverfahrens frei',
  'CommentProcedureSetState.target.internalNegotiations.button': 'Zur internen Diskussion einreichen',
  'CommentProcedureSetState.target.internalNegotiations.confirm': 'Möchten Sie das Stellungnahmeverfahren wirklich zur internen Prüfung weiterleiten?',
  'CommentProcedureSetState.target.negotiations.button': 'Senden Sie Reaktionen',
  'CommentProcedureSetState.target.negotiations.confirm': 'Sind Sie sicher, dass Sie die Kommentarantworten auf der Seite der Kommentatoren sehen möchten?',
  'CommentProcedureSetState.target.incorporation.button': 'Zur Bearbeitung einreichen',
  'CommentProcedureSetState.target.incorporation.confirm': 'Möchten Sie wirklich ein Stellungnahmeverfahren zur Gründung einreichen?',
  'CommentProcedureSetState.target.approval.button': 'Zur Genehmigung einreichen',
  'CommentProcedureSetState.target.approval.confirm': 'Sind Sie sicher, dass Sie das Kommentarverfahren zur Genehmigung einreichen möchten?',
  'CommentProcedureSetState.target.approved.button': 'Genehmigen',
  'CommentProcedureSetState.target.approved.confirm': 'Möchten Sie dem Kommentarverfahren wirklich zustimmen? Mit der Bestätigung wird das gesamte Kommentarverfahren genehmigt.',
  'CommentProcedureCommentsAttachments.button.publish.tooltip': 'Post',
  'CommentProcedureCommentsAttachments.relationIcon.both.tooltip': 'Öffentlich',
  'CommentProcedureCommentsAttachments.relationIcon.processor.tooltip': 'Intern im Prozessor',
  'CommentProcedureCommentsAttachments.relationIcon.submitter.tooltip': 'Intern für Kommentatoren',
  'CommentProcedureCommentDiscussionRelationEnum.None': 'NEIN',
  'CommentProcedureCommentDiscussionRelationEnum.Processor': 'Prozessoren',
  'CommentProcedureCommentDiscussionRelationEnum.Submitter': 'Kommentator',
  'CommentProcedureCommentDiscussionRelationEnum.Both': 'Öffentlich',
  'CommentProcedureCommentStateEnum.Commenting': 'Eingetragen',
  'CommentProcedureCommentStateEnum.Discarded': 'Weggeworfen',
  'CommentProcedureCommentStateEnum.InternallyDiscarded': 'Intern im Ruhestand',
  'CommentProcedureCommentStateEnum.ForDiscussion': 'Zur Diskussion',
  'CommentProcedureCommentStateEnum.ForSolution': 'Zu lösen',
  'CommentProcedureCommentStateEnum.ForIncorporation': 'Einzugliedern',
  'CommentProcedureCommentStateEnum.Incorporated': 'Eingearbeitet',
  'CommentProcedureCommentStateEnum.Approved': 'Genehmigt',
  'CommentProcedureBulkUpdateForm.state': 'Neuwertiger Zustand',
  'CommentProcedureBulkUpdateFormModal.title': 'Massenänderung des Kommentarstatus',
  'CommentProcedureCreateTeamForm.name': 'Teamname',
  'CommentProcedureCreateTeamForm.name.required': 'Bitte tragen Sie den Namen des Teams ein',
  'CommentProcedureCreateTeamForm.commentProcedureRole': 'Teamtyp',
  'CommentProcedureCreateTeamForm.commentProcedureRole.required': 'Bitte geben Sie den Teamtyp ein',
  'CommentProcedureCreateTeamFormModal.title': 'Fügen Sie ein neues Team hinzu',
  'CommentProcedureDetailPage.tabs.participants': 'Teilnehmer',
  'CommentProcedureEditTeamFormModal.title': 'Team bearbeiten',
  'CommentProcedureParticipants.tabName.responsiblePersons': 'Verantwortliche Personen',
  'CommentProcedureParticipants.tabName.teams': 'Mannschaften',
  'CommentProcedureParticipants.tabName.users': 'Benutzer',
  'CommentProcedureTeams.addTeam': 'Fügen Sie ein Team hinzu',
  'CommentProcedureTeams.teamAdmin': 'Kopf',
  'CommentProcedureTeams.teamAdmins': 'Teamleiter',
  'CommentProcedureTeams.teamMember': 'Mitglied',
  'CommentProcedureTeams.teamMembers': 'Teammitglieder',
  'CommentProcedureTeams.moveToAdmins': 'Wechseln Sie zu Führungskräften',
  'CommentProcedureTeams.moveToMembers': 'Zu Mitgliedern wechseln',
  'CommentProcedureTeams.lastTeamWarning.title': 'Du entfernst dich',
  'CommentProcedureTeams.lastTeamWarning.message': 'Mit dem Widerruf verlieren Sie den Zugang zu diesem Mahnverfahren. Sind Sie sicher, dass Sie sich abmelden möchten?',
  'CommentProcedureTeams.memberAllowStatusChange': 'Mitglieder können den Status von Kommentaren ändern',
  'CommentProcedureRoleEnum.Processor': 'Prozessor',
  'CommentProcedureRoleEnum.Processors': 'Prozessoren',
  'CommentProcedureRoleEnum.Submitter': 'Kommentator',
  'CommentProcedureRoleEnum.Submitters': 'Kommentatoren',
  'CommentProcedureCommentsDetails.teamNotSet': 'Nicht zugewiesen',
  'CommentProcedureCommentsDetails.tabs.discussion': 'Diskussion',
  'CommentProcedureCommentsDetails.tabs.annotations': `Kommentare {count, plural,
    =0 {}
    other {(#)}
    }`,
  'CommentProcedureCommentsDetails.tabs.documents': `Dokumente {count, plural,
    =0 {}
    other {(#)}
    }`,
  'CommentProcedureCommentsDetails.notSaveAlert': 'Die Erinnerung wird nicht gespeichert. Wenn Sie fortfahren, gehen die Änderungen verloren.',
  'CommentProcedureCommentsNote.notSaveAlert': 'Der Kommentar wird nicht gespeichert. Wenn Sie fortfahren, gehen die Änderungen verloren.',
  'CommentProcedureExportForm.author': 'Kommentarautor',
  'CommentProcedureExportForm.ButtonFilter': 'Fügen Sie einen Filter hinzu',
  'CommentProcedureExportForm.Filter': 'Filter',
  'CommentProcedureExportForm.sortA_Z': 'Von A bis Z sortieren (absteigend)',
  'CommentProcedureExportForm.sortBy': 'Sortieren nach',
  'CommentProcedureExportForm.sortId': 'ID (chronologisch)',
  'CommentProcedureExportForm.state': 'Erinnerungsstatus',
  'CommentProcedureExportItemFormModal.button': 'Export',
  'CommentProcedureExportItemFormModal.title': 'Kommentare exportieren',
  'CommentProcedureDetailPage.tabs.categories': 'Kategorie',
  'CommentProcedureDetailPage.tabs.errors': 'Fehler',
  'CommentProcedureCategories.tabName.categories': 'Kategorie',
  'CommentProcedureCreateCategoryForm.name': 'Kategoriename',
  'CommentProcedureCreateCategoryForm.name.required': 'Bitte geben Sie den Namen der Kategorie ein',
  'CommentProcedureCreateCategoryForm.description': 'Kategoriebeschreibung',
  'CommentProcedureCreateCategoryFormModal.title': 'Fügen Sie eine neue Kategorie hinzu',
  'CommentProcedureEditCategoryFormModal.title': 'Kategorie bearbeiten',
  'CommentProcedureCategories.addCategory': 'Kategorie hinzufügen',
  'CommentProcedureCategories.import': 'Kategorien importieren',
  'CommentProcedureCategories.importSubmitButton': 'Import',
  'CommentProcedureImportCategoryForm.commentProcedure': 'Aus dem Mahnverfahren',
  'CommentProcedureImportCategoryForm.commentProcedure.required': 'Bitte wählen Sie ein Mahnverfahren aus',
  'CommentProcedureImportCategoryFormModal.title': 'Kategorien importieren',
  'CommentProcedureLinkAttachment.tooltip.attach': 'Anhänge anhängen',
  'CommentProcedureLinkAttachment.staged.tooltip': 'Bereit zum Hochladen',
  'CommentProcedureLinkAttachment.tooltip.disabled': 'Der Anhang konnte nicht angehängt werden',
  'CommentProcedureLinkAttachment.tooltip.maxAttachmentsExceeded': 'Maximale Anzahl angehängter Anhänge',
  'CommentProcedureLinkAttachment.tooltip.noAttachments': 'Es sind keine Anhänge verfügbar',
  'CommentProcedureLinkAttachment.tooltip.noMoreAttachments': 'Es sind keine weiteren Anhänge verfügbar',
  'CommentProcedureRolesSettings.mainSubmitterCanEditProcessorTeams': 'Der Hauptkommentator kann ein Redaktionsteam zusammenstellen',
  'CommentProcedureRolesSettings.mainProcessorCanEditSubmitterTeams': 'Der Hauptbearbeiter kann ein Gutachterteam bilden',
  'CommentProcedureRolesSettings.title': 'Erinnerungsverwaltung und Workflow',
  'CommentProcedureRolesSettings.useWorkDays': 'Legen Sie Standardwerktage anstelle von Kalendertagen fest',
  'DiscussionAssignAttachmentForm.assignedDocuments': 'Angehängte Dokumente an Anhänge',
  'DiscussionAssignAttachmentForm.assignedDocuments.modalTitle': 'Wählen Sie Dokumente aus, die Sie den Anhängen hinzufügen möchten',
  'DiscussionLinkAttachment.warning.publishing': 'Interne Anhänge werden veröffentlicht',
  'DiscussionLinkAttachmentForm.title': 'Benennung von Anhängen',
  'DiscussionLinkAttachmentForm.name': 'Der Name der Datei',
  'DiscussionLinkAttachmentForm.name.required': 'Bitte geben Sie einen Dateinamen für das eingebettete Bild ein',
  'DiscussionLinkAttachmentForm.disabled': 'Maximale Anzahl an angehängten Anhängen. Es kann kein neues Bild angehängt werden.',
  'CommentText.messageTitle': 'Kommentartext:',
  'CommentText.additionalMessageTitle': 'Antwort auf Erinnerung:',
  'DiscussionInput.placeholder': 'Einen Kommentar hinterlassen...',
  'DiscussionInput.send': 'Senden Sie eine Nachricht',
  'CompareRevisions.title': 'Vergleichen Sie Dokumentrevisionen',
  'CompareRevisions.firstRevision': 'Erste Überarbeitung:',
  'CompareRevisions.secondRevision': 'Zweite Überarbeitung:',
  'CompareRevisions.comparisonType': 'Vergleichstyp:',
  'CompareRevisions.comparisonType.Text': 'Text',
  'CompareRevisions.comparisonType.Drawing': 'Zeichnung (PDF)',
  'CompareRevisions.comparisonType.ModelDiffTool': 'Modell (DiffTool)',
  'DateFilter.unlimited': '(unbegrenzt)',
  'DateRangeForm.monthFrom': 'Einen Monat später',
  'DateRangeForm.monthTo': 'Einen Monat bis',
  'DateRangeForm.title': 'Datum von/bis auswählen',
  'DateRangeForm.yearFrom': 'Jahr von',
  'DateRangeForm.yearTo': 'Jahr bis',
  'DateRangeForm.reset': 'Zurücksetzen',
  'DateRangeForm.cancel': 'Stornieren',
  'DateRangeForm.confirm': 'Bestätigen',
  'DateRangeForm.defaultRange': 'Standardbereich',
  'DateRangeForm.previousRange': 'Der zuletzt festgelegte Bereich',
  'DateSelect.n_workDays': `{workDays, plural,
    =0 {dieser Arbeitstag}
    one {nächster Arbeitstag}
    other {# Arbeitstage}
    }`,
  'DateSelect.n_weekDays': `{weekDays, plural,
    =0 {dieser Wochentag}
    one {nächster Wochentag}
    other {# Wochentage}
    }`,
  'DateSelect.n_calendarDays': `{calendarDays, plural,
    =0 {heute}
    one {morgen}
    other {# Tage}
    }`,
  'Deadline.changeDeadline': 'Datum ändern',
  'Deadline.changeReason': 'Grund für die Änderung:',
  'Deadline.unlimited': 'Keine Einschränkungen',
  'DeadlineExtensionRequestModal.title': 'Antrag auf Verlängerung der Aufgabenfrist',
  'DeadlineExtensionRequestModal.submitText': 'Senden Sie eine Anfrage',
  'DeadlineExtensionRequestForm.actualTerm': 'Aktuelle Amtszeit:',
  'DeadlineExtensionRequestForm.justification': 'Begründung der Anfrage',
  'DeadlineExtensionRequestForm.justification.required': 'Eine Begründung ist zwingend erforderlich',
  'DeadlineExtensionRequestForm.newTerm': 'Vorgeschlagenes Datum',
  'DeadlineExtensionApprovalForm.applicant': 'Antragsteller:',
  'DeadlineExtensionApprovalForm.proposedTerm': 'Vorgeschlagener Termin:',
  'DeadlineExtensionApprovalForm.requestJustification': 'Grund der Anfrage:',
  'DeadlineExtensionApprovalForm.decisionJustification': 'Begründung der Entscheidung:',
  'DeadlineExtensionApprovalForm.decisionJustification.required': 'Eine Begründung der Entscheidung ist zwingend erforderlich',
  'DeadlineExtensionApprovalForm.decisionApproval': 'Entscheidung:',
  'DeadlineExtensionApprovalForm.decisionApproval.required': 'Die Entscheidung ist zwingend',
  'DeadlineExtensionApprovalForm.switch.Approve': 'Genehmigen',
  'DeadlineExtensionApprovalForm.switch.Reject': 'Ablehnen',
  'DeadlineExtensionApprovalForm.submitText.approve': 'Genehmigen Sie die Verlängerung',
  'DeadlineExtensionApprovalForm.submitText.reject': 'Lehnen Sie die Verlängerung ab',
  'DeepShareDownloadFormModal.title': 'Link zum Herunterladen des Ordners',
  'DeepShareDownloadFormModal.success': 'Der Link zum Herunterladen des Ordners wurde erfolgreich gesendet.',
  'DerivativesFileViewerModal.firstPagePreview': 'Titelblatt',
  'DerivativesFileViewerModal.preview': 'Vorschau',
  'DerivativesFileViewerModal.original': 'Original',
  'DerivativesFileViewerModal.signedDocument': 'Ein unterschriebenes Dokument',
  'DerivativesFileViewerModal.discardedAttachment': 'Eliminiert',
  'DeleteButton.cannotDeleteYourself': 'Sie können sich nicht selbst entfernen',
  'DeleteButton.orgHasProject': 'Eine Organisation, die bereits über ein Projekt verfügt, kann nicht gelöscht werden',
  'DeleteButton.isLastAdmin': 'Der letzte Administrator konnte nicht entfernt werden',
  'DocumentsMoveFormModal.destinationDirectoryId.label': 'Zum Ordner',
  'docMenu.restore': 'Wiederherstellen',
  'DiscardDirectory.Modal.description': 'Sind Sie sicher, dass Sie den ausgewählten Ordner einschließlich aller Dokumente und Unterordner verwerfen möchten?',
  'DiscardDirectory.Modal.description.links': 'Einige Dokumente enthalten Links. Sind Sie sicher, dass Sie den ausgewählten Ordner einschließlich aller Dokumente, Links und Unterordner verwerfen möchten?',
  'DiscardDirectory.Modal.title': 'Ordner verwerfen',
  'DiscardDirectoryLink.Modal.description': 'Sind Sie sicher, dass Sie den Ordnerlink löschen möchten?',
  'DiscardDirectoryLink.Modal.title': 'Einen Link zu einem Ordner löschen',
  'DiscardDirectoryLink.Modal.targetFolder': 'Verlinkter Ordner:',
  'DiscardedDocumentRow.discarded': 'Weggeworfen',
  'DiscardedDocumentRow.discardedBy': 'Vom Benutzer entfernt',
  'DiscardedDocumentRow.discardedTitle': 'Im Ruhestand: {date}',
  'DiscardedDocumentsPage.loading': 'Verworfene Dokumente und Ordner werden geladen...',
  'DiscardedDocumentRow.restore': 'Wiederherstellen',
  'DiscardedFolderRow.discardedBy': 'Vom Benutzer entfernt',
  'DiscardedFolderRow.discardedTitle': 'Im Ruhestand: {date}',
  'DiscardedFolderRow.restoreFolder': 'Ordner wiederherstellen',
  'DiscardedFolderRow.checkboxTooltip': 'Ordner können nicht in großen Mengen ausgewählt werden',
  'DocumentationLevelFilter.undefined': '0 - Nicht eingegeben',
  'DocumentationLevelFilter.zp': '1 - Erkennungsprotokoll',
  'DocumentationLevelFilter.dur': '2 - DUR',
  'DocumentationLevelFilter.dusl': '3 - DUSL',
  'DocumentationLevelFilter.dusp': '4 - DUSP',
  'DocumentationLevelFilter.dsp': '5 - DSP',
  'DocumentationLevelFilter.pdps': '6 - PDSP',
  'DocumentationLevelFilter.rds': '7 - RDS',
  'DocumentationLevelFilter.dsps': '8 - DSPS',
  'DocumentationLevelFilter.finished': '9 – Fertig',
  'DocumentCompleteList.downloadedZipName': 'Unterlagen',
  'DocumentList.cannotSelectDiscarded': 'Ein verworfenes Dokument kann nicht ausgewählt werden',
  'DocumentMultipleActionError.error.NotFound': 'Dokument <strong>{name}</strong> nicht gefunden',
  'DocumentMultipleActionError.error.IsDiscarded': 'Das Dokument <strong>{name}</strong> wurde zurückgezogen',
  'DocumentMultipleActionError.error.BadDirectory': 'Das Dokument <strong>{name}</strong> befindet sich nicht im Quellordner. Es wurde verschoben nach: {path}.',
  'DocumentMultipleActionError.error.AccessDenied': 'Sie verfügen nicht über ausreichende Berechtigungen zum Dokumentieren von <strong>{name}</strong>.',
  'DocumentMultipleActionError.error.IsNotDiscarded': 'Dokument <strong>{name}</strong> wurde nicht verworfen',
  'DocumentMultipleActionError.error.HasDocToDocLink': `{count, plural,
    =0 {no}
    one {Dem Dokument <strong>{name}</strong> ist 1 Bindung zugewiesen. Durch das Zurückziehen wird es gelöscht.}
    few {Dem Dokument <strong>{name}</strong> sind # Bindungen zugewiesen. Durch das Verwerfen werden sie gelöscht.}
    other {Dem Dokument <strong>{name}</strong> sind # Bindungen zugewiesen. Durch Verwerfen werden sie gelöscht.}
    }`,
  'DocumentMultipleActionError.error.HasModelElementLink': `{count, plural,
    =0 {no}
    Einem {Modell <strong>{name}</strong> ist 1 Dokumentbindung zugewiesen. Wenn Sie es entfernen, wird es gelöscht.}
    few {Modell <strong>{name}</strong> sind # Dokumentbindungen zugewiesen. Wenn Sie sie entfernen, werden sie gelöscht.}
    other {Modell <strong>{name}</strong> sind # Dokumentbindungen zugewiesen. Wenn Sie sie entfernen, werden sie gelöscht.}
    }`,
  'DocumentMultipleActionError.error.InWorkflow': 'Das Dokument <strong>{name}</strong> wurde in einem Workflow zugewiesen.',
  'DocumentMultipleActionError.error.InCommentProcedure': 'Das Dokument <strong>{name</strong> befindet sich im Kommentarprozess.',
  'DocumentMultipleActionError.error.InActiveWorkflow': 'Dokument <strong>{name}</strong> befindet sich bereits im aktiven Workflow.',
  'DocumentMultipleActionError.error.InActiveCommentProcedure': 'Dokument <strong>{name}</strong> befindet sich bereits im aktiven Kommentarprozess.',
  'DocumentMultipleActionError.error.InappropriateDocumentState': 'Das Dokument <strong>{name}</strong> muss den Status „Freigegeben“ haben.',
  'DocumentMultipleActionError.error.Reserved': 'Dokument <strong>{name}</strong> ist reserviert.',
  'DocumentMultipleActionError.error.HasOwner': 'Das Dokument <strong>{name}</strong> hat einen Administrator.',
  'DocumentMultipleActionError.error.EsticonDocument': 'Dokument <strong>{name}</strong> wird aus AspeEsticon importiert.',
  'DocumentMultipleActionError.error.EsticonDocumentDilci': 'Dokument <strong>{name}</strong> ist eine Unterkomponente eines gebündelten Imports von AspeEsticon.',
  'DocumentMultipleActionError.error.LinkNotFound': 'Dokumentreferenz <strong>{name}</strong> nicht gefunden.',
  'DocumentMultipleActionError.error.LinkBadDirectory': 'Der Link <strong>{name}</strong> verweist auf den falschen Ordner.',
  'DocumentMultipleActionError.error.LinkAccessDenied': 'Sie verfügen nicht über ausreichende Berechtigungen, um das referenzierte Dokument <strong>{name}</strong> anzuzeigen.',
  'DocumentMultipleActionError.error.LinkedDocumentAccessDenied': 'Sie verfügen nicht über ausreichende Berechtigungen für das referenzierte Dokument <strong>{name}</strong>.',
  'DocumentMultipleActionError.error.NotInModel': 'Das Dokument <strong>{name</strong> ist nicht in den Modellen enthalten und kann nicht entfernt werden.',
  'DocumentMultipleActionError.error.AlreadyInModel': 'Das Dokument <strong>{name</strong> ist bereits in den Modellen enthalten.',
  'DocumentsGate.loading': 'Dokumente werden geladen...',
  'DocumentRow.tooltip.lastRevision': 'Letzte Überarbeitung: {date}',
  'DocumentRow.tooltip.originalDocument': '(Erstellung des Dokuments: {date})',
  'DocumentRow.tooltip.attachmentUpload': 'Hochgeladenes Datum: {date}',
  'DocumentRow.tooltip.revisionCreated': 'Erstellungsdatum der Revision: {date}',
  'DocumentRow.tooltip.documentLinkCreated': 'Bindung hergestellt: {date}',
  'DocumentsDiscardFormModal.button.Discard': 'Beseitigen',
  'DocumentsDiscardFormModal.button.DiscardNext': 'Eliminiere andere',
  'DocumentsDiscardFormModal.button.DiscardLinks': 'Löschen Sie Links und Referenzen und verwerfen Sie Dokumente',
  'DocumentsDiscardFormModal.button.DiscardLinksNext': 'Löschen Sie Links und Referenzen und verwerfen Sie andere Dokumente',
  'DocumentsDiscardForm.DiscardDocuments': `{count, plural,
    =0 {Möchten Sie wirklich 0 ausgewählte Dokumente verwerfen?}
    one {Möchten Sie wirklich 1 ausgewähltes Dokument verwerfen?}
    few {Möchten Sie wirklich # ausgewählte Dokumente verwerfen? }
    other {Möchten Sie # ausgewählte Dokumente wirklich verwerfen?}
    }`,
  'DocumentsDiscardForm.DiscardDocumentLinks': `{count, plural,
    =0 {Möchten Sie wirklich 0 ausgewählte Dokumentlinks entfernen?}
    one {Möchten Sie wirklich 1 ausgewählten Dokumentlink entfernen?}
    few {Möchten Sie wirklich # ausgewählte entfernen? Dokument-Links?}
    other {Sind Sie sicher, dass Sie die # ausgewählten Dokument-Links verwerfen möchten?}
    }`,
  'DocumentsDiscardForm.errorsTitle.missed': `{count, plural,
    =0 {Alle OK}
    one {Bei der Vorbereitung zum Verwerfen von Dokumenten ist ein Fehler aufgetreten. Es wurde kein Dokument verworfen.}
    few {Beim Vorbereiten des Verwerfens von Dokumenten sind Fehler aufgetreten. Es wurde kein Dokument verworfen.}
    other {Beim Vorbereiten des Verwerfens von Dokumenten sind Fehler aufgetreten. Es wurde kein Dokument verworfen.}
    }`,
  'DocumentsDiscardFormModal.title': 'Dokumente verwerfen',
  'DocumentsDownloadForm.downloadDocuments': `{count, plural,
    =0 {0 ausgewählte Dokumente herunterladen}
    one {1 ausgewähltes Dokument herunterladen}
    few {# ausgewählte Dokumente herunterladen}
    other {# ausgewählte Dokumente herunterladen}
    }`,
  'DocumentsDownloadForm.downloadDocumentLinks': `{count, plural,
    =0 {0 ausgewählte verknüpfte Dokumente herunterladen}
    one {1 ausgewählte verknüpfte Dokument herunterladen}
    few {# ausgewählte verknüpfte Dokumente herunterladen}
    other {# ausgewählte verknüpfte Dokumente herunterladen}
    }`,
  'DocumentsDownloadForm.errorsTitle.missed': `{count, plural,
    =0 {Alles OK}
    one {Beim Vorbereiten des Archivs ist ein Fehler aufgetreten. Das Archiv wurde nicht heruntergeladen.}
    few {Beim Vorbereiten des Archivs sind Fehler aufgetreten. Das Archiv wurde nicht heruntergeladen.}
    other {Beim Vorbereiten des Archivs sind Fehler aufgetreten. Das Archiv wurde nicht heruntergeladen.}
    }`,
  'DocumentsDownloadForm.form.items.name.rules.required': 'Bitte geben Sie den Namen des Dokumentenarchivs ein',
  'DocumentsDownloadForm.form.items.name.rules.valid': 'Bitte geben Sie einen gültigen Dateinamen ein',
  'DocumentsDownloadForm.nameLabel': 'Der Name des Archivs',
  'DocumentsDownloadFormModal.button.download': 'Herunterladen',
  'DocumentsDownloadFormModal.button.downloadNext': 'Andere herunterladen',
  'DocumentsDownloadFormModal.title': 'Dokumente herunterladen',
  'DocumentsGridHeader.select': 'Alles auswählen',
  'DocumentsGridHeader.deselect': 'Auswahl abbrechen',
  'DocumentLinkRow.missingDocument.error': 'Das referenzierte Dokument wurde nicht gefunden',
  'DocumentLinkRow.missingDocument.restoreTooltip': 'Aktualisieren Sie das verknüpfte Dokument',
  'DocumentLinkRow.missingDocument.contactAdminTooltip': 'Kontaktieren Sie Ihren Administrator',
  'DocumentsMoveFormModal.button.move': 'Bewegen',
  'DocumentsMoveFormModal.button.moveAndRename': 'Verschieben und umbenennen',
  'DocumentsMoveFormModal.button.moveNext': 'Andere bewegen',
  'DocumentsMoveFormModal.errorsTitle.duplicate': `{count, plural,
    =0 {Alle OK}
    one {Das folgende Dokument mit demselben Namen existiert bereits im Zielordner}
    few {Die folgenden Dokumente mit demselben Namen existieren bereits im Zielordner}\ n other {Die folgenden Dokumente mit demselben Namen sind bereits im Zielordner vorhanden}
    }`,
  'DocumentsMoveFormModal.errorsTitle.duplicateLinks': `{count, plural,
    =0 {Alle OK}
    one {Der folgende Dokumentverweis mit demselben Namen ist bereits im Zielordner vorhanden}
    few {Die folgenden Dokumentverweise mit demselben Namen sind bereits im Zielordner vorhanden }
    other {Die folgenden Verweise auf Dokumente mit demselben Namen sind bereits im Zielordner vorhanden}
    }`,
  'DocumentsMoveFormModal.errorsTitle.missed': `{count, plural,
    =0 {Alles OK}
    one {Beim Verschieben ist ein Fehler aufgetreten. Es wurden keine Dokumente verschoben.}
    few {Beim Verschieben sind Fehler aufgetreten. Es wurden keine Dokumente verschoben.}
    other {Beim Verschieben sind Fehler aufgetreten. Es wurden keine Dokumente verschoben.}
    }`,
  'DocumentsMoveFormModal.moveDocuments': `{count, plural,
    =0 {0 ausgewählte Dokumente verschieben}
    one {1 ausgewähltes Dokument verschieben}
    few {# ausgewählte Dokumente verschieben}
    other {# ausgewählte Dokumente verschieben}
    }`,
  'DocumentsMoveFormModal.moveLinks': `{count, plural,
    =0 {0 ausgewählte Dokument-Links verschieben}
    one {1 ausgewählten Dokument-Link verschieben}
    few {# ausgewählte Dokument-Links verschieben}
    other {# ausgewählte Dokument-Links verschieben} 
    }`,
  'DocumentsMoveFormModal.moveFromFolder': 'aus Ordner:',
  'DocumentsMoveRenameInfoModal.title': `{count, plural,
    =0 {Alle OK}
    one {Das folgende Dokument wurde umbenannt}
    few {Die folgenden Dokumente wurden umbenannt}
    other {Die folgenden Dokumente wurden umbenannt}
    }`,
  'DocumentsMoveRenameInfoModal.item': 'Das Dokument <strong>{origin}</strong> wurde in <strong>{destination}</strong> umbenannt',
  'DocumentsMoveFormModal.title': 'Dokumente verschieben',
  'DocumentsRestoreFormModal.button.Restore': 'Wiederherstellen',
  'DocumentsRestoreFormModal.button.RestoreNext': 'Andere wiederherstellen',
  'DocumentsRestoreForm.RestoreDocuments': `{count, plural,
    =0 {Möchten Sie wirklich 0 ausgewählte Dokumente wiederherstellen?}
    one {Möchten Sie wirklich 1 ausgewähltes Dokument wiederherstellen?}
    few {Möchten Sie wirklich # ausgewählte Dokumente wiederherstellen?}
    other {Möchten Sie wirklich # ausgewählte Dokumente wiederherstellen?}
    }`,
  'DocumentsRestoreFormModal.errorsTitle.duplicate': `{count, plural,
    =0 {Alles OK}
    one {Das folgende Dokument oder der folgende Ordner mit demselben Namen existiert bereits im Zielordner}
    few {Die folgenden Dokumente oder Ordner mit demselben Namen existieren bereits im Zielordner}
    other {Die folgenden Dokumente oder Ordner mit denselben Namen sind bereits im Zielordner vorhanden}
    }`,
  'DocumentsRestoreForm.errorsTitle.missed': `{count, plural,
    =0 {Alles OK}
    one {Beim Vorbereiten der Wiederherstellung von Dokumenten ist ein Fehler aufgetreten. Es wurde kein Dokument verworfen.}
    few {Beim Vorbereiten der Wiederherstellung von Dokumenten sind Fehler aufgetreten. Es wurde kein Dokument verworfen.}
    other {Beim Vorbereiten der Wiederherstellung von Dokumenten sind Fehler aufgetreten. Es wurde kein Dokument verworfen.}
    }`,
  'DocumentsRestoreRenameInfoModal.dupliciteDocumentError': 'Dokument: <strong>{document}</strong>',
  'DocumentsRestoreRenameInfoModal.dupliciteDirectoryError': 'Ordner: <strong>{directory}</strong>',
  'DocumentsRestoreFormModal.title': 'Dokumentenwiederherstellung',
  'DocumentLinksCreateFormModal.title': `{count, plural,
    one {Link zum Dokument erstellen}
    other {Link zu Dokumenten erstellen}
    }`,
  'DocumentLinksCreateFormModal.okButton': 'Erstellen Sie Links',
  'DocumentLinksCreateFormModal.okButton.createNext': 'Erstellen Sie weitere Links',
  'DocumentLinksCreateFormModal.result.success': `{count, plural,
    one {Dokument-Link erfolgreich erstellt.}
    other {Dokument-Links erfolgreich erstellt.}
    }`,
  'DocumentLinksCreateForm.bindingsToDocuments': `{count, plural,
    =0 {Link zu 0 ausgewählten Dokumenten aus Ordner erstellen}
    one {Link zu 1 ausgewählten Dokument aus Ordner erstellen}
    few {Link zu # ausgewählten Dokumenten aus Ordner erstellen}
    other {Erstellen Link zu # ausgewählten Dokumenten aus Ordner}
    }`,
  'DocumentLinksCreateForm.destinationDirectoryId.label': 'Zum Ordner',
  'DocumentLinksCreateForm.documentLinkName.label': 'Der Name des Links',
  'DocumentLinksCreateForm.errors': 'Fehler in Dateien',
  'DocumentLinkRenameFormModal.title': 'Ändern Sie den Namen des Links',
  'DocumentLinkRenameForm.linkName': 'Name',
  'DocumentLinkRenameForm.createdDate': 'Datum, an dem der Link erstellt wurde:',
  'DocumentLinkRenameForm.targetPath': 'Pfad zum referenzierten (Ziel-)Dokument:',
  'DocumentLinkRenameForm.linkLocation': 'Link-Standort:',
  'DocumentLinkRenameForm.linkAuthor': 'Autor des Links:',
  'DirectoryCreateForm.form.items.name.label': 'Name',
  'DirectoryCreateForm.form.items.name.placeholder': 'Ordner 1',
  'DirectoryCreateForm.form.items.name.rules.nameExists': 'Ein Ordner mit diesem Namen existiert bereits. Bitte wählen Sie einen anderen Namen',
  'DirectoryCreateForm.form.items.name.rules.required': 'Bitte geben Sie einen Ordnernamen ein',
  'DirectoryCreateFormModal.button.create': 'Ordner erstellen',
  'DirectoryCreateFormModal.button.create.saving': 'Ordner wird erstellt...',
  'DirectoryCreateFormModal.title': 'Neuer Ordner',
  'DirectoryCreateLinkFormModal.title': 'Erstellen Sie einen Ordnerlink',
  'DirectoryCreateLinkForm.source': 'Ressourcenordner',
  'DirectoryCreateLinkForm.label': 'Zum Ordner',
  'DirectoryCreateLinkForm.placeholder': 'Wählen Sie den Zielordner aus',
  'DirectoryCreateLinkForm.linkName': 'Der Name des Links',
  'DirectoryEditForm.form.items.description.label': 'Neue Ordnerbeschreibung',
  'DirectoryEditForm.form.items.name.label': 'Neuer Ordnername',
  'DirectoryEditForm.form.items.name.rules.nameExists': 'Ein Ordner mit diesem Namen existiert bereits. Bitte wählen Sie einen anderen Namen',
  'DirectoryEditForm.form.items.name.rules.required': 'Bitte geben Sie einen Ordnernamen ein',
  'DirectoryEditFormModal.button.save': 'Benennen Sie den Ordner um',
  'DirectoryEditFormModal.button.save.saving': 'Änderungen werden gespeichert...',
  'DirectoryEditFormModal.title': 'Bearbeiten eines Ordners',
  'DirectoryLinkEditForm.form.items.name.label': 'Neuer Ordner-Linkname',
  'DirectoryLinkEditForm.form.items.name.rules.nameExists': 'Ein Link mit diesem Namen existiert bereits, bitte wählen Sie einen anderen Namen',
  'DirectoryLinkEditForm.form.items.name.rules.required': 'Bitte geben Sie einen Linknamen für den Ordner ein',
  'DirectoryLinkEditFormModal.button.save': 'Ordnerlink umbenennen',
  'DirectoryLinkEditFormModal.button.save.saving': 'Änderungen werden gespeichert...',
  'DirectoryLinkEditFormModal.title': 'Bearbeiten eines Ordner-Links',
  'DirectoryForbiddenErrorBox.title': 'Sie haben keine Berechtigung, auf dieses Element zuzugreifen.',
  'DirectoryForbiddenErrorBox.description': 'Sie benötigen die Berechtigung, auf Dokumente in diesem Ordner zuzugreifen.',
  'DirectoryForbiddenErrorBox.request': 'Fordern Sie Zugriff auf einen Ordner an',
  'DirectoryForbiddenErrorBox.requestFailed': 'Die Anfrage konnte nicht gesendet werden.',
  'DirectoryForbiddenErrorBox.requestSent': 'Die Anfrage wurde erfolgreich gesendet.',
  'DirectoryForm.form.items.description.placeholder': 'Ordnerbeschreibung...',
  'DirectoryLinkMoveFormModal.title': 'Link in Ordner verschieben',
  'DirectoryLinkMoveFormModal.destinationDirectoryId.label': 'Bewegen Sie sich nach',
  'DirectoryLinkMoveFormModal.destinationDirectoryId.placeholder': 'Wählen Sie den Zielordner aus',
  'DirectoryLinkMoveFormModal.preserveAccessRights': 'Behalten Sie die Einstellungen für die Zugriffsrechte bei',
  'DirectoryLinkMoveFormModal.rename': 'Umbenennen',
  'DirectoryLinkMoveFormModal.selectedDirectory': 'Ausgewählter Ordner',
  'DirectoryLinkMoveFormModal.moveDirectoryLink': 'Link in Ordner verschieben',
  'DirectoryLinkMoveFormModal.moveError': 'Beim Verschieben sind Fehler aufgetreten. Der Ordnerlink wurde nicht verschoben.',
  'DirectoryMoveFormModal.title': 'Ordner verschieben',
  'DirectoryMoveFormModal.destinationDirectoryId.label': 'Bewegen Sie sich nach',
  'DirectoryMoveFormModal.destinationDirectoryId.placeholder': 'Wählen Sie den Zielordner aus',
  'DirectoryMoveFormModal.preserveAccessRights': 'Behalten Sie die Einstellungen für die Zugriffsrechte bei',
  'DirectoryMoveFormModal.rename': 'Umbenennen',
  'DirectoryMoveFormModal.selectedDirectory': 'Ausgewählter Ordner',
  'DirectoryMoveFormModal.moveDirectory': 'Ordner verschieben',
  'DirectoryMoveFormModal.moveError': 'Beim Verschieben sind Fehler aufgetreten. Der Ordner wurde nicht verschoben.',
  'DirectoryRestoreForm.RestoreDirectory': 'Sind Sie sicher, dass Sie den ausgewählten Ordner wiederherstellen möchten?',
  'DirectoryRestoreForm.errorsTitle': 'Der Ordner wurde nicht wiederhergestellt. Ein Ordner oder Pfad mit diesem Namen existiert bereits.',
  'DirectoryRestoreFormModal.button.Restore': 'Wiederherstellen',
  'DirectoryRestoreFormModal.button.RestoreAndRename': 'Wiederherstellen und umbenennen',
  'DirectoryRestoreFormModal.button.RestoreNext': 'Andere wiederherstellen',
  'DirectoryRestoreFormModal.title': 'Ordnerwiederherstellung',
  'DirectorySettingsForm.noUserPermissions': 'Keine Benutzerberechtigungen.',
  'DirectorySettingsForm.goToGroupsSettings': 'Gehen Sie zu den Gruppeneinstellungen',
  'DirectorySettingsForm.goToUsersSettings': 'Gehen Sie zu den Benutzereinstellungen',
  'DirectorySettingsForm.userAccessTab.searchButton.placeholder': 'Suchen Sie nach Benutzern nach Namen oder E-Mail',
  'DirectorySettingsForm.requiredCategories.label': 'Erforderliche Kategorien',
  'DirectorySettingsForm.requiredCategories.placeholder': 'Standardspeicherort der Kategorie',
  'DirectorySettingsForm.requiredCategories.tooltip': 'Bearbeiten Sie die erforderlichen Kategorien',
  'DirectorySettingsForm.tabs.accessPermission': 'Genehmigung',
  'DirectorySettingsForm.tabs.general': 'Im Allgemeinen',
  'DirectorySettingsForm.tabs.general.type': 'Typ',
  'DirectorySettingsForm.tabs.general.type.folder': 'Komponente',
  'DirectorySettingsForm.tabs.general.title': 'Informationen zum Inhalt des Ordners',
  'DirectorySettingsForm.tabs.general.size': 'Ordnergröße',
  'DirectorySettingsForm.tabs.general.sizeIncludingAttachments': 'Ordnergröße inklusive Anhänge',
  'DirectorySettingsForm.tabs.general.sizeIncludingSubfolders': 'Ordnergröße inklusive Unterordner',
  'DirectorySettingsForm.tabs.general.sizeIncludingSubfoldersAndAttachments': 'Ordnergröße einschließlich Unterordner und Anhänge',
  'DirectorySettingsForm.tabs.general.documentsCount': 'Anzahl der Dokumente und Ordner',
  'DirectorySettingsForm.tabs.general.documentsCountIncludingSubfolders': 'Anzahl der Dokumente und Ordner inklusive Unterordner',
  'DirectorySettingsForm.permissions.current': 'Ihre Ordnerberechtigungen',
  'DirectorySettingsForm.permissions.inheritLabel': 'Berechtigungen erben',
  'DirectorySettingsForm.permissions.groups': 'Gruppen',
  'DirectorySettingsForm.permissions.notSaveAlert': 'Einstellungen werden nicht gespeichert. Wenn Sie die Seite verlassen, gehen die Änderungen verloren.',
  'DirectorySettingsForm.permissions.users': 'Benutzer',
  'DirectorySettingsForm.permissions.save': 'Auferlegen',
  'DirectorySettingsForm.permissions.discardChanges': 'Änderungen verwerfen',
  'DirectoryWatchSettings.title': 'Ordnerverfolgung',
  'DirectoryWatchSettings.watchParamsTitle': 'Was möchtest du sehen?',
  'DirectoryWatchSettings.inheritSettings': 'Tracking übernehmen',
  'DirectoryWatchSettings.category.directoryChange': 'Ordner wechseln',
  'DirectoryWatchSettings.category.subfolders': 'Unterordner',
  'DirectoryWatchSettings.category.documentsChange': 'Änderung von Dokumenten',
  'DirectoriesTree.itemsCount': 'Anzahl der Dokumente',
  'DirectoriesTree.subItemsCount': 'Anzahl Dokumente (auch in Unterordnern)',
  'DirectoriesTree.linkedDirectoryPath': 'Pfad des verknüpften Ordners:',
  'DirectoriesTree.linkedDirectoryNotFound': 'Der referenzierte Ordner wurde nicht gefunden',
  'DirectoriesTreeSelect.other': '(DMS-Struktur)',
  'DirectoriesTreeSelect.preferred': 'Ausgewählte Ordner',
  'DiscardDocumentsPageFilterToolbar.type': 'Typ',
  'DiscardDocumentsPageFilterToolbar.type.file': 'Dokumentieren',
  'DiscardDocumentsPageFilterToolbar.type.folder': 'Komponente',
  'DiscardDocumentsPageFilterToolbar.usersLabel': 'Benutzer',
  'DocumentAnnotationModal.buttons.updateAnnotations': 'Kommentare speichern',
  'DocumentAnnotationModal.buttons.createCommentFromAnnotations': 'Erstellen Sie eine Erinnerung aus den Kommentaren',
  'DocumentAnnotationModal.error.emptyAnnotation': 'Kommentartext fehlt',
  'DocumentAnnotationModal.error.outOfDateDocument': 'Das Dokument wurde von einem anderen Benutzer aktualisiert',
  'DocumentAnnotationModal.message.success': 'Kommentare erfolgreich gespeichert',
  'DocumentAnnotationModal.notSavedAlert': 'Kommentare werden nicht gespeichert. Wenn Sie fortfahren, gehen die Änderungen verloren.',
  'DocumentCategoryTreeCreateFormModal.title': 'Neue Kategorie',
  'DocumentCategoryTreeNodeCreateFormModal.title': 'Neue Kategorieebene',
  'DocumentCategoryTreeNodeDeleteForm.documentsWithNode': 'Betroffene Dokumente',
  'DocumentCategoryTreeNodeDeleteForm.directoriesWithNode': 'Betroffene Ordner',
  'DocumentCategoryTreeNodeDeleteModal.ok': 'Entfernen',
  'DocumentCategoryTreeNodeDeleteModal.removeChildren.label': 'Auch untergeordnete Knoten entfernen?',
  'DocumentCategoryTreeNodeDeleteModal.removeChildren.preserve': 'Behalten und bewegen',
  'DocumentCategoryTreeNodeDeleteModal.removeChildren.remove': 'Entfernen',
  'DocumentCategoryTreeNodeDeleteModal.removeStrategy.label': 'Wie kann die Kategorie für die betroffenen Dokumente zurückgesetzt werden?',
  'DocumentCategoryTreeNodeDeleteModal.removeStrategy.replaceParent': 'Verwenden Sie den übergeordneten Knoten',
  'DocumentCategoryTreeNodeDeleteModal.removeStrategy.replaceRoot': 'Verwenden Sie die Wurzel',
  'DocumentCategoryTreeNodeDeleteModal.title': 'Löschen eines Kategorieknotens',
  'DocumentCreateForm.loadingCategoryTip': 'Erforderliche Kategorien werden geladen',
  'DocumentCreateForm.form.items.category.add': 'Kategorie hinzufügen',
  'DocumentCreateForm.form.items.category.add.noMore': 'Keine Kategorien zum Hinzufügen',
  'DocumentCreateForm.form.items.category.rules.required': 'Bitte wählen Sie einen Kategoriestandort aus',
  'DocumentCreateForm.form.items.category.select.placeholder': 'Wählen Sie eine Kategorie aus',
  'DocumentCreateForm.form.items.category.treeSelect.placeholder': 'Wählen Sie einen Kategoriestandort aus',
  'DocumentCreateForm.form.items.destinationDirectory.label': 'Speicherort (Ordner)',
  'DocumentCreateForm.form.items.destinationDirectory.placeholder': 'Wählen Sie den Zielordner aus',
  'DocumentCreateForm.form.items.destinationDirectory.rules.required': 'Bitte wählen Sie den Zielordner aus',
  'DocumentCreateForm.form.items.name.placeholder': 'Dokument 1.pdf',
  'DocumentCreateForm.form.items.name.rules.nameExists': 'Ein Dokument mit diesem Namen existiert bereits, bitte wählen Sie einen anderen Namen',
  'DocumentCreateForm.form.items.name.rules.nameDuplicity': 'Im Zielordner gibt es bereits ein Dokument mit demselben Namen. Die Datei wird als neues Dokument hochgeladen und automatisch umbenannt.',
  'DocumentCreateForm.form.items.name.rules.required': 'Bitte geben Sie einen Dokumenttitel ein',
  'DocumentCreateForm.form.items.name.rules.noValidByMask': 'Der Name stimmt nicht mit den für den Ordner festgelegten Masken überein',
  'DocumentCreateForm.form.items.relation.rules.required': 'Geben Sie an, ob es sich um einen internen oder öffentlichen Anhang handelt',
  'DocumentCreateForm.form.items.state.label': 'Zustand',
  'DocumentCreateForm.form.items.state.rules.required': 'Bitte wählen Sie einen Status aus',
  'DocumentCreateForm.form.oldRevisionWarning': 'Aufmerksamkeit! Sie bearbeiten eine ältere Revision eines Dokuments. Durch das Speichern wird eine neue Revision erstellt, die keine Änderungen enthält, die in neueren Revisionen vorgenommen wurden.',
  'DocumentCreateForm.section.categories': 'Kategorie',
  'DocumentCreateFormModal.checkingFiles': 'Dateien werden überprüft...',
  'DocumentCreateFormModal.continue': 'Versuchen Sie, dem nachzugehen',
  'DocumentCreateFormModal.cancelConfirm.title': 'Möchten Sie die Aufnahme wirklich beenden?',
  'DocumentCreateFormModal.cancelConfirm.text': 'Das Dokument, das gerade hochgeladen wird, und Dokumente, die auf den Upload warten, werden nicht hochgeladen. Möchten Sie fortfahren?',
  'DocumentCreateFormModal.pause': 'Unterbrechen',
  'DocumentCreateFormModal.title': 'Ein neues Dokument',
  'DocumentCreateMultipleForm.dropAreaTitle': 'Klicken Sie hier oder ziehen Sie Dateien.',
  'DocumentCreateMultipleForm.inheritPermission.label': 'Ordnerrechte vom übergeordneten Ordner übernehmen.',
  'DocumentCreateMultipleForm.file.description': 'Beschreibung',
  'DocumentCreateMultipleForm.file.description.placeholder': 'Geben Sie optional eine Dokumentbeschreibung ein',
  'DocumentCreateMultipleForm.file.manager': 'Administrator',
  'DocumentCreateMultipleForm.file.label': 'Die Namen der Dokumente entsprechen den Namen der angegebenen Dateien',
  'DocumentCreateMultipleForm.file.rules.required': 'Bitte wählen Sie die Dateien zum Hochladen aus',
  'DocumentCreateMultipleForm.file.rules.invalidNamesByMask': 'Einige Datei- oder Ordnernamen stimmen nicht mit den für die Ordner festgelegten Masken überein',
  'DocumentCreateMultipleForm.file.revisionComment': 'Kommentar',
  'DocumentCreateMultipleForm.file.revisionComment.rules.required': 'Bitte geben Sie einen Bewertungskommentar ein',
  'DocumentCreateMultipleForm.file.revisionComment.placeholder': 'Geben Sie einen Bewertungskommentar ein',
  'DocumentCreateMultipleForm.form.items.documentOptions.title': 'Parameter neuer Dokumente',
  'DocumentCreateMultipleForm.form.items.destinationDirectory.label': 'Speicherort (Zielordner)',
  'DocumentCreateMultipleForm.form.items.validationMaskFiles.label': 'Validierungsmaske für Dateinamen im Ordner',
  'DocumentCreateMultipleForm.form.items.validationMaskFolders.label': 'Validierungsmaske für Unterordnernamen',
  'DocumentCreateMultipleForm.form.items.state.label': 'Zustand',
  'DocumentCreateMultipleForm.form.items.revisionOptions.title': 'Parameter der Überarbeitung vorhandener Dokumente',
  'DocumentCreateMultipleForm.form.isModelSwitch.text': 'Zu Modellen hinzufügen',
  'DocumentCreateMultipleForm.primary.label': 'Primärdateien und Ordnerstruktur',
  'DocumentCreateMultipleForm.primary.text': 'Wählen Sie Primärdateien aus',
  'DocumentCreateMultipleForm.secondary.label': 'Anhänge',
  'DocumentCreateMultipleForm.secondary.text': 'Wählen Sie Anhänge aus',
  'DocumentCreateMultipleForm.secondary.cantAdd': 'Sie können ein signiertes Dokument oder Anhänge nicht in großen Mengen zu allen primären Dateien oder Ordnern hinzufügen. Fügen Sie nur eine Primärdatei ein, wenn Sie Anhänge hinzufügen möchten.',
  'DocumentCreateMultipleForm.signedDocument.label': 'Ein unterschriebenes Dokument',
  'DocumentCreateMultipleFormModal.title': 'Laden Sie Dokumente und Ordner hoch',
  'DocumentDetailPage.disableComparison.tooltip': 'Der Vergleich ist nicht verfügbar, da die Lizenz fehlt oder das Dokument für diese Aktion nicht unterstützt wird',
  'DocumentDetailPage.annotationEdit.error': 'Das Dokument verfügt über kein PDF-Derivat zum Kommentieren',
  'DocumentDetailPage.createRevision': 'Neue Überarbeitung',
  'DocumentDetailPage.createRevision.disabled': 'Eine neue Revision kann nicht hinzugefügt werden.',
  'DocumentDetailPage.createRevision.tooltip': 'Neue Revision hinzufügen',
  'DocumentDetailPage.createRevision.tooltip.Ok': 'Neue Revision hinzufügen.',
  'DocumentDetailPage.createRevision.tooltip.AccessDenied': 'Zugriff verweigert.',
  'DocumentDetailPage.createRevision.tooltip.InWorkflow': 'Das Dokument wird in einem Workflow verwendet.',
  'DocumentDetailPage.createRevision.tooltip.InCommentProcedure': 'Das Dokument wird im Kommentarverfahren verwendet.',
  'DocumentDetailPage.createRevision.tooltip.Reserved': 'Das Dokument ist reserviert.',
  'DocumentDetailPage.createRevision.tooltip.HasOwner': 'Das Dokument hat einen Eigentümer.',
  'DocumentDetailPage.createRevision.tooltip.EsticonDocument': 'Das Dokument ist mit AspeEsticon verlinkt.',
  'DocumentDetailPage.directoryForbiddenMessage': 'Das angeforderte Dokument befindet sich in einem Ordner {path}, auf den Sie keinen Zugriff haben.',
  'DocumentDetailPage.downloadPrimaryFile.tooltip': 'Laden Sie die Primärdatei herunter',
  'DocumentDetailPageCategories.editMode.tooltip': 'Kategorien bearbeiten',
  'DocumentDetailPage.favorite.saving': 'Favoriten speichern',
  'DocumentDetailPage.favorite.set': 'Zu Favoriten hinzufügen',
  'DocumentDetailPage.favorite.unset': 'Aus Favoriten entfernen',
  'DocumentDetailPage.manager.error.documentInActiveWorkflow': 'Das Dokument ist Teil des Workflows',
  'DocumentDetailPage.manager.error.documentInCommentProcedure': 'Das Dokument ist Teil des Mahnverfahrens',
  'DocumentDetailPage.manager.error.documentIsReserved': 'Das Dokument ist reserviert',
  'DocumentDetailPage.manager.error.documentHasOwner': 'Das Dokument hat einen Administrator',
  'DocumentDetailPage.manager.error.documentHasEsticonLink': 'Ein Administrator kann keinem AspeEsticon-Dokument zugewiesen werden',
  'DocumentDetailPage.model.add': 'Zu Modellen hinzufügen',
  'DocumentDetailPage.model.remove': 'Aus Modellen entfernen',
  'DocumentDetailPage.previewNotAvailable': 'Vorschau nicht verfügbar.',
  'DocumentDetailPage.secondaryFile': 'Anhänge',
  'DocumentDetailPage.name.successMessage': 'Der Name wurde erfolgreich geändert',
  'DocumentDetailPageBindings.deleteSuccess': 'Der Link zum Dokument wurde gelöscht',
  'DocumentDetailPageBindings.title': 'Link zu diesem Dokument',
  'DocumentDetailPageBindings.bindedDocuments.modalTitle': 'Auswahl von Dokumenten zum Erstellen von Links',
  'DocumentDetailPageBindings.addBinding': 'Fügen Sie Links zu Dokumenten hinzu',
  'DocumentDetailPageBindings.addBinding.success': 'Bindungen wurden erfolgreich hinzugefügt',
  'DocumentDetailPageBindings.addBinding.warning': `{count, plural,
    one {Eine Bindung an das Element ist bereits vorhanden.}
    few {Die Bindungen wurden erfolgreich hinzugefügt. # Elementbindungen sind bereits vorhanden.}
    other {Bindungen erfolgreich hinzugefügt. # Elementbindungen sind bereits vorhanden.}
    }`,
  'DocumentDetailPageBindings.removeBinding': 'Entfernen Sie diese Bindung',
  'DocumentDetailPageBindings.removeBinding.success': 'Bindung erfolgreich entfernt',
  'DocumentDetailPageBindings.noBindingToSameDocument': 'Ein Dokument kann nicht auf sich selbst verweisen.',
  'DocumentDetailPageBindings.bindingDuplicated': 'Der Link zu diesem Dokument ist bereits abgelaufen.',
  'DocumentDetailPageBindings.noSufficientPermissions': 'Der Link zum ausgewählten Dokument kann nicht erstellt werden. Sie verfügen nicht über ausreichende Berechtigungen.',
  'DocumentDetailPageBindings.selectDocumentsModal.title': 'Wählen Sie Dokumente aus, um einen Link hinzuzufügen',
  'DocumentDetailPageBindings.failureList.Modal.title': 'Im Folgenden konnte kein Link zum Dokument erstellt werden',
  'DocumentDetailPageBindings.documentError.NotFound': 'Datei {fileName} nicht gefunden',
  'DocumentDetailPageBindings.documentError.IsDiscarded': 'Die Datei {fileName} wurde entfernt',
  'DocumentDetailPageBindings.documentError.BadDirectory': 'Die Datei {fileName} befindet sich nicht mehr im Verzeichnis',
  'DocumentDetailPageBindings.documentError.AccessDenied': 'Sie verfügen nicht über ausreichende Berechtigungen zum Binden an die Datei {fileName}',
  'DocumentDetailPageBindings.documentError.PropertySet': 'Die Bindung an die Datei {fileName} wurde bereits festgelegt',
  'DocumentDetailPageBindings.documentError.PropertyUnset': 'Die Verknüpfung der Datei {fileName} wurde bereits aufgehoben',
  'DocumentDetailPageReferences.addReferences': 'Fügen Sie Verweise auf Dokumente hinzu',
  'DocumentDetailPageReferences.removeReference': 'Entfernen Sie den Verweis',
  'DocumentDetailPageReferences.order.reference': 'Eigene Referenz dieses Dokuments',
  'DocumentDetailPageReferences.order.referrer': 'Ein Dokument ist ein Verweis auf ein anderes Dokument',
  'DocumentDetailPageReferences.order.createdBy': 'Erstellt vom Benutzer',
  'DocumentDetailPageReferences.order.createdDate': 'Erstellungsdatum',
  'DocumentDetailPageReferences.order.modifiedDate': 'Datum der Änderung',
  'DocumentDetailPageReferences.addReferences.documentsSelect.title': 'Wählen Sie Dokumente aus, denen Sie eine Referenz hinzufügen möchten',
  'DocumentDetailPageReferences.noSufficientPermissions': 'Sie verfügen nicht über ausreichende Berechtigungen',
  'DocumentDetailPageReferences.noReferenceToSameDocument': 'Ein Dokument kann keinen Verweis auf sich selbst haben.',
  'DocumentDetailPageReferences.referenceDuplicated': 'Der Verweis auf dieses Dokument existiert bereits.',
  'DocumentDetailReferencesProvider.create.successMessage': 'Dokumentverweise erfolgreich erstellt',
  'DocumentDetailReferencesProvider.delete.successMessage': 'Der Dokumentverweis wurde erfolgreich entfernt',
  'DocumentDetailPageLabels.editMode.tooltip': 'Beschriftungen bearbeiten',
  'DocumentDetailPageLinks.title': 'Links zu diesem Dokument',
  'DocumentDetailPageLinks.deleteSuccess': 'Dokumentverknüpfung erfolgreich gelöscht',
  'DocumentDetailPageLinks.confirmDelete': 'Sind Sie sicher, dass Sie diesen Link löschen möchten?',
  'DocumentDetailPageLinks.needsWriteToEdit': 'Um einen Link zu bearbeiten, benötigen Sie Schreibrechte für den Ordner mit dem Link',
  'DocumentDetailPageLinks.needsWriteToDelete': 'Um einen Link zu löschen, benötigen Sie Schreibrechte für den Ordner mit dem Link',
  'DocumentDetailPageLinks.addLink': 'Fügen Sie Links zu Dokumenten hinzu',
  'DocumentDetailPageManager.label': 'Neuer Dokumentenmanager',
  'DocumentDetailPageManager.tooltip': 'Dokumentenmanager ändern.',
  'DocumentDetailPageManager.tooltip.noPermission': 'Sie verfügen nicht über die erforderlichen Berechtigungen oder das Dokument ist reserviert.',
  'DocumentDetailPageState.tooltip': 'Dokumentstatus ändern',
  'DocumentDetailPageState.label': 'Neuer Dokumentstatus',
  'DocumentDetailPageDescription.placeholder': 'Beschreibung hinzufügen',
  'DocumentDetailPageWatch.tabTitle': 'Verfolgung',
  'DocumentDetailPageWatch.title': 'Dokumentenverfolgung',
  'DocumentDetailPageWatch.category.documentShare': 'Ein Dokument teilen',
  'DocumentDetailPageWatch.category.documentChange': 'Dokumentenänderung',
  'DocumentDetailPageWatch.category.processInclusion': 'Einbindung in den Prozess',
  'DocumentDownloadWithAnnotationsModal.title': 'Dokument mit Kommentaren herunterladen',
  'DocumentDownloadWithAnnotationsModal.progressMessage': 'Kommentare werden in das Dokument importiert',
  'DocumentOnlineEditor.createRevisionBusyReason': 'Neue Revisionstermine sind in Vorbereitung',
  'DocumentOnlineEditor.storingRevisionBusyReason': 'Revision wird gespeichert, bitte warten',
  'DocumentOnlineEditor.defaultModalTitle': 'Online-Redakteur',
  'DocumentOnlineEditor.button.saveAsRevision': 'Revision speichern',
  'DocumentOnlineEditor.fileLimitError': 'Die maximal unterstützte Dateigröße konnte nicht geladen werden.',
  'DocumentOnlineEditor.notSavedAlert': 'Das Dokument wird nicht gespeichert. Wenn Sie fortfahren, gehen die Änderungen verloren.',
  'DocumentOnlineEditor.oversizeWarning': 'Das Dokument überschreitet die maximal unterstützte Größe zum Speichern. Wenn Sie das Dokument nicht verkleinern, kann es nicht gespeichert werden.',
  'DocumentRequest.accept': 'Bestätigen',
  'DocumentRequest.accept.title': 'Bestätigen Sie das zugrunde liegende Dokument',
  'DocumentRequest.accept.confirm': 'Möchten Sie dieses Dokument wirklich bestätigen?',
  'DocumentRequest.confirmDocument': 'Bestätigen Sie das Dokument',
  'DocumentRequest.confirm.title': 'Bestätigen Sie das vorherige Dokument',
  'DocumentRequest.confirm.confirm': 'Wählen Sie dasselbe Dokument (einschließlich der Revision) wie in der vorherigen Instanz aus.',
  'DocumentRequest.refuse': 'Zurückkehren',
  'DocumentRequest.refuse.title': 'Senden Sie das Dokument zur Überarbeitung zurück',
  'DocumentRequest.refuse.reason': 'Kommentar',
  'DocumentRequest.notPublished': 'Es können nur veröffentlichte Dokumente ausgewählt werden',
  'DocumentRequest.assignDocument': 'Dokument zuordnen',
  'DocumentRequest.term': 'Begriff:',
  'DocumentRequest.term.title': 'Einreichungsfrist: {date}',
  'DocumentRequest.missingUser': 'Benutzer fehlt!',
  'DocumentRequest.suspendedUser': 'Deaktivierter Benutzer!',
  'DocumentRequest.noDocument': '(Dokument nicht hochgeladen)',
  'DocumentRequest.newDocument': 'Ein neues Dokument',
  'DocumentRequest.downloadDocument': 'Laden Sie das Dokument herunter',
  'DocumentRequest.editRequest': 'Anfrage bearbeiten',
  'DocumentRequest.removeRequest': 'Anfrage verwerfen',
  'DocumentRequest.removeRequest.confirm': 'Sind Sie sicher, dass Sie die Anfrage „{name}“ verwerfen möchten?',
  'DocumentRequest.state': 'Zustand',
  'DocumentRequest.state.ready': 'Zur Überprüfung',
  'DocumentRequest.state.ready.dateTooltip': 'Zuweisungsdatum: {date}',
  'DocumentRequest.state.missed': 'Nicht hochgeladen',
  'DocumentRequest.state.missed.dateTooltip': 'Erstellungsdatum der Anfrage: {date}',
  'DocumentRequest.state.accepted': 'Genehmigt',
  'DocumentRequest.state.accepted.dateTooltip': 'Datum der Genehmigung: {date}',
  'DocumentRequest.state.accepted.userTooltip': 'Vom Benutzer genehmigt',
  'DocumentRequest.state.refused': 'Zurückgegeben',
  'DocumentRequest.state.refused.dateTooltip': 'Rückgabedatum: {date}',
  'DocumentRequest.state.refused.userTooltip': 'Vom Benutzer zurückgegeben',
  'DocumentRequest.state.forConfirm': 'Zur Bestätigung',
  'DocumentRequest.state.forConfirm.dateTooltip': 'Das Datum, an dem die Anfrage erstellt wurde',
  'DocumentReservationLock.error.oldRevision': 'Die reservierte Dokumentrevision ist älter als die aktuelle Dokumentrevision und kann nicht bearbeitet werden.',
  'DocumentRevisionsGrid.initialRevision': 'Erste Überarbeitung',
  'DocumentRevisionsGrid.primaryFile': 'Primärdatei',
  'DocumentRevisionsGrid.revisionDiscar.modal.title': 'Sind Sie sicher, dass Sie die Revision verwerfen möchten?',
  'DocumentRevisionsGrid.revisionDiscar.modal.description': 'Achtung: Nur der Projektadministrator kann die Revision wiederherstellen.',
  'DocumentRevisionsGrid.revisionDiscar.modal.okButton': 'Beseitigen',
  'DocumentRevisionsGrid.revisionDiscar.modal.cancelButton': 'Stornieren',
  'DocumentRevisionsGrid.revisionDiscarded': 'Die Revision wurde verworfen',
  'DocumentRevisionsGrid.revisionDiscarded.label': 'Weggeworfen',
  'DocumentRevisionsGrid.revisionDiscarded.success': 'Überarbeitung erfolgreich verworfen',
  'DocumentRevisionsGrid.downloadWithAnnotations.tooltip': 'Mit Kommentaren herunterladen',
  'DocumentRevisionsGrid.downloadWithAttachments.error': 'Dateien konnten nicht heruntergeladen werden',
  'DocumentRevisionsGrid.rowMenu.downloadWithAttachments': 'Download inklusive Anhänge',
  'DocumentRevisionsGrid.rowMenu.addAttachments': 'Anhänge hinzufügen',
  'DocumentRevisionsGrid.rowMenu.addSignedDocument': 'Fügen Sie ein signiertes Dokument hinzu',
  'DocumentRevisionsGrid.rowMenu.discardRevision': 'Beseitigen',
  'DocumentRevisionsGrid.rowMenu.setAsCurrent': 'Als aktuell festlegen',
  'DocumentRevisionsGrid.rowMenu.setAsCurrent.currentRevision': 'Dies ist die aktuelle Revision',
  'DocumentRevisionsGrid.rowMenu.setAsCurrent.inWorkflow': 'Das Dokument befindet sich im Workflow',
  'DocumentRevisionsGrid.rowMenu.setAsCurrent.inCommentProcedure': 'Das Dokument befindet sich im Kommentarverfahren',
  'DocumentRevisionsGrid.rowMenu.editDocumentRevision': 'Öffnen Sie die Revision im Editor',
  'DocumentRevisionsGrid.rowMenu.externalMetadata': 'Externe Metadaten',
  'DocumentRevisionsGrid.rowMenu.restoreDiscarded': 'Stellen Sie eine verworfene Revision wieder her',
  'DocumentRevisionsGrid.confirm.restoreDiscarded.title': 'Eine verworfene Revision wiederherstellen?',
  'DocumentRevisionsGrid.confirm.restoreDiscarded.okButton': 'Wiederherstellen',
  'DocumentRevisionsGrid.signedDocument': 'Ein unterschriebenes Dokument',
  'DocumentRevisionsGrid.annotatedDocument': 'Dokument mit Kommentaren',
  'DocumentRevisionsGrid.confirm.restoreDiscarded.cancelButton': 'Stornieren',
  'DocumentRevisionsGrid.confirm.restoreDiscarded.success': 'Die Revision wurde erfolgreich fortgesetzt',
  'DocumentRevisionsGrid.secondaryFiles': 'Anhänge',
  'DocumentRevisionsGrid.metadata.aspeEsticon': 'AspeEsticon',
  'DocumentRevisionsGrid.metadata.openTree': 'OpenTree',
  'DocumentRevisionsGrid.metadata.user': 'Benutzer',
  'DocumentRevisionsGrid.metadata.firstName': 'Name',
  'DocumentRevisionsGrid.metadata.lastName': 'Nachname',
  'DocumentRevisionsGrid.metadata.attributeName': 'Der Name des Attributs',
  'DocumentRevisionsGrid.metadata.attributeValue': 'Wert',
  'DocumentSelect.directoryError': 'Dokumente aus Ordner {path} können nicht ausgewählt werden: {error}',
  'DocumentSelect.selectedInThisDirectory': 'Ausgewählte {count} Dokumente in diesem Verzeichnis.',
  'DocumentSelect.selectedInSubDirectories': '({count} Dokumente inklusive Unterverzeichnisse)',
  'DocumentSelect.disabledMessage.documentInThisWorkflow': 'Das Dokument ist in diesem Workflow bereits ausgewählt.',
  'DocumentSelect.disabledMessage.documentInWorkflow': 'Das Dokument wird im Workflow als genehmigtes Dokument ausgewählt.',
  'DocumentSelect.disabledMessage.documentInThisProcedure': 'Das Dokument ist in diesem Kommentarvorgang bereits ausgewählt.',
  'DocumentSelect.disabledMessage.documentInProcedure': 'Das Dokument wird in einem aktiven Kommentarvorgang ausgewählt.',
  'DocumentSelect.disabledMessage.notUnlocked': 'Es können nur entsperrte Dokumente ausgewählt werden.',
  'DocumentSelect.disabledMessage.notOwner': 'Sie müssen Administrator des Dokuments sein.',
  'DocumentSelect.disabledMessage.noWriteAccess': 'Sie müssen mindestens über Schreibrechte verfügen.',
  'DocumentSelect.disabledMessage.notShared': 'Es können nur freigegebene Dokumente ausgewählt werden.',
  'DocumentSelect.disabledMessage.notAllowedDocumentState': 'Es können nur Dokumente ausgewählt werden, deren Status durch die Workflow-Vorlage definiert ist.',
  'DocumentSelect.disabledMessage.partialEsticonDocument': 'Unterdokument kann nicht genehmigt werden.',
  'DocumentSelect.multierror.someDocumentsTitle': 'Beim Verarbeiten einiger Dokumente ist ein Fehler aufgetreten. Fortfahren?',
  'DocumentSelect.multierror.someDocumentsHaveError': '{count} der {total} ausgewählten Dokumente können nicht verarbeitet werden:',
  'DocumentSelect.multierror.continuePrompt': 'Möchten Sie die fehlerhaften Dokumente entfernen und die Aktion erneut durchführen?',
  'DocumentSelect.multierror.allDocumentsTitle': 'Bei der Verarbeitung jedes Dokuments ist ein Fehler aufgetreten',
  'DocumentSelect.multierror.allDocumentsHaveError': 'Keines der ausgewählten Dokumente ({count}) kann verarbeitet werden',
  'DocumentSelect.uploadDocuments': 'Laden Sie neue Dokumente hoch',
  'DocumentSelectAddAll.progress': 'Verarbeitete Ordner',
  'DocumentSelectAddAll.selectAll': 'Wählen Sie alle einschließlich Unterordner aus',
  'DocumentSelectAddAll.deselectAll': 'Entfernen Sie alles, einschließlich Unterordner',
  'DocumentSelectInfo.actualSelected': 'Letzte ausgewählte Aktion:',
  'DocumentSelectInfo.actualDeselected': 'Durch das letzte Ereignis aus der Auswahl entfernt:',
  'DocumentSelectInfo.selectedTotal': 'Insgesamt ausgewählt:',
  'DocumentSelectFormItem.selectDocuments': 'Dokumente auswählen',
  'DocumentSelectFormItem.selectDocument': 'Wählen Sie ein Dokument aus',
  'DocumentSelectFormItem.selectDocumentFromAspeHUB': 'Wählen Sie ein Dokument aus AspeHUB aus',
  'DocumentSelectFormItem.changeDocuments': 'Dokumente ändern',
  'DocumentSelectFormItem.changeDocument': 'Dokument ändern',
  'DownloadManager.UploadStatus.waitingToStart': 'Warten auf Upload',
  'DownloadManager.UploadStatus.initiating': 'Vorbereitung zur Aufnahme',
  'DownloadManager.UploadStatus.uploading': 'Aufnahme',
  'DownloadManager.UploadStatus.finished': 'Hochladen abgeschlossen',
  'DownloadManager.UploadStatus.interrupted': 'Aufnahme unterbrochen',
  'DownloadManager.UploadStatus.error': 'Fehler',
  'DownloadManager.UploadStatus.suspended': 'Warten auf Aufnahmeaufbau',
  'DownloadFileButton.tooltip': 'Laden Sie die Datei herunter',
  'DrawingActualOverview.title.toDate': 'am {date}',
  'DrawingActualOverview.char.column.constructionPrice': 'Preis für den Bau von Verkaufspreis',
  'DrawingActualOverview.char.column.invoiced': 'In Rechnung gestellt',
  'DrawingActualOverview.char.column.totalCost': 'Gesamtkosten',
  'DrawingActualOverview.char.column.income': 'Einnahmen',
  'DrawingActualOverview.char.column.totalCostActual': 'Gesamtkosten',
  'DrawingActualOverview.char.column.netIncomeDate': 'Wirtschaftsergebnis am',
  'DrawingActualOverview.char.column.invoicedOC': 'Abrechnung (Verkaufspreis)',
  'DrawingActualOverview.char.column.remaining': 'Verbleibend (KP)',
  'DrawingActualOverview.char.column.expectedNetIncome': 'Erwartetes Wirtschaftsergebnis',
  'DrawingActualReportDetail.income.name': 'Profitieren',
  'DrawingActualReportDetail.ownPerformance.name': 'Eigene Leistung',
  'DrawingActualReportDetail.uncategorized.name': 'Nicht zugewiesen',
  'DrawingActualReportDetail.uncategorized.name.tooltip': 'Alle Buchhaltungseinträge, die keinem Budget zugeordnet werden können',
  'DrawingActualReportDetail.unclassified.name': 'Nicht kategorisiert',
  'DrawingActualReportDetail.unclassified.name.tooltip': 'Nicht klassifizierte Tätigkeit im Haushalts- und Rechnungswesen',
  'DrawingActualReportDetail.unassignedWithCategory.name': 'Nicht mit einer Aktivität verknüpft',
  'DrawingActualReportDetail.unassignedWithCategory.name.tooltip': 'Klassifizierte Aktivität im Budget, ohne gültigen Lieferanten in der Buchhaltung',
  'DrawingActualReportDetail.unassignedWithoutCategory.name': 'Ungepaart und ohne Aktivität',
  'DrawingActualReportDetail.unassignedWithoutCategory.name.tooltip': 'Nicht klassifizierte Aktivitäten im Budget, ohne gültigen Lieferanten in der Buchhaltung',
  'DrawingActualReportDetail.budgetIncome.name': 'Einnahmen: Budget',
  'DrawingActualReportDetail.otherIncome.name': 'Andere',
  'DrawingActualReportDetail.headers.actual': 'Tatsache',
  'DrawingActualReportDetail.headers.actualCostOfOwnWork': 'Kosten der Eigenleistung',
  'DrawingActualReportDetail.headers.actualTotalCost': 'Gesamtkosten',
  'DrawingActualReportDetail.headers.category': 'Kategorie',
  'DrawingActualReportDetail.headers.changeOrder': 'ÄdB',
  'DrawingActualReportDetail.headers.coeficient': 'Koeffizient',
  'DrawingActualReportDetail.headers.contractuality': 'Vertragsmäßigkeit (KP)',
  'DrawingActualReportDetail.headers.costOfOwnWork': 'Kosten der Eigenleistung',
  'DrawingActualReportDetail.headers.costOfSubcontracting': 'Kosten für Unteraufträge',
  'DrawingActualReportDetail.headers.drawing': 'Pumpen',
  'DrawingActualReportDetail.headers.drawingTotalCost': 'Gesamtkosten',
  'DrawingActualReportDetail.headers.expectedNetIncome': 'Erwartetes Wirtschaftsergebnis',
  'DrawingActualReportDetail.headers.expectedResult': 'Erwartetes Ergebnis',
  'DrawingActualReportDetail.headers.netIncomeToDate': 'Wirtschaftsergebnis am',
  'DrawingActualReportDetail.headers.income': 'Einnahmen',
  'DrawingActualReportDetail.headers.internalChangeOrder': 'ÄdB-intern',
  'DrawingActualReportDetail.headers.investorContract': 'Schnäppchen',
  'DrawingActualReportDetail.headers.invoiced': 'In Rechnung gestellt',
  'DrawingActualReportDetail.headers.invoicedSP': 'Abrechnung (Verkaufspreis)',
  'DrawingActualReportDetail.headers.margin': 'Marge',
  'DrawingActualReportDetail.headers.ownWork': 'Eigene Leistung',
  'DrawingActualReportDetail.headers.priceCfW': 'WV-Preis',
  'DrawingActualReportDetail.headers.remaining': 'Es bleibt',
  'DrawingActualReportDetail.headers.remainingCP': 'Verbleibend (KP)',
  'DrawingActualReportDetail.headers.subcontracting': 'Unteraufträge',
  'DrawingActualReportDetail.headers.supplierInvoices': 'Lieferantenrechnungen',
  'DrawingActualReportDetail.headers.totalPrice': 'Baukosten (Verkaufspreis)',
  'DrawingActualReportDetail.reportDisplayMode.simplified': 'Vereinfacht',
  'DrawingActualReportDetail.reportDisplayMode.full': 'Vergrößert',
  'DrawingActualReportDetail.reportError.noData': 'Daten nicht verfügbar',
  'DropArea.hint': 'Ziehen Sie die Dateien hierher',
  'DropArea.disabled': 'Dateien können nicht hochgeladen werden',
  'DocumentDetailPage.dropFilesError': 'Es kann nur eine Datei als neue Revision hochgeladen werden. Bitte laden Sie nur eine Datei hoch.',
  'DocumentDetailPage.workflowList.responsible person': 'Verantwortliche Person',
  'DocumentDetailPage.workflowList.approvalDocument': 'Genehmigtes Dokument',
  'DocumentDetailPage.workflowList.backgroundDocument': 'Hintergrunddokument',
  'DocumentDetailPage.workflowList.typeFilterLabel': 'Typ',
  'DocumentDetailPage.workflowList.typeApproval': 'Genehmigt',
  'DocumentDetailPage.workflowList.typeBackground': 'Zugrundeliegend',
  'ERPDataImportButtonComponent.import.success': 'Daten aus dem verbundenen Projekt erfolgreich importiert',
  'EstiCategoryEnum.Stavba': 'Budget',
  'EstiCategoryEnum.Cerpani': 'Pumpen',
  'EstiCategoryEnum.CerpaniSdruzeni': 'Kombinierte Zeichnung',
  'EstiCategoryEnum.Fakturace': 'Abrechnung',
  'EstiCategoryEnum.FakturaSdruzeni': 'Kombinierte Rechnungsstellung',
  'EstiCategoryEnum.ZBV': 'ÄdB',
  'EstiCategoryEnum.Harmonogram': 'Zeitplan',
  'EsticonContainer.noProject.title': 'Dem Projekt ist kein AspeEsticon-Projekt zugeordnet.',
  'EsticonDetail.settingsSaveSuccess': 'Einstellungen erfolgreich gespeichert',
  'EsticonDetailDirectory.folders': 'Aus Esticon zu importierende Ordner',
  'EsticonDetailDirectory.targetPath.title': 'Der Zielordner',
  'EsticonDetailDirectory.pathMask': 'Die Maske der Reise',
  'EsticonDetailDirectory.fileMask': 'Dokumenttitelmaske',
  'EsticonDetailDirectory.addMaskDirectory': 'Fügen Sie ein Pfadelement hinzu',
  'EsticonFirmList.loadingError': 'Fehler beim Laden von AspeEsticon-Unternehmen. Einige konnten nicht geladen werden.',
  'EstiConImportValidationIssueType.Precerpani': 'Der Import von Erkennungsprotokoll führt zu einer Überziehung.',
  'EstiConImportValidationIssueType.MenepraceDoMinusu': 'Weniger Arbeit, die dazu führt, dass die Gesamtmenge des Artikels überschritten wird.',
  'EstiConImportValidationIssueType.ChybiZbvProPolozky': 'Die importierten Daten enthalten ein ÄdB-Element ohne entsprechende ÄdB im Projekt.',
  'EstiConImportValidationIssueType.ChybiZpProCerpani': 'Die importierten Daten enthalten ein Element ohne entsprechendes Erkennungsprotokoll für das Projekt.',
  'EstiConImportValidationIssueType.ChybiZbvProZp': 'Die importierten Daten enthalten Erkennungsprotokoll ohne entsprechende ÄdB im Projekt.',
  'EstiConImportValidationIssueType.ChybiZbvProZpSdruzeny': 'Die importierten Daten enthalten ein kombiniertes Erkennungsprotokoll ohne entsprechende ÄdB zum Projekt.',
  'EstiConImportValidationIssueType.ChybiFakturaProZp': 'Die importierten Daten enthalten ein Erkennungsprotokoll ohne entsprechende Rg.',
  'EstiConImportValidationIssueType.ChybiZpSdruzenyProZp': 'Die importierten Daten enthalten ein Erkennungsprotokoll ohne entsprechendes zugehöriges Erkennungsprotokoll.',
  'EstiConImportValidationIssueType.ChybiFakturaSdruzenaProFaktura': 'Importierte Daten enthalten Rg. ohne entsprechende zugeordnete Rg.',
  'EstiConImportValidationIssueType.ChybiFakturaProRozpisFaktury': 'Importierte Daten enthalten Rg. ohne entsprechende Backup-Rg.',
  'EstiConImportValidationIssueType.ChybiObjektProPolozkaFaktury': 'Die importierten Daten enthalten Rg. ohne entsprechende Objekte im Projekt.',
  'EstiConImportValidationIssueType.ChybiPartnerZFakturyNaZP': 'Die Rechnung enthält einen Partner, der fehlt oder vom Ermittlungsprotokoll abweicht',
  'EstiConImportValidationIssueType.ChybiClenoveNaSdruzeneStavbe': 'Im Gemeinschaftsgebäude befinden sich keine Vereinsmitglieder',
  'EstiConImportValidationIssueType.ChybiSmlDodatekProZbv': 'Importierte Daten enthalten ÄdB ohne entsprechenden Vertragszusatz.',
  'EstiConImportValidationIssueType.MazaniZPPouziteNaFakture': 'Beim Import wird die auf der Rechnung verwendetes Erkennungsprotokoll gelöscht.',
  'EstiConImportValidationIssueType.MazaniZPPouziteNaSpolZP': 'Beim Import wird das Erkennungsprotokoll gelöscht, das auf dem gemeinsamen Erkennungsprotokoll verwendet wird.',
  'EstiConImportValidationIssueType.MazaniFakturyPouziteNaSpolFakture': 'Beim Import wird Rg. gelöscht, der auf dem gemeinsamen Rg. verwendet wird.',
  'EstiConImportValidationIssueType.RozdilneZaokrouhleniCena': 'Importierte Daten enthalten Preisrundungen auf eine andere Anzahl von Dezimalstellen.',
  'EstiConImportValidationIssueType.RozdilneZaokrouhleniJednotkovaCena': 'Die importierten Daten enthalten eine Rundung des Stückpreises auf eine andere Anzahl von Dezimalstellen.',
  'EstiConImportValidationIssueType.RozdilneZaokrouhleniMnozstvi': 'Importierte Daten enthalten auf eine andere Anzahl von Dezimalstellen gerundete Mengen.',
  'EstiConImportValidationIssueType.ViceNezJednoCerpaniRozpisuStejnymZP': 'Die importierten Daten enthalten mehrere Zeichnungen des Artikels um ein Erkennungsprotokoll.',
  'EstiConImportValidationIssueType.RozdilneVypoctySDph': 'Die importierten Daten enthalten eine andere Halte- oder Halteeinstellung als das Projekt.',
  'EstiConImportValidationIssueType.VyssiPocetAktivit': 'Die importierten Daten enthalten eine höhere Anzahl an CEF-Aktivitäten.',
  'EstiConImportValidationIssueType.NizsiPocetAktivit': 'Die importierten Daten enthalten eine geringere Anzahl von CEF-Aktivitäten.',
  'EstiConImportValidationIssueType.DuplicateInSource': 'Importierte Daten enthalten doppelte Datensätze.',
  'EstiConImportValidationIssueType.DuplicateInDestination': 'Die Daten im Projekt enthalten doppelte Datensätze.',
  'EstiConImportValidationIssueType.ChybiVCili': 'Importierte Daten enthalten Datensätze, die nicht im Projekt enthalten sind.',
  'EstiConImportValidationIssueType.RozdilnaStruktura': 'Die importierten Daten enthalten eine andere Objektstruktur als im Zielprojekt.',
  'EstiConImportValidationIssueType.DuplicitniPolozky': 'Das Projekt enthält doppelte Elemente.',
  'EstiConImportValidationIssueType.SpatnyFormatSouboru': 'Die importierte Datei enthält nicht die richtigen Objektdaten.',
  'EstiConImportValidationIssueType.SpatnyFormatSouboru.WipReport': 'Die importierte Datei enthält keine Erkennungsprotokoll-Daten.',
  'EstiConImportValidationIssueType.SpatnyFormatSouboru.ChangeOrder': 'Die importierte Datei enthält keine ÄdB-Daten.',
  'EstiConImportValidationIssueType.SpatnyFormatSouboru.Invoice': 'Die importierte Datei enthält keine Rg.-Daten.',
  'EstiConImportValidationIssueType.SpatnyFormatSouboru.Schedule': 'Die importierte Datei enthält keine HMG-Daten.',
  'EstiConImportValidationIssueType.NevalidniData': 'Die importierte Datei enthält ungültige Daten.',
  'EstiConImportValidationIssueType.KontrolaXC4': 'Die importierte Datei enthält ungültige Daten im XC4-Format.',
  'EstiConImportValidationIssueType.OdeslanoNaHub': 'Die importierte Datei wurde bereits an AspeHub gesendet.',
  'EstiConImportValidationIssueType.NesparovaneStavebniDily': 'Die importierte Datei enthält ungepaarte Gebäudeteile.',
  'EstiConImportValidationIssueType.NeznamaChyba': 'Es ist ein unbekannter Fehler aufgetreten.',
  'EstiConImportValidationIssueType.ZbvNeniOdeslanoNaHub': 'ÄdB wird nicht an AspeHub gesendet.',
  'EstiConImportValidationIssueType.ChybiDodavatelClenskeFaktury': 'Der Rechnungslieferant des Mitglieds fehlt.',
  'EstiConImportValidationIssueType.NelzeNaparovatDodavateleFaktury': 'Rechnungslieferanten können nicht abgeglichen werden.',
  'EstiConImportValidationIssueType.DuplicitniCsNazev': 'Doppelter Name des Preissystems.',
  'EstiConImportValidationIssueType.DuplicitniCsVarianta': 'Eine doppelte Variante des Preissystems.',
  'EstiConImportValidationIssueType.KalkulaceDoNekalkulace': 'Die Daten im Import enthalten Berechnungen. Durch den Import in ein nicht berechnetes Gebäude werden die Berechnungen gelöscht.',
  'EstiConImportValidationIssueType.RozdilnaTvorbaStruktury': 'Das Gebäude, in das Sie Daten importieren, weist eine andere Strukturerstellung auf. Einfuhr verboten.',
  'EstiConImportValidationIssueType.HmgRozdilnaStruktura': 'Unterschiedliche Zeitplanstruktur.',
  'EstiConImportValidationIssueType.ExistingZpHasFaktura': 'Für den vorliegenden Untersuchungsbericht wird eine Rechnung ausgestellt.',
  'EstiConImportValidationIssueType.ZpIsApproved': 'Das Entdeckungsprotokoll ist genehmigt.',
  'EstiConImportValidationIssueType.ExistingZbvHasZp': 'Die bestehende ÄdB wurde bereits ausgelost.',
  'EstiConImportValidationIssueType.NeshodneValorizace': 'Unterschiedliche Bewertungen zum Projekt und zum importierten ÄdB',
  'EstiConImportValidationIssueType.NeshodnyTypValorizace': 'Verschiedene Arten der Valorisierung des Projekts und des importierten ÄdB',
  'EstiConImportValidationIssueType.NeshodneObdobiValorizace': 'Unterschiedliche Valorisierungszeiträume für das Projekt und importierte ÄdB',
  'EstiConImportValidationIssueType.ChybiSkupinyZbv': 'ÄdB-Gruppen fehlen',
  'EstiConImportValidationIssueType.StavbaNepouzivaSkupinyZbv': 'Die Konstruktion verwendet keine ÄdB-Gruppen',
  'EstiConImportValidationIssueType.NeshodnaMena': 'Für Datenbanken ist eine andere Standardwährung festgelegt',
  'EstiConImportValidationBlockingError.UnableToImport': 'Import nicht möglich.',
  'EsticonMaskInput.error.empty': 'Die Maske darf nicht leer sein',
  'EsticonMaskInput.error.invalidCharacters': 'Ungültige Zeichen im Namen /: *? "" |',
  'EsticonMaskInput.error.invalidTagFormat': 'Fehlerhaftes Tag',
  'EsticonMaskInput.error.invalidTag': 'Ungültiger Tag: {tag}',
  'EsticonMaskInput.templateDescription.ShortYear': 'Verkürztes Jahr (z. B. 21)',
  'EsticonMaskInput.templateDescription.Year': 'Ganzes Jahr (z. B. 2021)',
  'EsticonMaskInput.templateDescription.ShortMonth': 'Abgekürzter Monat (z. B. 4)',
  'EsticonMaskInput.templateDescription.Month': 'Vollmond (z. B. 04)',
  'EsticonMaskInput.templateDescription.Name': 'Name',
  'EsticonMaskInput.templateDescription.Number': 'Nummer',
  'EsticonMaskInput.templateDescription.Code': 'Markieren',
  'EsticonMaskInput.templateDescription.ObjectNumber': 'Objektcode/Markierung',
  'EsticonMaskInput.templateDescription.ObjectPath': 'Objektpfad',
  'EsticonMaskInput.templateDescription.Cerpani.Code': 'Protokollnummer',
  'EsticonMaskInput.templateDescription.Harmonogram.Code': 'Überarbeitung/Markierung planen',
  'EsticonContainer.noProject.goToSettings': 'Gehen Sie zu den Einstellungen',
  'EstiConDataAnalyzeResult.Success': 'Erfolg',
  'EstiConDataAnalyzeResult.FileReadError': 'Beim Lesen der Datei ist ein Fehler aufgetreten',
  'EstiConDataAnalyzeResult.IncorrectType': 'Die angehängte Datei hat nicht das richtige Format. Import ist nicht möglich',
  'EstiConDataAnalyzeResult.InvalidData': 'Die Datei enthält ungültige Daten',
  'EstiConDataAnalyzeResult.PairingError': 'Dateieinträge konnten nicht abgeglichen werden',
  'EstiConDataAnalyzeResult.Informative': 'Die angehängte Datei kann Fehler enthalten',
  'EstiConDataAnalyzeResult.NoData': 'Die Datei enthält keine Daten',
  'EsticonProjectTransferToControlDatabaseModal.title': 'Projektübertragung in die Steuerungsdatenbank',
  'EsticonProjectTransferToControlDatabaseModal.succes': 'Die Übertragung des Projekts in die Steuerungsdatenbank war erfolgreich',
  'EsticonProjectTransferToControlDatabaseModal.error': 'Das Projekt konnte nicht in die Steuerungsdatenbank übertragen werden',
  'EstiConSaveResult.Success': 'Erfolg',
  'EstiConSaveResult.DuplicateRecord': 'Der Datensatz existiert bereits',
  'EstiConSaveResult.NoData': 'Keine Daten',
  'EstiConSaveResult.DataNotSaveable': 'Daten können nicht gespeichert werden',
  'EstiConSaveResult.DataNotValid': 'Die Daten sind ungültig',
  'EstiConSaveResult.DataReadonly': 'Die Daten sind schreibgeschützt',
  'EstiConSaveResult.SentToHub': 'Gepostet auf AspeHub',
  'EstiConSaveResult.SqlError': 'Die importierte Datei enthält Daten, die nicht auf AspeEsticon hochgeladen werden können.',
  'EstiConSaveResult.IOException': 'Fehler beim Lesen oder Schreiben von Daten',
  'EstiConSaveResult.UnspecifiedError': 'Unbekannter Fehler',
  'EstiConSaveResult.PartialSuccess': 'Teilerfolg',
  'EstiConSaveResult.GeneralError': 'Allgemeiner Fehler',
  'EsticonObjectLink.disabledByEsticonLink': 'Das Dokument verfügt über keinen aktiven Link zum Esticon-Objekt',
  'esticonErrors.importedDirectoryNotSet': 'Der Ordner zum Importieren von Dokumenten aus Esticon ist nicht festgelegt. Bitte wenden Sie sich an den Projektadministrator.',
  'ErrorBoundary.title': 'Hoppla! Etwas ist schiefgelaufen ??',
  'ExtensionFilter.placeholder': 'Erweiterung (docx, pdf, ...)',
  'ExtensionFilter.withoutExtension': 'Kein Suffix',
  'FavoriteButton.saving': 'Sparen...',
  'FavoriteButton.set': 'Zu Favoriten hinzufügen',
  'FavoriteButton.addSuccess': 'Zu den Favoriten hinzugefügt.',
  'FavoriteButton.success': 'Ausgewählte Dokumente wurden zu den Favoriten hinzugefügt.',
  'FavoriteButton.removeSuccess': 'Aus den Favoriten entfernt.',
  'FavoriteButton.unset': 'Aus Favoriten entfernen',
  'FavoriteDocumentsPage.entries': `{total, plural,
    =0 {Keine Lieblingsdokumente gefunden.}
    one {Ein Lieblingsdokument gefunden.}
    few {# Lieblingsdokumente gefunden.}
    other {# Lieblingsdokumente gefunden.}
    }`,
  'FavoriteDocumentsPage.loading': 'Lieblingsdokumente werden geladen...',
  'FavoriteSwitch.hideSelected': 'Nur Lieblingsordner anzeigen',
  'FavoriteSwitch.showSelected': 'Alle Ordner anzeigen',
  'FileViewer.processing': 'Eine Vorschau des Dokuments wird derzeit verarbeitet',
  'FileViewer.reloadDerivateButton': 'Laden Sie die Vorschau erneut',
  'FileViewer.rerenderingDerivatesMessage': 'Die Regeneration läuft oder ist nicht möglich',
  'FileViewer.rerenderingDerivatesTooltip': 'Vorschau neu generieren',
  'FileViewer.rerenderingDerivatesButton': 'Vorschau neu generieren',
  'FileViewer.rerenderingDerivateButton': 'Regenerieren',
  'FileViewer.rerenderingProcessing': 'Verarbeitung...',
  'FileViewer.NoPreviewAvailable': 'Für diesen Dateityp ist keine Vorschau verfügbar',
  'FileViewer.UnsupportedMediaType': 'Nicht unterstützter Dateityp',
  'FileViewer.UnsupportedPDFBrowser': 'Der Browser unterstützt das Einbetten einer PDF-Datei in eine Seite nicht. Klicken Sie hier, um das PDF in einem neuen Fenster anzuzeigen.',
  'FinancialOverviewByUnitReport.noUnit.title': 'Wählen Sie eine Abteilung aus, um den Bericht anzuzeigen.',
  'FinancialOverviewByUnitReport.column.sign': 'Markieren',
  'FinancialOverviewByUnitReport.column.name': 'Name',
  'FinancialOverviewByUnitReport.column.unit': 'Einheit',
  'FinancialOverviewByUnitReport.column.investor': 'Investor',
  'FinancialOverviewByUnitReport.column.state': 'Zustand',
  'FinancialOverviewByUnitReport.column.startDate': 'Beginn',
  'FinancialOverviewByUnitReport.column.endDate': 'Fertigstellung',
  'FinancialOverviewByUnitReport.column.cost': 'Preis',
  'FinancialOverviewByUnitReport.column.invoicing': 'Abrechnung',
  'FinancialOverviewByUnitReport.column.invoicedSoFar': 'Noch',
  'FinancialOverviewByUnitReport.column.invoiceRemaining': 'Es bleibt',
  'FinancialOverviewByUnitReport.column.costs': 'Kosten',
  'FinancialOverviewByUnitReport.column.costSoFar': 'Noch',
  'FinancialOverviewByUnitReport.column.costRemaining': 'Es bleibt',
  'FinancialOverviewByUnitReport.column.costTotal': 'In Summe',
  'FinancialOverviewByUnitReport.column.profit': 'Gewinn/Verlust',
  'FinancialOverviewByUnitReport.column.coefficient': 'ZU',
  'FinancialOverviewByUnitReport.column.SoW': 'SOD',
  'FinancialOverviewByUnitReport.column.changeOrder': 'ÄdB',
  'FinancialOverviewByUnitReport.column.invoice': 'Zur Rechnungsstellung',
  'FinancialOverviewByUnitReport.column.proposed': 'Entwurf',
  'FinancialOverviewByUnitReport.column.internal': 'Intern',
  'FinancialOverviewByUnitReport.column.ownPerformance': 'Eigene Leistung',
  'FinancialOverviewByUnitReport.column.subcontracts': 'Unteraufträge',
  'FinancialOverviewByUnitReport.column.LinkToProject': 'Link zum Projekt',
  'FinancialOverviewByUnitReport.phase.Dokonceno': 'Vollendet',
  'FinancialOverviewByUnitReport.phase.Nabidka': 'Angebot',
  'FinancialOverviewByUnitReport.phase.Neziskano': 'Nicht erhalten',
  'FinancialOverviewByUnitReport.phase.Priprava': 'Vorbereitung',
  'FinancialOverviewByUnitReport.phase.Realizace': 'Realisierung',
  'FinancialOverviewByUnitReport.title': 'Produktionsplan',
  'FinancialOverviewByUnitReport.projectLink.text': 'Gehen Sie zum Projekt',
  'FormProcessTaskTemplate.title': 'Formularprozessvorlagen',
  'FormProcessVoteTypeEnum.notVoted': 'Er hat nicht gewählt',
  'FormProcessVoteTypeEnum.processed': 'Verarbeitet',
  'FormProcessVoteTypeEnum.notProcessed': 'Unbearbeitet',
  'FormProcessVoteTypeEnum.approved': 'Genehmigt',
  'FormProcessVoteTypeEnum.rejected': 'Abgelehnt',
  'FormProcessVoteTypeEnum.forwarded': 'Weitergeleitet',
  'FormProcessLayout.error.noLayout': 'Für diesen Formularabschnitt wurde kein Inhaltslayout gefunden',
  'FormProcessListItem.taskCount': `{count, plural,
    =0 {# Aufgaben}
    one {1 Aufgabe}
    few {# Aufgaben}
    other {# Aufgaben}
    }`,
  'FormProcessActorTypeEnum.submitter': 'Kommentator',
  'FormProcessActorTypeEnum.guarantor': 'Top-Garant',
  'FormProcessActorTypeEnum.expertGuarantor': 'Professioneller Bürge',
  'FormProcessActorTypeEnum.processor': 'Prozessor',
  'FormProcessActorTypeEnum.his': 'SEIN',
  'FormProcessTaskAddForm.input.template': 'Formularvorlage',
  'FormProcessTaskAddForm.input.entityType': 'Der Typ des auszuführenden Objekts',
  'FormProcessTaskAddForm.input.startingDocument': 'Dokument starten',
  'FormProcessTaskAddForm.input.startingDirectory': 'Boot-Ordner',
  'FormProcessTaskAddForm.input.submitterUser': 'Kommentator Benutzer',
  'FormProcessTaskAddForm.input.guarantorUser': 'Top-Garant',
  'FormProcessTaskAddForm.input.expertGuarantorUser': 'Professioneller Bürge',
  'FormProcessTaskAddForm.input.processorRoleUserId': 'Prozessor',
  'FormProcessTaskAddForm.input.hisRoleUserId': 'SEIN',
  'FormProcessTaskAddModal.title': 'Beginn eines neuen Jobs in Bearbeitung',
  'FormProcessTaskAddModal.form.name': 'Der Name des Prozesses',
  'FormProcessTaskAddModal.form.objectType': 'Objekttyp',
  'FormProcessTaskAddModalButton.title': 'Neue Aufgabe',
  'FormProcessTaskDetailFormModal.message.taskInitialized': 'Aufgabe bereit',
  'FormProcessTaskDetailFormModal.title': 'Formularaufgabe',
  'FormProcessTaskDetailVoteFormModal.title': '{actorName} – Meinungseintrag',
  'FormProcessTaskDetailOrgUnitChangeButton.warning': 'Der ausgewählte Benutzer der Organisation ist dem betreffenden Projekt nicht zugeordnet',
  'FormProcessTaskDetailOrgUnitChangeButton.description': 'Die Zuordnung können Sie in den Projekteinstellungen vornehmen',
  'FormProcessTaskDetailOrgUnitChangeButton.clickToClose': '(zum Schließen klicken)',
  'FormProcessTaskDetailOrgUnitChangeModal.title': 'Eine Rolle delegieren',
  'ProjectsInRealizationFiltersFormItem.missingValues': 'Alle Pflichtdaten zum Laden des Berichts sind nicht ausgefüllt',
  'ProjectsInRealizationOverviewReport.reportSelection.columnConfiguration': 'Angezeigte Werte',
  'ProjectsInRealizationOverviewReport.reportSelection.title': 'Berichtseinstellungen',
  'ProjectsInRealizationOverviewReport.reportSelection.reportingPeriod': 'Überwachter Zeitraum',
  'ProjectsInRealizationOverviewReport.reportSelection.date': 'Fertiggestellte Gebäude ab',
  'ProjectsInRealizationOverviewReport.reportSelection.esticonApp': 'AspeEsticon applikation',
  'ProjectsInRealizationOverviewReport.reportSelection.esticonFirm': 'Die im Bericht angezeigte Abteilung',
  'ProjectsInRealizationOverviewReport.reportSelection.view': 'Sicht',
  'ProjectsInRealizationOverviewReport.reportSelection.required.forYear': 'Das Jahr, für das Daten angezeigt werden, ist obligatorisch',
  'ProjectsInRealizationOverviewReport.reportSelection.required.unit': 'Das Berichtsformat ist obligatorisch',
  'ProjectsInRealizationOverviewReport.reportSelection.required.date': 'Das Datum des Berichts ist obligatorisch',
  'ProjectsInRealizationOverviewReport.reportSelection.required.esticonApp': 'Die AspeEsticon applikation ist für den Bericht obligatorisch',
  'ProjectsInRealizationOverviewReport.column.io': 'Über + I',
  'ProjectsInRealizationOverviewReport.column.unit': 'Wettrennen',
  'ProjectsInRealizationOverviewReport.column.supervisor': 'TDS',
  'ProjectsInRealizationOverviewReport.column.subsidies': 'Subvention',
  'ProjectsInRealizationOverviewReport.column.projectName': 'Aktionsname',
  'ProjectsInRealizationOverviewReport.column.projectSign': 'Aktion Nr',
  'ProjectsInRealizationOverviewReport.column.supplier': 'Anbieter',
  'ProjectsInRealizationOverviewReport.column.state': 'WV (Datum)',
  'ProjectsInRealizationOverviewReport.column.startDate': 'Beginn',
  'ProjectsInRealizationOverviewReport.column.endDate': 'Fertigstellung',
  'ProjectsInRealizationOverviewReport.column.cost': 'Preis',
  'ProjectsInRealizationOverviewReport.column.costI': 'Preis - I',
  'ProjectsInRealizationOverviewReport.column.costO': 'Preis - O',
  'ProjectsInRealizationOverviewReport.column.performedWorksTotal': 'Aufgebaute Summe',
  'ProjectsInRealizationOverviewReport.column.performedWorksTotalI': 'Baubarkeit I – total',
  'ProjectsInRealizationOverviewReport.column.performedWorksTotalO': 'Baubarkeit O – insgesamt',
  'ProjectsInRealizationOverviewReport.column.performedWorksYear': 'Gebaut {year}',
  'ProjectsInRealizationOverviewReport.column.performedWorksYearI': 'Vollständigkeit I – {year}',
  'ProjectsInRealizationOverviewReport.column.performedWorksYearO': 'Abgeschlossen O – {year}',
  'ProjectsInRealizationOverviewReport.column.costTotal': 'Gesamt',
  'ProjectsInRealizationOverviewReport.column.unassignedYear': 'Nicht kategorisiert',
  'ProjectsInRealizationOverviewReport.column.note': 'Notiz',
  'ProjectsInRealizationOverviewReport.column.planI': 'Plan {year} – I',
  'ProjectsInRealizationOverviewReport.column.planO': 'Plan {year} – O',
  'ProjectsInRealizationOverviewReport.column.updateDate': 'Letzte Bearbeitung',
  'ProjectsInRealizationOverviewReport.filter.type.empty': 'Kein Typ',
  'ProjectsInRealizationOverviewReport.filter.type.i': 'UND',
  'ProjectsInRealizationOverviewReport.filter.type.o': 'O',
  'ProjectsInRealizationOverviewReport.filter.type.oi': 'Über + I',
  'ProjectsInRealizationOverviewReport.filter.subsidies.empty': 'Kein Zuschuss',
  'ProjectsInRealizationOverviewReport.notesSaved': 'Projektnotizen gespeichert',
  'ProjectsInRealizationOverviewReport.title': 'Im Bau befindliche Gebäude {year} (in Tausend CZK) am {date}',
  'ProjectsInRealizationOverviewReport.views.total': 'Gesamtbauvolumen im WV {year}',
  'ProjectsInRealizationOverviewReport.views.onlyI': 'Investition – Bauvolumen im Rahmen von WV {year}',
  'ProjectsInRealizationOverviewReport.views.onlyO': 'Reparaturen – Bauvolumen unter WV {year}',
  'ProjectsInRealizationPairingNotesPreviewModal.title': 'Vorschau gespeicherter Notizen',
  'ProjectsInRealizationPairingNotesModal.title': 'Fix für ungepaarte Notizen',
  'ProjectsInRealizationPairingNotesModal.mergeWarning.title': 'Vorhandene Notizen zusammenführen',
  'ProjectsInRealizationPairingNotesModal.mergeWarning.description': 'Für das ausgewählte EstiCon-Projekt sind bereits Notizen erstellt. Notizen werden zusammengeführt, wenn Sie fortfahren.',
  'ProjectsInRealizationPairingNotesModal.mergeWarning.continue': 'Weitermachen',
  'ProjectsInRealizationPairingNotesModal.labels.noteList': 'Kommentar',
  'ProjectsInRealizationPairingNotesModal.labels.projectName': 'Projektname',
  'ProjectsInRealizationPairingNotesModal.labels.sign': 'Markieren',
  'ProjectsInRealizationPairingNotesModal.labels.startDate': 'Startdatum',
  'ProjectsInRealizationPairingNotesModal.labels.pairTo': 'Paar an',
  'ProjectsInRealizationPairingNotes.selectProjectName': '{name} (Übereinstimmung mit {matchCount} %)',
  'ProjectsInRealizationReportConfigurationColumnForm.addColumn': 'Fügen Sie eine neue Spalte hinzu',
  'ProjectsInRealizationReportConfigurationColumnForm.defaultColumnName': 'Neue Kolumne',
  'ProjectsInRealizationReportConfigurationColumnForm.columnName.name': 'Spaltenname',
  'ProjectsInRealizationReportConfigurationColumnForm.columnName.value': 'Wert',
  'ProjectsInRealizationReportConfigurationColumnForm.columnName.unitOrder': 'Reihenfolge der Einheiten',
  'ProjectsInRealizationReportConfigurationColumnForm.columnType.constant': 'Konstante',
  'ProjectsInRealizationReportConfigurationColumnForm.columnType.property': 'Aus dem Bericht',
  'ProjectsInRealizationReportConfigurationColumnForm.columnDecimalUnit.units': 'Einheiten',
  'ProjectsInRealizationReportConfigurationColumnForm.columnDecimalUnit.thousands': 'Tausend',
  'ProjectsInRealizationReportConfigurationColumnForm.columnDecimalUnit.millions': 'Millionen',
  'ProjectsInRealizationReportConfigurationColumnForm.columnDecimalUnit.billions': 'Milliarden',
  'ProjectsInRealisationSavedViewsModal.title': 'Gespeicherte Ansichten',
  'ProjectsInRealisationSavedViewsModal.newView.title': 'Aktuelle Ansicht als neu speichern',
  'ProjectsInRealisationSavedViewsModal.button.rename.tooltip': 'Umbenennen',
  'ProjectsInRealisationSavedViewsModal.delete.title': 'Ansicht löschen',
  'ProjectsInRealisationSavedViewsModal.delete.content': 'Die Ansicht wird dauerhaft gelöscht. Weitermachen?',
  'ProjectsInRealisationSavedViews.workingView.name': '*Nicht gespeichert',
  'ProjectsInRealisationNoteModal.title': 'Projektnotizen',
  'ProjectsInRealisationNoteModal.delete.title': 'Notiz löschen',
  'ProjectsInRealisationNoteModal.delete.content': 'Die Notiz wird dauerhaft gelöscht. Weitermachen?',
  'ProjectsInRealisationNoteModal.error.save': 'Fehler beim Speichern der Notizen. Änderungen konnten nicht gespeichert werden.',
  'ProjectsInRealisationNote.source.AspeHub': 'Beachten Sie AspeHub',
  'ProjectsInRealisationNote.source.AspeEsticon': 'Hinweis von AspeEsticon',
  'ProjectsInRealizationPohReportSettingsForm.defaultDate': 'Standarddatum der Berichtsimplementierung',
  'ProjectsInRealizationPohReportSettingsForm.completedRealizationColor': 'Untermalung fertiggestellter Gebäude',
  'ProjectsInRealizationPohReportSettingsForm.unitColors': 'Verfärbung von Formationen',
  'ProjectsInRealizationPohReport.export.fileName': 'Overview_of_project_in_implementation_{date}.{extension}',
  'ProjectsInRealizationPohReport.Export.pdf': 'Als PDF exportieren',
  'ProjectsInRealizationPohReport.Export.xlsx': 'Nach Excel exportieren',
  'ReportUnitColorAssign.error.unitNotFound': 'Einheit nicht gefunden',
  'ReportUnitColorAssign.addNewUnit': 'Abteilung hinzufügen',
  'ReportUnitColorAssign.selectEsticonConn': 'Auswahl der AspeEsticon applikation',
  'ReportUnitColorAssign.selectEsticonFirm': 'Auswahl der esticon-Datenbank',
  'ReportUnitSettingsModal.title': 'Berechtigung für Abteilungen im Bericht',
  'Forge.cannotViewInMode': 'Das Modell {name} kann nicht im angeforderten Modus {mode} angezeigt werden',
  'Forge.loading': 'Autodesk® Forge Viewer wird geladen...',
  'Forge.loadingError': 'Fehler beim Laden von Autodesk® Forge Viewer.',
  'Forge.loadModelError': 'Fehler beim Laden des Modells {name}',
  'Forge.viewing.showHoverOption.name': 'Markieren Sie Modelle',
  'Forge.viewing.showHoverOption.description': 'Beim Bewegen der Maus werden die Modelle farblich hervorgehoben.',
  'Forge.warning.compare.title': 'Vergleichshinweis',
  'Forge.warning.compare.extension': 'Attribute werden für {extension}-Modelle falsch verglichen.',
  'Forge.warning.compare.turnOffProperties': 'Sie können den Attributvergleich in den Vergleichseinstellungen deaktivieren.',
  'Forge.warning.multipleScales.title': 'Die Modelle enthalten unterschiedliche Maßstäbe',
  'Forge.warning.multipleScales.message': 'Die ausgewählten Modelle können nicht korrekt angezeigt werden, da sie mehrere unterschiedliche Maßstäbe enthalten.',
  'Forge.warning.multipleScales.meter': 'Modelle in Metern:',
  'Forge.warning.multipleScales.millimeter': 'Modelle in Millimetern:',
  'Forge.warning.multipleScales.other': 'Modelle in einer anderen Einheit:',
  'Forge.warning.longDistances.title': 'Die Modelle liegen zu weit auseinander',
  'Forge.warning.longDistances.message': 'Da die ausgewählten Modelle zu weit voneinander entfernt sind, können nicht alle Modelle gleichzeitig angezeigt werden.',
  'Forge.warning.longDistances.group': 'Gruppe {number}:',
  'Forge.warning.longDistances.sharedCoordinatesMissing': 'Modelle verwenden keine gemeinsamen Koordinaten.',
  'forge.warning.notFoundElements': 'Einige Elemente konnten im Modell nicht gefunden werden.',
  'ForgeViewer.loadingTokenError': 'Fehler beim Laden der Vorschaudaten.',
  'ForgeViewer.processModel': 'Verarbeiten Sie das Modell',
  'ForgeViewer.processError': 'Bei der Verarbeitung der Modelle ist ein Fehler aufgetreten:',
  'ForgeViewer.processing': 'Die Modelle sind in Vorbereitung:',
  'ForgeViewer.cannotProcessModels': 'Das Modell kann derzeit nicht verarbeitet werden. Bitte versuchen Sie es später erneut.',
  'ForgeViewer.processModelInfo': 'Die Modellverarbeitung ist ein anspruchsvoller Vorgang und kann je nach Größe des Modells mehrere Minuten dauern.',
  'Forge.measurements.errors.noElementSelected': 'Wählen Sie das Element aus, für das Sie messen möchten.',
  'Forge.measurements.errors.notAvailable': 'Volumen und Oberfläche konnten nicht gemessen werden. Das Element kann geöffnet werden.',
  'Forge.measurements.button.tooltip': 'Volumen und Oberfläche messen',
  'Forge.measurements.result.surface': 'Oberfläche:',
  'Forge.measurements.result.volume': 'Volumen:',
  'Forge.measurements.result.title': 'Messung von Oberfläche und Volumen',
  'Forge.measurements.warnings.openVolume': 'Einige der ausgewählten Objekte sind nicht geschlossen, das Volumen wird möglicherweise nicht korrekt berechnet.',
  'ForgeByAttributesFilter.foundElements': `{count, plural,
    =0 {Keine Elemente gefunden}
    one {Ein Element gefunden}
    few {# Elemente gefunden}
    other {# Elemente gefunden}
    }`,
  'ForgeByAttributesFilter.filtersTitle': 'Suche nach Elementen anhand von Eigenschaften',
  'ForgeByAttributesFilter.parameterFilterLabel': 'Attribut',
  'ForgeByAttributesFilter.valueFilterLabel': 'Wert',
  'ForgeViews.label': 'Ansichten',
  'ForgeViews.attributesFilter': 'Attribut-Filter',
  'ForgeViews.addNew': 'Neue Ansicht hinzufügen',
  'ForgeViews.editView': 'Ansicht bearbeiten',
  'ForgeViews.resetViewTooltip': 'Ansicht zurücksetzen',
  'ForgeViews.viewName': 'Name der Ansicht',
  'ForgeViews.fileType': 'Dateiformat',
  'ForgeViews.noViews': 'Noch keine Ansichten gespeichert',
  'ForgeViews.linkCopied': 'Der Link wurde in die Zwischenablage kopiert',
  'ForgeViews.form.rules.requiredViewName': 'Der Name der Ansicht muss eingegeben werden',
  'ForgeViews.form.rules.maxLengthViewName': 'Der Name kann maximal {max} Zeichen enthalten',
  'ForgeViews.form.item.paramsSettings': 'Anzeigeparameter aufzeichnen',
  'ForgeViews.form.item.paramsSettings.changeCamera': 'Die Kamera',
  'ForgeViews.form.item.paramsSettings.changeSelected': 'Auswählen/Isolieren von Elementen',
  'ForgeViews.form.item.paramsSettings.changeCuttingPlane': 'Schnitte',
  'ForgeViews.form.item.paramsSettings.renderOptions': 'Render-Einstellungen',
  'ForgeViews.form.item.paramsSettings.groupedModels': 'Aktualisieren Sie die Liste der zugehörigen Modelle',
  'ForgeAttributesFilter.category.inputLabel': 'Kategorie',
  'ForgeAttributesFilter.category.placeholder': 'Geben Sie der Kategorien ein',
  'ForgeAttributesFilter.parameter.inputLabel': 'Die Parameter',
  'ForgeAttributesFilter.parameter.placeholder': 'Geben Sie dem Parameter ein',
  'ForgeAttributesFilter.value.inputLabel': 'Parameterwerte',
  'ForgeAttributesFilter.value.placeholder': 'Geben Sie den Wert ein',
  'ForgeAttributesFilter.parametersList.title': `{count, plural,
    =0 {Wählen sie ein element aus}
    one {Ein Element wählen}
    other {# Elemente wählen}
  }`,
  'ForgeAttributesFilter.export.title': 'Export von Elementen mit Parametern',
  'ForgeAttributesFilter.export.items.fileFormat': 'Dateiformat',
  'ForgeAttributesFilter.export.items.csvDelimiter': 'Säulentrenner',
  'ForgeAttributesFilter.export.csvDelimiter.coma': 'Das Komma “,“',
  'ForgeAttributesFilter.export.csvDelimiter.tab': 'Tabulator',
  'ForgeAttributesFilter.export.csvDelimiter.semicolon': 'Semikolon “;“',
  'ForgeAttributesFilter.export.csvDelimiter.pipe': 'Vertikale Linie „|“',
  'forms.button.cancel': 'Stornieren',
  'forms.button.submit': 'Auferlegen',
  'forms.button.submit.loading': 'Sparen...',
  'forms.items.createdBy.label': 'Erstellt von',
  'forms.items.participants.label': 'Teilnehmer',
  'forms.items.description.label': 'Beschreibung',
  'forms.items.esticon.label': 'Angehängtes AspeEsticon-Projekt',
  'forms.items.esticon.warning': 'Sind Sie sicher, dass Sie dieses Projekt einrichten möchten? Das angehängte Projekt kann nachträglich nicht mehr geändert werden!',
  'forms.items.esticon.warning.ok': 'Ja, fertig',
  'forms.items.userId.label': 'Benutzer',
  'forms.items.userIds.label': 'Benutzer',
  'forms.items.associatedIds.label': 'Vertreter',
  'forms.items.userId.rules.required': 'Bitte wählen Sie einen Benutzer aus',
  'forms.items.roleId.label': 'Rolle',
  'forms.items.roleId.rules.required': 'Bitte wählen Sie eine Rolle aus',
  'forms.items.forceRemove.label': 'Benutzer entfernen',
  'forms.items.forceRemove.description': 'Bestätigen Sie das Entfernen des Benutzers',
  'forms.items.email.label': 'E-Mail',
  'forms.items.email.placeholder': 'jan.novak@email.cz',
  'forms.items.labels.label': 'Etiketten',
  'forms.items.name.label': 'Name',
  'forms.items.name.rules.empty': 'Der Name darf nicht leer sein',
  'forms.items.name.rules.nameExists': 'Der eingegebene Name wird bereits verwendet, bitte wählen Sie einen anderen Namen',
  'forms.items.name.rules.required': 'Bitte geben Sie einen Namen ein',
  'forms.items.owner.label': 'Eigentümer des Dokuments',
  'forms.items.primaryFile.rules.required': 'Bitte wählen Sie die Primärdatei aus',
  'forms.items.rules.duplicateEmail': 'Die E-Mail-Adresse wird bereits verwendet',
  'forms.items.rules.email': 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
  'forms.items.rules.email.noDiacritics': 'Bitte geben Sie eine gültige E-Mail-Adresse ohne diakritische Zeichen ein.',
  'forms.items.rules.invalidGuid': 'Die eingegebene Kennung ist ungültig',
  'forms.items.rules.required': 'Feld ist erforderlich',
  'forms.items.rules.noEmpty': 'Das Feld darf nicht leer sein',
  'forms.items.rules.document.required': 'Bitte wählen Sie einige Dokumente aus',
  'forms.items.rules.duplicated': 'Der Wert muss eindeutig sein',
  'forms.items.status.label': 'Zustand',
  'forms.items.userPermissionProfile.label': 'Berechtigungsprofil',
  'forms.items.addDocumentsToWokrflow.justAllowedDocuments': 'Es können nur Dateien einbezogen werden, deren Status den Einstellungen der ausgewählten Vorlage entspricht.',
  'FormWizardWrapper.prev': '< Zurück',
  'FormWizardWrapper.next': 'Weiter >',
  'folderMasks.inheritedMasksFromParentFolder': 'Die Maske wird vom übergeordneten Ordner geerbt',
  'folderMasks.deleteAndInherit.title': 'Masken löschen',
  'folderMasks.deleteAndInherit.description': 'Masken in diesem Ordner werden gelöscht (dies wirkt sich auch auf Masken in Unterordnern aus, die diese Einstellung erben). Es werden Masken aus dem nächstgelegenen übergeordneten Ordner verwendet, für den Masken festgelegt sind (oder keine).',
  'folderMasks.deleteAndInherit.confirm': 'Sind Sie sicher, dass Sie die Masken löschen möchten?',
  'folderMasks.fileNameMask': 'Dateinamenmaske',
  'folderMasks.noMasksSet': 'Die Maske ist ab',
  'folderMasks.subfolderNameMask': 'Maske für den Namen des Unterordners',
  'folderMasks.directoryValidationMask': 'Validierungsmasken',
  'folderMasks.directoryValidationMask.description': 'Masken zur Überprüfung von Ordner- und Dateinamen. Die Namen neuer Dateien und Ordner müssen den angegebenen Regeln entsprechen.',
  'folderMasks.editDirectoryValidationMask': 'Bearbeiten einer Validierungsmaske für einen Ordner',
  'folderMasks.MaskInput.text.initialValue': 'Geben Sie den Text ein',
  'folderMasks.MaskItem.length.title': 'Maskenlänge bearbeiten',
  'folderMasks.MaskItem.length.label': 'Länge',
  'folderMasks.MaskItem.length.charsCount': 'Anzahl der Zeichen',
  'folderMasks.MaskItem.length.rules.required': 'Geben Sie die Anzahl der Zeichen ein',
  'folderMasks.MaskItem.length.rules.format': 'Geben Sie die Anzahl der Zeichen im Format „spezifische Zahl“ oder „minimale Zahl-maximale Zahl“ ein. Zum Beispiel „10“ oder „10-20“',
  'folderMasks.MaskItem.length.rules.invalidCountOrder': 'Die zweite Zahl muss größer als die erste sein',
  'folderMasks.MaskItem.text.title': 'Bearbeitung von Textmasken',
  'folderMasks.MaskItem.text.label': 'Spezifischer Text',
  'folderMasks.MaskItem.text.rules.required': 'Füllen Sie den Text aus',
  'folderMasks.MaskItem.text.rules.invalidCharacters': 'Der Text enthält ungültige Zeichen (\\/:*?""<>|)',
  'folderMasks.MaskItem.letters.allowNumbers': 'Ziffer',
  'folderMasks.MaskItem.letters.allowSpecialCharacters': 'Sonderzeichen',
  'folderMasks.MaskItem.letters.allowSpecialCharacters.tooltip': 'Zulässige Sonderzeichen sind: @$#&%+-_!().,',
  'folderMasks.MaskItem.letters.whitespace': 'Räume',
  'folderMasks.MaskItem.letters.case.upper': 'Großbuchstaben',
  'folderMasks.MaskItem.letters.case.lower': 'Kleinbuchstaben',
  'folderMasks.MaskItem.letters.case.mixed': 'Groß- und Kleinbuchstaben',
  'folderMasks.MaskItem.general.unknownType': 'Unbekannter Typ',
  'folderMasks.MaskItem.simpleDisplay.digits': 'Ziffer',
  'folderMasks.MaskItem.simpleDisplay.letters': 'Charaktere',
  'FilenameErrorMessage.cancelRename': 'Umbenennen abbrechen',
  'FilenameErrorMessage.message': 'Ungültige Zeichen im Namen',
  'FilenameErrorMessage.ok': 'Schließen',
  'FilenameErrorMessage.title': 'Ungültiger Name',
  'FilenameErrorMessage.invalidCharactersTitle': 'Der Name darf keine Zeichen enthalten',
  'FilenameErrorMessage.invalidNamesTitle': 'Verbotene Namen',
  'FilenameErrorMessage.showMoreInfo': 'Weitere Informationen (auf Englisch)',
  'fullscreen.maximize': 'Maximieren',
  'fullscreen.minimize': 'Minimieren',
  'general.administrator': 'Administrator',
  'general.administrator.aspeHub': 'AspeHub-Administrator',
  'general.activity': 'Aktivität',
  'general.add': 'Hinzufügen',
  'general.addDefinition': 'Definition hinzufügen',
  'general.addUsers': 'Benutzer hinzufügen',
  'general.addNewUsers': 'Neue Benutzer hinzufügen',
  'general.addOrganization': 'Organisation hinzufügen',
  'general.addProject': 'Projekt hinzufügen',
  'general.editOrganization': 'Organisation bearbeiten',
  'general.addDescription': 'Beschreibung hinzufügen',
  'general.addName': 'Fügen Sie einen Titel hinzu',
  'general.assignedTo': 'Dem Solver zugewiesen',
  'general.assignment': 'Aufgabe',
  'general.attachments': 'Anhänge',
  'general.attachmentsWithCount': 'Anhänge ({count})',
  'general.author': 'Autor',
  'general.back': 'Zurück',
  'general.budget': 'Budget',
  'general.clearFilters': 'Filter aufheben',
  'general.categories': 'Kategorie',
  'general.categoriesNodes': 'Kategorieknoten',
  'general.completed': 'Vollendet',
  'general.date': 'Datum',
  'general.deadline': 'Begriff',
  'general.tests': 'Stresstests',
  'general.charLength': `{count, plural,
    =0 {# Zeichen}
    one {1 Zeichen}
    few {# Zeichen}
    other {# Zeichen}
    }`,
  'general.clearFilter': 'Filter aufheben',
  'general.close': 'Schließen',
  'general.color': 'Farbe',
  'general.copy': 'Kopieren',
  'general.comments': 'Erinnerungen',
  'general.commentProcedure': 'Mahnverfahren',
  'general.compare': 'Vergleichen',
  'general.create': 'Erstellen',
  'general.currencyLabel': '{label} [{currency}]',
  'general.currency.CZK': 'CZK',
  'general.currency.EUR': '€',
  'general.currency.USD': '$',
  'general.currency.PLN': 'zł',
  'general.currencyFormat.CZK': '{value} CZK',
  'general.currencyFormat.EUR': '{value} €',
  'general.currencyFormat.USD': '{value} $',
  'general.currencyFormat.PLN': '{value} zł',
  'general.custom': 'Eigen',
  'general.createdBy': 'Erstellt vom Benutzer',
  'general.createdDate': 'Erstellt: {date}',
  'general.created': 'Erstellt',
  'general.escape': 'Esc',
  'general.deletedDate': 'Gelöscht: {date}',
  'general.modifiedDate': 'Bearbeitet: {date}',
  'general.dayNames.monday': 'Montag',
  'general.dayNames.tuesday': 'Dienstag',
  'general.dayNames.wednesday': 'Mittwoch',
  'general.dayNames.thursday': 'Donnerstag',
  'general.dayNames.friday': 'Freitag',
  'general.dayNames.saturday': 'Samstag',
  'general.dayNames.sunday': 'Sonntag',
  'general.days': 'Tage',
  'general.daysPlural': `{count, plural,
    =0 {Tage}
    one {Tag}
    few {Tage}
    other {Tage}
    }`,
  'general.default': 'Standard',
  'general.defaultExtendedPermission': 'Das Standardbenutzerberechtigungsprofil',
  'general.delete': 'Entfernen',
  'general.deleted': 'ENTFERNT',
  'general.deletedItemSuccessfully': `{count, plural,
    =0 {0 Elemente gelöscht}
    one {Element erfolgreich gelöscht}
    few {# Elemente erfolgreich gelöscht}
    other {# Elemente erfolgreich gelöscht}
    }`,
  'general.description': 'Beschreibung',
  'general.discussion': 'Diskussion',
  'general.docMenu.showModel': 'Modell ansehen',
  'general.docMenu.addModel': 'Neues Modell',
  'general.docMenu.addToSelected': 'Zu Favoriten hinzufügen',
  'general.docMenu.bulkEdit': 'Massenbearbeitungen',
  'general.docMenu.bulkEdit.documentWatch': 'Verfolgung einstellen',
  'general.docMenu.bulkEdit.modelsSetting': 'Modelleinstellung',
  'general.docMenu.bulkEdit.modelsSetting.add': 'Zu Modellen hinzufügen',
  'general.docMenu.bulkEdit.modelsSetting.remove': 'Aus Modellen entfernen',
  'general.docMenu.bulkEdit.changeState': 'Status ändern',
  'general.docMenu.bulkEdit.setLabels': 'Etiketten festlegen',
  'general.docMenu.downloadInZip': 'ZIP herunterladen',
  'general.docMenu.downloadInZip.thisDirOnly': 'Nur dieser Ordner',
  'general.docMenu.downloadInZip.deepWithFiles': 'Alle Unterordner und Dokumente',
  'general.docMenu.downloadInZip.deepWithoutFiles': 'Ordnerstruktur ohne Dokumente',
  'general.docMenu.downloadInZip.downloadError': 'ZIP konnte nicht heruntergeladen werden',
  'general.docMenu.downloadInZip.directoryLinkWarning': 'Enthaltene Ordner-Links sind nicht Teil der generierten ZIP-Datei.',
  'general.docMenu.downloadOriginal': 'Original herunterladen',
  'general.docMenu.downloadSignedDocument': 'Dokument zum Unterschreiben herunterladen',
  'general.docMenu.downloadWithAnnotations': 'Mit Kommentaren herunterladen',
  'general.docMenu.selectedFiles': `{count, plural,
    =0 {Nichts ausgewählt}
    one {1 Element}
    few {# Elemente}
    other {# Elemente}
    }`,
  'general.docMenu.selected.tooltip': 'Alles deaktivieren',
  'general.docMenu.share': 'Teilen',
  'general.docMenu.shareAppUserDownload': 'Mit Projektmitgliedern teilen',
  'general.docMenu.shareDownload': 'Download-Link senden',
  'general.docMenu.sign': 'Unterschreiben',
  'general.docMenu.signOnline': 'Dokument elektronisch unterschreiben',
  'general.docMenu.signLocally': 'Signierte Dokument hochladen',
  'general.docMenu.startWorkflow': 'Workflow starten',
  'general.docMenu.createDocumentLinks': 'Link erstellen',
  'general.dirMenu.createDirectoryLink': 'Link erstellen',
  'general.documents': 'Dokumente',
  'general.documentsWithCount': 'Dokumente ({count})',
  'general.download': 'Herunterladen',
  'general.downloadAll': 'Alles herunterladen',
  'general.drawing': 'Schöpfen',
  'general.createNewProject': 'Neues Projekt erstellen',
  'general.editProject': 'Projekt bearbeiten',
  'general.edit': 'Bearbeiten',
  'general.error': 'Fehler',
  'general.failedAction': 'Aktion ist fehlgeschlagen',
  'general.export': 'Exportieren',
  'general.export.tooltip': 'Exportieren (gemäß dem aktuellen Filter)',
  'general.extension': 'Suffix',
  'general.ExternApps': 'Externe Applikation',
  'general.filter': 'Filter',
  'general.turnFiltersOn': 'Filter einschalten',
  'general.turnFiltersOff': 'Filter ausschalten',
  'general.folder': 'Komponente',
  'general.folders': 'Ordner',
  'general.general': 'Im Allgemeinen',
  'general.general.setting': 'Allgemeine Einstellungen',
  'general.groups': 'Gruppen',
  'general.help': 'Helfen',
  'general.goTo': 'Gehen Sie zu:',
  'general.import': 'Import',
  'general.insufficientPermission': 'Sie verfügen nicht über ausreichende Zugriffsrechte.',
  'general.invoicing': 'Abrechnung',
  'general.links': 'Links',
  'general.bindings': 'Bindungen',
  'general.acknowledge': 'Ich nehme es zur Kenntnis',
  'general.ok': 'OK',
  'general.on': 'An',
  'general.off': 'Aus',
  'general.onOff': 'Teilweise eingeschaltet',
  'general.help.licence': 'Lizenzinformationen',
  'general.labels': 'Etiketten',
  'general.loading': 'Laden...',
  'general.logout': 'Abmelden',
  'general.maxDescriptionLength': 'Die Beschreibung ist zu lang. Die maximale Beschreibungslänge beträgt {max} Zeichen.',
  'general.maxNameLength': 'Der Titel ist zu lang. Die maximale Namenslänge beträgt {max} Zeichen.',
  'general.minNameLength': 'Der Titel ist zu kurz. Die Mindestlänge des Namens beträgt {min} Zeichen.',
  'general.maxLength': 'Der eingegebene Wert ist zu lang. Die maximale Länge beträgt {max} Zeichen.',
  'general.minCINlength': 'Der eingegebene Wert ist zu kurz. Die Mindestlänge beträgt {min} Zeichen. Sie können überhaupt keinen Wert eingeben.',
  'general.badCINControlDigit':'Ungültig in Bezug auf die Schecknummer',
  'general.justNumbers':'Nur Ziffern',
  'general.manager': 'Dokumentenmanager',
  'general.modified': 'Bearbeitet',
  'general.modifiedBy': 'Bearbeitet',
  'general.move': 'Bewegen',
  'general.month.january': 'Januar',
  'general.month.february': 'Februar',
  'general.month.march': 'Marsch',
  'general.month.april': 'April',
  'general.month.may': 'Mai',
  'general.month.june': 'Juni',
  'general.month.july': 'Juli',
  'general.month.august': 'August',
  'general.month.september': 'September',
  'general.month.october': 'Oktober',
  'general.month.november': 'November',
  'general.month.december': 'Dezember',
  'general.name': 'Name',
  'general.personName': 'Name',
  'general.email': 'E-Mail',
  'general.phone': 'Rufnummer',
  'general.sign': 'Markieren',
  'general.nameAndDescription': 'Name und Beschreibung',
  'general.newProject': 'Neues Projekt',
  'general.defaultTimeZone': 'Standardzeitzone',
  'general.storagearea': 'Zweig',
  'general.storageareas': 'Zweige',
  'general.defaultLanguage': 'Standardsprache',
  'general.no': 'NEIN',
  'general.organizations': 'Organisation',
  'general.organizationLabels': 'Organisationsetiketten',
  'general.groupedByOrganizations': 'Gruppiert nach Organisationen',
  'general.permission': 'Genehmigung',
  'general.previous': 'Vorherige',
  'general.next': 'Andere',
  'general.noMoreUsers': 'Keine anderen Benutzer',
  'general.from': 'Aus:',
  'general.to': 'Zu:',
  'general.unknown': 'Unbekannt',
  'general.unlimited': '(unbegrenzt)',
  'general.units': 'Einheiten',
  'general.noUser': 'Kein Benutzer',
  'general.Associated': 'Vertreter',
  'general.AssociatedOnly': 'Nur der Vertreter',
  'general.noDocuments': 'Im ausgewählten Ordner befinden sich keine Dokumente.',
  'general.noItems': 'Hier sind keine Artikel vorhanden.',
  'general.notEmptyItemError': 'Der Artikel muss abgeschlossen sein',
  'general.notSet': 'Nicht festgelegt',
  'general.nothingFound': 'Es wurde nichts gefunden.',
  'general.organization': 'Organisation',
  'general.path': 'Pfad',
  'general.PartialWiP': 'Partielles Erkennungsprotokoll',
  'general.PartialInvoice': 'Partielle Rg.',
  'general.preview': 'Vorschau',
  'general.project': 'Projekt',
  'general.projectList': 'Liste der Projekte',
  'general.projects': 'Projekte',
  'general.projectSettings': 'Projekteinstellungen',
  'general.reinvite': 'Nochmals einladen',
  'general.return': 'Rückgängig',
  'general.revisions': 'Revision',
  'general.role': 'Rolle',
  'general.roles': 'Rolle',
  'general.roleName': 'Rollenname',
  'general.save': 'Auferlegen',
  'general.saveProfile': 'Profil speichern',
  'general.search': 'Suchen',
  'general.settings': 'Einstellungen',
  'general.settings.profile': 'Profileinstellungen',
  'general.source': 'Quelle',
  'general.sort': 'Arrangieren',
  'general.sortedBy': 'Sortiert nach',
  'general.sortBy': 'Sortieren nach',
  'general.sortDirection': 'Sortierrichtung',
  'general.sort.asc': 'Von A bis Z (aufsteigend)',
  'general.sort.desc': 'Von Z nach A (absteigend)',
  'general.start': 'Laufen',
  'general.state': 'Zustand',
  'general.documentationLevel': 'Grad der Dokumentation',
  'general.Phase': 'Phase',
  'general.Current': 'Aktuell',
  'general.MdConditions': 'Bedingungen der ZK',
  'general.state.archived': 'Archiviert',
  'general.state.published': 'Veröffentlicht',
  'general.state.rejected': 'Abgelehnt',
  'general.state.shared': 'Geteilt',
  'general.state.workInProgress': 'Im Gange',
  'general.title': 'Titel',
  'general.cancel': 'Stornieren',
  'general.cancelChanges': 'Änderungen verwerfen',
  'general.change': 'Ändern',
  'general.showMore': 'Mehr anzeigen',
  'general.select': 'Wählen',
  'general.selectAll': 'Alles auswählen',
  'general.showMoreOptions': 'mehr Optionen',
  'general.showLess': 'Weniger anzeigen',
  'general.schedule': 'Zeitplan',
  'general.signIn': 'Einloggen',
  'general.size': 'Größe',
  'general.team': 'Team',
  'general.category': 'Kategorie',
  'general.total': 'In Summe',
  'general.template': 'Vorlage',
  'general.template.create': 'Erstellen Sie eine Vorlage',
  'general.type': 'Typ',
  'general.users': 'Benutzer',
  'general.userName': 'Benutzer',
  'general.userMail': 'Die E-Mail-Adresse des Benutzers',
  'general.firstName': 'Name',
  'general.lastName': 'Nachname',
  'general.language': 'Sprache',
  'general.userNames': 'Vor- und Nachname',
  'general.messages': 'Nachricht',
  'general.warning': 'Beachten',
  'general.watch': 'Verfolgung',
  'general.workDaysCalendar': 'Kalender der Arbeitstage',
  'general.organizationStructure': 'Organisationsstruktur',
  'general.view': 'Anzeige',
  'general.yes': 'Ja',
  'general.needAdminAccess': 'Für den Zugriff auf dieses Element benötigen Sie Administratorrechte.',
  'general.send': 'Schicken',
  'general.ZBV': 'ÄdB',
  'general.workflow': 'Arbeitsablauf',
  'general.document': 'Dokumentieren',
  'general.link': 'Link',
  'general.forge': 'Modellverarbeitung',
  'general.textMaxLengthWarning': 'Sie haben die maximale Zeichenanzahl erreicht',
  'general.reference': 'Referenz',
  'general.metadata': 'Zusätzliche Daten',
  'general.emptyItemValue': 'Das Element ist nicht gefüllt',
  'general.value': 'Wert',
  'general.justification': 'Rechtfertigung',
  'general.statement': 'Meinung',
  'JobPositions.title': 'Arbeitsposition',
  'JobPositions.addPosition': 'Fügen Sie eine Stelle hinzu',
  'JobPositions.delete.confirmTitle': 'Stellenausschreibung löschen?',
  'JobPositions.delete.confirmDeleteFromUsers': `Diese Jobposition ist {count} {count, plural,
  one {user}
  other {users}
  } zugewiesen. Durch das Entfernen dieser Position wird sie von allen Benutzern entfernt. Weitermachen?`,
  'JobPositions.delete.confirmed': 'Die Stellenausschreibung wurde gelöscht.',
  'JobPositions.add.rules.name': 'Der Positionsname ist ein Pflichtfeld',
  'JobPositions.add.rules.nameLength': 'Der Positionsname ist zu lang. Die maximale Länge beträgt {maxLength} Zeichen',
  'contextMenuExpandCollapseUtils.expand': 'Auspacken',
  'contextMenuExpandCollapseUtils.collapse': 'Packen',
  'GrantAccessModal.addUser': 'Fügen Sie dem Benutzer Berechtigungen hinzu',
  'GrantAccessModal.folderLabel': 'Komponente',
  'GrantAccessModal.message': 'Benutzer {user} hat die Erlaubnis angefordert.',
  'GrantAccessModal.noInGroup': 'Benutzer {user} gehört keiner Gruppe an.',
  'GrantAccessModal.requiredPermissionLabel': 'Erlaubnis erforderlich',
  'GrantAccessModal.title': 'Erteilen der Berechtigung zum Zugriff auf einen Ordner',
  'GrantAccessModal.userLabel': 'Für Benutzer',
  'GrantAccessModal.usersLabel': 'Legen Sie Benutzerberechtigungen fest',
  'GrantAccessModal.groupsDescription': 'Gruppen, in denen sich der Benutzer {user} befindet',
  'GrantAccessModal.groupsDescription.highlight': 'hervorgehoben',
  'GrantAccessModal.groupsLabel': 'Gruppenberechtigungen für den Ordner',
  'GrantAccessModal.groupsMemberLabel': 'Gruppen von Benutzern {user}',
  'GrantAccessModal.unauthorizedError.message': 'Sie verfügen nicht über die erforderliche Berechtigung zum Festlegen des Zugriffs.',
  'GroupForm.items.defaultDirectoryAccessLevel.label': 'Die standardmäßige Zugriffsberechtigungsstufe für den Ordner',
  'GroupForm.items.name.placeholder': 'Viewer – schreibgeschützt',
  'GroupFormModal.title.create': 'Neue Gruppe',
  'GroupUserCount.noUsers': 'Keine Benutzer',
  'GroupUserCount.userCount': `{count} {count, plural,
    ein {Benutzer}
    few {Benutzer}
    other {Benutzer}
    }`,
  'GroupUserAddFormModal.title': 'Auswählen von Benutzern, die der Gruppe hinzugefügt werden sollen',
  'HeaderInfoDropdown.Licence.title': 'Lizenzinformationen',
  'HeaderInfoDropdown.Licence.used': 'Gebraucht',
  'HeaderInfoDropdown.Licence.remaining': 'Restkapazität',
  'HeaderInfoDropdown.Licence.validTo': 'Gültig bis',
  'HeaderInfoDropdown.Documentation.title': 'Dokumentation',
  'HeaderInfoDropdown.UserDocumentation.title': 'Benutzerdokumentation',
  'HeaderInfoDropdown.TechnicalDocumentation.title': 'Technische Dokumentation',
  'HeaderInfoDropdown.MDDocumentation.title': 'MD-Handbuch',
  'HeaderInfoDropdown.Version.title': 'Versionsinformationen',
  'HeaderInfoDropdown.Version.number': 'Version',
  'HeaderInfoDropdown.Version.NoData': 'Informationen nicht verfügbar',
  'HeaderInfoDropdown.Version.builtDate': 'Baudatum',
  'HeaderInfoDropdown.ShortCuts.title': 'Tastaturkürzel',
  'HeaderInfoDropdown.ShortCuts.ESC': 'Vorschau schließen',
  'HeaderInfoDropdown.ShortCuts.next': 'Ein weiteres Dokument',
  'HeaderInfoDropdown.ShortCuts.DocumentPreview': 'Dokumentvorschau',
  'HeaderInfoDropdown.ShortCuts.DocumentsList': 'Liste der Dokumente',
  'HeaderInfoDropdown.ShortCuts.ModelWorking': 'Arbeiten mit dem Modell',
  'HeaderInfoDropdown.ShortCuts.MouseLeftClick': 'Linker Mausklick',
  'HeaderInfoDropdown.ShortCuts.previous': 'Vorheriges Dokument',
  'HeaderInfoDropdown.ShortCuts.ContinuousSelection': 'Kontinuierliche Auswahl von Dokumenten',
  'HeaderInfoDropdown.ShortCuts.MultipleModelElementsSelect': 'Auswahl mehrerer Modellelemente',
  'HeaderInfoDropdown.GDPR.title': 'DSGVO',
  'HeaderInfoDropdown.GDPR.text': 'Im Rahmen der AspeHub-Anwendung werden personenbezogene Daten der Nutzer zum Zwecke der Registrierung und Projektverwaltung in der AspeHub-Anwendung im erforderlichen Umfang (Vorname, Nachname, E-Mail, Telefon) verarbeitet. Für nähere Informationen, einschließlich der Möglichkeit, die Einwilligung zur Verarbeitung personenbezogener Daten zu widerrufen, können Sie sich an den Betreiber der Anwendung wenden.',
  'HeaderInfoDropdown.Contacts.title': 'Kontakte',
  'HeaderInfoDropdown.Contacts.webPageTitle': 'Web',
  'HeaderInfoDropdown.Contacts.technicalSupport': 'Technische Unterstützung',
  'HeaderInfoDropdown.Contacts.phone': 'Tel.:',
  'HeaderInfoDropdown.Contacts.mail': 'E-Mail:',
  'HeaderLocaleDropdown.changeLanguage': 'Sprache ändern',
  'HeaderSearch.placeholder': 'Im Projekt suchen',
  'IdleTimerActivityCheck.modal.title': 'Abmelden, wenn Sie inaktiv sind',
  'IdleTimerActivityCheck.modal.message': 'Sie werden {time} aufgrund von Inaktivität abgemeldet. Möchten Sie weiterarbeiten?',
  'IdleTimerActivityCheck.modal.confirm': 'Bleiben Sie eingeloggt',
  'InsufficientAccessLevelError.message': 'Sie haben keine Berechtigung, dieses Dokument zu lesen.',
  'InsufficientAccessLevelError.description': 'Bitten Sie zum Anzeigen den Ordneradministrator um Zugriff.',
  'InviteReportModal.label': 'Es konnten nicht alle Benutzer eingeladen werden',
  'InviteReportModal.alreadyExists.label': `{count, plural,
    =0 {no}
    one {Der folgende Benutzer ist bereits im Projekt vorhanden}
    other {Die folgenden Benutzer sind bereits im Projekt vorhanden}
    }.`,
  'InviteReportModal.invited.label': `{count, plural,
    =0 {Es wurden keine Benutzer eingeladen}
    one {Der folgende Benutzer wurde eingeladen}
    other {Die folgenden Benutzer wurden eingeladen}
    }.`,
  'InviteReportModal.uninvited.label': `{count, plural,
    =0 {nein}
    one {Der folgende Benutzer konnte nicht eingeladen werden}
    other {Die folgenden Benutzer konnten nicht eingeladen werden}
    }.`,
  'InviteReportModal.limit': `{count, plural,
    =0 {Kann keinen anderen Benutzer einladen}
    one {Kann einen Benutzer einladen}
    few {Kann # Benutzer einladen}
    other {Kann # Benutzer einladen}
    }.`,
  'InvoiceDetail.title': 'Rechnungsdetails',
  'InvoiceDetail.menu.additionalInformation': 'Zusätzliche Daten',
  'InvoiceDetail.menu.items': 'Artikel',
  'InvoiceDetail.menu.overview': 'Überblick',
  'InvoiceDetailAdditionalInformation.forTheContractor': 'Für den Auftragnehmer',
  'InvoiceDetailAdditionalInformation.forTheCustomer': 'Für den Kunden',
  'InvoiceDetailAdditionalInformation.function': 'Funktion',
  'InvoiceDetailAdditionalInformation.invoiceManager': 'Rechnungsmanager 7.7',
  'InvoiceDetailAdditionalInformation.where': 'Wo',
  'InvoiceItemsDetail.columns.basicVATPercent': 'Mehrwertsteuerbasis {rate} %',
  'InvoiceItemsDetail.columns.object': 'Objekt',
  'InvoiceItemsDetail.columns.realizedPercent': 'Gezogen {rate} %',
  'InvoiceItemsDetail.columns.retentionPercent': 'Zurückbehaltung [%]',
  'InvoiceItemsDetail.columns.retention': 'Zurückhaltend',
  'InvoiceItemsDetail.columns.salePercent': 'Rabatt [%]',
  'InvoiceItemsDetail.columns.sale': 'Rabatt',
  'InvoiceItemsDetail.columns.suspensionPercent': 'Pause [%]',
  'InvoiceItemsDetail.columns.suspension': 'Pause',
  'InvoiceItemsDetail.columns.total': 'In Summe',
  'InvoiceItemsDetail.columns.valorizationPercent': 'Valorisierung [%]',
  'InvoiceItemsDetail.columns.valorization': 'Aufwertung',
  'InvoiceItemsDetail.columns.vat': 'MwSt. {rate} %',
  'InvoiceDetail.menu.paymentInformation': 'Zahlungsdetails',
  'InvoiceDetailOverview.administrator': 'Administrator/Investor',
  'InvoiceDetailOverview.billingPeriod': 'Abrechnungszeitraum',
  'InvoiceDetailOverview.contacts': 'Kontakte',
  'InvoiceDetailOverview.contractor': 'Anbieter',
  'InvoiceDetailOverview.invoiceNumber': 'Rechnungsnummer',
  'InvoiceDetailOverview.issue': 'Belichtung',
  'InvoiceDetailOverview.maturity': 'Reife',
  'InvoiceDetailOverview.payment': 'Erstattung',
  'InvoiceDetailOverview.price': 'Preis',
  'InvoiceDetailOverview.serialNumber': 'OZ',
  'InvoiceDetailOverview.taxBase': 'Steuerbemessungsgrundlage (21 %)',
  'InvoiceDetailOverview.taxDocument': 'Steuerdokument',
  'InvoiceDetailOverview.taxableSupply': 'Steuerpflichtige Leistung',
  'InvoiceDetailOverview.totalWithoutVat': 'Gesamt ohne MwSt.',
  'InvoiceDetailOverview.totalToBePaidIncludingVat': 'Zu zahlender Gesamtbetrag inkl. MwSt.',
  'InvoiceDetailOverview.invoiceType': 'Rechnungstyp',
  'InvoiceDetailOverview.invoiceType.Klasicka': 'Klassiker',
  'InvoiceDetailOverview.invoiceType.Zalohova': 'Sicherung',
  'InvoiceDetailOverview.invoiceType.Dobropis': 'Gutschrift',
  'InvoiceDetailPaymentInformation.bankAccount': 'Bankkonto',
  'InvoiceDetailPaymentInformation.bankDetailsAdministrator': 'Treuhänder-/Investorbankverbindung',
  'InvoiceDetailPaymentInformation.bankDetailsContractor': 'Anbieter von Bankverbindungen',
  'InvoiceDetailPaymentInformation.bicSwift': 'BIC/SWIFT',
  'InvoiceDetailPaymentInformation.iban': 'IBAN',
  'InvoiceDetailAdditionalInformation.taxPeriod': 'Steuersaison',
  'InvoiceDetailAdditionalInformation.VATTransferRegime': 'Mehrwertsteuerübertragungssystem',
  'InvoiceDetailAdditionalInformation.contracts': 'Verträge',
  'InvoiceDetailAdditionalInformation.signature': 'Rechnungsunterschrift',
  'InvoiceDetailAdditionalInformation.contractDate': 'Datum des Vertrags',
  'InvoiceDetailAdditionalInformation.contractContractor': 'Vertragspartner',
  'InvoiceDetailAdditionalInformation.contractCustomer': 'Vertragskunde',
  'IsModelButton.modal.confirm.title': 'Das Modell enthält Bindungen an Elemente',
  'IsModelButton.modal.confirm.content': 'Wenn Sie ein Dokument aus den Modellen entfernen, werden alle Bindungen an die Elemente entfernt. Weitermachen?',
  'IsModelButton.message.success': 'Das Dokument wurde aus den Modellen entfernt',
  'IsModelButton.message.error': 'Das Dokument konnte nicht aus den Modellen entfernt werden',
  'IsModelButton.message.addSuccess': 'Das Dokument wurde den Modellen hinzugefügt',
  'IsModelButton.message.addError': 'Das Dokument konnte nicht zu den Modellen hinzugefügt werden',
  'CommonInvoiceDetail.title': 'Gemeinsame Rechnungsdetails',
  'CommonInvoiceDetail.menu.overview': 'Überblick',
  'CommonInvoiceDetail.menu.paymentInformation': 'Zahlungsdetails',
  'CommonInvoiceDetail.menu.additionalInformation': 'Zusätzliche Daten',
  'CommonInvoiceDetail.menu.memberInvoices': 'Rechnungen für die Mitgliedschaft',
  'CommonInvoiceItemsGrid.columns.sign': 'Code',
  'CommonInvoiceItemsGrid.columns.contractor': 'Anbieter',
  'CommonInvoiceItemsGrid.columns.order': 'Befehl',
  'CommonInvoiceItemsGrid.columns.priceWithoutVat': 'Preis ohne Mehrwertsteuer',
  'CommonInvoiceItemsGrid.columns.vatPriceBasic': 'Grundsatz der Mehrwertsteuer',
  'CommonInvoiceItemsGrid.columns.vatPriceReduced1': 'Erster ermäßigter Mehrwertsteuersatz',
  'CommonInvoiceItemsGrid.columns.vatPriceReduced2': 'Zweiter ermäßigter Mehrwertsteuersatz',
  'CommonInvoiceItemsGrid.columns.totalPrice': 'Gesamtpreis',
  'ItemNotFoundErrorBox.description': 'Möglicherweise wurde das Element entfernt oder die Linkadresse ist falsch.',
  'ItemNotFoundErrorBox.title': 'Artikel nicht gefunden.',
  'ItemNotFoundErrorBox.toDocuments': 'Gehen Sie zu Dokumente',
  'ItemRowNameContainer.createdDate': 'Letzte Änderung am {date}',
  'ItemRowNameContainer.user': 'Benutzer',
  'JSONVariableLinkFormItem.label': 'Linktext',
  'JSONVariableLinkFormItem.url': 'URL-Adresse',
  'JSONVariableProjectLinkSelectorFormItem.button.selectDirectory': 'Ordner auswählen',
  'JSONVariableProjectLinkSelectorFormItem.button.selectDocument': 'Wählen Sie ein Dokument aus',
  'JSONVariableType.empty': 'Gruppe',
  'JSONVariableType.number': 'Nummer',
  'JSONVariableType.numberDiff': 'Nummern (bestehend und neu)',
  'JSONVariableType.string': 'Text',
  'JSONVariableType.stringDiff': 'Texte (bestehende und neue)',
  'JSONVariableType.date': 'Datum',
  'JSONVariableType.link': 'Weblink',
  'JSONVariableType.boolean': 'Schalten',
  'JSONVariableType.interval': 'Intervall',
  'JSONVariableType.ckApprovalConditions': 'ZK-Zulassungsbedingungen',
  'JSONVariableType.hubLink': 'AspeHub-Link',
  'JSONVariableType.enum': 'Aufzählung',
  'JSONVariableType.orgStructureWithUser': 'Organisationsstruktur mit Benutzer',
  'KpiConfigurationFormModal.title.create': 'Neue KPI-Konfiguration',
  'KpiConfigurationFormModal.title.edit': 'Bearbeiten Sie die KPI-Konfiguration',
  'KpiConfigurationForm.title': 'Name',
  'KpiConfigurationForm.title.required': 'Name ist erforderlich',
  'KpiConfigurationForm.columnRules': 'Regeln',
  'KpiConfigurationFormItem.column': 'Berichtsspalte',
  'KpiConfigurationFormItem.condition': 'Zustand',
  'KpiConfigurationFormItem.referentialValue': 'Referenzwert',
  'KpiConfigurationFormItem.evaluation': 'Auswertung',
  'KpiConfigurationFormItem.evaluation.ok': 'In Ordnung',
  'KpiConfigurationFormItem.evaluation.critical': 'Kritisch',
  'KpiConfigurationFormItem.addRule.title': 'Neue Regel',
  'KpiConfigurationFormItem.addRule.button': 'Hinzufügen',
  'KpiConfigurationFormItem.addRule.disabled.tooltip': 'Für alle Berichtsspalten ist bereits eine Regel erstellt',
  'KpiSettingsToolbar.menu.add': 'Fügen Sie eine neue KPI-Konfiguration hinzu',
  'KpiSettingsToolbar.menu.edit': 'Bearbeiten Sie die aktive KPI-Konfiguration',
  'KpiSettingsToolbar.menu.delete': 'Aktive KPI-Konfiguration löschen',
  'LabelFilterItem.Operators.AND': 'UND',
  'LabelFilterItem.Operators.OR': 'ODER',
  'LabelFormModal.title.create': 'Neues Etikett',
  'LabelsForm.form.items.color.label': 'Farbe',
  'LabelsForm.form.items.description.placeholder': 'Optionale Beschreibung...',
  'LabelsForm.form.items.name.placeholder': 'Labelname',
  'LabelsForm.preview': 'Etikettenvorschau',
  'LabelsList.deleteConfirmModal.delete': 'Etikett löschen',
  'LabelsList.deleteConfirmModal.description': 'Sind Sie sicher, dass Sie das Label löschen und aus {documentCount}-Dokumenten, {commentProcedureCount}-Kommentarprozeduren und {workflowCount}-Workflows entfernen möchten?',
  'LabelsList.deleteConfirmModal.title': 'Die Beschriftung wurde nicht gelöscht, da sie einigen Objekten zugewiesen ist.',
  'LabelsInput.placeholder': 'Wählen Sie Etiketten aus',
  'LablesEditableList.noLabels': 'Keine Etiketten',
  'ListEmpty.noData': 'Die Liste ist leer.',
  'ListEmpty.noItemsFound': 'Keine Elemente entsprechen den angegebenen Filtern.',
  'ListEmpty.noDocumentsFound': 'Keine Dokumente entsprechen den angegebenen Filtern.',
  'LocalSigningModal.title': 'Signatur mit einem lokalen Zertifikat',
  'LocalSigningModal.button.sign': 'Zeichen',
  'LocalSigningModal.input.passphrase': 'Passwort',
  'LocalSigningModal.certificateFile': 'Signaturdatei auswählen (pfx, p12)',
  'LocalSigningModal.error.stampLoadingFailed': 'Der Hintergrund für die digitale Signatur konnte nicht geladen werden',
  'LocalSigningModal.error.fontLoadingFailed': 'Die Schriftart für die digitale Signatur konnte nicht geladen werden',
  'LocalSigningModal.error.missingResources': 'Die Dokumente zum Starten der Signatur werden nicht geladen',
  'LocalSigningModal.status.initializing': 'Vorbereiten der Signierumgebung',
  'LocalSigningModal.status.signingDocument': 'Unterschreiben eines Dokuments',
  'LocalSigningModal.status.uploadingDocument': 'Hochladen eines signierten Dokuments',
  'LocalSignatureErrors.singingProcessFailed': 'Beim Signieren des Dokuments ist ein Fehler aufgetreten. Das Dokument konnte nicht signiert werden.',
  'LocalSignatureErrors.uploadProcessFailed': 'Beim Speichern des signierten Dokuments ist ein Fehler aufgetreten. Das Dokument konnte nicht signiert werden.',
  'LocalSignatureErrors.invalidCertificateOrPassword': 'Falsches Zertifikatformat oder ungültiges Passwort.',
  'LocalSignatureErrors.certificateMissingHolderName': 'Das Zertifikat enthält nicht den anzuzeigenden Namen des Inhabers.',
  'LocalSignatureErrors.signerFailedToCreate': 'Das Signaturformat für PDF konnte nicht erstellt werden.',
  'LoginErrorPage.AccessDeniedMessage': 'Sie sind nicht berechtigt, auf diese Anwendung zuzugreifen.',
  'LoginErrorPage.Button.ReturnToLogin': 'Zurück zum Login',
  'LoginErrorPage.ErrorTitle': 'Zugriff verweigert',
  'MainDashboard.noSelectedProjects': 'Sie haben keine ausgewählten Projekte :-(',
  'MainDashboard.noSelectedReports': 'Sie haben keine Berichte festgelegt',
  'MainDashboard.viewAllProjects': 'Alle Projekte ansehen',
  'MainDashboard.addFirstReportButton': 'Fügen Sie einen neuen Bericht hinzu',
  'MD.approvalMeetings.listModal.button.applyResult': 'Notieren Sie die Ergebnisse des Treffens',
  'MD.approvalMeetings.listModal.button.downloadBackground': 'Laden Sie Besprechungsunterlagen herunter',
  'MD.approvalMeetings.listModal.confirm.cancel.title': 'Besprechung absagen',
  'MD.approvalMeetings.listModal.download.fileName': 'ZK Tagesordnung {date}.docx',
  'MD.approvalMeetings.listModal.confirm.cancel.description': 'Sind Sie sicher, dass Sie die eingetragene Besprechung absagen möchten?',
  'MD.OverheadProjectSettingsForm.overheadProject.label': 'Projekt des Regisseurs',
  'MD.OverheadProjectSettingsForm.overheadProject.label.required': 'Das Regieprojekt ist obligatorisch',
  'MD.OverheadProjectSettingsForm.overheadProject.alert': 'Alle Benutzer aus der MD-Abteilung werden zum ausgewählten Projekt eingeladen und erhalten das Recht zum Lesen.',
  'MD.MeetingBackgroundDocumentModal.title': 'Dokument mit Dokumenten für Sitzung des Zentralkomitees',
  'MD.MeetingBackgroundDocumentModal.requestIsRequired': 'Das Dokument zur Tagesordnung wurde noch nicht erstellt.',
  'MD.MeetingBackgroundDocumentModal.directory.label': 'Ordner für die Ablage des Tagesordnungsdokuments',
  'MD.MeetingBackgroundDocumentModal.filename.label': 'Dateiname für das Dokument',
  'MD.MeetingBackgroundDocumentModal.filename.label.initial.part': 'Zusammenfassende Grundlage der Sitzung des ZK',
  'MD.MeetingBackgroundDocumentModal.dirName.label': 'Name des Besprechungsordners',
  'MD.MeetingBackgroundDocumentModal.progress': 'Verfahren zur Vorbereitung von Dokumenten: {progress}%',
  'MD.MeetingBackgroundDocumentModal.button.download': 'Laden Sie das vorbereitete Tagesordnungsdokuments herunter',
  'MD.MeetingBackgroundDocumentModal.button.recreate': 'Das Dokument neu vorbereiten',
  'MD.MeetingBackgroundDocumentModal.error.generationFailed': 'Die Dokumentenvorbereitung wurde mit einem Fehler beendet. Bitte überprüfen Sie Ihre Projekteinstellungen und versuchen Sie erneut, das Dokument zu generieren oder wenden Sie sich an den Administrator.',
  'MD.MeetingBackgroundDocumentModal.error.generationFailedWithRetry': 'Das generierte Dokument wurde mit einem Fehler beendet und es wird ein neuer Versuch unternommen, es zu verarbeiten. Bitte überprüfen Sie die Projekteinstellungen für das Meeting.',
  'MD.Projects.ProjectReportPage.title': 'Zugelassene Materialien',
  'MD.Projects.ProjectCreateModal.title': 'Neues Material',
  'MD.Projects.ProjectCreateModal.creating.title': 'Das Projekt befindet sich im Aufbau',
  'maskInput.letters': 'Charaktere',
  'maskInput.metadata': 'Metadaten',
  'maskInput.text': 'Text',
  'maskInput.number': 'Ziffer',
  'maskInput.specialCharacters': 'Sonderzeichen',
  'mask.metadata.folderName': 'Ordnername',
  'MD.Project.ActionCharacter.investment': 'Investition',
  'MD.Project.ActionCharacter.nonInvestment': 'Keine Investition',
  'MD.Project.ActionCharacter.other': 'Andere',
  'MD.Projects.ProjectCreateModal.form.name.required': 'Name ist erforderlich',
  'MD.Projects.ProjectCreateModal.form.hubProjectName': 'Der Name des AspeHub-Projekts',
  'MD.Projects.ProjectCreateModal.form.phase': 'Zustand',
  'MD.Projects.ProjectCreateModal.form.phase.required': 'Der Status ist obligatorisch',
  'MD.Projects.ProjectCreateModal.form.role': 'Gründerrollen',
  'MD.Projects.ProjectCreateModal.form.role.required': 'Die Gründungsrolle ist zwingend erforderlich',
  'MD.Projects.ProjectCreateModal.form.template': 'Vorlage',
  'MD.Projects.ProjectCreateModal.form.template.required': 'Die Vorlage ist erforderlich',
  'MD.Projects.ProjectCreateModal.form.ckIdentification': 'Identifizierung von ZK',
  'MD.Projects.ProjectCreateModal.form.ckIdentification.required': 'ZK-Identifizierung ist obligatorisch',
  'MD.Projects.ProjectCreateModal.form.submissionDate': 'Datum der Einreichung',
  'MD.Projects.ProjectCreateModal.success': 'Das Material wurde erfolgreich erstellt',
  'MD.ProjectsCreateApprovalModal.filter.current.title': 'Aktuelle',
  'MD.ProjectsCreateApprovalModal.filter.current.description': 'Materialien, die als aktuell gekennzeichnet sind',
  'MD.ProjectsCreateApprovalModal.filter.current.yes': 'Ja - nur aktuelle Materialien',
  'MD.ProjectsCreateApprovalModal.filter.current.no': 'Nein - nur Materialien mit einer veröffentlichten Aktualisierung',
  'MD.ProjectsCreateApprovalModal.title': 'Erstellen Sie eine Materialbasis für ZK',
  'MD.ProjectsCreateApprovalModal.form.meetingNumber': 'Seriennummer',
  'MD.ProjectsCreateApprovalModal.form.meetingNumber.required': 'Die Seriennummer des Meetings ist zwingend erforderlich',
  'MD.ProjectsCreateApprovalModal.form.meetingNumber.unique': 'Diese Nummer ist bereits vergeben',
  'MD.ProjectsCreateApprovalModal.form.date': 'Datum des Treffens',
  'MD.ProjectsCreateApprovalModal.form.date.required': 'Der Termin für das Treffen ist verbindlich',
  'MD.ProjectsCreateApprovalModal.form.timeRange': 'Zeitbereich',
  'MD.ProjectsCreateApprovalModal.form.timeRange.required': 'Die Besprechungszeit ist obligatorisch',
  'MD.ProjectsCreateApprovalModal.form.note': 'Notiz',
  'MD.ProjectsCreateApprovalModal.form.note.maxLength': 'Der eingegebene Wert ist zu lang. Die maximale Länge beträgt {max} Zeichen.',
  'MD.ProjectsCreateApprovalModal.form.referenceCode': 'Referenzcode',
  'MD.ProjectsCreateApprovalModal.form.referenceCode.required': 'Referenzcode ist obligatorisch',
  'MD.ProjectsCreateApprovalModal.form.referenceCode.maxLength': 'Der Referenzcode ist zu lang. Die maximale Länge beträgt {max} Zeichen.',
  'MD.ProjectsCreateApprovalModal.from.projectSelection': 'Auswahl der Materialien zur Genehmigung',
  'MD.ProjectsCreateApprovalModal.from.projectSelection.required': 'Wählen Sie mindestens ein Projekt zur Genehmigung aus',
  'MD.MDcreateProject.ckIdentification.other': 'Andere',
  'MD.MDcreateProject.ckIdentification.railway': 'Eisenbahn',
  'MD.MDcreateProject.ckIdentification.road': 'Straße',
  'MD.MDcreateProject.ckIdentification.water': 'Wasser',
  'MD.Projects.ProjectCardModal.title': 'ZK-Materialkarte',
  'MD.Projects.ProjectCardModal.section.header.title': 'Grundlegende Informationen',
  'MD.Projects.ProjectCardModal.button.transfer.o910': 'Weiter zu O910',
  'MD.Projects.ProjectCardModal.button.transfer.division': 'Bei der Abteilung einreichen',
  'MD.Projects.ProjectCardModal.button.transfer.guarantor': 'Dem Bürgen geben',
  'MD.Projects.ProjectCardModal.button.setDivision': 'Abteilung einstellen',
  'MD.Projects.ProjectCardModal.button.returnToSubmitter': 'Zurück zum Einreicher',
  'MD.Projects.ProjectCardModal.button.returnToSubmitter.disable.notGuarantor': 'Sie sind nicht der Garant für das Material',
  'MD.Projects.ProjectCardModal.button.returnToSubmitter.disable.isPreparedForCkAproval': 'Das Material ist für die ZK-Zulassung vorgesehen',
  'MD.Projects.ProjectCardModal.button.returnToO910': 'Zurück zu Abteilung O910',
  'MD.Projects.ProjectCardModal.button.returnToO910.disable.isNotProjectSupervisorRole': 'Sie sind nicht der Leiter der Abteilung',
  'MD.Projects.ProjectCardModal.button.returnToO910.disableReason.submittedFor.notSet': 'Das Feld „Gesendet“ ist nicht festgelegt',
  'MD.Projects.ProjectCardModal.button.returnToO910.disableReason.processorDivision.notSet': 'Abteilung ist nicht eingestellt',
  'MD.Projects.ProjectCardModal.button.returnToO910.disableReason.guarantor.notSet': 'Der Garant ist nicht festgelegt',
  'MD.Projects.ProjectCardModal.button.returnToO910.disableReason.unexpectedMdRoleType': 'Sie sind nicht in der erforderlichen Rolle',
  'MD.Projects.ProjectCardModal.button.returnToO910.disableReason.unexpectedProjectState': 'ZK-Material ist nicht die erforderliche Stufe',
  'MDReturnMaterialModal.returnToSubmitter.confirmText': 'Wollen Sie das Material wirklich mit dieser Begründung an den Einsender zurückschicken?',
  'MDReturnMaterialModal.returnToO910.confirmText': 'Wollen Sie das Material wirklich mit dieser Begründung an O910 zurückschicken?',
  'MD.Projects.ProjectCardModal.button.setGuarantor': 'Bürge festlegen',
  'MD.Projects.ProjectCardModal.button.commentingDivisions': 'Erinnerungsgewerkschaften',
  'MD.Projects.ProjectCardModal.button.save': 'Änderungen speichern',
  'MD.Projects.ProjectCardModal.button.delete.confirm': 'Möchten Sie dieses ZK-Material wirklich entfernen? Mit dieser Aktion wird das angehängte ZK-Material mit Dateien zusammen mit der ZK-Materialkarte gelöscht.',
  'MD.Projects.ProjectCardModal.form.name.required': 'Der Name des ZK-Materials ist obligatorisch',
  'MD.Projects.ProjectCardModal.form.name.duplicate': 'Der Materialname ZK wird bereits verwendet',
  'MD.Projects.ProjectCardModal.form.projectProposer.label': 'Er gründete',
  'MD.Projects.ProjectCardModal.form.guarantor.label': 'Garant',
  'MD.Projects.ProjectCardModal.form.division.label': 'Abteilung',
  'MD.Projects.ProjectCardModal.form.phase.label': 'Zustand',
  'MD.Projects.ProjectCardModal.input.price.suffix': 'CZK ohne MwSt.',
  'MD.Projects.ProjectCardModal.section.projectData.title': 'ZK-Materialdaten',
  'MD.Projects.ProjectCardModal.section.commission.title': 'Zentralkommission',
  'MD.Projects.ProjectCardModal.stateTransfer.divisionSetting.title': 'Leiten Sie das ZK-Material an O910 weiter',
  'MD.Projects.ProjectCardModal.stateTransfer.divisionSetting.description': 'Beenden Sie die Bearbeitung der Karte und übertragen Sie das ZK-Material zur Verarbeitung an O910.',
  'MD.Projects.ProjectCardModal.stateTransfer.guarantorSetting.title': 'Senden Sie das ZK-Material an die Abteilung',
  'MD.Projects.ProjectCardModal.stateTransfer.guarantorSetting.description': 'Das ZK-Material wird zur Zuweisung eines Bürgen an die ausgewählte Abteilung weitergeleitet',
  'MD.Projects.ProjectCardModal.stateTransfer.projectValidation.title': 'Übergeben Sie das ZK-Material an den Bürgen',
  'MD.Projects.ProjectCardModal.stateTransfer.projectValidation.description': 'Stellt dem ausgewählten Bürgen die Verwaltung des ZK-Materials zur Verfügung.',
  'MD.Projects.ProjectCardModal.warning.overviewProjectNotSet': 'Das Gemeinkostenprojekt ist nicht festgelegt',
  'MD.Projects.ProjectDivisionSetModal.title': 'Aufbau der ZK-Materialsparte',
  'MD.Projects.ProjectDivisionSetModal.division.current.label': 'Aktuelle Abteilung: {division}',
  'MD.Projects.ProjectDivisionSetModal.division.label': 'Abteilung',
  'MD.Projects.ProjectDivisionSetModal.division.required': 'Abteilung ist obligatorisch',
  'MD.Projects.ProjectGuarantorSetModal.title': 'Festlegen des Materialgaranten ZK',
  'MD.Projects.ProjectGuarantorSetModal.role.current.label': 'Aktuelle Rolle des Bürgen:',
  'MD.Projects.ProjectGuarantorSetModal.role.label': 'Die Rolle des Bürgen',
  'MD.Projects.ProjectGuarantorSetModal.role.required': 'Die Rolle des Bürgen ist obligatorisch',
  'MD.Projects.ProjectCommentingDivisionsSetModal.title': 'Erinnerungsgewerkschaften',
  'MD.Projects.ProjectCkApprovalReadinesSetModal.title': 'Muss von der ZK genehmigt werden',
  'MD.Projects.ProjectCkApprovalReadinesSetModal.title.prerequisites.referenceNumber': 'Zur Einstellung der Bereitschaft ist eine Transaktionsnummer erforderlich',
  'MD.Projects.ProjectCkApprovalReadinesSetModal.title.prerequisites.ckBackgroundDocuments': 'Um die Bereitschaft herzustellen, ist das Hochladen von Dokumenten für die Sitzung der ZK erforderlich',
  'MD.Projects.ProjectCommentingDivisionsSetModal.division.label': 'Gewerkschaften',
  'MD.Projects.submittedFor.forApproval': 'Zur Genehmigung',
  'MD.Projects.submittedFor.forInformation': 'Als Referenz',
  'MD.Projects.MDSetCkPreparedModal.preparedForCkApproval.label': 'ZK-Material für die ZK-Zulassung vorbereitet',
  'MD.Projects.ckStatement.acknowledged': 'Er nimmt es zur Kenntnis',
  'MD.Projects.ckStatement.acknowledgedWithSuggestion': 'Notizen mit Empfehlung',
  'MD.Projects.ckStatement.approved': 'Er stimmt zu',
  'MD.Projects.ckStatement.approvedWithConditions': 'Genehmigt mit Bedingung/en',
  'MD.Projects.ckStatement.rejected': 'Er weigert sich',
  'MD.Projects.ckStatement.returnedForRework': 'Kehrt zur Nacharbeit zurück',
  'MD.Projects.ckStatement.interrupted': 'Unterbrochen von',
  'MD.Projects.materialPhases.columnTitle': 'Materielle Phase',
  'MD.Projects.materialPhases.new': 'Neues Material',
  'MD.Projects.materialPhases.submittedToO910': 'An O910 gesendet',
  'MD.Projects.materialPhases.forwardedToDivision': 'Weitergeleitet an die Abteilung',
  'MD.Projects.materialPhases.forwardedToGuarantor': 'Weitergeleitet an den Bürgen',
  'MD.Projects.materialPhases.CKprocessing': 'Besprochen ZK',
  'MD.Projects.materialPhases.returnedToO910': 'Zurück zu O910',
  'MD.Projects.materialPhases.returnedToProposer': 'Zurück zu Antragsteller',
  'MDProjectApprovalForm.projectIds.current': 'Aktuell',
  'MDProjectApprovalForm.projectIds.all': 'Alle',
  'MD.Organization.divisions.title': 'Gewerkschaften',
  'MD.Organization.divisions.panel.add': 'Abteilung hinzufügen',
  'MD.Organization.divisions.panel.delete.confirm.tooltip': 'Abteilung mit zugewiesenen Benutzern löschen?',
  'MD.Organization.divisions.panel.deleteUser.confirm.tooltip': "Benutzer der Abteilung löschen",
  'MD.Organization.divisions.panel.edit.tooltip': 'Bearbeiten Sie die Abteilung und ihre Benutzer',
  'MD.Organization.divisions.modal.title.create': 'Neue Gewerkschaft',
  'MD.Organization.divisions.modal.title.edit': 'Abteilung bearbeiten',
  'MD.Organization.divisions.modal.input.name': 'Abteilungsname',
  'MD.Organization.divisions.modal.input.description': 'Abteilungsbeschreibung',
  'MD.Organization.divisions.modal.input.organization': 'Organisation',
  'MD.Organization.divisions.modal.input.leader': 'Abteilungsleiter',
  'MD.Organization.divisions.modal.input.leader.required': 'Wählen Sie einen Abteilungsleiter',
  'MD.Organization.divisions.modal.input.users': 'Benutzer',
  'MD.Organization.divisions.modal.input.isProposerOnlyCheck': 'Abteilung nur für Einreicher',
  'MD.Organization.divisionsUsers.panel.addUser': 'Benutzer hinzufügen',
  'MD.Organization.divisionsUsers.panel.addUser.disabledTooltip': 'Die Abteilung hat keinen Abteilungsleiter',
  'MD.Organization.divisionsUsers.panel.title': 'Abteilungsbenutzer',
  'MD.Organization.divisionsUsers.headUserTag': 'Abteilungsleiter',
  'MD.Organization.divisionsUsers.delete.division.title': 'Die Abteilung hat einige Benutzer in der ZK-Rolle oder im Material',
  'MD.Organization.divisionsUsers.delete.division.description': 'Durch das Löschen einer Abteilung werden diese aus den Rollen entfernt:\n{roles}.\nMöchten Sie den Benutzer löschen?',
  'MD.Organization.divisionsUsers.delete.fromRole.title': 'Der Benutzer befindet sich in einer Rolle',
  'MD.Organization.divisionsUsers.delete.fromRole.description': 'Durch das Löschen eines Benutzers werden diese aus den Proxy-Rollen {roles} und {membersCount} entfernt.\n Möchten Sie den Benutzer löschen?',
  'MD.Organization.divisionsUsers.delete.success': 'Der Benutzer wurde erfolgreich aus der Abteilung entfernt.',
  'MD.Organization.divisionsUsers.modal.title.add': 'Benutzer zur Abteilung hinzufügen',
  'MD.Organization.roles.modal.title.create': 'Neuer Benutzer für die Rolle',
  'MD.Organization.roles.modal.title.edit': 'Bearbeiten Sie einen Benutzer in einer Rolle',
  'MD.Organization.roles.modal.selectedDivision': 'Abteilungsrolle',
  'MD.Organization.roles.modal.selectedDivision.notSelected': 'Um eine Abteilung einzurichten, wählen Sie einen Rollenmanager aus',
  'MD.Organization.roles.modal.selectedDivision.moreDivisions': 'Wählen Sie aus mehreren Optionen eine Abteilung aus',
  'MD.Organization.roles.modal.input.leader': 'Führungsrolle',
  'MD.Organization.roles.modal.input.leader.required': 'Die Führungsrolle ist obligatorisch',
  'MD.Organization.roles.modal.input.users': 'Rollenvertreter',
  'MD.Organization.roles.modal.error.headUserDivisionNotFound': 'Benutzer-Lead-Abteilung nicht gefunden',
  'MD.Organization.roles.title': 'Rolle',
  'MD.Organization.roleUsers.panel.title': 'Rollenbenutzer',
  'MD.Organization.roleUsers.panel.add': 'Fügen Sie einer Rolle eine Gruppe von Benutzern hinzu',
  'MD.Organization.roleUser.delete.success': 'Der Benutzer wurde erfolgreich aus der Rolle entfernt.',
  'MD.Organization.roles.panel.delete.confirm.tooltip': 'Den ausgewählten Benutzer aus der Rolle löschen?',
  'MD.Organization.sectionTitle': 'Zulassung von ZK-Materialien',
  'MD.Roles.name.proposer': 'Moderator',
  'MD.Roles.name.O910': 'O910',
  'MD.Roles.name.guarantor': 'Garant',
  'MD.Roles.name.supervisor': 'Abteilungsleiter',
  'MD.Roles.name.processor': 'Kommentarhandler',
  'MD.Roles.name.executive': 'Offizier',
  'MD.Roles.name.ck_organizer': 'Veranstalter ZK',
  'MD.Roles.name.ck_head': 'Vorsitzender ZK',
  'MD.Roles.name.md_admin': 'MD-Administrator',
  'MD.Roles.name.md_preview': 'Vorschau der Materialien',
  'MD.ProjectPhase.other': '0 – Andere',
  'MD.ProjectPhase.init': '1 – Vorbereitung des ZK-Materials',
  'MD.ProjectPhase.study': '2 – Untersuchung von ZK-Material',
  'MD.ProjectPhase.intention': '3 – Zweck des ZK-Materials',
  'MD.ProjectPhase.update': '4 - Aktualisieren',
  'MD.ProjectVariableCondition.condition.title': 'ZK-Zustand',
  'MD.ProjectVariableCondition.deadline.title': 'Frist zur Erfüllung',
  'MD.ProjectVariableCondition.approvalDone.title': 'Vollendet',
  'MD.ProjectVariableCondition.approvalDescription.title': 'Art der Erfüllung',
  'MD.ProjectVariableCondition.addNewRule.button': 'Fügen Sie eine Bedingung hinzu',
  'MD.ProjectVariableCondition.validation.nameRequired': 'Der Erinnerungstext darf nicht leer sein.',
  'MD.ProjectVariableCondition.validation.duplicateName': 'Der Kommentartext muss eindeutig sein.',
  'MD.MDCkConditionsEnum.conditionsMet': 'Vollendet',
  'MD.MDCkConditionsEnum.conditionsNotMet': 'Unerfüllt',
  'MD.MDCkConditionsEnum.withConditions': 'Mit Bedingungen',
  'MD.MDCkConditionsEnum.none': 'Das sind sie nicht',
  'MD.MDUpdatedValueEnum.updated': 'Aktualisiert',
  'MDUpdatedValueEnum.noUpdated': 'Nicht aktualisiert',
  'MDUpdatedValueEnum.noValue': 'Kein Wert',
  'MD.ProjectVariableEnum.projectName.name': 'Name des Materials ZK',
  'MD.ProjectVariableEnum.constructionRegion.name': 'Ort der Umsetzung (Region)',
  'MD.ProjectVariableEnum.stretch.name': 'Streicheln/Strecken',
  'MD.ProjectVariableEnum.documentationLink.name': 'Dokumentation im Repository',
  'MD.ProjectVariableEnum.totalCost.name': 'Gesamtkosten der Veranstaltung ohne MwSt.',
  'MD.ProjectVariableEnum.expectedRealisationTime.name': 'Geschätzte Zeit der Implementierung',
  'MD.ProjectVariableEnum.expectedRealisationTime.from.name': 'Realisierung von',
  'MD.ProjectVariableEnum.expectedRealisationTime.to.name': 'Realisierung bis',
  'MD.ProjectVariableEnum.commentProcedure.name': 'Mahnverfahren',
  'MD.ProjectVariableEnum.ckMeetingBackground.name': 'Grundlage für die Sitzung der ZK der MD',
  'MD.ProjectVariableEnum.ckMeetingMinutes.name': 'Protokoll der Sitzung der ZK MD',
  'MD.ProjectVariableEnum.intentionApprovalClause.name': 'Billigungsklausel',
  'MD.ProjectVariableEnum.ckApprovalDate.name': 'Datum der Diskussion von ZK MD',
  'MD.ProjectVariableEnum.ckApproved.name': 'Besprochen von ZK',
  'MD.ProjectVariableEnum.ckApprovalConditions.name': 'Bedingungen der ZK',
  'MD.ProjectVariableEnum.ckApprovalConditions.met': 'Vollendet',
  'MD.ProjectVariableEnum.ckApprovalConditions.notMet': 'Unerfüllt',
  'MD.ProjectVariableEnum.preparationUpdate.name': 'Update zur Vorbereitung des ZK-Materials',
  'MD.ProjectVariableEnum.investorContactPerson.name': 'Ansprechpartner des Investors',
  'MD.ProjectVariableEnum.studyType.name': 'Studienart',
  'MD.ProjectVariableEnum.actionCharacter.name': 'Der Charakter der Aktion',
  'MD.ProjectVariableEnum.ckIdentificaion.name': 'Identifizierung von ZK',
  'MD.ProjectVariableEnum.hasOpponentsReport.name': 'Bericht des Gegners',
  'MD.ProjectVariableEnum.opponentsReport.name': 'Bericht des Gegners',
  'MD.ProjectVariableEnum.isUPDChangeRequest.name': 'Das Projekt erfordert eine Änderung der Definition im ÚPD',
  'MD.ProjectVariableEnum.intentionUpdated.name': 'Erkennungsprotokoll-Update',
  'MD.ProjectVariableEnum.mdContactPerson.name': 'Ansprechpartner MD',
  'MD.ProjectVariableEnum.isProFond.name': 'ISPROFUND',
  'MD.ProjectVariableEnum.referenceNumber.name': 'Verfahrensnummer',
  'MD.ProjectVariableEnum.preparedForCkApproval.name': 'Für die ZK-Zulassung vorgesehen',
  'MD.ProjectVariableEnum.submittedFor.name': 'Eingereicht',
  'MD.ProjectVariableEnum.ckStatement.name': 'ZK-Erklärung',
  'MD.ProjectVariableLinkModal.directory.title': 'Ordnerauswahl',
  'MD.ProjectVariableLinkModal.document.title': 'Dateiauswahl',
  'MDCkConditionModal.Condition': 'ZK-Zustand',
  'MDCkConditionModal.Deadline': 'Frist zur Erfüllung',
  'MDCkConditionModal.ApprovalDone': 'Vollendet',
  'MDCkConditionModal.ApprovalDescription': 'Art der Erfüllung',
  'MDCkConditionModal.title': 'Zulassungsbedingungen',
  'menu.side.comment.procedure': 'Mahnverfahren',
  'menu.side.AspeHubSetting': 'AspeHub-Einstellungen',
  'menu.side.hubAdministrators': 'Administratoren',
  'menu.side.hubOrganizations': 'Organisation',
  'menu.side.hubUsers': 'Benutzer',
  'menu.side.organizationsSetting': 'Organisationseinstellung',
  'menu.side.organizations.setting': 'Organisationseinstellung',
  'menu.side.dashboard': 'Dashboard',
  'menu.side.search': 'Suche im Projekt',
  'menu.side.settings': 'Projekteinstellung',
  'menu.side.projects': 'Projekte',
  'menu.side.projectsGrid': 'Projektdaten',
  'menu.side.reporting': 'Reports',
  'menu.side.mdProjects': 'ZK-Materialien',
  'menu.side.mdOverheadProject': 'Zentrale Kommission',
  'menu.side.documents': 'Dokumenten',
  'menu.side.documents.all': 'Alles',
  'menu.side.documents.category': 'Kategorie',
  'menu.side.documents.favorite': 'Favoriten',
  'menu.side.documents.recent': 'Unlängst',
  'menu.side.documents.smart': 'SMART',
  'menu.side.documents.filters': 'Filter',
  'menu.side.toggleMenu': 'Menu maximieren',
  'menu.side.minimizeMenu': 'Menu minimieren',
  'menu.side.model': 'Modell',
  'menu.side.changeProject': 'Projekt ändern',
  'menu.side.workflow': 'Workflow',
  'menu.side.workflow.instances': 'Gestartet',
  'menu.side.workflow.templates': 'Schablonen',
  'menu.side.workflow.processes': 'Prozesse',
  'menu.side.budget': 'Budget',
  'menu.side.construction': 'AspeEsticon',
  'menu.side.drawing': 'Pumpen',
  'menu.side.invoicing': 'Abrechnung',
  'menu.side.zbv': 'ÄdB',
  'menu.side.schedule': 'Harmonogramm',
  'menu.side.financialPlan': 'Finanzplan',
  'menu.side.constructionJournal': 'Bautagebuch',
  'menu.side.documents.discarded': 'Korb',
  'menu.side.externalApplications': 'Externe Applikation',
  'MessageCenterAssignments.createAssignment': 'Eine neue Aufgabe',
  'MessageCenterAssignments.createdDate': 'Erstellungsdatum',
  'MessageCenterAssignments.deadlineDate': 'Erforderlicher Begriff',
  'MessageCenterAssignments.resolvedDate': 'Fertigstellungsdatum',
  'MessageCenterAssignments.source': 'Rolle',
  'MessageCenterAssignments.source.title': 'Meine Rolle bei der Aufgabe',
  'MessageCenterAssignments.showOnlyMyAssignments': 'Nur meine Aufgaben anzeigen',
  'MessageCenterAssignments.switchButtons.showAll': 'Alle',
  'MessageCenterAssignments.switchButtons.onlyMy': 'Nur meins',
  'MessageCenterAssignments.goToSettings': 'Gehen Sie zu den Organisationseinstellungen',
  'MessageCenterPage.tabs.assignments': 'Aufgaben',
  'MessageCenterPage.tabs.messages': 'Nachricht',
  'MessageCenterPage.tabs.report': 'Bericht',
  'MessageCenterPage.tabs.settings': 'Einstellungen',
  'MessageCenterPage.title': 'Nachrichtencenter',
  'MessageCenterPage.filters.category.label': 'Kategorie',
  'MessageCenterPage.filters.categoryType.label': 'Typ',
  'MessageCenterPage.filters.createDateRange.label': 'Erstellt',
  'MessageCenterPage.filters.finishDateRange.label': 'Fertig',
  'MessageCenterPage.filters.state.label': 'Zustand',
  'MessageCenterPage.filters.project.label': 'Projekt',
  'MessageCenterPage.order.createTime.label': 'Schöpfungszeit',
  'MessageCenterPage.order.finishTime.label': 'Endzeit',
  'MessageCenterPage.order.status.label': 'Zustand',
  'MessageCenterPage.order.category.label': 'Kategorie',
  'MessageCenterPage.order.project.label': 'Projekt',
  'MessageCenterPage.message.categoryType.assignment': 'Aufgabe',
  'MessageCenterPage.message.categoryType.commentProcedure': 'Mahnverfahren',
  'MessageCenterPage.message.categoryType.workflow': 'Arbeitsablauf',
  'MessageCenterPage.message.categoryType.appFileShare': 'Teilen',
  'MessageCenterPage.message.categoryType.alert': 'Warnung',
  'MessageCenterPage.message.categoryType.bulkAction': 'Massenaktion',
  'MessageCenterPage.message.categoryType.subscriber': 'Verfolgung',
  'MessageCenterPage.message.categoryType.accountNotification': 'Projekt',
  'MessageCenterPage.message.categoryType.document': 'Unterlagen',
  'MessageCenterPage.message.categoryType.document.filter': 'Unterlagen',
  'MessageCenterPage.message.categoryType.demandMsg': 'Benutzerhinweis',
  'MessageCenterPage.message.category.wfApprovalDocumentsShared.filter': 'Genehmigte Workflow-Dokumente werden geteilt',
  'MessageCenterPage.message.category.wfApprovalDocumentsShared': 'Genehmigte Workflow-Dokumente werden geteilt',
  'MessageCenterPage.message.category.wfDeadlineExtensionApprovalRequested': 'Für die WF-Aufgabe wurde eine Fristverlängerung beantragt',
  'MessageCenterPage.message.category.wfDeadlineExtensionRequestSent': 'Die Anfrage zur Fristverlängerung der Aufgabe wurde gesendet',
  'MessageCenterPage.message.category.wfDeadlineExtensionApproved': 'Der Antrag auf Fristverlängerung für die Aufgabe wurde bearbeitet',
  'MessageCenterPage.message.category.wfDeadlineExtensionApprovalRequested.filter': 'Für die WF-Aufgabe wurde eine Fristverlängerung beantragt',
  'MessageCenterPage.message.category.wfDeadlineExtensionRequestSent.filter': 'Die Anfrage zur Fristverlängerung der Aufgabe wurde gesendet',
  'MessageCenterPage.message.category.wfDeadlineExtensionApproved.filter': 'Der Antrag auf Fristverlängerung für die Aufgabe wurde bearbeitet',
  'MessageCenterPage.message.category.assignmentDeadlineExpired': 'Die Aufgabe ist abgelaufen',
  'MessageCenterPage.message.category.assignmentDeadlineExpired.filter': 'Die Aufgabe ist abgelaufen',
  'MessageCenterPage.message.category.assignmentDeadlineWarning': 'Die Frist in der Aufgabe läuft bald ab.',
  'MessageCenterPage.message.category.assignmentDeadlineWarning.filter': 'Die Frist in der Aufgabe läuft bald ab.',
  'MessageCenterPage.message.category.assignmentDocumentsChanged': 'Das Dokument in der Aufgabe hat sich geändert.',
  'MessageCenterPage.message.category.assignmentStarted': 'Eine Aufgabe wurde erstellt.',
  'MessageCenterPage.message.category.assignmentChanged': 'Die Zuordnung wurde geändert',
  'MessageCenterPage.message.category.assignmentUsersChanged': 'Aufgabenbenutzer wurden geändert.',
  'MessageCenterPage.message.category.assignmentStateChanged': 'Der Status der Aufgabe hat sich geändert.',
  'MessageCenterPage.message.category.assignmentUserAdded': 'Benutzer zur Aufgabe hinzugefügt.',
  'MessageCenterPage.message.category.assignmentUserRemoved': 'Benutzer aus der Aufgabe entfernt.',
  'MessageCenterPage.message.category.assignmentNoteAdded': 'Der Aufgabe wurde ein neuer Kommentar hinzugefügt',
  'MessageCenterPage.message.category.assignmentNoteUserNotified': 'Hinweis zur Erwähnung im Aufgabenkommentar',
  'MessageCenterPage.message.category.wfNodeStarted': 'Ihnen wurde im Workflow gerade eine Aufgabe zur Lösung zugewiesen.',
  'MessageCenterPage.message.category.wfNodeLimitWillBeExceeded': 'Die Frist zur Lösung der aktuellen Aufgabe wird bald überschritten sein.',
  'MessageCenterPage.message.category.wfNodeLimitExceeded': 'Die Frist zur Lösung der aktuellen Aufgabe wurde überschritten.',
  'MessageCenterPage.message.category.wfApprovalDocumentsRevisionAdded': 'Eine Überarbeitung wurde zum genehmigten Dokument hochgeladen.',
  'MessageCenterPage.message.category.wfApprovalDocumentAdded': 'Ein neues genehmigtes Dokument wurde hochgeladen.',
  'MessageCenterPage.message.category.wfApprovalDocumentRemoved': 'Ein genehmigtes Dokument wurde entfernt.',
  'MessageCenterPage.message.category.wfWorkflowFinished': 'Der Workflow-Genehmigungsprozess ist beendet.',
  'MessageCenterPage.message.category.wfBackgroundDocumentLimitExceeded': 'Die Frist zum Einfügen des zugrunde liegenden Dokuments wurde überschritten.',
  'MessageCenterPage.message.category.wfBackgroundDocumentLimitWillBeExceeded': 'Die Frist für die Einreichung des Nachweises ist bald überschritten.',
  'MessageCenterPage.message.category.wfBackgroundDocumentRequestStarted': 'Im Workflow-Genehmigungsprozess haben Sie eine aktive Anfrage zum Anhängen eines unterstützenden Dokuments.',
  'MessageCenterPage.message.category.wfBackgroundDocumentAdded': 'Hintergrunddokument wurde beigefügt.',
  'MessageCenterPage.message.category.wfBackgroundDocumentRequestRemoved': 'Die Anforderung, dem Workflow-Genehmigungsprozess ein unterstützendes Dokument beizufügen, wurde gestrichen.',
  'MessageCenterPage.message.category.wfBackgroundDocumentRequestChanged': 'Die Anforderung, dem Workflow-Genehmigungsprozess ein unterstützendes Dokument beizufügen, wurde geändert.',
  'MessageCenterPage.message.category.wfBackgroundDocumentAccepted': 'Das Hintergrunddokument wurde genehmigt.',
  'MessageCenterPage.message.category.wfBackgroundDocumentRefused': 'Das zugrunde liegende Dokument wurde abgelehnt.',
  'MessageCenterPage.message.category.wfNodeFinished': 'Die Aufgabe „Workflow-Genehmigungsprozess“ ist beendet.',
  'MessageCenterPage.message.category.wfWorkflowClosed': 'Der Workflow-Genehmigungsprozess wurde abgeschlossen.',
  'MessageCenterPage.message.category.wfApprovalDocumentsAccepted': 'Genehmigte Dokumente wurden genehmigt.',
  'MessageCenterPage.message.category.wfApprovalDocumentsRefused': 'Genehmigte Dokumente wurden abgelehnt.',
  'MessageCenterPage.message.category.wfNotification': 'Nachrichten vom Benachrichtigungsknoten.',
  'MessageCenterPage.message.category.wfWorkflowNodeNoteAdded': 'Der Workflow-Aufgabe wurde ein Kommentar hinzugefügt.',
  'MessageCenterPage.message.category.wfWorkflowNodeNoteUserNotified': 'Der Benutzer im Job-Workflow-Kommentar wurde gewarnt.',
  'MessageCenterPage.message.category.wfWorkflowNodeAttachmentAdded': 'Anhang zur Workflow-Aufgabe hinzugefügt.',
  'MessageCenterPage.message.category.wfWorkflowNodeAttachmentRemoved': 'Aus der Workflow-Aufgabe wurde ein Anhang entfernt.',
  'MessageCenterPage.message.category.wfWorkflowNodeAttachmentRestored': 'Der Anhang in der Workflow-Aufgabe wurde wiederhergestellt.',
  'MessageCenterPage.message.category.cpCommentAdded': 'Eine Erinnerung wurde hinzugefügt.',
  'MessageCenterPage.message.category.cpDocumentAdded': 'Ein Dokument wurde zur Kommentierung hinzugefügt.',
  'MessageCenterPage.message.category.cpDocumentRemoved': 'Das Dokument wurde aus dem Kommentar entfernt.',
  'MessageCenterPage.message.category.cpStateChanged': 'Der Status des Mahnverfahrens wurde geändert.',
  'MessageCenterPage.message.category.cpCommentStateChangded': 'Der Status des Kommentars im Kommentarverfahren wurde geändert',
  'MessageCenterPage.message.category.cpCommentSubmitterTeamChanged': 'Es gab Änderungen an der Liste der kommentierenden Teams.',
  'MessageCenterPage.message.category.cpCommentProcessorTeamChanged': 'Es gab Änderungen an der Liste der Entwicklerteams für Kommentare.',
  'MessageCenterPage.message.category.cpCommentNoteAdded': 'Der Erinnerung wurde ein Kommentar hinzugefügt.',
  'MessageCenterPage.message.category.cpCommentTextChanged': 'Der Text der Erinnerung wurde geändert.',
  'MessageCenterPage.message.category.cpUserAdded': 'Der Benutzer wurde zum Erinnerungsverfahren hinzugefügt.',
  'MessageCenterPage.message.category.cpUserRemoved': 'Der Benutzer wurde aus dem Erinnerungsverfahren entfernt.',
  'MessageCenterPage.message.category.cpCommentDocumentAdded': 'Ein Dokument wurde zur Kommentierung hinzugefügt.',
  'MessageCenterPage.message.category.cpCommentDocumentRemoved': 'Das Dokument wurde aus dem Kommentar entfernt.',
  'MessageCenterPage.message.category.cpStarted': 'Das Rückrufverfahren hat begonnen.',
  'MessageCenterPage.message.category.cpClosed': 'Das Mahnverfahren ist abgeschlossen.',
  'MessageCenterPage.message.category.cpCommentAttachmentAdded': 'Der Erinnerung wurde ein Anhang hinzugefügt.',
  'MessageCenterPage.message.category.cpCommentAttachmentRemoved': 'Es wurde aus dem Kommentar entfernt hinzugefügt.',
  'MessageCenterPage.message.category.cpCommentNoteUserNotified': 'Der Benutzer im Kommentarkommentar wurde gewarnt.',
  'MessageCenterPage.message.category.cpDeadlineWarning': 'Die Frist für das Stellungnahmeverfahren läuft bald ab.',
  'MessageCenterPage.message.category.cpDeadlineExpired': 'Die Frist im Stellungnahmeverfahren ist abgelaufen.',
  'MessageCenterPage.message.category.cpDeadlineChanged': 'Die Frist im Kommentarverfahren wurde geändert.',
  'MessageCenterPage.message.category.appFileShare': 'Eine Datei/ein Ordner wurde freigegeben.',
  'MessageCenterPage.message.category.alertSign': 'Hinweis zur Unterzeichnung des Dokuments.',
  'MessageCenterPage.message.category.demWorkflowNodeResolveDemand': 'Benutzerhinweis – Arbeitsablauf',
  'MessageCenterPage.message.category.demWorkflowNodeResolveDemand.filter': 'Benutzerhinweis – Arbeitsablauf',
  'MessageCenterPage.message.category.demAssignmentResolveDemand': 'Benutzerwarnung – Aufgabe',
  'MessageCenterPage.message.category.demAssignmentResolveDemand.filter': 'Benutzerwarnung – Aufgabe',
  'MessageCenterPage.message.category.assignmentStarted.filter': 'Erstellen Sie eine Aufgabe',
  'MessageCenterPage.message.category.wfNotification.filter': 'Nachrichten vom Benachrichtigungsknoten',
  'MessageCenterPage.message.category.assignmentStateChanged.filter': 'Den Status einer Aufgabe ändern',
  'MessageCenterPage.message.category.assignmentUserAdded.filter': 'Hinzufügen eines Benutzers zu einer Aufgabe',
  'MessageCenterPage.message.category.assignmentUserRemoved.filter': 'Entfernen Sie einen Benutzer aus einer Aufgabe',
  'MessageCenterPage.message.category.assignmentDocumentsChanged.filter': 'Ändern Sie ein Dokument in einer Aufgabe',
  'MessageCenterPage.message.category.assignmentUsersChanged.filter': 'Änderung der Aufgabenbenutzer',
  'MessageCenterPage.message.category.assignmentChanged.filter': 'Aufgabenzuweisung geändert',
  'MessageCenterPage.message.category.assignmentNoteAdded.filter': 'Kommentar zur Aufgabe hinzugefügt',
  'MessageCenterPage.message.category.assignmentNoteUserNotified.filter': 'Benutzer im Aufgabenkommentar benachrichtigt',
  'MessageCenterPage.message.category.wfNodeStarted.filter': 'Workflow-Aufgabe zugewiesen',
  'MessageCenterPage.message.category.wfNodeLimitWillBeExceeded.filter': 'Das Fälligkeitsdatum in der Workflow-Aufgabe läuft bald ab.',
  'MessageCenterPage.message.category.wfNodeLimitExceeded.filter': 'Workflow-Aufgabe nach Ablauf der Frist',
  'MessageCenterPage.message.category.wfApprovalDocumentsRevisionAdded.filter': 'Laden Sie eine Überarbeitung eines genehmigten Dokuments hoch',
  'MessageCenterPage.message.category.wfApprovalDocumentAdded.filter': 'Laden Sie ein genehmigtes Dokument hoch',
  'MessageCenterPage.message.category.wfApprovalDocumentRemoved.filter': 'Entfernung eines genehmigten Dokuments',
  'MessageCenterPage.message.category.wfWorkflowFinished.filter': 'Der Workflow wird beendet',
  'MessageCenterPage.message.category.wfBackgroundDocumentLimitExceeded.filter': 'Bei der Anforderung des Workflow-Dokuments ist eine Zeitüberschreitung aufgetreten',
  'MessageCenterPage.message.category.wfBackgroundDocumentLimitWillBeExceeded.filter': 'Die Workflow-Dokumentanforderung läuft bald ab',
  'MessageCenterPage.message.category.wfBackgroundDocumentRequestStarted.filter': 'Anforderung zum Anhängen eines Dokuments im Workflow',
  'MessageCenterPage.message.category.wfBackgroundDocumentAdded.filter': 'Hintergrund-Workflow-Dokument beigefügt',
  'MessageCenterPage.message.category.wfBackgroundDocumentRequestRemoved.filter': 'Anforderung eines Workflow-Dokuments abgebrochen',
  'MessageCenterPage.message.category.wfBackgroundDocumentRequestChanged.filter': 'Workflow-Dokumentanforderung geändert',
  'MessageCenterPage.message.category.wfBackgroundDocumentAccepted.filter': 'Hintergrund-Workflow-Dokument genehmigt',
  'MessageCenterPage.message.category.wfBackgroundDocumentRefused.filter': 'Workflow-Hintergrunddokument abgelehnt',
  'MessageCenterPage.message.category.wfNodeFinished.filter': 'Workflow-Aufgabe beendet',
  'MessageCenterPage.message.category.wfWorkflowClosed.filter': 'Der Workflow wurde geschlossen',
  'MessageCenterPage.message.category.wfApprovalDocumentsAccepted.filter': 'Genehmigte Workflow-Dokumente genehmigt',
  'MessageCenterPage.message.category.wfApprovalDocumentsRefused.filter': 'Genehmigte Workflow-Dokumente abgelehnt',
  'MessageCenterPage.message.category.wfWorkflowNodeNoteAdded.filter': 'Kommentar zur Workflow-Aufgabe hinzugefügt',
  'MessageCenterPage.message.category.wfWorkflowNodeNoteUserNotified.filter': 'Benutzer im Kommentar zur Workflow-Aufgabe benachrichtigt',
  'MessageCenterPage.message.category.wfWorkflowNodeAttachmentAdded.filter': 'Anhang zur Workflow-Aufgabe hinzugefügt',
  'MessageCenterPage.message.category.wfWorkflowNodeAttachmentRemoved.filter': 'Anhang aus Workflow-Aufgabe entfernt',
  'MessageCenterPage.message.category.wfWorkflowNodeAttachmentRestored.filter': 'Anhang in Workflow-Aufgabe wiederhergestellt',
  'MessageCenterPage.message.category.cpCommentAdded.filter': 'Eine Erinnerung wurde hinzugefügt',
  'MessageCenterPage.message.category.cpDocumentAdded.filter': 'Dokument zur Kommentierung hinzugefügt',
  'MessageCenterPage.message.category.cpDocumentRemoved.filter': 'Dokument aus Kommentar entfernt',
  'MessageCenterPage.message.category.cpStateChanged.filter': 'Der Status des Mahnverfahrens wurde geändert',
  'MessageCenterPage.message.category.cpCommentStateChangded.filter': 'Kommentarstatus geändert',
  'MessageCenterPage.message.category.cpCommentSubmitterTeamChanged.filter': 'Änderungen an der Liste der Teams, die Kommentare kommentieren',
  'MessageCenterPage.message.category.cpCommentProcessorTeamChanged.filter': 'Änderungen an der Liste der Bearbeitungsteams für Mahnungen',
  'MessageCenterPage.message.category.cpCommentNoteAdded.filter': 'Kommentar zur Erinnerung hinzugefügt',
  'MessageCenterPage.message.category.cpCommentTextChanged.filter': 'Der Text der Erinnerung wurde geändert',
  'MessageCenterPage.message.category.cpUserAdded.filter': 'Benutzer zum Erinnerungsverfahren hinzugefügt',
  'MessageCenterPage.message.category.cpUserRemoved.filter': 'Benutzer aus dem Erinnerungsverfahren entfernt',
  'MessageCenterPage.message.category.cpCommentDocumentAdded.filter': 'Dokument zur Kommentierung hinzugefügt',
  'MessageCenterPage.message.category.cpCommentDocumentRemoved.filter': 'Dokument aus Kommentar entfernt',
  'MessageCenterPage.message.category.cpStarted.filter': 'Das Rückrufverfahren wurde eingeleitet',
  'MessageCenterPage.message.category.cpClosed.filter': 'Das Mahnverfahren ist beendet',
  'MessageCenterPage.message.category.cpCommentAttachmentAdded.filter': 'Anhang für Kommentar hinzugefügt',
  'MessageCenterPage.message.category.cpCommentAttachmentRemoved.filter': 'Anhang aus Kommentar entfernt',
  'MessageCenterPage.message.category.cpCommentNoteUserNotified.filter': 'Der Benutzer wurde im Kommentar benachrichtigt',
  'MessageCenterPage.message.category.cpDeadlineWarning.filter': 'Die Frist für das Stellungnahmeverfahren läuft bald ab.',
  'MessageCenterPage.message.category.cpDeadlineExpired.filter': 'Die Frist im Stellungnahmeverfahren ist abgelaufen',
  'MessageCenterPage.message.category.cpDeadlineChanged.filter': 'Die Frist im Kommentarverfahren wurde geändert',
  'MessageCenterPage.message.category.appFileShare.filter': 'Datei-/Ordnerfreigabe',
  'MessageCenterPage.message.category.alertSign.filter': 'Hinweis zur Unterzeichnung des Dokuments',
  'MessageCenterPage.message.category.bulkActionReportStateChanged': 'Massenänderung von Dokumentzuständen',
  'MessageCenterPage.message.category.bulkActionReportStateChanged.filter': 'Massenänderung von Dokumentzuständen',
  'MessageCenterPage.message.category.bulkActionReportLabelsChanged': 'Massenänderung von Dokumentenetiketten',
  'MessageCenterPage.message.category.bulkActionReportLabelsChanged.filter': 'Massenänderung von Dokumentenetiketten',
  'MessageCenterPage.message.category.documentSubscriber': 'Änderung im überwachten Dokument',
  'MessageCenterPage.message.category.documentSubscriber.filter': 'Änderung im überwachten Dokument',
  'MessageCenterPage.message.category.directorySubscriber.filter': 'Änderung im überwachten Ordner',
  'MessageCenterPage.message.category.directorySubscriber': 'Änderung im überwachten Ordner',
  'MessageCenterPage.message.category.bulkActionReportNotification': 'Zusammenfassender Massenänderungsbericht',
  'MessageCenterPage.message.category.bulkActionReportNotification.filter': 'Zusammenfassender Massenänderungsbericht',
  'MessageCenterPage.message.category.projectAccountStateChange': 'Änderung des Kontostatus für das Projekt',
  'MessageCenterPage.message.category.projectAccountStateChange.filter': 'Einladung zum Projekt, Aktivierung und Deaktivierung des Kontos',
  'MessageCenterPage.message.category.docReleaseReservationAlert': 'Die Reservierung läuft bald ab',
  'MessageCenterPage.message.category.docReservationReleased': 'Die Reservierung ist abgelaufen',
  'MessageCenterPage.message.category.docReleaseReservationAlert.filter': 'Die Reservierung läuft bald ab',
  'MessageCenterPage.message.category.docReservationReleased.filter': 'Die Reservierung ist abgelaufen',
  'MessageCenterPage.message.expireTime': 'Begriff:',
  'MessageCenterPage.message.detail.assignment.title': 'Aufgabenbenachrichtigung',
  'MessageCenterPage.message.detail.bulkChanges.title': 'Benachrichtigung über Massendokumentänderungen',
  'MessageCenterPage.message.detail.bulkChanges.BulkActionReportType.documentStatesChanged': 'Dokumentstatus ändern',
  'MessageCenterPage.message.detail.bulkChanges.BulkActionReportType.documentLabelsAdded': 'Hinzufügen von Registerkarten zu Dokumenten',
  'MessageCenterPage.message.detail.bulkChanges.BulkActionReportType.documentLabelsReplaced': 'Ersetzen von Etiketten auf Dokumenten',
  'MessageCenterPage.message.detail.bulkChanges.BulkActionReportType.documentLabelsRemoved': 'Entfernen von Registerkarten in Dokumenten',
  'MessageCenterPage.message.detail.bulkChanges.newLabels': 'Neue Etiketten',
  'MessageCenterPage.message.detail.bulkChanges.newState': 'Neuer Dokumentstatus',
  'MessageCenterPage.message.detail.bulkChanges.changedDocuments': 'Geänderte Dokumente',
  'MessageCenterPage.message.detail.bulkChanges.unchangedDocuments': 'Unveränderte Dokumente',
  'MessageCenterPage.message.detail.bulkChanges.ignoredDocuments': 'Ignorierte Dokumente',
  'MessageCenterPage.message.detail.bulkChanges.originalState': 'Originalzustand:',
  'MessageCenterPage.message.detail.commentProcedure.title': 'Bekanntmachung über ein Rückrufverfahren',
  'MessageCenterPage.message.detail.document.title': 'Benachrichtigung über ein Ereignis im Zusammenhang mit einem Dokument',
  'MessageCenterPage.message.detail.userNotification.title': 'Benutzerhinweis',
  'MessageCenterPage.message.detail.userNotification.type.workflow': 'Bericht aus der Workflow-Genehmigungsprozessaufgabe',
  'MessageCenterPage.message.detail.userNotification.type.task': 'Aufgabenname',
  'MessageCenterPage.message.detail.subscriber.documentTitle': 'Benachrichtigung über das überwachte Dokument',
  'MessageCenterPage.message.detail.subscriber.directoryTitle': 'Warnung „Überwachter Ordner“.',
  'MessageCenterPage.message.detail.workflow.title': 'Benachrichtigung über den Workflow-Genehmigungsprozess',
  'MessageCenterPage.message.detail.projectAccount.title': 'Einladung zum Projekt, Aktivierung und Deaktivierung des Kontos',
  'MessageCenterPage.message.detail.projectAccount.userAddedToOrganization': '{user} hat Sie zu {organization} eingeladen.',
  'MessageCenterPage.message.detail.projectAccount.userAddedToProject': 'Der Benutzer {user} hat Sie zum Projekt {project} eingeladen.',
  'MessageCenterPage.message.detail.projectAccount.userActivated': 'Der Benutzer {user} hat Ihr Konto für das Projekt {project} aktiviert.',
  'MessageCenterPage.message.detail.projectAccount.userDeactivated': '{user} hat Ihr Konto bei {project} deaktiviert.',
  'MessageCenterPage.message.detail.error.viewer': 'Dieser Nachrichtentyp wird nicht unterstützt',
  'MessageCenterPage.message.detail.grid.assignment': 'Aufgabe',
  'MessageCenterPage.message.detail.grid.assignmentContent': 'Aufgabeninhalt',
  'MessageCenterPage.message.detail.grid.author': 'Autor',
  'MessageCenterPage.message.detail.grid.completeTime': 'Fertigstellungsdatum',
  'MessageCenterPage.message.detail.grid.deadlineTime': 'Gewünschter Fertigstellungstermin',
  'MessageCenterPage.message.detail.grid.document': `{count, plural,
    one {Dokumentieren}
    other {Unterlagen}
    }`,
  'MessageCenterPage.message.detail.grid.organization': 'Organisation',
  'MessageCenterPage.message.detail.grid.applicant': 'Antragsteller',
  'MessageCenterPage.message.detail.grid.approved': 'Genehmigt',
  'MessageCenterPage.message.detail.grid.byDays': 'Anzahl der Tage',
  'MessageCenterPage.message.detail.grid.requestJustification': 'Begründung der Anfrage',
  'MessageCenterPage.message.detail.grid.decisionJustification': 'Begründung der Entscheidung',
  'MessageCenterPage.message.detail.grid.project': 'Projekt',
  'MessageCenterPage.message.detail.grid.solvers': 'Löser',
  'MessageCenterPage.message.detail.grid.workflow': 'Arbeitsablauf',
  'MessageCenterPage.message.detail.grid.commentProcedure': 'Erinnerungskontrolle',
  'MessageCenterPage.message.detail.grid.commentName': 'Kommentar',
  'MessageCenterPage.message.detail.grid.team': 'Team',
  'MessageCenterPage.message.detail.grid.user': 'Benutzer',
  'MessageCenterPage.message.detail.grid.task': 'Rolle',
  'MessageCenterPage.message.detail.grid.expireTime': 'Erforderlicher Begriff',
  'MessageCenterPage.message.detail.grid.backgroundDocument': 'Hintergrunddokument',
  'MessageCenterPage.message.detail.grid.message': 'Nachricht',
  'MessageCenterPage.message.detail.grid.subject': 'Betreff der Nachricht',
  'MessageCenterPage.message.detail.grid.startNodeMessages': 'Aussage aus der Aufgabe',
  'MessageCenterPage.message.detail.grid.oldState': 'Originalzustand',
  'MessageCenterPage.message.detail.grid.newState': 'Neuwertiger Zustand',
  'MessageCenterPage.message.detail.grid.currentState': 'Aktueller Stand',
  'MessageCenterPage.message.detail.grid.noteText': 'Kommentartext',
  'MessageCenterPage.message.detail.grid.shareFolder': 'Freigegebener Ordner',
  'MessageCenterPage.message.detail.grid.shareFiles': 'Geteilte Dateien',
  'MessageCenterPage.message.detail.grid.receiver': 'Empfänger',
  'MessageCenterPage.message.detail.grid.receiverMessage': 'Nachricht',
  'MessageCenterPage.message.detail.grid.removedDocument': `{count, plural,
    one {Entferntes Dokument}
    other {Entfernte Dokumente}
    }`,
  'MessageCenterPage.message.detail.grid.directoryName': 'Ein freigegebener Ordner',
  'MessageCenterPage.message.detail.grid.documentNames': 'Geteilte Dokumente',
  'MessageCenterPage.message.detail.grid.shareMessageFiles': 'Der Benutzer {user} hat einen Link zu Dokumenten ({documentCount}) innerhalb des Projekts {projectName} geteilt',
  'MessageCenterPage.message.detail.grid.shareMessageFolder': 'Der Benutzer {user} hat einen Link zu einem Ordner im Projekt {projectName} geteilt',
  'MessageCenterPage.message.detail.grid.documentPath': 'Pfad zum Dokument',
  'MessageCenterPage.message.detail.grid.expirationReservation': 'Ablaufzeit der maximalen Reservierungszeit',
  'MessageCenterPage.message.detail.grid.taskDeadline': 'Aufgabenfrist',
  'MessageCenterPage.message.detail.grid.taskDeadline.task': 'Aufgabenfrist',
  'MessageCenterPage.message.detail.grid.useNotifiEntry': 'Der Benutzer „{user}“ hat Ihnen die folgende Nachricht gesendet:',
  'MessageCenterPage.message.status.new': 'Nicht gelesen',
  'MessageCenterPage.message.status.read': 'Lesen',
  'MessageCenterPage.message.status.solved': 'Gelöst',
  'MessageCenterPage.message.status.finished': 'Fertig',
  'MessageCenterPage.message.status.ignored': 'Stummgeschaltet',
  'MessageCenterPage.notification.messages': 'Nachricht',
  'MessageCenterPage.notification.tasks': 'Aufgaben',
  'MessageCenterPage.notification.settings': 'Benachrichtigungseinstellungen',
  'MessageCenterPage.settings.menu.default': 'Benutzer-Standardeinstellungen',
  'MessageCenterPage.settings.menu.default.tooltip': 'Diese Einstellung wird für Projekte verwendet, für die keine separaten Nachrichtenoptionen festgelegt sind.',
  'MessageCenterPage.settings.menu.project': 'Einstellungen für das Projekt',
  'MessageCenterPage.settings.menu.project.tooltip': 'Diese Einstellung passt das Senden von Nachrichten für das ausgewählte Projekt an. Die ausgewählten Optionen haben Vorrang vor den Standardeinstellungen.',
  'MessageCenterPage.settings.option.header.email': 'E-Mail',
  'MessageCenterPage.settings.option.header.report': 'Zusammenfassender Bericht',
  'MessageCenterPage.settings.option.header.mute': 'Stumm',
  'MessageCenterPage.settings.option.header.restore': 'Standardeinstellungen wiederherstellen',
  'MessageCenterPage.settings.option.header.email.tooltip': 'Sofortige E-Mail-Benachrichtigung.',
  'MessageCenterPage.settings.option.header.report.tooltip': 'Versenden eines täglichen Berichts über alle Benachrichtigungen in einer E-Mail.',
  'MessageCenterPage.settings.option.header.mute.tooltip': 'Markiert ausgewählte Benachrichtigungen automatisch als gelesen.',
  'MessageCenterPage.settings.option.header.restore.tooltip': 'Verwendet die Standardeinstellungen des Benutzers für die angegebene Aktion.',
  'MessageCenterPage.settings.unmappedCategory.title': 'Andere',
  'MessageCenterPage.settings.useDefaultSetting.button': 'Standard verwenden',
  'MessageCenterPage.settings.usingDefaultSetting.state': 'Verwendet die Standardeinstellung',
  'MessageGrid.changestateButton.markAsRead': 'Als gelesen markieren',
  'MessageGrid.changestateButton.markAsUnread': 'Als ungelesen markieren',
  'MessageGrid.changestateButton.markAllAsRead': 'Alle als gelesen markieren',
  'Metadata.addButton.title': 'Zusätzlichen Datentyp hinzufügen',
  'Metadata.newProperty.title': 'Zusätzlichen Datentyp hinzufügen',
  'Metadata.editProperty.title': 'Bearbeiten Sie den zusätzlichen Datentyp',
  'MetadataForm.noEdit.variableType.description': 'Bei bereits angelegten weiteren Datentypen kann der Variablentyp nicht geändert werden, ggf. einen neuen Datentyp anlegen',
  'MetadataExportImportButton.menu.export.noData': 'Es gibt nichts zu exportieren',
  'MetadataExportImportButton.menu.import.noAccess': 'Sie haben keine Schreibberechtigung oder höher',
  'MetadataExportImportButton.csvFilesOnly': 'Es können nur Dateien im *.csv-Format importiert werden',
  'MetadataExportImportButton.importSuccess': 'Der Import war erfolgreich',
  'MetadataExportImportButton.import.modalTitle': 'Import zusätzlicher Daten',
  'MetadataExportImportButton.export.modalTitle': 'Exportieren zusätzlicher Daten',
  'MetadataExportImportButton.export.withoutNoAccess': 'Überspringen Sie Ordner, für die Sie keine Berechtigung haben',
  'MetadataExportImportButton.description': 'Es wird nur der Import aus *.csv-Dateien unterstützt, mit Trennzeichen – Semikolon (;)',
  'MetadataExportImportButton.import.directoryFails.common': 'Ordner – Werte, die nicht importiert werden konnten',
  'MetadataExportImportButton.import.documentsFails.common': 'Dateien – Werte, deren Import fehlgeschlagen ist',
  'MetadataExportImportButton.import.replaceExisting': 'Vorhandenes überschreiben',
  'MetadataExportImportButton.import.addMissingDefinition': 'Fehlende Metadatendefinition hinzufügen',
  'MetadataExportImportButton.import.deleteMetadataMissingInInImportedFile': 'Löschen von Metadaten, die nicht in der importierten Datei enthalten sind',
  'MetadataExportImportButton.withTree': 'Einschließlich Unterordner',
  'MetadataExportImportButton.csvPath': 'Importierter Ordner: ',
  'MetadataExportImportButton.destinationPath': 'Der Zielordner: ',
  'MetadataExportImportButton.directoryNotInImports': 'Der Ordner ist nicht in der importierten Datei enthalten',
  'MetadataExportImportButton.documentNotInImports': 'Das Dokument ist nicht in der importierten Datei enthalten',
  'MetadataExportImportButton.missingMetadataDefinition': 'Für den Zielordner sind keine Metadaten definiert',
  'MetadataExportImportButton.notImportedDirectoryKeys': 'Nicht importierbare Ordnermetadaten',
  'MetadataExportImportButton.notImportedDocumentKeys': 'Nicht importierbare Dokumentmetadaten',
  'MetadataExportImportButton.table.reason': 'Grund',
  'MetadataExportImportButton.table.path': 'Pfad',
  'MetadataExportImportButton.table.docname': 'Das Dokument',
  'MetadataExportImportButton.table.notImportedKeys': 'Nicht importiert',
  'MetadataExportImportButton.importResult.title': 'Ergebnisse importieren',
  'MetadataExportImportButton.importResult.changedDirectories': 'Geänderte Ordner:',
  'MetadataExportImportButton.importResult.addedDirectoryDefinitions': 'Definitionen für Ordner hinzugefügt:',
  'MetadataExportImportButton.importResult.changedDirectoryValues': 'Geänderte Werte in Ordnern:',
  'MetadataExportImportButton.importResult.unChangedDirectories': 'Unveränderte Ordner:',
  'MetadataExportImportButton.importResult.unChangedDirectoryValues': 'Ordnern mit unveränderten Werten:',
  'MetadataExportImportButton.importResult.removedDirectoryValues': 'Gelöschte Werte in Ordnern:',
  'MetadataExportImportButton.importResult.changedDocuments': 'Geänderte Dokumente:',
  'MetadataExportImportButton.importResult.addedDocumentDefinitions': 'Definitionen für Dokumente hinzugefügt:',
  'MetadataExportImportButton.importResult.changedDocumentValues': 'Geänderte Werte in Dokumenten:',
  'MetadataExportImportButton.importResult.unChangedDocuments': 'Unveränderte Dokumente:',
  'MetadataExportImportButton.importResult.unChangedDocumentValues': 'Dokumente mit unveränderten Werten:',
  'MetadataExportImportButton.importResult.removedDocumentValues': 'Gelöschte Werte in Dokumenten:',
  'MetadataExportImportButton.importFailReason.metadataDefinitionNotFound': 'Der importierte Datentyp wurde nicht gefunden',
  'MetadataExportImportButton.importFailReason.ignoreExisting': 'Der ursprüngliche Wert wurde beibehalten',
  'MetadataExportImportButton.importFailReason.documentNotFound': 'Dokument nicht gefunden',
  'MetadataExportImportButton.importFailReason.directoryNotFound': 'Ordner nicht gefunden',
  'MetadataExportImportButton.importFailReason.documentHasOwner': 'Das Dokument hat einen Administrator',
  'MetadataExportImportButton.importFailReason.accessDenied': 'Sie verfügen nicht über ausreichende Berechtigungen zum Importieren',
  'MetadataExportImportButton.importFailReason.metadataTypeMess': 'Unerwarteter Metadatentyp',
  'MetadataExportImportButton.importFailReason.documentLocationNotFound': 'Dateispeicherort nicht gefunden',
  'MetadataExportImportButton.importFailReason.duplicitDocument': 'Es gibt mehrere Datensätze für das Dokument',
  'MetadataExportImportButton.importFailReason.duplicitDir': 'Es gibt mehrere Einträge für den angegebenen Ordner',
  'MetadataExportImportButton.importFailReason.valueCastError': 'Unerwarteter Wert',
  'MetadataExportImportButton.importFailReason.unexpectedError': 'Unerwarteter Fehler',
  'modalFormConfirmClose.content': 'Es ist möglich, dass die vorgenommenen Änderungen nicht gespeichert werden.',
  'modalFormConfirmClose.title': 'Möchten Sie wirklich gehen?',
  'ModelDetailPageLinks.docToDocLinks': 'Bindungen an das Modell',
  'ModelDetailPageLinks.documentList.button.addSelection.tooltip': 'Dokumentelemente auswählen',
  'ModelDetailPageLinks.bindings.elementsHeader': `{count, plural,
    one {Dokumente des ausgewählten Elements}
    other {Dokumente von # ausgewählten Elementen}
    }`,
  'ModelDetailPageLinks.bindings.noBindingsOnElements': 'Die ausgewählten Elemente haben keine Bindungen',
  'ModelDetailPageLinks.bindings.return.tooltip': 'Zurück zur Liste der Dokumente',
  'ModelDetailPageLinks.bindings.missingElements': `{count, plural,
    =0 {Alle Elemente geladen}
    one {1 Element im aktuellen Modell nicht gefunden}
    few {# Elemente im aktuellen Modell nicht gefunden}
    other {# Elemente im aktuellen Modell nicht gefunden} 
    }`,
  'ModelDetailPage.goToModel': 'Gehen Sie zum Modell',
  'ModelDetailPage.linksClose': 'Links ausblenden',
  'ModelDetailPage.linksOpen': 'Links anzeigen',
  'ModelDetailPage.urlParamParseFail': 'Die Modellliste konnte nicht aus dem URL-Parameter abgerufen werden.',
  'ModelDetailPage.viewByForgeError.message': 'Das Modell kann nicht angezeigt werden.',
  'ModelDetailPage.viewByForgeError.description': 'Dieses Dokument kann nicht als 3D-Modell angezeigt werden.',
  'ModelDetailPage.viewByForgeError.descriptionMultiple': 'Folgende Dokumente können nicht als 3D-Modell angezeigt werden:',
  'ModelDetailPage.viewByForgeError.pleaseWait': 'Wenn Sie das Modell gerade erst hochgeladen haben, wird die Datei wahrscheinlich noch vorbereitet. Bitte versuchen Sie es später noch einmal.',
  'ModelDetailPage.toolbar.addLinks': 'Fügen Sie Links hinzu',
  'ModelDetailPage.toolbar.createRevision': 'Neue Überarbeitung',
  'ModelDetailPage.toolbar.linkElementToDocument': 'Link zum Dokument',
  'ModelDetailPage.toolbar.diff': 'Revisionen vergleichen',
  'ModelDetailPage.toolbar.removeModel': 'Modell entfernen',
  'ModelDetailPage.toolbar.removeModelDiscard': 'Modell entfernen und entsorgen',
  'ModelDetailPage.toolbar.removeModelOnly': 'Entfernen Sie nur das Modell',
  'ModelDetailPage.bindings.removeLink.tooltip': 'Heben Sie die Verknüpfung des Modells mit dem Dokument auf',
  'ModelDetailPage.removeSelectedBindings': 'Entfernen Sie Links zum Dokument',
  'ModelDetailPage.removeSelectedBindings.tooltip': 'Ausgewählte Dokumentverknüpfungen entfernen',
  'ModelDetailPage.removeSelectedBindings.confirm': 'Sind Sie sicher, dass Sie Links zum Dokument entfernen möchten?',
  'ModelDetailPage.invalidElementName': 'Ungültiger Elementname',
  'ModelPage.groupedModelPageTitle': '{count} gepoolte Modelle anzeigen',
  'ModelPage.loading': 'Modelle werden geladen...',
  'ModelPage.newModelModalTitle': 'Neues Modell',
  'ModelPage.showModel': 'Modell ansehen',
  'ModelPage.showGroupedModel': 'Sehen Sie sich das gepoolte Modell an',
  'ModelPage.removeFromModels': 'Aus Modellen entfernen',
  'ModelPage.toolbar.upload': 'Aufzeichnen',
  'ModelsMoveFormModal.destinationDirectoryId.label': 'Zum Ordner',
  'ModelsMoveFormModal.errorsTitle.duplicate': `{count, plural,
    =0 {Alle OK}
    one {Das folgende Modell mit demselben Namen existiert bereits im Zielordner}
    few {Die folgenden Modelle mit demselben Namen existieren bereits im Zielordner}\ n other {Die folgenden Modelle mit den gleichen Namen sind bereits im Zielordner vorhanden}
    }`,
  'ModelsMoveFormModal.errorsTitle.missed': `{count, plural,
    =0 {Alles OK}
    one {Beim Verschieben ist ein Fehler aufgetreten. Es wurden keine Modelle verschoben.}
    few {Beim Verschieben sind Fehler aufgetreten. Es wurden keine Modelle verschoben.}
    other {Beim Verschieben sind Fehler aufgetreten. Es wurden keine Modelle verschoben.}
    }`,
  'ModelsMoveFormModal.moveModels': `{count, plural,
    =0 {0 ausgewählte Modelle aus Ordner verschieben}
    one {1 ausgewähltes Modell aus Ordner verschieben}
    few {# ausgewählte Modelle aus Ordner verschieben}
    other {# ausgewählte Modelle aus Ordner verschieben} 
    }`,
  'ModelProcessingExportFormModal.title': 'Export der Modellverarbeitungsanforderungen für den ausgewählten Zeitraum',
  'ModelProcessingExportFormModal.periodSelection': 'Wahl des Zeitraums',
  'ModelProcessingExportFormModal.buttonTooltip': 'Exporte für den Zeitraum',
  'ModelBindingAddModal.form.documentSelect': 'Dokumente zur Erstellung einer Bindung',
  'ModelBindingAddModal.form.documentSelect.modalTitle': 'Auswahl von Dokumenten zum Erstellen einer Verknüpfung',
  'ModelBindingAddModal.form.elements': 'Ausgewählte Modellelemente',
  'ModelBindingAddModal.form.title': 'Elementbindungen erstellen',
  'ModelBindingAddModal.title': 'Modell mit Dokumenten verknüpfen',
  'ModelBindingAddModal.addBinding.success': 'Dokumentverknüpfungen erfolgreich hinzugefügt',
  'ModelBindingAddModal.addBinding.noDocuments': 'Es wurden keine Dokumente ausgewählt',
  'ModelBindingList.warning.missingElement': 'Das Element für diese Bindung wurde im Modell nicht gefunden',
  'ModelBindingList.documentList.header': 'Links zu Dokumenten',
  'ModelBindingRemoveModal.title': 'Entfernen Sie Links zum Dokument',
  'ModelBindingRemoveModal.submit': 'Entfernen',
  'ModelBindingRemoveModal.form.documentSelect': 'Auswahl der zu entfernenden Dokumente',
  'ModelElementsExport.element': 'Element',
  'ModelElementsExport.categoryName': 'Kategorien von Attributs',
  'ModelElementsExport.attributeName': 'Name des Attributs',
  'ModelElementsExport.attributeValue': 'Wert des Attributs',
  'ModelElementsExport.attributeUnits': 'Attribut Einheit',
  'MultipleRolesListSelect.addManager': 'Fügen Sie eine Rolle hinzu',
  'MultipleRolesListSelect.removeManager': 'Rolle entfernen',
  'MultipleRolesListSelect.noAvailableRole': 'Keine Rolle verfügbar',
  'NoApplicableNewDocumentStateAlert.title': 'Es kann kein neues Dokument hochgeladen werden',
  'NoApplicableNewDocumentStateAlert.description': 'In Ihrem Benutzerprofil für dieses Projekt ist kein anwendbarer Status zum Hochladen von Dokumenten aktiviert. Bitte wenden Sie sich an den Projektadministrator.',
  'NodeCommonInfo.deadlineExtension.requestButton': 'Beantragen Sie eine Verlängerung der Aufgabenfrist',
  'NodeCommonInfo.deadlineExtension.approveButton': 'Entscheiden Sie sich für eine Verlängerung der Aufgabenfrist',
  'NodePortActionTypeEnum.accept': 'Genehmigen',
  'NodePortActionTypeEnum.accepted': 'Genehmigt',
  'NodePortActionTypeEnum.refuse': 'Ablehnen',
  'NodePortActionTypeEnum.refused': 'Abgelehnt',
  'NodePortActionTypeEnum.process': 'Verfahren',
  'NodePortActionTypeEnum.processed': 'Verarbeitet',
  'NodeInPortCommonTitle': 'Eingang',
  'NotificationContext.updateMessage.messages': `{count, plural,
    one {# neue Nachricht}
    few {# neue Nachrichten}
    other {# neue Nachrichten}
    }`,
  'NotificationContext.updateMessage.newAssignments': `{count, plural,
    one {# neue Aufgabe}
    few {# neue Aufgaben}
    other {# neue Aufgaben}
    }`,
  'NotificationContext.updateMessage.updatedAssignments': `{count, plural,
    one {# Aufgabe aktualisiert}
    few {# Aufgaben aktualisiert}
    other {# Aufgaben aktualisiert}
    }`,
  'NotificationContext.updateMessage.notification': `{count, plural,
    one {{message}}
    other {{message} und {lastMessage}}
    }`,
  'notifications.downloadError.description': 'Die Datei konnte nicht heruntergeladen werden.',
  'notifications.error.message': 'Hoppla! Etwas ist schiefgelaufen ??',
  'notifications.reserveError.reserve.description': 'Das Dokument konnte nicht zur Bearbeitung reserviert werden.',
  'notifications.reserveError.release.description': 'Die Dokumentreservierung konnte nicht storniert werden.',
  'notifications.savingError.description': 'Beim Speichern der Daten ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
  'NoticeList.daysLeft': 'Tage übrig',
  'NoticeList.deadline': 'Begriff:',
  'NoticeList.project': 'Projekt:',
  'OrgLabelsList.deleteConfirmModal.description': `{projectCount, plural,
    one {Möchten Sie {label} wirklich löschen und aus {projectCount}-Projekten entfernen?}
    other {Möchten Sie {label} wirklich löschen und aus {projectCount} entfernen? Projekte?}
    }`,
    'OrganizationActiveUsersReportModal.title':'Bericht aktiver Benutzer der Organisation',
    'OrganizationActiveUsersReportModal.orgName':'Organisation: {orgName}',
    'OrganizationActiveUsersReportModal.reportingPeriod':'Überwachter Zeitraum',
  'OrganizationSettings.title': 'Organisationseinstellungen',
  'OrganizationSettingsFormModal.title': 'Einstellungen für {settingsName}',
  'OrganizationSettingsListPanel.report.mdOverheadProject.name': 'Regieprojekt MD',
  'OrganizationSettingsListPanel.report.mdOverheadProject.description': 'Verlinkung des AspeHub-Projekts',
  'OrganizationSettingsListPanel.report.projectsInRealizationPOH.name': 'Baubericht in Bearbeitung',
  'OrganizationSettingsListPanel.report.projectsInRealizationPOH.description': 'Hintergrundfarbe und Standarddatum festlegen',
  'OrganizationUserPermissionProfiles.title': 'Benutzerberechtigungsprofile',
  'OrganizationUserPermissionProfiles.modal.add.title': 'Fügen Sie ein Berechtigungsprofil hinzu',
  'OrganizationUserPermissionProfiles.modal.edit.title': 'Berechtigungsprofil bearbeiten',
  'OrganizationUsersEditFormModal.title': 'Bearbeiten Sie Benutzer in der Organisation',
  'OrganizationUserEditForm.nameSource': 'Die Quelle des Namens',
  'OrganizationUserEditForm.setByOrganization': 'Wird von der Organisation festgelegt',
  'OrganizationUserEditForm.takeByUserSetting': 'Übernehmen Sie die Benutzereinstellungen',
  'OrganizationUserEditForm.firstNameLastName': 'Vor- und Nachname',
  'OrganizationUserEditForm.namesDisplayValue': 'Der angezeigte Wert',
  'OrganizationUserEditForm.jobPosition': 'Arbeitsposition',
  'OrganizationUserEditForm.namesNotSetValue': 'Der Wert für Vor- und Nachname ist nicht festgelegt',
  'OrganizationUserClaim.claimType.company': 'Firma:',
  'OrganizationUserClaim.claimType.phonenumber': 'Telefon:',
  'OrgProjectCardData.title': 'Zusätzliche Projektdaten',
  'OrgProjectCardData.units': 'Einheiten',
  'OrgProjectCardData.addSupplementData': 'Fügen Sie zusätzliche Informationen hinzu',
  'OrgProjectCardData.editSupplementData': 'Bearbeiten Sie zusätzliche Daten',
  'OrgProjectCardData.delete.confirmed': 'Zusätzliche Daten erfolgreich entfernt',
  'OrgProjectCardData.delete.confirmTitle': 'Zusätzliche Daten entfernen',
  'OrgProjectCardData.delete.confirmDelete': 'Zusätzliche Daten werden aus allen Projekten der Organisation entfernt. Möchten Sie die zusätzlichen Daten wirklich entfernen?',
  'OrgProjectCardData.form.rules.name.required': 'Name ist erforderlich',
  'OrgProjectCardData.form.rules.name.maxLength': 'Die maximale Namenslänge beträgt {max} Zeichen',
  'OrgProjectCardData.form.rules.variableType': 'Der Werttyp',
  'OrgProjectCardData.form.rules.variableType.required': 'Der Werttyp ist erforderlich',
  'OrgProjectCardData.form.rules.defaultValue': 'Standardwert',
  'ProjectCardData.form.rules.value': 'Wert',
  'Panel.addCategory.tooltip': 'Kategorie hinzufügen',
  'Panel.addCategoryNode.tooltip': 'Knoten hinzufügen',
  'Panel.addGroup.tooltip': 'Fügen Sie eine Gruppe hinzu',
  'Panel.addGroupUser.tooltip': 'Benutzer hinzufügen',
  'Panel.AddLabel.tooltip': 'Fügen Sie ein Etikett hinzu',
  'Panel.AddRole.tooltip': 'Fügen Sie eine Rolle hinzu',
  'Panel.addUser.tooltip': 'Benutzer hinzufügen',
  'Panel.addUserToTeam.tooltip': 'Hinzufügen eines Benutzers zu einem Team',
  'RolePanel.Modal.CannotRemove.title': 'Die Rolle kann nicht gelöscht werden',
  'Popconfirm.deleteItem': 'Sind Sie sicher, dass Sie das Element löschen möchten?',
  'PrimaryFileInput.collapse.hint': 'Klicken oder ziehen Sie die Datei hierher',
  'PrimaryFileInput.collapse.text': 'Wählen Sie die Primärdatei aus',
  'CommonFilesInput.remove': 'Aus Liste entfernen',
  'CommonFilesInput.recordVideo': 'Laden Sie ein Video hoch',
  'CommonFilesInput.recordAudio': 'Ton aufnehmen',
  'CommonFilesInput.takePicture': 'Machen Sie ein Foto',
  'Capture.record.delete': 'Datensatz löschen',
  'Capture.record.confirm': 'Akzeptieren',
  'CommonFilesInput.record.filenamePrefix': 'Aufzeichnen',
  'CommonFilesInput.hint': 'Klicken oder ziehen Sie Dateien und Ordner in diesen Bereich.',
  'CommonFilesInput.invalidNamesByMasks.label': 'Einige Dateien und Ordner haben Namen, die nicht mit den für den Ordner festgelegten Masken übereinstimmen',
  'CommonFilesInput.statistics.withErrors.tooltip': 'Ordner und Dateien mit Fehlern:',
  'CommonFilesInput.statistics.duplicateDocuments.tooltip': 'Doppelte Dateien:',
  'CommonFilesInput.statistics.duplicateFolders.tooltip': 'Doppelte Ordner:',
  'CommonFilesInput.statistics.newRevisions.tooltip': 'Neue Überarbeitungen:',
  'CommonFilesInput.statistics.invalidRevisions.tooltip': 'Revision kann nicht erstellt werden:',
  'CommonFilesInput.button.remove': 'Alle aus der Liste entfernen',
  'CommonFilesInput.button.include': 'Beziehen Sie alle in die Auswahl ein',
  'CommonFilesInput.button.exclude': 'Alle von der Auswahl ausschließen',
  'CommonFilesInput.button.excludeInvalidNames': 'Alles mit falschem Namen ausschließen',
  'CommonFilesInput.button.excludeWithoutAccess': 'Ohne Schreibberechtigung alles ausschließen',
  'CommonFilesInput.button.removeInvalidNames': 'Entfernen Sie alles mit dem falschen Namen',
  'CommonFilesInput.button.removeWithoutAccess': 'Alle ohne Schreibberechtigung entfernen',
  'CommonFilesInput.button.uploadAllAsNewDocuments': 'Laden Sie alle als neue Dokumente hoch',
  'CommonFilesInput.button.uploadAllAsRevisions': 'Laden Sie alle als Revisionen hoch',
  'CommonFilesInput.button.uploadAsNewDocument': 'Als neues Dokument hochladen',
  'CommonFilesInput.button.uploadAsRevision': 'Als Revision hochladen',
  'CommonFilesInput.duplicateFolders.buttonLabel': 'Entfernen Sie doppelte Ordner.',
  'CommonFilesInput.duplicateFolders.text': 'Einige Ordner sind bereits vorhanden und werden automatisch zusammengeführt.',
  'CommonFilesInput.duplicateFolders.tooltip': 'Ein Ordner mit diesem Namen existiert bereits.',
  'CommonFilesInput.duplicateDocuments.buttonLabel': 'Entfernen Sie doppelte Dateien.',
  'CommonFilesInput.duplicateDocuments.text': 'Es gibt bereits Dokumente mit den Namen einiger registrierter Brüder. Die Dateien werden als neue Dokumente hochgeladen und automatisch umbenannt.',
  'CommonFilesInput.duplicateDocuments.tooltip': 'Ein Dokument mit diesem Namen existiert bereits.',
  'CommonFilesInput.revision.tooltip': 'Das Dokument wird als Revision hochgeladen.',
  'CommonFilesInput.notWritableDocument.tooltip': 'Sie haben keine Berechtigung zum Erstellen dieser Datei.',
  'CommonFilesInput.notWritableDirectory.tooltip': 'Sie haben keine Berechtigung zum Erstellen dieses Ordners.',
  'CommonFilesInput.invalidDirectoryName.tooltip': 'Der Ordner hat einen ungültigen Namen.',
  'CommonFilesInput.invalidDocumentName.tooltip': 'Die Datei hat einen ungültigen Namen.',
  'CommonFilesInput.invalidNameByMask.tooltip': 'Der Dateiname stimmt nicht mit der für den Ordner festgelegten Maske überein.',
  'CommonFilesInput.notWritable.buttonLabel': 'Entfernen Sie nicht beschreibbare Dateien und Ordner.',
  'CommonFilesInput.notWritable.text': 'Sie verfügen nicht über die erforderlichen Berechtigungen zum Erstellen einiger Ordner und Dateien. Ändern Sie die Berechtigungen für den übergeordneten Ordner oder entfernen Sie sie aus der Upload-Liste.',
  'CommonFilesInput.invalidName.text': 'Einige Ordner oder Dateien haben keinen gültigen Namen. Benennen Sie sie um und laden Sie sie erneut hoch oder entfernen Sie sie aus der Upload-Liste.',
  'CommonFilesInput.invalidName.buttonLabel': 'Entfernen Sie Ordner und Dateien mit ungültigem Namen.',
  'CommonFilesInput.blockedRevisions.text': 'Einige Revisionen können nicht erstellt werden. Ändern Sie den Upload in ein neues Dokument oder entfernen Sie sie aus der Liste.',
  'CommonFilesInput.blockedRevisions.buttonLabel': 'Revisionsblockierte Dateien entfernen',
  'CommonFilesInput.checkLoading.text': 'Überprüfen Sie Dateien und Ordner.',
  'CommonFilesInput.treeLoading.text': 'Laden von Dateien und Ordnern.',
  'CommonFilesInput.treeLoading.error': 'Die gesamte Ordnerstruktur konnte nicht geladen werden. Da der Dateipfad auf Ihrem Gerät zu lang ist, konnten einige Dateien oder Ordner nicht geladen werden.',
  'CommonFilesInput.treeLoading.error.help': 'Um Dateien und Ordner hochzuladen, versuchen Sie, Dateien in einen anderen Ordner auf Ihrem Computer zu verschieben, Ordner und Dateien mit langen Namen umzubenennen (kürzere Namen zu verwenden) oder versuchen Sie, Dateien separat hochzuladen.',
  'CommonFilesInput.treeLoading.itemErrorInfo': 'Der gesamte Inhalt dieses Ordners konnte nicht geladen werden, da der Dateipfad auf Ihrem Gerät zu lang ist. Einige Dateien und Ordner fehlen.',
  'CommonFilesInput.treeLoading.fileErrorInfo': 'Die Datei ({name}) konnte nicht geladen werden, da der Pfad auf Ihrem Gerät zu lang ist.',
  'CommonFilesInput.treeLoading.folderErrorInfo': 'Der Inhalt des Ordners konnte nicht geladen werden, da der Pfad auf Ihrem Gerät zu lang ist.',
  'CommonFilesInput.treeLoading.error.listTitle': 'Fehlende Dateien und Ordner',
  'CommonFilesInput.treeLoading.firefoxWarning': 'Firefox warnt Sie nicht, wenn Datei- oder Ordnerpfade zu lang sind und einige fehlen. Bitte überprüfen Sie die Anzahl der zum Hochladen hinzugefügten Dateien und Ordner.',
  'CommonFilesInput.treeLoading.pathTooLongWarning': 'Möglicherweise wurde nicht die gesamte Ordnerstruktur geladen. Möglicherweise ist der Dateipfad zu lang und die Datei wurde nicht erkannt. Überprüfen Sie die Anzahl der erkannten und hochgeladenen Dateien oder Ordner und kürzen Sie ggf. deren Namen.',
  'CommonFilesInput.treeItemMenu.duplicateFilesAsRevisions': 'Laden Sie doppelte Dateien als Revisionen hoch',
  'CommonFilesInput.treeItemMenu.duplicateFilesAsNew': 'Laden Sie doppelte Dateien als neue hoch',
  'CommonFilesInput.treeItemMenu.includeDuplicateFolders': 'Fügen Sie doppelte Ordner hinzu',
  'CommonFilesInput.treeItemMenu.excludeDuplicateFolders': 'Schließen Sie doppelte Ordner aus',
  'CommonFilesInput.treeItemMenu.includeDuplicateFiles': 'Fügen Sie doppelte Dateien hinzu',
  'CommonFilesInput.treeItemMenu.excludeDuplicateFiles': 'Schließen Sie doppelte Dateien aus',
  'CommonFilesInput.treeItemMenu.excludeAllNotWritable': 'Ohne Schreibberechtigung alles ausschließen',
  'CommonFilesInput.treeItemMenu.excludeAllInvalid': 'Alle ungültigen ausschließen',
  'SingleFileInput.collapse.hint': 'Klicken oder ziehen Sie die Datei hierher',
  'SingleFileInput.collapse.text': 'Wählen Sie eine Datei aus',
  'paramEncode.error.urlParse': 'URL-Parameter konnte nicht dekodiert werden.',
  'paramEncode.error.jsonParse': 'Die Konvertierung in ein JSON-Objekt ist fehlgeschlagen.',
  'paramEncode.error.checkFormat': 'Der verarbeitete Wert entspricht nicht dem erforderlichen Format.',
  'OrganizationAddForm.title': 'Name',
  'OrganizationAddForm.CIN': 'WIN',
  'OrganizationAddForm.title.placeholder': 'Name der Organisation...',
  'OrganizationAddForm.description.placeholder': 'Beschreibung der Organisation...',
  'OrganizationAddForm.description': 'Beschreibung',
  'OrganizationAddForm.language': 'Sprache',
  'OrganizationAddForm.language.tooltip': 'Hauptsprache der Organisation. In welcher Sprache werden die meisten Dokumente voraussichtlich vorliegen?',
  'OrganizationAddForm.CinWarning.tooltip': 'Der eingegebene Wert entspricht nicht dem Standard des Informationssystems der öffentlichen Verwaltung der Tschechischen Republik. Wenn Sie eine Betreff-ID aus einem anderen Land eingeben, ignorieren Sie diese Warnung',
  'OrganizationAddForm.timeZone': 'Zeitzone',
  'OrganizationAddForm.addUsersAlsoWithInvitation': 'Der Projektadministrator kann Benutzer von AspeHub hinzufügen, auch per E-Mail-Einladung',
  'OrganizationAddForm.required': 'Es ist notwendig, mindestens einen Benutzer als Admin auszuwählen',
  'OrganizationList.organizationByUserTitle': 'Organisationen, denen der ausgewählte Benutzer angehört',
  'OrganizationGeneralTab.title': 'Hinzufügen von Benutzern zum Projekt per E-Mail-Einladung (Projektadministratoren)',
  'OrganizationGeneralTab.description': 'Bei aktivierter Option können Projektadministratoren auch per E-Mail-Einladung einen neuen Benutzer hinzufügen. Dadurch wird ein neuer Benutzer zu AspeHub hinzugefügt.\n Wenn die Option deaktiviert ist, kann nur ein vorhandener Benutzer in der Organisation zum Projekt hinzugefügt werden.',
  'OrganizationDetail.forgeText': 'Anzahl der gesendeten Anfragen: {count}',
  'OrganizationDetail.forgeTooltip': 'Die Anzahl der eingereichten Modellverarbeitungsanfragen pro Organisation',
  'OrganizationUsersEditModal.title': 'Ändern Sie die Benutzereinstellungen pro Organisation',
  'OrganizationUserSettingOnProjectsModal.title': 'Benutzereinstellungen für Projekte ändern',
  'OrganizationUserSettingOnProjectsModal.noSuspendReason.Title': 'Die Lösch-/Deaktivierungsaktion ist fehlgeschlagen',
  'OrganizationUserSettingOnProjectsModal.noSuspendReason.projectName': 'Projektname',
  'OrganizationUserSettingOnProjectsModal.noSuspendReason.reason': 'Grund für das Scheitern',
  'OrganizationUserSettingOnProjectsModal.noSuspendReason.NotFound': 'Benutzer nicht gefunden',
  'OrganizationUserSettingOnProjectsModal.noSuspendReason.IsLastAdmin': 'Der Benutzer ist der einzige Administrator des Projekts',
  'OrganizationUserSettingOnProjectsModal.noSuspendReason.InvalidOperation': 'Ungültiger Vorgang',
  'OrganizationUserSettingOnProjectsModal.usersActiveProjects': 'Dem Benutzer zugewiesene Projekte',
  'OrganizationUserSettingOnProjectsModal.usersNewProjects': 'Projekte, bei denen der Benutzer nicht erscheint',
  'OrganizationUserSettingOnProjectsModal.DeleteDeactivateButtonText': 'Entfernen/Deaktivieren',
  'OrganizationUserSettingOnProjectsModal.AddButtonText': 'Zum Projekt hinzufügen',
  'OrganizationUserSettingPermissionPanel.Title': 'Genehmigung',
  'OrganizationUserSettingPermissionPanel.permissionCategoryType.reports': 'Berichte',
  'OrganizationUserSettingPermissionPanel.permissionCategoryType.organizationOverview': 'Organisationsübersicht',
  'OrganizationUserSettingPermissionPanel.permissionType.financialOverviewByUnit': 'Finanzieller Überblick über AspeEsticon-Projekte nach Abteilung',
  'OrganizationUserSettingPermissionPanel.permissionType.projectRepHubCostObjectSummary': 'Finanzielle Zusammenfassung des Baus',
  'OrganizationUserSettingPermissionPanel.permissionType.projectsInRealisationOverview': 'Übersicht über die laufenden Bauarbeiten',
  'OrganizationUserSettingPermissionPanel.permissionType.projectsInRealisationOverviewAdmin': 'Verwaltung von Bauübersichtsnotizen',
  'OrganizationUserSettingPermissionPanel.permissionType.projectsInRealisationShareWidget': 'Geteilter Widget-Manager',
  'OrganizationUserSettingPermissionPanel.permissionType.assignmentsOverview': 'Alle Aufgaben anzeigen',
  'ProjectCreateForm.form.items.description.placeholder': 'Projektbeschreibung...',
  'ProjectCreateForm.form.items.language.cs': 'tschechisch',
  'ProjectCreateForm.form.items.language.en': 'Englisch',
  'ProjectCreateForm.form.items.language.label': 'Sprache',
  'ProjectCreateForm.form.items.language.tooltip': 'Die Hauptsprache des Projekts. In welcher Sprache werden die meisten Dokumente voraussichtlich vorliegen?',
  'ProjectCreateForm.form.items.name.label': 'Name',
  'ProjectCreateForm.form.items.name.placeholder': 'D1 Prag - Brünn',
  'Project.name.empty': 'Der Projektname darf nicht leer sein',
  'ProjectCreateForm.form.items.name.rules.required': 'Bitte geben Sie einen Projektnamen ein',
  'ProjectCreateForm.form.tabs.newProject': 'Aus der Aufgabe',
  'ProjectCreateForm.form.tabs.fromTemplate': 'Aus der Vorlage',
  'ProjectCreateForm.form.template': 'Vorlage',
  'ProjectCreateForm.form.timeZone': 'Zeitzone',
  'ProjectCreateForm.form.timeZone.rules.required': 'Bitte geben Sie eine Zeitzone ein',
  'ProjectCreateForm.form.template.rules.required': 'Bitte wählen Sie eine Projektvorlage aus',
  'ProjectCreateForm.form.template.rules.storageAreaValidation': 'In der Vorlage ist kein Zweig ausgefüllt. Wählen Sie eine andere Projektvorlage',
  'ProjectCreateFormModal.button.create': 'Erstellen Sie ein Projekt',
  'ProjectCreateFormModal.infoText.common': 'Das Projekt wird jetzt erstellt.',
  'ProjectCreateFormModal.infoText.youWillSeeProject': 'Das Projekt wird jetzt erstellt. Nachdem es erstellt wurde, ist es im Projektmodul verfügbar.',
  'ProjectUserAddForm.addToGroups': 'Fügen Sie Benutzer zu Gruppen hinzu',
  'Esticon.enums.phase.Priprava': 'Vorbereitung',
  'Esticon.enums.phase.Nabidka': 'Angebot',
  'Esticon.enums.phase.Realizace': 'Realisierung',
  'Esticon.enums.phase.Dokonceno': 'Vollendet',
  'Esticon.enums.phase.Neziskano': 'Nicht erhalten',
  'ProjectDashboard.Reports.notAvailableError': 'Der Bericht ist nicht verfügbar',
  'ProjectDashboard.Reports.removeFromDashboard': 'Bericht entfernen',
  'ProjectDashboard.Reports.copyReportWidget': 'Machen Sie eine Kopie',
  'ProjectDashboard.Reports.esticonForbidden': 'Sie sind nicht berechtigt, Baudaten anzuzeigen',
  'ProjectDashboard.Reports.title.documentOverview': 'Übersicht der Dokumente',
  'ProjectDashboard.Reports.title.financialPlan': 'Finanzplan/Realität',
  'ProjectDashboard.Reports.title.projectOverview': 'Projektübersicht',
  'ProjectDashboard.Reports.title.workflowTasks': 'Genehmigungsprozesse',
  'ProjectDashboard.Reports.title.withdrawOverview': 'Übersicht über das Pumpen',
  'ProjectDashboard.Reports.title.drawingActualOverview': 'Finanzielle Übersicht über den Bau',
  'ProjectDashboard.Reports.AddForm.report.label': 'Bericht',
  'ProjectDashboard.Reports.AddForm.report.rules.required': 'Bitte wählen Sie den Bericht aus, den Sie hinzufügen möchten',
  'ProjectDashboard.Reports.AddForm.submit': 'Hinzufügen',
  'ProjectDashboard.Reports.AddForm.title': 'Bericht hinzufügen',
  'ProjectDashboard.Reports.DocumentOverview.author': 'Meins',
  'ProjectDashboard.Reports.DocumentOverview.mineCreated': 'Von mir hochgeladen',
  'ProjectDashboard.Reports.DocumentOverview.admin': 'Administrator',
  'ProjectDashboard.Reports.DocumentOverview.other': 'Andere',
  'ProjectDashboard.Reports.ExportDate': 'Gültig am:',
  'ProjectDashboard.Reports.Export.jpg': 'Als JPG exportieren',
  'ProjectDashboard.Reports.Export.png': 'Als PNG exportieren',
  'ProjectDashboard.Reports.Export.svg': 'Als SVG exportieren',
  'ProjectDashboard.Reports.Export.pdf': 'Als PDF exportieren',
  'ProjectDashboard.Reports.Export.xlsx': 'Nach Excel exportieren',
  'ProjectDashboard.Reports.FinancialPlan.invoiced': 'Abrechnung',
  'ProjectDashboard.Reports.FinancialPlan.plan': 'Planen',
  'ProjectDashboard.Reports.FinancialPlan.remaining': 'Es bleibt',
  'ProjectDashboard.Reports.FinancialPlan.outOfPlan': 'aus dem Plan',
  'ProjectDashboard.Reports.FinancialPlan.outOfTotal': 'aus dem Ganzen',
  'ProjectDashboard.Reports.Menu.addReport': 'Bericht hinzufügen',
  'ProjectDashboard.Reports.Menu.remove': 'Bericht entfernen',
  'ProjectDashboard.Reports.Menu.moveDown': 'Vergrößern',
  'ProjectDashboard.Reports.Menu.moveUp': 'Reduzieren',
  'ProjectDashboard.Reports.Menu.maximize': 'Maximieren',
  'ProjectDashboard.Reports.Menu.minimize': 'Minimieren',
  'ProjectDashboard.Reports.Menu.selectFromTo': 'Wählen Sie von - bis',
  'ProjectDashboard.Reports.Menu.showDateModal': 'Wählen Sie ein Datum von/bis',
  'ProjectDashboard.Reports.Menu.showDetail': 'Detail anzeigen',
  'ProjectDashboard.Reports.timePeriod.Months': 'Monatsansicht',
  'ProjectDashboard.Reports.timePeriod.Years': 'Jährliche Ausstellung',
  'ProjectDashboard.Reports.ProjectOverview.numberOfDocuments': 'Anzahl der Dokumente',
  'ProjectDashboard.Reports.ProjectOverview.numberOfUsers': 'Anzahl der Benutzer',
  'ProjectDashboard.Reports.ProjectOverview.loggedInToday': 'Heute eingeloggt',
  'ProjectDashboard.Reports.ProjectOverview.projectDate': 'Dauer des Projekts',
  'ProjectDashboard.Reports.ProjectOverview.projectPhase': 'Projektphase',
  'ProjectDashboard.Reports.ProjectOverview.recapitulation': 'Reprise',
  'ProjectDashboard.Reports.ProjectOverview.ZBV': 'ÄdB',
  'ProjectDashboard.Reports.ProjectOverview.ZBV.Tooltip': 'Änderungen während der Bauphase, Gesamtbetrag in {currency} ohne MwSt.',
  'ProjectDashboard.Reports.ProjectOverview.currentPrice': 'Aktueller Preis',
  'ProjectDashboard.Reports.ProjectOverview.currentPrice.Tooltip': 'Gesamtpreis in {currency} ohne MwSt.',
  'ProjectDashboard.Reports.ProjectOverview.ZP': 'Gepumpt',
  'ProjectDashboard.Reports.ProjectOverview.ZP.Tooltip': 'Erkennungsprotokolle, bezogen auf insgesamt {currency} ohne MwSt.',
  'ProjectDashboard.Reports.WorkflowTasks.finished': 'Vollendet',
  'ProjectDashboard.Reports.WorkflowTasks.active': 'Aktiv',
  'ProjectDashboard.Reports.projectDocumentationStatistics.title': 'Übersicht der Projekte nach Dokumentationsgrad',
  'ProjectDashboard.Reports.userNotificationStatistics.title': 'Benutzerbenachrichtigung',
  'ProjectDashboard.Reports.userActiveAssignmentStatistics.title': 'Aktive Benutzeraufgaben',
  'ProjectDashboard.ProjectCard.title': 'Registerkarte „Projekt“.',
  'ProjectDashboard.ProjectCard.marking': 'Bezeichnung',
  'ProjectDashboard.ProjectCard.documentationLevel': 'Grad der Dokumentation',
  'ProjectDashboard.ProjectCard.duration': 'Fortschritt von-bis',
  'ProjectDashboard.ProjectCard.noData': 'Projektdaten sind nicht verfügbar',
  'ProjectDashboard.ProjectCard.editButtonLabel': 'Projektdaten bearbeiten',
  'ProjectDashboard.ProjectAgenda.title': 'Agenda',
  'ProjectDashboard.ProjectAdditionalData.title': 'Zusätzliche Daten',
  'ProjectDashboard.ProjectAdditionalData.noData': 'Weitere Projektdaten sind nicht verfügbar',
  'ProjectDashboard.ProjectAdditionalData.editButtonLabel': 'Bearbeiten Sie zusätzliche Daten',
  'ProjectSearchPage.detailMode': 'Detailansicht',
  'ProjectSearchPage.foundEntries': `{total, plural,
    =0 {keine Ergebnisse gefunden}
    one {ein Datensatz gefunden}
    few {# Datensätze gefunden}
    other {# Datensätze gefunden}
    } in {took} ms, {showing}`,
  'ProjectsInRealisationOverviewReportPage.menu.editViews.tooltip': 'Gespeicherte Ansichten bearbeiten',
  'ProjectsInRealisationOverviewReportPage.menu.override.tooltip': 'Überschreiben Sie die aktuelle Ansicht',
  'ProjectsInRealisationOverviewReportPage.menu.repairNotes.tooltip': 'Korrigieren Sie ungepaarte Notizen',
  'ProjectsInRealisationOverviewReportPage.menu.addWidget.tooltip': 'Erstellen Sie aus dem angezeigten Bericht ein Dashboard-Widget',
  'ProjectsInRealisationOverviewReportPage.menu.manageSharedWidgets.tooltip': 'Geteilte Widgets verwalten',
  'ProjectSearchPage.loadMore': 'Mehr laden {count}',
  'ProjectTemplates.title': 'Projektvorlagen',
  'ProjectTemplates.list.createProjectTemplate': 'Erstellen Sie eine Vorlage',
  'ProjectTemplates.list.editProjectTemplate': 'Vorlage bearbeiten',
  'ProjectTemplates.list.deleteTemplate.confirmation': 'Vorlage wirklich löschen?',
  'ProjectTemplates.createModal.confirm': 'Speichern Sie die Vorlage',
  'ProjectTemplates.createModal.title': 'Neue Projektvorlage',
  'ProjectTemplates.saveMessage.success': 'Projektvorlage erfolgreich gespeichert',
  'ProjectTemplates.general.permissionTemplates.title': 'Autorisierungsvorlagen',
  'ProjectTemplateBuilder.warning.tooltip.general': 'Obligatorische allgemeine Einstellungselemente in der Vorlage sind nicht festgelegt.',
  'ProjectTemplateBuilder.warning.tooltip.groups.missingAdmin': 'Die Gruppe „Administratoren“ muss mindestens einen Benutzer enthalten.',
  'ProjectTemplateBuilder.warning.tooltip.groups.tooMany': 'Die Anzahl der Gruppen hat den zulässigen Grenzwert überschritten.',
  'ProjectTemplateBuilder.warning.tooltip.users': 'In der Projektvorlage sind keine Benutzer angegeben.',
  'ProjectTemplateBuilder.workflow.disabled.tooltip.notSaved': 'Die Projektvorlage wird nicht auf dem Server gespeichert, um die Übereinstimmung mit der Workflow-Vorlage zu überprüfen',
  'ProjectTemplateBuilder.workflow.disabled.tooltip.hasChanges': 'Die Projektvorlage weist nicht gespeicherte Änderungen auf, die sich auf die Kopplung mit der Workflow-Vorlage auswirken können',
  'ProjectTemplateBuilder.workflow.disabled.tooltip.noUsers': 'In der Projektvorlage sind keine Benutzer installiert',
  'ProjectTemplateBuilder.workflow.disabled.tooltip.noRoles': 'Die Projektvorlage verfügt über keine festgelegten Rollen',
  'ProjectTemplateDirectoriesTab.delete.confirmNotEmpty': 'Der ausgewählte Ordner ist nicht leer. Möchten Sie den Ordner mit allen Unterordnern wirklich löschen?',
  'ProjectTemplateDirectoriesTab.move.duplicateNameError': 'Im Zielordner ist bereits ein Ordner mit demselben Namen vorhanden.',
  'ProjectTemplateDirectoriesTab.noDirsWarning': 'Es wurde kein Ordner ausgewählt/eingefügt',
  'ProjectTemplateDirectoriesTab.dropzoneText': 'Lediglich die Ordnerstruktur wird gespeichert',
  'ProjectTemplateDirectoriesTab.dropzone.treeLoading.error': 'Die gesamte Ordnerstruktur konnte nicht geladen werden. Da der Pfad zu den Ordnern auf Ihrem Gerät zu lang ist, konnten einige Ordner nicht geladen werden.',
  'ProjectTemplateDirectoriesTab.dropzone.treeLoading.error.help': 'Um Ordner hochzuladen, versuchen Sie, Ordner mit langen Namen auf Ihrem Computer umzubenennen (verwenden Sie kürzere Namen) oder versuchen Sie, Ordner separat hochzuladen.',
  'ProjectTemplateDirectoriesTab.dropzone.treeLoading.error.listTitle': 'Fehlende Ordner',
  'ProjectTemplateCategoryFormModal.add.title': 'Neue Kategorie',
  'ProjectTemplateCategoryFormModal.edit.title': 'Kategorie bearbeiten',
  'ProjectTemplateCategoryNodeFormModal.add.title': 'Neuer Knoten',
  'ProjectTemplateCategoryNodeFormModal.edit.title': 'Knoten bearbeiten',
  'ProjectTemplateDirectoryFormModal.add.title': 'Neuer Ordner',
  'ProjectTemplateDirectoryFormModal.edit.title': 'Ordner bearbeiten',
  'ProjectTemplateDirectoryCategoryForm.category.required': 'Bitte wählen Sie eine Kategorie aus',
  'ProjectTemplateDirectoryCategoryForm.categoryNode.required': 'Bitte wählen Sie einen Knoten aus',
  'ProjectTemplateDirectoryCategoryForm.node': 'Knoten',
  'ProjectTemplateDirectoryCategoryFormModal.add.title': 'Kategorie hinzufügen',
  'ProjectTemplateDirectoryCategoryFormModal.edit.title': 'Kategorie bearbeiten',
  'ProjectTemplateDirectoryDetail.noCategories': 'Keine Kategorie',
  'ProjectTemplateDirectoryDetail.button.addCategory': 'Kategorie hinzufügen',
  'ProjectTemplateGeneralTab.templateName.title': 'Der Name der Vorlage',
  'ProjectTemplateGeneralTab.templateDescription.title': 'Beschreibung der Vorlage',
  'ProjectTemplateGeneralTab.directory.metadataSetting.title': 'Definition von Ordnermetadaten',
  'ProjectTemplateGeneralTab.document.metadataSetting.title': 'Definition von Dokumentmetadaten',
  'ProjectTemplateMetadataDefinitionsSettingModal.variable.boolean': 'JA-NEIN',
  'ProjectTemplateMetadataDefinitionsSettingModal.variable.string': 'Text',
  'ProjectTemplateMetadataDefinitionsSettingModal.variable.number': 'Nummer',
  'ProjectTemplateMetadataDefinitionsSettingModal.variable.date': 'Datum',
  'ProjectTemplateMetadataDefinitionsSettingModal.variable.orgStructureWithUser': 'Organisationsstruktur mit dem Benutzer',
  'ProjectTemplateMetadataDefinitionsSettingModal.inputTitle': 'Name',
  'ProjectTemplateMetadataDefinitionsSettingModal.selectTitle': 'Typ',
  'ProjectTemplateMetadataDefinitionsSettingModal.metadataValues.title': 'Metadatenwerte',
  'ProjectTemplateMetadataSettingModal.title': 'Legen Sie Metadatenwerte fest',
  'ProjectTemplateGroupFormModal.add.title': 'Neue Gruppe',
  'ProjectTemplateGroupFormModal.edit.title': 'Gruppe bearbeiten',
  'ProjectTemplateLabelFormModal.add.title': 'Neues Etikett',
  'ProjectTemplateLabelFormModal.edit.title': 'Etikett bearbeiten',
  'ProjectTemplateRoleFormModal.add.title': 'Eine neue Rolle',
  'ProjectTemplateRoleFormModal.edit.title': 'Rolle bearbeiten',
  'ProjectTemplatePermissionPresetFormModal.title': 'Neues Profil',
  'ProjectTemplateRoleTab.warning.emptyRole': 'Für die Rolle ist kein Benutzer festgelegt',
  'ProjectTemplateWorkflowTemplateAddFormModal.title': 'Neue Workflow-Vorlage',
  'ProjectTemplateWorkflowTemplateAddFormModal.workflowTemplate': 'Workflow-Vorlage',
  'ProjectTemplateWorkflowTemplateAddFormModal.roles': 'Rolle {name}',
  'ProjectTemplateWorkflowTemplateAddFormModal.users': 'Benutzer {name}',
  'ProjectTemplateWorkflowTemplateAddFormModal.directory': 'Ordner {name}',
  'ProjectTemplateWorkflowTemplateAddFormModal.directory.empty': 'Vorlage nicht ausgefüllt',
  'ProjectTemplate.WorkflowTemplateTab.title': 'Workflow-Vorlagen',
  'ProjectTemplate.WorkflowTemplateTab.button.add': 'Workflow-Vorlage hinzufügen',
  'ReinviteButton.reinvite': 'Nochmals einladen',
  'ReinviteButton.reinvite.tooltip': 'Senden Sie die Aktivierungs-E-Mail erneut',
  'ReinviteButton.reinvited': 'Schon wieder eingeladen',
  'Reporting.list.title': 'Berichte',
  'Reporting.reportCategory.name.General': 'Grundlegende Berichte',
  'Reporting.reportType.name.financialOverviewByUnit': 'Finanzieller Überblick über AspeEsticon-Projekte nach Abteilung',
  'Reporting.reportType.name.projectRepHubCostObjectSummary': 'Finanzielle Zusammenfassung des Baus',
  'Reporting.reportType.name.projectsInRealisationOverview': 'Übersicht über die laufenden Bauarbeiten',
  'Reporting.reportType.name.projectsInRealisationOverviewAdmin': 'Admin-Notizen in der Übersicht der laufenden Bauarbeiten',
  'Reporting.reportType.name.projectsInRealisationShareWidget': 'Geteilter Widget-Manager',
  'Reporting.reportType.name.mdApprovedProjects': 'Übersicht genehmigter Projekte',
  'Reporting.reportType.name.assignmentsOverview': 'Übersicht aller Aufgaben',
  'SearchPageHelp.searchHelp': 'Suchhilfe',
  'SearchPageHelp.basicSearch': 'Dokumente, die mindestens eines der eingegebenen Wörter enthalten, werden nach ihrer Wurzel durchsucht.',
  'SearchPageHelp.wholeWords': 'Um nach <strong>Vokabular</strong> zu suchen, geben Sie den Begriff in Anführungszeichen ein. Beispiel: <span class=""primary"">""Strukturelement""</span>.',
  'SearchPageHelp.mustHave': 'Ein Pluszeichen (+) vor einem Ausdruck bedeutet, dass der Ausdruck enthalten <strong>muss</strong> ist. Beispiel: <span class=""primary"">+""Strukturelement""</span>.',
  'SearchPageHelp.mustNotHave': 'Ein Minuszeichen (-) vor einem Ausdruck bedeutet, dass der Ausdruck <strong>nicht</strong> enthalten sein darf. Beispiel: <span class=""primary"">-structural</span>.',
  'SearchPageInput.placeholder': 'Geben Sie eine Suchanfrage ein',
  'SearchPageSettings.searchSettings': 'Sucheinstellungen',
  'SearchPageSettings.displayConfig': 'Anzeigeeinstellungen',
  'SearchPageSettings.filterSettings': 'Sucheinstellungen',
  'SearchPageSettings.showDetail': 'Detail anzeigen',
  'SearchPageSettings.detailSize': 'Vorschaugröße',
  'SearchPageSettings.searchInName': 'Suche im Dokumenttitel',
  'SearchPageSettings.searchInDescription': 'Suchen Sie in der Dokumentbeschreibung',
  'SearchPageSettings.searchInRevisionNote': 'Suchen Sie in den Kommentaren nach Überarbeitungen',
  'SearchPageSettings.searchInContent': 'Durchsuchen Sie den Inhalt des Dokuments',
  'SearchPageSettings.searchInMetadata': 'Suche in Dokumentmetadaten',
  'SearchPageSettings.currentRevisionOnly': 'Nur aktuelle Revisionen anzeigen',
  'SearchPageSettings.allMetadataParemetersTypes': 'Alle Arten von Metadaten',
  'SearchPageSettings.unknownMetadataParemeterType': 'Der zuvor gesetzte Suchparameter {unknownParameter} wurde in der Metadatendefinition umbenannt oder gelöscht. Die Suchergebnisse sind möglicherweise ungültig.',
  'SearchPageSettings.noMetadataDefinitions': 'Metadaten sind nicht definiert',
  'SearchPageSettings.directoryId': 'Suche im Ordner:',
  'SearchPageSettings.includeSubdirectories': 'Einschließlich Unterordner',
  'SearchResultRow.documentDescription': 'Dokumentbeschreibung',
  'SearchResultRow.revisionDescription': 'Kommentar überprüfen',
  'SearchResultRow.metadataKey': 'Metadatenschlüssel',
  'SearchResultRow.metadataValue': 'Metadatenwert',
  'SearchResultRow.metadataSource': 'Quelle der Metadaten',
  'ProjectSettingsPageGeneral.esticonId.placeholder': 'Es ist kein Projekt angehängt. Achtung: Das angehängte Projekt kann nachträglich nicht mehr geändert werden!',
  'ProjectSettingsPageGeneral.name': 'Projektname',
  'ProjectSettingsPageGeneral.description': 'Projektbeschreibung',
  'ProjectSettingsPageGeneral.nameError': 'Der Titel ist zu lang. Die maximale Namenslänge beträgt {max} Zeichen.',
  'ProjectSettingsPageGeneral.imports': 'Importe',
  'ProjectSettingsPageGeneral.importConnectedERPData': 'Importieren Sie ERP-Daten aus einem verknüpften Projekt',
  'ProjectSettingsPageGeneral.exports': 'Exporte',
  'ProjectSettingsPageGeneral.exportUsersAndProjectSetting': 'Exportieren Sie Benutzer und Projekteinstellungen',
  'ProjectSettingsPage.ExternApps.addApplication': 'App hinzufügen',
  'ProjectSettingsPage.ExternApps.pageTitle': 'Verlinkte externe Anwendungen',
  'ProjectSettingsPage.ExternApps.selectAppTitle': 'Wählen Sie eine Anwendung aus',
  'ProjectSettingsPage.ExternApps.Esticon.addButton': 'AspeEsticon',
  'ProjectSettingsPage.ExternApps.Esticon.addTitle': 'Verbinden Sie das AspeEsticon-Projekt',
  'ProjectSettingsPage.ExternApps.Esticon.ProjectList.serverError': 'Verbindung zur Datenbank konnte nicht hergestellt werden. Wenden Sie sich an einen AspeHub-Administrator',
  'ProjectSettingsPage.ExternApps.Esticon.Form.Database': 'Datenbank',
  'ProjectSettingsPage.ExternApps.Esticon.Form.esticonApp': 'AspeEsticon applikation',
  'ProjectSettingsPage.ExternApps.Esticon.Form.Project': 'Projekt',
  'ProjectSettingsPage.ExternApps.Esticon.listItemTitle': 'AspeEsticon',
  'ProjectSettingsPage.ExternApps.Esticon.loadError': 'Beim Laden der Daten von AspeEsticon ist ein Fehler aufgetreten',
  'ProjectSettingsPage.ExternApps.Esticon.projectMissing': 'AspeEsticon-Projekt nicht gefunden',
  'ProjectSettingsPage.ExternApps.Esticon.projectNotSet': 'Das AspeEsticon-Projekt wurde nicht eingerichtet',
  'ProjectSettingsPage.ExternApps.Esticon.firm': 'Firmendatenbank',
  'ProjectSettingsPage.ExternApps.Esticon.projectSign': 'Projektmarke',
  'ProjectSettingsPage.ExternApps.Esticon.projectName': 'Projektname',
  'ProjectSettingsPage.ExternApps.Esticon.projectId': 'Projekt-ID',
  'ProjectSettingsPage.ExternApps.Esticon.currency': 'Währung',
  'ProjectSettingsPage.ExternApps.Esticon.folderHelp': 'Beginnen Sie mit der Eingabe von <, um Tags anzuzeigen. Die Tags werden durch die Werte des importierten Objekts im Dokument- oder Ordnernamen ersetzt. Monat und Jahr werden vom Ausstellungsdatum übernommen. Die Objektpfadmaske wird auf jeden erstellten Ordner im Pfad angewendet.',
  'ProjectSettingsPage.ExternApps.Esticon.folderHelp.invoices': 'Beginnen Sie mit der Eingabe von <, um Tags anzuzeigen. Die Tags werden durch die Werte aus dem importierten Objekt im Dokument- oder Ordnernamen ersetzt. Monat und Jahr werden aus dem Datum des Abrechnungszeitraums übernommen.',
  'ProjectSettingsPage.ExternApps.Esticon.folder.Stavba': 'Budget-Importordner',
  'ProjectSettingsPage.ExternApps.Esticon.folder.Cerpani': 'Ordner zum Importieren von Erkennungsprotokollen',
  'ProjectSettingsPage.ExternApps.Esticon.folder.CerpaniSdruzeni': 'Ordner zum Importieren gebündelter Erkennungsprotokolle',
  'ProjectSettingsPage.ExternApps.Esticon.folder.Fakturace': 'Ordner zum Importieren von Rechnungen',
  'ProjectSettingsPage.ExternApps.Esticon.folder.FakturaSdruzeni': 'Ordner zum Importieren konsolidierter Rechnungen',
  'ProjectSettingsPage.ExternApps.Esticon.folder.ZBV': 'ÄdB-Importordner',
  'ProjectSettingsPage.ExternApps.Esticon.folder.Harmonogram': 'Importordner planen',
  'ProjectSettingsPage.ExternApps.ConstructionJournal.addButton': 'Bautagebuch',
  'ProjectSettingsPage.ExternApps.ConstructionJournal.listItemTitle': 'Bautagebuch',
  'ProjectSettingsPage.ExternApps.ConstructionJournal.title': 'Link zum Bautagebuch',
  'ProjectSettingsPage.ExternApps.ApplicationUrl.addButton': 'Externer Link',
  'ProjectSettingsPage.ExternApps.ApplicationUrl.listItemTitle': '{name} (Externer Link)',
  'ProjectSettingsPage.ExternApps.ApplicationUrlForm.title': 'Einstellungen für externe Anwendungslinks',
  'ProjectSettingsPage.ExternApps.ApplicationUrlForm.menuName.placeholder': 'Name im Navigationsmenü',
  'ProjectSettingsPage.ExternApps.ApplicationUrlForm.url.placeholder': 'Die Adresse der externen Anwendung',
  'ProjectSettingsPage.ExternApps.ApplicationUrlForm.url.newTab': 'In neuem Tab öffnen',
  'ProjectSettingsPage.ExternApps.DocumentView.addButton': 'PDF-Dokument',
  'ProjectSettingsPage.ExternApps.DocumentView.listItemTitle': 'PDF-Dokument',
  'ProjectSettingsPage.ExternApps.DocumentView.missingPdfDerivate': 'Das Dokument verfügt über kein generiertes PDF-Derivat zur Anzeige',
  'ProjectSettingsPage.ExternApps.TextPage.addButton': 'Textseite',
  'ProjectSettingsPage.ExternApps.TextPage.listItemTitle': 'Textseite',
  'ProjectSettingsPage.ExternApps.TextPage.title.placeholder': 'Seitentitel',
  'ProjectSettingsPage.Groups.erasedGroupMessage': 'Die Gruppe <strong>{name}</strong> wurde gelöscht.',
  'ProjectSettingsProjectCardDataTree.oldValue': 'Vorhanden',
  'ProjectSettingsProjectCardDataTree.newValue': 'Neu',
  'ProjectStatusPage.projectCreating.projectCreating': 'Ein Projekt erstellen',
  'ProjectStatusPage.projectCreating.projectIsReady': 'Das Projekt ist fertig :-)',
  'ProjectStatusPage.projectCreating.projectError': 'Fehler :-(',
  'ProjectsListPage.addDocumentationLevelOverview': 'Fügen Sie eine Übersicht über Projekte entsprechend dem Dokumentationsgrad hinzu',
  'ProjectsListPage.addNotificationOverview': 'Fügen Sie eine Übersicht über Benutzerbenachrichtigungen hinzu',
  'ProjectsListPage.addAssignmentsOverview': 'Fügen Sie eine Übersicht über aktive Aufgaben hinzu',
  'ProjectsListPage.addNewReport': 'Fügen Sie einen neuen Bericht hinzu',
  'ProjectsListPage.addNewSharedReport': 'Fügen Sie einen neuen freigegebenen Bericht hinzu',
  'ProjectsListPage.addToFavorites': 'Zu Favoriten hinzufügen',
  'ProjectsListPage.createFirstProject': 'Erstellen Sie Ihr erstes Projekt',
  'ProjectsListPage.createProject': 'Erstellt: {date}',
  'ProjectsListPage.motherOrganization': 'Projekt in Organisation: {organizationName}',
  'ProjectsListPage.removeProjects': 'Projekte entfernen',
  'ProjectsListPage.reorderProjects': 'Organisieren',
  'ProjectsListPage.sortProjectsAZ': 'Alphabetisch sortieren',
  'ProjectsListPage.sortProjectsDate': 'Nach Datum sortieren',
  'ProjectsListPage.sortProjectsFav': 'Nach Favoriten sortieren.',
  'ProjectsListPage.Filter.toolbar.organization': 'Organisation',
  'ProjectsListPage.projects': 'Beliebte Projekte',
  'ProjectsListPage.projectDrawingProcess': 'Der Fortschritt beim Zeichnen beliebter Projekte',
  'ProjectsListPage.removeFromFavorites': 'Aus Favoriten entfernen',
  'ProjectsListPage.reports': 'Ausgewählte Berichte',
  'ProjectsListPage.taskList': 'Meine Aufgaben',
  'ProjectsListPage.financialRecapitulation': 'Finanzielle Zusammenfassung',
  'ProjectsListPage.zbvGroups': 'ÄdB - Gruppen nach 18/2017',
  'ProjectsListPage.list.empty': 'Du hast keine Projekte :-(',
  'ProjectsListPage.list.empty.noAdmin': 'Sie haben für kein Projekt die Erlaubnis :-(',
  'ProjectsListPage.list.empty.noMatch': 'Keine Projekte gefunden :-(',
  'ProjectsListPage.tabs.all': 'Alle Projekte',
  'ProjectsListPage.tabs.favorite': 'Favoriten',
  'ProjectsListPage.tabs.recent': 'Jüngste',
  'ProjectsListPage.Card.duration': 'Dauer des Projekts',
  'ProjectsListPage.Card.duration.unknown': 'Unbekannt',
  'ProjectsListPage.Card.duration.unset': 'Unbestimmt',
  'ProjectsListPage.Card.remove': 'Projekt aus Favoriten entfernen',
  'ProjectsListPage.Card.phase': 'Projektphase',
  'ProjectsListPage.Card.status.unknown': 'Unbekannt',
  'ProjectInvitationAcceptPage.goToProject': 'Gehen Sie zum Projekt',
  'ProjectInvitationAcceptPage.acceptAllTermsAndConditions.invitation': 'Mit dieser Einladung haben Sie Zugang zu einem neuen Projekt:',
  'ProjectInvitationAcceptPage.acceptAllTermsAndConditions.mainInvitation': 'Willkommen!',
  'ProjectInvitationAcceptPage.acceptAllTermsAndConditions.invitationNotFound': 'Die Einladung zum Projekt wurde nicht gefunden.',
  'ProjectInvitationAcceptPage.acceptAllTermsAndConditions.invitationError': 'Fehler beim Laden der Einladung.',
  'ProjectInvitationAcceptPage.invitationsDeprecated': 'Diese Art der Einladung wird nicht mehr unterstützt. Benutzer werden nun von der Organisation, zu der das Projekt gehört, zu Projekten eingeladen. Wenn Sie noch kein aktiviertes Konto haben, wenden Sie sich bitte an den Administrator der Organisation, um die Registrierung erneut zu senden.',
  'ProjectTemplateSelectSourceProject.title': 'Erstellen Sie eine Vorlage aus einem Projekt',
  'ProjectTemplateSelectSourceProjectModal.projectSelect': 'Wählen Sie ein Projekt aus',
  'ProjectTemplateSelectSourceProjectModal.error.projectMissing': 'Kein Projekt ausgewählt',
  'ProjectTemplateSelectSourceProjectModal.form.tryPairing': 'Koppeln Sie Benutzerberechtigungen',
  'ProjectTemplateSelectSourceProjectModal.form.tryPairing.tooltip': 'Ordnet Benutzerberechtigungen den Benutzerberechtigungsprofilen pro Organisation zu, wenn die Einstellungen übereinstimmen.',
  'ProjectUserInviteFormData.form.addEmail': 'E-Mail hinzufügen',
  'ProjectUserInviteFormData.form.addMultiMail': 'In großen Mengen hinzufügen',
  'ProjectUserInviteFormData.form.emails': 'Benutzer-E-Mails',
  'ProjectUserInviteFormData.form.items.groups.label': 'Weisen Sie eingeladene Benutzer Gruppen zu',
  'ProjectUserInviteFormData.form.items.message.initialValue': 'Hallo! Ich möchte Sie einladen, an diesem Projekt mitzuarbeiten.',
  'ProjectUserInviteFormData.form.items.message.label': 'Einladungstext',
  'ProjectUserInviteFormData.form.multipleInput.addEmails': 'E-Mails hinzufügen',
  'ProjectUserInviteFormData.form.multipleInput.error': 'Geben Sie eine durch Kommas getrennte Liste von E-Mail-Adressen ein.',
  'ProjectUserInviteFormData.form.multipleInput.inputText': 'Geben Sie einen Standardtext mit E-Mails ein',
  'ProjectUserInviteFormData.form.multipleInput.label': 'Massen-E-Mail-Eintrag',
  'ProjectUserInviteFormData.form.multipleInput.resultText': 'E-Mails gefunden',
  'ProjectUserInviteFormData.form.multipleInput.placeholder': 'josef.novak@gmail.com , petr.spaleny@seznam.cz',
  'ProjectUserInviteFormModal.send': 'Schicken',
  'ProjectUserInviteFormModal.title': 'Zum Projekt einladen',
  'ProjectUserStatus.active': 'aktiv',
  'ProjectUserStatus.expired': 'ist abgelaufen',
  'ProjectUserStatus.invited': 'eingeladen',
  'ProjectUserStatus.suspended': 'deaktiviert',
  'ProjectUserStatus.system': 'SYSTEM',
  'ProjectUsersEditModal.title': 'Benutzereinstellungen für ein Projekt ändern',
  'ProjectUsersEditModal.active': 'Aktiviert',
  'ProjectUsersEditModal.deactive': 'Deaktiviert',
  'filters.searchPlaceholder.labels': 'Suchtags',
  'filters.searchPlaceholder.users': 'Nach Benutzern suchen',
  'FiltersDocumentsPage.foundEntries': `{total, plural,
      =0 {keine Ergebnisse gefunden}
      one {ein Datensatz gefunden}
      other {# Datensätze gefunden}
    } in {took} ms`,
  'FiltersDocumentsPage.loading': 'Gefilterte Dokumente werden geladen...',
  'FilterPreset.category.mine': 'Meine',
  'FilterPreset.category.shared': 'Gemeinsame',
  'FilterPresetEditForm.filters.label': 'Filter einstellen',
  'FilterPresetEditFormModal.title': 'Bearbeiten einer gespeicherten Einstellung',
  'FilterPresetManageModal.title': 'Gespeicherte Filtereinstellungen verwalten',
  'FilterPresetSaveForm.title': 'Aktuelle Filtereinstellungen speichern',
  'FilterPresetSaveForm.newName': 'Speichern unter',
  'FilterPresetSaveForm.overridePreset': 'Vorhandene Einstellungen überschreiben',
  'ReportWidget.projectsInRealization.column.PlanYearICurrent': 'Plan I',
  'ReportWidget.projectsInRealization.column.PlanYearOCurrent': 'Plan O',
  'ReportWidget.projectsInRealization.column.PerformedWorkYearI': 'Wieder aufgebaut I',
  'ReportWidget.projectsInRealization.column.PerformedWorkYearO': 'Neu aufgebaut 0',
  'ReportWidget.projectsInRealization.column.remainI': 'Ich bleibe',
  'ReportWidget.projectsInRealization.column.remainO': 'O bleibt',
  'ReportWidgetCreateFormModal.title': 'Fügen Sie einen neuen Bericht hinzu',
  'ReportWidgetCreateFormModal.addButton': 'Bericht hinzufügen',
  'ReportWidgetCreateFormModalForm.unit': 'Befehl',
  'ReportWidgetCreateFormModalForm.unit.required': 'Die Bestellung ist obligatorisch',
  'ReportWidgetCreateFormModalForm.alignLabel': 'Werteausrichtung',
  'ReportWidgetCreateFormModalForm.alignLabel.required': 'Die Ausrichtung ist obligatorisch',
  'ReportWidgetCreateFormModalForm.organization.required': 'Organisation ist Pflicht',
  'ReportWidgetCreateFormModalForm.reportType': 'Berichtstyp',
  'ReportWidgetCreateFormModalForm.reportType.required': 'Der Berichtstyp ist obligatorisch',
  'ReportWidgetCreateFormModalForm.filters': 'Datenfilter',
  'ReportWidgetCreateFormModalForm.columnConfigurations': 'Angezeigte Spalten',
  'ReportWidgetCreateFormModalForm.columnConfigurations.required': 'Es ist mindestens eine Spalte erforderlich',
  'ReportWidgetCreateFormModalForm.name.info': 'Wenn nichts ausgefüllt ist, wird der Name des freigegebenen Berichts verwendet.',
  'ReportWidgetCreateFormModalForm.name.placeholder': 'Investition 2024',
  'ReportWidgetEditFormModal.title': 'Bericht bearbeiten',
  'ReportWidgetDecimalUnit.units.label': '{currency}',
  'ReportWidgetDecimalUnit.thousands.label': 'in Tausend {currency}',
  'ReportWidgetDecimalUnit.millions.label': 'in Millionen {currency}',
  'ReportWidgetDecimalUnit.billions.label': 'in Milliarden {currency}',
  'ReportWidgetLabelAlignEnum.top.label': 'Hoch',
  'ReportWidgetLabelAlignEnum.middle.label': 'Zur Mitte',
  'ReportWidgetLabelAlignEnum.bottom.label': 'Runter',
  'ReserveFileButton.label.set': 'Zur Bearbeitung vorbehalten',
  'ReserveFileButton.label.unset': 'Beenden Sie die Reservierung',
  'ReserveFileButton.tooltip.reserve': 'Reservieren Sie ein Dokument zur Bearbeitung.',
  'ReserveFileButton.tooltip.reserve.withLimit': 'Wird für {reservationDays} Tage reserviert.',
  'ReserveFileButton.tooltip.reserve.noLimit': 'Die Reservierung ist nicht zeitlich begrenzt.',
  'ReserveFileButton.tooltip.release': 'Beenden Sie die Buchung von {user} am {date}.',
  'ReserveFileButton.tooltip.release.info': 'Die Reservierung endet automatisch am {releaseDate}.',
  'ReserveFileButton.tooltip.release.noLimit': 'Die Reservierung ist zeitlich nicht begrenzt.',
  'ReserveFileButton.tooltip.reservedByUser': 'Dokument bearbeitet von {user}',
  'ReserveFileButton.message.reserve': 'Erfolgreich gebucht.',
  'ReserveFileButton.message.release': 'Die Reservierung ist beendet.',
  'RestoreInfoModal.categoryRemovedFrom.item': 'Einige nicht vorhandene Kategorien wurden für <strong>{file}</strong> entfernt',
  'RestoreInfoModal.labelRemovedFrom.item': 'Einige nicht vorhandene Tags für <strong>{file}</strong> entfernt',
  'RestoreInfoModal.newDirectoryPaths.item': 'Der Ordner (oder Pfad) <strong>{origin}</strong> wurde in <strong>{destination}</strong> umbenannt',
  'RestoreInfoModal.newFileName.item': 'Das Dokument <strong>{origin}</strong> wurde in <strong>{destination}</strong> umbenannt',
  'RestoreInfoModal.newFilePath.item': 'Der Dokumentordner <strong>{origin}</strong> wurde in <strong>{destination}</strong> umbenannt',
  'RestoreInfoModal.requireCategoryRemovedFrom.item': 'Einige nicht vorhandene erforderliche Kategorien für den Ordner <strong>{file}</strong> wurden entfernt',
  'RestoreInfoModal.title': '{directoryCount} Ordner und {fileCount} Dokumente wiederhergestellt und die folgenden Änderungen vorgenommen:',
  'RevisionCreateForm.form.items.description.label': 'Kommentar überprüfen',
  'RevisionCreateForm.form.items.description.initial': 'Legen Sie die Revision {revisionNumber} als Standard fest.',
  'RevisionCreateForm.form.items.description.placeholder': 'Beschreibung der vorgenommenen Änderungen...',
  'RevisionCreateForm.form.items.description.rules.required': 'Bitte geben Sie einen Bewertungskommentar ein',
  'RevisionCreateFormModal.fileTypeReplacement': 'Die Datei, die Sie hochladen, hat eine andere Erweiterung. Möchten Sie fortfahren?',
  'RevisionCreateFormModal.fileTypeReplacement.description': 'Die Datei mit der Erweiterung „{oldExt}“ wird in der neuen Revision durch die Datei mit der Erweiterung „{newExt}“ ersetzt.',
  'RevisionCreateFormModal.title': 'Neue Überarbeitung',
  'RevisionFromRevisionCreateFormModal.title': 'Eine neue Revision einer bestehenden Revision',
  'RevisionAttachmentsAddFormModal.title': 'Anhänge hinzufügen',
  'RevisionsDiff.pdfMissing': 'Revision ({revision}) hat keine PDF-Ableitung, der Unterschied kann nicht angezeigt werden.',
  'RevisionsDiff.pdfProcessing': 'Revision ({revision}): Das Dokument wird derzeit verarbeitet. Versuchen Sie es später erneut.',
  'RevisionsDiff.firstPdfMissing': 'Die erste Revision hat keine PDF-Ableitung, der Unterschied kann nicht angezeigt werden.',
  'RevisionsDiff.secondPdfMissing': 'Die zweite Revision hat keine PDF-Ableitung, der Unterschied kann nicht angezeigt werden.',
  'RevisionSignedDocumentAddFormModal.analyzeRunning': 'Unterschriften werden geprüft',
  'RevisionSignedDocumentAddFormModal.analyzeFinished': 'Prüfung abgeschlossen',
  'RevisionSignedDocumentAddFormModal.downloadSignedDocumentMessage': 'Laden Sie das Dokument zum Unterschreiben herunter',
  'RevisionSignedDocumentAddFormModal.invalidFileType': 'Dieser signierte Dokumentdateityp wird nicht unterstützt.',
  'RevisionSignedDocumentAddFormModal.title': 'Fügen Sie ein signiertes Dokument hinzu',
  'RevisionSignedDocumentAddFormModal.replaceNotification': 'Es ist nur ein unterschriebenes Dokument zulässig. Das bestehende Dokument wird ersetzt.',
  'RevisionSignedDocumentAddFormModal.missingSignatureWarning': 'Achtung, das hochgeladene Dokument enthält keine der Signaturen der vorherigen Version des Dokuments. Möchten Sie das Dokument trotzdem hochladen?',
  'RevisionSignedDocumentAddFormModal.unexpectedCountWraning': 'Achtung, das hochgeladene Dokument enthält die gleiche oder eine geringere Anzahl an Unterschriften. Möchten Sie das Dokument trotzdem hochladen?',
  'RevisionSignDocumentFormModal.title': 'Unterschreiben Sie das Dokument',
  'RevisionNumberTag.title': '{number}. Revision',
  'RevisionStatus.tooltips.Ok.text': '',
  'RevisionStatus.tooltips.AccessDenied.text': 'Sie haben keinen Zugriff auf das Dokument',
  'RevisionStatus.tooltips.InWorkflow.text': 'Das Dokument wird in einem Workflow zugewiesen.',
  'RevisionStatus.tooltips.InCommentProcedure.text': 'Das Dokument befindet sich im Kommentarverfahren.',
  'RevisionStatus.tooltips.Reserved.text': 'Das Dokument ist reserviert.',
  'RevisionStatus.tooltips.HasOwner.text': 'Das Dokument hat einen Administrator.',
  'RevisionStatus.tooltips.EsticonDocument.text': 'Das Dokument ist mit AspeEsticon verlinkt.',
  'RevisionRow.withAnnotations': 'Vorschau mit Kommentaren',
  'RoleForm.items.name.placeholder': 'Viewer – schreibgeschützt',
  'RoleForm.items.userId.suspended.placeholder': 'Benutzer nicht eingetragen oder deaktiviert, bitte geben Sie einen Benutzer ein',
  'RoleFormModal.title.create': 'Eine neue Rolle',
  'RoleAssignFormModal.title.create': 'Weisen Sie eine Rolle zu',
  'RoleInfo.noRole': '(keine Rolle)',
  'RoleSelect.preferredRoles': 'Ausgewählte Rollen',
  'RoleSelect.otherRoles': 'Andere',
  'RoleSelect.emptyError': 'Keine Rolle verfügbar',
  'RoleSelect.noRoleSet': 'Es ist keine Rolle ausgewählt',
  'RelativeDateWithDeadlines.tooltips.start': 'Anfang:',
  'RelativeDateWithDeadlines.tooltips.deadline': 'Begriff:',
  'RelativeDateWithDeadlines.tooltips.completion': 'Fertigstellung:',
  'RelativeDateWithDeadlines.tooltips.estimatedDates': 'Voraussichtliche Termine:',
  'RelativeDateWithDeadlines.tooltips.initialization': 'Parallelzweige ausführen:',
  'RelativeDateWithDeadlines.tooltips.parallelEnd': 'Ende der Parallelzweige:',
  'ReportChard.noValidConfiguration.title': 'Der Bericht enthält keine vollständigen Konfigurationsdaten',
  'ReportChard.noValidConfiguration.description': 'Klicken Sie hier, um das Formular zum Abschluss der Konfiguration zu öffnen',
  'SearchSortTypeItem.lastChange': 'Letzte Bearbeitung',
  'SearchSortTypeItem.modifiedBy': 'Der Benutzer, der die Änderung vorgenommen hat',
  'SearchSortTypeItem.createdDate': 'Erstellungsdatum',
  'SearchSortTypeItem.deadlineDate': 'Erforderlicher Begriff',
  'SearchSortTypeItem.description': 'Sortierung:',
  'SearchSortTypeItem.directoryPath': 'Standort (Pfad)',
  'SearchSortTypeItem.discaredDate': 'Ruhestandsdatum',
  'SearchSortTypeItem.documentCreated': 'Ein Dokument erstellen',
  'SearchSortTypeItem.documentModified': 'Dokumentenänderung',
  'SearchSortTypeItem.extension': 'Suffix',
  'SearchSortTypeItem.isModel': 'Modell',
  'SearchSortTypeItem.hasAnnotation': 'Erinnerungen',
  'SearchSortTypeItem.isSigned': 'Unterschrift',
  'SearchSortTypeItem.organization': 'Organisation',
  'SearchSortTypeItem.change': 'Datum der Änderung',
  'SearchSortTypeItem.projectName': 'Projektname',
  'SearchSortTypeItem.lastAccessDate': 'Datum des letzten Besuchs',
  'SearchSortTypeItem.projectCreated': 'Datum der Projekterstellung',
  'SearchSortTypeItem.id': 'Ausweis',
  'SearchSortTypeItem.name': 'Name',
  'SearchSortTypeItem.priority': 'Priorität',
  'SearchSortTypeItem.resolvedDate': 'Fertigstellungsdatum',
  'SearchSortTypeItem.taskDeadline': 'Das Datum des Abschlusses der aktiven Aufgabe',
  'SearchSortTypeItem.workflowRoles': 'WF-Administrator',
  'SearchSortTypeItem.orderId': 'Ausweis',
  'SearchSortTypeItem.project': 'Projekt',
  'SearchSortTypeItem.state': 'Zustand',
  'SearchSortTypeItem.path': 'Pfad',
  'SearchSortTypeItem.type': 'Typ',
  'SearchSortTypeItem.revisionCreated': 'Erstellen Sie eine Revision',
  'SignedDocumentInput.collapse.header': 'Fügen Sie ein signiertes Dokument hinzu',
  'SignedDocumentInput.collapse.text': 'Wählen Sie ein signiertes Dokument (PDF, Bild) aus.',
  'SignedDocumentInput.collapse.hint': 'Klicken oder ziehen Sie die Datei hierher',
  'SecondaryFilesInput.collapse.header': 'Fügen Sie einen Anhang hinzu',
  'SecondaryFilesInput.collapse.text': 'Klicken oder ziehen Sie Dateien hierher',
  'serviceError.AddProjectUserByMailForbiddenError': 'Sie sind nicht berechtigt, neue Benutzer per E-Mail zum Projekt einzuladen.',
  'serviceError.AdminAccessRequiredError': 'Für diesen Vorgang sind Administratorrechte erforderlich.',
  'serviceError.ApplicationAdminAccessRequiredError': 'Für diesen Vorgang sind AspeHub-Administratorrechte erforderlich.',
  'serviceError.OrganizationAdminAccessRequiredError': 'Für diesen Vorgang sind Organisationsadministratorrechte erforderlich.',
  'serviceError.ProjectAdminAccessRequiredError': 'Für diesen Vorgang sind Projektadministratorrechte erforderlich.',
  'serviceError.ProjectCardDataNameAlreadyExistsError': 'Der Name der Zusatzdaten existiert bereits.',
  'serviceError.ApplicationOrOrganizationAdminAccessRequiredError': 'Für diesen Vorgang sind AspeHub- oder Organisationsadministratorrechte erforderlich.',
  'serviceError.AppUserNotFoundError': 'Benutzeranwendungsprofil nicht gefunden.',
  'serviceError.AssignmentAttachmentIsUseError': 'Der Anhang wird im Kommentar verwendet.',
  'serviceError.BadRequestError': 'Es wurde eine falsche Anfrage gesendet.',
  'serviceError.BlobTokenInvalidError': 'Das Zugriffstoken ist ungültig.',
  'serviceError.CommentProcedureCommentAttachmentNotFoundError': 'Der ausgewählte Anhang wurde nicht gefunden.',
  'serviceError.CommentProcedureCommentAttachmentNotDiscardedBadRequestError': 'Das ausgewählte Dokument wird nicht verworfen.',
  'serviceError.CommentProcedureCommentDocumentAlreadyAddedError': 'Das Dokument ist bereits zur Mahnung hinzugefügt.',
  'serviceError.CommentProcedureCommentDeleteAttachmentForbiddenError': 'Das Verwerfen dieses Anhangs in der Erinnerung ist dem aktuellen Benutzer untersagt.',
  'serviceError.CommentProcedureCommentRestoreAttachmentForbiddenError': 'Das Aktualisieren dieses Anhangs in der Erinnerung ist für den aktuellen Benutzer untersagt.',
  'serviceError.CommentProcedureForbiddenError': 'Sie sind diesem Kommentarprozess nicht zugeordnet und verfügen nicht über die erforderlichen Zugriffsberechtigungen.',
  'serviceError.CommentProcedureDuplicateNameError': 'Es existiert bereits eine Rückrufprozedur mit diesem Namen.',
  'serviceError.CreateModelsError': 'Fehler beim Erstellen der Modellbindungen',
  'serviceError.DirectoryAlreadyExistsThereError': 'Der Ordner wurde nicht verschoben. Es befindet sich bereits an diesem Ort.',
  'serviceError.DirectoryDuplicateNameError': 'Ein Ordner mit diesem Namen existiert bereits. Bitte wählen Sie einen anderen Namen.',
  'serviceError.DirectoryMoveForbiddenError': 'Sie sind nicht berechtigt, den Ordner oder seine Unterordner zu verschieben.',
  'serviceError.DirectoryRecursiveMoveError': 'Der Ordner kann nicht in seine Unterordner verschoben werden.',
  'serviceError.DestinationDirectoryForMoveForbiddenError': 'In den ausgewählten Ordner kann nicht geschrieben werden. Sie verfügen nicht über die erforderlichen Berechtigungen.',
  'serviceError.DirectoryNotFoundError': 'Ordner nicht gefunden.',
  'serviceError.DirectoryPermissionInheritanceOnRootNotPermitedError': 'Es ist nicht möglich, Vererbungsrechte für den Stammordner festzulegen.',
  'serviceError.DocumentCategoryNodeRemoveObstacleError': 'Der Kategorieknoten wurde nicht gelöscht, da er einigen Dokumenten oder Ordnern zugeordnet ist.',
  'serviceError.DocumentCategoryTreeRemoveObstacleError': 'Die Kategorie wurde nicht gelöscht, da sie einigen Dokumenten oder Ordnern zugewiesen ist.',
  'serviceError.DocumentCommentProcedureForbiddenActionError': 'Die angegebene Aktion kann nicht ausgeführt werden. Das Dokument befindet sich im Kommentarverfahren.',
  'serviceError.DocumentInWorkflowForbiddenActionError': 'Das Dokument wird von einem Workflow verwaltet, daher kann diese Aktion nicht ausgeführt werden.',
  'serviceError.ReservedDocumentAccessError': 'Das Dokument ist reserviert und kann nicht bearbeitet werden.',
  'serviceError.DocumentReservationReleaseLockError': 'Das Dokument wurde vom Benutzer {user} bearbeitet.',
  'serviceError.DocumentReservationLockNotFoundError': 'Die Sperre für das Dokument wurde nicht gefunden',
  'serviceError.DocumentIsReservedError': 'Das Dokument ist bereits von einem anderen Benutzer reserviert.',
  'serviceError.DocumentLinkNameNotPermitedBadRequestError': 'Für Massenaktionen ist es nicht möglich, einen Liniennamen festzulegen.',
  'serviceError.DocumentLinkNameRequiredBadRequestError': 'Der Dokumentlinkname darf nicht leer sein.',
  'serviceError.DocumentLinkDuplicateNameError': 'Im Ordner ist bereits ein Link zu einem Dokument mit dem angegebenen Namen vorhanden.',
  'serviceError.DirectoryLinkDuplicateNameError': 'Im Ordner ist bereits ein Ordnerlink mit dem angegebenen Namen vorhanden.',
  'serviceError.DocumentManagerAccessError': 'Die angegebene Aktion kann nicht ausgeführt werden. Das Dokument hat einen Administrator.',
  'serviceError.DocumentStateForbiddenChangeError': 'Der Dokumentstatus kann nicht vom aktuellen Status in diesen Status geändert werden.',
  'serviceError.DocumentNotFoundError': 'Dokument nicht gefunden.',
  'serviceError.DocumentSigningIsProcessedError': 'Das Dokument wird bereits unterzeichnet.',
  'serviceError.EsticonDocumentsAlreadyLinkedError': 'Das AspeEsticon-Projekt verfügt über verknüpfte Dokumente und kann nicht geändert werden.',
  'serviceError.EsticonProjectNotFoundError': 'Das diesem Projekt beigefügte AspeEsticon-Projekt wurde nicht gefunden.',
  'serviceError.ForbiddenError': 'Sie verfügen nicht über ausreichende Berechtigungen.',
  'serviceError.GroupCountLimitExceededError': 'Die maximale Anzahl an Gruppen wurde überschritten. Es kann keine neue Gruppe erstellt werden.',
  'serviceError.GroupNotFoundError': 'Gruppe nicht gefunden.',
  'serviceError.InappropriateCommentStateForChangeError': 'Kommentare haben einen Fehlerstatus, um den Status des Kommentarvorgangs zu ändern.',
  'serviceError.LabelDuplicateNameError': 'Es existiert bereits ein Label mit diesem Namen.',
  'serviceError.LabelIsDeprecatedError': 'Das Tag ist veraltet.',
  'serviceError.LabelIsUsedOnDocumentsError': 'Die Beschriftung ist auf einem Dokument festgelegt.',
  'serviceError.LabelIsUsedOnEntitiesError': 'Die Bezeichnung wird für Anwendungsobjekte verwendet.',
  'serviceError.LabelNotFoundError': 'Etikett nicht gefunden.',
  'serviceError.ModelStateInvalidError': 'Einige Einstellungen sind ungültig.',
  'serviceError.NetworkError': 'Netzwerkfehler.',
  'serviceError.NotFoundError': 'Die angeforderte Datenquelle wurde nicht gefunden.',
  'serviceError.ParentDirectoryNotFoundError': 'Übergeordneter Ordner nicht gefunden.',
  'serviceError.ProjectNotFoundError': 'Projekt nicht gefunden.',
  'serviceError.ProjectUserInvitationAlreadyAcceptedError': 'Diese Einladung wurde bereits angenommen.',
  'serviceError.ProjectUserInvitationInvalidAcceptCodeError': 'Ungültiger Bestätigungscode für die Einladung.',
  'serviceError.ProjectUserInvitationNotFoundError': 'Ihre Einladung ist abgelaufen.',
  'serviceError.ProjectUserNotFoundError': 'Projektbenutzerprofil nicht gefunden.',
  'serviceError.RevisionIsLockedError': 'Die Dokumentrevision ist für die Signatur durch einen anderen Benutzer gesperrt.',
  'serviceError.RevisionLockNotFoundError': 'Revisionssperre nicht gefunden.',
  'serviceError.RevisionNotFoundError': 'Der angeforderte Inhalt ist nicht mehr gültig.',
  'serviceError.RevisionNotDiscardableError': 'Es ist nicht möglich, die ausgewählte Revision zu verwerfen.',
  'serviceError.RevisionNotDiscardedBadRequestError': 'Die Revision wird nicht verworfen.',
  'serviceError.RevisionRestoreForbiddenError': 'Sie verfügen nicht über ausreichende Berechtigungen, um die ausgewählte Revision wiederherzustellen.',
  'serviceError.ReturnToReworkEsticonDocumentResult.Ok': `AspeHub hat festgestellt, dass für das zusammengeführte Dokument {count, plural,
    one {ein Teil wird nicht hochgeladen}
    few {keine Teilteile hochgeladen werden}
    other {es werden keine Teilteile hochgeladen}`,
  'serviceError.ReturnToReworkEsticonDocumentResult.ZPHasRelatedObject': 'Erkennungsprotokoll hat derzeit Rg. bereits hochgeladen',
  'serviceError.ReturnToReworkEsticonDocumentResult.ZPHasParentObject': 'Dieses Teil-Erkennungsprotokoll ist Teil der Gesamt-Erkennungsprotokoll',
  'serviceError.ReturnToReworkEsticonDocumentResult.FaHasParentObject': 'Diese partielle Rg. ist Teil der kombinierten Rg.',
  'serviceError.ReturnToReworkEsticonDocumentResult.ZBVHasZP': 'ÄdB hat aktuell bereits Erkennungsprotokoll hochgeladen',
  'serviceError.ReturnToReworkEsticonDocumentResult.ObjectIsNotSent': 'Versuchen Sie, ein Dokument zurückzugeben, das derzeit nicht hochgeladen ist',
  'serviceError.ReturnToReworkEsticonDocumentResult.RelatedObjectIsNotSent': 'Versuchen Sie, ein zusammengeführtes Dokument ohne hochgeladenes Unterdokument zurückzugeben',
  'serviceError.ReturnToReworkEsticonDocumentResult.UnspecifiedError': 'Nicht spezifizierter AspeEsticon-Fehler',
  'serviceError.ServerError': '500 (Serverfehler)',
  'serviceError.SelfPermissionsCannotBeSetError': 'Der Benutzer kann keine benutzerdefinierten Berechtigungen festlegen',
  'serviceError.UnauthorizedError': 'Sie verfügen nicht über die erforderlichen Berechtigungen!',
  'serviceError.undefined': 'Unbekannter Fehler.',
  'serviceError.UploadAlreadyCompleteError': 'Der Datei-Upload ist bereits abgeschlossen.',
  'serviceError.UploadAlreadyReferencedError': 'Der Datei-Upload wird bereits an anderer Stelle verwendet.',
  'serviceError.UploadExpiredError': 'Das Zeitfenster für den Upload ist abgelaufen.',
  'serviceError.UploadMissingCompletePartsError': 'Beim Hochladen einer Datei fehlen fertige Teile.',
  'serviceError.UploadNotCompleteError': 'Der Datei-Upload wurde nicht abgeschlossen.',
  'serviceError.UploadNotFoundError': 'Upload-Prozess nicht gefunden.',
  'serviceError.UploadPartNotFoundError': 'Ein Teil der hochzuladenden Datei wurde nicht gefunden.',
  'serviceError.UserProfileAlreadyExistsError': 'Dieser Benutzer existiert bereits.',
  'serviceError.WfTemplateDuplicateNameError': 'Eine Workflow-Vorlage mit diesem Namen ist bereits vorhanden.',
  'serviceError.WorkflowDuplicateNameError': 'Ein Workflow mit diesem Namen existiert bereits. Bitte geben Sie einen anderen Namen ein.',
  'serviceError.WorkflowMultipleDocumentStateChangeError': 'Ein Workflow darf nicht mehrere Genehmigungs- und Ablehnungsknoten in einem einzigen Zweig enthalten.',
  'serviceError.WorkflowNodeWithoutRoleError': 'Für alle Benutzer- und Entscheidungsaufgaben muss die Solver-Rolle festgelegt sein.',
  'serviceError.WorkflowNotReachableNodeError': 'Alle Ports aller Knoten müssen verbunden und alle Knoten müssen erreichbar sein.',
  'serviceError.WorkflowPortShouldHaveOneEdgeError': 'Alle Ports auf allen Knoten müssen verbunden sein.',
  'serviceError.WorkflowCircularDependencyError': 'Der Workflow darf keine zyklischen Bindungen enthalten.',
  'serviceError.WorkflowTemplateInsufficientAccessLevelError': 'Sie verfügen nicht über die erforderlichen Berechtigungen, um auf die Workflow-Vorlage zuzugreifen.',
  'serviceError.CommentProcedureApproveError': 'Das Kommentarverfahren konnte nicht genehmigt werden',
  'serviceError.DirectoryForbiddenError': 'Sie haben keinen Zugriff auf diesen Ordner',
  'serviceError.RoleInProcedureError': 'Die Rolle ist Teil des Mahnverfahrens',
  'serviceError.UploadAlreadyCompletedError': 'Der Upload ist bereits abgeschlossen',
  'serviceError.UploadPartAlreadyCompletedError': 'Der Upload-Teil ist bereits abgeschlossen',
  'serviceError.DocumentMultipleAddObstacleError': 'Fehler beim Hinzufügen von Dokumenten',
  'serviceError.DocumentMultipleMoveObstacleError': 'Fehler beim Verschieben der Dateien',
  'serviceError.DocumentMultipleDiscardObstacleError': 'Fehler beim Verwerfen von Dateien',
  'serviceError.DocumentMultipleRestoreObstacleError': 'Fehler beim Wiederherstellen der Dateien',
  'serviceError.DocumentMoveDuplicateError': 'Ein Dokument mit diesem Namen existiert bereits',
  'serviceError.DirectoryRestoreDuplicateError': 'Ein Ordner mit diesem Namen existiert bereits',
  'serviceError.DocumentRestoreDuplicateError': 'Ein Dokument mit diesem Namen existiert bereits',
  'serviceError.ShareExpiredError': 'Das Zeitlimit für den Download freigegebener Inhalte ist abgelaufen',
  'serviceError.ShareInvalidPinError': 'Ungültige PIN',
  'serviceError.ShareInvalidFileCountError': 'Es konnte keine gültige Datei gefunden werden',
  'serviceError.DirectoryInsufficientAccessLevelError': 'Sie verfügen nicht über ausreichende Berechtigungen für den Ordner',
  'serviceError.GoneError': 'Der Server reagierte nicht mehr',
  'serviceError.WorkflowNodeHasCorruptedLockError': 'Der Workflow enthält unbestätigte Änderungen in den zugrunde liegenden Dokumenten',
  'serviceError.LicenceExpirationError': 'Die Lizenz ist abgelaufen',
  'serviceError.DocumentDeepDownloadForbiddenError': 'Sie haben keinen Zugriff auf einige der Dokumente, die Sie herunterladen möchten',
  'serviceError.ProjectForbiddenSuspendedError': 'Das Benutzerkonto wurde deaktiviert',
  'serviceError.ProjectUserLockError': 'Ein Vorgang konnte für das Benutzerkonto nicht ausgeführt werden, da gerade eine andere Aktion für das Konto ausgeführt wird.',
  'serviceError.CommentProcedureTeamIsUseError': 'Dem Team wird eine Erinnerung zugewiesen',
  'serviceError.EsticonUnlinkDocumentExistsError': 'Das AspeEsticon-Projekt verfügt über verknüpfte Dokumente und kann nicht entfernt werden.',
  'serviceError.EsticonDocumentNotFoundError': 'Das angeforderte AspeEsticon-Dokument wurde nicht gefunden.',
  'serviceError.CommentProcedureDuplicateTeamError': 'Ein Team mit diesem Namen existiert bereits. Geben Sie einen anderen Teamnamen ein.',
  'serviceError.EmailLimitExceedError': 'Die Anzahl der gesendeten E-Mails wurde überschritten',
  'serviceError.AddAccessLevelToAdminError': 'Die Zugriffsrechte der Administratorengruppe können nicht geändert werden.',
  'serviceError.AdminOrExtendedPermissionRequiredError': 'Für diese Aktion ist keine ausreichende Berechtigung vorhanden.',
  'serviceError.AnyDocumentNotFoundError': 'Kein Dokument gefunden.',
  'serviceError.AnnotationUsedInCommentProcedureCommentBadRequestError': 'Der eingegangene Kommentar wird der Mahnung des Mahnverfahrens zugeordnet.',
  'serviceError.ApprovaDocumentAlreadyInWorkflowError': 'Das Dokument ist in diesem Workflow bereits als genehmigtes Dokument zugeordnet.',
  'serviceError.ApprovaDocumentInsufficientRoleError': 'Genehmigtes Dokument wegen unzureichender Rolle.',
  'serviceError.AppUserForProjectNotFoundError': 'Der Anwendungsbenutzer konnte nicht zum Projektbenutzer gefunden werden.',
  'serviceError.AppUserInvalidDashbordConfigurationError': 'Ungültige Dashboard-Einstellungen.',
  'serviceError.AuditLogNotFoundError': 'Aktivität nicht gefunden.',
  'serviceError.AutodeskForgeServiceError': 'Fehler im Autodesk Forge-Dienst.',
  'serviceError.BackgroundDocumentAlreadyAssignedError': 'Das zugrundeliegende Dokument ist bereits zugeordnet.',
  'serviceError.BackgroundDocumentAlreadyCheckedError': 'Das zugrunde liegende Dokument ist bereits genehmigt oder abgelehnt.',
  'serviceError.BackgroundDocumentInsufficientAccessLevelError': 'Unzureichende Berechtigung zum Arbeiten mit dem zugrunde liegenden Dokument.',
  'serviceError.BackgroundDocumentNameAlreadExistsError': 'Ein zugrunde liegendes Dokument mit diesem Namen existiert bereits.',
  'serviceError.BackgroundDocumentNotAssignedError': 'Das zugrunde liegende Dokument ist nicht zugeordnet.',
  'serviceError.BackgroundDocumentNotFoundError': 'Das zugrunde liegende Dokument wurde nicht gefunden.',
  'serviceError.BackgroundTaskInProcessError': 'Die Ableitung wird noch bearbeitet.',
  'serviceError.BlobDerivateNotFoundError': 'Ableitungsdaten nicht gefunden.',
  'serviceError.BlobNotFoundError': 'Keine abgeleiteten Metadaten gefunden.',
  'serviceError.ChekedFilePathNotInPathsError': 'Dateipfad nicht gefunden.',
  'serviceError.CommentChangeStateForbiddenError': 'Sie sind nicht berechtigt, den Status eines Kommentars zu ändern.',
  'serviceError.CommentProcedureAddCommentForbiddenError': 'Sie sind nicht berechtigt, einen Kommentar hinzuzufügen.',
  'serviceError.CommentProcedureAlreadyHasWorkflowError': 'Dieses Erinnerungssteuerelement ist bereits an den Workflow angehängt.',
  'serviceError.CommentProcedureAttachmentChangeForbiddenError': 'Sie sind nicht berechtigt, den Anhang zu ändern.',
  'serviceError.CommentProcedureAttachmentIsUseError': 'Um einen Anhang zu verwerfen, löschen Sie ihn zunächst aus dem Kommentartext.',
  'serviceError.CommentProcedureCategoryAprovedOrClosedError': 'Es ist nicht möglich, die Kategorien des geschlossenen Kommentarverfahrens zu ändern.',
  'serviceError.CommentProcedureCategoryIsUseError': 'Es wird die Kategorie Rückrufverfahren verwendet.',
  'serviceError.CommentProcedureCategoryNotFoundError': 'Die Kategorie des Mahnverfahrens wurde nicht gefunden.',
  'serviceError.CommentProcedureCommentNoteNotFoundError': 'Kommentarnotiz nicht gefunden.',
  'serviceError.CommentProcedureCommentNotFoundError': 'Erinnerung nicht gefunden.',
  'serviceError.CommentProcedureDocumentAlreadyAddedError': 'Das Dokument wurde bereits dem Kommentarverfahren hinzugefügt.',
  'serviceError.CommentProcedureDocumentForbiddenError': 'Unzureichende Berechtigung für das Kommentarverfahrensdokument.',
  'serviceError.CommentProcedureDocumentInCommentError': 'Das Dokument wird in einer Mahnung zugeordnet.',
  'serviceError.CommentProcedureDuplicateCategoryError': 'Die Kategorie des Mahnverfahrens mit diesem Namen existiert bereits.',
  'serviceError.CommentProcedureDuplicateUserError': 'Der Benutzer ist diesem Mahnverfahren bereits zugeordnet.',
  'serviceError.CommentProcedureNoteChangeForbiddenError': 'Sie haben keine Berechtigung zum Bearbeiten eines Kommentarkommentars.',
  'serviceError.CommentProcedureNotFoundError': 'Das Mahnverfahren wurde nicht gefunden.',
  'serviceError.CommentProcedureNotifiedUserNotFoundError': 'Benachrichtigter Benutzer nicht gefunden.',
  'serviceError.CommentProcedureProcessorForbiddenError': 'Sie müssen der Bearbeiter dieses Kommentarverfahrens sein.',
  'serviceError.CommentProcedureRoleChangeForbiddenError': 'Sie haben kein Recht, die Rolle im Kommentarverfahren zu ändern.',
  'serviceError.CommentProcedureRoleProcessorEmptyError': 'Im Kommentarverfahren muss mindestens ein Hauptbearbeiter vorhanden sein.',
  'serviceError.CommentProcedureRoleSubmitterEmptyError': 'Im Kommentarverfahren muss es mindestens einen Hauptkommentator geben.',
  'serviceError.CommentProcedureStateChangeForbiddenError': 'Sie haben keine Berechtigung, den Status des Mahnverfahrens zu ändern.',
  'serviceError.CommentProcedureSubmitterForbiddenError': 'Sie müssen in diesem Kommentarprozess Kommentator sein.',
  'serviceError.CommentProcedureTeamNotFoundError': 'Das Rückrufmanagementteam wurde nicht gefunden.',
  'serviceError.CommentProcedureTeamPermissionForbiddenError': 'Sie sind nicht berechtigt, dieses Team zu ändern.',
  'serviceError.CommentProcedureUserNotFoundError': 'Der Benutzer des Erinnerungsverfahrens wurde nicht gefunden.',
  'serviceError.CommentProcedureProcessorNoteForbiddenError': 'Redakteure sind aus dieser Newsgroup ausgeschlossen.',
  'serviceError.CommentProcedureSubmitterNoteForbiddenError': 'Kommentatoren sind aus dieser Newsgroup ausgeschlossen.',
  'serviceError.DbConcurrencyError': 'Bei der gleichzeitigen Verarbeitung mehrerer Anfragen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
  'serviceError.DestinationDirectoryForDocumentMoveForbiddenError': 'Sie haben im Zielverzeichnis keine Berechtigung zum Schreiben von Dateien.',
  'serviceError.DirectoryAdminGroupAccessLevelCannotBeModifiedError': 'Die Zugriffsberechtigungen der Administratorgruppe können nicht geändert werden.',
  'serviceError.DirectoryEsticonImportDiscardForbiddenError': 'Der Ordner ist (oder enthält) das Verzeichnis, das zum Importieren von Daten aus esticon ausgewählt wurde.',
  'serviceError.DirectoryIdRequiredError': 'Die obligatorische Ordner-ID fehlt.',
  'serviceError.DirectoryInvalidNameError': 'Ungültiger Ordnername.',
  'serviceError.DirectoryIsAlreadyDiscardedError': 'Der Ordner ist bereits verworfen.',
  'serviceError.DirectoryIsNotDiscardedError': 'Der Ordner wird nicht verworfen.',
  'serviceError.DirectoryIsNotEmptyError': 'Der Ordner ist nicht leer.',
  'serviceError.DirectoryIsRootError': 'Der Stammordner kann nicht gelöscht oder verschoben werden.',
  'serviceError.DirectoryRootNotFoundError': 'Stammordner nicht gefunden.',
  'serviceError.DirectoryTreeMaxDepthExceededError': 'Die maximale Eintauchtiefe des Ordners wurde überschritten.',
  'serviceError.DirectoryUserAccessAlreadyExistsError': 'Der Benutzer hat bereits Zugriff auf diesen Ordner.',
  'serviceError.DiscoveryError': 'Beim Herstellen einer WOPI-Verbindung ist ein Fehler aufgetreten.',
  'serviceError.DocumentAlreadyInModelError': 'Das Dokument ist bereits Modellen zugeordnet.',
  'serviceError.DocumentAlreadyReservedError': 'Das Dokument ist bereits reserviert.',
  'serviceError.DocumentCategoryNodeDuplicateNameError': 'Ein Kategorieknoten mit diesem Namen existiert bereits.',
  'serviceError.DocumentCategoryNodeNotFoundError': 'Kategorieknoten nicht gefunden.',
  'serviceError.DocumentCategoryTreeDuplicateIdInListError': 'Der Kategoriebaum enthält eine doppelte ID.',
  'serviceError.DocumentCategoryTreeDuplicateNameError': 'Der Kategoriebaum enthält eine doppelte ID.',
  'serviceError.DocumentCategoryTreeMaxDepthExceededError': 'Die maximale Einbettungstiefe des Kategoriebaums wurde erreicht.',
  'serviceError.DocumentCategoryTreeNotFoundError': 'Kategoriebaum nicht gefunden.',
  'serviceError.DocumentCategoryTreeRootIsFixedError': 'Der Kategoriestammknoten kann nicht verschoben werden.',
  'serviceError.DocumentCreatorAccessError': 'Das Dokument hat bereits einen anderen Administrator.',
  'serviceError.DocumentDuplicateNameError': 'Im Ordner ist bereits ein Dokument mit diesem Namen vorhanden.',
  'serviceError.DocumentDuplicateNamesError': 'Sekundärdokumente mit diesen Namen existieren bereits.',
  'serviceError.DocumentIdRequiredError': 'Die Dokument-ID ist ein erforderlicher Parameter.',
  'serviceError.DocumentInCommentProcedureForbiddenActionError': 'Das Dokument ist Teil des Mahnverfahrens und kann nicht geändert werden.',
  'serviceError.DocumentIsAlreadyDiscardedError': 'Das Dokument wurde bereits verworfen.',
  'serviceError.DocumentIsNotDiscardedError': 'Das Dokument wird nicht verworfen.',
  'serviceError.DocumentIsNotInModelError': 'Das Dokument ist keinen Modellen zugeordnet.',
  'serviceError.DocumentIsUploadingError': 'Das Dokument wird noch hochgeladen.',
  'serviceError.DocumentLinkedToEsticonError': 'Das Dokument kann nicht verworfen werden, da es Inhalte von Esticon enthält.',
  'serviceError.DocumentNotContainsRequiredCategoryTreeError': 'Das Dokument enthält keine obligatorischen Kategorien.',
  'serviceError.DocumentNotReservedError': 'Das Dokument ist nicht reserviert.',
  'serviceError.DocumentRequiredCategoryTreeDeleteError': 'Die Kategorie wird im Dokumentenordner nach Bedarf eingestellt.',
  'serviceError.DocumentRemoveFromModelMultipleObstacleError': 'Das Dokument kann nicht aus den Modellen entfernt werden',
  'serviceError.DocumentReservationReleaseForbiddenError': 'Nur der Administrator kann die Reservierung eines anderen Benutzers stornieren.',
  'serviceError.DocumentWithManagerForbiddenActionError': 'Das Dokument hat einen Administrator und kann nicht reserviert werden.',
  'serviceError.EsticonAccessRequiredError': 'Nur das Esticon-Systemkonto kann diese Aktion ausführen.',
  'serviceError.EsticonDirectoryIsNotCreatedError': 'Der Ordner zum Importieren von Dateien aus esticon ist nicht festgelegt.',
  'serviceError.EventLogInsufficientAccessLevelError': 'Sie verfügen nicht über ausreichende Berechtigungen, um den Aktivitätsverlauf anzuzeigen.',
  'serviceError.FavoriteDirectoryNotFoundError': 'Lieblingsordner nicht gefunden.',
  'serviceError.FavoriteDocumentNotFoundError': 'Lieblingsdokument nicht gefunden.',
  'serviceError.GroupDuplicateNameError': 'Eine andere Gruppe mit diesem Namen existiert bereits.',
  'serviceError.GroupIsSystemFixedError': 'Systemgruppe kann nicht geändert werden.',
  'serviceError.InappropriateCommentProcedureStateError': 'Die Aktion kann in diesem Status des Mahnverfahrens nicht durchgeführt werden.',
  'serviceError.InappropriateCommentStateError': 'Die Aktion kann nicht ausgeführt werden: Eine der Erinnerungen ist nicht im richtigen Zustand.',
  'serviceError.InappropriateDocumentStateError': 'Eines der Dokumente ist nicht gültig.',
  'serviceError.InappropriateTaskStateError': 'Unerwarteter Fehler: Die Hintergrundaufgabe hat keinen gültigen Status.',
  'serviceError.InappropriateTaskTypeError': 'Ungültiger Aufgabentyp.',
  'serviceError.InappropriatMessageStatusError': 'Einige Nachrichten befinden sich bereits im ausgewählten Status.',
  'serviceError.InvalidWopiSessionError': 'Wopi-Authentifizierung fehlgeschlagen: ungültige Sitzung.',
  'serviceError.LinkNotFoundError': 'Bindung erworben gefunden.',
  'serviceError.MessageBadUserError': 'Sie können den Status dieser Nachricht nicht ändern.',
  'serviceError.MetaextractorTaskInProcessError': 'Derivat konnte nicht entfernt werden: Metaextraktionsaufgabe wird noch ausgeführt.',
  'serviceError.MetadataDefinationTemplateNotFoundError': 'Metadatendefinition nicht gefunden.',
  'serviceError.ModelHasLinkError': 'Das Entfernen des Modells würde dazu führen, dass seine Bindungen aufgehoben würden.',
  'serviceError.ModelOperationInsufficientAccessLevelError': 'Sie verfügen nicht über ausreichende Berechtigungen, um als Modell mit diesem Dokument zu arbeiten.',
  'serviceError.MdDivisionObstacleError': 'Der Benutzer in der Abteilung konnte nicht geändert werden',
  'serviceError.MdUserAccessRequiredError': 'Für diesen Vorgang ist eine Gewerkschaftsmitgliedschaft erforderlich.',
  'serviceError.MdRoleAccessRequiredError': 'Für diesen Vorgang ist eine Rollenmitgliedschaft erforderlich.',
  'serviceError.MdDivisionO910CannotBeChangedError': 'Abteilung O910 kann nicht geändert werden.',
  'serviceError.O910CanHaveOnlyO910UsersError': 'Benutzer in der Rolle O910 können nur aus der Abteilung O910 stammen.',
  'serviceError.MdDivisionNotFoundError': 'Abteilung nicht gefunden.',
  'serviceError.MdDivisionIsInMdProjectError': 'Der ausgewählte Bereich wird dem Projekt zugeordnet.',
  'serviceError.MdRoleNotFoundError': 'Die angeforderte Rolle wurde nicht gefunden.',
  'serviceError.MdProjectNotFoundError': 'Das angeforderte Projekt wurde nicht gefunden.',
  'serviceError.MdProjectHasIntentionError': 'Das ausgewählte Projekt hat Absichten geschaffen.',
  'serviceError.MdUserNotFoundError': 'Sie verfügen nicht über ausreichende Berechtigungen.',
  'serviceError.MdModuleNotFoundError': 'Das MD-Modul ist für die ausgewählte Organisation nicht aktiviert.',
  'serviceError.MdUserNotInSetDivisionError': 'Der Benutzer befindet sich nicht in der ausgewählten Revision.',
  'serviceError.MdDivisionDuplicateNameError': 'Ein Zweig mit demselben Namen existiert bereits.',
  'serviceError.MdProjectDataCkApprovalReadinesConditionNotMetError': 'Die Voraussetzungen für die Feststellung der Genehmigungsreife liegen nicht vor.',
  'serviceError.MdProjectDuplicateNameError': 'Ein Projekt mit demselben Namen existiert bereits.',
  'serviceError.MdProjectUnsupportedShiftError': 'Der gesetzte Zustand ist für ein gestanztes Projekt nicht möglich.',
  'serviceError.MdProjectUnsupportedStateOpperationError': 'Die Aktion wird für den ausgewählten Projektstatus nicht unterstützt.',
  'serviceError.MdProjectShiftParametersNotSetError': 'Einige für den Projektwechsel erforderliche Projekteinstellungen sind nicht festgelegt.',
  'serviceError.MdProjectShiftProjectDataNotSetError': 'Es gibt keine verbindlichen Projektwechseltermine.',
  'serviceError.MdUserInAotherDivisionError': 'Der Benutzer befindet sich in einer anderen Abteilung.',
  'serviceError.MdRoleWithSetHeadUserAlreadyExistError': 'Der Rolle ist bereits ein ausgewählter Benutzer hinzugefügt.',
  'serviceError.MdRoleIsProjectGuarantorError': 'Der Benutzer in der Rolle ist der Garant des Projekts.',
  'serviceError.MdRoleIsProjectProcessorError': 'Der Benutzer in der Rolle ist der Entwickler des Projekts.',
  'serviceError.MdRoleIsSupervisorError': 'Der Benutzer in der Rolle ist der Abteilungsleiter.',
  'serviceError.MdDivisionUserObstacleError': 'Ein Benutzer kann nicht aus einer Abteilung entfernt werden, da er sich in einer Rolle oder einem Projekt befindet.',
  'serviceError.MdDivisionO910NotAllowedError': 'Abschnitt O910 ist nicht zulässig.',
  'serviceError.MdMeetingApprovalBackgroundNumberAlreadyExistsBadRequestError': 'Es existiert bereits eine Kommissionssitzung mit der eingegebenen Seriennummer',
  'serviceError.MdApprovalMeetingBackgroundMinutesStateNotFoundError': 'Die Unterlagen für die Sitzung des Zentralkomitees sind noch nicht erstellt',
  'serviceError.IdmIsNotConnectedError':'Verbindung zum IDM fehlgeschlagen',
  'serviceError.IdmIsNotEnabledError':'Verbindung zum IDM ist nicht eingerichtet',
  'serviceError.MdMeetingApprovalBackgroundNotActiveBadRequest': 'Der Hintergrund für das Verfahren der Zentralkommission befindet sich nicht in einem aktiven Zustand',
  'serviceError.OverheadMdProjectUserInMaterialsError': 'Das ausgewählte Projekt wird im MD-Material verwendet.',
  'serviceError.NotUpdatableBackgroundDocumentStateError': 'Für diese Anfrage kann derzeit kein Beleg hochgeladen werden.',
  'serviceError.NotUpdatableWorkflowNodeStateError': 'Der Workflow-Knoten kann in diesem Zustand nicht geändert werden.',
  'serviceError.NotUpdatableWorkflowStateError': 'Der Workflow kann in diesem Zustand nicht geändert werden.',
  'serviceError.OverheadMdProjectNotSetError': 'Das Gemeinkostenprojekt ist nicht festgelegt.',
  'serviceError.ProjectActionForbiddenError': 'Diese Aktion kann nicht ausgeführt werden.',
  'serviceError.ProjectDuplicateNameError': 'Ein Projekt mit diesem Namen existiert bereits.',
  'serviceError.ProjectForbiddenError': 'Sie haben keine Berechtigung für dieses Projekt.',
  'serviceError.ProjectNotReadyError': 'Das Projekt befindet sich noch im Aufbau.',
  'serviceError.ProjectSettingNotFoundError': 'Projekteinstellungen nicht gefunden.',
  'serviceError.ProjectUserInvitationInvalidTermsAcceptCodeError': 'Sie stimmen nicht mit dem Einladungsbestätigungscode des Benutzers überein.',
  'serviceError.ProjectUserStatusCannotBeChangedError': 'Der Benutzerstatus kann nicht geändert werden.',
  'serviceError.ProjectUserSuspendedError': 'Der Benutzer ist deaktiviert.',
  'serviceError.ReferencedWorkflowNodeNotExistError': 'Ungültige WF-Vorlage: Der referenzierte Knoten existiert nicht.',
  'serviceError.RestoreDirectoryForbiddenError': 'Nur der Projektadministrator kann einen verworfenen Ordner wiederherstellen.',
  'serviceError.RestoreDocumentForbiddenError': 'Nur der Projektadministrator kann ein verworfenes Dokument wiederherstellen.',
  'serviceError.RoleAlreadyUsedError': 'Die Rolle wird in dieser Aufgabe bereits verwendet.',
  'serviceError.RoleAlreadyVotedError': 'Sie haben bereits für alle Ihre Rollen in dieser Rolle gestimmt.',
  'serviceError.RoleDuplicateNameError': 'Eine andere Rolle mit diesem Namen existiert bereits.',
  'serviceError.RoleInCommentProcedureError': 'Die Rolle wird im Mahnverfahren verwendet.',
  'serviceError.RoleIsAlreadyUsedError': 'Die Rolle ist bereits verwendet.',
  'serviceError.RoleNotFoundError': 'Rolle nicht gefunden.',
  'serviceError.RoleRequireUserForbiddenError': 'Einer Rolle muss ein Benutzer zugewiesen sein.',
  'serviceError.SecondaryDocumentNotFoundError': 'Anhang nicht gefunden.',
  'serviceError.ShareNotFoundError': 'Link zum Teilen nicht gefunden.',
  'serviceError.SourceDirectoryForDocumentMoveForbiddenError': 'Um Dokumente in einen anderen Ordner zu verschieben, benötigen Sie Schreibrechte.',
  'serviceError.DocumentMultipleStateChangeObstacleError': 'Einige Dokumentstatus aus der Dokumentenliste können nicht in den gewünschten Status geändert werden.',
  'serviceError.TmpBlobRequiredError': 'Für diese Aktion ist eine temporäre Datei erforderlich.',
  'serviceError.UploadAcccessDeniedError': 'Sie haben keine Berechtigung, hier eine Datei hochzuladen.',
  'serviceError.UploadedTaskNotFoundError': 'Upload-Aufgabe nicht gefunden.',
  'serviceError.UploadPartETagIsRequiredError': 'Etag fehlt für die Aufnahme.',
  'serviceError.UserCantRemoveFromAdminGroupError': 'Die Administratorgruppe kann nicht entfernt werden.',
  'serviceError.UserIsInRoleError': 'Der Benutzer kann nicht gelöscht werden: Er ist Rollen zugewiesen.',
  'serviceError.UserIsNotInvitedError': 'Der Benutzer hat keine aktive Einladung.',
  'serviceError.UserIsNotTeamAdminError': 'Sie müssen ein Teammanager sein.',
  'serviceError.UserIsSystemFixedError': 'Der Systembenutzer kann nicht geändert werden.',
  'serviceError.UserNameNotVerifiedBadRequestError': 'Um Dokumente unterschreiben zu können, müssen Sie authentifiziert sein.',
  'serviceError.UserOrRoleCanBeSetError': 'Sie müssen entweder eine verantwortliche Rolle oder einen Benutzer angeben (aber nicht beides).',
  'serviceError.WopiSessionNotFoundError': 'Wopi-Sitzung nicht gefunden.',
  'serviceError.WorkflowApprovalRevisionNotFoundError': 'Keine genehmigte Revision gefunden',
  'serviceError.WorkflowAutomaticActionShouldHaveOneOutputPortError': 'Eine automatische Aufgabe muss genau eine Ausgabe haben.',
  'serviceError.WorkflowDefaultDirectoryAlreadyUsedError': 'Der Ordner für Hintergrunddokumente wird in diesem Workflow bereits verwendet.',
  'serviceError.WorkflowDefaultDirectoryAssignedRequestError': 'Der Standardordner kann nicht entfernt werden, da er bereits die zugrunde liegenden Dokumente enthält.',
  'serviceError.WorkflowDefaultDirectoryDuplicateNameError': 'Ein Standardordner mit diesem Namen ist bereits vorhanden.',
  'serviceError.WorkflowDefaultDirectoryDuplicatePathError': 'Dieser Ordner ist bereits in einem anderen Standardordner ausgewählt.',
  'serviceError.WorkflowDefaultDirectoryInsufficientAccessLevelError': 'Um einen Standardordner auszuwählen, müssen Sie über Schreibzugriff auf diesen Ordner verfügen.',
  'serviceError.WorkflowDefaultDirectoryNotFoundError': 'Standardordner nicht gefunden.',
  'serviceError.WorkflowHeadShouldHaveOneOutputPortError': 'Die Startaufgabe sollte genau eine Ausgabe haben.',
  'serviceError.WorkflowInsufficientAccessLevelError': 'Sie verfügen in diesem Workflow nicht über ausreichende Berechtigungen.',
  'serviceError.WorkflowInvalidDocumentAliasError': 'Ungültiger Workflow-Dokumentalias.',
  'serviceError.WorkflowInvalidRoleError': 'Ungültige Workflow-Rolle.',
  'serviceError.WorkflowNodeDuplicateRoleError': 'Dieselbe Rolle wird in einer Aufgabe mehrmals verwendet.',
  'serviceError.WorkflowNodeInsufficientAccessLevelError': 'Sie sind nicht berechtigt, Änderungen an dieser Aufgabe vorzunehmen.',
  'serviceError.WorkflowNodeNotAcceptedBackgroundDocumentError': 'Alle Belege müssen genehmigt werden.',
  'serviceError.WorkflowNodeNotFoundError': 'Workflow-Aufgabe nicht gefunden.',
  'serviceError.WorkflowNodePortNotFoundError': 'Die Ausgabe der Workflow-Aufgabe wurde nicht gefunden.',
  'serviceError.WorkflowNodeRoleNotFoundError': 'Workflow-Rolle nicht gefunden.',
  'serviceError.WorkflowNotFoundError': 'Workflow nicht gefunden.',
  'serviceError.WorkflowTailMustHaveNoNoOutputPortError': 'Der End-Task darf keine Ausgänge haben.',
  'serviceError.WorkflowTemplateDuplicateNameError': 'Eine andere Vorlage mit diesem Namen existiert bereits.',
  'serviceError.WorkflowTemplteNotFoundError': 'WF-Vorlage nicht gefunden.',
  'serviceError.WorkflowTemplteShouldHaveOneHeadError': 'Die WF-Vorlage darf nur 1 Startaufgabe haben.',
  'serviceError.WorkflowUnassihnedAliasError': 'Sie müssen die Standardordner zum Hochladen der zugrunde liegenden Dokumente ausfüllen.',
  'serviceError.WorkflowNodeAttachmentNotDiscardedBadRequestError': 'Der Workflow-Aufgabenanhang wird nicht verworfen.',
  'serviceError.WorkflowNodeAttachmentNotFoundError': 'Workflow-Job-Anhang nicht gefunden.',
  'serviceError.WorkflowNodeNoteChangeForbiddenError': 'Die Diskussionsnotiz zur Workflow-Aufgabe kann nicht geändert werden.',
  'serviceError.WorkflowNodeNoteNotFoundError': 'Diskussionsnotiz zur Workflow-Aufgabe nicht gefunden.',
  'serviceError.WorkflowNodeNotifiedUserNotFoundError': 'Der benachrichtigte Benutzer wurde in der Workflow-Aufgabe nicht gefunden.',
  'serviceError.WorkflowApprovalDocumentAlreadyAssignedError': 'Das Dokument wurde bereits einem anderen Workflow zugewiesen.',
  'ServiceError.WorkflowApprovalRevisionBadStateError': 'Der ausgewählte Dokumentstatus ist im Workflow nicht zulässig.',
  'ServiceError.WorkflowApprovalDocumentBadStateError': 'Der ausgewählte Dokumentstatus ist im Workflow nicht zulässig.',
  'serviceError.WorkflowHasCriticalErrorError': 'Im Workflow ist ein kritischer Fehler aufgetreten',
  'serviceError.AssignmentCloseBadRequestError': 'Die Aufgabe ist geschlossen und kann nicht bearbeitet werden.',
  'serviceError.AssignmentDuplicateNameBadRequestError': 'In diesem Projekt existiert bereits eine andere Aufgabe mit diesem Namen.',
  'serviceError.AssignmentFailedSaveServerError': 'Aufgabe konnte nicht gespeichert werden.',
  'serviceError.AssignmentForbiddenError': 'Sie sind nicht berechtigt, diese Aufgabe anzuzeigen.',
  'serviceError.AssignmentNotFoundError': 'Aufgabe nicht gefunden.',
  'serviceError.DocumentEsticonForbiddenActionError': 'Das Dokument kann nicht aktualisiert werden, da es an Esticon angehängt ist.',
  'serviceError.EsticonDocumentIsPartialError': 'Der Status eines Esticon-Unterdokuments kann nicht geändert werden.',
  'serviceError.InappropriateStateChangeBadRequestError': 'Der Aufgabenstatus kann nicht auf diesen Wert geändert werden.',
  'serviceError.InappropriateUserForChangeBadRequestError': 'Sie können den Aufgabenstatus nicht auf diesen Wert ändern.',
  'serviceError.InsufficientSubWorkflowCountError': 'Unzureichende Anzahl paralleler Zweige.',
  'serviceError.ProjectUserNotActivatedError': 'Einige der Benutzer sind nicht aktiv.',
  'serviceError.SubWorkflowNotFoundError': 'Subworkflow nicht gefunden.',
  'serviceError.WorkflowApprovalDocumentNotFoundNotFoundError': 'Genehmigtes Dokument nicht gefunden.',
  'serviceError.WorkingPositionAssignedToUserError': 'Einem Benutzer in der Organisation wird eine Stellenposition zugewiesen.',
  'serviceError.WorkingPositionNameAlreadyExistsError': 'Es gibt bereits eine Stelle mit dieser Bezeichnung.',
  'serviceError.InvaitedUserNameDifferentError': 'Die Einladung gehört einem anderen Benutzer.',
  'serviceError.AssignmentAttachmentChangeForbiddenError': 'Der Aufgabenanhang kann nicht geändert werden.',
  'serviceError.AssignmentAttachmentForbiddenError': 'Sie sind nicht berechtigt, diese Aufgabe anzuzeigen.',
  'serviceError.AssignmentAttachmentNotFoundError': 'Aufgabenanhang nicht gefunden.',
  'serviceError.AssignmentDeleteAttachmentForbiddenError': 'Sie sind nicht berechtigt, diesen Aufgabenanhang zu löschen.',
  'serviceError.AssignmentNoteChangeForbiddenError': 'Nur der Autor kann eine Notiz bearbeiten.',
  'serviceError.AssignmentNoteNotFoundError': 'Aufgabennotiz nicht gefunden.',
  'serviceError.AssignmentNotifiedUserNotFoundError': 'Der Benachrichtigungsbenutzer ist kein Teilnehmer dieser Aufgabe.',
  'serviceError.AssignmentRestoreAttachmentForbiddenError': 'Der Aufgabenanhang konnte nicht wiederhergestellt werden.',
  'serviceError.DirectoryCreateForbiddenError': 'Sie sind nicht berechtigt, hier einen Ordner zu erstellen.',
  'serviceError.DocumentNotSignableBadRequestError': 'Diese Art von Dokument kann nicht signiert werden.',
  'serviceError.DocumentSignBlobIsObsoleteError': 'Die Gültigkeit der Signatur des Dokuments ist veraltet.',
  'serviceError.InappropriateSigningStateError': 'Fehler beim Signieren des Dokuments: Ungültiger Status.',
  'serviceError.InvalidReturnAddressError': 'Fehler beim Signieren des Dokuments: Ungültige Absenderadresse.',
  'serviceError.SigningNotFoundError': 'Signatur nicht gefunden.',
  'serviceError.OrganizationUserLastAdminError': 'Der letzte Administrator konnte nicht gelöscht werden',
  'serviceError.OrganizationUserInProjectError': 'Es ist nicht möglich, einen im Projekt enthaltenen Benutzer zu löschen',
  'serviceError.UserConfirmationResendRecentlyBadRequestError': 'Die Bestätigungs-E-Mail des Benutzers wurde kürzlich gesendet. Bitte versuchen Sie es später noch einmal.',
  'serviceError.UserAlreadyConfirmedBadRequestError': 'Der Benutzer hat die Aktivierungs-E-Mail bereits bestätigt.',
  'serviceError.OrganizationDuplicateNameError': 'Eine Organisation mit diesem Namen existiert bereits in AspeHub',
  'serviceError.OrganizationUserInProjectTemplateError': 'Der Benutzer kann nicht entfernt werden, da er in der Projektvorlage enthalten ist',
  'serviceError.OrganizationUserNotFoundError': 'Benutzer nicht gefunden',
  'serviceError.OrganizationNotFoundError': 'Die angeforderte Organisation wurde nicht gefunden.',
  'serviceError.OrganizationIsSameError': 'Die gewünschte Organisation ist dieselbe wie die aktuelle.',
  'serviceError.OrganizationForbiddenError': 'Die Organisation enthält keinen aktuellen Benutzer mit Administratorrechten.',
  'serviceError.OrganizationHasProjectError': 'Das Löschen einer Organisation mit einem bestehenden Projekt ist nicht zulässig.',
  'serviceError.OrganizationReportForbiddenError': 'Für den aktuellen Benutzer wurde keine erweiterte Berechtigung für den Organisationsbericht gefunden.',
  'serviceError.OrgLabelIsUsedOnEntitiesError': 'Die Bezeichnung wird für Organisationsobjekte verwendet.',
  'serviceError.ExtendedPermissionTemplateNotFoundError': 'Die angeforderte Berechtigungsvorlage wurde nicht gefunden.',
  'serviceError.ExtendedPermissionTemplateInProjectTemplateError': 'Die Berechtigungsvorlage wird in der Projektvorlage verwendet.',
  'serviceError.ExtendedPermissionTemplateDuplicateNameError': 'Eine Berechtigungsvorlage mit diesem Namen ist bereits vorhanden.',
  'serviceError.ProjectTemplateNotFoundError': 'Die angeforderte Projektvorlage wurde nicht gefunden.',
  'serviceError.ProjectTemplateAdminGroupNotFoundError': 'Eine Projektvorlage kann nur eine Gruppe von Administratoren enthalten.',
  'serviceError.ProjectTemplateUserNotFoundError': 'Der angeforderte Projektvorlagenbenutzer wurde nicht gefunden.',
  'serviceError.CategoryNodeParentNotFoundError': 'Ein übergeordneter Kategorieknoten wurde nicht gefunden.',
  'serviceError.DirectoryParentNotFoundError': 'Ein übergeordnetes Verzeichnis wurde nicht gefunden.',
  'serviceError.CategoryNodeTemplateNotFoundError': 'Die angeforderte Vorlagenknotenkategorie wurde nicht gefunden.',
  'serviceError.GroupTemplateNotFoundError': 'Die angeforderte Vorlagengruppe wurde nicht gefunden.',
  'serviceError.UserIsInWorkflowError': 'Benutzer können nicht gelöscht werden. Es spielt im Workflow eine Rolle.',
  'serviceError.UserIsInCommentProcedureError': 'Benutzer können nicht gelöscht werden. Er nimmt am Mahnverfahren teil.',
  'serviceError.ComparedDocumentRevisionsAreIdenticalBadRequestError': 'Die verglichenen Dokumentrevisionen sind identisch.',
  'serviceError.KontrolaSkupinIssueError': 'Nicht alle ÄdB-Artikel sind gruppiert',
  'serviceError.DocumentStateOperationForbiddenError': 'Sie verfügen nicht über ausreichende Berechtigungen',
  'serviceError.AssignmentOverviewPermissionNotSetError': 'Sie verfügen nicht über ausreichende Berechtigungen, um die Aufgabenübersicht anzuzeigen.',
  'serviceError.MdProjectHasNotCreatedHubProjectError': 'Für das Material wurde noch kein AspeHub-Projekt erstellt',
  'serviceError.EsticonConnectionFirmIsUsedError': 'Die Verbindung zu dieser AspeEsticon-Datenbank wird in einigen Projekten verwendet',
  'serviceError.ProjectMetadataDefinitionIsUsedError':'Die Projektmetadatendefinition wird für einige Verzeichnisse oder Dokumente verwendet.',
  'serviceError.MdApprovalMeetingBackgroundMinutesDocumentExist':'Ein Dokument mit Unterlagen zur Zentralkomiteesitzung mit diesem Namen existiert bereits',
  'serviceError.MdApprovalMeetingBackgroundMinutesNotDirExist':'Es gibt kein Verzeichnis zur Aufbewahrung von Dokumenten für die Sitzungen des Zentralkomitees',
  'shareDownloadFormModal.errorsTitle.obstacle': 'Die folgenden Fehler verhindern die Erstellung eines freigegebenen Links:',
  'shareDownloadFormModal.errorsTitle.obstaclePartial': 'Die folgenden Fehler verhindern die Erstellung eines vollständig freigegebenen Links:',
  'ShareDownloadFormModal.send': 'Schicken',
  'ShareDownloadFormModal.sendNext': 'Schicken Sie andere',
  'ShareDownloadFormModal.title': 'Link zum Herunterladen der Datei(en).',
  'ShareDownloadFormModal.success': 'Der Link zum Herunterladen der Datei(en) wurde erfolgreich gesendet.',
  'ShareDownloadForm.form.emails': 'Benutzer-E-Mails',
  'SharedDownload.header.file': 'Laden Sie die Datei vom AspeHub-Portal herunter',
  'SharedDownload.header.archive': 'Laden Sie das Archiv vom AspeHub-Portal herunter',
  'SharedDownload.form.items.includeSubdirectories.title': 'Unterverzeichnisse einschließen',
  'SharedDownload.form.items.includeSubdirectories.label': 'Freigabeordner inklusive aller Unterverzeichnisse',
  'SharedDownload.form.items.message.label': 'Nachricht an den Empfänger',
  'SharedDownload.page.button.download': 'Herunterladen',
  'SharedDownload.page.button.download.Others': 'Andere herunterladen',
  'SharedDownload.page.downloadError': 'Beim Herunterladen ist ein Fehler aufgetreten:',
  'SharedDownload.page.error': 'Beim Laden der Dokumentfreigabe ist ein Fehler aufgetreten',
  'SharedDownload.page.error.download': 'Beim Herunterladen freigegebener Dokumente ist ein Fehler aufgetreten ({error})',
  'SharedDownload.page.error.obstacle': 'Die folgenden Dokumente können nicht heruntergeladen werden',
  'SharedDownload.page.expired': 'Download abgelaufen am {date}',
  'SharedDownload.page.expiredInterval': 'Der Link ist {interval} Tage gültig',
  'SharedDownload.page.file': 'Datei',
  'SharedDownload.page.label.Pin': 'STIFT:',
  'SharedDownload.page.label.name': 'Archivname:',
  'SharedDownload.page.pin.placeholder': 'Geben Sie Ihre PIN ein',
  'SharedDownload.page.ready': 'Download-Anfrage erfolgreich gesendet',
  'SharedDownload.page.revision': 'Revision',
  'SharedDownload.page.user': `Benutzer <strong>{user}</strong> hat Ihnen {count, plural,
    one {folgende Datei}
    few {folgende # Dateien}
    other {folgende # Dateien}
    } gesendet:`,
  'SharedReportWidgetsModal.title': 'Geteilte Widgets verwalten',
  'SharedReportWidgetsModal.confirmDelete.title': 'Bestätigen Sie das Löschen des freigegebenen Widgets',
  'SharedReportWidgetsModal.confirmDelete.description': 'Durch das Löschen eines freigegebenen Widgets ist dieses Widget für Benutzer nicht mehr verfügbar, die dieses Widget zu ihrem Dashboard hinzugefügt haben. Weitermachen?',
  'ShowFavoriteSettingResponseModal.documents': 'Unterlagen',
  'ShowFavoriteSettingResponseModal.links': 'Linien',
  'SignalR.directory.newDocumentAdded': `{count, plural,
    =0 {Kein Dokument hinzugefügt}
    one {1 neues Dokument hinzugefügt von {user}}
    few {# neue Dokumente hinzugefügt von {user}}
    other {# neue Dokumente wurden hinzugefügt von {user}}
    }`,
  'SignalR.directory.onDocumentsRestored': `{count, plural,
    =0 {Es wurden keine Dokumente wiederhergestellt.}
    one {1 Dokument wurde von {user} wiederhergestellt.}
    few {# Dokumente wurden von {user} wiederhergestellt.}
    other {# Dokumente wurden von {user} wiederhergestellt.}
    }`,
  'SignalR.directory.onDocumentDiscarded': `{count, plural,
    =0 {Kein Dokument wurde verworfen.}
    one {1 Dokument wurde von {user} verworfen.}
    few {# Dokumente wurden von {user} verworfen.}
    other {# Dokumente wurde von {user} gelöscht.}
    }`,
  'SignalR.directory.onDocumentChanged': `{count, plural,
    =0 {Kein Dokument wurde geändert.}
    one {1 Dokument wurde von {user} geändert.}
    few {# Dokumente wurden von {user} geändert.}
    other {# Dokumente wurden von {user} geändert.}
    }`,
  'SignalR.directory.onDocumentMovedTo': `{count, plural,
    =0 {Es wurden keine Dokumente hierher verschoben.}
    one {1 Dokument wurde vom Benutzer {user} hierher verschoben.}
    few {# Dokumente wurden vom Benutzer {user} hierher verschoben.}
    other {# Dokumente wurden von {user} hierher verschoben.}
    }`,
  'SignalR.directory.onDocumentMovedOut': `{count, plural,
    =0 {Es wurden keine Dokumente aus diesem Ordner verschoben.}
    one {1 Dokument wurde von {user} aus diesem Ordner verschoben.}
    few {# Dokumente wurden aus diesem Ordner verschoben von {user }.}
    other {# Dokumente wurden von {user} aus diesem Ordner verschoben.}
    }`,
  'SignalR.directory.onDocumentLinkAdded': `{count, plural,
    =0 {Kein Dokumentlink hinzugefügt.}
    one {1 Dokumentlink hinzugefügt von {user}.}
    few {# Dokumentlinks hinzugefügt von {user}.} 
    other {# Dokument Links hinzugefügt von {user}.}
    }`,
  'SignalR.directory.onDocumentLinkRemoved': `{count, plural,
    =0 {Es wurden keine Dokumentverweise entfernt.}
    one {1 Dokumentverweis wurde von {user} entfernt.}
    few {# Dokumentverweise wurden von {user} entfernt.} 
    other {# Dokumentlinks wurden von {user} entfernt.}
    }`,
  'SignalR.directory.onDocumentLinkMovedOut': `{count, plural,
    =0 {Es wurden keine Dokument-Links aus diesem Ordner verschoben.}
    one {1 Dokument-Link wurde aus diesem Ordner von {user} verschoben.}
    few {# Dokument-Links wurden verschoben aus diesem Ordner von {user}.}
    other {# Dokumentlinks wurden aus diesem Ordner von {user} verschoben.}
    }`,
  'SignalR.directory.onDocumentLinkMovedTo': `{count, plural,
    =0 {Kein Dokumentlink hierher verschoben.}
    one {1 Dokumentlink hierher verschoben von {user}.}
    few {# Dokumentlinks hierher verschoben von {user}.}
    other {# Dokumentlinks wurden von {user} hierher verschoben.}
    }`,
  'SignalR.directory.onDocumentLinkNameChanged': `{count, plural,
    =0 {Es wurden keine Dokumentverknüpfungen geändert.}
    one {1 Dokumentverknüpfungen wurden von {user} geändert.}
    few {# Dokumentverknüpfungen wurden von {user} geändert.} 
    other {Anzahl der Dokumentlinks wurden von {user} geändert.}
    }`,
  'SignalR.directory.directoryLocationChanged': 'Der Pfad zu diesem Ordner wurde geändert.',
  'SignalR.directory.DirectoryCurrentUserRightsChanged': 'Die Rechte für diesen Ordner wurden geändert.',
  'SignalR.document.commentProcedureChanged.message': 'Das Mahnverfahren {name} wurde geändert. {reason}',
  'SignalR.document.commentProcedureChanged.CommentProcedureChangedActionEnum.added': 'Dokument zum Kommentarverfahren hinzugefügt.',
  'SignalR.document.commentProcedureChanged.CommentProcedureChangedActionEnum.removed': 'Dokument aus dem Kommentarverfahren entfernt.',
  'SignalR.document.commentProcedureChanged.CommentProcedureChangedActionEnum.addedToComment': 'Dokument zum Kommentar hinzugefügt.',
  'SignalR.document.commentProcedureChanged.CommentProcedureChangedActionEnum.removedFromComment': 'Dokument aus Kommentar entfernt.',
  'SignalR.document.commentProcedureChanged.CommentProcedureChangedActionEnum.nameChanged': 'Der Name des Mahnverfahrens wurde geändert.',
  'SignalR.document.commentProcedureChanged.CommentProcedureChangedActionEnum.commentTitleChanged': 'Der Name des Kommentars wurde geändert.',
  'SignalR.document.commentProcedureChanged.CommentProcedureChangedActionEnum.commentStateChanged': 'Kommentarstatus geändert.',
  'SignalR.document.documentDiscarded.message': 'Das Dokument wurde vom Benutzer {user} verworfen.',
  'SignalR.document.documentMoved.message': 'Das Dokument wurde von {user} nach {path} verschoben.',
  'SignalR.document.documentChanged.message': 'Das Dokument wurde von {user} geändert.\n{reason}',
  'SignalR.document.documentChanged.messageWithoutUser': 'Das Dokument wurde geändert.\n{reason}',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.rezervation': 'Dokument reserviert.',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.owner': 'Geänderter Dokumentenmanager.',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.revision': 'Revision ändern.',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.icon': 'Vorschau geändert.',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.state': 'Status geändert.',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.labels': 'Etiketten geändert.',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.name': 'Name geändert.',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.isModel': 'Änderung der Modellbezeichnung.',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.isFavorite': 'Favoritenstatus geändert.',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.category': 'Kategorie geändert.',
  'SignalR.document.linksChanged.message': 'Der Link {name} wurde von {user} geändert. {reason}',
  'SignalR.document.linksChanged.LinkActionEnum.added': 'Neuer Link erstellt.',
  'SignalR.document.linksChanged.LinkActionEnum.removed': 'Link entfernt.',
  'SignalR.document.linksChanged.LinkActionEnum.rename': 'Link umbenannt.',
  'SignalR.document.linksChanged.LinkActionEnum.moved': 'Link verschoben.',
  'SignalR.document.bindingChanged.message': 'Der Link zum Dokument {name} wurde von {user} geändert. {reason}',
  'SignalR.document.bindingChanged.DocToDocLinkActionEnum.added': 'Link erstellt.',
  'SignalR.document.bindingChanged.DocToDocLinkActionEnum.removed': 'Bindung entfernt.',
  'SignalR.document.revisionChanged.message': 'Die Revision von {name} wurde von {user} geändert. {reason}',
  'SignalR.document.revisionChanged.DocumentChangedPropertyEnum.added': 'Neue Revision hinzugefügt',
  'SignalR.document.revisionChanged.DocumentChangedPropertyEnum.discarded': 'Überarbeitung verworfen',
  'SignalR.document.revisionChanged.DocumentChangedPropertyEnum.restored': 'Die Überarbeitung wurde fortgesetzt',
  'SignalR.document.revisionChanged.DocumentChangedPropertyEnum.signAdded': 'Signiertes Dokument hinzugefügt',
  'SignalR.document.revisionChanged.DocumentChangedPropertyEnum.secondaryAdded': 'Sekundärdokument hinzugefügt',
  'SignalR.document.revisionChanged.DocumentChangedPropertyEnum.annotationChanged': 'Anmerkung hinzugefügt',
  'SignalR.document.revisionChanged.DocumentChangedPropertyEnum.name': 'Revisionsname geändert',
  'SignalR.document.revisionChanged.DocumentChangedPropertyEnum.blobProcessed': 'Revisionsdatei verarbeitet',
  'SignalR.document.workflowChanged.message': 'Der Workflow {name} wurde von {user} geändert. {reason}',
  'SignalR.document.workflowChanged.WorkflowChangedActionEnum.addedApproval': 'Ein genehmigtes Dokument wurde hinzugefügt.',
  'SignalR.document.workflowChanged.WorkflowChangedActionEnum.removedApproval': 'Ein genehmigtes Dokument wurde entfernt.',
  'SignalR.document.workflowChanged.WorkflowChangedActionEnum.addedApprovalRevision': 'Eine Überarbeitung des genehmigten Dokuments wurde hinzugefügt.',
  'SignalR.document.workflowChanged.WorkflowChangedActionEnum.backgroundChanged': 'Das zugrunde liegende Dokument oder sein Status wurde geändert.',
  'SignalR.document.workflowChanged.WorkflowChangedActionEnum.state': 'Es ist eine Statusänderung aufgetreten.',
  'SignalR.esticonGrid.onObjectAdded': `{count, plural,
  =0 {Kein Objekt hinzugefügt.}
  one {1 Objekt hinzugefügt von {user}.}
  few {# Objekte hinzugefügt von {user}.}
  other {# Objekte wurden hinzugefügt von {user}.}
  }`,
  'SignalR.esticonGrid.onObjectDateChanged': `{count, plural,
  =0 {Das Genehmigungsdatum von keinem Objekt wurde geändert.}
  one {1 Objekt wurde das {user}-Genehmigungsdatum geändert.}
  other {# Objekten wurde das {user}-Genehmigungsdatum geändert geändert. }
  }`,
  'SignalR.esticonGrid.onObjectReturnForRework': `{count, plural,
  =0 {Es wurden keine Objekte zur Überarbeitung zurückgegeben.}
  one {1 Objekt wurde zur Überarbeitung von {user} zurückgegeben.}
  few {# Objekte wurden zur Überarbeitung von {user} zurückgegeben.} 
  other {# Objekte wurden von {user} zur erneuten Verarbeitung zurückgegeben.}
  }`,
  'SignalR.md.HubProjectAdded.message': 'Das Material wurde aktualisiert',
  'SignalR.workflow.workflowChanged.message': 'Der Workflow wurde vom Benutzer {user} geändert.',
  'SignalR.workflow.workflowTemplateChanged.message': 'Die Workflow-Vorlage wurde von {user} geändert.',
  'ToolbarSettingsButton.close': 'Einstellungen schließen',
  'ToolbarSettingsButton.open': 'Ordnereinstellungen',
  'UnsupportedBrowser.continueAnyway': 'Machen Sie trotzdem weiter',
  'UnsupportedBrowser.error': 'Diese Anwendung wird von dieser Browserversion nicht unterstützt.',
  'UploadNewDocument.tooltip': 'Laden Sie ein neues Dokument hoch',
  'UploadState.toolbar.showOnlyErrors': 'Nur Upload-Fehler anzeigen',
  'UploadState.error.uploadDeniedError': 'Hochladen abgelehnt: {reason}',
  'UserActivity.report.modal.rangeLabel': 'Das Datum des exportierten Benutzeraktivitätszeitraums',
  'UserActivity.report.modal.checkbox.exportAllProjectTimeReport': 'Datenexport ab Projektbeginn',
  'UserActivity.report.modal.disableOk.tooltipText': 'Kein relevanter Zeitraum angegeben',
  'UserActivity.report.modal.title': 'Exportieren Sie Benutzeraktivitäten und Projekteinstellungen für den ausgewählten Zeitraum',
  'UserAvatar.MultiUserAvatar.documentUserAndRevisionUserTitle': 'Autor des Dokuments und letzte Überarbeitung',
  'UserAvatar.MultiUserAvatar.documentUserTitle': 'Dokumentautor',
  'UserAvatar.MultiUserAvatar.revisionUserTitle': 'Autor der letzten Überarbeitung',
  'UserAvatar.revisionTitle': 'Rezensionsautor',
  'UserDetailPanel.ConfirmSuspend.title': 'Benutzerdeaktivierung',
  'UserDetailPanel.ConfirmSuspend.option': 'Deaktivieren Sie den Benutzer gleichzeitig in allen anderen Projekten, in denen er kein Administrator ist',
  'UserDetailPanel.ConfirmSuspend.text': 'Sind Sie sicher, dass Sie diesen Benutzer für dieses Projekt deaktivieren möchten?',
  'UserDetailPanel.ConfirmActivate.text': 'Sind Sie sicher, dass Sie diesen Benutzer aktivieren möchten?',
  'UserDetailPanel.GeneralTab': 'Benutzereinstellungen',
  'UserDetailPanel.GeneralTab.currentUser': 'Derzeit angemeldeter Benutzer',
  'UserDetailPanel.GeneralTab.reinviteButton': 'Senden Sie die Aktivierungs-E-Mail erneut',
  'UserDetailPanel.GeneralTab.deleteInvitedButton': 'Benutzer löschen',
  'UserDetailPanel.GeneralTab.suspendButton': 'Benutzer deaktivieren',
  'UserDetailPanel.GeneralTab.suspendingIsPossible': 'Benutzer können deaktiviert werden',
  'UserDetailPanel.GeneralTab.activateButton': 'Benutzer aktivieren',
  'UserDetailPanel.GeneralTab.reinviteExceeded': 'Die maximale Anzahl gesendeter E-Mails für die E-Mail-Adresse des Benutzers wurde überschritten. Bitte versuchen Sie es später noch einmal.',
  'UserDetailPanel.GeneralTab.reinviteError': 'Beim erneuten Senden der Einladung ist ein Fehler aufgetreten.',
  'UserDetailPanel.GeneralTab.deleteInvitedError': 'Beim Versuch, die Einladung zu löschen, ist ein Fehler aufgetreten.',
  'UserDetailPanel.GeneralTab.deactivateUserError': 'Beim Versuch, den Benutzer zu deaktivieren, ist ein Fehler aufgetreten.',
  'UserDetailPanel.GeneralTab.activateError': 'Beim Versuch, den Benutzer zu aktivieren, ist ein Fehler aufgetreten.',
  'UserDetailPanel.GeneralTab.licenceError': 'Sie verfügen nicht über genügend Lizenzen, um einen anderen Benutzer zu aktivieren.',
  'UserDetailPanel.GeneralTab.reinviteSuccess': 'Die Einladung wurde erfolgreich versendet.',
  'UserDetailPanel.GeneralTab.deleteInvitedSuccess': 'Die Einladung wurde erfolgreich gelöscht.',
  'UserDetailPanel.GeneralTab.deactivateUserSuccess': 'Der Benutzer wurde erfolgreich deaktiviert.',
  'UserDetailPanel.GeneralTab.activateSuccess': 'Der Benutzer wurde erfolgreich aktiviert.',
  'UserDetailPanel.GeneralTab.currentLoggedUser': 'Derzeit angemeldeter Benutzer',
  'UserDetailPanel.GroupsTab': 'Gruppen',
  'UserDetailPanel.GroupsTab.UserDisabledWarning': 'Der Benutzer ist deaktiviert. Gruppen können nicht eingerichtet werden.',
  'UserDetailPanel.PermisionsTab': 'Genehmigung',
  'UserDetailPanel.PermisionsTab.categoryType.projectManager': 'Projektmanager',
  'UserDetailPanel.PermisionsTab.categoryType.document': 'Dokumente – aktivierte Status',
  'UserDetailPanel.PermisionsTab.categoryType.workflow': 'Arbeitsablauf',
  'UserDetailPanel.PermisionsTab.categoryType.aspeEsticon': 'AspeEsticon',
  'UserDetailPanel.PermisionsTab.category.addWorkflow': 'Bearbeitung von Vorlagen',
  'UserDetailPanel.PermisionsTab.category.category': 'Kategorie',
  'UserDetailPanel.PermisionsTab.category.externalApplications': 'Externe Anwendungen',
  'UserDetailPanel.PermisionsTab.category.groups': 'Gruppen',
  'UserDetailPanel.PermisionsTab.category.importHarmonogram': 'Planen Sie den Import',
  'UserDetailPanel.PermisionsTab.category.returnForRework': 'Zurück zur Überarbeitung',
  'UserDetailPanel.PermisionsTab.category.importFaktura': 'Rechnungen importieren',
  'UserDetailPanel.PermisionsTab.category.importZP': 'Import vom Erkennungsprotokoll',
  'UserDetailPanel.PermisionsTab.category.importZBV': 'Import von ÄdB',
  'UserDetailPanel.PermisionsTab.category.importWorkflow': 'Import-/Export-Workflow',
  'UserDetailPanel.PermisionsTab.category.labels': 'Etiketten',
  'UserDetailPanel.PermisionsTab.category.roleEditing': 'Bearbeiten von Rollen',
  'UserDetailPanel.PermisionsTab.category.userEditing': 'Benutzer bearbeiten',
  'UserDetailPanel.PermisionsTab.category.stavba': 'AspeEsticon-Modul',
  'UserDetailPanel.PermisionsTab.categoryDescription.stavba': 'Aktivierung des gesamten Baumoduls, d. h. Budget, Anzapfung und mehr.',
  'UserDetailPanel.PermisionsTab.category.prenosHub': 'Anzeige einschließlich nicht gesendeter Daten auf AspeHub',
  'UserDetailPanel.PermisionsTab.category.workflowPreview': 'Workflow-Vorschau',
  'UserDetailPanel.PermisionsTab.category.setApprovalDate': 'Genehmigungsdatum festlegen',
  'UserDetailPanel.PermisionsTab.category.transferKD': 'Übertragen in die Kontrolldatenbank',
  'UserDetailPanel.PermisionsTab.category.addPartner': 'Gründung von Partnern',
  'UserDetailPanel.PermisionsTab.category.forbidWorkInProgressState': 'verbietenWorkInProgressState',
  'UserDetailPanel.PermisionsTab.category.forbidSharedState': 'verbietenSharedState',
  'UserDetailPanel.PermisionsTab.category.forbidRejectedState': 'forbidRejectedState',
  'UserDetailPanel.PermisionsTab.category.forbidPublishedState': 'verbietenPublishedState',
  'UserDetailPanel.PermisionsTab.category.forbidarchivedState': 'verbietenarchivedState',
  'UserDetailPanel.PermisionsTab.setDefaultProjectPermission': 'Legen Sie Standardprojektberechtigungen fest',
  'UserDetailPanel.userActivitiesTab': 'Benutzeraktivitäten – Sitzungen',
  'UserDetailPanel.RolesTab': 'Rolle',
  'UserDetailPanel.RolesTab.activity': 'Rollenaktivität {name}',
  'UserDetailPanel.RolesTab.AddRole': 'Fügen Sie eine Rolle hinzu',
  'UserDetailPanel.RolesTab.AddRole.title': 'Benutzer auf Rolle festlegen',
  'UserDetailPanel.RolesTab.AddRole.description': 'Ersetzt den aktuellen Benutzer aus der angegebenen Rolle.',
  'UserDetailPanel.RolesTab.rolesListTitle': 'Benutzerrolle',
  'UserDetailPanel.RolesTab.ConfirmRemove.roleInDocument': 'Dokumentieren:',
  'UserDetailPanel.RolesTab.ConfirmRemove.roleInWorkflow': 'WF:',
  'UserDetailPanel.RolesTab.ConfirmRemove.roleInWorkflowTemplate': 'WF-Vorlage:',
  'UserDetailPanel.RolesTab.ConfirmRemove.roleInCommentProcedure': 'Kündigungsverfahren:',
  'UserDetailPanel.RolesTab.ConfirmRemove.title': 'Die ausgewählte Rolle wird in einem bestehenden Workflow verwendet',
  'UserDetailPanel.RolesTab.ConfirmRemove.content': `{count, plural,
    =0 {Rolle wird nicht verwendet}
    one {Rolle wird für ein Element verwendet}
    few {Rolle wird für # Elemente verwendet}
    other {Rolle wird für # Elemente verwendet}
    }.`,
  'UserDetailPanel.RolesTab.ConfirmRemove.question': 'Sind Sie sicher, dass Sie den Benutzer der Rolle entfernen möchten?',
  'UserDetailPanel.RolesTab.ConfirmChange.title': 'Die ausgewählte Rolle hat bereits einen Benutzer',
  'UserDetailPanel.RolesTab.ConfirmChange.content': 'Die ausgewählte Rolle hat bereits den Benutzer {user}, fortfahren?',
  'UserListPanel.addUserButton.disableTooltip': 'Alle verfügbaren Benutzer der Organisation sind bereits im Projekt, gleichzeitig haben Sie keine Berechtigung, neue Benutzer einzuladen',
  'UserNotificationButton.resolver.notification.title': 'Solver-Hinweis',
  'UserNotificationButton.resolver.notification.wasNotified': 'Der Löser wurde bereits benachrichtigt',
  'UserNotificationButton.resolver.notification.label': 'Nachricht für die Löser',
  'UserNotificationButton.resolver.notification.WorkflowNodeResolveDemand': 'Hallo, ich sende eine Benachrichtigung über eine ausstehende WF-Aufgabe.',
  'UserNotificationButton.resolver.notification.AssignmentResolveDemand': 'Hallo, ich sende eine Benachrichtigung über eine ungelöste Aufgabe.',
  'UserNotificationButton.resolver.notification.noText': 'Es gibt keine Meldung für den Löser',
  'UserNotificationStatisticReport.empty': 'Keine Nachrichten zum Anzeigen',
  'UserAssignmentsStatisticReport.empty': 'Sie haben keine aktiven Aufgaben',
  'UserAssignmentsStatisticReport.userRole.tooltip': 'Die Rolle des Benutzers in der Aufgabe',
  'UserAssignmentsStatisticReport.date.tooltip': 'Aufgabenfrist',
  'SessionTab.auditlogTitle': 'Benutzeraktivitäten',
  'SessionTab.OrderFilter.sessionStart': 'Sitzungsbeginn',
  'SessionTab.OrderFilter.sessionEnd': 'Ende der Sitzung',
  'SessionTab.OrderFilter.period': 'Zeitraum',
  'SessionListItem.sessionStart': 'Anfang:',
  'SessionListItem.sessionEnd': 'Ende:',
  'SessionListItem.IpAddress': 'IP-Adresse:',
  'UsersFilter.documentUser': 'Dokumentautor',
  'UsersFilter.discardedUser': 'Vom Benutzer entfernt',
  'UsersFilter.revisionUser': 'Rezensionsautor',
  'UsersFilter.modifiedUser': 'Autor ändern',
  'UsersFilter.ownerUser': 'Dokumentenmanager',
  'UsersSelect.emptyError': 'Kein Benutzer verfügbar',
  'UserTransfer.sourceTitle': 'Verfügbare Benutzer',
  'UserTransfer.targetTitle': 'Ausgewählte Benutzer',
  'UserTransfer.searchPlaceholder': 'Suchen',
  'UserTransfer.itemUnit': 'Benutzer',
  'UserTransfer.itemsUnit': 'Benutzer',
  'UserTransfer.selectAll': 'Alles auswählen',
  'UserTransfer.selectInvert': 'Auswahl umkehren',
  'ArchiveViewer.headers.size': 'Größe',
  'ArchiveViewer.headers.type': 'Archivtyp',
  'EmailViewer.headers.subject': 'Thema',
  'EmailViewer.headers.from': 'Absender',
  'EmailViewer.headers.to': 'Dem',
  'EmailViewer.headers.cc': 'Kopie',
  'EmailViewer.headers.date': 'Datum',
  'EmailViewer.headers.attachments': 'Anhänge',
  'PdfViewer.title': 'PDF',
  'PdfSignatureAction.documentInWorkflowWarning.title': 'Das Dokument befindet sich im Workflow',
  'PdfSignatureAction.documentInWorkflowWarning.content': 'Dieses Dokument ist in einen laufenden Workflow eingebettet und kann nicht außerhalb desselben signiert werden. Möchten Sie zu diesem Workflow wechseln?',
  'EditDocumentOnlineAction.documentInWorkflowWarning.title': 'Das Dokument befindet sich im Workflow',
  'EditDocumentOnlineAction.documentInWorkflowWarning.content': 'Dieses Dokument wird in den laufenden Workflow eingefügt und kann außerhalb desselben nicht bearbeitet werden. Möchten Sie zu diesem Workflow wechseln?',
  'PdfAnnotationAction.documentInWorkflowWarning.title': 'Das Dokument befindet sich im Workflow',
  'PdfAnnotationAction.documentInWorkflowWarning.content': 'Dieses Dokument wird in den laufenden Workflow eingefügt und kann außerhalb nicht kommentiert werden. Möchten Sie zu diesem Workflow wechseln?',
  'ProcessedJsonViewer.title': 'Inhalt',
  'ProcessedJsonViewer.processor.notFound': 'Dateiinhalte werden nicht unterstützt.',
  'ProcessedJsonViewer.processor.file.error': 'Fehler beim Laden des Dateiinhalts',
  'ForgeViewer.title': 'Modell',
  'ImageViewer.title': 'Bild',
  'OfficeViewer.title': 'Büro',
  'VideoViewer.title': 'Video',
  'AudioViewer.title': 'Klang',
  'XmlViewer.title': 'XML',
  'XmlViewer.copyWarning': 'HINWEIS: XML-Texteinträge wurden dekodiert (z. B. „Radius < 5 cm“ statt „Radius < 5 cm“). Das Kopieren des Vorschautexts erzeugt möglicherweise KEIN gültiges XML.',
  'ZipViewer.title': 'Reißverschluss',
  'WatchSwitch.watch': 'Schiene',
  'WatchSwitch.buttonTitle.nameChange': 'Namensänderung',
  'WatchSwitch.buttonTitle.descriptionChange': 'Beschreibung ändern',
  'WatchSwitch.buttonTitle.categoryChange': 'Kategorien ändern',
  'WatchSwitch.buttonTitle.permissionChange': 'Berechtigungen ändern',
  'WatchSwitch.buttonTitle.directoryDiscard': 'Ordner verwerfen',
  'WatchSwitch.buttonTitle.directoryMove': 'Verschieben Sie einen Ordner',
  'WatchSwitch.buttonTitle.addSubdirectory': 'Unterordner hinzufügen',
  'WatchSwitch.buttonTitle.removeSubdirectory': 'Entfernen eines Unterordners',
  'WatchSwitch.buttonTitle.addDocument': 'Ein Dokument hinzufügen',
  'WatchSwitch.buttonTitle.removeDocument': 'Entfernen Sie das Dokument',
  'WatchSwitch.buttonTitle.revisionChange': 'Revision ändern',
  'WatchSwitch.buttonTitle.download': 'Sehen Sie sich das Dokument an und laden Sie es herunter',
  'WatchSwitch.buttonTitle.documentShare': 'Ein Dokument mit Projektmitgliedern teilen',
  'WatchSwitch.buttonTitle.linkShare': 'Senden Sie einen Download-Link',
  'WatchSwitch.buttonTitle.linkShareDownload': 'Laden Sie den übermittelten Link herunter',
  'WatchSwitch.buttonTitle.stateChange': 'Statusänderung',
  'WatchSwitch.buttonTitle.labelChange': 'Etikettenwechsel',
  'WatchSwitch.buttonTitle.ownerChange': 'Eigentümerwechsel',
  'WatchSwitch.buttonTitle.documentMove': 'Verschieben Sie ein Dokument',
  'WatchSwitch.buttonTitle.documentDiscard': 'Ein Dokument verwerfen',
  'WatchSwitch.buttonTitle.documentSign': 'Unterzeichnung des Dokuments',
  'WatchSwitch.buttonTitle.annotationChange': 'Kommentare ändern',
  'WatchSwitch.buttonTitle.attachmentChange': 'Anhänge ändern',
  'WatchSwitch.buttonTitle.cpChange': 'Änderung im Kommentarverfahren',
  'WatchSwitch.buttonTitle.wfChange': 'Änderung innerhalb des Workflows',
  'WatchSwitch.buttonTitle.assignmentChange': 'Änderung innerhalb einer Aufgabe',
  'WidgetReportsContext.message.addWidget.success': 'Das Widget wurde erfolgreich zum Dashboard hinzugefügt',
  'WidgetReportsContext.message.cloneWidget.prefix': 'Kopie',
  'WidgetReportsContext.message.cloneWidget.error.notFound': 'Widget-Konfiguration nicht gefunden. Es kann keine Kopie erstellt werden.',
  'WipRecordDetail.additionalInformation': 'Zusätzliche Daten',
  'WipRecordDetail.administrator': 'Administrator / Investor',
  'WipRecordDetail.approval': 'Genehmigung',
  'WipRecordDetail.destinationObjectId': 'Verbindung zum Objekt',
  'WipRecordDetail.objectPath': 'Objekt',
  'WipRecordDetail.constructionParticipants': 'Baubeteiligte',
  'WipRecordDetail.contractor': 'Anbieter',
  'WipRecordDetail.issue': 'Belichtung',
  'WipRecordDetail.overview': 'Überblick',
  'WipRecordDetail.projectRealizationWithVAT': 'Pumpen mit MwSt.',
  'WipRecordDetail.protocolNumber': 'Protokollnummer',
  'WipRecordDetail.quantityCfW': 'Menge an WV',
  'WipRecordDetail.vatByExposed': 'MwSt. je nach Ausgabe',
  'WipRecordDetail.realized': 'Gepumpt',
  'WipRecordDetail.realizedQuantityType': 'Art der geförderten Menge',
  'WipRecordDetail.realizedWithValorization': 'Gepumpt mit Aufwertung',
  'WipRecordDetail.realizedWithValorizationAndVAT': 'Gepumpt mit Aufwertung und Mehrwertsteuer',
  'WipRecordDetail.retentionIncludingVAT': 'Quellensteuer inklusive MwSt.',
  'WipRecordDetail.suspensionIncludingVAT': 'Aussetzung inklusive MwSt.',
  'WipRecordDetail.retentionWithoutVAT': 'Quellensteuer ohne MwSt.',
  'WipRecordDetail.suspensionWithoutVAT': 'Aussetzung ohne MwSt.',
  'WipRecordDetail.taxPeriod': 'Steuersaison',
  'WipRecordDetail.totalIncludingVAT': 'Gesamtsumme inklusive MwSt.',
  'WipRecordDetail.valorization': 'Aufwertung',
  'WipRecordDetail.VAT': 'MwSt. ({percent}%)',
  'WipRecordDetail.VATTransferRegime.title': 'MwSt.-Übertragungssystem',
  'WipRecordDetail.VATTransferRegime.option.notApplied': 'Nicht anwendbar',
  'WipRecordDetail.VATTransferRegime.option.receiverOfTheInvoiceWillPayVAT': 'MwSt. wird vom Rechnungsempfänger bezahlt',
  'WipRecordDetail.VATTransferRegime.option.VATReverseChargedFromContractor': 'Vom Anbieter (Lieferanten) abgeführte MwSt.',
  'Workflow.name.full': 'ID {id} – {name}',
  'Workflow.name.id': 'ID {id}',
  'Workflow.nodes.start': 'Anfang',
  'Workflow.nodes.stop': 'Ende',
  'Workflow.nodes.unknown': '??',
  'Workflow.nodes.ApprovalNode': 'Entscheidungsrolle',
  'Workflow.nodes.GenericNode': 'Benutzerrolle',
  'Workflow.nodes.userTask': 'Benutzerrolle',
  'Workflow.nodes.userAction': 'Benutzerrolle',
  'Workflow.nodes.notification': 'Beachten',
  'Workflow.nodes.accept': 'Genehmigung von Dokumenten',
  'Workflow.nodes.refuse': 'Ablehnung von Dokumenten',
  'Workflow.nodes.parallel': 'Parallelverzweigung',
  'Workflow.nodes.addRevision': 'Änderung von Dokumenten',
  'Workflow.nodes.input': 'Eingang',
  'Workflow.nodes.parallel_END': 'Parallelverzweigung - Ende',
  'Workflow.nodes.changeDocState': 'Dokumentstatus ändern',
  'WorkflowDetail.ActiveTaskDetail.tabname': 'Aufgaben',
  'WorkflowDetail.CommentProcedures.tabname': 'Mahnverfahren',
  'WorkflowDetail.DefaultFolders.tabname': 'Standardordner',
  'WorkflowDetail.DefaultFolders.buttonAdd.text': 'Ordner hinzufügen',
  'WorkflowDetail.DefaultFolders.invalidFolder': 'Ungültiger Standardordner',
  'WorkflowDetail.DefaultFolders.name': 'Name',
  'WorkflowDetail.DefaultFolders.noDirectory': '(Ordner nicht festgelegt)',
  'WorkflowDetail.DefaultFolders.description': 'Beschreibung',
  'WorkflowAddRequestFormModal.title': 'Fügen Sie eine Anfrage für ein zugrunde liegendes Dokument hinzu',
  'WorkflowChangeRequestFormModal.title': 'Anfrage bearbeiten',
  'WorkflowAddRequestForm.name': 'Der Name der Anfrage',
  'WorkflowAddRequestForm.name.required': 'Bitte geben Sie den Namen der Anfrage ein',
  'WorkflowAddRequestForm.name.unique': 'Es existiert bereits eine andere Anfrage mit diesem Namen',
  'WorkflowAddRequestForm.description': 'Beschreibung der Anfrage',
  'WorkflowAddRequestForm.insertionTerm': 'Erforderlicher Begriff',
  'WorkflowAddRequestForm.insertionTerm.required': 'Bitte geben Sie das gewünschte Datum ein',
  'WorkflowAddRequestForm.insertionTerm.target': 'Aufgabenfrist',
  'WorkflowAddRequestForm.workflowDefaultDirectoryId': 'Ordner zum Hochladen von Dokumenten',
  'WorkflowAddRequestForm.workflowDefaultDirectoryId.required': 'Bitte wählen Sie einen Ordner aus',
  'WorkflowAddRequestForm.workflowDefaultDirectoryId.placeholder': 'Ordner zum Hochladen von Dateien',
  'WorkflowAddRequestForm.responsibleRoleId': 'Verantwortlicher (Rolle)',
  'WorkflowAddRequestForm.responsibleRoleId.required': 'Bitte wählen Sie eine verantwortliche Rolle aus',
  'WorkflowApprovalDocumentActivityList.activityType.addedDocument': 'Dokument zum Workflow hinzugefügt',
  'WorkflowApprovalDocumentActivityList.activityType.addedRevision': 'Eine neue Revision des Dokuments wurde hochgeladen',
  'WorkflowApprovalDocumentActivityList.activityType.signed': 'Dokumentrevision unterzeichnet',
  'WorkflowApprovalDocumentActivityList.activityType.changedAnnotation': 'Das Dokument wurde kommentiert',
  'WorkflowApprovalDocumentActivityModal.title': 'Änderungen an einem Dokument in einem Workflow',
  'WorkflowApprovalDocumentActivityHistoryButton.tooltip': 'Änderungsverlauf im Workflow dokumentieren',
  'WorkflowCommentProcedures.removeProcedure.tooltip': 'Entfernen Sie die Erinnerungssteuerung aus dem Workflow',
  'WorkflowCommentProcedures.removeProcedure.confirm': 'Erinnerungsprozedur wirklich entfernen?',
  'WorkflowCommentProcedures.removeProcedure.success': 'Erinnerungsvorgang erfolgreich entfernt.',
  'WorkflowDetail.DocumentApprovals.tabname': 'Genehmigte Dokumente ({count})',
  'WorkflowDetail.DocumentApprovals.revisionsModalTitle': 'Überarbeitung des Dokuments zu diesem WF',
  'WorkflowDetail.BackgroundDocuments.tabname': 'Hintergrunddokumente ({count})',
  'WorkflowDetail.WorkflowActivity.tabname': 'Aktivität',
  'WorkflowDetail.WorkflowErrors.tabname': 'Fehler',
  'WorkflowDetail.WorkflowErrors.noErrors': 'Der Workflow enthält keine Fehler',
  'WorkflowDefaultDirectoryForm.modal.title': 'Standardordner',
  'WorkflowDefaultDirectoryForm.modal.title.create': 'Standardordner hinzufügen',
  'WorkflowDefaultDirectoryForm.modal.title.update': 'Standardordner bearbeiten',
  'WorkflowDefaultDirectoryForm.form.name': 'Standardordnername',
  'WorkflowDefaultDirectoryForm.form.name.required': 'Der Standardordnername ist erforderlich',
  'WorkflowDefaultDirectoryForm.form.description': 'Beschreibung des Standardordners',
  'WorkflowDefaultDirectoryForm.form.path': 'Der Pfad für den Standardordner',
  'WorkflowDefaultDirectoryForm.form.path.required': 'Der Standardpfad muss ausgefüllt sein',
  'WorkflowDocuments.revisionDateLabel': 'Workflow-Überarbeitung: {date}',
  'ActiveTaskDetail.WorkflowErrors.subjectName': 'Der Name des Objekts',
  'ActiveTaskDetail.WorkflowErrors.severity': 'Die Schwere des Fehlers',
  'ActiveTaskDetail.WorkflowErrors.roleId': 'Betroffene Rolle',
  'ActiveTaskDetail.WorkflowErrors.subject': 'Fehlertyp',
  'ErrorWorkflowSubjectEnum.backgroundDocumentRole': 'Leerer oder deaktivierter Benutzer für die Rolle, die für das Hochladen der Anfrage verantwortlich ist',
  'ErrorWorkflowSubjectEnum.backgroundDocumentTerm': 'Die Anfrage ist abgelaufen',
  'ErrorWorkflowSubjectEnum.nodeRole': 'Unbesetzter oder deaktivierter Benutzer für die Solver-Rolle',
  'ErrorWorkflowSubjectEnum.nodeTerm': 'Die Aufgabe ist abgelaufen',
  'ErrorWorkflowSubjectEnum.workflowRole': 'Leerer oder deaktivierter Benutzer für die Workflow-Manager-Rolle',
  'ErrorWorkflowSubjectEnum.backgroundDocumentDir': 'Ungültiger Standardordner',
  'ErrorCommentProcedureSubjectEnum.CommentProcedureRole': 'Ein leerer oder deaktivierter Benutzer in der Rolle',
  'ErrorCommentProcedureSubjectEnum.CommentProcedureUser': 'Der Benutzer ist deaktiviert',
  'ErrorSeverityEnum.warning': 'Warnung',
  'ErrorSeverityEnum.error': 'Fehler',
  'ErrorSeverityEnum.critical': 'Kritischer Fehler',
  'ActiveTaskDetail.WorkflowErrors.subject.backgroundDocumentRole': 'Der Rolle, die für das Hochladen der Anfrage verantwortlich ist, ist kein Benutzer zugewiesen',
  'ActiveTaskDetail.WorkflowErrors.subject.backgroundDocumentTerm': 'Die Anfrage ist abgelaufen',
  'ActiveTaskDetail.WorkflowErrors.subject.nodeRole': 'Der Solver-Rolle ist kein Benutzer zugewiesen',
  'ActiveTaskDetail.WorkflowErrors.subject.nodeTerm': 'Die Aufgabe ist abgelaufen',
  'ActiveTaskDetail.WorkflowErrors.subject.workflowRole': 'Der Workflow-Manager-Rolle ist kein Benutzer zugewiesen',
  'ActiveTaskDetail.WorkflowErrors.subject.defaultDirectory': 'Ungültiger Standardordner',
  'WorkflowDetail.WorkflowSchema.tabname': 'Diagramm',
  'WorkflowInstantiationButton.noTemplates': 'Es sind keine Vorlagen zum Erstellen eines Workflows verfügbar',
  'WorkflowInstantiationButton.noRoles': 'Damit das Projekt ausgeführt werden kann, muss Ihnen mindestens eine Rolle zugewiesen sein.',
  'WorkflowInstantiationForm.name': 'Name',
  'WorkflowInstantiationForm.name.required': 'Bitte geben Sie den Workflow-Namen ein',
  'WorkflowInstantiationForm.description': 'Beschreibung',
  'WorkflowInstantiationForm.template': 'Vorlage',
  'WorkflowInstantiationForm.template.required': 'Bitte wählen Sie eine Workflow-Vorlage aus',
  'WorkflowInstantiationForm.approvalDocuments': 'Genehmigte Dokumente',
  'WorkflowInstantiationForm.defaultDirectories': 'Standardordner',
  'WorkflowInstantiationForm.defaultDirectories.noTemplate': 'Wählen Sie zunächst eine Vorlage aus',
  'WorkflowInstantiationForm.defaultDirectories.placeholder': 'Wählen Sie den Standardordner aus',
  'WorkflowInstantiationForm.defaultDirectories.required': 'Bitte wählen Sie alle Standardordner aus',
  'WorkflowInstantiationForm.workflowRole': 'Workflow-Manager',
  'WorkflowInstantiationForm.workflowRole.required': 'Bitte füllen Sie den Workflow-Manager aus',
  'WorkflowInstantiationFormModal.messagePlaceholder': 'Kommentar für den Löser der ersten Workflow-Aufgabe...',
  'WorkflowInstantiationFormModal.messageLabel': 'Kommentieren Sie die Erstellung eines Workflows',
  'WorkflowInstantiationFormModal.title': 'Starten Sie einen neuen Workflow',
  'WorkflowInstantiationFormModal.WfIsStarting': 'Der Workflow beginnt...',
  'WorkflowDetail.defaultDirectory.removeImpossible.message': 'Der Standardordner kann nicht gelöscht werden, es liegt eine darauf basierende Anfrage vor',
  'WorkflowDetail.defaultBackgroundDirectory.name': 'Ordner für Hintergrunddokumente',
  'WorkflowListItem.admin': 'Workflow-Manager',
  'WorkflowListItem.taskTerm': 'Datum des aktiven Jobs: {date}',
  'WorkflowListPage.filter.assignedTo.options.waiting': 'Meine Zukunft',
  'WorkflowListPage.filter.assignedTo.options.current': 'Meins zu lösen',
  'WorkflowListPage.filter.assignedTo.options.out': 'Meine nicht verfügbar',
  'WorkflowListPage.filter.assignedTo.options.processed': 'Mein erledigt',
  'WorkflowListPage.filter.assignedTo.options.other': 'Andere',
  'WorkflowListPage.filter.assignedTo.label': 'Aufgaben',
  'WorkflowListPage.filter.date.start': 'Gründung',
  'WorkflowListPage.filter.date.end': 'Beendigung',
  'WorkflowListPage.filter.date.end.description': 'Wenn der Workflow abgeschlossen oder abgebrochen wurde, ist dies die tatsächliche Endzeit, andernfalls die vorhergesagte Endzeit.',
  'WorkflowListPage.filter.workflowSolversReady': 'Sie haben bereits abgestimmt',
  'WorkflowListPage.filter.workflowSolversPending': 'Warten',
  'WorkflowListPage.filter.solvers.label': 'Aktuelle Löser',
  'WorkflowListPage.filter.workflowSolversAll': 'Alle',
  'WorkflowListPage.filter.currentNodeName.label': 'Der Name des aktuellen Jobs',
  'WorkflowListPage.filter.currentNodeDeadline.label': 'Aufgabenfrist',
  'WorkflowListPage.filter.currentNodeDeadline.beforeDeadline': 'Vor Ablauf der Frist',
  'WorkflowListPage.filter.currentNodeDeadline.afterDeadline': 'Nach Ablauf der Frist',
  'WorkflowListExportButton.orderBy': 'Sortieren nach:',
  'WorkflowListExportButton.orderBy.author': 'Der Autor',
  'WorkflowListExportButton.orderBy.id': 'Identifikationsnummer',
  'WorkflowListExportButton.orderBy.lastChange': 'Datum der letzten Änderung',
  'WorkflowListExportButton.modalTitle': 'Export ausgewählter Workflow-Daten nach *.xlsx',
  'WorkflowListExportButton.fileNamePart': 'FILTERED_LIST_WORKFLOW',
  'WorkflowRow.createdDate': 'Letzte Änderung: {date}',
  'WorkflowPage.closeWorkflow': 'Beenden Sie den Workflow',
  'WorkflowPage.closeWorkflow.confirm': 'Sind Sie sicher, dass Sie diesen Workflow beenden möchten?',
  'WorkflowDetailPage.AddCommentProcedure.button': 'Erinnerungsverfahren hinzufügen',
  'WorkflowDetailPage.AddCommentProcedure.add': 'Vorhandenes hinzufügen',
  'WorkflowDetailPage.AddCommentProcedure.addTitle': 'Erinnerungsverfahren hinzufügen',
  'WorkflowDetailPage.AddCommentProcedure.addSuccess': 'Erinnerungsvorgang erfolgreich hinzugefügt',
  'WorkflowDetailPage.AddCommentProcedure.new': 'Erstellen Sie ein neues',
  'WorkflowDetailPage.AddCommentProcedure.newTitle': 'Fügen Sie dem Workflow ein neues Erinnerungssteuerelement hinzu',
  'WorkflowDetailPage.addedRevisions.signingState.all': 'Alle Dokumente',
  'WorkflowDetailPage.addedRevisions.signingState.signed': 'Dokumente mit beigefügtem unterzeichnetem Dokument',
  'WorkflowDetailPage.addedRevisions.signingState.unsigned': 'Dokumente ohne Anhang, signiertes Dokument',
  'WorkflowDetailPage.addedRevisions.commentedState.all': 'Alle Dokumente',
  'WorkflowDetailPage.addedRevisions.commentedState.commented': 'Dokumente mit Anhang, Dokument mit Kommentaren',
  'WorkflowDetailPage.addedRevisions.commentedState.clean': 'Dokumente ohne Anhang, Dokument mit Kommentaren',
  'WorkflowPageToolbar.newTemplate': 'Neue Vorlage',
  'WorkflowPageToolbar.newWorkflow': 'Workflow starten',
  'WorkflowPageToolbar.exportWorkflows': 'Liste exportieren ({count})',
  'WorkflowPageToolbar.saveTemplate': 'In eine Datei exportieren',
  'WorkflowProcessFormModal.title.create': 'Erstellen Sie einen Prozess',
  'WorkflowProcessListPageToolbar.newProcess': 'Starten Sie einen neuen Prozess',
  'WorkflowTemplate.solver': 'Löser',
  'WorkflowTemplate.solvers': 'Löser',
  'WorkflowTemplate.parallelBranchingSetting': 'Starteinstellungen',
  'WorkflowTemplate.parallelBranchingSetting.options.runAll': 'Führen Sie alle Zweige automatisch aus',
  'WorkflowTemplate.parallelBranchingSetting.options.runManually': 'Wählen Sie Zweige beim Knotenstart manuell aus',
  'WorkflowTemplate.parallelBranchingSetting.solverRoles': 'Löser, die parallele Zweige auswählen können',
  'WorkflowTemplate.solverRoles': 'Die Rolle der Löser',
  'WorkflowTemplate.confirmTitle': 'Möchten Sie wirklich gehen?',
  'WorkflowTemplate.confirmMessage': 'Es ist möglich, dass die vorgenommenen Änderungen nicht gespeichert werden.',
  'WorkflowTemplate.parallelBranch.output': 'Zweig',
  'WorkflowTemplates.title': 'Workflow-Vorlagen',
  'WorkflowTemplateList.invalid': 'Ungültig',
  'WorkflowTemplatePage.editTemplate': 'Schema bearbeiten',
  'WorkflowTemplatePage.saveSuccess': 'WF wurde gespeichert',
  'WorkflowTemplatePage.saveError': 'WF konnte nicht gespeichert werden.',
  'WorkflowTemplatePage.saveError.savedAsDraft': 'Die Vorlage ist ungültig, sie wird als Entwurf gespeichert.',
  'WorkflowTemplatePage.copyError.saveAsDraft': 'Die kopierte Vorlage ist ungültig, die Kopie wird als Entwurf gespeichert.',
  'WorkflowTemplatePage.copySuccess': 'Eine Kopie von WF wurde gespeichert',
  'WorkflowTemplatePage.loading': 'Vorlagendetails werden geladen...',
  'WorkflowTemplatePage.forbiddenError': 'Sie verfügen nicht über die erforderlichen Berechtigungen, um auf WF-Vorlagen zuzugreifen.',
  'WorkflowTemplatePage.forReadOnly': 'Die Vorlage ist schreibgeschützt. Sie verfügen nicht über die erforderlichen Berechtigungen.',
  'WorkflowTemplatePage.duplicityNameError': 'Eine andere Vorlage hat bereits den angegebenen Namen',
  'WorkflowTemplatePage.errorHeader.description': 'Fehlerbeschreibung',
  'WorkflowTemplatePage.errorHeader.severity': 'Die Schwere des Fehlers',
  'WorkflowTemplatePage.errorHeader.source': 'Die Fehlerquelle',
  'WorkflowTemplatePage.errorHeader.link': 'Link',
  'WorkflowTemplatePage.error.goToNode': 'Gehe zur Aufgabe',
  'WorkflowTemplatePage.error.sourceInTemplate': 'Vorlage',
  'WorkflowTemplatePage.errorNames.WorkflowNotReachableNodeError': 'Die Aufgabe ist nicht angehängt',
  'WorkflowTemplatePage.errorNames.WorkflowMultipleDocumentStateChangeError': 'Der Status des Dokuments ändert sich wiederholt',
  'WorkflowTemplatePage.errorNames.WorkflowCircularDependencyError': 'Eine Endlosschleife',
  'WorkflowTemplatePage.errorNames.ReferencedWorkflowNodeNotExistError': 'Die Aufgabe existiert nicht',
  'WorkflowTemplatePage.errorNames.WorkflowPortShouldHaveOneEdgeError': 'Jeder Ausgang muss genau eine Verbindung haben. Fügen Sie für jeden leeren Port eine Verbindung hinzu.',
  'WorkflowTemplatePage.errorNames.WorkflowTailMustHaveNoNoOutputPortError': 'Die Endaufgabe kann keine Ausgabe haben',
  'WorkflowTemplatePage.errorNames.WorkflowNodeWithoutRoleError': 'Der Aufgabe fehlt eine Rolle. Fügen Sie mindestens eine Solver-Rolle hinzu.',
  'WorkflowTemplatePage.errorNames.WorkflowInvalidDocumentAliasError': 'Ungültiger Dokumentalias',
  'WorkflowTemplatePage.errorNames.WorkflowInvalidRoleError': 'Falsche Rolle',
  'WorkflowTemplatePage.errorNames.WorkflowNodeDuplicateRoleError': 'Doppelte Rolle',
  'WorkflowTemplatePage.errorNames.WorkflowHeadShouldHaveOneOutputPortError': 'Die Startaufgabe muss einen Ausgang haben',
  'WorkflowTemplatePage.errorNames.WorkflowTemplateDuplicateNameError': 'Doppelter Workflowname',
  'WorkflowTemplatePage.errorNames.UserOrRoleCanBeSetTempError': 'Eine Aufgabe kann nur einen Benutzer oder eine Rolle haben',
  'WorkflowTemplatePage.errorNames.WorkflowInvalidTailNodeError': 'Schlechte Arbeitsergebnisse',
  'WorkflowTemplatePage.errorNames.NestedSubWorkflowError': 'Fehler im Parallelzweig',
  'WorkflowTemplatePage.errorNames.WorkflowInvalidRequiredVotersCount': 'Es wird eine kleine Wählerzahl festgelegt. Mindestens einer muss abstimmen',
  'WorkflowTemplatePage.errorNames.WorkflowTemplateLowMaxVotersValueError': 'Die maximale Wählerzahl liegt unter der minimalen',
  'WorkflowTemplatePage.errorNames.WorkflowTemplateHighRequestedVotesValueError': 'Die maximale Wählerzahl ist geringer als die erforderliche Zahl an Zustimmungen',
  'WorkflowTemplatePage.errorNames.SubworkflowsCountError': 'Parallele Verzweigungen müssen mindestens eine Verzweigung haben',
  'WorkflowTemplatePage.errorNames.SubworkflowRequestedVoteCountError': 'Die angeforderte Anzahl der gestarteten Zweige stimmt nicht mit der Anzahl der erstellten Zweige überein',
  'WorkflowTemplatePage.errorNames.NotifyNodeShouldHaveMessageError': 'Die Benachrichtigungsaufgabe sollte eine Nachricht enthalten.',
  'WorkflowTemplatePage.errorNames.OnlyNotifyNodeCanHaveMessageError': 'Nur eine Benachrichtigungsaufgabe kann eine Nachricht enthalten.',
  'WorkflowTemplatePage.errorNames.WorkflowNodeMessageDuplicateuserError': 'Doppelter Benutzer als Nachrichtenempfänger.',
  'WorkflowTemplatePage.errorNames.WorkflowTemplateShouldHaveOneHeadError': 'Eine Workflow-Vorlage kann nur einen Startknoten haben.',
  'WorkflowTemplatePage.errorNames.DocumentRequestsWithSameNameError': 'Die Aufgabe enthält Anforderungen für ein gleichnamiges übergeordnetes Dokument.',
  'WorkflowTemplatePage.errorNames.AddRevisionInBadPossitionError': 'Eine Bewertung kann nur hochgeladen werden, bevor Dokumente genehmigt oder abgelehnt werden.',
  'WorkflowTemplatePage.errorNames.WorkflowInvalidUserError': 'Der ausgewählte Benutzer wurde nicht gefunden.',
  'WorkflowTemplatePage.errorNames.MessageSubjectEmptyError': 'Der Betreff der Nachricht darf nicht leer sein.',
  'WorkflowTemplatePage.errorNames.MessageMessageEmptyError': 'Der Nachrichtentext ist leer.',
  'WorkflowTemplatePage.errorNames.MessageNoRecipientsError': 'Es sind keine Nachrichtenempfänger ausgewählt.',
  'WorkflowTemplatePage.errorNames.WorkflowBDRoleWithoutUserError': 'Die zugrunde liegende Dokumentrolle enthält keine Benutzer.',
  'WorkflowTemplatePage.errorNames.WorkflowNodeRoleWithoutUserError': 'Die Rolle für die Aufgabe enthält keinen Benutzer.',
  'WorkflowTemplatePage.signalWarning.title': 'Die Workflow-Vorlage wurde geändert',
  'WorkflowTemplatePage.signalWarning': 'Die Workflow-Vorlage wurde von {user} geändert. Möchten Sie die aktuelle Workflow-Vorlage neu laden oder weiterarbeiten und die aktuelle Vorlage beim Speichern überschreiben? Vorgenommene Änderungen gehen beim Neuladen verloren.',
  'WorkflowTemplatePage.signalWarning.sameUser': 'Sie haben diese Vorlage geändert. Möchten Sie die aktuelle Workflow-Vorlage neu laden oder weiterarbeiten und die aktuelle Vorlage beim Speichern überschreiben? Vorgenommene Änderungen gehen beim Neuladen verloren.',
  'WorkflowTemplatePage.signalWarning.continue': 'Fahren Sie mit der alten Version fort',
  'WorkflowTemplatePage.signalWarning.reload': 'Aktuelle Version laden',
  'WorkflowTemplatePage.errorNames.WorkflowNodeEmptyAddDocumentPermittedActionsError': 'Für die Aufgabe ist keine Aktion zulässig',
  'WorkflowTemplatePage.errorNames.WorkflowNodeEmptyDestinationDocumentStateError': 'Es wird kein neuer Dokumentstatus gesetzt',
  'WorkflowTemplatePage.errorNames.WorkflowNodeEmptyAddDocumentPermittedStatesError': 'Der aktivierte Status des hinzugefügten Dokuments ist für die Aufgabe oder Vorlage nicht festgelegt',
  'WorkflowTemplatePage.errorNames.WorkflowEmptyAddDocumentPermittedStatesError': 'Der aktivierte Status des hinzugefügten Dokuments ist in der Vorlage nicht festgelegt',
  'WorkflowTemplatePage.exportToOrganization.button.label': 'Export zur Organisation',
  'WorkflowTemplatePage.exportToOrganization.success': 'Die Vorlage wurde erfolgreich in die Organisation exportiert',
  'WorkflowTemplatePage.exportToOrganization.confirm.title': 'In der Organisation ist bereits eine Vorlage mit diesem Namen vorhanden',
  'WorkflowTemplatePage.exportToOrganization.confirm.content': 'Möchten Sie eine bestehende Vorlage überschreiben?',
  'WorkflowTemplateNodeDetail.workflowSettings': 'Workflow-Einstellungen',
  'WorkflowTemplateNodeDetail.workflowSolver': 'Workflow-Manager',
  'WorkflowTemplateNodeDetail.workflowManager': 'Workflow-Manager',
  'WorkflowTemplateNodeDetail.defaultDirectories': 'Standardordner',
  'WorkflowTemplateNodeDetail.inputDocumentStatuses': 'Auswahl der Status von Eingabedokumenten',
  'WorkflowTemplateNodeDetail.inputDocumentStatuses.workInProgress': 'Im Gange',
  'WorkflowTemplateNodeDetail.inputDocumentStatuses.shared': 'Geteilt',
  'WorkflowTemplateNodeDetail.inputDocumentStatuses.published': 'Veröffentlicht',
  'WorkflowTemplateNodeDetail.inputLabels': 'Auswahl an Etiketten',
  'WorkflowTemplateNodeDetail.requestedVotes': 'Muss genehmigen',
  'WorkflowTemplateNodeDetail.requestedVotes.tooltip': 'Erforderliche Anzahl an Genehmigungen',
  'WorkflowTemplateNodeDetail.minRequestedVoters': 'Mindestanzahl an Stimmen',
  'WorkflowTemplateNodeDetail.maxRequestedVoters': 'Maximale Anzahl an Stimmen',
  'WorkflowTemplateNodeDetail.commonRequestedVoters': 'Sie müssen abstimmen',
  'WorkflowTemplateNodeDetail.minRequestedVoters.tooltip': 'Mindestanzahl der Resolver, die abstimmen müssen.',
  'WorkflowTemplateNodeDetail.maxRequestedVoters.tooltip': 'Maximale Anzahl an Lösern, die abstimmen können.',
  'WorkflowTemplateNodeDetail.commonRequestedVoters.tooltip': 'Erforderliche Anzahl Wähler.',
  'WorkflowTemplateNodeDetail.requestedParallelBranches': 'Anzahl der parallelen Zweige, die ausgeführt werden',
  'WorkflowTemplateNodeDetail.requestedParallelBranches.tooltip': 'Erforderliche Mindestanzahl laufender Zweige',
  'WorkflowTemplateNodeDetail.name': 'Jobname',
  'WorkflowTemplateNodeDetail.node': 'Rolle',
  'WorkflowTemplateNodeDetail.description': 'Stellenbeschreibung',
  'WorkflowTemplateNodeDetail.duration': 'Begriff',
  'WorkflowTemplateNodeDetail.noNodeSelected': 'Es ist kein Knoten ausgewählt!',
  'WorkflowTemplateNodeDetail.timeoutAction.title': 'Aktion nach Ablauf der Frist',
  'WorkflowTemplateNodeDetail.timeoutAction.wait': 'Keine Aktion',
  'WorkflowTemplateNodeDetail.timeoutAction.selectDefault': 'Verwenden Sie eine bestimmte Ausgabe',
  'WorkflowTemplateNodeDetail.timeoutAction.closeWorkflow': 'Beenden Sie die Workflow-Instanz',
  'WorkflowTemplateNodeDetail.backgroundDocument.title': 'Hintergrunddokumente',
  'WorkflowTemplateNodeDetail.backgroundDocument.addButton': 'Anfrage hinzufügen',
  'WorkflowTemplateNodeDetail.backgroundDocument.defineDefaultFolderTooltip': 'Definieren Sie einen Standardordner für die Option zum Hinzufügen einer Anfrage zum zugrunde liegenden Dokument',
  'WorkflowTemplateNodeDetail.backgroundDocument.addBackgroundDocumentTooltip': 'Fügen Sie eine Anfrage für ein zugrunde liegendes Dokument hinzu',
  'WorkflowTemplateNodeDetail.backgroundDocument.responsibleRolePopover': 'Verantwortlicher (Rolle)',
  'WorkflowTemplateNodeDetail.backgroundDocument.roleWithoutUser': 'Eine Rolle ohne Benutzer',
  'WorkflowTemplateNodeDetail.backgroundDocument.modalTitle': 'Fügen Sie eine Anfrage für ein zugrunde liegendes Dokument hinzu',
  'WorkflowTemplateNodeDetail.documentNewState': 'Neuwertiger Zustand',
  'WorkflowTemplateNodeDetail.notification.messageTitle': 'Betreff der Nachricht',
  'WorkflowTemplateNodeDetail.notification.messageTitle.placeholder': 'Benachrichtigung über den Workflow-Genehmigungsprozess',
  'WorkflowTemplateNodeDetail.notification.messageBody': 'Nachrichtentext',
  'WorkflowTemplateNodeDetail.notification.recipients': 'Nachrichtenempfänger',
  'WorkflowTemplateNodeDetail.notification.recipients.description': 'Als Empfänger ist keine Rolle oder kein Benutzer ausgewählt.',
  'WorkflowTemplateNodeDetail.notification.recipients.roles': 'Rollen als Adressaten',
  'WorkflowTemplateNodeDetail.notification.recipients.users': 'Benutzer als Empfänger',
  'WorkflowTemplateNodeDetail.notification.settingsTitle': 'Nachrichteneinstellungen',
  'WorkflowTemplateNodeDetail.permittedActions.title': 'Für die Aufgabe zulässige Aktionen',
  'WorkflowTemplateNodeDetail.permittedActions.removeDocument': 'Entfernen Sie das Dokument',
  'WorkflowTemplateNodeDetail.permittedActions.addDocument': 'Ein Dokument hinzufügen',
  'WorkflowTemplateNodeDetail.permittedActions.signDocument': 'Unterzeichnung des Dokuments',
  'WorkflowTemplateNodeDetail.permittedActions.addRevision': 'Hinzufügen einer Dokumentrevision',
  'WorkflowTemplateNodeDetail.permittedActions.stateFromNode': 'Anders als die Standardeinstellungen hinzugefügter Dokument-/Revisionsstatus',
  'WorkflowTemplateNodeDetail.permittedActions.stateWorkInProgress': 'Im Gange',
  'WorkflowTemplateNodeDetail.permittedActions.stateShared': 'Geteilt',
  'WorkflowTemplateNodeDetail.permittedActions.statePublish': 'Veröffentlicht',
  'WorkflowTemplateAddBackgroundDocumentForm.requestTitle': 'Der Name der Anfrage',
  'WorkflowTemplateAddBackgroundDocumentForm.requestDescription': 'Beschreibung der Anfrage',
  'WorkflowTemplateNodeDetail.backgroundDocument.date': `{count, plural,
    =0 {Datum: # Tage}
    one {Datum: # Tage}
    few {Datum: # Tage}
    other {Datum: # Tage}
    }`,
  'WorkflowTemplateNodeDetail.deadlineExtension.setting': 'Verlängerung der Laufzeit – Einstellung',
  'WorkflowTemplateNodeDetail.deadlineExtension.action.forbidden': 'Verlängerung verboten',
  'WorkflowTemplateNodeDetail.deadlineExtension.action.finishOnTime': 'Nach Ablauf der Frist fortfahren',
  'WorkflowTemplateNodeDetail.deadlineExtension.action.waitToDecision': 'Warte auf eine Stellungnahme',
  'WorkflowTemplateAddBackgroundDocumentForm.folder': 'Ordner zum Hochladen von Dokumenten',
  'WorkflowTemplateAddBackgroundDocumentForm.responsiblePerson': 'Verantwortlicher (Rolle)',
  'WorkflowTemplateAddBackgroundDocumentForm.requiredDate': 'Gewünschtes Datum (Tage)',
  'WorkflowTemplateAddBackgroundDocumentForm.requestTitle.required': 'Bitte geben Sie den Namen der Anfrage ein',
  'WorkflowTemplateAddBackgroundDocumentForm.requestTitle.duplicateName': 'Dieser Name wird bereits von einer anderen Anfrage verwendet',
  'WorkflowTemplateAddBackgroundDocumentForm.requiredDate.required': 'Bitte geben Sie das gewünschte Datum ein',
  'WorkflowTemplateAddBackgroundDocumentForm.folder.required': 'Bitte wählen Sie einen Ordner aus, um das Dokument hochzuladen',
  'WorkflowTemplateNodeDetail.backgroundDocument.responsiblePerson.required': 'Bitte wählen Sie eine verantwortliche Rolle aus',
  'WorkflowTemplateNodeDetail.otherPortsName': 'Ausfahrt',
  'WorkflowTemplateErrors.severity.Error': 'Fehler',
  'WorkflowTemplateErrors.severity.Warning': 'Warnung',
  'WorkflowTemplateExportFormModal.title': 'Vorlagen exportieren',
  'WorkflowTemplateListPageToolbar.export': 'Vorlagen exportieren',
  'WorkflowTemplateExportModal.submitButtonText': 'Vorlagen exportieren',
  'WorkflowTemplateExportButton.tooltip': 'Exportieren Sie die Vorlage',
  'WorkflowTemplateExportForm.label': `{count, plural,
    one {1 ausgewählte Vorlage in eine JSON-Datei exportieren}
    few {# ausgewählte Vorlagen in eine JSON-Datei exportieren}
    other {# ausgewählte Vorlagen in eine JSON-Datei exportieren}
    }`,
  'WorkflowTemplateExportForm.nameLabel': 'Der Name der Datei',
  'WorkflowTemplateExportForm.archiveNameRules': 'Bitte geben Sie einen Dateinamen ein',
  'WorkflowTemplateCopyCreateFormModal.formTitle': 'Erstellen Sie eine Kopie der Vorlage',
  'WorkflowTemplateCopyCreateFormModal.submitButtonText': 'Machen Sie eine Kopie',
  'WorkflowTemplateCopyCreateForm.copyInformation': 'Sie erstellen eine Kopie der Vorlage:',
  'WorkflowTemplateCopyCreateForm.copyName': 'Der Name der Kopie',
  'WorkflowTemplateCopyCreateForm.alreadyExistName': 'Der eingegebene Name wird bereits verwendet, bitte wählen Sie einen anderen Namen',
  'WorkflowTemplateCopyCreateForm.description': 'Beschreibung',
  'WorkflowTemplatePage.saveAsCopy.buttonText': 'Machen Sie eine Kopie',
  'WorkflowTemplateExportDefaultFilename': 'Vorlagen',
  'AllDocumentsPageFilterToolbar.WorkflowBackgroundDocumentFilterLabel': 'Jobname',
  'WorkflowTemplateCheckImportNamesAndRolesFormModal.title': 'Festlegen der Namen, Rollen und Standardordner importierter Vorlagen',
  'WorkflowTemplateCheckImportNamesAndRolesForm.templateTitle': 'Der Name der Vorlage',
  'WorkflowTemplateCheckImportNamesAndRolesForm.templateTitle.required': 'Bitte geben Sie den Namen der Vorlage ein',
  'WorkflowTemplateCheckImportNamesAndRolesForm.templateTitle.alreadyExist': 'Für diesen Namen gibt es bereits eine Vorlage',
  'WorkflowTemplateCheckImportNamesAndRolesForm.templateTitle.dontDuplicate': 'Der Vorlagenname muss eindeutig sein',
  'WorkflowTemplateCheckImportNamesAndRolesForm.roleAssignment': 'Rollenverteilung',
  'WorkflowTemplateCheckImportNamesAndRolesForm.userAssignment': 'Benutzerzuordnung',
  'WorkflowTemplateCheckImportNamesAndRolesForm.checkRecipients': 'Überprüfen Sie die Empfänger in der Benachrichtigungsaufgabe.',
  'WorkflowTemplateCheckImportNamesAndRolesForm.defaultDirectories': 'Standardordner',
  'WorkflowTemplateListPageToolbar.delete.confirm': 'Sind Sie sicher, dass Sie diese Vorlagen löschen möchten?',
  'WorkflowTemplateListPageToolbar.jsonFilesOnly': 'Es kann nur eine JSON-Datei eingefügt werden',
  'WorkflowTemplateListPageToolbar.checkImport.noValidFile': 'Die Validierung der importierten Vorlage ist fehlgeschlagen',
  'WorkflowTemplateListPageToolbar.checkImport.noEmptyFile': 'Die importierte Datei enthält keine Vorlage',
  'WorkflowTemplateListPageToolbar.checkImport.importFailed': 'Der Import ist fehlgeschlagen',
  'WorkflowTemplateListPageToolbar.checkImport.importButtonText': 'Vorlage importieren',
  'WorkflowTemplateListPageToolbar.checkImport.modalLabel': 'Importvorlagen (*.json)',
  'WorkflowTemplate.DocumentStateSetting.placeholder': 'Wählen Sie einen neuen Dokumentstatus',
  'WorkflowSubWorkflowsSettings.title': 'Parallelzweige',
  'WorkflowSubWorkflowsSettings.addBranch': 'Zweig hinzufügen',
  'WipRecordDetailDataProvider.commonZP.title': 'Detail des gemeinsamen Erkennungsprotokolls',
  'NodeOutputSettings.outputs': 'Ausgänge',
  'NodeOutputSettings.addOutput': 'Ausgabe hinzufügen',
  'ZBVDetail.Title': 'Detail des Änderungsblatts',
  'ZbvDetail.additionalInformation': 'Zusätzliche Daten',
  'ZbvDetail.overview': 'Überblick',
  'ZbvDetailAdditionalInformation.responsiblePersons': 'Verantwortliche Personen',
  'ZbvDetailAdditionalInformation.contractor': 'Auftragnehmer (Bauleiter)',
  'ZbvDetailAdditionalInformation.employee.priceNegotiation': 'Mitarbeiter (Preisverhandlung)',
  'ZbvDetailAdditionalInformation.designer': 'Designer (Autorenbetreuung)',
  'ZbvDetailAdditionalInformation.employee.statementOnZBV': 'Mitarbeiter (Stellungnahme zur ÄdB)',
  'ZbvDetailAdditionalInformation.constructionSupervision': 'Bauüberwachung',
  'ZbvDetailAdditionalInformation.employeeFunctions': 'Mitarbeiterfunktion (Ausdruck)',
  'ZbvDetailAdditionalInformation.constructionManager': 'Bauleiter',
  'ZbvDetailAdditionalInformation.authorizedPerson': 'Autorisierte Person',
  'ZbvDetailAdditionalInformation.supervision': 'Aufsicht',
  'ZbvDetailAdditionalInformation.justification': 'Rechtfertigung',
  'ZbvDetailAdditionalInformation.descriptionOfChanges': 'Beschreibung der Änderung',
  'ZbvDetailOverview.additionalInformation': 'Zusätzliche Daten',
  'ZbvDetailOverview.approval': 'Genehmigung',
  'ZbvDetailOverview.changeOrderClassification': 'Klassifikation der ÄdB',
  'ZbvDetailOverview.chart.changedItems': 'Geänderte Artikel',
  'ZbvDetailOverview.chart.newItems': 'Neue Artikel',
  'ZbvDetailOverview.code': 'Markieren',
  'ZbvDetailOverview.contractualAmendment': 'Vertragsnachtrag',
  'ZbvDetailOverview.valorization': 'Aufwertung ÄdB',
  'ZbvDetailOverview.graf.newItems': 'Neue Artikel',
  'ZbvDetailOverview.graf.changedItems': 'Geänderte Artikel',
  'ZbvDetailOverview.grouping': 'Gruppierung',
  'ZbvDetailOverview.issue': 'Belichtung',
  'ZbvDetailOverview.lessWork': 'Weniger Arbeit',
  'ZbvDetailOverview.moreWork': 'Multitasking',
  'ZbvDetailOverview.name': 'Name',
  'ZbvDetailOverview.price': 'Preis',
  'ZbvDetailOverview.pricesWithVAT': 'Preise inklusive MwSt.',
  'ZbvDetailOverview.overview': 'Überblick',
  'ZbvDetailOverview.recapitulation': 'Rekapitulation',
  'ZbvDetailOverview.taxBase': 'Steuerbemessungsgrundlage',
  'ZbvDetailOverview.totalPrice': 'Gesamtpreis',
  'ZbvDetailOverview.type': 'Typ',
  'ZbvDetailOverview.unclassified': 'Nicht kategorisiert',
  'ZbvDetailOverview.PricesWithVAT': 'Preise inklusive MwSt.',
  'ZbvDetailOverview.rateDPH': '{percent} %',
  'general.region': 'Bezirk',
  'general.areal': 'Areal',
  'general.property': 'Eigentum',
  'general.TWIST': 'TWIST-Kennung',
  'general.TWIST.detail': 'TWIST-Kennungen - Detail',
  'general.synchronized':'Synchronisiert',
  'JSONVariableType.buildingIdentification': 'TWIST-Kennung'
};
