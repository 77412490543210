import { Checkbox } from 'antd';

import { CheckboxChangeEvent, CheckboxProps } from 'antd/lib/checkbox';
import { JSONVariableTypeEnum } from 'api/completeApiInterfaces';
import { useIntl } from 'hooks';
import React, { FunctionComponent, useCallback } from 'react';
import { JSONVariableBoolean } from '../JSONVariableTypes';

export type JSONVariableBooleanProps = {
  dataType: JSONVariableTypeEnum.boolean;
  value?: JSONVariableBoolean;
  onChange?: (value: JSONVariableBoolean) => void;
} & Omit<CheckboxProps, 'value' | 'onChange'>;

export const isJSONBooleanValueTrue = (value: boolean | string) => value === 'true' || value === true;

const JSONVariableCheckboxFormItem: FunctionComponent<JSONVariableBooleanProps> = ({
  dataType,
  value,
  onChange,
  ...restProps
}) => {
  const intl = useIntl();
  const handleChange = useCallback(
    (e: CheckboxChangeEvent) => {
      onChange && onChange({ type: dataType, value: e.target.checked ? 'true' : 'false' });
    },
    [onChange]
  );
  return (
    <Checkbox onChange={handleChange} checked={isJSONBooleanValueTrue(value?.value)} {...restProps}>
      {intl.formatMessage({ id: isJSONBooleanValueTrue(value?.value) ? 'general.yes' : 'general.no' })}
    </Checkbox>
  );

  // TODO: use nullable checkbox when implemented on BE
  // const [checkState, setCheckState] = useState<'true' | 'false'>(value?.value ? 'true' : 'false');
  //
  // const handleChange = useCallback(
  //   (e: CheckboxChangeEvent) => {
  //     onChange && onChange({ type: dataType, value: e.target.checked ? checkState : null });
  //   },
  //   [onChange, checkState]
  // );
  // const handleValueChanged = useCallback(
  //   (checked: boolean) => {
  //     setCheckState(checked ? 'true' : 'false');
  //     onChange && onChange({ type: dataType, value: checked ? 'true' : 'false' });
  //   },
  //   [onChange]
  // );
  // return (
  //   <Space>
  //     <Checkbox onChange={handleChange} checked={value?.value != null} />
  //     <Switch disabled={value == null} checked={checkState === 'true'} onChange={handleValueChanged} />
  //   </Space>
  // );
};

export default React.memo(JSONVariableCheckboxFormItem);
