import { masterApi } from 'api/completeApi';
import { CalendarSetDto } from 'api/completeApiInterfaces';
import { FormModalWrapper } from 'components/forms/FormModalWrapper';
import { useSameCallback } from 'hooks/useSameCallback';
import { InjectedIntlProps } from 'locale/Fmt';
import React, { FunctionComponent } from 'react';
import { FormModalProps } from '../FormModalProps';
import {
  CalendarSettingsForm,
  CalendarSettingsFormConfiguration,
  CalendarSettingsFormData,
} from './CalendarSettingsForm';
import { getDefaultCalendar } from './CalendarSettingsForm.utils';

type Props = FormModalProps<CalendarSettingsFormData, void> &
  InjectedIntlProps &
  CalendarSettingsFormConfiguration & { organizationId: Guid; projectId?: Guid; editDisabled: boolean };

export const formDataToDto = (
  data: CalendarSettingsFormData,
  organizationId: Guid,
  projectId?: Guid
): CalendarSetDto => {
  const { workingDays, ...rest } = data;

  const selectedWorkingDays = data.workingDays.reduce(
    (calendar, selectedDay) => ({ ...calendar, [selectedDay]: true }),
    getDefaultCalendar(organizationId, projectId)
  );

  return {
    ...selectedWorkingDays,
    ...rest,
  };
};

export const CalendarSettingsFormModal: FunctionComponent<Props> = ({
  onSubmit,
  onClose,
  intl,
  open,
  initialCalendar,
  editDisabled,
  organizationId,
  projectId,
  ...restProps
}) => {
  const handleSubmit = useSameCallback(async (values: CalendarSettingsFormData) => {
    if (!values) {
      return null;
    }

    const [err, resp] = await masterApi.projects.admin.calendar.patch(formDataToDto(values, organizationId, projectId));
    if (err) {
      return err;
    }

    onSubmit && (await onSubmit(values, resp.data));
    return null;
  });

  return (
    <>
      <FormModalWrapper
        onSubmit={handleSubmit}
        onClose={onClose}
        titleId={
          !!projectId ? 'CalendarSettingsFormModal.title.project' : 'CalendarSettingsFormModal.title.organization'
        }
        width="700px"
        open={open}
        onSubmitDisabled={editDisabled}
        {...restProps}
      >
        <CalendarSettingsForm initialCalendar={initialCalendar} />
      </FormModalWrapper>
    </>
  );
};
