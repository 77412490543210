import { DatePicker, Form } from 'antd';
import { useIntl } from 'hooks';
import moment from 'moment';
import React from 'react';
import { isDateInThePast } from '../AssignmentForm/AssignmentForm';

export type CalendarExcludedDayImportFormData = { endDate: moment.Moment };

export const CalendarExcludedDayImportForm = () => {
  const intl = useIntl();

  return (
    <>
      <Form.Item
        name="endDate"
        initialValue={moment().add(1, 'year')}
        label={intl.formatMessage({ id: 'CalendarExcludedDayImportForm.label.endDate' })}
      >
        <DatePicker disabledDate={(current) => isDateInThePast(current)} allowClear={false} />
      </Form.Item>
    </>
  );
};
