import { ExcludedDaySetDto } from 'api/completeApiInterfaces';
import { FormModalWrapper } from 'components/forms/FormModalWrapper';
import { useSameCallback } from 'hooks/useSameCallback';
import { InjectedIntlProps } from 'locale/Fmt';
import React, { FunctionComponent } from 'react';
import { FormModalProps } from '../FormModalProps';
import { CalendarExcludedDayForm, CalendarExcludedDayFormData } from './CalendarExcludedDayForm';

type Props = FormModalProps<CalendarExcludedDayFormData, ExcludedDaySetDto> &
  InjectedIntlProps & { isRepeating: boolean; initialExcludedDay?: ExcludedDaySetDto };

const formDataToDto = (
  data: CalendarExcludedDayFormData,
  initialExcludedDay?: ExcludedDaySetDto
): ExcludedDaySetDto => {
  const { date, name, repeating } = data;

  return {
    id: initialExcludedDay?.id,
    name: name,
    day: date.date(),
    month: date.month() + 1,
    year: repeating ? undefined : date.year(),
  };
};

export const CalendarExcludedDayFormModal: FunctionComponent<Props> = ({
  onSubmit,
  onClose,
  open,
  initialExcludedDay,
  isRepeating,
  ...restProps
}) => {
  const handleSubmit = useSameCallback(async (values: CalendarExcludedDayFormData) => {
    if (!values) {
      return null;
    }

    onSubmit && (await onSubmit(values, formDataToDto(values, initialExcludedDay)));
    return null;
  });

  return (
    <>
      <FormModalWrapper
        onSubmit={handleSubmit}
        onClose={onClose}
        titleId={!!initialExcludedDay ? 'CalendarExcludedDayForm.title.edit' : 'CalendarExcludedDayForm.title.add'}
        open={open}
        layout="vertical"
        {...restProps}
      >
        <CalendarExcludedDayForm initialExcludedDay={initialExcludedDay} isRepeating={isRepeating} />
      </FormModalWrapper>
    </>
  );
};
