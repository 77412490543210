import { CloseOutlined, CopyOutlined, EditOutlined, EllipsisOutlined, FileImageOutlined } from '@ant-design/icons';
import { Button, Dropdown, Empty, MenuProps } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import classNames from 'classnames';
import DisplayName from 'components/DisplayName';
import { DragHook } from 'components/DragTile/DragTile';
import { MovableCard } from 'components/MovableCard/MovableCard';
import { NoClickTooltip } from 'components/NoClickTooltip/NoClickTooltip';
import { ChartFormatTypeEnum, exportChartImage } from 'components/ProjectWidgets/commonExport';
import {
  PersonalReportWidgetConfiguration,
  ReportWidgetConfiguration,
  useReportWidgetsContext,
} from 'components/Reports/contexts/ReportWidgetsContextProvider';
import { Fmt, InjectedIntl, InjectedIntlProps } from 'locale';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { DEFAULT_CURRENCY } from 'utils/currencyGridAndDashboardUtils';
import styles from './ReportCard.module.less';
import POHReportChart from './ReportCharts/POHReportChart/POHReportChart';
import ProjectDocumentationStatisticReportChart from './ReportCharts/ProjectDocumentationStatisticReportChart/ProjectDocumentationStatisticReportChart';
import UserAssignmentStatisticsReport from './ReportStatistics/UserAssignmentStatistics/UserAssignmentStatisticsReport';
import UserNotificationStatisticsReport from './ReportStatistics/UserNotificationsStatistics/UserNotificationStatisticsReport';

type Props = InjectedIntlProps & {
  reportConfiguration: ReportWidgetConfiguration;
  sharedWidgets: Record<Guid, PersonalReportWidgetConfiguration[]>;
  sharedWidgetsLoading: Set<Guid>;
  className?: string;
  dragRef: DragHook;
};

const getReportExportName = (configuration: ReportWidgetConfiguration, intl: InjectedIntl) => {
  switch (configuration.type) {
    case 'shared':
      return configuration.title;
    case 'personal':
      return intl.formatMessage(
        { id: `ReportWidgetDecimalUnit.${configuration.data.viewDecimalUnit}.label` },
        {
          currency: intl.formatMessage({ id: `general.currency.${DEFAULT_CURRENCY}` }),
        }
      );
    case 'projectDocumentationStatistics':
      return intl.formatMessage({ id: 'ProjectDashboard.Reports.projectDocumentationStatistics.title' });
    case 'assignmentStatistics':
      return intl.formatMessage({ id: 'ProjectDashboard.Reports.userActiveAssignmentStatistics.title' });
    case 'notificationStatistics':
      return intl.formatMessage({ id: 'ProjectDashboard.Reports.userNotificationStatistics.title' });
  }
};

const isReportEditable = (configuration: ReportWidgetConfiguration) => {
  return configuration.type === 'personal' || configuration.type === 'shared';
};

const isReportCopyable = (configuration: ReportWidgetConfiguration) => {
  return configuration.type === 'personal' || configuration.type === 'shared';
};

const isReportExportable = (configuration: ReportWidgetConfiguration) => {
  return (
    configuration.type === 'personal' ||
    configuration.type === 'shared' ||
    configuration.type === 'projectDocumentationStatistics'
  );
};

const ReportCard: FunctionComponent<Props> = ({
  intl,
  reportConfiguration,
  className,
  sharedWidgets,
  sharedWidgetsLoading,
  dragRef,
}) => {
  const chartRef = useRef();
  const [exportTo, setExportTo] = useState<ChartFormatTypeEnum>();
  const { removeDashboardWidget, showReportEditModal, createReportWidgetCopy } = useReportWidgetsContext();

  const removeReport = useCallback(async () => {
    await removeDashboardWidget(reportConfiguration.id);
  }, [removeDashboardWidget, reportConfiguration.id]);

  const editReport = useCallback(() => {
    showReportEditModal(reportConfiguration.id);
  }, [showReportEditModal, reportConfiguration.id]);

  const configuration = useMemo(() => {
    if (reportConfiguration.type === 'shared') {
      return sharedWidgets[reportConfiguration.organizationId]?.find(
        (widget) => widget.id === reportConfiguration.widgetId
      );
    } else {
      return { ...reportConfiguration };
    }
  }, [reportConfiguration, sharedWidgets]);

  const titleText = reportConfiguration?.title || configuration?.title;

  useEffect(() => {
    if (exportTo) {
      exportChartImage(
        chartRef,
        exportTo,
        titleText,
        getReportExportName(configuration, intl),
        intl,
        { name: '' },
        () => setExportTo(undefined)
      );
    }
  }, [exportTo]);

  const menu = useMemo((): MenuProps => {
    const items: ItemType[] = [
      {
        key: 'remove',
        label: intl.formatMessage({ id: 'ProjectDashboard.Reports.removeFromDashboard' }),
        icon: <CloseOutlined />,
        onClick: removeReport,
      },
      isReportEditable(reportConfiguration) && {
        key: 'edit',
        label: intl.formatMessage({ id: 'general.edit' }),
        icon: <EditOutlined />,
        onClick: editReport,
      },
      isReportCopyable(reportConfiguration) && {
        key: 'copy',
        label: intl.formatMessage({ id: 'ProjectDashboard.Reports.copyReportWidget' }),
        icon: <CopyOutlined />,
        onClick: () => createReportWidgetCopy(reportConfiguration.id),
      },
      isReportExportable(reportConfiguration) && {
        key: 'exportPng',
        label: intl.formatMessage({ id: 'ProjectDashboard.Reports.Export.png' }),
        icon: <FileImageOutlined />,
        onClick: () => setExportTo(ChartFormatTypeEnum.png),
      },
      isReportExportable(reportConfiguration) && {
        key: 'exportSvg',
        label: intl.formatMessage({ id: 'ProjectDashboard.Reports.Export.svg' }),
        icon: <FileImageOutlined />,
        onClick: () => setExportTo(ChartFormatTypeEnum.svg),
      },
    ].filter(Boolean);
    return { items };
  }, [intl, removeReport, editReport, createReportWidgetCopy, reportConfiguration]);

  const menuDropdown = (
    <Dropdown menu={menu}>
      <Button type="default" shape="circle">
        <EllipsisOutlined rotate={90} />
      </Button>
    </Dropdown>
  );

  const loading =
    !!(reportConfiguration?.type === 'shared') && sharedWidgetsLoading.has(reportConfiguration.organizationId);

  const title = useMemo(() => {
    return (
      <NoClickTooltip mouseEnterDelay={0.5} title={titleText}>
        <div className={styles.name}>
          <DisplayName text={titleText} />
        </div>
      </NoClickTooltip>
    );
  }, [titleText]);

  return (
    <MovableCard className={classNames(styles.card, className)} title={title} menu={menuDropdown} dragRef={dragRef}>
      {configuration?.type === 'personal' && (
        <POHReportChart
          reportConfiguration={configuration?.data}
          widgetId={reportConfiguration.id}
          chartRef={chartRef}
          exportTo={exportTo}
        />
      )}
      {configuration?.type === 'projectDocumentationStatistics' && (
        <ProjectDocumentationStatisticReportChart chartRef={chartRef} exportTo={exportTo} />
      )}
      {configuration?.type === 'notificationStatistics' && (
        <UserNotificationStatisticsReport configuration={configuration} />
      )}
      {configuration?.type === 'assignmentStatistics' && (
        <UserAssignmentStatisticsReport configuration={configuration} />
      )}
      {!configuration && !loading && (
        <Empty
          className={styles.notAvailableError}
          description={<Fmt id="ProjectDashboard.Reports.notAvailableError" />}
        />
      )}
    </MovableCard>
  );
};

export default injectIntl(ReportCard);
