import { DeliveredProcedureOutlined, DoubleLeftOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Button, FormInstance, Modal, PageHeader, Tooltip } from 'antd';
import { masterApi } from 'api/completeApi';
import {
  EntityTypesEnum,
  MdProjectDto,
  MdProjectStateEnum,
  MdRoleDto,
  MdRoleEnum,
  OrgUserClaimDto,
} from 'api/completeApiInterfaces';
import { AuditLogButton } from 'components/ActionButtons';
import AuditLogMasterApiEntityModal from 'components/AuditLogsComponents/AuditLogMasterApiEntityModal';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { MdProjectWithUnpackedData } from 'components/JSONVariableFormItems/dataUnpackers/mdProjectUnpacker.utils';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { SaveOrCancelButton } from 'components/SaveOrCancelButton/SaveOrCancelButton';
import { useApiData, useBoolean, useCurrentAppUser, useIntl, useIsMounted, useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { useCallback, useMemo, useState } from 'react';
import { messageError } from 'utils';
import MDReturnMaterialFormModal from '../MDReturnMaterialModal/MDReturnMaterialFormModal';
import MDSetCkPreparedSwitch from '../MdSetCkPreparedSwitch/MDSetCkPreparedSwitch';
import MDSetCommentingDivisionsModal from '../MdSetCommentingDivisionsModal/MDSetCommentingDivisionsModal';
import MDSetDivisionModal from '../MdSetDivisionModal/MDSetDivisionModal';
import MDSetGuarantorModal from '../MdSetGuarantorModal/MDSetGuarantorModal';

type Props = {
  form: FormInstance<any>;
  mdProject: MdProjectWithUnpackedData;
  mdRoles: MdRoleDto[];
  onCancelChanges: () => void;
  onProjectUpdate: (project: MdProjectWithUnpackedData) => void;
  isDirty: boolean;
  saving: boolean;
  extendedUserClaims: OrgUserClaimDto[];
  isProposerRole: boolean;
  isO910Role: boolean;
  isProjectGuarantorRole: boolean;
  isPreparedForCKApproval: boolean;
};

type TransferEnableWithReason = { isEnable: boolean; disableReason: string };

const getStateTransitionButtonText = (state: MdProjectStateEnum): IntlMessageId => {
  switch (state) {
    case MdProjectStateEnum.entering:
      return 'MD.Projects.ProjectCardModal.button.transfer.o910';
    case MdProjectStateEnum.divisionSetting:
      return 'MD.Projects.ProjectCardModal.button.transfer.division';
    case MdProjectStateEnum.guarantorSetting:
      return 'MD.Projects.ProjectCardModal.button.transfer.guarantor';
  }
  return undefined;
};

const MDProjectCardToolbar: React.FC<Props> = ({
  form,
  mdProject,
  mdRoles,
  onCancelChanges,
  onProjectUpdate,
  isDirty,
  saving,
  extendedUserClaims,
  isProposerRole,
  isO910Role,
  isProjectGuarantorRole,
  isPreparedForCKApproval,
}) => {
  const currentAppUser = useCurrentAppUser();
  const intl = useIntl();
  const isMounted = useIsMounted();
  const [setDivisionModalVisible, showSetDivisionModal, hideSetDivisionModal] = useBoolean();
  const [setGuarantorModalVisible, showSetGuarantorModal, hideSetGuarantorModal] = useBoolean();
  const [
    setCommentingDivisionsModalVisible,
    showSetCommentingDivisionsModal,
    hideSetCommentingDivisionsModal,
  ] = useBoolean();
  const [toShowActivityMdProject, setToShowActivityMdProject] = useState<MdProjectDto>();
  const [returnToMdProjectState, setReturnToMdProjectSTate] = useState<MdProjectStateEnum>();

  const onFormSubmit = () => {
    form.submit();
  };

  const transferButtonText = getStateTransitionButtonText(mdProject?.state);

  const [mdDivisions, mdDivisionsError, mdDivisionsLoading] = useApiData(
    (ct) => masterApi.projects.md.divisions.id.get(mdProject.organization.id, ct),
    { autoload: true }
  );

  const isProjectSupervisorRole = useMemo(() => {
    if (!mdProject || !mdRoles) return false;
    const divisionRoles = mdRoles.filter(
      (role) => role.mdRoleType === MdRoleEnum.supervisor && role.division.id === mdProject.processorDivision?.id
    );
    return divisionRoles.some(
      (divisionRole) =>
        divisionRole.head.orgUser.appUserProfileId === currentAppUser.id ||
        divisionRole.mdUsers.some((user) => user.orgUser.appUserProfileId === currentAppUser.id)
    );
  }, [currentAppUser, mdProject, mdRoles]);

  const hasReferenceNumber = useMemo(() => !!mdProject?.referenceNumber?.value, [mdProject]);
  const hasCkMeetingBackground = useMemo(() => mdProject?.ckMeetingBackground?.value, [mdProject]);
  const canSetCkApproval = hasReferenceNumber && hasCkMeetingBackground && isProjectGuarantorRole;

  const canTransferStateWithReason = useMemo((): TransferEnableWithReason => {
    if (!mdProject) return { isEnable: false, disableReason: intl.formatMessage({ id: 'BudgetGrid.noDataText' }) };
    switch (mdProject?.state) {
      case MdProjectStateEnum.entering: {
        if (isProposerRole) {
          return !!mdProject.submittedFor?.value
            ? { isEnable: true, disableReason: '' }
            : {
                isEnable: false,
                disableReason: intl.formatMessage({
                  id: 'MD.Projects.ProjectCardModal.button.returnToO910.disableReason.submittedFor.notSet',
                }),
              };
        }
        return {
          isEnable: false,
          disableReason: intl.formatMessage({
            id: 'MD.Projects.ProjectCardModal.button.returnToO910.disableReason.unexpectedMdRoleType',
          }),
        };
      }
      case MdProjectStateEnum.divisionSetting: {
        if (isO910Role) {
          return !!mdProject.processorDivision?.id
            ? { isEnable: true, disableReason: '' }
            : {
                isEnable: false,
                disableReason: intl.formatMessage({
                  id: 'MD.Projects.ProjectCardModal.button.returnToO910.disableReason.processorDivision.notSet',
                }),
              };
        }
        return {
          isEnable: false,
          disableReason: intl.formatMessage({
            id: 'MD.Projects.ProjectCardModal.button.returnToO910.disableReason.unexpectedMdRoleType',
          }),
        };
      }
      case MdProjectStateEnum.guarantorSetting: {
        if (isProjectSupervisorRole) {
          return isProjectSupervisorRole && !!mdProject.guarantor?.id
            ? { isEnable: true, disableReason: '' }
            : {
                isEnable: false,
                disableReason: intl.formatMessage({
                  id: 'MD.Projects.ProjectCardModal.button.returnToO910.disableReason.guarantor.notSet',
                }),
              };
        }
        return {
          isEnable: false,
          disableReason: intl.formatMessage({
            id: 'MD.Projects.ProjectCardModal.button.returnToO910.disableReason.unexpectedMdRoleType',
          }),
        };
      }
    }
    return {
      isEnable: false,
      disableReason: intl.formatMessage({
        id: 'MD.Projects.ProjectCardModal.button.returnToO910.disableReason.unexpectedProjectState',
      }),
    };
  }, [intl, isO910Role, isProjectSupervisorRole, isProposerRole, mdProject]);

  const handleStateTransfer = useSameCallback(async (newState: MdProjectStateEnum) => {
    const [err, res] = await masterApi.projects.md.project.setstate.post({
      projectId: mdProject.id,
      requestedState: newState,
    });
    if (!isMounted.current) {
      return;
    }
    if (err) {
      messageError(err, intl);
      return;
    }
    onProjectUpdate(res.data);
  });

  const returnToSubmitterDisabledTooltip = useMemo(() => {
    if (isPreparedForCKApproval && !isProjectGuarantorRole)
      return (
        <>
          <div>
            {intl.formatMessage({ id: 'MD.Projects.ProjectCardModal.button.returnToSubmitter.disable.notGuarantor' })}
          </div>
          <div>
            {intl.formatMessage({
              id: 'MD.Projects.ProjectCardModal.button.returnToSubmitter.disable.isPreparedForCkAproval',
            })}
          </div>
        </>
      );

    if (isPreparedForCKApproval && isProjectGuarantorRole)
      return (
        <div>
          {intl.formatMessage({
            id: 'MD.Projects.ProjectCardModal.button.returnToSubmitter.disable.isPreparedForCkAproval',
          })}
        </div>
      );
    if (!isPreparedForCKApproval && !isProjectGuarantorRole)
      return (
        <div>
          {intl.formatMessage({ id: 'MD.Projects.ProjectCardModal.button.returnToSubmitter.disable.notGuarantor' })}
        </div>
      );
    return null;
  }, [intl, isProjectGuarantorRole, isPreparedForCKApproval]);

  const returnToO910DisableTooltip = useMemo(() => {
    if (!isProjectSupervisorRole)
      return (
        <div>
          {intl.formatMessage({
            id: 'MD.Projects.ProjectCardModal.button.returnToO910.disable.isNotProjectSupervisorRole',
          })}
        </div>
      );
    return null;
  }, [intl, isProjectSupervisorRole]);

  const handleStateButtonClick = useCallback(() => {
    if (!canTransferStateWithReason) return;

    switch (mdProject.state) {
      case MdProjectStateEnum.entering: {
        Modal.confirm({
          title: intl.formatMessage({ id: 'MD.Projects.ProjectCardModal.stateTransfer.divisionSetting.title' }),
          content: intl.formatMessage({ id: 'MD.Projects.ProjectCardModal.stateTransfer.divisionSetting.description' }),
          onOk: async () => {
            await handleStateTransfer(MdProjectStateEnum.divisionSetting);
          },
          okText: intl.formatMessage({ id: 'general.yes' }),
          cancelText: intl.formatMessage({ id: 'general.no' }),
        });
        break;
      }
      case MdProjectStateEnum.divisionSetting: {
        Modal.confirm({
          title: intl.formatMessage({ id: 'MD.Projects.ProjectCardModal.stateTransfer.guarantorSetting.title' }),
          content: intl.formatMessage({
            id: 'MD.Projects.ProjectCardModal.stateTransfer.guarantorSetting.description',
          }),
          onOk: async () => {
            await handleStateTransfer(MdProjectStateEnum.guarantorSetting);
          },
          okText: intl.formatMessage({ id: 'general.yes' }),
          cancelText: intl.formatMessage({ id: 'general.no' }),
        });
        break;
      }
      case MdProjectStateEnum.guarantorSetting: {
        Modal.confirm({
          title: intl.formatMessage({ id: 'MD.Projects.ProjectCardModal.stateTransfer.projectValidation.title' }),
          content: intl.formatMessage({
            id: 'MD.Projects.ProjectCardModal.stateTransfer.projectValidation.description',
          }),
          onOk: async () => {
            await handleStateTransfer(MdProjectStateEnum.projectValidation);
          },
          okText: intl.formatMessage({ id: 'general.yes' }),
          cancelText: intl.formatMessage({ id: 'general.no' }),
        });
        break;
      }
    }
  }, [canTransferStateWithReason, handleStateTransfer, intl, mdProject]);

  const stateActionButton = useMemo(() => {
    if (!mdProject) return null;
    switch (mdProject.state) {
      case MdProjectStateEnum.divisionSetting:
        return (
          <Button
            icon={<TeamOutlined />}
            type="primary"
            key="setDivision"
            disabled={!isO910Role}
            onClick={showSetDivisionModal}
          >
            <Fmt id="MD.Projects.ProjectCardModal.button.setDivision" />
          </Button>
        );
      case MdProjectStateEnum.guarantorSetting:
        return (
          <Button
            icon={<UserOutlined />}
            type="primary"
            key="setGuarantor"
            disabled={!isProjectSupervisorRole}
            onClick={showSetGuarantorModal}
          >
            <Fmt id="MD.Projects.ProjectCardModal.button.setGuarantor" />
          </Button>
        );
      case MdProjectStateEnum.projectValidation:
        return (
          <>
            <Tooltip
              title={
                !hasReferenceNumber ? (
                  <Fmt id="MD.Projects.ProjectCkApprovalReadinesSetModal.title.prerequisites.referenceNumber" />
                ) : !hasCkMeetingBackground ? (
                  <Fmt id="MD.Projects.ProjectCkApprovalReadinesSetModal.title.prerequisites.ckBackgroundDocuments" />
                ) : (
                  ''
                )
              }
            >
              <MDSetCkPreparedSwitch
                mdProject={mdProject}
                onProjectUpdate={onProjectUpdate}
                disabled={!canSetCkApproval}
              />
            </Tooltip>

            <Button
              icon={<UserOutlined />}
              type="primary"
              key="setCommentingDivisions"
              disabled={!isProjectGuarantorRole}
              onClick={showSetCommentingDivisionsModal}
            >
              <Fmt id="MD.Projects.ProjectCardModal.button.commentingDivisions" />
            </Button>
          </>
        );
    }
    return null;
  }, [
    canSetCkApproval,
    hasCkMeetingBackground,
    hasReferenceNumber,
    isO910Role,
    isProjectSupervisorRole,
    mdProject,
    onProjectUpdate,
    showSetDivisionModal,
    showSetGuarantorModal,
    showSetCommentingDivisionsModal,
  ]);

  const hideAuditLog = useCallback(() => {
    setToShowActivityMdProject(undefined);
  }, []);

  const handleReturnMaterial = useCallback(
    (project: MdProjectDto) => {
      onProjectUpdate(project);
      setReturnToMdProjectSTate(undefined);
    },
    [onProjectUpdate]
  );

  const handleCommentingDivisionsSet = useCallback(
    (project: MdProjectDto) => {
      onProjectUpdate(project);
      hideSetCommentingDivisionsModal();
    },
    [onProjectUpdate]
  );

  const handleGuarantorSet = useCallback(
    (project: MdProjectDto) => {
      onProjectUpdate(project);
      hideSetGuarantorModal();
    },
    [onProjectUpdate]
  );

  const handleDivisionSet = useCallback(
    (project: MdProjectDto) => {
      onProjectUpdate(project);
      hideSetDivisionModal();
    },
    [onProjectUpdate]
  );

  if (!mdProject) return null;

  return (
    <>
      <PageHeader
        subTitle={<Fmt id="MD.Projects.ProjectCardModal.section.header.title" />}
        extra={
          <FlowLayout wrap>
            <ContentGate loading={mdDivisionsLoading}>
              {mdProject.state === MdProjectStateEnum.projectValidation && (
                <CommonHubTooltip title={returnToSubmitterDisabledTooltip}>
                  <Button
                    icon={<DoubleLeftOutlined />}
                    key="returnToSubmitter"
                    type="primary"
                    shape="round"
                    size="small"
                    disabled={!!returnToSubmitterDisabledTooltip}
                    onClick={() => setReturnToMdProjectSTate(MdProjectStateEnum.entering)}
                  >
                    <Fmt id={'MD.Projects.ProjectCardModal.button.returnToSubmitter'} />
                  </Button>
                </CommonHubTooltip>
              )}
              {mdProject.state === MdProjectStateEnum.guarantorSetting && (
                <CommonHubTooltip title={returnToO910DisableTooltip}>
                  <Button
                    icon={<DoubleLeftOutlined />}
                    key="returnToSubmitter"
                    type="primary"
                    shape="round"
                    size="small"
                    disabled={!!returnToO910DisableTooltip}
                    onClick={() => setReturnToMdProjectSTate(MdProjectStateEnum.divisionSetting)}
                  >
                    <Fmt id={'MD.Projects.ProjectCardModal.button.returnToO910'} />
                  </Button>
                </CommonHubTooltip>
              )}
              {stateActionButton}
              {transferButtonText && (
                <CommonHubTooltip title={canTransferStateWithReason.disableReason}>
                  <Button
                    icon={<DeliveredProcedureOutlined />}
                    type="primary"
                    key="transfer"
                    disabled={!canTransferStateWithReason.isEnable}
                    onClick={handleStateButtonClick}
                  >
                    <Fmt id={transferButtonText} />
                  </Button>
                </CommonHubTooltip>
              )}
            </ContentGate>
            <SaveOrCancelButton
              key="saveOrCancel"
              onSave={onFormSubmit}
              onCancel={onCancelChanges}
              disabled={!isDirty}
              loading={saving}
            />
            <AuditLogButton key="auditLogButton" onClick={() => setToShowActivityMdProject(mdProject)} />
          </FlowLayout>
        }
      />
      {setDivisionModalVisible && (
        <MDSetDivisionModal
          open={setDivisionModalVisible}
          mdDivisions={mdDivisions}
          mdProjectId={mdProject.id}
          mdCurrentDivision={mdProject.processorDivision}
          onClose={hideSetDivisionModal}
          onSubmit={handleDivisionSet}
        />
      )}
      {setGuarantorModalVisible && (
        <MDSetGuarantorModal
          open={setGuarantorModalVisible}
          mdProject={mdProject}
          mdRoles={mdRoles}
          onClose={hideSetGuarantorModal}
          onSubmit={handleGuarantorSet}
          extendedUserClaims={extendedUserClaims}
        />
      )}
      {setCommentingDivisionsModalVisible && (
        <MDSetCommentingDivisionsModal
          open={setCommentingDivisionsModalVisible}
          mdProject={mdProject}
          mdDivisions={mdDivisions}
          onClose={hideSetCommentingDivisionsModal}
          onSubmit={handleCommentingDivisionsSet}
        />
      )}
      {!!toShowActivityMdProject && (
        <AuditLogMasterApiEntityModal
          visible={!!toShowActivityMdProject}
          label={<Fmt id="AuditLog.mdMaterial.label" values={{ projectName: toShowActivityMdProject.name }} />}
          entityId={toShowActivityMdProject.id}
          entityType={EntityTypesEnum.mdProject}
          onOk={hideAuditLog}
          deps={[toShowActivityMdProject]}
        />
      )}
      {!!returnToMdProjectState && (
        <MDReturnMaterialFormModal
          mdProjectId={mdProject.id}
          newState={returnToMdProjectState}
          open={!!returnToMdProjectState}
          onClose={() => setReturnToMdProjectSTate(undefined)}
          onSubmit={handleReturnMaterial}
        />
      )}
    </>
  );
};

export default MDProjectCardToolbar;
