import { DirectoryContentDto, DocumentContentDto } from 'api/completeApiInterfaces';
import { DocumentSelectDocumentState } from 'components/DocumentSelect/DocumentSelect';
import { DocumentSelectModal } from 'components/DocumentSelect/DocumentSelectModal';
import { DocumentCreateFormModal } from 'components/forms/DocumentCreateForm';
import { createDocumentUploadData } from 'components/forms/DocumentCreateForm/DocumentCreateFormModal';
import FolderValidationMasksContextProvider from 'components/forms/DocumentCreateForm/FolderValidationMasksContext';
import { AddIcon, UploadIcon } from 'components/Icons/HubActionsIcons';
import ShrinkableToolbar, { ToolbarItemProps } from 'components/Toolbar/ShrinkableToolbar';
import Toolbar, { ToolbarProps } from 'components/Toolbar/Toolbar';
import { useBoolean } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { DisabledWithReason } from 'utils/types';

export type DocumentToolbarSelectedDocuments = {
  uploaded?: DocumentSelectDocumentState[];
  existing?: DocumentSelectDocumentState[];
};

type Props = {
  canUpload: boolean;
  canSelectExisting: boolean;
  multiple?: boolean;
  selectedExistingDocuments?: DocumentSelectDocumentState[];
  openSelectionWithDocumentsChange?: boolean;
  onDocumentSelect: (documents: DocumentToolbarSelectedDocuments) => Promise<void>;
  disableDocumentForSelection: (document: DirectoryContentDto) => DisabledWithReason;
} & ToolbarProps;

export const DocumentSelectToolbar: FunctionComponent<Props> = ({
  canUpload,
  canSelectExisting,
  multiple,
  selectedExistingDocuments,
  openSelectionWithDocumentsChange = false,
  onDocumentSelect,
  disableDocumentForSelection,
  ...toolbarProps
}) => {
  const [addModalOpen, showAddModal, hideAddModal] = useBoolean();
  const [uploadVisible, showUpload, hideUpload] = useBoolean();
  const [selectedDocuments, setSelectedDocuments] = useState<DocumentSelectDocumentState[]>([]);
  const [addSaving, setAddSaving] = useState(false);

  const handleShowAdd = useCallback(() => {
    setSelectedDocuments([]);
    showAddModal();
  }, []);

  useEffect(() => {
    if (selectedExistingDocuments?.length > 0 && canSelectExisting && openSelectionWithDocumentsChange) {
      setSelectedDocuments(selectedExistingDocuments);
      showAddModal();
    }
  }, [selectedExistingDocuments, openSelectionWithDocumentsChange]);

  const handleSelectExisting = useCallback(async () => {
    hideAddModal();
    setAddSaving(true);
    await onDocumentSelect({ existing: selectedDocuments });
    setAddSaving(false);
    setSelectedDocuments([]);
  }, [selectedDocuments, onDocumentSelect]);

  const handleUpload = useCallback(
    async (document: DocumentContentDto) => {
      hideUpload();
      setAddSaving(true);
      await onDocumentSelect({
        uploaded: [
          {
            id: document.id,
            name: document.name,
            directoryId: document.directoryId,
            directoryPath: document.directoryPath,
          },
        ],
      });
      setAddSaving(false);
    },
    [onDocumentSelect]
  );

  const items = useMemo(
    (): ToolbarItemProps[] =>
      [
        canSelectExisting && {
          key: 'addExistingDocument',
          label: 'AssignmentDocumentsField.addExistingDocument',
          onClick: handleShowAdd,
          icon: <AddIcon />,
        },
        canUpload && {
          key: 'uploadNewDocument',
          label: 'AssignmentDocumentsField.uploadNewDocument',
          onClick: showUpload,
          icon: <UploadIcon />,
        },
      ].filter(Boolean) as ToolbarItemProps[],
    [canSelectExisting, canUpload, handleShowAdd, showUpload]
  );

  return (
    <>
      <Toolbar {...toolbarProps}>
        <ShrinkableToolbar items={items} />
      </Toolbar>
      {canSelectExisting && (
        <DocumentSelectModal
          title={<Fmt id="AssignmentDocumentsField.selectDocuments" />}
          selectedDocuments={selectedDocuments}
          setSelectedDocuments={setSelectedDocuments}
          multiple={multiple}
          disabledDocuments={disableDocumentForSelection}
          open={addModalOpen}
          onCancel={hideAddModal}
          onOk={handleSelectExisting}
          confirmLoading={addSaving}
        />
      )}
      {canUpload && (
        <FolderValidationMasksContextProvider>
          <DocumentCreateFormModal
            open={uploadVisible}
            validateUniqueName={() => true}
            onSubmit={(_data, result) => handleUpload(result)}
            createUploadData={createDocumentUploadData}
            onClose={hideUpload}
            showDestinationDirectory
          />
        </FolderValidationMasksContextProvider>
      )}
    </>
  );
};
