import { ExtendedPermissionEnum, ProjectUserProfileDto } from 'api/completeApiInterfaces';

export const userHasPermission = (
  user: ProjectUserProfileDto | undefined,
  permission: ExtendedPermissionEnum
): boolean => user?.extendedPermissions?.some((userPermission) => userPermission === permission);

export const userHasSomePermissions = (
  user: ProjectUserProfileDto | undefined,
  permissions: ExtendedPermissionEnum[]
): boolean => user?.extendedPermissions?.some((userPermission) => permissions.includes(userPermission));
