import { Checkbox, DatePicker, Form, Input } from 'antd';
import { ExcludedDaySetDto } from 'api/completeApiInterfaces';
import { useIntl } from 'hooks';
import moment from 'moment';
import React, { FC } from 'react';
import { requiredRule } from 'utils/formHelpers';
import { momentFromExcludedDay } from '../CalendarSettingsForm/CalendarSettingsForm.utils';

export type CalendarExcludedDayFormData = { name: string; date: moment.Moment; repeating: boolean };

type Props = {
  isRepeating: boolean;
  initialExcludedDay?: ExcludedDaySetDto;
};

const getDefaultDate = (excludedDay?: ExcludedDaySetDto) => {
  return !!excludedDay ? momentFromExcludedDay(excludedDay) : moment();
};

export const CalendarExcludedDayForm: FC<Props> = ({ initialExcludedDay, isRepeating }) => {
  const intl = useIntl();

  return (
    <>
      <Form.Item
        name="name"
        initialValue={initialExcludedDay?.name || ''}
        label={intl.formatMessage({ id: 'general.name' })}
        rules={[requiredRule('forms.items.rules.required')]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="date"
        initialValue={getDefaultDate(initialExcludedDay)}
        label={intl.formatMessage({ id: 'general.date' })}
        rules={[requiredRule('forms.items.rules.required')]}
      >
        <DatePicker format="L" allowClear={false} />
      </Form.Item>
      <Form.Item
        name="repeating"
        initialValue={isRepeating}
        label={intl.formatMessage({ id: 'CalendarExcludedDayForm.repeating.label' })}
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
    </>
  );
};
