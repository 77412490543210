import { masterApi } from 'api/completeApi';
import {
  ExtendedPermissionCategoryEnum,
  ExtendedPermissionPatchDto,
  ExtendedPermissionTemplateDto,
} from 'api/completeApiInterfaces';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import { useIntl, useSameCallback } from 'hooks';
import { difference } from 'lodash';
import {
  DEFAULT_ACTIVE_PERMISSIONS,
  PERMISSION_MAP,
  PERMISSIONS_BUILDING_MAP,
} from 'pages/ProjectSettingsPage/Users/UserDetailPanel/PermissionsDrawer';
import React, { FunctionComponent, useState } from 'react';
import { messageError } from 'utils';
import { UserPermissionProfileForm, UserPermissionProfileFormData } from './UserPermissionProfileForm';

type Props = FormModalProps<ExtendedPermissionTemplateDto> & {
  editedPermissionProfile?: ExtendedPermissionTemplateDto;
  organizationId: Guid;
  validateUniqueName: (name: string) => boolean;
};

const UserPermissionProfileFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, editedPermissionProfile, organizationId, validateUniqueName, ...restProps } = props;
  const [saving, setSaving] = useState<boolean>();
  const intl = useIntl();

  const handleSubmit: FormSubmitHandler<UserPermissionProfileFormData> = useSameCallback(async (values) => {
    setSaving(true);

    const mappedPermission = Object.keys(PERMISSION_MAP).flatMap(
      (permissionCategory: ExtendedPermissionCategoryEnum) => {
        const categoryMap = PERMISSION_MAP[permissionCategory].map(
          (permission): ExtendedPermissionPatchDto => ({
            permission: values.activePermissions.some((activePermission) => activePermission === permission),
            permissionType: permission,
          })
        );

        if (permissionCategory === ExtendedPermissionCategoryEnum.aspeEsticon) {
          return [
            ...PERMISSIONS_BUILDING_MAP.map(
              (permission): ExtendedPermissionPatchDto => ({
                permission: values.activePermissions.some((activePermission) => activePermission === permission),
                permissionType: permission,
              })
            ),
            ...categoryMap,
          ];
        }
        return categoryMap;
      }
    );
    if (!!editedPermissionProfile) {
      const [err, res] = await masterApi.projects.tempates.extendedpermissiontemplate.patch({
        id: editedPermissionProfile.id,
        organizationId: organizationId,
        name: values.name,
        description: values.description,
        settings: mappedPermission,
      });
      if (err) {
        messageError(err, intl);
      } else {
        onSubmit(res.data);
      }
    } else {
      const hasOnlyDefaultValues = difference(values.activePermissions, DEFAULT_ACTIVE_PERMISSIONS).length === 0;
      const [err, res] = await masterApi.projects.tempates.extendedpermissiontemplate.post({
        organizationId: organizationId,
        name: values.name,
        description: values.description,
        settings: hasOnlyDefaultValues ? undefined : mappedPermission,
      });
      if (err) {
        messageError(err, intl);
      } else {
        onSubmit(res.data);
      }
    }

    setSaving(false);
    return null;
  });

  const isPermissionProfileEditing = !!editedPermissionProfile;

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId={
        isPermissionProfileEditing
          ? 'OrganizationUserPermissionProfiles.modal.edit.title'
          : 'OrganizationUserPermissionProfiles.modal.add.title'
      }
      submitTextId={isPermissionProfileEditing ? 'general.edit' : 'general.add'}
      forceLoading={saving}
      width={700}
      {...restProps}
    >
      <UserPermissionProfileForm defaults={editedPermissionProfile} validateUniqueName={validateUniqueName} />
    </FormModalWrapper>
  );
};

export default UserPermissionProfileFormModal;
