import { useCallback, useEffect, useState } from 'react';

export const useDirtyState = (isDirty: boolean) => {
  const [editedNodeIds, setEditedNodeIds] = useState<Set<string>>();
  const [newNodeIds, setNewNodeIds] = useState<Set<string>>();

  const setEdited = useCallback((nodeId: string) => {
    setEditedNodeIds((editedNodeIds) => new Set([...(editedNodeIds || []), nodeId]));
  }, []);

  const setNew = useCallback((nodeId: string) => {
    setNewNodeIds((newNodeIds) => new Set([...(newNodeIds || []), nodeId]));
  }, []);

  const clear = useCallback(() => {
    setEditedNodeIds(new Set());
    setNewNodeIds(new Set());
  }, []);

  useEffect(() => {
    if (!isDirty) {
      clear();
    }
  }, [isDirty]);

  return { editedNodeIds, setEdited, newNodeIds, setNew, clear };
};
