import { InputRef, message } from 'antd';
import { api } from 'api';
import { DocumentMultipleDownloadDto, DownloadUrl } from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import { transformError } from 'components/DocumentMultipleActionError';
import { IMultipleDocumentErrorData } from 'components/DocumentMultipleActionError/DocumentMultipleActionError';
import { useFocus } from 'hooks/useFocus';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { processApiError } from 'utils';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import DocumentsDownloadForm, { DocumentsDownloadFormData } from './DocumentsDownloadForm';

type Props = InjectedIntlProps &
  FormModalProps<DownloadUrl> & {
    firstDocumentName: string;
    selectedDocumentIds: Guid[];
    selectedDocumentLinkIds?: Guid[];
    includingDirectories: boolean;
  };

const DocumentsDownloadFormModal: FunctionComponent<Props> = (props) => {
  const {
    onSubmit,
    onClose,
    firstDocumentName,
    selectedDocumentIds,
    selectedDocumentLinkIds = [],
    includingDirectories,
    ...restProps
  } = props;
  const [errors, setErrors] = useState<JSX.Element[]>([]);
  const [selectedOkDocuments, setSelectedOkDocuments] = useState<Guid[]>(null);
  const [selectedOkLinks, setSelectedOkLinks] = useState<Guid[]>(null);

  const handleSubmit: FormSubmitHandler<DocumentsDownloadFormData> = async (values) => {
    const selectedDocs = !!selectedOkDocuments ? selectedOkDocuments : selectedDocumentIds;
    const selectedLinks = !!selectedOkLinks ? selectedOkLinks : selectedDocumentLinkIds;
    if (selectedDocs.length + selectedLinks.length > 0) {
      const data: DocumentMultipleDownloadDto = {
        documentsIds: selectedDocs,
        documentLinksIds: selectedLinks,
        name: values.archiveName,
        includingDirectories: includingDirectories,
      };
      const [err, res] = await api.project.documents.downloadDocuments(data);
      if (err) {
        processApiError(err, (error) => {
          if (error != null && !!error.errorData) {
            switch (error.referenceErrorCode) {
              case ServiceErrorEnum.DocumentMultipleMoveObstacleError:
                // eslint-disable-next-line no-case-declarations
                const errData = error.errorData as IMultipleDocumentErrorData;
                setSelectedOkDocuments(
                  selectedDocumentIds.filter(
                    (doc) => !errData.documentsErrors.some((errDoc) => errDoc.objectId === doc)
                  )
                );
                setSelectedOkLinks(
                  selectedDocumentLinkIds.filter(
                    (link) => !errData.documentsErrors.some((errDoc) => errDoc.objectId === link)
                  )
                );
                setErrors(errData.documentsErrors.map(transformError));
                return null;
            }
          }
          message.error(props.intl.formatMessage({ id: `serviceError.${error.referenceErrorCode}` }));
        });
        return null;
      }

      onSubmit(res.data);
      resetDialog();
      return null;
    }
    resetDialog();
    onClose();
    return null;
  };

  const handleClose = () => {
    resetDialog();
    onClose();
  };

  const resetDialog = () => {
    setErrors([]);
    setSelectedOkDocuments(null);
    setSelectedOkLinks(null);
  };

  const downloadableEntitiesCount = useMemo(
    () =>
      (!!selectedOkDocuments ? selectedOkDocuments.length : selectedDocumentIds?.length) +
      (!!selectedOkLinks ? selectedOkLinks.length : selectedDocumentLinkIds?.length),
    [selectedOkDocuments, selectedDocumentIds, selectedOkLinks, selectedOkLinks]
  );

  const submitTextId = useMemo(() => {
    if (!downloadableEntitiesCount) return 'general.ok';
    return errors.length === 0
      ? 'DocumentsDownloadFormModal.button.download'
      : 'DocumentsDownloadFormModal.button.downloadNext';
  }, [downloadableEntitiesCount, errors]);

  const { setInputRef } = useFocus<InputRef>(restProps.open);

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      onClose={handleClose}
      titleId="DocumentsDownloadFormModal.title"
      confirmClose={false}
      {...restProps}
      submitTextId={submitTextId}
      layout="horizontal"
      //labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
    >
      <DocumentsDownloadForm
        firstDocumentName={firstDocumentName}
        selectedDocuments={!!selectedOkDocuments ? selectedOkDocuments : selectedDocumentIds}
        selectedLinks={!!selectedOkLinks ? selectedOkLinks : selectedDocumentLinkIds}
        errors={errors}
        setRef={setInputRef}
      />
    </FormModalWrapper>
  );
};

export default injectIntl(DocumentsDownloadFormModal);
