import { api } from 'api';
import { AppUserDto, AppUserRequestDto, AppUsersRequestDto } from 'api/completeApiInterfaces';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent } from 'react';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import AppUserAddForm, { AppUserAddFormData } from './AppUserAddForm';

type Props = FormModalProps<AppUserRequestDto> & {
  toAddAppUsers: AppUserDto[];
  isAdminsAdding?: boolean;
  titleId: IntlMessageId;
};
const AppUsersAddFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, toAddAppUsers, isAdminsAdding, titleId, ...restProps } = props;

  const handleSubmit: FormSubmitHandler<AppUserAddFormData> = async (values) => {
    const data: AppUsersRequestDto = {
      users: Object.entries(values.usersToAddWithAttributes).map(([mail, attributes]) => ({
        mail: mail,
        language: attributes.defaultLanguage,
        isAdmin: attributes.isAdmin,
      })),
    };

    const [err] = await api.master.organization.setappuser(data);
    if (err) return err;
    onSubmit(undefined);
    return null;
  };

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      title={<Fmt id={titleId} />}
      submitTextId="general.add"
      width={800}
      {...restProps}
    >
      <AppUserAddForm toAddAppUsers={toAddAppUsers} isAdminsAdding={isAdminsAdding} />
    </FormModalWrapper>
  );
};

export default AppUsersAddFormModal;
