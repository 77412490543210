import { message } from 'antd';
import { masterApi } from 'api/completeApi';
import {
  MdCkIdentificationEnum,
  MdMeetingApprovalBackgroundDto,
  MdMeetingApprovalBackgroundPatchDto,
  MdMeetingApprovalBackgroundRequestDto,
  MdMeetingBackgroundMdMeetingStatusEnum,
  MdRoleDto,
  MdRoleEnum,
} from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { momentToIsoDate, momentToIsoTime } from 'components/DateSelect/DateSelect';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import { MdProjectListWithUnpackedData } from 'components/JSONVariableFormItems/dataUnpackers/mdProjectUnpacker.utils';
import { isJSONBooleanValueTrue } from 'components/JSONVariableFormItems/Items/JSONVariableCheckboxFormItem';
import { useApiData, useDispatchEffect, useIntl, useIsMounted, useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import { uniq } from 'lodash';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { messageError } from 'utils';
import { useMdMeetingBackgroundDocumentState } from '../MDMeetingBackgroundDocumentModal/useMdMeetingBackgroundDocumentState';
import MDProjectApprovalForm, { MDProjectApprovalFormData } from './MDProjectApprovalForm';

export type MdMeetingAprovalDefault = Partial<MdMeetingApprovalBackgroundDto> & { meetingNumber: number };

type Props = FormModalProps<MdMeetingApprovalBackgroundDto> & {
  projectsList: MdProjectListWithUnpackedData[];
  organizationId: Guid;
  defaultMeetingBackground: MdMeetingAprovalDefault;
  mdUserCkOrganizerRoles: MdRoleDto[];
};

const MDProjectApprovalFormModal: FunctionComponent<Props> = (props) => {
  const {
    onSubmit,
    organizationId,
    projectsList,
    defaultMeetingBackground,
    mdUserCkOrganizerRoles,
    ...restProps
  } = props;
  const intl = useIntl();
  const isMounted = useIsMounted();

  useDispatchEffect((dispatch) => dispatch.activeCalendar.loadCalendar({ organizationId }), [organizationId]);

  const [
    meetingDocumentState,
    meetingDocumentStateError,
    isPreparationError,
    isDocumentNotYetRequested,
    isDocumentProcessing,
    loadMeetingDocumentState,
  ] = useMdMeetingBackgroundDocumentState(organizationId, defaultMeetingBackground?.id);

  const preparedProjectList = useMemo(
    () =>
      projectsList?.filter(
        (project) =>
          isJSONBooleanValueTrue(project.preparedForCkApproval?.value) ||
          defaultMeetingBackground?.mdProjectIds.some((projectId) => project.id === projectId)
      ),
    [projectsList]
  );

  useEffect(() => {
    defaultMeetingBackground?.id && loadMeetingDocumentState();
  }, [defaultMeetingBackground?.id]);

  const allowedCkIdentifications = useMemo(
    (): MdCkIdentificationEnum[] =>
      uniq(
        mdUserCkOrganizerRoles
          .filter((role) => role.mdRoleType === MdRoleEnum.ck_organizer)
          .reduce((acc, role) => [...acc, ...role.mdCkIdentifications], [])
      ),
    [mdUserCkOrganizerRoles]
  );

  const [existingMeetings] = useApiData((ct) => masterApi.projects.md.approvalmeeting.id.get(organizationId, ct), {
    autoload: true,
  });

  const handleSubmit: FormSubmitHandler<MDProjectApprovalFormData> = useSameCallback(async (values) => {
    if (defaultMeetingBackground?.id) {
      const meetingApprovalRequestDto: MdMeetingApprovalBackgroundPatchDto = {
        meetingNumber: values.meetingNumber,
        note: values.note,
        ckIdentification: values.ckIdentification,
        referenceCode: values.referenceCode,
        approvalDateTime: momentToIsoDate(values.approvalDateTime),
        startTime: momentToIsoTime(values.approvalTimeRange[0]),
        endTime: momentToIsoTime(values.approvalTimeRange[1]),
        projectIds: values.projectIds,
      };
      const [meetingApprovalError, meetingApprovalResponse] = await masterApi.projects.md.approvalmeeting.id.id.patch(
        organizationId,
        defaultMeetingBackground?.id,
        meetingApprovalRequestDto
      );
      if (!isMounted.current) return;
      if (meetingApprovalError) {
        messageError(meetingApprovalError, intl);
        return;
      } else {
        void message.success(intl.formatMessage({ id: 'MDApprovedProjectsReport.prepareCkApprovals.edit.success' }));
        onSubmit(meetingApprovalResponse.data);
      }
    } else {
      const meetingApprovalRequestDto: MdMeetingApprovalBackgroundRequestDto = {
        meetingNumber: values.meetingNumber,
        ckIdentification: values.ckIdentification,
        note: values.note,
        referenceCode: values.referenceCode,
        approvalDateTime: momentToIsoDate(values.approvalDateTime),
        startTime: momentToIsoTime(values.approvalTimeRange[0]),
        endTime: momentToIsoTime(values.approvalTimeRange[1]),
        projectIds: values.projectIds,
      };

      const [meetingApprovalError, meetingApprovalResponse] = await masterApi.projects.md.approvalmeeting.id.post(
        organizationId,
        meetingApprovalRequestDto
      );
      if (!isMounted.current) return;
      if (meetingApprovalError) {
        messageError(meetingApprovalError, intl);
        return;
      } else {
        void message.success(intl.formatMessage({ id: 'MDApprovedProjectsReport.prepareCkApprovals.create.success' }));
        onSubmit(meetingApprovalResponse.data);
      }
    }

    return null;
  });

  const isActive =
    !defaultMeetingBackground?.status ||
    defaultMeetingBackground.status === MdMeetingBackgroundMdMeetingStatusEnum.inProcess;

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      title={
        defaultMeetingBackground?.id ? (
          <Fmt id="MDApprovedProjectsReport.prepareCkApprovals.edit" />
        ) : (
          <Fmt id="MDApprovedProjectsReport.prepareCkApprovals.create" />
        )
      }
      submitTextId={defaultMeetingBackground?.id ? 'general.edit' : 'general.create'}
      okButtonProps={{ disabled: !isActive }}
      width={800}
      {...restProps}
    >
      <ContentGate loading={isDocumentProcessing} loadingIntlId="MDApprovedProjectsReport.meetingDocument.inProgress">
        <MDProjectApprovalForm
          projectsList={preparedProjectList}
          defaultMeetingBackground={defaultMeetingBackground}
          allowedCkIdentifications={allowedCkIdentifications}
          existingMeetings={existingMeetings}
          disabled={!isActive}
        />
      </ContentGate>
    </FormModalWrapper>
  );
};

export default MDProjectApprovalFormModal;
