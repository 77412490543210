import { FileAddOutlined } from '@ant-design/icons';
import { Button, message, Modal, Tag } from 'antd';
import { masterApi } from 'api/completeApi';
import { MdMeDto, MdProjectDto, MdProjectPhaseEnum, MdProjectStateEnum, MdRoleEnum } from 'api/completeApiInterfaces';
import { BudgetGrid, OnContextMenuPreparingType } from 'components/BudgetGrid/BudgetGrid';
import { ButtonsSelect } from 'components/ButtonsSelect/ButtonsSelect';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { mdProjectListDataUnpack } from 'components/JSONVariableFormItems/dataUnpackers/mdProjectUnpacker.utils';
import { MDCkApprovalCondition } from 'components/JSONVariableFormItems/Items/JSONVariableConditionsFormItem';
import { MinMaxContainer } from 'components/MinMaxContainer/MinMaxContainer';
import MDProjectApprovalButtons from 'components/Reports/MDProjectCard/MDProjectApprovalCreateForm/MDProjectApprovalButtons';
import MDProjectCreateFormModal from 'components/Reports/MDProjectCard/MDProjectCardCreateForm/MDProjectCreateFormModal';
import MDProjectCardModal from 'components/Reports/MDProjectCard/MDProjectCardModal/MDProjectCardModal';
import StackPanel from 'components/StackPanel/StackPanel';
import { Template } from 'devextreme-react';
import { FilterRow, HeaderFilter } from 'devextreme-react/tree-list';
import { dxTreeListColumn } from 'devextreme/ui/tree_list';
import { useApiData, useBoolean, useIntl } from 'hooks';
import { useMDProjectsChangedSignal, useMDSignal } from 'hooks/useMDSignalR';
import { Fmt } from 'locale';
import { isNumber, round, uniq } from 'lodash';
import moment from 'moment-business-days';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { messageError } from 'utils';
import { DEFAULT_CURRENCY } from 'utils/currencyGridAndDashboardUtils';
import { currencyLong } from 'utils/formatters';
import stylesBudgedGridCommon from '../../../../components/BudgetGrid/BudgetGrid.module.less';
import { getApprovalProjectsColumns } from './MDApprovalProjectReport.gridUtils';
import {
  filterJustMineVisibleData,
  filterJustMyDivisionVisibleData,
  getCkConditionsMetStateIntlId,
  MAP_MD_PROJECT_PHASES_TO_MESSAGE_IDS,
  mapFlatDataToGridData,
  mapListObjectsToFlatData,
  MDGridData,
} from './MDApprovalProjectReport.utils';
import styles from './MDApprovedProjectsReportPage.module.less';
import MDCkConditionModal from './MDCkConditionModal';
import { MdDisplayMode, useMDProjectListDisplayMode } from './useMDProjectListDisplayMode';

const PROJECT_CARD_WIDTH = 1000;

type Props = {
  organizationId: Guid;
  mdCurrentUser: MdMeDto;
  initialProjectId?: Guid;
};

const getMdGridRowStyle = () => {
  return stylesBudgedGridCommon.MdRows;
};

const getStudyOrUpdateChildrenKeys = (gridData: MDGridData[], result: Guid[]) => {
  gridData.forEach((row) => {
    if (!!row.children?.length) {
      const hasStudyOrUpdateChildren = row.children.filter(
        (children) =>
          children.projectPhase === MAP_MD_PROJECT_PHASES_TO_MESSAGE_IDS[MdProjectPhaseEnum.study] ||
          children.projectPhase === MAP_MD_PROJECT_PHASES_TO_MESSAGE_IDS[MdProjectPhaseEnum.update]
      );

      result = uniq([
        ...result,
        hasStudyOrUpdateChildren ? row.key : null,
        ...getStudyOrUpdateChildrenKeys(row.children, result),
      ]);
    }
  });

  return result.filter(Boolean) as Guid[];
};

export type ColumnType = dxTreeListColumn & { dataField?: keyof MDGridData };

const MDApprovedProjectsReport: FunctionComponent<Props> = ({ organizationId, mdCurrentUser, initialProjectId }) => {
  const intl = useIntl();
  const history = useHistory();
  const [focusedKey, setFocusedKey] = useState<Guid>();
  const [newProjectVisible, showNewProjectModal, hideNewProjectModal] = useBoolean();
  const [selectedMdProjectId, setSelectedMdProjectId] = useState<Guid>(initialProjectId);
  const [initialPhaseValue, setInitialPhaseValue] = useState<MdProjectPhaseEnum>();
  const [intentionerId, setIntentionerId] = useState<Guid>();
  const [displayMode, setDisplayMode] = useState<MdDisplayMode>('justMine');

  const modesOptions = useMDProjectListDisplayMode(intl);

  const handleFocusRowChange = useCallback((data: MDGridData) => setFocusedKey(data?.id), []);

  const [mdRoles, mdRolesError, mdRolesLoading, loadMdRoles] = useApiData(
    (ct) => masterApi.projects.md.roles.id.get(organizationId, ct),
    { autoload: false, errorCallback: (err) => messageError(err, intl) }
  );
  const [projectsList, projectsError, projectsLoading, loadProjects] = useApiData(
    (ct) => masterApi.projects.md.projects.id.get(organizationId, ct),
    { autoload: true }
  );
  const [overheadProject, overheadProjectError, overheadProjectLoading] = useApiData(
    (ct) => masterApi.projects.md.settings.id.getoverhead.get(organizationId, ct),
    { autoload: true, errorCallback: (err) => messageError(err, intl) }
  );

  useEffect(() => {
    if (
      mdCurrentUser?.mdRoles.some(
        (role) => role.mdRoleType === MdRoleEnum.supervisor || role.mdRoleType === MdRoleEnum.proposer
      )
    ) {
      loadMdRoles();
    }
  }, [mdCurrentUser]);

  const hubProjectNames = useMemo(() => {
    return projectsList?.map((MdProject) => MdProject.project?.name) || [];
  }, [projectsList]);
  const projectsListWithUnpackedData = useMemo(() => projectsList?.map(mdProjectListDataUnpack), [projectsList]);

  const [flatData, setFlatData] = useState<MDGridData[]>();
  const [conditionModalData, setConditionModalData] = useState<MDCkApprovalCondition[]>();

  useMDSignal(organizationId);
  useMDProjectsChangedSignal(loadProjects);

  const isUserPreviewer = useMemo(
    () => !mdCurrentUser?.mdRoles.some((role) => role.mdRoleType !== MdRoleEnum.md_preview),
    [mdCurrentUser]
  );

  const isCKOrganizerRole = useMemo(
    () => mdCurrentUser?.mdRoles.some((role) => role.mdRoleType === MdRoleEnum.ck_organizer),
    [mdCurrentUser]
  );

  const isMdAdminRole = useMemo(() => mdCurrentUser?.mdRoles.some((role) => role.mdRoleType === MdRoleEnum.md_admin), [
    mdCurrentUser,
  ]);

  useEffect(() => {
    if (!!projectsList && !!projectsList.length) {
      const filteredData =
        displayMode === 'all' || isUserPreviewer
          ? projectsList
          : displayMode === 'justMyDivision'
          ? filterJustMyDivisionVisibleData(projectsList, mdCurrentUser)
          : filterJustMineVisibleData(projectsList, mdCurrentUser);
      setFlatData(mapListObjectsToFlatData(filteredData, intl));
    }
  }, [displayMode, intl, isUserPreviewer, mdCurrentUser, projectsList]);

  const handleProjectUpdate = useCallback(
    (updatedProject: MdProjectDto) => {
      loadProjects();
      hideNewProjectModal();
      setIntentionerId(undefined);
      setInitialPhaseValue(undefined);
    },
    [hideNewProjectModal, loadProjects]
  );

  const approvalProjectsColumns = useMemo(() => getApprovalProjectsColumns(intl), [intl]);

  const renderBooleanAsItemUpToDate = useCallback(
    (cellInfo: { value?: number; row: { data: MDGridData }; column: { dataField: 'commonUpdate' } }) => {
      if (!cellInfo.row.data) return null;
      const cellValue = cellInfo.row.data.isUpToDate;
      return <input type="checkbox" disabled checked={cellValue} />;
    },
    []
  );

  const renderBooleanAsItemAfterDeadline = useCallback(
    (cellInfo: { value?: number; row: { data: MDGridData }; column: { dataField: 'commonUpdate' } }) => {
      if (!cellInfo.row.data || cellInfo.row.data.isAfterDeadline === undefined) return null;
      const cellValue = cellInfo.row.data.isAfterDeadline;
      return <input type="checkbox" disabled checked={cellValue} />;
    },
    []
  );

  const renderBooleanPreparedForCkApproval = useCallback(
    (cellInfo: { value?: number; row: { data: MDGridData }; column: { dataField: 'commonUpdate' } }) => {
      if (!cellInfo.row.data || cellInfo.row.data.preparedForCkApproval === undefined) return null;
      const cellValue = cellInfo.row.data.preparedForCkApproval;
      return <input type="checkbox" disabled checked={cellValue} />;
    },
    []
  );

  const renderTotalCost = useCallback(
    (cellInfo: { value?: number; row: { data: MDGridData }; column: { dataField: string } }) => {
      if (!cellInfo.row.data || !cellInfo.row.data.totalCost) return null;
      const longFormatter = currencyLong(intl.locale, DEFAULT_CURRENCY);
      const value = cellInfo.row.data.totalCost;
      return isNumber(+value) ? (
        <CommonHubTooltip title={longFormatter(value)}>{`${round(+value / 1000000, 1)} mil. Kč`}</CommonHubTooltip>
      ) : null;
    },
    [intl]
  );

  const renderApprovalDate = useCallback(
    (cellInfo: { value?: number; row: { data: MDGridData }; column: { dataField: string } }) => {
      if (!cellInfo.row.data.ckApprovalDate) return null;

      return moment(cellInfo.row.data.ckApprovalDate)
        .locale(intl.locale)
        .format('L');
    },
    [intl]
  );

  const getFormattedRealizationTimes = useCallback(
    (realisationTime: string) => {
      if (!realisationTime) return null;
      return `${moment(realisationTime)
        .locale(intl.locale)
        .format('MM/YYYY')}`;
    },
    [intl]
  );

  const renderExpectedRealisationFrom = useCallback(
    (cellInfo: { value?: number; row: { data: MDGridData }; column: { dataField: string } }) => {
      if (!cellInfo.row.data) return null;

      return getFormattedRealizationTimes(cellInfo.row.data.expectedRealisationTimeFrom);
    },
    [getFormattedRealizationTimes]
  );

  const renderExpectedRealisationTo = useCallback(
    (cellInfo: { value?: number; row: { data: MDGridData }; column: { dataField: string } }) => {
      if (!cellInfo.row.data) return null;

      return getFormattedRealizationTimes(cellInfo.row.data.expectedRealisationTimeTo);
    },
    [getFormattedRealizationTimes]
  );

  const renderCkApprovalConditions = useCallback(
    (cellInfo: { value?: number; row: { data: MDGridData }; column: { dataField: string } }) => {
      if (!cellInfo.row.data || !cellInfo.row.data.ckApprovalConditions?.length) return null;
      const conditionsIntlMessageId = getCkConditionsMetStateIntlId(cellInfo.row.data.ckApprovalConditions);
      const tagColor =
        conditionsIntlMessageId === 'MD.MDCkConditionsEnum.conditionsMet'
          ? 'green'
          : conditionsIntlMessageId === 'MD.MDCkConditionsEnum.conditionsNotMet'
          ? 'red'
          : undefined;

      return (
        <Tag onClick={() => setConditionModalData(cellInfo.row.data.ckApprovalConditions)} color={tagColor}>
          {cellInfo.row.data.ckApprovalConditionsText}
        </Tag>
      );
    },
    []
  );

  const renderCkApprovalConditionsExpired = useCallback(
    (cellInfo: { value?: number; row: { data: MDGridData }; column: { dataField: string } }) => {
      if (!cellInfo.row.data || cellInfo.row.data.isAfterDeadline === undefined) return null;
      return cellInfo.row.data.isAfterDeadline;
    },
    []
  );

  const onRowDblClick = useCallback((e) => {
    if (e.rowType === 'data' && e.data) {
      const data = e.data as MDGridData;
      setSelectedMdProjectId(data.id);
    }
  }, []);

  const gridData = useMemo(
    () =>
      mapFlatDataToGridData(
        flatData?.filter((row) => !row.intentionerId),
        flatData
      ),
    [flatData]
  );

  const handleDeleteProject = useCallback(
    async (projectId: Guid) => {
      Modal.confirm({
        title: intl.formatMessage({ id: `MDApprovedProjectsReport.delete.title` }),
        content: intl.formatMessage({ id: 'MDApprovedProjectsReport.delete.message' }),
        onOk: async () => {
          void message.info(intl.formatMessage({ id: 'MDApprovedProjectsReport.deletingInProgress' }));
          const [err] = await masterApi.projects.md.project.delete.id.delete(projectId);
          if (err) {
            messageError(err, intl);
            return;
          }
          loadProjects();
        },
        okText: intl.formatMessage({ id: 'general.delete' }),
        cancelText: intl.formatMessage({ id: 'general.cancel' }),
      });
    },
    [intl, loadProjects]
  );

  const handleContextMenuCreateProject = useCallback(
    (intentionerId: Guid, initialPhaseValue: MdProjectPhaseEnum) => {
      setInitialPhaseValue(initialPhaseValue);
      setIntentionerId(intentionerId);
      showNewProjectModal();
    },
    [showNewProjectModal]
  );

  const addMenuItems = useCallback(
    (e: OnContextMenuPreparingType) => {
      const row: MDGridData = (e.row as any)?.data;
      if (e.target !== 'header' && !!row) {
        if (!e.items) e.items = [];
        // Add a custom menu item
        e.items.push({
          text: intl.formatMessage({ id: 'ApprovedProjectReport.menu.projectCard' }),
          onItemClick: () => setSelectedMdProjectId(row?.id),
        });
        row.id &&
          e.items.push({
            text: intl.formatMessage({ id: 'ApprovedProjectReport.menu.goToDMS' }),
            onItemClick: () => {
              if (!!row.documentationLink) {
                history.push(row.documentationLink);
              } else {
                void message.info(intl.formatMessage({ id: 'ProjectCreateFormModal.infoText.common' }));
              }
            },
          });
        if (
          row.projectPhase ===
          intl.formatMessage({ id: MAP_MD_PROJECT_PHASES_TO_MESSAGE_IDS[MdProjectPhaseEnum.study] })
        ) {
          e.items.push({
            text: intl.formatMessage({ id: 'ApprovedProjectReport.menu.newIntention' }),
            onItemClick: () => handleContextMenuCreateProject(row.id, MdProjectPhaseEnum.intention),
          });
        }
        if (
          row.projectPhase ===
            intl.formatMessage({ id: MAP_MD_PROJECT_PHASES_TO_MESSAGE_IDS[MdProjectPhaseEnum.init] }) ||
          row.projectPhase ===
            intl.formatMessage({ id: MAP_MD_PROJECT_PHASES_TO_MESSAGE_IDS[MdProjectPhaseEnum.intention] })
        ) {
          e.items.push({
            text: intl.formatMessage({ id: 'ApprovedProjectReport.menu.newActualization' }),
            onItemClick: () => handleContextMenuCreateProject(row.id, MdProjectPhaseEnum.update),
          });
        }
        if (row.projectState === MdProjectStateEnum.divisionSetting && isMdAdminRole) {
          e.items.push({
            text: intl.formatMessage({ id: 'ApprovedProjectReport.menu.deleteProject' }),
            onItemClick: () => {
              void handleDeleteProject(row.id);
            },
          });
        }
      }
    },
    [handleContextMenuCreateProject, handleDeleteProject, history, intl, isMdAdminRole]
  );

  const handleProjectDetailClose = () => {
    setSelectedMdProjectId(undefined);
    history.replace('/mdProjects');
  };

  const canCurrentUserCreateProject = useMemo(
    () => mdCurrentUser?.mdRoles.some((role) => role.mdRoleType === MdRoleEnum.proposer),
    [mdCurrentUser]
  );

  const defaultExpandedKeys = useMemo(() => {
    const expandedKeys: Guid[] = [];
    return getStudyOrUpdateChildrenKeys(gridData, expandedKeys);
  }, [gridData]);

  return (
    <StackPanel vertical stretch className={styles.reportPanel}>
      <div className={styles.buttonToolbar}>
        {!isUserPreviewer && (
          <ButtonsSelect<MdDisplayMode>
            onChange={setDisplayMode}
            selectedKey={displayMode}
            options={modesOptions}
            size="small"
          />
        )}
        <Button
          type="link"
          icon={<FileAddOutlined />}
          onClick={showNewProjectModal}
          disabled={!canCurrentUserCreateProject}
        >
          <Fmt id="general.add" />
        </Button>
        {isCKOrganizerRole && (
          <MDProjectApprovalButtons
            mdProjectList={projectsListWithUnpackedData}
            organizationId={organizationId}
            overheadProjectId={overheadProject?.id}
            mdUserRoles={mdCurrentUser.mdRoles}
            reloadProjectList={loadProjects}
          />
        )}
      </div>
      <MinMaxContainer offsetPx={10} itemsCount={gridData?.length}>
        <StackPanel stretch className={styles.reportWrapper}>
          <ContentGate loading={!projectsList && projectsLoading} error={projectsError}>
            <BudgetGrid
              data={gridData}
              columns={approvalProjectsColumns}
              focusedRowKey={focusedKey}
              onFocusedRowChanged={handleFocusRowChange}
              clearFocusOnSearchChange
              onContextMenuPreparing={addMenuItems}
              onRowDblClick={onRowDblClick}
              getRowClass={getMdGridRowStyle}
              defaultExpandedKeys={defaultExpandedKeys}
            >
              <HeaderFilter visible={true} />
              <FilterRow visible={true} />
              <Template name="totalCost" render={renderTotalCost} />
              <Template name="ckApprovalDate" render={renderApprovalDate} />
              <Template name="expectedRealisationTimeFrom" render={renderExpectedRealisationFrom} />
              <Template name="expectedRealisationTimeTo" render={renderExpectedRealisationTo} />
              <Template name="ckApprovalConditions" render={renderCkApprovalConditions} />
              <Template name="ckApprovalConditionsExpired" render={renderCkApprovalConditionsExpired} />
              <Template name="booleanToIconUpToDate" render={renderBooleanAsItemUpToDate} />
              <Template name="booleanToIconAfterDeadline" render={renderBooleanAsItemAfterDeadline} />
              <Template name="preparedForCkApproval" render={renderBooleanPreparedForCkApproval} />
            </BudgetGrid>
          </ContentGate>
        </StackPanel>
      </MinMaxContainer>
      {newProjectVisible && (
        <MDProjectCreateFormModal
          organizationId={organizationId}
          intentionerId={intentionerId}
          creator={mdCurrentUser}
          onSubmit={handleProjectUpdate}
          open={newProjectVisible}
          onClose={() => {
            hideNewProjectModal();
            setInitialPhaseValue(undefined);
          }}
          mdRoles={mdRoles}
          initialPhaseValue={initialPhaseValue}
          hubProjectNames={hubProjectNames}
        />
      )}
      {selectedMdProjectId && (
        <MDProjectCardModal
          organizationId={organizationId}
          mdProjectId={selectedMdProjectId}
          open={!!selectedMdProjectId}
          onClose={handleProjectDetailClose}
          onProjectUpdate={handleProjectUpdate}
          mdRoles={mdRoles}
          mdProjects={projectsListWithUnpackedData}
          width={PROJECT_CARD_WIDTH}
          mdCurrentUser={mdCurrentUser}
          overheadProjectId={overheadProject?.id}
          loading={mdRolesLoading || overheadProjectLoading}
        />
      )}
      {conditionModalData && (
        <MDCkConditionModal ckApprovalConditions={conditionModalData} onOK={() => setConditionModalData(undefined)} />
      )}
    </StackPanel>
  );
};

export default React.memo(MDApprovedProjectsReport);
