import { Form, Input } from 'antd';
import { masterApi } from 'api/completeApi';
import { MdProjectDto, MdProjectStateEnum } from 'api/completeApiInterfaces';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { useState } from 'react';
import { messageError } from 'utils';
import { requiredRule } from 'utils/formHelpers';
import { modalConfirm } from 'utils/modalConfirm';

type Props = FormModalProps<MdProjectDto> & { mdProjectId: Guid; newState: MdProjectStateEnum };

export type MDReturnMaterialFormData = {
  justification: string;
};

const MAP_TARGET_STATE_TO_TITLE_MESSAGE_ID: Partial<Record<MdProjectStateEnum, IntlMessageId>> = {
  [MdProjectStateEnum.entering]: 'MD.Projects.ProjectCardModal.button.returnToSubmitter',
  [MdProjectStateEnum.divisionSetting]: 'MD.Projects.ProjectCardModal.button.returnToO910',
};

const MAP_TARGET_STATE_TO_CONFIRM_MESSAGE_ID: Partial<Record<MdProjectStateEnum, IntlMessageId>> = {
  [MdProjectStateEnum.entering]: 'MDReturnMaterialModal.returnToSubmitter.confirmText',
  [MdProjectStateEnum.divisionSetting]: 'MDReturnMaterialModal.returnToO910.confirmText',
};

const MDReturnMaterialFormModal: React.FC<Props> = ({ mdProjectId, onClose, onSubmit, newState, ...modalProps }) => {
  const intl = useIntl();

  const [isLoading, setLoading] = useState(false);

  const handleSubmit: FormSubmitHandler<MDReturnMaterialFormData> = async (values) => {
    const isConfirmed = await modalConfirm({
      title: `${intl.formatMessage({ id: MAP_TARGET_STATE_TO_TITLE_MESSAGE_ID[newState] })}?`,
      content: (
        <>
          <div>{intl.formatMessage({ id: MAP_TARGET_STATE_TO_CONFIRM_MESSAGE_ID[newState] })}</div>
          <div>{values.justification}</div>
        </>
      ),
      okText: intl.formatMessage({ id: 'general.yes' }),
      cancelText: intl.formatMessage({ id: 'general.no' }),
    });
    if (!isConfirmed) return null;
    setLoading(true);
    const [err, res] = await masterApi.projects.md.project.setstate.post({
      projectId: mdProjectId,
      requestedState: newState,
      justification: values.justification,
    });

    if (err) {
      messageError(err, intl);
      setLoading(false);
      onClose();
      return err;
    }
    onSubmit(res.data);
    setLoading(false);
    onClose();
    return null;
  };

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      title={<Fmt id={MAP_TARGET_STATE_TO_TITLE_MESSAGE_ID[newState]} />}
      okButtonProps={{ loading: isLoading }}
      submitTextId="general.return"
      open={!!newState}
      onClose={onClose}
      {...modalProps}
    >
      <Form.Item
        label={<Fmt id="general.justification" />}
        name="justification"
        rules={[requiredRule('forms.items.rules.required')]}
      >
        <Input.TextArea showCount maxLength={1000} />
      </Form.Item>
    </FormModalWrapper>
  );
};

export default MDReturnMaterialFormModal;
