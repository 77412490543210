import { Empty } from 'antd';
import { api } from 'api';
import { MsgCategoryEnum, MsgCategoryTypeEnum, MsgStatusEnum } from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { useApiData, useIsMounted } from 'hooks';
import { Fmt } from 'locale';
import { Dictionary } from 'lodash';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useDebounce } from 'react-use';
import { MARK_AS_READ_DELAY } from '../Constants';
import { MessagesReducerActions } from '../tabs/MessageCenterMessages';
import { ReportsReducerActions } from '../tabs/MessageCenterReports';
import styles from './MessageDetail.module.less';
import { ProjectMessageNames } from './MessageGrid';
import { AppShareMessageViewer, ShareMessageData } from './viewers/AppShareMessageViewer';
import { AssignmentMessageData, AssignmentMessageViewer } from './viewers/AssignmentMessageViewer';
import { BulkChangesMessageData, BulkChangesMessageViewer } from './viewers/BulkChangesMessageViewer';
import { CommentProcedureMessageData, CommentProcedureMessageViewer } from './viewers/CommentProcedureMessageViewer';
import { DocumentMessageData, DocumentMessageViewer } from './viewers/DocumentMessageViewer';
import { ProjectAccountMessageData, ProjectAccountMessageViewer } from './viewers/ProjectAccountMessageViewer';
import { SubsciberMessageViewer, SubscriberMessageData } from './viewers/SubscriberMessageViewer';
import { UserNotificationData, UserNotificationViewer } from './viewers/UserNotificationViewer';
import { WorkflowMessageData, WorkflowMessageViewer } from './viewers/WorkflowMessageViewer';

type ViewerDataTypes =
  | WorkflowMessageData
  | CommentProcedureMessageData
  | ShareMessageData
  | AssignmentMessageData
  | BulkChangesMessageData
  | SubscriberMessageData
  | ProjectAccountMessageData
  | DocumentMessageData
  | UserNotificationData;

type Props = {
  // message: MessageListDto;
  messageId: Guid;
  projectNames: Dictionary<ProjectMessageNames>;
  setMessageState: (selectedMessages: Guid[], status: MsgStatusEnum) => Promise<boolean>;
  messagesDispatch?: React.Dispatch<MessagesReducerActions | ReportsReducerActions>;
};

type MessageViewerProps = {
  category: MsgCategoryEnum;
  messageData: ViewerDataTypes;
  projectName: string;
  organizationName: string;
  projectId: Guid;
};
export type MessageViewer = FunctionComponent<MessageViewerProps>;

export const messageDetailUrlLinkParse = (link: string): string => {
  const linkUrl = new URL(link);
  return linkUrl.pathname;
};

const MessageDetailViewers: Record<MsgCategoryTypeEnum, MessageViewer> = {
  workflow: WorkflowMessageViewer,
  commentProcedure: CommentProcedureMessageViewer,
  appFileShare: AppShareMessageViewer,
  assignment: AssignmentMessageViewer,
  bulkAction: BulkChangesMessageViewer,
  alert: undefined, // TODO
  subscriber: SubsciberMessageViewer,
  accountNotification: ProjectAccountMessageViewer,
  document: DocumentMessageViewer,
  demandMsg: UserNotificationViewer,
};

export const MessageDetail: FunctionComponent<Props> = (props) => {
  const { messageId, projectNames, setMessageState, messagesDispatch } = props;
  const isMounted = useIsMounted();

  const [messageDetail, messageDetailError, messageDetailLoading, loadMessageDetail, setMessageDetail] = useApiData(
    (ct) => api.master.messageCenter.getMessageDetail(messageId, ct),
    { autoload: false }
  );

  const Viewer = MessageDetailViewers[messageDetail?.categoryType];

  useEffect(() => {
    messageId ? loadMessageDetail() : setMessageDetail(undefined);
  }, [messageId]);

  useDebounce(
    async () => {
      if (!messageDetail || messageDetail.status !== MsgStatusEnum.new) return;

      if (await setMessageState([messageId], MsgStatusEnum.read)) {
        if (!isMounted.current) {
          return;
        }

        messagesDispatch({
          type: 'setMessagesState',
          messages: [messageId],
          state: MsgStatusEnum.read,
        });
      }
    },
    MARK_AS_READ_DELAY,
    [messageDetail]
  );

  const projectName = useMemo(() => projectNames[messageDetail?.projectId], [messageDetail?.projectId, projectNames]);
  return (
    <div className={styles.content}>
      <ContentGate loading={messageDetailLoading} error={messageDetailError}>
        {messageDetail ? (
          Viewer ? (
            <Viewer
              key={messageDetail.id} // Force messages to render independently (e.g. do not remember which audit logs are unfolded)
              category={messageDetail.category}
              messageData={messageDetail.data as ViewerDataTypes}
              projectName={projectName?.name}
              projectId={messageDetail.projectId}
              organizationName={projectName?.organizationName}
            />
          ) : (
            <div className={styles.error}>
              <Fmt id="MessageCenterPage.message.detail.error.viewer" />
            </div>
          )
        ) : (
          <Empty className={styles.empty} />
        )}
      </ContentGate>
    </div>
  );
};
