import { useStoreSelector } from 'hooks';
import { orderTree } from 'utils/tree/treeHelpers';

export const useActiveProjectProjectCardData = () =>
  useStoreSelector((state) => {
    const projectCardData = state.activeProject.activeProject?.projectCard?.projectCardData;
    if (!projectCardData) {
      return null;
    }
    return orderTree(projectCardData);
  });
