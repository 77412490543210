import { ProjectCardDocumentationLevelEnum } from 'api/completeApiInterfaces';
import {
  createSingleSelectFilterFunction,
  IOption,
  SELECT_CHECK_FORMAT,
  SELECT_CLEARED_VALUE,
  SELECT_DEFAULT_VALUE,
  SELECT_IS_EMPTY,
  SelectFilter,
  SelectFilterValue,
} from 'components/filters/components/SelectFilter/SelectFilter';
import { BackendFilter, CommonFilter, FrontendFilter } from 'components/filters/filterTypes';
import { Fmt, InjectedIntlProps, memoizeWithIntl } from 'locale';
import React, { FunctionComponent, useMemo } from 'react';
import { checkObject } from 'utils';
import {
  PROJECT_DOCUMENTATION_LEVELS,
  PROJECT_DOCUMENTATION_LEVELS_TRANSLATIONS,
} from 'utils/typeMappings/documentationLevels/documentationLevelsTypes';

type Props = InjectedIntlProps & {
  value: SelectFilterValue<Guid>;
  onChange: React.Dispatch<React.SetStateAction<SelectFilterValue<Guid>>>;
};

const DocumentationLevelFilterComponent: FunctionComponent<Props> = ({ intl, value, onChange }) => {
  const levelsOptions = useMemo<IOption<ProjectCardDocumentationLevelEnum>[]>(
    () =>
      PROJECT_DOCUMENTATION_LEVELS.map((state: ProjectCardDocumentationLevelEnum) => ({
        id: state,
        title: intl.formatMessage({ id: PROJECT_DOCUMENTATION_LEVELS_TRANSLATIONS[state] }),
      })),
    [intl]
  );

  return (
    <SelectFilter
      label={<Fmt id="general.documentationLevel" />}
      value={value}
      onChange={onChange}
      options={levelsOptions}
      showSearchMinItems={PROJECT_DOCUMENTATION_LEVELS.length + 1} // Do not show search
    />
  );
};

export const DocumentationLevelFilter = memoizeWithIntl(DocumentationLevelFilterComponent);

const createCommonDocumentationLevelFilter = (
  key: string
): CommonFilter<SelectFilterValue<ProjectCardDocumentationLevelEnum>> => ({
  key,
  render: (value, setValue) => <DocumentationLevelFilter value={value} onChange={setValue} />,
  isEmpty: SELECT_IS_EMPTY,
  defaultValue: SELECT_DEFAULT_VALUE([]),
  clearedValue: SELECT_CLEARED_VALUE,
  checkFormat: checkObject(SELECT_CHECK_FORMAT),
});

export const createFrontendDocumentationLevelFilter = <T,>(
  key: string,
  valueExtractor: (item: T) => ProjectCardDocumentationLevelEnum
): FrontendFilter<T, SelectFilterValue<ProjectCardDocumentationLevelEnum>> => ({
  ...createCommonDocumentationLevelFilter(key),
  filter: createSingleSelectFilterFunction(valueExtractor),
});

export const createBackendDocumentationLevelFilter = <T,>(
  key: string,
  serialize: (accumulator: T, value: SelectFilterValue<ProjectCardDocumentationLevelEnum>) => T
): BackendFilter<T, SelectFilterValue<ProjectCardDocumentationLevelEnum>> => ({
  ...createCommonDocumentationLevelFilter(key),
  serialize,
});
