import { masterApi } from 'api/completeApi';
import { AppUserDto, OrgUserRequestDto, OrgUsersRequestDto } from 'api/completeApiInterfaces';
import { UserAttributes, UserSourceEnum } from 'components/forms/AppUsersAddFormModal/AppUsersFormItem';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import ProjectUserAddForm, { ProjectUserAddFormData } from 'components/forms/ProjectUsersAddForm/ProjectUserAddForm';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent } from 'react';

type Props = FormModalProps<OrgUserRequestDto[]> & {
  organisationUsers: AppUserDto[];
  titleId: IntlMessageId;
  organizationId: Guid;
};

const mapUserWithAttributesToDto = ([user, attributes]: [string, UserAttributes]): OrgUserRequestDto => ({
  mail: user,
  language: attributes.defaultLanguage,
  isAdmin: attributes.isAdmin,
  namesFromOrganization: undefined,
  firstname: undefined,
  lastname: undefined,
});

const ProjectUsersAddFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, organisationUsers, titleId, organizationId, ...restProps } = props;

  const handleSubmit: FormSubmitHandler<ProjectUserAddFormData> = async (values) => {
    const data: OrgUsersRequestDto = {
      users: Object.entries(values.usersToAddWithAttributes)
        .filter(([mail, attributes]) => attributes.source === UserSourceEnum.new)
        .map(mapUserWithAttributesToDto),
      organizationId,
    };

    const [err] = await masterApi.projects.admin.setorgusers.post(data);
    if (err) return err;
    onSubmit(Object.entries(values.usersToAddWithAttributes).map(mapUserWithAttributesToDto));
    return null;
  };

  return (
    <FormModalWrapper onSubmit={handleSubmit} titleId={titleId} submitTextId="general.add" width={700} {...restProps}>
      <ProjectUserAddForm
        toAddAppUsers={organisationUsers}
        groupsList={[]}
        existUsersOnly={false}
        requestEndpoint={'organization'}
      />
    </FormModalWrapper>
  );
};

export default ProjectUsersAddFormModal;
