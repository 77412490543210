import { Form } from 'antd';
import { OrganizationPOHReportSettingDto } from 'api/completeApiInterfaces';
import { DatePickerWithHolidays } from 'components/CalendarWithHolidays/DatePickerWithHolidays';
import ColorPickerInput from 'components/ColorPickerInput';
import { useIntl } from 'hooks';
import moment from 'moment';
import React, { FC } from 'react';
import { ReportUnitColorAssign } from './ReportUnitColorAssign';

type Props = {
  defaults: OrganizationPOHReportSettingDto;
  organizationId: Guid;
};

const ProjectsInRealizationPohReportSettingsFormComponent: FC<Props> = ({ defaults, organizationId }) => {
  const intl = useIntl();

  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: 'ProjectsInRealizationPohReportSettingsForm.defaultDate' })}
        name="defaultReportDate"
        initialValue={moment(defaults.defaultReportDate) || undefined}
      >
        <DatePickerWithHolidays allowClear />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'ProjectsInRealizationPohReportSettingsForm.completedRealizationColor' })}
        name="completeRealizationColor"
        initialValue={defaults.completeRealizationColor}
      >
        <ColorPickerInput />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'ProjectsInRealizationPohReportSettingsForm.unitColors' })}
        name="unitSettings"
        initialValue={defaults.unitSettings}
      >
        <ReportUnitColorAssign intl={intl} organizationId={organizationId} />
      </Form.Item>
    </>
  );
};

export const ProjectsInRealizationPohReportSettingsForm = React.memo(
  ProjectsInRealizationPohReportSettingsFormComponent
);
