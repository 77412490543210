import { DoubleRightOutlined } from '@ant-design/icons';
import { projectApi } from 'api/completeApi';
import { ProjectCardDocumentationLevelEnum, ProjectDto } from 'api/completeApiInterfaces';
import GeneralSelectSettingsItem from 'components/GeneralSelectSettingsItem/GeneralSelectSettingsItem';
import { useIntl } from 'hooks';
import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import { processApiError } from 'utils';
import { PROJECT_DOCUMENTATION_LEVELS_TRANSLATIONS } from 'utils/typeMappings/documentationLevels/documentationLevelsTypes';

type Props = {
  project: ProjectDto;
  setActiveProject: (project: ProjectDto) => void;
  disableEdit?: boolean;
};

export const DocumentationLevelItem: FC<Props> = ({ project, disableEdit, setActiveProject }) => {
  const intl = useIntl();

  const documentationLevelOptions = useMemo(() => {
    return Object.entries(PROJECT_DOCUMENTATION_LEVELS_TRANSLATIONS).map(([key, value]) => ({
      value: key,
      text: intl.formatMessage({ id: value }),
    }));
  }, [intl]);

  const onEditDocumentationLevel = useCallback(
    async (documentationLevel: ProjectCardDocumentationLevelEnum): Promise<boolean | ReactNode> => {
      if (documentationLevel !== project.projectCard?.documentationLevel) {
        const [err] = await projectApi.card.patch({
          ...project.projectCard,
          documentationLevel,
        });
        if (!err) {
          setActiveProject({ ...project, projectCard: { ...project.projectCard, documentationLevel } });
          return true;
        } else {
          return intl.formatMessage({
            id: `serviceError.${processApiError(err).referenceErrorCode}`,
          });
        }
      }
      return true;
    },
    [setActiveProject, project, intl]
  );

  return (
    <GeneralSelectSettingsItem
      icon={<DoubleRightOutlined rotate={-90} />}
      value={project.projectCard?.documentationLevel}
      onSave={onEditDocumentationLevel}
      headline={intl.formatMessage({ id: 'ProjectDashboard.ProjectCard.documentationLevel' })}
      options={documentationLevelOptions}
      disableEdit={disableEdit}
      showSearch
    />
  );
};
