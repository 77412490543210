import { Button, Form, Modal, ModalProps, Progress, Typography } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { ServiceError } from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import Axios from 'axios';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import DisplayName from 'components/DisplayName';
import { FlowLayout } from 'components/layouts/FlowLayout';
import ServiceErrorBox from 'components/ServiceErrorBox';
import { DEBUG } from 'config/env';
import { useIntl, useIsMounted } from 'hooks';
import { useCloseOnEscape } from 'hooks/useCloseOnEscape';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { modalFormConfirmClose, processApiError } from 'utils';
import styles from './FormModalWrapper.module.less';

// TODO: refactor this function, move it to utils
export const parseErrorMessage = (error: ApiError | null): ReactNode => {
  if (!error) return null;
  const processed = processApiError(error);
  return <Fmt id={`serviceError.${processed.referenceErrorCode}`} />;
};

const EMPTY_FUNCTION = () => {};

export type FormSubmitHandler<T = any> = (values: T) => Promise<ApiError>;

type Props = Omit<ModalProps, 'onCancel' | 'okText' | 'cancelText'> & {
  onSubmit: FormSubmitHandler;
  onClose?: () => void;
  children: ReactNode;
  additionalChildren?: ReactNode;
  title?: ReactNode | string;
  titleId?: IntlMessageId;
  submitTextId?: IntlMessageId;
  submitTextLoadingId?: IntlMessageId;
  cancelTextId?: IntlMessageId;
  confirmClose?: boolean;
  progress?: number;
  showProgress?: boolean;
  forceLoading?: boolean;
  errorMessage?: ReactNode;
  onSubmitDisabled?: boolean;
  layout?: 'vertical' | 'horizontal';
  form?: FormInstance;
};

export const FormModalWrapper: FunctionComponent<Props> = (props) => {
  const {
    title,
    titleId,
    submitTextId = 'forms.button.submit',
    submitTextLoadingId = 'forms.button.submit.loading',
    cancelTextId = 'forms.button.cancel',
    progress,
    showProgress,
    errorMessage,
    forceLoading,
    onSubmit,
    onClose = EMPTY_FUNCTION,
    confirmClose = false,
    children,
    additionalChildren,
    onSubmitDisabled,
    layout = 'vertical',
    open,
    okButtonProps,
    form: formInstance,
    ...restModalProps
  } = props;
  const intl = useIntl();
  const isMountedRef = useIsMounted();
  const [form] = Form.useForm(formInstance);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ServiceError>(null);

  useEffect(() => {
    form.resetFields();
  }, [open]);

  function handleSubmit() {
    form
      .validateFields()
      .then((values: any) => {
        if (isMountedRef.current) setLoading(true);
        onSubmit(values)
          .then((apiError) => {
            if (!isMountedRef.current) return;
            if (apiError) {
              if (!Axios.isCancel(apiError)) {
                processApiError(apiError, handleError);
              }
            } else {
              setError(null);
              setLoading(false);
            }
          })
          .catch((err) => {
            if (isMountedRef.current) setLoading(false);
          });
      })
      .catch((err) => {
        DEBUG && console.error('Form validation error', err);
        if (isMountedRef.current) setLoading(false);
      });
  }

  function handleError(error: ServiceError) {
    setError(error);
    setLoading(false);
  }

  const inConfirmationRef = useRef<boolean>(false);

  const handleClose = useCallback(() => {
    setError(null);
    onClose();
  }, [onClose]);

  const handleCancel = useCallback(() => {
    if (inConfirmationRef.current) return;
    if (confirmClose !== undefined && !confirmClose) {
      handleClose();
      return;
    }

    if (form.isFieldsTouched()) {
      inConfirmationRef.current = true;
      modalFormConfirmClose(
        intl,
        () => {
          inConfirmationRef.current = false;
          handleClose();
        },
        () => {
          inConfirmationRef.current = false;
        }
      );
    } else {
      handleClose();
    }
  }, [handleClose, confirmClose]);

  const _title = (
    <div className={styles.elipsis}>{title ? title : titleId ? intl.formatMessage({ id: titleId }) : ''}</div>
  );

  const titleWithTooltip = (
    <CommonHubTooltip placement="topLeft" title={_title}>
      {_title}
    </CommonHubTooltip>
  );

  const footer = (
    <FlowLayout alignRight>
      {!errorMessage && ((loading && progress) || showProgress) ? (
        <Progress
          style={{ flex: 1 }}
          percent={progress}
          status={progress === 100 ? 'success' : loading ? 'active' : 'normal'}
        />
      ) : !!errorMessage ? (
        <Typography.Text type="danger" style={{ flex: 1, textAlign: 'left' }}>
          {errorMessage}
        </Typography.Text>
      ) : (
        <div style={{ flex: 1 }} />
      )}

      <Button onClick={handleCancel}>{intl.formatMessage({ id: cancelTextId })}</Button>
      <Button
        type="primary"
        loading={loading || forceLoading}
        onClick={handleSubmit}
        disabled={onSubmitDisabled}
        {...okButtonProps}
      >
        {intl.formatMessage({
          id: (loading || forceLoading) && submitTextLoadingId ? submitTextLoadingId : submitTextId,
        })}
      </Button>
    </FlowLayout>
  );

  useCloseOnEscape(open, handleCancel);

  return (
    <Modal
      open={open}
      title={<DisplayName className={styles.main}>{titleWithTooltip}</DisplayName>}
      footer={footer}
      forceRender
      {...restModalProps}
      onCancel={handleCancel}
    >
      {!!error && <ServiceErrorBox error={error} />}
      {open && (
        <>
          <Form form={form} layout={layout}>
            {children}
          </Form>
          {additionalChildren}
        </>
      )}
    </Modal>
  );
};
