import { Pie as PieChart } from '@ant-design/charts';
import { PieConfig } from '@ant-design/charts/es/pie';
import { Pie as G2plotPie } from '@antv/g2plot';
import { ProjectCardDocumentationLevelEnum, ProjectListDto } from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { ChartFormatTypeEnum, exportConfig } from 'components/ProjectWidgets/commonExport';
import { ExportStateProps } from 'components/ProjectWidgets/commonTypes';
import { useIntl, useSelectorDispatch } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { InjectedIntl } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import { groupBy } from 'lodash';
import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { percentWhole } from 'utils/formatters';
import styles from './ProjectDocumentationStatisticCharts.module.less';

type ChardData = { name: string; value: number; color: string; intlId: IntlMessageId };

const CHART_COLORS: string[] = [
  '#193d71',
  '#54c51d',
  '#003f5c',
  '#f47a1f',
  '#377b2b',
  '#00529B',
  '#ff402c',
  '#278ecf',
  '#ffca1f',
  '#d42ae8',
  '#a93226',
  '#a93226 ',
];

const formatCartData = (projects: ProjectListDto[]): ChardData[] => {
  if (!projects) return undefined;
  const groupedProjects = groupBy(
    projects,
    (project) => project.projectCard?.documentationLevel || ProjectCardDocumentationLevelEnum.undefined
  );

  return Object.entries(groupedProjects).map(
    ([documentation, projects]: [ProjectCardDocumentationLevelEnum, ProjectListDto[]], index): ChardData => ({
      name: documentation,
      value: projects.length,
      color: CHART_COLORS[index],
      intlId: `DocumentationLevelFilter.${documentation}`,
    })
  );
};

function getConfig(data: ChardData[], intl: InjectedIntl): PieConfig {
  if (!data || !data.length) {
    return undefined;
  }
  const total = data.reduce((prev, curr) => prev + curr.value, 0);
  const percentFormatter = percentWhole(intl.locale);

  return {
    animation: false,
    forceFit: true,
    data,
    padding: [0, 120, 0, 0],
    angleField: 'value',
    colorField: 'name',
    color: data.map((d) => d.color),
    label: {
      visible: true,
      formatter: (value: number) => (!!total ? percentFormatter(value / total) : ''),
      style: {
        fill: '#101010',
        fontSize: 15,
        fontWeight: 600,
      },
    },
    tooltip: {
      showTitle: false,
      formatter: (value: number, name: string) => {
        const item: ChardData = data.find((e) => e.name === name);
        return {
          name: intl.formatMessage({ id: item.intlId }),
          value: item.value,
        };
      },
    },
    legend: {
      offsetX: -30,
      text: {
        style: {
          fontSize: 16,
          fontWeight: 600,
        },
        formatter: (name: string) => {
          return (
            intl.formatMessage({ id: data.find((e) => e.name === name).intlId }) +
            ': ' +
            data.find((d) => d.name === name).value
          );
        },
      },
    },
  } as PieConfig;
}

type Props = ExportStateProps & {
  chartRef?: React.MutableRefObject<G2plotPie | undefined>;
};

const ProjectDocumentationStatisticReportChart = ({ chartRef, exportTo }: Props) => {
  const intl = useIntl();
  const projects = useSelectorDispatch(
    (selector) => selector.allProjects,
    (dispatch) => dispatch.allProjects.loadData({ reload: false })
  );

  useDirtyStoreReload(
    (store) => store.allProjects,
    (dispatch) => dispatch.allProjects
  );

  const config: PieConfig = useMemo(() => getConfig(formatCartData(projects.data?.projects), intl), [
    projects.data?.projects,
    intl,
  ]);

  return (
    <ContentGate loading={projects.loading}>
      {!!projects?.data && !!config && (
        <>
          <div className={styles.chart}>
            <PieChart key={intl.locale} {...config} />
          </div>
          {exportTo && (
            <PieChart
              {...config}
              {...exportConfig}
              renderer={exportTo === ChartFormatTypeEnum.svg ? 'svg' : 'canvas'}
              chartRef={chartRef}
            />
          )}
        </>
      )}
    </ContentGate>
  );
};

export default React.memo(injectIntl(ProjectDocumentationStatisticReportChart));
