import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import { useIsMounted, useSameCallback, useSelectorDispatch } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import React, { FunctionComponent, useState } from 'react';
import { projectsListSelector } from 'store/selectors';
import {
  ProjectTemplateSelectSourceProjectForm,
  ProjectTemplateSelectSourceProjectFormData,
} from './ProjectTemplateSelectSourceProjectForm';

type Props = FormModalProps<ProjectTemplateSelectSourceProjectFormData> & {
  organizationId: Guid;
};

const ProjectTemplateSelectSourceProjectFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, organizationId, ...restProps } = props;
  const [saving, setSaving] = useState<boolean>();
  const isMounted = useIsMounted();

  const projectsList = useSelectorDispatch(projectsListSelector, (dispatch) =>
    dispatch.allProjects.loadData({ reload: false })
  );

  useDirtyStoreReload(
    (state) => state.allProjects,
    (dispatch) => dispatch.allProjects
  );

  const handleSubmit: FormSubmitHandler<ProjectTemplateSelectSourceProjectFormData> = useSameCallback(
    async (values) => {
      setSaving(true);
      await onSubmit(values);
      if (isMounted.current) {
        setSaving(false);
      }
      return null;
    }
  );

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId={'ProjectTemplateSelectSourceProject.title'}
      submitTextId={'general.template.create'}
      forceLoading={saving}
      width={700}
      {...restProps}
    >
      <ProjectTemplateSelectSourceProjectForm projectsList={projectsList} organizationId={organizationId} />
    </FormModalWrapper>
  );
};

export default ProjectTemplateSelectSourceProjectFormModal;
