import { InputRef } from 'antd';
import { api } from 'api';
import { StartWopiResponseDto, WorkflowStateEnum } from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler, parseErrorMessage } from 'components/forms/FormModalWrapper';
import { useCancelToken } from 'hooks';
import { useFocus } from 'hooks/useFocus';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useState } from 'react';
import DocumentOnlineEditorRevisionCreateForm, {
  DocumentOnlineEditorRevisionCreateFormData,
} from './DocumentOnlineEditorRevisionCreateForm';

type Props = FormModalProps<StartWopiResponseDto> & {
  documentId: Guid;
  isOldRevision: boolean;
  allowedStates?: WorkflowStateEnum[];
};

const DocumentOnlineEditorRevisionCreateFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, onClose, documentId, isOldRevision, allowedStates, ...restProps } = props;
  const [error, setError] = useState<ApiError>(null);
  const apiCancelToken = useCancelToken('Online document closed', []);

  const handleSubmit: FormSubmitHandler<DocumentOnlineEditorRevisionCreateFormData> = useCallback(
    async (values) => {
      const [error, data] = await api.project.wopi.addRevision(
        { documentId: documentId, state: values.state, description: values.description },
        apiCancelToken
      );

      if (!!error) {
        setError(error);
        return null;
      }

      onSubmit && (await onSubmit(data.data));
      return null;
    },
    [onSubmit]
  );

  const handleClose = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  const { setInputRef } = useFocus<InputRef>(restProps.open);

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      onClose={handleClose}
      errorMessage={parseErrorMessage(error)}
      title={<Fmt id="RevisionCreateFormModal.title" />}
      {...restProps}
    >
      <DocumentOnlineEditorRevisionCreateForm
        isOldRevision={isOldRevision}
        setRef={setInputRef}
        allowedStates={allowedStates}
      />
    </FormModalWrapper>
  );
};

export default DocumentOnlineEditorRevisionCreateFormModal;
