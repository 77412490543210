import { Button, Col, message, Modal, ModalProps, Row } from 'antd';
import Select, { DefaultOptionType } from 'antd/lib/select';
import Tree, { DataNode } from 'antd/lib/tree';
import { masterApi } from 'api/completeApi';
import { EstiConUtvaryStructureDto } from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import DisplayName from 'components/DisplayName';
import { Margin } from 'components/Margin/Margin';
import StackPanel from 'components/StackPanel';
import { useApiData, useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, ReactNode, useEffect, useMemo, useState } from 'react';
import styles from './ReportUnitSettingsModal.module.less';

type Props = Omit<ModalProps, 'onCancel' | 'onOk' | 'okButtonProps' | 'cancelButtonProps' | 'footer'> & {
  organizationId: Guid;
  onUnitSettingsDisplay: (unit: EstiConUtvaryStructureDto) => ReactNode;
  onClose: () => void;
};

const mapEsticonUnitsToData = (
  esticonUnits: EstiConUtvaryStructureDto[],
  onUnitSettingsDisplay: (unit: EstiConUtvaryStructureDto) => ReactNode
): DataNode[] =>
  esticonUnits
    ?.sort((a, b) => a.sign.localeCompare(b.sign))
    .map((unit) => ({
      key: unit.id,
      title: (
        <StackPanel>
          <DisplayName className={styles.permissionName}>
            {unit.sign} - {unit.name}
          </DisplayName>
          <div className={styles.permission}>{onUnitSettingsDisplay(unit)}</div>
        </StackPanel>
      ),
      children: mapEsticonUnitsToData(unit.structure, onUnitSettingsDisplay),
    })) || [];

const ReportUnitSettingsModal: FunctionComponent<Props> = (props) => {
  const { organizationId, onUnitSettingsDisplay, onClose, ...restProps } = props;
  const [selectedEsticonConnId, setSelectedEsticonConnId] = useState<Guid>();
  const [selectedEsticonFirmId, setSelectedEsticonFirmId] = useState<Guid>();
  const intl = useIntl();

  const [orgEsticonReport, orgEsticonReportError, orgEsticonReportLoading, loadOrgEsticonReport] = useApiData(
    (ct) => masterApi.projects.reports.firmlist.post({ organizationId: organizationId }, ct),
    {
      fetchCallback: (data) =>
        data.esticonConns?.some((conn) => conn.esticonFirms == null) &&
        message.warning(intl.formatMessage({ id: 'EsticonFirmList.loadingError' })),
    }
  );

  useEffect(() => {
    organizationId && loadOrgEsticonReport();
  }, [organizationId]);

  const esticonConnsOptions = useMemo(() => {
    return orgEsticonReport?.esticonConns.map(
      (estiConn): DefaultOptionType => ({ value: estiConn.conn.id, label: estiConn.conn.name })
    );
  }, [orgEsticonReport]);
  const esticonFirmsOption = useMemo(() => {
    return (
      orgEsticonReport?.esticonConns
        ?.find((conn) => conn.conn.id === selectedEsticonConnId)
        ?.esticonFirms?.map(
          (estiFirm): DefaultOptionType => ({ value: estiFirm.firm.id, label: estiFirm.firm.nazev })
        ) || []
    );
  }, [orgEsticonReport, selectedEsticonConnId]);

  const [esticonUnits, esticonUnitsError, esticonUnitsLoading, loadEsticonUnits] = useApiData((ct) =>
    masterApi.EsticonReports.org.id.estiConn.id.firms.id.projectunits.get(
      organizationId,
      selectedEsticonConnId,
      selectedEsticonFirmId,
      ct
    )
  );

  useEffect(() => {
    !!selectedEsticonFirmId && loadEsticonUnits();
  }, [selectedEsticonFirmId]);

  const unitTreeData = useMemo(() => mapEsticonUnitsToData(esticonUnits, onUnitSettingsDisplay), [
    esticonUnits,
    onUnitSettingsDisplay,
  ]);

  return (
    <Modal
      title={<Fmt id="ReportUnitSettingsModal.title" />}
      width={500}
      footer={[
        <Button key="submit" type="primary" onClick={onClose}>
          <Fmt id="general.close" />
        </Button>,
      ]}
      onCancel={onClose}
      {...restProps}
    >
      <ContentGate error={orgEsticonReportError}>
        <Margin bottom>
          <Row>
            <Col span={10}>
              <Fmt id="ReportUnitColorAssign.selectEsticonConn" />
            </Col>
            <Col span={14}>
              <Select
                options={esticonConnsOptions}
                size="middle"
                loading={esticonUnitsLoading}
                onChange={setSelectedEsticonConnId}
                className={styles.esticonDbSelect}
              />
            </Col>
          </Row>
        </Margin>
        <Margin bottom>
          <Row>
            <Col span={10}>
              <Fmt id="ReportUnitColorAssign.selectEsticonFirm" />
            </Col>
            <Col span={14}>
              <Select
                options={esticonFirmsOption}
                size="middle"
                loading={esticonUnitsLoading}
                onChange={setSelectedEsticonFirmId}
                className={styles.esticonDbSelect}
              />
            </Col>
          </Row>
        </Margin>
      </ContentGate>
      {!!selectedEsticonFirmId && (
        <ContentGate loading={esticonUnitsLoading} error={esticonUnitsError}>
          {unitTreeData?.length && (
            <Tree
              treeData={unitTreeData}
              selectable={false}
              defaultExpandedKeys={[unitTreeData[0].key]}
              className={styles.permissionTree}
            />
          )}
        </ContentGate>
      )}
    </Modal>
  );
};

export default ReportUnitSettingsModal;
