import { Form, Input, Select } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { Rule } from 'antd/lib/form';
import { useWatch } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import { apiConstraints } from 'api/completeApiConstraints';
import { AssignmentTypeEnum, ProjectUserProfileStatusEnum } from 'api/completeApiInterfaces';
import { DatePickerWithHolidays } from 'components/CalendarWithHolidays/DatePickerWithHolidays';
import { DocumentSelectDocumentState } from 'components/DocumentSelect/DocumentSelect';
import { DocumentsSelectMultipleFormItem } from 'components/DocumentSelect/FormItem/DocumentSelectMultipleFormItem';
import { groupSelectUserFooter, UserTransferFormItem } from 'components/UserTransfer/UserTransfer';
import { useCurrentProjectUser, useDispatchEffect, useIntl, useStoreSelector } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import { uniq } from 'lodash';
import moment, { Moment } from 'moment';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { groupListSelector } from 'store/selectors/groupSelectors';
import { projectUsersListSelector } from 'store/selectors/projectUsersSelectors';
import { maxLengthRule, requiredRule, simpleSelectFilter } from 'utils/formHelpers';

export type AssignmentFormData = {
  name: string;
  content: string;
  type: AssignmentTypeEnum;
  deadlineDate: IsoDateTime;
  assignmentDocuments: DocumentSelectDocumentState[];
  solverIds: Guid[];
  taskGiverId: Guid;
  associateIds: Guid[];
};

export type AssignmentAddFormConfiguration = {
  initialDocuments?: DocumentSelectDocumentState[];
};

export const isDateInThePast = (current: Moment) => current < moment();

const AVAILABLE_ASSIGNMENT_TYPES: AssignmentTypeEnum[] = [
  AssignmentTypeEnum.userTask,
  AssignmentTypeEnum.userDirectoryPermission,
  AssignmentTypeEnum.uploadDocuments,
  AssignmentTypeEnum.defectSolving,
];

type Props = AssignmentAddFormConfiguration;

const AssignmentFormComponent: FC<Props> = ({ initialDocuments }) => {
  const form = useFormInstance<AssignmentFormData>();
  const intl = useIntl();
  const taskGiverId = useWatch('taskGiverId');
  const associateIds = useWatch('associateIds');

  useDispatchEffect((dispatch) => {
    dispatch.projectUsers.loadData({ reload: false });
    dispatch.groups.loadData({ reload: false });
  }, []);

  useDirtyStoreReload(
    (store) => store.projectUsers,
    (dispatch) => dispatch.projectUsers
  );

  useDirtyStoreReload(
    (store) => store.groups,
    (dispatch) => dispatch.groups
  );

  const currentUser = useCurrentProjectUser();
  const projectUsers = useStoreSelector(projectUsersListSelector);
  const projectUsersActiveOnly = projectUsers?.filter((user) => user.status === ProjectUserProfileStatusEnum.active);

  const deadlineInitialValue = useMemo(() => moment().add(2, 'days'), []);

  const groups = useStoreSelector(groupListSelector);
  const createUserTransferFooter = useMemo(() => groupSelectUserFooter(groups, projectUsersActiveOnly), [
    groups,
    projectUsersActiveOnly,
  ]);

  useEffect(() => {
    if (!!associateIds) {
      if (taskGiverId === currentUser.id) {
        form?.setFieldValue('associateIds', [...associateIds.filter((id: Guid) => id !== currentUser.id)]);
      } else {
        form?.setFieldValue('associateIds', uniq([...associateIds, currentUser.id]));
      }
    }
  }, [associateIds, currentUser.id, form, taskGiverId]);

  const userOptions = useMemo(() => {
    return (
      projectUsers &&
      Object.values(projectUsers).map((user) => <Select.Option key={user.id}>{user.username}</Select.Option>)
    );
  }, [projectUsers]);

  const associatedUserRule = useCallback(
    (): Rule => ({
      required: taskGiverId !== currentUser.id,
      validator: (rule, value: Guid[]) => {
        const currentUserIsTaskGiver = taskGiverId === currentUser.id;
        if (!currentUserIsTaskGiver && !value) {
          return Promise.reject(intl.formatMessage({ id: 'forms.items.rules.required' }));
        } else if (!!value && value.some((id) => id === taskGiverId)) {
          return Promise.reject(intl.formatMessage({ id: 'AssignmentForm.error.userDuplicateInAssociates' }));
        } else if (!currentUserIsTaskGiver && (!value || !value.some((id) => id === currentUser.id))) {
          return Promise.reject(intl.formatMessage({ id: 'AssignmentForm.error.currentUserRequiredInAssociates' }));
        } else {
          return Promise.resolve();
        }
      },
    }),
    [currentUser.id, intl, taskGiverId]
  );

  return (
    <>
      <Form.Item
        name="type"
        label={intl.formatMessage({ id: 'AssignmentForm.type' })}
        initialValue={AssignmentTypeEnum.userTask}
        rules={[{ required: true, message: intl.formatMessage({ id: 'forms.items.rules.required' }) }]}
      >
        <Select>
          {AVAILABLE_ASSIGNMENT_TYPES.map((assignmentType) => (
            <Select.Option key={assignmentType} value={assignmentType}>
              <Fmt id={`AssignmentTypeEnum.${assignmentType}`} />
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="deadlineDate"
        label={intl.formatMessage({ id: 'AssignmentForm.deadlineDate' })}
        initialValue={deadlineInitialValue}
        rules={[{ required: true, message: intl.formatMessage({ id: 'forms.items.rules.required' }) }]}
      >
        <DatePickerWithHolidays showToday disabledDate={isDateInThePast} />
      </Form.Item>

      <Form.Item
        name="name"
        label={intl.formatMessage({ id: 'AssignmentForm.name' })}
        rules={[
          { required: true, message: intl.formatMessage({ id: 'AssignmentForm.name.required' }) },
          maxLengthRule('general.maxNameLength', apiConstraints.assignmentCreateDto.name.maxLength),
        ]}
      >
        <Input maxLength={apiConstraints.assignmentCreateDto.name.maxLength} />
      </Form.Item>

      <Form.Item
        name="content"
        label={intl.formatMessage({ id: 'AssignmentForm.content' })}
        rules={[maxLengthRule('general.maxDescriptionLength', apiConstraints.assignmentCreateDto.content.maxLength)]}
      >
        <TextArea rows={3} />
      </Form.Item>

      <Form.Item
        name="taskGiverId"
        label={intl.formatMessage({ id: 'AssignmentForm.taskGiver' })}
        initialValue={currentUser.id}
        rules={[{ required: true, message: intl.formatMessage({ id: 'forms.items.rules.required' }) }]}
      >
        <Select allowClear showSearch filterOption={simpleSelectFilter}>
          {userOptions}
        </Select>
      </Form.Item>

      <Form.Item
        name="associateIds"
        label={intl.formatMessage({ id: 'AssignmentForm.associateIds' })}
        rules={[associatedUserRule()]}
      >
        <UserTransferFormItem users={projectUsersActiveOnly} createFooter={createUserTransferFooter} />
      </Form.Item>

      <Form.Item
        name="solverIds"
        label={intl.formatMessage({ id: 'AssignmentForm.solverIds' })}
        rules={[requiredRule('forms.items.rules.required')]}
      >
        <UserTransferFormItem users={projectUsersActiveOnly} createFooter={createUserTransferFooter} />
      </Form.Item>

      <Form.Item
        name="assignmentDocuments"
        label={intl.formatMessage({ id: 'AssignmentForm.assignmentDocuments' })}
        initialValue={initialDocuments}
      >
        <DocumentsSelectMultipleFormItem allowUploadNew />
      </Form.Item>
    </>
  );
};

export const AssignmentForm = AssignmentFormComponent;
