import { Checkbox, Form } from 'antd';
import { CalendarDto, ExcludedDaySetDto } from 'api/completeApiInterfaces';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FC, ReactNode, useState } from 'react';
import { CalendarSettingsExcludedDaysItem } from './CalendarSettingsExcludedDaysItem';
import styles from './CalendarSettingsForm.module.less';

export type CalendarDaysKeys = keyof Pick<CalendarDto, 'mo' | 'tu' | 'we' | 'th' | 'fr' | 'sa' | 'su'>;

export type CalendarSettingsFormData = {
  workingDays: CalendarDaysKeys[];
  excludedDays: ExcludedDaySetDto[];
};

export type CalendarSettingsFormConfiguration = {
  initialCalendar?: CalendarDto;
};

type Props = CalendarSettingsFormConfiguration;

const workingDaysOptions: { label: ReactNode; value: CalendarDaysKeys }[] = [
  { label: <Fmt id={'general.dayNames.monday'} />, value: 'mo' },
  { label: <Fmt id={'general.dayNames.tuesday'} />, value: 'tu' },
  { label: <Fmt id={'general.dayNames.wednesday'} />, value: 'we' },
  { label: <Fmt id={'general.dayNames.thursday'} />, value: 'th' },
  { label: <Fmt id={'general.dayNames.friday'} />, value: 'fr' },
  { label: <Fmt id={'general.dayNames.saturday'} />, value: 'sa' },
  { label: <Fmt id={'general.dayNames.sunday'} />, value: 'su' },
];

const mapSelectedCalendarDays = (calendar: CalendarDto) =>
  Object.entries(calendar)
    .filter((value) => value[1] === true)
    .map((value) => value[0]);

export const CalendarSettingsForm: FC<Props> = ({ initialCalendar }) => {
  const intl = useIntl();
  const [showOnlyFuture, setShowOnlyFuture] = useState<boolean>(false);

  return (
    <>
      <Form.Item
        name="workingDays"
        label={intl.formatMessage({ id: 'CalendarSettingsForm.workingDays' })}
        initialValue={mapSelectedCalendarDays(initialCalendar)}
      >
        <Checkbox.Group options={workingDaysOptions} />
      </Form.Item>
      <Form.Item
        name="excludedDays"
        initialValue={initialCalendar.excludedDays}
        label={
          <FlowLayout className={styles.filterWrapper}>
            {intl.formatMessage({ id: 'CalendarSettingsForm.excludedDays' })}
          </FlowLayout>
        }
      >
        <CalendarSettingsExcludedDaysItem showOnlyFuture={showOnlyFuture} setShowOnlyFuture={setShowOnlyFuture} />
      </Form.Item>
    </>
  );
};
