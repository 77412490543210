import { DownOutlined, ExportOutlined, ImportOutlined } from '@ant-design/icons';
import {
  Button,
  ButtonProps,
  Checkbox,
  Collapse,
  Divider,
  Dropdown,
  MenuProps,
  message,
  Modal,
  Space,
  Table,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { projectApi } from 'api/completeApi';
import {
  DirectoryDetailDto,
  ImportStrategyEnum,
  ProjectMetadataDefinitionMetadataEntityType,
  ProjectMetadataExportElementDto,
  ProjectMetadataExportOwnerDto,
  ProjectMetadataExportRequestDto,
  ProjectMetadataImportCheckResultDto,
  ProjectMetadataImportDto,
  ProjectMetadataImportResultDto,
  ProjectMetadataValueDto,
} from 'api/completeApiInterfaces';
import { directoriesApi } from 'api/project/directories/directoriesApi';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { Margin } from 'components/Margin/Margin';
import SingleFileInput from 'components/PrimaryFileInput/SingleFileInput';
import { DROPDOWN_TRIGGER_CLICK, HIDE_BUTTON_PROPS, LARGE_SETTING_MODAL_WIDTH } from 'config/constants';
import { useApiData, useBoolean, useIntl, useIsMounted, useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import { chunk } from 'lodash';
import moment from 'moment';
import { getMetadataVariableTypeText } from 'pages/OrganizationsSettingPage/ProjectTemplate/Tabs/General/MetadataUtils';
import React, { FunctionComponent, useCallback, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import { messageError } from 'utils';
import { clearFilenameValue, CSV_DELIMITERS, parseCsvFile } from 'utils/csv/formatCsv';
import { downloadBlob } from 'utils/downloadFile';

const { Panel } = Collapse;

type Props = {
  orderedItems: ProjectMetadataValueDto[];
  metadataLoading: boolean;
  canEdit?: boolean;
  currentDirectory: DirectoryDetailDto;
  loadDirectoryMetadata: () => void;
};

enum NotImportedMetaKeyReasonEnum {
  documentNotFound = 'documentNotFound',
  directoryNotFound = 'directoryNotFound',
  documentHasOwner = 'documentHasOwner',
  metadataDefinitionNotFound = 'metadataDefinitionNotFound',
  ignoreExisting = 'ignoreExisting',
  accessDenied = 'accessDenied',
  metadataTypeMess = 'metadataTypeMess',
  documentLocationNotFound = 'documentLocationNotFound',
  duplicitDocument = 'duplicitDocument',
  duplicitDir = 'duplicitDir',
  valueCastError = 'valueCastError',
}

const getProjectMetadataElements = (vaLuesArray: string[], entityLength: number): ProjectMetadataExportElementDto[] => {
  return chunk(vaLuesArray, entityLength).map((entityArray) => ({
    elementName: entityArray[0],
    elementType: entityArray[1],
    elementValues: entityArray.slice(2, entityLength),
  }));
};

export const MetadataExportImportButton: FunctionComponent<Props> = React.memo(
  ({ orderedItems, metadataLoading, loadDirectoryMetadata, canEdit, currentDirectory }) => {
    const intl = useIntl();
    const isMountedRef = useIsMounted();
    const [loading, setLoading] = useState<boolean>(false);
    const [importVisible, showImport, hideImport] = useBoolean(false);
    const [exportVisible, showExport, hideExport] = useBoolean(false);
    const [addMissingDefinition, setAddMissingDefinition] = useState<boolean>(false);
    const [replaceExisting, setReplaceExisting] = useState<boolean>(false);
    const [deleteMetadataMissingInImportedFile, setDeleteMetadataMissingInImportedFile] = useState<boolean>(false);
    const [isExportOnlyPartCorrect, setIsExportOnlyPartCorrect] = useState<boolean>(false);
    const [withTree, setWithTree] = useState<boolean>(false);
    const [metadataOwners, setMetadataOwners] = useState<ProjectMetadataExportOwnerDto[]>();
    const [importResult, setImportResult] = useState<ProjectMetadataImportResultDto>();
    const [checkImportResult, setCheckImportResult] = useState<ProjectMetadataImportCheckResultDto>();

    const [projectMetadata] = useApiData((ct) => projectApi.metadata.definitions.get(ct), { autoload: true });

    const hasDocsMetadatas = useMemo(
      () =>
        !!projectMetadata?.definitions.find(
          (definition) => definition.type === ProjectMetadataDefinitionMetadataEntityType.document
        ),
      [projectMetadata]
    );

    const onExportSubmit = useSameCallback(async () => {
      setLoading(true);

      const data: ProjectMetadataExportRequestDto = {
        withTree,
        strategy: isExportOnlyPartCorrect ? ImportStrategyEnum.importPart : null,
      };
      const [err, resp] = await directoriesApi.exportDirectoryMetadata(currentDirectory.id, data);
      if (err) {
        messageError(err, intl);
        setLoading(false);
        return null;
      }
      const exportFileName = `${clearFilenameValue(currentDirectory.name)}_DIRECTORY_METADATA_${moment().format(
        'YYYY-MM-DD-hh:mm:ss'
      )}.csv`;
      if (!!resp.data) {
        const blob = new Blob([resp.data], { type: '"text/csv"' });
        downloadBlob(blob, exportFileName);
      }

      setLoading(false);
      setWithTree(false);
      setIsExportOnlyPartCorrect(false);
      hideExport();
      return null;
    });

    const menu = useMemo(
      (): MenuProps => ({
        items: [
          {
            label: (
              <CommonHubTooltip
                title={
                  !orderedItems.length
                    ? intl.formatMessage({ id: 'MetadataExportImportButton.menu.export.noData' })
                    : null
                }
              >
                {intl.formatMessage({ id: 'general.export' })}
              </CommonHubTooltip>
            ),
            key: 'Export',
            icon: <ExportOutlined />,
            disabled: !orderedItems.length && !hasDocsMetadatas,
            onClick: showExport,
          },
          {
            label: (
              <CommonHubTooltip
                title={!canEdit ? intl.formatMessage({ id: 'MetadataExportImportButton.menu.import.noAccess' }) : null}
              >
                {intl.formatMessage({ id: 'general.import' })}
              </CommonHubTooltip>
            ),
            key: 'Import',
            icon: <ImportOutlined />,
            disabled: !canEdit,
            onClick: () => showImport(),
          },
        ],
      }),
      [canEdit, hasDocsMetadatas, intl, orderedItems, showExport, showImport]
    );

    const onInvalidFileDrop = useCallback(() => {
      messageError('MetadataExportImportButton.csvFilesOnly', intl);
    }, [intl]);

    const clearStates = () => {
      setMetadataOwners(undefined);
      setCheckImportResult(undefined);
      setWithTree(false);
      setReplaceExisting(false);
      setAddMissingDefinition(false);
      setDeleteMetadataMissingInImportedFile(false);
      setLoading(false);
    };

    const activeFileReaderRef = useRef<FileReader>();

    const checkImportingResult = useSameCallback(async () => {
      const data = {
        addMissingDefinition,
        replaceExisting,
        deleteMetadataMissingInInImportedFile: deleteMetadataMissingInImportedFile,
        withTree,
        metadataOwners,
      };
      const [err, resp] = await projectApi.metadata.import.id.check.post(currentDirectory.id, data);
      if (err) {
        messageError(err, intl);
        return;
      }
      setCheckImportResult(resp.data);
    });

    useDebounce(
      () => {
        if (!metadataOwners?.length) return;
        void checkImportingResult();
      },
      500,
      [addMissingDefinition, replaceExisting, deleteMetadataMissingInImportedFile, withTree, metadataOwners]
    );

    const handleMetadataImport = useCallback(
      (value: File) => {
        clearStates();
        if (value) {
          const fileExt = value.name.split('.').pop();

          if (fileExt !== 'csv') {
            onInvalidFileDrop();
            return;
          }
          setLoading(true);
          activeFileReaderRef.current?.abort();
          activeFileReaderRef.current = new FileReader();
          activeFileReaderRef.current.readAsText(value);
          activeFileReaderRef.current.onload = (event) => {
            if (!isMountedRef.current) return;
            setLoading(false);
            const { result } = event.target;
            const [headers, lineArrays] = parseCsvFile(result as string, CSV_DELIMITERS.semicolon);
            const countOfPaths = headers.filter((value) => value.startsWith('path')).length;
            let countOfMetadataValues = 0;
            for (const headerString of headers) {
              if (headerString.startsWith('metadataValue1')) countOfMetadataValues++;
              else if (headerString.startsWith('metadataName2')) {
                break;
              }
            }

            const metadataOwners: ProjectMetadataExportOwnerDto[] = lineArrays.map((lineArray) => {
              return {
                dirPath: lineArray.slice(0, countOfPaths),
                documentName: lineArray[countOfPaths],

                metadataElements: getProjectMetadataElements(
                  lineArray.slice(countOfPaths + 1),
                  countOfMetadataValues + 2
                ),
              };
            });

            setMetadataOwners(metadataOwners);
          };
          setLoading(false);
        }
      },
      [isMountedRef, onInvalidFileDrop]
    );

    const onImportSubmit = useCallback(async () => {
      const importData: ProjectMetadataImportDto = {
        addMissingDefinition,
        replaceExisting,
        deleteMetadataMissingInInImportedFile: deleteMetadataMissingInImportedFile,
        withTree,
        metadataOwners,
      };
      if (!metadataOwners?.length) {
        onInvalidFileDrop();
        return;
      }
      setLoading(true);
      const [err, resp] = await projectApi.metadata.import.id.post(currentDirectory.id, importData);
      if (err) {
        messageError(err, intl);
        clearStates();
        hideImport();
        return null;
      }
      setImportResult(resp.data);

      void message.success(intl.formatMessage({ id: 'MetadataExportImportButton.importSuccess' }));
      loadDirectoryMetadata();
      clearStates();
      hideImport();
    }, [
      addMissingDefinition,
      currentDirectory,
      deleteMetadataMissingInImportedFile,
      hideImport,
      intl,
      loadDirectoryMetadata,
      metadataOwners,
      onInvalidFileDrop,
      replaceExisting,
      withTree,
    ]);

    const handleReplaceExistingCheckboxChange = useSameCallback((e: CheckboxChangeEvent) =>
      setReplaceExisting(e.target.checked)
    );

    const handleAddMissingDefinitionCheckboxChange = useSameCallback((e: CheckboxChangeEvent) =>
      setAddMissingDefinition(e.target.checked)
    );
    const handleDeleteMetadataMissingInInImportedFileCheckboxChange = useSameCallback((e: CheckboxChangeEvent) =>
      setDeleteMetadataMissingInImportedFile(e.target.checked)
    );
    const handleWithTreeCheckboxChange = useSameCallback((e: CheckboxChangeEvent) => setWithTree(e.target.checked));

    const handleIsExportOnlyPartCorrectCheckboxChange = useSameCallback((e: CheckboxChangeEvent) =>
      setIsExportOnlyPartCorrect(e.target.checked)
    );

    const failReasonText = useCallback(
      (reasonValue: NotImportedMetaKeyReasonEnum) => {
        return reasonValue in NotImportedMetaKeyReasonEnum
          ? intl.formatMessage({ id: `MetadataExportImportButton.importFailReason.${reasonValue}` })
          : intl.formatMessage({ id: 'MetadataExportImportButton.importFailReason.unexpectedError' });
      },
      [intl]
    );

    const importModalOkProps: ButtonProps = useMemo(
      () => ({
        loading: loading,
      }),
      [loading]
    );

    const missingMetadataDefinitions = useMemo(() => {
      if (!checkImportResult?.missingMetadataDefinition) return [];
      return Object.entries(checkImportResult.missingMetadataDefinition).map(([key, value]) => (
        <div key={key}>{`${key} - ${getMetadataVariableTypeText(value, intl)}`}</div>
      ));
    }, [checkImportResult, intl]);

    const notImportedDirectoryKeys = useMemo(() => {
      if (!checkImportResult?.notImportedDirectoryKeys) return [];
      return Object.entries(checkImportResult.notImportedDirectoryKeys).flatMap(([key, value]) => {
        return value.map((value) => ({
          reason: failReasonText(key as NotImportedMetaKeyReasonEnum),
          path: value.path,
          notImportedKeys: value.notImportedKeys?.map((key) => <div key={key}>{key}</div>),
        }));
      });
    }, [checkImportResult, failReasonText]);

    const notImportedDocumentKeys = useMemo(() => {
      if (!checkImportResult?.notImportedDocumentKeys) return [];
      return Object.entries(checkImportResult.notImportedDocumentKeys).flatMap(([key, value]) => {
        return value.map((value) => ({
          reason: failReasonText(key as NotImportedMetaKeyReasonEnum),
          path: value.path,
          name: value.name,
          notImportedKeys: value.notImportedKeys?.map((key) => <div key={key}>{key}</div>),
        }));
      });
    }, [checkImportResult, failReasonText]);

    const notImportedKeysDirectoryColumns: ColumnsType = useMemo(
      () => [
        {
          title: intl.formatMessage({ id: 'MetadataExportImportButton.table.reason' }),
          dataIndex: 'reason',
          key: 'reason',
          ellipsis: true,
        },
        {
          title: intl.formatMessage({ id: 'MetadataExportImportButton.table.path' }),
          dataIndex: 'path',
          key: 'path',
          ellipsis: true,
        },
        {
          title: intl.formatMessage({ id: 'MetadataExportImportButton.table.notImportedKeys' }),
          dataIndex: 'notImportedKeys',
          key: 'notImportedKeys',
          ellipsis: true,
        },
      ],
      [intl]
    );

    const notImportedKeysDocumentColumns = useMemo(
      () => [
        {
          title: intl.formatMessage({ id: 'MetadataExportImportButton.table.reason' }),
          dataIndex: 'reason',
          key: 'reason',
          ellipsis: true,
        },
        {
          title: intl.formatMessage({ id: 'MetadataExportImportButton.table.path' }),
          dataIndex: 'path',
          key: 'path',
          ellipsis: true,
        },
        {
          title: intl.formatMessage({ id: 'MetadataExportImportButton.table.docname' }),
          dataIndex: 'name',
          key: 'name',
          ellipsis: true,
        },
        {
          title: intl.formatMessage({ id: 'MetadataExportImportButton.table.notImportedKeys' }),
          dataIndex: 'notImportedKeys',
          key: 'notImportedKeys',
          ellipsis: true,
        },
      ],
      [intl]
    );

    return (
      <>
        <Dropdown menu={menu} trigger={DROPDOWN_TRIGGER_CLICK}>
          <Button shape="round" disabled={metadataLoading}>
            <Space>
              Export/Import
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
        <Modal
          key="fileExport"
          open={exportVisible}
          title={<Fmt id="MetadataExportImportButton.export.modalTitle" />}
          onOk={onExportSubmit}
          onCancel={() => {
            hideExport();
            setWithTree(false);
          }}
          destroyOnClose
        >
          <Space direction="vertical">
            <Checkbox checked={withTree} onChange={handleWithTreeCheckboxChange}>
              {intl.formatMessage({ id: 'MetadataExportImportButton.withTree' })}
            </Checkbox>
            <Checkbox checked={isExportOnlyPartCorrect} onChange={handleIsExportOnlyPartCorrectCheckboxChange}>
              {intl.formatMessage({ id: 'MetadataExportImportButton.export.withoutNoAccess' })}
            </Checkbox>
          </Space>
        </Modal>
        {canEdit && (
          <Modal
            key="fileImport"
            open={importVisible}
            onOk={onImportSubmit}
            onCancel={() => {
              hideImport();
              clearStates();
            }}
            okButtonProps={importModalOkProps}
            title={<Fmt id="MetadataExportImportButton.import.modalTitle" />}
            confirmLoading={loading}
            width={LARGE_SETTING_MODAL_WIDTH}
            destroyOnClose
          >
            <Margin bottom>
              <Typography.Text>
                <Fmt id="MetadataExportImportButton.description" />
              </Typography.Text>
            </Margin>
            <SingleFileInput
              acceptedContentType={[
                'text/csv',
                'application/csv',
                'text/comma-separated-values',
                'application/excel',
                'application/vnd.ms-excel',
                'application/vnd.msexcel',
              ]}
              onInvalidFileDrop={onInvalidFileDrop}
              onChange={handleMetadataImport}
            />
            {!!metadataOwners?.length && (
              <Margin top bottom>
                <Space direction="vertical">
                  <Checkbox checked={replaceExisting} onChange={handleReplaceExistingCheckboxChange}>
                    {intl.formatMessage({ id: 'MetadataExportImportButton.import.replaceExisting' })}
                  </Checkbox>
                  <Checkbox checked={addMissingDefinition} onChange={handleAddMissingDefinitionCheckboxChange}>
                    {intl.formatMessage({ id: 'MetadataExportImportButton.import.addMissingDefinition' })}
                  </Checkbox>
                  <Checkbox
                    checked={deleteMetadataMissingInImportedFile}
                    onChange={handleDeleteMetadataMissingInInImportedFileCheckboxChange}
                  >
                    {intl.formatMessage({
                      id: 'MetadataExportImportButton.import.deleteMetadataMissingInInImportedFile',
                    })}
                  </Checkbox>
                  <Checkbox checked={withTree} onChange={handleWithTreeCheckboxChange}>
                    {intl.formatMessage({ id: 'MetadataExportImportButton.withTree' })}
                  </Checkbox>
                </Space>
              </Margin>
            )}
            {!!checkImportResult && (
              <>
                <Margin top>
                  <div>
                    <Typography.Text strong>
                      {intl.formatMessage({ id: 'MetadataExportImportButton.csvPath' })}
                    </Typography.Text>
                    <Typography.Text>{checkImportResult.exportedPath}</Typography.Text>
                  </div>
                  <div>
                    <Typography.Text strong>
                      {intl.formatMessage({ id: 'MetadataExportImportButton.destinationPath' })}
                    </Typography.Text>
                    <Typography.Text>{checkImportResult.importedPath}</Typography.Text>
                  </div>
                </Margin>
                <Margin top>
                  <Collapse>
                    {!!checkImportResult?.directoryNotInImports.length && (
                      <Panel
                        header={intl.formatMessage({ id: 'MetadataExportImportButton.directoryNotInImports' })}
                        key="directoryNotInImports"
                      >
                        {checkImportResult.directoryNotInImports.map((dir) => (
                          <div key={dir.id}>
                            <Typography.Text>{`${dir.name}`}</Typography.Text>
                          </div>
                        ))}
                      </Panel>
                    )}
                    {!!checkImportResult?.documentNotInImports.length && (
                      <Panel
                        header={intl.formatMessage({ id: 'MetadataExportImportButton.documentNotInImports' })}
                        key="documentNotInImports"
                      >
                        {checkImportResult.documentNotInImports.map((doc) => (
                          <div key={doc.id}>
                            <Typography.Text>{`${doc.name} - ${doc.directoryPath}`}</Typography.Text>
                          </div>
                        ))}
                      </Panel>
                    )}
                    {!!missingMetadataDefinitions.length && (
                      <Panel
                        header={intl.formatMessage({ id: 'MetadataExportImportButton.missingMetadataDefinition' })}
                        key="missingMetadataDefinition"
                      >
                        {missingMetadataDefinitions}
                      </Panel>
                    )}

                    {!!notImportedDirectoryKeys.length && (
                      <Panel
                        header={intl.formatMessage({ id: 'MetadataExportImportButton.notImportedDirectoryKeys' })}
                        key="notImportedDirectoryKeys"
                      >
                        <Table
                          size="small"
                          bordered
                          dataSource={notImportedDirectoryKeys}
                          columns={notImportedKeysDirectoryColumns}
                        ></Table>
                      </Panel>
                    )}
                    {!!notImportedDocumentKeys.length && (
                      <Panel
                        header={intl.formatMessage({ id: 'MetadataExportImportButton.notImportedDocumentKeys' })}
                        key="notImportedDocumentKeys"
                      >
                        <Table
                          size="small"
                          bordered
                          dataSource={notImportedDocumentKeys}
                          columns={notImportedKeysDocumentColumns}
                        ></Table>
                      </Panel>
                    )}
                  </Collapse>
                </Margin>
              </>
            )}
          </Modal>
        )}

        {!!importResult && (
          <Modal
            key="importResult"
            title={intl.formatMessage({ id: 'MetadataExportImportButton.importResult.title' })}
            open={!!importResult}
            cancelButtonProps={HIDE_BUTTON_PROPS}
            onCancel={() => setImportResult(undefined)}
            onOk={() => setImportResult(undefined)}
            destroyOnClose
          >
            <>
              <div key="changedDirectories">{`${intl.formatMessage({
                id: 'MetadataExportImportButton.importResult.changedDirectories',
              })} ${importResult.changedDirectories}`}</div>
              <div key="addedDirectoryDefinations">{`${intl.formatMessage({
                id: 'MetadataExportImportButton.importResult.addedDirectoryDefinitions',
              })} ${importResult.addedDirectoryDefinitions}`}</div>
              <div key="changedDirectoryValues">{`${intl.formatMessage({
                id: 'MetadataExportImportButton.importResult.changedDirectoryValues',
              })} ${importResult.changedDirectoryValues}`}</div>
              <div key="unChangedDirectories">{`${intl.formatMessage({
                id: 'MetadataExportImportButton.importResult.unChangedDirectories',
              })} ${importResult.unChangedDirectories}`}</div>
              <div key="unChangedDirectoryValues">{`${intl.formatMessage({
                id: 'MetadataExportImportButton.importResult.unChangedDirectoryValues',
              })} ${importResult.unChangedDirectoryValues}`}</div>
              <div key="removedDirectoryValues">{`${intl.formatMessage({
                id: 'MetadataExportImportButton.importResult.removedDirectoryValues',
              })} ${importResult.removedDirectoryValues}`}</div>
              <Divider />
              <div key="changedDocuments">{`${intl.formatMessage({
                id: 'MetadataExportImportButton.importResult.changedDocuments',
              })} ${importResult.changedDocuments}`}</div>
              <div key="addedDocumentDefinitions">{`${intl.formatMessage({
                id: 'MetadataExportImportButton.importResult.addedDocumentDefinitions',
              })} ${importResult.addedDocumentDefinitions}`}</div>
              <div key="changedDocumentValues">{`${intl.formatMessage({
                id: 'MetadataExportImportButton.importResult.changedDocumentValues',
              })} ${importResult.changedDocumentValues}`}</div>
              <div key="unChangedDocuments">{`${intl.formatMessage({
                id: 'MetadataExportImportButton.importResult.unChangedDocuments',
              })} ${importResult.unChangedDocuments}`}</div>
              <div key="unChangedDocumentValues">{`${intl.formatMessage({
                id: 'MetadataExportImportButton.importResult.unChangedDocumentValues',
              })} ${importResult.unChangedDocumentValues}`}</div>
              <div key="removedDocumentValues">{`${intl.formatMessage({
                id: 'MetadataExportImportButton.importResult.removedDocumentValues',
              })} ${importResult.removedDocumentValues}`}</div>
            </>
          </Modal>
        )}
      </>
    );
  }
);
