import { Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { JSONVariableTypeEnum } from 'api/completeApiInterfaces';
import React, { FunctionComponent, useCallback } from 'react';
import { JSONVariableSelectEnum } from '../JSONVariableTypes';

export type JSONVariableSelectEnumProps = {
  dataType: JSONVariableTypeEnum.enum;
  disabled?: boolean;
  value?: JSONVariableSelectEnum;
  enumType: string;
  selectOptions: DefaultOptionType[];
  onChange?: (value: JSONVariableSelectEnum) => void;
} & Omit<SelectProps, 'value' | 'onChange' | 'disabled'>;

const JSONVariableSelectFormItem: FunctionComponent<JSONVariableSelectEnumProps> = ({
  dataType,
  value,
  selectOptions,
  onChange,
  enumType,
  disabled,
  ...restProps
}) => {
  const handleChange = useCallback(
    (value: string) => {
      onChange && onChange({ type: dataType, enumType, value });
    },
    [dataType, onChange]
  );

  return (
    <Select {...restProps} onChange={handleChange} disabled={disabled} options={selectOptions} value={value?.value} />
  );
};

export default React.memo(JSONVariableSelectFormItem);
