import { FileAddOutlined } from '@ant-design/icons';
import { Button, message, Skeleton } from 'antd';
import { api } from 'api';
import {
  DirectoryContentDto,
  DocumentContentDto,
  NodeStateEnum,
  SecondaryBlobTypeEnum,
  SortOrder,
  WorkflowApprovalDocumentDto,
  WorkFlowApprovalDocumentStateEnum,
  WorkflowDocumentDto,
  WorkflowDto,
  WorkflowNodeAddDocumentDto,
  WorkflowNodeDto,
  WorkflowNodePermittedActionsEnum,
  WorkflowStateEnum,
} from 'api/completeApiInterfaces';
import { injectDocumentIdsToCreateDto } from 'api/project/upload/uploadHelpers';
import DocumentOnlineEditorModal, {
  OnlineEditorDocument,
} from 'components/DocumentOnlineEditor/DocumentOnlineEditorModal/DocumentOnlineEditorModal';
import { DocumentSelectDocumentState } from 'components/DocumentSelect/DocumentSelect';
import { DocumentSelectModal } from 'components/DocumentSelect/DocumentSelectModal';
import DocumentsGridHeader from 'components/DocumentsGridHeader/DocumentsGridHeader';
import { EmptyGate } from 'components/EmptyGate/EmptyGate';
import CommonDerivativesFileViewer, {
  CommonDerivedFileViewerItem,
} from 'components/FileViewer/CommonDerivativesFileViewer';
import { createFrontendExtensionFilter } from 'components/filters/components/ExtensionFilter/ExtensionFilter';
import { createFrontendSingleSelectFilter, IOption } from 'components/filters/components/SelectFilter/SelectFilter';
import { createFrontendLabelsFilter } from 'components/filters/components/SelectFilter/variants/LabelsFilter/LabelsFilter';
import { createFrontendMultiTextFilter } from 'components/filters/components/TextFilter/TextFilter';
import { FiltersPersistentKey, FrontendFilter } from 'components/filters/filterTypes';
import { FrontendOrderOption } from 'components/filters/orderTypes';
import { FilterToolbar } from 'components/filters/render/FilterToolbar/FilterToolbar';
import { OrderSelect } from 'components/filters/render/OrderSelect/OrderSelect';
import { DocumentCreateFormModal } from 'components/forms/DocumentCreateForm';
import {
  CreateDocumentUploadData,
  documentCreateFormDataToCreateDto,
} from 'components/forms/DocumentCreateForm/DocumentCreateFormModal';
import FolderValidationMasksContextProvider from 'components/forms/DocumentCreateForm/FolderValidationMasksContext';
import RevisionSignedDocumentFileCreateFormModal from 'components/forms/RevisionSecondaryFilesCreateForm/RevisionSignedDocumentFileCreateFormModal';
import { isWorkflowDocumentDisabled } from 'components/forms/WorkflowInstantiationForm';
import { UploadIcon } from 'components/Icons/HubActionsIcons';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { Margin } from 'components/Margin/Margin';
import { AnnotationModeEnum } from 'components/PdfAnnotation/PdfAnnotationModal';
import { PdfSignatureModal } from 'components/PdfSignature/PdfSignatureModal';
import PrimaryFileViewerTitle from 'components/PrimaryFileViewerTitle/PrimaryFileViewerTitle';
import {
  AnnotationEditorSettings,
  AnnotationModalContext,
} from 'components/RevisionsList/AnnotationModalContextProvider';
import { Section } from 'components/Section/Section';
import SpinBoxCenter from 'components/SpinBoxCenter';
import StackPanel from 'components/StackPanel';
import { PREVIEW_ORIGINAL_URL_EXPIRATION } from 'config/constants';
import {
  useActiveProject,
  useBoolean,
  useCurrentProjectUser,
  useFrontendFilters,
  useIntl,
  useSameCallback,
  useVisibleState,
} from 'hooks';
import { useLostPermissionEffect } from 'hooks/useLostPermissionEffect';
import { Fmt } from 'locale';
import moment from 'moment';
import { usePdfTronLicenceKeyContext } from 'pages/DocumentDetailPage/context/PdfTronLicenceKeyContext';
import { isPdf } from 'pages/DocumentDetailPage/DocumentDetailPage.Toolbar';
import { FunctionComponent, default as React, useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AutoSizer, List } from 'react-virtualized';
import { downloadFile, processApiError } from 'utils';
import { dateComparer, numberComparer, textComparer } from 'utils/comparators';
import { selectMaxValue } from 'utils/selectMaxValue';
import { ConnectedDirectory } from 'utils/typeMappings/directories/directoryTypes';
import { WorkflowTabProps } from '../../WorkflowDetailTabs';
import styles from './AddedRevisions.module.less';
import { ApprovalDocumentRevisionInfo } from './AddedRevisions/ApprovalDocumentRevisionInfo';
import { UploadApprovalRevisionModal } from './AddedRevisions/UploadApprovalRevisionModal';

type SigningDocument = WorkflowDocumentDto &
  Pick<DocumentContentDto, 'primaryFile' | 'secondaryFiles' | 'currentRevision'>;

type Props = WorkflowTabProps & {
  selectedNode: WorkflowNodeDto;
  isSolver: boolean;
};

enum SignedStateTypeEnum {
  unsigned = 'unsigned',
  signed = 'signed',
}
enum ComentedStateTypeEnum {
  commented = 'commented',
  clean = 'clean',
}

const TRUE_LAMBDA = () => true;
const OVERSCAN_ROW_COUNT = 15;
const MAX_APPROVAL_DOCUMENT_IN_LIST = 5;
const APPROVAL_DOCUMENT_ROW_HEIGHT = 100;
const APPROVAL_DOCUMENT_ROW_SPACING = 5;

export const getWorkflowAllowedDocumentStates = (
  publishedPermitted: boolean,
  sharedPermitted: boolean,
  workInProgressPermitted: boolean
): WorkflowStateEnum[] => {
  const allowed = [];
  if (publishedPermitted) allowed.push(WorkflowStateEnum.published);
  if (sharedPermitted) allowed.push(WorkflowStateEnum.shared);
  if (workInProgressPermitted) allowed.push(WorkflowStateEnum.workinprogress);
  return allowed;
};

const ORDER_OPTIONS: FrontendOrderOption<WorkflowApprovalDocumentDto>[] = [
  {
    key: 'name',
    label: <Fmt id="SearchSortTypeItem.name" />,
    compare: textComparer.map((workflowDocument) => workflowDocument.document.name),
    defaultOrder: SortOrder.asc,
  },
  {
    key: 'createDate',
    label: <Fmt id="SearchSortTypeItem.createdDate" />,
    compare: dateComparer.map((workflowDocument) => moment(workflowDocument.document.createdDate)),
  },
];

export const currentRevisionSelector = (workflowDocument: WorkflowApprovalDocumentDto) => {
  if (!workflowDocument.addedRevisions.length) return workflowDocument.revision;
  return selectMaxValue(
    workflowDocument.addedRevisions.map((workflowRevision) => workflowRevision.revision),
    numberComparer.map((item) => item.number)
  );
};

const PREVIEW_TRANSFORM: (
  item: WorkflowApprovalDocumentDto,
  getOriginalUrl: () => Promise<string>
) => CommonDerivedFileViewerItem = (item, getOriginalUrl) => {
  const currentRevision = currentRevisionSelector(item);
  return {
    id: item.id,
    title: (
      <PrimaryFileViewerTitle
        revisionNo={currentRevision.number}
        state={currentRevision.revisionState}
        fileName={item.document.name}
      />
    ),
    blobToken: item.revision.primaryFile.blobToken,
    getOriginalUrl,
  };
};

export type ToEditApprovalDocument = OnlineEditorDocument & { name: string; workflowNodeId?: Guid };

const FILTERS_PERSISTENT_KEY = FiltersPersistentKey.AddedWorkflowRevisions;

export const AddedRevisions: FunctionComponent<Props> = ({
  workflow,
  updateWorkflow,
  loadWorkflow,
  selectedNode,
  isSolver,
}) => {
  const [selectedDocument, uploadModalVisible, setSelectedDocument, hideUploadModal] = useVisibleState<
    WorkflowApprovalDocumentDto
  >();

  const [newDocumentFormVisible, showNewDocumentForm, hideNewDocumentForm] = useBoolean(false);
  const [previewId, previewVisible, setPreviewId, hidePreview] = useVisibleState<Guid>();
  const [modalDocuments, setModalDocuments] = useState<DocumentSelectDocumentState[]>([]); // files selected in modal
  const [selectDocumentsVisible, showSelectDocuments, hideSelectDocuments] = useBoolean(false);
  const [selectedSigningDocument, setSelectedSigningDocument] = useState<SigningDocument>();
  const [signDocumentsOnlineVisible, showSignDocumentsOnline, hideSignDocumentsOnline] = useBoolean(false);
  const [uploadSignedDocumentVisible, showSignedDocumentUpload, hideSignedDocumentUpload] = useBoolean(false);
  const [documentOnlineEditorVisible, showDocumentOnlineEditor, hideDocumentOnlineEditor] = useBoolean(false);
  const [selectedEditingDocument, setSelectedEditingDocument] = useState<ToEditApprovalDocument>();

  const intl = useIntl();
  const history = useHistory();
  const activeProject = useActiveProject();
  const annotationModalContext = useContext(AnnotationModalContext);

  const pdfTronLicenseKeyContext = usePdfTronLicenceKeyContext();
  const pdfTronLicenseKey = pdfTronLicenseKeyContext?.licenseKey;

  const selectedSignedDerivate = useMemo(
    () =>
      selectedSigningDocument?.secondaryFiles?.find(
        (secondaryFile) => secondaryFile.blobType === SecondaryBlobTypeEnum.signed
      ),
    [selectedSigningDocument]
  );

  const handleSignedDocumentSubmit = useCallback(() => {
    hideSignDocumentsOnline();
    hideSignedDocumentUpload();
    loadWorkflow();
  }, [loadWorkflow]);

  const handleSubmitNewDocumentForm = useCallback(
    (_data: unknown, response?: WorkflowDto) => {
      updateWorkflow(response);
      hideNewDocumentForm();
    },
    [updateWorkflow]
  );

  const handleSignDocumentOnline = useCallback((document: WorkflowApprovalDocumentDto) => {
    showSignDocumentsOnline();
    const currentRevision = currentRevisionSelector(document);
    setSelectedSigningDocument({
      ...document.document,
      primaryFile: currentRevision.primaryFile,
      currentRevision: currentRevision,
      secondaryFiles: currentRevision.secondaryFiles,
    });
  }, []);

  const handleUploadSignDocument = useCallback((document: WorkflowApprovalDocumentDto) => {
    showSignedDocumentUpload();
    const currentRevision = currentRevisionSelector(document);
    setSelectedSigningDocument({
      ...document.document,
      primaryFile: currentRevision.primaryFile,
      currentRevision: currentRevision,
      secondaryFiles: currentRevision.secondaryFiles,
    });
  }, []);

  const handleAnnotateDocument = useCallback(
    (document: WorkflowApprovalDocumentDto, options: AnnotationEditorSettings = {}) => {
      if (document.document.isModel) {
        history.push(`/projects/${activeProject.id}/model/${document.document.id}`);
      } else {
        const lastRevision = currentRevisionSelector(document);

        if (!lastRevision.primaryFile.hasPdfDerivate) {
          void message.info(intl.formatMessage({ id: 'AddedRevisions.message.noDerivateData' }));
          return;
        }
        annotationModalContext.show(
          {
            parentDocumentId: document.document.id,
            revision: lastRevision,
          },
          {
            annotationMode: AnnotationModeEnum.documentAnnotation,
            canAddRevision: false,
            canEditOwnAnnotations: true,
            ...options,
          },
          selectedNode.id,
          workflow.id
        );
      }
    },
    [activeProject.id, annotationModalContext, history, selectedNode.id, workflow]
  );

  const handleOnlineEditDocument = useCallback(
    (document: WorkflowApprovalDocumentDto) => {
      showDocumentOnlineEditor();
      const currentRevision = currentRevisionSelector(document);
      if (isPdf(currentRevision.primaryFile)) {
        handleAnnotateDocument(document, { canAddRevision: true });
        return;
      }
      setSelectedEditingDocument({
        id: document.document.id,
        currentRevision,
        primaryFile: currentRevision.primaryFile,
        name: document.document.name,
        workflowNodeId: selectedNode.id,
      });
    },
    [selectedNode, showDocumentOnlineEditor]
  );

  const handleDocumentDownload = useCallback((document: WorkflowApprovalDocumentDto) => {
    void downloadFile(() => api.project.documents.getDocumentDownloadUrlById(document.document.id));
  }, []);

  const handleSelectedDocuments = useCallback(
    (data: DocumentSelectDocumentState[]) => {
      setModalDocuments(data);
    },
    [setModalDocuments]
  );

  const onCancelSelectedDocuments = useCallback(() => {
    setModalDocuments([]);
    hideSelectDocuments();
  }, [setModalDocuments]);

  const handleSubmit = useCallback(
    (data: WorkflowDto) => {
      updateWorkflow(data);
      hideUploadModal();
      message.success(intl.formatMessage({ id: 'AddedRevisions.message.documentsAdded' }));
    },
    [updateWorkflow, hideUploadModal, intl]
  );

  const [loading, setLoading] = React.useState(false);

  const onRemove = useCallback(
    async (document: WorkflowApprovalDocumentDto) => {
      const requestBody = {
        workflowId: workflow.id,
        workflowNodeId: selectedNode.id,
        approvalDocumentId: document.id,
      };
      setLoading(true);
      const [err, result] = await api.project.workflow.workflowNode.removeDocument(requestBody);

      if (!!err) {
        processApiError(err);
      } else {
        updateWorkflow(result.data);
      }
      setLoading(false);
    },
    [workflow, selectedNode]
  );

  const createUploadData = useCallback<CreateDocumentUploadData<WorkflowDto>>(
    (data) => {
      const createDto: WorkflowNodeAddDocumentDto = {
        ...documentCreateFormDataToCreateDto(data),
        workflowNodeId: selectedNode.id,
        workflowId: workflow.id,
      };
      return {
        createSaveRequest: (data) =>
          api.project.workflow.workflowNode.addDocument(
            injectDocumentIdsToCreateDto(createDto, data),
            data.ctSource.token
          ),
      };
    },
    [workflow, selectedNode]
  );

  const canUpload = isSolver && selectedNode.state === NodeStateEnum.current;

  const allowedStates = useMemo((): WorkflowStateEnum[] => {
    if (selectedNode.permittedActions.some((action) => action === WorkflowNodePermittedActionsEnum.stateFromNode)) {
      return selectedNode.permittedActions.reduce((permittedStates, action) => {
        switch (action) {
          case WorkflowNodePermittedActionsEnum.statePublish:
            return [...permittedStates, WorkflowStateEnum.published];
          case WorkflowNodePermittedActionsEnum.stateShared:
            return [...permittedStates, WorkflowStateEnum.shared];
          case WorkflowNodePermittedActionsEnum.stateWorkInProgress:
            return [...permittedStates, WorkflowStateEnum.workinprogress];
        }
        return permittedStates;
      }, [] as WorkflowStateEnum[]);
    }
    return getWorkflowAllowedDocumentStates(
      workflow.publishedPermitted,
      workflow.sharedPermitted,
      workflow.workInProgressPermitted
    );
  }, [selectedNode, workflow]);

  const handleFormHideByUploadPermission = useCallback(() => {
    hideNewDocumentForm();
    hideSelectDocuments();
  }, [hideNewDocumentForm, hideSelectDocuments]);

  useLostPermissionEffect(handleFormHideByUploadPermission, [canUpload]);

  const currentUser = useCurrentProjectUser();
  const disabledDocuments = useCallback(
    (document: DirectoryContentDto, directory: ConnectedDirectory) =>
      isWorkflowDocumentDisabled(document, directory, currentUser, intl, allowedStates),
    [currentUser, intl]
  );

  const handleRevisionPermissionRemove = useCallback(() => {
    hideUploadModal();
    hideNewDocumentForm();
    onCancelSelectedDocuments();
    hideSignDocumentsOnline();
    hideSignedDocumentUpload();
  }, [
    hideNewDocumentForm,
    hideSignDocumentsOnline,
    hideSignedDocumentUpload,
    hideUploadModal,
    onCancelSelectedDocuments,
  ]);

  useLostPermissionEffect(handleRevisionPermissionRemove, [canUpload], selectedNode?.id);

  const submitSelectedDocuments = useCallback(async () => {
    hideSelectDocuments();

    const requestBody = {
      workflowId: workflow.id,
      workflowNodeId: selectedNode.id,
      documentIds: modalDocuments.map((document) => document.id),
    };
    setLoading(true);
    const [err, result] = await api.project.workflow.workflowNode.assignDocument(requestBody);

    if (!!err) {
      const error = processApiError(err);
      message.error(
        `${intl.formatMessage({
          id: `AddedRevisions.message.documentsAddedError`,
        })} \n(${intl.formatMessage({
          id: `serviceError.${error.referenceErrorCode}`,
        })})`
      );
    } else {
      updateWorkflow(result.data);
      message.success(intl.formatMessage({ id: 'AddedRevisions.message.documentsAssigned' }));
    }
    setLoading(false);
    setModalDocuments([]);
  }, [modalDocuments]);

  const availableDocuments = useMemo(
    () => workflow.workflowApprovalDocuments.filter((d) => d.state === WorkFlowApprovalDocumentStateEnum.used),
    [workflow.workflowApprovalDocuments]
  );

  const getOriginalUrl = useSameCallback(async () => {
    const document = availableDocuments.find((d) => d.id === previewId);
    const [err, result] = await api.project.documents.getDocumentRevisionDownloadUrlById(
      document?.document?.id,
      document?.revision?.id,
      PREVIEW_ORIGINAL_URL_EXPIRATION
    );
    if (!err) {
      return result.data.url;
    }
    return undefined;
  });

  const transformItems = useCallback((item) => PREVIEW_TRANSFORM(item, getOriginalUrl), [getOriginalUrl]);

  const signingStateOptions = useMemo<IOption<SignedStateTypeEnum>[]>(
    () => [
      {
        id: SignedStateTypeEnum.signed,
        title: intl.formatMessage({ id: 'WorkflowDetailPage.addedRevisions.signingState.signed' }),
      },
      {
        id: SignedStateTypeEnum.unsigned,
        title: intl.formatMessage({ id: 'WorkflowDetailPage.addedRevisions.signingState.unsigned' }),
      },
    ],
    [intl]
  );

  const commentStateOptions = useMemo<IOption<ComentedStateTypeEnum>[]>(
    () => [
      {
        id: ComentedStateTypeEnum.commented,
        title: intl.formatMessage({ id: 'WorkflowDetailPage.addedRevisions.commentedState.commented' }),
      },
      {
        id: ComentedStateTypeEnum.clean,
        title: intl.formatMessage({ id: 'WorkflowDetailPage.addedRevisions.commentedState.clean' }),
      },
    ],
    [intl]
  );

  const filters = useMemo<FrontendFilter<WorkflowApprovalDocumentDto>[]>(
    () => [
      createFrontendMultiTextFilter(
        'name',
        (workflowDocument) => [workflowDocument.document.name, workflowDocument.document.description],
        {
          label: <Fmt id="general.name" />,
        }
      ),
      createFrontendLabelsFilter('labels', (workflowDocument) => workflowDocument.document?.labels),
      createFrontendExtensionFilter('extension', (workflowDocument) => workflowDocument.document?.ext),
      createFrontendSingleSelectFilter(
        'signingStatus',
        { label: <Fmt id="AddedRevisions.filter.signature" />, options: signingStateOptions },
        (workflowDocument) =>
          currentRevisionSelector(workflowDocument).secondaryFiles.some(
            (secondaryFile) => secondaryFile.blobType === SecondaryBlobTypeEnum.signed
          )
            ? SignedStateTypeEnum.signed
            : SignedStateTypeEnum.unsigned
      ),
      createFrontendSingleSelectFilter(
        'commentStatus',
        { label: <Fmt id="AddedRevisions.filter.comment" />, options: commentStateOptions },
        (workflowDocument) =>
          currentRevisionSelector(workflowDocument).annotations.length
            ? ComentedStateTypeEnum.commented
            : ComentedStateTypeEnum.clean
      ),
    ],
    [signingStateOptions, commentStateOptions]
  );

  const { orderedItems, clearFilters, hasFilteredOutItems, ...filterProps } = useFrontendFilters(
    filters,
    ORDER_OPTIONS,
    availableDocuments,
    FILTERS_PERSISTENT_KEY
  );

  const canSignDocuments = useMemo(
    () => selectedNode.permittedActions?.some((action) => action === WorkflowNodePermittedActionsEnum.signDocument),
    [selectedNode]
  );

  const handleOnlineEditClose = useCallback(() => {
    hideDocumentOnlineEditor();
    setSelectedEditingDocument(undefined);
  }, [hideDocumentOnlineEditor]);

  const rowRenderer = useCallback(
    ({ index, key, isScrolling, isVisible, style }) => {
      if (isScrolling && !isVisible) {
        return (
          <StackPanel vertical style={style} key={key}>
            <Skeleton.Input active block />
            <Skeleton.Input active block />
          </StackPanel>
        );
      }
      const approvalDocument = orderedItems[index];
      return (
        <ApprovalDocumentRevisionInfo
          key={key}
          approvalDocument={approvalDocument}
          selectedNodeId={selectedNode.id}
          permittedActions={selectedNode.permittedActions}
          canPerformActions={canUpload}
          onUpload={setSelectedDocument}
          onRemove={onRemove}
          onDownload={handleDocumentDownload}
          onSignOnline={handleSignDocumentOnline}
          onSignUpload={handleUploadSignDocument}
          setPreviewId={setPreviewId}
          onEditOnline={handleOnlineEditDocument}
          onAnnotate={handleAnnotateDocument}
          style={{ ...style, height: style.height - APPROVAL_DOCUMENT_ROW_SPACING }}
        />
      );
    },
    [
      canUpload,
      handleAnnotateDocument,
      handleDocumentDownload,
      handleOnlineEditDocument,
      handleSignDocumentOnline,
      handleUploadSignDocument,
      onRemove,
      orderedItems,
      selectedNode,
      setPreviewId,
      setSelectedDocument,
    ]
  );

  return (
    <>
      <SpinBoxCenter spinning={loading} overflowVisible>
        <Section
          title={<Fmt id="AddedRevisions.title" />}
          rightContent={
            !!selectedNode.permittedActions?.some(
              (action) => action === WorkflowNodePermittedActionsEnum.addDocument
            ) && (
              <FlowLayout wrap>
                <Button onClick={showSelectDocuments} icon={<FileAddOutlined />} type="primary" disabled={!canUpload}>
                  <Fmt id="AddedRevisions.buttons.selectDocuments" />
                </Button>
                <Button onClick={showNewDocumentForm} icon={<UploadIcon />} type="primary" disabled={!canUpload}>
                  <Fmt id="AddedRevisions.buttons.uploadDocument" />
                </Button>
              </FlowLayout>
            )
          }
        >
          <Margin top>
            <DocumentsGridHeader
              disableSelect
              order={<OrderSelect {...filterProps} />}
              filters={<FilterToolbar clearFilters={clearFilters} hasFilteredOutItems {...filterProps} />}
            />
            <div className={styles.documentList}>
              {orderedItems?.length ? (
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <List
                      height={
                        Math.min(MAX_APPROVAL_DOCUMENT_IN_LIST, orderedItems.length) * APPROVAL_DOCUMENT_ROW_HEIGHT
                      }
                      overscanRowCount={OVERSCAN_ROW_COUNT}
                      rowCount={orderedItems?.length}
                      rowHeight={APPROVAL_DOCUMENT_ROW_HEIGHT}
                      rowRenderer={rowRenderer}
                      width={width}
                    />
                  )}
                </AutoSizer>
              ) : (
                <EmptyGate
                  empty={!orderedItems.length}
                  clearSearch={clearFilters}
                  hasUnfilteredItems={hasFilteredOutItems}
                  noFilteredItemsMessage={<Fmt id="ListEmpty.noItemsFound" />}
                />
              )}
            </div>
          </Margin>
        </Section>
      </SpinBoxCenter>
      <CommonDerivativesFileViewer
        items={orderedItems}
        previewId={previewId}
        setPreviewDocumentId={setPreviewId}
        visible={previewVisible}
        onCancel={hidePreview}
        transform={transformItems}
      />
      <UploadApprovalRevisionModal
        visible={uploadModalVisible}
        onClose={hideUploadModal}
        onSubmit={handleSubmit}
        workflowId={workflow.id}
        workflowNodeId={selectedNode.id}
        approvalDocument={selectedDocument}
        allowedStates={allowedStates}
        canAddSignedDocument={canSignDocuments}
      />
      <FolderValidationMasksContextProvider>
        <DocumentCreateFormModal
          uploadDisabled={!canUpload}
          open={newDocumentFormVisible}
          onClose={hideNewDocumentForm}
          onSubmit={handleSubmitNewDocumentForm}
          createUploadData={createUploadData}
          preferredDirectories={workflow.workflowDefaultDirectories}
          showDestinationDirectory
          validateUniqueName={TRUE_LAMBDA}
          allowedStates={allowedStates}
          canAddSignedDocument={canSignDocuments}
        />
      </FolderValidationMasksContextProvider>

      <DocumentSelectModal
        title={<Fmt id="AddedRevisions.title" />}
        visible={selectDocumentsVisible}
        onCancel={onCancelSelectedDocuments}
        onOk={submitSelectedDocuments}
        multiple
        selectedDocuments={modalDocuments}
        setSelectedDocuments={handleSelectedDocuments}
        isCancelable
        disabledDocuments={disabledDocuments}
      />
      {pdfTronLicenseKey && (
        <PdfSignatureModal
          pdfTronLicenseKey={pdfTronLicenseKey}
          visible={signDocumentsOnlineVisible}
          document={selectedSigningDocument}
          workflowNodeId={selectedNode?.id}
          workflowId={workflow.id}
          onClose={hideSignDocumentsOnline}
          onSubmit={handleSignedDocumentSubmit}
        />
      )}
      <RevisionSignedDocumentFileCreateFormModal
        documentId={selectedSigningDocument?.id}
        revisionId={selectedSigningDocument?.currentRevision?.id}
        workflowNodeId={selectedNode?.id}
        open={uploadSignedDocumentVisible}
        signedFileName={selectedSigningDocument?.name || selectedSigningDocument?.primaryFile?.name}
        signedSecondaryFileId={selectedSignedDerivate?.id}
        titleId="RevisionSignedDocumentAddFormModal.title"
        onSubmit={handleSignedDocumentSubmit}
        onClose={hideSignedDocumentUpload}
      />
      <DocumentOnlineEditorModal
        visible={documentOnlineEditorVisible}
        document={selectedEditingDocument}
        revisionId={selectedEditingDocument?.currentRevision?.id}
        documentName={selectedEditingDocument?.name}
        onClose={handleOnlineEditClose}
        workflowNodeId={selectedEditingDocument?.workflowNodeId}
        frameKey="wfNode"
        allowedStates={allowedStates}
      />
    </>
  );
};
