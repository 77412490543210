import { EditButton } from 'components/ActionButtons';
import { DefaultEditableTreeNode, useEditableTreeItemContext } from 'components/EditableTree/EditableTreeItemContext';
import React from 'react';

type Props = {
  onEdit: (id: string) => void;
};

export const EditableTreeEditButton = ({ onEdit }: Props) => {
  const { item } = useEditableTreeItemContext<DefaultEditableTreeNode>();

  return <EditButton onClick={() => onEdit(item.id)} />;
};
