import { IntlMessagesShape } from './cs';

// prettier-ignore
export const enMessages: IntlMessagesShape = {
  'AccessLevel.label': 'Access level',
  'AccessLevel.inheritedPermission': 'Inherited Permission',
  'AccessLevel.notSet': 'Not set',
  'AccessLevel.tooltips.none': 'None',
  'AccessLevel.tooltips.read': 'Read',
  'AccessLevel.tooltips.write': 'Write',
  'AccessLevel.tooltips.admin': 'Admin',
  'AccessLevel.tooltips.admin.label': 'Admin full access',
  'AccessLevel.tooltips.none.label': 'None access',
  'AccessLevel.tooltips.read.label': 'Read only',
  'AccessLevel.tooltips.write.label': 'Write and read',
  'AccessLevel.tooltips.admin.description': 'Full access, document and folder creation, including changing folder structure and access rights adjustments.',
  'AccessLevel.tooltips.none.description': 'Without any access.',
  'AccessLevel.tooltips.read.description': 'Viewing and downloading documents only.',
  'AccessLevel.tooltips.write.description': 'View, download and upload documents.',
  'AccessTokenExpiredModal.detail': 'Try signing in again or contact AspeHub support.',
  'AccessTokenExpiredModal.error': 'There was an error during the sign-in request. Please try again or refresh the page.',
  'AccessTokenExpiredModal.title': 'Your session has expired. Please log in.',
  'ActiveTaskDetail.addRequest': 'Add request',
  'ActiveTaskDetail.backgroundDocuments': 'Background documents',
  'ActiveTaskDetail.comment': 'Comment',
  'ActiveTaskDetail.parallelInitialization.button': 'Set parallel tasks',
  'ActiveTaskDetail.parallelInitialization.subworkflows': 'Parallel tasks',
  'ActiveTaskDetail.parallelInitialization.title': 'Parallel tasks settings',
  'ActiveTaskDetail.parallelInitialization.validation.error': 'Number of active branches must be at least {value}',
  'ActiveTaskDetail.parallelOverview.label': 'Parallel task states',
  'ActiveTaskDetail.parallelOverview.subworkflowState.initialized': 'Initialized',
  'ActiveTaskDetail.parallelOverview.subworkflowState.wait': 'Waiting for initialization',
  'ActiveTaskDetail.parallelOverview.subworkflowState.out': 'Omitted',
  'ActiveTaskDetail.parallelOverview.subworkflowState.finished': 'Finished',
  'ActiveTaskDetail.voteLockedWarning': 'There was a change in background documents.',
  'ActiveTaskDetail.voteLockedError':
    'There was a change in background documents. The workflow was refreshed, please review the changes before voting again.',
  'ActiveTaskDetail.votingRoleSelect': 'Voting roles selection',
  'ActiveTaskDetail.votingMessage': 'Voting message',
  'AdminListPanel.addFormTitle': 'Add administrator',
  'Administration.downloadDMSMetaData.buttonTitle': 'Download DMS including logs and metadata',
  'Administration.downloadDMSMetaDataModal.modalTitle': 'Downloading DMS including logs and metadata',
  'Administration.downloadDMSMetaDataModal.formRadio.actualRevisions': 'Current revisions',
  'Administration.downloadDMSMetaDataModal.formRadio.allRevisions': 'All revisions',
  'Administration.downloadDMSMetaDataModal.submit.prepareDocuments': 'Prepare documents',
  'Administration.downloadDMSMetaDataModal.submit.processing': 'Processing',
  'Administration.downloadDMSMetaDataModal.file.metadata': 'Metadata',
  'Administration.downloadDMSMetaDataModal.file.activity': 'Activity',
  'Administration.downloadDMSMetaDataModal.file.dms': 'DMS',
  'AdminPageStressTest.testName.label': 'Test name',
  'AdminPageStressTest.testData.label': 'Test data',
  'AdminPageStressTest.Run.button': 'Run test',
  'AdminPageStressTest.Result.label': 'Result',
  'NodePortActionTypeEnum.accept.button': 'Approve',
  'NodePortActionTypeEnum.accept.confirm': 'Approve this task',
  'NodePortActionTypeEnum.accept.vote': 'Approved',
  'NodePortActionTypeEnum.refuse.button': 'Refuse',
  'NodePortActionTypeEnum.refuse.confirm': 'Refuse this task',
  'NodePortActionTypeEnum.refuse.vote': 'Refused',
  'NodePortActionTypeEnum.process.button': '{action}',
  'NodePortActionTypeEnum.process.confirm': 'Choose {action}',
  'NodePortActionTypeEnum.process.vote': '{action}',
  'NodePortActionTypeEnum.parallel.button': 'Parallel execution',
  'NodePortActionTypeEnum.parallel.confirm': 'Parallel execution',
  'NodePortActionTypeEnum.parallel.vote': 'Parallel execution',
  'NodePortActionTypeEnum.continue': 'Continue',
  'ActiveProjectGate.backToProjectList.ButtonText': 'Return to the list of projects',
  'ActiveTaskDetail.taskDescription': 'Task description',
  'ActiveTaskDetail.taskDescription.edit': 'Edit task description',
  'ActiveTaskDetail.resolver': 'Resolver',
  'ActiveTaskDetail.resolvers': 'Resolvers',
  'ActiveTaskDetail.Resolver.delegate': 'Delegate',
  'ActiveTaskDetail.Resolver.delegate.title': 'Delegate role',
  'ActiveTaskDetail.Resolver.delegate.newResolver': 'New resolver',
  'ActiveTaskDetail.Resolver.delegate.noResolver': 'Please select the new resolver',
  'ActiveTaskDetail.Resolver.delegate.message': 'Message for the new resolver',
  'ActiveTaskDetail.resolver.noVote': 'Resolver did not vote',
  'ActiveTaskDetail.resolver.noVote.yet': 'Resolver did not vote yet',
  'ActiveTaskDetail.schema': 'Schema',
  'ActiveTaskDetail.taskName': 'Task name',
  'ActiveTaskDetail.timeoutAction.title': 'Action after the deadline',
  'ActiveTaskDetail.timeoutAction.wait': 'No action',
  'ActiveTaskDetail.timeoutAction.selectDefault': 'Use specific output',
  'ActiveTaskDetail.timeoutAction.output': 'Output',
  'ActiveTaskDetail.timeoutAction.closeWorkflow': 'Terminate the workflow instance',
  'ActiveTaskDetail.timeoutAction.accordingToVote': 'According to the vote',
  'ActiveTaskDetail.timeoutAction.evalLateAsAccept': `Didn't vote ⇒ approves`,
  'ActiveTaskDetail.timeoutAction.evalLateAsRefuse': `Didn't vote ⇒ rejects`,
  'ActiveTaskDetail.timeoutAction.tooltip': 'With this option, it can be determined that the solvers who did not vote on the deadline approved/rejected the task in question.',
  'ActiveTaskDetail.term': 'Deadline',
  'ActiveTaskDetail.term.title': 'Deadline: {date}',
  'ActiveTaskDetail.term.maxExpectedTime': '(maximal expected: {maxExpectedDate})',
  'ActiveTaskDetail.term.maxExpectedTime.notDetermined': 'Not determined',
  'ActiveTaskDetail.workflowState.started': 'Active',
  'ActiveTaskDetail.workflowState.finished': 'Finished',
  'ActiveTaskDetail.workflowState.criticalError': 'Critical error',
  'ActiveTaskDetail.workflowState.error': 'Error',
  'ActiveTaskDetail.workflowState.warning': 'Warning',
  'ActiveTaskDetail.workflowState.closed': 'Closed',
  'ActiveTaskDetail.workflowNodeState': 'State',
  'ActiveTaskDetail.workflowNodeState.waiting': 'Waiting',
  'ActiveTaskDetail.workflowNodeState.current': 'Active',
  'ActiveTaskDetail.workflowNodeState.out': 'Unused/Unreachable',
  'ActiveTaskDetail.workflowNodeState.processed': 'Processed',
  'ActiveTaskDetail.workflowNodeState.accepted': 'Accepted',
  'ActiveTaskDetail.workflowNodeState.refused': 'Refused',
  'ActiveTaskDetail.workflowNodeState.over': 'Returned',
  'ActiveTaskDetail.workflowNodeState.init': 'Initialization',
  'ActiveTaskDetail.workflowNodeState.outOver': 'Not used, previously processed',
  'ActiveTaskDetail.workflowNodeState.closed': 'Closed',
  'ActiveTaskDetail.workflowNodeState.closedIn': 'Closed in parallel initialization',
  'ActiveTaskDetail.workflowNodeStateTitle': '{date}',
  'ActiveTaskDetail.notification.title': 'Notification data',
  'ActiveTaskDetail.notification.recipients': 'Recipients',
  'ActiveTaskDetail.notification.subject': 'Subject',
  'ActiveTaskDetail.notification.message': 'Message body',
  'ActiveTaskDetail.notification.noData': 'No data',
  'AddedRevisions.title': 'Approval documents changes',
  'AddedRevisions.addDocuments.title': 'Add documents',
  'AddedRevisions.buttons.selectDocuments': 'Select documents',
  'AddedRevisions.buttons.uploadDocument': 'Upload new',
  'AddedRevisions.filter.signature': 'Signature',
  'AddedRevisions.filter.comment': 'Comment',
  'AddedRevisions.message.documentsAdded': 'Document has been uploaded and added to WF',
  'AddedRevisions.message.documentsAssigned': 'Documents have been added to WF',
  'AddedRevisions.message.documentsAddedError': 'Error adding documents to WF.',
  'AddedRevisions.message.noDerivateData': 'The selected revision of the document cannot be commented, it does not have a PDF derivative',
  'AdditionalInformation.constructionBillingData': 'Construction billing data',
  'AdditionalInformation.contractorContract': 'Contractor contract',
  'AdditionalInformation.CustomerContract': 'Customer contract',
  'AdditionalInformation.district': 'District',
  'AdditionalInformation.firm': 'Firm',
  'AdditionalInformation.forTheContractor': 'For the contractor',
  'AdditionalInformation.forTheCustomer': 'For the customer',
  'AdditionalInformation.function': 'Function',
  'AdditionalInformation.functionDescription': 'Function description',
  'AdditionalInformation.name': 'Name',
  'AdditionalInformation.placeOfConstruction': 'Place of construction',
  'AdditionalInformation.placeOfInvoicing': 'Place of invoicing',
  'AdditionalInformation.wipItems': 'Signatures WIP items',
  'AdditionalInformation.thirdPartySignatures': 'Third party signatures',
  'AdditionalInformation.where': 'Where',
  'AdditionalInformation.wipReport': 'WIP report',
  'AllDocumentPage.deletedTargetDirectory.error': 'Folder <strong>{name}</strong> was discarded.',
  'AllDocumentPage.deletedTargetDirectory.lastPath': 'Original location: {path}',
  'AllDocumentsPage.forbiddenFilesWarning': 'Cannot download all documents',
  'AllDocumentsPage.downloadOtherFiles': 'Download others',
  'AllDocumentsPage.docMenu.annotate': 'Annotate',
  'AllDocumentsPage.docMenu.createDirectory': 'New Folder',
  'AllDocumentsPage.docMenu.tooltip': 'Folder options',
  'AllDocumentsPage.docMenu.closeSettings': 'Close settings',
  'AllDocumentsPage.docMenu.createAssignment': 'New task',
  'AllDocumentsPage.docMenu.discard': 'Discard',
  'AllDocumentsPage.docMenu.delete': 'Delete',
  'AllDocumentsPage.docMenu.displaySubfolders': 'Display subfolders',
  'AllDocumentsPage.docMenu.documentEditor': 'Edit document',
  'AllDocumentsPage.docMenu.editOnline': 'Edit',
  'AllDocumentsPage.docMenu.expandAll': 'Expand All',
  'AllDocumentsPage.docMenu.collapseAll': 'Collapse All',
  'AllDocumentsPage.docMenu.openSettings': 'Settings',
  'AllDocumentsPage.docMenu.signDocument': 'Sign doc.',
  'AllDocumentsPage.docMenu.upload': 'Upload',
  'AllDocumentsPage.docMenu.uploadDocument': 'New document',
  'AllDocumentsPage.docMenu.uploadMultiple': 'Upload Multiple',
  'AllDocumentsPage.favoriteFilterActive': 'Only favorite folders are shown.',
  'AllDocumentsPage.searchFilterActive': `Searching "{text}" in folder name.`,
  'AllDocumentsPage.docMenu.discard.disabled.byRights': 'You do not have sufficient permission to discard this folder',
  'AllDocumentsPage.docMenu.discard.disabled.byEsticon': 'The directory or its subdirectory is reserved for import of documents from AspeEsticon',
  'AllDocumentsPage.directory.report.button.tooltip': 'List of documents',
  'AllDocumentsPage.directory.report.modalTitle': 'List of documents',
  'AllDocumentsPage.directory.report.modalOkText': 'Export',
  'AllDocumentsPage.directory.report.form.authorOrder': 'According to author of revision in period',
  'AllDocumentsPage.directory.report.form.createTimeOrder': 'By revision upload date and time',
  'AllDocumentsPage.directory.report.form.documentNameOrder': 'By document name',
  'AllDocumentsPage.directory.report.form.documentPathOrder': 'According to the path to the document',
  'AllDocumentsPage.directory.report.form.authorFilterTitle': 'Author of revision',
  'AllDocumentsPage.directory.report.form.revisionChangeDateTitle': 'Revision change date',
  'AllDocumentsPage.directory.report.form.checkBoxIncludeSubdirectories': 'Include subdirectories',
  'AllDocumentsPage.directory.report.form.checkBoxIncludeAllRevisions': 'Include all revisions',
  'AllDocumentsPage.directory.report.form.clearDatepickers': 'Clear',
  'AllDocumentsPage.downloadErrorsModal.documents': 'Documents:',
  'AllDocumentsPage.metadataDefinitions.loading.Error': 'Failed to load metadata definitions',
  'AllDocumentsPage.metadataDefinitions.noDefinitions.Error': 'No metadata definitions are set for the documents',
  'AllDocumentsPageFileToolbar.favoriteChanged.success': 'You have successfully changed your favorite settings',
  'AllDocumentsPageFileToolbar.favoriteChanged.successParcial': 'For some documents, the favorite setting did not change',
  'AllDocumentsPageFileToolbar.favoriteChanged.failure': 'Favorites settings have not been changed for any of the documents',
  'AllDocumentsPageFileToolbar.favoriteChanged.documentError.NotFound': 'File not found',
  'AllDocumentsPageFileToolbar.favoriteChanged.documentError.IsDiscarded': 'The file {fileName} has been removed',
  'AllDocumentsPageFileToolbar.favoriteChanged.documentError.BadDirectory': 'The file {fileName} is no longer in the directory',
  'AllDocumentsPageFileToolbar.favoriteChanged.documentError.AccessDenied': 'You do not have sufficient permissions to change the file {fileName} attribute ',
  'AllDocumentsPageFileToolbar.favoriteChanged.documentError.PropertySet': 'The file {fileName} was already added to Favorites',
  'AllDocumentsPageFileToolbar.favoriteChanged.documentError.PropertyUnset': 'File {fileName} has already been removed from Favorites',
  'AllDocumentsPageFileToolbar.favoriteChanged.linkError.NotFound': 'Document shortcut not found',
  'AllDocumentsPageFileToolbar.favoriteChanged.linkError.DocumentIsDiscarded': 'The shortcut {linkName} leads to the removed file',
  'AllDocumentsPageFileToolbar.favoriteChanged.linkError.LinkAccessDenied': 'You do not have sufficient permissions to shortcut {linkName}',
  'AllDocumentsPageFileToolbar.favoriteChanged.linkError.DocumentAccessDenied': 'You do not have sufficient permissions to the document binded by the shortcut {linkName}',
  'AllDocumentsPageFileToolbar.favoriteChanged.linkError.PropertySet': 'The document binded by the {linkName} shortcut was already added to Favorites',
  'AllDocumentsPageFileToolbar.favoriteChanged.linkError.PropertyUnset': 'The document binded by the {linkName} shortcut has already been removed from Favorites',
  'AllDocumentsPageDirectoryToolbar.directoryChoice': 'Directory',
  'AllDocumentsPageDirectoryToolbar.directoryLinkChoice': 'Directory shortcut',
  'AllDocumentsPageDirectoryTree.NoMatchingDirs': 'No matching folders.',
  'AllDocumentPageChangeViewButton.commonView':'Common view',
  'AllDocumentPageChangeViewButton.metadata':'Metadata',
  'ApprovalDocumentRevisionInfo.cannotChangeEsticonDocument': 'Document is imported from Esticon and cannot be modified.',
  'ApprovalDocumentRevisionInfo.noRevisionAdded': 'Revision was not changed.',
  'ApprovalDocumentRevisionInfo.addRevision': 'Upload a new revision',
  'ApprovalDocumentRevisionInfo.documentPreview': 'Document preview',
  'ApprovalDocumentRevisionInfo.edit': 'Edit',
  'ApprovalDocumentRevisionInfo.annotate': 'Annotate',
  'ApprovalDocumentRevisionInfo.removeDocumentFromWf': 'Remove from WF',
  'AppUserShareFormModal.form.selectGroup': 'Add users from group',
  'AppUserShareFormModal.form.users': 'Sharing users',
  'AppUserShareFormModal.send': 'Send',
  'AppUserShareFormModal.form.items.message.initialValue.document':
    'Hi! I would like to share the attached document with you.',
  'AppUserShareFormModal.form.items.message.initialValue.folder':
    'Hi! I would like to share the attached folder with you.',
  'AppUserShareFormModal.form.items.message.label': 'Message for recipient',
  'AppUserShareFormModal.title.document': 'Document Sharing',
  'AppUserShareFormModal.title.directory': 'Folder Sharing',
  'AppUsersAddFormModal.title': 'Add administrators AspeHub',
  'AppUsersFormItem.addCurrentUsers.aspeHub': 'Add from AspeHub',
  'AppUsersFormItem.addCurrentUsers.organization': 'Add from organization',
  'AppUsersFormItem.addCurrentUsers.disable.aspeHub': 'The list of available AspeHub users is empty.',
  'AppUsersFormItem.addCurrentUsers.disable.organization': 'All users of the organization are already on the project. The list of available organization users is empty.',
  'AppUsersFormItem.changeCurrentUsers': 'Change current users list',
  'AppUsersFormItem.removeCurrentUsers': 'Remove current users list',
  'AppUsersFormItem.selectCurrentUsers': 'Select current users',
  'AppUsersFormItem.addNewUsers': 'Add new',
  'AppUsersFormItem.changeNewUsers': 'Change new users list',
  'AppUsersFormItem.removeNewUsers': 'Remove new users list',
  'AspeEsticonAddForm.serverURL':'Server URL',
  'AspeEsticonAddForm.firmId':'Database ID',
  'AspeEsticonListPanel.title':'AspeEsticon application',
  'AspeEsticonListPanel.noRecordInDb':'No record in the database matches the entered value',
  'AspeEsticonListPanel.delete.confirmTitle':'Remove AspeEsticon application?',
  'AspeEsticonListPanel.add.title':'Add AspeEsticon application',
  'AspeEsticonListPanel.edit.title':'Edit AspeEsticon application',
  'AspeEsticonPermissionPanel.title':'AspeEsticon database',
  'MDApprovedProjectsReport.actionName':'Action name',
  'MDApprovedProjectsReport.displayMode.all': 'All',
  'MDApprovedProjectsReport.displayMode.justMine': 'Only mine',
  'MDApprovedProjectsReport.displayMode.justMyDivision':'My division',
  'MDApprovedProjectsReport.projectPhase':'CK material state',
  'MDApprovedProjectsReport.projectState': 'CK material phase',
  'MDApprovedProjectsReport.lastActualizationDate':'Last actualization date',
  'MDApprovedProjectsReport.termExpired':'Term expired',
  'MDApprovedProjectsReport.delete.title': 'Delete CK material',
  'MDApprovedProjectsReport.delete.message': 'Do you really want to delete CK material?',
  'MDApprovedProjectsReport.deletingInProgress':'Action - CK material deletion is in progress',
  'MDApprovedProjectsReport.prepareCkApprovals.create': 'Create material approval',
  'MDApprovedProjectsReport.prepareCkApprovals.create.success': 'Material approval successfully created',
  'MDApprovedProjectsReport.prepareCkApprovals.edit': 'Edit material approval',
  'MDApprovedProjectsReport.prepareCkApprovals.edit.success': 'Material approval successfully edited',
  'MDApprovedProjectsReport.prepareCkApprovals.list': 'List of material approvals',
  'MDApprovedProjectsReport.prepareCkApprovals.list.number': 'Number',
  'MDApprovedProjectsReport.prepareCkApprovals.list.date': 'Date',
  'MDApprovedProjectsReport.prepareCkApprovals.list.time': 'Time',
  'MDApprovedProjectsReport.prepareCkApprovals.list.ckIdentification': 'CK Identification',
  'MDApprovedProjectsReport.prepareCkApprovals.list.referenceCode': 'Reference code',
  'MDApprovedProjectsReport.filters.referenceCodeOrNumber': 'Reference code or number',
  'MDApprovedProjectsReport.meetingDocument.inProgress': 'Meeting document generation in progress',
  'MdApprovalProjectsReport.ckApprovalMeetings': 'CK approval meetings list',
  'MDMeetingResultModal.title': 'Entering the result of the CK meeting',
  'MDMeetingResultModal.item.meetingDate': 'CK Meeting date',
  'MDMeetingResultModal.item.ckMeetingMomentsLink': 'Link to CK meeting moments',
  'MDMeetingResultModal.message.success': 'Meeting results were successfully distributed to projects',
  'MdMeetingBackgroundMdMeetingStatusEnum.inProcess': 'In progress',
  'MdMeetingBackgroundMdMeetingStatusEnum.resolved': 'Finished',
  'MdMeetingBackgroundMdMeetingStatusEnum.canceled': 'Canceled',
  'MDMeetingResultModal.button.submit': 'Save and complete meeting',
  'ApprovedProjectReport.menu.projectCard':'CK material card',
  'ApprovedProjectReport.menu.goToDMS':'Go to DMS',
  'ApprovedProjectReport.menu.deleteProject':'Delete CK material',
  'ApprovedProjectReport.menu.newIntention':'New intention',
  'ApprovedProjectReport.menu.newActualization':'New actualization',
  'AspeHubUsersListPanel.addModal.title': 'Add users',
  'AssignmentDocumentsField.documentIsAlreadySelected': 'Document is already selected',
  'AssignmentDocumentsField.unableToAddDocument.content': 'The document was successfully uploaded, but we were unable to add it to the task. ' +
    'You can try to add it manually as an existing document instead.',
  'AssignmentDocumentsField.addExistingDocument': 'Add an existing document',
  'AssignmentDocumentsField.uploadNewDocument': 'Upload a new document',
  'AssignmentDocumentsField.removeDocument': 'Remove document',
  'AssignmentDocumentsField.selectDocuments': 'Select documents',
  'CommonExportFormModal.title.assignments': 'Task list export',
  'CommonExportFormModal.title.projects': 'Project list export',
  'CommonExportFormModal.title.selectFormat': 'Select a file format',
  'CommonExportFormModal.columnName.name': 'Name',
  'CommonExportFormModal.columnName.project': 'Project',
  'CommonExportFormModal.columnName.type': 'Type',
  'CommonExportFormModal.columnName.state': 'State',
  'CommonExportFormModal.columnName.taskGiver': 'Task giver',
  'CommonExportFormModal.columnName.representative': 'Representative of task giver (list of representatives)',
  'CommonExportFormModal.columnName.createDate': 'Create date',
  'CommonExportFormModal.columnName.deadline': 'Deadline (required term)',
  'CommonExportFormModal.columnName.resolvedDate': 'Resolved date',
  'CommonExportFormModal.columnName.content': 'Content',
  'CommonExportFormModal.columnName.resolvers': 'Resolvers (list of resolvers)',
  'CommonExportFormModal.columnName.projectName': 'Project name',
  'CommonExportFormModal.columnName.description': 'Description',
  'CommonExportFormModal.columnName.organization': 'Organization',
  'CommonExportFormModal.columnName.labels': 'Labels',
  'AssignmentForm.error.currentUserRequiredInAssociates': 'Current user is required in associates if task giver is someone else',
  'AssignmentForm.error.userDuplicateInAssociates': 'Same user cannot be in associates and task giver at the same time',
  'AssignmentForm.deadlineDate': 'Deadline date',
  'AssignmentForm.content': 'Content',
  'AssignmentForm.bcfDetail': 'BCF Detail',
  'AssignmentForm.solverIds': 'Solvers',
  'AssignmentForm.assignmentDocuments': 'Documents',
  'AssignmentForm.associateIds': 'Associates',
  'AssignmentForm.name': 'Task name',
  'AssignmentForm.name.required': 'Please enter the task name',
  'AssignmentForm.type': 'Task type',
  'AssignmentForm.taskGiver': 'Task giver',
  'AssignmentFormModal.projectSelect': 'Please select a project in which you want to create a task',
  'AssignmentFormModal.projectSelect.title': 'Project select',
  'AssignmentFormModal.result.success': 'Task created successfully',
  'AssignmentFormModal.title': 'Create a new task',
  'AssignmentProjectCheck.youMustSwitchProject': 'You must switch to this task\'s project in order to deal with it.',
  'AssignmentProjectCheck.switchProject': 'Switch to project',
  'AssignmentProjectCheck.targetProject': 'Project to switch on',
  'AssignmentOverview.created': 'Created',
  'AssignmentOverview.projectNotFound': 'Project not found',
  'AssignmentStateEnum.new': 'New',
  'AssignmentStateEnum.inProgress': 'In progress',
  'AssignmentStateEnum.forApproval': 'For approval',
  'AssignmentStateEnum.solved': 'Solved',
  'AssignmentStateEnum.closed': 'Canceled',
  'AssignmentStateField.startSolving': 'Start solving',
  'AssignmentStateField.submitForApproval': 'Submit for approval',
  'AssignmentStateField.returnToSolver': 'Return to solver',
  'AssignmentStateField.approveAndClose': 'Approve and close',
  'AssignmentStateField.continueSolving': 'Continue solving',
  'AssignmentStateField.cancel': 'Cancel task',
  'AssignmentSolversField.addAssociate': 'Add associate',
  'AssignmentSolversField.addSolvers': 'Add solvers',
  'AssignmentSolversField.removeAssociate': 'Remove associate',
  'AssignmentSolversField.removeSolver': 'Remove solver',
  'AssignmentSolversField.cannotRemoveAssociate': 'Cannot remove this associate user',
  'AssignmentSolversField.cannotRemoveLastSolver': 'Cannot remove the last solver',
  'AssignmentSolversField.cannotRemovePrimarySolver': 'Cannot remove primary solver',
  'AssignmentSolversField.primarySolver': 'Primary solver',
  'AssignmentSolversField.lostAccessWarning':
    'You no longer have access to this task because you removed yourself from the solvers',
  'AssignmentSolversField.makePrimary': 'Make primary solver',
  'AssignmentSourceEnum.createdByUser': 'Created by user',
  'AssignmentSourceEnum.assignedToUser': 'Assigned to user',
  'AssignmentSourceEnum.primarySolver': 'Primary solver',
  'AssignmentSourceEnum.associate': 'Associated user',
  'AssignmentSourceEnum.taskGiver': 'Task giver',
  'AssignmentTypeEnum.userTask': 'User task',
  'AssignmentTypeEnum.userDirectoryPermission': 'Directory permissions settings',
  'AssignmentTypeEnum.uploadDocuments': 'Document upload',
  'AssignmentTypeEnum.comment': 'Comment',
  'AssignmentTypeEnum.defectSolving': 'Defect solving',
  'AssignmentTypeEnum.bcfTask': 'Model task',
  'AttachmentCreateFormModal.title': 'New Attachment',
  'AttachmentCreateFormModal.form.item.relation.label': 'Visibility',
  'AttachmentCreateFormModal.stagedUpload.title': 'Uploading attachments',
  'AuditLogBuildingModal.categories': 'Construction categories',
  'AuditLog.assignment.label': 'Activities on task \'{path}\'',
  'AuditLog.directory.label': 'Activities on folder \'{path}\'',
  'AuditLog.detail.title': 'Detail',
  'AuditLog.document.label': 'Activities on document \'{path}\'',
  'AuditLog.user.label': 'Activities on user \'{username}\'',
  'AuditLog.wfTemplate.label': 'Activities on workflow template \'{wfTemplateName}\'',
  'AuditLog.organization.label': `Activities on organization '{orgName}'`,
  'AuditLog.project.label': `Activities on project '{projectName}'`,
  'AuditLog.mdMaterial.label': `Activities on CK material'{projectName}'`,
  'AuditLog.division.label': `Activities on division '{divisionName}'`,
  'AuditLog.mdUser.label': `Activities on user '{mdUserName}'`,
  'AuditLog.mdRole.label': `Activities on role`,
  'AuditLog.projectTemplate.label': `Activities on template '{projectTemplateName}'`,
  'AuditLog.permissionProfile.label': `Activities on permission profile '{profileName}'`,
  'AuditLog.orgLabel.label': `Activities on label '{orgLabelName}'`,
  'AuditLog.ProjectNote.label': 'Project notes activities',
  'AuditLog.EntityData.Name': 'Name',
  'AuditLog.EntityData.Version': 'Version',
  'AuditLog.EntityData.Description': 'Description',
  'AuditLog.EntityData.DownloadCounter': 'Download Counter',
  'AuditLog.EntityData.ElementId': 'Element ID',
  'AuditLog.EntityData.AttributeName': 'Attribute Name',
  'AuditLog.EntityData.true': 'Yes',
  'AuditLog.EntityData.false': 'No',
  'AuditLog.EntityData.unset': 'Unset',
  'AuditLog.EntityData.Path': 'Path',
  'AuditLog.EntityData.PermissionInheritance': 'Permission Inheritance',
  'AuditLog.EntityData.Username': 'User Name',
  'AuditLog.EntityData.Firstname': 'First Name',
  'AuditLog.EntityData.Lastname': 'Last Name',
  'AuditLog.EntityData.IsModel': 'Document is Model',
  'AuditLog.EntityData.State': 'Document State',
  'AuditLog.EntityData.InstanceName': 'Workflow name',
  'AuditLog.EntityData.InstanceDescription': 'Workflow description',
  'AuditLog.EntityData.Content': 'Annotation content',
  'AuditLog.EntityData.ReservedById': 'Reserved by id',
  'AuditLog.EntityData.ReservedDate': 'Date of reservation',
  'AuditLog.EntityData.DefaultLanguage': 'Default language',
  'AuditLog.EntityData.DefaultTimeZoneId': 'Default time zone',
  'AuditLog.EventData.projectDescription': 'Project description',
  'AuditLog.EventData.orgDescription': 'Organization description',
  'AuditLog.EventData.projectName': 'Project name',
  'AuditLog.EventData.orgName': 'Organization name',
  'AuditLog.EventData.isAdmin': 'User is administrator',
  'AuditLog.EventData.extendedPermission': 'Permissions',
  'AuditLog.EventData.oldValue': 'Old value',
  'AuditLog.EventData.newValue': 'New value',
  'AuditLog.EventData.mail': 'Email',
  'AuditLog.EventData.orgNameOld': 'Old name',
  'AuditLog.EventData.old	': 'Old state',
  'AuditLog.EventData.@new': 'New state',
  'AuditLog.EventData.users': 'Users',
  'AuditLog.EventData.user': 'User',
  'AuditLog.EventData.set': 'Set',
  'AuditLog.EventData.status': 'State',
  'AuditLog.EventData.oldStatus': 'Old state',
  'AuditLog.EventData.removedLabels  ': 'Removed labels',
  'AuditLog.EventData.esticonProjectName': 'Esticon project name',
  'AuditLog.EventData.destinationProjectName': 'Destination project name',
  'AuditLog.EventData.destinationProjectSign': 'Destination project sign',
  'AuditLog.EventData.itemNote': 'Note text',
  'AuditLog.EventData.itemOrderId': 'Note serial number',
  'AuditLog.EntityData.ICO': 'CIN',
  'AuditLog.EntityData.DefaultStorageArea': 'Default storage area',
  'AuditLog.EntityData.ProjectAdminCanInviteNewUser': 'Project admin can invite new user',
  'AuditLog.EntityData.TimeZoneId': 'Time zone',
  'AuditLog.EntityData.Language': 'Language',
  'AuditLog.EntityData.Color': 'Color',
  'AuditLog.EntityType.assignment': 'Task',
  'AuditLog.EntityType.commentProcedure': 'Comment procedure',
  'AuditLog.EntityType.directory': 'Folder',
  'AuditLog.EntityType.document': 'Document',
  'AuditLog.EntityType.label': 'Label',
  'AuditLog.EntityType.project': 'Project',
  'AuditLog.EntityType.projectUserProfile': 'Project User',
  'AuditLog.EntityType.projectNote': 'Project note',
  'AuditLog.EntityType.projectSetting': 'Project setting',
  'AuditLog.EntityType.group': 'Group',
  'AuditLog.EntityType.documentCategoryNode': 'Category',
  'AuditLog.EntityType.documentCategoryTree': 'Category',
  'AuditLog.EntityType.esticonToDocumentLink': 'Link to Esticon document',
  'AuditLog.EntityType.role': 'Role',
  'AuditLog.EntityType.head': 'Head',
  'AuditLog.EntityType.link': 'Document shortcut',
  'AuditLog.EntityType.shared': 'Document sharing',
  'AuditLog.EntityType.linkedModelElement': 'Model shortcut',
  'AuditLog.EntityType.workflow': 'Workflow',
  'AuditLog.EntityType.workflowTemplate': 'Workflow template',
  'AuditLog.EntityType.documentAnnotation': 'Document annotation',
  'AuditLog.EntityType.organization': 'Organization',
  'AuditLog.EntityType.extendedPermissionTemplate': 'Permission template',
  'AuditLog.EntityType.projectTemplate': 'Project template',
  'AuditLog.EntityType.orgLabel': 'Organization label',
  'AuditLog.EntityType.mdRole': 'Role',
  'AuditLog.EntityType.mdDivision': 'Division',
  'AuditLog.EntityType.mdProject': 'CK material',
  'AuditLog.EntityType.mdUser': 'User',
  'AuditLog.EntityType.mdMeetingBackground': 'Meeting background',
  'AuditLog.EntityType.orgProjectCardData': 'Project supplement data',
  'AuditLog.EntityType.projectCard': 'Project card',
  'AuditLog.EntityType.organizationStructure': 'Organization structure',
  'AuditLog.EntityTypeParam.assignment': 'Task parameters',
  'AuditLog.EntityTypeParam.commentProcedure': 'Comment procedure parameters',
  'AuditLog.EntityTypeParam.directory': 'Folder Parameters',
  'AuditLog.EntityTypeParam.document': 'Document Parameters',
  'AuditLog.EntityTypeParam.label': 'Label Parameters',
  'AuditLog.EntityTypeParam.project': 'Project Parameters',
  'AuditLog.EntityTypeParam.projectUserProfile': 'User Parameters',
  'AuditLog.EntityTypeParam.projectNote': 'Project note',
  'AuditLog.EntityTypeParam.projectSetting': 'Project setting',
  'AuditLog.EntityTypeParam.projectCard': 'Project card',
  'AuditLog.EntityTypeParam.group': 'Group Parameters',
  'AuditLog.EntityTypeParam.documentCategoryNode': 'Category Node Parameters',
  'AuditLog.EntityTypeParam.documentCategoryTree': 'Category Parameters',
  'AuditLog.EntityTypeParam.esticonToDocumentLink': 'Esticon document',
  'AuditLog.EntityTypeParam.role': 'Role Parameters',
  'AuditLog.EntityTypeParam.head': 'Head Parameters',
  'AuditLog.EntityTypeParam.link': 'Document Shortcut Parameters',
  'AuditLog.EntityTypeParam.shared': 'Document Sharing Parameters',
  'AuditLog.EntityTypeParam.linkedModelElement': 'Link To Model Parameters',
  'AuditLog.EntityTypeParam.workflow': 'Workflow parameters',
  'AuditLog.EntityTypeParam.workflowTemplate': 'Workflow template parameters',
  'AuditLog.EntityTypeParam.documentAnnotation': 'Annotations in document',
  'AuditLog.EntityTypeParam.organization': 'Organization',
  'AuditLog.EntityTypeParam.extendedPermissionTemplate': 'Permission template',
  'AuditLog.EntityTypeParam.projectTemplate': 'Project template',
  'AuditLog.EntityTypeParam.orgLabel': 'Organization label',
  'AuditLog.EntityTypeParam.orgProjectCardData': 'organization projects supplementary data',
  'AuditLog.EntityTypeParam.mdRole': 'Role',
  'AuditLog.EntityTypeParam.mdDivision': 'Division',
  'AuditLog.EntityTypeParam.mdProject': 'Project',
  'AuditLog.EntityTypeParam.mdUser': 'User',
  'AuditLog.EntityTypeParam.mdMeetingBackground': 'Meeting background',
  'AuditLog.EntityTypeParam.organizationStructure': 'Organization structure',
  'AuditLog.EventData.accessLevel': 'Access Level',
  'AuditLog.EventData.addedExtendedPermissions': 'Added extended permissions',
  'AuditLog.EventData.addedLabels': 'Added labels',
  'AuditLog.EventData.annotationContent': 'Annotation content',
  'AuditLog.EventData.approvalDate': 'Approval date',
  'AuditLog.EventData.assignmentName': 'Task name',
  'AuditLog.EventData.assignmentOrderId': 'Task ID',
  'AuditLog.EventData.assignmentState': 'Task state',
  'AuditLog.EventData.attachmentName': 'Attachment name',
  'AuditLog.EventData.backgroundDocumentDescription': 'Request description',
  'AuditLog.EventData.backgroundDocumentName': 'Request name',
  'AuditLog.EventData.bulkActionType': 'Bulk action type',
  'AuditLog.EventData.category': 'Category',
  'AuditLog.EventData.categoryDescription': 'Category Description',
  'AuditLog.EventData.categoryName': 'Category Name',
  'AuditLog.EventData.commentProcedureName': 'Comment procedure name',
  'AuditLog.EventData.commentProcedureRole': 'Comment procedure role',
  'AuditLog.EventData.commentsCommentAddition': 'Response to the comment',
  'AuditLog.EventData.commentsCommentCategory': 'Comment Category',
  'AuditLog.EventData.commentsCommentMsg': 'Comment message',
  'AuditLog.EventData.commentsCommentName': 'Comment name',
  'AuditLog.EventData.commentsCommentOrderId': 'Comment id',
  'AuditLog.EventData.commentsCommentProcessors': 'Processors team',
  'AuditLog.EventData.commentsCommentState': 'Comment state',
  'AuditLog.EventData.commentsCommentStateOld': 'Previous comment state',
  'AuditLog.EventData.commentsCommentSubmitters': 'Submitters team',
  'AuditLog.EventData.commentsName': 'Comment procedure name',
  'AuditLog.EventData.commentsOrderId': 'Comment procedure id',
  'AuditLog.EventData.commentsState': 'Comment procedure state',
  'AuditLog.EventData.content': 'Content',
  'AuditLog.EventData.dailyCount': 'Daily count',
  'AuditLog.EventData.dailyLimitExceeded': 'Daily limit exceeded',
  'AuditLog.EventData.deadlineDate': 'Deadline date',
  'AuditLog.EventData.deletedUser': 'Deleted user',
  'AuditLog.EventData.description': 'Description',
  'AuditLog.EventData.directoryPath': 'Folder',
  'AuditLog.EventData.documentName': 'Document name',
  'AuditLog.EventData.documentState': 'Document State',
  'AuditLog.EventData.draftTemplateDescription': 'Draft template description',
  'AuditLog.EventData.draftTemplateName': 'Draft template name',
  'AuditLog.EventData.email': 'Email',
  'AuditLog.EventData.esticonId': 'Esticon ID',
  'AuditLog.EventData.externalShare': 'Share outside AspeHub',
  'AuditLog.EventData.false': 'No',
  'AuditLog.EventData.fileName': 'File Name',
  'AuditLog.EventData.fromDate': 'From date',
  'AuditLog.EventData.groupName': 'Group Name',
  'AuditLog.EventData.userStatus': 'User status',
  'AuditLog.EventData.oldUserStatus': 'Old user status',
  'AuditLog.EventData.hourlyCount': 'Hourly count',
  'AuditLog.EventData.hourlyLimitExceeded': 'Hourly limit exceeded',
  'AuditLog.EventData.includeAllRevisions': 'Include all revisions',
  'AuditLog.EventData.includeSubdirectories': 'Include subdirectories',
  'AuditLog.EventData.invitationMessage': 'Invitation message',
  'AuditLog.EventData.ipAddress': 'User IP address',
  'AuditLog.EventData.isImport': 'Is import',
  'AuditLog.EventData.labelColor': 'Label Color',
  'AuditLog.EventData.labelName': 'Label Name',
  'AuditLog.EventData.linkFrom': 'Link from Document',
  'AuditLog.EventData.linkName': 'Document Shortcut name',
  'AuditLog.EventData.linkTo': 'Link to Document',
  'AuditLog.EventData.managerName': 'Document Manager',
  'AuditLog.EventData.method': 'Method',
  'AuditLog.EventData.moveFrom': 'Move from',
  'AuditLog.EventData.moveTo': 'Move to',
  'AuditLog.EventData.name': 'Name',
  'AuditLog.EventData.newCategoryDescription': 'New Category Description',
  'AuditLog.EventData.newCategoryName': 'New Category Name',
  'AuditLog.EventData.newDocument': 'New document',
  'AuditLog.EventData.newLabels': 'New labels',
  'AuditLog.EventData.newNodeName': 'New node name',
  'AuditLog.EventData.newRoleName': 'New role name',
  'AuditLog.EventData.newStateChangePermission': 'New comment state change permission',
  'AuditLog.EventData.newTeamName': 'New team name',
  'AuditLog.EventData.newUserPermission': 'New user permission',
  'AuditLog.EventData.nodeName': 'Node Name',
  'AuditLog.EventData.noteMessage': 'Comment note',
  'AuditLog.EventData.noteOldMessage': 'Old comment note',
  'AuditLog.EventData.noteTitle': 'Note title',
  'AuditLog.EventData.old': 'Old value',
  'AuditLog.EventData.oldAssignmentState': 'Old task state',
  'AuditLog.EventData.oldCategoryDescription': 'Previous Category Description',
  'AuditLog.EventData.oldCategoryName': 'Previous Category Name',
  'AuditLog.EventData.oldCommentsCommentAddition': 'Previous response to the comment',
  'AuditLog.EventData.oldCommentsCommentCategory': 'Previous Comment Category',
  'AuditLog.EventData.oldCommentsCommentMsg': 'Previous comment message',
  'AuditLog.EventData.oldCommentsCommentName': 'Previous comment name',
  'AuditLog.EventData.oldCommentsCommentProcessors': 'Previous processors team',
  'AuditLog.EventData.oldCommentsCommentSubmitters': 'Previous submitters team',
  'AuditLog.EventData.oldCommentsState': 'Previous comment procedure state',
  'AuditLog.EventData.oldDocumentState': 'Previous document state',
  'AuditLog.EventData.oldLinkName': 'Old shortcut name',
  'AuditLog.EventData.oldNodeName': 'Old node name',
  'AuditLog.EventData.oldPrimarySolver': 'Old primary solver',
  'AuditLog.EventData.oldRoleName': 'Old role name',
  'AuditLog.EventData.oldStateChangePermission': 'Previous comment state change permission',
  'AuditLog.EventData.oldTeamName': 'Previous team name',
  'AuditLog.EventData.oldUserPermission': 'Previous user permission',
  'AuditLog.EventData.orderId': 'ID',
  'AuditLog.EventData.origNodeName': 'Original Node name',
  'AuditLog.EventData.origRoleName': 'Original Role name',
  'AuditLog.EventData.path': 'Path',
  'AuditLog.EventData.primarySolver': 'Primary solver',
  'AuditLog.EventData.reason': 'Reason',
  'AuditLog.EventData.receiver': 'Receiver',
  'AuditLog.EventData.removedExtendedPermissions': 'Removed extended permissions',
  'AuditLog.EventData.removedLabels': 'Removed labels',
  'AuditLog.EventData.removedNodes': 'Removed tasks',
  'AuditLog.EventData.requestAddress': 'Request address',
  'AuditLog.EventData.requestedState': 'Requested state',
  'AuditLog.EventData.reservedById': 'Reserved by id',
  'AuditLog.EventData.reservedByName': 'Reserved by name',
  'AuditLog.EventData.reservedDate': 'Reserved date',
  'AuditLog.EventData.resultOk': 'It went correctly',
  'AuditLog.EventData.revisionDescription': 'Revision description',
  'AuditLog.EventData.revisionNumber': 'Revision number',
  'AuditLog.EventData.secondRevisionNumber': 'Revision number to compare',
  'AuditLog.EventData.secondRevisionDescription': 'Revision description to compare',
  'AuditLog.EventData.roleName': 'Role name',
  'AuditLog.EventData.secDocError': 'Secondary document error',
  'AuditLog.EventData.secondaryDocumentName': 'Attachment name',
  'AuditLog.EventData.secundaryDocumentType': 'Attachment file type',
  'AuditLog.EventData.state': 'State',
  'AuditLog.EventData.teamName': 'Team name',
  'AuditLog.EventData.teamRole': 'Team role',
  'AuditLog.EventData.templateDescription': 'Template description',
  'AuditLog.EventData.templateName': 'Template name',
  'AuditLog.EventData.toDate': 'To date',
  'AuditLog.EventData.true': 'Yes',
  'AuditLog.EventData.unset': 'Unset',
  'AuditLog.EventData.type': 'Type',
  'AuditLog.EventData.userAgent': 'User Agent',
  'AuditLog.EventData.userId': 'User id',
  'AuditLog.EventData.username': 'User Name',
  'AuditLog.EventData.userName': 'User name',
  'AuditLog.EventData.userPermission': 'User permission',
  'AuditLog.EventData.version': 'Version',
  'AuditLog.EventData.voteMessage': 'Vote message',
  'AuditLog.EventData.votePortName': 'Vote action name',
  'AuditLog.EventData.workflowDescription': 'Workflow description',
  'AuditLog.EventData.WorkflowName': 'Workflow name',
  'AuditLog.EventData.workflowName': 'Workflow name',
  'AuditLog.EventData.workflowNodeDescription': 'Task description',
  'AuditLog.EventData.workflowNodeName': 'Task name',
  'AuditLog.EventData.message':'Message',
  'AuditLog.EventData.notifiedUser':'Notified user',
  'AuditLog.EventData.workflowOrderId': 'Workflow id',
  'AuditLog.EventData.stateKD': 'Transfer state',
  'AuditLog.EventData.endTimeKD': 'Transfer end',
  'AuditLog.EventData.startTimeKD': 'Transfer start',
  'AuditLog.EventData.versionName': 'Name',
  'AuditLog.EventData.versionSign': 'Sign',
  'AuditLog.EventData.refereerName': 'Document name',
  'AuditLog.EventData.referenceName': 'Reference name',
  'AuditLog.EventData.menuName': 'Name in menu',
  'AuditLog.EventData.applicationType': 'Application type',
  'AuditLog.EventData.mdProjectName': 'Action name',
  'AuditLog.EventData.mdProjectDescription': 'Action description',
  'AuditLog.EventData.justification': 'Justification',
  'AuditLog.EventData.hubProjectDataName': 'DMS',
  'AuditLog.EventData.mdCkIdentificaion': 'CK Identification',
  'AuditLog.EventData.mdProjectPhase': 'Action state',
  'AuditLog.EventData.mdProjectOldState': 'Original value',
  'AuditLog.EventData.mdProjectNewState': 'New value',
  'AuditLog.EventData.mdProjectProcessorDivisionName': 'Division of the processor',
  'AuditLog.EventData.mdProposer': 'Proposer',
  'AuditLog.EventData.mdGuarantor': 'Guarantor',
  'AuditLog.EventData.mdRequestProcessor': 'Request processor',
  'AuditLog.EventData.oldMdRequestProcessor': 'Original request processor',
  'AuditLog.EventData.mdProjectDataName': 'Card parameter name',
  'AuditLog.EventData.mdRoleName': 'Role name',
  'AuditLog.EventData.mdProjectDataOldValue': 'Original parameter value',
  'AuditLog.EventData.mdProjectDataNewValue': 'New parameter value',
  'AuditLog.EventData.mdApprovalConditionCondition': 'Approval condition',
  'AuditLog.EventData.mdApprovalConditionApprovalDescription': 'Condition description',
  'AuditLog.EventData.mdApprovalConditionDeadline': 'Deadline',
  'AuditLog.EventData.mdApprovalConditionApprovalDone': 'Is fulfilled',
  'AuditLog.EventData.mdApprovalConditionOld': 'Orignal condition',
  'AuditLog.EventData.mdApprovalConditionOldApprovalDescription': 'Original condition description',
  'AuditLog.EventData.mdApprovalConditionOldDeadline': 'Original deadline',
  'AuditLog.EventData.mdApprovalConditionOldApprovalDone': 'Is fulfilled - original value',
  'AuditLog.EventData.roleHead': 'Main role user',
  'AuditLog.EventData.division': 'Division',
  'AuditLog.EventData.roleType': 'Role type',
  'AuditLog.EventData.isHead': 'Is dision leader',
  'AuditLog.EventData.isRoleHead': 'Is main role user',
  'AuditLog.EventData.roleHeadEmail': 'Main role user',
  'AuditLog.EventData.byDays': 'Days count',
  'AuditLog.EventData.approved': 'Approved',
  'AuditLog.EventData.rejected': 'Rejected',
  'AuditLog.EventName.approvalDocumentAssigned': 'Approval document assigned',
  'AuditLog.EventName.approvalDocumentAddedToWorkflow': 'Approved document has been uploaded and added',
  'AuditLog.EventName.approvalDocumentRevisionAddedInWorkflow': 'Revision of the approved document has been added',
  'AuditLog.EventName.approvalDocumentRemovedFromWorkflow': 'The approved document has been removed from the workflow',
  'AuditLog.EventName.associateUserAddedToAssignment': 'Associated user added to task',
  'AuditLog.EventName.associateUserRemovedFromAssignment': 'Associated user removed from task',
  'AuditLog.EventName.bulkAction': 'Bulk action',
  'AuditLog.EventName.bulkDocumentsChange': 'Bulk documents change',
  'AuditLog.EventName.workflowAssignApprovalDocuments': 'Approved documents has been assigned to the workflow',
  'AuditLog.EventName.initialised': 'Initialization',
  'AuditLog.EventName.patched': 'Change',
  'AuditLog.EventName.created': 'Creation',
  'AuditLog.EventName.currentRevisionChanged': 'Current Revision Change',
  'AuditLog.EventName.invitationCreated': 'Invitation Creation',
  'AuditLog.EventName.invitationAccepted': 'Invitation Accepting',
  'AuditLog.EventName.statusChanged': 'Status Change',
  'AuditLog.EventName.requiredCategoryTreeAdded': 'Required Category Added',
  'AuditLog.EventName.requiredCategoryTreeRemoved': 'Required Category Removed',
  'AuditLog.EventName.requiredCategoryTreeChanged': 'Required Category Changed',
  'AuditLog.EventName.groupAccessLevelHasBeenSet': 'Group Access Set',
  'AuditLog.EventName.groupAccessLevelHasBeenUnset': 'Group Access Removed',
  'AuditLog.EventName.groupUsersAdded': 'Users added to group',
  'AuditLog.EventName.userAccessLevelHasBeenSet': 'User Access Added',
  'AuditLog.EventName.userAccessLevelHasBeenUnset': 'User Access Removed',
  'AuditLog.EventName.permisssionInheritanceChanged': 'Permission Inheritance Changed',
  'AuditLog.EventName.move': 'Moving',
  'AuditLog.EventName.labelAdded': 'Label Added',
  'AuditLog.EventName.labelRemoved': 'Label Removed',
  'AuditLog.EventName.linkAdded': 'Document Shortcut Added',
  'AuditLog.EventName.revisionAdded': 'Revision Added',
  'AuditLog.EventName.categoryTreeNodeAdded': 'Category Node Added',
  'AuditLog.EventName.categoryTreeNodeChanged': 'Category Node Changed',
  'AuditLog.EventName.categoryTreeNodeRemoved': 'Category Node Removed',
  'AuditLog.EventName.suspended': 'Deactivated',
  'AuditLog.EventName.activated': 'Activated',
  'AuditLog.EventName.discarded': 'Discarded',
  'AuditLog.EventName.requiredDefaultCategoryTreeNodechanged': 'Required Category Node Changed',
  'AuditLog.EventName.moved': 'Moved',
  'AuditLog.EventName.documentsDiscarded': 'Documents Discarded',
  'AuditLog.EventName.documentsRestored': 'Documents Restored',
  'AuditLog.EventName.documentsMoved': 'Documents Moved',
  'AuditLog.EventName.directoriesDiscarded': 'Directories Discarded',
  'AuditLog.EventName.directoriesRestored': 'Directories Restored',
  'AuditLog.EventName.documentAddedForApproval': 'Added approval document to workflow',
  'AuditLog.EventName.restored': 'Restored',
  'AuditLog.EventName.used': 'Processed',
  'AuditLog.EventName.documentShared': 'Document Was Shared',
  'AuditLog.EventName.sharedDocumentDownloaded': 'Shared Document Downloaded',
  'AuditLog.EventName.shareSent': 'Sharing was sent',
  'AuditLog.EventName.documentDownloaded': 'Document Downloaded',
  'AuditLog.EventName.documentsDownloaded': 'Documents Downloaded',
  'AuditLog.EventName.secondaryFileAdded': 'Attachments Added',
  'AuditLog.EventName.documentAddedToModels': 'Document was added to models',
  'AuditLog.EventName.documentRemovedFromModels': 'Document was removed from models',
  'AuditLog.EventName.secondaryFileDownloaded': 'Attachment file was downloaded',
  'AuditLog.EventName.linkRemoved': 'Link was removed',
  'AuditLog.EventName.addedToGroup': 'User was added to group',
  'AuditLog.EventName.removedFromGroup': 'User was removed from group',
  'AuditLog.EventName.usersInvited': 'User was invited',
  'AuditLog.EventName.workflowStateChanged': 'Document state was changed',
  'AuditLog.EventName.reserved': 'Reservation',
  'AuditLog.EventName.reservationReleased': 'Reservation released',
  'AuditLog.EventName.ownerChanged': 'Document Manager was changed',
  'AuditLog.EventName.userRoleAdded': 'The user has been assigned a role',
  'AuditLog.EventName.userRoleDeleted': "The user's role has been removed",
  'AuditLog.EventName.roleAddedToWorkflow': 'A role was added to workflow',
  'AuditLog.EventName.roleAddedToNode': 'A role was added to a task',
  'AuditLog.EventName.roleRemovedFromNode': 'A role was removed from a task',
  'AuditLog.EventName.roleAddedToBackgroundDocument': 'A role was added to a request',
  'AuditLog.EventName.finished': 'Finished',
  'AuditLog.EventName.backgroundDocumentAssigned': 'A document was assigned to a request',
  'AuditLog.EventName.backgroundDocumentAccepted': 'Request was accepted',
  'AuditLog.EventName.backgroundDocumentRefused': 'Request was refused',
  'AuditLog.EventName.backgroundDocumentRequestAdded': 'A new request was added',
  'AuditLog.EventName.backgroundDocumentRequestChanged': 'Request was changed',
  'AuditLog.EventName.backgroundDocumentRequestRemoved': 'Request was removed',
  'AuditLog.EventName.workflowNodeStarted': 'Workflow task started',
  'AuditLog.EventName.workflowNodeRoleDelegation': 'A resolved delegated their role',
  'AuditLog.EventName.workflowNodeVote': 'A vote was cast in a task',
  'AuditLog.EventName.workflowNodeFinish': 'Workflow task finished',
  'AuditLog.EventName.documentAddedAsBackgroundDocument': 'A new document was added to a request',
  'AuditLog.EventName.documentRemovedFromBackgroundDocument': 'A document was removed from a request',
  'AuditLog.EventName.workflowVote': 'Workflow vote',
  'AuditLog.EventName.roleRemovedFromBackgroundDocument': 'A role was removed from a request',
  'AuditLog.EventName.documentRemovedFromComments': 'Document was removed from the comment procedure',
  'AuditLog.EventName.documentAddedToComments': 'Document was added to the comment procedure',
  'AuditLog.EventName.roleAddedToComments': 'Role was added to the comment procedure',
  'AuditLog.EventName.roleRemovedFromComments': 'Role was removed from the comment procedure',
  'AuditLog.EventName.userAddedToComments': 'User was added to the comment procedure',
  'AuditLog.EventName.userRemovedFromComments': 'User was removed from the comment procedure',
  'AuditLog.EventName.userResendActivation': 'User activation email was sent again',
  'AuditLog.EventName.commentsUserPatched': 'User was edited in the comment procedure',
  'AuditLog.EventName.teamAddedToComments': 'Team was added to the comment procedure',
  'AuditLog.EventName.teamRemovedFromComments': 'Team was remove from the comment procedure',
  'AuditLog.EventName.commentsTeamPatched': 'Team was edited int the comment procedure',
  'AuditLog.EventName.CommentsSetState': 'Changed comment procedure state',
  'AuditLog.EventName.commentsComentCreated': 'New comment created',
  'AuditLog.EventName.commentsComentPatched': 'Comment edited',
  'AuditLog.EventName.commentsComentStateSet': 'Comment state changed',
  'AuditLog.EventName.attachmentAddedToCommentsComment': 'Attachment added to comment',
  'AuditLog.EventName.commentsComentNoteAdded': 'New response to a comment',
  'AuditLog.EventName.documentAddedToCommentsComment': 'Document added to a comment',
  'AuditLog.EventName.documentRemovedFromCommentsComment': 'Document removed from a comment',
  'AuditLog.EventName.commentsStateSet': 'Comment procedure state changed',
  'AuditLog.EventName.commentsComentNoteEdited': 'Comment\'s note edited',
  'AuditLog.EventName.commentsComentNoteDeleted': 'Comment\'s note deleted',
  'AuditLog.EventName.commentsComentNoteRestored': 'Comment\'s note restored',
  'AuditLog.EventName.attachmentDiscardedFromCommentsComment': 'Attachment discarded from comment',
  'AuditLog.EventName.attachmentRestoredInCommentsComment': 'Attachment restored in comment',
  'AuditLog.EventName.exported': 'Exported',
  'AuditLog.EventName.extendedPermissionChanged': 'User permission changed',
  'AuditLog.EventName.startEsticonUpload': 'File upload for Esticon started',
  'AuditLog.EventName.startEsticonDocumentUpload': 'Document upload for Esticon sterted',
  'AuditLog.EventName.esticonDocumentUploaded': 'Esticon document uploaded',
  'AuditLog.EventName.esticonDocumentRejected': 'Esticon document rejected',
  'AuditLog.EventName.documentApprovalFinished': 'Document approval finished',
  'AuditLog.EventName.closed': 'Closed',
  'AuditLog.EventName.documentRemovedFromCommentProcedure': 'Document released from comment procedure',
  'AuditLog.EventName.documentAddedToCommentProcedure': 'Document added to comment procedure',
  'AuditLog.EventName.documentAddedToAssignment': 'Document was added to a task',
  'AuditLog.EventName.documentRemovedFromAssignment': 'Document was removed from a task',
  'AuditLog.EventName.approvalDocumentsAccepted': 'Approval documents were approved',
  'AuditLog.EventName.approvalDocumentsRefused': 'Approval documents were refused',
  'AuditLog.EventName.CommentProcedureDocumentsAdded': 'Documents were added to the comment procedure',
  'AuditLog.EventName.commentProcedureRolesPatched': 'Roles were changed in comment procedure',
  'AuditLog.EventName.usersAddedToComments': 'Users were added to the comment procedure',
  'AuditLog.EventName.commentProcedureCategoryAdded': 'Category created',
  'AuditLog.EventName.commentProcedureCategoryPatched': 'Category updated',
  'AuditLog.EventName.commentProcedureCategoryDeleted': 'Category deleted',
  'AuditLog.EventName.commentProcedureCategoriesImported': 'Categories imported',
  'AuditLog.EventName.commentProcedureAdded': 'Comment procedure added',
  'AuditLog.EventName.commentProcedureRemoved': 'Comment procedure removed',
  'AuditLog.EventName.commentProcedureDeadlineSet': 'Comment procedure deadline was set',
  'AuditLog.EventName.commentsTeamPermissionPatched': 'Team permission was changed',
  'AuditLog.EventName.documentDerivatesDownloaded': 'Document previews displayed',
  'AuditLog.EventName.documentSecondaryDerivatesDownloaded': 'Secondary document previews displayed',
  'AuditLog.EventName.revisionDiscarded': 'Document revision was discarded',
  'AuditLog.EventName.revisionRestored': 'Document revision was restored',
  'AuditLog.EventName.projectOpened': 'Project opened',
  'AuditLog.EventName.projectClosed': 'Project closed',
  'AuditLog.EventName.reportCreated': 'Report created',
  'AuditLog.EventName.projectAddedToMeetingApproval': 'Material added to meeting approval',
  'AuditLog.EventName.projectRemovedFromMeetingApproval': 'Material removed from meeting approval',
  'AuditLog.EventName.workflowParallelInit': 'Parallel workflow initialized',
  'AuditLog.EventName.workflowNodeNoteAdded': 'Note added to workflow note discussion',
  'AuditLog.EventName.workflowNodeNoteEdited': 'Note edited in workflow note discussion',
  'AuditLog.EventName.workflowNodeNoteDeleted': 'Note deleted from workflow note discussion',
  'AuditLog.EventName.workflowNodeNoteRestored': 'Note restored in workflow note discussion',
  'AuditLog.EventName.attachmentAddedToWorkflowNode': 'Attachment added to workflow note discussion',
  'AuditLog.EventName.attachmentDiscardedFromWorkflowNode': 'Attachment removed from workflow note discussion',
  'AuditLog.EventName.attachmentRestoredInWorkflowNode': 'Attachment restored in workflow note discussion',
  'AuditLog.EventName.mailLimitExceed': 'Mail sending exceeded limit',
  'AuditLog.EventName.managerRoleAddedToWorkflow': 'Manager role was added to workflow',
  'AuditLog.EventName.userAddedToAssignment': 'User was added to a task',
  'AuditLog.EventName.userRemovedFromAssignment': 'User was removed from a task',
  'AuditLog.EventName.primarySolverChanged': 'Primary solver changed',
  'AuditLog.EventName.approvalDocumentAddRevision': 'Revision added to approval document',
  'AuditLog.EventName.assignmentNoteAdded': 'Note added to task discussion',
  'AuditLog.EventName.assignmentNoteEdited': 'Note edited in task discussion',
  'AuditLog.EventName.assignmentNoteDeleted': 'Note removed from task discussion',
  'AuditLog.EventName.assignmentNoteRestored': 'Note restored in task discussion',
  'AuditLog.EventName.attachmentAddedToAssignment': 'Attachment added to task discussion',
  'AuditLog.EventName.attachmentDiscardedFromAssignment': 'Attachment removed from task discussion',
  'AuditLog.EventName.attachmentRestoredInAssignment': 'Attachment restored in task discussion',
  'AuditLog.EventName.directoryShared': 'Directory was shared',
  'AuditLog.EventName.documentLinksCreated': 'Document shortcut created',
  'AuditLog.EventName.documentLinkAdded': 'Document shortcut added',
  'AuditLog.EventName.documentLinkRemoved': 'Document shortcut removed',
  'AuditLog.EventName.documentLinkChanged': 'Document shortcut changed',
  'AuditLog.EventName.documentEsticonMultiupload': 'Esticon document multiupload',
  'AuditLog.EventName.documentAnnotationsCreated': 'Annotations in document created',
  'AuditLog.EventName.documentAnnotationsChanged': 'Annotations in document changed',
  'AuditLog.EventName.documentAnnotationAdded': 'Annotation added to document',
  'AuditLog.EventName.documentAnnotationChanged': 'Annotation changed in document',
  'AuditLog.EventName.annotationRemoved': 'Annotation removed form document',
  'AuditLog.EventName.documentAnnotationAddedToCommentsComment': 'Document annotation linked to comment',
  'AuditLog.EventName.directoryLinkAdded': 'Directory shortcut added',
  'AuditLog.EventName.directoryLinkRemoved': 'Directory shortcut removed',
  'AuditLog.EventName.directoryLinkChanged': 'Directory shortcut changed',
  'AuditLog.EventName.esticonDocumentReturnForRework': 'Esticon document returned for rework',
  'AuditLog.EventName.esticonDocumentPublished': 'Esticon document published',
  'AuditLog.EventName.directoryLinkMoved': 'Directory shortcut moved',
  'AuditLog.EventName.attachmentDownloaded': 'Attachment downloaded',
  'AuditLog.EventName.attachmentsDownloaded': 'Attachments downloaded',
  'AuditLog.EventName.labelReplaced': 'Labels replaced',
  'AuditLog.EventName.esticonDocumentSetApprovalDate': 'Document approval date set',
  'AuditLog.EventName.transferToKD': 'Transfer to control database',
  'AuditLog.EventName.unknown': 'Unknown event',
  'AuditLog.EventName.attachmentVisibilityChangedInCommentsComment': 'Attachment visibility changed',
  'AuditLog.EventName.docToDocLinksCreated': 'Document bindings created',
  'AuditLog.EventName.assignmentStateChanged': 'Assignment state changed',
  'AuditLog.EventName.organizationDeleted': 'Organization deleted',
  'AuditLog.EventName.addOrganizationUser': 'Add organization user',
  'AuditLog.EventName.patchOrganizationUser': 'Change organization user',
  'AuditLog.EventName.usersAddedToProject': 'Users added to project',
  'AuditLog.EventName.projectUserSetAdmin': 'Project user admin flag has been changed',
  'AuditLog.EventName.projectUserChangeStatus': 'Project user status has been changed',
  'AuditLog.EventName.addOrganizationProject': 'Added project to organization',
  'AuditLog.EventName.harmonogramImported': 'The project schedule has been imported',
  'AuditLog.EventName.removeOrganizationUser': 'A user has been removed from the organization',
  'AuditLog.EventName.projectUserRemoveInvited': 'An invited user has been removed from the project',
  'AuditLog.EventName.organizationChanged': 'The organization of the project has been changed',
  'AuditLog.EventName.directoryDownloaded': 'The directory was downloaded',
  'AuditLog.EventName.bulkUpdateAnnotationsWithAssignment': 'Bulk update of comments with attachment',
  'AuditLog.EventName.documentDownloadedForDiff': 'Document downloaded for comparison',
  'AuditLog.EventName.workflowStateChangedFail': 'Workflow state change fail',
  'AuditLog.EventName.approvalDocumentsShared': 'Approval documents shared',
  'AuditLog.EventName.approvalDocumentSignRevision': 'Approval document revision signed',
  'AuditLog.EventName.documentStateChangedFail': 'An attempt to change the status of the document failed',
  'AuditLog.EventName.merged': 'Merged',
  'AuditLog.EventName.addItem': 'Item was added',
  'AuditLog.EventName.patchItem': 'Item was updated',
  'AuditLog.EventName.deleteItem': 'Item was deleted',
  'AuditLog.EventName.documentReferenceAdded': 'Added document reference',
  'AuditLog.EventName.documentReferenceMatrixAdded': 'Added document references',
  'AuditLog.EventName.documentReferenceRemoved': 'Document reference removed',
  'AuditLog.EventName.userNotified': 'User notified',
  'AuditLog.EventName.settingsDeleted': 'Settings deleted',
  'AuditLog.EventName.deleted': 'Deleted',
  'AuditLog.EventName.addedToRole': 'User added to role',
  'AuditLog.EventName.deletedFromRole': 'User deleted from role',
  'AuditLog.EventName.changeMdState': 'Changing the internal phase of the action',
  'AuditLog.EventName.changeMdProjectCommentingDivision': 'Commenting divisions changed',
  'AuditLog.EventName.setMdProcessorDivision': 'Division setting',
  'AuditLog.EventName.setMdProjectGuarantor': 'Guarantor setting ',
  'AuditLog.EventName.setMdProjectRequestProcessor': 'Comment handler settings',
  'AuditLog.EventName.setMdProjectData': 'Card parameter settings',
  'AuditLog.EventName.setMdProjectDatas': 'Card parameters settings',
  'AuditLog.EventName.setMdProjecApprovalConditionAdd': 'Adding an approval condition',
  'AuditLog.EventName.setMdProjecApprovalConditionRemove': 'Removal of approval condition',
  'AuditLog.EventName.setMdProjecApprovalConditionChange': 'Change of approval condition',
  'AuditLog.EventName.approvalDocumentAnnotationsRevision': 'Changing an approved document on a workflow task',
  'AuditLog.EventName.associateAdded': 'Associate added',
  'AuditLog.EventName.associateRemoved': 'Associate removed',
  'AuditLog.EventName.userRoleAssociationAdded': 'User has been added to associates of role',
  'AuditLog.EventName.userRoleAssociationDeleted': 'User has been removed from associates of role',
  'AuditLog.EventName.workflowNodeDeadlineExtendRequested': 'An extension of the task deadline has been requested',
  'AuditLog.EventName.workflowNodeDeadlineExtendApproval': 'The request to extend the deadline for the task was processed',
  'AuditLog.EventName.organizationStructureUpdated': 'Organization structure updated',
  'AuditLog.EventName.resultApplied': 'Ck Meeting result applied',
  'AuditLog.EventName.returnMdProjectToO910': 'Return Ck material to division O910',
  'AuditLog.EventName.returnMdProjectToProposer': 'Return Ck material to proposer',
  'AuditLog.ParamsTable.EventName': 'Action Parameters',
  'AuditLog.ParamsTable.Value': 'Value',
  'AuditLog.ParamsTable.OriginValue': 'Original Value',
  'AuditLog.ParamsTable.NewValue': 'New Value',
  'AuditLog.view': 'View related activities',
  'BcfPanel.notSaveAlert': 'BCF topics are not saved. If you continue, your changes will be lost.',
  'BcfTopics': 'BCF Issues',
  'BcfTopics.noTopics': 'There are no issues yet',
  'BcfAddNewTopic': 'Create a new BCF issue',
  'BcfUploadTopicsFile': 'Load issues from a BCF file',
  'BcfDownloadTopicsFile': 'Save issues to a BCF file',
  'BcfForm.includeSnapshot': 'Model preview (screenshot)',
  'BcfForm.includeViewpoint': 'Viewpoint data (e.g. camera position)',
  'BcfForm.viewerIntegration': 'Include graphic information',
  'BcfTopicDetail.title': 'Issue detail',
  'BcfTopicDetail.focusView': 'Center the camera',
  'BcfTopicDetail.automaticFocusView': 'Automatic centering of camera',
  'BcfTopicDetail.goToAssignment': 'Go To Assignment',
  'BcfTopicComments': 'Issue comments',
  'BcfTopicAddComment': 'Add comment',
  'BcfTopicAddComment.placeholder': 'Write your issue comment here ...',
  'BcfTopicStatus.open': 'Open',
  'BcfTopicStatus.inProgress': 'In progress',
  'BcfTopicStatus.reopened': 'Reopened',
  'BcfTopicStatus.resolved': 'Resolved',
  'BcfTopicStatus.closed': 'Closed',
  'BcfTopicTypes.task': 'Task',
  'BcfTopicTypes.issue': 'Issue',
  'BcfTopicTypes.request': 'Request',
  'BcfTopicTypes.clash': 'Clash',
  'BcfImport.success': 'BCF file (version {value}) has been successfully loaded.',
  'BcfImport.error': 'Failed to import BCF file.',
  'BcfSave.error': 'Failed to save changed tasks.',
  'BcfSave.success': 'Changed tasks have been saved.',
  'BcfImportConflictModal.title': 'Conflict resolution',
  'BcfExportModal.title': 'Export notes',
  'BcfExportModal.summary': 'Selected notes count: {count}',
  'BcfImportModal.title': 'Import notes to the model',
  'BcfImportModal.nameTooltip': 'Import updates the note',
  'BcfImportModal.conflictTooltip': 'Imported item has invalid data and must be resolved if marked for import',
  'BcfImportModal.resolveConflict': 'Resolve conflict',
  'BooleanFilter.isModel':'Models',
  'BooleanFilter.isModel.description':'View documents that are models',
  'BooleanFilter.isModel.yes':'Yes - models only',
  'BooleanFilter.isModel.no':'No - only non-model documents',
  'BooleanFilter.isNewProject': 'New project',
  'BooleanFilter.isNewProject.description': 'View not yet opened projects',
  'BooleanFilter.isNewProject.yes': 'Yes - still unopened',
  'BooleanFilter.isNewProject.no': 'No - only projects that have already been opened',
  'BooleanFilter.hasValue':'It has value',
  'BooleanFilter.hasValue.description':'Show entities with set value',
  'BooleanFilter.hasValue.yes':'Yes - with value',
  'BooleanFilter.hasValue.no':'No - no value',
  'BooleanFilter.isSigned':'Signed',
  'BooleanFilter.isSigned.description':'View documents that are signed',
  'BooleanFilter.isSigned.yes':'Yes - signed only',
  'BooleanFilter.isSigned.no':'No - only unsigned',
  'BooleanFilter.hasAnnotation':'Annotated',
  'BooleanFilter.hasAnnotation.description':'View documents that are annotated',
  'BooleanFilter.hasAnnotation.yes':'Yes - annotated only',
  'BooleanFilter.hasAnnotation.no':'No - only unannotated',
  'BudgetItemDetail.price': 'Price',
  'BudgetItemDetail.margins': 'Margin',
  'BudgetItemDetail.title': 'Item detail',
  'BudgetItemDetail.menu.overview': 'Overview',
  'BudgetItemDetail.menu.quantitySheet': 'Quantity takeoff',
  'BudgetItemDetail.menu.specification': 'Specification',
  'BudgetItemDetail.menu.realization': 'Realization',
  'BudgetItemDetail.menu.cefActivities': 'CEF activities',
  'BudgetItemDetail.tab.specification.description': 'Description',
  'BudgetItemDetail.tab.specification.techSpecification': 'Technical specification',
  'BudgetItemDetail.tab.quantitySheet.title': 'Quantity takeoff',
  'BudgetItemDetailRealization.column.wipReportSign': 'WIP report sign',
  'BudgetItemDetailRealization.column.issuedDate': 'Issued date',
  'BudgetItemDetailRealization.column.originName': 'SOW/CO',
  'BudgetItemDetailRealization.column.drawedAmount': 'Drawed',
  'BudgetItemDetailRealization.column.drawedCost': 'Drawed [{currency}]',
  'BudgetItemDetailRealization.column.totalAmount': 'Drawed total',
  'BudgetItemDetailRealization.column.totalCost': 'Drawed total [{currency}]',
  'BudgetItemDetailRealization.column.invoiceSign': 'Invoice sign',
  'BudgetItemDetailStatement.column.code': 'Code',
  'BudgetItemDetailStatement.column.description': 'Description',
  'BudgetItemDetailStatement.column.expression': 'Formula',
  'BudgetItemDetailStatement.column.value': 'Value',
  'BudgetItemDetailStatement.column.include': 'Include',
  'BudgetItemDetailCefActivities.activity': 'Activity',
  'BudgetItemDetailCefActivities.notClassified': 'Not classified',
  'BudgetItemDetailCefActivities.overviewOfChangesInCefActivities': 'Overview of the changes in CEF activities',
  'BudgetItemDetailCefActivities.rationaleForChangeInCefActivity': 'Rationale for the change in CEF activity',
  'BudgetItemDetailCefActivities.column.dateOfChange': 'Date of change',
  'BudgetItemDetailCefActivities.column.validFrom': 'Valid from',
  'BudgetItemDetailCefActivities.column.activityBeforeChange': 'Activity before change',
  'BudgetItemDetailCefActivities.column.activityAfterChange': 'Activity after change',
  'BudgetItemDetailCefActivities.column.formulatedBy': 'Formulated by',
  'BudgetDetail.amount': 'Quantity',
  'BudgetDetail.structuralElementTitle': 'Work in progress report',
  'BudgetDetail.designElementTitle': 'Design element detail',
  'BudgetDetail.buildingPartTitle': 'Element of construction detail',
  'BudgetDetail.fixed.OC': 'Fixed OC',
  'BudgetDetail.JOC': 'SPU',
  'BudgetDetail.OC.total': 'Total SP',
  'BudgetDetail.margins': 'Margin',
  'BudgetDetail.measureUnit': 'Unit of measure',
  'BudgetDetail.name': 'Name',
  'BudgetDetail.serialNumber': 'SN',
  'BudgetDetail.costSpent': 'Billed',
  'BudgetDetail.JNC': 'CPU',
  'BudgetDetail.JNCTotal': 'Total CP',
  'BudgetDetail.notConnected': 'No budget is connected to this project.',
  'BudgetDetail.priceSystem': 'Price system',
  'BudgetDetail.totalMargins': 'Total margin',
  'BudgetDetail.VAT': 'VAT',
  'BudgetDetail.withVAT': 'Total SP including VAT',
  'BudgetDetail.otherProperties': 'Other properties',
  'BudgetDetail.itemIsValorized': 'Item is valorized',
  'BudgetGrid.noSearchDataText': 'No items match the entered text',
  'BudgetGrid.noDataText': 'No data',
  'BudgetGrid.preparing': 'Preparing ...',
  'BudgetObjectDetail.title': 'Object Detail',
  'BudgetObjectDetail.costPrices': 'Cost prices',
  'BudgetObjectDetail.contacts': 'Contacts',
  'BudgetObjectDetail.ncTotal': 'Total cost price',
  'BudgetObjectDetail.begin': 'Start date',
  'BudgetObjectDetail.end': 'Completion date',
  'BudgetObjectDetail.administrator': 'Manager / Investor',
  'BudgetObjectDetail.contractor': 'Contractor',
  'BudgetObjectDetail.designer': 'Designer',
  'BudgetObjectDetail.salesPrices': 'Sales prices',
  'BudgetProjectDetail.title': 'Project Detail',
  'BudgetProjectDetail.realized': 'Billed',
  'BudgetProjectDetail.remaining': 'Remaining',
  'BudgetProjectDetail.costNow': 'Current cost',
  'BudgetProjectDetail.costNowShort': 'Cur. cost',
  'BudgetProjectDetail.costChange': 'Cost difference',
  'BudgetProjectDetail.costChangeShort': 'Difference',
  'Building.general.amount': 'Quantity',
  'Building.general.aspeEsticon': 'AspeEsticon',
  'Building.general.name': 'Name',
  'Building.general.unit': 'Unit',
  'Building.general.budgetType': 'Projekt type',
  'Building.general.budgetType.StavebniDily': 'By construction units',
  'Building.general.budgetType.Elementy': 'By elements',
  'Building.general.stage': 'Project stage',
  'Building.general.stage.Zp': 'ZP',
  'Building.general.stage.Dur': 'DUR',
  'Building.general.stage.Dsp': 'DSP',
  'Building.general.stage.Dps': 'DPS',
  'Building.general.price': 'Price',
  'Building.general.sign': 'Sign',
  'Building.general.code': 'Code',
  'Building.general.document': 'Document',
  'Building.general.existingAddress': 'Existing address',
  'Building.general.importedAddress': 'Imported address',
  'Building.general.establish': 'Create new',
  'Building.general.dontEstablish': 'Not to create',
  'Building.general.number': 'Number',
  'Building.general.spent': 'Billed quantity',
  'Building.general.billed': 'Billed',
  'Building.general.spentPercent': 'Billed [%]',
  'Building.general.variant': 'Variant',
  'Building.general.valorization': 'Valorization',
  'Building.general.notValorized': 'Not valorized',
  'Building.general.short.displayUnit': 'Unit',
  'Building.general.short.orderNumber': 'SN',
  'Building.general.short.unitPrice': 'SPU',
  'Building.general.begin': 'Start date',
  'Building.general.end': 'Completion date',
  'Building.general.partner': 'Partner',
  'Building.general.phase': 'Phase',
  'Building.general.phase.Priprava': 'Preparation',
  'Building.general.phase.Nabidka': 'Offer',
  'Building.general.phase.Realizace': 'Realization',
  'Building.general.phase.Dokonceno': 'Performed quantity',
  'Building.general.phase.Neziskano': 'Not Gainer',
  'Building.general.scopeOfWork.abbreviation': 'SOW',
  'Building.general.changeOrder.abbreviation': 'CO',
  'Building.general.sod': 'SOW SP',
  'Building.general.zbv': 'Change order price',
  'Building.general.totalPrice': 'Total SP',
  'Building.general.totalPriceWithVat': 'Total price including VAT',
  'Building.general.showBreakdown': 'Show breakdown',
  'Building.general.showDocument': 'Show document',
  'Building.general.returnToRework': 'Return to rework',
  'Building.general.returnToReworkAssociated': 'Return to rework including members',
  'Building.general.displayMode.all': 'Show all',
  'Building.general.displayMode.sent': 'Sent only',
  'BuildingApprovalDateForm.documentType.ZjistovaciProtokolSdruzeny': 'Document type: Common WiP report',
  'BuildingApprovalDateForm.documentType.ZjistovaciProtokol': 'Document type: WiP report',
  'BuildingApprovalDateForm.documentType.ZBV': 'Document type: Change order',
  'BuildingApprovalDateForm.title': 'Change of approval date',
  'BuildingApprovalDateForm.formItemLabel': 'Approval date',
  'BuildingApprovalDateForm.submitText.enter': 'Set approval date',
  'BuildingApprovalDateForm.submitText.remove': 'No approval date',
  'BuildingApprovalDateForm.requiredRuleText': 'Please enter an approval date',
  'Building.general.qualified': 'Qualified',
  'Building.general.notQualified': 'Not qualified',
  'Building.general.qualification.headerLabel': 'Qualification',
  'Building.general.sodQualification': 'SOW qualification',
  'Building.general.zbvQualification': 'Change order qualification',
  'BuildingBudget.title': 'Estimate',
  'BuildingBudget.uploadProject': 'Upload',
  'BuildingBudget.searchPlaceholder': 'Search...',
  'BuildingBudget.showColumns': 'Column Chooser',
  'BuildingBudget.accordingTo.KP.tooltip': 'Construction elements',
  'BuildingBudget.accordingTo.SD.tooltip': 'Building parts',
  'BuildingBudget.accordingTo.KP': 'CE',
  'BuildingBudget.accordingTo.SD': 'BP',
  'BuildingBudget.column.orderNumber': 'SN',
  'BuildingBudget.column.sign': 'Code',
  'BuildingBudget.column.variant': 'Variant',
  'BuildingBudget.column.name': 'Name',
  'BuildingBudget.column.amount': 'Total quantity',
  'BuildingBudget.column.displayUnit': 'Unit',
  'BuildingBudget.column.unitSellingPrice': 'SPU',
  'BuildingBudget.column.unitCostPrice': 'CPU',
  'BuildingBudget.column.sellingPrice': 'SP',
  'BuildingBudget.column.costPrice': 'CP',
  'BuildingBudget.column.sodAmount': 'SOW quantity',
  'BuildingBudget.column.drawnAmount': 'Billed quantity',
  'BuildingBudget.column.remainingAmount': 'To bill quantity',
  'BuildingBudget.column.sodSellingPrice': 'SOW SP',
  'BuildingBudget.column.zbvAmount': 'CO quantity',
  'BuildingBudget.column.zbvSellingPrice': 'CO SP',
  'BuildingBudget.column.spentSellingPrice': 'Billed',
  'BuildingBudget.column.spentPercent': 'Billed [%]',
  'BuildingBudget.column.remainingSellingPrice': 'To bill',
  'BuildingBudget.column.originType': 'Created in',
  'BuildingBudget.column.priceSystem': 'Price system',
  'BuildingBudget.column.valorized': 'Valorized',
  'BuildingBudget.columnHeaderTooltip.unitSellingPrice': 'Selling price per unit [CZK]',
  'BuildingBudget.columnHeaderTooltip.unitCostPrice': 'Cost price per unit [CZK]',
  'BuildingBudget.columnHeaderTooltip.sellingPrice': 'Selling price [CZK]',
  'BuildingBudget.columnHeaderTooltip.costPrice': 'Cost price [CZK]',
  'BuildingBudget.columnHeaderTooltip.sodAmount': 'Scope of work quantity',
  'BuildingBudget.columnHeaderTooltip.sodSellingPrice': 'Scope of work selling price [CZK]',
  'BuildingBudget.columnHeaderTooltip.zbvAmount': 'Change order quantity',
  'BuildingBudget.columnHeaderTooltip.zbvSellingPrice': 'Change order selling price [CZK]',
  'BuildingBudget.columnHeaderTooltip.drawnAmount': 'Billed quantity',
  'BuildingBudget.columnHeaderTooltip.remainingAmount': 'To bill quantity',
  'BuildingBudget.columnHeaderTooltip.cenaCelkem': 'Construction cost including changes during construction',
  'BuildingBudget.columnHeaderTooltip.fakturovano': 'Invoices from contract price',
  'BuildingBudget.columnHeaderTooltip.cerpanoNakladyCelkem': 'Planned costs from invoice completion',
  'BuildingBudget.columnHeaderTooltip.actualIncome': 'Issued invoices from accounting',
  'BuildingBudget.columnHeaderTooltip.actualTotalCost': 'Received invoices and other costs from accounting',
  'BuildingBudget.columnHeaderTooltip.netIncomeToDate': 'Net income to particular date',
  'BuildingBudget.columnHeaderTooltip.zbyvaFakturovano': 'Remaining invoices',
  'BuildingBudget.columnHeaderTooltip.zbyvaNc': 'Costs for rest of the plan',
  'BuildingBudget.columnHeaderTooltip.expectedNetIncome': 'Contract expected result',
  'BuildingBudget.columnHeaderTooltip.coefficient': 'Ratio of expected result',
  'BuildingBudget.columnHeaderTooltip.margin': 'Margin in %',
  'BuildingBudget.columnHeaderTooltip.erpHeaderDrawing': 'Completed work',
  'BuildingBudget.columnHeaderTooltip.erpHeaderActual': 'Actual revenue and costs in accounting',
  'BuildingBudget.columnHeaderTooltip.erpHeaderRemaining': 'Remaining contract budget',
  'BuildingBudget.columnHeaderTooltip.erpHeaderexpectedResult': 'Contract expected result',
  'BuildingBudget.noProjectContent': 'Budget cannot be displayed.',
  'BuildingBudget.transferToControlDb': 'Transfer of the project to the control database',
  'BuildingBudget.transferToControlDb.lastSent': 'Last sent: ',
  'BuildingBudget.transferToControlDb.transferAuthor': 'Transfer author: ',
  'BuildingBudget.transferToControlDb.transferNotYet': 'The transfer to the control database has not yet been carried out',
  'BuildingBudget.transferToControlDb.button': 'Transfer to CD',
  'BuildingBudget.transferToControlDb.confirm.title': 'Do you wish to send the project to the control database?',
  'BuildingBudget.transferToControlDb.confirm.content': 'Data from AspeEsticon will be transferred to the Control Database, which may not agree with the data sent to AspeHub.',
  'BuildingBudget.transferToControlDb.confirm.okText': 'Transfer',
  'BuildingBudget.transferToControlDb.confirm.cancelText': 'Storno',
  'BuildingBudgetModel.colorsTooltip.title': 'Colored drawing schema',
  'BuildingBudgetModel.colorsTooltip.drawed': 'Drawed',
  'BuildingBudgetModel.changeModels': 'Change models',
  'BuildingBudgetModel.selectModelsTitle': 'Select models to display',
  'BuildingBudgetModel.selectModelsButton': 'Open models',
  'BuildingBudgetModel.options.showDrawingColors': 'Show or hide colors of model elements according to their funds draw',
  'BuildingBudgetModel.options.showHighlighted': 'Show/hide highlighting of selected elements in the model',
  'BuildingBudgetModel.options.showCheckboxes': 'Show/hide checkboxes to isolate elements in the model',
  'BuildingBudgetModel.options': 'Display options',
  'BuildingDocumentMenu.unsetApprovalDate': 'Unset approval date',
  'BuildingDrawing.newZPRevision': 'New revision (WIP report)',
  'BuildingDrawing.addZP': 'Upload WIP report',
  'BuildingDrawing.addZP.success': 'The data has been successfully uploaded to AspeEsticon. Now the transfer process to AspeHub begins.',
  'BuildingDrawing.addZP.disabled': 'To upload WIP report, the Esticon project must be in the realization phase.',
  'BuildingDrawing.downloadZP': 'Download WIP report',
  'BuildingDrawing.downloadZP.success': 'Work in progress report successfully downloaded',
  'BuildingDrawing.showBreakdown': 'Show breakdown',
  'BuildingDrawingDownloadZp.modal.title': 'Export WIP reports',
  'BuildingDrawingDownloadZp.modal.okText': 'Export',
  'BuildingDrawingDownloadZp.input.default': 'Export WIP',
  'BuildingDrawingDownloadZp.successMessage': 'Successful export',
  'BuildingDrawingDownloadZp.maxLengthErrorMessage': 'The export failed due to the text of some items being too long.',
  'BuildingDrawingDownloadZp.errorMessage': 'Export failed',
  'BuildingDrawingDownloadZp.input.fileName': 'File name',
  'BuildingDrawingDownloadZp.radioChoice.all': 'All',
  'BuildingDrawingDownloadZp.radioChoice.actualMonth': 'Current month',
  'BuildingDrawingDownloadZp.radioChoice.ZPinPeriod': 'WIP report in period',
  'BuildingDrawingDownloadZp.formatRadioChoice.exportFormat': 'Export format',
  'BuildingDrawingDownloadZp.checkbox.title': 'Export common WIP including members WIP',
  'BuildingDrawingDownloadZp.valuesSelectTitle': 'Data selection',
  'BuildingDrawingPartnersRegisterModal.title': 'Address book in AspeEsticon',
  'BuildingDrawingPartnersRegisterModal.name': 'Name',
  'BuildingDrawingPartnersRegisterModal.CIN': 'CIN',
  'BuildingDrawingPartnersRegisterModal.street': 'Street',
  'BuildingDrawingPartnersRegisterModal.ZIP': 'ZIP',
  'BuildingDrawingPartnersRegisterModal.city': 'City',
  'BuildingRealization.addZP': 'Upload WIP report',
  'BuildingRealization.showBreakdown': 'Show breakdown',
  'BuildingRealization.SoD': 'SOW',
  'BuildingRealization.mode.all': 'All',
  'BuildingRealization.mode.realized': 'Billed only',
  'BuildingRealization.commonProtocolsMode.partial.label': 'Partial',
  'BuildingRealization.commonProtocolsMode.common.label': 'Associated',
  'BuildingRealization.commonProtocolsMode.partial.tooltip': 'Partial WIP reports',
  'BuildingRealization.commonProtocolsMode.common.tooltip': 'Associated WIP reports',
  'BuildingDrawingBreakdown.title': 'Work in progress - Breakdown',
  'BuildingDrawingBreakdown.column.totalSpentAmount': 'Total billed quantity',
  'BuildingDrawingBreakdown.column.totalSpent': 'Total billed',
  'BuildingDrawingBreakdown.column.totalSpentPercent': 'Total billed [%]',
  'BuildingDrawingBreakdown.column.remainingAmount': 'Quantity to bill',
  'BuildingDrawingBreakdown.column.remainingSellingPrice': 'To bill',
  'BuildingDrawingBreakdown.column.done': 'Performed quantity',
  'BuildingDrawingBreakdown.column.totalDone': 'Total performed quantity',
  'BuildingDrawingPage.title': 'Work in progress',
  'BuildingDrawingPage.noProjectContent': 'Drawing cannot be displayed.',
  'BuildingDrawingPage.columns.code': 'Code',
  'BuildingDrawingPage.columns.name': 'Name',
  'BuildingDrawingPage.columns.exposed': 'Issued date',
  'BuildingDrawingPage.columns.approved': 'Approved',
  'BuildingDrawingPage.columns.cost': 'Price',
  'BuildingDrawingPage.columns.spentCost': 'Billed',
  'BuildingDrawingPage.columns.spentPercent': 'Billed [%]',
  'BuildingDrawingPage.columns.remainingCost': 'To bill',
  'BuildingDrawingPage.columns.sourceDocument': 'Source document',
  'BuildingDrawingPage.columns.originType': 'Quantity type',
  'BuildingDrawingPage.columns.contractor': 'Contractor',
  'BuildingDrawingPage.columns.investor': 'Investor',
  'BuildingDrawingPage.columns.wipType': 'Association',
  'BuildingDrawingPage.columns.wipSpecies': 'WIP report type',
  'BuildingDrawingPage.columns.wipSpecies.Klasicky': 'Classical',
  'BuildingDrawingPage.columns.wipSpecies.Storno': 'Cancellation',
  'BuildingDrawingPage.columns.wipSpecies.Valorizacni': 'Valorization',
  'BuildingDrawingPage.columns.valorizationPeriod': 'Period of valorization',
  'BuildingDrawingAnalyzeZpForm.documentId': 'Select a document to upload:',
  'BuildingDrawingAnalyzeZpForm.documentId.placeholder': 'Click here to select a document from DMS',
  'BuildingDrawingAnalyzeZpForm.file': 'Or upload a new document:',
  'BuildingDrawingAnalyzeZpForm.justZbvGroups': 'Update groups (import Change order groups only)',
  'buildingDrawingAnalyzeZpStep.chooseOneWarning': 'Either select an existing document or upload a new file.',
  'buildingDrawingAnalyzeZpStep.title': 'Select file',
  'buildingDrawingAnalyzeZpStep.analyzeError': 'Failed to parse file. Make sure the WIP report is valid.',
  'buildingDrawingImportInvoiceStep.noImportableInvoice': 'The imported file does not contain any importable invoice',
  'buildingDrawingImportZpStep.importError': 'Failed to import work in progress report.',
  'buildingDrawingImportZpStep.title': 'Configure import',
  'buildingDrawingImportZpStep.upload': 'Upload',
  'buildingDrawingImportZpStep.pairingNecessaryAlert': 'Marked suppliers must be assigned an existing member of the association before uploading',
  'buildingDrawingImportZpStep.someDestinationObjectIdNotSetError': 'All WiPs must have a connection to the object before uploading. Please select the object from the menu.',
  'buildingDrawingImportZpStep.noIsSelectedZps': 'The file does not contain any importable WiPs',
  'buildingDrawingImportZpStep.noProtocols': 'No WIP reports are selected',
  'buildingDrawingImportZpStep.cannotImportSomeProtocols': 'Some WIP reports cannot be uploaded',
  'buildingDrawingImportZpStep.hasInvalidZpPartners': 'WIP reports contain invalid partners identifier or invalid addresses. Only invoices can be imported without including WIP.',
  'buildingDrawingImportZpStep.hasNoAssignedObjectOnSomeInvoice': 'For invoices marked in red, new objects need to be assigned.',
  'BuildingDrawingImportZpList.zpAssociationMembers': 'Association partners',
  'BuildingDrawingImportZpList.tooltip.error': 'WIP report cannot be uploaded',
  'BuildingDrawingImportZpList.tooltip.new': 'New work in progress report',
  'BuildingDrawingImportZpList.tooltip.updatable': 'Updatable work in progress report',
  'BuildingDrawingImportZpList.tooltip.cannotCreate': 'Unable to create WIP report',
  'BuildingDrawingImportZpList.zpList': 'Work in progress reports for import',
  'BuildingDrawingImportZpForm.delegateDph': 'By importing, delegate the obligation to fill and report VAT',
  'BuildingDrawingImportZpForm.includeWiPreports': 'Include WIP reports',
  'BuildingDrawingImportZpForm.importExistingSettingsLabel': 'How to resolve duplicate WIPs',
  'BuildingDrawingImportZpForm.replaceExistingZPs': 'Delete existing WIP reports and replace them with imported ones',
  'BuildingDrawingImportZpForm.ignoreExistingZPs': 'Do not import WIPs with duplicate number',
  'BuildingDrawingImportZpForm.partners': 'Partners',
  'BuildingDrawingImportZpForm.associationMembers': 'Association members',
  'BuildingDrawingImportZpForm.partnersInvalidCIN': 'Invalid CIN partners list',
  'BuildingDrawingImportZpForm.selectAddress': 'Pick an address',
  'BuildingDrawingCreatePartnerForm.title': 'New partner',
  'BuildingDrawingCreatePartnerForm.name': 'Name',
  'BuildingDrawingCreatePartnerForm.branchName': 'Branch name',
  'BuildingDrawingCreatePartnerForm.CIN': 'CIN',
  'BuildingDrawingCreatePartnerForm.VAT': 'VAT',
  'BuildingDrawingCreatePartnerForm.street': 'Street',
  'BuildingDrawingCreatePartnerForm.city': 'City',
  'BuildingDrawingCreatePartnerForm.ZIP': 'ZIP',
  'BuildingDrawingCreatePartnerForm.personType': 'Person',
  'BuildingDrawingCreatePartnerForm.juridicalPerson': 'Juridical',
  'BuildingDrawingCreatePartnerForm.BIN': 'BIN?',
  'BuildingDrawingCreatePartnerForm.poBox': 'PO Box',
  'BuildingDrawingCreatePartnerForm.IBAN': 'IBAN',
  'BuildingDrawingCreatePartnerForm.bicSwift': 'BIC/SWIFT',
  'BuildingDrawingCreatePartnerForm.naturalPerson': 'Natural',
  'BuildingDrawingCreatePartnerForm.VatRegistered': 'VAT registered',
  'BuildingDrawingCreatePartnerForm.VatDeclaration': 'VAT declaration',
  'BuildingDrawingCreatePartnerForm.note': 'Note',
  'BuildingDrawingCreatePartnerForm.BusinessRegister': 'Entry in the Commercial Register',
  'BuildingDrawingCreatePartnerForm.defaultAddress': 'Default address',
  'BuildingDrawingCreatePartnerForm.defaultAddress.default': 'Default',
  'BuildingDrawingCreatePartnerForm.branch': 'Branch',
  'BuildingDrawingCreatePartnerForm.defaultAddress.branch': 'Default address - branch',
  'BuildingDrawingCreatePartnerForm.sign': 'Abbreviation (brand)',
  'BuildingDrawingCreatePartnerForm.partnerAddresses': 'Partner addresses',
  'BuildingDrawingCreatePartnerForm.bankAccount': 'Bank account',
  'BuildingDrawingCreatePartnerForm.bankAccountNumberList': 'Bank accounts list',
  'BuildingDrawingCreatePartnerForm.bankAccountNumber': 'Account number',
  'BuildingDrawingCreatePartnerForm.bankNumber': 'Bank',
  'BuildingDrawingCreatePartnerForm.bankName': 'Name',
  'BuildingDrawingCreatePartnerForm.contactPerson': 'Contact person',
  'BuildingDrawingCreatePartnerForm.contactPersonNumberList': 'Contact persons list',
  'BuildingDrawingCreatePartnerForm.contactName': 'Name',
  'BuildingDrawingCreatePartnerForm.contactSurname': 'Surname',
  'BuildingDrawingCreatePartnerForm.contactDegreeBefore': 'Degree before',
  'BuildingDrawingCreatePartnerForm.contactDegreeAfter': 'Degree after',
  'BuildingDrawingCreatePartnerForm.contactMobile': 'Mobile',
  'BuildingDrawingCreatePartnerForm.contactPhone': 'Phone',
  'BuildingDrawingCreatePartnerForm.contactEmail': 'Email',
  'BuildingDrawingUploadZpFormWizard.title': 'Import work in progress report',
  'BuildingDrawingDownloadZp.column.sign': 'Code',
  'BuildingDrawingDownloadZp.column.name': 'Name',
  'BuildingDrawingDownloadZp.column.exposed': 'Issued date',
  'BuildingDrawingDownloadZp.column.realizedQuantityType': 'Quantity type',
  'BuildingDrawingDownloadZp.column.VATTransferRegime': 'VAT reverse charge',
  'BuildingDrawingDownloadZp.column.investor': 'Investor',
  'BuildingDrawingDownloadZp.column.supplier': 'Supplier',
  'BuildingDrawingDownloadZp.columnChooser': 'Choose columns',
  'BuildingSchedulePage.title': 'Schedule',
  'BuildingSchedulePage.noScheduleNotice': 'Schedule not available.',
  'BuildingSchedulePage.uploadHMG': 'Upload schedule',
  'BuildingScheduleUploadHmgFormWizard.title': 'Schedule import',
  'BuildingScheduleImportHmgForm.hmgVersionPlaceholder': 'Choose a version',
  'BuildingScheduleImportHmgForm.importDataType.all': 'Schedule and FP',
  'BuildingScheduleImportHmgForm.importDataType.HmgOnly': 'Schedule only',
  'BuildingScheduleImportHmgForm.importDataType.FpOnly': 'FP only',
  'BuildingScheduleImportHmgForm.importDataStyle.actualization': 'Actualization',
  'BuildingScheduleImportHmgForm.importDataStyle.createNew': 'New version',
  'BuildingScheduleImportHmgForm.importDataStyle.createNew.sign': 'Sign',
  'BuildingScheduleImportHmgForm.importDataStyle.createNew.name': 'Name',
  'BuildingScheduleImportHmgForm.import.duplicitySign': 'A schedule with the specified sign already exists',
  'BuildingZbvPage.title': 'Change order',
  'BuildingZbvPage.noProjectContent': 'Change order cannot be displayed.',
  'BuildingZbvPage.columns.sign': 'Code / sign',
  'BuildingZbvPage.columns.name': 'Name',
  'BuildingZbvPage.columns.exposed': 'Issued date',
  'BuildingZbvPage.columns.approved': 'Approved',
  'BuildingZbvPage.columns.totalSellingPrice': 'Total selling cost',
  'BuildingZbvPage.columns.totalCostPrice': 'Total cost (NC)',
  'BuildingZbvPage.columns.spentPrice': 'Billed',
  'BuildingZbvPage.columns.sodSellingPrice': 'SOW selling cost',
  'BuildingZbvPage.columns.sodCostPrice': 'SOW cost (NC)',
  'BuildingZbvPage.columns.zbvSellingPrice': 'WIP selling cost',
  'BuildingZbvPage.columns.zbvCostPrice': 'WIP cost (NC)',
  'BuildingZbvPage.columns.zbvSpentPrice': 'WIP billed',
  'BuildingZbvPage.columns.type': 'Type',
  'BuildingZbvPage.columns.contractAddendum': 'Contract addendum',
  'BuildingZbvPage.zbvType.Investorsky': 'Investor',
  'BuildingZbvPage.zbvType.Navrh': 'Design',
  'BuildingZbvPage.zbvType.Interni': 'Internal',
  'BuildingZbvUploadInvoiceFormWizard.title': 'Import Change order',
  'BuildingZbv.addZbv': 'Upload Change order',
  'BuildingZbv.addZbv.success': 'The data has been successfully uploaded to AspeEsticon. Now the transfer process to AspeHub begins.',
  'BuildingZbv.addZbv.disabled': 'To upload Change order, the Esticon project must be in the realization phase.',
  'BuildingZbvPage.zbvApprove.cannotApprove': 'You do not have sufficient permissions',
  'BuildingZbvPage.zbvApprove.noDocument': 'ZBV is not sent to AspeHub',
  'BuildingZbvImportGrid.exists': 'Exists',
  'BuildingZbvImportGrid.approved': 'Approved',
  'BuildingZbvImportGrid.drawn': 'Drawn',
  'BuildingZbvImportGrid.sentToHub': 'Posted on AspeHub',
  'BuildingZbvImportGrid.existingAddress': 'Existing address',
  'BuildingZbvImportGrid.importedAddress': 'Imported address',
  'BuildingZbvImportGrid.name': 'Name',
  'BuildingZbvImportGrid.partner': 'Partner',
  'BuildingZbvImportGrid.sign': 'Sign',
  'BuildingZbvImportGrid.systemPairing': 'Paired',
  'BuildingZbvImportZbv.existingZbv': 'Change order already exists',
  'BuildingZbvImportZbv.criticalValorizationDifference': 'Due to the different valorization on the project and the imported ZBV, the import cannot be continued.',
  'BuildingZbvImportZbv.noZbvsInOnlyZbvGroupImport': 'The file does not contain Change order groups.',
  'BuildingZbvImportZbv.ZbvGroups': 'Change order groups',
  'BuildingZbvSkupinyImportPairing.sourceZbvGroup': 'Change order group in import',
  'BuildingZbvSkupinyImportPairing.destinationZbvGroup': 'Existing Change order group',
  'BuildingZbvImportZbv.noAddPartnerPermissionAskAdmin': 'To establish a new partner or its address, contact the project manager.',
  'BuildingZbvImportZbv.protocolWithoutContractor': 'The contractor is missing in the imported WIP.',
  'BuildingZbvImportZbvForm.notifyReplace': 'In the import are Change Orders that already exist in the project. They will be overwritten by the data from the imported file when continuing.',
  'BuildingZbvImportZbvList.zbvList': 'Change order list for import',
  'BuildingZbvImportZbvList.zbvPairing': 'Data pairing',
  'BuildingZbvBreakdown.title': 'Amendments',
  'BuildingZbvBreakdown.columns.zbvAmount': 'WIP quantity',
  'BuildingZbvBreakdown.columns.zbvSellingPrice': 'WIP selling cost',
  'BuildingZbvBreakdown.columns.zbvCostPrice': 'WIP cost (NC)',
  'BuildingZbvBreakdown.columns.sodAmount': 'SOW quantity',
  'BuildingZbvBreakdown.columns.sodSellingPrice': 'SOW selling cost',
  'BuildingZbvBreakdown.columns.sodCostPrice': 'SOW cost (NC)',
  'BuildingZbvBreakdown.columns.totalAmount': 'Total quantity',
  'BuildingZbvBreakdown.columns.totalSellingPrice': 'Total selling cost',
  'BuildingZbvBreakdown.columns.totalCostPrice': 'Total cost (NC)',
  'BuildingZbvBreakdown.columns.groups': 'Groups',
  'BuildingZbvBreakdown.columns.category': 'Category',
  'BuildingZbvBreakdownHighlight.groupsBreakdown': 'Groups breakdown',
  'BuildingZbvBreakdownHighlightToggles.groups.hide': 'Hide groups breakdown',
  'BuildingZbvBreakdownHighlightToggles.groups.show': 'Show groups breakdown',
  'BuildingInvoicingPage.title': 'Invoicing',
  'BuildingInvoicePage.columns.sign': 'Code',
  'BuildingInvoicePage.columns.order': 'SN',
  'BuildingInvoicePage.columns.timePeriod': 'Tax period',
  'BuildingInvoicePage.columns.priceWithoutVat': 'Price without VAT',
  'BuildingInvoicePage.columns.vatPriceBasic': 'Basic VAT rate',
  'BuildingInvoicePage.columns.vatPriceReduced1': '1st reduced VAT Rate',
  'BuildingInvoicePage.columns.vatPriceReduced2': '2nd reduced VAT Rate',
  'BuildingInvoicePage.columns.totalPrice': 'Total price',
  'BuildingInvoicePage.columns.administrator': 'Manager / Investor',
  'BuildingInvoicePage.columns.contractor': 'Contractor',
  'BuildingInvoicePage.columns.importContractor': 'Import contractor',
  'BuildingInvoicePage.columns.buildingContractor': 'Building contractor',
  'BuildingInvoicePage.columns.VATTransferRegime': 'VAT reverse charge',
  'BuildingInvoicePage.VATTransferRegime.NeuplatnujeSe': 'Not applicable',
  'BuildingInvoicePage.VATTransferRegime.ZaplatiPrijemce': 'The recipient pays',
  'BuildingInvoicePage.VATTransferRegime.PrenesenoOdPoskytovatele': 'Transferred from provider',
  'BuildingInvoicePage.displayModes.partial.label': 'Partial',
  'BuildingInvoicePage.displayModes.partial.tooltip': 'Partial invoices',
  'BuildingInvoicePage.displayModes.common.label': 'Associated',
  'BuildingInvoicePage.displayModes.common.tooltip': 'Associated invoices',
  'BuildingInvoicingUploadInvoiceFormWizard.title': 'Import invoice',
  'BuildingInvoicing.addInvoice': 'Upload Invoice',
  'BuildingInvoicing.addInvoice.success': 'The data has been successfully uploaded to AspeEsticon. Now the transfer process to AspeHub begins.',
  'BuildingInvoicing.addInvoice.disabled': 'To upload invoice, the Esticon project must be in the realization phase.',
  'BuildingInvoicingImportInvoice.newInvoice': 'New invoice',
  'BuildingInvoicingImportInvoice.existingInvoice': 'Invoice with same number already exists',
  'BuildingInvoicingImportInvoiceForm.delegateDph': 'By importing, delegate the obligation to fill and report VAT',
  'BuildingInvoicingImportInvoiceForm.replaceExistingInvoices': 'Delete existing drawings are replace then from import',
  'BuildingInvoicingImportInvoiceList.invoiceList': 'Invoice list for import',
  'BuildingInvoicingPairingGrid.associationMembers': 'Association members',
  'BuildingInvoicingPairingGrid.newObjects': 'New object setting',
  'BuildingInvoicingPairingGrid.CIN': 'CIN',
  'BuildingInvoicingPairingGrid.name': 'Name',
  'BuildingInvoicingPairingGrid.sign': 'Sign',
  'BuildingInvoicingPairingGrid.street': 'Street',
  'BuildingInvoicingPairingGrid.city': 'City',
  'BuildingInvoicingPairingGrid.ZIP': 'ZIP',
  'BuildingInvoicingInvoiceItemsList.title': 'Invoice items',
  'BuildingInvoicingInvoiceItemsList.object': 'Object',
  'BuildingInvoicingInvoiceItemsList.objectNew': 'New object',
  'BuildingInvoicingInvoiceItemsList.drawn': 'Drawn',
  'BuildingInvoicingInvoiceItemsList.valorizationPercent': 'Valorization %',
  'BuildingInvoicingInvoiceItemsList.valorizationValue': 'Valorization',
  'BuildingInvoicingInvoiceItemsList.discountPercent': 'Discount %',
  'BuildingInvoicingInvoiceItemsList.discountValue': 'Discount',
  'BulkEditActionResultModal.content.updatedDocuments': 'Number of changed documents',
  'BulkEditActionResultModal.content.updatedLinks': 'Number of changed document shortcuts',
  'BulkEditActionResultModal.content.unchangedDocuments': 'Number of documents without change',
  'BulkEditActionResultModal.content.unchangedLinks': 'Number of document shortcuts without change',
  'BulkEditActionResultModal.moveTo': 'Move to Message center',
  'BulkEditDocumentsLabelsForm.documentsCount': 'Selected documents count',
  'BulkEditDocumentsLabelsForm.title': 'Change documents labels',
  'BulkEditDocumentsLabelsForm.labels': 'Labels',
  'BulkEditDocumentsLabelsForm.labels.required': 'Please select at least one label',
  'BulkEditDocumentsLabelsForm.mode': 'Labels change mode',
  'BulkEditDocumentsLabelsForm.mode.required': 'Please select labels change mode',
  'BulkEditDocumentsLabelsForm.mode.add': 'Add labels',
  'BulkEditDocumentsLabelsForm.mode.replace': 'Replace labels',
  'BulkEditDocumentsLabelsForm.mode.remove': 'Remove labels',
  'BulkEditDocumentsLabelsForm.impossibleChanges': 'Labels cannot be changed on the following documents and document links',
  'BulkEditDocumentsLabelsForm.resultModal.title': 'The result of the action - bulk change of labels',
  'BulkEditDocumentsStateForm.title': 'Change documents states',
  'BulkEditDocumentsStateForm.documentsCount': 'Count of selected documents: {count}',
  'BulkEditDocumentsStateForm.executeChanges': 'Execute changes',
  'BulkEditDocumentsStateForm.executeChangesToOthers': 'Execute changes ot others',
  'BulkEditDocumentsStateForm.newDocumentsState': 'New documents state',
  'BulkEditDocumentsStateForm.newDocumentsState.required': 'Please set documents status',
  'BulkEditDocumentsStateForm.newDocumentsState.impossibleChanges': 'The following documents and document links cannot be changed to the desired state',
  'BulkEditDocumentsStateForm.newDocumentsState.noAvailableStates': 'There is no common status for the selected set of documents and links',
  'BulkEditDocumentsStateForm.successModal.title': 'The result of the action - bulk change of states',
  'BulkEditDocumentsWatchForm.impossibleChanges': 'Track settings cannot be changed on the following documents and document links',
  'BulkEditDocumentsWatchForm.info': 'New settings will override the existing documents track settings',
  'BulkEditDocumentsWatchForm.resultModal.title': 'The result of the action - bulk document track settings',
  'BulkEditDocumentsWatchForm.title': 'Change document track',
  'BulkEditDocumentsWatchForm.watches': 'Track',
  'BulkRemoveFromModelsModal.button.Remove': 'Remove',
  'BulkRemoveFromModelsModal.button.RemoveAndDeleteLinks': 'Remove models and delete links',
  'BulkRemoveFromModelsModal.title': 'Remove document from models',
  'BulkRemoveFromModelsModal.RemoveModels': `{count, plural,
    =0 {Do you want remove 0 selected document from models?}
    one {Do you want remove 1 selected document from model?}
    few {Do you want remove # selected documents from models?}
    other {Do you want remove # selected documents from models?}
  }`,
  'BulkRemoveFromModelsModal.errorsTitle.missed': `{count, plural,
    =0 {All right}
    one {An error occurred while removing. No model have been removed.}
    few {Errors occurred during removing. No models have been removed.}
    other {Error occurred during removing. No models have been removed.}
  }`,
  'BulkAddToModelsModal.button.Add': 'Add',
  'BulkAddToModelsModal.button.AddOthers': 'Add others',
  'BulkAddToModelsModal.title': 'Add documents to models',
  'BulkAddToModelsModal.AddModels': `{count, plural,
    =0 {Do you really want to add 0 selected to models?}
    one {Do you really want to add 1 selected to models?}
    few {Do you really want to add # selected to models?}
    other {Do you really want to add # selected to models?}
  }`,
  'BulkAddToModelsModal.errorsTitle.missed': `{count, plural,
    =0 {All right}
    one {An error occurred while adding the model. No model has been added.}
    few {Errors occurred while adding the models. No model has been added.}
    other {Errors occurred while adding the models. No model has been added.}
  }`,
  'CalendarDaySelect.workingDays': 'Pracovní dny',
  'CalendarDaySelect.calendarDays': 'Kalendářní dny',
  'CalendarExcludedDayImportForm.modal.title': 'Importing Czech holidays into the calendar',
  'CalendarExcludedDayImportForm.modal.importError': 'An error occurred while importing holidays. Import failed.',
  'CalendarExcludedDayImportForm.modal.importWarning': 'The selected time period does not include any Czech holidays.',
  'CalendarExcludedDayImportForm.label.endDate': 'Load holidays as of date',
  'CalendarExcludedDayForm.title.add': 'Add holiday',
  'CalendarExcludedDayForm.title.edit': 'Edit holiday',
  'CalendarExcludedDayForm.repeating.label': 'Repeat the holiday every year on the same day',
  'CalendarSettingsDeleteButton.confirmDelete.message': 'After deleting the project calendar, the organization settings will be used. Do you want to delete the project calendar?',
  'CalendarSettingsExcludedDaysItem.onlyOnce': 'Only once',
  'CalendarSettingsExcludedDaysItem.repeating': 'Repeating',
  'CalendarSettingsExcludedDaysItem.import': 'Import czech holidays',
  'CalendarSettingsForm.workingDays': 'Working days',
  'CalendarSettingsForm.excludedDays': 'Holidays and days off',
  'CalendarSettingsForm.filterPast.tooltip': 'Show only future holidays',
  'CalendarSettingsFormModal.title.organization': 'Organization calendar setting',
  'CalendarSettingsFormModal.title.project': 'Project calendar setting',
  'CalendarSettingsFormModal.title.template': 'Template calendar setting',
  'CategoryList.deleteConfirmModal.description': 'Do you want to force category deletion and remove it from all folders and documents?',
  'CategoryList.deleteConfirmModal.delete': 'Delete',
  'CategoryFilterItem.category.description': 'Category',
  'CategoryFilterItem.category.placeholder': 'Category Name ...',
  'CategoryFilterItem.category.allUsed': 'All categories used in the filter',
  'CategoryFilterItem.node.desc': 'Node',
  'CategoryFilterItem.node.placeholder': 'Node Name ...',
  'CertificateSignature.title': 'Sign document',
  'CertificateSignature.signButtonWithBankID': 'Sign with BankID',
  'CertificateSignature.signButtonCertificate': 'Sign with certificate',
  'CertificateSignature.derivateWarning':
    'You are about to sign the generated PDF document from the original. Formatting may have changed during the conversion. Verify the document before signing.',
  'CertificateSignature.secondaryFileNotSignable':
    'The document contains an attachment with a signed document in a format that cannot be signed online.',
  'CertificateSignature.form.signingError': 'Error occurred when signing a document.',
  'CertificateSignature.error.invalidContentType.text': 'Document {document} is not supported for signing.',
  'CertificateSignature.reason.key': 'AspeHub Signature',
  'CertificateSignature.reason.value': 'Document approved by AspeHub user',
  'CertificateSignature.error.storage': 'An error occured during document upload for signature. {error}',
  'CertificateSignature.error.redirectEmpty': 'There was an error when signing the document. BankID login address could not be retrieved.',
  'CertificateSignature.verifyModal.title': 'Processing signed document',
  'CertificateSignature.verifyModal.status.downloading': 'Downloading signed document',
  'CertificateSignature.verifyModal.status.finished': 'Document signed successfully',
  'CertificateSignature.verifyModal.status.error': 'Error occured during signing',
  'CertificateSignature.verifyModal.button.hide': 'Hide',
  'CertificateSignature.error.GetSignInfoForUploadError': 'Error getting document info for upload to BankID',
  'CertificateSignature.error.DownloadSignedDocumentFromStorageError': 'Error download signed document from storage',
  'CertificateSignature.error.ReadingSignedMetadataError': 'Error reading signed file metadata',
  'CertificateSignature.error.InitialDocumentRequestError': 'Initial request to sign document failed',
  'CertificateSignature.error.RosUrlsError': 'Urls in bankID response is missing',
  'CertificateSignature.error.SigningRequestExpiredError': 'Signing request expired',
  'CertificateSignature.error.InitialBankIdRequestError': 'Initial request to sign document failed',
  'CertificateSignature.error.FileUploadBankIDError': 'Error uploading file to BankID',
  'CertificateSignature.error.GetSignInfoForDownloadError': 'Error getting document info for download from BankID',
  'CertificateSignature.error.DownloadSignedDocError': 'Error downloading signed document from BankID',
  'CertificateSignature.error.UploadSignedDocError': 'Error uploading signed document to hub',
  'CertificateSignature.error.CreateSignedDocError': 'Error creating signed document on hub',
  'CertificateSignature.error.ClientNotEligible': 'BankID user has no permission for document signing',
  'CertificateSignature.error.AccessDenied': 'User is not verified by BankID',
  'CertificateSignature.error.DocumentMissingCreateDate': 'The document does not contain the mandatory date of creation',
  'CertificateSignature.error.UnknownError': 'BankId login failed with error',
  'ColorPickerInput.refresh': 'Generate color',
  'DiscussionNote.closeEditing': 'Cancel edit',
  'DiscussionNote.edit': 'Edit',
  'DiscussionNote.delete': 'Delete',
  'DiscussionNote.addAttachments': 'Add attachments',
  'DiscussionNote.deletedNote': 'This comment has been deleted.',
  'DiscussionNote.restore': 'Back',
  'DiscussionNote.linkAttachments.title': 'Link attachments',
  'DiscussionNote.linkAttachments.link': 'Link',
  'DiscussionNote.Notification.noticeWasSentTo': 'Notified to: ',
  'DiscussionNote.Notification.sendNoticeTo': 'Send notice to: ',
  'DiscussionNote.Notification.tooltipToAddUserButton': 'Select users to send notification',
  'DiscussionNote.Notification.teamDropdownButton': 'Select users from team',
  'DiscussionNote.Notification.roleDropdownButton': 'Select users from role',
  'DiscussionNote.Notification.modalTitleUserSelecting': 'Select users to send notification',
  'DiscussionNote.Notification.mainProcessor': 'Main processors',
  'DiscussionNote.Notification.mainSubmitter': 'Main submitters',
  'CommentProcedure.general.processorTeams': 'Processors teams',
  'CommentProcedure.general.submitterTeams': 'Submitters teams',
  'CommentProcedure.general.category': 'Comment category',
  'CommentProcedureAddCommentForm.annotationMessageSegment': 'Page: {page}\nContent: {content}',
  'CommentProcedureAddCommentForm.title': 'Comment name',
  'CommentProcedureAddCommentForm.title.required': 'Please fill in the name',
  'CommentProcedureAddCommentForm.message': 'Comment text',
  'CommentProcedureAddCommentForm.message.required': 'Please fill in the text',
  'CommentProcedureAddCommentForm.commentProcedureCommentDocuments': 'Commented documents',
  'CommentProcedureAddCommentForm.notSet': 'Not set',
  'CommentProcedureAddCommentForm.commentProcedure': 'For the comment procedure',
  'CommentProcedureAddCommentFormModal.title': 'Add a new comment',
  'CommentProceduresPageToolbar.export': 'Export comment procedures',
  'CommentProceduresPageToolbar.newComment': 'New comment procedure',
  'CommentProcedureAddForm.commentedDocuments': 'Documents for commenting',
  'CommentProcedureAddForm.commentedDocuments.required': 'Please select documents for commenting.',
  'CommentProcedureAddForm.commentedDocuments.modalTitle': 'Select documents for commenting',
  'CommentProcedureAddForm.name': 'Procedure name',
  'CommentProcedureAddForm.name.required': 'Please fill in the procedure name.',
  'CommentProcedureAddForm.description': 'Procedure description',
  'CommentProcedureAddForm.headOfSubmitters': 'Head of Submitters',
  'CommentProcedureAddForm.headOfProcessors': 'Head of Processors',
  'CommentProcedureAddForm.role.required': 'Pleas select a role.',
  'CommentProcedureAddFormModal.title': 'Create new Comment procedure',
  'CommentProcedureCommentsAddAttachment.addAttachment': 'Add attachment',
  'AttachmentHeader.addDocument': 'Select from documents',
  'AttachmentHeader.addNewFile': 'Upload new file',
  'CommentProcedureCommentsAddAttachment.discadToggleTooltip': 'Show discarded',
  'AttachmentList.discardAttachment.tooltip': 'Discard attachment',
  'AttachmentList.restoreAttachment.tooltip': 'Restore attachment',
  'AttachmentList.insufficientPermissionTooltip': 'Insufficient permission',
  'AttachmentList.discardAttachment.confirm': 'Are you sure you want to discard this attachment?',
  'AttachmentList.restoreAttachment.confirm': 'Are you sure you want to restore this attachment?',
  'CommentProcedureDeadline.for.Commenting': 'Deadline for creating comments',
  'CommentProcedureDeadline.for.InternalNegotiations': 'Deadline for internal negotiation',
  'CommentProcedureDeadline.for.Negotiations': 'Deadline for negotiating comments',
  'CommentProcedureDeadline.for.Incorporation': 'Deadline for incorporation comments',
  'CommentProcedureDeadline.for.Approval': 'Deadline for approving comments',
  'CommentProcedureDeadline.for.Approved': 'Deadline for approval',
  'CommentProcedureDeadline.for.Closed': 'Deadline for closing',
  'CommentProcedureDeadlineForm.reason.required': 'Please provide a reason for deadline change',
  'CommentProceduresPageToolbar.addCommentProcedureSuccess': 'A new comment procedure has been successfully created.',
  'CommentProcedureDetailPage.newComment': 'New comment',
  'CommentProcedureAddDocumentButton.addDocuments': 'Add document',
  'CommentProcedureAddDocumentButton.commentDocument.title': 'Add document to comment',
  'CommentProcedureAddDocumentButton.selectDocument': 'Select from available documents',
  'CommentProcedureAddDocumentButton.selectDocument.title': 'Add documents for commenting',
  'CommentProcedureAddDocumentButton.uploadDocument': 'Upload a new document',
  'CommentProcedureCommentDocuments.removeDocument.tooltip': 'Remove document',
  'CommentProcedureCommentDocuments.removeDocument.confirm': 'Are you sure you want to remove this document from the comment?',
  'CommentProcedureCommentDocuments.downloadWithAnnotationDocument.error.notFound': 'Document for download not found',
  'CommentProcedureCommentDiscussion.tabs.common': 'Common',
  'CommentProcedureCommentDiscussion.tabs.internalSubmitter': 'Internal Submitters',
  'CommentProcedureCommentDiscussion.tabs.internalProcessor': 'Internal Processors',
  'CommentProcedureDetailPage.commentName': 'Comment name',
  'CommentProcedureDetailPage.export': 'Export procedure',
  'CommentProcedureDetailPage.changeStateMultiple': 'Change comments state',
  'CommentProcedureDetailPage.multipleSelectTitle': 'Multiple comments select',
  'CommentProcedureDetailPage.filterUserTitle': 'Comment author',
  'CommentProcedureDetailPage.closeProcedure': 'Close procedure',
  'CommentProcedureDetailPage.closeProcedure.confirm': 'Are you sure you want to close this comment procedure?',
  'CommentProcedureDetailPage.tabs.comments': 'Comments',
  'CommentProcedureDetailPage.tabs.documents': 'Documents ({count})',
  'CommentProcedureDetailPage.tabs.users': 'Users',
  'CommentProcedureDocument.revisionDateLabel': 'Revision in CP: {date}',
  'CommentProcedureDocumentDownloadModal.comentStateFilter.text': 'Comment state for download',
  'CommentProcedureDocumentDownloadModal.finishInBackground.text': 'Close (finish in background)',
  'CommentProcedureDocumentTab.annotate.tooltip': 'Annotate document',
  'CommentProcedureDocumentTab.Edit.tooltip': 'Edit document',
  'CommentProcedureDocumentTab.compareRevisions.tooltip': 'Compare revisions',
  'CommentProcedureDocumentTab.downloadWithAnnotations.tooltip': 'Download with annotations',
  'CommentProcedureDocumentTab.remove.confirm': 'Remove this document from the comment procedure?',
  'CommentProcedureDocumentTab.remove.tooltip': 'Remove document from CP',
  'CommentProcedureDocumentTab.filter.inComment.label': 'Commented',
  'CommentProcedureDocumentTab.filter.inComment.tooltip': 'Show documents that are part of a comment.',
  'CommentProcedureDocumentTab.filter.inComment.yes': 'Yes - only commented documents',
  'CommentProcedureDocumentTab.filter.inComment.no': 'No - only not commented documents',
  'CommentProcedureDocumentsTab.revisionsModalTitle': 'Document revisions on this Comment procedure',
  'CommentProcedureErrors.noErrors': 'Comment procedure does not contain any errors',
  'CommentProcedureErrors.severity': 'Error severity',
  'CommentProcedureErrors.subject': 'Error type',
  'CommentProcedureErrors.roleOrUserId': 'Affected role/user',
  'CommentProcedureErrors.subjectName': 'Subject name',
  'CommentProcedureErrors.commentProcedureRole': 'Team type',
  'CommentProceduresListItem.createdDate': 'Created: {date}',
  'CommentProceduresListItem.createdBy': 'Created by',
  'CommentProceduresListItem.hasWorkflow': 'Workflow instance attached',
  'CommentProcedurePhaseEnum.Commenting': 'Commenting',
  'CommentProcedurePhaseEnum.InternalNegotiations': 'Internal negotiations',
  'CommentProcedurePhaseEnum.Negotiations': 'Negotiations',
  'CommentProcedurePhaseEnum.Incorporation': 'Incorporation',
  'CommentProcedurePhaseEnum.Approval': 'Approval',
  'CommentProcedurePhaseEnum.Approved': 'Approved',
  'CommentProcedurePhaseEnum.Closed': 'Closed',
  'CommentProcedureSetState.documentsWarning':
    'Some documents are in states Archived or Rejected and cannot therefore be approved. Do you want to approve all other documents?',
  'CommentProcedureSetState.approveDocuments': 'Approve comment procedure documents',
  'CommentProcedureSetState.dontApproveDocuments': 'Don\'t approve comment procedure document',
  'CommentProcedureSetState.target.internalNegotiations.button': 'Pass to internal negotiations',
  'CommentProcedureSetState.target.internalNegotiations.confirm': 'Are you sure to pass the comment procedure to internal negotiations?',
  'CommentProcedureSetState.target.negotiations.button': 'Send reactions',
  'CommentProcedureSetState.target.negotiations.confirm': 'Are you sure to show comment reactions to submitters?',
  'CommentProcedureSetState.target.incorporation.button': 'Pass to incorporation',
  'CommentProcedureSetState.target.incorporation.confirm': 'Are you sure to pass the comment procedure to incorporation?',
  'CommentProcedureSetState.target.approval.button': 'Pass to approval',
  'CommentProcedureSetState.target.approval.confirm': 'Are you sure to pass the comment procedure to approval?',
  'CommentProcedureSetState.target.approved.button': 'Approve',
  'CommentProcedureSetState.target.approved.confirm': 'Are you sure to approve the entire comment procedure?',
  'CommentProcedureCommentsAttachments.button.publish.tooltip': 'Publish',
  'CommentProcedureCommentsAttachments.relationIcon.both.tooltip': 'Published',
  'CommentProcedureCommentsAttachments.relationIcon.processor.tooltip': 'Internal for processors',
  'CommentProcedureCommentsAttachments.relationIcon.submitter.tooltip': 'Internal for submitters',
  'CommentProcedureCommentDiscussionRelationEnum.None': 'None',
  'CommentProcedureCommentDiscussionRelationEnum.Processor': 'Processors',
  'CommentProcedureCommentDiscussionRelationEnum.Submitter': 'Submitters',
  'CommentProcedureCommentDiscussionRelationEnum.Both': 'Public',
  'CommentProcedureCommentStateEnum.Commenting': 'Commenting',
  'CommentProcedureCommentStateEnum.Discarded': 'Discarded',
  'CommentProcedureCommentStateEnum.InternallyDiscarded': 'Internally discarded',
  'CommentProcedureCommentStateEnum.ForDiscussion': 'For discussion',
  'CommentProcedureCommentStateEnum.ForSolution': 'To solve',
  'CommentProcedureCommentStateEnum.ForIncorporation': 'For incorporation',
  'CommentProcedureCommentStateEnum.Incorporated': 'Incorporated',
  'CommentProcedureCommentStateEnum.Approved': 'Approved',
  'CommentProcedureBulkUpdateForm.state': 'New state',
  'CommentProcedureBulkUpdateFormModal.title': 'Bulk update Comment procedure comments',
  'CommentProcedureCreateTeamForm.name': 'Team name',
  'CommentProcedureCreateTeamForm.name.required': 'Please fill in the team name',
  'CommentProcedureCreateTeamForm.commentProcedureRole': 'Team type',
  'CommentProcedureCreateTeamForm.commentProcedureRole.required': 'Please select the team type',
  'CommentProcedureCreateTeamFormModal.title': 'Add a new team',
  'CommentProcedureDetailPage.tabs.participants': 'Participants',
  'CommentProcedureEditTeamFormModal.title': 'Edit team',
  'CommentProcedureParticipants.tabName.responsiblePersons': 'Responsible persons',
  'CommentProcedureParticipants.tabName.teams': 'Teams',
  'CommentProcedureParticipants.tabName.users': 'Users',
  'CommentProcedureTeams.addTeam': 'Add team',
  'CommentProcedureTeams.teamAdmin': 'Admin',
  'CommentProcedureTeams.teamAdmins': 'Team admins',
  'CommentProcedureTeams.teamMember': 'Member',
  'CommentProcedureTeams.teamMembers': 'Team members',
  'CommentProcedureTeams.moveToAdmins': 'Move to admin',
  'CommentProcedureTeams.moveToMembers': 'Move to members',
  'CommentProcedureTeams.lastTeamWarning.title': 'You are removing yourself',
  'CommentProcedureTeams.lastTeamWarning.message':
    'By removing yourself you will lose access to this comment procedure. Remove yourself anyway?',
  'CommentProcedureTeams.memberAllowStatusChange': 'Members are allowed to set up comments status',
  'CommentProcedureRoleEnum.Processor': 'Processor',
  'CommentProcedureRoleEnum.Processors': 'Processors',
  'CommentProcedureRoleEnum.Submitter': 'Submitter',
  'CommentProcedureRoleEnum.Submitters': 'Submitters',
  'CommentProcedureCommentsDetails.teamNotSet': 'Not set',
  'CommentProcedureCommentsDetails.tabs.discussion': 'Discussion',
  'CommentProcedureCommentsDetails.tabs.annotations': 'Annotations',
  'CommentProcedureCommentsDetails.tabs.documents': `Documents {count, plural,
    =0 {}
    other {(#)}
  }`,
  'CommentProcedureCommentsDetails.notSaveAlert': 'Comment not saved. If you continue, your changes will be lost.',
  'CommentProcedureCommentsNote.notSaveAlert': 'Comment note not saved. If you continue, your changes will be lost.',
  'CommentProcedureExportForm.author': 'Author of the comment',
  'CommentProcedureExportForm.ButtonFilter': 'Add filter',
  'CommentProcedureExportForm.Filter': 'Filter',
  'CommentProcedureExportForm.sortA_Z': 'Sort A to Z (Descending)',
  'CommentProcedureExportForm.sortBy': 'Sort by',
  'CommentProcedureExportForm.sortId': 'ID (chronological)',
  'CommentProcedureExportForm.state': 'State of the comment',
  'CommentProcedureExportItemFormModal.button': 'Export',
  'CommentProcedureExportItemFormModal.title': 'Export comments',
  'CommentProcedureDetailPage.tabs.categories': 'Categories',
  'CommentProcedureDetailPage.tabs.errors': 'Errors',
  'CommentProcedureCategories.tabName.categories': 'Categories',
  'CommentProcedureCreateCategoryForm.name': 'Category name',
  'CommentProcedureCreateCategoryForm.name.required': 'Please fill in the category name',
  'CommentProcedureCreateCategoryForm.description': 'Category description',
  'CommentProcedureCreateCategoryFormModal.title': 'Add category',
  'CommentProcedureEditCategoryFormModal.title': 'Edit category',
  'CommentProcedureCategories.addCategory': 'Add category',
  'CommentProcedureCategories.import': 'Import categories',
  'CommentProcedureCategories.importSubmitButton': 'Import',
  'CommentProcedureImportCategoryForm.commentProcedure': 'From comment procedure',
  'CommentProcedureImportCategoryForm.commentProcedure.required': 'Please select comment procedure',
  'CommentProcedureImportCategoryFormModal.title': 'Import categories',
  'CommentProcedureLinkAttachment.tooltip.attach': 'Link attachments',
  'CommentProcedureLinkAttachment.tooltip.disabled': 'Cannot add attachment',
  'CommentProcedureLinkAttachment.tooltip.maxAttachmentsExceeded': 'Maximum number of attachment linked',
  'CommentProcedureLinkAttachment.tooltip.noAttachments': 'No attachments are available',
  'CommentProcedureLinkAttachment.tooltip.noMoreAttachments': 'No more attachments are available',
  'CommentProcedureRolesSettings.mainSubmitterCanEditProcessorTeams': 'Main submitter can set up a team of processors',
  'CommentProcedureRolesSettings.mainProcessorCanEditSubmitterTeams': 'Main processor can set up a team of submitters',
  'CommentProcedureRolesSettings.title': 'Comment Procedure and Workflow',
  'CommentProcedureRolesSettings.useWorkDays': 'Set default working days instead of calendar days',
  'CommentProcedureLinkAttachment.staged.tooltip': 'Prepared for upload',
  'DiscussionAssignAttachmentForm.assignedDocuments': 'Documents assigning to attachments',
  'DiscussionAssignAttachmentForm.assignedDocuments.modalTitle': 'Select documents to assign into attachments',
  'DiscussionLinkAttachment.warning.publishing': 'Internal attachments will be published',
  'DiscussionLinkAttachmentForm.title': 'Create attachment name',
  'DiscussionLinkAttachmentForm.name': 'File name',
  'DiscussionLinkAttachmentForm.name.required': 'Please enter file name for attached image',
  'DiscussionLinkAttachmentForm.disabled': 'Maximum number of attachment linked, new attachment cannot be created.',
  'CommentText.messageTitle': 'Comment text:',
  'CommentText.additionalMessageTitle': 'Response to the comment:',
  'DiscussionInput.placeholder': 'Insert comment...',
  'DiscussionInput.send': 'Send message',
  'CompareRevisions.title': 'Document revision comparison',
  'CompareRevisions.firstRevision': 'First revision:',
  'CompareRevisions.secondRevision': 'Second revision:',
  'CompareRevisions.comparisonType': 'Comparison type:',
  'CompareRevisions.comparisonType.Text': 'Text',
  'CompareRevisions.comparisonType.Drawing': 'Drawing (PDF)',
  'CompareRevisions.comparisonType.ModelDiffTool': 'Model (DiffTool)',
  'DateFilter.unlimited': '(unlimited)',
  'DateRangeForm.monthFrom': 'Month from',
  'DateRangeForm.monthTo': 'Month to',
  'DateRangeForm.title': 'Select from/to date',
  'DateRangeForm.yearFrom': 'Year from',
  'DateRangeForm.yearTo': 'Year to',
  'DateRangeForm.reset': 'Reset',
  'DateRangeForm.cancel': 'Cancel',
  'DateRangeForm.confirm': 'Confirm',
  'DateRangeForm.defaultRange': 'Default range',
  'DateRangeForm.previousRange': 'Last set range',
  'DateSelect.n_workDays': `{workDays, plural,
    =0 {this workday}
    one {next workday}
    other {# work days}
  }`,
  'DateSelect.n_weekDays': `{weekDays, plural,
    =0 {this week day}
    one {next week day}
    other {# week days}
  }`,
  'DateSelect.n_calendarDays': `{calendarDays, plural,
    =0 {today}
    one {tomorrow}
    other {# days}
  }`,
  'Deadline.changeDeadline': 'Change deadline',
  'Deadline.changeReason': 'Change reason:',
  'Deadline.unlimited': 'Unlimited',
  'DeadlineExtensionRequestModal.title':'Request for an extension of the task deadline',
  'DeadlineExtensionRequestModal.submitText':'Submit request',
  'DeadlineExtensionRequestForm.actualTerm':'Actual term:',
  'DeadlineExtensionRequestForm.justification':'Justification of the request',
  'DeadlineExtensionRequestForm.justification.required':'Decision is required',
  'DeadlineExtensionRequestForm.newTerm':'Proposed term',
  'DeadlineExtensionApprovalForm.applicant':'Applicant: ',
  'DeadlineExtensionApprovalForm.proposedTerm':'Proposed term: ',
  'DeadlineExtensionApprovalForm.requestJustification':'Request justification: ',
  'DeadlineExtensionApprovalForm.decisionJustification':'Decision justification: ',
  'DeadlineExtensionApprovalForm.decisionJustification.required':'Decision justification is required',
  'DeadlineExtensionApprovalForm.decisionApproval': 'Decision:',
  'DeadlineExtensionApprovalForm.decisionApproval.required': 'Recisopm is required',
  'DeadlineExtensionApprovalForm.switch.Approve':'Approve',
  'DeadlineExtensionApprovalForm.switch.Reject':'Reject',
  'DeadlineExtensionApprovalForm.submitText.approve':'Approve extension',
  'DeadlineExtensionApprovalForm.submitText.reject':'Reject extension',
  'DeepShareDownloadFormModal.title': 'Directory Download Sharing',
  'DeepShareDownloadFormModal.success': 'Link to shared download has been sent successfully.',
  'DerivativesFileViewerModal.firstPagePreview': 'First page preview',
  'DerivativesFileViewerModal.preview': 'Preview',
  'DerivativesFileViewerModal.original': 'Original',
  'DerivativesFileViewerModal.signedDocument': 'Signed document',
  'DerivativesFileViewerModal.discardedAttachment': 'Discarded',
  'DeleteButton.cannotDeleteYourself': 'You cannot remove yourself',
  'DeleteButton.orgHasProject': 'Cannot delete an organization that already has a project',
  'DeleteButton.isLastAdmin': 'Unable to remove last administrator',
  'DocumentCompleteList.downloadedZipName': 'documents',
  'DocumentList.cannotSelectDiscarded': 'Cannot select discarded document',
  'DocumentsMoveFormModal.destinationDirectoryId.label': 'To folder',
  'DocumentMultipleActionError.error.NotFound': 'Document <strong>{name}</strong> not found.',
  'DocumentMultipleActionError.error.IsDiscarded': 'Document <strong>{name}</strong> is discarded.',
  'DocumentMultipleActionError.error.IsNotDiscarded': 'Document <strong>{name}</strong> is not discarded.',
  'DocumentMultipleActionError.error.BadDirectory': 'Document <strong>{name}</strong> is not in source folder. It was moved to folder {path}.',
  'DocumentMultipleActionError.error.AccessDenied': 'You do not have sufficient permission to document <strong> {name} </strong>.',
  'DocumentMultipleActionError.error.HasDocToDocLink': `{count, plural,
    =0 {no}
    one {Document <strong>{name}</strong> has # link. Link will be removed.}
    other {Document <strong>{name}</strong> has # links. Links will be removed.}
    }`,
  'DocumentMultipleActionError.error.HasModelElementLink': `{count, plural,
      =0 {no}
      one {Model <strong>{name}</strong> has # link to document. Link will be removed.}
      other {Model <strong>{name}</strong> has # links to documents. Links will be removed.}
      }`,

  'DocumentMultipleActionError.error.InWorkflow': 'Document <strong>{name}</strong> is assigned in a workflow.',
  'DocumentMultipleActionError.error.InCommentProcedure': 'Document <strong>{name}</strong> is in the comment procedure.',
  'DocumentMultipleActionError.error.InActiveWorkflow': 'Document <strong>{name}</strong> is already in the active workflow.',
  'DocumentMultipleActionError.error.InActiveCommentProcedure': 'Document <strong>{name}</strong> is already in the active comment procedure.',
  'DocumentMultipleActionError.error.InappropriateDocumentState': 'Document <strong>{name}</strong> must be in "Shared" state.',
  'DocumentMultipleActionError.error.Reserved': 'Document <strong>{name}</strong> is reserved.',
  'DocumentMultipleActionError.error.HasOwner': 'Document <strong>{name}</strong> has a Document Manager.',
  'DocumentMultipleActionError.error.EsticonDocument': 'Document <strong>{name}</strong> is imported from AspeEsticon.',
  'DocumentMultipleActionError.error.EsticonDocumentDilci': 'Document <strong>{name}</strong> is a part of a grouped import from AspeEsticon.',
  'DocumentMultipleActionError.error.LinkNotFound': 'Document shortcut <strong>{name}</strong> not found.',
  'DocumentMultipleActionError.error.LinkBadDirectory': 'Shortcut <strong>{name}</strong> is in wrong directory.',
  'DocumentMultipleActionError.error.LinkAccessDenied':
    'You do not have sufficient permission to access shortcut <strong>{name}</strong>.',
  'DocumentMultipleActionError.error.LinkedDocumentAccessDenied':
    'You do not have sufficient permission to access binded document <strong>{name}</strong>.',
    'DocumentMultipleActionError.error.NotInModel':'The document <strong>{name}</strong> is not in the models, cannot be removed.',
    'DocumentMultipleActionError.error.AlreadyInModel':'The document <strong>{name}</strong> is already in the models.',
  'DocumentRow.tooltip.lastRevision': 'Last revision: {date}',
  'DocumentRow.tooltip.originalDocument': '(Document created: {date})',
  'DocumentRow.tooltip.attachmentUpload': 'Upload date: {date}',
  'DocumentRow.tooltip.revisionCreated': 'Revision created: {date}',
  'DocumentRow.tooltip.documentLinkCreated': 'Document link created: {date}',
  'DocumentsGate.loading': 'Loading documents...',
  'DocumentsDiscardFormModal.button.Discard': 'Discard',
  'DocumentsDiscardFormModal.button.DiscardNext': 'Discard Others',
  'DocumentsDiscardFormModal.button.DiscardLinks': 'Remove Links and References and Discard Documents',
  'DocumentsDiscardFormModal.button.DiscardLinksNext': 'Remove Links and References and Discard Others Documents',
  'DocumentsDiscardForm.DiscardDocuments': `{count, plural,
    =0 {Do you want discard 0 selected documents?}
    one {Do you want discard 1 selected document?}
    few {Do you want discard # selected documents?}
    other {Do you want discard # selected documents?}
  }`,
  'DocumentsDiscardForm.DiscardDocumentLinks': `{count, plural,
    =0 {Do you want discard 0 selected document shortcuts?}
    one {Do you want discard 1 selected document shortcuts?}
    few {Do you want discard # selected document shortcuts?}
    other {Do you want discard # selected document shortcuts?}
  }`,
  'DocumentsDiscardForm.errorsTitle.missed': `{count, plural,
    =0 {nothing bad}
    one {An error occurred while discarding. No documents have been discarded.}
    few {Errors occurred during discarding. No documents have been discarded.}
    other {Error occurred during discarding. No documents have been discarded.}
  }`,
  'DocumentsDiscardFormModal.title': 'Discard documents',
  'DocumentsDownloadForm.downloadDocuments': `{count, plural,
    =0 {nothing for move}
    one {Download 1 document from folder}
    few {Download # documents from folder}
    other {Download # documents from folder}
  }`,
  'DocumentsDownloadForm.downloadDocumentLinks': `{count, plural,
    =0 {nothing for move}
    one {Download 1 document shortcut from folder}
    few {Download # document shortcuts from folder}
    other {Download # document shortcuts from folder}
  }`,
  'DocumentsDownloadForm.errorsTitle.missed': `{count, plural,
    =0 {nothing bad}
    one {An error occurred during archive preparation. Archive haven't been moved.}
    few {Errors occurred during archive preparation. Archive haven't been moved.}
    other {Errors occurred during archive preparation. Archive haven't been moved.}
  }`,
  'DocumentsDownloadForm.nameLabel': 'Archive Name',
  'DocumentsDownloadForm.form.items.name.rules.required': 'Please enter a name of the documents archive',
  'DocumentsDownloadForm.form.items.name.rules.valid': 'Please enter a valid file name',
  'DocumentsDownloadFormModal.title': 'Download Documents',
  'DocumentsDownloadFormModal.button.download': 'Download',
  'DocumentsDownloadFormModal.button.downloadNext': 'Download Others',
  'DocumentLinksCreateFormModal.title': `{count, plural,
    one {Create link to document}
    other {Create links to documents}
  }`,
  'DocumentLinksCreateFormModal.okButton': 'Create links',
  'DocumentLinksCreateFormModal.okButton.createNext': 'Create other links',
  'DocumentLinksCreateFormModal.result.success': `{count, plural,
    one {Document links created successfully}
    other {Document links created successfully}
  }`,
  'DocumentLinksCreateForm.bindingsToDocuments': `{count, plural,
    =0 {Create links to 0 selected documents in folder}
    one {Create link to 1 selected document in folder}
    other {Create links to # selected documents in folder}
  }`,
  'DocumentLinksCreateForm.destinationDirectoryId.label': 'To directory',
  'DocumentLinksCreateForm.documentLinkName.label': 'Shortcut name',
  'DocumentLinksCreateForm.errors': 'Errors in documents',
  'DocumentLinkRenameFormModal.title': 'Change the name of the shortcut',
  'DocumentLinkRenameForm.linkName': 'Name',
  'DocumentLinkRenameForm.createdDate': 'The date the shortcut was created:',
  'DocumentLinkRenameForm.targetPath': 'The path to the binded (target) document:',
  'DocumentLinkRenameForm.linkLocation': 'Shortcut location:',
  'DocumentLinkRenameForm.linkAuthor': 'Shortcut author:',
  'DocumentsGridHeader.select': 'Select all',
  'DocumentsGridHeader.deselect': 'Deselect all',
  'docMenu.restore': 'Restore',
  'DiscardDirectory.Modal.description': 'Are you sure you want to discard the selected folder, including all files and subfolders?',
  'DiscardDirectory.Modal.description.links': 'Some documents have links. Are you sure you want to discard the selected folder, including all files, links and subfolders?',
  'DiscardDirectory.Modal.title': 'Folder Discarding',
  'DiscardDirectoryLink.Modal.description': 'Are you sure you want to discard the selected folder shortcuts?',
  'DiscardDirectoryLink.Modal.title': 'Folder shortcut discarding',
  'DiscardDirectoryLink.Modal.targetFolder': 'Directory shortcut: ',
  'DiscardedDocumentRow.discarded': 'Discarded',
  'DiscardedDocumentRow.discardedBy': 'Discarded by',
  'DiscardedDocumentRow.discardedTitle': 'Discarded: {date}',
  'DiscardedDocumentRow.restore': 'Restore',
  'DiscardedDocumentsPage.loading': 'Loading discarded documents and folders...',
  'DiscardedFolderRow.discardedBy': 'Discarded by',
  'DiscardedFolderRow.discardedTitle': 'Discarded: {date}',
  'DiscardedFolderRow.restoreFolder': 'Restore folder',
  'DiscardedFolderRow.checkboxTooltip': 'Folders cannot be selected in bulk',
  'DocumentationLevelFilter.undefined':'0 - Undefined',
  'DocumentationLevelFilter.zp':'1 - ZP',
  'DocumentationLevelFilter.dur':'2 - DUR',
  'DocumentationLevelFilter.dusl':'3 - DUSL',
  'DocumentationLevelFilter.dusp':'4 - DUSP',
  'DocumentationLevelFilter.dsp':'5 - DSP',
  'DocumentationLevelFilter.pdps':'6 - PDSP',
  'DocumentationLevelFilter.rds':'7 - RDS',
  'DocumentationLevelFilter.dsps':'8 - DSPS',
  'DocumentationLevelFilter.finished':'9 - Finished',
  'DocumentsMoveFormModal.button.move': 'Move',
  'DocumentsMoveFormModal.button.moveAndRename': 'Move and Rename',
  'DocumentsMoveFormModal.button.moveNext': 'Move Others',
  'DocumentsMoveFormModal.errorsTitle.duplicate': `{count, plural,
    =0 {nothing duplicate}
    one {The following document with the same name already exists in destination folder}
    few {Following documents with the same name already exist in destination folder}
    other {Following documents with the same name already exist in destination folder}
  }`,
  'DocumentsMoveFormModal.errorsTitle.duplicateLinks': `{count, plural,
    =0 {nothing duplicate}
    one {The following document shortcut with the same name already exists in destination folder}
    few {Following documents shortcuts with the same name already exist in destination folder}
    other {Following documents shortcuts with the same name already exist in destination folder}
  }`,
  'DocumentsMoveFormModal.errorsTitle.missed': `{count, plural,
    =0 {nothing bad}
    one {An error occurred while moving. No documents have been moved.}
    few {Errors occurred during move. No documents have been moved.}
    other {Errors occurred during move. No documents have been moved.}
  }`,
  'DocumentsMoveFormModal.moveDocuments': `{count, plural,
    =0 {nothing for move}
    one {Move 1 document}
    few {Move # documents}
    other {Move # documents}
  }`,
  'DocumentsMoveFormModal.moveLinks': `{count, plural,
    =0 {nothing for move}
    one {Move 1 document shortcut}
    few {Move # document shortcuts}
    other {Move # document shortcuts}
  }`,
  'DocumentsMoveFormModal.moveFromFolder': 'from folder:',
  'DocumentsMoveFormModal.title': 'Move Documents',
  'DocumentsMoveRenameInfoModal.title': `{count, plural,
    =0 {nothing bad}
    one {The following document was renamed}
    few {Following documents were renamed}
    other {Following documents were renamed}
  }`,
  'DocumentsMoveRenameInfoModal.item': 'Document <strong>{origin}</strong> was renamed to <strong>{destination}</strong>',
  'DocumentsRestoreFormModal.button.Restore': 'Restore',
  'DocumentsRestoreFormModal.button.RestoreNext': 'Restore Others',
  'DocumentsRestoreForm.RestoreDocuments': `{count, plural,
    =0 {Do you want restore 0 selected documents?}
    one {Do you want restore 1 selected document?}
    few {Do you want restore # selected documents?}
    other {Do you want restore # selected documents?}
  }`,
  'DocumentsRestoreFormModal.errorsTitle.duplicate': `{count, plural,
    =0 {nothing duplicate}
    one {The following document or folder with the same name already exists in destination folder}
    few {Following documents or folders with the same name already exist in destination folder}
    other {Following documents or folders with the same name already exist in destination folder}
  }`,
  'DocumentsRestoreForm.errorsTitle.missed': `{count, plural,
    =0 {nothing bad}
    one {An error occurred while restoring. No documents have been restored.}
    few {Errors occurred during restoring. No documents have been restored.}
    other {Error occurred during restoring. No documents have been restored.}
  }`,
  'DocumentsRestoreRenameInfoModal.dupliciteDocumentError': 'Document: <strong>{document}</strong>',
  'DocumentsRestoreRenameInfoModal.dupliciteDirectoryError': 'Directory: <strong>{directory}</strong>',
  'DocumentsRestoreFormModal.title': 'Documents Restore',
  'DirectoryCreateForm.form.items.name.label': 'Name',
  'DirectoryCreateForm.form.items.name.placeholder': 'Folder 1',
  'DirectoryCreateForm.form.items.name.rules.nameExists': 'Folder with this name already exists, please chose another name',
  'DirectoryCreateForm.form.items.name.rules.required': 'Please enter a name of the folder',
  'DirectoryCreateFormModal.button.create': 'Create folder',
  'DirectoryCreateFormModal.button.create.saving': 'Creating new folder...',
  'DirectoryCreateFormModal.title': 'New Folder',
  'DirectoryCreateLinkFormModal.title': 'Create directory shortcut',
  'DirectoryCreateLinkForm.source': 'Source directory',
  'DirectoryCreateLinkForm.label': 'To directory',
  'DirectoryCreateLinkForm.placeholder': 'Select the destination folder',
  'DirectoryCreateLinkForm.linkName': 'Shortcut name',
  'DirectoryEditForm.form.items.description.label': 'New description',
  'DirectoryEditForm.form.items.name.label': 'New name',
  'DirectoryEditForm.form.items.name.rules.nameExists': 'Folder with this name already exists, please chose another name',
  'DirectoryEditForm.form.items.name.rules.required': 'Please enter a name of the folder',
  'DirectoryEditFormModal.button.save': 'Create Folder',
  'DirectoryEditFormModal.button.save.saving': 'Creating new Folder...',
  'DirectoryEditFormModal.title': 'New Folder',
  'DirectoryLinkEditForm.form.items.name.label': 'New name of folder shortcut',
  'DirectoryLinkEditForm.form.items.name.rules.nameExists': 'Shortcut with this name already exists, please chose another name',
  'DirectoryLinkEditForm.form.items.name.rules.required': 'Please enter a name of the folder shortcut',
  'DirectoryLinkEditFormModal.button.save': 'Rename folder shortcut',
  'DirectoryLinkEditFormModal.button.save.saving': 'Creating new folder shortcut...',
  'DirectoryLinkEditFormModal.title': 'Editing a folder shortcut',
  'DirectoryForbiddenErrorBox.title': 'Access forbidden',
  'DirectoryForbiddenErrorBox.description': 'You do not have an access to this folder\'s documents.',
  'DirectoryForbiddenErrorBox.request': 'Request directory read access',
  'DirectoryForbiddenErrorBox.requestFailed': 'Sending request failed.',
  'DirectoryForbiddenErrorBox.requestSent': 'Your request has been sent successfully.',
  'DirectoryForm.form.items.description.placeholder': 'Folder description...',
  'DirectoryLinkMoveFormModal.title': 'Move folder shortcut',
  'DirectoryLinkMoveFormModal.destinationDirectoryId.label': 'Move to',
  'DirectoryLinkMoveFormModal.destinationDirectoryId.placeholder': 'Choose destination folder',
  'DirectoryLinkMoveFormModal.preserveAccessRights': 'Preserve Access Rights',
  'DirectoryLinkMoveFormModal.rename': 'Rename',
  'DirectoryLinkMoveFormModal.selectedDirectory': 'Selected folder',
  'DirectoryLinkMoveFormModal.moveDirectoryLink': 'Move folder shortcut',
  'DirectoryLinkMoveFormModal.moveError': 'Errors occurred during move. The folder shortcut was not moved.',
  'DirectoryMoveFormModal.title': 'Move folder',
  'DirectoryMoveFormModal.destinationDirectoryId.label': 'Move to',
  'DirectoryMoveFormModal.destinationDirectoryId.placeholder': 'Choose destination folder',
  'DirectoryMoveFormModal.preserveAccessRights': 'Preserve Access Rights',
  'DirectoryMoveFormModal.rename': 'Rename',
  'DirectoryMoveFormModal.selectedDirectory': 'Selected folder',
  'DirectoryMoveFormModal.moveDirectory': 'Move folder',
  'DirectoryMoveFormModal.moveError': 'Errors occurred during move. The folder was not moved.',
  'DirectoryRestoreForm.RestoreDirectory': 'Are you sure you want to restore the selected folder?',
  'DirectoryRestoreForm.errorsTitle': `Some directory from the documents list have duplicate names.`,
  'DirectoryRestoreFormModal.button.Restore': 'Restore',
  'DirectoryRestoreFormModal.button.RestoreAndRename': 'Restore and Rename',
  'DirectoryRestoreFormModal.button.RestoreNext': 'Restore other',
  'DirectoryRestoreFormModal.title': 'Restore Folder',
  'DirectorySettingsForm.noUserPermissions': 'No user permissions.',
  'DirectorySettingsForm.goToGroupsSettings': 'Go to groups settings',
  'DirectorySettingsForm.goToUsersSettings': 'Go to users Settings',
  'DirectorySettingsForm.userAccessTab.searchButton.placeholder': 'Search User by Name or E-mail',
  'DirectorySettingsForm.requiredCategories.label': 'Required categories',
  'DirectorySettingsForm.requiredCategories.placeholder': 'Default category location',
  'DirectorySettingsForm.requiredCategories.tooltip': 'Edit required categories',
  'DirectorySettingsForm.tabs.accessPermission': 'Permissions',
  'DirectorySettingsForm.tabs.general': 'General',
  'DirectorySettingsForm.tabs.general.type': 'Type',
  'DirectorySettingsForm.tabs.general.type.folder': 'Folder',
  'DirectorySettingsForm.tabs.general.title': 'Information about the contents of the folder',
  'DirectorySettingsForm.tabs.general.size': 'Folder size',
  'DirectorySettingsForm.tabs.general.sizeIncludingAttachments': 'Folder size including attachments',
  'DirectorySettingsForm.tabs.general.sizeIncludingSubfolders': 'Folder size including subfolders',
  'DirectorySettingsForm.tabs.general.sizeIncludingSubfoldersAndAttachments': 'Folder size including subfolders and attachments',
  'DirectorySettingsForm.tabs.general.documentsCount': 'Number of documents and folders',
  'DirectorySettingsForm.tabs.general.documentsCountIncludingSubfolders': 'Number of documents and folders including subfolders',
  'DirectorySettingsForm.permissions.current': 'Your folder permission',
  'DirectorySettingsForm.permissions.inheritLabel': 'Permission inheritance',
  'DirectorySettingsForm.permissions.groups': 'Groups',
  'DirectorySettingsForm.permissions.notSaveAlert': 'The settings are not saved. If you leave the page, your changes will be lost.',
  'DirectorySettingsForm.permissions.users': 'Users',
  'DirectorySettingsForm.permissions.save': 'Save',
  'DirectorySettingsForm.permissions.discardChanges': 'Discard changes',
  'DirectoryWatchSettings.title': 'Directory track settings',
  'DirectoryWatchSettings.watchParamsTitle': 'What do you want to track?',
  'DirectoryWatchSettings.inheritSettings': 'Inherit track settings',
  'DirectoryWatchSettings.category.directoryChange': 'Directory change',
  'DirectoryWatchSettings.category.subfolders': 'Subdirectories',
  'DirectoryWatchSettings.category.documentsChange': 'Documents change',
  'DirectoriesTree.itemsCount': 'Number of documents',
  'DirectoriesTree.subItemsCount': 'Number of documents (in subfolders)',
  'DirectoriesTree.linkedDirectoryPath': 'Binded directory path:',
  'DirectoriesTree.linkedDirectoryNotFound': 'Binded directory not found',
  'DirectoriesTreeSelect.other': '(DMS structure)',
  'DirectoriesTreeSelect.preferred': 'Selected folders',
  'DiscardDocumentsPageFilterToolbar.type': 'Type',
  'DiscardDocumentsPageFilterToolbar.type.file': 'Documents',
  'DiscardDocumentsPageFilterToolbar.type.folder': 'Folders',
  'DiscardDocumentsPageFilterToolbar.usersLabel': 'User',
  'DocumentAnnotationModal.buttons.updateAnnotations': 'Save annotations',
  'DocumentAnnotationModal.buttons.createCommentFromAnnotations': 'Create comment from annotations',
  'DocumentAnnotationModal.error.emptyAnnotation': 'Annotation is missing content',
  'DocumentAnnotationModal.error.outOfDateDocument': 'Document was updated by another user',
  'DocumentAnnotationModal.message.success': 'Annotations saved successfully',
  'DocumentAnnotationModal.notSavedAlert': 'Annotations are not saved. If you continue, the changes will be lost.',
  'DocumentCategoryTreeCreateFormModal.title': 'New category',
  'DocumentCategoryTreeNodeCreateFormModal.title': 'New category node',
  'DocumentCategoryTreeNodeDeleteForm.documentsWithNode': 'Affected documents',
  'DocumentCategoryTreeNodeDeleteForm.directoriesWithNode': 'Affected folders',
  'DocumentCategoryTreeNodeDeleteModal.ok': 'Delete',
  'DocumentCategoryTreeNodeDeleteModal.removeChildren.label': 'Remove child nodes?',
  'DocumentCategoryTreeNodeDeleteModal.removeChildren.preserve': 'Keep and move',
  'DocumentCategoryTreeNodeDeleteModal.removeChildren.remove': 'Remove',
  'DocumentCategoryTreeNodeDeleteModal.removeStrategy.label': 'How to reset the category for the affected documents and directories?',
  'DocumentCategoryTreeNodeDeleteModal.removeStrategy.replaceParent': 'Use parent category node',
  'DocumentCategoryTreeNodeDeleteModal.removeStrategy.replaceRoot': 'Use the root',
  'DocumentCategoryTreeNodeDeleteModal.title': 'Remove category node',
  'DocumentCreateForm.loadingCategoryTip': 'Loading required categories',
  'DocumentCreateForm.form.items.category.add': 'Add category',
  'DocumentCreateForm.form.items.category.add.noMore': 'No categories to add',
  'DocumentCreateForm.form.items.category.rules.required': 'Please select location of category',
  'DocumentCreateForm.form.items.category.select.placeholder': 'Select category',
  'DocumentCreateForm.form.items.category.treeSelect.placeholder': 'Select category location',
  'DocumentCreateForm.form.items.destinationDirectory.label': 'Location (folder)',
  'DocumentCreateForm.form.items.destinationDirectory.placeholder': 'Select document location',
  'DocumentCreateForm.form.items.destinationDirectory.rules.required': 'Please select document location',
  'DocumentCreateForm.form.items.name.placeholder': 'Document 1.pdf',
  'DocumentCreateForm.form.items.name.rules.nameExists': 'Document with this name already exists, please chose another name',
  'DocumentCreateForm.form.items.name.rules.required': 'Please enter a name of the document',
  'DocumentCreateForm.form.items.name.rules.noValidByMask':'The name does not match the masks set on the folder',
  'DocumentCreateForm.form.items.name.rules.nameDuplicity':'There is already a document with the same name in the destination folder. The file will be uploaded as a new document and automatically renamed.',
  'DocumentCreateForm.form.items.relation.rules.required': 'Please enter a relation of the document',
  'DocumentCreateForm.form.items.state.label': 'State',
  'DocumentCreateForm.form.items.state.rules.required': 'Please select state',
  'DocumentCreateForm.form.oldRevisionWarning': 'Warning! You are editing an older revision. ' +
    'Saving will create a new revision which will not contain changes from newer revisions.',
  'DocumentCreateForm.section.categories': 'Categories',
  'DocumentCreateFormModal.checkingFiles': 'Checking files...',
  'DocumentCreateFormModal.continue': 'Try to Continue',
  'DocumentCreateFormModal.cancelConfirm.title': 'Do you really want to stop recording??',
  'DocumentCreateFormModal.cancelConfirm.text': 'The document currently being uploaded and documents waiting to be uploaded will not be uploaded. Do you wish to continue?',
  'DocumentCreateFormModal.pause': 'Pause',
  'DocumentCreateFormModal.title': 'New Document',
  'DocumentCreateMultipleForm.inheritPermission.label': 'Inherit rights from the parent folder.',
  'DocumentCreateMultipleForm.dropAreaTitle': 'Click here or drop files',
  'DocumentCreateMultipleForm.file.description': 'Description',
  'DocumentCreateMultipleForm.file.description.placeholder': 'Enter an optional document description',
  'DocumentCreateMultipleForm.file.label': 'Document names correspond with entered file names',
  'DocumentCreateMultipleForm.file.manager': 'Manager',
  'DocumentCreateMultipleForm.file.rules.required': 'Please select files for upload',
  'DocumentCreateMultipleForm.file.rules.invalidNamesByMask': 'Some file or folder names do not match the masks set on the folders',
  'DocumentCreateMultipleForm.file.revisionComment': 'Comment',
  'DocumentCreateMultipleForm.file.revisionComment.rules.required': 'please enter revision comment',
  'DocumentCreateMultipleForm.file.revisionComment.placeholder': 'Enter revision comment',
  'DocumentCreateMultipleForm.form.items.documentOptions.title': 'Parameters of the new documents',
  'DocumentCreateMultipleForm.form.items.destinationDirectory.label': 'Location (destination folder)',
  'DocumentCreateMultipleForm.form.items.validationMaskFiles.label': 'Validating mask for file names in folder',
  'DocumentCreateMultipleForm.form.items.validationMaskFolders.label': 'Validating mask for folder names',
  'DocumentCreateMultipleForm.form.items.state.label': 'State',
  'DocumentCreateMultipleForm.form.items.revisionOptions.title': 'Parameters for revision of existing documents',
  'DocumentCreateMultipleForm.form.isModelSwitch.text': 'Add to models',
  'DocumentCreateMultipleFormModal.title': 'Upload documents and folders',
  'DocumentCreateMultipleForm.primary.label': 'Primary files',
  'DocumentCreateMultipleForm.primary.text': 'Pleas select primary files',
  'DocumentCreateMultipleForm.secondary.label': 'Attachment',
  'DocumentCreateMultipleForm.secondary.text': 'Pleas select attachments',
  'DocumentCreateMultipleForm.secondary.cantAdd': 'You cannot add signed document or attachments in bulk to all primary files on to folders only. To add them, insert only one primary file.',
  'DocumentCreateMultipleForm.signedDocument.label': 'Signed document',
  'DocumentDetailPage.disableComparison.tooltip': 'The comparison is not available because the license is missing or the document is not supported for this action',
  'DocumentDetailPage.annotationEdit.error': 'Document doesn\'t have PDF derivate for annotation',
  'DocumentDetailPage.createRevision': 'New revision',
  'DocumentDetailPage.createRevision.tooltip': 'Create new revision',
  'DocumentDetailPage.createRevision.disabled': 'Cannot create new revision.',
  'DocumentDetailPage.createRevision.tooltip.Ok': 'Create new revision.',
  'DocumentDetailPage.createRevision.tooltip.AccessDenied': 'Access denied.',
  'DocumentDetailPage.createRevision.tooltip.InWorkflow': 'Document is in workflow.',
  'DocumentDetailPage.createRevision.tooltip.InCommentProcedure': 'Document is in comment procedure.',
  'DocumentDetailPage.createRevision.tooltip.Reserved': 'Document is reserved.',
  'DocumentDetailPage.createRevision.tooltip.HasOwner': 'Document has owner.',
  'DocumentDetailPage.createRevision.tooltip.EsticonDocument': 'Document has link to AspeEsticon.',
  'DocumentDetailPage.directoryForbiddenMessage': 'Requested document is located in the {path} directory, to which you do not have access.',
  'DocumentDetailPage.downloadPrimaryFile.tooltip': 'Download primary file',
  'DocumentDetailPageCategories.editMode.tooltip': 'Edit categories',
  'DocumentDetailPage.favorite.saving': 'Saving favorite',
  'DocumentDetailPage.favorite.set': 'Add to Favorites',
  'DocumentDetailPage.favorite.unset': 'Remove from Favorites',
  'DocumentDetailPage.manager.error.documentInActiveWorkflow': 'Document is in active workflow',
  'DocumentDetailPage.manager.error.documentInCommentProcedure': 'Document is in comment procedure',
  'DocumentDetailPage.manager.error.documentIsReserved': 'Document is reserved',
  'DocumentDetailPage.manager.error.documentHasOwner': 'Document has owner',
  'DocumentDetailPage.manager.error.documentHasEsticonLink': 'AspeEsticon document cannot have owner',
  'DocumentDetailPage.model.add': 'Add to models',
  'DocumentDetailPage.model.remove': 'Remove from models',
  'DocumentDetailPage.previewNotAvailable': 'Preview is not available.',
  'DocumentDetailPage.secondaryFile': 'Attachments',
  'DocumentDetailPage.name.successMessage': 'Document name successfully changed',
  'DocumentDetailPageBindings.deleteSuccess': 'The binding to the document has been deleted',
  'DocumentDetailPageBindings.title': 'Bindings for this document',
  'DocumentDetailPageBindings.bindedDocuments.modalTitle': 'Selecting documents for referencing',
  'DocumentDetailPageBindings.addBinding': 'Add binding',
  'DocumentDetailPageBindings.addBinding.success': 'Bindings added successfully',
  'DocumentDetailPageBindings.addBinding.warning': `{count, plural,
    one {Binding to element already exists.}
    other {Binding added successfully. # bindings to element already existed.}
  }`,
  'DocumentDetailPageBindings.removeBinding': 'Remove this binding',
  'DocumentDetailPageBindings.removeBinding.success': 'Binding successfully removed',
  'DocumentDetailPageBindings.noBindingToSameDocument': 'A document cannot refer to itself.',
  'DocumentDetailPageBindings.bindingDuplicated': 'The binding to this document is already exited.',
  'DocumentDetailPageBindings.noSufficientPermissions': 'The binding to the selected document cannot be created. You do not have sufficient permissions.',
  'DocumentDetailPageBindings.selectDocumentsModal.title': 'Select documents to add binding',
  'DocumentDetailPageBindings.failureList.Modal.title': 'In the following cases, the document binding failed to be set',
  'DocumentDetailPageBindings.documentError.NotFound': 'File {fileName} not found',
  'DocumentDetailPageBindings.documentError.IsDiscarded': 'The file {fileName} has been removed',
  'DocumentDetailPageBindings.documentError.BadDirectory': 'The file {fileName} is no longer in the directory',
  'DocumentDetailPageBindings.documentError.AccessDenied': 'You do not have sufficient permissions to bind to the file {fileName}',
  'DocumentDetailPageBindings.documentError.PropertySet': 'A binding to file {fileName} has already been set',
  'DocumentDetailPageBindings.documentError.PropertyUnset': 'A binding to file {fileName} has already been unset',
  'DocumentDetailPageReferences.addReferences': 'Add reference to document',
  'DocumentDetailPageReferences.removeReference': 'Remove reference',
  'DocumentDetailPageReferences.order.reference': 'Reference target',
  'DocumentDetailPageReferences.order.referrer': 'Reference source',
  'DocumentDetailPageReferences.order.createdBy': 'Created by user',
  'DocumentDetailPageReferences.order.createdDate': 'Creation date',
  'DocumentDetailPageReferences.order.modifiedDate': 'Date of change',
  'DocumentDetailPageReferences.addReferences.documentsSelect.title': 'Select documents to add reference',
  'DocumentDetailPageReferences.noSufficientPermissions': 'You do not have sufficient permissions',
  'DocumentDetailPageReferences.noReferenceToSameDocument': 'A document cannot have a reference to itself.',
  'DocumentDetailPageReferences.referenceDuplicated': 'The reference to this document already exists.',
  'DocumentDetailReferencesProvider.create.successMessage': 'Document references successfully created',
  'DocumentDetailReferencesProvider.delete.successMessage': 'Reference to the document was successfully removed',
  'DocumentDetailPageLabels.editMode.tooltip': 'Edit labels',
  'DocumentDetailPageLinks.title': 'Shortcuts to this document',
  'DocumentDetailPageLinks.deleteSuccess': 'Document shortcuts successfully deleted',
  'DocumentDetailPageLinks.confirmDelete': 'Do you really want to delete this document shortcut?',
  'DocumentDetailPageLinks.needsWriteToEdit': 'You need write permisision in the shortcut\'s directory to edit the shortcut',
  'DocumentDetailPageLinks.needsWriteToDelete': 'You need write permisision in the shortcut\'s directory to delete the shortcut',
  'DocumentDetailPageLinks.addLink': 'Add shortcut',
  'DocumentDetailPageManager.label': 'New document manager',
  'DocumentDetailPageManager.tooltip': 'Change document manager.',
  'DocumentDetailPageManager.tooltip.noPermission': 'You do not have required permissions or the document is reserved.',
  'DocumentDetailPageState.tooltip': 'Change document state',
  'DocumentDetailPageState.label': 'New document state',
  'DocumentDetailPageDescription.placeholder': 'Add Description',
  'DocumentDetailPageWatch.tabTitle': 'Track',
  'DocumentDetailPageWatch.title': 'Document track settings',
  'DocumentDetailPageWatch.category.documentShare': 'Document share',
  'DocumentDetailPageWatch.category.documentChange': 'Document change',
  'DocumentDetailPageWatch.category.processInclusion': 'Inclusion in a process',
  'DocumentDownloadWithAnnotationsModal.title': 'Download document with annotations',
  'DocumentDownloadWithAnnotationsModal.progressMessage': 'Importing annotations to document',
  'DocumentLinkRow.missingDocument.error': 'Bindingd document not found',
  'DocumentLinkRow.missingDocument.restoreTooltip': 'Restore binded document',
  'DocumentLinkRow.missingDocument.contactAdminTooltip': 'Contact your admin',
  'ERPDataImportButtonComponent.import.success': 'Data from connected project successfully imported',
  'EstiCategoryEnum.Stavba': 'Budget', // TODO: refactor EstiCategoryEnum names
  'EstiCategoryEnum.Cerpani': 'Drawing',
  'EstiCategoryEnum.CerpaniSdruzeni': 'Grouped drawing',
  'EstiCategoryEnum.Fakturace': 'Invoice',
  'EstiCategoryEnum.FakturaSdruzeni': 'Grouped invoice',
  'EstiCategoryEnum.ZBV': 'ZBV',
  'EstiCategoryEnum.Harmonogram': 'Schedule',
  'DocumentOnlineEditor.createRevisionBusyReason': 'Preparing revision data',
  'DocumentOnlineEditor.storingRevisionBusyReason': 'Saving new revision, please wait',
  'DocumentOnlineEditor.defaultModalTitle': 'Online editor',
  'DocumentOnlineEditor.button.saveAsRevision': 'Save revision',
  'DocumentOnlineEditor.fileLimitError': 'The maximal supported file size failed to load.',
  'DocumentOnlineEditor.notSavedAlert': 'The document is not saved. If you continue, the changes will be lost.',
  'DocumentOnlineEditor.oversizeWarning': 'The document exceeds the maximum supported file size for saving. If you do not reduce the size of the document, it will not be possible to save it.',
  'DocumentRequest.accept': 'Accept',
  'DocumentRequest.accept.title': 'Accept background document',
  'DocumentRequest.accept.confirm': 'Are you sure you want to accept this document?',
  'DocumentRequest.confirmDocument': 'Confirm document',
  'DocumentRequest.confirm.title': 'Confirm previous document',
  'DocumentRequest.confirm.confirm': 'Select same document (including revision) as in last instance.',
  'DocumentRequest.refuse': 'Refuse',
  'DocumentRequest.refuse.title': 'Refuse document',
  'DocumentRequest.refuse.reason': 'Comment',
  'DocumentRequest.notPublished': 'You can select only published documents',
  'DocumentRequest.assignDocument': 'Assign document',
  'DocumentRequest.term': 'Deadline: ',
  'DocumentRequest.term.title': 'Upload deadline: {date}',
  'DocumentRequest.missingUser': 'Missing user!',
  'DocumentRequest.suspendedUser': 'Deactivated user!',
  'DocumentRequest.noDocument': '(document isn\'t uploaded)',
  'DocumentRequest.newDocument': 'Upload document',
  'DocumentRequest.downloadDocument': 'Download document',
  'DocumentRequest.editRequest': 'Edit request',
  'DocumentRequest.removeRequest': 'Remove request',
  'DocumentRequest.removeRequest.confirm': 'Really remove request "{name}"?',
  'DocumentRequest.state': 'State',
  'DocumentRequest.state.ready': 'Waiting for approval',
  'DocumentRequest.state.ready.dateTooltip': 'Request creation date: {date}',
  'DocumentRequest.state.missed': 'Not uploaded',
  'DocumentRequest.state.missed.dateTooltip': 'Document added date: {date}',
  'DocumentRequest.state.accepted': 'Approved',
  'DocumentRequest.state.accepted.dateTooltip': 'Document approved date: {date}',
  'DocumentRequest.state.accepted.userTooltip': 'Approved by user',
  'DocumentRequest.state.refused': 'Refused',
  'DocumentRequest.state.refused.dateTooltip': 'Document refused date: {date}',
  'DocumentRequest.state.refused.userTooltip': 'Refused by user',
  'DocumentRequest.state.forConfirm': 'For confirmation',
  'DocumentRequest.state.forConfirm.dateTooltip': 'Request creation date: {date}',
  'DocumentReservationLock.error.oldRevision': 'Reserved document revi is older than current document revision and cannot be editer.',
  'DocumentRevisionsGrid.initialRevision': 'Initial revision',
  'DocumentRevisionsGrid.primaryFile': 'Primary file',
  'DocumentRevisionsGrid.revisionDiscar.modal.title': 'Really discard this revision?',
  'DocumentRevisionsGrid.revisionDiscar.modal.description': 'Attention: revision can be restored only by project admin.',
  'DocumentRevisionsGrid.revisionDiscar.modal.okButton': 'Discard',
  'DocumentRevisionsGrid.revisionDiscar.modal.cancelButton': 'Storno',
  'DocumentRevisionsGrid.revisionDiscarded': 'Revision was discarded',
  'DocumentRevisionsGrid.revisionDiscarded.label': 'Discarded',
  'DocumentRevisionsGrid.revisionDiscarded.success': 'Revision successfully discarded',
  'DocumentRevisionsGrid.downloadWithAnnotations.tooltip': 'Download with annotations',
  'DocumentRevisionsGrid.downloadWithAttachments.error': 'File download failed',
  'DocumentRevisionsGrid.rowMenu.downloadWithAttachments': 'Download including attachments',
  'DocumentRevisionsGrid.rowMenu.addAttachments': 'Add attachments',
  'DocumentRevisionsGrid.rowMenu.addSignedDocument': 'Add signed document',
  'DocumentRevisionsGrid.rowMenu.discardRevision': 'Discard',
  'DocumentRevisionsGrid.rowMenu.setAsCurrent': 'Set as current',
  'DocumentRevisionsGrid.rowMenu.setAsCurrent.currentRevision':'This is the current revision',
  'DocumentRevisionsGrid.rowMenu.setAsCurrent.inWorkflow':'The document is in the workflow',
  'DocumentRevisionsGrid.rowMenu.setAsCurrent.inCommentProcedure':'The document is in the comments procedure',
  'DocumentRevisionsGrid.rowMenu.editDocumentRevision': 'Open revision in editor',
  'DocumentRevisionsGrid.rowMenu.externalMetadata': 'External metadata',
  'DocumentRevisionsGrid.rowMenu.restoreDiscarded': 'Restore a discarded revision',
  'DocumentRevisionsGrid.confirm.restoreDiscarded.title': 'Restore a discarded revision?',
  'DocumentRevisionsGrid.confirm.restoreDiscarded.okButton': 'Restore',
  'DocumentRevisionsGrid.confirm.restoreDiscarded.cancelButton': 'Storno',
  'DocumentRevisionsGrid.confirm.restoreDiscarded.success': 'Revision successfully restored',
  'DocumentRevisionsGrid.signedDocument': 'Signed document',
  'DocumentRevisionsGrid.annotatedDocument': 'Annotated document',
  'DocumentRevisionsGrid.secondaryFiles': 'Attachments',
  'DocumentRevisionsGrid.metadata.aspeEsticon': 'AspeEsticon',
  'DocumentRevisionsGrid.metadata.openTree': 'OpenTree',
  'DocumentRevisionsGrid.metadata.user': 'User',
  'DocumentRevisionsGrid.metadata.firstName': 'Firstname',
  'DocumentRevisionsGrid.metadata.lastName': 'Lastname',
  'DocumentRevisionsGrid.metadata.attributeName': 'Attribute name',
  'DocumentRevisionsGrid.metadata.attributeValue': 'Value',
  'DocumentSelect.directoryError': 'Cannot select documents from folder {path}: {error}',
  'DocumentSelect.selectedInThisDirectory': '{count} documents selected in this folder.',
  'DocumentSelect.selectedInSubDirectories': '({count} documents including subfolders)',
  'DocumentSelect.disabledMessage.documentInThisWorkflow': 'The document is already selected in this workflow.',
  'DocumentSelect.disabledMessage.documentInWorkflow': 'The document is selected in an active workflow.',
  'DocumentSelect.disabledMessage.documentInThisProcedure': 'Document is already selected in this comment procedure.',
  'DocumentSelect.disabledMessage.documentInProcedure': 'Document is selected in an active comment procedure.',
  'DocumentSelect.disabledMessage.notUnlocked': 'Only unlocked documents can be selected.',
  'DocumentSelect.disabledMessage.notOwner': 'You must be a document manager.',
  'DocumentSelect.disabledMessage.noWriteAccess': 'You must have the right to write at least.',
  'DocumentSelect.disabledMessage.notShared': 'You must have the right to write at least.',
  'DocumentSelect.disabledMessage.notAllowedDocumentState': 'Only documents in the states defined by the workflow template can be selected.',
  'DocumentSelect.disabledMessage.partialEsticonDocument': 'A partial document cannot be approved.',
  'DocumentSelect.multierror.someDocumentsTitle': 'An error occurred when processing some documents, continue?',
  'DocumentSelect.multierror.someDocumentsHaveError': '{count} out of {total} selected documents cannot be processed:',
  'DocumentSelect.multierror.continuePrompt': 'Do you want to remove the faulty documents and execute the action again?',
  'DocumentSelect.multierror.allDocumentsTitle': 'Error when processing every document',
  'DocumentSelect.multierror.allDocumentsHaveError': 'All selected documents ({count}) cannot be processed',
  'DocumentSelect.uploadDocuments': 'Upload new documents',
  'DocumentSelectAddAll.progress': 'Processed folders',
  'DocumentSelectAddAll.selectAll': 'Select all including subfolders',
  'DocumentSelectAddAll.deselectAll': 'Unselect all including subfolders',
  'DocumentSelectInfo.actualSelected': 'In last action selected:',
  'DocumentSelectInfo.actualDeselected': 'In last action deselected:',
  'DocumentSelectInfo.selectedTotal': 'Total selected:',
  'DocumentSelectFormItem.selectDocuments': 'Select documents',
  'DocumentSelectFormItem.selectDocument': 'Select document',
  'DocumentSelectFormItem.selectDocumentFromAspeHUB': 'Select document from AspeHUB',
  'DocumentSelectFormItem.changeDocuments': 'Change documents',
  'DocumentSelectFormItem.changeDocument': 'Change document',
  'DownloadManager.UploadStatus.waitingToStart': 'Waiting na upload',
  'DownloadManager.UploadStatus.initiating': 'Upload preparation',
  'DownloadManager.UploadStatus.uploading': 'Uploading',
  'DownloadManager.UploadStatus.finished': 'Upload finished',
  'DownloadManager.UploadStatus.interrupted': 'Upload interrupted',
  'DownloadManager.UploadStatus.error': 'Error',
  'DownloadManager.UploadStatus.suspended': 'Waiting to new upload',
  'DownloadFileButton.tooltip': 'Download File',
  'DrawingActualOverview.title.toDate': 'as of date {date}',
  'DrawingActualOverview.char.column.constructionPrice': 'Construction cost OC',
  'DrawingActualOverview.char.column.invoiced': 'Invoiced',
  'DrawingActualOverview.char.column.totalCost': 'Total cost',
  'DrawingActualOverview.char.column.income': 'Income',
  'DrawingActualOverview.char.column.totalCostActual': 'Total cost',
  'DrawingActualOverview.char.column.netIncomeDate': 'Net income as of date',
  'DrawingActualOverview.char.column.invoicedOC': 'Invoiced (OC)',
  'DrawingActualOverview.char.column.remaining': 'Remain (Cost price)',
  'DrawingActualOverview.char.column.expectedNetIncome': 'Expected net income',
  'DrawingActualReportDetail.income.name': 'Income',
  'DrawingActualReportDetail.ownPerformance.name': 'Own performance',
  'DrawingActualReportDetail.uncategorized.name': 'Uncategorized',
  'DrawingActualReportDetail.uncategorized.name.tooltip': 'All accounting records that cannot be associated with the budget',
  'DrawingActualReportDetail.unclassified.name': 'Unclassified',
  'DrawingActualReportDetail.unclassified.name.tooltip': 'Unclassified activities in the budget and accounts',
  'DrawingActualReportDetail.unassignedWithCategory.name': 'Unassigned with category',
  'DrawingActualReportDetail.unassignedWithCategory.name.tooltip': 'Unclassified activity in the budget, without a valid supplier in the accounting',
  'DrawingActualReportDetail.unassignedWithoutCategory.name': 'Unassigned without category',
  'DrawingActualReportDetail.unassignedWithoutCategory.name.tooltip': 'Classified activity in the budget, without a valid supplier in the accounting',
  'DrawingActualReportDetail.budgetIncome.name': 'Budget income',
  'DrawingActualReportDetail.otherIncome.name': 'Other',
  'DrawingActualReportDetail.headers.actual': 'Actual',
  'DrawingActualReportDetail.headers.actualCostOfOwnWork': 'Cost of own work',
  'DrawingActualReportDetail.headers.actualTotalCost': 'Total cost',
  'DrawingActualReportDetail.headers.category': 'Category',
  'DrawingActualReportDetail.headers.coeficient': 'Coeficient',
  'DrawingActualReportDetail.headers.contractuality': 'Contractuality (Cost price)',
  'DrawingActualReportDetail.headers.costOfOwnWork': 'Cost of own work',
  'DrawingActualReportDetail.headers.costOfSubcontracting': 'Cost of subcontracting',
  'DrawingActualReportDetail.headers.drawing': 'Drawing',
  'DrawingActualReportDetail.headers.drawingTotalCost': 'Total cost',
  'DrawingActualReportDetail.headers.expectedNetIncome': 'Estimated net income',
  'DrawingActualReportDetail.headers.expectedResult': 'Expected result',
  'DrawingActualReportDetail.headers.netIncomeToDate': 'Net income as of date',
  'DrawingActualReportDetail.headers.changeOrder': 'Change order',
  'DrawingActualReportDetail.headers.income': 'Income',
  'DrawingActualReportDetail.headers.internalChangeOrder': 'Change order internal',
  'DrawingActualReportDetail.headers.invoiced': 'Invoiced',
  'DrawingActualReportDetail.headers.invoicedSP': 'Invoiced (SP)',
  'DrawingActualReportDetail.headers.investorContract': 'Contract',
  'DrawingActualReportDetail.headers.margin': 'Margin',
  'DrawingActualReportDetail.headers.ownWork': 'Own work',
  'DrawingActualReportDetail.headers.priceCfW': 'Price CfW',
  'DrawingActualReportDetail.headers.remaining': 'Remaining',
  'DrawingActualReportDetail.headers.remainingCP': 'Remain (Cost price)',
  'DrawingActualReportDetail.headers.totalPrice': 'Building cost (SP)',
  'DrawingActualReportDetail.headers.subcontracting': 'Subcontracting',
  'DrawingActualReportDetail.headers.supplierInvoices': 'Supplier invoices',
  'DrawingActualReportDetail.reportDisplayMode.simplified': 'Simplified',
  'DrawingActualReportDetail.reportDisplayMode.full': 'Full',
  'DrawingActualReportDetail.reportError.noData': 'No data available',
  'DropArea.hint': 'Drag files here',
  'DropArea.disabled': 'Files upload is not allowed',
  'DocumentDetailPage.dropFilesError': 'Only one file can be uploaded as a new revision. Please upload only one file.',
  'DocumentDetailPage.workflowList.responsible person': 'Responsible person',
  'DocumentDetailPage.workflowList.approvalDocument': 'Approval document',
  'DocumentDetailPage.workflowList.backgroundDocument': 'Background document',
  'DocumentDetailPage.workflowList.typeFilterLabel': 'Type',
  'DocumentDetailPage.workflowList.typeApproval': 'Approval',
  'DocumentDetailPage.workflowList.typeBackground': 'Background',
  'EsticonContainer.noProject.title': 'The AspeEsticon project is not attached to the project.',
  'EsticonDetail.settingsSaveSuccess': 'Settings successfully saved',
  'EsticonDetailDirectory.folders': 'Folders for import from Esticon',
  'EsticonDetailDirectory.targetPath.title': 'Target folder',
  'EsticonDetailDirectory.pathMask': 'Path mask:',
  'EsticonDetailDirectory.fileMask': 'File name mask:',
  'EsticonDetailDirectory.addMaskDirectory': 'Add path mask item',
  'EsticonFirmList.loadingError': 'Some AspeEsticon firms failed to load.',
  'EstiConImportValidationIssueType.Precerpani': 'Importing WIP report would cause overbilling.',
  'EstiConImportValidationIssueType.MenepraceDoMinusu': 'Less work that causes the total quantity of the item to be exceeded.',
  'EstiConImportValidationIssueType.ChybiZbvProPolozky': 'Imported data contains a change order item without a matching change order in the project.',
  'EstiConImportValidationIssueType.ChybiZpProCerpani': 'Imported data contains an item without a matching WIP report in the project.',
  'EstiConImportValidationIssueType.ChybiZbvProZp': 'Imported data contains a WIP report without a matching change order in the project.',
  'EstiConImportValidationIssueType.ChybiZbvProZpSdruzeny': 'Imported data contains a grouped WIP report without a matching change order in the project.',
  'EstiConImportValidationIssueType.ChybiFakturaProZp': ' Imported data contains a WIP report without a matching invoice.',
  'EstiConImportValidationIssueType.ChybiZpSdruzenyProZp': 'Imported data contains a WIP report without a matching grouped WIP report.',
  'EstiConImportValidationIssueType.ChybiFakturaSdruzenaProFaktura': 'Imported data contains an invoice without a matching grouped invoice.',
  'EstiConImportValidationIssueType.ChybiFakturaProRozpisFaktury': 'Imported data contains an invoice without a matching backup invoice.',
  'EstiConImportValidationIssueType.ChybiObjektProPolozkaFaktury': 'Imported data contains an invoice without matching objects in the project.',
  'EstiConImportValidationIssueType.ChybiPartnerZFakturyNaZP': 'Imporded invoice data contains an invoice with partner without matching partner in WiP report.',
  'EstiConImportValidationIssueType.ChybiClenoveNaSdruzeneStavbe': 'The Associated Esticon project is missing members of association.',
  'EstiConImportValidationIssueType.ChybiSmlDodatekProZbv': 'Imported data contains a change order without a matching contractual amendment.',
  'EstiConImportValidationIssueType.MazaniZPPouziteNaFakture': 'Importing would delete a WIP report which is used in an invoice.',
  'EstiConImportValidationIssueType.MazaniZPPouziteNaSpolZP': 'Importing would delete a WIP report which is used in a grouped WIP report.',
  'EstiConImportValidationIssueType.MazaniFakturyPouziteNaSpolFakture': 'Importing would delete an invoice which is used in a grouped invoice.',
  'EstiConImportValidationIssueType.RozdilneZaokrouhleniCena': 'Imported data contains a rounding of a price to a different number of decimal places.',
  'EstiConImportValidationIssueType.RozdilneZaokrouhleniJednotkovaCena': 'Imported data contains a rounding of a unit price to a different number of decimal places.',
  'EstiConImportValidationIssueType.RozdilneZaokrouhleniMnozstvi': 'Imported data contains a rounding of a quantity to another number of decimal places.',
  'EstiConImportValidationIssueType.ViceNezJednoCerpaniRozpisuStejnymZP': 'Imported data contains multiple drawing of an item by one WIP report.',
  'EstiConImportValidationIssueType.RozdilneVypoctySDph': 'Imported data contains other suspension or retention settings than on the project.',
  'EstiConImportValidationIssueType.VyssiPocetAktivit': 'Imported data contains higher number of CEF activities.',
  'EstiConImportValidationIssueType.NizsiPocetAktivit': 'Imported data contains lower number of CEF activities.',
  'EstiConImportValidationIssueType.DuplicateInSource': 'Imported data contains duplicate records.',
  'EstiConImportValidationIssueType.DuplicateInDestination': 'The data in the project contains duplicate records.',
  'EstiConImportValidationIssueType.ChybiVCili': 'Imported data contains records that are not in the project.',
  'EstiConImportValidationIssueType.RozdilnaStruktura': 'Imported data contains different object structure than in the target project.',
  'EstiConImportValidationIssueType.DuplicitniPolozky': 'The project contains duplicate items.',
  'EstiConImportValidationIssueType.SpatnyFormatSouboru': 'The imported file does not contain correct object data.',
  'EstiConImportValidationIssueType.SpatnyFormatSouboru.WipReport': 'The imported file does not contain WIP report data.',
  'EstiConImportValidationIssueType.SpatnyFormatSouboru.ChangeOrder': 'The imported file does not contain change order data.',
  'EstiConImportValidationIssueType.SpatnyFormatSouboru.Invoice': 'The imported file does not contain invoice data.',
  'EstiConImportValidationIssueType.SpatnyFormatSouboru.Schedule': 'The imported file does not contain schedule data.',
  'EstiConImportValidationIssueType.NevalidniData': 'The imported file contains invalid data.',
  'EstiConImportValidationIssueType.KontrolaXC4': 'The imported file contains invalid XC4 data.',
  'EstiConImportValidationIssueType.OdeslanoNaHub': 'The imported file is already imported to AspeHub.',
  'EstiConImportValidationIssueType.NesparovaneStavebniDily': 'The imported file contains unpaired construction parts.',
  'EstiConImportValidationIssueType.NeznamaChyba': 'An unknown error occurred.',
  'EstiConImportValidationIssueType.ZbvNeniOdeslanoNaHub': 'Change order is not submitted to AspeHub.',
  'EstiConImportValidationIssueType.ChybiDodavatelClenskeFaktury': 'Missing contractor on invoice.',
  'EstiConImportValidationIssueType.NelzeNaparovatDodavateleFaktury': 'Contractor on invoice could not be paired.',
  'EstiConImportValidationIssueType.DuplicitniCsNazev': 'Duplicate name of the pricing scheme.',
  'EstiConImportValidationIssueType.DuplicitniCsVarianta': 'Duplicate variant of the pricing scheme.',
  'EstiConImportValidationIssueType.KalkulaceDoNekalkulace': 'The data in the import contains calculations. Importing into the project without calculations will delete the calculations.',
  'EstiConImportValidationIssueType.RozdilnaTvorbaStruktury': 'The Esticon project you import data into has a different structure. Import disabled.',
  'EstiConImportValidationIssueType.HmgRozdilnaStruktura': 'Different harmonogram structure.',
  'EstiConImportValidationIssueType.ExistingZpHasFaktura': 'Existing WiP report has invoice.',
  'EstiConImportValidationIssueType.ZpIsApproved': 'WiP report is approved.',
  'EstiConImportValidationIssueType.ExistingZbvHasZp': 'Existing change order has realization.',
  'EstiConImportValidationIssueType.NeshodneValorizace': 'Different valorizations on the project and imported ZBV',
  'EstiConImportValidationIssueType.NeshodnyTypValorizace': 'Different type of valorization on the project and imported ZBV',
  'EstiConImportValidationIssueType.NeshodneObdobiValorizace': 'Different periods of valorization on the project and imported ZBV',
  'EstiConImportValidationIssueType.ChybiSkupinyZbv': 'Missing Change order groups',
  'EstiConImportValidationIssueType.StavbaNepouzivaSkupinyZbv': 'The building does not use Change order groups',
  'EstiConImportValidationIssueType.NeshodnaMena': 'Databases have a different default currency set',
  'EstiConImportValidationBlockingError.UnableToImport': 'Unable to import.',
  'EsticonMaskInput.error.empty': 'Mask must not be empty',
  'EsticonMaskInput.error.invalidCharacters': 'Invalid characters / : * ? " |',
  'EsticonMaskInput.error.invalidTagFormat': 'Wrongly formatted tag',
  'EsticonMaskInput.error.invalidTag': 'Wrong tag: {tag}',
  'EsticonMaskInput.templateDescription.ShortYear': 'Short year (e.g. 21)',
  'EsticonMaskInput.templateDescription.Year': 'Long year (e.g. 2021)',
  'EsticonMaskInput.templateDescription.ShortMonth': 'Short month (e.g. 4)',
  'EsticonMaskInput.templateDescription.Month': 'Long month (e.g. 04)',
  'EsticonMaskInput.templateDescription.Name': 'Name',
  'EsticonMaskInput.templateDescription.Number': 'Number',
  'EsticonMaskInput.templateDescription.Code': 'Sign',
  'EsticonMaskInput.templateDescription.ObjectNumber': 'Object number',
  'EsticonMaskInput.templateDescription.ObjectPath': 'Object path',
  'EsticonMaskInput.templateDescription.Cerpani.Code': 'WIP report number',
  'EsticonMaskInput.templateDescription.Harmonogram.Code': 'Revision/Schedule code',
  'EsticonContainer.noProject.goToSettings': 'Go To Settings',
  'EstiConDataAnalyzeResult.Success': 'Success',
  'EstiConDataAnalyzeResult.FileReadError': 'Error when reading the file',
  'EstiConDataAnalyzeResult.IncorrectType': 'File does not match correct format. Import is not possible',
  'EstiConDataAnalyzeResult.InvalidData': 'File has invalid data',
  'EstiConDataAnalyzeResult.PairingError': 'Error when pairing data',
  'EstiConDataAnalyzeResult.Informative': 'File can contain incorrect data',
  'EstiConDataAnalyzeResult.NoData': 'File does not contain any data',
  'EsticonProjectTransferToControlDatabaseModal.title': 'Project transfer to the control database',
  'EsticonProjectTransferToControlDatabaseModal.succes': 'The transfer of the project to the control database was successful',
  'EsticonProjectTransferToControlDatabaseModal.error': 'Failed to transfer the project to the control database',
  'EstiConSaveResult.Success': 'Success',
  'EstiConSaveResult.DuplicateRecord': 'Duplicate error',
  'EstiConSaveResult.NoData': 'No data avaliable',
  'EstiConSaveResult.DataNotSaveable': 'Data is not saveable',
  'EstiConSaveResult.DataNotValid': 'Data is not valid',
  'EstiConSaveResult.DataReadonly': 'Data is read only',
  'EstiConSaveResult.SentToHub': 'Sent to AspeHub',
  'EstiConSaveResult.SqlError': 'The imported file contains data that cannot be uploaded to AspeEsticon.',
  'EstiConSaveResult.IOException': 'Error when reading or writing data',
  'EstiConSaveResult.UnspecifiedError': 'Unspecified error',
  'EstiConSaveResult.PartialSuccess': 'Partial Success',
  'EstiConSaveResult.GeneralError': 'General error',
  'EsticonObjectLink.disabledByEsticonLink': 'Document doesn\'t have active link to esticon object',
  'esticonErrors.importedDirectoryNotSet': 'Esticon import directory is not set. Please contact the project administrator.',
  'ErrorBoundary.title': 'Ups! Something went wrong 💥',
  'ExtensionFilter.placeholder': 'Extension (docx, pdf, ...)',
  'ExtensionFilter.withoutExtension': 'Without extension',
  'FavoriteButton.saving': 'Saving ...',
  'FavoriteButton.set': 'Add to Favorites',
  'FavoriteButton.addSuccess': 'Added to favorites.',
  'FavoriteButton.success': 'Documents was added to favorites.',
  'FavoriteButton.removeSuccess': 'Removed from favorites.',
  'FavoriteButton.unset': 'Remove from Favorites',
  'FavoriteDocumentsPage.entries': `{total, plural,
    =0 {No favorite documents found.}
    one {One favorite document found.}
    other {# favorite documents found.}
  }`,
  'FavoriteDocumentsPage.loading': 'Loading favorite documents...',
  'FavoriteSwitch.hideSelected': 'Show favorite folders only',
  'FavoriteSwitch.showSelected': 'Show all folders',
  'FileViewer.processing': 'Document preview is currently processing',
  'FileViewer.reloadDerivateButton': 'Reload preview',
  'FileViewer.rerenderingDerivatesMessage': 'Regeneration is in progress or not possible',
  'FileViewer.rerenderingDerivatesTooltip': 'Regenerate the preview',
  'FileViewer.rerenderingDerivatesButton': 'Regenerate the preview',
  'FileViewer.rerenderingDerivateButton': 'Regenerate',
  'FileViewer.rerenderingProcessing': 'Processing ...',
  'FileViewer.NoPreviewAvailable': 'No preview is available.',
  'FileViewer.UnsupportedMediaType': 'Unsupported media type',
  'FileViewer.UnsupportedPDFBrowser': 'This browser does not support embedding PDFs into the page. Click to view the PDF in a new window.',
  'FinancialOverviewByUnitReport.noUnit.title': 'Select a unit to view the report.',
  'FinancialOverviewByUnitReport.column.sign': 'Sign',
  'FinancialOverviewByUnitReport.column.name': 'Name',
  'FinancialOverviewByUnitReport.column.unit': 'Unit',
  'FinancialOverviewByUnitReport.column.investor': 'Investor',
  'FinancialOverviewByUnitReport.column.state': 'State',
  'FinancialOverviewByUnitReport.column.startDate': 'Start date',
  'FinancialOverviewByUnitReport.column.endDate': 'Finish date',
  'FinancialOverviewByUnitReport.column.cost': 'Cost',
  'FinancialOverviewByUnitReport.column.invoicing': 'Invoicing',
  'FinancialOverviewByUnitReport.column.invoicedSoFar': 'So far',
  'FinancialOverviewByUnitReport.column.invoiceRemaining': 'Remaining',
  'FinancialOverviewByUnitReport.column.costs': 'Costs',
  'FinancialOverviewByUnitReport.column.costSoFar': 'So far',
  'FinancialOverviewByUnitReport.column.costRemaining': 'Remaining',
  'FinancialOverviewByUnitReport.column.costTotal': 'Total',
  'FinancialOverviewByUnitReport.column.profit': 'Profit/loss',
  'FinancialOverviewByUnitReport.column.coefficient': 'K',
  'FinancialOverviewByUnitReport.column.SoW': 'SoW',
  'FinancialOverviewByUnitReport.column.changeOrder': 'Change order',
  'FinancialOverviewByUnitReport.column.invoice': 'Invoiced',
  'FinancialOverviewByUnitReport.column.proposed': 'Proposed',
  'FinancialOverviewByUnitReport.column.internal': 'Internal',
  'FinancialOverviewByUnitReport.column.ownPerformance': 'Own performance',
  'FinancialOverviewByUnitReport.column.subcontracts': 'Subcontracts',
  'FinancialOverviewByUnitReport.column.LinkToProject': 'Link to project',
  'FinancialOverviewByUnitReport.title': 'Production plan',
  'FinancialOverviewByUnitReport.phase.Dokonceno': 'Finished',
  'FinancialOverviewByUnitReport.phase.Nabidka': 'Offer',
  'FinancialOverviewByUnitReport.phase.Neziskano': 'Not acquired',
  'FinancialOverviewByUnitReport.phase.Priprava': 'Preparation',
  'FinancialOverviewByUnitReport.phase.Realizace': 'Realization',
  'FinancialOverviewByUnitReport.projectLink.text': 'Go to project',
  'FormProcessTaskTemplate.title': 'Process form templates',
  'FormProcessVoteTypeEnum.notVoted':'Not voted',
  'FormProcessVoteTypeEnum.processed':'Processed',
  'FormProcessVoteTypeEnum.notProcessed':'Not processed',
  'FormProcessVoteTypeEnum.approved':'Approved',
  'FormProcessVoteTypeEnum.rejected':'Rejected',
  'FormProcessVoteTypeEnum.forwarded':'Forwarded',
  'FormProcessLayout.error.noLayout': 'Layout for this form section was not found',
  'FormProcessListItem.taskCount': `{count, plural,
    =0 {# tasks}
    one {1 task}
    other {# tasks}
  }`,
  'FormProcessActorTypeEnum.submitter':'Submitter',
  'FormProcessActorTypeEnum.guarantor':'Guarantor',
  'FormProcessActorTypeEnum.expertGuarantor':'Expert guarantor',
  'FormProcessActorTypeEnum.processor':'Processor',
  'FormProcessActorTypeEnum.his':'HIS',
  'FormProcessTaskAddModal.title': 'Start new process task',
  'FormProcessTaskAddModal.form.name': 'Process task name',
  'FormProcessTaskAddModal.form.objectType': 'Object type',
  'FormProcessTaskAddModalButton.title': 'New task',
  'FormProcessTaskAddForm.input.template': 'Form process template',
  'FormProcessTaskAddForm.input.entityType': 'Starting entity type',
  'FormProcessTaskAddForm.input.startingDocument': 'Starting document',
  'FormProcessTaskAddForm.input.startingDirectory': 'Starting directory',
  'FormProcessTaskAddForm.input.submitterUser': 'Submitter',
  'FormProcessTaskAddForm.input.guarantorUser': 'Guarantor',
  'FormProcessTaskAddForm.input.expertGuarantorUser': 'Expert guarantor',
  'FormProcessTaskAddForm.input.processorRoleUserId': 'Processor',
  'FormProcessTaskAddForm.input.hisRoleUserId': 'HIS',
  'FormProcessTaskDetailFormModal.message.taskInitialized': 'Task initialized',
  'FormProcessTaskDetailFormModal.title': 'Task form',
  'FormProcessTaskDetailVoteFormModal.title':'{actorName} - statement setting',
  'FormProcessTaskDetailOrgUnitChangeButton.warning':'The selected user of the organization is not assigned to the project in question',
  'FormProcessTaskDetailOrgUnitChangeButton.description':'You can make the assignment in the project settings',
  'FormProcessTaskDetailOrgUnitChangeButton.clickToClose':'(click to close)',
  'FormProcessTaskDetailOrgUnitChangeModal.title':'Delegate',
  'ProjectsInRealizationFiltersFormItem.missingValues': 'Missing some of the required values to load report',
  'ProjectsInRealizationOverviewReport.reportSelection.columnConfiguration': 'Displayed values',
  'ProjectsInRealizationOverviewReport.reportSelection.title': 'Report settings',
  'ProjectsInRealizationOverviewReport.reportSelection.reportingPeriod': 'Reporting period',
  'ProjectsInRealizationOverviewReport.reportSelection.esticonApp': 'Aspe Esticon application',
  'ProjectsInRealizationOverviewReport.reportSelection.esticonFirm': 'Unit',
  'ProjectsInRealizationOverviewReport.reportSelection.date': 'Finished projects as of',
  'ProjectsInRealizationOverviewReport.reportSelection.view': 'View',
  'ProjectsInRealizationOverviewReport.reportSelection.required.forYear': 'The year for which the data is displayed is required',
  'ProjectsInRealizationOverviewReport.reportSelection.required.esticonApp': 'Report AspeEsticon applicationis required',
  'ProjectsInRealizationOverviewReport.reportSelection.required.unit': 'Report unit is required',
  'ProjectsInRealizationOverviewReport.reportSelection.required.date': 'Report date is required',
  'ProjectsInRealizationOverviewReport.column.io': 'O + I',
  'ProjectsInRealizationOverviewReport.column.unit': 'Unit',
  'ProjectsInRealizationOverviewReport.column.supervisor': 'CS',
  'ProjectsInRealizationOverviewReport.column.subsidies': 'Subsidies',
  'ProjectsInRealizationOverviewReport.column.projectName': 'Name of the event',
  'ProjectsInRealizationOverviewReport.column.projectSign': 'Project num',
  'ProjectsInRealizationOverviewReport.column.supplier': 'Supplier',
  'ProjectsInRealizationOverviewReport.column.state': 'SOW (date)',
  'ProjectsInRealizationOverviewReport.column.startDate': 'Start date',
  'ProjectsInRealizationOverviewReport.column.endDate': 'End date',
  'ProjectsInRealizationOverviewReport.column.cost': 'Cost',
  'ProjectsInRealizationOverviewReport.column.costI': 'Cost I',
  'ProjectsInRealizationOverviewReport.column.costO': 'Cost O',
  'ProjectsInRealizationOverviewReport.column.performedWorksTotal': 'Amount spent total',
  'ProjectsInRealizationOverviewReport.column.performedWorksTotalI': 'Amount spent I - total',
  'ProjectsInRealizationOverviewReport.column.performedWorksTotalO': 'Amount spent O - total',
  'ProjectsInRealizationOverviewReport.column.performedWorksYear': 'Amount spent {year}',
  'ProjectsInRealizationOverviewReport.column.performedWorksYearI': 'Amount spent I - {year}',
  'ProjectsInRealizationOverviewReport.column.performedWorksYearO': 'Amount spent O - {year}',
  'ProjectsInRealizationOverviewReport.column.costTotal': 'Total',
  'ProjectsInRealizationOverviewReport.column.unassignedYear': 'Unassigned',
  'ProjectsInRealizationOverviewReport.column.note': 'Note',
  'ProjectsInRealizationOverviewReport.column.planI': 'Plan {year} - I',
  'ProjectsInRealizationOverviewReport.column.planO': 'Plan {year} - O',
  'ProjectsInRealizationOverviewReport.column.updateDate': 'Last update',
  'ProjectsInRealizationOverviewReport.filter.type.empty': 'Empty',
  'ProjectsInRealizationOverviewReport.filter.type.i': 'I',
  'ProjectsInRealizationOverviewReport.filter.type.o': 'O',
  'ProjectsInRealizationOverviewReport.filter.type.oi': 'O + I',
  'ProjectsInRealizationOverviewReport.filter.subsidies.empty': 'No subsidies',
  'ProjectsInRealizationOverviewReport.notesSaved': 'Project notes saved',
  'ProjectsInRealizationOverviewReport.title': 'Projects in realization {year} (in thousands CZK) as of {date}',
  'ProjectsInRealizationOverviewReport.views.total': 'Total construction volume by SOW {year}',
  'ProjectsInRealizationOverviewReport.views.onlyI': 'Investmens construction volume by SOW {year}',
  'ProjectsInRealizationOverviewReport.views.onlyO': 'Repairs construction volume by SOW {year}',
  'ProjectsInRealizationPairingNotesPreviewModal.title': 'Preview saved notes',
  'ProjectsInRealizationPairingNotesModal.title': 'Repair unpaired notes',
  'ProjectsInRealizationPairingNotesModal.mergeWarning.title': 'Merge existing notes',
  'ProjectsInRealizationPairingNotesModal.mergeWarning.description': 'Selected EstiCon project already have existing notes. If you continue, the notes will be merged.',
  'ProjectsInRealizationPairingNotesModal.mergeWarning.continue': 'Continue',
  'ProjectsInRealizationPairingNotesModal.labels.noteList': 'Note list',
  'ProjectsInRealizationPairingNotesModal.labels.projectName': 'Project name',
  'ProjectsInRealizationPairingNotesModal.labels.sign': 'Sign',
  'ProjectsInRealizationPairingNotesModal.labels.startDate': 'Start date',
  'ProjectsInRealizationPairingNotesModal.labels.pairTo': 'Pair to',
  'ProjectsInRealizationPairingNotes.selectProjectName': '{name} (Match {matchCount} %)',
  'ProjectsInRealizationReportConfigurationColumnForm.addColumn': 'Add new column',
  'ProjectsInRealizationReportConfigurationColumnForm.defaultColumnName': 'New column',
  'ProjectsInRealizationReportConfigurationColumnForm.columnName.name': 'Column name',
  'ProjectsInRealizationReportConfigurationColumnForm.columnName.value': 'Column value',
  'ProjectsInRealizationReportConfigurationColumnForm.columnName.unitOrder': 'Order of magnitude',
  'ProjectsInRealizationReportConfigurationColumnForm.columnType.constant': 'Constant',
  'ProjectsInRealizationReportConfigurationColumnForm.columnType.property': 'Form report',
  'ProjectsInRealizationReportConfigurationColumnForm.columnDecimalUnit.units': 'Units',
  'ProjectsInRealizationReportConfigurationColumnForm.columnDecimalUnit.thousands': 'Thousands',
  'ProjectsInRealizationReportConfigurationColumnForm.columnDecimalUnit.millions': 'Millions',
  'ProjectsInRealizationReportConfigurationColumnForm.columnDecimalUnit.billions': 'Billions',
  'ProjectsInRealisationSavedViewsModal.title': 'Saved views',
  'ProjectsInRealisationSavedViewsModal.newView.title': 'Save current view as new',
  'ProjectsInRealisationSavedViewsModal.button.rename.tooltip': 'Rename',
  'ProjectsInRealisationSavedViewsModal.delete.title': 'Delete saved view',
  'ProjectsInRealisationSavedViewsModal.delete.content': 'Saved view will be deleted. Continue?',
  'ProjectsInRealisationSavedViews.workingView.name': '*Unsaved',
  'ProjectsInRealisationNoteModal.title': 'Project notes',
  'ProjectsInRealisationNoteModal.delete.title': 'Delete note',
  'ProjectsInRealisationNoteModal.delete.content': 'Note will be permanently removed. Continue?',
  'ProjectsInRealisationNoteModal.error.save': 'Error while saving notes. Changes have not been saved.',
  'ProjectsInRealisationNote.source.AspeHub': 'Note from AspeHub',
  'ProjectsInRealisationNote.source.AspeEsticon': 'Note form AspeEsticon',
  'ProjectsInRealizationPohReportSettingsForm.defaultDate': 'Initial report realization date',
  'ProjectsInRealizationPohReportSettingsForm.completedRealizationColor': 'Background color for completed realizations',
  'ProjectsInRealizationPohReportSettingsForm.unitColors': 'Unit colors',
  'ProjectsInRealizationPohReport.export.fileName': 'realizations_report_{date}.{extension}',
  'ProjectsInRealizationPohReport.Export.pdf': 'Export to pdf',
  'ProjectsInRealizationPohReport.Export.xlsx': 'Export to excel',
  'ReportUnitColorAssign.error.unitNotFound': 'Unit not found',
  'ReportUnitColorAssign.addNewUnit': 'Add new unit',
  'ReportUnitColorAssign.selectEsticonConn': 'Select AspeEsticon application',
  'ReportUnitColorAssign.selectEsticonFirm': 'Select AspeEsticon database',
  'ReportUnitSettingsModal.title': 'Permissions for units in report',
  'Forge.cannotViewInMode': 'Model {name} cannot be viewed in the requested {mode} mode',
  'Forge.loading': 'Loading Autodesk® Forge Viewer...',
  'Forge.loadingError': 'Error while loading Autodesk® Forge Viewer.',
  'Forge.loadModelError': 'There was an error when loading model {name}',
  'Forge.viewing.showHoverOption.name': 'Highlight models',
  'Forge.viewing.showHoverOption.description': 'Highlight models on mouse hover.',
  'Forge.warning.compare.title': 'Comparison warning',
  'Forge.warning.compare.extension': 'Properties are not correctly compared in {extension} models.',
  'Forge.warning.compare.turnOffProperties': 'You can turn off property comparison in settings.',
  'Forge.warning.multipleScales.title': 'Models contain different scales',
  'Forge.warning.multipleScales.message':
    'Selected models cannot be displayed correctly because they contain multiple different scales.',
  'Forge.warning.multipleScales.meter': 'Models in meters:',
  'Forge.warning.multipleScales.millimeter': 'Models in millimetres:',
  'Forge.warning.multipleScales.other': 'Models in another unit:',
  'Forge.warning.longDistances.title': 'Models are too far apart.',
  'Forge.warning.longDistances.message':
  'Selected models are placed too far apart from each other, it will be impossible to see them all at once.',
  'Forge.warning.longDistances.group': 'Group {number}:',
  'Forge.warning.longDistances.sharedCoordinatesMissing': 'Models don\'t use shared coodrdinates.',
  'forge.warning.notFoundElements': 'Some elements could not be found in the model.',
  'ForgeViewer.loadingTokenError': 'Error while loading preview data.',
  'ForgeViewer.processModel': 'Process the model',
  'ForgeViewer.processError': 'An error occurred while processing the models:',
  'ForgeViewer.processing': 'Models are being processed:',
  'ForgeViewer.cannotProcessModels': 'Cannot process model, please try again later.',
  'ForgeViewer.processModelInfo': 'Model processing is a complex operation and may take several minutes, depending on the size of the model.',

  'Forge.measurements.errors.noElementSelected': 'Select an element for which you want to perform the measurement.',
  'Forge.measurements.errors.notAvailable': 'Volume and surface could not be measured. The element may be unclosed.',
  'Forge.measurements.button.tooltip': 'Measure volume and surface',
  'Forge.measurements.result.surface': 'Surface:',
  'Forge.measurements.result.volume': 'Volume:',
  'Forge.measurements.result.title': 'Measured volume and surface',
  'Forge.measurements.warnings.openVolume': 'Some of the selected objects are not closed. The volume may not be accurate.',
  'ForgeByAttributesFilter.foundElements': `{count, plural,
    =0 {No elements found}
    other {# elements found}
  }`,
  'ForgeByAttributesFilter.filtersTitle': 'Find elements',
  'ForgeByAttributesFilter.parameterFilterLabel': 'Attribute',
  'ForgeByAttributesFilter.valueFilterLabel': 'Value',
  'ForgeViews.label': 'Views',
  'ForgeViews.attributesFilter': 'Attributes filter',
  'ForgeViews.addNew': 'Add new view',
  'ForgeViews.editView': 'Edit view',
  'ForgeViews.resetViewTooltip': 'Reset view',
  'ForgeViews.viewName': 'View name',
  'ForgeViews.fileType': 'File type',
  'ForgeViews.noViews': 'No views saved yet',
  'ForgeViews.linkCopied': 'Link copied to clipboard',
  'ForgeViews.form.rules.requiredViewName': 'View name is required',
  'ForgeViews.form.rules.maxLengthViewName': 'View name must be at most {max} characters long',
  'ForgeViews.form.item.paramsSettings': 'View parameters to capture',
  'ForgeViews.form.item.paramsSettings.changeCamera': 'Camera position',
  'ForgeViews.form.item.paramsSettings.changeSelected': 'Selection/isolation of elements',
  'ForgeViews.form.item.paramsSettings.changeCuttingPlane': 'Cut planes',
  'ForgeViews.form.item.paramsSettings.renderOptions': 'Rendering options',
  'ForgeViews.form.item.paramsSettings.groupedModels': 'Update list of grouped models',
  'ForgeAttributesFilter.category.inputLabel': 'Categories',
  'ForgeAttributesFilter.category.placeholder': 'Enter categories',
  'ForgeAttributesFilter.parameter.inputLabel': 'Parameters',
  'ForgeAttributesFilter.parameter.placeholder': 'Enter parameters',
  'ForgeAttributesFilter.value.inputLabel': 'Value of parameters',
  'ForgeAttributesFilter.value.placeholder': 'Enter value',
  'ForgeAttributesFilter.parametersList.title': `{count, plural,
    =0 {Select some elements}
    one {One element selected}
    few {# elements selected}
    other {# elements selected}
  }`,
  'ForgeAttributesFilter.export.title': 'Export elements with parameters',
  'ForgeAttributesFilter.export.items.fileFormat': 'File format',
  'ForgeAttributesFilter.export.items.csvDelimiter': 'Column separator',
  'ForgeAttributesFilter.export.csvDelimiter.coma': 'Coma ","',
  'ForgeAttributesFilter.export.csvDelimiter.tab': 'Tab',
  'ForgeAttributesFilter.export.csvDelimiter.semicolon': 'Semicolon ";"',
  'ForgeAttributesFilter.export.csvDelimiter.pipe': 'Pipe "|"',

  'forms.button.cancel': 'Cancel',
  'forms.button.submit': 'Save',
  'forms.button.submit.loading': 'Saving...',
  'forms.items.createdBy.label': 'Created by',
  'forms.items.participants.label': 'Participants',
  'forms.items.description.label': 'Description',
  'forms.items.esticon.label': 'Connected AspeEsticon project',
  'forms.items.esticon.warning': 'The connected project cannot be changed!',
  'forms.items.esticon.warning.ok': 'OK, set the project',
  'forms.items.userId.label': 'User',
  'forms.items.userIds.label': 'Users',
  'forms.items.associatedIds.label': 'Associated',
  'forms.items.userId.rules.required': 'Please select a user',
  'forms.items.roleId.label': 'Role',
  'forms.items.roleId.rules.required': 'Please select a role',
  'forms.items.forceRemove.label': 'Remove user',
  'forms.items.forceRemove.description': 'Confirm user removal',
  'forms.items.email.label': 'Email',
  'forms.items.email.placeholder': 'john.doe@email.com',
  'forms.items.labels.label': 'Labels',
  'forms.items.name.label': 'Name',
  'forms.items.name.rules.empty': 'Name cannot be empty',
  'forms.items.name.rules.nameExists': 'Entered name is already used, please choose another name',
  'forms.items.name.rules.required': 'Please enter a name',
  'forms.items.owner.label': 'Owner document',
  'forms.items.primaryFile.rules.required': 'Please select a primary file',
  'forms.items.rules.duplicateEmail': 'Email address already in use',
  'forms.items.rules.email': 'Please input valid email address',
  'forms.items.rules.email.noDiacritics': 'Please input valid email address, without diacritics.',
  'forms.items.rules.invalidGuid':'The identifier entered is not valid',
  'forms.items.rules.required': 'Field is required',
  'forms.items.rules.noEmpty': 'Field can not be empty',
  'forms.items.rules.document.required': 'Please select some documents',
  'forms.items.rules.duplicated': 'The value must be unique',
  'forms.items.status.label': 'State',
  'forms.items.userPermissionProfile.label': 'Permission profile',
  'forms.items.addDocumentsToWokrflow.justAllowedDocuments': 'Only files whose status corresponds to the setting of the selected template can be included.',
  'FormWizardWrapper.prev': '< Back',
  'FormWizardWrapper.next': 'Next >',

  'folderMasks.inheritedMasksFromParentFolder': 'The mask is inherited from the parent folder',
  "folderMasks.deleteAndInherit.title": "Delete and inherit masks",
  "folderMasks.deleteAndInherit.description": "The masks in this folder will be deleted (this will also affect the masks in subfolders that inheriting this setting). The masks from the nearest parent folder that has them set (or none) will be used.",
  "folderMasks.deleteAndInherit.confirm": "Are you sure you want to delete the masks?",
  'folderMasks.fileNameMask': 'File name mask',
  'folderMasks.noMasksSet': 'Mask is turned off',
  'folderMasks.subfolderNameMask': 'Subfolder name mask',
  "folderMasks.directoryValidationMask": "Validation masks",
  "folderMasks.directoryValidationMask.description": "Masks for checking the names of folders and files. The names of new files and folders must match the entered rules.",
  'folderMasks.editDirectoryValidationMask': 'Edit directory validation mask',
  'folderMasks.MaskInput.text.initialValue': 'Insert text',
  "folderMasks.MaskItem.length.title": "Edit length of mask",
  "folderMasks.MaskItem.length.label": "Length",
  "folderMasks.MaskItem.length.charsCount": "Number of characters",
  "folderMasks.MaskItem.length.rules.required": 'Fill in the number of characters',
  "folderMasks.MaskItem.length.rules.format": 'Enter the number of characters in the format "specific number" or "minimum number-maximum number. For example "10" or "10-20"',
  "folderMasks.MaskItem.length.rules.invalidCountOrder": 'The second number must be greater than the first',
  'folderMasks.MaskItem.text.title': 'Edit text',
  "folderMasks.MaskItem.text.label": 'Specific text',
  "folderMasks.MaskItem.text.rules.required": 'Fill in the text',
  "folderMasks.MaskItem.text.rules.invalidCharacters": 'Text contains invalid characters (\\/:*?"<>|)',
  "folderMasks.MaskItem.letters.allowNumbers": 'digits',
  "folderMasks.MaskItem.letters.allowSpecialCharacters": 'special characters',
  'folderMasks.MaskItem.letters.allowSpecialCharacters.tooltip': 'Allowed special characters are: @$#&%+-_!().,',
  "folderMasks.MaskItem.letters.whitespace": 'spaces',
  'folderMasks.MaskItem.letters.case.upper': 'uppercase letters',
  'folderMasks.MaskItem.letters.case.lower': 'lowercase letters',
  'folderMasks.MaskItem.letters.case.mixed': 'uppercase and lowercase letters',
  'folderMasks.MaskItem.general.unknownType': 'Unknown type',
  'folderMasks.MaskItem.simpleDisplay.digits': 'digits',
  'folderMasks.MaskItem.simpleDisplay.letters': 'letters',

  'FilenameErrorMessage.cancelRename': 'Cancel rename',
  'FilenameErrorMessage.message': 'Invalid characters',
  'FilenameErrorMessage.ok': 'Close',
  'FilenameErrorMessage.title': 'Invalid name',
  'FilenameErrorMessage.invalidCharactersTitle': 'Cannot contain characters',
  'FilenameErrorMessage.invalidNamesTitle': 'Name cannot be',
  'FilenameErrorMessage.showMoreInfo': 'More information (in english)',
  'fullscreen.maximize': 'Maximize',
  'fullscreen.minimize': 'Minimize',
  'general.administrator': 'Administrator',
  'general.administrator.aspeHub': 'Administrator AspeHub',
  'general.activity': 'Activity',
  'general.add': 'Add',
    'general.addDefinition': 'Přidat definition',
  'general.addUsers': 'Add users',
  'general.addNewUsers': 'Add new users',
  'general.addOrganization': 'Add organization',
  'general.addProject': 'Add project',
  'general.editOrganization': 'Edit organization',
  'general.addDescription': 'Add description',
  'general.addName': 'Add name',
  'general.assignedTo': 'Assigned to',
  'general.assignment': 'Assignment',
  'general.attachments': 'Attachments',
  'general.attachmentsWithCount': 'Attachments ({count})',
  'general.author': 'Author',
  'general.back': 'Back',
  'general.budget': 'Estimate',
  'general.categories': 'Categories',
  'general.categoriesNodes': 'Categories nodes',
  'general.completed': 'Completed',
  'general.clearFilters': 'Clear filters',
  'general.date': 'Date',
  'general.deadline': 'Due date',
  'general.tests': 'Stress tests',
  'general.charLength': `{count, plural,
    =0 {# characters}
    one {# character}
    few {# characters}
    other {# characters}
  }`,
  'general.clearFilter': 'Clear filter',
  'general.close': 'Close',
  'general.color': 'Color',
  'general.copy': 'Copy',
  'general.comments': 'Comments',
  'general.commentProcedure': 'Comment procedure',
  'general.compare': 'Compare',
  'general.create': 'Create',
  'general.currencyLabel': '{label} [{currency}]',
  'general.currency.CZK': 'CZK',
  'general.currency.EUR': '€',
  'general.currency.USD': '$',
  'general.currency.PLN': 'zł',
  'general.currencyFormat.CZK': 'CZK {value}',
  'general.currencyFormat.EUR': '€{value}',
  'general.currencyFormat.USD': '${value}',
  'general.currencyFormat.PLN': 'zł {value}',
  'general.custom': 'Custom',
  'general.createdBy': 'Created by',
  'general.createdDate': 'Created: {date}',
  'general.created': 'Created',
  'general.escape': 'Esc',
  'general.deletedDate': 'Deleted: {date}',
  'general.modifiedDate': 'Modified: {date}',
  'general.dayNames.monday': 'Monday',
  'general.dayNames.tuesday': 'Tuesday',
  'general.dayNames.wednesday': 'Wednesday',
  'general.dayNames.thursday': 'Thursday',
  'general.dayNames.friday': 'Friday',
  'general.dayNames.saturday': 'Saturday',
  'general.dayNames.sunday': 'Sunday',
  'general.days': 'Days',
  'general.daysPlural': `{count, plural,
    =0 {days}
    one {day}
    few {days}
    other {days}
  }`,
  'general.default': 'Default',
  'general.defaultExtendedPermission': 'Default user permission profile',
  'general.delete': 'Delete',
  'general.deleted': 'Deleted',
  'general.deletedItemSuccessfully': `{count, plural,
    =0 {No item deleted}
    one {Item was successfully deleted}
    few {# items was successfully deleted}
    other {# items was successfully deleted}
  }`,
  'general.description': 'Description',
  'general.discussion': 'Discussion',
  'general.docMenu.showModel': 'Show Model',
  'general.docMenu.addModel': 'New Model',
  'general.docMenu.addToSelected': 'Add to selected',
  'general.docMenu.bulkEdit': 'Bulk edit',
  'general.docMenu.bulkEdit.documentWatch': 'Track settings',
  'general.docMenu.bulkEdit.modelsSetting': 'Model settings',
  'general.docMenu.bulkEdit.modelsSetting.add': 'Add to models',
  'general.docMenu.bulkEdit.modelsSetting.remove': 'Remove from models',
  'general.docMenu.bulkEdit.changeState': 'Change state',
  'general.docMenu.bulkEdit.setLabels': 'Set labels',
  'general.docMenu.downloadInZip': 'Download ZIP',
  'general.docMenu.downloadInZip.thisDirOnly': 'Only this folder',
  'general.docMenu.downloadInZip.deepWithFiles': 'All subfolders and documents',
  'general.docMenu.downloadInZip.deepWithoutFiles': 'Folder structure without documents',
  'general.docMenu.downloadInZip.downloadError': 'Download ZIP failed',
  'general.docMenu.downloadInZip.directoryLinkWarning': 'Included folder shortcuts are not part of the generated ZIP.',
  'general.docMenu.downloadOriginal': 'Download original',
  'general.docMenu.downloadSignedDocument': 'Download document for signing',
  'general.docMenu.downloadWithAnnotations': 'Download with annotaitons',
  'general.docMenu.selectedFiles': `{count, plural,
    =0 {0 selected items}
    one {1 selected item}
    few {# selected items}
    other {# selected items}
  }`,
  'general.docMenu.selected.tooltip': 'Deselect everything',
  'general.docMenu.share': 'Share',
  'general.docMenu.shareAppUserDownload': 'Project members share',
  'general.docMenu.shareDownload': 'Send download link',
  'general.docMenu.sign': 'Sign',
  'general.docMenu.signOnline': 'Sign document with electronig signature',
  'general.docMenu.signLocally': 'Upload signed document',
  'general.docMenu.startWorkflow': 'Start workflow',
  'general.docMenu.createDocumentLinks': 'Create document shortcut',
  'general.dirMenu.createDirectoryLink': 'Create directory shortcut',
  'general.documents': 'Documents',
  'general.documentsWithCount': 'Documents ({count})',
  'general.download': 'Download',
  'general.downloadAll': 'Download all',
  'general.drawing': 'Work in progress',
  'general.createNewProject': 'Create new project',
  'general.editProject': 'Edit project',
  'general.edit': 'Edit',
  'general.error': 'Error',
  'general.failedAction': 'The action failed',
  'general.export': 'Export',
  'general.export.tooltip': 'Export (according to the current filter)',
  'general.ExternApps': 'External applications',
  'general.extension': 'Extension',
  'general.filter': 'Filter',
  'general.turnFiltersOn': 'Turn filters on',
  'general.turnFiltersOff': 'Turn filters off',
  'general.folder': 'Folder',
  'general.folders': 'Folders',
  'general.general': 'General',
  'general.general.setting': 'General setting',
  'general.groups': 'Groups',
  'general.goTo': 'Go to: ',
  'general.help': 'Help',
  'general.import': 'Import',
  'general.insufficientPermission': 'You don\'t have sufficient permission to access this area.',
  'general.invoicing': 'Invoicing',
  'general.bindings': 'Bindings',
  'general.links': 'Shortcuts',
  'general.acknowledge': 'I Acknowledge',
  'general.ok': 'OK',
  'general.on': 'On',
  'general.off': 'Off',
  'general.onOff': 'Partially on',
  'general.help.licence': 'Licence Info',
  'general.labels': 'Labels',
  'general.loading': 'Loading ...',
  'general.logout': 'Logout',
  'general.maxDescriptionLength': 'The project description is too long. The maximum description length is {max} characters.',
  'general.maxNameLength': 'The name is too long. The maximum name length is {max} characters.',
  'general.minNameLength': 'The name is too short. The minimum name length is {min} characters.',
  'general.maxLength': 'The value is too long. The maximum length is {max} characters.',
  'general.minCINlength': 'The value is too short. Minimum length is {min} characters. You can not enter a value at all.',
  'general.badCINControlDigit':'Invalid with reference to check number',
  'general.justNumbers':'Digits only',
  'general.manager': 'Document manager',
  'general.move': 'Move to',
  'general.modified': 'Modified',
  'general.modifiedBy': 'Modified by',
  'general.month.january': 'January',
  'general.month.february': 'February',
  'general.month.march': 'March',
  'general.month.april': 'April',
  'general.month.may': 'May',
  'general.month.june': 'June',
  'general.month.july': 'July',
  'general.month.august': 'August',
  'general.month.september': 'September',
  'general.month.october': 'October',
  'general.month.november': 'November',
  'general.month.december': 'December',
  'general.name': 'Name',
  'general.personName':'Name',
  'general.email':'Email',
  'general.phone':'Phone',
  'general.sign': 'Sign',
  'general.nameAndDescription': 'Name and desc.',
  'general.defaultTimeZone': 'Default time zone',
  'general.storagearea': 'Branch',
  'general.storageareas': 'Branches',
  'general.defaultLanguage': 'Default language',
  'general.no': 'No',
  'general.organizations': 'Organizations',
  'general.organizationLabels': 'Organization labels',
  'general.groupedByOrganizations': 'Grouped by organizaions',
  'general.permission': 'Permission',
  'general.previous': 'Previous',
  'general.next': 'Next',
  'general.noMoreUsers': 'No more users',
  'general.from': 'From: ',
  'general.to': 'To: ',
  'general.unknown': 'Unknown',
  'general.unlimited': '(unlimited)',
  'general.units': 'Units',
  'general.noUser': 'No user',
  'general.Associated':'Associated',
  'general.AssociatedOnly':'Associated only',
  'general.notEmptyItemError': 'Please enter the non-empty value.',
  'general.noDocuments': 'There are no documents in the selected folder.',
  'general.noItems': 'There are no items here.',
  'general.notSet': 'Not set',
  'general.nothingFound': 'Nothing was found',
  'general.organization': 'Organization',
  'general.path': 'Path',
  'general.PartialWiP': 'Partial WIP report',
  'general.PartialInvoice': 'Partial invoice',
  'general.preview': 'Preview',
  'general.project': 'Project',
  'general.projectList': 'Project list',
  'general.projects': 'Projects',
  'general.newProject': 'New project',
  'general.projectSettings': 'Projects Settings',
  'general.reinvite': 'Reinvite',
  'general.return':'Return',
  'general.revisions': 'Revisions',
  'general.role': 'Role',
  'general.roles': 'Roles',
  'general.roleName': 'Role name',
  'general.save': 'Save',
  'general.saveProfile': 'Save profile',
  'general.search': 'Search',
  'general.settings': 'Settings',
  'general.settings.profile': 'Profile settings',
  'general.source': 'Source',
  'general.sort': 'Sort',
  'general.sortedBy': 'Sorted by',
  'general.sortBy': 'Sort by',
  'general.sortDirection': 'Sort direction',
  'general.sort.asc': 'Ascending',
  'general.sort.desc': 'Descending',
  'general.start': 'Start',
  'general.state': 'State',
  'general.documentationLevel': 'Documentation level',
  'general.Phase': 'Phase',
  'general.Current': 'Current',
  'general.MdConditions': 'CK conditions',
  'general.state.archived': 'Archived',
  'general.state.published': 'Published',
  'general.state.rejected': 'Rejected',
  'general.state.shared': 'Shared',
  'general.state.workInProgress': 'Work in progress',
  'general.title': 'Title',
  'general.cancel': 'Cancel',
  'general.cancelChanges': 'Cancel changes',
  'general.change': 'Change',
  'general.showMore': 'Show more',
  'general.select': 'Select',
  'general.selectAll': 'Select all',
  'general.showMoreOptions': 'Show more options',
  'general.showLess': 'Show less',
  'general.schedule': 'Schedule',
  'general.signIn': 'Sign in',
  'general.size': 'Size',
  'general.team': 'Team',
  'general.category': 'Category',
  'general.total': 'Total',
  'general.template': 'Template',
  'general.template.create': 'Create template',
  'general.type': 'Type',
  'general.users': 'Users',
  'general.userName': 'Username',
  'general.userMail': 'User\'s e-mail',
  'general.firstName': 'First name',
  'general.lastName': 'Last name',
  'general.language': 'Language',
  'general.messages': 'Messages',
  'general.userNames': 'First name and last name',
  'general.warning': 'Warning',
  'general.watch': 'Watch',
  'general.workDaysCalendar': 'Working days calendar',
  'general.organizationStructure':'Organization structure',
  'general.view': 'View',
  'general.yes': 'Yes',
  'general.needAdminAccess': 'You need administrator privileges to access this item.',
  'general.send': 'Send',
  'general.ZBV': 'Change order',
  'general.workflow': 'Workflow',
  'general.document': 'Document',
  'general.link': 'Shortcut',
  'general.forge': 'Model processing',
  'general.textMaxLengthWarning': 'You have reached the maximum number of characters',
  'general.reference': 'Reference',
  'general.metadata': 'Additional Information',
  'general.emptyItemValue': 'Value not set',
  'general.value':'Value',
  'general.justification':'Justification',
  'general.statement':'Statement',
  'general.region':'District',
  'general.areal':'Areal',
  'general.property':'Property',
  'general.TWIST':'TWIST identifier',
  'general.synchronized':'Synchronized',
  'general.TWIST.detail':'TWIST identifiers - detail',





  'JobPositions.title': 'Job positions',
  'JobPositions.addPosition': 'Add job position',
  'JobPositions.delete.confirmTitle': 'Delete job position?',
  'JobPositions.delete.confirmDeleteFromUsers': `This working position is assigned to {count} {count, plural,
      one {user}
      few {users}
      other {users}
    }. Do you want to delete it from them?`,
  'JobPositions.delete.confirmed': 'Job position was successfully deleted.',
  'JobPositions.add.rules.name': 'Please enter the job position name.',
  'JobPositions.add.rules.nameLength': 'The job position name is too long, the maximum length is {maxLength} characters.',

  'contextMenuExpandCollapseUtils.expand': 'Expand',
  'contextMenuExpandCollapseUtils.collapse': 'Collapse',
  'GrantAccessModal.addUser': 'Add user permission',
  'GrantAccessModal.folderLabel': 'Folder',
  'GrantAccessModal.message': 'User {user} requested access for the folder.',
  'GrantAccessModal.noInGroup': 'User {user} is not in any group.',
  'GrantAccessModal.requiredPermissionLabel': 'Requested permission',
  'GrantAccessModal.title': 'Grant permission',
  'GrantAccessModal.userLabel': 'User',
  'GrantAccessModal.usersLabel': 'Users Permissions',
  'GrantAccessModal.groupsDescription': 'Groups with the user {user} are',
  'GrantAccessModal.groupsDescription.highlight': 'highlighted.',
  'GrantAccessModal.groupsLabel': 'Groups Permissions',
  'GrantAccessModal.groupsMemberLabel': '{user} User Groups',
  'GrantAccessModal.unauthorizedError.message': 'You do not have the necessary permissions to set access.',
  'GroupForm.items.defaultDirectoryAccessLevel.label': 'Default folder Access Level',
  'GroupForm.items.name.placeholder': 'Viewer - read only',
  'GroupFormModal.title.create': 'New Group',
  'GroupUserCount.noUsers': 'No users',
  'GroupUserCount.userCount': `{count} {count, plural,
    one {User}
    few {Users}
    other {Users}
  }`,
  'GroupUserAddFormModal.title': 'Add a user to the group',
  'HeaderInfoDropdown.Licence.title': 'Licence information',
  'HeaderInfoDropdown.Licence.used': 'Used',
  'HeaderInfoDropdown.Licence.remaining': 'Remaining',
  'HeaderInfoDropdown.Licence.validTo': 'Valid to',
  'HeaderInfoDropdown.Documentation.title': 'Documentation',
  'HeaderInfoDropdown.UserDocumentation.title': 'User documentation',
  'HeaderInfoDropdown.TechnicalDocumentation.title': 'Technical documentation',
  'HeaderInfoDropdown.MDDocumentation.title': 'MD Manual',
  'HeaderInfoDropdown.Version.title': 'Version info',
  'HeaderInfoDropdown.Version.number': 'Version',
  'HeaderInfoDropdown.Version.NoData': 'Information not available',
  'HeaderInfoDropdown.Version.builtDate': 'Date of built',
  'HeaderInfoDropdown.ShortCuts.title': 'Shortcuts',
  'HeaderInfoDropdown.ShortCuts.ESC': 'Close preview',
  'HeaderInfoDropdown.ShortCuts.next': 'Next document',
  'HeaderInfoDropdown.ShortCuts.previous': 'Previous document',
  'HeaderInfoDropdown.ShortCuts.DocumentPreview': 'Document preview',
  'HeaderInfoDropdown.ShortCuts.DocumentsList': 'Documents list',
  'HeaderInfoDropdown.ShortCuts.ModelWorking': 'Working with model',
  'HeaderInfoDropdown.ShortCuts.MouseLeftClick': 'Left click',
  'HeaderInfoDropdown.ShortCuts.ContinuousSelection': 'Continuous selection of documents',
  'HeaderInfoDropdown.ShortCuts.MultipleModelElementsSelect': 'Select multiple model elements',
  'HeaderInfoDropdown.GDPR.title': 'GDPR',
  'HeaderInfoDropdown.GDPR.text': 'Within the AspeHub application, personal data of users are processed for the needs of registration and project management in the AspeHub application to the necessary extent (name, surname, email). For more information, including the possibility of revoking the consent to the processing of personal data, it is possible to contact the application administrator.',
  'HeaderInfoDropdown.Contacts.title': 'Contacts',
  'HeaderInfoDropdown.Contacts.webPageTitle': 'Web',
  'HeaderInfoDropdown.Contacts.technicalSupport': 'Technical support',
  'HeaderInfoDropdown.Contacts.phone': 'phone:',
  'HeaderInfoDropdown.Contacts.mail': 'e-mail:',
  'HeaderLocaleDropdown.changeLanguage': 'Change language',
  'HeaderSearch.placeholder': 'Search in project',
  'IdleTimerActivityCheck.modal.title': 'Logging out during inactivity',
  'IdleTimerActivityCheck.modal.message': 'You will be logged out {time} due to inactivity. Do you wish to continue working?',
  'IdleTimerActivityCheck.modal.confirm': 'Stay logged in',
  'InsufficientAccessLevelError.message': 'You do not have permission to read this document.',
  'InsufficientAccessLevelError.description': 'Ask the folder owner to grant you viewing permission.',
  'InviteReportModal.label': 'Not all users could be invited',
  'InviteReportModal.alreadyExists.label': `{count, plural,
    =0 {no}
    one {The following user already exists in the project}
    other {The following users already exist in the project}
    }.`,
  'InviteReportModal.invited.label': `{count, plural,
    =0 {No users have been invited}
    one {The following user has been invited}
    other {The following users have been invited}
    }.`,
  'InviteReportModal.uninvited.label': `{count, plural,
    =0 {no}
    one {The following user could not be invited}
    other {The following users could not be invited}
    }.`,
  'InviteReportModal.limit': `{count, plural,
    =0 {no}
    one {It is possible to invite one user}
    other {It is possible to invite # users}
    }.`,
  'InvoiceDetail.title': 'Invoice detail',
  'InvoiceDetail.menu.additionalInformation': 'Additional information',
  'InvoiceDetail.menu.items': 'Items',
  'InvoiceDetail.menu.overview': 'Overview',
  'InvoiceDetailAdditionalInformation.forTheContractor': 'For the contractor',
  'InvoiceDetailAdditionalInformation.forTheCustomer': 'For the customer',
  'InvoiceDetailAdditionalInformation.function': 'Function',
  'InvoiceDetailAdditionalInformation.invoiceManager': 'Invoice manager 7.7',
  'InvoiceDetailAdditionalInformation.where': 'Where',
  'InvoiceItemsDetail.columns.basicVATPercent': 'Basic VAT {rate} %',
  'InvoiceItemsDetail.columns.object': 'Object',
  'InvoiceItemsDetail.columns.realizedPercent': 'Realized {rate} %',
  'InvoiceItemsDetail.columns.retentionPercent': 'Retention [%]',
  'InvoiceItemsDetail.columns.retention': 'Retention',
  'InvoiceItemsDetail.columns.salePercent': 'Sale [%]',
  'InvoiceItemsDetail.columns.sale': 'Sale',
  'InvoiceItemsDetail.columns.suspensionPercent': 'Suspension',
  'InvoiceItemsDetail.columns.suspension': 'Suspension',
  'InvoiceItemsDetail.columns.total': 'Total',
  'InvoiceItemsDetail.columns.valorizationPercent': 'Valorization [%]',
  'InvoiceItemsDetail.columns.valorization': 'Valorization',
  'InvoiceItemsDetail.columns.vat': 'VAT {rate} %',
  'InvoiceDetail.menu.paymentInformation': 'Payment information',
  'InvoiceDetailOverview.administrator': 'Administrator/Investor',
  'InvoiceDetailOverview.billingPeriod': 'Billing period',
  'InvoiceDetailOverview.contractor': 'Contractor',
  'InvoiceDetailOverview.contacts': 'Contacts',
  'InvoiceDetailOverview.invoiceNumber': 'Invoice number',
  'InvoiceDetailOverview.issue': 'Issued',
  'InvoiceDetailOverview.maturity': 'Maturity',
  'InvoiceDetailOverview.price': 'Price',
  'InvoiceDetailOverview.payment': 'Payment',
  'InvoiceDetailOverview.serialNumber': 'SN',
  'InvoiceDetailOverview.taxDocument': 'Tax document',
  'InvoiceDetailOverview.taxBase': 'Tax base (21%)',
  'InvoiceDetailOverview.taxableSupply': 'Taxable Supply',
  'InvoiceDetailOverview.totalToBePaidIncludingVat': 'Total to be paid incl. VAT',
  'InvoiceDetailOverview.totalWithoutVat': 'Total without VAT',
  'InvoiceDetailOverview.invoiceType': 'Invoice type',
  'InvoiceDetailOverview.invoiceType.Klasicka': 'Classic',
  'InvoiceDetailOverview.invoiceType.Zalohova': 'Proforma',
  'InvoiceDetailOverview.invoiceType.Dobropis': 'Credit note',
  'InvoiceDetailPaymentInformation.bankAccount': 'Bank Account',
  'InvoiceDetailPaymentInformation.bankDetailsAdministrator': 'Bank details Administrator/Investor',
  'InvoiceDetailPaymentInformation.bankDetailsContractor': 'Bank details Contractor',
  'InvoiceDetailPaymentInformation.bicSwift': 'BIC/SWIFT',
  'InvoiceDetailPaymentInformation.iban': 'IBAN',
  'InvoiceDetailAdditionalInformation.taxPeriod': 'Tax period',
  'InvoiceDetailAdditionalInformation.VATTransferRegime': 'VAT reverse charge',
  'InvoiceDetailAdditionalInformation.contracts': 'Contracts',
  'InvoiceDetailAdditionalInformation.signature': 'Invoice signature',
  'InvoiceDetailAdditionalInformation.contractDate': 'Contract date',
  'InvoiceDetailAdditionalInformation.contractContractor': 'Contractor contract',
  'InvoiceDetailAdditionalInformation.contractCustomer': 'Customer contract',
  'IsModelButton.modal.confirm.title': 'Model contains bindings to elements',
  'IsModelButton.modal.confirm.content': 'When a document is removed from the models, all bindings to elements will be removed. Continue?',
  'IsModelButton.message.success': 'Document was successfully removed from models',
  'IsModelButton.message.error': 'The document could not be removed from the models',
  'IsModelButton.message.addSuccess': 'Document was successfully added to models',
  'IsModelButton.message.addError': 'The document could not be added to the models',
  'CommonInvoiceDetail.title': 'Detail of common invoice',
  'CommonInvoiceDetail.menu.overview': 'Overview',
  'CommonInvoiceDetail.menu.paymentInformation': 'Payment information',
  'CommonInvoiceDetail.menu.additionalInformation': 'Additional information',
  'CommonInvoiceDetail.menu.memberInvoices': 'Member invoices',
  'CommonInvoiceItemsGrid.columns.sign': 'Code',
  'CommonInvoiceItemsGrid.columns.contractor': 'Contractor',
  'CommonInvoiceItemsGrid.columns.order': 'SN',
  'CommonInvoiceItemsGrid.columns.priceWithoutVat': 'Price without Vat',
  'CommonInvoiceItemsGrid.columns.vatPriceBasic': 'Basic VAT rate',
  'CommonInvoiceItemsGrid.columns.vatPriceReduced1': '1st reduced VAT rate',
  'CommonInvoiceItemsGrid.columns.vatPriceReduced2': '2nd reduced VAT rate',
  'CommonInvoiceItemsGrid.columns.totalPrice': 'Total price',
  'ItemNotFoundErrorBox.description': 'The item may have been removed or the link is incorrect.',
  'ItemNotFoundErrorBox.title': 'Item not found.',
  'ItemNotFoundErrorBox.toDocuments': 'Go to documents',
  'ItemRowNameContainer.user': 'User',
  'ItemRowNameContainer.createdDate': 'Last Change {date}',
  'JSONVariableLinkFormItem.label': 'Link text',
  'JSONVariableLinkFormItem.url': 'URL address',
  'JSONVariableProjectLinkSelectorFormItem.button.selectDirectory': 'Select directory',
  'JSONVariableProjectLinkSelectorFormItem.button.selectDocument': 'Select document',
  'JSONVariableType.empty': 'Group',
  'JSONVariableType.number': 'Number',
  'JSONVariableType.string': 'Text',
  'JSONVariableType.stringDiff': 'Texts',
  'JSONVariableType.date': 'Date',
  'JSONVariableType.link': 'Web Link',
  'JSONVariableType.boolean': 'Toggle',
  'JSONVariableType.interval': 'Interval',
  'JSONVariableType.ckApprovalConditions': 'Ck Approval Conditions',
  'JSONVariableType.hubLink': 'AspeHub Link',
  'JSONVariableType.enum': 'Enumeration',
  'JSONVariableType.numberDiff': 'Numbers',
  'JSONVariableType.orgStructureWithUser': 'Organization structure with user',
  'JSONVariableType.buildingIdentification':'TWIST identifier',
  'KpiConfigurationFormModal.title.create': 'New KPI configuration',
  'KpiConfigurationFormModal.title.edit': 'Edit KPI configuration',
  'KpiConfigurationForm.title': 'Name',
  'KpiConfigurationForm.title.required': 'Name is required',
  'KpiConfigurationForm.columnRules': 'Rules',
  'KpiConfigurationFormItem.column': 'Report column',
  'KpiConfigurationFormItem.condition': 'Condition',
  'KpiConfigurationFormItem.referentialValue': 'Referenatial value',
  'KpiConfigurationFormItem.evaluation': 'Evaluation',
  'KpiConfigurationFormItem.evaluation.ok': 'Correct',
  'KpiConfigurationFormItem.evaluation.critical': 'Critical',
  'KpiConfigurationFormItem.addRule.title': 'New rule',
  'KpiConfigurationFormItem.addRule.button': 'Add',
  'KpiConfigurationFormItem.addRule.disabled.tooltip': 'All report columns already have a rule',
  'KpiSettingsToolbar.menu.add': 'Add new KPI configuration',
  'KpiSettingsToolbar.menu.edit': 'Edit active KPI configuration',
  'KpiSettingsToolbar.menu.delete': 'Delete active KPI configuration',
  'LabelFilterItem.Operators.AND': 'AND',
  'LabelFilterItem.Operators.OR': 'OR',
  'LabelFormModal.title.create': 'New Label',
  'LabelsForm.form.items.color.label': 'Color',
  'LabelsForm.form.items.description.placeholder': 'Optional description...',
  'LabelsForm.form.items.name.placeholder': 'Label name',
  'LabelsForm.preview': 'Label preview',
  'LabelsList.deleteConfirmModal.delete': 'Delete',
  'LabelsList.deleteConfirmModal.description': 'Do you want to force label deletion and remove it from '
    + '{documentCount} documents, {commentProcedureCount} comment procedures and {workflowCount} workflows?',
  'LabelsList.deleteConfirmModal.title': 'The label was not deleted because it is assigned to some objects.',
  'LabelsInput.placeholder': 'Select labels',
  'LablesEditableList.noLabels': 'No labels',
  'ListEmpty.noData': 'The list is empty',
  'ListEmpty.noItemsFound': 'No items match the specified filters.',
  'ListEmpty.noDocumentsFound': 'No items match the specified filters.',
  'LocalSigningModal.title': 'Sign with local certificate',
  'LocalSigningModal.button.sign': 'Sign',
  'LocalSigningModal.input.passphrase': 'Passphrase',
  'LocalSigningModal.certificateFile': 'Select certificate file (pfx, p12)',
  'LocalSigningModal.error.stampLoadingFailed': 'Background for signature failed to load',
  'LocalSigningModal.error.fontLoadingFailed': 'Font for signature failed to load',
  'LocalSigningModal.error.missingResources': 'Some of siging prerequisites are not loaded',
  'LocalSigningModal.status.initializing': 'Initializing signing environment',
  'LocalSigningModal.status.signingDocument': 'Signing document',
  'LocalSigningModal.status.uploadingDocument': 'Uploading signed document',
  'LocalSignatureErrors.singingProcessFailed': 'An error occured during document signing. Document cannot be signed.',
  'LocalSignatureErrors.uploadProcessFailed': 'An error occured during upload of signed document. Document was not signed.',
  'LocalSignatureErrors.invalidCertificateOrPassword': 'Wrong certificate file format or invalid passphrase.',
  'LocalSignatureErrors.certificateMissingHolderName': 'Certificate missing holder name to display.',
  'LocalSignatureErrors.signerFailedToCreate': 'Signer could not create valid signature format in document.',
  'LoginErrorPage.AccessDeniedMessage': 'You are not authorized to access this application.',
  'LoginErrorPage.Button.ReturnToLogin': 'Return to login',
  'LoginErrorPage.ErrorTitle': 'Access denied',
  'MainDashboard.noSelectedProjects': 'You have no selected projects :-(',
  'MainDashboard.noSelectedReports': 'You have no selected reports',
  'MainDashboard.viewAllProjects': 'View all projects',
  'MainDashboard.addFirstReportButton': 'Add new report',
  'MD.approvalMeetings.listModal.button.applyResult': 'Apply the results of the meeting',
  'MD.approvalMeetings.listModal.button.downloadBackground': 'Download meeting background document',
  'MD.approvalMeetings.listModal.confirm.cancel.title': 'Cancel meeting',
  'MD.approvalMeetings.listModal.confirm.cancel.description': 'Do you really want to cancel the meeting?',
  'MD.approvalMeetings.listModal.download.fileName': 'Meeting background {date}.docx',
  'MD.MeetingBackgroundDocumentModal.title': 'Document for CK meeting',
  'MD.MeetingBackgroundDocumentModal.requestIsRequired': 'Meeting document was not yet created',
  'MD.MeetingBackgroundDocumentModal.directory.label': 'Directory for meeting document',
  'MD.MeetingBackgroundDocumentModal.filename.label': 'Document filename',
  'MD.MeetingBackgroundDocumentModal.filename.label.initial.part': 'Summary basis of the meeting of the CK',
  'MD.MeetingBackgroundDocumentModal.dirName.label': 'Name of meeting folder',
  'MD.MeetingBackgroundDocumentModal.progress': 'Document preparation progress: {progress}%',
  'MD.MeetingBackgroundDocumentModal.button.download': 'Download prepared CK meeting document',
  'MD.MeetingBackgroundDocumentModal.button.recreate': 'Recreate document',
  'MD.MeetingBackgroundDocumentModal.error.generationFailed': 'Document generation failed with error. Please check included projects setting and try repeat document generation or contant admin.',
  'MD.MeetingBackgroundDocumentModal.error.generationFailedWithRetry': 'Document generation failed with error and will be processed again later. Please check included projects setting.',
  'MD.OverheadProjectSettingsForm.overheadProject.label': 'Overhead project',
  'MD.OverheadProjectSettingsForm.overheadProject.label.required': 'Overhead project is required',
  'MD.OverheadProjectSettingsForm.overheadProject.alert': 'Division users will be invited into selected project and read permission will be given for root directory.',
  'MD.Projects.ProjectReportPage.title': 'CK materials approval',
  'MD.Projects.ProjectCreateModal.title': 'Create CK material',
  'MD.Projects.ProjectCreateModal.creating.title': 'Creating CK material',
  'maskInput.letters': 'Characters',
  'maskInput.metadata': 'Metadata',
  'maskInput.text': "Text",
  'maskInput.number': "Digits",
  'maskInput.specialCharacters': "Special characters",
  'mask.metadata.folderName': 'Folder name',
  'MD.Projects.ProjectCreateModal.form.name.required': 'Name is required',
  'MD.Projects.ProjectCreateModal.form.hubProjectName': 'AspeHub project name',
  'MD.Projects.ProjectCreateModal.form.phase': 'State',
  'MD.Projects.ProjectCreateModal.form.phase.required': 'State is required',
  'MD.Projects.ProjectCreateModal.form.role': 'From role',
  'MD.Projects.ProjectCreateModal.form.role.required': 'Role is required',
  'MD.Projects.ProjectCreateModal.form.template': 'Template',
  'MD.Projects.ProjectCreateModal.form.template.required': 'Template is required',
  'MD.Projects.ProjectCreateModal.form.ckIdentification': 'CK identification',
  'MD.Projects.ProjectCreateModal.form.ckIdentification.required': 'CK identification is required',
  'MD.Projects.ProjectCreateModal.form.submissionDate': 'Date of submission',
  'MD.Projects.ProjectCreateModal.success': 'Material was successfully created',
  'MD.ProjectsCreateApprovalModal.filter.current.title': 'Current',
  'MD.ProjectsCreateApprovalModal.filter.current.description': 'Materials marked as current',
  'MD.ProjectsCreateApprovalModal.filter.current.yes': 'Yes - only current materials',
  'MD.ProjectsCreateApprovalModal.filter.current.no': 'No - materials with created newer',
  'MD.ProjectsCreateApprovalModal.title': 'Create CK material approval',
  'MD.ProjectsCreateApprovalModal.form.meetingNumber': 'Serial number',
  'MD.ProjectsCreateApprovalModal.form.meetingNumber.required': 'Serial number is required',
  'MD.ProjectsCreateApprovalModal.form.meetingNumber.unique': 'This number is already used',
  'MD.ProjectsCreateApprovalModal.form.date': 'Meeting date',
  'MD.ProjectsCreateApprovalModal.form.date.required': 'Meeting date is required',
  'MD.ProjectsCreateApprovalModal.form.timeRange': 'Meeting time',
  'MD.ProjectsCreateApprovalModal.form.timeRange.required': 'Meeting time is required',
  'MD.ProjectsCreateApprovalModal.form.note': 'Note',
  'MD.ProjectsCreateApprovalModal.form.note.maxLength': 'Note length is too long. Maximum length is {max} characters.',
  'MD.ProjectsCreateApprovalModal.form.referenceCode': 'Reference code',
  'MD.ProjectsCreateApprovalModal.form.referenceCode.required': 'Reference code is required',
  'MD.ProjectsCreateApprovalModal.form.referenceCode.maxLength': 'Reference code is too long. Maximum length is {max} characters.',
  'MD.ProjectsCreateApprovalModal.from.projectSelection': 'Material selection for approval',
  'MD.ProjectsCreateApprovalModal.from.projectSelection.required': 'Select at least one material for approval',
  'MD.MDcreateProject.ckIdentification.other':'Other',
  'MD.MDcreateProject.ckIdentification.railway':'Railway',
  'MD.MDcreateProject.ckIdentification.road':'Road',
  'MD.MDcreateProject.ckIdentification.water':'Water',
  'MD.Project.ActionCharacter.investment':'Investment',
  'MD.Project.ActionCharacter.nonInvestment':'Non investment',
  'MD.Project.ActionCharacter.other':'Other',
  'MD.Projects.ProjectCardModal.title': 'CK material card',
  'MD.Projects.ProjectCardModal.section.header.title': 'Basic information',
  'MD.Projects.ProjectCardModal.button.transfer.o910': 'Give to O910',
  'MD.Projects.ProjectCardModal.button.transfer.division': 'Give to division',
  'MD.Projects.ProjectCardModal.button.transfer.guarantor': 'Give to guarantor',
  'MD.Projects.ProjectCardModal.button.setDivision': 'Set division',
  'MD.Projects.ProjectCardModal.button.returnToSubmitter': 'Return to proposer',
  'MD.Projects.ProjectCardModal.button.returnToSubmitter.disable.notGuarantor': 'You are not the guarantor of the material',
  'MD.Projects.ProjectCardModal.button.returnToSubmitter.disable.isPreparedForCkAproval': 'The material is set for CK approval',
  'MD.Projects.ProjectCardModal.button.returnToO910': 'Return to O910',
  'MD.Projects.ProjectCardModal.button.returnToO910.disable.isNotProjectSupervisorRole': 'You are not the head of the division',
  'MD.Projects.ProjectCardModal.button.returnToO910.disableReason.submittedFor.notSet':'The Submitted field is not set',
'MD.Projects.ProjectCardModal.button.returnToO910.disableReason.processorDivision.notSet':'Division is not set',
'MD.Projects.ProjectCardModal.button.returnToO910.disableReason.guarantor.notSet':'The Guarantor is not set',
'MD.Projects.ProjectCardModal.button.returnToO910.disableReason.unexpectedMdRoleType':'You are not in the required role',
'MD.Projects.ProjectCardModal.button.returnToO910.disableReason.unexpectedProjectState':'CK material is not the required phase',
  'MDReturnMaterialModal.returnToSubmitter.confirmText':'Are you sure you want to return the material to the Submitter with this justification?',
    'MDReturnMaterialModal.returnToO910.confirmText':'Are you sure you want to return the material back to the O910 with this rationale?',
  'MD.Projects.ProjectCardModal.button.setGuarantor': 'Set guarantor',
  'MD.Projects.ProjectCardModal.button.commentingDivisions': 'Set commenting divisions',
  'MD.Projects.ProjectCardModal.button.save': 'Save changes',
  'MD.Projects.ProjectCardModal.button.delete.confirm': 'Do you really want to delete the CK material card? Connected project with files will be deleted as well.',
  'MD.Projects.ProjectCardModal.form.name.required': 'CK material name is required',
  'MD.Projects.ProjectCardModal.form.name.duplicate': 'CK material name is already used',
  'MD.Projects.ProjectCardModal.form.projectProposer.label': 'Created by',
  'MD.Projects.ProjectCardModal.form.guarantor.label': 'Guarantor',
  'MD.Projects.ProjectCardModal.form.division.label': 'Division',
  'MD.Projects.ProjectCardModal.form.phase.label': 'State',
  'MD.Projects.ProjectCardModal.input.price.suffix': 'CZK without VAT',
  'MD.Projects.ProjectCardModal.section.projectData.title': 'CK material data',
  'MD.Projects.ProjectCardModal.section.commission.title': 'Central Commission',
  'MD.Projects.ProjectCardModal.stateTransfer.divisionSetting.title': 'Give CK material to O910',
  'MD.Projects.ProjectCardModal.stateTransfer.divisionSetting.description': 'Finish editing CK material card and give the CK material to O910 for processing.',
  'MD.Projects.ProjectCardModal.stateTransfer.guarantorSetting.title': 'Give CK material to division',
  'MD.Projects.ProjectCardModal.stateTransfer.guarantorSetting.description': 'Selected division will be able to set CK material guarantor.',
  'MD.Projects.ProjectCardModal.stateTransfer.projectValidation.title': 'Give CK material to guarantor',
  'MD.Projects.ProjectCardModal.stateTransfer.projectValidation.description': 'Guarantor will get editing rights for this CK material.',
  'MD.Projects.ProjectCardModal.warning.overviewProjectNotSet': 'Overview project is not set',
  'MD.Projects.ProjectDivisionSetModal.title': 'Set CK material division',
  'MD.Projects.ProjectDivisionSetModal.division.current.label': 'Current division: {division}',
  'MD.Projects.ProjectDivisionSetModal.division.label': 'Division',
  'MD.Projects.ProjectDivisionSetModal.division.required': 'Division is required',
  'MD.Projects.ProjectGuarantorSetModal.title': 'Set CK material guarantor',
  'MD.Projects.ProjectGuarantorSetModal.role.current.label': 'Current guarantor role: ',
  'MD.Projects.ProjectGuarantorSetModal.role.label': 'Guarantor role',
  'MD.Projects.ProjectGuarantorSetModal.role.required': 'Guarantor is required',
  'MD.Projects.ProjectCommentingDivisionsSetModal.title': 'Set commenting divisions',
  'MD.Projects.ProjectCkApprovalReadinesSetModal.title': 'Allow CK approval',
  'MD.Projects.ProjectCkApprovalReadinesSetModal.title.prerequisites.referenceNumber': 'Reference number is required to set readines for CK approval',
  'MD.Projects.ProjectCkApprovalReadinesSetModal.title.prerequisites.ckBackgroundDocuments': 'Ck background documents are required to set readines for CK approval',
  'MD.Projects.ProjectCommentingDivisionsSetModal.division.label': 'Divisions',
  'MD.Projects.submittedFor.forApproval':'For approval',
  'MD.Projects.submittedFor.forInformation':'For reference',
  'MD.Projects.ckStatement.acknowledged':'Takes note',
  'MD.Projects.ckStatement.acknowledgedWithSuggestion':'Notes with recommendation',
  'MD.Projects.ckStatement.approved':'Approves',
  'MD.Projects.ckStatement.approvedWithConditions':'Approves with condition/s',
  'MD.Projects.ckStatement.rejected':'Rejectes',
  'MD.Projects.ckStatement.returnedForRework':'Returns for rework',
  'MD.Projects.ckStatement.interrupted': 'Interrupted',
  'MD.Projects.MDSetCkPreparedModal.preparedForCkApproval.label': 'CK material is prepared for CK approval',
  'MD.Projects.materialPhases.columnTitle':'Material phase',
  'MD.Projects.materialPhases.new':'New material',
  'MD.Projects.materialPhases.submittedToO910':'Submitted to O910',
  'MD.Projects.materialPhases.forwardedToDivision':'Forwarded to division',
  'MD.Projects.materialPhases.forwardedToGuarantor':'Forwarded to Guarantor',
  'MD.Projects.materialPhases.CKprocessing':'Discussed by CK',
  'MD.Projects.materialPhases.returnedToO910':'Returned to O910',
  'MD.Projects.materialPhases.returnedToProposer':'Returned to Proposer',
  'MDProjectApprovalForm.projectIds.current':'Current',
  'MDProjectApprovalForm.projectIds.all':'All',
  'MD.Organization.divisions.title': 'Divisions',
  'MD.Organization.divisions.panel.add': 'Add division',
  'MD.Organization.divisions.panel.delete.confirm.tooltip': 'Delete division and its users',
  'MD.Organization.divisions.panel.deleteUser.confirm.tooltip': "Delete division's user",
  'MD.Organization.divisions.panel.edit.tooltip': 'Edit division',
  'MD.Organization.divisions.modal.title.create': 'New division',
  'MD.Organization.divisions.modal.title.edit': 'Edit divition',
  'MD.Organization.divisions.modal.input.name': 'Division name',
  'MD.Organization.divisions.modal.input.description': 'Division description',
  'MD.Organization.divisions.modal.input.organization': 'Organization',
  'MD.Organization.divisions.modal.input.leader': 'Division leader',
  'MD.Organization.divisions.modal.input.leader.required': 'Choose division leader',
  'MD.Organization.divisions.modal.input.users': 'Users',
  'MD.Organization.divisions.modal.input.isProposerOnlyCheck': 'Proposer only',
  'MD.Organization.divisionsUsers.panel.addUser': 'Add users',
  'MD.Organization.divisionsUsers.panel.addUser.disabledTooltip': 'Division does not have a division head',
  'MD.Organization.divisionsUsers.panel.title': 'Division users',
  'MD.Organization.divisionsUsers.headUserTag': 'Division head',
  'MD.Organization.divisionsUsers.modal.title.add': 'Add users to division',
  'MD.Organization.divisionsUsers.delete.division.title': 'Division have some users in roles',
  'MD.Organization.divisionsUsers.delete.division.description': 'Continuing will remove users from roles: {roles}. Do you wish to continue?',
  'MD.Organization.divisionsUsers.delete.fromRole.title': 'User is in role or CK material',
  'MD.Organization.divisionsUsers.delete.fromRole.description': 'User will be removed from roles. Do you wish to continue?',
  'MD.Organization.divisionsUsers.delete.success': 'User was successfully removed from role.',
  'MD.Organization.roles.title': 'Roles',
  'MD.Organization.roleUsers.panel.title': 'Users in role',
  'MD.Organization.roleUsers.panel.add': 'Add new user group into role',
  'MD.Organization.roleUser.delete.success': 'User successfully removed from role.',
  'MD.Organization.roles.panel.delete.confirm.tooltip': 'Delete selected user from role?',
  'MD.Organization.roles.modal.title.create': 'New user in role',
  'MD.Organization.roles.modal.title.edit': 'Edit user in role',
  'MD.Organization.roles.modal.selectedDivision': 'Role division',
  'MD.Organization.roles.modal.selectedDivision.notSelected': 'To set a division, select the role leader',
  'MD.Organization.roles.modal.selectedDivision.moreDivisions':'Choose a division from several options',
  'MD.Organization.roles.modal.input.leader': 'Role leader',
  'MD.Organization.roles.modal.input.leader.required': 'Role leader is required',
  'MD.Organization.roles.modal.input.users': 'Role users',
  'MD.Organization.roles.modal.error.headUserDivisionNotFound': 'Division of selected head user not found',
  'MD.Organization.sectionTitle': 'CK material approval',
  'MD.Roles.name.proposer': 'Proposer',
  'MD.Roles.name.O910': 'O910',
  'MD.Roles.name.guarantor': 'Guarantor',
  'MD.Roles.name.supervisor': 'Supervisor',
  'MD.Roles.name.processor': 'Processor',
  'MD.Roles.name.executive': 'Executive',
  'MD.Roles.name.ck_organizer': 'CK Organizer',
  'MD.Roles.name.ck_head': 'CK Head',
  'MD.Roles.name.md_admin': 'MD Manager',
  'MD.Roles.name.md_preview': 'Material Preview',
  'MD.ProjectPhase.other': '0 - Other',
  'MD.ProjectPhase.init': '1 - CK material preparation',
  'MD.ProjectPhase.study': '2 - CK material study',
  'MD.ProjectPhase.intention': '3 - CK material intention',
  'MD.ProjectPhase.update': '4 - Actualization',
  'MD.ProjectVariableCondition.condition.title': 'CK Condition',
  'MD.ProjectVariableCondition.deadline.title': 'Deadline',
  'MD.ProjectVariableCondition.approvalDone.title': 'Fulfilled',
  'MD.ProjectVariableCondition.approvalDescription.title': 'Method of fulfilment',
  'MD.ProjectVariableCondition.addNewRule.button': 'Add condition',
  'MD.ProjectVariableCondition.validation.nameRequired': 'Condition text is required.',
  'MD.ProjectVariableCondition.validation.duplicateName': 'Condition text must be unique between project conditions.',
  'MD.MDCkConditionsEnum.conditionsMet':'Met',
  'MD.MDCkConditionsEnum.conditionsNotMet':'Not met',
  'MD.MDCkConditionsEnum.withConditions': 'With conditions',
  'MD.MDCkConditionsEnum.none':'None',
  'MD.MDUpdatedValueEnum.updated':'Actualised',
  'MDUpdatedValueEnum.noUpdated':'No actualised',
  'MDUpdatedValueEnum.noValue':'No value',
  'MD.ProjectVariableEnum.projectName.name': 'CK material name',
  'MDApprovedProjectsReport.constructionRegion':'Realization location',
  'MD.ProjectVariableEnum.constructionRegion.name': 'Location of realisation (region)',
  'MD.ProjectVariableEnum.stretch.name': 'Route / segment',
  'MD.ProjectVariableEnum.documentationLink.name': 'Documentation on storage',
  'MD.ProjectVariableEnum.totalCost.name': 'Total costs without VAT',
  'MD.ProjectVariableEnum.expectedRealisationTime.name': 'Expected realisation time',
  'MD.ProjectVariableEnum.expectedRealisationTime.from.name': 'Realisation time from',
  'MD.ProjectVariableEnum.expectedRealisationTime.to.name': 'Realisation time to',
  'MD.ProjectVariableEnum.commentProcedure.name': 'Comment procedure',
  'MD.ProjectVariableEnum.ckMeetingBackground.name': 'Documentation for the meeting of the CC of the MD',
  'MD.ProjectVariableEnum.ckMeetingMinutes.name': 'Minutes of the meeting of the CC of the MD',
  'MD.ProjectVariableEnum.intentionApprovalClause.name': 'Intention approval clause',
  'MD.ProjectVariableEnum.ckApprovalDate.name': 'CC of the MD review date',
  'MD.ProjectVariableEnum.ckApproved.name': 'CK discussed',
  'MD.ProjectVariableEnum.ckApprovalConditions.name': 'Central Commission conditions',
  'MD.ProjectVariableEnum.ckApprovalConditions.met': 'Met',
  'MD.ProjectVariableEnum.ckApprovalConditions.notMet': 'Not met',
  'MD.ProjectVariableEnum.preparationUpdate.name': 'CK material preparation update',
  'MD.ProjectVariableEnum.investorContactPerson.name': 'Investor contact persion',
  'MD.ProjectVariableEnum.studyType.name': 'Type of study',
  'MD.ProjectVariableEnum.actionCharacter.name': 'Nature of the action',
  'MD.ProjectVariableEnum.ckIdentificaion.name': 'CK Identification',
  'MD.ProjectVariableEnum.hasOpponentsReport.name': 'Has opponent\'s report',
  'MD.ProjectVariableEnum.opponentsReport.name': 'Opponent\'s report',
  'MD.ProjectVariableEnum.isUPDChangeRequest.name': 'The project requires a change in the ÚPD',
  'MD.ProjectVariableEnum.intentionUpdated.name': 'Intention update',
  'MD.ProjectVariableEnum.mdContactPerson.name': 'MD contact person',
  'MD.ProjectVariableEnum.isProFond.name': 'ISPROFOND',
  'MD.ProjectVariableEnum.referenceNumber.name': 'Reference number',
  'MD.ProjectVariableEnum.preparedForCkApproval.name': 'Prepared for Ck approval',
  'MD.ProjectVariableEnum.submittedFor.name': 'Submitted for',
  'MD.ProjectVariableEnum.ckStatement.name': 'CK statement',
  'MD.ProjectVariableLinkModal.directory.title': 'Select directory',
  'MD.ProjectVariableLinkModal.document.title': 'Select document',
  'MDCkConditionModal.Condition':'CK condition',
  'MDCkConditionModal.Deadline':'Deadline for fulfillment',
  'MDCkConditionModal.ApprovalDone':'Fulfilled',
  'MDCkConditionModal.ApprovalDescription':'Filling method',
  'MDCkConditionModal.title':'Conditions of approval',
  'menu.side.comment.procedure': 'Comments procedure',
  'menu.side.AspeHubSetting': 'AspeHub setting',
  'menu.side.hubAdministrators': 'Administrators',
  'menu.side.hubOrganizations': 'Organizations',
  'menu.side.hubUsers': 'Users',
  'menu.side.organizationsSetting': 'Organizations setting',
  'menu.side.organizations.setting': 'Organizations setting',
  'menu.side.dashboard': 'Dashboard',
  'menu.side.search': 'Search in the project',
  'menu.side.settings': 'Project settings',
  'menu.side.projects': 'Projects',
  'menu.side.projectsGrid': 'Data of Projects',
  'menu.side.reporting': 'Reports',
  'menu.side.mdProjects': 'CK materials',
  'menu.side.mdOverheadProject': 'Central commission',
  'menu.side.documents': 'Documents',
  'menu.side.documents.all': 'All',
  'menu.side.documents.category': 'Categories',
  'menu.side.documents.favorite': 'Favorites',
  'menu.side.documents.recent': 'Recent',
  'menu.side.documents.smart': 'SMART',
  'menu.side.documents.filters': 'Filters',
  'menu.side.toggleMenu': 'Maximize the menu',
  'menu.side.minimizeMenu': 'Minimize the menu',
  'menu.side.model': 'Model',
  'menu.side.changeProject': 'Change project',
  'menu.side.workflow': 'Workflow',
  'menu.side.workflow.instances': 'Running',
  'menu.side.workflow.templates': 'Templates',
  'menu.side.workflow.processes': 'Processes',
  'menu.side.budget': 'Estimate',
  'menu.side.construction': 'AspeEsticon',
  'menu.side.drawing': 'Work in progress',
  'menu.side.invoicing': 'Invoicing',
  'menu.side.zbv': 'Change order',
  'menu.side.schedule': 'Schedule',
  'menu.side.financialPlan': 'Financial Plan',
  'menu.side.constructionJournal': 'Construction journal',
  'menu.side.documents.discarded': 'Discarded ',
  'menu.side.externalApplications': 'External applications',
  'Metadata.addButton.title':'Add additional data type',
  'Metadata.newProperty.title':'Add additional data type',
  'Metadata.editProperty.title':'Edit additional data type',
  'MetadataForm.noEdit.variableType.description':'The variable type cannot be changed for additional data types that have already been created, if necessary, create a new data type',
  'MetadataExportImportButton.menu.export.noData':'There is nothing to export',
'MetadataExportImportButton.menu.import.noAccess':'You do not have write permission or higher',
'MetadataExportImportButton.csvFilesOnly':'Only files in *.csv format can be imported',
'MetadataExportImportButton.importSuccess':'The import was successful',
'MetadataExportImportButton.import.modalTitle': 'Metadata import',
  'MetadataExportImportButton.export.modalTitle': 'Metadata export',
  'MetadataExportImportButton.export.withoutNoAccess': 'Skip folders for which you do not have sufficient permissions',
'MetadataExportImportButton.description':'Only import from *.csv files is supported, with separator - semicolon (;)',
'MetadataExportImportButton.import.directoryFails.common':'Directory - values ​​that failed to import',
'MetadataExportImportButton.import.documentsFails.common':'Files - values ​​that failed to import',
'MetadataExportImportButton.import.replaceExisting':'Replace existing',
'MetadataExportImportButton.import.addMissingDefinition': 'Add missing definitions',
'MetadataExportImportButton.import.deleteMetadataMissingInInImportedFile': 'Delete metadata missing in imported file',
'MetadataExportImportButton.withTree': 'Including subdirectories',
'MetadataExportImportButton.csvPath': 'Imported directory: ',
'MetadataExportImportButton.destinationPath': 'Destination directory: ',
'MetadataExportImportButton.directoryNotInImports': 'Directory is not in imported file',
'MetadataExportImportButton.documentNotInImports': 'Document is not in imported file',
'MetadataExportImportButton.missingMetadataDefinition': 'This metadata is not defined for the target folder',
'MetadataExportImportButton.notImportedDirectoryKeys':'Non-importable folder metadata',
'MetadataExportImportButton.notImportedDocumentKeys':'Non-importable document metadata',
'MetadataExportImportButton.table.reason':'Reason',
'MetadataExportImportButton.table.path':'Path',
'MetadataExportImportButton.table.docname':'Document',
'MetadataExportImportButton.table.notImportedKeys':'Not imported',
'MetadataExportImportButton.importResult.title':'Import results',
'MetadataExportImportButton.importResult.changedDirectories':'Changed directories:',
'MetadataExportImportButton.importResult.addedDirectoryDefinitions':'Added definitions for directories:',
'MetadataExportImportButton.importResult.changedDirectoryValues':'Changed values ​​in directories:',
'MetadataExportImportButton.importResult.unChangedDirectories':'Unchanged directories:',
'MetadataExportImportButton.importResult.unChangedDirectoryValues':'Directories with unchanged values:',
'MetadataExportImportButton.importResult.removedDirectoryValues':'Deleted values ​​in directories:',

'MetadataExportImportButton.importResult.changedDocuments':'Changed documents:',
'MetadataExportImportButton.importResult.addedDocumentDefinitions':'Added definitions for documents:',
'MetadataExportImportButton.importResult.changedDocumentValues':'Changed values ​​in documents:',
'MetadataExportImportButton.importResult.unChangedDocuments':'Unchanged documents:',
'MetadataExportImportButton.importResult.unChangedDocumentValues':'Documents with unchanged values:',
'MetadataExportImportButton.importResult.removedDocumentValues':'Deleted values ​​in documents:',

'MetadataExportImportButton.importFailReason.metadataDefinitionNotFound': 'Imported data type was not found',
'MetadataExportImportButton.importFailReason.ignoreExisting': 'Original value was left',
'MetadataExportImportButton.importFailReason.documentNotFound': 'Document not found',
'MetadataExportImportButton.importFailReason.directoryNotFound': 'Directory not found',
'MetadataExportImportButton.importFailReason.documentHasOwner': 'Document has owner',
'MetadataExportImportButton.importFailReason.accessDenied': 'Access denied',
'MetadataExportImportButton.importFailReason.metadataTypeMess': 'Unexpected metadata type',
'MetadataExportImportButton.importFailReason.documentLocationNotFound': 'File location not found',
'MetadataExportImportButton.importFailReason.duplicitDocument': 'There are multiple records for this documen',
'MetadataExportImportButton.importFailReason.duplicitDir': 'There are multiple entries for the given folder',
'MetadataExportImportButton.importFailReason.valueCastError': 'Unexpected value',
'MetadataExportImportButton.importFailReason.unexpectedError': 'Unexpected error',
  'MessageCenterAssignments.createAssignment': 'New task',
  'MessageCenterAssignments.createdDate': 'Created date',
  'MessageCenterAssignments.deadlineDate': 'Deadline',
  'MessageCenterAssignments.resolvedDate': 'Resolved date',
  'MessageCenterAssignments.source': 'Role',
  'MessageCenterAssignments.source.title': 'My role in the task',
  'MessageCenterAssignments.showOnlyMyAssignments': 'Show only my tasks',
  'MessageCenterAssignments.switchButtons.showAll': 'All',
  'MessageCenterAssignments.switchButtons.onlyMy': 'Only my',
  'MessageCenterAssignments.goToSettings': 'Go to organization settings',
  'MessageCenterPage.tabs.assignments': 'Tasks',
  'MessageCenterPage.tabs.messages': 'Messages',
  'MessageCenterPage.tabs.report': 'Report',
  'MessageCenterPage.tabs.settings': 'Settings',
  'MessageCenterPage.title': 'Message center',
  'MessageCenterPage.filters.category.label': 'Category',
  'MessageCenterPage.filters.categoryType.label': 'Type',
  'MessageCenterPage.filters.createDateRange.label': 'Create in',
  'MessageCenterPage.filters.finishDateRange.label': 'Finish in',
  'MessageCenterPage.filters.state.label': 'State',
  'MessageCenterPage.filters.project.label': 'Project',
  'MessageCenterPage.order.createTime.label': 'Create time',
  'MessageCenterPage.order.finishTime.label': 'Finish time',
  'MessageCenterPage.order.status.label': 'Status',
  'MessageCenterPage.order.category.label': 'Category',
  'MessageCenterPage.order.project.label': 'Project',
  'MessageCenterPage.message.categoryType.assignment': 'Task',
  'MessageCenterPage.message.categoryType.commentProcedure': 'Comment procedure',
  'MessageCenterPage.message.categoryType.workflow': 'Workflow',
  'MessageCenterPage.message.categoryType.appFileShare': 'Sharing',
  'MessageCenterPage.message.categoryType.alert': 'Alert',
  'MessageCenterPage.message.categoryType.bulkAction': 'Bulk action',
  'MessageCenterPage.message.categoryType.subscriber': 'Tracked changes',
  'MessageCenterPage.message.categoryType.accountNotification': 'Project',
  'MessageCenterPage.message.categoryType.document': 'Documents',
  'MessageCenterPage.message.categoryType.document.filter': 'Documents',
  'MessageCenterPage.message.categoryType.demandMsg': 'User notice',
  'MessageCenterPage.message.category.assignmentDeadlineExpired': 'The task expired',
  'MessageCenterPage.message.category.assignmentDeadlineExpired.filter': 'The task expired',
  'MessageCenterPage.message.category.assignmentDeadlineWarning': 'The deadline in the task is about to expire.',
  'MessageCenterPage.message.category.assignmentDeadlineWarning.filter': 'The deadline in the task is about to expire.',
  'MessageCenterPage.message.category.assignmentDocumentsChanged': 'A task document has changed.',
  'MessageCenterPage.message.category.assignmentStarted': 'A task has started.',
  'MessageCenterPage.message.category.assignmentChanged': 'Task description has changed.',
  'MessageCenterPage.message.category.assignmentUsersChanged': 'Task users were changed.',
  'MessageCenterPage.message.category.assignmentStateChanged': 'Task state has changed.',
  'MessageCenterPage.message.category.assignmentUserAdded': 'A user was added to a task.',
  'MessageCenterPage.message.category.assignmentUserRemoved': 'A user was removed from a task.',
  'MessageCenterPage.message.category.assignmentNoteAdded': 'New comment added to a task.',
  'MessageCenterPage.message.category.assignmentNoteUserNotified': 'New mention in task comment.',
  'MessageCenterPage.message.category.wfNodeStarted': 'You have just been assigned a workflow task to solve.',
  'MessageCenterPage.message.category.wfNodeLimitWillBeExceeded': 'The deadline for solving the current task will be exceeded.',
  'MessageCenterPage.message.category.wfNodeLimitExceeded': 'The deadline for solving the current task has been exceeded.',
  'MessageCenterPage.message.category.wfApprovalDocumentsRevisionAdded': 'Approval document revision uploaded.',
  'MessageCenterPage.message.category.wfApprovalDocumentAdded': 'A new approval document has been uploaded.',
  'MessageCenterPage.message.category.wfApprovalDocumentRemoved': 'An approval document has been removed.',
  'MessageCenterPage.message.category.wfWorkflowFinished': 'Workflow has finished.',
  'MessageCenterPage.message.category.wfBackgroundDocumentLimitExceeded': 'The deadline for attaching background document has been exceeded.',
  'MessageCenterPage.message.category.wfBackgroundDocumentLimitWillBeExceeded': 'The deadline for attaching background document will be exceeded.',
  'MessageCenterPage.message.category.wfBackgroundDocumentRequestStarted': 'You have an active request in the Workflow to add a background document.',
  'MessageCenterPage.message.category.wfBackgroundDocumentAdded': 'Background document has been added.',
  'MessageCenterPage.message.category.wfBackgroundDocumentRequestRemoved': 'The request to add a background document in the Workflow approval process has been removed.',
  'MessageCenterPage.message.category.wfBackgroundDocumentRequestChanged': 'The request to add a background document in the Workflow approval process has been changed.',
  'MessageCenterPage.message.category.wfBackgroundDocumentAccepted': 'Background document has been accepted.',
  'MessageCenterPage.message.category.wfBackgroundDocumentRefused': 'Background document has been refused.',
  'MessageCenterPage.message.category.wfNodeFinished': 'The Workflow approval task has been completed.',
  'MessageCenterPage.message.category.wfWorkflowClosed': 'The Workflow approval process has been closed.',
  'MessageCenterPage.message.category.wfApprovalDocumentsAccepted': 'Approval documents has been accepted.',
  'MessageCenterPage.message.category.wfApprovalDocumentsRefused': 'Approval document has been refused.',
  'MessageCenterPage.message.category.wfWorkflowNodeNoteAdded': 'Note has been added to workflow task.',
  'MessageCenterPage.message.category.wfWorkflowNodeNoteUserNotified': 'Workflow user in node was notified.',
  'MessageCenterPage.message.category.wfWorkflowNodeAttachmentAdded': 'Attachment has been added to a workflow task.',
  'MessageCenterPage.message.category.wfWorkflowNodeAttachmentRemoved': 'Attachment has been discarded from a workflow task.',
  'MessageCenterPage.message.category.wfWorkflowNodeAttachmentRestored': 'Attachment has been restored in a workflow task.',
  'MessageCenterPage.message.category.cpCommentAdded': 'The comment has been added.',
  'MessageCenterPage.message.category.cpDocumentAdded': 'A document has been added to comment.',
  'MessageCenterPage.message.category.cpDocumentRemoved': 'A document has been removed from comment.',
  'MessageCenterPage.message.category.cpStateChanged': 'The status of the comment procedure has been changed.',
  'MessageCenterPage.message.category.cpCommentStateChangded': 'Comment status changed in comment procedure.',
  'MessageCenterPage.message.category.cpCommentSubmitterTeamChanged': 'Changes have been made to the comment in the list of submitter teams.',
  'MessageCenterPage.message.category.cpCommentProcessorTeamChanged': 'Changes have been made to the comment in the list of processor teams.',
  'MessageCenterPage.message.category.cpCommentNoteAdded': 'Note has been added to comment.',
  'MessageCenterPage.message.category.cpCommentTextChanged': 'The text of the comment has been changed.',
  'MessageCenterPage.message.category.cpUserAdded': 'User has been added to comment procedure.',
  'MessageCenterPage.message.category.cpUserRemoved': 'User has been removed from comment procedure.',
  'MessageCenterPage.message.category.cpCommentDocumentAdded': 'A document has been added to comment.',
  'MessageCenterPage.message.category.cpCommentDocumentRemoved': 'A document has been removed from comment.',
  'MessageCenterPage.message.category.cpStarted': 'Comment procedure has started.',
  'MessageCenterPage.message.category.cpClosed': 'Comment procedure has been closed.',
  'MessageCenterPage.message.category.cpCommentAttachmentAdded': 'Attachment has been added to comment.',
  'MessageCenterPage.message.category.cpCommentAttachmentRemoved': 'Attachment has been removed from comment.',
  'MessageCenterPage.message.category.cpCommentNoteUserNotified': 'Comment procedure user in comment was notified.',
  'MessageCenterPage.message.category.cpDeadlineWarning': 'Comment procedure deadline will expire soon.',
  'MessageCenterPage.message.category.cpDeadlineExpired': 'Comment procedure deadline has expired.',
  'MessageCenterPage.message.category.cpDeadlineChanged': 'Comment procedure deadline has been changed.',
  'MessageCenterPage.message.category.appFileShare': 'File or folder shared',
  'MessageCenterPage.message.category.alertSign': 'Document signing alert',
  'MessageCenterPage.message.category.demWorkflowNodeResolveDemand': 'User notice - workflow',
  'MessageCenterPage.message.category.demWorkflowNodeResolveDemand.filter': 'User notice - workflow',
  'MessageCenterPage.message.category.demAssignmentResolveDemand': 'User notice - task',
  'MessageCenterPage.message.category.demAssignmentResolveDemand.filter': 'User notice - task',
  'MessageCenterPage.message.category.assignmentStarted.filter': 'Task created',
  'MessageCenterPage.message.category.wfNotification': 'Message from a notification node',
  'MessageCenterPage.message.category.wfNotification.filter': 'Message from a notification node',
  'MessageCenterPage.message.category.assignmentStateChanged.filter': 'Task state changed',
  'MessageCenterPage.message.category.assignmentUserAdded.filter': 'Task user added',
  'MessageCenterPage.message.category.assignmentUserRemoved.filter': 'Task user removed',
  'MessageCenterPage.message.category.assignmentDocumentsChanged.filter': 'Task document changed',
  'MessageCenterPage.message.category.assignmentUsersChanged.filter': 'Task users changed',
  'MessageCenterPage.message.category.assignmentChanged.filter': 'Task description changed.',
  'MessageCenterPage.message.category.assignmentNoteAdded.filter': 'New task comment added ',
  'MessageCenterPage.message.category.assignmentNoteUserNotified.filter': 'New mention in task comment.',
  'MessageCenterPage.message.category.wfNodeStarted.filter': 'Workflow task assigned',
  'MessageCenterPage.message.category.wfNodeLimitWillBeExceeded.filter': 'The deadline in the workflow task is about to expire.',
  'MessageCenterPage.message.category.wfNodeLimitExceeded.filter': 'Workflow task expired',
  'MessageCenterPage.message.category.wfApprovalDocumentsRevisionAdded.filter': 'Approval document revision upload',
  'MessageCenterPage.message.category.wfApprovalDocumentAdded.filter': 'A new approval document upload',
  'MessageCenterPage.message.category.wfApprovalDocumentRemoved.filter': 'An approval document removed',
  'MessageCenterPage.message.category.wfWorkflowFinished.filter': 'Workflow finished',
  'MessageCenterPage.message.category.wfBackgroundDocumentLimitExceeded.filter': 'Document request on workflow limit exceeded',
  'MessageCenterPage.message.category.wfBackgroundDocumentLimitWillBeExceeded.filter': 'Document request on workflow will be exceeded',
  'MessageCenterPage.message.category.wfBackgroundDocumentRequestStarted.filter': 'Request to add background document to workflow',
  'MessageCenterPage.message.category.wfBackgroundDocumentAdded.filter': 'Background document added to workflow',
  'MessageCenterPage.message.category.wfBackgroundDocumentRequestRemoved.filter': 'Request for background document removed from workflow',
  'MessageCenterPage.message.category.wfBackgroundDocumentRequestChanged.filter': 'Request for background document changed in workflow',
  'MessageCenterPage.message.category.wfBackgroundDocumentAccepted.filter': 'Background document accepted in workflow',
  'MessageCenterPage.message.category.wfBackgroundDocumentRefused.filter': 'Background document refused in workflow',
  'MessageCenterPage.message.category.wfNodeFinished.filter': 'Workflow task finished',
  'MessageCenterPage.message.category.wfWorkflowClosed.filter': 'Workflow closed',
  'MessageCenterPage.message.category.wfApprovalDocumentsAccepted.filter': 'Workflow approval document accepted',
  'MessageCenterPage.message.category.wfApprovalDocumentsRefused.filter': 'Workflow approval document refused',
  'MessageCenterPage.message.category.wfWorkflowNodeNoteAdded.filter': 'Workflow task note added',
  'MessageCenterPage.message.category.wfWorkflowNodeNoteUserNotified.filter': 'User in workflow task note notified',
  'MessageCenterPage.message.category.wfWorkflowNodeAttachmentAdded.filter': 'Attachment added to workflow task',
  'MessageCenterPage.message.category.wfWorkflowNodeAttachmentRemoved.filter': 'Attachment discarded from workflow task',
  'MessageCenterPage.message.category.wfWorkflowNodeAttachmentRestored.filter': 'Attachment restored in workflow task',
  'MessageCenterPage.message.category.cpCommentAdded.filter': 'Commend procedure comment added',
  'MessageCenterPage.message.category.cpDocumentAdded.filter': 'Document added to comment',
  'MessageCenterPage.message.category.cpDocumentRemoved.filter': 'Document removed from comment',
  'MessageCenterPage.message.category.cpStateChanged.filter': 'Comment procedure state changed',
  'MessageCenterPage.message.category.cpCommentStateChangded.filter': 'Comment state changed',
  'MessageCenterPage.message.category.cpCommentSubmitterTeamChanged.filter': 'Comment submitter teams list changed',
  'MessageCenterPage.message.category.cpCommentProcessorTeamChanged.filter': 'Comment processor teams list changed',
  'MessageCenterPage.message.category.cpCommentNoteAdded.filter': 'Comment node added',
  'MessageCenterPage.message.category.cpCommentTextChanged.filter': 'Comment text changed',
  'MessageCenterPage.message.category.cpUserAdded.filter': 'User added to comment procedure',
  'MessageCenterPage.message.category.cpUserRemoved.filter': 'User removed from comment procedure',
  'MessageCenterPage.message.category.cpCommentDocumentAdded.filter': 'Document added to comment',
  'MessageCenterPage.message.category.cpCommentDocumentRemoved.filter': 'Document removed from comment',
  'MessageCenterPage.message.category.cpStarted.filter': 'Comment procedure started',
  'MessageCenterPage.message.category.cpClosed.filter': 'Comment procedure closed',
  'MessageCenterPage.message.category.cpCommentAttachmentAdded.filter': 'Attachment added to comment',
  'MessageCenterPage.message.category.cpCommentAttachmentRemoved.filter': 'Attachment removed from comment',
  'MessageCenterPage.message.category.cpCommentNoteUserNotified.filter': 'User in comment note notified',
  'MessageCenterPage.message.category.cpDeadlineWarning.filter': 'Comment procedure deadline will expire soon',
  'MessageCenterPage.message.category.cpDeadlineExpired.filter': 'Comment procedure deadline expired',
  'MessageCenterPage.message.category.cpDeadlineChanged.filter': 'Comment procedure deadline changed',
  'MessageCenterPage.message.category.appFileShare.filter': 'File shared',
  'MessageCenterPage.message.category.alertSign.filter': 'Document signing alert',
  'MessageCenterPage.message.category.bulkActionReportStateChanged': 'Bulk change of document states',
  'MessageCenterPage.message.category.bulkActionReportLabelsChanged': 'Bulk change of document labels',
  'MessageCenterPage.message.category.bulkActionReportStateChanged.filter': 'Bulk change of document states',
  'MessageCenterPage.message.category.bulkActionReportLabelsChanged.filter': 'Bulk change of document labels',
  'MessageCenterPage.message.category.documentSubscriber': 'Tracked document change',
  'MessageCenterPage.message.category.documentSubscriber.filter': 'Tracked document change',
  'MessageCenterPage.message.category.directorySubscriber': 'Tracked directory change',
  'MessageCenterPage.message.category.directorySubscriber.filter': 'Tracked directory change',
  'MessageCenterPage.message.category.bulkActionReportNotification': 'Bulk action report',
  'MessageCenterPage.message.category.bulkActionReportNotification.filter': 'Bulk action report',
  'MessageCenterPage.message.category.projectAccountStateChange': 'Change of account status on the project',
  'MessageCenterPage.message.category.projectAccountStateChange.filter': 'Invitation to the project, activation and deactivation of the account',
  'MessageCenterPage.message.category.docReleaseReservationAlert': 'The end of the reservation of the document is approaching',
  'MessageCenterPage.message.category.docReservationReleased': 'The reservation of the document has been completed',
  'MessageCenterPage.message.category.docReleaseReservationAlert.filter': 'The end of the reservation of the document is approaching',
  'MessageCenterPage.message.category.docReservationReleased.filter': 'The reservation of the document has been completed',
  'MessageCenterPage.message.category.wfApprovalDocumentsShared.filter': 'Workflow approval documents shared',
  'MessageCenterPage.message.category.wfApprovalDocumentsShared': 'Workflow approval documents shared',
  'MessageCenterPage.message.category.wfDeadlineExtensionApprovalRequested': 'An extension of the deadline for the WF task has been requested',
  'MessageCenterPage.message.category.wfDeadlineExtensionRequestSent': 'The request to extend the task deadline has been sent',
  'MessageCenterPage.message.category.wfDeadlineExtensionApproved': 'The request to extend the deadline for the task was processed',
  'MessageCenterPage.message.category.wfDeadlineExtensionApprovalRequested.filter': 'An extension of the deadline for the WF task has been requested',
  'MessageCenterPage.message.category.wfDeadlineExtensionRequestSent.filter': 'The request to extend the task deadline has been sent',
  'MessageCenterPage.message.category.wfDeadlineExtensionApproved.filter': 'The request to extend the deadline for the task was processed',
  'MessageCenterPage.message.expireTime': 'Expire time: ',
  'MessageCenterPage.message.detail.assignment.title': 'Task Notification',
  'MessageCenterPage.message.detail.bulkChanges.title': 'Notification of mass document changes',
  'MessageCenterPage.message.detail.bulkChanges.BulkActionReportType.documentStatesChanged': 'Change document statuses',
  'MessageCenterPage.message.detail.bulkChanges.BulkActionReportType.documentLabelsAdded': 'Document labels added',
  'MessageCenterPage.message.detail.bulkChanges.BulkActionReportType.documentLabelsReplaced': 'Document labels replaced',
  'MessageCenterPage.message.detail.bulkChanges.BulkActionReportType.documentLabelsRemoved': 'Document labels removed',
  'MessageCenterPage.message.detail.bulkChanges.newState': 'New documents status',
  'MessageCenterPage.message.detail.bulkChanges.newLabels': 'New labels',
  'MessageCenterPage.message.detail.bulkChanges.changedDocuments': 'Changed documents',
  'MessageCenterPage.message.detail.bulkChanges.unchangedDocuments': 'Unchanged documents',
  'MessageCenterPage.message.detail.bulkChanges.ignoredDocuments': 'Ignored documents',
  'MessageCenterPage.message.detail.bulkChanges.originalState': 'Original state:',
  'MessageCenterPage.message.detail.commentProcedure.title': 'Comment Procedure Notification',
  'MessageCenterPage.message.detail.document.title': 'Notification of an event related to a document',
  'MessageCenterPage.message.detail.userNotification.title': 'User notification',
  'MessageCenterPage.message.detail.userNotification.type.workflow': 'Message from the workflow approval process task',
  'MessageCenterPage.message.detail.userNotification.type.task': 'Task name',
  'MessageCenterPage.message.detail.grid.taskDeadline': 'Task deadline',
  'MessageCenterPage.message.detail.grid.taskDeadline.task': 'Task deadline',
  'MessageCenterPage.message.detail.grid.useNotifiEntry': "User '{user}' sent you the following message:",
  'MessageCenterPage.message.detail.subscriber.documentTitle': 'Tracked document Notification',
  'MessageCenterPage.message.detail.subscriber.directoryTitle': 'Tracked directory Notification',
  'MessageCenterPage.message.detail.workflow.title': 'Workflow Approval Process Notification',
  'MessageCenterPage.message.detail.projectAccount.title': 'Invitation to the project, activation and deactivation of the account',
  'MessageCenterPage.message.detail.projectAccount.userAddedToOrganization': 'Uživatel {user} invited you to organization: {organization}.',
  'MessageCenterPage.message.detail.projectAccount.userAddedToProject': 'User {user} invited you to project: {project}.',
  'MessageCenterPage.message.detail.projectAccount.userActivated': 'User {user} aktivated your account on project {project}.',
  'MessageCenterPage.message.detail.projectAccount.userDeactivated': 'User {user} deaktivated your account on project {project}.',
  'MessageCenterPage.message.detail.error.viewer': 'This type of message is not supported',
  'MessageCenterPage.message.detail.grid.assignment': 'Task',
  'MessageCenterPage.message.detail.grid.assignmentContent': 'Task content',
  'MessageCenterPage.message.detail.grid.author': 'Author',
  'MessageCenterPage.message.detail.grid.completeTime': 'Completed time',
  'MessageCenterPage.message.detail.grid.deadlineTime': 'Deadline time',
  'MessageCenterPage.message.detail.grid.document': `{count, plural,
    one {Document}
    other {Documents}
  }`,
  'MessageCenterPage.message.detail.grid.organization': 'Organization',
  'MessageCenterPage.message.detail.grid.applicant': 'Applicant',
  'MessageCenterPage.message.detail.grid.approved': 'Approved',
  'MessageCenterPage.message.detail.grid.byDays': 'By days',
  'MessageCenterPage.message.detail.grid.requestJustification': 'Request justification',
  'MessageCenterPage.message.detail.grid.decisionJustification': 'Decision justification',
  'MessageCenterPage.message.detail.grid.project': 'Project',
  'MessageCenterPage.message.detail.grid.solvers': 'Solvers',
  'MessageCenterPage.message.detail.grid.workflow': 'Workflow',
  'MessageCenterPage.message.detail.grid.commentProcedure': 'Comment procedure',
  'MessageCenterPage.message.detail.grid.commentName': 'Comment',
  'MessageCenterPage.message.detail.grid.team': 'Team',
  'MessageCenterPage.message.detail.grid.user': 'User',
  'MessageCenterPage.message.detail.grid.task': 'Task',
  'MessageCenterPage.message.detail.grid.expireTime': 'Deadline',
  'MessageCenterPage.message.detail.grid.backgroundDocument': 'Background document',
  'MessageCenterPage.message.detail.grid.message': 'Message',
  'MessageCenterPage.message.detail.grid.subject': 'Message subject',
  'MessageCenterPage.message.detail.grid.startNodeMessages': 'Reasoning from the task',
  'MessageCenterPage.message.detail.grid.oldState': 'Old state',
  'MessageCenterPage.message.detail.grid.newState': 'New state',
  'MessageCenterPage.message.detail.grid.currentState': 'Actual state',
  'MessageCenterPage.message.detail.grid.noteText': 'Note text',
  'MessageCenterPage.message.detail.grid.shareFolder': 'Folder shared',
  'MessageCenterPage.message.detail.grid.shareFiles': 'Files shared',
  'MessageCenterPage.message.detail.grid.receiver': 'Receiver',
  'MessageCenterPage.message.detail.grid.receiverMessage': 'Message',
  'MessageCenterPage.message.detail.grid.removedDocument': `{count, plural,
    one {Removed document}
    other {Removed documents}
  }`,
  'MessageCenterPage.message.detail.grid.directoryName': 'Shared folder',
  'MessageCenterPage.message.detail.grid.documentNames': 'Shared documents',
  'MessageCenterPage.message.detail.grid.shareMessageFiles': 'User {user} shared link with you to documents ({documentCount}) in project {projectName}',
  'MessageCenterPage.message.detail.grid.shareMessageFolder': 'User {user} shared link with you to directory in project {projectName}',
  'MessageCenterPage.message.detail.grid.documentPath': 'Document path',
  'MessageCenterPage.message.detail.grid.expirationReservation': 'Expiration time of the maximum reservation time',
  'MessageCenterPage.message.status.new': 'Not read',
  'MessageCenterPage.message.status.read': 'Read',
  'MessageCenterPage.message.status.solved': 'Solved',
  'MessageCenterPage.message.status.finished': 'Finished',
  'MessageCenterPage.message.status.ignored': 'Muted',
  'MessageCenterPage.notification.messages': 'Messages',
  'MessageCenterPage.notification.tasks': 'Tasks',
  'MessageCenterPage.notification.settings': 'Notification settings',
  'MessageCenterPage.settings.menu.default': 'User default settings',
  'MessageCenterPage.settings.menu.default.tooltip': 'This setting is used for projects that do not have messaging options set separately.',
  'MessageCenterPage.settings.menu.project': 'Settings for project',
  'MessageCenterPage.settings.menu.project.tooltip': 'This setting customizes the sending of messages on the selected project. The selected options take precedence over the default settings.',
  'MessageCenterPage.settings.option.header.email': 'Email',
  'MessageCenterPage.settings.option.header.report': 'Report',
  'MessageCenterPage.settings.option.header.mute': 'Mute', 'MessageCenterPage.settings.option.header.restore': 'Restore default',
  'MessageCenterPage.settings.option.header.email.tooltip': 'Immediate sending of notifications by email.',
  'MessageCenterPage.settings.option.header.report.tooltip': 'Sending a daily report of all notifications in one email.',
  'MessageCenterPage.settings.option.header.mute.tooltip': 'Automatically marks selected notifications as read.',
  'MessageCenterPage.settings.option.header.restore.tooltip': 'Uses user default settings for the action.',
  'MessageCenterPage.settings.unmappedCategory.title': 'Other',
  'MessageCenterPage.settings.useDefaultSetting.button': 'Use default',
  'MessageCenterPage.settings.usingDefaultSetting.state': 'Using default',
  'MessageGrid.changestateButton.markAsRead':'Mark as read',
  'MessageGrid.changestateButton.markAsUnread':'Mark as unread',
  'MessageGrid.changestateButton.markAllAsRead':'Mark all as read',
  'modalFormConfirmClose.content': 'Changes you made may not be saved.',
  'modalFormConfirmClose.title': 'Do you really want to leave?',
  'ModelDetailPageLinks.docToDocLinks': 'Links to whole model',
  'ModelDetailPageLinks.documentList.button.addSelection.tooltip': 'Select elements of document',
  'ModelDetailPageLinks.bindings.elementsHeader': `{count, plural,
    one {Documents of selected element}
    other {Documents of # selected elements}
  }`,
  'ModelDetailPageLinks.bindings.noBindingsOnElements': 'Selected elements does not have any bindings',
  'ModelDetailPageLinks.bindings.return.tooltip': 'Return to document list',
  'ModelDetailPageLinks.bindings.missingElements': `{count, plural,
    =0 {All elements found}
    one {1 element was not found in current model}
    other {# elements were not found in current model}
  }`,
  'ModelDetailPage.goToModel': 'Go to model',
  'ModelDetailPage.linksClose': 'Hide links',
  'ModelDetailPage.linksOpen': 'Show links',
  'ModelDetailPage.urlParamParseFail': 'Unable to load model list from URL parameter.',
  'ModelDetailPage.viewByForgeError.message': 'Unable to show the model',
  'ModelDetailPage.viewByForgeError.description': 'Can\'t show the document as 3D model.',
  'ModelDetailPage.viewByForgeError.descriptionMultiple': 'Following documents cannot be shown as a 3D model:',
  'ModelDetailPage.viewByForgeError.pleaseWait':
    'If you have uploaded the model just now, then the file is probably still being prepared. Please try again later.',
  'ModelDetailPage.toolbar.addLinks': 'Add links',
  'ModelDetailPage.toolbar.createRevision': 'New revision',
  'ModelDetailPage.toolbar.linkElementToDocument': 'Link to document',
  'ModelDetailPage.toolbar.diff': 'Compare revisions',
  'ModelDetailPage.toolbar.removeModel': 'Remove model',
  'ModelDetailPage.toolbar.removeModelDiscard': 'Remove model and discard',
  'ModelDetailPage.toolbar.removeModelOnly': 'Remove model only',
  'ModelDetailPage.bindings.removeLink.tooltip': 'Remove model to document binding',
  'ModelDetailPage.removeSelectedBindings': 'Remove document bindings',
  'ModelDetailPage.removeSelectedBindings.tooltip': 'Remove selected document bindings',
  'ModelDetailPage.removeSelectedBindings.confirm': 'Are you sure you want to remove selected document bindings?',
  'ModelDetailPage.invalidElementName': 'Missing element name',
  'ModelPage.groupedModelPageTitle': 'Showing {count} grouped models',
  'ModelPage.loading': 'Loading models...',
  'ModelPage.newModelModalTitle': 'New model',
  'ModelPage.showModel': 'Show model',
  'ModelPage.showGroupedModel': 'Show grouped model',
  'ModelPage.removeFromModels': 'Remove from models',
  'ModelPage.toolbar.upload': 'Upload',
  'ModelsMoveFormModal.destinationDirectoryId.label': 'To folder',
  'ModelsMoveFormModal.errorsTitle.duplicate': `{count, plural,
    =0 {nothing duplicate}
    one {The following model with the same name already exists in destination folder}
    few {Following models with the same name already exist in destination folder}
    other {Following models with the same name already exist in destination folder}
  }`,
  'ModelsMoveFormModal.errorsTitle.missed': `{count, plural,
    =0 {nothing bad}
    one {An error occurred while moving. No models have been moved.}
    few {Errors occurred during move. No models have been moved.}
    other {Errors occurred during move. No models have been moved.}
  }`,
  'ModelsMoveFormModal.moveModels': `{count, plural,
    =0 {nothing for move}
    one {Move 1 model from folder}
    few {Move # models from folder}
    other {Move # models from folder}
  }`,
  'ModelProcessingExportFormModal.title': 'Export of model processing requirements for the selected period',
  'ModelProcessingExportFormModal.periodSelection': 'Period selection',
  'ModelProcessingExportFormModal.buttonTooltip': 'Exports for the period',
  'ModelBindingAddModal.form.documentSelect': 'Documents to link',
  'ModelBindingAddModal.form.documentSelect.modalTitle': 'Select documents to link',
  'ModelBindingAddModal.form.elements': 'Selected model elements',
  'ModelBindingAddModal.form.title': 'Create model links',
  'ModelBindingAddModal.title': 'Link model to documents',
  'ModelBindingAddModal.addBinding.success': 'Bindings to documents added successfully',
  'ModelBindingAddModal.addBinding.noDocuments': 'No documents selected',
  'ModelBindingList.warning.missingElement': 'The element for this binding was not found in the model',
  'ModelBindingList.documentList.header': 'Document bindings',
  'ModelBindingRemoveModal.title': 'Remove document bindings',
  'ModelBindingRemoveModal.submit': 'Remove',
  'ModelBindingRemoveModal.form.documentSelect': 'Select documents to remove',

  'ModelElementsExport.element': 'Element',
  'ModelElementsExport.categoryName': 'Category of attribute',
  'ModelElementsExport.attributeName': 'Attribute name',
  'ModelElementsExport.attributeValue': 'Attribute value',
  'ModelElementsExport.attributeUnits': 'Attribute units',

  'MultipleRolesListSelect.addManager': 'Add role',
  'MultipleRolesListSelect.removeManager': 'Remove role',
  'MultipleRolesListSelect.noAvailableRole': 'No role is available',
  'NoApplicableNewDocumentStateAlert.title': 'Unable to upload new document',
  'NoApplicableNewDocumentStateAlert.description': 'You do not have any applicable upload document enabled status set in your user profile on this project. Please contact the project administrator.',
  'NodeCommonInfo.deadlineExtension.requestButton':'Request an extension of the task deadline',
  'NodeCommonInfo.deadlineExtension.approveButton':'Decide on an extension of the task deadline',
  'NodePortActionTypeEnum.accept': 'Accept',
  'NodePortActionTypeEnum.accepted': 'Accepted',
  'NodePortActionTypeEnum.refuse': 'Refuse',
  'NodePortActionTypeEnum.refused': 'Refused',
  'NodePortActionTypeEnum.process': 'Process',
  'NodePortActionTypeEnum.processed': 'Processed',
  'NodeInPortCommonTitle': 'Input',
  'NotificationContext.updateMessage.messages': `{count, plural,
    one {# new message}
    other {# new messages}
  }`,
  'NotificationContext.updateMessage.newAssignments': `{count, plural,
    one {# new task}
    other {# new tasks}
  }`,
  'NotificationContext.updateMessage.updatedAssignments': `{count, plural,
    one {# updated task}
    other {# update tasks}
  }`,
  'NotificationContext.updateMessage.notification': `{count, plural,
    one {{message}}
    other {{message} and {lastMessage}}
  }`,
  'notifications.downloadError.description': 'File could not be downloaded.',
  'notifications.error.message': 'Oops! Something went wrong 💥',
  'notifications.reserveError.reserve.description': 'There was a problem with document reservation.',
  'notifications.reserveError.release.description': 'There was a problem with document reservation canceling.',
  'notifications.savingError.description': 'There was a problem while saving the data, please try again.',
  'NoticeList.daysLeft': 'Days left',
  'NoticeList.deadline': 'Deadline: ',
  'NoticeList.project': 'Project: ',
  'OrgLabelsList.deleteConfirmModal.description': `{projectCount, plural,
    one {Do you want to force label {label} deletion and remove it from {projectCount} project?}
    other {Do you want to force label {label} deletion and remove it from {projectCount} projects?}
  }`,
  'OrganizationActiveUsersReportModal.title':'Report of Active Users of the Organization',
  'OrganizationActiveUsersReportModal.orgName':'Organization: {orgName}',
  'OrganizationActiveUsersReportModal.reportingPeriod':'Reporting period',
  'OrganizationSettings.title': 'Organization settings',
  'OrganizationSettingsFormModal.title': 'Settings for {settingsName}',
  'OrganizationSettingsListPanel.report.mdOverheadProject.name': 'Md Overhead project',
  'OrganizationSettingsListPanel.report.mdOverheadProject.description': 'Connected AspeHub project',
  'OrganizationSettingsListPanel.report.projectsInRealizationPOH.name': 'Report overview of constructions in realization',
  'OrganizationSettingsListPanel.report.projectsInRealizationPOH.description': 'Background colors and default report date',
  'OrganizationUserPermissionProfiles.title': 'User permission profile',
  'OrganizationUserPermissionProfiles.modal.add.title': 'Add permission profile',
  'OrganizationUserPermissionProfiles.modal.edit.title': 'Edit permission profile',
  'OrganizationUsersEditFormModal.title': 'Edit user in organization',
  'OrganizationUserEditForm.nameSource': 'Source of name',
  'OrganizationUserEditForm.setByOrganization': 'Set by organization',
  'OrganizationUserEditForm.takeByUserSetting': 'Take from user settings',
  'OrganizationUserEditForm.firstNameLastName': 'First and last name',
  'OrganizationUserEditForm.namesDisplayValue': 'Display value',
  'OrganizationUserEditForm.jobPosition': 'Job position',
  'OrganizationUserEditForm.namesNotSetValue': 'The value of first and last name is not set',
  'OrganizationUserClaim.claimType.company': 'Company: ',
  'OrganizationUserClaim.claimType.phonenumber': 'Phone: ',
  'OrganizationUserSettingOnProjectsModal.title': 'Changing user settings on projects',
  'OrganizationUserSettingOnProjectsModal.noSuspendReason.Title': 'The delete/deactivate action failed',
  'OrganizationUserSettingOnProjectsModal.noSuspendReason.projectName': 'Name of project',
  'OrganizationUserSettingOnProjectsModal.noSuspendReason.reason': 'Reason for failure',
  'OrganizationUserSettingOnProjectsModal.noSuspendReason.NotFound': 'User not found',
  'OrganizationUserSettingOnProjectsModal.noSuspendReason.IsLastAdmin': 'User is last admin on the project',
  'OrganizationUserSettingOnProjectsModal.noSuspendReason.InvalidOperation': 'Invalid operation',
  'OrganizationUserSettingOnProjectsModal.usersActiveProjects': 'Projects assigned to the user',
  'OrganizationUserSettingOnProjectsModal.usersNewProjects': 'Projects where the user does not appear',
  'OrganizationUserSettingOnProjectsModal.DeleteDeactivateButtonText': 'Remove/Deactivate',
  'OrganizationUserSettingOnProjectsModal.AddButtonText': 'Add to project',
  'OrganizationUserSettingPermissionPanel.Title': 'Permissions',
  'OrganizationUserSettingPermissionPanel.permissionCategoryType.reports': 'Reports',
  'OrganizationUserSettingPermissionPanel.permissionCategoryType.organizationOverview': 'Organization overview',
  'OrganizationUserSettingPermissionPanel.permissionType.financialOverviewByUnit': 'Financial overview of AspeEsticon projects by units',
  'OrganizationUserSettingPermissionPanel.permissionType.projectRepHubCostObjectSummary': 'Financial recapitulation of the construction',
  'OrganizationUserSettingPermissionPanel.permissionType.projectsInRealisationOverview': 'Overview or constructions in realization',
  'OrganizationUserSettingPermissionPanel.permissionType.projectsInRealisationOverviewAdmin': 'Notes administration for overview or constructions in realization',
  'OrganizationUserSettingPermissionPanel.permissionType.projectsInRealisationShareWidget': 'Shared widgets manager',
  'OrganizationUserSettingPermissionPanel.permissionType.assignmentsOverview': 'Show all assignments',
  'OrgProjectCardData.title': 'Project supplement data',
  'OrgProjectCardData.units': 'Units',
  'OrgProjectCardData.addSupplementData': 'Add supplement data',
  'OrgProjectCardData.editSupplementData': 'Edit supplement data',
  'OrgProjectCardData.delete.confirmed': 'Suplement data removed successfully',
  'OrgProjectCardData.delete.confirmTitle': 'Remove supplement data',
  'OrgProjectCardData.delete.confirmDelete': 'Supplement data will ve removed from all projects. Do you wish to continue?',
  'OrgProjectCardData.form.rules.name.required': 'Supplement data name is required',
  'OrgProjectCardData.form.rules.name.maxLength': 'Maximum length of supplement data name is {max} characters',
  'OrgProjectCardData.form.rules.variableType': 'Value type',
  'OrgProjectCardData.form.rules.variableType.required': 'Variable type is required',
  'OrgProjectCardData.form.rules.defaultValue': 'Default value',
  'ProjectCardData.form.rules.value': 'Value',
  'Panel.addCategory.tooltip': 'Add category',
  'Panel.addCategoryNode.tooltip': 'Add a node',
  'Panel.addGroup.tooltip': 'Add group',
  'Panel.addGroupUser.tooltip': 'Add users',
  'Panel.AddLabel.tooltip': 'Add label',
  'Panel.AddRole.tooltip': 'Add role',
  'Panel.addUser.tooltip': 'Add user',
  'Panel.addUserToTeam.tooltip': 'Add a user to the team',
  'RolePanel.Modal.CannotRemove.title': 'Cannot delete role',
  'Popconfirm.deleteItem': 'Are you sure you want to delete this item?',
  'PrimaryFileInput.collapse.hint': 'Click or drag file to this area',
  'PrimaryFileInput.collapse.text': 'Select a Primary File',
  'CommonFilesInput.remove': 'Remove from list',
  'CommonFilesInput.recordVideo': 'Record video',
  'CommonFilesInput.recordAudio': 'Record audio',
  'CommonFilesInput.takePicture': 'Take a picture',
  'Capture.record.delete': 'Delete record',
  'Capture.record.confirm': 'Confirm',
  'CommonFilesInput.record.filenamePrefix': 'Record',
  'CommonFilesInput.hint': 'Click or drag files or folders to this area.',
  'CommonFilesInput.invalidNamesByMasks.label': 'Some files and folders have names that do not match the masks set on the folder',
  'CommonFilesInput.statistics.withErrors.tooltip': 'Folders and files with errors:',
  'CommonFilesInput.statistics.duplicateDocuments.tooltip': 'Duplicate documents:',
  'CommonFilesInput.statistics.duplicateFolders.tooltip': 'Duplicate folders:',
  'CommonFilesInput.statistics.newRevisions.tooltip': 'New Revisions:',
  'CommonFilesInput.statistics.invalidRevisions.tooltip': 'Can\'t create revisions:',
  'CommonFilesInput.button.remove': 'Remove all from list',
  'CommonFilesInput.button.include': 'Include all into selection',
  'CommonFilesInput.button.exclude': 'Exclude all from selection',
  'CommonFilesInput.button.excludeInvalidNames': 'Exclude all with invalid names',
  'CommonFilesInput.button.excludeWithoutAccess': 'Exclude all without writable permission',
  'CommonFilesInput.button.removeInvalidNames': 'Remove all with invalid names',
  'CommonFilesInput.button.removeWithoutAccess': 'Remove all without writable permission',
  'CommonFilesInput.button.uploadAllAsNewDocuments': 'Upload all as new documents',
  'CommonFilesInput.button.uploadAllAsRevisions': 'Upload all as revisions',
  'CommonFilesInput.button.uploadAsNewDocument': 'Upload as new document',
  'CommonFilesInput.button.uploadAsRevision': 'Upload as revision',
  'CommonFilesInput.duplicateFolders.buttonLabel': 'Remove duplicate folders.',
  'CommonFilesInput.duplicateFolders.text': 'Some folders already exist and will be merged automatically.',
  'CommonFilesInput.duplicateFolders.tooltip': 'A folder with this name already exists.',
  'CommonFilesInput.duplicateDocuments.buttonLabel': 'Remove duplicate files.',
  'CommonFilesInput.duplicateDocuments.text': 'Some files already exist and will be uploaded as new documents and automatically renamed.',
  'CommonFilesInput.duplicateDocuments.tooltip': 'A document with this name already exists.',
  'CommonFilesInput.revision.tooltip': 'Document will be uploaded as revision.',
  'CommonFilesInput.notWritableDocument.tooltip': 'You do not have permission to create this file.',
  'CommonFilesInput.notWritableDirectory.tooltip': 'You do not have permission to create this folder.',
  'CommonFilesInput.invalidDirectoryName.tooltip': 'Directory name is invalid.',
  'CommonFilesInput.invalidDocumentName.tooltip': 'File name is invalid.',
  'CommonFilesInput.invalidNameByMask.tooltip': 'File name does not match the mask set on the folder.',
  'CommonFilesInput.notWritable.buttonLabel': 'Remove un-writable files and folders.',
  'CommonFilesInput.notWritable.text': 'You do not have the necessary permissions to create some folders and files. Change the permissions on their parent folder, or remove them from the upload list.',
  'CommonFilesInput.invalidName.text': 'Some folders or files have invalid name. Change folder and file names and upload them again, or remove them from the upload list.',
  'CommonFilesInput.invalidName.buttonLabel': 'Remove folders and files with invalid name.',
  'CommonFilesInput.blockedRevisions.text': 'Some revisions cannot be created. Change them to new document, or remove them from the upload list.',
  'CommonFilesInput.blockedRevisions.buttonLabel': 'Remove blocked revisions.',
  'CommonFilesInput.checkLoading.text': 'Validating files and folders.',
  'CommonFilesInput.treeLoading.text': 'Analyzing files and folders.',
  'CommonFilesInput.treeLoading.error': 'The folder structure cannot be fully loaded. The path is too long.',
  'CommonFilesInput.treeLoading.error.help': 'To upload files and folders, try moving the files on your computer to another folder, rename folders and files with long names (use shorter names), or try uploading files separately.',
  'CommonFilesInput.treeLoading.itemErrorInfo': 'The contents of this folder failed to load completely because the path to the files on your device is too long. Some files and folders are missing.',
  'CommonFilesInput.treeLoading.fileErrorInfo': 'File ({name}) failed to load because the path on your device is too long.',
  'CommonFilesInput.treeLoading.folderErrorInfo': 'The contents of folder could not be loaded because the path on your device is too long.',
  'CommonFilesInput.treeLoading.error.listTitle': 'Missing files and folders',
  'CommonFilesInput.treeLoading.firefoxWarning': 'Firefox does not warn you if paths of files or folders are too long and some are missing. Please check numbers of files and folders added for upload.',
  'CommonFilesInput.treeLoading.pathTooLongWarning': 'The entire folder structure may not have been read. Some file path may be too long and the file was not detected. Check the number of detected and uploaded files or folders and shorten their names if necessary.',
  'CommonFilesInput.treeItemMenu.duplicateFilesAsRevisions': 'Duplicate files as revisions',
  'CommonFilesInput.treeItemMenu.duplicateFilesAsNew': 'Duplicate files as new',
  'CommonFilesInput.treeItemMenu.includeDuplicateFolders': 'Include duplicate folders',
  'CommonFilesInput.treeItemMenu.excludeDuplicateFolders': 'Exclude duplicate folders',
  'CommonFilesInput.treeItemMenu.includeDuplicateFiles': 'Include duplicate files',
  'CommonFilesInput.treeItemMenu.excludeDuplicateFiles': 'Exclude duplicate files',
  'CommonFilesInput.treeItemMenu.excludeAllNotWritable': 'Exclude all without writable access rights',
  'CommonFilesInput.treeItemMenu.excludeAllInvalid': 'Exclude all invalid',
  'SingleFileInput.collapse.hint': 'Click or drag file to this area',
  'SingleFileInput.collapse.text': 'Select a Single File',
  'paramEncode.error.urlParse': 'Unable to decode URL parameter.',
  'paramEncode.error.jsonParse': 'Failed to decode JSON object.',
  'paramEncode.error.checkFormat': 'Parsed value does not match the expected format.',
  'ProjectCreateForm.form.items.description.placeholder': 'Project description...',
  'ProjectCreateForm.form.items.language.cs': 'Czech',
  'ProjectCreateForm.form.items.language.en': 'English',
  'ProjectCreateForm.form.items.language.label': 'Language',
  'ProjectCreateForm.form.items.language.tooltip': 'Main language of the project. Means what is the primary language of documents.',
  'ProjectCreateForm.form.items.name.label': 'Name',
  'ProjectCreateForm.form.items.name.placeholder': 'D1 Prague - Brno',
  'Project.name.empty': 'Project name cannot be empty',
  'ProjectCreateForm.form.items.name.rules.required': 'Please enter a name of the project',
  'ProjectCreateForm.form.tabs.newProject': 'From user entry',
  'ProjectCreateForm.form.tabs.fromTemplate': 'From template',
  'ProjectCreateForm.form.template': 'Template',
  'ProjectCreateForm.form.timeZone': 'Time zone',
  'ProjectCreateForm.form.timeZone.rules.required': 'Please enter time zone',
  'ProjectCreateForm.form.template.rules.required': 'Please select project template',
  'ProjectCreateForm.form.template.rules.storageAreaValidation': 'The template does not have a branch filled in, choose another project template',
  'ProjectCreateFormModal.button.create': 'Create Project',
  'ProjectCreateFormModal.infoText.common': 'The project is now being created.',
  'ProjectCreateFormModal.infoText.youWillSeeProject': 'The project is now being created. After it is created, it will be available in the projects module.',
  'ProjectUserAddForm.addToGroups': 'Add users to groups',
  'Esticon.enums.phase.Priprava': 'Preparation',
  'Esticon.enums.phase.Nabidka': 'Proposition',
  'Esticon.enums.phase.Realizace': 'Realization',
  'Esticon.enums.phase.Dokonceno': 'Finished',
  'Esticon.enums.phase.Neziskano': 'Not obtained',
  'ProjectDashboard.Reports.notAvailableError': 'Report is not available',
  'ProjectDashboard.Reports.removeFromDashboard': 'Remove report',
  'ProjectDashboard.Reports.copyReportWidget': 'Create copy',
  'ProjectDashboard.Reports.esticonForbidden': 'You don\'t have permission to view construction data',
  'ProjectDashboard.Reports.title.documentOverview': 'Document overview',
  'ProjectDashboard.Reports.title.financialPlan': 'Financial plan/reality',
  'ProjectDashboard.Reports.title.projectOverview': 'Project overview',
  'ProjectDashboard.Reports.title.workflowTasks': 'Workflow',
  'ProjectDashboard.Reports.title.withdrawOverview': 'Drawing overview',
  'ProjectDashboard.Reports.title.drawingActualOverview': `Financial overview of the construction`,
  'ProjectDashboard.Reports.AddForm.report.label': 'Report',
  'ProjectDashboard.Reports.AddForm.report.rules.required': 'Please select the report which you want to add',
  'ProjectDashboard.Reports.AddForm.submit': 'Add',
  'ProjectDashboard.Reports.AddForm.title': 'Add report',
  'ProjectDashboard.Reports.DocumentOverview.author': 'Mine',
  'ProjectDashboard.Reports.DocumentOverview.mineCreated': 'Uploaded by me',
  'ProjectDashboard.Reports.DocumentOverview.admin': 'Admin',
  'ProjectDashboard.Reports.DocumentOverview.other': 'Other',
  'ProjectDashboard.Reports.ExportDate': 'Valid as of: ',
  'ProjectDashboard.Reports.Export.jpg': 'Export to jpg',
  'ProjectDashboard.Reports.Export.png': 'Export to png',
  'ProjectDashboard.Reports.Export.svg': 'Export to svg',
  'ProjectDashboard.Reports.Export.pdf': 'Export to pdf',
  'ProjectDashboard.Reports.Export.xlsx': 'Export to excel',
  'ProjectDashboard.Reports.FinancialPlan.invoiced': 'Invoiced',
  'ProjectDashboard.Reports.FinancialPlan.plan': 'Plan',
  'ProjectDashboard.Reports.FinancialPlan.remaining': 'Remaining',
  'ProjectDashboard.Reports.FinancialPlan.outOfPlan': 'out of plan',
  'ProjectDashboard.Reports.FinancialPlan.outOfTotal': 'out of total',
  'ProjectDashboard.Reports.Menu.addReport': 'Add report',
  'ProjectDashboard.Reports.Menu.remove': 'Remove report',
  'ProjectDashboard.Reports.Menu.moveDown': 'Enlarge',
  'ProjectDashboard.Reports.Menu.moveUp': 'Shrink',
  'ProjectDashboard.Reports.Menu.maximize': 'Maximize',
  'ProjectDashboard.Reports.Menu.minimize': 'Minimize',
  'ProjectDashboard.Reports.Menu.selectFromTo': 'Select from - to',
  'ProjectDashboard.Reports.Menu.showDetail': 'Show detail',
  'ProjectDashboard.Reports.timePeriod.Months': 'Monthly view',
  'ProjectDashboard.Reports.timePeriod.Years': 'Yearly view',
  'ProjectDashboard.Reports.Menu.showDateModal': 'Select date from/to',
  'ProjectDashboard.Reports.ProjectOverview.numberOfDocuments': 'No. of documents',
  'ProjectDashboard.Reports.ProjectOverview.numberOfUsers': 'Number of users',
  'ProjectDashboard.Reports.ProjectOverview.loggedInToday': 'Logged in today',
  'ProjectDashboard.Reports.ProjectOverview.projectDate': 'Project duration',
  'ProjectDashboard.Reports.ProjectOverview.projectPhase': 'Project phase',
  'ProjectDashboard.Reports.ProjectOverview.recapitulation': 'Recapitulation',
  'ProjectDashboard.Reports.ProjectOverview.ZBV': 'Change order',
  'ProjectDashboard.Reports.ProjectOverview.ZBV.Tooltip': 'Change order total in {currency} without VAT',
  'ProjectDashboard.Reports.ProjectOverview.currentPrice': 'Current price',
  'ProjectDashboard.Reports.ProjectOverview.currentPrice.Tooltip': 'Total price in {currency} without VAT',
  'ProjectDashboard.Reports.ProjectOverview.ZP': 'Billed',
  'ProjectDashboard.Reports.ProjectOverview.ZP.Tooltip': 'Work in progress reports, realized total in {currency} without VAT',
  'ProjectDashboard.Reports.WorkflowTasks.finished': 'Finished',
  'ProjectDashboard.Reports.WorkflowTasks.active': 'Active',
  'ProjectDashboard.Reports.projectDocumentationStatistics.title': 'Project overview by documentation level',
  'ProjectDashboard.Reports.userNotificationStatistics.title': 'User notifications',
  'ProjectDashboard.Reports.userActiveAssignmentStatistics.title': 'Přehled projektů dle stupně dokumentace',

  'ProjectDashboard.ProjectCard.title':"Project card",
  'ProjectDashboard.ProjectCard.marking':"Designation",
  'ProjectDashboard.ProjectCard.documentationLevel':"Level of documentation",
  'ProjectDashboard.ProjectCard.duration':"Duration from - to",
  'ProjectDashboard.ProjectCard.noData': "No project data available",
  'ProjectDashboard.ProjectCard.editButtonLabel': "Edit project data",
  'ProjectDashboard.ProjectAgenda.title':"Agenda",
  'ProjectDashboard.ProjectAdditionalData.title':"Additional data",
  'ProjectDashboard.ProjectAdditionalData.noData': "Additional project data is not available",
  'ProjectDashboard.ProjectAdditionalData.editButtonLabel': "Edit additional project data",

  'ProjectInvitationAcceptPage.goToProject': 'Go to project',
  'ProjectInvitationAcceptPage.acceptAllTermsAndConditions.invitation': 'With this invitation you have access to a new project: ',
  'ProjectInvitationAcceptPage.acceptAllTermsAndConditions.mainInvitation': 'You have been invited to the project',
  'ProjectInvitationAcceptPage.acceptAllTermsAndConditions.invitationNotFound': 'Invitation to the project was not found.',
  'ProjectInvitationAcceptPage.acceptAllTermsAndConditions.invitationError': 'Error loading the invitation.',
  'ProjectInvitationAcceptPage.invitationsDeprecated':
    'This method of invitation is discontinued. Users are now invited to projects by the organisation to which the project belongs. If you have not yet activated your account, please contact organization administrator to resend confirmation email.',
  'ProjectTemplateSelectSourceProject.title': 'Create template from project',
  'ProjectTemplateSelectSourceProjectModal.projectSelect': 'Select project',
  'ProjectTemplateSelectSourceProjectModal.error.projectMissing': 'Project is not selected',
  'ProjectTemplateSelectSourceProjectModal.form.tryPairing': 'Pair user permissions',
  'ProjectTemplateSelectSourceProjectModal.form.tryPairing.tooltip': 'Matches user permissions to User Permission Profiles per organization if the settings match.',
  'ProjectUsersEditModal.title': 'Changing project user settings',
  'ProjectUsersEditModal.active': 'Active',
  'ProjectUsersEditModal.deactive': 'Deactive',
  'ProjectSearchPage.detailMode': 'Detail mode',
  'ProjectSearchPage.foundEntries': `{total, plural,
    =0 {no entries found}
    one {found one entry}
    other {found # entries}
  } in {took} ms, showing {showing}`,
  'ProjectsInRealisationOverviewReportPage.menu.editViews.tooltip': 'Edit saved views',
  'ProjectsInRealisationOverviewReportPage.menu.override.tooltip': 'Override current view',
  'ProjectsInRealisationOverviewReportPage.menu.repairNotes.tooltip': 'Fix unpaired notes',
  'ProjectsInRealisationOverviewReportPage.menu.addWidget.tooltip': 'Create widget on dashboard from viewed report',
  'ProjectsInRealisationOverviewReportPage.menu.manageSharedWidgets.tooltip': 'Manage shared widgets',
  'ProjectSearchPage.loadMore': 'Load {count} more',
  'ProjectTemplates.title': 'Project templates',
  'ProjectTemplates.list.createProjectTemplate': 'Create template',
  'ProjectTemplates.list.editProjectTemplate': 'Edit template',
  'ProjectTemplates.list.deleteTemplate.confirmation': 'Really delete template?',
  'ProjectTemplates.createModal.confirm': 'Save template',
  'ProjectTemplates.createModal.title': 'New project template',
  'ProjectTemplates.saveMessage.success': 'Project template saved successfully',
  'ProjectTemplates.general.permissionTemplates.title': 'Permission templates',
  'ProjectTemplateBuilder.warning.tooltip.general': 'Required general settings items are not set in the template.',
  'ProjectTemplateBuilder.warning.tooltip.groups.missingAdmin': 'The administrator group must contain at least one user.',
  'ProjectTemplateBuilder.warning.tooltip.groups.tooMany': 'Too many groups created.',
  'ProjectTemplateBuilder.warning.tooltip.users': 'Template doesn\'t have any users',
  'ProjectTemplateBuilder.workflow.disabled.tooltip.notSaved': 'Project template is not saved to server for pairing checks in workflow template',
  'ProjectTemplateBuilder.workflow.disabled.tooltip.hasChanges': 'Project template has unsaved changes with potentialy breaking pairing for workflow template',
  'ProjectTemplateBuilder.workflow.disabled.tooltip.noUsers': 'Project template doesn\'t have any users',
  'ProjectTemplateBuilder.workflow.disabled.tooltip.noRoles': 'Project template doesn\'t have any roles',
  'ProjectTemplateDirectoriesTab.delete.confirmNotEmpty': 'Selected directory is not empty. Do you really want to delete the directory with all subfolders?',
  'ProjectTemplateDirectoriesTab.move.duplicateNameError': 'Directory with same name already exists in target directory.',
  'ProjectTemplateDirectoriesTab.noDirsWarning': 'No folder was selected/inserted',
  'ProjectTemplateDirectoriesTab.dropzoneText': 'Only the folder structure will be saved',
  'ProjectTemplateDirectoriesTab.dropzone.treeLoading.error': 'The entire folder structure could not be loaded. Because the path to the folders on your device is too long, some folders could not be loaded.',
  'ProjectTemplateDirectoriesTab.dropzone.treeLoading.error.help': 'To upload folders, try renaming folders with long names on your computer (use shorter names), or try uploading folders separately.',
  'ProjectTemplateDirectoriesTab.dropzone.treeLoading.error.listTitle': 'Missing folders',
  'ProjectTemplateCategoryFormModal.add.title': 'Create category',
  'ProjectTemplateCategoryFormModal.edit.title': 'Edit category',
  'ProjectTemplateCategoryNodeFormModal.add.title': 'Create node',
  'ProjectTemplateCategoryNodeFormModal.edit.title': 'Edit node',
  'ProjectTemplateDirectoryFormModal.add.title': 'Create directory',
  'ProjectTemplateDirectoryFormModal.edit.title': 'Edit directory',
  'ProjectTemplateDirectoryCategoryForm.category.required': 'Please select category',
  'ProjectTemplateDirectoryCategoryForm.categoryNode.required': 'Please select node',
  'ProjectTemplateDirectoryCategoryForm.node': 'Node',
  'ProjectTemplateDirectoryCategoryFormModal.add.title': 'Add category',
  'ProjectTemplateDirectoryCategoryFormModal.edit.title': 'Edit category',
  'ProjectTemplateDirectoryDetail.noCategories': 'No categories',
  'ProjectTemplateDirectoryDetail.button.addCategory': 'Add category',
  'ProjectTemplateGeneralTab.templateName.title': 'Template name',
  'ProjectTemplateGeneralTab.templateDescription.title': 'Template description',
  'ProjectTemplateGeneralTab.directory.metadataSetting.title': 'Folders metadata definition',
  'ProjectTemplateGeneralTab.document.metadataSetting.title': 'Documents metadata definition',
  'ProjectTemplateMetadataDefinitionsSettingModal.variable.boolean':'YES-NO',
  'ProjectTemplateMetadataDefinitionsSettingModal.variable.string':'text',
  'ProjectTemplateMetadataDefinitionsSettingModal.variable.number':'number',
  'ProjectTemplateMetadataDefinitionsSettingModal.variable.date':'date',
  'ProjectTemplateMetadataDefinitionsSettingModal.variable.orgStructureWithUser':'organization structure with user',
  'ProjectTemplateMetadataDefinitionsSettingModal.inputTitle':'Name',
  'ProjectTemplateMetadataDefinitionsSettingModal.selectTitle':'Type',
  'ProjectTemplateMetadataDefinitionsSettingModal.metadataValues.title': 'Metadata values',
  'ProjectTemplateMetadataSettingModal.title': "Metadata values setting",
  'ProjectTemplateGroupFormModal.add.title': 'Create group',
  'ProjectTemplateGroupFormModal.edit.title': 'Edit group',
  'ProjectTemplateLabelFormModal.add.title': 'Create label',
  'ProjectTemplateLabelFormModal.edit.title': 'Edit label',
  'ProjectTemplateRoleFormModal.add.title': 'Create role',
  'ProjectTemplateRoleFormModal.edit.title': 'Edit role',
  'ProjectTemplateRoleTab.warning.emptyRole': 'User is missing in role',
  'ProjectTemplatePermissionPresetFormModal.title': 'New profile',
  'ProjectTemplateWorkflowTemplateAddFormModal.title': 'New workflow template',
  'ProjectTemplateWorkflowTemplateAddFormModal.workflowTemplate': 'Workflow template',
  'ProjectTemplateWorkflowTemplateAddFormModal.roles': 'Role {name}',
  'ProjectTemplateWorkflowTemplateAddFormModal.users': 'User {name}',
  'ProjectTemplateWorkflowTemplateAddFormModal.directory': 'Directory {name}',
  'ProjectTemplateWorkflowTemplateAddFormModal.directory.empty': 'Not set in template',
  'ProjectTemplate.WorkflowTemplateTab.title': 'Workflow templates',
  'ProjectTemplate.WorkflowTemplateTab.button.add': 'Add workflow template',
  'ReinviteButton.reinvite': 'Invite again',
  'ReinviteButton.reinvite.tooltip': 'Resend the activation email',
  'ReinviteButton.reinvited': 'Already invited again',
  'Reporting.list.title': 'Reports',
  'Reporting.reportCategory.name.General': 'General reports',
  'Reporting.reportType.name.financialOverviewByUnit': 'Financial overview of AspeEsticon projects by unit',
  'Reporting.reportType.name.projectRepHubCostObjectSummary': 'Financial recapitulation of the construction',
  'Reporting.reportType.name.projectsInRealisationOverview': 'Overview of constructions in realisation',
  'Reporting.reportType.name.projectsInRealisationOverviewAdmin': 'Note administration on overview of constructions in realisation',
  'Reporting.reportType.name.projectsInRealisationShareWidget': 'Shared widgets manager',
  'Reporting.reportType.name.mdApprovedProjects': 'Overview of approved projects',
  'Reporting.reportType.name.assignmentsOverview': 'Overview of all assignments',
  'SearchPageHelp.searchHelp': 'Search help',
  'SearchPageHelp.basicSearch': 'Documents are searched based on word root, document must contain at least one word.',
  'SearchPageHelp.wholeWords': 'To search an <strong>entire phrase</strong>, put it into quotes.'
    + ' Example: <span class="primary">"construction element"</span>.',
  'SearchPageHelp.mustHave': 'Symbol plus (+) before an expression means that it <strong>must</strong> be included.'
    + ' Example: <span class="primary">+"construction element"</span>.',
  'SearchPageHelp.mustNotHave': 'Symbol minus (-) before an expression means that it <strong>must not</strong> be included.'
    + ' Example: <span class="primary">-construction</span>.',
  'SearchPageInput.placeholder': 'Input search query',
  'SearchPageSettings.searchSettings': 'Search settings',
  'SearchPageSettings.displayConfig': 'Display settings',
  'SearchPageSettings.filterSettings': 'Search settings',
  'SearchPageSettings.showDetail': 'Show detail',
  'SearchPageSettings.detailSize': 'Preview size',
  'SearchPageSettings.searchInName': 'Search in document name',
  'SearchPageSettings.searchInDescription': 'Search in document description',
  'SearchPageSettings.searchInRevisionNote': 'Search in revision comment',
  'SearchPageSettings.searchInContent': 'Search in document content',
  'SearchPageSettings.searchInMetadata': 'Search in document metadata',
  'SearchPageSettings.currentRevisionOnly': 'Show only current revisions',
  'SearchPageSettings.allMetadataParemetersTypes': 'All types of metadata',
  'SearchPageSettings.unknownMetadataParemeterType': 'The previously set search parameter {unknownParameter} was renamed or deleted in the metadata definition. Search results may not be valid.',
  'SearchPageSettings.noMetadataDefinitions':'Metadata not defined',
  'SearchPageSettings.directoryId': 'Search in folder:',
  'SearchPageSettings.includeSubdirectories': 'Include subfolders',
  'SearchResultRow.documentDescription': 'Document description',
  'SearchResultRow.revisionDescription': 'Revision comment',
  'SearchResultRow.metadataKey': 'Metadata key',
  'SearchResultRow.metadataValue': 'Metadata value',
  'SearchResultRow.metadataSource': 'Metadata source',
  'OrganizationAddForm.title': 'Name',
  'OrganizationAddForm.CIN': 'CIN',
  'OrganizationAddForm.title.placeholder': 'Name of organization...',
  'OrganizationAddForm.description.placeholder': 'Description of organization...',
  'OrganizationAddForm.description': 'Description',
  'OrganizationAddForm.language': 'Language',
  'OrganizationAddForm.language.tooltip': 'Main language of the organization. Means what is the primary language of documents.',
  'OrganizationAddForm.CinWarning.tooltip': 'The entered value is not in accordance with the standard of the Information System of the Public Administration of the Czech Republic. If you are entering a subject identifier from another country, ignore this warning',
  'OrganizationAddForm.timeZone': 'Time zone',
  'OrganizationAddForm.addUsersAlsoWithInvitation': 'The project administrator can add users from AspeHub, even by email invitation',
  'OrganizationAddForm.required': 'It is necessary to select at least one user as admin',
  'OrganizationList.organizationByUserTitle': 'Organizations to which the selected user belongs',
  'OrganizationDetail.forgeText': 'Number of submitted requests: {count}',
  'OrganizationDetail.forgeTooltip': 'The number of submitted model processing requests per organization',
  'OrganizationGeneralTab.title': 'Project administrator rights to add a user',
  'OrganizationGeneralTab.description': 'If the option is enabled, project administrators can add a new user by email invitation. This will add a new user to AspeHub. \nIf the option is disabled, only existing users in the organization can be added to the project.',
  'OrganizationUsersEditModal.title': 'Change user settings per organization',
  'ProjectSettingsPageGeneral.esticonId.placeholder': 'No AspeEsticon project is connected. Attention: the connected project cannot be changed later!',
  'ProjectSettingsPageGeneral.name': 'Project Name',
  'ProjectSettingsPageGeneral.description': 'Project description',
  'ProjectSettingsPageGeneral.nameError': 'The project name is to long. The maximum name length is {max} characters.',
  'ProjectSettingsPageGeneral.imports': 'Imports',
  'ProjectSettingsPageGeneral.importConnectedERPData': 'Import ERP data from connected project',
  'ProjectSettingsPageGeneral.exports': 'Exports',
  'ProjectSettingsPageGeneral.exportUsersAndProjectSetting': 'Export users and project settings',
  'ProjectSettingsPage.ExternApps.addApplication': 'Add application',
  'ProjectSettingsPage.ExternApps.pageTitle': 'Connected external applications',
  'ProjectSettingsPage.ExternApps.selectAppTitle': 'Choose an application',
  'ProjectSettingsPage.ExternApps.Esticon.addButton': 'AspeEsticon',
  'ProjectSettingsPage.ExternApps.Esticon.addTitle': 'Attach AspeEsticon project',
  'ProjectSettingsPage.ExternApps.Esticon.ProjectList.serverError': 'Database connection failed. Please contact AspeHub admin',
  'ProjectSettingsPage.ExternApps.Esticon.Form.Database': 'Database',
  'ProjectSettingsPage.ExternApps.Esticon.Form.esticonApp': 'AspeEsticon application',
  'ProjectSettingsPage.ExternApps.Esticon.Form.Project': 'Project',
  'ProjectSettingsPage.ExternApps.Esticon.listItemTitle': 'AspeEsticon',
  'ProjectSettingsPage.ExternApps.Esticon.loadError': 'There was an error when loading data from AspeEsticon',
  'ProjectSettingsPage.ExternApps.Esticon.projectMissing': 'AspeEsticon project was not found',
  'ProjectSettingsPage.ExternApps.Esticon.projectNotSet': 'AspeEsticon project is not set',
  'ProjectSettingsPage.ExternApps.Esticon.firm': 'Firm database',
  'ProjectSettingsPage.ExternApps.Esticon.projectSign': 'Project sign',
  'ProjectSettingsPage.ExternApps.Esticon.projectName': 'Project name',
  'ProjectSettingsPage.ExternApps.Esticon.projectId': 'Project ID',
  'ProjectSettingsPage.ExternApps.Esticon.currency': 'Currency',
  'ProjectSettingsPage.ExternApps.Esticon.folderHelp': 'Start typing < to display available tags. The tags will be replaced by ' +
    'values from the imported object in the folder or document name.' +
    ' The month and year are taken from the date of issue.' +
    ' Object path mask content will be applied to each created directory name',
  'ProjectSettingsPage.ExternApps.Esticon.folderHelp.invoices': 'Start typing < to display available tags. The tags will be replaced by ' +
    'values from the imported object in the folder or document name.' +
    ' The month and year are taken from the billing period date.',
  'ProjectSettingsPage.ExternApps.Esticon.folder.Stavba': 'Folder for budget import',
  'ProjectSettingsPage.ExternApps.Esticon.folder.Cerpani': 'Folder for detection protocol import',
  'ProjectSettingsPage.ExternApps.Esticon.folder.CerpaniSdruzeni': 'Folder for grouped detection protocol import',
  'ProjectSettingsPage.ExternApps.Esticon.folder.Fakturace': 'Folder for invoice import',
  'ProjectSettingsPage.ExternApps.Esticon.folder.FakturaSdruzeni': 'Folder for grouped invoice import',
  'ProjectSettingsPage.ExternApps.Esticon.folder.ZBV': 'Folder for ZBV import',
  'ProjectSettingsPage.ExternApps.Esticon.folder.Harmonogram': 'Folder for schedule import',
  'ProjectSettingsPage.ExternApps.ConstructionJournal.addButton': 'Construction journal',
  'ProjectSettingsPage.ExternApps.ConstructionJournal.listItemTitle': 'Construction journal',
  "ProjectSettingsPage.ExternApps.ConstructionJournal.title": 'ConstructionJournal',
  'ProjectSettingsPage.ExternApps.ApplicationUrl.addButton': 'External link',
  'ProjectSettingsPage.ExternApps.ApplicationUrl.listItemTitle': '{name} (External link)',
  'ProjectSettingsPage.ExternApps.ApplicationUrlForm.title': 'External application url settings',
  'ProjectSettingsPage.ExternApps.ApplicationUrlForm.menuName.placeholder': 'Name in navigation menu',
  'ProjectSettingsPage.ExternApps.TextPage.title.placeholder': 'Page title',
  'ProjectSettingsPage.ExternApps.ApplicationUrlForm.url.placeholder': 'Enter external application URL',
  'ProjectSettingsPage.ExternApps.ApplicationUrlForm.url.newTab': 'Open in new tab',
  'ProjectSettingsPage.ExternApps.DocumentView.addButton': 'PDF document',
  'ProjectSettingsPage.ExternApps.DocumentView.listItemTitle': 'PDF document',
  'ProjectSettingsPage.ExternApps.DocumentView.missingPdfDerivate': 'Document is missing PDF derivate',
  'ProjectSettingsPage.ExternApps.TextPage.addButton': 'Text page',
  'ProjectSettingsPage.ExternApps.TextPage.listItemTitle': 'Text page',
  'ProjectSettingsPage.Groups.erasedGroupMessage': 'The group <strong>{name}</strong> has been deleted',
  'ProjectSettingsProjectCardDataTree.oldValue': 'Old value',
  'ProjectSettingsProjectCardDataTree.newValue': 'New',
  'ProjectStatusPage.projectCreating.projectCreating': 'Creating project',
  'ProjectStatusPage.projectCreating.projectIsReady': 'Project is ready :-)',
  'ProjectStatusPage.projectCreating.projectError': 'Error :-(',
  'ProjectsListPage.addDocumentationLevelOverview': 'Add project overview by documentation level',
  'ProjectsListPage.addNotificationOverview': 'Add notification overview',
  'ProjectsListPage.addAssignmentsOverview': 'Add active assignment overview',
  'ProjectsListPage.addNewReport': 'Add new report',
  'ProjectsListPage.addNewSharedReport': 'Add new shared report',
  'ProjectsListPage.addToFavorites': 'Add to selected',
  'ProjectsListPage.createFirstProject': 'Create your 1st project',
  'ProjectsListPage.createProject': 'Created: {date}',
  'ProjectsListPage.motherOrganization': 'Project in organization: {organizationName}',
  'ProjectsListPage.Filter.toolbar.organization': 'Organization',
  'ProjectsListPage.removeProjects': 'Remove projects',
  'ProjectsListPage.reorderProjects': 'Reorder',
  'ProjectsListPage.sortProjectsAZ': 'Sort alphabetically',
  'ProjectsListPage.sortProjectsDate': 'Sort by date',
  'ProjectsListPage.sortProjectsFav': 'Sort by fav.',
  'ProjectsListPage.financialRecapitulation': 'Financial recapitulation',
  'ProjectsListPage.projects': 'Selected projects',
  'ProjectsListPage.projectDrawingProcess': 'The drawing process of favorite projects',
  'ProjectsListPage.zbvGroups': 'ZBV - groups according to SGŘ 18/2017',
  'ProjectsListPage.removeFromFavorites': 'Remove from selected',
  'ProjectsListPage.reports': 'Selected reports',
  'ProjectsListPage.taskList': 'Task List',
  'ProjectsListPage.list.empty': 'You have no projects :-(',
  'ProjectsListPage.list.empty.noAdmin': 'You do not have permission to any project :-(',
  'ProjectsListPage.list.empty.noMatch': 'No matching projects :-(',
  'ProjectsListPage.tabs.all': 'All projects',
  'ProjectsListPage.tabs.favorite': 'Favorites',
  'ProjectsListPage.tabs.recent': 'Recent',
  'ProjectsListPage.Card.duration': 'Project duration',
  'ProjectsListPage.Card.duration.unknown': 'Unknown',
  'ProjectsListPage.Card.duration.unset': 'Unset',
  'ProjectsListPage.Card.remove': 'Remove project from selected',
  'ProjectsListPage.Card.phase': 'Project phase',
  'ProjectsListPage.Card.status.unknown': 'Unknown',
  'ProjectUserInviteFormData.form.addEmail': 'Add Email',
  'ProjectUserInviteFormData.form.addMultiMail': 'Bulk Input',
  'ProjectUserInviteFormData.form.emails': 'Users Emails',
  'ProjectUserInviteFormData.form.items.groups.label': 'Add the users to groups',
  'ProjectUserInviteFormData.form.items.message.initialValue': 'Hi! I would like you to cooperate on this project.',
  'ProjectUserInviteFormData.form.items.message.label': 'Invitation Text',
  'ProjectUserInviteFormData.form.multipleInput.addEmails': 'Add Emails',
  'ProjectUserInviteFormData.form.multipleInput.error': 'Set multiple email addresses separated by commas',
  'ProjectUserInviteFormData.form.multipleInput.inputText': 'Please enter a source text containing emails',
  'ProjectUserInviteFormData.form.multipleInput.label': 'Bulk Emails Input',
  'ProjectUserInviteFormData.form.multipleInput.resultText': 'Found Emails',
  'ProjectUserInviteFormData.form.multipleInput.placeholder': 'josef.novak@gmail.com, petr.spaleny@seznam.cz',
  'ProjectUserInviteFormModal.send': 'Send',
  'ProjectUserInviteFormModal.title': 'Invite to project',
  'ProjectUserStatus.active': 'active',
  'ProjectUserStatus.expired': 'expired',
  'ProjectUserStatus.invited': 'invited',
  'ProjectUserStatus.suspended': 'deactivated',
  'ProjectUserStatus.system': 'SYSTEM',
  'filters.searchPlaceholder.labels': 'Search tags',
  'filters.searchPlaceholder.users': 'Search users',
  'FiltersDocumentsPage.foundEntries': `{total, plural,
    =0 {no entries found}
    one {found one entry}
    other {found # entries}
  } in {took} ms`,
  'FiltersDocumentsPage.loading': 'Loading filtered documents...',
  'FilterPreset.category.mine': 'Mine',
  'FilterPreset.category.shared': 'Shared',
  'FilterPresetEditForm.filters.label':'Filter settings',
  'FilterPresetEditFormModal.title': 'Edit saved preset',
  'FilterPresetManageModal.title': 'Správa uložených nastavení filtrů',
  'FilterPresetSaveForm.title': 'Save current filter preset',
  'FilterPresetSaveForm.newName': 'Name of preset',
  'FilterPresetSaveForm.overridePreset': 'Override exisiting preset',
  'ReportWidget.projectsInRealization.column.PlanYearICurrent': 'Plan I',
  'ReportWidget.projectsInRealization.column.PlanYearOCurrent': 'Plan O',
  'ReportWidget.projectsInRealization.column.PerformedWorkYearI': 'Performed work I',
  'ReportWidget.projectsInRealization.column.PerformedWorkYearO': 'Performed work 0',
  'ReportWidget.projectsInRealization.column.remainI': 'Remain I',
  'ReportWidget.projectsInRealization.column.remainO': 'Remain O',
  'ReportWidgetCreateFormModal.title': 'Add new report',
  'ReportWidgetCreateFormModal.addButton': 'Add report',
  'ReportWidgetCreateFormModalForm.unit': 'Magnitude',
  'ReportWidgetCreateFormModalForm.unit.required': 'Magnitude is required',
  'ReportWidgetCreateFormModalForm.alignLabel': 'Value alignment',
  'ReportWidgetCreateFormModalForm.alignLabel.required': 'Alignment is required',
  'ReportWidgetCreateFormModalForm.organization.required': 'Organization is required',
  'ReportWidgetCreateFormModalForm.reportType': 'Report type',
  'ReportWidgetCreateFormModalForm.reportType.required': 'Report type is required',
  'ReportWidgetCreateFormModalForm.filters': 'Content filter',
  'ReportWidgetCreateFormModalForm.columnConfigurations': 'Viewed columns',
  'ReportWidgetCreateFormModalForm.columnConfigurations.required': 'At least one column is required',
  'ReportWidgetCreateFormModalForm.name.info': 'If not filled in, the name of the shared report will be used.',
  'ReportWidgetCreateFormModalForm.name.placeholder': 'Investment 2024',
  'ReportWidgetEditFormModal.title': 'Edit report',
  'ReportWidgetDecimalUnit.units.label': '{currency}',
  'ReportWidgetDecimalUnit.thousands.label': 'T {currency}',
  'ReportWidgetDecimalUnit.millions.label': 'MM {currency}',
  'ReportWidgetDecimalUnit.billions.label': 'BN {currency}',
  'ReportWidgetLabelAlignEnum.top.label': 'Top',
  'ReportWidgetLabelAlignEnum.middle.label': 'Middle',
  'ReportWidgetLabelAlignEnum.bottom.label': 'Bottom',
  'ReserveFileButton.label.set': 'Reservation',
  'ReserveFileButton.label.unset': 'Cancel Reservation',
  'ReserveFileButton.tooltip.reserve': 'Reserve a document for editing.',
  'ReserveFileButton.tooltip.reserve.withLimit': 'Will be reserved for {reservationDays} days.',
  'ReserveFileButton.tooltip.reserve.noLimit': 'The reservation will not be time-limited.',
  'ReserveFileButton.tooltip.release': 'Release reservation from {user} at {date}.',
  'ReserveFileButton.tooltip.release.info': 'Reservation will be automatically terminated {releaseDate}.',
  'ReserveFileButton.tooltip.release.noLimit': 'The reservation is not limited in time.',
  'ReserveFileButton.tooltip.reservedByUser': 'The document is edited by the user {user}',
  'ReserveFileButton.message.reserve': 'The document has been reserved.',
  'ReserveFileButton.message.release': 'The reservation has been closed',
  'RestoreInfoModal.categoryRemovedFrom.item': 'Some nonexistent categories have been removed for the document <strong>{file}</strong>',
  'RestoreInfoModal.labelRemovedFrom.item': 'Some nonexistent labels have been removed for the document <strong>{file}</strong>',
  'RestoreInfoModal.newDirectoryPaths.item': 'Folder <strong>{origin}</strong> was renamed to <strong>{destination}</strong>',
  'RestoreInfoModal.newFileName.item': 'Document <strong>{origin}</strong> was renamed to <strong>{destination}</strong>',
  'RestoreInfoModal.newFilePath.item': 'Document\'s <strong>{origin}</strong> folder was renamed to <strong>{destination}</strong>',
  'RestoreInfoModal.requireCategoryRemovedFrom.item': 'Some nonexistent required categories have been removed for the file <strong>{file}</strong>',
  'RestoreInfoModal.title': '{directoryCount} folders and {fileCount} documents was restored. The following changes occurred:',
  'RevisionCreateForm.form.items.description.label': 'Revision comment',
  'RevisionCreateForm.form.items.description.initial': 'Set revision {revisionNumber} as default.',
  'RevisionCreateForm.form.items.description.placeholder': 'Description of changes made...',
  'RevisionCreateForm.form.items.description.rules.required': 'Please enter a revision comment',
  'RevisionCreateFormModal.fileTypeReplacement': 'The file you are uploading has a different extension. Do you want to continue?',
  'RevisionCreateFormModal.fileTypeReplacement.description': 'The file with the extension "{oldExt}" will be replaced by the file with the extension "{newExt}".',
  'RevisionCreateFormModal.title': 'New Revision',
  'RevisionFromRevisionCreateFormModal.title': 'New revision from existing revision',
  'RevisionAttachmentsAddFormModal.title': 'Add attachments',
  'RevisionsDiff.pdfMissing': 'Revision ({revision}) does not have a PDF derivate, cannot compare the differences.',
  'RevisionsDiff.pdfProcessing': 'Revision ({revision}): the document is currently being processed, try again later.',
  'RevisionsDiff.firstPdfMissing': 'First revision does not have a PDF derivate, cannot compare the differences.',
  'RevisionsDiff.secondPdfMissing': 'Second revision does not have a PDF derivate, cannot compare the differences.',
  'RevisionSignedDocumentAddFormModal.analyzeRunning': 'Analyzing existing signatures',
  'RevisionSignedDocumentAddFormModal.analyzeFinished': 'Signatures analyzed',
  'RevisionSignedDocumentAddFormModal.downloadSignedDocumentMessage': 'Download document for signing',
  'RevisionSignedDocumentAddFormModal.invalidFileType': 'This file type is not supported for signed document.',
  'RevisionSignedDocumentAddFormModal.title': 'Add signed document',
  'RevisionSignedDocumentAddFormModal.replaceNotification': 'Only one signed document is allowed. Current document will be replaced.',
  'RevisionSignedDocumentAddFormModal.missingSignatureWarning':
    'Caution, the uploaded document does not contain some of the signatures from the previous version of the document. Would you like to upload the document anyway?',
  'RevisionSignedDocumentAddFormModal.unexpectedCountWraning':
    'Caution, the uploaded document has fewer signatures than expected. Would you like to upload the document anyway?',
  'RevisionSignDocumentFormModal.title': 'Sign document',
  'RevisionNumberTag.title': 'Revision number {number}',
  'RevisionStatus.tooltips.Ok.text': '',
  'RevisionStatus.tooltips.AccessDenied.text': 'You do not have sufficient permission to this document',
  'RevisionStatus.tooltips.InWorkflow.text': 'Document is assigned to workflow.',
  'RevisionStatus.tooltips.InCommentProcedure.text': 'Document is assigned to comment procedure.',
  'RevisionStatus.tooltips.Reserved.text': 'Document is reserved.',
  'RevisionStatus.tooltips.HasOwner.text': 'Document has a Document Manager.',
  'RevisionStatus.tooltips.EsticonDocument.text': 'Document has a link to AspeEsticon.',
  'RevisionRow.withAnnotations': 'Preview with annotations',
  'RoleForm.items.name.placeholder': 'Viewer - read only',
  'RoleForm.items.userId.suspended.placeholder': 'User not entered or deactivated, enter user please',
  'RoleFormModal.title.create': 'New Role',
  'RoleAssignFormModal.title.create': 'Assign Role',
  'RoleInfo.noRole': '(no role)',
  'RoleSelect.preferredRoles': 'Selected roles',
  'RoleSelect.otherRoles': 'Other roles',
  'RoleSelect.emptyError': 'No role is available',
  'RoleSelect.noRoleSet': 'No roles are selected',
  'RelativeDateWithDeadlines.tooltips.start': 'Start:',
  'RelativeDateWithDeadlines.tooltips.deadline': 'Deadline:',
  'RelativeDateWithDeadlines.tooltips.completion': 'Completion:',
  'RelativeDateWithDeadlines.tooltips.estimatedDates': 'Estimated dates:',
  'RelativeDateWithDeadlines.tooltips.initialization': 'Starting parallel branches:',
  'RelativeDateWithDeadlines.tooltips.parallelEnd': 'End of parallel branches:',
  'ReportChard.noValidConfiguration.title':'The report does not have complete configuration data',
  'ReportChard.noValidConfiguration.description':'Click to open the configuration completion form',
  'SearchSortTypeItem.lastChange': 'Last change',
  'SearchSortTypeItem.modifiedBy': 'User',
  'SearchSortTypeItem.createdDate': 'Creation date',
  'SearchSortTypeItem.deadlineDate': 'Deadline',
  'SearchSortTypeItem.description': 'Sorting:',
  'SearchSortTypeItem.directoryPath': 'Location (path)',
  'SearchSortTypeItem.discaredDate': 'Discarded date',
  'SearchSortTypeItem.documentCreated': 'Document creation date',
  'SearchSortTypeItem.documentModified': 'Document modification date',
  'SearchSortTypeItem.extension': 'Extension',
  'SearchSortTypeItem.isModel': 'Model',
  'SearchSortTypeItem.hasAnnotation': 'Annotation',
  'SearchSortTypeItem.isSigned': 'Signed',
  'SearchSortTypeItem.organization': 'Organization',
  'SearchSortTypeItem.projectName': 'Project name',
  'SearchSortTypeItem.lastAccessDate': 'Last access date',
  'SearchSortTypeItem.projectCreated': 'Project created',
  'SearchSortTypeItem.change': 'Change Document',
  'SearchSortTypeItem.id': 'ID',
  'SearchSortTypeItem.name': 'Name',
  'SearchSortTypeItem.priority': 'Priority',
  'SearchSortTypeItem.resolvedDate': 'Resolved date',
  'SearchSortTypeItem.taskDeadline': 'Active task deadline',
  'SearchSortTypeItem.workflowRoles': 'Solver for the WF',
  'SearchSortTypeItem.orderId': 'ID',
  'SearchSortTypeItem.project': 'Project',
  'SearchSortTypeItem.state': 'State',
  'SearchSortTypeItem.path': 'Path',
  'SearchSortTypeItem.revisionCreated': 'Create revision',
  'SearchSortTypeItem.type': 'Type',
  'SignedDocumentInput.collapse.header': 'Add Signed Document',
  'SignedDocumentInput.collapse.text': 'Select a Signed Document (PDF, Image)',
  'SignedDocumentInput.collapse.hint': 'Click or drag file to this area',
  'SecondaryFilesInput.collapse.header': 'Add Attachments',
  'SecondaryFilesInput.collapse.text': 'Click or drag files to this area',
  'serviceError.AddProjectUserByMailForbiddenError': 'You do not have permission to invite new users to the project via e-mail.',
  'serviceError.AdminAccessRequiredError': 'This operation requires administrator rights.',
  'serviceError.ApplicationAdminAccessRequiredError': 'This operation requires AspeHub administrator rights.',
  'serviceError.OrganizationAdminAccessRequiredError': 'This operation requires organization administrator rights.',
  'serviceError.ProjectAdminAccessRequiredError': 'This operation requires project administrator rights.',
  'serviceError.ProjectCardDataNameAlreadyExistsError': 'Supplementary data with the same name already exists.',
  'serviceError.ApplicationOrOrganizationAdminAccessRequiredError': 'This operation requires AspeHub or organization administrator rights.',
  'serviceError.AnnotationUsedInCommentProcedureCommentBadRequestError': 'Removed annotation is linked to comment procedure comment and cannot be removed.',
  'serviceError.AppUserNotFoundError': 'Application user profile was not found.',
  'serviceError.AssignmentAttachmentIsUseError': '',
  'serviceError.BadRequestError': 'A bad request occurred.',
  'serviceError.BlobTokenInvalidError': 'Provided token is invalid or expired.',
  'serviceError.CommentProcedureCommentAttachmentNotFoundError': 'Comment procedure attachment was not found.',
  'serviceError.CommentProcedureCommentAttachmentNotDiscardedBadRequestError': 'Comment procedure attachment is not discarded.',
  'serviceError.CommentProcedureCommentDocumentAlreadyAddedError': 'Document is already added to the comment.',
  'serviceError.CommentProcedureCommentDeleteAttachmentForbiddenError': 'Discard for this comment procedure attachment is forbidden for current user.',
  'serviceError.CommentProcedureCommentRestoreAttachmentForbiddenError': 'Restoration for this comment procedure attachment is forbidden for current user.',
  'serviceError.CommentProcedureDuplicateNameError': 'Comment procedure with the name already exists.',
  'serviceError.CommentProcedureForbiddenError': 'You are not assigned to this comment procedure and you do not have the necessary access rights.',
  'serviceError.CreateModelsError': 'Error creating model links',
  'serviceError.DirectoryAlreadyExistsThereError': 'The folder is already in this location.',
  'serviceError.DirectoryDuplicateNameError': 'Folder with this name already exists. Please chose another name.',
  'serviceError.DirectoryMoveForbiddenError': 'You do not have an access right for the folder or its subfolders.',
  'serviceError.DirectoryRecursiveMoveError': 'Folder cannot be moved to its subfolders.',
  'serviceError.DestinationDirectoryForMoveForbiddenError': 'Create a folder in the requested destination folder for move is not permitted.',
  'serviceError.DirectoryNotFoundError': 'Folder was not found.',
  'serviceError.DirectoryPermissionInheritanceOnRootNotPermitedError': 'You cannot inherit permissions in the root folder.',
  'serviceError.DocumentCategoryNodeRemoveObstacleError': 'The category node has not been deleted because it is assigned to some documents or folders.',
  'serviceError.DocumentCategoryTreeRemoveObstacleError': 'The category has not been deleted because it is assigned to some documents or folders.',
  'serviceError.DocumentCommentProcedureForbiddenActionError': 'Action cannot be performed. Document is in comment procedure.',
  'serviceError.ReservedDocumentAccessError': 'Document is reserved and can not be changed.',
  'serviceError.DocumentRemoveFromModelMultipleObstacleError': 'The document cannot be removed from the models',
  'serviceError.DocumentReservationReleaseLockError': 'The document is edited by the user {user}.',
  'serviceError.DocumentReservationLockNotFoundError': 'Reservation lock for document was not found.',
  'serviceError.DocumentIsReservedError': 'Document is reserved by another user and can not be changed.',
  'serviceError.DocumentInWorkflowForbiddenActionError': 'The document is managed by a workflow, so the action cannot be performed.',
  'serviceError.DocumentLinkNameNotPermitedBadRequestError': 'Setting document shortcut name is not permited for bulk shortcut action.',
  'serviceError.DocumentLinkNameRequiredBadRequestError': 'Document shortcut name is required.',
  'serviceError.DocumentLinkDuplicateNameError': 'Document shortcut with given name already exist in directory.',
  'serviceError.DirectoryLinkDuplicateNameError': 'Directory shortcut with given name already exist in directory.',
  'serviceError.DocumentManagerAccessError': 'Action cannot be performed. Document has owner.',
  'serviceError.DocumentStateForbiddenChangeError': 'The document state cannot be changed to this state.',
  'serviceError.DocumentNotFoundError': 'Document was not found.',
  'serviceError.DocumentSigningIsProcessedError': 'Document is already in signing process',
  'serviceError.EsticonDocumentsAlreadyLinkedError': 'The AspeEsticon project has linked documents and cannot be changed.',
  'serviceError.EsticonProjectNotFoundError': 'The AspeEsticon project linked to this project was not found.',
  'serviceError.ForbiddenError': 'You do not have sufficient permission.',
  'serviceError.GroupCountLimitExceededError': 'Group limit exceeded, cannot create any more groups.',
  'serviceError.GroupNotFoundError': 'Group was not found.',
  'serviceError.InappropriateCommentStateForChangeError': 'Comments have inappropriate state for comment procedure change.',
  'serviceError.LabelDuplicateNameError': 'A label with this name already exists.',
  'serviceError.LabelIsDeprecatedError': 'Label is deprecated.',
  'serviceError.LabelIsUsedOnDocumentsError': 'Error. The label is used on document.',
  'serviceError.LabelNotFoundError': 'Label not found.',
  'serviceError.LabelIsUsedOnEntitiesError': 'Label is used in application entities.',
  'serviceError.ModelStateInvalidError': 'Some settings are not valid.',
  'serviceError.NetworkError': 'Network error',
  'serviceError.NotFoundError': 'Requested resource was not found.',
  'serviceError.ParentDirectoryNotFoundError': 'Parent directory was not found.',
  'serviceError.ProjectNotFoundError': 'Project was not found.',
  'serviceError.ProjectUserInvitationAlreadyAcceptedError': 'This invitation has already been accepted.',
  'serviceError.ProjectUserInvitationInvalidAcceptCodeError': 'Invalid accept code for invitation.',
  'serviceError.ProjectUserInvitationNotFoundError': 'Your invitation has expired.',
  'serviceError.ProjectUserNotFoundError': 'User project profile was not found.',
  'serviceError.RevisionIsLockedError': 'Document revision is locked for signing by another user.',
  'serviceError.RevisionLockNotFoundError': 'Revision lock was not found.',
  'serviceError.RevisionNotFoundError': 'Requested content is no longer valid.',
  'serviceError.RevisionNotDiscardableError': 'Selected revision is not discardable.',
  'serviceError.RevisionNotDiscardedBadRequestError': 'Revision is not discarded.',
  'serviceError.RevisionRestoreForbiddenError': 'You do not have necessary permission to restore revision.',
  'serviceError.ReturnToReworkEsticonDocumentResult.Ok': `AspeHub detected, than to the associated document  {count, plural,
    one {is not recorded one partial}
    few {are not recorded # partials}
    other {are not recorded # partials}`,
  'serviceError.ReturnToReworkEsticonDocumentResult.ZPHasRelatedObject': 'WIP report  has already uploaded invoice',
  'serviceError.ReturnToReworkEsticonDocumentResult.ZPHasParentObject': 'This partial WIP report is currently in common WIP report',
  'serviceError.ReturnToReworkEsticonDocumentResult.FaHasParentObject': 'This partial invoice is currently in common invoice',
  'serviceError.ReturnToReworkEsticonDocumentResult.ZBVHasZP': 'Change order  has already uploaded WIP report',
  'serviceError.ReturnToReworkEsticonDocumentResult.ObjectIsNotSent': 'Attempt to return a document that is not currently uploaded',
  'serviceError.ReturnToReworkEsticonDocumentResult.RelatedObjectIsNotSent': 'Attempt to return common document, that does not have uploaded partial',
  'serviceError.ReturnToReworkEsticonDocumentResult.UnspecifiedError': 'Unspecified AspeEsticon error',
  'serviceError.ServerError': '500 (server error)',
  'serviceError.SelfPermissionsCannotBeSetError': 'The user can\'t set self permissions.',
  'serviceError.UnauthorizedError': 'You do not have the necessary permissions!',
  'serviceError.undefined': 'Unknown error.',
  'serviceError.UploadAlreadyCompleteError': 'File upload is already completed.',
  'serviceError.UploadAlreadyReferencedError': 'Upload is already used somewhere else.',
  'serviceError.UploadExpiredError': 'Upload time window expired.',
  'serviceError.UploadMissingCompletePartsError': 'File upload is missing complete parts.',
  'serviceError.UploadNotCompleteError': 'File upload was not completed.',
  'serviceError.UploadNotFoundError': 'Upload process was not found.',
  'serviceError.UploadPartNotFoundError': 'Upload part was not found.',
  'serviceError.UserProfileAlreadyExistsError': 'This user already exists.',
  'serviceError.WfTemplateDuplicateNameError': 'Template with this name already exists.',
  'serviceError.WorkflowDuplicateNameError': 'A workflow with this name already exists. Please enter a different name.',
  'serviceError.WorkflowMultipleDocumentStateChangeError': 'The workflow cannot contain multiple approval and rejection nodes in a single branch.',
  'serviceError.WorkflowNodeWithoutRoleError': 'All User and Decision tasks must have the solver role set.',
  'serviceError.WorkflowNotReachableNodeError': 'All ports on all nodes must be connected.',
  'serviceError.WorkflowPortShouldHaveOneEdgeError': 'All ports on all nodes must be connected.',
  'serviceError.WorkflowCircularDependencyError': 'The workflow must not contain cyclic linkages.',
  'serviceError.WorkflowTemplateInsufficientAccessLevelError': 'You do not have the necessary permissions to access the workflow template.',
  'serviceError.CommentProcedureApproveError': 'An error occurred when approving the comment procedure',
  'serviceError.DirectoryForbiddenError': 'Forbidden access to directory',
  'serviceError.RoleInProcedureError': 'Role is a part of a comment procedure',
  'serviceError.UploadAlreadyCompletedError': 'Upload is already completed',
  'serviceError.UploadPartAlreadyCompletedError': 'Upload part is already completed',
  'serviceError.DocumentMultipleAddObstacleError': 'Error when adding documents',
  'serviceError.DocumentMultipleMoveObstacleError': 'Error when moving documents',
  'serviceError.DocumentMultipleDiscardObstacleError': 'Error when discarding documents',
  'serviceError.DocumentMultipleRestoreObstacleError': 'Error when restoring documents',
  'serviceError.DocumentMoveDuplicateError': 'A document with that name already exists',
  'serviceError.DirectoryRestoreDuplicateError': 'A directory with that name already exists',
  'serviceError.DocumentRestoreDuplicateError': 'A document with that name already exists',
  'serviceError.ShareExpiredError': 'Shared content download timed out',
  'serviceError.ShareInvalidPinError': 'Invalid PIN',
  'serviceError.ShareInvalidFileCountError': 'Cannot find valid file',
  'serviceError.DirectoryInsufficientAccessLevelError': 'Insufficient access level for directory',
  'serviceError.GoneError': 'Server stopped responding',
  'serviceError.WorkflowNodeHasCorruptedLockError': 'Workflow contains unconfirmed changes in background documents',
  'serviceError.LicenceExpirationError': 'License expired',
  'serviceError.DocumentDeepDownloadForbiddenError': 'You do not have access to some of the documents you are trying to download.',
  'serviceError.ProjectForbiddenSuspendedError': 'User account was deactivated',
  'serviceError.ProjectUserLockError': 'Could not perform operation on user account because another action is being performed on that account',
  'serviceError.CommentProcedureTeamIsUseError': 'Team is assigned to a comment',
  'serviceError.EsticonUnlinkDocumentExistsError': 'AspeEsticon project has linked documents and cannot be removed.',
  'serviceError.EsticonDocumentNotFoundError': 'Requested AspeEsticon document not found.',
  'serviceError.CommentProcedureDuplicateTeamError': 'Team with this name already exists. Enter a different team name.',
  'serviceError.EmailLimitExceedError': 'Sent mail limit exceeded',
  'serviceError.AddAccessLevelToAdminError': 'Cannot change admin group access permissions.',
  'serviceError.AdminOrExtendedPermissionRequiredError': 'Insufficient permissions for this action.',
  'serviceError.AnyDocumentNotFoundError': 'No document found.',
  'serviceError.ApprovaDocumentAlreadyInWorkflowError': 'Document is already added as an approval document in this workflow.',
  'serviceError.ApprovaDocumentInsufficientRoleError': 'Approval document has insufficient role.',
  'serviceError.AppUserForProjectNotFoundError': 'Application user was not found for project user.',
  'serviceError.AppUserInvalidDashbordConfigurationError': 'Invalid dashboard configuration.',
  'serviceError.DocumentMultipleStateChangeObstacleError': 'Some document states from the documents list cannot change to requested state.',
  'serviceError.AuditLogNotFoundError': 'Audit log not found.',
  'serviceError.AutodeskForgeServiceError': 'Autodesk Forge service error',
  'serviceError.BackgroundDocumentAlreadyAssignedError': 'Background document is already assigned.',
  'serviceError.BackgroundDocumentAlreadyCheckedError': 'Background document is already approved or rejected.',
  'serviceError.BackgroundDocumentInsufficientAccessLevelError': 'Insufficient access permission to modify background document.',
  'serviceError.BackgroundDocumentNameAlreadExistsError': 'Background document with this name already exists.',
  'serviceError.BackgroundDocumentNotAssignedError': 'Background document is not assigned.',
  'serviceError.BackgroundDocumentNotFoundError': 'Background document was not found.',
  'serviceError.BackgroundTaskInProcessError': 'Derivate is still processing.',
  'serviceError.BlobDerivateNotFoundError': 'Derivate data was not found.',
  'serviceError.BlobNotFoundError': 'Derivate metadata was not found.',
  'serviceError.ChekedFilePathNotInPathsError': 'File path was not found.',
  'serviceError.CommentChangeStateForbiddenError': 'You don\'t have permission to change comment state.',
  'serviceError.CommentProcedureAddCommentForbiddenError': 'You don\'t have permission to add a comment.',
  'serviceError.CommentProcedureAlreadyHasWorkflowError': 'This comment procedure is already connected to a workflow.',
  'serviceError.CommentProcedureAttachmentChangeForbiddenError': 'You don\'t have permission to change attachment.',
  'serviceError.CommentProcedureAttachmentIsUseError': 'To discard an attachment, first delete it from the comment text.',
  'serviceError.CommentProcedureCategoryAprovedOrClosedError': 'Cannot change categories in closed comment procedures.',
  'serviceError.CommentProcedureCategoryIsUseError': 'Comment procedure category is in use.',
  'serviceError.CommentProcedureCategoryNotFoundError': 'Comment procedure category not found.',
  'serviceError.CommentProcedureCommentNoteNotFoundError': 'Comment note was not found.',
  'serviceError.CommentProcedureCommentNotFoundError': 'Comment was not found.',
  'serviceError.CommentProcedureDocumentAlreadyAddedError': 'Document is already added to the comment procedure.',
  'serviceError.CommentProcedureDocumentForbiddenError': 'You are missing permission for the comment procedure document.',
  'serviceError.CommentProcedureDocumentInCommentError': 'Document is used in a comment.',
  'serviceError.CommentProcedureDuplicateCategoryError': 'Comment procedure category with this name already exists.',
  'serviceError.CommentProcedureDuplicateUserError': 'User is already a member of this comment procedure.',
  'serviceError.CommentProcedureNoteChangeForbiddenError': 'You do not have permission to change comment note.',
  'serviceError.CommentProcedureNotFoundError': 'Comment procedure not found.',
  'serviceError.CommentProcedureNotifiedUserNotFoundError': 'Notified user was not found',
  'serviceError.CommentProcedureProcessorForbiddenError': 'You need to be a processor in this comment procedure.',
  'serviceError.CommentProcedureRoleChangeForbiddenError': 'You do not have permission to change role in the comment procedure.',
  'serviceError.CommentProcedureRoleProcessorEmptyError': 'There must be at least one main processor in the comment procedure.',
  'serviceError.CommentProcedureRoleSubmitterEmptyError': 'There must be at least one main submitter in the comment procedure.',
  'serviceError.CommentProcedureStateChangeForbiddenError': 'You do not have permission to change the state of the comment procedure.',
  'serviceError.CommentProcedureSubmitterForbiddenError': 'You need to be a submitter in this comment procedure.',
  'serviceError.CommentProcedureTeamNotFoundError': 'Comment procedure team was not found.',
  'serviceError.CommentProcedureTeamPermissionForbiddenError': 'You do not have permission to change this team.',
  'serviceError.CommentProcedureUserNotFoundError': 'Comment procedure user was not found.',
  'serviceError.CommentProcedureProcessorNoteForbiddenError': 'Processors are forbidden from this discussion group.',
  'serviceError.CommentProcedureSubmitterNoteForbiddenError': 'Submitters are forbidden from this discussion group.',
  'serviceError.DbConcurrencyError': 'An error occurred whe processing multiple database queries. Please try again.',
  'serviceError.DestinationDirectoryForDocumentMoveForbiddenError': 'You do not have permissions in the destination directory for writing files.',
  'serviceError.DirectoryAdminGroupAccessLevelCannotBeModifiedError': 'Admin group access level cannot be modified.',
  'serviceError.DirectoryEsticonImportDiscardForbiddenError': 'Directory is (or contains) a folder selected for esticon import',
  'serviceError.DirectoryIdRequiredError': 'Missing required parameter directory id.',
  'serviceError.DirectoryInvalidNameError': 'Invalid directory name.',
  'serviceError.DirectoryIsAlreadyDiscardedError': 'Directory is already discarded.',
  'serviceError.DirectoryIsNotDiscardedError': 'Directory is not discarded.',
  'serviceError.DirectoryIsNotEmptyError': 'Directory is not empty.',
  'serviceError.DirectoryIsRootError': 'Cannot move or remove root directory.',
  'serviceError.DirectoryRootNotFoundError': 'Root directory was not found.',
  'serviceError.DirectoryTreeMaxDepthExceededError': 'Maximum folder nesting depth was exceeded.',
  'serviceError.DirectoryUserAccessAlreadyExistsError': 'User already has access to this folder.',
  'serviceError.DiscoveryError': 'There was an error when establishing a WOPI connection.',
  'serviceError.DocumentAlreadyInModelError': 'The document already is a model.',
  'serviceError.DocumentAlreadyReservedError': 'The document is already reserved.',
  'serviceError.DocumentCategoryNodeDuplicateNameError': 'Category node with this name already exists.',
  'serviceError.DocumentCategoryNodeNotFoundError': 'Category node was not found.',
  'serviceError.DocumentCategoryTreeDuplicateIdInListError': 'Category tree contains duplicate id.',
  'serviceError.DocumentCategoryTreeDuplicateNameError': 'Category tree contains duplicate name.',
  'serviceError.DocumentCategoryTreeMaxDepthExceededError': 'Category tree max nesting depth was reached.',
  'serviceError.DocumentCategoryTreeNotFoundError': 'Category tree was not found.',
  'serviceError.DocumentCategoryTreeRootIsFixedError': 'Cannot move category root node.',
  'serviceError.DocumentCreatorAccessError': 'Document already has a different manager.',
  'serviceError.DocumentDuplicateNameError': 'A document with this name already exists in folder.',
  'serviceError.DocumentDuplicateNamesError': 'Secondary documents with these names already exist.',
  'serviceError.DocumentIdRequiredError': 'Document id is a required parameter.',
  'serviceError.DocumentInCommentProcedureForbiddenActionError': 'Document is part of a comment procedure and cannot be edited.',
  'serviceError.DocumentIsAlreadyDiscardedError': 'Document is already discarded.',
  'serviceError.DocumentIsNotDiscardedError': 'Document is not discarded.',
  'serviceError.DocumentIsNotInModelError': 'Document is not added to models.',
  'serviceError.DocumentIsUploadingError': 'Document is still uploading.',
  'serviceError.DocumentLinkedToEsticonError': 'Document cannot be discarded, because it is linked to Esticon content.',
  'serviceError.DocumentNotContainsRequiredCategoryTreeError': 'Document does not contain required categories.',
  'serviceError.DocumentNotReservedError': 'Document is not reserved.',
  'serviceError.DocumentRequiredCategoryTreeDeleteError': 'Category is required in the document\'s directory.',
  'serviceError.DocumentReservationReleaseForbiddenError': 'Only an admin can release someone else\'s reservation.',
  'serviceError.DocumentWithManagerForbiddenActionError': 'Document has a manager and cannot be reserved.',
  'serviceError.EsticonAccessRequiredError': 'This action can only be performed by the Esticon system user.',
  'serviceError.EsticonDirectoryIsNotCreatedError': 'Directory for esticon import is not set.',
  'serviceError.EventLogInsufficientAccessLevelError': 'You do not have sufficient permission to view activity history.',
  'serviceError.FavoriteDirectoryNotFoundError': 'Favorite directory was not found.',
  'serviceError.FavoriteDocumentNotFoundError': 'Favorite document was not found.',
  'serviceError.GroupDuplicateNameError': 'Another group with this name already exists.',
  'serviceError.GroupIsSystemFixedError': 'Unable to change a system group.',
  'serviceError.InappropriateCommentProcedureStateError': 'Action cannot be performed in this comment procedure state.',
  'serviceError.InappropriateCommentStateError': 'Cannot perform action: one of the comments is not in the corrent state.',
  'serviceError.InappropriateDocumentStateError': 'One of the documents is in an invalid state.',
  'serviceError.InappropriateTaskStateError': 'Unexpected error: a background task is in an invalid state.',
  'serviceError.InappropriateTaskTypeError': 'Invalid task type.',
  'serviceError.InappropriatMessageStatusError': 'Some messages are already in the selected state.',
  'serviceError.InvalidWopiSessionError': 'Wopi Authentication failed: invalid session.',
  'serviceError.LinkNotFoundError': 'Link was not found.',
  'serviceError.MessageBadUserError': 'You cannot change the state of this message.',
  'serviceError.MetaextractorTaskInProcessError': 'Cannot remove derivate: meta extractor task is in progress.',
  'serviceError.MetadataDefinationTemplateNotFoundError': 'Metadata definition not found.',
  'serviceError.ModelHasLinkError': 'Removing the document from models would remove it\'s links.',
  'serviceError.ModelOperationInsufficientAccessLevelError': 'You do not have sufficient permissions to work with this document as a model.',
  'serviceError.MdDivisionObstacleError': 'Users in division failed to change.',
  'serviceError.MdUserAccessRequiredError': 'This operation requires md User membership in specific division.',
  'serviceError.MdRoleAccessRequiredError': 'This operation requires md User membership in specific role.',
  'serviceError.MdDivisionO910CannotBeChangedError': 'Md division O910 cannot be changed.',
  'serviceError.O910CanHaveOnlyO910UsersError': 'Md role O910 can have only O910 Division users.',
  'serviceError.MdDivisionNotFoundError': 'Requested division not found',
  'serviceError.MdDivisionIsInMdProjectError': 'Requested division is in some project.',
  'serviceError.MdRoleNotFoundError': 'Requested role not found.',
  'serviceError.MdProjectNotFoundError': 'Requested project not found.',
  'serviceError.MdProjectHasIntentionError': 'Requested md project has Intention.',
  'serviceError.MdUserNotFoundError': 'You do not have sufficient permissions.',
  'serviceError.MdModuleNotFoundError': 'Md module id not added to organization.',
  'serviceError.MdUserNotInSetDivisionError': 'Requested user is not in set division.',
  'serviceError.MdDivisionDuplicateNameError': 'Division with set name already exists.',
  'serviceError.MdProjectDataCkApprovalReadinesConditionNotMetError': 'Conditions to set CK Approval Readines are not met.',
  'serviceError.MdProjectDuplicateNameError': 'Project with set name already exists.',
  'serviceError.MdProjectUnsupportedShiftError': 'Unsupported shift to requested project state.',
  'serviceError.MdProjectUnsupportedStateOpperationError': 'Unsupported operation for requested project state.',
  'serviceError.MdProjectShiftParametersNotSetError': 'Some parameters required for shift is not set.',
  'serviceError.MdProjectShiftProjectDataNotSetError': 'Required data field for project shift are not set.',
  'serviceError.MdUserInAotherDivisionError': 'Requested user is in another division.',
  'serviceError.MdRoleWithSetHeadUserAlreadyExistError': 'Some role with set head user already exist.',
  'serviceError.MdRoleIsProjectGuarantorError': 'Requested role is guarantor on project.',
  'serviceError.MdRoleIsProjectProcessorError': 'Requested role is processor on project.',
  'serviceError.MdRoleIsSupervisorError': 'Requested role is Supervisor.',
  'serviceError.MdDivisionUserObstacleError': 'User cannot be removed from division because they are in project or role.',
  'serviceError.MdDivisionO910NotAllowedError': 'Division O910 is not allowed.',
  'serviceError.MdMeetingApprovalBackgroundNumberAlreadyExistsBadRequestError': 'Meeting with given serial number already exists.',
  'serviceError.MdApprovalMeetingBackgroundMinutesStateNotFoundError': 'Meeting background document for CK was not yet created',
  'serviceError.IdmIsNotConnectedError':'Connection to IDM failed',
  'serviceError.IdmIsNotEnabledError':'Connection to IDM is not set',
  'serviceError.MdMeetingApprovalBackgroundNotActiveBadRequest': 'Meeting background is not in active state',
  'serviceError.NotUpdatableBackgroundDocumentStateError': 'You cannot upload a background document to this request right now.',
  'serviceError.NotUpdatableWorkflowNodeStateError': 'Workflow task cannot be updated in this state.',
  'serviceError.NotUpdatableWorkflowStateError': 'Workflow cannot be updated in this state.',
  'serviceError.OverheadMdProjectNotSetError': ' Overhead MD project not set.',
  'serviceError.OverheadMdProjectUserInMaterialsError': 'Selected project is used in MD materials.',
  'serviceError.ProjectActionForbiddenError': 'Cannot perform this action.',
  'serviceError.ProjectDuplicateNameError': 'A project with this name already exists.',
  'serviceError.ProjectForbiddenError': 'You do not have permission in this project.',
  'serviceError.ProjectNotReadyError': 'Project is still being created.',
  'serviceError.ProjectSettingNotFoundError': 'Project setting was not found.',
  'serviceError.ProjectUserInvitationInvalidTermsAcceptCodeError': 'Invalid invitation hash code.',
  'serviceError.ProjectUserStatusCannotBeChangedError': 'Cannot change user\'s status.',
  'serviceError.ProjectUserSuspendedError': 'User is suspended.',
  'serviceError.ReferencedWorkflowNodeNotExistError': 'Invalid WF template: referenced node does not exist.',
  'serviceError.RestoreDirectoryForbiddenError': 'Only a project admin can resore a discarded directory.',
  'serviceError.RestoreDocumentForbiddenError': 'Only a project admin can resore a discarded document.',
  'serviceError.RoleAlreadyUsedError': 'Role is already used in this task.',
  'serviceError.RoleAlreadyVotedError': 'You have already voted in all your roles in this task.',
  'serviceError.RoleDuplicateNameError': 'Another role with this name already exists.',
  'serviceError.RoleInCommentProcedureError': 'Role is used in a comment procedure.',
  'serviceError.RoleIsAlreadyUsedError': 'Role is already used.',
  'serviceError.RoleNotFoundError': 'Role was not found.',
  'serviceError.RoleRequireUserForbiddenError': 'Role must have a user assigned.',
  'serviceError.SecondaryDocumentNotFoundError': 'Secondary document was not found.',
  'serviceError.ShareNotFoundError': 'Share link was not found.',
  'serviceError.SourceDirectoryForDocumentMoveForbiddenError': 'You need a write permission in the current directory to move documents.',
  'serviceError.TmpBlobRequiredError': 'A temporary file is required for this action.',
  'serviceError.UploadAcccessDeniedError': 'You do not have permission to upload a document here.',
  'serviceError.UploadedTaskNotFoundError': 'Upload task was not found.',
  'serviceError.UploadPartETagIsRequiredError': 'Upload Etag is missing.',
  'serviceError.UserCantRemoveFromAdminGroupError': 'Cannot remove admin group.',
  'serviceError.UserIsInRoleError': 'Cannot remove user: they are assigned to roles.',
  'serviceError.UserIsNotInvitedError': 'User does not have an active invitation.',
  'serviceError.UserIsNotTeamAdminError': 'You must be a team admin.',
  'serviceError.UserIsSystemFixedError': 'System user cannot be changed.',
  'serviceError.UserNameNotVerifiedBadRequestError': 'You must be verified to sign documents.',
  'serviceError.UserOrRoleCanBeSetError': 'You must fill either a responsible tole or a user (but not both).',
  'serviceError.WopiSessionNotFoundError': 'Wopi relation was not found.',
  'serviceError.WorkflowApprovalRevisionNotFoundError': 'Workflow approval revision was not found.',
  'serviceError.WorkflowAutomaticActionShouldHaveOneOutputPortError': 'An automatic task must have exactly one port.',
  'serviceError.WorkflowDefaultDirectoryAlreadyUsedError': 'This background documents folder is already used in this workflow.',
  'serviceError.WorkflowDefaultDirectoryAssignedRequestError': 'Cannot remove default directory, because it already contains documents.',
  'serviceError.WorkflowDefaultDirectoryDuplicateNameError': 'A default directory with this name already exists.',
  'serviceError.WorkflowDefaultDirectoryDuplicatePathError': 'This folder is already assigned to another default directory.',
  'serviceError.WorkflowDefaultDirectoryInsufficientAccessLevelError': 'To select a default directory, you must have write access in that directory.',
  'serviceError.WorkflowDefaultDirectoryNotFoundError': 'Default directory was not found.',
  'serviceError.WorkflowHeadShouldHaveOneOutputPortError': 'Start task should hav exactly one output port.',
  'serviceError.WorkflowInsufficientAccessLevelError': 'You do not have sufficient permission in this workflow.',
  'serviceError.WorkflowInvalidDocumentAliasError': 'Invalid workflow document alias.',
  'serviceError.WorkflowInvalidRoleError': 'Invalid workflow role.',
  'serviceError.WorkflowNodeDuplicateRoleError': 'The same role is in a same task multiple times.',
  'serviceError.WorkflowNodeInsufficientAccessLevelError': 'You do not have permission to make changes in this task.',
  'serviceError.WorkflowNodeNotAcceptedBackgroundDocumentError': 'All background documents must be accepted.',
  'serviceError.WorkflowNodeNotFoundError': 'Workflow task was not found.',
  'serviceError.WorkflowNodePortNotFoundError': 'Workflow output port was not found.',
  'serviceError.WorkflowNodeRoleNotFoundError': 'Workflow role was not found.',
  'serviceError.WorkflowNotFoundError': 'Workflow was not found.',
  'serviceError.WorkflowTailMustHaveNoNoOutputPortError': 'The Stop task must not have any output ports.',
  'serviceError.WorkflowTemplateDuplicateNameError': 'Another template with this name already exists.',
  'serviceError.WorkflowTemplteNotFoundError': 'WF template was not found.',
  'serviceError.WorkflowTemplteShouldHaveOneHeadError': 'WF template must have only 1 Start node.',
  'serviceError.WorkflowUnassihnedAliasError': 'You must fill default directories for background documents upload.',
  'serviceError.WorkflowNodeAttachmentNotDiscardedBadRequestError': 'Workflow task attachment is not discarded.',
  'serviceError.WorkflowNodeAttachmentNotFoundError': 'Workflow task attachment was not found.',
  'serviceError.WorkflowNodeNoteChangeForbiddenError': 'Workflow task discussion note cannot be changed.',
  'serviceError.WorkflowNodeNoteNotFoundError': 'Workflow task discussion note was not found.',
  'serviceError.WorkflowNodeNotifiedUserNotFoundError': 'Notified user was not found in workflow task.',
  'serviceError.WorkflowApprovalDocumentAlreadyAssignedError': 'The document has already been assigned to another workflow.',
  'ServiceError.WorkflowApprovalRevisionBadStateError': 'Selected document state is not enabled in workflow.',
  'ServiceError.WorkflowApprovalDocumentBadStateError': 'Selected document state is not enabled in workflow.',
  'serviceError.WorkflowHasCriticalErrorError': 'A critical error occurs on the workflow',
  'serviceError.AssignmentCloseBadRequestError': 'Task is closed and cannot be edited.',
  'serviceError.AssignmentDuplicateNameBadRequestError': 'Another task with this name already exists in this project.',
  'serviceError.AssignmentFailedSaveServerError': 'Failed to save task.',
  'serviceError.AssignmentForbiddenError': 'You do not have the permission to view this task.',
  'serviceError.AssignmentNotFoundError': 'Task was not found.',
  'serviceError.DocumentEsticonForbiddenActionError': 'Cannot update an Esticon-linked document.',
  'serviceError.EsticonDocumentIsPartialError': 'Cannot change state of partial Esticon document.',
  'serviceError.InappropriateStateChangeBadRequestError': 'Cannot change task state to this value.',
  'serviceError.InappropriateUserForChangeBadRequestError': 'You cannot change task state to this value.',
  'serviceError.InsufficientSubWorkflowCountError': 'Insufficient parallel branches count.',
  'serviceError.ProjectUserNotActivatedError': 'Some of users is not active.',
  'serviceError.SubWorkflowNotFoundError': 'Subworkflow was not found.',
  'serviceError.WorkflowApprovalDocumentNotFoundNotFoundError': 'Approval document was not found.',
  'serviceError.WorkingPositionAssignedToUserError': 'Working position is assigned to some user in organization.',
  'serviceError.WorkingPositionNameAlreadyExistsError': 'Working position with this name already exists.',
  'serviceError.InvaitedUserNameDifferentError': 'The invitation belongs to another user.',
  'serviceError.AssignmentAttachmentChangeForbiddenError': 'Cannot change task attachment.',
  'serviceError.AssignmentAttachmentForbiddenError': 'You do not have the permission to change view task.',
  'serviceError.AssignmentAttachmentNotFoundError': 'Task attachment was not found.',
  'serviceError.AssignmentDeleteAttachmentForbiddenError': 'You do not have the permission to remove this task assignment.',
  'serviceError.AssignmentNoteChangeForbiddenError': 'Note can be editted only by the author.',
  'serviceError.AssignmentNoteNotFoundError': 'Task note was not found.',
  'serviceError.AssignmentNotifiedUserNotFoundError': 'Notified user is not a participant of this task.',
  'serviceError.AssignmentRestoreAttachmentForbiddenError': 'Cannot restore task attachment.',
  'serviceError.DirectoryCreateForbiddenError': 'You do not have permission to create a folder here.',
  'serviceError.DocumentNotSignableBadRequestError': 'This type of document cannot be signed.',
  'serviceError.DocumentSignBlobIsObsoleteError': 'Document signature is obsolete.',
  'serviceError.InappropriateSigningStateError': 'Error when signing a document: invalid state.',
  'serviceError.InvalidReturnAddressError': 'Error when signing a document: invalid return address.',
  'serviceError.SigningNotFoundError': 'Signature not found.',
  'serviceError.OrganizationUserLastAdminError': 'Unable to delete the last administrator',
  'serviceError.OrganizationUserInProjectError': 'It is not possible to delete a user included in the project',
  'serviceError.UserConfirmationResendRecentlyBadRequestError': 'User confirmation email was send recently. Try again later.',
  'serviceError.UserAlreadyConfirmedBadRequestError': 'User already confirmed activation email.',
  'serviceError.OrganizationDuplicateNameError': 'An organization with this name already exists in AspeHub',
  'serviceError.OrganizationUserInProjectTemplateError': 'The user cannot be removed because it is in the project template',
  'serviceError.OrganizationUserNotFoundError': 'User not found',
  'serviceError.OrganizationNotFoundError': 'Requested organization not found.',
  'serviceError.OrganizationIsSameError': 'Requested organization is same as current.',
  'serviceError.OrganizationForbiddenError': 'Organization do not contain current user with admin permissions.',
  'serviceError.OrganizationHasProjectError': 'Delete organization with existing project is not permited.',
  'serviceError.OrganizationReportForbiddenError': 'Organization report extended permission is not found for current user.',
  'serviceError.OrgLabelIsUsedOnEntitiesError': 'Label is used in organization entities',
  'serviceError.ExtendedPermissionTemplateNotFoundError': 'Requested permission template not found.',
  'serviceError.ExtendedPermissionTemplateInProjectTemplateError': 'The permission template is used in the project template.',
  'serviceError.ExtendedPermissionTemplateDuplicateNameError': 'Extended permission template with this name already exists.',
  'serviceError.ProjectTemplateNotFoundError': 'Requested project template not found.',
  'serviceError.ProjectTemplateAdminGroupNotFoundError': 'A project template can only contain one group of administrators.',
  'serviceError.ProjectTemplateUserNotFoundError': 'Requested project template user not found.',
  'serviceError.CategoryNodeParentNotFoundError': 'Some category node parent not found.',
  'serviceError.DirectoryParentNotFoundError': 'Some directory parent not found.',
  'serviceError.CategoryNodeTemplateNotFoundError': 'Requested category node template not found.',
  'serviceError.GroupTemplateNotFoundError': 'Requested template group not found.',
  'serviceError.UserIsInWorkflowError': 'Users cannot be deleted. He figures in the workflow.',
  'serviceError.UserIsInCommentProcedureError': 'Users cannot be deleted. He figures in the comment procedure.',
  'serviceError.ComparedDocumentRevisionsAreIdenticalBadRequestError': 'Compared document revisions are identical.',
  'serviceError.KontrolaSkupinIssueError': 'Not all ZBV items are grouped',
  'serviceError.DocumentStateOperationForbiddenError': 'You do not have sufficient permissions',
  'serviceError.AssignmentOverviewPermissionNotSetError': 'You do not have sufficient permissions to view the task overview.',
  'serviceError.MdProjectHasNotCreatedHubProjectError':'The material has not yet created an AspeHub project',
  'shareDownloadFormModal.errorsTitle.obstacle': 'The following errors prevent you from creating a shared link:',
  'serviceError.EsticonConnectionFirmIsUsedError': 'Esticon connection DB is used in some project.',
  'serviceError.ProjectMetadataDefinitionIsUsedError':'Project metadata definition is used on some directory or document.',
  'serviceError.MdApprovalMeetingBackgroundMinutesDocumentExist':'A document with supporting documents for the CK meeting with this title already exists.',
  'serviceError.MdApprovalMeetingBackgroundMinutesNotDirExist':'The directory for storing documents for the CK meeting does not exist.',
  'shareDownloadFormModal.errorsTitle.obstaclePartial': 'The following errors prevent you from creating complete shared link:',
  'ShareDownloadFormModal.send': 'Send',
  'ShareDownloadFormModal.sendNext': 'Send next',
  'ShareDownloadFormModal.title': 'File Download Sharing',
  'ShareDownloadFormModal.success': 'Link to shared download has been sent successfully.',
  'ShareDownloadForm.form.emails': 'Users Emails',
  'SharedDownload.header.file': 'Download file from AspeHub',
  'SharedDownload.header.archive': 'Download archive from AspeHub',
  'SharedDownload.form.items.includeSubdirectories.title': 'Include subdirectories',
  'SharedDownload.form.items.includeSubdirectories.label': 'Share folder including all subfolders',
  'SharedDownload.form.items.message.label': 'Message for recipient',
  'SharedDownload.page.button.download': 'Download',
  'SharedDownload.page.button.download.Others': 'Download Others',
  'SharedDownload.page.downloadError': 'Downloading error:',
  'SharedDownload.page.error': 'Share Download Error',
  'SharedDownload.page.error.download': 'Downloading shared documents error ({error})',
  'SharedDownload.page.error.obstacle': 'The following documents cannot be downloaded:',
  'SharedDownload.page.expired': 'Download expired at {date}',
  'SharedDownload.page.expiredInterval': 'Download will expire after {interval} days',
  'SharedDownload.page.file': 'File',
  'SharedDownload.page.label.Pin': 'PIN:',
  'SharedDownload.page.label.name': 'Archive Name:',
  'SharedDownload.page.pin.placeholder': 'Please, enter PIN',
  'SharedDownload.page.ready': 'Request for downloading was sent',
  'SharedDownload.page.revision': 'Revision',
  'SharedDownload.page.user': `User <strong>{user}</strong> sent you {count, plural,
    one {following file}
    other {# following files}
  }:`,
  'SharedReportWidgetsModal.title': 'Manage shared widgets',
  'SharedReportWidgetsModal.confirmDelete.title': 'Confirm shared widget delete',
  'SharedReportWidgetsModal.confirmDelete.description': 'Deleting shared widget will remove access to this widget for users using it on their dashboard. Continue?',
  'ShowFavoriteSettingResponseModal.documents': 'Documents',
  'ShowFavoriteSettingResponseModal.links': 'Shortcuts',
  'SignalR.directory.newDocumentAdded': `{count, plural,
    =0 {No document was added.}
    one {1 new document was added by {user}.}
    other {# new documents were added by {user}.}
  }`,
  'SignalR.directory.onDocumentsRestored': `{count, plural,
    =0 {No document was restored.}
    one {1 document was restored.}
    other {# documents were restored.}
  }`,
  'SignalR.directory.onDocumentDiscarded': `{count, plural,
    =0 {No document was discarded.}
    one {1 document was discarded.}
    other {# documents were discarded.}
  }`,
  'SignalR.directory.onDocumentChanged': `{count, plural,
    =0 {No document was changed.}
    one {1 document was changed.}
    other {# documents were changed.}
  }`,
  'SignalR.directory.onDocumentMovedTo': `{count, plural,
    =0 {No document was moved.}
    one {1 document was moved.}
    other {# documents were moved.}
  }`,
  'SignalR.directory.onDocumentMovedOut': `{count, plural,
    =0 {No document was moved out of the folder.}
    one {1 document was moved out of the folder.}
    other {# documents were moved out of the folder.}
  }`,
  'SignalR.directory.onDocumentLinkAdded': `{count, plural,
    =0 {No document shortcut was created.}
    one {1 document shortcut was created.}
    other {# document shortcuts were created.}
  }`,
  'SignalR.directory.onDocumentLinkRemoved': `{count, plural,
    =0 {No document shortcut was removed.}
    one {1 document shortcut was removed.}
    other {# document shortcuts were removed.}
  }`,
  'SignalR.directory.onDocumentLinkMovedOut': `{count, plural,
    =0 {No document shortcut was moved out of the folder.}
    one {1 document shortcut was moved out of the folder.}
    other {# document shortcuts were moved out of the folder.}
  }`,
  'SignalR.directory.onDocumentLinkMovedTo': `{count, plural,
    =0 {No document shortcut was moved to this folder.}
    one {1 document shortcut was moved to this folder.}
    other {# documents shortcuta were moved to this folder.}
  }`,
  'SignalR.directory.onDocumentLinkNameChanged': `{count, plural,
    =0 {No document shortcut was changed.}
    one {1 document shortcut was changed.}
    other {# document shortcuts were changed.}
  }`,
  'SignalR.directory.directoryLocationChanged': 'The path to this folder has been changed.',
  'SignalR.directory.DirectoryCurrentUserRightsChanged': 'The permissions to this folder have been changed.',
  'SignalR.document.commentProcedureChanged.message': 'Comment procedure {name} was changed. {reason}',
  'SignalR.document.commentProcedureChanged.CommentProcedureChangedActionEnum.added': 'Document added to comment procedure.',
  'SignalR.document.commentProcedureChanged.CommentProcedureChangedActionEnum.removed': 'Document removed from comment procedure.',
  'SignalR.document.commentProcedureChanged.CommentProcedureChangedActionEnum.addedToComment': 'Document added to comment.',
  'SignalR.document.commentProcedureChanged.CommentProcedureChangedActionEnum.removedFromComment': 'Document removed from comment.',
  'SignalR.document.commentProcedureChanged.CommentProcedureChangedActionEnum.nameChanged': 'Comment procedure name changed.',
  'SignalR.document.commentProcedureChanged.CommentProcedureChangedActionEnum.commentTitleChanged': 'Comment title changed.',
  'SignalR.document.commentProcedureChanged.CommentProcedureChangedActionEnum.commentStateChanged': 'Comment state changed.',
  'SignalR.document.documentDiscarded.message': 'Document was discarded by {user}.',
  'SignalR.document.documentMoved.message': 'Document was moved to {path} by {user}.',
  'SignalR.document.documentChanged.message': 'Dokument was changed by user {user}. \n{reason}',
  'SignalR.document.documentChanged.messageWithoutUser': 'Document was changed. \n{reason}',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.rezervation': 'Document reserved',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.owner': 'Changed owner.',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.revision': 'Changed revision',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.icon': 'Changed preview.',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.state': 'Changed state.',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.labels': 'Changed labels.',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.name': 'Changed name.',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.isModel': 'Changed model status.',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.isFavorite': 'Changed favorite status.',
  'SignalR.document.documentChanged.DocumentChangedPropertyEnum.category': 'Changed category.',
  'SignalR.document.linksChanged.message': 'Link {name} was changed by user {user}. {reason}',
  'SignalR.document.linksChanged.LinkActionEnum.added': 'new link created.',
  'SignalR.document.linksChanged.LinkActionEnum.removed': 'Link removed.',
  'SignalR.document.linksChanged.LinkActionEnum.rename': 'Link renamed.',
  'SignalR.document.linksChanged.LinkActionEnum.moved': 'Link moved.',
  'SignalR.document.bindingChanged.message': 'Binding {name} was changed by user {user}. {reason}',
  'SignalR.document.bindingChanged.DocToDocLinkActionEnum.added': 'Binding created.',
  'SignalR.document.bindingChanged.DocToDocLinkActionEnum.removed': 'Binding removed.',
  'SignalR.document.revisionChanged.message': 'Revision {name} was changed by {user}. {reason}',
  'SignalR.document.revisionChanged.DocumentChangedPropertyEnum.added': 'New revision added',
  'SignalR.document.revisionChanged.DocumentChangedPropertyEnum.discarded': 'Revision discarded',
  'SignalR.document.revisionChanged.DocumentChangedPropertyEnum.restored': 'Revision restored',
  'SignalR.document.revisionChanged.DocumentChangedPropertyEnum.signAdded': 'Signed document added',
  'SignalR.document.revisionChanged.DocumentChangedPropertyEnum.secondaryAdded': 'Secondary document added',
  'SignalR.document.revisionChanged.DocumentChangedPropertyEnum.annotationChanged': 'Annotations added',
  'SignalR.document.revisionChanged.DocumentChangedPropertyEnum.name': 'Revision name changed',
  'SignalR.document.revisionChanged.DocumentChangedPropertyEnum.blobProcessed': 'Revision file processed',
  'SignalR.document.workflowChanged.message': 'Workflow {name} was changed by user {user}. {reason}',
  'SignalR.document.workflowChanged.WorkflowChangedActionEnum.addedApproval': 'Approval document added.',
  'SignalR.document.workflowChanged.WorkflowChangedActionEnum.removedApproval': 'Approval document removed.',
  'SignalR.document.workflowChanged.WorkflowChangedActionEnum.addedApprovalRevision': 'Revision of approval document added.',
  'SignalR.document.workflowChanged.WorkflowChangedActionEnum.backgroundChanged': 'Background document or its state changed.',
  'SignalR.document.workflowChanged.WorkflowChangedActionEnum.state': 'State changed.',
  'SignalR.esticonGrid.onObjectAdded': `{count, plural,
    =0 {No esticon object added.}
    one {1 esticon object added by user {user}.}
    other {# esticon objects added by user {user}.}
  }`,
  'SignalR.esticonGrid.onObjectDateChanged': `{count, plural,
    =0 {No esticon object change approval date.}
    one {1 esticon object changed approval date by user {user}.}
    other {# esticon objects changed approval date by user {user}.}
  }`,
  'SignalR.esticonGrid.onObjectReturnForRework': `{count, plural,
    =0 {No esticon object was returned for rework.}
    one {1 esticon object was returned for rework by user {user}.}
    other {# esticon objects were returned for rework by user {user}.}
  }`,
  'SignalR.md.HubProjectAdded.message': 'Material was updated',
  'SignalR.workflow.workflowChanged.message': 'Workflow was changed by user {user}.',
  'SignalR.workflow.workflowTemplateChanged.message': 'Workflow template was changed by user {user}.',
  'ToolbarSettingsButton.close': 'Close settings',
  'ToolbarSettingsButton.open': 'Folder settings',
  'UnsupportedBrowser.continueAnyway': 'Continue anyway',
  'UnsupportedBrowser.error': 'This application is not supported by this version of the browser.',
  'UploadNewDocument.tooltip': 'Upload a new document',
  'UploadState.toolbar.showOnlyErrors': 'Show only upload errors',
  'UploadState.error.uploadDeniedError': 'Upload denied: {reason}',
  'UserActivity.report.modal.rangeLabel': 'Date of the exported period of user activity',
  'UserActivity.report.modal.checkbox.exportAllProjectTimeReport': 'Data export from the beginning of the project',
  'UserActivity.report.modal.disableOk.tooltipText': 'No relevant period specified',
  'UserActivity.report.modal.title': 'Export user activity and project settings for selected period',
  'UserAvatar.MultiUserAvatar.documentUserAndRevisionUserTitle': 'Document and last revision author',
  'UserAvatar.MultiUserAvatar.documentUserTitle': 'Document author',
  'UserAvatar.MultiUserAvatar.revisionUserTitle': 'Last revision author',
  'UserAvatar.revisionTitle': 'Revision author',
  'UserDetailPanel.ConfirmSuspend.title': 'User Deactivation',
  'UserDetailPanel.ConfirmSuspend.option': 'Deactivate user in all projects where they are not an administrator',
  'UserDetailPanel.ConfirmSuspend.text': 'Are you sure you want to deactivate user?',
  'UserDetailPanel.ConfirmActivate.text': 'Are you sure you want to activate user',
  'UserDetailPanel.GeneralTab': 'User settings',
  'UserDetailPanel.GeneralTab.currentUser': 'Current logged user',
  'UserDetailPanel.GeneralTab.reinviteButton': 'Resend the activation email',
  'UserDetailPanel.GeneralTab.reinviteExceeded':
    'The limit for emails sent to user\'s address was exceeded. Please try again later.',
  'UserDetailPanel.GeneralTab.reinviteError': 'Error resending invitation.',
  'UserDetailPanel.GeneralTab.deleteInvitedError': 'Error deleting invitation.',
  'UserDetailPanel.GeneralTab.deactivateUserError': 'Error deactivating user.',
  'UserDetailPanel.GeneralTab.activateError': 'Activate user error.',
  'UserDetailPanel.GeneralTab.licenceError': 'You do not have enough licenses to activate another user.',
  'UserDetailPanel.GeneralTab.reinviteSuccess': 'The invitation was successfully resent.',
  'UserDetailPanel.GeneralTab.deleteInvitedSuccess': 'The invitation was successfully deleted.',
  'UserDetailPanel.GeneralTab.deactivateUserSuccess': 'The user was successfully deactivated.',
  'UserDetailPanel.GeneralTab.activateSuccess': 'The user was successfully activate.',
  'UserDetailPanel.GeneralTab.currentLoggedUser': 'Currently logged in user',
  'UserDetailPanel.GeneralTab.deleteInvitedButton': 'Delete user',
  'UserDetailPanel.GeneralTab.suspendButton': 'Deactivate user',
  'UserDetailPanel.GeneralTab.suspendingIsPossible': 'Uživatele je možno deaktivovat',
  'UserDetailPanel.GeneralTab.activateButton': 'Activate user',
  'UserDetailPanel.GroupsTab': 'Groups',
  'UserDetailPanel.GroupsTab.UserDisabledWarning': 'User is disabled. Groups cannot be changed.',
  'UserDetailPanel.PermisionsTab': 'Permissions',
  'UserDetailPanel.PermisionsTab.categoryType.projectManager': 'Project manager',
  'UserDetailPanel.PermisionsTab.categoryType.document': 'Documents - enabled statuses',
  'UserDetailPanel.PermisionsTab.categoryType.workflow': 'Workflow',
  'UserDetailPanel.PermisionsTab.categoryType.aspeEsticon': 'AspeEsticon',
  'UserDetailPanel.PermisionsTab.category.addWorkflow': 'Templates editing',
  'UserDetailPanel.PermisionsTab.category.category': 'Categories',
  'UserDetailPanel.PermisionsTab.category.externalApplications': 'External applications',
  'UserDetailPanel.PermisionsTab.category.groups': 'Groups',
  'UserDetailPanel.PermisionsTab.category.importHarmonogram': 'Import harmonogram',
  'UserDetailPanel.PermisionsTab.category.returnForRework': 'Return for rework',
  'UserDetailPanel.PermisionsTab.category.importFaktura': 'Import invoice',
  'UserDetailPanel.PermisionsTab.category.importZP': 'Import ZP',
  'UserDetailPanel.PermisionsTab.category.importZBV': 'Import change order',
  'UserDetailPanel.PermisionsTab.category.importWorkflow': 'Import/export workflow',
  'UserDetailPanel.PermisionsTab.category.labels': 'Labels',
  'UserDetailPanel.PermisionsTab.category.roleEditing': 'Role editing',
  'UserDetailPanel.PermisionsTab.category.userEditing': 'User editing',
  'UserDetailPanel.PermisionsTab.category.stavba': 'Module AspeEsticon',
  'UserDetailPanel.PermisionsTab.categoryDescription.stavba': 'Enable and show the building module.',
  'UserDetailPanel.PermisionsTab.category.prenosHub': 'Display including unsent on AspeHub',
  'UserDetailPanel.PermisionsTab.category.workflowPreview': 'Workflow preview',
  'UserDetailPanel.PermisionsTab.category.setApprovalDate': 'Apoproval date setting',
  'UserDetailPanel.PermisionsTab.category.transferKD': 'Transfer to controll database',
  'UserDetailPanel.PermisionsTab.category.addPartner': 'Partners institution',
  'UserDetailPanel.PermisionsTab.category.forbidWorkInProgressState': 'forbidWorkInProgressState',
  'UserDetailPanel.PermisionsTab.category.forbidSharedState': 'forbidSharedState',
  'UserDetailPanel.PermisionsTab.category.forbidRejectedState': 'forbidRejectedState',
  'UserDetailPanel.PermisionsTab.category.forbidPublishedState': 'forbidPublishedState',
  'UserDetailPanel.PermisionsTab.category.forbidarchivedState': 'forbidarchivedState',
  'UserDetailPanel.PermisionsTab.setDefaultProjectPermission': 'Set default project permissions',
  'UserDetailPanel.userActivitiesTab': 'User activities - sessions',
  'UserDetailPanel.RolesTab': 'Role',
  'UserDetailPanel.RolesTab.activity': 'Activity of role {name}',
  'UserDetailPanel.RolesTab.AddRole': 'Add role',
  'UserDetailPanel.RolesTab.AddRole.title': 'Set user to role',
  'UserDetailPanel.RolesTab.AddRole.description': 'Replaces the user currently set in the role.',
  'UserDetailPanel.RolesTab.rolesListTitle': 'User\'s roles',
  'UserDetailPanel.RolesTab.ConfirmRemove.roleInDocument': 'Document:',
  'UserDetailPanel.RolesTab.ConfirmRemove.roleInWorkflow': 'WF:',
  'UserDetailPanel.RolesTab.ConfirmRemove.roleInWorkflowTemplate': 'WF template:',
  'UserDetailPanel.RolesTab.ConfirmRemove.roleInCommentProcedure': 'Comment procedure:',
  'UserDetailPanel.RolesTab.ConfirmRemove.title': 'Selected role is used in an existing workflow',
  'UserDetailPanel.RolesTab.ConfirmRemove.content': `{count, plural,
    =0 {The role is not used}
    one {The role is used in one item}
    few {The role is used in # items}
    other {The role is used in # items}
    }.`,
  'UserDetailPanel.RolesTab.ConfirmRemove.question': 'Are you sure you want to remove the user from the role?',
  'UserDetailPanel.RolesTab.ConfirmChange.title': 'Selected role already has a user',
  'UserDetailPanel.RolesTab.ConfirmChange.content': 'Selected role already has {user}, continue?',
  'UserNotificationButton.resolver.notification.title': 'To solver notice',
  'UserNotificationButton.resolver.notification.wasNotified': 'Solver was notified',
  'UserNotificationButton.resolver.notification.label': 'Message for the solver',
  'UserNotificationButton.resolver.notification.WorkflowNodeResolveDemand': 'Hello, I am sending a notification about an outstanding WF task.',
  'UserNotificationButton.resolver.notification.AssignmentResolveDemand': 'Hello, I am sending a notification about an unsolved task.',
  'UserNotificationButton.resolver.notification.noText': 'There is no message for the solver',
  'UserNotificationStatisticReport.empty': 'No messages to show',
  'UserAssignmentsStatisticReport.empty': 'You don\'t have any active assignments',
  'UserAssignmentsStatisticReport.userRole.tooltip': 'User role in assignment',
  'UserAssignmentsStatisticReport.date.tooltip': 'Deadline date',
  'SessionTab.auditlogTitle': 'User activities',
  'SessionTab.OrderFilter.sessionStart': 'Session start',
  'SessionTab.OrderFilter.sessionEnd': 'Session end',
  'SessionTab.OrderFilter.period': 'Period',
  'SessionListItem.sessionStart': 'Start: ',
  'SessionListItem.sessionEnd': 'End: ',
  'SessionListItem.IpAddress': 'IP address: ',
  'UsersFilter.documentUser': 'Author of the document',
  'UsersFilter.discardedUser': 'Discarded by user',
  'UsersFilter.revisionUser': 'Author of the revision',
  'UsersFilter.modifiedUser': 'Author of the change',
  'UsersFilter.ownerUser': 'Document manager',
  'UsersSelect.emptyError': 'No user is avaliable',
  'UserTransfer.sourceTitle': 'Available users',
  'UserTransfer.targetTitle': 'Selected users',
  'UserTransfer.searchPlaceholder': 'Search',
  'UserTransfer.itemUnit': 'user',
  'UserTransfer.itemsUnit': 'users',
  'UserTransfer.selectAll': 'Select all',
  'UserTransfer.selectInvert': 'Invert selection',
  'UserListPanel.addUserButton.disableTooltip': 'All available users of the organization are already on the project, at the same time you do not have permission to invite new users',
  'ArchiveViewer.headers.size': 'Size',
  'ArchiveViewer.headers.type': 'Archive type',
  'EmailViewer.headers.subject': 'Subject',
  'EmailViewer.headers.from': 'From',
  'EmailViewer.headers.to': 'To',
  'EmailViewer.headers.cc': 'Copy',
  'EmailViewer.headers.date': 'Date',
  'EmailViewer.headers.attachments': 'Attachments',
  'PdfViewer.title': 'PDF',
  'PdfSignatureAction.documentInWorkflowWarning.title': 'Document is in workflow',
  'PdfSignatureAction.documentInWorkflowWarning.content': 'This document is used in active workflow and cannot be signed outside it. Would you like to go to linked workflow?',
  'EditDocumentOnlineAction.documentInWorkflowWarning.title': 'Document is in workflow',
  'EditDocumentOnlineAction.documentInWorkflowWarning.content': 'This document is used in active workflow and cannot be edited outside it. Would you like to go to linked workflow?',
  'PdfAnnotationAction.documentInWorkflowWarning.title': 'Document is in workflow',
  'PdfAnnotationAction.documentInWorkflowWarning.content': 'This document is used in active workflow and cannot be annotated outside it. Would you like to go to linked workflow?',
  'ProcessedJsonViewer.title': 'Content',
  'ProcessedJsonViewer.processor.notFound': 'File content is not supported.',
  'ProcessedJsonViewer.processor.file.error': 'Error occurred during content loading',
  'ForgeViewer.title': 'Model',
  'ImageViewer.title': 'Image',
  'OfficeViewer.title': 'Office',
  'VideoViewer.title': 'Video',
  'AudioViewer.title': 'Sound',
  'XmlViewer.title': 'Xml',
  'XmlViewer.copyWarning': 'WARNING: text items in xml were decoded (ie. "radius < 5cm" instead of "radius &lt; 5cm"), copying the preview text MIGHT NOT yield a valid xml.',
  'ZipViewer.title': 'Zip',
  'WatchSwitch.watch': 'Track',
  'WatchSwitch.buttonTitle.nameChange': 'Name change',
  'WatchSwitch.buttonTitle.descriptionChange': 'Description change',
  'WatchSwitch.buttonTitle.categoryChange': 'Category change',
  'WatchSwitch.buttonTitle.permissionChange': 'Permission change',
  'WatchSwitch.buttonTitle.directoryDiscard': 'Directory discard',
  'WatchSwitch.buttonTitle.directoryMove': 'Directory move',
  'WatchSwitch.buttonTitle.addSubdirectory': 'Add subdirectory',
  'WatchSwitch.buttonTitle.removeSubdirectory': 'Remove subdirectory',
  'WatchSwitch.buttonTitle.addDocument': 'Add document',
  'WatchSwitch.buttonTitle.removeDocument': 'Remove document',
  'WatchSwitch.buttonTitle.revisionChange': 'Revision change',
  'WatchSwitch.buttonTitle.download': 'Document download and share',
  'WatchSwitch.buttonTitle.documentShare': 'Share document with project members',
  'WatchSwitch.buttonTitle.linkShare': 'Share download link',
  'WatchSwitch.buttonTitle.linkShareDownload': 'Download shared link',
  'WatchSwitch.buttonTitle.stateChange': 'State change',
  'WatchSwitch.buttonTitle.labelChange': 'Label change',
  'WatchSwitch.buttonTitle.ownerChange': 'Ownder change',
  'WatchSwitch.buttonTitle.documentMove': 'Document move',
  'WatchSwitch.buttonTitle.documentDiscard': 'Document discard',
  'WatchSwitch.buttonTitle.documentSign': 'Document sign',
  'WatchSwitch.buttonTitle.annotationChange': 'Annotation change',
  'WatchSwitch.buttonTitle.attachmentChange': 'Attachment change',
  'WatchSwitch.buttonTitle.cpChange': 'Change in comment procedure',
  'WatchSwitch.buttonTitle.wfChange': 'Change in workflow',
  'WatchSwitch.buttonTitle.assignmentChange': 'Change in task',
  'WidgetReportsContext.message.addWidget.success': 'Widget was successfully added to dashboard',
  'WidgetReportsContext.message.cloneWidget.prefix': 'Copy',
  'WidgetReportsContext.message.cloneWidget.error.notFound': 'Widget configuration was not found. New widget could not be created.',
  'WipRecordDetail.additionalInformation': 'Additional information',
  'WipRecordDetail.administrator': 'Manager / Investor',
  'WipRecordDetail.approval': 'Approval',
  'WipRecordDetail.destinationObjectId': 'Add to object',
  'WipRecordDetail.objectPath': 'Object',
  'WipRecordDetail.constructionParticipants': 'Construction participants',
  'WipRecordDetail.contractor': 'Contractor',
  'WipRecordDetail.issue': 'Issued',
  'WipRecordDetail.overview': 'Overview',
  'WipRecordDetail.projectRealizationWithVAT': 'Billing including VAT',
  'WipRecordDetail.protocolNumber': 'WIP report number',
  'WipRecordDetail.quantityCfW': 'SOW quantity',
  'WipRecordDetail.vatByExposed': 'VAT according to issue date',
  'WipRecordDetail.realized': 'Billed',
  'WipRecordDetail.realizedQuantityType': 'Quantity type',
  'WipRecordDetail.realizedWithValorizationAndVAT': 'Billed including valorization and VAT',
  'WipRecordDetail.realizedWithValorization': 'Billed including valorization',
  'WipRecordDetail.retentionIncludingVAT': 'Retention including VAT',
  'WipRecordDetail.suspensionIncludingVAT': 'Suspension including VAT',
  'WipRecordDetail.retentionWithoutVAT': 'Retention without VAT',
  'WipRecordDetail.suspensionWithoutVAT': 'Suspension without VAT',
  'WipRecordDetail.taxPeriod': 'Tax period',
  'WipRecordDetail.totalIncludingVAT': 'Total including VAT',
  'WipRecordDetail.valorization': 'Valorization',
  'WipRecordDetail.VAT': 'VAT ({percent}%)',
  'WipRecordDetail.VATTransferRegime.title': 'VAT reverse charge',
  'WipRecordDetail.VATTransferRegime.option.notApplied': 'Not applied',
  'WipRecordDetail.VATTransferRegime.option.receiverOfTheInvoiceWillPayVAT': 'Receiver of the invoice will pay VAT',
  'WipRecordDetail.VATTransferRegime.option.VATReverseChargedFromContractor': 'VAT reverse charged from contractor',
  'Workflow.name.full': 'ID {id} - {name}',
  'Workflow.name.id': 'ID {id}',
  'Workflow.nodes.start': 'Start',
  'Workflow.nodes.stop': 'Stop',
  'Workflow.nodes.unknown': '??',
  'Workflow.nodes.ApprovalNode': 'Decision Task',
  'Workflow.nodes.GenericNode': 'User Task',
  'Workflow.nodes.userTask': 'User Task',
  'Workflow.nodes.accept': 'Accept documents',
  'Workflow.nodes.refuse': 'Refuse documents',
  'Workflow.nodes.parallel': 'Parallel execution',
  'Workflow.nodes.addRevision': 'Change documents',
  'Workflow.nodes.userAction': 'User Task',
  'Workflow.nodes.notification': 'Notification',
  'Workflow.nodes.input': 'Input',
  'Workflow.nodes.parallel_END': 'Parallel execution - end',
  'Workflow.nodes.changeDocState': 'Change document state',
  'WorkflowDetail.ActiveTaskDetail.tabname': 'Active task detail',
  'WorkflowDetail.CommentProcedures.tabname': 'Comment procedures',
  'WorkflowDetail.DefaultFolders.tabname': 'Default folders',
  'WorkflowDetail.DefaultFolders.buttonAdd.text': 'Add folder',
  'WorkflowDetail.DefaultFolders.invalidFolder': 'Invalid default folder',
  'WorkflowDetail.DefaultFolders.name': 'Name',
  'WorkflowDetail.DefaultFolders.noDirectory': '(directory not set)',
  'WorkflowDetail.DefaultFolders.description': 'Description',
  'WorkflowAddRequestFormModal.title': 'Add request for background document',
  'WorkflowChangeRequestFormModal.title': 'Edit request',
  'WorkflowAddRequestForm.name': 'Request name',
  'WorkflowAddRequestForm.name.required': 'Please fill in the request name',
  'WorkflowAddRequestForm.name.unique': 'Another request with the same name already exists',
  'WorkflowAddRequestForm.description': 'Request description',
  'WorkflowAddRequestForm.insertionTerm': 'Insertion term',
  'WorkflowAddRequestForm.insertionTerm.required': 'Please select an insertion term',
  'WorkflowAddRequestForm.insertionTerm.target': 'Task term',
  'WorkflowAddRequestForm.workflowDefaultDirectoryId': 'Directory for uploading documents',
  'WorkflowAddRequestForm.workflowDefaultDirectoryId.required': 'Please select a directory',
  'WorkflowAddRequestForm.workflowDefaultDirectoryId.placeholder': 'Directory for uploading files',
  'WorkflowAddRequestForm.responsibleRoleId': 'Responsible user (role)',
  'WorkflowAddRequestForm.responsibleRoleId.required': 'Please select a responsible role',
  'WorkflowApprovalDocumentActivityList.activityType.addedDocument': 'Document added to workflow',
  'WorkflowApprovalDocumentActivityList.activityType.addedRevision': 'New document revision uploaded',
  'WorkflowApprovalDocumentActivityList.activityType.signed': 'Revision signed',
  'WorkflowApprovalDocumentActivityList.activityType.changedAnnotation': 'Document was annotated',
  'WorkflowApprovalDocumentActivityModal.title': 'Document changes in workflow',
  'WorkflowApprovalDocumentActivityHistoryButton.tooltip': 'Workflow document changes history',
  'WorkflowCommentProcedures.removeProcedure.tooltip': 'Remove comment procedure from workflow',
  'WorkflowCommentProcedures.removeProcedure.confirm': 'Really remove comment procedure?',
  'WorkflowCommentProcedures.removeProcedure.success': 'Comment procedure successfully removed.',
  'WorkflowDetail.DocumentApprovals.tabname': 'Document approvals ({count})',
  'WorkflowDetail.DocumentApprovals.revisionsModalTitle': 'Document revisions on this WF',
  'WorkflowDetail.BackgroundDocuments.tabname': 'Background documents ({count})',
  'WorkflowDetail.WorkflowActivity.tabname': 'Activity',
  'WorkflowDetail.WorkflowErrors.tabname': 'Errors',
  'WorkflowDetail.WorkflowErrors.noErrors': 'Workflow does not contain any errors',
  'WorkflowDefaultDirectoryForm.modal.title': 'Default directory',
  'WorkflowDefaultDirectoryForm.modal.title.create': 'Add default directory',
  'WorkflowDefaultDirectoryForm.modal.title.update': 'Update default directory',
  'WorkflowDefaultDirectoryForm.form.name': 'Default directory name',
  'WorkflowDefaultDirectoryForm.form.name.required': 'Default directory name is required',
  'WorkflowDefaultDirectoryForm.form.description': 'Default directory description',
  'WorkflowDefaultDirectoryForm.form.path': 'Directory path for default directory',
  'WorkflowDefaultDirectoryForm.form.path.required': 'Directory path for default directory must be selected',
  'WorkflowDocuments.revisionDateLabel': 'Workflow revision: {date}',
  'ActiveTaskDetail.WorkflowErrors.subjectName': 'Subject name',
  'ActiveTaskDetail.WorkflowErrors.severity': 'Error severity',
  'ActiveTaskDetail.WorkflowErrors.roleId': 'Affected role',
  'ActiveTaskDetail.WorkflowErrors.subject': 'Error type',
  'ErrorWorkflowSubjectEnum.backgroundDocumentRole': 'Missing or deactivated user in a document request\'s role',
  'ErrorWorkflowSubjectEnum.backgroundDocumentTerm': 'Document request deadline has expired',
  'ErrorWorkflowSubjectEnum.nodeRole': 'Missing or deactivated user in a task resolver role',
  'ErrorWorkflowSubjectEnum.nodeTerm': 'Task deadline has expired',
  'ErrorWorkflowSubjectEnum.workflowRole': 'Missing or deactivated user in the workflow admin role',
  'ErrorWorkflowSubjectEnum.backgroundDocumentDir': 'Invalid default folder',
  'ErrorCommentProcedureSubjectEnum.CommentProcedureRole': 'Missing or deactivated user in a role',
  'ErrorCommentProcedureSubjectEnum.CommentProcedureUser': 'User is deactivated',
  'ErrorSeverityEnum.warning': 'Warning',
  'ErrorSeverityEnum.error': 'Error',
  'ErrorSeverityEnum.critical': 'Critical error',
  'ActiveTaskDetail.WorkflowErrors.subject.backgroundDocumentRole': 'Document request\'s role is missing a user',
  'ActiveTaskDetail.WorkflowErrors.subject.backgroundDocumentTerm': 'Document request deadline has expired',
  'ActiveTaskDetail.WorkflowErrors.subject.nodeRole': 'Task resolver role is missing a user',
  'ActiveTaskDetail.WorkflowErrors.subject.nodeTerm': 'Task deadline has expired',
  'ActiveTaskDetail.WorkflowErrors.subject.workflowRole': 'Workflow admin role is missing a user',
  'ActiveTaskDetail.WorkflowErrors.subject.defaultDirectory': 'Invalid default folder',
  'WorkflowDetail.WorkflowSchema.tabname': 'Schema',
  'WorkflowInstantiationButton.noTemplates': 'There are no workflow templates available',
  'WorkflowInstantiationButton.noRoles': 'You need to have at least one role in project assigned to start a workflow',
  'WorkflowInstantiationForm.name': 'Name',
  'WorkflowInstantiationForm.name.required': 'Please fill in the workflow name',
  'WorkflowInstantiationForm.description': 'Description',
  'WorkflowInstantiationForm.template': 'Template',
  'WorkflowInstantiationForm.template.required': 'Please select a workflow template',
  'WorkflowInstantiationForm.approvalDocuments': 'Approval documents',
  'WorkflowInstantiationForm.defaultDirectories': 'Default directories',
  'WorkflowInstantiationForm.defaultDirectories.noTemplate': 'First select a template',
  'WorkflowInstantiationForm.defaultDirectories.placeholder': 'Select a default folder',
  'WorkflowInstantiationForm.defaultDirectories.required': 'Please select all default directories',
  'WorkflowInstantiationForm.workflowRole': 'Workflow admin',
  'WorkflowInstantiationForm.workflowRole.required': 'Please select a workflow admin',
  'WorkflowInstantiationFormModal.messagePlaceholder': 'Comment for the solver of the first workflow role...',
  'WorkflowInstantiationFormModal.messageLabel': 'Comment on workflow creation',
  'WorkflowInstantiationFormModal.title': 'Start a new workflow',
  'WorkflowInstantiationFormModal.WfIsStarting': 'Workflow is starting...',
  'WorkflowDetail.defaultDirectory.removeImpossible.message': 'The default folder cannot be deleted, there is a request based on it',
  'WorkflowDetail.defaultBackgroundDirectory.name': 'Directory for background documents',
  'WorkflowListItem.admin': 'Workflow admin',
  'WorkflowListItem.taskTerm': 'Active task deadline: {date}',
  'WorkflowListPage.filter.assignedTo.options.waiting': 'My future',
  'WorkflowListPage.filter.assignedTo.options.current': 'My to do',
  'WorkflowListPage.filter.assignedTo.options.out': 'My unreachable',
  'WorkflowListPage.filter.assignedTo.options.processed': 'My done',
  'WorkflowListPage.filter.assignedTo.options.other': 'Other',
  'WorkflowListPage.filter.assignedTo.label': 'Tasks',
  'WorkflowListPage.filter.date.start': 'Start',
  'WorkflowListPage.filter.date.end': 'End',
  'WorkflowListPage.filter.date.end.description': 'If the workflow has been completed or cancelled, this is the actual end time, otherwise it is the predicted end time.',
  'WorkflowListPage.filter.workflowSolversReady': 'Voted',
  'WorkflowListPage.filter.workflowSolversPending': 'Pending',
  'WorkflowListPage.filter.workflowSolversAll': 'All',
  'WorkflowListPage.filter.solvers.label': 'Current solvers',
  'WorkflowListPage.filter.currentNodeName.label': 'Current node name',
  'WorkflowListPage.filter.currentNodeDeadline.label': 'Node deadline',
  'WorkflowListPage.filter.currentNodeDeadline.beforeDeadline': 'Before deadline',
  'WorkflowListPage.filter.currentNodeDeadline.afterDeadline': 'After deadline',
  'WorkflowListExportButton.orderBy':'Order by: ',
  'WorkflowListExportButton.orderBy.author':'Author',
  'WorkflowListExportButton.orderBy.id':'Identification number',
  'WorkflowListExportButton.orderBy.lastChange':'Date of last change',
  'WorkflowListExportButton.modalTitle':'Export of selected workflow data to *.xlsx',
  'WorkflowListExportButton.fileNamePart':'FILTERED_WORKFLOW_LIST',
  'WorkflowRow.createdDate': 'Last modified: {date}',
  'WorkflowPage.closeWorkflow': 'End workflow',
  'WorkflowPage.closeWorkflow.confirm': 'Are you sure you want to end this workflow?',
  'WorkflowTemplateList.invalid': 'Invalid',
  'WorkflowDetailPage.AddCommentProcedure.button': 'Add comment procedure',
  'WorkflowDetailPage.AddCommentProcedure.add': 'Add existing',
  'WorkflowDetailPage.AddCommentProcedure.addTitle': 'Add comment procedure',
  'WorkflowDetailPage.AddCommentProcedure.addSuccess': 'Comment procedure successfully added',
  'WorkflowDetailPage.AddCommentProcedure.new': 'Create new',
  'WorkflowDetailPage.AddCommentProcedure.newTitle': 'Add new comment procedure to workflow',
  'WorkflowDetailPage.addedRevisions.signingState.all': 'All documents',
  'WorkflowDetailPage.addedRevisions.signingState.signed': 'Documents with signed attachment',
  'WorkflowDetailPage.addedRevisions.signingState.unsigned': 'Dokuments without signed attachment',
  'WorkflowDetailPage.addedRevisions.commentedState.all': 'All documents',
  'WorkflowDetailPage.addedRevisions.commentedState.commented': 'Documents with comments',
  'WorkflowDetailPage.addedRevisions.commentedState.clean': 'Dokuments without comments',
  'WorkflowPageToolbar.newTemplate': 'New template',
  'WorkflowPageToolbar.newWorkflow': 'Start workflow',
  'WorkflowPageToolbar.exportWorkflows': 'Export list ({count})',
  'WorkflowPageToolbar.saveTemplate': 'Export to file',
  'WorkflowProcessFormModal.title.create': 'Create process',
  'WorkflowProcessListPageToolbar.newProcess': 'Start new process',
  'WorkflowTemplate.solver': 'Solver',
  'WorkflowTemplate.solvers': 'Solvers',
  'WorkflowTemplate.parallelBranchingSetting': 'Parallel branches start settings',
  'WorkflowTemplate.parallelBranchingSetting.options.runAll': 'Start all branches automatically',
  'WorkflowTemplate.parallelBranchingSetting.options.runManually': 'Select branches manually when starting a node',
  'WorkflowTemplate.parallelBranchingSetting.solverRoles': 'Solvers who can select parallel branches',
  'WorkflowTemplate.solverRoles': 'Solver roles',
  'WorkflowTemplate.confirmTitle': 'Do you really want to leave?',
  'WorkflowTemplate.confirmMessage': 'Changes you made may not be saved.',
  'WorkflowTemplate.parallelBranch.output': 'Branch',
  'WorkflowTemplates.title': 'Workflow templates',
  'WorkflowTemplatePage.editTemplate': 'Edit template',
  'WorkflowTemplatePage.saveSuccess': 'WF was saved',
  'WorkflowTemplatePage.saveError': 'WF could not be saved.',
  'WorkflowTemplatePage.saveError.savedAsDraft': 'The template is invalid, it is saved as a draft.',
  'WorkflowTemplatePage.copyError.saveAsDraft': 'Copied WF template is invalid, copy was saved as a draft.',
  'WorkflowTemplatePage.copySuccess': 'Copy of WF was saved',
  'WorkflowTemplatePage.loading': 'Loading template detail...',
  'WorkflowTemplatePage.forbiddenError': 'You do not have the necessary permissions to access WF templates.',
  'WorkflowTemplatePage.forReadOnly': `The template is read-only. You don't have the necessary permissions to edit it.`,
  'WorkflowTemplatePage.duplicityNameError': 'Another template already has the name you entered',
  'WorkflowTemplatePage.errorHeader.description': 'Error description',
  'WorkflowTemplatePage.errorHeader.severity': 'Error severity',
  'WorkflowTemplatePage.errorHeader.source': 'Error source',
  'WorkflowTemplatePage.errorHeader.link': 'Shortcut',
  'WorkflowTemplatePage.error.goToNode': 'Go to node',
  'WorkflowTemplatePage.error.sourceInTemplate': 'Template',
  'WorkflowTemplatePage.errorNames.WorkflowNotReachableNodeError': 'Not reachable node',
  'WorkflowTemplatePage.errorNames.WorkflowMultipleDocumentStateChangeError': 'Multiple document state changes',
  'WorkflowTemplatePage.errorNames.WorkflowCircularDependencyError': 'Infinite cycle',
  'WorkflowTemplatePage.errorNames.ReferencedWorkflowNodeNotExistError': 'Node don\'t exists',
  'WorkflowTemplatePage.errorNames.WorkflowPortShouldHaveOneEdgeError': 'Each output port must have exactly one connection',
  'WorkflowTemplatePage.errorNames.WorkflowTailMustHaveNoNoOutputPortError': 'End node must have no output',
  'WorkflowTemplatePage.errorNames.WorkflowNodeWithoutRoleError': 'Role missing in node',
  'WorkflowTemplatePage.errorNames.WorkflowInvalidDocumentAliasError': 'Invalid document alias',
  'WorkflowTemplatePage.errorNames.WorkflowInvalidRoleError': 'Invalid role',
  'WorkflowTemplatePage.errorNames.WorkflowNodeDuplicateRoleError': 'Duplicate role',
  'WorkflowTemplatePage.errorNames.WorkflowHeadShouldHaveOneOutputPortError': 'Start node must have one output',
  'WorkflowTemplatePage.errorNames.WorkflowTemplateDuplicateNameError': 'Workflow name already exists',
  'WorkflowTemplatePage.errorNames.UserOrRoleCanBeSetTempError': 'Only user or role can be set',
  'WorkflowTemplatePage.errorNames.WorkflowInvalidTailNodeError': 'Invalid node outputs',
  'WorkflowTemplatePage.errorNames.NestedSubWorkflowError': 'Parallel workflow error',
  'WorkflowTemplatePage.errorNames.WorkflowInvalidRequiredVotersCount': 'A small number of voters is set. At least one must vote',
  'WorkflowTemplatePage.errorNames.WorkflowTemplateLowMaxVotersValueError': 'The maximum number of voters is lower than the minimum',
  'WorkflowTemplatePage.errorNames.WorkflowTemplateHighRequestedVotesValueError': 'The maximum number of voters is less than the required number of approvals',
  'WorkflowTemplatePage.errorNames.SubworkflowsCountError': 'Parallel workflow must have at least one branch',
  'WorkflowTemplatePage.errorNames.SubworkflowRequestedVoteCountError': 'The required number of branches to start does not correspond to the number of branches created',
  'WorkflowTemplatePage.errorNames.NotifyNodeShouldHaveMessageError': 'Notification node should have a message body.',
  'WorkflowTemplatePage.errorNames.OnlyNotifyNodeCanHaveMessageError': 'Only notification node can have a message body.',
  'WorkflowTemplatePage.errorNames.WorkflowNodeMessageDuplicateuserError': 'Duplicate user as the recipient of the message.',
  'WorkflowTemplatePage.errorNames.WorkflowTemplateShouldHaveOneHeadError': 'Workflow template should have only one start node.',
  'WorkflowTemplatePage.errorNames.DocumentRequestsWithSameNameError': 'Task contains background document requests with the same name.',
  'WorkflowTemplatePage.errorNames.AddRevisionInBadPossitionError': 'Revision upload can be only before accepting or refusing documents.',
  'WorkflowTemplatePage.errorNames.WorkflowInvalidUserError': 'Selected user does not exist.',
  'WorkflowTemplatePage.errorNames.MessageSubjectEmptyError': 'Message subject cannot be empty.',
  'WorkflowTemplatePage.errorNames.MessageMessageEmptyError': 'Message text is empty.',
  'WorkflowTemplatePage.errorNames.MessageNoRecipientsError': 'No message recipients are selected.',
  'WorkflowTemplatePage.errorNames.WorkflowBDRoleWithoutUserError': 'The background document role does not contain users.',
  'WorkflowTemplatePage.errorNames.WorkflowNodeRoleWithoutUserError': 'The role on the task does not contain a user.',
  'WorkflowTemplatePage.signalWarning.title': 'Workflow template was changed',
  'WorkflowTemplatePage.signalWarning': 'Workflow template was changed by user {user}. Do you wish to reload new workflow template or continue editing openned version and override new template? All changes will be discarded with template reload.',
  'WorkflowTemplatePage.signalWarning.sameUser': 'You have changed this template. Do you wish to reload new workflow template or continue editing openned version and override new template? All changes will be discarded with template reload.',
  'WorkflowTemplatePage.signalWarning.continue': 'Continue with old version',
  'WorkflowTemplatePage.signalWarning.reload': 'Reload new version',
  'WorkflowTemplatePage.errorNames.WorkflowNodeEmptyAddDocumentPermittedActionsError': 'No action is allowed on the task',
  'WorkflowTemplatePage.errorNames.WorkflowNodeEmptyDestinationDocumentStateError': 'A new document status is not set',
  'WorkflowTemplatePage.errorNames.WorkflowNodeEmptyAddDocumentPermittedStatesError': 'The enabled state of the document being added is not set to task or template',
  'WorkflowTemplatePage.errorNames.WorkflowEmptyAddDocumentPermittedStatesError': 'The enabled state of the document being added is not set to template',
  'WorkflowTemplatePage.exportToOrganization.button.label': 'Export to organization',
  'WorkflowTemplatePage.exportToOrganization.success': 'Template was successfully exported to organization',
  'WorkflowTemplatePage.exportToOrganization.confirm.title': 'Template with this name already exists in organization',
  'WorkflowTemplatePage.exportToOrganization.confirm.content': 'Do you want to overwrite the existing template?',
  'WorkflowTemplateNodeDetail.workflowSettings': 'Workflow settings',
  'WorkflowTemplateNodeDetail.workflowSolver': 'Solver for the workflow',
  'WorkflowTemplateNodeDetail.workflowManager': 'Workflow manager',
  'WorkflowTemplateNodeDetail.defaultDirectories': 'Default directories',
  'WorkflowTemplateNodeDetail.inputDocumentStatuses': 'Selection of statuses of input documents',
  'WorkflowTemplateNodeDetail.inputDocumentStatuses.workInProgress': 'Work in progress',
  'WorkflowTemplateNodeDetail.inputDocumentStatuses.shared': 'Shared',
  'WorkflowTemplateNodeDetail.inputDocumentStatuses.published': 'Published',
  'WorkflowTemplateNodeDetail.inputLabels': 'Selection of labels',
  'WorkflowTemplateNodeDetail.requestedVotes': 'Must approve',
  'WorkflowTemplateNodeDetail.requestedVotes.tooltip': 'Required number of approves',
  'WorkflowTemplateNodeDetail.minRequestedVoters': 'Minimum number of votes',
  'WorkflowTemplateNodeDetail.maxRequestedVoters': 'Maximum number of votes',
  'WorkflowTemplateNodeDetail.commonRequestedVoters': 'Must vote',
  'WorkflowTemplateNodeDetail.minRequestedVoters.tooltip': 'Minimum number of resolvers who must vote.',
  'WorkflowTemplateNodeDetail.maxRequestedVoters.tooltip': 'Maximum number of solvers who can vote.',
  'WorkflowTemplateNodeDetail.commonRequestedVoters.tooltip': 'Required number of voters.',
    'WorkflowTemplateNodeDetail.requestedParallelBranches': 'Required parallel count',
  'WorkflowTemplateNodeDetail.requestedParallelBranches.tooltip': 'Required minimum number of running branches',
  'WorkflowTemplateNodeDetail.name': 'Task name',
  'WorkflowTemplateNodeDetail.node': 'Node',
  'WorkflowTemplateNodeDetail.description': 'Task description',
  'WorkflowTemplateNodeDetail.duration': 'Deadline',
  'WorkflowTemplateNodeDetail.noNodeSelected': 'No task selected!',
  'WorkflowTemplateNodeDetail.timeoutAction.title': 'Action after the deadline',
  'WorkflowTemplateNodeDetail.timeoutAction.wait': 'No action',
  'WorkflowTemplateNodeDetail.timeoutAction.selectDefault': 'Use specific output',
  'WorkflowTemplateNodeDetail.timeoutAction.closeWorkflow': 'Terminate the workflow instance',
  'WorkflowTemplateNodeDetail.backgroundDocument.title': 'Background documents',
  'WorkflowTemplateNodeDetail.backgroundDocument.addButton': 'Add request',
  'WorkflowTemplateNodeDetail.backgroundDocument.defineDefaultFolderTooltip': 'For the ability to add a request to the background document, define a default folder',
  'WorkflowTemplateNodeDetail.backgroundDocument.addBackgroundDocumentTooltip': 'Add a request to the background document',
  'WorkflowTemplateNodeDetail.backgroundDocument.responsibleRolePopover': 'Responsible person (role)',
  'WorkflowTemplateNodeDetail.backgroundDocument.roleWithoutUser': 'Role without a user',
  'WorkflowTemplateNodeDetail.backgroundDocument.modalTitle': 'Add a request to the background document',
  'WorkflowTemplateNodeDetail.documentNewState': 'New state',
  'WorkflowTemplateNodeDetail.notification.messageTitle': 'Subject',
  'WorkflowTemplateNodeDetail.notification.messageTitle.placeholder': 'Notification from Workflow Approval Process',
  'WorkflowTemplateNodeDetail.notification.messageBody': 'Message body',
  'WorkflowTemplateNodeDetail.notification.recipients': 'Recipients',
  'WorkflowTemplateNodeDetail.notification.recipients.description': 'Select at least one role or user',
  'WorkflowTemplateNodeDetail.notification.recipients.roles': 'Roles as recipients',
  'WorkflowTemplateNodeDetail.notification.recipients.users': 'Users as recipients',
  'WorkflowTemplateNodeDetail.notification.settingsTitle': 'Message settings',
  'WorkflowTemplateNodeDetail.permittedActions.title': 'Permitted actions on task',
  'WorkflowTemplateNodeDetail.permittedActions.removeDocument': 'Remove document',
  'WorkflowTemplateNodeDetail.permittedActions.addDocument': 'Add document',
  'WorkflowTemplateNodeDetail.permittedActions.signDocument': 'Sign document',
  'WorkflowTemplateNodeDetail.permittedActions.addRevision': 'Add new document revision',
  'WorkflowTemplateNodeDetail.permittedActions.stateFromNode': 'Other than the default settings of added document/revision states',
  'WorkflowTemplateNodeDetail.permittedActions.stateWorkInProgress': 'Work in progress',
  'WorkflowTemplateNodeDetail.permittedActions.stateShared': 'Shared',
  'WorkflowTemplateNodeDetail.permittedActions.statePublish': 'Published',
  'WorkflowTemplateAddBackgroundDocumentForm.requestTitle': 'Request name',
  'WorkflowTemplateAddBackgroundDocumentForm.requestDescription': 'Request description',
  'WorkflowTemplateNodeDetail.backgroundDocument.date': `{count, plural,
    =0 {Date: # days}
    one {Date: # day}
    few {Date: # days}
    other {Date: # days}
  } `,
'WorkflowTemplateNodeDetail.deadlineExtension.setting':'Deadline extension - setting',
'WorkflowTemplateNodeDetail.deadlineExtension.action.forbidden':'Extension prohibited',
'WorkflowTemplateNodeDetail.deadlineExtension.action.finishOnTime':'Continue after deadline',
'WorkflowTemplateNodeDetail.deadlineExtension.action.waitToDecision':'Waiting for a statement',
  'WorkflowTemplateAddBackgroundDocumentForm.folder': 'Folder for uploading documents',
  'WorkflowTemplateAddBackgroundDocumentForm.responsiblePerson': 'Responsible person (role)',
  'WorkflowTemplateAddBackgroundDocumentForm.requiredDate': 'Required date (days)',
  'WorkflowTemplateAddBackgroundDocumentForm.requestTitle.required': 'Please fill in the name of the request',
  'WorkflowTemplateAddBackgroundDocumentForm.requestTitle.duplicateName': 'This name is already used in another request',
  'WorkflowTemplateAddBackgroundDocumentForm.requiredDate.required': 'Please fill in the required date',
  'WorkflowTemplateAddBackgroundDocumentForm.folder.required': 'Please select a folder to upload document',
  'WorkflowTemplateNodeDetail.backgroundDocument.responsiblePerson.required': 'Please select a responsible role',
  'WorkflowTemplateNodeDetail.otherPortsName': 'Output',
  'WorkflowTemplateErrors.severity.Error': 'Error',
  'WorkflowTemplateErrors.severity.Warning': 'Warning',
  'WorkflowTemplateExportFormModal.title': 'Export of templates',
  'WorkflowTemplateListPageToolbar.export': 'Export templates',
  'WorkflowTemplateExportModal.submitButtonText': 'Export templates',
  'WorkflowTemplateExportButton.tooltip': 'Export template',
  'WorkflowTemplateExportForm.label': `{count, plural,
    one {Export 1 selected template to JSON file}
    few {Export # selected templates to JSON file}
    other {Export # selected templates to JSON file}
  }`,
  'WorkflowTemplateExportForm.nameLabel': 'Name of file',
  'WorkflowTemplateExportForm.archiveNameRules': 'Please enter a name for the file',
  'WorkflowTemplateCopyCreateFormModal.formTitle': 'Make copy of the template',
  'WorkflowTemplateCopyCreateFormModal.submitButtonText': 'Make copy',
  'WorkflowTemplateCopyCreateForm.copyInformation': 'You are making copy of the template: ',
  'WorkflowTemplateCopyCreateForm.copyName': 'Name of copy',
  'WorkflowTemplateCopyCreateForm.alreadyExistName': 'Entered name is already used, please choose another name',
  'WorkflowTemplateCopyCreateForm.description': 'Description',
  'WorkflowTemplatePage.saveAsCopy.buttonText': 'Create copy',
  'WorkflowTemplateExportDefaultFilename': 'Templates',
  'AllDocumentsPageFilterToolbar.WorkflowBackgroundDocumentFilterLabel': 'Name of task',
  'WorkflowTemplateCheckImportNamesAndRolesFormModal.title': 'Setting names, roles and directories for imported templates',
  'WorkflowTemplateCheckImportNamesAndRolesForm.templateTitle': 'Template name',
  'WorkflowTemplateCheckImportNamesAndRolesForm.templateTitle.required': 'Please fill in the name of the template',
  'WorkflowTemplateCheckImportNamesAndRolesForm.templateTitle.alreadyExist': 'Template with this name already exists',
  'WorkflowTemplateCheckImportNamesAndRolesForm.templateTitle.dontDuplicate': 'Template name must be unique',
  'WorkflowTemplateCheckImportNamesAndRolesForm.roleAssignment': 'Role assignment',
  'WorkflowTemplateCheckImportNamesAndRolesForm.userAssignment': 'User assignment',
  'WorkflowTemplateCheckImportNamesAndRolesForm.checkRecipients': 'Check the recipients on the Alerts task.',
  'WorkflowTemplateCheckImportNamesAndRolesForm.defaultDirectories': 'Default directories',
  'WorkflowTemplateListPageToolbar.delete.confirm': 'Really delete these templates?',
  'WorkflowTemplateListPageToolbar.jsonFilesOnly': 'Only a JSON file can be inserted',
  'WorkflowTemplateListPageToolbar.checkImport.noValidFile': 'Imported template validation failed',
  'WorkflowTemplateListPageToolbar.checkImport.noEmptyFile': 'Imported file does not contain any templates',
  'WorkflowTemplateListPageToolbar.checkImport.importFailed': 'Import failed',
  'WorkflowTemplateListPageToolbar.checkImport.importButtonText': 'Import a template',
  'WorkflowTemplateListPageToolbar.checkImport.modalLabel': 'Import templates (*.json)',
  'WorkflowTemplate.DocumentStateSetting.placeholder': 'Select a new document state',
  'WorkflowSubWorkflowsSettings.title': 'Branches',
  'WorkflowSubWorkflowsSettings.addBranch': 'Add branch',
  'WipRecordDetailDataProvider.commonZP.title': 'Common work in progres report',
  'NodeOutputSettings.outputs': 'Outputs',
  'NodeOutputSettings.addOutput': 'Add output',
  'ZBVDetail.Title': 'Detail of the change sheet',
  'ZbvDetail.additionalInformation': 'Additional Information',
  'ZbvDetail.overview': 'Overview',
  'ZbvDetailAdditionalInformation.responsiblePersons': 'Responsible persons',
  'ZbvDetailAdditionalInformation.contractor': 'Contractor (construction manager)',
  'ZbvDetailAdditionalInformation.employee.priceNegotiation': 'Employee (price negotiation)',
  'ZbvDetailAdditionalInformation.designer': `Designer (author's supervision)`,
  'ZbvDetailAdditionalInformation.employee.statementOnZBV': 'Employee (statement on ZBV)',
  'ZbvDetailAdditionalInformation.constructionSupervision': 'Construction supervision',
  'ZbvDetailAdditionalInformation.employeeFunctions': 'Employee functions (statement)',
  'ZbvDetailAdditionalInformation.constructionManager': 'Construction manager',
  'ZbvDetailAdditionalInformation.authorizedPerson': 'Authorized person',
  'ZbvDetailAdditionalInformation.supervision': 'Supervision',
  'ZbvDetailAdditionalInformation.justification': 'Justification',
  'ZbvDetailAdditionalInformation.descriptionOfChanges': 'Description of changes',
  'ZbvDetailOverview.additionalInformation': 'Additional Information',
  'ZbvDetailOverview.approval': 'Approval',
  'ZbvDetailOverview.changeOrderClassification': 'Change order classification',
  'ZbvDetailOverview.chart.changedItems': 'Changed items',
  'ZbvDetailOverview.chart.newItems': 'New items',
  'ZbvDetailOverview.code': 'Code',
  'ZbvDetailOverview.contractualAmendment': 'Contractual amendment',
  'ZbvDetailOverview.valorization': 'Change order valorization',
  'ZbvDetailOverview.graf.newItems': 'New items',
  'ZbvDetailOverview.graf.changedItems': 'Changed items',
  'ZbvDetailOverview.grouping': 'Grouping',
  'ZbvDetailOverview.issue': 'Issued',
  'ZbvDetailOverview.moreWork': 'More work',
  'ZbvDetailOverview.lessWork': 'Less Work',
  'ZbvDetailOverview.name': 'Name',
  'ZbvDetailOverview.price': 'Price',
  'ZbvDetailOverview.pricesWithVAT': 'Prices including VAT',
  'ZbvDetailOverview.overview': 'Overview',
  'ZbvDetailOverview.recapitulation': 'Recapitulation',
  'ZbvDetailOverview.taxBase': 'Tax base',
  'ZbvDetailOverview.totalPrice': 'Total price',
  'ZbvDetailOverview.type': 'Type',
  'ZbvDetailOverview.unclassified': 'Unclassified',
  'ZbvDetailOverview.PricesWithVAT': 'Prices including VAT',
  'ZbvDetailOverview.rateDPH': '{percent} %',
};
