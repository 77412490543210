import { Form, Input, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { apiConstraints } from 'api/completeApiConstraints';
import {
  DirectoryContentDto,
  ProjectUserProfileDto,
  RoleDto,
  WorkflowStateEnum,
  WorkflowTemplateListDto,
  WorkflowTemplateStateEnum,
} from 'api/completeApiInterfaces';
import { DocumentSelectDocumentState } from 'components/DocumentSelect/DocumentSelect';
import { DocumentsSelectMultipleFormItem } from 'components/DocumentSelect/FormItem/DocumentSelectMultipleFormItem';
import { LabelsInput } from 'components/LabelsInput/LabelsInput';
import { Margin } from 'components/Margin/Margin';
import RevisionNumberTag from 'components/RevisionNumberTag';
import { RoleSelect } from 'components/RoleSelect/RoleSelect';
import StackPanel from 'components/StackPanel';
import { WorkflowInstantiationDirectoryAssign } from 'components/WorkflowInstantiationDirectoryAssign/WorkflowInstantiationDirectoryAssign';
import { useCurrentProjectUser, useIntl, useSelectorDispatch } from 'hooks';
import { useIsDirectoryIdValid } from 'hooks/useIsDirectoryIdValid';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { InjectedIntl } from 'locale';
import { getWorkflowAllowedDocumentStates } from 'pages/WorkflowDetailPage/DetailTabs/ActiveTaskDetail/DetailTaskComponents/AddedRevisions';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  duplicateNameRule,
  justAllowedDocumentStatesRule,
  maxLengthRule,
  requiredRule,
  simpleSelectFilter,
} from 'utils/formHelpers';
import { ConnectedDirectory } from 'utils/typeMappings/directories/directoryTypes';
import { DisabledWithReason } from 'utils/types';
import { isCommentWorkflowDocumentDisabled } from '../CommentProcedureAddForm/CommentProcedureAddForm';
import { availableRolesForWorkflow } from './WorkflowInstantiationButton';

export type WorkflowInstantiationFormData = {
  templateId: Guid;
  name: string;
  description: string;
  labels: Guid[];
  approvalDocuments: DocumentSelectDocumentState[];
  defaultDirectories: Record<Guid, Guid>;
  workflowRole: Guid;
  message: string;
};

export type WorkflowInstantiationFormConfiguration = {
  availableTemplates: WorkflowTemplateListDto[]; // filtered list of templates
  initialTemplate?: Guid; // pre-selected template when creating from template page
  startDirectoryId?: Guid; // start file selection in this directory
  initialDocuments?: DocumentSelectDocumentState[]; // pre-selected files when creating from selected documents;
};

const defaultWorkflowRoleField: Partial<WorkflowInstantiationFormData> = { workflowRole: undefined };

type Props = WorkflowInstantiationFormConfiguration & {};

export const isWorkflowDocumentDisabled = (
  document: DirectoryContentDto,
  directory: ConnectedDirectory,
  currentUser: ProjectUserProfileDto,
  intl: InjectedIntl,
  allowedDocumentStates: WorkflowStateEnum[] = [],
  workflowId?: Guid
): DisabledWithReason => {
  if (workflowId && document.workflowId === workflowId) {
    return intl.formatMessage({ id: 'DocumentSelect.disabledMessage.documentInThisWorkflow' });
  }

  if (!!allowedDocumentStates.length && !!document?.state && !allowedDocumentStates.includes(document.state)) {
    return intl.formatMessage({ id: 'DocumentSelect.disabledMessage.notAllowedDocumentState' });
  }

  return isCommentWorkflowDocumentDisabled(document, directory, currentUser, intl);
};

const WorkflowInstantiationForm: FC<Props> = ({
  availableTemplates,
  initialTemplate,
  startDirectoryId,
  initialDocuments,
}) => {
  const intl = useIntl();
  const form = useFormInstance();
  const [selectedTemplateId, setSelectedTemplateId] = useState<Guid>(initialTemplate);
  const inputRef = useRef<HTMLDivElement>();
  const getPopupContainer = useCallback(() => inputRef.current, []);

  const formTemplateId = useWatch('templateId');
  const workflowRole = useWatch('workflowRole');

  const existingWorkflows = useSelectorDispatch(
    (state) => state.workflows.data,
    (dispatch) => dispatch.workflows.loadData({ reload: false })
  );

  useDirtyStoreReload(
    (store) => store.workflows,
    (dispatch) => dispatch.workflows
  );

  const allowedDocumentStates = useMemo(() => {
    const selectedTemplate = availableTemplates.find((template) => template.id === formTemplateId);
    return getWorkflowAllowedDocumentStates(
      selectedTemplate?.publishedPermitted,
      selectedTemplate?.sharedPermitted,
      selectedTemplate?.workInProgressPermitted
    );
  }, [availableTemplates, formTemplateId]);

  // which files are disabled for select and for what reason
  const currentUser = useCurrentProjectUser();
  const disabledDocuments = useCallback(
    (document: DirectoryContentDto, directory: ConnectedDirectory) =>
      isWorkflowDocumentDisabled(document, directory, currentUser, intl, allowedDocumentStates),
    [allowedDocumentStates, currentUser, intl]
  );

  const isDirectoryIdValid = useIsDirectoryIdValid();

  // get directory aliases of current workflow tempalte
  const directoryAliases = useMemo(() => {
    return availableTemplates
      ?.find((e) => e.id === formTemplateId)
      ?.workflowTemplateDirectoryAliases?.map((alias) => ({
        directoryId: isDirectoryIdValid(alias?.directoryId) ? alias?.directoryId : null,
        id: alias.id,
        name: alias.name,
        description: alias?.description,
      }));
  }, [availableTemplates, formTemplateId, isDirectoryIdValid]);

  // only user's roles are selectable
  const availableRoles = useMemo(() => {
    const template = availableTemplates?.find((e) => e.id === formTemplateId);
    return availableRolesForWorkflow(template, currentUser);
  }, [availableTemplates, formTemplateId, currentUser]);
  const isRoleDisabled = useCallback((role: RoleDto) => !availableRoles.has(role.id), [availableRoles]);

  // de-select selected role if it is not available in new workflow
  useEffect(() => {
    if (!availableRoles.has(workflowRole)) {
      form.setFieldsValue(defaultWorkflowRoleField);
    }
  }, [formTemplateId, isRoleDisabled]);

  const selectTemplateFormItemTitle = useMemo(() => {
    return (
      <>
        {intl.formatMessage({ id: 'WorkflowInstantiationForm.template' })}
        <StackPanel>
          {allowedDocumentStates?.map((state) => (
            <Margin right key={state}>
              <RevisionNumberTag state={state} />
            </Margin>
          ))}
        </StackPanel>
      </>
    );
  }, [allowedDocumentStates, intl]);

  const defaultDirectoriesInitialValues: Record<Guid, Guid> = useMemo(
    () =>
      formTemplateId &&
      Object.fromEntries(
        availableTemplates
          .find((id) => id.id === formTemplateId)
          .workflowTemplateDirectoryAliases.map((alias) => [alias.id, alias.directoryId])
      ),
    [formTemplateId, availableTemplates]
  );

  const initialLabelsValue = useMemo(
    () => availableTemplates.find((template) => template.id === initialTemplate)?.labels || [],
    [initialTemplate, availableTemplates]
  );

  useEffect(() => {
    setSelectedTemplateId(formTemplateId);
  }, [formTemplateId]);

  useEffect(() => {
    const currentTemplateLabels =
      availableTemplates.find((template) => template.id === selectedTemplateId)?.labels || [];

    form.setFieldsValue({ labels: currentTemplateLabels });
  }, [availableTemplates, selectedTemplateId]);

  return (
    <div ref={inputRef}>
      <>
        <Form.Item
          label={intl.formatMessage({ id: 'WorkflowInstantiationForm.name' })}
          name="name"
          rules={[
            requiredRule('WorkflowInstantiationForm.name.required', true),
            maxLengthRule('general.maxNameLength', apiConstraints.workflowInstantiationDto.instanceName.maxLength),
            duplicateNameRule(
              'forms.items.name.rules.nameExists',
              existingWorkflows?.map((workflow) => workflow.instanceName),
              true
            ),
          ]}
        >
          <Input
            maxLength={apiConstraints.workflowInstantiationDto.instanceName.maxLength}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'WorkflowInstantiationForm.description' })}
          name="description"
          rules={[
            maxLengthRule(
              'general.maxDescriptionLength',
              apiConstraints.workflowInstantiationDto.instanceDescription.maxLength
            ),
          ]}
        >
          <Input.TextArea rows={3} maxLength={apiConstraints.workflowInstantiationDto.instanceDescription.maxLength} />
        </Form.Item>
        <Form.Item
          label={selectTemplateFormItemTitle}
          name="templateId"
          initialValue={initialTemplate}
          rules={[requiredRule('WorkflowInstantiationForm.template.required')]}
        >
          <Select allowClear={false} showSearch filterOption={simpleSelectFilter} getPopupContainer={getPopupContainer}>
            {availableTemplates
              ?.filter((t) => t.state === WorkflowTemplateStateEnum.valid)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((e) => (
                <Select.Option key={e.id} value={e.id} title={e.description}>
                  {e.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'forms.items.labels.label' })}
          name="labels"
          initialValue={initialLabelsValue}
        >
          <LabelsInput />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'WorkflowInstantiationForm.approvalDocuments' })}
          name="approvalDocuments"
          initialValue={initialDocuments || []}
          rules={[
            justAllowedDocumentStatesRule(
              'forms.items.addDocumentsToWokrflow.justAllowedDocuments',
              allowedDocumentStates
            ),
          ]}
        >
          <DocumentsSelectMultipleFormItem
            disabledDocuments={disabledDocuments}
            startDirectoryId={startDirectoryId}
            titleId="WorkflowInstantiationForm.approvalDocuments"
          />
        </Form.Item>
        {directoryAliases?.length > 0 && (
          <Form.Item
            label={intl.formatMessage({ id: 'WorkflowInstantiationForm.defaultDirectories' })}
            name="defaultDirectories"
            initialValue={{}}
            rules={[
              {
                validator: (rule: any, value: Record<Guid, Guid>) => {
                  return Object.keys(value).some(
                    (directoryAliasId) => !directoryAliases.some((alias) => alias.id === directoryAliasId)
                  ) || Object.values(value).filter((v) => v !== null).length < directoryAliases.length
                    ? Promise.reject(
                        intl.formatMessage({ id: 'WorkflowInstantiationForm.defaultDirectories.required' })
                      )
                    : Promise.resolve();
                },
                required: true,
              },
            ]}
          >
            <WorkflowInstantiationDirectoryAssign
              directoryAliases={directoryAliases}
              initialFolders={defaultDirectoriesInitialValues}
              intl={intl}
            />
          </Form.Item>
        )}
        <Form.Item
          label={intl.formatMessage({ id: 'WorkflowInstantiationForm.workflowRole' })}
          name="workflowRole"
          rules={[requiredRule('WorkflowInstantiationForm.workflowRole.required')]}
        >
          <RoleSelect hiddenRoles={isRoleDisabled} selectDefault getPopupContainer={getPopupContainer} />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'WorkflowInstantiationFormModal.messageLabel' })}
          name="message"
          rules={[]}
        >
          <Input.TextArea
            rows={3}
            placeholder={intl.formatMessage({ id: 'WorkflowInstantiationFormModal.messagePlaceholder' })}
            maxLength={1000}
          />
        </Form.Item>
      </>
    </div>
  );
};

export default WorkflowInstantiationForm;
