import { ExportOutlined } from '@ant-design/icons';
import { DocumentContentDto } from 'api/completeApiInterfaces';
import {
  AddBindingIcon,
  AddIcon,
  AddToFavoriteIcon,
  AnnotateIcon,
  DeleteIcon,
  EditIcon,
  FileAddIcon,
  FileDownloadIcon,
  FileWithAnnotationDownloadIcon,
  NewTaskIcon,
  RemoveFromFavoriteIcon,
  ShareAppUsersIcon,
  ShareDownloadIcon,
  ShareIcon,
  SignDocumentIcon,
  UploadIcon,
} from 'components/Icons/HubActionsIcons';
import { ModelOffIcon, ModelOnIcon, ShortcutIcon } from 'components/Icons/HubEntitiesIcons';
import ShrinkableToolbar, { ToolbarItemProps } from 'components/Toolbar/ShrinkableToolbar';
import Toolbar from 'components/Toolbar/Toolbar';
import { useCurrentProjectUser, useIntl, useReserveDocumentButton } from 'hooks';
import { InjectedIntl } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import { DocumentDetailActionsType, useModalContext } from 'pages/DocumentDetailPage/context/DocumentActionsContext';
import { useDocumentDetailContext } from 'pages/DocumentDetailPage/context/DocumentDetailContext';
import React, { FunctionComponent, ReactNode, useMemo } from 'react';

type DocumentDetailPageToolbarProps =
  | {
      key: DocumentDetailActionsType;
      icon: ReactNode;
      label: IntlMessageId;
      tooltip?: (intl: InjectedIntl, document: DocumentContentDto) => ReactNode;
      children?: undefined;
      onClick?: undefined;
    }
  | {
      key: string;
      action: DocumentDetailActionsType;
      icon: ReactNode;
      label: IntlMessageId;
      tooltip?: (intl: InjectedIntl, document: DocumentContentDto) => ReactNode;
      children?: undefined;
      onClick?: undefined;
    }
  | {
      key: string;
      icon: ReactNode;
      label: IntlMessageId;
      children?: undefined;
      disabled: boolean;
      tooltip?: (intl: InjectedIntl, document: DocumentContentDto) => ReactNode;
      onClick: () => void;
    }
  | {
      onClick?: undefined;
      key: string;
      icon: ReactNode;
      label: IntlMessageId;
      tooltip?: (intl: InjectedIntl, document: DocumentContentDto) => ReactNode;
      children: DocumentDetailPageToolbarProps[];
    };

const MAP_ITEM = (
  item: DocumentDetailPageToolbarProps,
  isDisabled: any,
  isRegistered: any,
  callAction: any,
  intl: any,
  document: any
): ToolbarItemProps | undefined => {
  if (!item.children?.length) {
    if (item.onClick) {
      return {
        key: item.key,
        icon: item.icon,
        label: item.label,
        onClick: item.onClick,
        // @ts-ignore
        disabled: item.disabled,
        tooltip: item.tooltip?.(intl, document),
      };
    }

    // @ts-ignore
    const actionKey = item.action || item.key;

    if (!isRegistered(actionKey)) return undefined;
    const disabled = !!isDisabled && isDisabled(actionKey);
    const result: ToolbarItemProps = {
      key: item.key,
      icon: item.icon,
      label: item.label,
      disabled: !!disabled,
      onClick: () => callAction(actionKey),
    };
    if (item.tooltip) {
      const tooltip = item.tooltip?.(intl, document);
      if (tooltip) result.tooltip = tooltip;
    } else if (typeof disabled === 'string') result.tooltip = isDisabled(actionKey);
    return result;
  } else {
    const children = item.children
      .map((item) => MAP_ITEM(item, isDisabled, isRegistered, callAction, intl, document))
      .filter(Boolean) as ToolbarItemProps[];
    return !!children?.length
      ? {
          key: item.key,
          icon: item.icon,
          label: item.label,
          subItems: children,
        }
      : undefined;
  }
};

export const DocumentDetailPageToolbar: FunctionComponent = () => {
  const intl = useIntl();
  const { isRegistered, isDisabled, callAction } = useModalContext<DocumentDetailActionsType>();
  const { document, directory, loadDocument } = useDocumentDetailContext();
  const currentUser = useCurrentProjectUser();

  const reserveDocument = useReserveDocumentButton('reserveDocument', document, directory, currentUser, () => {
    void loadDocument();
  });

  const items: ToolbarItemProps[] = useMemo(() => {
    const itemsTemplate: DocumentDetailPageToolbarProps[] = [
      {
        key: 'CREATE_REVISION',
        icon: <FileAddIcon />,
        label: 'DocumentDetailPage.createRevision',
        tooltip: (intl: InjectedIntl, document: DocumentContentDto) =>
          intl.formatMessage({
            id: `DocumentDetailPage.createRevision.tooltip.${document.addRevisionState}`,
          }),
      },
      {
        key: 'download',
        icon: <FileDownloadIcon />,
        label: 'general.download',
        children: [
          {
            key: 'DOWNLOAD_ORIGINAL',
            icon: <FileDownloadIcon />,
            label: 'general.docMenu.downloadOriginal',
          },
          {
            key: 'DOWNLOAD_WITH_ANNOTATIONS',
            icon: <FileWithAnnotationDownloadIcon />,
            label: 'general.docMenu.downloadWithAnnotations',
          },
          {
            key: 'DOWNLOAD_SIGNED',
            icon: <SignDocumentIcon />,
            label: 'general.docMenu.downloadSignedDocument',
          },
        ],
      },
      {
        key: 'share',
        icon: <ShareIcon />,
        label: 'general.docMenu.share',
        children: [
          {
            key: 'SHARE_DOWNLOAD',
            icon: <ShareDownloadIcon />,
            label: 'general.docMenu.shareDownload',
          },
          {
            key: 'SHARE_APP_USER_DOWNLOAD',
            icon: <ShareAppUsersIcon />,
            label: 'general.docMenu.shareAppUserDownload',
          },
        ],
      },
      {
        key: 'DISCARD',
        icon: <DeleteIcon />,
        label: 'AllDocumentsPage.docMenu.discard',
      },
      {
        key: 'EDIT',
        action: isPdf(document?.primaryFile) ? 'ANNOTATION_EDIT' : 'ONLINE_EDIT',
        icon: <EditIcon />,
        label: 'AllDocumentsPage.docMenu.editOnline',
      },
      {
        key: 'ANNOTATION_EDIT',
        icon: <AnnotateIcon />,
        label: 'AllDocumentsPage.docMenu.annotate',
      },
      {
        key: 'sign',
        icon: <SignDocumentIcon />,
        label: 'AllDocumentsPage.docMenu.signDocument',
        children: [
          {
            key: 'DOCUMENT_SIGN_ONLINE',
            icon: <SignDocumentIcon />,
            label: 'general.docMenu.signOnline',
          },
          {
            key: 'DOCUMENT_SIGN_LOCAL',
            icon: <UploadIcon />,
            label: 'general.docMenu.signLocally',
          },
        ],
      },
      {
        key: 'RESERVE_DOCUMENT',
        icon: reserveDocument.icon,
        label: reserveDocument.label,
        disabled: reserveDocument.disabled,
        onClick: reserveDocument.onClick,
        tooltip: () => reserveDocument.tooltip,
      },
      {
        key: 'CREATE_ASSIGNMENTS',
        icon: <NewTaskIcon />,
        label: 'AllDocumentsPage.docMenu.createAssignment',
      },
      {
        key: 'FAVORITE',
        icon: document?.isFavorite ? <RemoveFromFavoriteIcon /> : <AddToFavoriteIcon />,
        label: document?.isFavorite ? 'FavoriteButton.unset' : 'FavoriteButton.set',
      },
      {
        key: 'IS_MODEL',
        icon: document?.isModel ? <ModelOnIcon /> : <ModelOffIcon />,
        label: document?.isModel ? 'DocumentDetailPage.model.remove' : 'DocumentDetailPage.model.add',
      },
      {
        key: 'CREATE_DIRECTORY_LINK',
        icon: <ShortcutIcon />,
        label: 'general.dirMenu.createDirectoryLink',
      },
      {
        key: 'ADD_NEW_COMMENT',
        icon: <AddIcon />,
        label: 'CommentProcedureDetailPage.newComment',
      },
      {
        key: 'ADD_BINDING',
        icon: <AddBindingIcon />,
        label: 'DocumentDetailPageBindings.addBinding',
      },
      {
        key: 'ADD_REFERENCE',
        icon: <ExportOutlined />,
        label: 'DocumentDetailPageReferences.addReferences',
      },
    ];

    return itemsTemplate
      .map((item) => MAP_ITEM(item, isDisabled, isRegistered, callAction, intl, document))
      .filter(Boolean) as ToolbarItemProps[];
  }, [isDisabled, isRegistered, callAction, intl, document, reserveDocument]);

  return (
    <Toolbar>
      <ShrinkableToolbar items={items} />
    </Toolbar>
  );
};

export function isPdf(primaryFile: { contentType?: string | undefined } | undefined) {
  return primaryFile?.contentType === 'application/pdf';
}
