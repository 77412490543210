import { Form, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { api } from 'api';
import { DirectoryDto, ServiceError } from 'api/completeApiInterfaces';
import CategoriesList from 'components/CategoriesList/CategoriesList';
import DocumentCategoryForm from 'components/forms/DocumentCategoryForm/DocumentCategoryForm';
import { EditIcon } from 'components/Icons/HubActionsIcons';
import SettingsItem from 'components/SettingsItem/SettingsItem';
import { DEBUG } from 'config/env';
import { useIntl } from 'hooks';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch, RootState } from 'store';
import { legacyMapDispatchToProps } from 'store/models/storeModelinterfaces';
import { categoryListSelector, categoryMapSelector } from 'store/selectors';
import { processApiError } from 'utils';

type DictionaryCategoriesFormData = {
  categories: { nodeId: string; categoryId: string }[];
};

const mapStateToProps = (state: RootState) => ({
  categoryMap: categoryMapSelector(state),
  categoryList: categoryListSelector(state),
  categoryError: state.categories.error,
  categoryLoading: state.categories.loading,
  categoryTrees: state.categoryTrees,
  hasDirtyCategories: state.categories.dirty,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadCategories: dispatch.categories.loadData,
});

type PropsFromState = ReturnType<typeof mapStateToProps>;

type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

type Props = InjectedIntlProps &
  PropsFromDispatch &
  PropsFromState & {
    directory: DirectoryDto;
    initLoading: boolean;
    onCategoriesChange?: () => void;
    editDisabled?: boolean;
  };

const DirectorySettingsRequiredCategories: FC<Props> = ({
  loadCategories,
  hasDirtyCategories,
  directory,
  categoryMap,
  initLoading,
  categoryTrees,
  categoryList,
  onCategoriesChange,
  editDisabled = false,
}) => {
  const [form] = useForm();
  const intl = useIntl();

  const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<ServiceError | null>(null);

  const handleCancel = () => {
    setEditMode(false);
    setError(null);
  };

  useEffect(() => {
    if (hasDirtyCategories) loadCategories({ reload: false, silent: true });
  }, [hasDirtyCategories, loadCategories]);

  const handleSave = useCallback(() => {
    form
      .validateFields()
      .then(async (values: DictionaryCategoriesFormData) => {
        if (values !== undefined) {
          setSaving(true);
          const [err, res] = await api.project.directories.patchDirectory(directory.id, {
            requiredCategoryTrees: values.categories.reduce(
              (previousValue, currentValue) => ({ ...previousValue, [currentValue.categoryId]: currentValue.nodeId }),
              {}
            ),
          });
          if (err) {
            processApiError(err, (error) => {
              setSaving(false);
              setError(error); // error is not used
              message.error(error.message);
            });
            return;
          }
          onCategoriesChange && (await onCategoriesChange());
        }
        setEditMode(false);
        setSaving(false);
      })
      .catch((err) => {
        DEBUG && console.error(err);
      });
  }, [directory, form, onCategoriesChange]);

  const categories = useMemo(() => {
    return directory
      ? Object.keys(directory.requiredCategoryTrees)
          .map((categoryId) => categoryMap[categoryId])
          .filter((category) => !!category)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((c) => ({
            defaultCategoryNodeId: undefined,
            categoryId: c.id,
            required: false,
          }))
      : [];
  }, [directory, categoryMap]);

  return (
    <SettingsItem
      editingChildren={
        <Form form={form} layout="vertical">
          <DocumentCategoryForm
            categoryTrees={categoryTrees}
            categoryTreeNodes={directory.requiredCategoryTrees}
            autoSelectDefault
            categories={categories}
            categoryList={categoryList}
            categoryMap={categoryMap}
            selectPlaceholder={intl.formatMessage({ id: 'DirectorySettingsForm.requiredCategories.placeholder' })}
          />
        </Form>
      }
      settingsIcon={<EditIcon />}
      onSave={handleSave}
      onCancel={handleCancel}
      onEdit={
        !editDisabled &&
        (() => {
          setEditMode(true);
        })
      }
      editMode={editMode}
      saving={saving || initLoading}
      errorMessage={null}
      headline={<Fmt id="DirectorySettingsForm.requiredCategories.label" />}
      editTooltip={<Fmt id="DirectorySettingsForm.requiredCategories.tooltip" />}
    >
      <CategoriesList categories={directory.requiredCategoryTrees} />
    </SettingsItem>
  );
};

export default connect(
  mapStateToProps,
  legacyMapDispatchToProps(mapDispatchToProps)
)(injectIntl(DirectorySettingsRequiredCategories));
