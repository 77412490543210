import { trim } from 'lodash';

export const CSV_DELIMITERS = {
  coma: ',',
  tab: '\t',
  semicolon: ';',
  pipe: '|',
} as const;

export type CsvDelimiterValue = typeof CSV_DELIMITERS[keyof typeof CSV_DELIMITERS];
export type CsvDelimiterKey = keyof typeof CSV_DELIMITERS;

export const DEFAULT_CSV_DELIMITER_KEY: CsvDelimiterKey = 'coma';
export const DEFAULT_CSV_DELIMITER = CSV_DELIMITERS[DEFAULT_CSV_DELIMITER_KEY];

export const formatCsvDisplayValue = (displayValue: string, separator: CsvDelimiterValue) => {
  if (!displayValue || typeof displayValue !== 'string') {
    return '';
  }
  return displayValue.includes(separator) ? `"${displayValue.replace('"', '""')}"` : displayValue;
};

export const formatCsvRow = (items: string[], separator: CsvDelimiterValue) =>
  items.map((item) => formatCsvDisplayValue(item, separator)).join(separator);

export const formatCsvFile = (header: string, rows: string[]) => [header, ...rows].join('\n');

export const clearFilenameValue = (value: string) => value.replace(/[/\\:*?"<>]/g, '');

export const parseCsvRow = (line: string, delimiter: CsvDelimiterValue = ';') => {
  if (delimiter?.length !== 1) {
    throw new Error('Delimiter must be a single character');
  }

  const csvParseRegex = new RegExp(
    // Hodnota začínající a končící uvozovkou, escapované uvozovky uvnitř jsou dvě uvozovky
    `"([^"]*(?:""[^"]*)*)"|` +
      // Nebo hodnota bez uvozovek
      `[^${delimiter}\\n]+`,
    'g'
  );

  const row = [];
  let match;
  while ((match = csvParseRegex.exec(line)) !== null) {
    if (match[1]) {
      // Pokud jsou escapované uvozovky, nahradíme je zpět jednou uvozovkou
      row.push(match[1].replace(/""/g, '"'));
    } else {
      row.push(trim(match[0], '"')); // Hodnota bez uvozovek
    }
  }
  return row;
};

export const parseCsvFile = (content: string, delimiter: CsvDelimiterValue) => {
  const allTextLines = content.split(/\r?\n|\r|\n/g).filter(Boolean);
  const lineArrays = allTextLines.map((line) => parseCsvRow(line, delimiter));
  const headers = lineArrays.shift();

  return [headers, lineArrays] as const;
};
