import { Modal, Typography } from 'antd';
import { api } from 'api';
import { EntityTypesEnum, ProjectUserProfileStatusEnum, RoleDto } from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import { AuditLogButton, EditButton } from 'components/ActionButtons';
import { DeleteButtonConfirm } from 'components/ActionButtons/DeleteButtonConfirm';
import { AuditLogEntityModal } from 'components/AuditLogsComponents';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import { Margin } from 'components/Margin/Margin';
import { RoleInProcedureError, RoleUsageError } from 'components/forms/RoleForm/RoleUsageError';
import { useDeleteItems, useVisibleState } from 'hooks';
import { Fmt, InjectedIntlProps, memoizeWithIntl } from 'locale';
import React, { FunctionComponent, useEffect } from 'react';
import { messageError, smartFilter } from 'utils';
import styles from './RolesList.module.less';

export const missingOrSuspendedUserInRole = (role: RoleDto) => {
  const isInvalidUser = !role?.user || role.user.status === ProjectUserProfileStatusEnum.suspended;
  const areInvalidAssociated = !role.roleAssociates?.some(
    (associated) =>
      associated.user.status === ProjectUserProfileStatusEnum.active ||
      associated.user.status === ProjectUserProfileStatusEnum.invited
  );
  return isInvalidUser && areInvalidAssociated;
};

type Props = InjectedIntlProps & {
  listData: RoleDto[];
  search: string;
  selectedId: Guid;
  onSelect?: (categoryId: Guid) => void;
  onEdit?: (categoryId: Guid) => void;
  onDelete?: (categoryId: Guid) => void;
  onClearSearch?: () => void;
};

const RolesList: FunctionComponent<Props> = ({
  intl,
  listData,
  search,
  selectedId,
  onSelect,
  onEdit,
  onDelete,
  onClearSearch,
}) => {
  const [deletingItems, handleDelete, error] = useDeleteItems<Guid>(
    intl,
    (id) => api.project.roles.deleteRoleById(id),
    onDelete,
    false
  );

  const [auditLogRole, auditLogVisible, setAuditLogRole, hideAuditLog] = useVisibleState<RoleDto>();

  useEffect(() => {
    if (error) {
      if (error.referenceErrorCode === ServiceErrorEnum.RoleInProcedureError) {
        const data = error.errorData as { roleErrors: RoleInProcedureError[] };
        Modal.error({
          title: intl.formatMessage({ id: 'RolePanel.Modal.CannotRemove.title' }),
          content: <RoleUsageError intl={intl} roleErrors={data.roleErrors} />,
        });
      } else {
        messageError(error, intl);
      }
    }
  }, [error, intl]);

  return (
    <GeneralSettingsContainer>
      <List<RoleDto>
        data={listData}
        search={search}
        filterItem={(item) => {
          return smartFilter(
            item.name + '\n' + (item.description || '') + '\n' + (item.user ? item.user?.username : ''),
            search
          );
        }}
        renderItem={(item) => (
          <GeneralSettingsItem
            key={item.id}
            selected={item.id === selectedId}
            onClick={onSelect ? () => onSelect(item.id) : null}
            title={<span className={styles.roleName}>{item.name}</span>}
            description={
              <>
                <Typography.Text strong>{item.user?.username}</Typography.Text>
                {!!item.roleAssociates?.length && (
                  <Margin top>{item.roleAssociates?.map((associated) => associated.user.username).toString()}</Margin>
                )}
              </>
            }
            additionalActions={
              <>
                <AuditLogButton onClick={() => setAuditLogRole(item)} />
                {onEdit && <EditButton onClick={() => onEdit(item.id)} />}
                {handleDelete && (
                  <DeleteButtonConfirm onConfirm={() => handleDelete(item.id)} loading={deletingItems.has(item.id)} />
                )}
              </>
            }
          />
        )}
        renderEmpty={(total, filtered) => <ListEmpty filtered={filtered} total={total} onClearSearch={onClearSearch} />}
      />
      <AuditLogEntityModal
        visible={auditLogVisible}
        label={<Fmt id="UserDetailPanel.RolesTab.activity" values={{ name: auditLogRole?.name }} />}
        entityType={EntityTypesEnum.role}
        entityId={auditLogRole?.id}
        onOk={hideAuditLog}
        deps={[auditLogRole]}
      />
    </GeneralSettingsContainer>
  );
};

export default memoizeWithIntl(RolesList);
