import { Button } from 'antd';
import { api } from 'api';
import { DownloadUrl } from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import DocumentRow from 'components/DocumentRow';
import { DocumentRowProps } from 'components/DocumentRow/DocumentRow';
import EmptyStyled from 'components/Empty/EmptyStyled';
import { EmptyGate } from 'components/EmptyGate/EmptyGate';
import { AddIcon } from 'components/Icons/HubActionsIcons';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { CSSProperties, Component, ReactNode, RefObject } from 'react';
import { AutoSizer, List, ListRowRenderer } from 'react-virtualized';
import styles from './DocumentsGrid.module.less';

export interface Pagination {
  pageSize: number;
  total: number;
  from: number;
  loadMore: () => void;
  loadingMore?: boolean;
}

export type DocumentsGridItemProps = Omit<
  DocumentRowProps,
  | 'onClickCheckbox'
  | 'getDownloadUrl'
  | 'onClick'
  | 'onThumbnailClick'
  | 'selected'
  | 'style'
  | 'onDownloadWithAnnotations'
>;

export const DOCUMENT_GRID_GET_DOCUMENT_URL = (id: Guid) => api.project.documents.getDocumentDownloadUrlById(id);

type OwnProps<T> = {
  selectedFilesIds: Set<Guid>;
  listRef: RefObject<List>;
  documents: T[];
  disableReserve?: boolean;
  disableSelect?: boolean;
  disableDownload?: boolean;
  spinning?: boolean;
  onThumbnailClick?: (id: Guid) => void;
  pagination?: Pagination;
  selectFile: (fileId: string, index: number) => void;
  transform: (item: T) => DocumentsGridItemProps;
  additionalButtons?: (document: T) => ReactNode;
  getDocumentUrl?: (id: Guid) => Promise<[ApiError, AxiosResponse<DownloadUrl>]>;
  revisionDateLabel?: IntlMessageId;
  selectItemOnClickEnabled?: boolean;
  onSignatureClick?: (documentId: Guid) => void;
  signatureDisabled?: boolean;
  reloadDocuments?: () => void;
  className?: string;
  style?: CSSProperties;
  clearFilters?: () => void;
  hasFilteredOutItems?: boolean;
  onDownloadWithAnnotations?: (id: Guid) => void;
  isModel?: boolean;
};

type Props<T> = OwnProps<T>;

type State = {
  items: DocumentsGridItemProps[];
};

class DocumentsGrid<T> extends Component<Props<T>, State> {
  constructor(props: Props<T>, state: State) {
    super(props, state);
    this.state = {
      items: this.mapItems(props.documents),
    };
  }

  componentDidUpdate(prevProps: Readonly<Props<T>>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevProps.documents !== this.props.documents) {
      this.props.listRef && this.props.listRef.current && this.props.listRef.current.forceUpdateGrid();
      this.setState({ items: this.mapItems(this.props.documents) });
    }
    if (prevProps.additionalButtons !== this.props.additionalButtons) {
      // TODO: rework this as a functional component instead
      this.props.listRef.current && this.props.listRef.current.forceUpdateGrid();
    }
  }

  mapItems = (documents: T[]) =>
    documents.map((document) => {
      return this.props.transform(document);
    });

  selectFileOnClick = (id: Guid, index: number) => {
    const { selectFile } = this.props;
    selectFile && selectFile(id, index);
  };

  rowRenderer: ListRowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) => {
    const {
      pagination,
      onThumbnailClick,
      disableReserve,
      disableSelect,
      disableDownload,
      selectedFilesIds,
      selectFile,
      additionalButtons,
      getDocumentUrl = DOCUMENT_GRID_GET_DOCUMENT_URL,
      revisionDateLabel,
      signatureDisabled,
      onSignatureClick,
      reloadDocuments,
      onDownloadWithAnnotations,
      isModel,
    } = this.props;
    const { items } = this.state;

    if (pagination !== undefined && index >= items.length) {
      return (
        <div style={style} key={key}>
          <Button
            type="primary"
            size="large"
            icon={<AddIcon />}
            loading={pagination.loadingMore}
            onClick={pagination.loadMore}
          >
            <Fmt
              id="ProjectSearchPage.loadMore"
              values={{
                count:
                  pagination.total - items.length > pagination.pageSize
                    ? pagination.pageSize
                    : pagination.total - items.length,
              }}
            />
          </Button>
        </div>
      );
    }
    if (!items || !items[index]) return null;

    const { id, ...hit } = items[index];
    return (
      <DocumentRow
        id={id}
        key={key}
        style={style}
        {...hit}
        getDownloadUrl={() => getDocumentUrl(id)}
        onClick={this.props.selectItemOnClickEnabled ? () => this.selectFileOnClick(id, index) : undefined}
        onClickCheckbox={() => selectFile && selectFile(id, index)}
        onThumbnailClick={onThumbnailClick}
        disableReserve={disableReserve || !this.props.reloadDocuments}
        disableSelect={disableSelect || hit.disableSelect}
        selected={selectedFilesIds.has(id)}
        additionalButtons={additionalButtons && additionalButtons(this.props.documents[index])}
        hideSelect={disableSelect}
        disableDownload={disableDownload}
        revisionDateLabel={revisionDateLabel}
        signatureDisabled={signatureDisabled}
        onSignatureClick={onSignatureClick}
        onReservationChange={reloadDocuments}
        onDownloadWithAnnotations={
          !!hit.primaryFile &&
          hit.primaryFile.hasPdfDerivate &&
          onDownloadWithAnnotations &&
          (() => onDownloadWithAnnotations(id))
        }
        isModel={hit.isModel}
      />
    );
  };

  renderList() {
    const { documents, pagination, style, className } = this.props;

    if (documents === null) return <div />;
    if (documents.length === 0) return <EmptyStyled />;
    const rowCount =
      pagination !== undefined && pagination.total > documents.length ? documents.length + 1 : documents.length;

    return (
      <AutoSizer style={style} className={className}>
        {({ width, height }) => (
          <List
            width={width}
            height={height}
            rowCount={rowCount}
            rowHeight={54}
            rowRenderer={this.rowRenderer}
            className={classNames(styles.list, 'scrollbar')}
            ref={this.props.listRef}
          />
        )}
      </AutoSizer>
    );
  }

  render() {
    const { documents } = this.props;
    if (documents === null) return <div />;
    if (documents.length === 0)
      return (
        <EmptyGate empty clearSearch={this.props.clearFilters} hasUnfilteredItems={this.props.hasFilteredOutItems} />
      );
    return this.renderList();
  }
}

export default DocumentsGrid;
