import { QuestionCircleOutlined } from '@ant-design/icons';
import { Checkbox, Form, Input, Radio, Select, Space, TreeSelect, Typography } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { DefaultOptionType } from 'antd/lib/select';
import { apiConstraints } from 'api/completeApiConstraints';
import { LanguageEnum, OrganizationStructureDto, OrgUserDto } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { Margin } from 'components/Margin/Margin';
import { useDispatchEffect, useIntl, useStoreSelector } from 'hooks';
import { Fmt } from 'locale';
import { useJobPositions } from 'pages/OrganizationsSettingPage/JobPositions/useJobPositions';
import React, { FC, useEffect, useMemo } from 'react';
import { organizationStructureOrderedListSelector } from 'store/selectors/organizationStrucureSelectors';
import { smartFilter } from 'utils';
import { maxLengthRule } from 'utils/formHelpers';

export type OrgUserEditFormData = {
  language: LanguageEnum;
  isAdmin: boolean;
  namesFromOrganization: boolean;
  firstname: string;
  lastname: string;
  workingPositionId: Guid;
  organizationStructureId: Guid;
};

type Props = {
  toEditUser: OrgUserDto;
  usefulFirstName: string;
  usefulLastName: string;
  setUsefulFirstName: React.Dispatch<React.SetStateAction<string>>;
  setUsefulLastName: React.Dispatch<React.SetStateAction<string>>;
  isAdminSettingDisabled: boolean;
};

const renderOrganizationStructureNodeTitle = (node: OrganizationStructureDto) => {
  return (
    <Space direction="vertical" style={{ display: 'flex' }}>
      <Typography.Text strong>{`${node.sign} - ${node.name}`}</Typography.Text>
      {!!node.description && <Typography.Text>{`${node.description}`}</Typography.Text>}
    </Space>
  );
};

export const organizationStructureToTreeSelectData = (
  organizationStructure: OrganizationStructureDto[]
): DefaultOptionType[] => {
  return organizationStructure.map((item) => ({
    label: renderOrganizationStructureNodeTitle(item),
    value: item.id,

    children: item?.children?.length ? organizationStructureToTreeSelectData(item.children) : null,
  }));
};

const OrganizationUserEditFormComponent: FC<Props> = ({
  toEditUser,
  usefulFirstName,
  usefulLastName,
  setUsefulFirstName,
  setUsefulLastName,
  isAdminSettingDisabled,
}) => {
  const intl = useIntl();
  const form = useFormInstance();
  const namesFromOrganization = useWatch('namesFromOrganization');
  const firstname = useWatch('firstname');
  const lastname = useWatch('lastname');

  const [jobPositions] = useJobPositions(toEditUser.organizationId);

  const organizationStructure = useStoreSelector(organizationStructureOrderedListSelector);

  useDispatchEffect(
    (dispatch) =>
      dispatch.organizationStructureList.loadData({
        reload: true,
        data: toEditUser.organizationId,
      }),
    [toEditUser.organizationId]
  );

  const convertStructureDataToTreeData = useMemo(() => {
    if (!organizationStructure.length) return [];
    return organizationStructureToTreeSelectData(organizationStructure);
  }, [organizationStructure]);

  useEffect(() => {
    if (namesFromOrganization) {
      form.setFieldsValue({
        firstname: usefulFirstName,
        lastname: usefulLastName,
      });
    } else {
      form.setFieldsValue({
        firstname: toEditUser.appUserProfile.firstname,
        lastname: toEditUser.appUserProfile.lastname,
      });
    }
  }, [namesFromOrganization]);

  useEffect(() => {
    if (namesFromOrganization) setUsefulFirstName(firstname);
  }, [firstname, namesFromOrganization]); // TODO:check if namesFromOrganization is needed

  useEffect(() => {
    if (namesFromOrganization) {
      setUsefulLastName(lastname);
    }
  }, [lastname, namesFromOrganization]); // TODO:check if namesFromOrganization is needed

  const displayValue = useMemo(() => {
    if (namesFromOrganization && !usefulFirstName && !usefulLastName) {
      return (
        <Typography.Text>{intl.formatMessage({ id: 'OrganizationUserEditForm.namesNotSetValue' })}</Typography.Text>
      );
    }

    if (!namesFromOrganization && !toEditUser.appUserProfile.firstname && !toEditUser.appUserProfile.lastname) {
      return (
        <Typography.Text>{intl.formatMessage({ id: 'OrganizationUserEditForm.namesNotSetValue' })}</Typography.Text>
      );
    }
    return namesFromOrganization ? (
      <>
        <Typography.Text strong ellipsis>
          {` ${usefulFirstName || ''} ${usefulLastName || ''}`}
        </Typography.Text>
      </>
    ) : (
      <>
        <Typography.Text strong ellipsis>
          {` ${toEditUser.appUserProfile.firstname || ''} ${toEditUser.appUserProfile.lastname || ''}`}
        </Typography.Text>
      </>
    );
  }, [toEditUser.appUserProfile.firstname, toEditUser.appUserProfile.lastname, usefulFirstName, usefulLastName]);

  return (
    <>
      <Form.Item label={intl.formatMessage({ id: 'general.userName' })} style={{ marginBottom: 0 }}>
        <Typography.Text strong ellipsis>
          {toEditUser.appUserProfile.username}
        </Typography.Text>
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'OrganizationUserEditForm.nameSource' })}
        name="namesFromOrganization"
        initialValue={toEditUser.namesFromOrganization}
      >
        <Radio.Group>
          <Space direction="vertical">
            <Radio value={true}>{intl.formatMessage({ id: 'OrganizationUserEditForm.setByOrganization' })}</Radio>
            <Radio value={false}>{intl.formatMessage({ id: 'OrganizationUserEditForm.takeByUserSetting' })}</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'OrganizationUserEditForm.firstNameLastName' })}
        style={{ marginBottom: 0 }}
        // TODO: check if this is working
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '0',
          }}
        >
          <Form.Item
            wrapperCol={{ sm: 24 }}
            style={{ width: '100%' }}
            name="firstname"
            initialValue={usefulFirstName}
            rules={[maxLengthRule('general.maxNameLength', apiConstraints.orgUserRequestDto.firstname.maxLength)]}
          >
            <Input disabled={!namesFromOrganization} placeholder={intl.formatMessage({ id: 'general.firstName' })} />
          </Form.Item>
          <Form.Item
            wrapperCol={{ sm: 24 }}
            style={{ width: '100%' }}
            name="lastname"
            initialValue={usefulLastName}
            rules={[maxLengthRule('general.maxNameLength', apiConstraints.orgUserRequestDto.lastname.maxLength)]}
          >
            <Input disabled={!namesFromOrganization} placeholder={intl.formatMessage({ id: 'general.lastName' })} />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item label={intl.formatMessage({ id: 'OrganizationUserEditForm.namesDisplayValue' })} style={{ margin: 0 }}>
        <Typography.Text ellipsis>{displayValue}</Typography.Text>
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'general.administrator' })}
        style={{ marginBottom: 0 }}
        name="isAdmin"
        initialValue={toEditUser.isAdmin}
        valuePropName="checked"
      >
        <Checkbox disabled={isAdminSettingDisabled} />
      </Form.Item>
      <Form.Item
        label={
          <span>
            <Fmt id="general.language" />
            <CommonHubTooltip
              title={intl.formatMessage({ id: 'OrganizationAddForm.language.tooltip' })}
              placement="right"
            >
              <QuestionCircleOutlined style={{ paddingLeft: '8px' }} />
            </CommonHubTooltip>
          </span>
        }
        name="language"
        initialValue={toEditUser.appUserProfile.language}
      >
        <Radio.Group>
          <Radio.Button value={LanguageEnum.cs}>
            {intl.formatMessage({ id: 'ProjectCreateForm.form.items.language.cs' })}
          </Radio.Button>
          <Radio.Button value={LanguageEnum.en}>
            {intl.formatMessage({ id: 'ProjectCreateForm.form.items.language.en' })}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'OrganizationUserEditForm.jobPosition' })}
        style={{ margin: 0 }}
        name="workingPositionId"
        initialValue={toEditUser.workingPosition?.id}
      >
        <Select
          showSearch
          allowClear
          filterOption={(inputValue, option) => smartFilter(inputValue, option?.label)}
          options={jobPositions.map((position) => ({
            label: position.name,
            value: position.id,
            key: position.id,
          }))}
        />
      </Form.Item>
      <Margin top>
        <Form.Item
          label={intl.formatMessage({ id: 'general.organizationStructure' })}
          style={{ margin: 0 }}
          name="organizationStructureId"
          initialValue={toEditUser.organizationStructureId}
        >
          <TreeSelect showSearch allowClear treeData={convertStructureDataToTreeData} />
        </Form.Item>
      </Margin>
    </>
  );
};

export default OrganizationUserEditFormComponent;
