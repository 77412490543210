import { Form, Input } from 'antd';
import { apiConstraints } from 'api/completeApiConstraints';
import { useIntl } from 'hooks';
import React from 'react';
import { maxLengthRule, requiredRule } from 'utils/formHelpers';

export type ProjectTemplatePermissionPresetFormData = {
  name: string;
  description: string;
};

const ProjectTemplatePermissionPresetForm = () => {
  const intl = useIntl();

  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.name.label' })}
        name="name"
        rules={[
          requiredRule('forms.items.name.rules.required', true),
          maxLengthRule('general.maxNameLength', apiConstraints.extendedPermissionTemplateCreateDto.name.maxLength),
        ]}
      >
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.description.label' })}
        name="description"
        rules={[
          maxLengthRule(
            'general.maxNameLength',
            apiConstraints.extendedPermissionTemplateCreateDto.description.maxLength
          ),
        ]}
      >
        <Input />
      </Form.Item>
    </>
  );
};

export default ProjectTemplatePermissionPresetForm;
