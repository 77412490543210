import { Alert, Modal, Select, Spin, Typography } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { DefaultOptionType } from 'antd/lib/select';
import { masterApi, projectApi } from 'api/completeApi';
import { EsticonLinkDto, ProjectHubDto } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { useActiveProject, useApiData, useIntl, useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import { keyBy } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { messageError, smartFilter } from 'utils';
import { AddExternAppsProps } from '../../externAppsTypes';
import styles from './EsticonCreate.module.less';

export const EsticonCreate: FunctionComponent<AddExternAppsProps> = ({ open, onAppAdded, onCancel }) => {
  const project = useActiveProject();
  const dispatch = useDispatch<Dispatch>();
  const [selectedOrgEsticonId, setSelectedOrgEsticonId] = useState<Guid>(undefined);
  const [selectedProjectId, setSelectedProjectId] = useState<Guid>(undefined);
  const [selectedFirmId, setSelectedFirmId] = useState<Guid>(undefined);
  const [saving, setSaving] = useState<boolean>(false);

  const intl = useIntl();

  const [orgEsticons, orgEsticonsError, orgEsticonsLoading] = useApiData(
    (ct) => masterApi.projects.admin.esticonn.id.get(project.organization.id, ct),
    {
      autoload: true,
    }
  );
  const availableOrgEsticons = useMemo(() => orgEsticons?.sort((a, b) => a.name.localeCompare(b.name)), [orgEsticons]);

  const [esticonFirms, esticonFirmsError, esticonFirmsLoading, loadEsticonFirms] = useApiData((ct) =>
    projectApi.Esticon.estiConn.id.firms.get(selectedOrgEsticonId, ct)
  );

  useEffect(() => {
    !!selectedOrgEsticonId && loadEsticonFirms();
  }, [selectedOrgEsticonId]);

  const availableFirms = useMemo(() => esticonFirms?.sort((a, b) => a.nazev.localeCompare(b.nazev)), [esticonFirms]);

  const [esticonProjects, esticonProjectsError, esticonProjectsLoading, loadEsticonProjects] = useApiData((ct) =>
    projectApi.Esticon.estiConn.id.firms.id.linkableprojects.get(selectedOrgEsticonId, selectedFirmId, ct)
  );

  useEffect(() => {
    selectedFirmId && loadEsticonProjects();
  }, [selectedFirmId]);

  const availableProjects = useMemo(() => esticonProjects?.sort((a, b) => a.name.localeCompare(b.name)), [
    esticonProjects,
  ]);

  const orgEsticonsById = useMemo(() => keyBy(orgEsticons, 'id'), [orgEsticons]);

  const handleEsticonsSearch = useCallback(
    (input: string, option: DefaultOptionType) => smartFilter(orgEsticonsById[option.value]?.name, input),
    [orgEsticonsById]
  );

  const projectsById = useMemo(() => keyBy(esticonProjects, 'id'), [esticonProjects]);
  const handleProjectSearch = useCallback(
    (input: string, option: DefaultOptionType) =>
      smartFilter(projectsById[option.value].name, input) ||
      smartFilter(option.value as string, input) ||
      smartFilter(projectsById[option.value].sign, input),
    [projectsById]
  );

  const firmsById = useMemo(() => keyBy(esticonFirms, 'id'), [esticonFirms]);

  const handleFirmSearch = useCallback(
    (input: string, option: DefaultOptionType) =>
      smartFilter(firmsById[option.value]?.nazev, input) || smartFilter(firmsById[option.value]?.popis, input),
    [firmsById]
  );
  const handleSubmit = useSameCallback(async () => {
    if (!!selectedFirmId && !!selectedProjectId && !saving) {
      const data: EsticonLinkDto = {
        esticonFirmId: selectedFirmId,
        esticonProjectId: selectedProjectId,
        estiConnId: selectedOrgEsticonId,
      };

      setSaving(true);
      const [err] = await projectApi.link.post(data);
      setSaving(false);

      if (!err) {
        dispatch.activeProject.setActiveProject({
          ...project,
          esticonFirmId: selectedFirmId,
          esticonProjectId: selectedProjectId,
        });
        onAppAdded();
        return true;
      } else {
        messageError(err, intl);
        return false;
      }
    }
    return false;
  });

  const okButtonProps = useMemo<ButtonProps>(
    () => ({
      disabled: !selectedProjectId,
      loading: saving,
    }),
    [selectedProjectId, saving]
  );

  const cancelButtonProps = useMemo<ButtonProps>(
    () => ({
      loading: saving,
    }),
    [saving]
  );

  const orgEsticonsOptions = useMemo((): DefaultOptionType[] => {
    return availableOrgEsticons?.map((orgEsticon) => ({
      value: orgEsticon.id,
      label: (
        <div className={styles.selectItem}>
          <Typography.Text ellipsis>{`${orgEsticon.name}`}</Typography.Text>
        </div>
      ),
    }));
  }, [availableOrgEsticons]);

  const firmsOptions = useMemo((): DefaultOptionType[] => {
    return availableFirms?.map((firm) => ({
      value: firm.id,
      label: (
        <div className={styles.selectItem}>
          <CommonHubTooltip title={`${firm.nazev} (${firm.popis})`}>
            <Typography.Text ellipsis>{`${firm.nazev}`}</Typography.Text>
          </CommonHubTooltip>
        </div>
      ),
    }));
  }, [availableFirms]);

  const projectsOptions = useMemo((): DefaultOptionType[] => {
    return availableProjects?.map((esticonProject) => ({
      value: esticonProject.id,
      label: (
        <div className={styles.selectItem}>
          <CommonHubTooltip title={`${esticonProject.name} (${esticonProject.sign})`}>
            <Typography.Text ellipsis>{`${esticonProject.name} (${esticonProject.sign})`}</Typography.Text>
          </CommonHubTooltip>
          <Typography.Text type="secondary">{esticonProject.id}</Typography.Text>
        </div>
      ),
    }));
  }, [availableProjects]);

  useEffect(() => {
    if (!!selectedProjectId && !availableProjects.some((project) => project.id === selectedProjectId))
      setSelectedProjectId(undefined);
  }, [availableProjects, selectedProjectId]);

  return (
    <Modal
      title={intl.formatMessage({ id: 'ProjectSettingsPage.ExternApps.Esticon.addTitle' })}
      open={open}
      onOk={handleSubmit}
      onCancel={onCancel}
      destroyOnClose
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
    >
      <ContentGate error={orgEsticonsError} loading={orgEsticonsLoading}>
        <div className={styles.label}>
          <Fmt id="ProjectSettingsPage.ExternApps.Esticon.Form.esticonApp" />
        </div>
        <Select
          showSearch
          filterOption={handleEsticonsSearch}
          value={selectedOrgEsticonId}
          onChange={setSelectedOrgEsticonId}
          className={styles.selectProject}
          loading={orgEsticonsLoading}
          options={orgEsticonsOptions}
        />

        {orgEsticonsError && (
          <Alert
            type="error"
            message={
              orgEsticonsError.statusCode === 500
                ? intl.formatMessage({ id: 'ProjectSettingsPage.ExternApps.Esticon.ProjectList.serverError' })
                : orgEsticonsError.message
            }
          />
        )}
        {selectedOrgEsticonId && !orgEsticonsError && (
          <>
            <div className={styles.label}>
              <Fmt id="ProjectSettingsPage.ExternApps.Esticon.Form.Database" />
            </div>
            <Select
              showSearch
              filterOption={handleFirmSearch}
              value={selectedFirmId}
              onChange={setSelectedFirmId}
              className={styles.selectProject}
              loading={esticonFirmsLoading}
              options={firmsOptions}
            />
          </>
        )}
        {esticonFirmsError && (
          <Alert
            type="error"
            message={
              esticonFirmsError.statusCode === 500
                ? intl.formatMessage({ id: 'ProjectSettingsPage.ExternApps.Esticon.ProjectList.serverError' })
                : esticonFirmsError.message
            }
          />
        )}
        {selectedFirmId && !esticonFirmsError && (
          <>
            <div className={styles.label}>
              <Fmt id="ProjectSettingsPage.ExternApps.Esticon.Form.Project" />
            </div>
            <Spin spinning={esticonProjectsLoading}>
              <Select
                showSearch
                filterOption={handleProjectSearch}
                value={selectedProjectId}
                onChange={setSelectedProjectId}
                className={styles.selectProject}
                loading={esticonProjectsLoading}
                options={projectsOptions}
              />
            </Spin>
          </>
        )}
      </ContentGate>
    </Modal>
  );
};

export const canLinkEsticonProject = (project: ProjectHubDto) => !project.esticonProjectId;
