import { Form, Input, InputProps, Space } from 'antd';
import { JSONVariableTypeEnum } from 'api/completeApiInterfaces';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback } from 'react';
import { JSONVariableStringDiff } from '../JSONVariableTypes';

export type JSONVariableStringDiffProps = {
  dataType: JSONVariableTypeEnum.stringDiff;
  itemType: 'input';
  value?: JSONVariableStringDiff;
  onChange?: (value: JSONVariableStringDiff) => void;
} & Omit<InputProps, 'value' | 'onChange'>;

const JSONVariableStringDiffFormItem: FunctionComponent<JSONVariableStringDiffProps> = ({
  dataType,
  value,
  onChange,
  itemRef,
  ...restProps
}) => {
  const handleOldValueChange = useCallback(
    (oldValue: string) => {
      if (!oldValue) oldValue = null;
      onChange && onChange({ type: dataType, value: value?.value, oldValue: oldValue });
    },
    [dataType, onChange, value?.value]
  );

  const handleChange = useCallback(
    (newValue: string) => {
      if (!newValue) newValue = null;
      onChange && onChange({ type: dataType, value: newValue, oldValue: value?.oldValue });
    },
    [dataType, onChange, value?.oldValue]
  );

  return (
    <Space>
      <Form.Item label={<Fmt id="ProjectSettingsProjectCardDataTree.oldValue" />}>
        <Input
          onChange={(e) => {
            handleOldValueChange(e.currentTarget.value);
          }}
          value={value?.oldValue}
          {...restProps}
          style={{ width: '150px' }}
        />
      </Form.Item>
      <Form.Item label={<Fmt id="ProjectSettingsProjectCardDataTree.newValue" />}>
        <Input
          onChange={(e) => {
            handleChange(e.currentTarget.value);
          }}
          value={value?.value}
          {...restProps}
          style={{ width: '150px' }}
        />
      </Form.Item>
    </Space>
  );
};

export default React.memo(JSONVariableStringDiffFormItem);
