import { Col, Radio, Row } from 'antd';
import { api } from 'api';
import { WorkingOrCalendarDaySelect } from 'components/CalendarDaySelect/CalendarDaySelect';
import { DatePickerWithHolidays } from 'components/CalendarWithHolidays/DatePickerWithHolidays';
import { ContentGate } from 'components/ContentGate/ContentGate';
import StackPanel from 'components/StackPanel';
import { getCalendarDaysFromWorkingDays } from 'components/forms/CalendarSettingsForm/CalendarSettingsForm.utils';
import { useApiData, useStoreSelector } from 'hooks';
import { Fmt } from 'locale';
import moment from 'moment-business-days';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { ignoreRef } from 'utils';
import styles from './DateSelect.module.less';

export enum DateSelectOptionType {
  WeekDays,
  CalendarDays,
  Custom,
  Unlimited,
}

export type DateSelectWeekDaysValue = {
  type: DateSelectOptionType.WeekDays;
  weekDays: number;
};

export type DateSelectCalendarDaysValue = {
  type: DateSelectOptionType.CalendarDays;
  calendarDays: number;
  workingDays: number;
};

export type DateSelectCustomValue = {
  type: DateSelectOptionType.Custom;
  date: IsoDate;
};

export type DateSelectUnlimitedValue = {
  type: DateSelectOptionType.Unlimited;
};

export type DateSelectValue =
  | DateSelectWeekDaysValue
  | DateSelectCalendarDaysValue
  | DateSelectCustomValue
  | DateSelectUnlimitedValue;

export const dateSelectValueToMoment = (value: DateSelectValue | null) => {
  if (!value) {
    return undefined;
  }
  switch (value.type) {
    case DateSelectOptionType.WeekDays:
      return moment()
        .businessAdd(value.weekDays, 'days')
        .startOf('day');
    case DateSelectOptionType.CalendarDays:
      return moment()
        .add(value.workingDays, 'days')
        .startOf('day');
    case DateSelectOptionType.Unlimited:
      return null;
    case DateSelectOptionType.Custom:
      return moment(value.date, 'YYYY-MM-DD');
  }
};

export const momentToIsoDate = (date: moment.Moment): IsoDate => date?.startOf('day').format('YYYY-MM-DD');
export const momentToIsoTime = (date: moment.Moment): IsoTime => date?.format('HH:mm:ss');

type Props = {
  value: DateSelectValue | null;
  onChange: (value: DateSelectValue) => void;
  weekDaysPresets?: number[];
  calendarDaysPresets?: number[];
  enableUnlimited?: boolean;
  allowEmptyDate?: boolean;
};

export const DateSelect: FunctionComponent<Props> = ({
  value,
  onChange,
  weekDaysPresets,
  calendarDaysPresets,
  enableUnlimited,
  allowEmptyDate = false,
}) => {
  const asMoment = useMemo(() => dateSelectValueToMoment(value), [value]);
  const { activeCalendar } = useStoreSelector((state) => state.activeCalendar);
  const [hasWorkingDaysPreference, setHasWorkingDaysPreference] = useState<boolean>(false);

  // TODO: load from stored project settings instead of BE after settings redesign
  const [data, error, loading] = useApiData(api.project.projectSetting.getCommentProcedureSettings, {
    autoload: true,
    fetchCallback: (data) => setHasWorkingDaysPreference(data.useWorkDays),
  });

  const setWeekDays = (weekDays: number) => {
    onChange({ type: DateSelectOptionType.WeekDays, weekDays });
  };

  const setCalendarDays = (calendarDays: number) => {
    const workingDays =
      !!activeCalendar && hasWorkingDaysPreference
        ? getCalendarDaysFromWorkingDays(
            moment(),
            calendarDays,
            activeCalendar.projectCalendar || activeCalendar.orgCalendar
          )
        : calendarDays;
    onChange({ type: DateSelectOptionType.CalendarDays, calendarDays, workingDays });
  };

  const setUnlimited = () => {
    onChange({ type: DateSelectOptionType.Unlimited });
  };

  const setCustomOption = () => {
    const tomorrow = moment().businessAdd(1, 'day');
    onChange({ type: DateSelectOptionType.Custom, date: momentToIsoDate(asMoment || tomorrow) });
  };

  const setCustomValue = (date: moment.Moment) => {
    onChange({ type: DateSelectOptionType.Custom, date: momentToIsoDate(date) });
  };

  // Switch to custom if days go out of the "preset" range for some reason
  useEffect(() => {
    let isInInvalidRange = false;
    if (value?.type === DateSelectOptionType.WeekDays && !weekDaysPresets?.includes(value.weekDays)) {
      isInInvalidRange = true;
    }
    if (value?.type === DateSelectOptionType.CalendarDays && !calendarDaysPresets?.includes(value.calendarDays)) {
      isInInvalidRange = true;
    }
    if (value?.type === DateSelectOptionType.Unlimited && !enableUnlimited) {
      isInInvalidRange = true;
    }

    if (isInInvalidRange) {
      if (asMoment) {
        onChange({ type: DateSelectOptionType.Custom, date: momentToIsoDate(asMoment) });
      } else if (enableUnlimited) {
        onChange({ type: DateSelectOptionType.Unlimited });
      } else {
        onChange({ type: DateSelectOptionType.Custom, date: momentToIsoDate(moment().add(1, 'day')) });
      }
    }
  }, [value, enableUnlimited, weekDaysPresets, calendarDaysPresets]);

  useEffect(() => {
    if (value?.type === DateSelectOptionType.CalendarDays) {
      setCalendarDays(value.calendarDays);
    }
  }, [hasWorkingDaysPreference]);

  return (
    <Row>
      <Col span={12}>
        <StackPanel vertical>
          {weekDaysPresets?.map((weekDays) => (
            <Radio
              key={weekDays}
              checked={value?.type === DateSelectOptionType.WeekDays && value?.weekDays === weekDays}
              onChange={() => setWeekDays(weekDays)}
              className={styles.radioOption}
            >
              {<Fmt id="DateSelect.n_weekDays" values={{ weekDays }} />}
            </Radio>
          ))}
          {calendarDaysPresets?.map((calendarDays) => (
            <Radio
              key={calendarDays}
              checked={value?.type === DateSelectOptionType.CalendarDays && value?.calendarDays === calendarDays}
              onChange={() => setCalendarDays(calendarDays)}
              className={styles.radioOption}
            >
              {<Fmt id="DateSelect.n_calendarDays" values={{ calendarDays }} />}
            </Radio>
          ))}
          {enableUnlimited && (
            <Radio
              checked={value?.type === DateSelectOptionType.Unlimited}
              onChange={setUnlimited}
              className={styles.radioOption}
            >
              <Fmt id="Deadline.unlimited" />
            </Radio>
          )}
          <Radio
            checked={value?.type === DateSelectOptionType.Custom}
            onChange={setCustomOption}
            className={styles.radioOption}
          >
            <Fmt id="general.custom" />:
          </Radio>
          <DatePickerWithHolidays
            disabled={value?.type !== DateSelectOptionType.Custom}
            value={asMoment}
            format="L"
            onChange={setCustomValue}
            allowClear={!!allowEmptyDate}
          />
        </StackPanel>
      </Col>
      <Col span={12}>
        <ContentGate loading={loading}>
          <WorkingOrCalendarDaySelect
            value={!!hasWorkingDaysPreference}
            onChange={(e) => setHasWorkingDaysPreference(e.target.value)}
          />
        </ContentGate>
      </Col>
    </Row>
  );
};

export const DateSelectFormItem: FunctionComponent<Omit<Props, 'value' | 'onChange'>> = ignoreRef(DateSelect);
