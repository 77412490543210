import { Form, Radio } from 'antd';
import { Fmt } from 'locale';
import React, { FC } from 'react';

export interface AdministrationMetadataFormData {
  onlyCurrentRevisions: boolean;
}

type Props = { disabled: boolean };

const AdministrationMetadataForm: FC<Props> = ({ disabled }) => {
  return (
    <Form.Item name="onlyCurrentRevisions" initialValue={true}>
      <Radio.Group disabled={disabled}>
        <Radio style={{ display: 'block' }} value={true}>
          <Fmt id="Administration.downloadDMSMetaDataModal.formRadio.actualRevisions" />
        </Radio>
        <Radio style={{ display: 'block' }} value={false}>
          <Fmt id="Administration.downloadDMSMetaDataModal.formRadio.allRevisions" />
        </Radio>
      </Radio.Group>
    </Form.Item>
  );
};

export default AdministrationMetadataForm;
