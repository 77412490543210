import { LoadingOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { api } from 'api';
import {
  DirectoryDto,
  DocumentContentDto,
  DocumentReservationDto,
  FavoriteDocumentsResultsDto,
  ProjectUserProfileDto,
  ServiceError,
} from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import { AxiosResponse, CancelToken } from 'axios';
import {
  AddToFavoriteIcon,
  CancelReservationIcon,
  RemoveFromFavoriteIcon,
  ReservateIcon,
} from 'components/Icons/HubActionsIcons';
import { ModelOffIcon, ModelOnIcon } from 'components/Icons/HubEntitiesIcons';
import { ToolbarItemProps } from 'components/Toolbar/ShrinkableToolbar';
import { useCancelToken } from 'hooks/useCancelToken';
import { useIntl } from 'hooks/useIntl';
import { InjectedIntl } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import { useToggleIsModelActions } from 'pages/DocumentDetailPage/modals/ToggleIsModelAction';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { checkDirectoryWriteAccess, messageError, processApiError } from 'utils';
import { useReserveDocumentTooltip } from './useReserveDocumentTooltip';

export const useOnOffButton = <T,>(
  key: string,
  itemId: Guid,
  isOn: boolean,
  labelOn: IntlMessageId,
  labelOff: IntlMessageId,
  iconOn: ReactNode,
  iconOff: ReactNode,
  setOn: (id: Guid, cancelToken?: CancelToken) => Promise<[ApiError, AxiosResponse<T>]>,
  setOff: (id: Guid, cancelToken?: CancelToken) => Promise<[ApiError, AxiosResponse<void>]>,
  onChange?: (isOn: boolean, response?: T | void) => void,
  onError?: (error: ServiceError) => void,
  successMessage?: string,
  disabled?: boolean,
  tooltip?: string | JSX.Element
) => {
  const intl = useIntl();
  const [saving, setSaving] = useState<boolean>(false);
  const cancelToken = useCancelToken(intl.formatMessage({ id: 'general.error' }), [intl]);

  const onClick = useCallback(async () => {
    setSaving(true);
    const [err, res] = !isOn
      ? setOn && (await setOn(itemId, cancelToken))
      : setOff && (await setOff(itemId, cancelToken));

    if (err) {
      processApiError(err, (error) => {
        onError && onError(error);
      });
      setSaving(false);
    } else {
      onChange && onChange(!isOn, res.data);
      if (!!successMessage) {
        message.success(successMessage);
      }
      setSaving(false);
    }
  }, [onChange, isOn, itemId, onError, setOn, setOff, successMessage]);

  return useMemo<ToolbarItemProps>(
    () => ({
      key,
      onClick,
      label: isOn ? labelOn : labelOff,
      icon: saving ? <LoadingOutlined /> : isOn ? iconOn : iconOff,
      disabled: disabled || saving,
      tooltip,
    }),
    [onClick, saving, isOn, labelOn, labelOff, iconOn, iconOff, tooltip, disabled]
  );
};

export const useFavoriteDirectoryButton = (
  key: string,
  itemId: Guid,
  isOn: boolean,
  onChange?: (id: Guid, isFavorite: boolean) => void,
  onError?: (error: ServiceError) => void,
  successMessage?: string
) => {
  const handleFavoriteChange = useCallback((isFavorite: boolean) => onChange && onChange(itemId, isFavorite), [
    onChange,
    itemId,
  ]);

  return useOnOffButton(
    key,
    itemId,
    isOn,
    'FavoriteButton.unset',
    'FavoriteButton.set',
    <RemoveFromFavoriteIcon />,
    <AddToFavoriteIcon />,
    api.project.directories.addFavoriteDirectory,
    api.project.directories.removeFavoriteDirectory,
    handleFavoriteChange,
    onError,
    successMessage
  );
};

export const useFavoriteButton = (
  key: string,
  itemId: Guid,
  isOn: boolean,
  onAdd: (id: Guid, cancelToken?: CancelToken) => Promise<any>,
  onRemove: (id: Guid, cancelToken?: CancelToken) => Promise<any>,
  onChange?: (isFavorite: boolean, response: FavoriteDocumentsResultsDto) => void,
  onError?: (error: ServiceError) => void,
  successMessage?: string,
  disabled?: boolean
) => {
  return useOnOffButton(
    key,
    itemId,
    isOn,
    'FavoriteButton.unset',
    'FavoriteButton.set',
    <RemoveFromFavoriteIcon />,
    <AddToFavoriteIcon />,
    onAdd,
    onRemove,
    onChange,
    onError,
    successMessage,
    disabled,
    undefined
  );
};

const MODEL_ON_ICON = <ModelOnIcon />;
const MODEL_OFF_ICON = <ModelOffIcon />;

export const useIsModelButton = (
  key: string,
  itemId: Guid,
  isOn: boolean,
  intl: InjectedIntl,
  onChange?: (isFavorite: boolean) => void,
  onError?: (error: ServiceError) => void,
  successMessage?: string
) => {
  const { handleClearIsModel, handleSetIsModel } = useToggleIsModelActions();

  return useOnOffButton(
    key,
    itemId,
    isOn,
    'DocumentDetailPage.model.remove',
    'DocumentDetailPage.model.add',
    MODEL_ON_ICON,
    MODEL_OFF_ICON,
    handleSetIsModel,
    handleClearIsModel,
    onChange,
    onError,
    successMessage
  );
};

const FILLED_LOCK_ICON = <ReservateIcon />;
const UNLOCK_ICON = <CancelReservationIcon />;

const isDocumentNotEditableByMe = (
  document: DocumentContentDto,
  directory: DirectoryDto,
  currentUser: ProjectUserProfileDto
) => {
  if (currentUser.isAdmin) return false;
  return (
    (!!document.reservedDate && currentUser.id !== document.reservedBy.id) ||
    !directory ||
    !checkDirectoryWriteAccess(directory.currentAccessLevel)
  );
};

const getReservationDisabled = (
  document: DocumentContentDto,
  directory: DirectoryDto,
  currentUser: ProjectUserProfileDto
) => {
  if (!!document.ownedBy || !!document.esticonObjectLink) return true;
  if (currentUser.isAdmin) return false;
  return isDocumentNotEditableByMe(document, directory, currentUser);
};

export const useReserveDocumentButton = (
  key: string,
  document: DocumentContentDto,
  directory: DirectoryDto,
  currentUser: ProjectUserProfileDto,
  onChange: (response: DocumentReservationDto) => void
) => {
  const intl = useIntl();
  const [reservedDate, setReservedDate] = useState(document?.reservedDate);
  const [reservedBy, setReservedBy] = useState(document?.reservedBy);
  const [releaseReservedDate, setReleaseReservedDate] = useState(document?.releaseReservedDate);

  useEffect(() => {
    if (!document) return;
    setReservedDate(document.reservedDate);
    setReservedBy(document.reservedBy);
    setReleaseReservedDate(document.releaseReservedDate);
  }, [document]);

  const documentId = document?.id;

  const reserve = useCallback(() => api.project.documents.reserveDocument(documentId), [documentId]);
  const release = useCallback(() => api.project.documents.releaseDocumentReservation(documentId), [documentId]);

  const isReservationDisabled = useMemo(() => getReservationDisabled(document, directory, currentUser), [
    document,
    directory,
    currentUser,
  ]);

  const isReservedByAnnotationOrEdition = useMemo(() => !!document.reservationPurpose, [document.reservationPurpose]);

  const onError = useCallback(
    (err: ServiceError) => {
      messageError(err, intl);
    },
    [intl]
  );

  const handleChange = useCallback(
    async (isOn: boolean, response?: DocumentReservationDto) => {
      setReservedBy(isOn ? response.reservedBy : null);
      setReservedDate(isOn ? response.reservedDate : null);
      onChange(isOn ? response : null);
    },
    [onChange, intl]
  );
  const isReserved = !!reservedDate && !!reservedBy;

  const successMessage = useMemo(() => {
    const isReserved = !!reservedDate && !!reservedBy;
    return intl.formatMessage({
      id: isReserved ? 'ReserveFileButton.message.release' : 'ReserveFileButton.message.reserve',
    });
  }, [reservedBy, reservedDate, intl]);

  const commonReserveTooltip = useReserveDocumentTooltip(
    isReserved,
    intl,
    reservedBy?.username,
    reservedDate,
    releaseReservedDate
  );
  const reservedByAnnotationOrEditionTooltip = intl.formatMessage(
    { id: 'ReserveFileButton.tooltip.reservedByUser' },
    { user: reservedBy?.username }
  );

  const tooltip = isReservedByAnnotationOrEdition ? reservedByAnnotationOrEditionTooltip : commonReserveTooltip;

  return useOnOffButton(
    key,
    document.id,
    !!reservedDate,
    'ReserveFileButton.label.unset',
    'ReserveFileButton.label.set',
    FILLED_LOCK_ICON,
    UNLOCK_ICON,
    reserve,
    release,
    handleChange,
    onError,
    successMessage,
    isReservationDisabled || isReservedByAnnotationOrEdition,
    tooltip
  );
};
