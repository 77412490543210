import Axios, { AxiosError } from 'axios';
import { processApiError } from 'utils';
import { identityMapper, Mapper } from 'utils/mappers';
import { ApiResponse } from './await-to';
import { MdRoleEnum, MdUserDto, ServiceError } from './completeApiInterfaces';

export type ApiError = AxiosError<ServiceError>;

// You can generate these with a regex:
// grep -Pzhro 'public class (?!\w+Data)\w+ : \w+Error' src | sed -E 's/public class (\w+) : \w+Error/  "\1" = "\1",\n/g' | sort -u
export enum ServiceErrorEnum {
  'AdminAccessRequiredError' = 'AdminAccessRequiredError',
  'AddProjectUserByMailForbiddenError' = 'AddProjectUserByMailForbiddenError',
  'ApplicationAdminAccessRequiredError' = 'ApplicationAdminAccessRequiredError',
  'ApplicationOrOrganizationAdminAccessRequiredError' = 'ApplicationOrOrganizationAdminAccessRequiredError',
  'AddAccessLevelToAdminError' = 'AddAccessLevelToAdminError',
  'AdminOrExtendedPermissionRequiredError' = 'AdminOrExtendedPermissionRequiredError',
  'AnyDocumentNotFoundError' = 'AnyDocumentNotFoundError',
  'ApprovaDocumentAlreadyInWorkflowError' = 'ApprovaDocumentAlreadyInWorkflowError',
  'ApprovaDocumentInsufficientRoleError' = 'ApprovaDocumentInsufficientRoleError',
  'AppUserForProjectNotFoundError' = 'AppUserForProjectNotFoundError',
  'AppUserInvalidDashbordConfigurationError' = 'AppUserInvalidDashbordConfigurationError',
  'AppUserNotFoundError' = 'AppUserNotFoundError',
  'AssignmentAttachmentChangeForbiddenError' = 'AssignmentAttachmentChangeForbiddenError',
  'AssignmentAttachmentForbiddenError' = 'AssignmentAttachmentForbiddenError',
  'AssignmentAttachmentNotFoundError' = 'AssignmentAttachmentNotFoundError',
  'AssignmentCloseBadRequestError' = 'AssignmentCloseBadRequestError',
  'AssignmentDeleteAttachmentForbiddenError' = 'AssignmentDeleteAttachmentForbiddenError',
  'AssignmentDuplicateNameBadRequestError' = 'AssignmentDuplicateNameBadRequestError',
  'AssignmentFailedSaveServerError' = 'AssignmentFailedSaveServerError',
  'AssignmentForbiddenError' = 'AssignmentForbiddenError',
  'AssignmentNoteChangeForbiddenError' = 'AssignmentNoteChangeForbiddenError',
  'AssignmentNoteNotFoundError' = 'AssignmentNoteNotFoundError',
  'AssignmentNotFoundError' = 'AssignmentNotFoundError',
  'AssignmentNotifiedUserNotFoundError' = 'AssignmentNotifiedUserNotFoundError',
  'AssignmentRestoreAttachmentForbiddenError' = 'AssignmentRestoreAttachmentForbiddenError',
  'AuditLogNotFoundError' = 'AuditLogNotFoundError',
  'AutodeskForgeServiceError' = 'AutodeskForgeServiceError',
  'BackgroundDocumentAlreadyAssignedError' = 'BackgroundDocumentAlreadyAssignedError',
  'BackgroundDocumentAlreadyCheckedError' = 'BackgroundDocumentAlreadyCheckedError',
  'BackgroundDocumentInsufficientAccessLevelError' = 'BackgroundDocumentInsufficientAccessLevelError',
  'BackgroundDocumentNameAlreadExistsError' = 'BackgroundDocumentNameAlreadExistsError',
  'BackgroundDocumentNotAssignedError' = 'BackgroundDocumentNotAssignedError',
  'BackgroundDocumentNotFoundError' = 'BackgroundDocumentNotFoundError',
  'BackgroundTaskInProcessError' = 'BackgroundTaskInProcessError',
  'BadRequestError' = 'BadRequestError',
  'BlobDerivateNotFoundError' = 'BlobDerivateNotFoundError',
  'BlobNotFoundError' = 'BlobNotFoundError',
  'BlobTokenInvalidError' = 'BlobTokenInvalidError',
  'CategoryNodeParentNotFoundError' = 'CategoryNodeParentNotFoundError',
  'CategoryNodeTemplateNotFoundError' = 'CategoryNodeTemplateNotFoundError',
  'ChekedFilePathNotInPathsError' = 'ChekedFilePathNotInPathsError',
  'CommentChangeStateForbiddenError' = 'CommentChangeStateForbiddenError',
  'CommentProcedureAddCommentForbiddenError' = 'CommentProcedureAddCommentForbiddenError',
  'CommentProcedureAlreadyHasWorkflowError' = 'CommentProcedureAlreadyHasWorkflowError',
  'CommentProcedureApproveError' = 'CommentProcedureApproveError',
  'CommentProcedureAttachmentChangeForbiddenError' = 'CommentProcedureAttachmentChangeForbiddenError',
  'CommentProcedureAttachmentIsUseError' = 'CommentProcedureAttachmentIsUseError',
  'CommentProcedureCategoryAprovedOrClosedError' = 'CommentProcedureCategoryAprovedOrClosedError',
  'CommentProcedureCategoryIsUseError' = 'CommentProcedureCategoryIsUseError',
  'CommentProcedureCategoryNotFoundError' = 'CommentProcedureCategoryNotFoundError',
  'CommentProcedureCommentAttachmentNotDiscardedBadRequestError' = 'CommentProcedureCommentAttachmentNotDiscardedBadRequestError',
  'CommentProcedureCommentAttachmentNotFoundError' = 'CommentProcedureCommentAttachmentNotFoundError',
  'CommentProcedureCommentDeleteAttachmentForbiddenError' = 'CommentProcedureCommentDeleteAttachmentForbiddenError',
  'CommentProcedureCommentDocumentAlreadyAddedError' = 'CommentProcedureCommentDocumentAlreadyAddedError',
  'CommentProcedureCommentNoteNotFoundError' = 'CommentProcedureCommentNoteNotFoundError',
  'CommentProcedureCommentNotFoundError' = 'CommentProcedureCommentNotFoundError',
  'CommentProcedureCommentRestoreAttachmentForbiddenError' = 'CommentProcedureCommentRestoreAttachmentForbiddenError',
  'CommentProcedureDocumentAlreadyAddedError' = 'CommentProcedureDocumentAlreadyAddedError',
  'CommentProcedureDocumentForbiddenError' = 'CommentProcedureDocumentForbiddenError',
  'CommentProcedureDocumentInCommentError' = 'CommentProcedureDocumentInCommentError',
  'CommentProcedureDuplicateCategoryError' = 'CommentProcedureDuplicateCategoryError',
  'CommentProcedureDuplicateNameError' = 'CommentProcedureDuplicateNameError',
  'CommentProcedureDuplicateTeamError' = 'CommentProcedureDuplicateTeamError',
  'CommentProcedureDuplicateUserError' = 'CommentProcedureDuplicateUserError',
  'CommentProcedureForbiddenError' = 'CommentProcedureForbiddenError',
  'CommentProcedureNoteChangeForbiddenError' = 'CommentProcedureNoteChangeForbiddenError',
  'CommentProcedureNotFoundError' = 'CommentProcedureNotFoundError',
  'CommentProcedureNotifiedUserNotFoundError' = 'CommentProcedureNotifiedUserNotFoundError',
  'CommentProcedureProcessorForbiddenError' = 'CommentProcedureProcessorForbiddenError',
  'CommentProcedureRoleChangeForbiddenError' = 'CommentProcedureRoleChangeForbiddenError',
  'CommentProcedureRoleProcessorEmptyError' = 'CommentProcedureRoleProcessorEmptyError',
  'CommentProcedureRoleSubmitterEmptyError' = 'CommentProcedureRoleSubmitterEmptyError',
  'CommentProcedureStateChangeForbiddenError' = 'CommentProcedureStateChangeForbiddenError',
  'CommentProcedureSubmitterForbiddenError' = 'CommentProcedureSubmitterForbiddenError',
  'CommentProcedureTeamIsUseError' = 'CommentProcedureTeamIsUseError',
  'CommentProcedureTeamNotFoundError' = 'CommentProcedureTeamNotFoundError',
  'CommentProcedureTeamPermissionForbiddenError' = 'CommentProcedureTeamPermissionForbiddenError',
  'CommentProcedureUserNotFoundError' = 'CommentProcedureUserNotFoundError',
  'CommentProcedureSubmitterNoteForbiddenError' = 'CommentProcedureSubmitterNoteForbiddenError',
  'CommentProcedureProcessorNoteForbiddenError' = 'CommentProcedureProcessorNoteForbiddenError',
  'CreateModelsError' = 'CreateModelsError',
  'DbConcurrencyError' = 'DbConcurrencyError',
  'DestinationDirectoryForDocumentMoveForbiddenError' = 'DestinationDirectoryForDocumentMoveForbiddenError',
  'DestinationDirectoryForMoveForbiddenError' = 'DestinationDirectoryForMoveForbiddenError',
  'DirectoryAdminGroupAccessLevelCannotBeModifiedError' = 'DirectoryAdminGroupAccessLevelCannotBeModifiedError',
  'DirectoryAlreadyExistsThereError' = 'DirectoryAlreadyExistsThereError',
  'DirectoryCreateForbiddenError' = 'DirectoryCreateForbiddenError',
  'DirectoryDuplicateNameError' = 'DirectoryDuplicateNameError',
  'DirectoryEsticonImportDiscardForbiddenError' = 'DirectoryEsticonImportDiscardForbiddenError',
  'DirectoryForbiddenError' = 'DirectoryForbiddenError',
  'DirectoryIdRequiredError' = 'DirectoryIdRequiredError',
  'DirectoryInsufficientAccessLevelError' = 'DirectoryInsufficientAccessLevelError',
  'DirectoryInvalidNameError' = 'DirectoryInvalidNameError',
  'DirectoryIsAlreadyDiscardedError' = 'DirectoryIsAlreadyDiscardedError',
  'DirectoryIsNotDiscardedError' = 'DirectoryIsNotDiscardedError',
  'DirectoryIsNotEmptyError' = 'DirectoryIsNotEmptyError',
  'DirectoryIsRootError' = 'DirectoryIsRootError',
  'DirectoryMoveForbiddenError' = 'DirectoryMoveForbiddenError',
  'DirectoryNotFoundError' = 'DirectoryNotFoundError',
  'DirectoryParentNotFoundError' = 'DirectoryParentNotFoundError',
  'DirectoryPermissionInheritanceOnRootNotPermitedError' = 'DirectoryPermissionInheritanceOnRootNotPermitedError',
  'DirectoryRecursiveMoveError' = 'DirectoryRecursiveMoveError',
  'DirectoryRestoreDuplicateError' = 'DirectoryRestoreDuplicateError',
  'DirectoryRootNotFoundError' = 'DirectoryRootNotFoundError',
  'DirectoryTreeMaxDepthExceededError' = 'DirectoryTreeMaxDepthExceededError',
  'DirectoryUserAccessAlreadyExistsError' = 'DirectoryUserAccessAlreadyExistsError',
  'DiscoveryError' = 'DiscoveryError',
  'DocumentAlreadyInModelError' = 'DocumentAlreadyInModelError',
  'DocumentAlreadyReservedError' = 'DocumentAlreadyReservedError',
  'DocumentCategoryNodeDuplicateNameError' = 'DocumentCategoryNodeDuplicateNameError',
  'DocumentCategoryNodeNotFoundError' = 'DocumentCategoryNodeNotFoundError',
  'DocumentCategoryNodeRemoveObstacleError' = 'DocumentCategoryNodeRemoveObstacleError',
  'DocumentCategoryTreeDuplicateIdInListError' = 'DocumentCategoryTreeDuplicateIdInListError',
  'DocumentCategoryTreeDuplicateNameError' = 'DocumentCategoryTreeDuplicateNameError',
  'DocumentCategoryTreeMaxDepthExceededError' = 'DocumentCategoryTreeMaxDepthExceededError',
  'DocumentCategoryTreeNotFoundError' = 'DocumentCategoryTreeNotFoundError',
  'DocumentCategoryTreeRemoveObstacleError' = 'DocumentCategoryTreeRemoveObstacleError',
  'DocumentCategoryTreeRootIsFixedError' = 'DocumentCategoryTreeRootIsFixedError',
  'DocumentCommentProcedureForbiddenActionError' = 'DocumentCommentProcedureForbiddenActionError',
  'DocumentCreatorAccessError' = 'DocumentCreatorAccessError',
  'DocumentDeepDownloadForbiddenError' = 'DocumentDeepDownloadForbiddenError',
  'DocumentDuplicateNameError' = 'DocumentDuplicateNameError',
  'DocumentDuplicateNamesError' = 'DocumentDuplicateNamesError',
  'DocumentEsticonForbiddenActionError' = 'DocumentEsticonForbiddenActionError',
  'DocumentIdRequiredError' = 'DocumentIdRequiredError',
  'DocumentInCommentProcedureForbiddenActionError' = 'DocumentInCommentProcedureForbiddenActionError',
  'DocumentInWorkflowForbiddenActionError' = 'DocumentInWorkflowForbiddenActionError',
  'DocumentIsAlreadyDiscardedError' = 'DocumentIsAlreadyDiscardedError',
  'DocumentIsNotDiscardedError' = 'DocumentIsNotDiscardedError',
  'DocumentIsNotInModelError' = 'DocumentIsNotInModelError',
  'DocumentIsReservedError' = 'DocumentIsReservedError',
  'DocumentIsUploadingError' = 'DocumentIsUploadingError',
  'DocumentLinkedToEsticonError' = 'DocumentLinkedToEsticonError',
  'DocumentManagerAccessError' = 'DocumentManagerAccessError',
  'DocumentMoveDuplicateError' = 'DocumentMoveDuplicateError',
  'DocumentMultipleAddObstacleError' = 'DocumentMultipleAddObstacleError',
  'DocumentMultipleDiscardObstacleError' = 'DocumentMultipleDiscardObstacleError',
  'DocumentMultipleMoveObstacleError' = 'DocumentMultipleMoveObstacleError',
  'DocumentMultipleRestoreObstacleError' = 'DocumentMultipleRestoreObstacleError',
  'DocumentMultipleStateChangeObstacleError' = 'DocumentMultipleStateChangeObstacleError',
  'DocumentNotContainsRequiredCategoryTreeError' = 'DocumentNotContainsRequiredCategoryTreeError',
  'DocumentNotFoundError' = 'DocumentNotFoundError',
  'DocumentNotReservedError' = 'DocumentNotReservedError',
  'DocumentNotSignableBadRequestError' = 'DocumentNotSignableBadRequestError',
  'DocumentRequiredCategoryTreeDeleteError' = 'DocumentRequiredCategoryTreeDeleteError',
  'DocumentRemoveFromModelMultipleObstacleError' = 'DocumentRemoveFromModelMultipleObstacleError',
  'DocumentReservationReleaseForbiddenError' = 'DocumentReservationReleaseForbiddenError',
  'DocumentReservationReleaseLockError' = 'DocumentReservationReleaseLockError',
  'DocumentReservationLockNotFoundError' = 'DocumentReservationLockNotFoundError',
  'DocumentRestoreDuplicateError' = 'DocumentRestoreDuplicateError',
  'DocumentSignBlobIsObsoleteError' = 'DocumentSignBlobIsObsoleteError',
  'DocumentSigningIsProcessedError' = 'DocumentSigningIsProcessedError',
  'DocumentStateForbiddenChangeError' = 'DocumentStateForbiddenChangeError',
  'DocumentWithManagerForbiddenActionError' = 'DocumentWithManagerForbiddenActionError',
  'EmailLimitExceedError' = 'EmailLimitExceedError',
  'EsticonAccessRequiredError' = 'EsticonAccessRequiredError',
  'EsticonDirectoryIsNotCreatedError' = 'EsticonDirectoryIsNotCreatedError',
  'EsticonDocumentIsPartialError' = 'EsticonDocumentIsPartialError',
  'EsticonDocumentNotFoundError' = 'EsticonDocumentNotFoundError',
  'EsticonDocumentsAlreadyLinkedError' = 'EsticonDocumentsAlreadyLinkedError',
  'EsticonProjectNotFoundError' = 'EsticonProjectNotFoundError',
  'EsticonUnlinkDocumentExistsError' = 'EsticonUnlinkDocumentExistsError',
  'EventLogInsufficientAccessLevelError' = 'EventLogInsufficientAccessLevelError',
  'ExtendedPermissionTemplateNotFoundError' = 'ExtendedPermissionTemplateNotFoundError',
  'ExtendedPermissionTemplateInProjectTemplateError' = 'ExtendedPermissionTemplateInProjectTemplateError',
  'ExtendedPermissionTemplateDuplicateNameError' = 'ExtendedPermissionTemplateDuplicateNameError',
  'FavoriteDirectoryNotFoundError' = 'FavoriteDirectoryNotFoundError',
  'FavoriteDocumentNotFoundError' = 'FavoriteDocumentNotFoundError',
  'ForbiddenError' = 'ForbiddenError',
  'GoneError' = 'GoneError',
  'GroupCountLimitExceededError' = 'GroupCountLimitExceededError',
  'GroupDuplicateNameError' = 'GroupDuplicateNameError',
  'GroupIsSystemFixedError' = 'GroupIsSystemFixedError',
  'GroupNotFoundError' = 'GroupNotFoundError',
  'GroupTemplateNotFoundError' = 'GroupTemplateNotFoundError',
  'InappropriateCommentProcedureStateError' = 'InappropriateCommentProcedureStateError',
  'InappropriateCommentStateError' = 'InappropriateCommentStateError',
  'InappropriateCommentStateForChangeError' = 'InappropriateCommentStateForChangeError',
  'InappropriateDocumentStateError' = 'InappropriateDocumentStateError',
  'InappropriateSigningStateError' = 'InappropriateSigningStateError',
  'InappropriateStateChangeBadRequestError' = 'InappropriateStateChangeBadRequestError',
  'InappropriateTaskStateError' = 'InappropriateTaskStateError',
  'InappropriateTaskTypeError' = 'InappropriateTaskTypeError',
  'InappropriateUserForChangeBadRequestError' = 'InappropriateUserForChangeBadRequestError',
  'InappropriatMessageStatusError' = 'InappropriatMessageStatusError',
  'InsufficientSubWorkflowCountError' = 'InsufficientSubWorkflowCountError',
  'InvaitedUserNameDifferentError' = 'InvaitedUserNameDifferentError',
  'InvalidReturnAddressError' = 'InvalidReturnAddressError',
  'InvalidWopiSessionError' = 'InvalidWopiSessionError',
  'LabelDuplicateNameError' = 'LabelDuplicateNameError',
  'LabelIsDeprecatedError' = 'LabelIsDeprecatedError',
  'LabelIsUsedOnEntitiesError' = 'LabelIsUsedOnEntitiesError',
  'LabelNotFoundError' = 'LabelNotFoundError',
  'LicenceExpirationError' = 'LicenceExpirationError',
  'LinkNotFoundError' = 'LinkNotFoundError',
  'MessageBadUserError' = 'MessageBadUserError',
  'MetaextractorTaskInProcessError' = 'MetaextractorTaskInProcessError',
  'ModelHasLinkError' = 'ModelHasLinkError',
  'ModelOperationInsufficientAccessLevelError' = 'ModelOperationInsufficientAccessLevelError',
  'ModelStateInvalidError' = 'ModelStateInvalidError',
  'NotFoundError' = 'NotFoundError',
  'NotUpdatableBackgroundDocumentStateError' = 'NotUpdatableBackgroundDocumentStateError',
  'NotUpdatableWorkflowNodeStateError' = 'NotUpdatableWorkflowNodeStateError',
  'NotUpdatableWorkflowStateError' = 'NotUpdatableWorkflowStateError',
  'OrganizationAdminAccessRequiredError' = 'OrganizationAdminAccessRequiredError',
  'OrganizationUserLastAdminError' = 'OrganizationUserLastAdminError',
  'OrganizationUserInProjectError' = 'OrganizationUserInProjectError',
  'OrganizationIsSameError' = 'OrganizationIsSameError',
  'OrganizationUserNotFoundError' = 'OrganizationUserNotFoundError',
  'OrganizationNotFoundError' = 'OrganizationNotFoundError',
  'OrganizationUserInProjectTemplateError' = 'OrganizationUserInProjectTemplateError',
  'OrganizationForbiddenError' = 'OrganizationForbiddenError',
  'OrganizationDuplicateNameError' = 'OrganizationDuplicateNameError',
  'OrganizationHasProjectError' = 'OrganizationHasProjectError',
  'OrganizationReportForbiddenError' = 'OrganizationReportForbiddenError',
  'OrgLabelIsUsedOnEntitiesError' = 'OrgLabelIsUsedOnEntitiesError',
  'ProjectAdminAccessRequiredError' = 'ProjectAdminAccessRequiredError',
  'ParentDirectoryNotFoundError' = 'ParentDirectoryNotFoundError',
  'ProjectActionForbiddenError' = 'ProjectActionForbiddenError',
  'ProjectDuplicateNameError' = 'ProjectDuplicateNameError',
  'ProjectForbiddenError' = 'ProjectForbiddenError',
  'ProjectForbiddenSuspendedError' = 'ProjectForbiddenSuspendedError',
  'ProjectMetadataDefinitionIsUsedError' = 'ProjectMetadataDefinitionIsUsedError',
  'ProjectNotFoundError' = 'ProjectNotFoundError',
  'ProjectNotReadyError' = 'ProjectNotReadyError',
  'ProjectTemplateNotFoundError' = 'ProjectTemplateNotFoundError',
  'ProjectTemplateAdminGroupNotFoundError' = 'ProjectTemplateAdminGroupNotFoundError',
  'ProjectTemplateUserNotFoundError' = 'ProjectTemplateUserNotFoundError',
  'ProjectSettingNotFoundError' = 'ProjectSettingNotFoundError',
  'ProjectUserInvitationAlreadyAcceptedError' = 'ProjectUserInvitationAlreadyAcceptedError',
  'ProjectUserInvitationInvalidTermsAcceptCodeError' = 'ProjectUserInvitationInvalidTermsAcceptCodeError',
  'ProjectUserInvitationNotFoundError' = 'ProjectUserInvitationNotFoundError',
  'ProjectUserLockError' = 'ProjectUserLockError',
  'ProjectUserNotActivatedError' = 'ProjectUserNotActivatedError',
  'ProjectUserNotFoundError' = 'ProjectUserNotFoundError',
  'ProjectUserStatusCannotBeChangedError' = 'ProjectUserStatusCannotBeChangedError',
  'ProjectUserSuspendedError' = 'ProjectUserSuspendedError',
  'ReservedDocumentAccessError' = 'ReservedDocumentAccessError',
  'RestoreDirectoryForbiddenError' = 'RestoreDirectoryForbiddenError',
  'RestoreDocumentForbiddenError' = 'RestoreDocumentForbiddenError',
  'RevisionLockNotFoundError' = 'RevisionLockNotFoundError',
  'RevisionNotDiscardableError' = 'RevisionNotDiscardableError',
  'RevisionNotDiscardedBadRequestError' = 'RevisionNotDiscardedBadRequestError',
  'RevisionNotFoundError' = 'RevisionNotFoundError',
  'RevisionRestoreForbiddenError' = 'RevisionRestoreForbiddenError',
  'RoleAlreadyUsedError' = 'RoleAlreadyUsedError',
  'RoleAlreadyVotedError' = 'RoleAlreadyVotedError',
  'RoleDuplicateNameError' = 'RoleDuplicateNameError',
  'RoleInCommentProcedureError' = 'RoleInCommentProcedureError',
  'RoleInProcedureError' = 'RoleInProcedureError',
  'RoleIsAlreadyUsedError' = 'RoleIsAlreadyUsedError',
  'RoleNotFoundError' = 'RoleNotFoundError',
  'RoleRequireUserForbiddenError' = 'RoleRequireUserForbiddenError',
  'SecondaryDocumentNotFoundError' = 'SecondaryDocumentNotFoundError',
  'SelfPermissionsCannotBeSetError' = 'SelfPermissionsCannotBeSetError',
  'ServerError' = 'ServerError',
  'ShareExpiredError' = 'ShareExpiredError',
  'ShareInvalidFileCountError' = 'ShareInvalidFileCountError',
  'ShareInvalidPinError' = 'ShareInvalidPinError',
  'ShareNotFoundError' = 'ShareNotFoundError',
  'SigningNotFoundError' = 'SigningNotFoundError',
  'SourceDirectoryForDocumentMoveForbiddenError' = 'SourceDirectoryForDocumentMoveForbiddenError',
  'SubWorkflowNotFoundError' = 'SubWorkflowNotFoundError',
  'TmpBlobRequiredError' = 'TmpBlobRequiredError',
  'UnauthorizedError' = 'UnauthorizedError',
  'UploadAcccessDeniedError' = 'UploadAcccessDeniedError',
  'UploadAlreadyCompletedError' = 'UploadAlreadyCompletedError',
  'UploadAlreadyReferencedError' = 'UploadAlreadyReferencedError',
  'UploadedTaskNotFoundError' = 'UploadedTaskNotFoundError',
  'UploadExpiredError' = 'UploadExpiredError',
  'UploadMissingCompletePartsError' = 'UploadMissingCompletePartsError',
  'UploadNotCompleteError' = 'UploadNotCompleteError',
  'UploadNotFoundError' = 'UploadNotFoundError',
  'UploadPartAlreadyCompletedError' = 'UploadPartAlreadyCompletedError',
  'UploadPartETagIsRequiredError' = 'UploadPartETagIsRequiredError',
  'UploadPartNotFoundError' = 'UploadPartNotFoundError',
  'UserCantRemoveFromAdminGroupError' = 'UserCantRemoveFromAdminGroupError',
  'UserIsInRoleError' = 'UserIsInRoleError',
  'UserIsNotInvitedError' = 'UserIsNotInvitedError',
  'UserIsNotTeamAdminError' = 'UserIsNotTeamAdminError',
  'UserIsSystemFixedError' = 'UserIsSystemFixedError',
  'UserNameNotVerifiedBadRequestError' = 'UserNameNotVerifiedBadRequestError',
  'UserOrRoleCanBeSetError' = 'UserOrRoleCanBeSetError',
  'UserProfileAlreadyExistsError' = 'UserProfileAlreadyExistsError',
  'UserConfirmationResendRecentlyBadRequestError' = 'UserConfirmationResendRecentlyBadRequestError',
  'UserAlreadyConfirmedBadRequestError' = 'UserAlreadyConfirmedBadRequestError',
  'WopiSessionNotFoundError' = 'WopiSessionNotFoundError',
  'WfTemplateDuplicateNameError' = 'WfTemplateDuplicateNameError',
  'WorkflowApprovalDocumentAlreadyAssignedError' = 'WorkflowApprovalDocumentAlreadyAssignedError',
  'WorkflowApprovalDocumentNotFoundNotFoundError' = 'WorkflowApprovalDocumentNotFoundNotFoundError',
  'WorkflowDefaultDirectoryAssignedRequestError' = 'WorkflowDefaultDirectoryAssignedRequestError',
  'WorkflowDefaultDirectoryDuplicateNameError' = 'WorkflowDefaultDirectoryDuplicateNameError',
  'WorkflowDefaultDirectoryDuplicatePathError' = 'WorkflowDefaultDirectoryDuplicatePathError',
  'WorkflowDefaultDirectoryInsufficientAccessLevelError' = 'WorkflowDefaultDirectoryInsufficientAccessLevelError',
  'WorkflowDefaultDirectoryNotFoundError' = 'WorkflowDefaultDirectoryNotFoundError',
  'WorkflowDuplicateNameError' = 'WorkflowDuplicateNameError',
  'WorkflowInsufficientAccessLevelError' = 'WorkflowInsufficientAccessLevelError',
  'WorkflowNodeAttachmentNotDiscardedBadRequestError' = 'WorkflowNodeAttachmentNotDiscardedBadRequestError',
  'WorkflowNodeAttachmentNotFoundError' = 'WorkflowNodeAttachmentNotFoundError',
  'WorkflowNodeHasCorruptedLockError' = 'WorkflowNodeHasCorruptedLockError',
  'WorkflowNodeInsufficientAccessLevelError' = 'WorkflowNodeInsufficientAccessLevelError',
  'WorkflowNodeNotAcceptedBackgroundDocumentError' = 'WorkflowNodeNotAcceptedBackgroundDocumentError',
  'WorkflowNodeNoteChangeForbiddenError' = 'WorkflowNodeNoteChangeForbiddenError',
  'WorkflowNodeNoteNotFoundError' = 'WorkflowNodeNoteNotFoundError',
  'WorkflowNodeNotFoundError' = 'WorkflowNodeNotFoundError',
  'WorkflowNodeNotifiedUserNotFoundError' = 'WorkflowNodeNotifiedUserNotFoundError',
  'WorkflowNodePortNotFoundError' = 'WorkflowNodePortNotFoundError',
  'WorkflowNodeRoleNotFoundError' = 'WorkflowNodeRoleNotFoundError',
  'WorkflowNotFoundError' = 'WorkflowNotFoundError',
  'WorkflowTemplateInsufficientAccessLevelError' = 'WorkflowTemplateInsufficientAccessLevelError',
  'WorkflowTemplteNotFoundError' = 'WorkflowTemplteNotFoundError',
  'WorkflowUnassihnedAliasError' = 'WorkflowUnassihnedAliasError',
  'WorkflowHasCriticalErrorError' = 'WorkflowHasCriticalErrorError',
  'KontrolaSkupinIssueError' = 'KontrolaSkupinIssueError',
  'DocumentStateOperationForbiddenError' = 'DocumentStateOperationForbiddenError',
  'MdDivisionObstacleError' = 'MdDivisionObstacleError',
  'MdUserAccessRequiredError' = 'MdUserAccessRequiredError',
  'MdRoleAccessRequiredError' = 'MdRoleAccessRequiredError',
  'MdDivisionO910CannotBeChangedError' = 'MdDivisionO910CannotBeChangedError',
  'O910CanHaveOnlyO910UsersError' = 'O910CanHaveOnlyO910UsersError',
  'MdDivisionNotFoundError' = 'MdDivisionNotFoundError',
  'MdDivisionIsInMdProjectError' = 'MdDivisionIsInMdProjectError',
  'MdRoleNotFoundError' = 'MdRoleNotFoundError',
  'MdProjectNotFoundError' = 'MdProjectNotFoundError',
  'MdProjectHasIntentionError' = 'MdProjectHasIntentionError',
  'MdUserNotFoundError' = 'MdUserNotFoundError',
  'MdModuleNotFoundError' = 'MdModuleNotFoundError',
  'MdUserNotInSetDivisionError' = 'MdUserNotInSetDivisionError',
  'MdDivisionDuplicateNameError' = 'MdDivisionDuplicateNameError',
  'MdProjectDuplicateNameError' = 'MdProjectDuplicateNameError',
  'MdProjectUnsupportedShiftError' = 'MdProjectUnsupportedShiftError',
  'MdProjectUnsupportedStateOpperationError' = 'MdProjectUnsupportedStateOpperationError',
  'MdProjectShiftParametersNotSetError' = 'MdProjectShiftParametersNotSetError',
  'MdUserInAotherDivisionError' = 'MdUserInAotherDivisionError',
  'MdRoleWithSetHeadUserAlreadyExistError' = 'MdRoleWithSetHeadUserAlreadyExistError',
  'MdRoleIsProjectGuarantorError' = 'MdRoleIsProjectGuarantorError',
  'MdRoleIsProjectProcessorError' = 'MdRoleIsProjectProcessorError',
  'MdRoleIsSupervisorError' = 'MdRoleIsSupervisorError',
  'OverheadMdProjectNotSetError' = 'OverheadMdProjectNotSetError',
  'AssignmentOverviewPermissionNotSetError' = 'AssignmentOverviewPermissionNotSetError',
  'MdProjectHasNotCreatedHubProjectError' = 'MdProjectHasNotCreatedHubProjectError',
  'MdApprovalMeetingBackgroundMinutesStateNotFoundError' = 'MdApprovalMeetingBackgroundMinutesStateNotFoundError',
  'IdmIsNotConnectedError' = 'IdmIsNotConnectedError',
  'IdmIsNotEnabledError' = 'IdmIsNotEnabledError',
  'MdApprovalMeetingBackgroundMinutesDocumentExist' = 'MdApprovalMeetingBackgroundMinutesDocumentExist',
  'MdApprovalMeetingBackgroundMinutesNotDirExist ' = 'MdApprovalMeetingBackgroundMinutesNotDirExist',

  // general errors
  'NetworkError' = 'NetworkError',
}

export const serviceErrorEnumStatusCodeMap: { [key: number]: ServiceErrorEnum } = {
  0: ServiceErrorEnum.NetworkError,
  400: ServiceErrorEnum.BadRequestError,
  401: ServiceErrorEnum.UnauthorizedError,
  403: ServiceErrorEnum.ForbiddenError,
  404: ServiceErrorEnum.NotFoundError,
  410: ServiceErrorEnum.GoneError,
  500: ServiceErrorEnum.ServerError,
};

export interface DirectoryDownloadDocumentErrorData {
  objectId: Guid;
  objectName: string;
  objectPath: string;
  isDocument: boolean;
  isDirectoryLinkWarning: boolean;
}

export enum ErrorType {
  ApiError = 'ApiError',
  Cancelled = 'Cancelled',
  CustomError = 'CustomError',
}

export type ApiErrorOption = {
  type: ErrorType.ApiError;
  error: ServiceError; // TODO: use service error, or api error?
};

export type CancelledErrorOption = {
  type: ErrorType.Cancelled;
  cancellation: unknown; // TODO: cancelled reason type?
};

export type CustomErrorOption = {
  type: ErrorType.CustomError;
  data: unknown; // TODO: deal with custom error data
};

export type ErrorVariant = ApiErrorOption | CancelledErrorOption | CustomErrorOption;

export type Result<T> = [null, T] | [ErrorVariant, undefined];

export const apiResponseToMappedResult = <T, U>(apiResponse: ApiResponse<T>, mapper: Mapper<T, U>): Result<U> => {
  const [err, resp] = apiResponse;
  if (Axios.isCancel(err)) {
    return [{ type: ErrorType.Cancelled, cancellation: err }, undefined];
  }
  if (err) {
    return [{ type: ErrorType.ApiError, error: processApiError(err) }, undefined];
  }
  if (resp) {
    return [null, mapper(resp.data)];
  }
  // TODO: this should never be reached ... can typescript enforce or detect that?
  return undefined as never;
};

export const apiResponseToResult = <T>(apiResponse: ApiResponse<T>): Result<T> =>
  apiResponseToMappedResult(apiResponse, identityMapper);

export type MdDivisionObstacleErrorData = {
  mdProjects: MdProjectErrorData[];
  mdRoles: MdRoleErrorData[];
};

type MdProjectErrorData = {
  projectName: string;
  processorDivision: string;
  proposerDivision: string;
};

type MdRoleErrorData = {
  roleHead: string;
  roleType: MdRoleEnum;
};

type MdUserRoleData = {
  mdUser: MdUserDto;
  mdRoleType: MdRoleEnum;
};

export type MdDivisionUserObstacleError = {
  usersInRoleHead: MdUserRoleData[];
  usersInRoleMembers: MdUserRoleData[];
};
