import { masterApi } from 'api/completeApi';
import { EntityTypesEnum, ExtendedPermissionTemplateDto } from 'api/completeApiInterfaces';
import { AuditLogButton, EditButton } from 'components/ActionButtons';
import { DeleteButtonConfirm } from 'components/ActionButtons/DeleteButtonConfirm';
import AuditLogMasterApiEntityModal from 'components/AuditLogsComponents/AuditLogMasterApiEntityModal';
import CommonHubEllipsisText from 'components/CommonHubEllipsisText/CommonHubEllipsisText';
import { ContentGate } from 'components/ContentGate/ContentGate';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import StackPanel from 'components/StackPanel';
import { useBoolean, useIntl } from 'hooks';
import { Fmt, InjectedIntlProps } from 'locale';
import Panel from 'pages/ProjectSettingsPage/Panel/Panel';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { messageError, smartFilter, strCompareCI } from 'utils';
import UserPermissionProfileFormModal from './UserPermissionProfileFormModal';

type Props = InjectedIntlProps & {
  organizationId: Guid;
  permissionProfileList: ExtendedPermissionTemplateDto[];
  permissionProfileLoading: boolean;
  reloadOrganizationPermissionProfiles: () => void;
};

const UserPermissionProfileListPanel: FunctionComponent<Props> = ({
  organizationId,
  permissionProfileList,
  permissionProfileLoading,
  reloadOrganizationPermissionProfiles,
}) => {
  const [search, setSearch] = useState<string>('');
  const [permissionProfileModalVisible, showPermissionProfileModal, hidePermissionProfileModal] = useBoolean(false);
  const [profileToEditId, setProfileToEditId] = useState<Guid>(undefined);
  const [toShowActivityProfile, setToShowActivityProfile] = useState<ExtendedPermissionTemplateDto>();
  const { url } = useRouteMatch();
  const intl = useIntl();

  const editedPermissionProfile = useMemo(
    () => permissionProfileList?.find((profile) => profile.id === profileToEditId),
    [profileToEditId, permissionProfileList]
  );

  const handlePermissionProfileEditShow = useCallback((editedId: Guid) => {
    setProfileToEditId(editedId);
    showPermissionProfileModal();
  }, []);

  const handlePermissionProfileSubmit = useCallback(() => {
    setProfileToEditId(undefined);
    reloadOrganizationPermissionProfiles();
    hidePermissionProfileModal();
  }, [reloadOrganizationPermissionProfiles]);

  const handlePermissionProfileClose = useCallback(() => {
    setProfileToEditId(undefined);
    hidePermissionProfileModal();
  }, []);

  const handlePermisionProfileDelete = useCallback(
    async (templateId: Guid) => {
      const [err] = await masterApi.projects.tempates.extendedpermissiontemplate.id.delete(templateId);
      if (err) {
        messageError(err, intl);
      } else {
        reloadOrganizationPermissionProfiles();
      }
    },
    [intl, reloadOrganizationPermissionProfiles]
  );

  const validateUniqueName = useCallback(
    (name: string): boolean =>
      !permissionProfileList.some(
        (profile) =>
          strCompareCI(profile.name, name) === 0 && (!!profileToEditId ? profile.id !== profileToEditId : true)
      ),
    [profileToEditId, permissionProfileList]
  );

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const hideAuditLog = useCallback(() => {
    setToShowActivityProfile(undefined);
  }, []);

  return (
    <>
      <MasterComponent
        url={url}
        title={intl.formatMessage({ id: 'OrganizationUserPermissionProfiles.title' })}
        children={(onSelect, selectedItemId) => (
          <StackPanel>
            <Panel
              noMargin
              panelWidth="auto"
              addButtonOnClick={showPermissionProfileModal}
              addButtonText={<Fmt id="OrganizationUserPermissionProfiles.modal.add.title" />}
              onSearch={setSearch}
              searchValue={search}
            >
              <ContentGate loading={permissionProfileLoading} empty={!permissionProfileList}>
                <GeneralSettingsContainer>
                  <List<ExtendedPermissionTemplateDto>
                    data={permissionProfileList}
                    search={search}
                    filterItem={(item) =>
                      (item && smartFilter(item?.name, search)) || smartFilter(item?.description, search)
                    }
                    renderItem={(item) => (
                      <GeneralSettingsItem
                        key={item.id}
                        title={item.name}
                        wrap
                        description={
                          !!item.description ? (
                            <CommonHubEllipsisText placement="topLeft" title={item.description}>
                              {item.description}
                            </CommonHubEllipsisText>
                          ) : null
                        }
                        additionalActions={
                          <>
                            {<EditButton onClick={() => handlePermissionProfileEditShow(item.id)} />}
                            {
                              <DeleteButtonConfirm
                                onConfirm={() => handlePermisionProfileDelete(item.id)}
                                confirmTooltip={<Fmt id="ProjectTemplates.list.deleteTemplate.confirmation" />}
                              />
                            }
                            {<AuditLogButton onClick={() => setToShowActivityProfile(item)} />}
                          </>
                        }
                      />
                    )}
                    renderEmpty={(total, filtered) => (
                      <ListEmpty filtered={filtered} total={total} onClearSearch={clearSearch} />
                    )}
                  />
                </GeneralSettingsContainer>
              </ContentGate>
              {!!permissionProfileModalVisible && (
                <UserPermissionProfileFormModal
                  open={!!permissionProfileModalVisible}
                  onSubmit={handlePermissionProfileSubmit}
                  onClose={handlePermissionProfileClose}
                  editedPermissionProfile={editedPermissionProfile}
                  organizationId={organizationId}
                  validateUniqueName={validateUniqueName}
                />
              )}
              {!!toShowActivityProfile && (
                <AuditLogMasterApiEntityModal
                  visible={!!toShowActivityProfile}
                  label={
                    <Fmt id="AuditLog.permissionProfile.label" values={{ profileName: toShowActivityProfile.name }} />
                  }
                  entityId={toShowActivityProfile.id}
                  entityType={EntityTypesEnum.extendedPermissionTemplate}
                  onOk={hideAuditLog}
                  deps={[toShowActivityProfile]}
                />
              )}
            </Panel>
          </StackPanel>
        )}
      />
    </>
  );
};

export default React.memo(UserPermissionProfileListPanel);
