import { Switch, SwitchProps } from 'antd';
import { masterApi } from 'api/completeApi';
import { JSONVariableTypeEnum, MdProjectVariableEnum } from 'api/completeApiInterfaces';
import { MdProjectWithUnpackedData } from 'components/JSONVariableFormItems/dataUnpackers/mdProjectUnpacker.utils';
import { isJSONBooleanValueTrue } from 'components/JSONVariableFormItems/Items/JSONVariableCheckboxFormItem';
import { useIntl, useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import React, { useState } from 'react';
import { messageError } from 'utils';

type Props = {
  mdProject: MdProjectWithUnpackedData;
  onProjectUpdate: (project: MdProjectWithUnpackedData) => void;
} & Omit<SwitchProps, 'onChange' | 'loading'>;

const MDSetCkPreparedSwitch: React.FC<Props> = ({ mdProject, onProjectUpdate, ...switchProps }) => {
  const intl = useIntl();
  const [isLoading, setLoading] = useState(false);

  const handleChange = useSameCallback(async (checked: boolean, event: React.MouseEvent<HTMLButtonElement>) => {
    setLoading(true);
    const [err, res] = await masterApi.projects.md.project.setdata.post({
      mdProjectId: mdProject.id,
      phase: mdProject.phase,
      insertOrUpdateData: {
        [MdProjectVariableEnum.preparedForCkApproval]: {
          type: JSONVariableTypeEnum.boolean,
          value: checked ? 'true' : 'false',
        },
      },
    });
    if (err) {
      messageError(err, intl);
      setLoading(false);
      return;
    }
    onProjectUpdate(res.data);
    setLoading(false);
  });

  return (
    <Switch
      checkedChildren={<Fmt id="MD.Projects.ProjectCkApprovalReadinesSetModal.title" />}
      unCheckedChildren={<Fmt id="MD.Projects.ProjectCkApprovalReadinesSetModal.title" />}
      defaultChecked={isJSONBooleanValueTrue(mdProject.preparedForCkApproval?.value)}
      onChange={handleChange}
      loading={isLoading}
      {...switchProps}
    />
  );
};

export default MDSetCkPreparedSwitch;
