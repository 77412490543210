import { Dropdown, DropDownProps, MenuProps, Tree, Typography } from 'antd';
import { DataNode } from 'antd/lib/tree';
import { masterApi } from 'api/completeApi';
import { OrganizationStructureDto, OrganizationStructureListDto } from 'api/completeApiInterfaces';
import StackPanel from 'components/StackPanel';
import { useBoolean, useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { messageError } from 'utils';
import { OrganizationStructureFormModal } from './OrganizationStructureFormModal';

type Props = {
  organizationstructure: OrganizationStructureListDto;
  loadOrganizationStructure: () => void;
  selectedStructureNode: OrganizationStructureDto;
  setSelectedStructureNode: React.Dispatch<React.SetStateAction<OrganizationStructureDto>>;
  organizationId: Guid;
  setOrganizationStructure: React.Dispatch<React.SetStateAction<OrganizationStructureListDto>>;
};

const DROPDOWN_TRIGGER: DropDownProps['trigger'] = ['contextMenu'];

const eliminateNode = (orgStructArray: OrganizationStructureDto[], deleteNodeId: Guid): OrganizationStructureDto[] => {
  return orgStructArray
    .map(
      (item) =>
        item.id !== deleteNodeId && {
          ...item,
          children: eliminateNode(item?.children || [], deleteNodeId).filter(Boolean),
        }
    )
    .filter(Boolean);
};

const OrganizationStructureSettingTree: FunctionComponent<Props> = ({
  organizationstructure,
  loadOrganizationStructure,
  selectedStructureNode,
  setSelectedStructureNode,
  organizationId,
  setOrganizationStructure,
}) => {
  const intl = useIntl();
  const [formModalVisible, showFormModal, hideFormModal] = useBoolean(false);
  const [parentId, setParentId] = useState<Guid>(undefined);

  const orgStructArray: OrganizationStructureDto[] = useMemo(() => {
    return organizationstructure?.organizationStructure || [];
  }, [organizationstructure]);

  const handleDeleteNode = useCallback(
    async (deleteNodeId: Guid) => {
      const [error, resp] = await masterApi.projects.reports.organizationstructure.id.patch(organizationId, {
        organizationStructure: eliminateNode(orgStructArray, deleteNodeId),
      });

      if (!!error) {
        messageError(error, intl);
        hideFormModal();

        return null;
      }
      setOrganizationStructure(resp.data);
      hideFormModal();
      return null;
    },
    [organizationId, orgStructArray, setOrganizationStructure, hideFormModal, intl]
  );

  const directoriesContextMenu = useCallback(
    (node: OrganizationStructureDto): MenuProps => ({
      items: [
        {
          label: <Fmt id="general.add" />,
          key: 'createDirectory',
          onClick: () => {
            setParentId(node.id);
            showFormModal();
            setSelectedStructureNode(undefined);
          },
        },
        {
          label: <Fmt id="general.edit" />,
          key: 'edit',
          onClick: () => {
            setSelectedStructureNode(node);
            showFormModal();
          },
        },

        {
          label: <Fmt id="general.delete" />,
          key: 'delete',
          onClick: () => handleDeleteNode(node.id),
          disabled: !!node?.children?.length,
        },
      ],
    }),
    [handleDeleteNode, setSelectedStructureNode, showFormModal]
  );

  const renderTitle = (node: OrganizationStructureDto, contextMenu: (node: OrganizationStructureDto) => MenuProps) => {
    return (
      <Dropdown menu={contextMenu(node)} trigger={DROPDOWN_TRIGGER}>
        <div>
          <Typography.Text>{node.sign}</Typography.Text>
          {' - '}
          <Typography.Text strong>{node.name}</Typography.Text>
          <div style={{ display: 'grid', marginTop: '-0.2rem' }}>
            {!!node.description && <Typography.Text type="secondary">{`${node.description}`}</Typography.Text>}
          </div>
        </div>
      </Dropdown>
    );
  };

  const structureToTree = useCallback(
    (organizationStructure: OrganizationStructureDto[]): DataNode[] => {
      return organizationStructure.map((item) => ({
        title: renderTitle(item, directoriesContextMenu),
        key: item.id,
        children: item?.children?.length ? structureToTree(item.children) : null,
      }));
    },
    [directoriesContextMenu]
  );

  const convertStructureDataToTreeData = useMemo(() => {
    if (!organizationstructure?.organizationStructure.length) return [];
    return structureToTree(organizationstructure.organizationStructure);
  }, [organizationstructure, structureToTree]);

  return (
    <>
      <StackPanel vertical>
        <Tree blockNode selectable showLine defaultExpandAll treeData={convertStructureDataToTreeData} />
      </StackPanel>
      {formModalVisible && (
        <OrganizationStructureFormModal
          onSubmit={() => {
            hideFormModal();
            loadOrganizationStructure();
          }}
          onClose={() => {
            hideFormModal(), setParentId(undefined);
            setParentId(undefined);
            setSelectedStructureNode(undefined);
          }}
          open={formModalVisible}
          selectedStructureNode={selectedStructureNode}
          organizationStructure={organizationstructure}
          organizationId={organizationId}
          setOrganizationStructure={setOrganizationStructure}
          parentId={parentId}
        />
      )}
    </>
  );
};

export default OrganizationStructureSettingTree;
