import { useEffect } from 'react';
import SignalR from 'utils/SignalR';
import { DEBUG } from 'config/env';

export const useJoinSignalGroup = (signal: SignalR, groupName: string) => {
  useEffect(() => {
    const usedSignal = signal;
    const usedGroupName = groupName;

    if (usedGroupName) {
      DEBUG && console.log(`Joining group ${usedGroupName}`);
      void usedSignal.joinGroup(usedGroupName);
    }
    return () => {
      if (usedGroupName) {
        DEBUG && console.log(`Leaving group ${usedGroupName}`);
        void usedSignal.leaveGroup(usedGroupName);
      }
    };
  }, [groupName, signal]);
};
