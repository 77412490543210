import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Alert, Button, InputRef } from 'antd';
import TextArea, { TextAreaRef } from 'antd/lib/input/TextArea';
import CommonHubEllipsisText from 'components/CommonHubEllipsisText/CommonHubEllipsisText';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { EditIcon } from 'components/Icons/HubActionsIcons';
import { useIsMounted } from 'hooks';
import React, {
  FormEvent,
  FunctionComponent,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  value: string;
  headline: ReactNode;
  onSave?: (value: string) => Promise<boolean | ReactNode>;
  disableEdit?: boolean;
  inputRef?: RefObject<InputRef | TextAreaRef>;
  maxLength?: number;
  loading?: boolean;
  itemId?: Guid;
  icon?: ReactNode;
};

const GeneralTextAreaSettingsItem: FunctionComponent<Props> = ({
  disableEdit,
  value,
  onSave,
  headline,
  inputRef,
  maxLength,
  loading,
  itemId,
  icon,
}) => {
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState<ReactNode>(null);
  const [liveValue, setLiveValue] = useState<string>(value);

  const newRef = useRef<InputRef | TextAreaRef>();
  const _ref = !!inputRef ? inputRef : newRef;

  const isMounted = useIsMounted();

  useEffect(() => {
    setLiveValue(value);
  }, [value]);

  useEffect(() => {
    if (!!itemId) handleCancel();
  }, [itemId]);

  const handleCancel = useCallback(() => {
    setLiveValue(value);
    setError(null);
  }, [value]);

  const handleSave = useCallback(async () => {
    if (onSave) {
      setSaving(true);
      const result = await onSave(liveValue);
      if (!isMounted) {
        return;
      }
      if (result !== false && result !== true) {
        setError(result);
        _ref.current.focus();
      } else if (result) {
        setError(null);
        setEditing(false);
      }
      setSaving(false);
    } else {
      setEditing(false);
    }
  }, [onSave, liveValue, _ref]);

  const handleValueChange = (e: FormEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setLiveValue(e.currentTarget.value);
  };

  const isDirty = liveValue !== value;

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancelEditing = () => {
    setLiveValue(value);
    setError(null);
    setEditing(false);
  };

  return (
    <GeneralSettingsItem
      icon={icon}
      disabled={disableEdit}
      title={headline}
      additionalActions={
        <>
          {!editing ? (
            <Button type="link" icon={<EditIcon />} onClick={handleEdit} disabled={isDirty || disableEdit} />
          ) : (
            <>
              <Button type="link" icon={<SaveOutlined />} onClick={handleSave} disabled={!isDirty} loading={loading} />
              <Button type="link" icon={<CloseOutlined />} onClick={handleCancelEditing} />
            </>
          )}
        </>
      }
      description={
        editing ? (
          <>
            <TextArea
              rows={4}
              defaultValue={liveValue}
              onChange={handleValueChange}
              disabled={disableEdit || saving || loading}
              autoFocus
              ref={_ref as RefObject<TextAreaRef>}
              maxLength={maxLength}
            />
            {!!error && <Alert type="error" banner message={error} />}
          </>
        ) : (
          <CommonHubEllipsisText title={value}>{value}</CommonHubEllipsisText>
        )
      }
    />
  );
};

export default GeneralTextAreaSettingsItem;
