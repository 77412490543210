import { Modal } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { ModalProps } from 'antd/lib/modal';
import { CancelTokenSource } from 'axios';
import { useIsMounted, useSameCallback } from 'hooks';
import { useCloseOnEscape } from 'hooks/useCloseOnEscape';
import React, { CSSProperties, FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { DocumentSelect, DocumentsSelectProps } from './DocumentSelect';

const DOCUMENT_SELECT_MODAL_STYLE: CSSProperties = { height: '500px', display: 'flex', padding: '0' };

export type DocumentSelectModalProps = Omit<
  DocumentsSelectProps,
  'chaningDocuments' | 'setChaningDocuments' | 'selectDocumentsTokenRef'
>;

type Props = DocumentSelectModalProps & ModalProps & { isCancelable?: boolean };

export const DocumentSelectModal: FunctionComponent<Props> = ({
  startDirectoryId,
  multiple,
  disabledDocuments,
  setSelectedDocuments,
  selectedDocuments,
  isCancelable,
  ...modalProps
}) => {
  const documentSelectProps = {
    startDirectoryId,
    multiple,
    disabledDocuments,
    setSelectedDocuments,
    selectedDocuments,
  };
  const { onOk, onCancel, okButtonProps, cancelButtonProps, open, ...restProps } = modalProps;

  const [chaningDocuments, setChaningDocuments] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);

  const addModalLoadingProps = useMemo<ButtonProps>(
    () => ({ ...okButtonProps, loading: okButtonProps?.loading || modalLoading }),
    [okButtonProps, modalLoading]
  );
  const modalCancelProps = useMemo<ButtonProps>(
    () => ({ ...cancelButtonProps, disabled: isCancelable ? false : modalLoading }),
    [cancelButtonProps, modalLoading, isCancelable]
  );

  const isMounted = useIsMounted();

  const selectDocumentsTokenRef = useRef<CancelTokenSource>(null);
  useEffect(() => () => selectDocumentsTokenRef.current?.cancel('DocumentSelectModal: unmounting'), []);

  const handleOk = useSameCallback(async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (chaningDocuments) {
      return; // cannot confirm while changing document
    }
    setModalLoading(true);
    onOk && (await onOk(event));
    if (isMounted.current) setModalLoading(false);
  });

  const handleCancel = useSameCallback((event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    selectDocumentsTokenRef.current?.cancel('DocumentSelectModal: manual cancel');
    setChaningDocuments(false);
    setModalLoading(false);
    onCancel && onCancel(event);
  });

  useCloseOnEscape(open, handleCancel);

  return (
    <Modal
      width="1200"
      bodyStyle={DOCUMENT_SELECT_MODAL_STYLE}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={addModalLoadingProps}
      cancelButtonProps={modalCancelProps}
      centered
      open={open}
      keyboard={false}
      destroyOnClose
      {...restProps}
    >
      <DocumentSelect
        chaningDocuments={chaningDocuments}
        setChaningDocuments={setChaningDocuments}
        selectDocumentsTokenRef={selectDocumentsTokenRef}
        visible={open}
        {...documentSelectProps}
      />
    </Modal>
  );
};

export const DocumentSelectModalMemoized = React.memo(DocumentSelectModal);
