import DocumentMultipleActionError from 'components/DocumentMultipleActionError';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FC } from 'react';
import styles from './RestoreForm.module.less';

type Props = {
  message: string;
  errorTitleId: IntlMessageId;
  errors: JSX.Element[];
};

const RestoreForm: FC<Props> = ({ message, errorTitleId, errors }) => {
  return (
    <>
      <div className={styles.restoreDocumentsWrap}>
        <div className={styles.restoreDocumentsTitle}>{message}</div>
      </div>
      {errors.length > 0 && <DocumentMultipleActionError errors={errors} titleId={errorTitleId} />}
    </>
  );
};

export default RestoreForm;
