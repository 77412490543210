import { QuestionOutlined, SolutionOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Badge, Popover } from 'antd';
import { RoleDto } from 'api/completeApiInterfaces';
import classNames from 'classnames';
import { UserAvatarSize, UserIcon } from 'components/avatars/UserIcon/UserIcon';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { memoizeWithIntl } from 'locale';
import { Fmt, InjectedIntlProps } from 'locale/Fmt';
import { missingOrSuspendedUserInRole } from 'pages/ProjectSettingsPage/Roles/RolesListPanel/RolesList';
import React, { FunctionComponent, useMemo } from 'react';
import styles from './RoleInfo.module.less';

type Props = InjectedIntlProps & {
  role: RoleDto | null;
  emailLink?: boolean;
};

const RoleInfoComponent: FunctionComponent<Props> = ({ intl, role, emailLink }) => {
  const { name, user, roleAssociates } = role || { name: intl.formatMessage({ id: 'RoleInfo.noRole' }) };

  const roleUserIcon = useMemo(() => {
    const userIcon = (
      <span className={styles.roleUserIcon}>
        {user ? (
          <UserIcon user={user} size={UserAvatarSize.Large} />
        ) : !!roleAssociates?.length ? (
          <TeamOutlined className={styles.associatedOnlyIcon} />
        ) : (
          <QuestionOutlined className={styles.noUserIcon} />
        )}
      </span>
    );
    if (!roleAssociates?.length) return userIcon;
    return (
      <Popover
        title={intl.formatMessage({ id: 'general.Associated' })}
        trigger={'hover'}
        placement="left"
        content={roleAssociates.map((associated) => (
          <div key={associated.user.username}>{associated.user.username}</div>
        ))}
      >
        <Badge dot color="lime">
          {userIcon}
        </Badge>
      </Popover>
    );
  }, [intl, roleAssociates, user]);

  return (
    <span className={styles.roleInfo}>
      {roleUserIcon}
      <span className={styles.name}>
        <CommonHubTooltip
          placement="topLeft"
          title={<div>{`${intl.formatMessage({ id: 'general.roleName' })}: ${name}`}</div>}
        >
          <strong className={classNames(styles.roleName, missingOrSuspendedUserInRole(role) && styles.noActiveUser)}>
            <SolutionOutlined className={styles.roleIcon} />
            <span className={styles.withIcon}> {name}</span>
          </strong>
        </CommonHubTooltip>
        {!!user ? (
          <span
            className={styles.usernameContainer}
            title={`${intl.formatMessage({ id: 'general.userName' })}: ${user.username}`}
          >
            <UserOutlined className={styles.userIcon} />
            {emailLink ? (
              <a target="_blank" href={`mailto:${user.username}`} className={styles.withIcon} rel="noreferrer">
                <span>{user.username}</span>
              </a>
            ) : (
              <span className={styles.withIcon}>{user.username}</span>
            )}
          </span>
        ) : !!roleAssociates?.length ? (
          <span className={styles.noUser}>
            (<Fmt id="general.AssociatedOnly" />)
          </span>
        ) : (
          <span className={styles.noUser}>
            (<Fmt id="general.noUser" />)
          </span>
        )}
      </span>
    </span>
  );
};

export const RoleInfo = memoizeWithIntl(RoleInfoComponent);
