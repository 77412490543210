import { DatePicker, Form, Typography } from 'antd';
import { masterApi } from 'api/completeApi';
import { OrganizationAdminReportDto } from 'api/completeApiInterfaces';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useIntl, useIsMounted } from 'hooks';
import { Fmt } from 'locale';
import moment, { Moment } from 'moment';
import React, { useCallback, useState } from 'react';
import { messageError } from 'utils';
import { downloadBlob } from 'utils/downloadFile';
import { requiredRule } from 'utils/formHelpers';
const { RangePicker } = DatePicker;

type Props = FormModalProps<OrganizationAdminReportDto> & {
  activeUsersReportOrganization: OrganizationAdminReportDto;
};

export type OrganizationActiveUsersReportFormData = {
  monitoredPeriod: [Moment, Moment];
};

const OrganizationActiveUsersReportModal: React.FC<Props> = ({
  activeUsersReportOrganization,
  onClose,
  onSubmit,
  ...modalProps
}) => {
  const intl = useIntl();
  const [isLoading, setLoading] = useState(false);
  const isMounted = useIsMounted();

  const handleFormSubmit: FormSubmitHandler<OrganizationActiveUsersReportFormData> = useCallback(
    async (values) => {
      setLoading(true);
      const [err, resp] = await masterApi.projects.admin.users.report.post({
        organizationId: activeUsersReportOrganization.id,
        timeFrom: moment(values.monitoredPeriod[0])
          .startOf('day')
          .toISOString(),
        timeTo: moment(values.monitoredPeriod[1])
          .endOf('day')
          .toISOString(),
        language: intl.locale,
      });
      if (!isMounted.current) return null;

      if (err) {
        setLoading(false);
        messageError(err, intl);
        return err;
      }

      if (!!resp.data) {
        const blob = new Blob([resp.data], { type: 'application/ms-excel' });
        downloadBlob(blob, 'Organization_Active_Users_Report.xlsx');
      }
      setLoading(false);
      onClose();
      return null;
    },
    [activeUsersReportOrganization, intl, onClose]
  );

  return (
    <FormModalWrapper
      title={<Fmt id="OrganizationActiveUsersReportModal.title" />}
      onSubmit={handleFormSubmit}
      okButtonProps={{ loading: isLoading }}
      onClose={onClose}
      open={!!activeUsersReportOrganization}
      submitTextId="general.download"
      cancelTextId="general.close"
      {...modalProps}
    >
      <Typography.Paragraph>
        <FlowLayout>
          <Fmt
            id="OrganizationActiveUsersReportModal.orgName"
            values={{ orgName: activeUsersReportOrganization?.name }}
          />
        </FlowLayout>
      </Typography.Paragraph>
      <Form.Item
        label={<Fmt id="OrganizationActiveUsersReportModal.reportingPeriod" />}
        name="monitoredPeriod"
        rules={[requiredRule('forms.items.rules.required')]}
      >
        <RangePicker />
      </Form.Item>
    </FormModalWrapper>
  );
};

export default OrganizationActiveUsersReportModal;
