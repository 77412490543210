import { useIntl } from 'hooks';
import moment from 'moment/moment';

const DATE_FORMAT = 'LL';

export const useAssignmentDeadlineFormatter = (assignment: { deadlineDate?: string }) => {
  const intl = useIntl();
  const asMoment = !!assignment.deadlineDate ? moment(assignment.deadlineDate) : null;

  return {
    asMoment,
    asFormatedDate: asMoment?.locale(intl.locale)?.format(DATE_FORMAT),
    dateFormat: DATE_FORMAT,
  } as const;
};
