import { DatePicker } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker';
import { CalendarDto } from 'api/completeApiInterfaces';
import { default as classNames, default as classnames } from 'classnames';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { getHoliday, isWorkingDay } from 'components/forms/CalendarSettingsForm/CalendarSettingsForm.utils';
import { useStoreSelector } from 'hooks';
import { Moment } from 'moment';
import React, { FunctionComponent, useCallback } from 'react';
import styles from './DatePickerWithHolidays.module.less';

type Props = { danger?: boolean } & DatePickerProps;

const HOLIDAY_TOOLTIP_DELAY = 1;

export const formatPickerHolidayHighlight = (current: Moment, calendar?: CalendarDto) => {
  const holiday = getHoliday(current, calendar);
  const isFreeDay = !isWorkingDay(current, calendar) || !!holiday;

  const dateComponent = (
    <div className={classNames('ant-picker-cell-inner', isFreeDay && styles.freeDay)}>{current.date()}</div>
  );
  if (!!holiday) {
    return (
      <CommonHubTooltip title={holiday.name} mouseEnterDelay={HOLIDAY_TOOLTIP_DELAY}>
        {dateComponent}
      </CommonHubTooltip>
    );
  }

  return dateComponent;
};

export const DatePickerWithHolidays: FunctionComponent<Props> = ({
  danger,
  allowClear = false,
  disabled,
  ...datepickerProps
}) => {
  const { activeCalendar, activeCalendarError, activeCalendarLoading } = useStoreSelector(
    (state) => state.activeCalendar
  );

  const cellHighlight = useCallback(
    (current: Moment) => {
      const calendar = activeCalendar?.projectCalendar || activeCalendar?.orgCalendar;
      return formatPickerHolidayHighlight(current, calendar);
    },
    [activeCalendar]
  );

  return (
    <DatePicker
      {...datepickerProps}
      className={classnames(datepickerProps.className, danger && styles.danger)}
      dateRender={cellHighlight}
      disabled={activeCalendarLoading || disabled}
      allowClear={allowClear}
    />
  );
};
