import { Alert, Button, message } from 'antd';
import { api } from 'api';
import { apiConstraints } from 'api/completeApiConstraints';
import {
  BlobDerivateTypeEnum,
  DocumentAnnotationDto,
  DocumentAnnotationSaveDto,
  DocumentLockPurposeEnum,
  RevisionDto,
  WorkflowDto,
} from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { DocumentReservationLock } from 'components/DocumentReservationLock/DocumentReservationLock';
import { CreateDocumentUploadData } from 'components/forms/DocumentCreateForm/DocumentCreateFormModal';
import { RevisionCreateFormModal } from 'components/forms/RevisionCreateForm';
import { createRevisionUploadData } from 'components/forms/RevisionCreateForm/RevisionCreateFormModal';
import Modal from 'components/Modal/Modal';
import { decryptLicenceKey } from 'components/PdfDiff/PdfDiff.utils';
import { useWindowDisabler } from 'components/WindowDisabler/WindowDisabler';
import { useApiData, useIntl, useSameCallback } from 'hooks';
import { useBoolean } from 'hooks/useBoolean';
import { Fmt } from 'locale';
import { createRevisionWorkflowData } from 'pages/WorkflowDetailPage/DetailTabs/ActiveTaskDetail/DetailTaskComponents/AddedRevisions/UploadApprovalRevisionModal';
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { DisableWindowStoreStartPayload } from 'store/models/storeModelinterfaces';
import { messageError, processApiError } from 'utils';
import { checkIsGuid } from 'utils/bcf/xml/utils';
import { fileNameChangeExtension } from 'utils/fileNameChangeExtenison';
import { modalFormConfirmClose } from 'utils/modalFormConfirmClose';
import uuid from 'uuid';
import { AnnotationData, PdfAnnotation } from './PdfAnnotation';

export enum AnnotationModeEnum {
  documentAnnotation = 'documentAnnotation',
  commentProcedureAnnotation = 'commentProcedureAnnotation',
  annotationViewer = 'annotationViewer',
}

export const mapAnnotationDataToAnnotationSaveDto = (annotationData: AnnotationData): DocumentAnnotationSaveDto => ({
  content: (annotationData.content || annotationData.note)?.substring(
    0,
    apiConstraints.documentAnnotationSaveDto.content.maxLength
  ),
  id: checkIsGuid(annotationData.contentId) ? annotationData.contentId : uuid(),
  position: annotationData.position,
  annotationData: annotationData.storeData,
});

type Props = {
  visible: boolean;
  onClose: () => void;
  documentId: Guid;
  pdfTronLicenseKey: string;
  revision: RevisionDto;
  annotationMode: AnnotationModeEnum;
  canAddRevision: boolean;
  canEditOwnAnnotations?: boolean;
  canEditOthersAnnotations?: boolean;
  canReplyToAnnotations?: boolean;
  additionalButtons?: ReactNode;
  highlightedAnnotationId?: Guid;
  onAnnotationsChanged?: (annotations: AnnotationData[]) => void;
  onNewAnnotationsChanged?: (annotations: AnnotationData[]) => void;
  annotationModalDisablerDeactivated?: boolean;
  workflowNodeId?: Guid;
  workflowId?: Guid;
};

const PdfAnnotationModalComponent: FunctionComponent<Props> = ({
  visible,
  onClose,
  documentId,
  pdfTronLicenseKey,
  revision,
  annotationMode,
  canAddRevision,
  canEditOthersAnnotations,
  canEditOwnAnnotations,
  canReplyToAnnotations,
  additionalButtons,
  highlightedAnnotationId,
  onAnnotationsChanged,
  onNewAnnotationsChanged,
  annotationModalDisablerDeactivated = false,
  workflowNodeId,
  workflowId,
}) => {
  const [isRevisionModalVisible, showRevisionModal, hideRevisionModal] = useBoolean(false);
  const [updatedPdfBlob, setUpdatedPdfBlob] = useState<Blob>();
  const [savingAnnotations, setSavingAnnotations] = useState<boolean>(false);
  const [updatedDocumentAnnotations, setUpdatedDocumentAnnotations] = useState<AnnotationData[]>([]);
  const [annotationsError, setAnnotationsError] = useState<string>();
  const [isUpToDateDocument, setUpToDateDocument] = useState<boolean>(true);
  const intl = useIntl();
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false);

  const [documentUrl, error, urlLoading, loadUrl] = useApiData((ct) =>
    api.project.documents.getDocumentRevisionDerivateDownloadUrlById(
      documentId,
      revision?.id,
      BlobDerivateTypeEnum.Pdf,
      undefined,
      ct
    )
  );

  const isReadOnlyMode = annotationMode === AnnotationModeEnum.annotationViewer;

  useEffect(() => {
    if (!visible || !isReadOnlyMode) return;

    loadUrl();
  }, [isReadOnlyMode, visible]);

  const annotationFileName = useMemo(() => fileNameChangeExtension(revision?.primaryFile?.name, 'pdf'), [
    revision?.primaryFile?.name,
  ]);
  const newRevisionFile = useMemo(() => new File([updatedPdfBlob], annotationFileName), [
    updatedPdfBlob,
    annotationFileName,
  ]);
  const documentName = useMemo(() => revision?.primaryFile?.name, [revision]);
  const licenseKey = useMemo(() => pdfTronLicenseKey && decryptLicenceKey(pdfTronLicenseKey), [pdfTronLicenseKey]);

  const [
    documentAnnotations,
    documentAnnotationsError,
    documentAnnotationsLoading,
    loadDocumentAnnotations,
  ] = useApiData((ct) => api.project.documentAnnotations.getDocumentAnnotations(documentId, revision?.id, ct), {
    autoload: false,
  });

  useEffect(() => {
    if (!visible || !revision) return;

    loadDocumentAnnotations();
  }, [visible, revision?.id]);

  const handleDocumentAnnotationsChange = useCallback((data: AnnotationData[]) => {
    if (data.some((annotation) => !annotation.content?.length && !annotation.note?.length)) {
      setAnnotationsError(intl.formatMessage({ id: 'DocumentAnnotationModal.error.emptyAnnotation' }));
      return;
    }
    setAnnotationsError(undefined);
    setUpdatedDocumentAnnotations(data);
    onAnnotationsChanged && onAnnotationsChanged(data);
  }, []);

  const handleAnnotationUpdate = useSameCallback(async () => {
    setSavingAnnotations(true);
    const [err, res] = await api.project.documentAnnotations.saveDocumentAnnotations({
      annotations: updatedDocumentAnnotations.map(mapAnnotationDataToAnnotationSaveDto),
      revisionId: revision.id,
      workflowNodeId: workflowNodeId,
    });

    setSavingAnnotations(false);
    if (err) {
      processApiError(err, (error) => {
        messageError(error, intl);
      });
    } else {
      onClose && onClose();
      message.success(intl.formatMessage({ id: `DocumentAnnotationModal.message.success` }));
      setUpdatedDocumentAnnotations([]);
      setUpdatedPdfBlob(undefined);
    }
  });

  const canSaveAnnotations =
    (canAddRevision || canEditOwnAnnotations || canEditOthersAnnotations || canReplyToAnnotations) && !isReadOnlyMode;

  const disableWindowConfig = useMemo(
    (): DisableWindowStoreStartPayload => ({
      message: intl.formatMessage({ id: 'DocumentAnnotationModal.notSavedAlert' }),
      showMask: false,
    }),
    [intl]
  );

  useWindowDisabler(
    !!updatedDocumentAnnotations.length && visible && !annotationModalDisablerDeactivated,
    disableWindowConfig
  );

  const createUploadData = (documentId: Guid): CreateDocumentUploadData<RevisionDto | WorkflowDto> => {
    if (workflowId && workflowNodeId) {
      return createRevisionWorkflowData(workflowId, workflowNodeId, documentId);
    }
    return createRevisionUploadData(documentId);
  };

  const saveButton = useMemo(() => {
    if (!!annotationsError) return <Alert type="error" message={annotationsError} />;
    if (!isUpToDateDocument)
      return (
        <Alert type="error" message={intl.formatMessage({ id: 'DocumentAnnotationModal.error.outOfDateDocument' })} />
      );

    return (
      <>
        {canSaveAnnotations && (
          <Button
            type="primary"
            onClick={handleAnnotationUpdate}
            disabled={!updatedDocumentAnnotations.length}
            loading={savingAnnotations}
          >
            <Fmt id="DocumentAnnotationModal.buttons.updateAnnotations" />
          </Button>
        )}
        {canAddRevision && (
          <Button type="primary" onClick={showRevisionModal} disabled={!updatedPdfBlob || savingAnnotations}>
            <Fmt id="DocumentOnlineEditor.button.saveAsRevision" />
          </Button>
        )}
        {additionalButtons}
      </>
    );
  }, [
    updatedPdfBlob,
    savingAnnotations,
    canAddRevision,
    additionalButtons,
    annotationsError,
    canSaveAnnotations,
    updatedDocumentAnnotations,
    isUpToDateDocument,
    intl,
  ]);

  const handleRevisionModal = useSameCallback(() => {
    hideRevisionModal();
    onClose();
    setUpdatedDocumentAnnotations([]);
    setUpdatedPdfBlob(undefined);
  });

  const handleAnnotationClose = useSameCallback(() => {
    const close = () => {
      setUpdatedDocumentAnnotations([]);
      setUpdatedPdfBlob(undefined);
      setIsConfirmModalVisible(false);
      setAnnotationsError(undefined);
      onClose();
    };

    if (updatedPdfBlob && !isReadOnlyMode) {
      if (!isConfirmModalVisible) {
        modalFormConfirmClose(intl, close, () => setIsConfirmModalVisible(false));
      }
      setIsConfirmModalVisible(true);
    } else {
      close();
    }
  });

  const initialPdfAnnotations = useMemo(
    (): DocumentAnnotationDto[] =>
      !updatedDocumentAnnotations.length
        ? documentAnnotations?.annotations
        : updatedDocumentAnnotations.map((updatedAnnotation) => ({
            id: updatedAnnotation.contentId,
            createdBy: revision.createdBy,
            createdDate: revision.createdDate,
            documentId: documentId,
            revisionId: revision.id,
            file: null,
            annotationData: updatedAnnotation.storeData,
            content: updatedAnnotation.content,
            position: updatedAnnotation.position,
          })),
    [updatedDocumentAnnotations, documentAnnotations?.annotations, revision, documentId]
  );

  const annotationModalContent = (
    <ContentGate
      error={!urlLoading && !documentAnnotationsLoading && licenseKey && (error || documentAnnotationsError)}
      loading={!revision || !documentUrl?.url || urlLoading || documentAnnotationsLoading || !visible}
    >
      <PdfAnnotation
        licenseKey={licenseKey}
        documentUrl={documentUrl?.url}
        initialPdfAnnotations={initialPdfAnnotations}
        onDocumentChanged={setUpdatedPdfBlob}
        onAnnotationsChanged={handleDocumentAnnotationsChange}
        onNewAnnotationsChanged={onNewAnnotationsChanged}
        annotationMode={annotationMode}
        canEditOthersAnnotations={canEditOthersAnnotations}
        canEditOwnAnnotations={canEditOwnAnnotations}
        canReplyToAnnotations={canReplyToAnnotations}
        highlightedAnnotationId={highlightedAnnotationId}
      />
    </ContentGate>
  );

  return (
    <Modal
      visible={visible}
      onCancel={handleAnnotationClose}
      title={documentName ? documentName : <Fmt id="DocumentOnlineEditor.defaultModalTitle" />}
      additionalButtons={saveButton}
    >
      {!isReadOnlyMode ? (
        <DocumentReservationLock
          documentId={documentId}
          revisionId={revision?.id}
          isLockRequested={visible}
          lockPurpose={DocumentLockPurposeEnum.annotation}
          onLockedDocumentChanged={() => setUpToDateDocument(false)}
          onLockSuccess={loadUrl}
        >
          {annotationModalContent}
        </DocumentReservationLock>
      ) : (
        annotationModalContent
      )}
      <RevisionCreateFormModal
        initialPrimaryFile={newRevisionFile}
        annotations={updatedDocumentAnnotations}
        open={isRevisionModalVisible}
        onSubmit={handleRevisionModal}
        onClose={hideRevisionModal}
        currentRevisionFileName={revision?.primaryFile?.name}
        createUploadData={createUploadData(documentId)}
      />
    </Modal>
  );
};

export const PdfAnnotationModal = React.memo(PdfAnnotationModalComponent);
