import { ApiPromise } from 'api/await-to';
import {
  CurrentRevisionDto,
  DownloadUrl,
  PrimaryFileDto,
  ProjectUserProfileListDto,
  RevisionDto,
  RoleDto,
  SortOrder,
} from 'api/completeApiInterfaces';
import DocumentsGrid, {
  DOCUMENT_GRID_GET_DOCUMENT_URL,
  DocumentsGridItemProps,
} from 'components/DocumentsGrid/DocumentsGrid';
import DocumentsGridHeader from 'components/DocumentsGridHeader/DocumentsGridHeader';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import FileToolbar from 'components/FileToolbar/FileToolbar';
import CommonDerivativesFileViewer, {
  CommonDerivedFileViewerItem,
} from 'components/FileViewer/CommonDerivativesFileViewer';
import { createFrontendDateFilter, DateFrontendFilterType } from 'components/filters/components/DateFilter/DateFilter';
import { createFrontendExtensionFilter } from 'components/filters/components/ExtensionFilter/ExtensionFilter';
import { createFrontendLabelsFilter } from 'components/filters/components/SelectFilter/variants/LabelsFilter/LabelsFilter';
import { createFrontendStateFilter } from 'components/filters/components/SelectFilter/variants/StateFilter/StateFilter';
import {
  createFrontendUsersFilter,
  USERS_FILTER_TYPE_CREATED_BY,
  USERS_FILTER_TYPE_OWNER,
  USERS_FILTER_TYPE_REVISION,
} from 'components/filters/components/SelectFilter/variants/UsersFilter/UsersFilter';
import { createFrontendMultiTextFilter } from 'components/filters/components/TextFilter/TextFilter';
import { FiltersPersistentKey, FrontendFilter } from 'components/filters/filterTypes';
import { FrontendOrderOption } from 'components/filters/orderTypes';
import { FilterPreset } from 'components/filters/render/FilterPreset/FilterPreset';
import { FilterToolbar } from 'components/filters/render/FilterToolbar/FilterToolbar';
import { OrderSelect } from 'components/filters/render/OrderSelect/OrderSelect';
import { DocumentsDownloadFormModal } from 'components/forms/DocumentsDownloadForm';
import PrimaryFileViewerTitle from 'components/PrimaryFileViewerTitle/PrimaryFileViewerTitle';
import StackPanel from 'components/StackPanel';
import Toolbar from 'components/Toolbar/Toolbar';
import { DEBUG } from 'config/env';
import { useSameCallback, useVisibleState } from 'hooks';
import { useFrontendFilters } from 'hooks/useFilters';
import { useMultiSelectInner } from 'hooks/useMultiSelect';
import { Fmt, InjectedIntlProps, memoizeWithIntl } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { CSSProperties, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { List } from 'react-virtualized';
import { ignoreRef } from 'utils';
import { dateComparer, textComparer } from 'utils/comparators';

const MIN_NUMBER_OF_ITEMS = 3;
const ROW_HEIGHT = 54;
const SPACE_FOR_SCROLLBAR = 36;
const EMPTY_SET = new Set<Guid>();

export const documentToGridItem = (document: CommonDocument): DocumentsGridItemProps => ({
  ...document,
  name: document.name,
  revisionNo: document.revision?.number,
  revisionDate: document.revision?.createdDate,
  revisionUser: document.revision?.createdBy,
  state: document.revision?.revisionState,
  primaryFileContentType: document.primaryFile?.contentType,
  thumbnailUrl: document.primaryFile?.thumbnail,
  path: document.directoryPath,
  pathGuid: document.directoryId,
  nameLink: `/projects/{projectId}/documents/${document.id}`,
  pathLink: `/projects/{projectId}/directories/`,
});

export type CommonDocument = {
  id: Guid;
  name?: string;
  description?: string;
  ext?: string;
  createdDate: IsoDateTime;
  labels?: Guid[];
  createdBy: ProjectUserProfileListDto;
  revision: RevisionDto | CurrentRevisionDto;
  ownedBy?: RoleDto;
  primaryFile?: PrimaryFileDto;
  directoryPath?: string;
  directoryId?: Guid;
};

const DEFAULT_PREVIEW_TRANSFORM = (
  item: CommonDocument,
  getOriginalUrl: () => Promise<string>
): CommonDerivedFileViewerItem => ({
  id: item.id,
  title: (
    <PrimaryFileViewerTitle
      revisionNo={item.revision?.number}
      state={item.revision?.revisionState}
      fileName={item.name}
    />
  ),
  blobToken: item.primaryFile?.blobToken,
  getOriginalUrl,
});

const DATE_FILTER_TYPES: DateFrontendFilterType<CommonDocument>[] = [
  {
    key: 'documentCreated',
    label: <Fmt id="SearchSortTypeItem.documentCreated" />,
    valueSelector: (document) => document.createdDate,
  },
  {
    key: 'revisionCreated',
    label: <Fmt id="SearchSortTypeItem.revisionCreated" />,
    valueSelector: (document) => document.revision?.createdDate,
  },
];

export const DOCUMENT_FILTERS: FrontendFilter<CommonDocument>[] = [
  createFrontendMultiTextFilter('text', (document) => [document.name, document.description], {
    label: <Fmt id="general.nameAndDescription" />,
  }),
  createFrontendExtensionFilter('extension', (document) => document.ext),
  createFrontendDateFilter('date', DATE_FILTER_TYPES),
  createFrontendStateFilter('state', (document) => document.revision?.revisionState),
  createFrontendLabelsFilter('labels', (document) => document.labels),
  createFrontendUsersFilter<CommonDocument>('users', [
    USERS_FILTER_TYPE_CREATED_BY,
    USERS_FILTER_TYPE_REVISION,
    USERS_FILTER_TYPE_OWNER,
  ]),
];

export const DOCUMENT_ORDER_OPTIONS: FrontendOrderOption<CommonDocument>[] = [
  {
    key: 'name',
    label: <Fmt id="SearchSortTypeItem.name" />,
    compare: textComparer.map((document) => document.name),
    defaultOrder: SortOrder.asc,
  },
  {
    key: 'revisionCreated',
    label: <Fmt id="SearchSortTypeItem.revisionCreated" />,
    compare: textComparer.map((document) => document.revision?.createdDate),
  },
  {
    key: 'documentCreated',
    label: <Fmt id="SearchSortTypeItem.documentCreated" />,
    compare: dateComparer.map((document) => document.createdDate),
  },
  {
    key: 'state',
    label: <Fmt id="SearchSortTypeItem.state" />,
    compare: textComparer.map((document) => document.revision?.revisionState),
  },
  {
    key: 'extension',
    label: <Fmt id="SearchSortTypeItem.extension" />,
    compare: textComparer.map((document) => document.ext),
  },
];

export type DocumentCompleteListProps<T extends CommonDocument> = {
  documents: T[];
  transform?: (document: T) => DocumentsGridItemProps;
  value?: Set<Guid>;
  onChange?: (documents: Set<Guid>) => void;
  filters?: FrontendFilter<T>[];
  orderOptions?: FrontendOrderOption<T>[];
  filtersKey?: FiltersPersistentKey;
  disableReserve?: boolean;
  disableDownload?: boolean; // TODO: remove this flag, and make "FileList" instead for comment procedure attachments
  reloadDocuments?: () => void;
  hideToolbar?: boolean;
  hideSelect?: boolean;
  hideFilters?: boolean;
  flexGrow?: boolean;
  revisionDateLabel?: IntlMessageId;
  additionalDocumentButtons?: (document: T) => ReactNode;
  getDocumentUrl?: (id: Guid) => ApiPromise<DownloadUrl>;
  selectItemOnClickEnabled?: boolean;
  onDownloadWithAnnotations?: (id: Guid) => void;
};

const DocumentCompleteListComponent = <T extends CommonDocument>({
  intl,
  documents,
  transform = documentToGridItem,
  value,
  onChange,
  filters = DOCUMENT_FILTERS,
  orderOptions = DOCUMENT_ORDER_OPTIONS,
  filtersKey,
  disableReserve,
  disableDownload,
  reloadDocuments,
  hideToolbar,
  hideSelect,
  hideFilters,
  flexGrow,
  revisionDateLabel,
  additionalDocumentButtons,
  getDocumentUrl = DOCUMENT_GRID_GET_DOCUMENT_URL,
  selectItemOnClickEnabled,
  onDownloadWithAnnotations,
}: InjectedIntlProps & DocumentCompleteListProps<T>) => {
  // filter and order the documents
  const { orderedItems, ...filterProps } = useFrontendFilters(filters, orderOptions, documents, filtersKey);

  useEffect(() => {
    if (!onChange && (!hideToolbar || !hideSelect)) {
      DEBUG && console?.error?.('Warning: toolbar and select is set to visible, but there is no onChange action set');
    }
  }, [hideToolbar, hideSelect, onChange]);

  const handleChange = useCallback(
    (documents: Set<Guid>) => {
      onChange
        ? onChange(documents)
        : DEBUG && console?.error?.('No onChange method set -> selecting items will not work');
    },
    [onChange]
  );

  // selection handling
  const listRef = useRef<List>();
  const [deselectAll, isAllSelected, selectAll, onSelectItem, isAnyItemSelected] = useMultiSelectInner(
    listRef,
    orderedItems,
    value,
    handleChange
  );

  const selectedItemsIds = value || EMPTY_SET;

  // mass download
  const [downloadDocuments, downloadVisible, setDownloadDocuments, hideDownload] = useVisibleState<Guid[]>();

  const onDocumentsDownload = useSameCallback(() => setDownloadDocuments(Array.from(selectedItemsIds)));

  const handleDownloadDocumentFormSubmit = useCallback(async (data: DownloadUrl) => {
    window.open(data.url, '_blank');
    hideDownload();
  }, []);

  const downloadFileName = useMemo<string>(() => {
    if (selectedItemsIds.size === 1) {
      const id = selectedItemsIds.values().next().value;
      const document = documents?.find((doc) => doc.id === id);
      if (document) {
        return `${document.name}.zip`;
      }
    }
    return `${intl.formatMessage({ id: 'DocumentCompleteList.downloadedZipName' })}.zip`;
  }, [documents, selectedItemsIds, intl]);

  // preview
  const [previewId, previewVisible, setPreviewId, hidePreview] = useVisibleState<Guid>();

  const getOriginalUrl = useSameCallback(async () => {
    if (getDocumentUrl) {
      const [err, resp] = await getDocumentUrl(previewId);
      if (!err) {
        return resp.data.url;
      }
    }
    return undefined;
  });

  // TODO: refactor this mess and fix it with proper styles
  const minHeightStyle = useMemo<CSSProperties>(
    () => ({
      minHeight:
        !!orderedItems?.length &&
        Math.min(orderedItems.length, MIN_NUMBER_OF_ITEMS) * ROW_HEIGHT + SPACE_FOR_SCROLLBAR + 'px',
    }),
    [orderedItems]
  );

  const transformPreviewItems = useCallback((item) => DEFAULT_PREVIEW_TRANSFORM(item, getOriginalUrl), []);

  return (
    <ErrorBoundary>
      <StackPanel vertical stretch={flexGrow}>
        {!hideToolbar && !!onChange && (
          <Toolbar>
            <FileToolbar
              selectedFilesIds={selectedItemsIds}
              downloadDisabled={!isAnyItemSelected}
              onDocumentsDownload={onDocumentsDownload}
              favoriteDisabled
              shareDownloadDisabled
            />
          </Toolbar>
        )}
        {!hideFilters && (
          <DocumentsGridHeader
            allFilesSelected={isAllSelected}
            isAnyItemSelected={isAnyItemSelected}
            disableSelect={hideSelect || !onChange}
            selectAllDocuments={selectAll}
            order={<OrderSelect {...filterProps} />}
            filters={<FilterToolbar {...filterProps} />}
            additionalContent={<FilterPreset filterKey={filtersKey} {...filterProps} />}
          />
        )}
        {orderedItems && (
          <StackPanel vertical stretch={flexGrow} style={minHeightStyle}>
            <DocumentsGrid
              disableSelect={hideSelect || !onChange}
              documents={orderedItems}
              disableReserve={disableReserve}
              disableDownload={disableDownload}
              selectedFilesIds={selectedItemsIds}
              listRef={listRef}
              selectFile={onSelectItem}
              transform={transform}
              additionalButtons={additionalDocumentButtons}
              getDocumentUrl={getDocumentUrl}
              onThumbnailClick={setPreviewId}
              revisionDateLabel={revisionDateLabel}
              selectItemOnClickEnabled={selectItemOnClickEnabled}
              reloadDocuments={reloadDocuments}
              hasFilteredOutItems={filterProps.hasFilteredOutItems}
              clearFilters={filterProps.clearFilters}
              onDownloadWithAnnotations={onDownloadWithAnnotations}
            />
          </StackPanel>
        )}
      </StackPanel>
      <CommonDerivativesFileViewer
        items={orderedItems}
        previewId={previewId}
        setPreviewDocumentId={setPreviewId}
        visible={previewVisible}
        onCancel={hidePreview}
        transform={transformPreviewItems}
      />
      <DocumentsDownloadFormModal
        selectedDocumentIds={downloadDocuments}
        firstDocumentName={downloadFileName}
        open={downloadVisible}
        onSubmit={handleDownloadDocumentFormSubmit}
        onClose={hideDownload}
        includingDirectories
      />
    </ErrorBoundary>
  );
};

export const DocumentCompleteList: <T extends CommonDocument>(
  props: DocumentCompleteListProps<T>
) => JSX.Element = memoizeWithIntl(DocumentCompleteListComponent);

export const DocumentCompleteListFormItem: <T extends CommonDocument>(
  props: Omit<DocumentCompleteListProps<T>, 'value' | 'onChange'>
) => JSX.Element = ignoreRef(DocumentCompleteList);
