import { Form, Input } from 'antd';
import { apiConstraints } from 'api/completeApiConstraints';
import { JToken, OrgProjectCardDataDto } from 'api/completeApiInterfaces';
import { useIntl } from 'hooks';
import { Dictionary } from 'lodash';
import React, { FC, useEffect } from 'react';
import { maxLengthRule, requiredRule } from 'utils/formHelpers';
import { renderProjectCardDefaultDataVariableFormItem } from './ProjectCardDataAddForm';

export type ProjectCardDataEditFormData = {
  id: Guid;
  name: string;
  defaultData: Dictionary<JToken>;
  unit?: string;
};

type Props = {
  initialCardData: OrgProjectCardDataDto;
};

const ProjectCardDataEditForm: FC<Props> = ({ initialCardData }) => {
  const intl = useIntl();
  const form = Form.useFormInstance();

  useEffect(() => {
    if (initialCardData) {
      form.setFieldsValue({
        name: initialCardData.name,
        defaultData: initialCardData.defaultData,
      });
    } else {
      form.resetFields();
    }
  }, [initialCardData]);

  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: 'general.name' })}
        name="name"
        rules={[
          requiredRule('OrgProjectCardData.form.rules.name.required'),
          maxLengthRule(
            'OrgProjectCardData.form.rules.name.maxLength',
            apiConstraints.orgProjectCardDataDto.name.maxLength
          ),
        ]}
        initialValue={initialCardData?.name}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="unit"
        initialValue={initialCardData?.unit}
        label={intl.formatMessage({ id: 'OrgProjectCardData.units' })}
      >
        <Input style={{ width: '100px' }} maxLength={apiConstraints.orgProjectCardDataDto.unit.maxLength} />
      </Form.Item>
      <Form.Item
        name="defaultData"
        hidden
        label={intl.formatMessage({ id: 'OrgProjectCardData.form.rules.defaultValue' })}
        initialValue={initialCardData?.defaultData}
      >
        {initialCardData?.variable && renderProjectCardDefaultDataVariableFormItem(initialCardData.variable)}
      </Form.Item>
      <Form.Item name="id" hidden initialValue={initialCardData?.id} noStyle />
    </>
  );
};

ProjectCardDataEditForm.displayName = 'ProjectCardDataAddForm';

export default ProjectCardDataEditForm;
