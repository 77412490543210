import { to } from 'api/await-to';
import * as Dtos from 'api/completeApiInterfaces';
import {
  BlobDerivateTypeEnum,
  DeepShareDownloadDto,
  DirectoryContentDto,
  DiscardedDocumentDto,
  DMSMetadataRequestDto,
  DocToDocLinkCreateResultsDto,
  DocToDocLinksListDto,
  DocumentBulkActionSetResultDto,
  DocumentBulkLabelsSetDto,
  DocumentBulkStateCheckDto,
  DocumentBulkStateCheckResultDto,
  DocumentBulkStateSetDto,
  DocumentContentDto,
  DocumentCreateDto,
  DocumentMoveDto,
  DocumentMoveResultDto,
  DocumentMultipleDownloadDto,
  DocumentPatchDto,
  DocumentReservationDto,
  DocumentReservationLockRequestDto,
  DocumentReservationLockResponseDto,
  DocumentRevisionDiffDownloadDto,
  DocumentSetApprovalDateDto,
  DocumentsRestoreDto,
  DocumentsRestoreResultDto,
  DocumentSubscriberEnumsWatchDocumentEnum,
  DownloadUrl,
  EsticonDocumentDto,
  FavoriteDocumentsResultsDto,
  ModelDocToDocLinkCreateDto,
  ModelDocToDocLinkCreateResultsDto,
  ModelDocumentsAddToModelDto,
  RevisionCreateDto,
  RevisionDto,
  SecondaryDocumentsAddDto,
  ShareDownloadDto,
  ShareDownloadResultDto,
  WorkflowStateEnum,
} from 'api/completeApiInterfaces';
import { CancelToken } from 'axios';
import { baseProjectApi } from '../baseProjectApi';
export const documentsApi = {
  createDocument: (data: DocumentCreateDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<DocumentContentDto>(`/documents`, data, { cancelToken })
    ),
  getDocumentById: (documentId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<DocumentContentDto>(`/documents/${documentId}`, { cancelToken })
    ),
  updateDocumentById: (documentId: Guid, data: DocumentPatchDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<DocumentContentDto>(`/documents/${documentId}`, data, { cancelToken })
    ),
  moveDocument: (documentId: Guid, destDirectoryId: Guid, cancelToken?: CancelToken) =>
    to(baseProjectApi.patch(`/documents/${documentId}/move/${destDirectoryId}`, { cancelToken })),
  getDocumentDownloadUrlById: (
    documentId: Guid,
    expires?: number,
    inline: boolean = false,
    cancelToken?: CancelToken
  ) =>
    to(
      baseProjectApi.get<DownloadUrl>(`/documents/${documentId}/download`, {
        params: { expires, inline },
        cancelToken,
      })
    ),
  addDocumentlabel: (documentId: Guid, labelId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<void>(`/documents/${documentId}/labels/${labelId}`, { cancelToken })
    ),
  removeDocumentlabel: (documentId: Guid, labelId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.delete<void>(`/documents/${documentId}/labels/${labelId}`, { cancelToken })
    ),
  listDocumentRevisions: (documentId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<RevisionDto[]>(`/documents/${documentId}/revisions`, { cancelToken })
    ),
  createDocumentRevision: (documentId: Guid, data: RevisionCreateDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<RevisionDto>(`/documents/${documentId}/revisions`, data, { cancelToken })
    ),
  getDocumentRevisionById: (documentId: Guid, revisionId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<RevisionDto>(`/documents/${documentId}/revisions/${revisionId}`, { cancelToken })
    ),
  getDocumentRevisionDownloadUrlById: (
    documentId: Guid,
    revisionId: Guid,
    expires?: number,
    inline: boolean = false,
    cancelToken?: CancelToken
  ) =>
    to(
      baseProjectApi.get<DownloadUrl>(`/documents/${documentId}/revisions/${revisionId}/download`, {
        params: { expires, inline },
        cancelToken,
      })
    ),
  getDocumentRevisionDownloadUrlByIdWithAttachments: (documentId: Guid, revisionId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<DownloadUrl>(`/documents/${documentId}/revisions/${revisionId}/downloadall`, {
        cancelToken,
      })
    ),
  getDocumentRevisionSecondaryFileDownloadUrlById: (
    documentId: Guid,
    revisionId: Guid,
    secondaryFileId: Guid,
    expires?: number,
    inline: boolean = false,
    cancelToken?: CancelToken
  ) =>
    to(
      baseProjectApi.get<DownloadUrl>(`/documents/${documentId}/revisions/${revisionId}/download/${secondaryFileId}`, {
        params: { expires, inline },
        cancelToken,
      })
    ),
  getDocumentDerivateDownloadUrlById: (
    documentId: Guid,
    type: BlobDerivateTypeEnum,
    expires?: number,
    cancelToken?: CancelToken
  ) =>
    to(
      baseProjectApi.get<DownloadUrl>(`/documents/${documentId}/download/derivates`, {
        params: { type, expires },
        cancelToken,
      })
    ),
  getDocumentRevisionDerivateDownloadUrlById: (
    documentId: Guid,
    revisionId: Guid,
    type: BlobDerivateTypeEnum,
    expires?: number,
    cancelToken?: CancelToken
  ) =>
    to(
      baseProjectApi.get<DownloadUrl>(`/documents/${documentId}/revisions/${revisionId}/download/derivates`, {
        params: { type, expires },
        cancelToken,
      })
    ),
  getDocumentRevisionsDerivateDownloadUrlForComparisonByIds: (
    documentId: Guid,
    firstRevisionId: Guid,
    secondRevisionId: Guid,
    type: BlobDerivateTypeEnum,
    expires?: number,
    cancelToken?: CancelToken
  ) =>
    to(
      baseProjectApi.get<DocumentRevisionDiffDownloadDto>(
        `/documents/${documentId}/revisions/diff/${firstRevisionId}/${secondRevisionId}/download/derivates`,
        {
          params: { type, expires },
          cancelToken,
        }
      )
    ),
  getDocumentRevisionSecondaryFileDerivateDownloadUrlById: (
    documentId: Guid,
    revisionId: Guid,
    secondaryFileId: Guid,
    type: BlobDerivateTypeEnum,
    expires?: number,
    cancelToken?: CancelToken
  ) =>
    to(
      baseProjectApi.get<DownloadUrl>(
        `/documents/${documentId}/revisions/${revisionId}/download/${secondaryFileId}/derivates`,
        { params: { type, expires }, cancelToken }
      )
    ),
  addSecondaryDocumentsRevision: (
    documentId: Guid,
    revisionId: Guid,
    data: SecondaryDocumentsAddDto,
    cancelToken?: CancelToken
  ) =>
    to(
      baseProjectApi.post<RevisionDto>(`/documents/${documentId}/revisions/${revisionId}/addsecondary`, data, {
        cancelToken,
      })
    ),
  discardRevision: (documentId: Guid, revisionId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.delete<void>(`/documents/${documentId}/revisions/${revisionId}`, {
        cancelToken,
      })
    ),
  restoreRevision: (documentId: Guid, revisionId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<RevisionDto>(
        `/documents/${documentId}/revisions/${revisionId}/restore`,
        {},
        {
          cancelToken,
        }
      )
    ),
  getFavoriteDocumentsList: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<DirectoryContentDto[]>(`/documents/favorite`, { cancelToken })
    ),
  addFavoriteDocuments: (documentsIds: Guid[], documentLinksIds?: Guid[], cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<FavoriteDocumentsResultsDto>(
        `/documents/favorite`,
        { documentsIds, documentLinksIds },
        { cancelToken }
      )
    ),
  removeFavoriteDocuments: (documentsIds: Guid[], documentLinksIds?: Guid[], cancelToken?: CancelToken) =>
    to(baseProjectApi.post(`/documents/favorite/deletelist`, { cancelToken, documentsIds, documentLinksIds })),
  reserveDocument: (documentId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<DocumentReservationDto>(`/documents/${documentId}/reserve`, { cancelToken })
    ),
  releaseDocumentReservation: (documentId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.delete<void>(`/documents/${documentId}/reserve`, { cancelToken })
    ),
  moveDocuments: (documentMoveDto: DocumentMoveDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<DocumentMoveResultDto>(`/documents/move`, documentMoveDto, { cancelToken })
    ),
  downloadDocuments: (data: DocumentMultipleDownloadDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<DownloadUrl>(`/documents/download`, data, { cancelToken })
    ),
  setDocumentWatch: (documentId: Guid, type: DocumentSubscriberEnumsWatchDocumentEnum, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.put<void>(`/documents/${documentId}/watch?type=${type}`, null, { cancelToken })
    ),
  getDiscardedDocumentsList: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<DiscardedDocumentDto[]>(`/documents/discardeddocs`, { cancelToken })
    ),
  restoreDocuments: (documentsRestoreDto: DocumentsRestoreDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<DocumentsRestoreResultDto>(`/documents/restore`, documentsRestoreDto, { cancelToken })
    ),
  getModelDocumentsList: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<DirectoryContentDto[]>(`/document/model`, { cancelToken })
    ),
  addModelDocuments: (documentId: Guid, cancelToken?: CancelToken) =>
    to(baseProjectApi.patch(`/document/model/${documentId}`, null, { cancelToken })),

  addModelDocumentsBulkVersion: (data: ModelDocumentsAddToModelDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<void>(`/document/model`, data, { cancelToken })
    ),
  removeModelDocuments: (
    documentId: Guid,
    strategy?: Dtos.DocumentLinkRemoveStrategyEnum,
    cancelToken?: CancelToken
  ) => {
    const strategyQuery = !!strategy ? `?strategy=${strategy}` : '';
    return to(
      baseProjectApi.delete<void>(`/document/model/${documentId}${strategyQuery}`, { cancelToken })
    );
  },
  removeModelDocumentsBulkVersion: (
    modelDocumentsRemoveFromModelDto: Dtos.ModelDocumentsRemoveFromModelDto,
    strategy?: Dtos.DocumentLinkRemoveStrategyEnum,
    cancelToken?: CancelToken
  ) => {
    const strategyQuery = !!strategy ? `?strategy=${strategy}` : '';
    return to(
      baseProjectApi.delete<void>(`/document/model/${strategyQuery}`, {
        cancelToken,
        data: modelDocumentsRemoveFromModelDto,
      })
    );
  },

  shareDownload: (data: ShareDownloadDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<ShareDownloadResultDto>(`/documents/share`, data, { cancelToken })
    ),
  deepShareDownload: (data: DeepShareDownloadDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<ShareDownloadResultDto>(`/documents/deepshare`, data, { cancelToken })
    ),
  getDocToDocLinksList: (documentId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<DocToDocLinksListDto>(`/documents/${documentId}/doctodoclink`, { cancelToken })
    ),
  createDocToDocLink: (linkedDocumentId: Guid, linkingDocumentIds: Guid[], cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<DocToDocLinkCreateResultsDto>(
        `/documents/doctodoclink`,
        { linkingDocumentIds, linkedDocumentId },
        { cancelToken }
      )
    ),
  createModelBinding: (data: ModelDocToDocLinkCreateDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<ModelDocToDocLinkCreateResultsDto>(`/document/model/doctodoclink`, data, { cancelToken })
    ),
  setState: (documentId: Guid, state: WorkflowStateEnum, cancelToken?: CancelToken) =>
    to(baseProjectApi.post<DocumentContentDto>(`/documents/${documentId}/setState`, { state }, { cancelToken })),
  setOwner: (documentId: Guid, ownedById: Guid, cancelToken?: CancelToken) =>
    to(baseProjectApi.post<DocumentContentDto>(`/documents/${documentId}/setOwner`, { ownedById }, { cancelToken })),
  getEsticonDocuments: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<EsticonDocumentDto[]>(`/documents/esticon`, { cancelToken })
    ),
  reservationLock: (data: DocumentReservationLockRequestDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<DocumentReservationLockResponseDto>(`/documents/reservationlock`, data, { cancelToken })
    ),
  reservationUnlock: (lockId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.delete<void>(`/documents/reservationlock/${lockId}`, { cancelToken })
    ),
  returnForRework: (esticonDocumentId: Guid, unsetApprovalDate: boolean, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<void>(`/documents/returnforrework`, { esticonDocumentId, unsetApprovalDate }, { cancelToken })
    ),
  setApprovalDate: (data: DocumentSetApprovalDateDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<void>(`/documents/setapprovaldate`, data, { cancelToken })
    ),
  bulkEditDocumentsStatesCheck: (data: DocumentBulkStateCheckDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<DocumentBulkStateCheckResultDto>(`/documents/bulk/statecheck`, data, {
        cancelToken,
      })
    ),
  bulkEditDocumentsStatesPatch: (data: DocumentBulkStateSetDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<DocumentBulkActionSetResultDto>(`/documents/bulk/state`, data, { cancelToken })
    ),
  bulkEditDocumentsLabelsPatch: (data: DocumentBulkLabelsSetDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<DocumentBulkActionSetResultDto>(`/documents/bulk/labels`, data, { cancelToken })
    ),
  bulkEditDocumentsWatchPatch: (data: Dtos.DocumentBulkSubscribersSetDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<DocumentBulkActionSetResultDto>(`/documents/bulk/watch`, data, { cancelToken })
    ),
  getDMSMetadata: (lang: DMSMetadataRequestDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<string>(`/documents/dms/metadata`, lang, { cancelToken, responseType: 'blob' })
    ),

  link: {
    /** Create document to document link */
    post: (data: Dtos.DocToDocLinkCreateDto, cancelToken?: CancelToken) =>
      to(
        baseProjectApi.post<Dtos.DocToDocLinkCreateResultsDto>(`/documents/doctodoclink`, data, { cancelToken })
      ),
    deletelist: {
      /** Delete link */
      post: (data: Dtos.DocToDocLinksDto, cancelToken?: CancelToken) =>
        to(
          baseProjectApi.post<Dtos.DocToDocLinkDeleteResultsDto>(`/documents/doctodoclink/deletelist`, data, {
            cancelToken,
          })
        ),
    },
  },
};
