import { Form, Input, Select, Tag } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import {
  MultiUploadCheckResponseDto,
  ProjectUserProfileStatusEnum,
  RoleDto,
  WorkflowStateEnum,
} from 'api/completeApiInterfaces';
import { PreferredDirectory } from 'components/DirectoriesTreeSelect/DirectoriesPreferredSelect';
import { DirectoriesTreeSelectFormItem } from 'components/DirectoriesTreeSelect/DirectoriesTreeSelect';
import { DocumentCategoryFormFc } from 'components/forms/DocumentCategoryForm/DocumentCategoryFormFc';
import { useRequiredCategories } from 'components/forms/DocumentCreateForm/DocumentCreateForm';
import { useFolderValidationMasksContext } from 'components/forms/DocumentCreateForm/FolderValidationMasksContext';
import { LabelsInput } from 'components/LabelsInput/LabelsInput';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { MaskItemSimpleDisplay } from 'components/MaskInput/MaskItemSimpleDisplay';
import { CommonFilesInput } from 'components/PrimaryFileInput/CommonFilesInput';
import { FilesStructure, FileSystemTreeNode } from 'components/PrimaryFileInput/CommonFilesInputTypes';
import SingleFileInput from 'components/PrimaryFileInput/SingleFileInput';
import RevisionNumberTag from 'components/RevisionNumberTag';
import { RoleSelect } from 'components/RoleSelect/RoleSelect';
import { Section } from 'components/Section/Section';
import { SettingsBox } from 'components/SettingsBox/SettingsBox';
import { SwitchLabeled } from 'components/SwitchLabeled/SwitchLabeled';
import { DocumentUploadStateData } from 'components/UploadState';
import { MAX_ITEM_DESCRIPTION_LENGTH, SIGNED_DOCUMENT_ACCEPTED_CONTENT_TYPES } from 'config/constants';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import { Dictionary } from 'lodash';
import { canWriteInDirectory } from 'pages/AllDocumentsPage/AllDocumentsPage';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';
import React, { FC, useEffect } from 'react';
import { maxLengthRule, requiredRule } from 'utils/formHelpers';
import styles from './DocumentCreateForm.module.less';

const { Option } = Select;

export type DocumentCreateMultipleFormData = {
  destinationDirectory: Guid;
  description: string;
  labels: Guid[];
  files: FilesStructure;
  secondaryFiles: FilesStructure;
  signedDocument: File;
  categories: { nodeId: Guid; categoryId: Guid }[];
  state: WorkflowStateEnum;
  ownedById: Guid;
  revisionState: WorkflowStateEnum;
  revisionComment: string;
  isModel: boolean;
};

const isHiddenRole = (role: RoleDto) => {
  if (!role?.user && !role?.roleAssociates?.length) return true;
  return (
    (role?.user?.status === ProjectUserProfileStatusEnum.suspended ||
      role?.user?.status === ProjectUserProfileStatusEnum.expired) &&
    !role?.roleAssociates?.some(
      (roleAssociate) =>
        roleAssociate.user.status === ProjectUserProfileStatusEnum.active ||
        roleAssociate.user.status === ProjectUserProfileStatusEnum.invited
    )
  );
};

type Props = {
  progressItemData: Dictionary<DocumentUploadStateData>;
  visible: boolean;
  startingDirectoryId?: Guid;
  onDirectoryChange?: (directoryId: Guid) => void;
  disableTargetFolderInput?: boolean;
  preferredDirectories?: PreferredDirectory[];
  checkData?: MultiUploadCheckResponseDto;
  onPrimaryFilesValidationChange?: (value: FilesStructure) => void;
  checkLoading?: boolean;
  droppedFiles?: FileSystemTreeNode[];
  defaultIsModel: boolean;
  usefulNewDocumentStatuses: WorkflowStateEnum[];
};

export const DocumentCreateMultipleForm: FC<Props> = ({
  visible,
  checkData,
  checkLoading,
  startingDirectoryId,
  onDirectoryChange,
  preferredDirectories,
  onPrimaryFilesValidationChange,
  droppedFiles,
  defaultIsModel,
  usefulNewDocumentStatuses,
}) => {
  const intl = useIntl();
  const form = useFormInstance();
  const files = useWatch('files');
  const secondaryFiles = useWatch('secondaryFiles');
  const signedDocument = useWatch('signedDocument');
  const destinationDirectoryId = useWatch('destinationDirectory');
  const hasSignedDocument = signedDocument != null;

  useEffect(() => {
    form.setFieldValue('destinationDirectory', startingDirectoryId);
  }, [startingDirectoryId]);

  const disableSecondaryFiles = files && files.files.length + files.revisions.length !== 1;
  const hasAnyFile = files && files.files.length + files.revisions.length > 0;
  const newDocumentsCount = files && files.files.length;
  const hasNewDocuments = !!newDocumentsCount;
  const revisionsCount = files && files.revisions.length;
  const hasRevisions = !!revisionsCount;
  const hasAttachments = secondaryFiles && secondaryFiles.files.length > 0;
  const canUploadMultipleFiles = !hasAttachments && !hasSignedDocument;

  destinationDirectoryId && onDirectoryChange && onDirectoryChange(destinationDirectoryId); // TODO: investigate why this is here
  const requiredCategories = useRequiredCategories(destinationDirectoryId);

  const {
    getValidationMaskForDirectory,
    validateNameByMask,
    setDestinationDirectoryId,
  } = useFolderValidationMasksContext();
  const documentMask = getValidationMaskForDirectory(destinationDirectoryId, 'files');
  const subdirectoriesMask = getValidationMaskForDirectory(destinationDirectoryId, 'folders');

  useEffect(() => {
    setDestinationDirectoryId(destinationDirectoryId);
  }, [destinationDirectoryId]);

  if (!visible) return null;

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.mainSection}>
          <Form.Item
            label={intl.formatMessage({ id: 'DocumentCreateMultipleForm.form.items.destinationDirectory.label' })}
            name="destinationDirectory"
            initialValue={startingDirectoryId}
            rules={[requiredRule('DocumentCreateForm.form.items.destinationDirectory.rules.required')]}
          >
            <DirectoriesTreeSelectFormItem
              isItemDisabled={(item) => !canWriteInDirectory(item)}
              showSearch
              defaultExpandDepth={3}
              preferredDirectories={preferredDirectories}
              placeholder={intl.formatMessage({
                id: 'DocumentCreateForm.form.items.destinationDirectory.placeholder',
              })}
            />
          </Form.Item>

          {!!documentMask?.length && (
            <Form.Item
              label={intl.formatMessage({ id: 'DocumentCreateMultipleForm.form.items.validationMaskFiles.label' })}
            >
              <FlowLayout wrap style={{ margin: '0 0.5rem 0.5rem' }}>
                {documentMask.map((item) => (
                  <Tag key={item.id}>
                    <MaskItemSimpleDisplay item={item} />
                  </Tag>
                ))}
              </FlowLayout>
            </Form.Item>
          )}

          {!!subdirectoriesMask?.length && (
            <Form.Item
              label={intl.formatMessage({ id: 'DocumentCreateMultipleForm.form.items.validationMaskFolders.label' })}
            >
              <FlowLayout wrap style={{ margin: '0 0.5rem 0.5rem' }}>
                {subdirectoriesMask.map((item) => (
                  <Tag key={item.id}>
                    <MaskItemSimpleDisplay item={item} />
                  </Tag>
                ))}
              </FlowLayout>
            </Form.Item>
          )}
          <Form.Item
            label={intl.formatMessage({ id: 'DocumentCreateMultipleForm.primary.label' })}
            name="files"
            rules={[
              requiredRule('DocumentCreateMultipleForm.file.rules.required'),
              {
                async validator(rule: RuleObject, value: StoreValue) {
                  if (
                    !!value &&
                    value.files.length === 0 &&
                    value.folders.length === 0 &&
                    value.revisions.length === 0
                  ) {
                    return Promise.reject(intl.formatMessage({ id: 'DocumentCreateMultipleForm.file.rules.required' }));
                  }
                  if (value?.hasInvalidNamesByMask) {
                    return Promise.reject(
                      intl.formatMessage({ id: 'DocumentCreateMultipleForm.file.rules.invalidNamesByMask' })
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <CommonFilesInput
              multiple={canUploadMultipleFiles}
              title={
                canUploadMultipleFiles ? (
                  <Fmt id="DocumentCreateMultipleForm.primary.text" />
                ) : (
                  <Fmt id="PrimaryFileInput.collapse.text" />
                )
              }
              checkData={checkData}
              checkLoading={checkLoading}
              onValidationChange={onPrimaryFilesValidationChange}
              droppedFiles={droppedFiles}
              nameValidator={validateNameByMask}
            />
          </Form.Item>

          {!disableSecondaryFiles && (
            <Form.Item
              label={intl.formatMessage({ id: 'DocumentCreateMultipleForm.signedDocument.label' })}
              name="signedDocument"
            >
              <SingleFileInput
                inputText="SignedDocumentInput.collapse.text"
                inputHint="SignedDocumentInput.collapse.hint"
                acceptedContentType={SIGNED_DOCUMENT_ACCEPTED_CONTENT_TYPES}
              />
            </Form.Item>
          )}
          <Form.Item
            label={intl.formatMessage({ id: 'DocumentCreateMultipleForm.secondary.label' })}
            name="secondaryFiles"
          >
            {disableSecondaryFiles ? (
              <Fmt id="DocumentCreateMultipleForm.secondary.cantAdd" />
            ) : (
              <CommonFilesInput
                multiple
                disableTreeStructure
                title={<Fmt id="DocumentCreateMultipleForm.secondary.text" />}
              />
            )}
          </Form.Item>
        </div>
        {hasAnyFile && (
          <div className={styles.sideSection}>
            {hasNewDocuments && (
              <div>
                <SettingsBox>
                  <Section
                    title={
                      <>
                        <Fmt id="DocumentCreateMultipleForm.form.items.documentOptions.title" />{' '}
                        <Tag>{newDocumentsCount}</Tag>
                      </>
                    }
                  >
                    <Form.Item
                      label={intl.formatMessage({ id: 'DocumentCreateMultipleForm.form.items.state.label' })}
                      name="state"
                      initialValue={usefulNewDocumentStatuses?.length === 1 ? usefulNewDocumentStatuses[0] : null}
                      rules={[requiredRule('DocumentCreateForm.form.items.state.rules.required')]}
                    >
                      <Select>
                        {usefulNewDocumentStatuses.map((state) => (
                          <Option key={state}>
                            <div className={styles.stateOption}>
                              <RevisionNumberTag state={state} showTitle />
                            </div>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name="isModel" valuePropName="checked" initialValue={defaultIsModel}>
                      <SwitchLabeled
                        label={intl.formatMessage({ id: 'DocumentCreateMultipleForm.form.isModelSwitch.text' })}
                      />
                    </Form.Item>
                    <Form.Item
                      label={intl.formatMessage({ id: 'DocumentCreateMultipleForm.file.description' })}
                      name="description"
                      rules={[
                        {
                          whitespace: true,
                          message: intl.formatMessage({ id: 'forms.items.name.rules.empty' }),
                        },
                        {
                          max: MAX_ITEM_DESCRIPTION_LENGTH,
                          message: intl.formatMessage(
                            { id: 'general.maxDescriptionLength' },
                            { max: MAX_ITEM_DESCRIPTION_LENGTH }
                          ),
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder={intl.formatMessage({
                          id: 'DocumentCreateMultipleForm.file.description.placeholder',
                        })}
                        rows={2}
                        autoSize={{ minRows: 2 }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={intl.formatMessage({ id: 'forms.items.labels.label' })}
                      name="labels"
                      initialValue={[]}
                    >
                      <LabelsInput />
                    </Form.Item>
                    <Form.Item label={<Fmt id="DocumentCreateMultipleForm.file.manager" />} name="ownedById">
                      <RoleSelect hiddenRoles={isHiddenRole} allowClear />
                    </Form.Item>
                    <DocumentCategoryFormFc requiredCategories={requiredCategories} autoSelectDefault={true} />
                  </Section>
                </SettingsBox>
              </div>
            )}
            {hasRevisions && (
              <div>
                <SettingsBox>
                  <Section
                    title={
                      <>
                        <Fmt id="DocumentCreateMultipleForm.form.items.revisionOptions.title" />{' '}
                        <Tag>{revisionsCount}</Tag>
                      </>
                    }
                  >
                    <Form.Item
                      label={intl.formatMessage({ id: 'DocumentCreateMultipleForm.form.items.state.label' })}
                      name="revisionState"
                      initialValue={usefulNewDocumentStatuses?.length === 1 ? usefulNewDocumentStatuses[0] : null}
                      rules={[requiredRule('DocumentCreateForm.form.items.state.rules.required')]}
                    >
                      <Select>
                        {usefulNewDocumentStatuses.map((state) => (
                          <Option key={state}>
                            <div className={styles.stateOption}>
                              <RevisionNumberTag state={state} showTitle />
                            </div>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label={intl.formatMessage({ id: 'DocumentCreateMultipleForm.file.revisionComment' })}
                      name="revisionComment"
                      rules={[
                        requiredRule('DocumentCreateMultipleForm.file.revisionComment.rules.required', true),
                        maxLengthRule('general.maxDescriptionLength', MAX_ITEM_DESCRIPTION_LENGTH),
                      ]}
                    >
                      <Input.TextArea
                        placeholder={intl.formatMessage({
                          id: 'DocumentCreateMultipleForm.file.description.placeholder',
                        })}
                        rows={2}
                        autoSize={{ minRows: 2 }}
                      />
                    </Form.Item>
                  </Section>
                </SettingsBox>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default DocumentCreateMultipleForm;
