import { ApiPromise } from 'api/await-to';
import { masterApi, projectApi } from 'api/completeApi';
import { AppUserDto, AppUserRequestDto, GroupListDto, ProjectUsersRequestDto } from 'api/completeApiInterfaces';
import { CancelToken } from 'axios';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent } from 'react';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import ProjectUserAddForm, { ProjectUserAddFormData } from './ProjectUserAddForm';

export type AddUserEndpointType = 'organization' | 'project';

const AddUserEndpoints: Record<
  AddUserEndpointType,
  (data: ProjectUsersRequestDto, cancelToken?: CancelToken) => ApiPromise<void>
> = {
  ['organization']: masterApi.projects.admin.addprojectusers.post,
  ['project']: projectApi.users.addprojectusers.post,
};

type Props = FormModalProps<AppUserRequestDto> & {
  toAddAppUsers: AppUserDto[];
  titleId: IntlMessageId;
  projectId: Guid;
  organizationId: Guid;
  requestEndpoint: AddUserEndpointType;
  groupsList?: GroupListDto[];
  existUsersOnly?: boolean;
};
const ProjectUsersAddFormModal: FunctionComponent<Props> = (props) => {
  const {
    onSubmit,
    toAddAppUsers,
    titleId,
    projectId,
    organizationId,
    groupsList,
    existUsersOnly,
    requestEndpoint,
    ...restProps
  } = props;

  const handleSubmit: FormSubmitHandler<ProjectUserAddFormData> = async (values) => {
    const data: ProjectUsersRequestDto = {
      users: Object.entries(values.usersToAddWithAttributes).map(([mail, attributes]) => ({
        mail: mail,
        language: attributes.defaultLanguage,
        addAsAdmin: attributes.isAdmin,
        groups: values.groups,
      })),
      organizationId,
      projectId,
    };

    const [err] = await AddUserEndpoints[requestEndpoint](data);
    if (err) return err;
    onSubmit(undefined);
    return null;
  };

  return (
    <FormModalWrapper onSubmit={handleSubmit} titleId={titleId} submitTextId="general.add" width={700} {...restProps}>
      <ProjectUserAddForm
        toAddAppUsers={toAddAppUsers}
        groupsList={groupsList}
        existUsersOnly={existUsersOnly}
        requestEndpoint={requestEndpoint}
      />
    </FormModalWrapper>
  );
};

export default ProjectUsersAddFormModal;
