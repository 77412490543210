import { ProjectTemplateLabelDto } from 'api/completeApiInterfaces';
import { EditButton } from 'components/ActionButtons/EditButton';
import { DeleteButton } from 'components/DeleteButton/DeleteButton';
import DisplayName from 'components/DisplayName';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import Label from 'components/Label';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import StackPanel from 'components/StackPanel';
import { useBoolean } from 'hooks';
import { Fmt } from 'locale';
import PageContent from 'pages/ProjectSettingsPage/PageContent';
import Panel from 'pages/ProjectSettingsPage/Panel';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { InjectedIntl } from 'react-intl';
import { smartFilter, strCompareCI } from 'utils';
import { ProjectTemplateData, ProjectTemplateDataAction } from '../../ProjectTemplateBuilder';
import ProjectTemplateLabelFormModal from './ProjectTemplateLabelFormModal';

type Props = {
  intl: InjectedIntl;
  projectTemplate: ProjectTemplateData;
  dispatchProjectTemplate: React.Dispatch<ProjectTemplateDataAction>;
};

const ProjectTemplateLabelsTab: FunctionComponent<Props> = ({ intl, projectTemplate, dispatchProjectTemplate }) => {
  const [search, setSearch] = useState<string>();
  const [editedLabelId, setEditedLabelId] = useState<Guid>();
  const [isLabelModalVisible, showLabelModal, hideLabelModal] = useBoolean(false);

  const handleLabelRemove = useCallback(
    (labelId: Guid) => {
      dispatchProjectTemplate({
        type: 'deleteLabel',
        labelId: labelId,
      });
    },
    [dispatchProjectTemplate]
  );

  const handleLabelSubmit = useCallback(
    (label: ProjectTemplateLabelDto) => {
      if (!!editedLabelId) {
        dispatchProjectTemplate({
          type: 'updateLabel',
          label: label,
        });
      } else {
        dispatchProjectTemplate({
          type: 'addLabel',
          label: label,
        });
      }
      setEditedLabelId(undefined);
      hideLabelModal();
    },
    [editedLabelId, dispatchProjectTemplate]
  );

  const showLabelEdit = useCallback((labelId: Guid) => {
    setEditedLabelId(labelId);
    showLabelModal();
  }, []);

  const handleClearSearch = () => setSearch(undefined);

  const validateUniqueName = useCallback(
    (name: string): boolean =>
      !projectTemplate.projectTemplateLabels.some(
        (label) => strCompareCI(label.name, name) === 0 && (!!editedLabelId ? label.id !== editedLabelId : true)
      ),
    [editedLabelId, projectTemplate.projectTemplateLabels]
  );

  const editedLabel = useMemo(() => projectTemplate.projectTemplateLabels.find((label) => label.id === editedLabelId), [
    editedLabelId,
    projectTemplate.projectTemplateLabels,
  ]);

  const onCloseModal = useCallback(() => {
    hideLabelModal();
    setEditedLabelId(undefined);
  }, [hideLabelModal]);

  return (
    <StackPanel vertical>
      <PageContent title={<Fmt id={'general.labels'} />}>
        <Panel
          onSearch={setSearch}
          searchValue={search}
          addButtonOnClick={showLabelModal}
          addButtonText={<Fmt id="Panel.AddLabel.tooltip" />}
        >
          <StackPanel vertical scrollable>
            <GeneralSettingsContainer>
              <List<ProjectTemplateLabelDto>
                data={projectTemplate.projectTemplateLabels}
                search={search}
                filterItem={(item) => item && smartFilter(item?.name, search)}
                renderItem={(item) => (
                  <GeneralSettingsItem
                    title={<Label color={item.color}>{<DisplayName text={item.name} />}</Label>}
                    description={item.description}
                    additionalActions={
                      <>
                        <DeleteButton onDelete={() => handleLabelRemove(item.id)} type="link" />
                        <EditButton onClick={() => showLabelEdit(item.id)} />
                      </>
                    }
                  />
                )}
                renderEmpty={(total, filtered) => (
                  <ListEmpty filtered={filtered} total={total} onClearSearch={handleClearSearch} />
                )}
              />
            </GeneralSettingsContainer>
          </StackPanel>
        </Panel>
        {isLabelModalVisible && (
          <ProjectTemplateLabelFormModal
            onSubmit={handleLabelSubmit}
            onClose={onCloseModal}
            open={isLabelModalVisible}
            editedLabel={editedLabel}
            validateUniqueName={validateUniqueName}
          />
        )}
      </PageContent>
    </StackPanel>
  );
};

export default ProjectTemplateLabelsTab;
