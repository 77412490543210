import { Button, Checkbox, Col, InputRef, Modal, Radio, RadioChangeEvent, Row, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { AppUserDto, LanguageEnum, ProjectUserProfileListDto } from 'api/completeApiInterfaces';
import { DeleteButton } from 'components/ActionButtons';
import CommonHubEllipsisText from 'components/CommonHubEllipsisText/CommonHubEllipsisText';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { AddIcon, DeleteIcon, EditIcon } from 'components/Icons/HubActionsIcons';
import { Margin } from 'components/Margin/Margin';
import { UserTransfer } from 'components/UserTransfer/UserTransfer';
import { USER_TRANSFER_MODAL_WIDTH } from 'config/constants';
import { useActiveProject, useBoolean, useCurrentAppUser, useCurrentProjectUser, useIntl } from 'hooks';
import { useFocus } from 'hooks/useFocus';
import { Fmt } from 'locale';
import { cloneDeep, difference, union } from 'lodash';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import { ignoreRef } from 'utils';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import ProjectUserInviteForm, { ProjectUserInviteFormData } from '../ProjectUserInviteForm/ProjectUserInviteForm';

export enum UserSourceEnum {
  current = 'current',
  new = 'new',
}

export enum SelectableUsersSourceEnum {
  aspeHub = 'aspeHub',
  organization = 'organization',
}

export type UserAttributes = {
  isAdmin: boolean;
  defaultLanguage: LanguageEnum;
  source: UserSourceEnum;
};

type Props = {
  selectableAppUsers: AppUserDto[];
  selectableUsersToAddSource: SelectableUsersSourceEnum;
  onChange?: (users: Record<string, UserAttributes>) => void;
  isAdminsAdding?: boolean;
  isProjectUsersAdding?: boolean;
};

const AppUsersFormItemComponent: React.FunctionComponent<Props> = ({
  selectableAppUsers,
  isAdminsAdding,
  isProjectUsersAdding,
  selectableUsersToAddSource,
  onChange,
}) => {
  const intl = useIntl();
  const [usersToAddWithAttributes, setNewUsersToAddWithAttributes] = useState<Record<string, UserAttributes>>({});
  const [userInviteVisible, showUserInviteModal, hideUserInviteModal] = useBoolean(false);

  const [newAppUsersToAddMails, setNewAppUsersToAddMails] = useState<string[]>([]);
  const [selectOfActualAppUsers, setSelectOfActualAppUsers] = useState<string[]>([]);
  const [transferValue, setTransferValue] = useState<Guid[]>([]);
  const [userTransferVisible, showUserTransfer, hideUserTransfer] = useBoolean(false);

  const currentProject = useActiveProject();
  const currentProjectUser = useCurrentProjectUser();
  const currentAppUser = useCurrentAppUser();
  const isCurrentUserProjectAdmin = !!currentProjectUser?.isAdmin;

  useEffect(() => {
    if (selectableAppUsers && selectableAppUsers.some((user) => user.id === currentAppUser.id)) {
      setSelectOfActualAppUsers([currentAppUser.username]);
      setTransferValue([currentAppUser.id]);
    }
  }, [currentAppUser, selectableAppUsers]);

  useEffect(() => {
    const usersToAddWithAttributesCopy = cloneDeep(usersToAddWithAttributes);
    const mailsInForm = Object.keys(usersToAddWithAttributesCopy);
    const mailsToAddToForm = difference([...newAppUsersToAddMails, ...selectOfActualAppUsers], mailsInForm);
    const mailsToRemoveFromForm = difference(mailsInForm, [...newAppUsersToAddMails, ...selectOfActualAppUsers]);

    if (!!mailsToRemoveFromForm.length) {
      mailsToRemoveFromForm.forEach((mail) => {
        delete usersToAddWithAttributesCopy[mail];
      });
    }
    const emptyRecord: Record<string, UserAttributes> = {};

    if (!!mailsToAddToForm.length) {
      !!newAppUsersToAddMails.length &&
        newAppUsersToAddMails.forEach((mail) => {
          emptyRecord[mail] = {
            isAdmin: !!usersToAddWithAttributes[mail]
              ? usersToAddWithAttributes[mail].isAdmin
              : false || !!isAdminsAdding,
            defaultLanguage: !!usersToAddWithAttributes[mail]
              ? usersToAddWithAttributes[mail].defaultLanguage
              : intl.locale,
            source: UserSourceEnum.new,
          };
        });
      !!selectOfActualAppUsers.length &&
        selectOfActualAppUsers.forEach((mail) => {
          emptyRecord[mail] = {
            isAdmin: !!usersToAddWithAttributes[mail]
              ? usersToAddWithAttributes[mail].isAdmin
              : false || !!isAdminsAdding || mail === currentAppUser.username,
            defaultLanguage: !!usersToAddWithAttributes[mail]
              ? usersToAddWithAttributes[mail].defaultLanguage
              : intl.locale,
            source: UserSourceEnum.current,
          };
        });
    }
    setNewUsersToAddWithAttributes({ ...usersToAddWithAttributesCopy, ...emptyRecord });
  }, [newAppUsersToAddMails, selectOfActualAppUsers, currentAppUser]);

  const setIsAdmin = useCallback(
    (checked: boolean, mail: string) => {
      setNewUsersToAddWithAttributes({
        ...usersToAddWithAttributes,
        [mail]: { ...usersToAddWithAttributes[mail], isAdmin: checked },
      });
    },
    [usersToAddWithAttributes]
  );

  const setLanguage = useCallback(
    (language: LanguageEnum, mail: string) => {
      setNewUsersToAddWithAttributes({
        ...usersToAddWithAttributes,
        [mail]: { ...usersToAddWithAttributes[mail], defaultLanguage: language },
      });
    },
    [usersToAddWithAttributes]
  );

  useDebounce(
    () => {
      onChange && onChange(Object.keys(usersToAddWithAttributes)?.length ? usersToAddWithAttributes : undefined);
    },
    200,
    [usersToAddWithAttributes]
  );

  const remappedSelectableUsers: ProjectUserProfileListDto[] = useMemo(() => {
    return (
      selectableAppUsers?.map((user) => ({
        id: user.id,
        username: user.username,
        firstname: user.firstname,
        lastname: user.lastname,
        isAdmin: user.isAdmin,
        status: undefined,
      })) || []
    );
  }, [selectableAppUsers]);

  const handleNewUsersAddSubmit: FormSubmitHandler<ProjectUserInviteFormData> = async (values) => {
    hideUserInviteModal();
    const inviteFormEmails = values.emails.map((item) => item.email.toLowerCase());
    const aspeHubEmails = selectableAppUsers?.map((user) => user.username.toLowerCase()) || [];
    const newUsersInviteFormEmails = inviteFormEmails.filter((email) => !aspeHubEmails.includes(email));
    setNewAppUsersToAddMails(newUsersInviteFormEmails);
    setSelectOfActualAppUsers(
      union([...selectOfActualAppUsers, ...difference(inviteFormEmails, newUsersInviteFormEmails)])
    );

    return null;
  };

  const attributedNewEmails = useMemo(
    () =>
      Object.entries(usersToAddWithAttributes)
        .filter(([mail, attributes]) => attributes.source === UserSourceEnum.new)
        .map(([mail, attributes]) => mail),

    [usersToAddWithAttributes]
  );

  const handleExistUsersAddSubmit = useCallback(() => {
    setSelectOfActualAppUsers(
      remappedSelectableUsers?.filter((user) => transferValue.includes(user.id)).map((user) => user.username)
    );
    hideUserTransfer();
  }, [hideUserTransfer, remappedSelectableUsers, transferValue]);

  const handleExistUsersAddClose = useCallback(() => {
    hideUserTransfer();
  }, [hideUserTransfer]);

  const formUsersRowsBySource = useCallback(
    (usersSource: UserSourceEnum) => {
      return Object.entries(usersToAddWithAttributes)
        .filter((item) => item[1].source === usersSource)
        .map(([key, value]) => (
          <div key={key}>
            <Row>
              <Col span={10}>
                <CommonHubEllipsisText title={key}>{key}</CommonHubEllipsisText>
              </Col>
              <Col span={5}>
                <Checkbox
                  checked={value.isAdmin}
                  onChange={(e: CheckboxChangeEvent) => setIsAdmin(e.target.checked, key)}
                  disabled={isAdminsAdding || (!isCurrentUserProjectAdmin && isProjectUsersAdding)}
                >
                  <Fmt id={'general.administrator'} />
                </Checkbox>
              </Col>
              <Col span={7}>
                <Radio.Group
                  size="small"
                  defaultValue={intl.locale}
                  onChange={(e: RadioChangeEvent) => setLanguage(e.target.value, key)}
                >
                  <Radio.Button value={LanguageEnum.cs}>
                    {intl.formatMessage({ id: 'ProjectCreateForm.form.items.language.cs' })}
                  </Radio.Button>
                  <Radio.Button value={LanguageEnum.en}>
                    {intl.formatMessage({ id: 'ProjectCreateForm.form.items.language.en' })}
                  </Radio.Button>
                </Radio.Group>
              </Col>
              <Col span={2}>
                <DeleteButton
                  onClick={() => {
                    setSelectOfActualAppUsers(selectOfActualAppUsers.filter((user) => user !== key));
                    setNewAppUsersToAddMails(newAppUsersToAddMails.filter((user) => user !== key));
                  }}
                />
              </Col>
            </Row>
          </div>
        ));
    },
    [usersToAddWithAttributes]
  );

  const { setInputRef } = useFocus<InputRef>(userInviteVisible);

  return (
    <>
      <FormModalWrapper
        onSubmit={handleNewUsersAddSubmit}
        titleId={'general.addNewUsers'}
        submitTextId="general.add"
        open={userInviteVisible}
        onClose={hideUserInviteModal}
      >
        <ProjectUserInviteForm
          sentUsers={undefined}
          initialUsers={attributedNewEmails}
          ignoreGroups
          setRef={setInputRef}
        />
      </FormModalWrapper>

      <>
        <Space>
          <CommonHubTooltip
            title={
              !selectableAppUsers?.length ? (
                <Fmt id={`AppUsersFormItem.addCurrentUsers.disable.${selectableUsersToAddSource}`} />
              ) : (
                undefined
              )
            }
          >
            <Button
              type="primary"
              size="small"
              icon={!!selectOfActualAppUsers.length ? <EditIcon /> : <AddIcon />}
              onClick={showUserTransfer}
              disabled={!selectableAppUsers?.length}
            >
              {!!selectOfActualAppUsers.length ? (
                <Fmt id={'AppUsersFormItem.changeCurrentUsers'} />
              ) : (
                <Fmt id={`AppUsersFormItem.addCurrentUsers.${selectableUsersToAddSource}`} />
              )}
            </Button>
          </CommonHubTooltip>
          {!!selectOfActualAppUsers.length && (
            <Button
              size="small"
              type="primary"
              onClick={() => {
                setTransferValue([]);
                setSelectOfActualAppUsers([]);
              }}
              icon={<DeleteIcon />}
            >
              <Fmt id={'AppUsersFormItem.removeCurrentUsers'} />
            </Button>
          )}
        </Space>
        <Margin top>{formUsersRowsBySource(UserSourceEnum.current)}</Margin>
      </>

      {(!currentProject?.organization || currentProject?.organization.projectAdminCanInviteNewUser) && (
        <Margin top>
          <Space>
            <Button
              onClick={showUserInviteModal}
              type="primary"
              size="small"
              icon={!!newAppUsersToAddMails.length ? <EditIcon /> : <AddIcon />}
            >
              {!!newAppUsersToAddMails.length ? (
                <Fmt id={'AppUsersFormItem.changeNewUsers'} />
              ) : (
                <Fmt id={'AppUsersFormItem.addNewUsers'} />
              )}
            </Button>
            {!!newAppUsersToAddMails.length && (
              <Button type="primary" size="small" onClick={() => setNewAppUsersToAddMails([])} icon={<DeleteIcon />}>
                <Fmt id={'AppUsersFormItem.removeNewUsers'} />
              </Button>
            )}
          </Space>
        </Margin>
      )}
      <Margin top>
        <Fragment>{formUsersRowsBySource(UserSourceEnum.new)}</Fragment>
      </Margin>
      <Modal
        title={<Fmt id={`AppUsersFormItem.addCurrentUsers.${selectableUsersToAddSource}`} />}
        open={userTransferVisible}
        onOk={handleExistUsersAddSubmit}
        onCancel={handleExistUsersAddClose}
        width={USER_TRANSFER_MODAL_WIDTH}
      >
        <UserTransfer users={remappedSelectableUsers} onChange={setTransferValue} value={transferValue} />
      </Modal>
    </>
  );
};

export const AppUsersFormItem = ignoreRef(React.memo(AppUsersFormItemComponent));
