import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ButtonType } from 'antd/lib/button';
import {
  ProjectMetadataDefinitionMetadataEntityType,
  ProjectTemplateMetadataDefinitionDto,
} from 'api/completeApiInterfaces';
import { useBoolean, useIntl, useSameCallback } from 'hooks';
import React, { FunctionComponent } from 'react';
import { ProjectTemplateMetadataDefinitionsSettingModal } from './ProjectTemplateMetadataDefinitionsSettingModal';

type Props = {
  entityType: ProjectMetadataDefinitionMetadataEntityType;
  metadataDefinitions: ProjectTemplateMetadataDefinitionDto[];
  disabled?: boolean;
  editDisabled?: boolean;
  title?: string;
  onSuccess?: (data: ProjectTemplateMetadataDefinitionDto[]) => void;
  buttonType?: ButtonType;
};

export const ProjectTemplateMetadataSettingsFormButton: FunctionComponent<Props> = ({
  metadataDefinitions,
  entityType,
  disabled,
  onSuccess,
  title,
  children,
  buttonType = 'link',
}) => {
  const [modalVisible, modalShow, modalHide] = useBoolean(false);

  const intl = useIntl();

  const handleSubmit = useSameCallback((data: ProjectTemplateMetadataDefinitionDto[]) => {
    onSuccess && onSuccess(data);
    modalHide();
  });

  return (
    <>
      <Button type={buttonType} icon={<EditOutlined />} disabled={disabled} onClick={modalShow}>
        {children}
      </Button>

      {modalVisible && (
        <ProjectTemplateMetadataDefinitionsSettingModal
          onSubmit={handleSubmit}
          open={modalVisible}
          onClose={modalHide}
          metadataDefinitions={metadataDefinitions}
          entityType={entityType}
          title={title}
          intl={intl}
        />
      )}
    </>
  );
};
