import { UserAddOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Form,
  FormListFieldData,
  FormListOperation,
  Input,
  InputRef,
  Row,
  Typography,
} from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { DeleteButton } from 'components/ActionButtons';
import MultipleInputModal from 'components/MultipleInputModal';
import { useBoolean, useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FC, ReactNode } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store';
import { includesDiacriticsRule, requiredRule } from 'utils/formHelpers';
import uuid from 'uuid';

const mapStateToProps = (state: RootState) => ({
  appSettings: state.appSettings.data,
});

type PropsFromState = ReturnType<typeof mapStateToProps>;

export type ShareDownloadFormData = {
  emails: MailData[];
  message: string;
  includeSubdirectories?: boolean;
};

type MailData = {
  email: string;
  id: Guid;
};

type OwnProps = {
  showIncludeSubdirectories?: boolean;
};

type Props = PropsFromState & OwnProps & { setRef: (ref: InputRef) => void };

const ShareDownloadForm: FC<Props> = ({ showIncludeSubdirectories, appSettings, setRef }) => {
  const form = useFormInstance();
  const intl = useIntl();
  const [isMultiMail, setIsMultiMail, clearIsMultiMail] = useBoolean(false);
  const emails = useWatch('emails');

  const handleEmailsAdd = (values: string[]) => {
    const valuesEmails = values.reduce<MailData[]>((acc, email) => {
      return [...acc, { id: uuid.v4(), email: email }];
    }, []);

    form.setFieldsValue({ emails: [...emails, ...valuesEmails] });
    clearIsMultiMail();
  };

  const itemsConverter = (value: string): string[] => {
    const matches: RegExpMatchArray = value.match(
      /([\w\-!#$%&'*+/=?^_`{|}~](\.?[\w\-!#$%&'*+/=?^_`{|}~])*@([\w\-!#$%&'*+/=?^_`{|}~]+\.)+[^\d\W]{2,})/gi
    );
    if (!matches || !matches.length) return [];
    const uniqueEmails: Set<string> = new Set(
      matches.filter((match) => !!match).map((match) => match.toString().toLowerCase())
    );
    return Array.from(uniqueEmails);
  };

  const emailDuplicateValidator = (value: MailData): Promise<void | any> | void => {
    const tValue: string = value.email.toLowerCase().trim();

    const result = emails.some((e: MailData) => {
      return e.email === tValue && e.id !== value.id;
    });

    if (result) {
      return Promise.reject(intl.formatMessage({ id: 'forms.items.rules.duplicateEmail' }));
    }
    return Promise.resolve();
  };

  return (
    <>
      {showIncludeSubdirectories && (
        <Form.Item
          label={intl.formatMessage({ id: 'SharedDownload.form.items.includeSubdirectories.title' })}
          name="includeSubdirectories"
          initialValue={true}
          valuePropName="checked"
        >
          <Checkbox>
            <Fmt id="SharedDownload.form.items.includeSubdirectories.label" />
          </Checkbox>
        </Form.Item>
      )}
      <Form.List name={'emails'} initialValue={[{ id: uuid.v4(), email: '' }] as MailData[]}>
        {(
          fields: FormListFieldData[],
          { add, remove }: FormListOperation,
          meta: { errors: ReactNode[]; warnings: ReactNode[] }
        ) => (
          <>
            {fields.map(({ key, name, ...restField }, i, data) => {
              return (
                <Form.Item
                  key={key}
                  label={i > 0 ? undefined : intl.formatMessage({ id: 'ShareDownloadForm.form.emails' })}
                  style={{ marginBottom: '4px' }}
                  name={name}
                  rules={[{ validator: (_, value) => emailDuplicateValidator(value) }]}
                >
                  <>
                    <Form.Item name={[name, 'id']} hidden noStyle />
                    <Row gutter={24}>
                      <Col span={fields.length > 1 ? 22 : 24}>
                        <Form.Item
                          {...restField}
                          noStyle
                          name={[name, 'email']}
                          validateFirst
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            includesDiacriticsRule('forms.items.rules.email.noDiacritics'),
                            requiredRule('forms.items.rules.required'),
                            { type: 'email', message: intl.formatMessage({ id: 'forms.items.rules.email' }) },
                          ]}
                        >
                          <Input
                            placeholder={intl.formatMessage({ id: 'forms.items.email.placeholder' })}
                            autoFocus
                            ref={setRef}
                          />
                        </Form.Item>
                      </Col>
                      {fields.length > 1 && (
                        <Col span={2}>
                          <DeleteButton onClick={() => remove(name)} />
                        </Col>
                      )}
                    </Row>
                  </>
                </Form.Item>
              );
            })}
            <Form.Item>
              <Row gutter={24}>
                <Col span={12}>
                  <Button onClick={setIsMultiMail} style={{ width: '100%' }}>
                    <UsergroupAddOutlined /> <Fmt id="ProjectUserInviteFormData.form.addMultiMail" />
                  </Button>
                </Col>
                {!isMultiMail && (
                  <Col span={12}>
                    <Button onClick={() => add({ id: uuid.v4(), email: '' })} style={{ width: '100%' }}>
                      <UserAddOutlined /> <Fmt id="ProjectUserInviteFormData.form.addEmail" />
                    </Button>
                  </Col>
                )}
              </Row>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item
        label={intl.formatMessage({ id: 'SharedDownload.form.items.message.label' })}
        name="message"
        initialValue=""
      >
        <Input.TextArea rows={3} autoSize={{ minRows: 3 }} />
      </Form.Item>

      <MultipleInputModal
        visible={isMultiMail}
        onSubmit={handleEmailsAdd}
        onClose={clearIsMultiMail}
        title={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.multipleInput.label' })}
        itemsFinder={itemsConverter}
        submitText={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.multipleInput.addEmails' })}
        cancelText={intl.formatMessage({ id: 'forms.button.cancel' })}
        placeholder={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.multipleInput.placeholder' })}
        errorText={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.multipleInput.error' })}
        inputText={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.multipleInput.inputText' })}
        resultText={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.multipleInput.resultText' })}
      />
      <Typography.Text type="danger" strong>
        <Fmt
          id="SharedDownload.page.expiredInterval"
          values={{ interval: Math.round(appSettings.sharedDownloadExpiredDate / 3600 / 24) }}
        />
      </Typography.Text>
    </>
  );
};

export default connect(mapStateToProps)(ShareDownloadForm);
