import { Badge, Col, Popover, Row } from 'antd';

import { MdRoleDto, MdUserDto, OrgUserClaimDto } from 'api/completeApiInterfaces';
import StackPanel from 'components/StackPanel';
import { UserAvatarSize, UserAvatarType, UserIcon } from 'components/avatars/UserIcon/UserIcon';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { Fmt } from 'locale';
import React, { FunctionComponent, useMemo } from 'react';
import styles from './MDRoleDisplay.module.less';

type Props = {
  mdRole: MdRoleDto;
  extendedUserClaim: OrgUserClaimDto[];
};

const renderUserClaim = (claim: OrgUserClaimDto) => {
  const claimType = claim.claimType.toLocaleLowerCase();
  if (claimType === 'phonenumber') {
    return (
      <Row key="name">
        <Col flex="100px">
          <Fmt id={`OrganizationUserClaim.claimType.${claimType}`} />
        </Col>
        <Col flex="auto">{claim.claimValue}</Col>
      </Row>
    );
  }
  return null;
};

const renderMdUser = (mdUser: MdUserDto, extendedUserClaim: OrgUserClaimDto[]) => {
  const mdUserFullName = mdUser.orgUser.namesFromOrganization
    ? ((mdUser.orgUser.firstname || '') + ' ' + (mdUser.orgUser.lastname || '')).trim()
    : ((mdUser.orgUser.appUserProfile.firstname || '') + ' ' + (mdUser.orgUser.appUserProfile.lastname || '')).trim();
  return (
    <FlowLayout key={mdUser.id}>
      <StackPanel vertical className={styles.roleUser}>
        {mdUserFullName && (
          <Row key={mdUser.id} className={styles.infoRow}>
            <Col flex="100px">
              <Fmt id="general.personName" />
            </Col>
            <Col flex="auto">{mdUserFullName}</Col>
          </Row>
        )}
        <Row key="email" className={styles.infoRow}>
          <Col flex="100px">
            <Fmt id="general.email" />
          </Col>
          <Col flex="auto">{mdUser.orgUser.appUserProfile.username}</Col>
        </Row>
        {extendedUserClaim?.filter((claim) => claim.userId === mdUser.orgUser.id).map(renderUserClaim)}
      </StackPanel>
    </FlowLayout>
  );
};

const MDRoleDisplay: FunctionComponent<Props> = ({ mdRole, extendedUserClaim }) => {
  const roleUsersContent = useMemo(() => mdRole?.mdUsers.map((mdUser) => renderMdUser(mdUser, extendedUserClaim)), [
    extendedUserClaim,
    mdRole.mdUsers,
  ]);

  const userIconData: UserAvatarType = useMemo(() => {
    return {
      firstname: mdRole?.head.orgUser.firstname || null,
      lastname: mdRole?.head.orgUser.lastname || null,
      username: mdRole?.head.orgUser.appUserProfile.username || null,
    };
  }, [mdRole]);
  return (
    <FlowLayout className={styles.wrapper} fullWidth>
      {renderMdUser(mdRole?.head, extendedUserClaim)}

      {!!roleUsersContent?.length ? (
        <Popover
          content={<StackPanel vertical>{roleUsersContent}</StackPanel>}
          title={<Fmt id="MD.Organization.roles.modal.input.users" />}
          trigger="hover"
        >
          <Badge dot color="lime">
            <UserIcon user={userIconData} size={UserAvatarSize.Large} />
          </Badge>
        </Popover>
      ) : (
        <UserIcon user={userIconData} size={UserAvatarSize.Large} />
      )}
    </FlowLayout>
  );
};

export default React.memo(MDRoleDisplay);
