import { LanguageEnum } from 'locale/messages/interfaces';
import { FunctionComponent } from 'react';
import { FormattedHTMLMessage, FormattedMessage, InjectedIntl as InjectedIntlDef, MessageValue } from 'react-intl';
import { IntlMessageId } from './messages/cs';

export interface MessageDescriptor extends FormattedMessage.MessageDescriptor {
  id: IntlMessageId;
}

export interface InjectedIntl extends InjectedIntlDef {
  formatMessage(messageDescriptor: MessageDescriptor, values?: { [key in string]: MessageValue }): string;
  formatHTMLMessage(messageDescriptor: MessageDescriptor, values?: { [key in string]: MessageValue }): string;
  locale: LanguageEnum;
  messages: { [id in IntlMessageId]: string };
  defaultLocale: LanguageEnum;
}

export interface InjectedIntlProps {
  intl: InjectedIntl;
}

export type FmtProps = FormattedMessage.Props & MessageDescriptor;

// FormattedMessage is a class component, but Fmt is declared as a function component, so the "as any" cast is needed.
// It is going to be more efficient because there will not be an extra Fmt "wrapper" component around FormattedMessage.
const Fmt: FunctionComponent<FmtProps> = FormattedMessage as any;

const FmtHTML: FunctionComponent<FmtProps> = FormattedHTMLMessage as any;

export { Fmt, FmtHTML };
