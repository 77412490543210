import { Button, Select, Space, Tag, Typography } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import {
  ExtendedPermissionCategoryEnum,
  ExtendedPermissionEnum,
  ExtendedPermissionTemplateDto,
  OrgUserDto,
  OrgUserRequestDto,
  ProjectTemplateUserDto,
} from 'api/completeApiInterfaces';
import { UserAvatarSize, UserIcon } from 'components/avatars/UserIcon/UserIcon';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { DeleteButton } from 'components/DeleteButton/DeleteButton';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import StackPanel from 'components/StackPanel';
import { useBoolean, useSameCallback } from 'hooks';
import { useMasterDetailView } from 'hooks/useMasterDetailView';
import { Fmt } from 'locale';
import { uniqBy } from 'lodash';
import PageContent from 'pages/ProjectSettingsPage/PageContent';
import Panel from 'pages/ProjectSettingsPage/Panel';
import {
  DEFAULT_ACTIVE_PERMISSIONS,
  PERMISSION_MAP,
  PermissionsDrawer,
} from 'pages/ProjectSettingsPage/Users/UserDetailPanel/PermissionsDrawer';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { InjectedIntl } from 'react-intl';
import { smartFilter } from 'utils';
import uuid from 'uuid';
import { ProjectTemplateData, ProjectTemplateDataAction } from '../../ProjectTemplateBuilder';
import ProjectTemplatePermissionPresetFormModal from './ProjectTemplatePermissionPresetFormModal';
import ProjectTemplateUsersAddFormModal from './ProjectTemplateUsersAddFormModal';

type Props = {
  intl: InjectedIntl;
  organizationId: Guid;
  projectTemplate: ProjectTemplateData;
  dispatchProjectTemplate: React.Dispatch<ProjectTemplateDataAction>;
  organizationUsers: OrgUserDto[];
  reloadOrganizationUsers: () => void;
};

const CUSTOM_USER_PERMISSION_PROFILE_KEY = '';

const ProjectTemplateUsersTab: FunctionComponent<Props> = ({
  intl,
  organizationId,
  projectTemplate,
  dispatchProjectTemplate,
  organizationUsers,
  reloadOrganizationUsers,
}) => {
  const [selectedUserId, setSelectedUserId] = useState<Guid>();
  const [search, setSearch] = useState<string>();
  const [userListToAdd, setUserListToAdd] = useState<OrgUserRequestDto[]>([]);

  const [isAddUserModalVisible, showAddUserModal, hideAddUserModal] = useBoolean(false);
  const [isPresetSaveModalVisible, showPresetSaveModal, hidePresetSaveModal] = useBoolean(false);

  const blockedUsersByWf = useMemo((): Set<Guid> => new Set<Guid>(projectTemplate.usedUserIds), [projectTemplate]);

  const handleUserRemove = useCallback(
    (userId: Guid) => {
      if (blockedUsersByWf.has(userId)) return;

      dispatchProjectTemplate({
        type: 'removeUser',
        userId: userId,
      });
    },
    [blockedUsersByWf, dispatchProjectTemplate]
  );

  const handleTemplateUsersAdd = useSameCallback(async (data: OrgUserRequestDto[]) => {
    reloadOrganizationUsers();
    setUserListToAdd(uniqBy([...userListToAdd, ...data], (user) => user.mail));
    hideAddUserModal();
  });

  useEffect(() => {
    if (!userListToAdd.length) return;

    const usersToAdd = organizationUsers
      .filter((user) =>
        userListToAdd.some((userToAdd) => user.appUserProfile.username.toLowerCase() === userToAdd.mail.toLowerCase())
      )
      .map(
        (userToAdd): ProjectTemplateUserDto => ({
          id: uuid(),
          appUserOrganizationId: userToAdd.id,
          appUserOrganization: userToAdd,
        })
      );

    dispatchProjectTemplate({
      type: 'addUsers',
      users: usersToAdd,
    });

    const usersToAddToAdmin = usersToAdd
      .filter((user) =>
        userListToAdd.some(
          (userToAdd) =>
            user.appUserOrganization.appUserProfile.username.toLowerCase() === userToAdd.mail.toLowerCase() &&
            userToAdd.isAdmin
        )
      )
      .map((user) => user.id);
    if (!!usersToAddToAdmin.length) {
      dispatchProjectTemplate({
        type: 'addAdmins',
        userIds: usersToAddToAdmin,
      });
    }

    if (!!usersToAdd.length) {
      setUserListToAdd(
        userListToAdd.filter(
          (current) =>
            !usersToAdd.some(
              (userToAdd) =>
                userToAdd.appUserOrganization.appUserProfile.username.toLowerCase() === current.mail.toLowerCase()
            )
        )
      );
    }
  }, [dispatchProjectTemplate, organizationUsers, userListToAdd]);

  const handlePermissionChange = useCallback(
    async (permission: ExtendedPermissionEnum, checked: boolean) => {
      dispatchProjectTemplate({
        type: 'setExtendedPermission',
        userId: selectedUserId,
        permission: permission,
        checked: checked,
        permissionCategory: Object.keys(PERMISSION_MAP).find((key: ExtendedPermissionCategoryEnum) =>
          PERMISSION_MAP[key].some((mapPermission) => mapPermission === permission)
        ) as ExtendedPermissionCategoryEnum,
      });
    },
    [dispatchProjectTemplate, selectedUserId]
  );

  const handlePresetSaveSubmit = useCallback(
    (data: ExtendedPermissionTemplateDto) => {
      dispatchProjectTemplate({
        type: 'createOrgPermissionTemplate',
        userId: selectedUserId,
        template: data,
      });
      hidePresetSaveModal();
    },
    [dispatchProjectTemplate, selectedUserId]
  );

  const handleUserPresetChange = useCallback(
    (value: Guid) => {
      dispatchProjectTemplate({
        type: 'setUserPermissionTemplate',
        userId: selectedUserId,
        presetId: value,
      });
    },
    [dispatchProjectTemplate, selectedUserId]
  );

  const availablePermissionPresets = useMemo((): DefaultOptionType[] => {
    return [
      ...projectTemplate.orgExtendedPermissionTempates.map((template) => ({
        label: template.name,
        value: template.id,
      })),
      { label: <Fmt id="general.custom" />, value: CUSTOM_USER_PERMISSION_PROFILE_KEY },
    ];
  }, [projectTemplate.orgExtendedPermissionTempates]);

  const selectedUser = useMemo(() => projectTemplate.projectTemplateUsers.find((user) => user.id === selectedUserId), [
    projectTemplate.projectTemplateUsers,
    selectedUserId,
  ]);

  const referencedPermissionTemplate = useMemo(() => {
    if (!selectedUser?.extendedPermissionId) return undefined;

    return (
      projectTemplate.orgExtendedPermissionTempates.find(
        (template) => template.id === selectedUser.extendedPermissionId
      ) ||
      projectTemplate.userExtendedPermissionTempates.find(
        (template) => template.id === selectedUser.extendedPermissionId
      )
    );
  }, [projectTemplate.orgExtendedPermissionTempates, projectTemplate.userExtendedPermissionTempates, selectedUser]);

  const selectedUserPermissions = useMemo((): Set<ExtendedPermissionEnum> => {
    if (!referencedPermissionTemplate) return new Set<ExtendedPermissionEnum>(DEFAULT_ACTIVE_PERMISSIONS);

    return new Set<ExtendedPermissionEnum>(
      referencedPermissionTemplate?.extendedPermissions.settings
        .filter((template) => template.permission)
        .map((template) => template.permissionType) || []
    );
  }, [referencedPermissionTemplate]);

  const isUserUsingOrgPreset = useMemo(
    () =>
      projectTemplate.orgExtendedPermissionTempates.some(
        (template) => template.id === selectedUser?.extendedPermissionId
      ),
    [selectedUser, projectTemplate.orgExtendedPermissionTempates]
  );

  const userDetailPanel = useMemo(
    () =>
      !!selectedUser ? (
        <StackPanel vertical scrollable>
          {availablePermissionPresets && (
            <Space.Compact block>
              <Select
                style={{ width: '100%' }}
                options={availablePermissionPresets}
                value={isUserUsingOrgPreset ? selectedUser.extendedPermissionId : CUSTOM_USER_PERMISSION_PROFILE_KEY}
                onChange={handleUserPresetChange}
              />
              <Button type="primary" onClick={showPresetSaveModal} disabled={isUserUsingOrgPreset}>
                <Fmt id="general.saveProfile" />
              </Button>
            </Space.Compact>
          )}
          <PermissionsDrawer
            userPermissionsSet={selectedUserPermissions}
            handlePermissionChange={handlePermissionChange}
            disabled={isUserUsingOrgPreset}
          />
        </StackPanel>
      ) : (
        undefined
      ),
    [availablePermissionPresets, handlePermissionChange, isUserUsingOrgPreset, selectedUser, selectedUserPermissions]
  );

  const availableOrganizationUsers = useMemo(
    () =>
      organizationUsers
        .filter(
          (orgUser) =>
            !projectTemplate.projectTemplateUsers.some(
              (templateUser) => templateUser.appUserOrganizationId === orgUser.id
            )
        )
        ?.map((orgUser) => orgUser.appUserProfile),
    [organizationUsers, projectTemplate]
  );

  const handleClearSearch = () => setSearch(undefined);
  const hideUserDetail = () => setSelectedUserId(undefined);

  const adminUsers = useMemo(() => {
    return new Set<Guid>(projectTemplate.projectTemplateGroups.find((group) => group.isAdminGroup).templateUserIds);
  }, [projectTemplate.projectTemplateGroups]);

  const { tableWrapRef, title, children } = useMasterDetailView(
    <Panel
      onSearch={setSearch}
      searchValue={search}
      addButtonOnClick={showAddUserModal}
      addButtonText={<Fmt id="general.addUsers" />}
    >
      <GeneralSettingsContainer>
        <List<ProjectTemplateUserDto>
          data={projectTemplate.projectTemplateUsers}
          search={search}
          filterItem={(item) =>
            (item && smartFilter(item?.appUserOrganization.appUserProfile.username, search)) ||
            smartFilter(item?.appUserOrganization.appUserProfile.firstname, search) ||
            smartFilter(item?.appUserOrganization.appUserProfile.lastname, search)
          }
          renderItem={(item) => (
            <GeneralSettingsItem
              icon={<UserIcon size={UserAvatarSize.Large} user={item?.appUserOrganization.appUserProfile} />}
              key={item.id}
              selected={item.id === selectedUserId}
              selectable
              onClick={() => setSelectedUserId(item.id)}
              title={item.appUserOrganization.appUserProfile.username}
              wrap
              description={
                (item.appUserOrganization.appUserProfile.firstname ||
                  item.appUserOrganization.appUserProfile.lastname) && (
                  <>
                    {item.appUserOrganization.appUserProfile.firstname && (
                      <Typography.Text>{`${item.appUserOrganization.appUserProfile.firstname}`}</Typography.Text>
                    )}{' '}
                    {item.appUserOrganization.appUserProfile.lastname && (
                      <Typography.Text>{`${item.appUserOrganization.appUserProfile.lastname}`}</Typography.Text>
                    )}
                  </>
                )
              }
              additionalActions={
                <>
                  {adminUsers.has(item.id) && (
                    <CommonHubTooltip title={<Fmt id="general.administrator" />}>
                      <Tag color="green">A</Tag>
                    </CommonHubTooltip>
                  )}

                  <DeleteButton
                    onDelete={() => handleUserRemove(item.id)}
                    type="link"
                    disabled={blockedUsersByWf.has(item.id)}
                  />
                </>
              }
            />
          )}
          renderEmpty={(total, filtered) => (
            <ListEmpty filtered={filtered} total={total} onClearSearch={handleClearSearch} />
          )}
        />
      </GeneralSettingsContainer>
    </Panel>,
    userDetailPanel,
    intl.formatMessage({ id: 'general.users' }),
    !!selectedUser && selectedUser.appUserOrganization.appUserProfile.username,
    hideUserDetail
  );

  return (
    <>
      <StackPanel vertical scrollable divRef={tableWrapRef}>
        <PageContent title={title}>{children}</PageContent>
        <ProjectTemplateUsersAddFormModal
          open={isAddUserModalVisible}
          onSubmit={handleTemplateUsersAdd}
          onClose={hideAddUserModal}
          organisationUsers={availableOrganizationUsers}
          titleId={'AspeHubUsersListPanel.addModal.title'}
          organizationId={organizationId}
        />
        <ProjectTemplatePermissionPresetFormModal
          open={isPresetSaveModalVisible}
          onSubmit={handlePresetSaveSubmit}
          onClose={hidePresetSaveModal}
          organizationId={organizationId}
          referencedTemplate={referencedPermissionTemplate}
        />
      </StackPanel>
    </>
  );
};

export default ProjectTemplateUsersTab;
