import { ExportOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { api } from 'api';
import {
  AssignmentDto,
  AssignmentMessageDto,
  AssignmentOrderTypeEnum,
  AssignmentSourceEnum,
  AssignmentStateEnum,
  MsgCenterAssignmentListRequestDto,
  MsgCenterAssignmentResponseDto,
  OrgExtendedPermissionEnum,
  OrgExtendedPermissionValueEnum,
  ResultFileFormatEnum,
  ServiceError,
  SortOrder,
} from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import Axios, { CancelTokenSource } from 'axios';
import { AssignmentState } from 'components/AssignmentState/AssignmentState';
import { DocumentsGridHeaderStyled } from 'components/DocumentsGridHeader/DocumentsGridHeaderStyled';
import EmptyStyled from 'components/Empty/EmptyStyled';
import { createBackendDateFilter } from 'components/filters/components/DateFilter/DateFilter';
import { createBackendSelectFilter, IOption } from 'components/filters/components/SelectFilter/SelectFilter';
import { createTranslatedOptions } from 'components/filters/components/SelectFilter/selectFilterUtils';
import { createBackendTextFilter } from 'components/filters/components/TextFilter/TextFilter';
import { BackendFilter, FiltersPersistentKey, FilterWithValue } from 'components/filters/filterTypes';
import { accumulateFilterValues } from 'components/filters/filterUtils';
import { OrderOption } from 'components/filters/orderTypes';
import { FilterToggleButton } from 'components/filters/render/FilterToggleButton/FilterToggleButton';
import { FilterToolbar } from 'components/filters/render/FilterToolbar/FilterToolbar';
import { OrderSelect } from 'components/filters/render/OrderSelect/OrderSelect';
import { AssignmentFormModal } from 'components/forms/AssignmentForm/AssignmentFormModal';
import CommonExportFormModal from 'components/forms/CommonExportFormModal/CommonExportFormModal';
import {
  ExportEntityTypeEnum,
  mapAssignmentsDataToDto,
} from 'components/forms/CommonExportFormModal/CommonExportUtils';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { Margin } from 'components/Margin/Margin';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import StackPanel from 'components/StackPanel';
import Toolbar from 'components/Toolbar/Toolbar';
import { EMPTY_GUID, ESTICON_USER_GUID, TOOLTIP_MOUSE_ENTER_DELAY } from 'config/constants';
import {
  useApiData,
  useBoolean,
  useCurrentAppUser,
  useDispatchEffect,
  useFilters,
  useIntl,
  useSameCallback,
  useStoreSelector,
} from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt, memoizeWithIntl } from 'locale';
import { isEqual } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useDebounce, useToggle } from 'react-use';
import { Dispatch } from 'store';
import { projectsListSelector } from 'store/selectors';
import { processApiError, RedirectOption, redirectWithOption } from 'utils';
import { DEFAULT_PAGE_SIZE } from '../Constants';
import { AssignmentLoader } from './AssignmentDetail/AssignmentLoader';
import { AssignmentList } from './AssignmentList/AssignmentList';

const ASSIGNMENT_STATES_ORDERED: AssignmentStateEnum[] = [
  AssignmentStateEnum.new,
  AssignmentStateEnum.inProgress,
  AssignmentStateEnum.forApproval,
  AssignmentStateEnum.solved,
  AssignmentStateEnum.closed,
];

const ASSIGNMENT_SOURCES_ORDERED: AssignmentSourceEnum[] = [
  AssignmentSourceEnum.createdByUser,
  AssignmentSourceEnum.taskGiver,
  AssignmentSourceEnum.associate,
  AssignmentSourceEnum.assignedToUser,
  AssignmentSourceEnum.primarySolver,
];

const ORDER_OPTIONS: OrderOption<AssignmentOrderTypeEnum>[] = [
  {
    key: AssignmentOrderTypeEnum.priority,
    label: <Fmt id="SearchSortTypeItem.priority" />,
    defaultOrder: SortOrder.asc,
  },
  {
    key: AssignmentOrderTypeEnum.orderNum,
    label: <Fmt id="SearchSortTypeItem.orderId" />,
  },
  {
    key: AssignmentOrderTypeEnum.name,
    label: <Fmt id="SearchSortTypeItem.name" />,
  },
  {
    key: AssignmentOrderTypeEnum.createTime,
    label: <Fmt id="SearchSortTypeItem.createdDate" />,
  },
  {
    key: AssignmentOrderTypeEnum.deadlineTime,
    label: <Fmt id="SearchSortTypeItem.deadlineDate" />,
  },
  {
    key: AssignmentOrderTypeEnum.resolvedTime,
    label: <Fmt id="SearchSortTypeItem.resolvedDate" />,
  },
  {
    key: AssignmentOrderTypeEnum.status,
    label: <Fmt id="SearchSortTypeItem.state" />,
  },
  {
    key: AssignmentOrderTypeEnum.project,
    label: <Fmt id="SearchSortTypeItem.project" />,
  },
];

const areFilterValuesSame = (value1: FilterWithValue[], value2: FilterWithValue[]) => {
  if (value1.length !== value2.length) {
    return false;
  }
  return value1.every((filter1) => {
    const filter2 = value2.find((filter) => filter.key === filter1.key);
    return !!filter2 && isEqual(filter1.value, filter2.value);
  });
};

const MessageCenterAssignmentsComponent: FunctionComponent = () => {
  const [appUsers] = useApiData(api.master.appUsers.getUsersList, { autoload: true });
  const dispatch = useDispatch<Dispatch>();
  const projects = useStoreSelector(projectsListSelector);
  const currentAppUser = useCurrentAppUser();

  const intl = useIntl();
  const { path, url } = useRouteMatch();

  useDispatchEffect((dispatch) => dispatch.allProjects.loadData({ reload: false }), []);

  useDirtyStoreReload(
    (state) => state.allProjects,
    (dispatch) => dispatch.allProjects
  );

  const selectAssignment = (assignmentId: Guid) => {
    redirectWithOption(`${url}/${assignmentId}`, RedirectOption.Replace);
  };

  const filters = useMemo((): BackendFilter<MsgCenterAssignmentListRequestDto>[] => {
    const stateOptions = createTranslatedOptions(
      intl,
      ASSIGNMENT_STATES_ORDERED,
      (state) => `AssignmentStateEnum.${state}`,
      (state) => <AssignmentState state={state} />
    );

    const userOptions = (appUsers || [])
      .filter((appUser) => appUser.id !== EMPTY_GUID && appUser.id !== ESTICON_USER_GUID)
      .map(
        (appUser): IOption<Guid> => ({
          id: appUser.id,
          title: appUser.username,
        })
      )
      .sort((a, b) => a.title.localeCompare(b.title));

    const projectOptions = (projects || [])
      .map(
        (project): IOption<Guid> => ({
          id: project.id,
          title: project.name,
        })
      )
      .sort((a, b) => a.title.localeCompare(b.title));

    const organizationOptions = (currentAppUser?.organizationUsers?.map((orgUser) => orgUser.organization) || [])
      .map(
        (organization): IOption<Guid> => ({
          id: organization.id,
          title: organization.name,
        })
      )
      .sort((a, b) => a.title.localeCompare(b.title));

    const sourceOptions = createTranslatedOptions(
      intl,
      ASSIGNMENT_SOURCES_ORDERED,
      (state) => `AssignmentSourceEnum.${state}`
    );

    return [
      createBackendTextFilter('name', (accu, value) => ({ ...accu, assignmentNameFilter: value }), {
        label: <Fmt id="general.name" />,
      }),
      createBackendSelectFilter(
        'state',
        { label: <Fmt id="general.state" />, options: stateOptions },
        (accu, value) => ({ ...accu, assignmentMsgStateEnumFilter: value.values })
      ),
      createBackendDateFilter('date', [
        {
          key: 'createdTime',
          label: <Fmt id="MessageCenterAssignments.createdDate" />,
          serialize: (accu, value) => ({
            ...accu,
            createTimeFromFilter: value.from || undefined,
            createTimeToFilter: value.to || undefined,
          }),
        },
        {
          key: 'deadlineTime',
          label: <Fmt id="MessageCenterAssignments.deadlineDate" />,
          serialize: (accu, value) => ({
            ...accu,
            deadlineFromFilter: value.from || undefined,
            deadlineToFilter: value.to || undefined,
          }),
        },
        {
          key: 'resolvedTime',
          label: <Fmt id="MessageCenterAssignments.resolvedDate" />,
          serialize: (accu, value) => ({
            ...accu,
            resolvedTimeFromFilter: value.from || undefined,
            resolvedTimeToFilter: value.to || undefined,
          }),
        },
      ]),
      createBackendSelectFilter(
        'users',
        {
          label: <Fmt id="general.users" />,
          options: userOptions,
        },
        (accu, value) => ({
          ...accu,
          assignmenAppUserFilter: {
            sources: Object.values(AssignmentSourceEnum),
            users: value.values,
          },
        })
      ),
      createBackendSelectFilter(
        'project',
        {
          label: <Fmt id="general.project" />,
          options: projectOptions,
        },
        (accu, value) => ({ ...accu, projectFilter: value.values })
      ),
      createBackendSelectFilter(
        'organization',
        {
          label: <Fmt id="general.organization" />,
          options: organizationOptions,
        },
        (accu, value) => ({
          ...accu,
          projectFilter: (projects || [])
            .filter((project) =>
              value.values.some(
                (orgId) =>
                  orgId === project.organization.id &&
                  (!accu.projectFilter?.length || accu.projectFilter.some((projectId) => projectId === project.id))
              )
            )
            .map((project) => project.id),
        })
      ),
      createBackendSelectFilter(
        'source',
        {
          label: <Fmt id="MessageCenterAssignments.source" />,
          options: sourceOptions,
        },
        (accu, value) => ({ ...accu, assignmentMsgSourceEnumFilter: value.values })
      ),
    ];
  }, [intl, appUsers, projects, currentAppUser]);

  const { filtersWithValues, order, ...filterProps } = useFilters(
    filters,
    ORDER_OPTIONS,
    FiltersPersistentKey.Assignments,
    true
  );

  const [filtersWithValuesMemoized, setFiltersWithValuesMemoized] = useState(filtersWithValues);
  useEffect(() => {
    if (!areFilterValuesSame(filtersWithValues, filtersWithValuesMemoized)) {
      setFiltersWithValuesMemoized(filtersWithValues);
    }
  }, [filtersWithValues, filtersWithValuesMemoized]);

  const [[lastError, lastResponse], setLastResult] = useState<[ServiceError, MsgCenterAssignmentResponseDto]>([
    null,
    null,
  ]);
  const [loading, setLoading] = useState(false);
  const [moreLoading, setMoreLoading] = useState(false);
  const [assignments, setAssignments] = useState<AssignmentMessageDto[]>([]);

  const backendRequestTokenRef = useRef<CancelTokenSource>();
  useEffect(() => () => backendRequestTokenRef.current?.cancel('MessageCenterAssignments: unmounting'), []);

  const [showAllTasks, setShowAllTasks] = useState(false);
  const history = useHistory();

  const assignmentsOverviewReadOrganizations = currentAppUser?.organizationUsers?.filter((orgUser) =>
    orgUser.extendedPermissions?.some(
      (perm) =>
        perm.permissionType === OrgExtendedPermissionEnum.assignmentsOverview &&
        perm.permission === OrgExtendedPermissionValueEnum.read
    )
  );
  const adminInOrganizations = currentAppUser?.organizationUsers?.filter((orgUser) => orgUser.isAdmin);
  const canShowAll = !!assignmentsOverviewReadOrganizations?.length || !!adminInOrganizations?.length; // TODO: Add permission for managers

  const createRequest = useCallback(
    async (from: number) => {
      backendRequestTokenRef.current?.cancel('MessageCenterAssignments: another search queued');
      backendRequestTokenRef.current = Axios.CancelToken.source();

      const request = accumulateFilterValues(filtersWithValuesMemoized, {
        size: DEFAULT_PAGE_SIZE,
        from,
        order: [
          {
            property: order.key,
            direction: order.direction,
          },
        ],
        assignmenAppUserFilter: undefined,
        viewOrganizationAssignments: showAllTasks,
      });

      const [err, resp] = await api.master.messageCenter.listAssignments(request, backendRequestTokenRef.current.token);
      if (Axios.isCancel(err)) {
        return null;
      }

      if (err) {
        setLastResult([processApiError(err), null]);
        return null;
      } else {
        setLastResult([null, resp.data]);
        setLoading(false);
        return resp.data;
      }
    },
    [filtersWithValuesMemoized, order, showAllTasks]
  );

  const loadData = useCallback(() => {
    setLoading(true);
    setMoreLoading(false);
    return createRequest(0).then((data) => {
      if (data && data.assignments !== null) {
        setLoading(false);
        setAssignments(data.assignments);
        return data.assignments;
      }
      return [];
    });
  }, [createRequest]);

  useDebounce(loadData, 100, [loadData]);

  useEffect(() => {
    loadData();
  }, [showAllTasks]);

  const loadMore = useSameCallback(() => {
    setLoading(false);
    setMoreLoading(true);
    createRequest(assignments.length).then((data) => {
      if (data && data.assignments !== null) {
        setMoreLoading(false);
        setAssignments((value) => [...value, ...data.assignments]);
      }
    });
  });

  const itemCounts = `${assignments.length}/${lastResponse?.total || 0}`;

  const [createModalVisible, showCreateModal, hideCreateModal] = useBoolean(false);
  const [exportModalVisible, showExportModal, hideExportModal] = useBoolean(false);

  const handleCreateSubmit = (_data: unknown, response: AssignmentDto) => {
    // Select assignment after data is loaded, otherwise the ID does not exist
    loadData().then(() => selectAssignment(response.id));
    hideCreateModal();
  };

  const autoOpenCreateModal = useStoreSelector((state) => state.persistentUi.openCreateAssignmentModal);
  useEffect(() => {
    if (autoOpenCreateModal) {
      showCreateModal();
      dispatch.persistentUi.setOpenCreateAssignmentModal(false);
    }
  }, [autoOpenCreateModal, showCreateModal, dispatch]);

  const loadMoreButton = lastResponse && assignments.length < lastResponse.total && (
    <Button type="primary" onClick={loadMore} loading={moreLoading}>
      <Fmt
        id="ProjectSearchPage.loadMore"
        values={{ count: Math.min(lastResponse?.total - assignments.length, DEFAULT_PAGE_SIZE) }}
      />
    </Button>
  );

  const [filtersToolbarVisible, toggleFiltersToolbar] = useToggle(true);

  const handleExport = useCallback(
    async (exportFileType: ResultFileFormatEnum) => {
      return await api.master.messageCenter.exportAssignments(
        mapAssignmentsDataToDto(assignments, intl, exportFileType)
      );
    },
    [assignments, intl]
  );

  return (
    <>
      <MasterComponent
        url={url}
        maxWidth={800}
        title={intl.formatMessage({ id: 'MessageCenterPage.tabs.assignments' })}
        children={(onClick, selectedItemId) => (
          <StackPanel vertical stretch>
            <Toolbar
              leftContent={
                <Button type="primary" onClick={showCreateModal} icon={<PlusOutlined />}>
                  <Fmt id="MessageCenterAssignments.createAssignment" />
                </Button>
              }
              rightContent={
                <>
                  {canShowAll && (
                    <Space.Compact size="small">
                      <Button ghost={showAllTasks} type="primary" onClick={() => setShowAllTasks(false)}>
                        {intl.formatMessage({ id: 'MessageCenterAssignments.switchButtons.onlyMy' })}
                      </Button>
                      <Button ghost={!showAllTasks} type="primary" onClick={() => setShowAllTasks(true)}>
                        {intl.formatMessage({ id: 'MessageCenterAssignments.switchButtons.showAll' })}
                      </Button>
                    </Space.Compact>
                  )}
                  <Button
                    type="link"
                    icon={<ExportOutlined />}
                    onClick={showExportModal}
                    disabled={!assignments.length}
                  >
                    <Fmt id="general.export" />
                  </Button>
                  <Space.Compact>
                    <OrderSelect orderOptions={ORDER_OPTIONS} order={order} showTooltip {...filterProps} />
                    <FilterToggleButton
                      activeFiltersCount={filtersWithValues.filter((f) => !f.isEmpty(f.value)).length}
                      onClick={toggleFiltersToolbar}
                      isOn={filtersToolbarVisible}
                      showTooltip
                      tooltipDelay={TOOLTIP_MOUSE_ENTER_DELAY}
                    />
                  </Space.Compact>
                </>
              }
            />
            {filtersToolbarVisible && (
              <DocumentsGridHeaderStyled>
                <FilterToolbar filters={filtersWithValues} itemCounts={itemCounts} {...filterProps} />
              </DocumentsGridHeaderStyled>
            )}

            {!!lastError &&
            lastError.referenceErrorCode === ServiceErrorEnum.AssignmentOverviewPermissionNotSetError ? (
              <EmptyStyled
                description={intl.formatMessage({ id: 'serviceError.AssignmentOverviewPermissionNotSetError' })}
              >
                <FlowLayout wrap>
                  {showAllTasks && (
                    <Button type="primary" onClick={() => setShowAllTasks(false)}>
                      {intl.formatMessage({ id: 'MessageCenterAssignments.showOnlyMyAssignments' })}
                    </Button>
                  )}
                  {!!adminInOrganizations?.length && (
                    <Button
                      onClick={() => {
                        const orgId = adminInOrganizations[0].organization.id;
                        history.push(`/organizationsSetting/${orgId || '00000000-0000-0000-0000-000000000000'}/users`);
                      }}
                    >
                      {intl.formatMessage({ id: 'MessageCenterAssignments.goToSettings' })}
                    </Button>
                  )}
                </FlowLayout>
              </EmptyStyled>
            ) : (
              <Margin top inFlex>
                <StackPanel scrollable vertical>
                  <AssignmentList
                    assignments={assignments}
                    loadMore={loadMoreButton}
                    selectedAssignmentId={selectedItemId}
                    selectAssignment={onClick}
                  />
                </StackPanel>
              </Margin>
            )}
          </StackPanel>
        )}
      />

      <Switch>
        <Route
          path={`${path}/:assignmentId/:projectId?`}
          render={(props) => (
            <AssignmentLoader
              key={props.match.params.assignmentId}
              assignmentMessages={assignments}
              listLoading={loading}
              reloadAssignments={loadData}
              {...props}
            />
          )}
        />
      </Switch>
      <AssignmentFormModal
        open={createModalVisible}
        onClose={hideCreateModal}
        onSubmit={handleCreateSubmit}
        intl={intl}
      />
      <CommonExportFormModal
        onSubmit={hideExportModal}
        onClose={hideExportModal}
        visible={exportModalVisible}
        handleExport={handleExport}
        exportEntityType={ExportEntityTypeEnum.assignments}
      />
    </>
  );
};

export const MessageCenterAssignments = memoizeWithIntl(MessageCenterAssignmentsComponent);
