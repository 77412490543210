import { ProjectTemplateGroupDto, ProjectTemplateUserDto } from 'api/completeApiInterfaces';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import React, { FunctionComponent } from 'react';
import uuid from 'uuid';
import { ProjectTemplateGroupForm, ProjectTemplateGroupFormData } from './ProjectTemplateGroupForm';

type Props = FormModalProps<ProjectTemplateGroupDto> & {
  templateUsers: ProjectTemplateUserDto[];
  editedGroup?: ProjectTemplateGroupDto;
  validateUniqueName: (name: string) => boolean;
};

const ProjectGroupsFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, templateUsers, editedGroup, validateUniqueName, ...restProps } = props;

  const handleSubmit: FormSubmitHandler<ProjectTemplateGroupFormData> = async (values) => {
    onSubmit({
      id: editedGroup?.id || uuid(),
      name: values.name,
      description: values.description,
      templateUserIds: values.templaceUserIds || editedGroup?.templateUserIds || [],
      isAdminGroup: editedGroup?.isAdminGroup || false,
    });
    return null;
  };

  const isGroupEditing = !!editedGroup;

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId={isGroupEditing ? 'ProjectTemplateGroupFormModal.edit.title' : 'ProjectTemplateGroupFormModal.add.title'}
      submitTextId={isGroupEditing ? 'general.edit' : 'general.add'}
      width={700}
      {...restProps}
    >
      <ProjectTemplateGroupForm
        defaults={editedGroup}
        templateUsers={templateUsers}
        validateUniqueName={validateUniqueName}
      />
    </FormModalWrapper>
  );
};

export default ProjectGroupsFormModal;
