import { useDispatchEffect, useStoreSelector } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { groupOrderedListSelector } from 'store/selectors/groupSelectors';
import { projectUsersListSelector } from 'store/selectors/projectUsersSelectors';

export const useLoadUsersAndGroups = () => {
  useDispatchEffect((dispatch) => {
    dispatch.projectUsers.loadData({ reload: false });
    dispatch.groups.loadData({ reload: false });
  }, []);

  useDirtyStoreReload(
    (store) => store.groups,
    (dispatch) => dispatch.groups
  );

  useDirtyStoreReload(
    (store) => store.projectUsers,
    (dispatch) => dispatch.projectUsers
  );

  const users = useStoreSelector(projectUsersListSelector);
  const groups = useStoreSelector(groupOrderedListSelector);

  return [users, groups] as const;
};
