import { projectApi } from 'api/completeApi';
import { DirectoryContentDto, JSONVariableTypeEnum, JToken, OrganizationStructureDto } from 'api/completeApiInterfaces';
import { BudgetGrid, SPACE_FOR_SCROLLBAR_COLUMN } from 'components/BudgetGrid/BudgetGrid';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { ContentGate } from 'components/ContentGate/ContentGate';
import DisplayName from 'components/DisplayName';
import { ShortcutIcon } from 'components/Icons/HubEntitiesIcons';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { MinMaxContainer } from 'components/MinMaxContainer/MinMaxContainer';
import RevisionNumberTag from 'components/RevisionNumberTag';
import StackPanel from 'components/StackPanel';
import { Template } from 'devextreme-react';
import { FilterRow, HeaderFilter } from 'devextreme-react/data-grid';
import { dxTreeListColumn } from 'devextreme/ui/tree_list';
import { useActiveProject, useApiData, useIntl, useStoreSelector } from 'hooks';
import { useDocuments } from 'hooks/useDocuments';
import { InjectedIntl } from 'locale';
import { Dictionary } from 'lodash';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { organizationStructureOrderedListSelector } from 'store/selectors/organizationStrucureSelectors';

type Props = {
  directoryId: Guid;
  documentsMetadataDefinitions?: MetadataGridDocumentDefinitionType[];
};

type DocumentsMetadataGridType = { id: Guid; key: Guid; docName: string; isLink: boolean } & { [k: string]: any };

export type ColumnType = dxTreeListColumn & { dataField?: keyof any };

export type MetadataGridDocumentDefinitionType = {
  metadataDefinitionId: Guid;
  definitionName: string;
  variableType: JSONVariableTypeEnum;
};

const getUserName = (structNode: OrganizationStructureDto, userId?: Guid) => {
  if (!userId) return '';
  return structNode?.organizationUsers.find((orgUser) => orgUser.id === userId).appUserProfile.username || '';
};

const getOrgStructureNode = (organizationStructure: OrganizationStructureDto[], structureNodeId?: Guid) => {
  const node = organizationStructure.find((structNode) => structNode.id === structureNodeId);
  if (node) {
    return node;
  } else if (node.children.length) {
    node.children.forEach((node) => {
      return getOrgStructureNode(node.children);
    });
  }
  return null;
};
const parseJsonValue = (
  value: Dictionary<JToken>,
  definitionVariable: JSONVariableTypeEnum,
  organizationStructure: OrganizationStructureDto[],
  intl: InjectedIntl
) => {
  switch (definitionVariable) {
    case JSONVariableTypeEnum.boolean: {
      return value['value'] === 'true'
        ? intl.formatMessage({ id: 'general.yes' })
        : value['value'] === 'false'
        ? intl.formatMessage({ id: 'general.no' })
        : '';
    }
    case JSONVariableTypeEnum.date:
    case JSONVariableTypeEnum.number:
    case JSONVariableTypeEnum.string:
      return value['value'];
    case JSONVariableTypeEnum.orgStructureWithUser: {
      const structureNode = getOrgStructureNode(organizationStructure, value['valueStructureId'] as Guid);
      const userName = structureNode ? getUserName(structureNode, value['valueUserId'] as Guid) : '';
      const nodeSign = !!structureNode?.sign ? `[${structureNode.sign}]` : '';
      const nodeName = !!structureNode?.name ? `- ${structureNode.name}` : '';
      return `${nodeSign} ${nodeName} ${userName}`;
    }
    default:
      return 'Unexpected value/value type';
  }
};

const getColumnProps = (definition: MetadataGridDocumentDefinitionType): ColumnType => {
  switch (definition.variableType) {
    case JSONVariableTypeEnum.boolean:
      return {
        width: 100,
        dataField: definition.definitionName,
        caption: definition.definitionName,
        allowFiltering: false,
        allowHeaderFiltering: true,
        dataType: 'string',
      };
    case JSONVariableTypeEnum.date:
      return {
        width: 100,
        dataField: definition.definitionName,
        caption: definition.definitionName,
        allowHeaderFiltering: false,

        dataType: 'date',
      };
    case JSONVariableTypeEnum.number:
      return {
        width: 100,
        dataField: definition.definitionName,
        caption: definition.definitionName,
        dataType: 'number',
      };
    case JSONVariableTypeEnum.orgStructureWithUser:
      return {
        width: 300,
        dataField: definition.definitionName,
        caption: definition.definitionName,
        dataType: 'string',
      };
    default:
      return {
        width: 200,
        dataField: definition.definitionName,
        caption: definition.definitionName,
        dataType: 'string',
      };
  }
};

const AllDocumentPageMetadataGrid: FunctionComponent<Props> = ({ directoryId, documentsMetadataDefinitions }) => {
  const intl = useIntl();
  const projectId = useActiveProject().id;

  const [metadataValues, metadataValuesError, metadataValuesLoading, loadMetadataValues] = useApiData(
    (ct) => projectApi.directories.primary.id.contentme.get(directoryId, ct),
    { autoload: false }
  );

  useEffect(() => {
    loadMetadataValues();
  }, [directoryId]);

  const [documents, documentLinks, documentsLoading, documentsError, reloadDocuments] = useDocuments(
    directoryId,
    projectId
  );
  const currentProject = useActiveProject();

  const organizationStructure = useStoreSelector(organizationStructureOrderedListSelector);

  const gridData = useMemo(() => {
    if (!documents) return null;

    const docObjects = documents?.map((doc) => {
      const docObject: DocumentsMetadataGridType = {
        id: doc.id,
        key: doc.id,
        docName: doc.name,
        document: doc,
        isLink: false,
        hasDiscardedDocument: false,
      };
      const docMetadataValues = metadataValues?.documentsMetadata[doc.id];
      docMetadataValues?.forEach((value) => {
        docObject[value.definitionName] = parseJsonValue(
          value.value,
          value.definitionVariable,
          organizationStructure,
          intl
        );
      });
      return docObject;
    });
    const docLinksObjects = documentLinks?.map((link) => {
      const docLinkObject: DocumentsMetadataGridType = {
        id: link.linkedDocument?.id || link?.linkedDiscardedDocument.id,
        key: link.linkedDocument?.id || link?.linkedDiscardedDocument.id,
        docName: link.linkedDocument?.name || link?.linkedDiscardedDocument.name,
        document: link?.linkedDocument || link.linkedDiscardedDocument,
        isLink: true,
        hasDiscardedDocument: !!link?.linkedDiscardedDocument,
      };
      const docLinkMetadataValues = metadataValues?.documentsMetadata[link.id];
      docLinkMetadataValues?.forEach((value) => {
        docLinkObject[value.definitionName] = parseJsonValue(
          value.value,
          value.definitionVariable,
          organizationStructure,
          intl
        );
      });
      return docLinkObject;
    });
    return [...(docObjects || []), ...(docLinksObjects || [])];
  }, [documentLinks, documents, intl, metadataValues, organizationStructure]);

  const columns = useMemo((): ColumnType[] => {
    const dynamicColumns: ColumnType[] =
      documentsMetadataDefinitions?.map((definition) => getColumnProps(definition)) || [];
    return [
      {
        width: 200,
        dataField: 'docName',
        caption: intl.formatMessage({ id: 'general.document' }),
        fixed: true,
        allowHiding: false,
        dataType: 'string',
        sortOrder: 'asc',
        cellTemplate: 'documentWithRevisionLink',
      },
      ...dynamicColumns,
      SPACE_FOR_SCROLLBAR_COLUMN,
    ];
  }, [intl, documentsMetadataDefinitions]);

  const renderToDocumentLinkWithRevision = (e: any) => {
    const doc: DirectoryContentDto = e.data.document;
    const isLink: boolean = e.data.isLink;
    const hasDiscardedDocument: boolean = e.data.hasDiscardedDocument;
    return (
      <>
        {doc && (
          <FlowLayout>
            <RevisionNumberTag no={doc.currentRevision?.number} state={doc.currentRevision?.revisionState} />
            {hasDiscardedDocument ? (
              <CommonHubTooltip
                title={intl.formatMessage({ id: 'AllDocumentsPageFileToolbar.favoriteChanged.linkError.NotFound' })}
              >
                <ShortcutIcon />
                <Link to={`/projects/${currentProject.id}/documents/discarded`}>
                  <DisplayName text={doc.name} title={doc.name} />
                </Link>
              </CommonHubTooltip>
            ) : (
              <Link to={`/projects/${currentProject.id}/documents/${doc.id}`}>
                {isLink && <ShortcutIcon />}
                <DisplayName strong text={doc.name} title={doc.name} />
              </Link>
            )}
          </FlowLayout>
        )}
      </>
    );
  };

  return (
    <StackPanel vertical stretch>
      <MinMaxContainer offsetPx={10} itemsCount={gridData?.length}>
        <StackPanel stretch>
          <ContentGate loading={metadataValuesLoading && !documents} error={metadataValuesError}>
            <BudgetGrid data={gridData} columns={columns}>
              <HeaderFilter visible={true} />
              <FilterRow visible={true} />
              <Template name="documentWithRevisionLink" render={renderToDocumentLinkWithRevision} />
            </BudgetGrid>
          </ContentGate>
        </StackPanel>
      </MinMaxContainer>
    </StackPanel>
  );
};

export default React.memo(AllDocumentPageMetadataGrid);
