import produce from 'immer';
import { smartFilter } from 'utils';

export type TreeNode = { id: Guid; children?: TreeNode[] };
export type TreeNodeWithOrder = { id: Guid; children?: TreeNodeWithOrder[]; order: number };
export type TreeNodeWithName = { id: Guid; children?: TreeNodeWithName[]; name?: string };

export const findNodeInTree = <T extends TreeNode>(tree: T[], nodeId: Guid): T | null => {
  for (const node of tree) {
    if (node.id === nodeId) {
      return node;
    }
    if (node.children) {
      const childNode = findNodeInTree(node.children, nodeId);
      if (childNode) {
        return childNode as T;
      }
    }
  }
  return null;
};

export const findTreeNodeParent = <T extends TreeNode>(tree: T[], nodeId: Guid): T | null => {
  for (const node of tree) {
    if (node.id === nodeId) {
      return null;
    }
  }
  return findChildrenTreeNodeParent(tree, nodeId);
};

const findChildrenTreeNodeParent = <T extends TreeNode>(tree: T[], nodeId: Guid): T | null => {
  for (const node of tree) {
    if (node.children) {
      if (node.children.some((child) => child.id === nodeId)) {
        return node;
      }
      const parent = findChildrenTreeNodeParent(node.children, nodeId);
      if (parent) {
        return parent as T;
      }
    }
  }
  return null;
};

export function findInTree<T extends TreeNode>(tree: T[], id: Guid): T | null {
  for (const item of tree) {
    if (item.id === id) {
      return item;
    }
    if (item.children) {
      const found = findInTree(item.children, id);
      if (found) {
        // @ts-ignore
        return found;
      }
    }
  }
  return null;
}

export function findInTreeByFunc<T extends TreeNode>(tree: T[], searchFunction: (item: T) => boolean): T | null {
  for (const item of tree) {
    if (searchFunction(item)) {
      return item;
    }
    if (item.children) {
      const found = findInTreeByFunc(item.children, searchFunction);
      if (found) {
        // @ts-ignore
        return found;
      }
    }
  }
  return null;
}

export function orderTree<T extends TreeNodeWithOrder>(tree: T[]) {
  if (!tree) return [];
  const resultTree: T[] = [...tree];

  resultTree.sort((a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  });

  return resultTree.map((node) => {
    const newNode = { ...node };
    if (newNode.children) {
      newNode.children = orderTree(newNode.children);
    }
    return newNode;
  });
}

export function filterTreeByName<T extends TreeNodeWithName>(tree: T[], search: string): T[] {
  if (!tree) return [];
  return tree
    .map((item) => {
      const foundInChildren = item.children ? filterTreeByName(item.children, search) : [];
      if (smartFilter(item.name, search) || foundInChildren.length > 0) {
        return { ...item, children: foundInChildren };
      } else return null;
    })
    .filter((item) => item !== null);
}

export function moveInTree<T extends TreeNodeWithOrder>(
  tree: T[],
  targetParentNodeId: string,
  sourceParentNodeId: string,
  dragNodeId: string,
  targetIndex: number,
  sourceIndex: number,
  haveSameParent: boolean
): T[] {
  return produce(tree, (draft) => {
    const dragNode = findInTree(draft, dragNodeId);
    if (!dragNode) return;

    const targetParentNode = findInTree(draft, targetParentNodeId)?.children || draft;
    const sourceParentNode = findInTree(draft, sourceParentNodeId)?.children || draft;

    if (haveSameParent) {
      sourceParentNode?.splice(sourceIndex, 1);
      sourceParentNode?.splice(targetIndex > sourceIndex ? targetIndex - 1 : targetIndex, 0, dragNode);

      sourceParentNode.forEach((item, index) => {
        item.order = index + 1;
      });
    } else {
      sourceParentNode?.splice(sourceIndex, 1);
      targetParentNode?.splice(targetIndex, 0, dragNode);
      sourceParentNode.forEach((item, index) => {
        item.order = index + 1;
      });
      targetParentNode.forEach((item, index) => {
        item.order = index + 1;
      });
    }
  });
}

export const moveToTree = <T extends TreeNode>(
  tree: T[],
  sourceItemId: Guid,
  targetItemId: Guid,
  targetIndex: number
): T[] => {
  return produce(tree, (draft) => {
    if (sourceItemId === targetItemId) return;
    const targetNode = findNodeInTree(draft, targetItemId);
    const sourceNode = findNodeInTree(draft, sourceItemId);
    const sourceParentNode = findTreeNodeParent(draft, sourceItemId);

    if (!targetNode || !sourceNode) {
      return;
    }
    if (!targetNode.children) {
      targetNode.children.splice(targetIndex, 0, sourceNode);
    } else {
      targetNode.children = [sourceNode];
    }
    if (sourceParentNode) {
      sourceParentNode.children?.splice(
        sourceParentNode.children.findIndex((node) => node.id === sourceItemId),
        1
      );
    } else if (draft?.length > 0) {
      draft.splice(
        draft.findIndex((node) => node.id === sourceItemId),
        1
      );
    }
  });
};
