import { InputRef } from 'antd';
import { api } from 'api';
import { DocumentCategoryTreeDto } from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import { AxiosResponse } from 'axios';
import { useFocus } from 'hooks/useFocus';
import React, { FunctionComponent } from 'react';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import DocumentCategoryTreeCreateForm, { DocumentCategoryTreeCreateFormData } from './DocumentCategoryTreeCreateForm';

type Props = FormModalProps<DocumentCategoryTreeDto> & {
  validateUniqueName: (name: string) => boolean;
  defaults?: DocumentCategoryTreeDto;
};

const DocumentCategoryTreeCreateFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, validateUniqueName, defaults, ...restProps } = props;

  const handleSubmit: FormSubmitHandler<DocumentCategoryTreeCreateFormData> = async (values) => {
    const data = {
      name: values.name,
      description: values.description,
    };

    let err: ApiError;
    let resp: AxiosResponse<DocumentCategoryTreeDto>;

    if (defaults) {
      // update
      [err, resp] = await api.project.categoryTrees.updateCategoryTreeById(defaults.id, data);
    } else {
      // create
      [err, resp] = await api.project.categoryTrees.createCategoryTree(data);
    }

    if (err) return err;

    await onSubmit(resp.data);
    return null;
  };

  const { setInputRef } = useFocus<InputRef>(restProps.open);

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      title={defaults ? defaults.name : null}
      titleId="DocumentCategoryTreeCreateFormModal.title"
      {...restProps}
    >
      <DocumentCategoryTreeCreateForm
        validateUniqueName={validateUniqueName}
        defaults={defaults}
        setRef={setInputRef}
      />
    </FormModalWrapper>
  );
};

export default DocumentCategoryTreeCreateFormModal;
