import { ExportOutlined } from '@ant-design/icons';
import { Alert, Button, ButtonProps } from 'antd';
import { ProjectUserProfileDto } from 'api/completeApiInterfaces';
import { useBoolean } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent } from 'react';
import AdministrationMetadataFormModal from './AdministrationMetadataFormModal';

type Props = { currentUser: ProjectUserProfileDto } & Pick<ButtonProps, 'type' | 'size' | 'icon'>;

const AdministrationMetadataExportButton: FunctionComponent<Props> = ({ currentUser, ...buttonProps }) => {
  const [isExportVisible, showExportModal, hideExportModal] = useBoolean(false);

  if (!currentUser.isAdmin) {
    return <Alert type="error" message={<Fmt id="general.insufficientPermission" />} />;
  }

  return (
    <>
      <Button type="primary" icon={<ExportOutlined />} onClick={showExportModal} {...buttonProps}>
        <Fmt id="general.export" />
      </Button>
      <AdministrationMetadataFormModal open={isExportVisible} onClose={hideExportModal} onSubmit={hideExportModal} />
    </>
  );
};

export default React.memo(AdministrationMetadataExportButton);
