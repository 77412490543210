import { Col, Row, Typography } from 'antd';
import { MsgCategoryEnum } from 'api/completeApiInterfaces';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import moment, { Moment } from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { parse } from 'url';
import { MessageViewer } from '../MessageDetail';
import MessageViewerDetailGrid from './MessageViewerDetailGrid';
import MessageViewerStyled from './MessageViewerStyled';

type WorkflowNodeMessage = {
  Item1: string;
  Item2: string;
};

type WorkflowNotificationInfo = {
  WorkflowNotificationType: number;
  WorkflowName: string;
  WorkflowNodeName: string;
  WorkflowBackgroundDocumentName: string;
  WorkflowLink: string;
  FinishTime: Moment;
  StartNodeMessages: WorkflowNodeMessage[];
  DelegateMessage: string;
  Subject: string;
  Applicant: string;
  Approved: boolean;
  ByDays: number;
  Message: string;
  NoteText?: string;
};

export type WorkflowMessageData = {
  Language: string;
  ProjectId: Guid;
  ProjectDescription: string;
  WorkflowNotificationInfo: WorkflowNotificationInfo;
};

export const WorkflowMessageViewer: MessageViewer = ({ category, messageData, projectName, organizationName }) => {
  const data = messageData as WorkflowMessageData;
  const intl = useIntl();

  const header = <Fmt id={'MessageCenterPage.message.detail.workflow.title'} />;

  const content = (
    <>
      <Typography.Text>
        <Fmt id={`MessageCenterPage.message.category.${category}`} />
      </Typography.Text>
      <MessageViewerDetailGrid>
        {(category === MsgCategoryEnum.wfDeadlineExtensionApproved ||
          category === MsgCategoryEnum.wfDeadlineExtensionRequestSent ||
          category === MsgCategoryEnum.wfDeadlineExtensionApprovalRequested) && (
          <>
            <Row>
              <Col span={8}>
                <Fmt id={'MessageCenterPage.message.detail.grid.applicant'} />
              </Col>
              <Col span={12}>{data.WorkflowNotificationInfo.Applicant}</Col>
            </Row>
            <Row>
              <Col span={8}>
                <Fmt id={'MessageCenterPage.message.detail.grid.byDays'} />
              </Col>
              <Col span={12}>{data.WorkflowNotificationInfo.ByDays}</Col>
            </Row>
          </>
        )}
        {(category === MsgCategoryEnum.wfDeadlineExtensionApprovalRequested ||
          category === MsgCategoryEnum.wfDeadlineExtensionRequestSent) && (
          <Row>
            <Col span={8}>
              <Fmt id={'MessageCenterPage.message.detail.grid.requestJustification'} />
            </Col>
            <Col span={12}>
              <Typography.Paragraph>{data.WorkflowNotificationInfo.Message}</Typography.Paragraph>
            </Col>
          </Row>
        )}

        {category === MsgCategoryEnum.wfDeadlineExtensionApproved && (
          <>
            <Row>
              <Col span={8}>
                <Fmt id={'MessageCenterPage.message.detail.grid.approved'} />
              </Col>
              <Col span={12}>
                <Fmt id={data.WorkflowNotificationInfo.Approved ? 'general.yes' : 'general.no'} />
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Fmt id={'MessageCenterPage.message.detail.grid.decisionJustification'} />
              </Col>
              <Col span={12}>
                <Typography.Paragraph>{data.WorkflowNotificationInfo.Message}</Typography.Paragraph>
              </Col>
            </Row>
          </>
        )}

        <Row>
          <Col span={8}>
            <Fmt id={'MessageCenterPage.message.detail.grid.organization'} />
          </Col>
          <Col span={12}>{organizationName}</Col>
        </Row>
        <Row>
          <Col span={8}>
            <Fmt id={'MessageCenterPage.message.detail.grid.project'} />
          </Col>
          <Col span={12}>{projectName}</Col>
        </Row>
        <Row>
          <Col span={8}>
            <Fmt id={'MessageCenterPage.message.detail.grid.workflow'} />
          </Col>
          <Col span={12}>
            <Link to={parse(data.WorkflowNotificationInfo.WorkflowLink || '').pathname}>
              <Typography.Link underline>{data.WorkflowNotificationInfo.WorkflowName}</Typography.Link>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Fmt id={'MessageCenterPage.message.detail.grid.task'} />
          </Col>
          <Col span={12}>{data.WorkflowNotificationInfo.WorkflowNodeName}</Col>
        </Row>
        {data.WorkflowNotificationInfo.WorkflowBackgroundDocumentName && (
          <Row>
            <Col span={8}>
              <Fmt id={'MessageCenterPage.message.detail.grid.backgroundDocument'} />
            </Col>
            <Col span={12}>{data.WorkflowNotificationInfo.WorkflowBackgroundDocumentName}</Col>
          </Row>
        )}
        {data.WorkflowNotificationInfo.FinishTime && (
          <Row>
            <Col span={8}>
              <Fmt id={'MessageCenterPage.message.detail.grid.expireTime'} />
            </Col>
            <Col span={12}>
              {moment(data.WorkflowNotificationInfo.FinishTime)
                .locale(intl.locale)
                .format('LLL')}
            </Col>
          </Row>
        )}
        {data.WorkflowNotificationInfo.Subject && (
          <Row>
            <Col span={8}>
              <Fmt id={'MessageCenterPage.message.detail.grid.subject'} />
            </Col>
            <Col span={12}>{data.WorkflowNotificationInfo.Subject}</Col>
          </Row>
        )}
        {data.WorkflowNotificationInfo.DelegateMessage && (
          <Row>
            <Col span={8}>
              <Fmt id={'MessageCenterPage.message.detail.grid.message'} />
            </Col>
            <Col span={12}>{data.WorkflowNotificationInfo.DelegateMessage}</Col>
          </Row>
        )}
        {data.WorkflowNotificationInfo.StartNodeMessages?.length > 0 && (
          <Row>
            <Col span={8}>
              <Fmt id={'MessageCenterPage.message.detail.grid.startNodeMessages'} />
            </Col>
            <Col span={16}>
              <>
                {data.WorkflowNotificationInfo.StartNodeMessages?.map((message, index) => (
                  <Row key={index}>
                    <Col span={12}>{message.Item1}</Col>
                    <Col span={12}>{message.Item2}</Col>
                  </Row>
                ))}
              </>
            </Col>
          </Row>
        )}
        {data.WorkflowNotificationInfo.NoteText && (
          <Row>
            <Col span={8}>
              <Fmt id={'MessageCenterPage.message.detail.grid.noteText'} />
            </Col>
            <Col span={16}>{data.WorkflowNotificationInfo.NoteText}</Col>
          </Row>
        )}
      </MessageViewerDetailGrid>
    </>
  );

  return <MessageViewerStyled header={header} content={content} />;
};
