import { Form, Select, Typography } from 'antd';
import { masterApi } from 'api/completeApi';
import { MdDivisionDto, MdProjectDto } from 'api/completeApiInterfaces';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import { RESERVED_DIVISION_O910 } from 'pages/OrganizationsSettingPage/MDDivisions/MDDivisionListPanel';
import React, { useMemo, useState } from 'react';
import { messageError } from 'utils';
import { requiredRule, simpleSelectFilter } from 'utils/formHelpers';

type Props = FormModalProps<MdProjectDto> & {
  mdProjectId: Guid;
  mdDivisions: MdDivisionDto[];
  mdCurrentDivision: MdDivisionDto;
};

export type MDProjectCardDivisionSetFormData = {
  divisionId: Guid;
};

const MDSetDivisionModal: React.FC<Props> = ({
  mdProjectId,
  mdDivisions,
  onClose,
  onSubmit,
  mdCurrentDivision,
  ...modalProps
}) => {
  const intl = useIntl();
  const [isLoading, setLoading] = useState(false);

  const handleFormSubmit: FormSubmitHandler<MDProjectCardDivisionSetFormData> = async (values) => {
    setLoading(true);
    const [err, res] = await masterApi.projects.md.project.setdivision.post({
      divisionId: values.divisionId,
      projectId: mdProjectId,
    });
    if (err) {
      messageError(err, intl);
      setLoading(false);
      return err;
    }
    onSubmit(res.data);
    setLoading(false);
    onClose();
    return null;
  };

  const divisionOptions = useMemo(() => {
    return mdDivisions
      ?.filter((division) => division.name != RESERVED_DIVISION_O910)
      .map((division) => ({
        label: division.name,
        value: division.id,
      }));
  }, [mdDivisions]);

  return (
    <FormModalWrapper
      title={<Fmt id="MD.Projects.ProjectDivisionSetModal.title" />}
      onSubmit={handleFormSubmit}
      okButtonProps={{ loading: isLoading }}
      onClose={onClose}
      {...modalProps}
    >
      {mdCurrentDivision && (
        <Typography.Paragraph>
          <Fmt
            id="MD.Projects.ProjectDivisionSetModal.division.current.label"
            values={{ division: mdCurrentDivision.name }}
          />
        </Typography.Paragraph>
      )}
      <Form.Item
        label={<Fmt id="MD.Projects.ProjectDivisionSetModal.division.label" />}
        name="divisionId"
        rules={[requiredRule('MD.Projects.ProjectDivisionSetModal.division.required')]}
      >
        <Select options={divisionOptions} showSearch filterOption={simpleSelectFilter} />
      </Form.Item>
    </FormModalWrapper>
  );
};

export default MDSetDivisionModal;
