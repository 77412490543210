import { InputRef } from 'antd';
import { ProjectMetadataValueDto } from 'api/completeApiInterfaces';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper } from 'components/forms/FormModalWrapper';
import { useFocus } from 'hooks/useFocus';
import { InjectedIntlProps } from 'locale';
import React, { FunctionComponent, useCallback } from 'react';
import { MetadataEditForm, MetadataFormData } from './MetadataEditForm';

type Props = FormModalProps<ProjectMetadataValueDto> &
  InjectedIntlProps & {
    metaItemForEdit: ProjectMetadataValueDto;
    open: boolean;
  };

const MetadataEditFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, onClose, metaItemForEdit, ...restProps } = props;

  const handleSubmit = useCallback(
    async (values: MetadataFormData) => {
      const editedEntity: ProjectMetadataValueDto = { ...metaItemForEdit, value: values.data };

      await onSubmit(editedEntity);
      return null;
    },
    [metaItemForEdit, onSubmit]
  );

  const { setInputRef } = useFocus<InputRef>(restProps.open);

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      onClose={onClose}
      title={metaItemForEdit?.definitionName}
      titleId="RoleFormModal.title.create"
      width={600}
      {...restProps}
    >
      <MetadataEditForm metaItemForEdit={metaItemForEdit} setRef={setInputRef} />
    </FormModalWrapper>
  );
};

export default MetadataEditFormModal;
