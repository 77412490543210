import { EditOutlined, FileOutlined, FolderOutlined } from '@ant-design/icons';
import { Alert, Button, Modal, Tag } from 'antd';
import { ProjectTemplateDirectoryValidationMaskDto } from 'api/completeApiInterfaces';
import { DeleteButtonConfirm } from 'components/ActionButtons/DeleteButtonConfirm';
import { ContentGate } from 'components/ContentGate/ContentGate';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { FlowLayout } from 'components/layouts/FlowLayout';
import MaskInput from 'components/MaskInput/MaskInput';
import { MaskItemType } from 'components/MaskInput/MaskInput.types';
import { MaskItemSimpleDisplay } from 'components/MaskInput/MaskItemSimpleDisplay';
import { useIntl } from 'hooks';
import React, { FunctionComponent, useMemo, useState } from 'react';
import uuid from 'uuid';

type Props = {
  onEditDirectoryMasks: (
    projectTemplateDirectoryValidationMask: ProjectTemplateDirectoryValidationMaskDto
  ) => Promise<boolean>;
  projectTemplateDirectoryValidationMask?: ProjectTemplateDirectoryValidationMaskDto;
  inheritedValidationMask?: ProjectTemplateDirectoryValidationMaskDto;
};

export const ProjectTemplateDirectoryMasksInput: FunctionComponent<Props> = ({
  projectTemplateDirectoryValidationMask,
  onEditDirectoryMasks,
  inheritedValidationMask,
}) => {
  const intl = useIntl();

  const subdirectoryMaskItems: MaskItemType[] = useMemo(
    () => (projectTemplateDirectoryValidationMask?.subdirectoryMask?.rules as MaskItemType[]) || [],
    [projectTemplateDirectoryValidationMask]
  );

  const subdirectoriesMasksToShow = useMemo(() => {
    return inheritedValidationMask
      ? (inheritedValidationMask?.subdirectoryMask?.rules as MaskItemType[]) || []
      : subdirectoryMaskItems;
  }, [inheritedValidationMask, subdirectoryMaskItems]);

  const documentMask: MaskItemType[] = useMemo(
    () => (projectTemplateDirectoryValidationMask?.documentMask?.rules as MaskItemType[]) || [],
    [projectTemplateDirectoryValidationMask]
  );

  const documentMasksToShow = inheritedValidationMask
    ? (inheritedValidationMask?.documentMask?.rules as MaskItemType[]) || []
    : documentMask;

  const [showModal, setShowModal] = useState<false | 'documentMask' | 'subdirectoryMask'>(false);

  const handleSaveChanges = async (values: MaskItemType[], modal: false | 'documentMask' | 'subdirectoryMask') => {
    if (!modal) return;

    const dataDto: ProjectTemplateDirectoryValidationMaskDto = {
      id: projectTemplateDirectoryValidationMask?.id || uuid(),
      documentMask:
        modal === 'documentMask'
          ? !!values?.length
            ? { rules: values }
            : null
          : projectTemplateDirectoryValidationMask?.documentMask,
      subdirectoryMask:
        modal === 'subdirectoryMask'
          ? !!values?.length
            ? { rules: values }
            : null
          : projectTemplateDirectoryValidationMask?.subdirectoryMask,
    };

    await onEditDirectoryMasks(dataDto);
    setShowModal(false);
  };

  return (
    <ContentGate>
      {!!inheritedValidationMask?.id ? (
        <>
          {(!!inheritedValidationMask?.documentMask || !!inheritedValidationMask?.subdirectoryMask) && (
            <Alert
              type="warning"
              description={intl.formatMessage({ id: 'folderMasks.inheritedMasksFromParentFolder' })}
            />
          )}
        </>
      ) : (
        <GeneralSettingsItem
          title={intl.formatMessage({ id: 'folderMasks.deleteAndInherit.title' })}
          description={intl.formatMessage({ id: 'folderMasks.deleteAndInherit.description' })}
          input={
            <DeleteButtonConfirm
              type="link"
              confirmTooltip={intl.formatMessage({ id: 'folderMasks.deleteAndInherit.confirm' })}
              onConfirm={() => void onEditDirectoryMasks(undefined)}
              disabled={!projectTemplateDirectoryValidationMask?.id}
            />
          }
        />
      )}
      <GeneralSettingsItem
        icon={<FileOutlined />}
        title={intl.formatMessage({ id: 'folderMasks.fileNameMask' })}
        input={
          <Button
            icon={<EditOutlined />}
            type="link"
            onClick={() => {
              setShowModal('documentMask');
            }}
          />
        }
        description={
          documentMasksToShow?.length ? (
            <FlowLayout wrap>
              {documentMasksToShow.map((item) => (
                <Tag key={item.id}>
                  <MaskItemSimpleDisplay item={item} />
                </Tag>
              ))}
            </FlowLayout>
          ) : (
            intl.formatMessage({ id: 'folderMasks.noMasksSet' })
          )
        }
      />
      <GeneralSettingsItem
        icon={<FolderOutlined />}
        title={intl.formatMessage({ id: 'folderMasks.subfolderNameMask' })}
        input={
          <Button
            icon={<EditOutlined />}
            type="link"
            onClick={() => {
              setShowModal('subdirectoryMask');
            }}
          />
        }
        description={
          subdirectoriesMasksToShow?.length ? (
            <FlowLayout wrap>
              {subdirectoriesMasksToShow.map((item) => (
                <Tag key={item.id}>
                  <MaskItemSimpleDisplay item={item} />
                </Tag>
              ))}
            </FlowLayout>
          ) : (
            intl.formatMessage({ id: 'folderMasks.noMasksSet' })
          )
        }
      />
      <Modal
        title={intl.formatMessage({ id: 'folderMasks.directoryValidationMask' })}
        maskClosable={false}
        width={800}
        footer={null}
        open={!!showModal}
        destroyOnClose
        onCancel={() => setShowModal(false)}
      >
        <MaskInput
          onCancel={() => setShowModal(false)}
          onSubmit={(values: MaskItemType[]) => handleSaveChanges(values, showModal)}
          values={showModal === 'documentMask' ? documentMask : subdirectoryMaskItems}
        />
      </Modal>
    </ContentGate>
  );
};
