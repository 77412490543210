import { Button } from 'antd';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { EditIcon } from 'components/Icons/HubActionsIcons';
import React, { FunctionComponent, ReactNode } from 'react';
import { DisabledWithReason } from 'utils/types';

type Props = {
  onEdit: () => void;
  loading?: boolean;
  disabled?: DisabledWithReason;
  tooltip?: ReactNode;
  className?: string;
};

export const EditButton: FunctionComponent<Props> = ({ onEdit, loading, disabled, tooltip, className }) => {
  return (
    <CommonHubTooltip title={typeof disabled === 'string' ? disabled : tooltip} placement="leftTop">
      <Button
        shape="circle"
        icon={<EditIcon />}
        size="small"
        type="link"
        disabled={!!disabled}
        loading={loading}
        onClick={onEdit}
        className={className}
      />
    </CommonHubTooltip>
  );
};
