import { Form, Input } from 'antd';
import { api } from 'api';
import { GroupListDto, ProjectUserProfileStatusEnum } from 'api/completeApiInterfaces';
import { groupSelectUserFooter, UserTransferFormItem } from 'components/UserTransfer/UserTransfer';
import { useApiData, useIntl } from 'hooks';
import { Dictionary } from 'lodash';
import { AutoSizeType } from 'rc-textarea/lib/ResizableTextArea';
import React, { FC, useEffect, useMemo } from 'react';
import { messageError } from 'utils';
import { textComparer } from 'utils/comparators';

export type AppUserShareFormData = {
  users: Dictionary<Guid>;
  message: string;
};

const AUTOSIZE_OPTIONS: AutoSizeType = { minRows: 3 };

type Props = {
  directoryId: Guid;
  shareDocument: boolean;
};

const AppUserShareFormComponent: FC<Props> = ({ directoryId, shareDocument }) => {
  const intl = useIntl();

  const [accessInfo, accessInfoError, accessInfoLoading, loadAccessInfo] = useApiData((ct) =>
    api.project.sharedDownload.getDirectoryAccessInfo(directoryId, ct)
  );
  useEffect(loadAccessInfo, [directoryId]);

  useEffect(() => {
    if (accessInfoError) {
      messageError(accessInfoError, intl);
    }
  }, [accessInfoError]);

  const users = accessInfo?.users.filter((user) => user.status !== ProjectUserProfileStatusEnum.suspended);

  const groups = useMemo<GroupListDto[]>(() => accessInfo?.groups.sort(textComparer.map((group) => group.name)), [
    accessInfo,
  ]);

  const createFooter = useMemo(() => groupSelectUserFooter(groups, users), [groups, users]);

  return (
    <>
      <Form.Item
        name="users"
        initialValue={[]}
        rules={[{ required: true, message: intl.formatMessage({ id: 'forms.items.rules.noEmpty' }) }]}
        label={intl.formatMessage({ id: 'AppUserShareFormModal.form.users' })}
      >
        <UserTransferFormItem users={users} disabled={accessInfoLoading} createFooter={createFooter} />
      </Form.Item>
      <Form.Item
        initialValue={
          shareDocument
            ? intl.formatMessage({ id: 'AppUserShareFormModal.form.items.message.initialValue.document' })
            : intl.formatMessage({ id: 'AppUserShareFormModal.form.items.message.initialValue.folder' })
        }
        name="message"
        label={intl.formatMessage({ id: 'AppUserShareFormModal.form.items.message.label' })}
      >
        <Input.TextArea rows={3} autoSize={AUTOSIZE_OPTIONS} />
      </Form.Item>
    </>
  );
};

export default AppUserShareFormComponent;
