import { getMasksFromDto } from 'components/MaskInput/getMasksFromDto';
import { MaskItemType } from 'components/MaskInput/MaskInput.types';
import { validateFilenameByMask } from 'components/MaskInput/NameValidatorByMask/validateFilenameByMask';
import { useSelectorDispatch } from 'hooks';
import React, { createContext, FunctionComponent, useCallback, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { directoryConnectedMapSelector } from 'store/selectors';
import { ConnectedDirectory } from 'utils/typeMappings/directories/directoryTypes';

export type NameValidatorByMask = (
  name: string,
  relativePath: string,
  type?: 'file' | 'folder'
) => { isValid: boolean; mask?: MaskItemType[] };

type ValueType = {
  getValidationMaskForDirectory: (directoryId: Guid, type: 'files' | 'folders') => MaskItemType[];
  validateNameByMask: NameValidatorByMask;
  setDestinationDirectoryId: (directoryId: Guid) => void;
};

export const FolderValidationMasksContext = createContext<ValueType>(undefined);

export const useFolderValidationMasksContext = () => {
  const context = useContext(FolderValidationMasksContext);
  if (context === undefined) {
    throw new Error('useFolderValidationMasksContext must be used within a FolderValidationMasksContextProvider');
  }
  return context;
};

function findNearestParentDirectoryForPath(
  path: string,
  directoryMap: Record<string, ConnectedDirectory>
): ConnectedDirectory {
  const directory = Object.values(directoryMap).find((dir) => dir.path === '/' + path);
  if (!!directory) return directory;
  if (path === '') return null;
  return findNearestParentDirectoryForPath(
    path
      .split('/')
      .slice(0, -1)
      .join('/'),
    directoryMap
  );
}

const FolderValidationMasksContextProvider: FunctionComponent = ({ children }) => {
  const directoryMap = useSelector(directoryConnectedMapSelector);

  const organizationLabelsListSelector = (state: RootState) => state.projectDirectoryMasks.data;
  const directoryValidationMasks = useSelectorDispatch(organizationLabelsListSelector, (dispatch) =>
    dispatch.projectUsers.loadData({ reload: false })
  );

  const validationMasks = useMemo(() => {
    return directoryValidationMasks?.directoryValidationMasks || {};
  }, [directoryValidationMasks]);

  const getValidationMaskForDirectory = useCallback(
    (directoryId: string, type: 'files' | 'folders') => {
      if (!validationMasks) return null;
      const masks = validationMasks[directoryId];
      if (!masks) return null;
      return getMasksFromDto(type === 'files' ? masks.documentMask : masks.subdirectoryMask);
    },
    [validationMasks]
  );

  const [destinationDirectoryId, setDestinationDirectoryId] = useState<string | null>(null);
  const destinationDirectory = directoryMap[destinationDirectoryId];

  const validateNameByMask = useCallback(
    (name: string, relativePath: string, type: 'file' | 'folder' = 'file') => {
      if (!destinationDirectory) {
        return { isValid: true };
      }
      const directoryForPath = findNearestParentDirectoryForPath(
        [...destinationDirectory.path.split('/'), ...relativePath.split('/')].filter(Boolean).join('/'),
        directoryMap
      );

      if (!directoryForPath) {
        return { isValid: true };
      }

      const masks = validationMasks?.[directoryForPath.id];

      if (type === 'file' && !!masks) {
        const mask = getMasksFromDto(masks?.documentMask);
        if (!mask) return { isValid: true };
        const nameIsValid = validateFilenameByMask(mask, name, {
          folderName: directoryForPath?.name,
        });
        return { isValid: nameIsValid, mask };
      }

      if (type === 'folder') {
        const relativePathToParentParts = relativePath.split('/').slice(0, -1);
        const directoryForPath = findNearestParentDirectoryForPath(
          [...destinationDirectory.path.split('/'), ...relativePathToParentParts].filter(Boolean).join('/'),
          directoryMap
        );
        const masks = validationMasks?.[directoryForPath.id];
        const mask = getMasksFromDto(masks?.subdirectoryMask);
        if (!mask) return { isValid: true };

        const nameIsValid = validateFilenameByMask(mask, name, {
          folderName: directoryForPath?.name,
        });
        return { isValid: nameIsValid, mask };
      }
      return { isValid: true };
    },
    [destinationDirectory, validationMasks, directoryMap]
  );

  const value = {
    getValidationMaskForDirectory,
    setDestinationDirectoryId,
    validateNameByMask,
  } as const;

  return <FolderValidationMasksContext.Provider value={value}>{children}</FolderValidationMasksContext.Provider>;
};

export default FolderValidationMasksContextProvider;
