import { RoleDto } from 'api/completeApiInterfaces';
import { compact } from 'lodash';

export const isUserInRoleAssociate = (userId: Guid, role: RoleDto) => {
  return role?.roleAssociates?.some((associated) => associated.user.id === userId) || false;
};

export const isUserInRoleMain = (userId: Guid, role: RoleDto) => {
  return role.user?.id === userId;
};

export const isUserInRole = (userId: Guid, role: RoleDto) => {
  return isUserInRoleMain(userId, role) || isUserInRoleAssociate(userId, role);
};

export const isUserInRoles = (userId: Guid, roles: RoleDto[]) => {
  return roles?.some((role) => isUserInRole(userId, role)) || false;
};

export const getRoleUsersIds = (role: RoleDto) => {
  return compact([role?.user.id, ...(role?.roleAssociates?.map((role) => role?.user.id) || [])]);
};

export const getRolesUsersIds = (roles: RoleDto[]) => {
  return roles?.flatMap((role) => getRoleUsersIds(role)) || [];
};
