import { ProjectOutlined } from '@ant-design/icons';
import { List, Tooltip, Typography } from 'antd';
import { masterApi } from 'api/completeApi';
import {
  AssignmentMessageDto,
  AssignmentOrderTypeEnum,
  AssignmentStateEnum,
  LanguageEnum,
  ProjectUserProfileStatusEnum,
  SortOrder,
} from 'api/completeApiInterfaces';
import classNames from 'classnames';
import { AssignmentState } from 'components/AssignmentState/AssignmentState';
import { UserAvatar } from 'components/avatars/UserAvatar/UserAvatar';
import { UserAvatarSize } from 'components/avatars/UserIcon/UserIcon';
import { TOOLTIP_DELAY } from 'components/CommonHubTooltip/CommonHubTooltip';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { ReportWidgetConfiguration } from 'components/Reports/contexts/ReportWidgetsContextProvider';
import StackPanel from 'components/StackPanel';
import { useApiData, useCurrentAppUser, useIntl, useSelectorDispatch } from 'hooks';
import { useDirtyStoreReload, useStoreSelector } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { projectsMapSelector } from 'store/selectors';
import styles from './UserAssignmentStatisticsReport.module.less';

type Props = { configuration: ReportWidgetConfiguration & { type: 'assignmentStatistics' } };

const UserAssignmentStatisticsReport: React.FC<Props> = ({ configuration }) => {
  const intl = useIntl();
  const history = useHistory();
  const projects = useSelectorDispatch(projectsMapSelector, (dispatch) =>
    dispatch.allProjects.loadData({ reload: false })
  );

  const currentAppUser = useCurrentAppUser();

  useDirtyStoreReload(
    (state) => state.allProjects,
    (dispatch) => dispatch.allProjects
  );

  const [userAssignments, userAssignmentsError, userAssignmentsLoading, loadUserNotificaitons] = useApiData((ct) =>
    masterApi.projects.msgcenter.assignments.post({
      from: 0,
      size: 15,
      order: [{ property: AssignmentOrderTypeEnum.deadlineTime, direction: SortOrder.asc }],
      assignmentMsgStateEnumFilter: [
        AssignmentStateEnum.new,
        AssignmentStateEnum.inProgress,
        AssignmentStateEnum.forApproval,
      ],
      viewOrganizationAssignments: false,
      assignmenAppUserFilter: undefined,
    })
  );

  const assignmentStatistics = useStoreSelector((state) => state.userSummary.data?.assigments);

  useEffect(() => {
    loadUserNotificaitons();
  }, [configuration, assignmentStatistics]);

  const renderItem = useCallback(
    (assignment: AssignmentMessageDto) => {
      const isAfterDeadline = moment().isAfter(assignment.deadlineDate);
      const isSolver = assignment.solvers.some((solver) => solver.id === currentAppUser.id);
      const isTaskGiver =
        assignment.taskGiver?.id === currentAppUser.id ||
        assignment.createdBy.id === currentAppUser.id ||
        assignment.associates?.some((associate) => associate.id === currentAppUser.id);

      const userRoles = [
        isTaskGiver && intl.formatMessage({ id: 'AssignmentSourceEnum.taskGiver' }),
        isSolver && intl.formatMessage({ id: 'AssignmentSourceEnum.assignedToUser' }),
      ].filter(Boolean);

      return (
        <List.Item key={assignment.id} className={classNames()}>
          <StackPanel vertical className={classNames(styles.message)}>
            <FlowLayout growFirst>
              <Typography.Text
                ellipsis
                className={styles.link}
                onClick={() =>
                  history.push(
                    `/projects/${assignment.projectId}/messagecenter/assignments/${assignment.assignmentId}/${assignment.projectId}`
                  )
                }
              >
                <span className={styles.order}>#{assignment.orderId}</span>
                {assignment.name}
              </Typography.Text>
              <Tooltip title={<Fmt id="UserAssignmentsStatisticReport.date.tooltip" />}>
                <Typography.Text className={classNames(styles.date, isAfterDeadline && styles.pastDeadline)}>
                  {assignment.deadlineDate &&
                    moment(assignment.deadlineDate)
                      .locale(intl.locale)
                      .format('LL')}
                </Typography.Text>
              </Tooltip>
              <AssignmentState state={assignment.state} />
            </FlowLayout>
            <FlowLayout growFirst>
              <Typography.Text
                ellipsis={{
                  tooltip: {
                    placement: 'topLeft',
                    title: projects?.[assignment.projectId]?.name,
                    mouseEnterDelay: TOOLTIP_DELAY,
                  },
                }}
                onClick={() => history.push(`/projects/${assignment.projectId}`)}
                style={{ cursor: 'pointer' }}
              >
                <ProjectOutlined className={styles.projectIcon} />
                {projects?.[assignment.projectId]?.name}
              </Typography.Text>

              <Tooltip title={<Fmt id="UserAssignmentsStatisticReport.userRole.tooltip" />}>
                {userRoles.join(' / ')}
              </Tooltip>
              <UserAvatar
                title={<Fmt id="general.createdBy" />}
                size={UserAvatarSize.Small}
                user={{
                  ...(assignment.taskGiver || assignment.createdBy),
                  status: ProjectUserProfileStatusEnum.active,
                }}
              />
            </FlowLayout>
          </StackPanel>
        </List.Item>
      );
    },
    [intl, projects, currentAppUser]
  );

  return (
    <ContentGate
      loading={!userAssignments?.assignments && userAssignmentsLoading}
      error={userAssignmentsError}
      empty={!userAssignmentsLoading && !userAssignments?.assignments?.length}
      emptyIntlId="UserAssignmentsStatisticReport.empty"
    >
      {!!userAssignments && (
        <div className={styles.listContainer}>
          <List
            itemLayout="vertical"
            size="small"
            pagination={{
              pageSize: intl.locale === LanguageEnum.de ? 2 : 3,
              hideOnSinglePage: true,
            }}
            dataSource={userAssignments.assignments}
            renderItem={renderItem}
          />
        </div>
      )}
    </ContentGate>
  );
};

export default React.memo(UserAssignmentStatisticsReport);
