import { CheckOutlined, CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Form, Select, Space, Switch, Typography } from 'antd';
import { ProjectListDto } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { useIntl } from 'hooks';
import React, { FC } from 'react';
import { requiredRule, simpleSelectFilter } from 'utils/formHelpers';

export type ProjectTemplateSelectSourceProjectFormData = {
  projectId: Guid;
  tryUserPermissionPairig: boolean;
};

type Props = {
  projectsList: ProjectListDto[];
  organizationId: Guid;
};

const ProjectTemplateSelectSourceProjectFormComponent: FC<Props> = ({ projectsList, organizationId }) => {
  const intl = useIntl();

  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: 'ProjectTemplateSelectSourceProjectModal.projectSelect' })}
        name="projectId"
        rules={[requiredRule('ProjectTemplateSelectSourceProjectModal.error.projectMissing')]}
      >
        <Select showSearch filterOption={simpleSelectFilter}>
          {projectsList
            ?.filter((project) => project.organization.id === organizationId)
            .map(({ id, name }) => (
              <Select.Option key={id} value={id}>
                {name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Space>
        <Form.Item name="tryUserPermissionPairig" initialValue={true} valuePropName="checked">
          <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} size="small" />{' '}
          <Typography.Text>
            {intl.formatMessage({ id: 'ProjectTemplateSelectSourceProjectModal.form.tryPairing' })}
          </Typography.Text>{' '}
          <CommonHubTooltip
            title={intl.formatMessage({ id: 'ProjectTemplateSelectSourceProjectModal.form.tryPairing.tooltip' })}
          >
            <InfoCircleOutlined />
          </CommonHubTooltip>
        </Form.Item>
      </Space>
    </>
  );
};

export const ProjectTemplateSelectSourceProjectForm = React.memo(ProjectTemplateSelectSourceProjectFormComponent);
