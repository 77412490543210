import { MdCkIdentificationEnum } from 'api/completeApiInterfaces';
import {
  createSingleSelectFilterFunction,
  IOption,
  SELECT_CHECK_FORMAT,
  SELECT_CLEARED_VALUE,
  SELECT_DEFAULT_VALUE,
  SELECT_IS_EMPTY,
  SelectFilter,
  SelectFilterValue,
} from 'components/filters/components/SelectFilter/SelectFilter';
import { CommonFilter, FrontendFilter } from 'components/filters/filterTypes';
import { MAP_CK_IDENTIFICATIONS_ENUM_TO_INTL_MESSAGE_ID } from 'components/Reports/MDProjectCard/MDProjectCardCreateForm/MDProjectCreateForm';
import { Fmt, InjectedIntlProps, memoizeWithIntl } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent, useMemo } from 'react';
import { checkObject } from 'utils';

type Props = InjectedIntlProps & {
  value: SelectFilterValue<Guid>;
  onChange: React.Dispatch<React.SetStateAction<SelectFilterValue<Guid>>>;
};

const MdCkIdentificationFilterComponent: FunctionComponent<Props> = ({ intl, value, onChange }) => {
  const identificationOptions = useMemo<IOption<MdCkIdentificationEnum>[]>(
    () =>
      Object.entries(MAP_CK_IDENTIFICATIONS_ENUM_TO_INTL_MESSAGE_ID).map(
        ([identificationKey, identificationIntlId]: [MdCkIdentificationEnum, IntlMessageId]) => ({
          id: identificationKey,
          title: intl.formatMessage({ id: identificationIntlId }),
        })
      ),
    [intl]
  );

  return (
    <SelectFilter
      label={<Fmt id="MD.ProjectVariableEnum.ckIdentificaion.name" />}
      value={value}
      onChange={onChange}
      options={identificationOptions}
    />
  );
};

export const MdCkIdentificationFilter = memoizeWithIntl(MdCkIdentificationFilterComponent);

const createCommonMdCkIdentificationFilter = (
  key: string
): CommonFilter<SelectFilterValue<MdCkIdentificationEnum>> => ({
  key,
  render: (value, setValue) => <MdCkIdentificationFilter value={value} onChange={setValue} />,
  isEmpty: SELECT_IS_EMPTY,
  defaultValue: SELECT_DEFAULT_VALUE([]),
  clearedValue: SELECT_CLEARED_VALUE,
  checkFormat: checkObject(SELECT_CHECK_FORMAT),
});

export const createFrontendMdCkIdentificationFilter = <T,>(
  key: string,
  valueExtractor: (item: T) => MdCkIdentificationEnum
): FrontendFilter<T, SelectFilterValue<MdCkIdentificationEnum>> => ({
  ...createCommonMdCkIdentificationFilter(key),
  filter: createSingleSelectFilterFunction(valueExtractor),
});
