import { PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Modal, Row, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { api } from 'api';
import { AppUserDto, OrganizationAdminReportDto, OrgUserDto, OrgUserRequestDto } from 'api/completeApiInterfaces';
import CommonHubEllipsisText from 'components/CommonHubEllipsisText/CommonHubEllipsisText';
import { DeleteButton } from 'components/DeleteButton/DeleteButton';
import OrganizationUsersAddFormModal from 'components/forms/OrganizationUserAddFormModal/OrganizationUserAddFormModal';
import { Margin } from 'components/Margin/Margin';
import { ReinviteButton } from 'components/ReinviteButton/ReinviteButton';
import { HIDE_BUTTON_PROPS } from 'config/constants';
import { useApiData, useBoolean, useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { messageError } from 'utils';

type Props = {
  onClose: () => void;
  open: boolean;
  organization: OrganizationAdminReportDto;
  loadOrganizationsAdminReport: () => void;
};

export const OrganizationUsersEditModal: FunctionComponent<Props> = ({
  open,
  organization,
  loadOrganizationsAdminReport,
  onClose,
}) => {
  const intl = useIntl();
  const [addUsersAddModalVisible, showUsersAddModal, hideUsersAddModal] = useBoolean(false);
  const [reinvitedUserIds, setReinvitedUserIds] = useState<Guid[]>([]);

  const [appUsers, apptUsersError, appUsersLoading, loadAppUsers] = useApiData(
    (ct) => api.master.organization.getappusers(ct),
    {
      autoload: true,
    }
  );

  const [orgUsers, orgtUsersError, orgUsersLoading, loadOrgUsers] = useApiData(
    (ct) => api.master.organization.getorgusers(organization?.id, ct),
    {
      autoload: true,
    }
  );

  const handleAddModalSubmit = () => {
    hideUsersAddModal();
    loadOrgUsers();
    loadOrganizationsAdminReport();
  };

  const orgAppUsersMails = useMemo(() => orgUsers?.map((user) => user.appUserProfile.username), [orgUsers]);

  const toAddAppUsers: AppUserDto[] = useMemo(() => {
    return appUsers?.filter((appUser) => !orgAppUsersMails?.includes(appUser.username));
  }, [orgAppUsersMails, appUsers]);

  const handleEditUser = async (isAdmin: boolean, user: OrgUserDto) => {
    const userData: OrgUserRequestDto[] = [
      {
        language: user.appUserProfile.language,
        mail: user.appUserProfile.username,
        isAdmin,
        namesFromOrganization: user.namesFromOrganization,
        firstname: user.lastname,
        lastname: user.firstname,
      },
    ];

    const data = { users: userData, organizationId: organization.id };
    const [err] = await api.master.organization.setorgusers(data);
    if (err) return err;
    loadAppUsers();
    loadOrgUsers();
    return null;
  };

  const reinviteOrganizationUser = useCallback(async (appUserId: Guid) => {
    const [err] = await api.master.organization.reinvite({
      appUserId,
      organizationtId: organization.id,
    });
    if (err) {
      messageError(err, intl);
    } else {
      setReinvitedUserIds((prevState) => [...prevState, appUserId]);
    }
  }, []);

  const removeOrganizationUser = useCallback(
    async (orgUserId: Guid) => {
      const [err] = await api.master.organization.deleteOrganizationUser(orgUserId);
      if (err) {
        messageError(err, intl);
      } else {
        loadOrgUsers();
      }
    },

    [loadOrgUsers]
  );

  const isOnlyOneAdmin = useMemo(() => orgUsers?.filter((user) => user.isAdmin).length < 2, [orgUsers]);

  return (
    <>
      <Modal
        title={<Fmt id="OrganizationUsersEditModal.title" />}
        open={open}
        onOk={onClose}
        onCancel={onClose}
        okText={<Fmt id="general.close" />}
        cancelButtonProps={HIDE_BUTTON_PROPS}
        width={800}
      >
        <Button type="primary" onClick={showUsersAddModal} icon={<PlusOutlined />}>
          <Fmt id="Panel.addUser.tooltip" />
        </Button>
        <Margin top>
          {!!orgUsers?.length &&
            orgUsers.map((user) => (
              <div key={user.id}>
                <Row>
                  <Col span={10}>
                    <CommonHubEllipsisText title={user.appUserProfile.username}>
                      {user.appUserProfile.username}
                    </CommonHubEllipsisText>
                  </Col>
                  <Col span={6}>
                    <Checkbox
                      checked={user.isAdmin}
                      onChange={(e: CheckboxChangeEvent) => handleEditUser(e.target.checked, user)}
                      disabled={user.isAdmin && isOnlyOneAdmin}
                    >
                      <Fmt id={'general.administrator'} />
                    </Checkbox>
                  </Col>

                  <Col span={6}>
                    <Space>
                      {!user.appUserProfile.lastAccessDate && (
                        <ReinviteButton
                          disabled={
                            reinvitedUserIds.includes(user.id)
                              ? intl.formatMessage({ id: 'ReinviteButton.reinvited' })
                              : undefined
                          }
                          tooltip={intl.formatMessage({ id: 'ReinviteButton.reinvite.tooltip' })}
                          onReinvite={() => reinviteOrganizationUser(user.appUserProfile.id)}
                          type="default"
                        >
                          {intl.formatMessage({ id: 'ReinviteButton.reinvite' })}
                        </ReinviteButton>
                      )}
                      {!user.inProject && (
                        <DeleteButton
                          disabled={isOnlyOneAdmin ? intl.formatMessage({ id: 'DeleteButton.isLastAdmin' }) : undefined}
                          onDelete={() => removeOrganizationUser(user.id)}
                          type="default"
                          shape="default"
                        >
                          {intl.formatMessage({ id: 'general.delete' })}
                        </DeleteButton>
                      )}
                    </Space>
                  </Col>
                </Row>
              </div>
            ))}
        </Margin>
      </Modal>
      {addUsersAddModalVisible && (
        <OrganizationUsersAddFormModal
          onSubmit={handleAddModalSubmit}
          onClose={hideUsersAddModal}
          open={addUsersAddModalVisible}
          toAddAppUsers={toAddAppUsers}
          organizationId={organization?.id}
          titleId={'AspeHubUsersListPanel.addModal.title'}
        />
      )}
    </>
  );
};
