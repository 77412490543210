import { message } from 'antd';
import { masterApi } from 'api/completeApi';
import {
  MdMeetingApprovalBackgroundDto,
  MdMeetingApprovalResultApplicationDto,
  MdMeetingApprovalResultApplicationProjectDto,
  MdMeetingBackgroundMdMeetingStatusEnum,
} from 'api/completeApiInterfaces';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper } from 'components/forms/FormModalWrapper';
import { MdProjectListWithUnpackedData } from 'components/JSONVariableFormItems/dataUnpackers/mdProjectUnpacker.utils';
import { useApiData, useIntl, useSameCallback, useSelectorDispatch } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent } from 'react';
import MDMeetingResultDistributeForm, { MDMeetingResultDistributeFormData } from './MDMeetingResultDistributeForm';

type Props = {
  organizationId: Guid;
  meetingApproval: MdMeetingApprovalBackgroundDto;
  mdProjectList: MdProjectListWithUnpackedData[];
} & FormModalProps<MdMeetingApprovalResultApplicationDto, MdMeetingApprovalBackgroundDto>;

const MDMeetingResultDistributeFormModal: FunctionComponent<Props> = (props) => {
  const { organizationId, meetingApproval, mdProjectList, onSubmit, ...restProps } = props;
  const intl = useIntl();

  const handleSubmit = useSameCallback(async (values: MDMeetingResultDistributeFormData) => {
    const resultSaveDto: MdMeetingApprovalResultApplicationDto = {
      ckMeetingResultLink: values.ckMeetingResultLink,
      meetingDate: values.meetingDate
        ?.endOf('day')
        .utc()
        .toISOString(),
      mdMeetingApprovalProjectResults: meetingApproval.mdProjectIds.map(
        (projectId): MdMeetingApprovalResultApplicationProjectDto => ({
          projectId,
          ckStatement: values.mdCkStatementsProjectResults[projectId],
          conditions: values.mdCkConditions?.[projectId],
        })
      ),
    };
    const [err, res] = await masterApi.projects.md.approvalmeeting.id.id.applyresults.post(
      organizationId,
      meetingApproval.id,
      resultSaveDto
    );
    if (err) {
      return err;
    }

    void message.success(intl.formatMessage({ id: 'MDMeetingResultModal.message.success' }));
    onSubmit(resultSaveDto, res.data);

    return null;
  });

  const disabled = meetingApproval?.status !== MdMeetingBackgroundMdMeetingStatusEnum.inProcess;

  const organizationCalendar = useSelectorDispatch(
    (state) => state.activeCalendar?.activeCalendar?.orgCalendar,
    async (dispatch) => {
      await dispatch.activeCalendar.loadCalendar({ organizationId: organizationId });
    }
  );

  const [overheadProject, overheadProjectError, overheadProjectLoading] = useApiData(
    (ct) => masterApi.projects.md.settings.id.getoverhead.get(organizationId, ct),
    { autoload: true }
  );

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      title={<Fmt id="MDMeetingResultModal.title" />}
      open={open}
      submitTextId="MDMeetingResultModal.button.submit"
      okButtonProps={{ disabled: disabled }}
      width={800}
      {...restProps}
    >
      <MDMeetingResultDistributeForm
        overheadProjectId={overheadProject?.id}
        overheadProjectLoading={overheadProjectLoading}
        meetingApproval={meetingApproval}
        mdProjectList={mdProjectList}
        organizationCalendar={organizationCalendar}
        disabled={disabled}
      />
    </FormModalWrapper>
  );
};

export default MDMeetingResultDistributeFormModal;
