import { Col, Input, InputProps, Row } from 'antd';

import { JSONVariableTypeEnum } from 'api/completeApiInterfaces';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { JSONVariableString } from '../JSONVariableTypes';

export type JSONVariableInputInvestorContactPersonProps = {
  dataType: JSONVariableTypeEnum.string;
  itemType: 'investorContactPerson';
  value?: JSONVariableString;
  onChange?: (value: JSONVariableString) => void;
} & Omit<InputProps, 'value' | 'onChange'>;

const JSONVariableInputInvestorContactPersonFormItem: FunctionComponent<JSONVariableInputInvestorContactPersonProps> = ({
  dataType,
  value,
  onChange,
  disabled,
}) => {
  const [nameValue, emailValue, phoneValue] = useMemo(() => {
    return value?.value?.split('\n') || ['', '', ''];
  }, [value]);

  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange({ type: dataType, value: e.target.value + '\n' + emailValue + '\n' + phoneValue });
    },
    [dataType, emailValue, onChange, phoneValue]
  );

  const handleEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange({ type: dataType, value: nameValue + '\n' + e.target.value + '\n' + phoneValue });
    },
    [dataType, nameValue, onChange, phoneValue]
  );

  const handlePhoneChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange({ type: dataType, value: nameValue + '\n' + emailValue + '\n' + e.target.value });
    },
    [dataType, emailValue, nameValue, onChange]
  );

  return (
    <>
      <Row key="name">
        <Col flex="100px">
          <Fmt id="general.personName" />
        </Col>
        <Col flex="auto">
          <Input value={nameValue} onChange={handleNameChange} disabled={disabled} />
        </Col>
      </Row>
      <Row key="email">
        <Col flex="100px">
          <Fmt id="general.email" />
        </Col>
        <Col flex="auto">
          <Input value={emailValue} onChange={handleEmailChange} disabled={disabled} />
        </Col>
      </Row>
      <Row key="phone">
        <Col flex="100px">
          <Fmt id="general.phone" />
        </Col>
        <Col flex="auto">
          <Input value={phoneValue} onChange={handlePhoneChange} disabled={disabled} />
        </Col>
      </Row>
    </>
  );
};

export default React.memo(JSONVariableInputInvestorContactPersonFormItem);
