import { Form } from 'antd';
import { ApiPromise } from 'api/await-to';
import { DownloadUrl } from 'api/completeApiInterfaces';
import { CommonDocument, DocumentCompleteListFormItem } from 'components/DocumentCompleteList/DocumentCompleteList';
import React, { FC } from 'react';

export type DiscussionLinkAttachmentFormData = {
  linkedAttachments: Set<Guid>;
};

type Props = {
  availableAttachments: CommonDocument[];
  getOriginalUrl?: (id: Guid) => ApiPromise<DownloadUrl>;
};

export const DiscussionLinkAttachmentForm: FC<Props> = ({ getOriginalUrl, availableAttachments }) => {
  return (
    <Form.Item name="linkedAttachments">
      <DocumentCompleteListFormItem
        documents={availableAttachments}
        disableReserve
        selectItemOnClickEnabled
        hideToolbar
        hideFilters
        disableDownload
        getDocumentUrl={getOriginalUrl}
      />
    </Form.Item>
  );
};
