import { Form, Input, InputRef } from 'antd';
import { apiConstraints } from 'api/completeApiConstraints';
import { ProjectTemplateDirectoryDto } from 'api/completeApiInterfaces';
import { useIntl } from 'hooks';
import { AutoSizeType } from 'rc-textarea/lib/ResizableTextArea';
import React, { FC } from 'react';
import { duplicateNameRuleCallback, maxLengthRule, requiredRule } from 'utils/formHelpers';

export type ProjectTemplateDirectoryFormData = {
  name: string;
  description: string;
};

const DESCRIPTION_AUTOSIZE: AutoSizeType = { minRows: 2 };

type Props = {
  validateUniqueName: (name: string) => boolean;
  autofocusRef: (ref: InputRef) => void;
  defaults?: ProjectTemplateDirectoryDto;
};

const ProjectTemplateDirectoryFormComponent: FC<Props> = ({ validateUniqueName, defaults, autofocusRef }) => {
  const intl = useIntl();

  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.name.label' })}
        name="name"
        initialValue={defaults?.name}
        rules={[
          requiredRule('forms.items.name.rules.required', true),
          maxLengthRule('general.maxNameLength', apiConstraints.projectTemplateDirectoryDto.name.maxLength),
          duplicateNameRuleCallback(validateUniqueName),
        ]}
      >
        <Input
          placeholder={intl.formatMessage({ id: 'DirectoryCreateForm.form.items.name.placeholder' })}
          autoFocus
          ref={autofocusRef}
        />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.description.label' })}
        name="description"
        initialValue={defaults?.description}
        rules={[
          {
            whitespace: true,
            message: intl.formatMessage({ id: 'forms.items.name.rules.empty' }),
          },
          maxLengthRule(
            'general.maxDescriptionLength',
            apiConstraints.projectTemplateDirectoryDto.description.maxLength
          ),
        ]}
      >
        <Input.TextArea
          rows={2}
          autoSize={DESCRIPTION_AUTOSIZE}
          placeholder={intl.formatMessage({ id: 'DirectoryForm.form.items.description.placeholder' })}
        />
      </Form.Item>
    </>
  );
};
export const ProjectTemplateDirectoryForm = React.memo(ProjectTemplateDirectoryFormComponent);
