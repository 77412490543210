import { InputRef, message } from 'antd';
import { masterApi } from 'api/completeApi';
import {
  LanguageEnum,
  MdMeDto,
  MdProjectCreateDto,
  MdProjectDto,
  MdProjectPhaseEnum,
  MdRoleDto,
  MdRoleEnum,
  ProjectCreateFromTempDto,
  ProjectUserRequestDto,
} from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import { useApiData, useCancelToken, useCurrentAppUser, useIntl, useIsMounted, useSameCallback } from 'hooks';
import { useFocus } from 'hooks/useFocus';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import React, { FunctionComponent, useMemo } from 'react';
import { messageError } from 'utils';
import MDProjectCreateForm, { MDProjectCreateFormData } from './MDProjectCreateForm';

type Props = FormModalProps<MdProjectDto> & {
  creator: MdMeDto;
  organizationId: Guid;
  intentionerId: Guid;
  mdRoles: MdRoleDto[];
  initialPhaseValue?: MdProjectPhaseEnum;
  hubProjectNames?: string[];
};

const MDProjectCreateFormModal: FunctionComponent<Props> = (props) => {
  const {
    onSubmit,
    organizationId,
    creator,
    intentionerId,
    mdRoles,
    initialPhaseValue,
    hubProjectNames,
    ...restProps
  } = props;
  const intl = useIntl();
  const ct = useCancelToken('Project create form unloading', []);
  const isMounted = useIsMounted();
  const { setInputRef } = useFocus<InputRef>(restProps.open);
  const currentAppUser = useCurrentAppUser();

  const [projectTemplates, projectTemplatesError, projectTemplatesLoading] = useApiData(
    (ct) => masterApi.projects.tempates.projecttemplatelist.id.get(organizationId, ct),
    { autoload: true }
  );

  useDirtyStoreReload(
    (state) => state.allProjects,
    (dispatch) => dispatch.allProjects
  );

  const currentUserRoles = useMemo(
    () => creator?.mdRoles.filter((role) => role.mdRoleType === MdRoleEnum.proposer) || [],
    [creator]
  );

  const proposerRole = useMemo(
    () =>
      currentUserRoles.reduce((priorityRole, role) => {
        return creator?.mdUsers?.some((user) => user.id === role.head.id) ? role : priorityRole;
      }, currentUserRoles[0]),
    [creator, currentUserRoles]
  );

  const handleSubmit: FormSubmitHandler<MDProjectCreateFormData> = useSameCallback(async (values) => {
    const mdManagerRoles = mdRoles?.filter((role) => role.mdRoleType === MdRoleEnum.md_admin);
    const projectCreateData: ProjectCreateFromTempDto = {
      name: !!values.modifiedName ? values.modifiedName : values.name,
      description: values.description,
      organizationId,
      projectTemplateId: values.templateId,
      storageArea: currentAppUser.organizationUsers.find((ou) => ou.organization.id === organizationId)?.organization
        .defaultStorageArea[0],
      isMdProject: true,
      expandUsers: [
        ...(mdManagerRoles
          ?.flatMap((role) => [role.head, ...(role.mdUsers || [])])
          .map(
            (user): ProjectUserRequestDto => ({
              addAsAdmin: true,
              language: LanguageEnum.cs,
              mail: user.orgUser.appUserProfile.username,
            })
          ) || []),
      ],
    };
    const [projectErr, projectRes] = await masterApi.projects.createfromtemplate.post(projectCreateData, ct);
    if (projectErr) {
      messageError(projectErr, intl);
      return;
    }

    const creatingProjectData: MdProjectCreateDto = {
      organizationId,
      intentionerId,
      projectId: projectRes.data.id,
      mdRoleId: proposerRole.id,
      ckIdentificaion: values.ckIdentification,
      ...values,
    };

    const [err, res] = await masterApi.projects.md.project.create.post(creatingProjectData, ct);
    if (err) {
      messageError(err, intl);
      return;
    }
    if (!isMounted.current) return;

    void message.success(intl.formatMessage({ id: 'MD.Projects.ProjectCreateModal.success' }));
    onSubmit(res.data);

    return null;
  });

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId={'MD.Projects.ProjectCreateModal.title'}
      submitTextId={'general.create'}
      {...restProps}
    >
      <ContentGate loading={projectTemplatesLoading} error={projectTemplatesError}>
        <MDProjectCreateForm
          setRef={setInputRef}
          projectTemplates={projectTemplates}
          initialPhaseValue={initialPhaseValue}
          hubProjectNames={hubProjectNames}
        />
      </ContentGate>
    </FormModalWrapper>
  );
};

export default MDProjectCreateFormModal;
