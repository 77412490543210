import { Form, Input, InputRef } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import Select, { DefaultOptionType } from 'antd/lib/select';
import { apiConstraints } from 'api/completeApiConstraints';
import { MdCkIdentificationEnum, MdProjectPhaseEnum, ProjectTemplateListDto } from 'api/completeApiInterfaces';
import { useIntl, useSelectorDispatch } from 'hooks';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import { compact, uniq } from 'lodash';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { duplicateNameRule, maxLengthRule, minLengthRule, requiredRule } from 'utils/formHelpers';
import styles from './MDProjectCreateForm.module.less';

export type MDProjectCreateFormData = {
  name: string;
  modifiedName: string;
  description?: string;
  requestedPhase: MdProjectPhaseEnum;
  templateId: Guid;
  ckIdentification: MdCkIdentificationEnum;
};

export const MAP_CK_IDENTIFICATIONS_ENUM_TO_INTL_MESSAGE_ID: Record<MdCkIdentificationEnum, IntlMessageId> = {
  [MdCkIdentificationEnum.other]: 'MD.MDcreateProject.ckIdentification.other',
  [MdCkIdentificationEnum.railway]: 'MD.MDcreateProject.ckIdentification.railway',
  [MdCkIdentificationEnum.road]: 'MD.MDcreateProject.ckIdentification.road',
  [MdCkIdentificationEnum.water]: 'MD.MDcreateProject.ckIdentification.water',
};

const DESCRIPTION_ROWS = 3;

type Props = {
  setRef: (ref: InputRef) => void;
  projectTemplates: ProjectTemplateListDto[];
  initialPhaseValue?: MdProjectPhaseEnum;
  hubProjectNames?: string[];
};

const MDProjectCreateForm: FC<Props> = ({ projectTemplates, initialPhaseValue, hubProjectNames, setRef }) => {
  const [isNeededModifiedProjectName, setIsNeededModifiedProjectName] = useState<boolean>(false);
  const intl = useIntl();

  const hubProjectsStore = useSelectorDispatch(
    (state) => state.allProjects.data,
    (dispatch) => dispatch.allProjects.loadData({ reload: false }),
    []
  );

  const uniqueHubProjectNames = useMemo(() => {
    const concatedNames = hubProjectsStore?.projects.map((project) => project.name).concat(hubProjectNames);
    return uniq(concatedNames);
  }, [hubProjectNames, hubProjectsStore]);

  const mdProjectInputName = useWatch('name')?.trim();
  const form = useFormInstance();

  useEffect(() => {
    const identicalName = uniqueHubProjectNames.find((hubProjectName) => hubProjectName === mdProjectInputName) || '';
    if (!!identicalName) {
      setIsNeededModifiedProjectName(true);
      let i = 1;
      let isUnique = false;

      while (!isUnique) {
        const nameOffer = `${identicalName} (${i})`;
        if (!uniqueHubProjectNames.some((hubProjectName) => hubProjectName === nameOffer)) {
          form.setFieldValue('modifiedName', nameOffer);

          isUnique = true;
        }
        i++;
      }
    } else {
      form.setFieldValue('modifiedName', '');
      setIsNeededModifiedProjectName(false);
    }
  }, [uniqueHubProjectNames, mdProjectInputName]);

  const phaseOptions = useMemo(
    (): DefaultOptionType[] =>
      compact([
        { label: intl.formatMessage({ id: 'MD.ProjectPhase.other' }), value: MdProjectPhaseEnum.other },
        { label: intl.formatMessage({ id: 'MD.ProjectPhase.init' }), value: MdProjectPhaseEnum.init },
        { label: intl.formatMessage({ id: 'MD.ProjectPhase.study' }), value: MdProjectPhaseEnum.study },
        { label: intl.formatMessage({ id: 'MD.ProjectPhase.intention' }), value: MdProjectPhaseEnum.intention },
        !!initialPhaseValue
          ? { label: intl.formatMessage({ id: 'MD.ProjectPhase.update' }), value: MdProjectPhaseEnum.update }
          : null,
      ]),
    [intl, initialPhaseValue]
  );

  const templateOptions: DefaultOptionType[] = useMemo(
    () =>
      projectTemplates?.map((template) => ({
        label: template.name,
        value: template.id,
      })) || [],
    [projectTemplates]
  );

  const ckIdentificationsOptions: DefaultOptionType[] = useMemo(
    () =>
      Object.entries(MAP_CK_IDENTIFICATIONS_ENUM_TO_INTL_MESSAGE_ID).map(([enumValue, intlMessageId]) => ({
        label: intl.formatMessage({ id: intlMessageId }),
        value: enumValue,
      })) || [],
    [intl]
  );

  return (
    <>
      <Form.Item
        label={<Fmt id="general.name" />}
        name="name"
        rules={[
          requiredRule('MD.Projects.ProjectCreateModal.form.name.required', true),
          maxLengthRule('general.maxNameLength', apiConstraints.mdProjectListDto.name.maxLength),
          minLengthRule('general.minNameLength', apiConstraints.mdProjectListDto.name.minLength),
        ]}
      >
        <Input />
      </Form.Item>
      {!!isNeededModifiedProjectName && (
        <Form.Item
          label={<Fmt id="MD.Projects.ProjectCreateModal.form.hubProjectName" />}
          name="modifiedName"
          rules={[
            requiredRule('MD.Projects.ProjectCreateModal.form.name.required', true),
            maxLengthRule('general.maxNameLength', apiConstraints.mdProjectListDto.name.maxLength),
            minLengthRule('general.minNameLength', apiConstraints.mdProjectListDto.name.minLength),
            duplicateNameRule('forms.items.name.rules.nameExists', uniqueHubProjectNames, true),
          ]}
        >
          <Input />
        </Form.Item>
      )}
      <Form.Item
        label={<Fmt id="general.description" />}
        name="description"
        rules={[maxLengthRule('general.maxDescriptionLength', apiConstraints.mdProjectListDto.description.maxLength)]}
      >
        <Input.TextArea rows={DESCRIPTION_ROWS} />
      </Form.Item>

      <Form.Item
        label={<Fmt id="MD.Projects.ProjectCreateModal.form.phase" />}
        name="requestedPhase"
        rules={[requiredRule('MD.Projects.ProjectCreateModal.form.phase.required', true)]}
        initialValue={initialPhaseValue}
      >
        <Select options={phaseOptions} disabled={!!initialPhaseValue} className={styles.slelctWidth} />
      </Form.Item>

      <Form.Item
        label={<Fmt id="MD.Projects.ProjectCreateModal.form.template" />}
        name="templateId"
        rules={[requiredRule('MD.Projects.ProjectCreateModal.form.template.required', true)]}
        initialValue={templateOptions?.length === 1 ? templateOptions[0].value : null}
      >
        <Select options={templateOptions} className={styles.slelctWidth} />
      </Form.Item>

      <Form.Item
        label={<Fmt id="MD.Projects.ProjectCreateModal.form.ckIdentification" />}
        name="ckIdentification"
        rules={[requiredRule('MD.Projects.ProjectCreateModal.form.ckIdentification.required', true)]}
      >
        <Select options={ckIdentificationsOptions} allowClear className={styles.slelctWidth} />
      </Form.Item>
    </>
  );
};

export default MDProjectCreateForm;
