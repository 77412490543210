import { useStoreSelector } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Dictionary } from 'lodash';
import React, { FunctionComponent, useMemo } from 'react';
import { categoryListSelector, categoryMapSelector } from 'store/selectors';
import DocumentCategoryForm, { CategoryList } from './DocumentCategoryForm';

type Props = {
  requiredCategories: Dictionary<Guid>;
  autoSelectDefault?: boolean;
  selectPlaceholder?: string;
};

export const DocumentCategoryFormFc: FunctionComponent<Props> = ({ requiredCategories, ...restProps }) => {
  const categoryMap = useStoreSelector(categoryMapSelector);
  const categoryList = useStoreSelector(categoryListSelector);
  const categoryTrees = useStoreSelector((state) => state.categoryTrees);
  // No need to load categories, it is done so in project init

  useDirtyStoreReload(
    (store) => store.categories,
    (dispatch) => dispatch.categories
  );

  const categories = useMemo((): CategoryList => {
    if (!categoryMap) return [];
    return Object.entries(requiredCategories || {}).map(([categoryId, defaultNodeId]) => ({
      categoryId,
      required: !!requiredCategories?.[categoryId], // TODO: this will always be true?
      defaultCategoryNodeId: defaultNodeId,
    }));
  }, [categoryMap, requiredCategories]);

  return (
    categoryMap &&
    categoryList &&
    categoryTrees && (
      <DocumentCategoryForm
        categories={categories}
        categoryList={categoryList}
        categoryMap={categoryMap}
        categoryTrees={categoryTrees}
        {...restProps}
      />
    )
  );
};
