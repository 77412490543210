import { Input } from 'antd';

import { TextAreaProps } from 'antd/lib/input';
import { JSONVariableTypeEnum } from 'api/completeApiInterfaces';
import React, { FunctionComponent, useCallback } from 'react';
import { JSONVariableString } from '../JSONVariableTypes';

export type JSONVariableTextAreaProps = {
  dataType: JSONVariableTypeEnum.string;
  itemType: 'textarea';
  value?: JSONVariableString;
  onChange?: (value: JSONVariableString) => void;
} & Omit<TextAreaProps, 'value' | 'onChange'>;

const JSONVariableTextareaFormItem: FunctionComponent<JSONVariableTextAreaProps> = ({
  dataType,
  value,
  onChange,
  ...restProps
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange && onChange({ type: dataType, value: e.target.value });
    },
    [dataType, onChange]
  );
  return <Input.TextArea onChange={handleChange} value={value?.value} {...restProps} />;
};

export default React.memo(JSONVariableTextareaFormItem);
