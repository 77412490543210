import { Button } from 'antd';
import { apiConstraints } from 'api/completeApiConstraints';
import {
  AccessLevelEnum,
  ProjectMetadataDefinitionMetadataEntityType,
  ProjectTemplateDirectoryDto,
  ProjectTemplateDirectoryMetadataDto,
  ProjectTemplateDirectoryValidationMaskDto,
} from 'api/completeApiInterfaces';
import { AccessLevelListDataSource } from 'components/AccessLevelList/AccessLevelList';
import { DeleteButton, EditButton } from 'components/ActionButtons';
import DirectorySettingsPermissionsDrawer from 'components/DirectorySettingsPermissions/DirectorySettingsPermissionsDrawer';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import GeneralTextSettingsItem from 'components/GeneralTextSettingsItem/GeneralTextSettingsItem';
import List from 'components/List';
import { Margin } from 'components/Margin/Margin';
import StackPanel from 'components/StackPanel/StackPanel';
import { useBoolean } from 'hooks';
import { Fmt, InjectedIntl } from 'locale';
import { uniq } from 'lodash';
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { ProjectTemplateData } from '../../ProjectTemplateBuilder';
import ProjectTemplateDirectoryCategoryFormModal from './ProjectTemplateDirectoryCategoryFormModal';
import styles from './ProjectTemplateDirectoryDetail.module.less';
import { ProjectTemplateDirectoryMasksInput } from './ProjectTemplateDirectoryMasksInput';
import ProjectTemplateDirectoryMetadataSettingModalFormModal from './ProjectTemplateDirectoryMetadataSettingModal';

type Props = {
  intl: InjectedIntl;
  selectedDirectory: ProjectTemplateDirectoryDto;
  directoryMap: Record<Guid, ProjectTemplateDirectoryDto>;
  onDirectoryChange: (directory: ProjectTemplateDirectoryDto) => void;
  projectTemplate: ProjectTemplateData;
};

const getInheritedPermission = (
  groupId: Guid,
  directoryId: Guid,
  directoryMap: Record<Guid, ProjectTemplateDirectoryDto>
): AccessLevelEnum => {
  if (!directoryId) return AccessLevelEnum.none;

  const directoryGroupPermission = directoryMap[directoryId].projectTemplateGroupPermMap[groupId];
  const directoryPermissionInheritance = directoryMap[directoryId].permissionInheritance;

  if (!!directoryGroupPermission) return directoryGroupPermission;
  if (!directoryPermissionInheritance) return AccessLevelEnum.none;
  return getInheritedPermission(groupId, directoryMap[directoryId].parentId, directoryMap);
};

const getInheritedValidationMasks = (
  directoryId: Guid,
  directoryMap: Record<Guid, ProjectTemplateDirectoryDto>
): ProjectTemplateDirectoryValidationMaskDto => {
  const parentId = directoryMap[directoryId]?.parentId;
  if (!directoryId || !parentId) return undefined;

  if (!!directoryMap[parentId]?.projectTemplateDirectoryValidationMask?.id)
    return directoryMap[parentId].projectTemplateDirectoryValidationMask;
  return getInheritedValidationMasks(parentId, directoryMap);
};

const ProjectTemplateDirectoryDetailPanel: FunctionComponent<Props> = ({
  intl,
  selectedDirectory,
  directoryMap,
  projectTemplate,
  onDirectoryChange,
}) => {
  const [isCategoryModalVisible, showCategoryModal, hideCategoryModal] = useBoolean();
  const [isMetadataSettingModalVisible, showMetadataSettingModal, hideMetadataSettingModal] = useBoolean();
  const [inheritedValidationMask, setInheritedValidationMask] = useState<ProjectTemplateDirectoryValidationMaskDto>();
  const [editedCategoryNodeId, setEditedCategoryNodeId] = useState<Guid>();
  const handleSetPermissionsInheritance = useCallback(
    (inheritPermissions: boolean) => {
      onDirectoryChange({ ...selectedDirectory, permissionInheritance: inheritPermissions });
    },
    [selectedDirectory, onDirectoryChange]
  );

  useEffect(() => {
    if (!selectedDirectory.projectTemplateDirectoryValidationMask?.id && !!selectedDirectory?.parentId) {
      setInheritedValidationMask(getInheritedValidationMasks(selectedDirectory.id, directoryMap));
    } else {
      setInheritedValidationMask(undefined);
    }
  }, [selectedDirectory, directoryMap]);

  const handleChangeGroupAccess = useCallback(
    (accessLevel: AccessLevelEnum, groupId: Guid) => {
      onDirectoryChange({
        ...selectedDirectory,
        projectTemplateGroupPermMap: { ...selectedDirectory.projectTemplateGroupPermMap, [groupId]: accessLevel },
      });
    },
    [onDirectoryChange, selectedDirectory]
  );

  const handleRemoveGroup = useCallback(
    (groupId: Guid) => {
      const directoryGroupPermissions = { ...selectedDirectory.projectTemplateGroupPermMap };
      delete directoryGroupPermissions[groupId];
      onDirectoryChange({ ...selectedDirectory, projectTemplateGroupPermMap: directoryGroupPermissions });
    },
    [onDirectoryChange, selectedDirectory]
  );

  const showCategoryEdit = useCallback((categoryNodeId: Guid) => {
    setEditedCategoryNodeId(categoryNodeId);
    showCategoryModal();
  }, []);

  const showCategoryAdd = useCallback(() => {
    setEditedCategoryNodeId(undefined);
    showCategoryModal();
  }, []);

  const handleCategorySave = useCallback(
    (categoryNodeId: Guid) => {
      if (!!editedCategoryNodeId) {
        onDirectoryChange({
          ...selectedDirectory,
          projectTemplateDirectoryCategoryNodeIds: uniq([
            ...selectedDirectory.projectTemplateDirectoryCategoryNodeIds.filter(
              (nodeId) => nodeId !== editedCategoryNodeId
            ),
            categoryNodeId,
          ]),
        });
      } else {
        onDirectoryChange({
          ...selectedDirectory,
          projectTemplateDirectoryCategoryNodeIds: uniq([
            ...selectedDirectory.projectTemplateDirectoryCategoryNodeIds,
            categoryNodeId,
          ]),
        });
      }
      hideCategoryModal();
    },
    [hideCategoryModal, onDirectoryChange, selectedDirectory, editedCategoryNodeId]
  );

  const handleCategoryRemove = useCallback(
    (removedId: Guid) => {
      onDirectoryChange({
        ...selectedDirectory,
        projectTemplateDirectoryCategoryNodeIds: selectedDirectory.projectTemplateDirectoryCategoryNodeIds.filter(
          (nodeId) => nodeId !== removedId
        ),
      });
    },
    [selectedDirectory, onDirectoryChange]
  );

  const handleEditMetadataValues = useCallback(
    (projectTemplateDirectoryMetadatas: ProjectTemplateDirectoryMetadataDto[]) => {
      onDirectoryChange({
        ...selectedDirectory,
        projectTemplateDirectoryMetadatas,
      });
      hideMetadataSettingModal();
    },
    [selectedDirectory]
  );

  const groupsAccessForDisplay = useMemo((): AccessLevelListDataSource[] => {
    return projectTemplate.projectTemplateGroups.map((group) => {
      const groupAccessLevel = selectedDirectory.projectTemplateGroupPermMap[group.id];

      const inheritedPermission = group.isAdminGroup
        ? AccessLevelEnum.admin
        : getInheritedPermission(group.id, selectedDirectory.id, directoryMap);
      const currentAccessLevel = !!selectedDirectory.projectTemplateGroupPermMap[group.id]
        ? selectedDirectory.projectTemplateGroupPermMap[group.id]
        : selectedDirectory.permissionInheritance && inheritedPermission
        ? inheritedPermission
        : AccessLevelEnum.none;

      return {
        id: group.id,
        edited: false,
        key: group.id,
        disabled: group.isAdminGroup,
        label: group.name,
        selfAccessLevel: groupAccessLevel,
        accessLevel: currentAccessLevel,
        inherited: !groupAccessLevel && selectedDirectory.permissionInheritance,
      };
    });
  }, [directoryMap, projectTemplate.projectTemplateGroups, selectedDirectory]);

  const appliedCategories = useMemo(() => {
    const activeCategoryNodes = projectTemplate.projectTemplateCategoryNodes.filter((node) =>
      selectedDirectory.projectTemplateDirectoryCategoryNodeIds.some((categoryNode) => categoryNode === node.id)
    );

    return activeCategoryNodes.map(
      (node) => ({
        category: projectTemplate.projectTemplateCategoryTrees.find((tree) => tree.id === node.templateCategoryTreeId)
          .name,
        node: node.name,
        id: node.id,
        categoryId: node.templateCategoryTreeId,
      }),
      {} as Record<string, string>
    );
  }, [
    projectTemplate.projectTemplateCategoryNodes,
    projectTemplate.projectTemplateCategoryTrees,
    selectedDirectory.projectTemplateDirectoryCategoryNodeIds,
  ]);

  const usedTrees = useMemo(
    () =>
      new Set<Guid>(
        projectTemplate.projectTemplateCategoryNodes
          .filter((node) =>
            selectedDirectory.projectTemplateDirectoryCategoryNodeIds.some(
              (selectedNodeId) => selectedNodeId === node.id
            )
          )
          .map((node) => node.templateCategoryTreeId)
      ),
    [projectTemplate.projectTemplateCategoryNodes, selectedDirectory.projectTemplateDirectoryCategoryNodeIds]
  );

  const onEditDirectoryName = useCallback(
    async (name: string): Promise<boolean | ReactNode> => {
      onDirectoryChange({ ...selectedDirectory, name });
      return true;
    },
    [onDirectoryChange, selectedDirectory]
  );

  const onEditDirectoryDescription = useCallback(
    async (description: string): Promise<boolean | ReactNode> => {
      onDirectoryChange({ ...selectedDirectory, description });
      return true;
    },
    [onDirectoryChange, selectedDirectory]
  );

  const onEditDirectoryMasks = useCallback(
    async (projectTemplateDirectoryValidationMask: ProjectTemplateDirectoryValidationMaskDto) => {
      setInheritedValidationMask(undefined);
      onDirectoryChange({ ...selectedDirectory, projectTemplateDirectoryValidationMask });
      return true;
    },
    [onDirectoryChange, selectedDirectory]
  );

  const onDeleteMetadataValues = useCallback(async () => {
    onDirectoryChange({ ...selectedDirectory, projectTemplateDirectoryMetadatas: [] });
    return true;
  }, [onDirectoryChange, selectedDirectory]);

  const directoryMetadata = useMemo(() => {
    return projectTemplate.projectTemplateMetadataDefinitions.filter(
      (def) => def.entityType === ProjectMetadataDefinitionMetadataEntityType.directory
    );
  }, [projectTemplate]);

  const hasSomeDirectoryMetadataDefinitions = !!directoryMetadata?.length;

  return (
    <>
      <StackPanel vertical scrollable className={styles.wrapper}>
        <Margin bottom>
          <GeneralSettingsContainer itemsLargeGap>
            <GeneralSettingsContainer>
              <GeneralTextSettingsItem
                value={selectedDirectory.name}
                onSave={onEditDirectoryName}
                disableEdit={false}
                headline={<Fmt id="general.name" />}
                maxLength={apiConstraints.projectPatchDto.name.maxLength}
              />
              <GeneralTextSettingsItem
                value={selectedDirectory.description}
                onSave={onEditDirectoryDescription}
                disableEdit={false}
                headline={<Fmt id="general.description" />}
                maxLength={apiConstraints.projectPatchDto.name.maxLength}
              />
            </GeneralSettingsContainer>

            <GeneralSettingsContainer
              title={intl.formatMessage({ id: 'DirectorySettingsForm.requiredCategories.label' })}
            >
              <div>
                <List
                  data={appliedCategories}
                  renderItem={(item) => (
                    <GeneralSettingsItem
                      key={item.id}
                      title={
                        <>
                          {item.category}: {item.node}
                        </>
                      }
                      wrap
                      additionalActions={
                        <>
                          <EditButton onClick={() => showCategoryEdit(item.id)} />
                          <DeleteButton onClick={() => handleCategoryRemove(item.id)} />
                        </>
                      }
                    />
                  )}
                  renderEmpty={() => <Fmt id="ProjectTemplateDirectoryDetail.noCategories" />}
                />
              </div>
              <Button onClick={showCategoryAdd} type="primary">
                <Fmt id="ProjectTemplateDirectoryDetail.button.addCategory" />
              </Button>
            </GeneralSettingsContainer>

            <GeneralSettingsContainer title={intl.formatMessage({ id: 'general.permission' })}>
              <DirectorySettingsPermissionsDrawer
                inheritPermissions={selectedDirectory.permissionInheritance}
                intl={intl}
                groupsAccessForDisplay={groupsAccessForDisplay}
                handleSetPermissionsInheritance={handleSetPermissionsInheritance}
                directoryId={selectedDirectory.id}
                handleChangeGroupAccess={handleChangeGroupAccess}
                handleRemoveGroup={handleRemoveGroup}
                showIndividualUserAccessSettings={false}
              />
            </GeneralSettingsContainer>

            <GeneralSettingsContainer
              title={intl.formatMessage({ id: 'folderMasks.directoryValidationMask' })}
              description={intl.formatMessage({ id: 'folderMasks.directoryValidationMask.description' })}
            >
              <ProjectTemplateDirectoryMasksInput
                projectTemplateDirectoryValidationMask={selectedDirectory?.projectTemplateDirectoryValidationMask}
                onEditDirectoryMasks={onEditDirectoryMasks}
                inheritedValidationMask={inheritedValidationMask}
              />
            </GeneralSettingsContainer>

            {hasSomeDirectoryMetadataDefinitions && (
              <GeneralSettingsContainer
                title={intl.formatMessage({
                  id: 'ProjectTemplateMetadataDefinitionsSettingModal.metadataValues.title',
                })}
              >
                <GeneralSettingsItem
                  key="metadataSetting"
                  title={intl.formatMessage({
                    id: 'ProjectTemplateMetadataDefinitionsSettingModal.metadataValues.title',
                  })}
                  wrap
                  additionalActions={
                    <>
                      <EditButton
                        onClick={() => {
                          showMetadataSettingModal();
                        }}
                      />
                      {!!selectedDirectory.projectTemplateDirectoryMetadatas?.length && (
                        <DeleteButton onClick={onDeleteMetadataValues} />
                      )}
                    </>
                  }
                />
              </GeneralSettingsContainer>
            )}
          </GeneralSettingsContainer>
        </Margin>
      </StackPanel>
      {isCategoryModalVisible && (
        <ProjectTemplateDirectoryCategoryFormModal
          projectTemplate={projectTemplate}
          onSubmit={handleCategorySave}
          open={isCategoryModalVisible}
          editedCategoryNodeId={editedCategoryNodeId}
          usedTrees={usedTrees}
          onClose={hideCategoryModal}
        />
      )}
      <ProjectTemplateDirectoryMetadataSettingModalFormModal
        onSubmit={handleEditMetadataValues}
        onClose={hideMetadataSettingModal}
        open={isMetadataSettingModalVisible}
        editedDirectory={selectedDirectory}
        directoryMetadataDefinitions={directoryMetadata}
      />
    </>
  );
};

export default ProjectTemplateDirectoryDetailPanel;
