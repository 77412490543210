import { Tag, Typography } from 'antd';
import {
  DirectoryDetailDto,
  JSONVariableTypeEnum,
  JToken,
  ProjectMetadataValueDto,
  ServiceError,
} from 'api/completeApiInterfaces';
import { EditButton } from 'components/ActionButtons';
import { DeleteButtonConfirm } from 'components/ActionButtons/DeleteButtonConfirm';
import { ContentGate } from 'components/ContentGate/ContentGate';
import DocumentsGridHeader from 'components/DocumentsGridHeader/DocumentsGridHeader';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { getStructureById } from 'components/JSONVariableFormItems/Items/JSONVariableOrganizationStructureWithUserFormItem';
import { JSONVariable } from 'components/JSONVariableFormItems/JSONVariableTypes';
import List from 'components/List/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import { MinMaxContainer } from 'components/MinMaxContainer/MinMaxContainer';
import StackPanel from 'components/StackPanel';
import { FiltersPersistentKey, FrontendFilter } from 'components/filters/filterTypes';
import { FrontendOrderOption } from 'components/filters/orderTypes';
import { FilterPreset } from 'components/filters/render/FilterPreset/FilterPreset';
import { FilterToolbar } from 'components/filters/render/FilterToolbar/FilterToolbar';
import { OrderSelect } from 'components/filters/render/OrderSelect/OrderSelect';
import { useFrontendFilters, useIntl, useStoreSelector } from 'hooks';
import { Fmt } from 'locale';
import { Dictionary } from 'lodash';
import moment from 'moment';
import { getMetadataVariableTypeText } from 'pages/OrganizationsSettingPage/ProjectTemplate/Tabs/General/MetadataUtils';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { organizationStructureOrderedListSelector } from 'store/selectors/organizationStrucureSelectors';
import { textComparer } from 'utils/comparators';
import { BooleanOption, createFrontendBooleanFilter } from '../RadioFilter/variants/BooleanFilter/BooleanFilter';
import { createFrontendMultiTextFilter } from '../TextFilter/TextFilter';
import MetadataEditFormModal from './MetadataEditFormModal';
import { MetadataExportImportButton } from './MetadataExportImportButton';

export const METADATA_ORDER_OPTIONS: FrontendOrderOption<ProjectMetadataValueDto>[] = [
  {
    key: 'name',
    label: <Fmt id="SearchSortTypeItem.name" />,
    compare: textComparer.map((metadataItem) => metadataItem.definitionName),
  },
];

const hasJSONVariableValue = (variable: JSONVariable) =>
  !!variable &&
  Object.entries(variable)
    .filter(([key, value]) => key !== 'type')
    .some(([key, value]) => !!value);

export const METADATA_FILTERS: FrontendFilter<ProjectMetadataValueDto>[] = [
  createFrontendMultiTextFilter('name', (metadataItem) => [metadataItem.definitionName], {
    label: <Fmt id="general.name" />,
  }),
  createFrontendBooleanFilter(
    'hasValue',
    {
      label: <Fmt id="BooleanFilter.hasValue" />,
      title: <Fmt id="BooleanFilter.hasValue.description" />,
      trueLabel: <Fmt id="BooleanFilter.hasValue.yes" />,
      falseLabel: <Fmt id="BooleanFilter.hasValue.no" />,
    },
    (metadataItem) => (!!metadataItem.value ? BooleanOption.True : BooleanOption.False)
  ),
];

type Props = {
  metadataItems: ProjectMetadataValueDto[];
  metadataLoading: boolean;
  metadataError: ServiceError;
  handleSave: (value: Dictionary<JToken>, id: Guid) => Promise<React.ReactNode>;
  persistentKey: FiltersPersistentKey;
  canEdit?: boolean;
  currentDirectory?: DirectoryDetailDto;
  loadDirectoryMetadata?: () => void;
};

export const MetadataEditList: FunctionComponent<Props> = React.memo(
  ({
    metadataItems,
    metadataLoading,
    metadataError,
    handleSave,
    loadDirectoryMetadata,
    persistentKey,
    canEdit,
    currentDirectory,
  }) => {
    const intl = useIntl();
    const organizationStructure = useStoreSelector(organizationStructureOrderedListSelector);

    const { orderedItems, ...filterProps } = useFrontendFilters(
      METADATA_FILTERS,
      METADATA_ORDER_OPTIONS,
      metadataItems,
      persistentKey
    );

    const [metaItemForEdit, setMetaItemForEdit] = useState<ProjectMetadataValueDto>();

    const onEdit = useCallback(
      (data: ProjectMetadataValueDto) => {
        void handleSave(data.value, data.metadataDefinitionId);
        setMetaItemForEdit(undefined);
      },
      [handleSave]
    );

    const valueTypeText = useCallback(
      (valueType: JSONVariableTypeEnum) => {
        return getMetadataVariableTypeText(valueType, intl);
      },
      [intl]
    );

    const valueToShow = useCallback(
      (metaItem: ProjectMetadataValueDto) => {
        if (metaItem.definitionVariable === JSONVariableTypeEnum.orgStructureWithUser) {
          if (!metaItem?.value?.valueStructureId || !organizationStructure) return null;
          const orgStructure = getStructureById(metaItem.value?.valueStructureId as Guid, organizationStructure);
          if (!orgStructure) return null;
          const userName =
            orgStructure?.organizationUsers?.find((user) => user.id === (metaItem.value?.valueUserId as Guid))
              ?.appUserProfile?.username || '';
          return `${orgStructure?.sign}-${orgStructure?.name}  ${userName}`;
        }

        if (!metaItem?.value?.value) return null;
        if (metaItem.definitionVariable === JSONVariableTypeEnum.date)
          return moment(metaItem.value.value)
            .locale(intl.locale)
            .format('L');
        if (
          metaItem.definitionVariable === JSONVariableTypeEnum.number ||
          metaItem.definitionVariable === JSONVariableTypeEnum.string
        )
          return metaItem.value.value;
        else {
          return <Fmt id={metaItem.value.value === 'true' ? 'general.yes' : 'general.no'} />;
        }
      },
      [intl, organizationStructure]
    );

    return (
      <>
        <MinMaxContainer
          offsetPx={10}
          itemsCount={orderedItems?.length}
          header={
            <div style={{ padding: '0.5rem 0 0.25rem 0' }}>
              <DocumentsGridHeader
                disableSelect
                order={<OrderSelect {...filterProps} />}
                filters={<FilterToolbar {...filterProps} />}
                additionalContent={
                  <>
                    {!!currentDirectory && !!loadDirectoryMetadata ? (
                      <MetadataExportImportButton
                        currentDirectory={currentDirectory}
                        orderedItems={orderedItems}
                        metadataLoading={metadataLoading}
                        canEdit={canEdit}
                        loadDirectoryMetadata={loadDirectoryMetadata}
                      />
                    ) : null}
                    <FilterPreset filterKey={persistentKey} {...filterProps} />
                  </>
                }
              />
            </div>
          }
        >
          <ContentGate loading={metadataLoading} empty={!orderedItems.length} error={metadataError}>
            <StackPanel vertical scrollable>
              <GeneralSettingsContainer>
                <List<ProjectMetadataValueDto>
                  data={orderedItems}
                  renderItem={(item) => (
                    <GeneralSettingsItem
                      key={item.metadataDefinitionId}
                      title={
                        <>
                          <Typography.Text strong>{item.definitionName}</Typography.Text>{' '}
                          <Tag>{valueTypeText(item.definitionVariable)}</Tag>
                        </>
                      }
                      description={valueToShow(item)}
                      wrap
                      disabled={!canEdit}
                      additionalActions={
                        <>
                          <EditButton onClick={() => setMetaItemForEdit(item)} />
                          {hasJSONVariableValue(item.value as JSONVariable) && (
                            <DeleteButtonConfirm onConfirm={() => handleSave(null, item.metadataDefinitionId)} />
                          )}
                        </>
                      }
                    />
                  )}
                  renderEmpty={(total, filtered) => <ListEmpty filtered={filtered} total={total} />}
                />
              </GeneralSettingsContainer>
            </StackPanel>
          </ContentGate>
        </MinMaxContainer>
        {
          <MetadataEditFormModal
            onSubmit={onEdit}
            onClose={() => setMetaItemForEdit(undefined)}
            open={!!metaItemForEdit}
            metaItemForEdit={metaItemForEdit}
            intl={intl}
          />
        }
      </>
    );
  }
);
