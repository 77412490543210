import { masterApi } from 'api/completeApi';
import { OrganizationStructureDto } from 'api/completeApiInterfaces';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import ServiceErrorBox from 'components/ServiceErrorBox';
import StackPanel from 'components/StackPanel';
import { useApiData, useBoolean, useIntl } from 'hooks';
import Panel from 'pages/ProjectSettingsPage/Panel';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { OrganizationStructureFormModal } from './OrganizationStructureFormModal';
import OrganizationStructureSettingTree from './OrganizationStructureSettingTree';

type Props = {
  selectedOrganizationId: string;
};

const deleteOrgUsers = (orgStructure: OrganizationStructureDto[]): OrganizationStructureDto[] => {
  return orgStructure.map((struct) => ({
    ...struct,
    organizationUsers: undefined,
    children: deleteOrgUsers(struct.children || []),
  }));
};

export const OrganizationStructurePanel: FC<Props> = ({ selectedOrganizationId }) => {
  const [
    organizationStructure,
    organizationStructureError,
    organizationStructureLoading,
    loadOrganizationStructure,
    setOrganizationStructure,
  ] = useApiData((ct) => masterApi.projects.reports.organizationstructure.id.get(selectedOrganizationId, ct), {
    autoload: false,
  });
  useEffect(() => {
    loadOrganizationStructure();
  }, [selectedOrganizationId]);

  const [formModalVisible, showFormModal, hideFormModal] = useBoolean(false);
  const [selectedStructureNode, setSelectedStructureNode] = useState<OrganizationStructureDto>();

  const { url } = useRouteMatch();
  const intl = useIntl();

  const organizationStructureWithoutOrgUsers = useMemo(() => {
    return { organizationStructure: deleteOrgUsers(organizationStructure?.organizationStructure || []) };
  }, [organizationStructure]);

  const renderContent = () => {
    if (organizationStructureError) return <ServiceErrorBox error={organizationStructureError} />;
    if (organizationStructureLoading) return null;

    return (
      <GeneralSettingsContainer title={intl.formatMessage({ id: 'general.organizationStructure' })}>
        <OrganizationStructureSettingTree
          organizationstructure={organizationStructureWithoutOrgUsers}
          loadOrganizationStructure={loadOrganizationStructure}
          selectedStructureNode={selectedStructureNode}
          setSelectedStructureNode={setSelectedStructureNode}
          organizationId={selectedOrganizationId}
          setOrganizationStructure={setOrganizationStructure}
        />
      </GeneralSettingsContainer>
    );
  };

  return (
    <>
      <MasterComponent
        maxWidth={600}
        url={url}
        children={(onSelect, selectedItemId) => (
          <StackPanel>
            {!!organizationStructure && (
              <Panel
                noMargin
                addButtonText={intl.formatMessage({ id: 'general.add' })}
                addButtonOnClick={
                  !organizationStructure?.organizationStructure.length
                    ? () => {
                        showFormModal();
                        setSelectedStructureNode(undefined);
                      }
                    : undefined
                }
              >
                {renderContent()}
              </Panel>
            )}
          </StackPanel>
        )}
      />
      {formModalVisible && (
        <OrganizationStructureFormModal
          onSubmit={hideFormModal}
          onClose={hideFormModal}
          open={formModalVisible}
          selectedStructureNode={selectedStructureNode}
          organizationStructure={organizationStructure}
          organizationId={selectedOrganizationId}
          setOrganizationStructure={setOrganizationStructure}
        />
      )}
    </>
  );
};
