import { Form, InputNumber, InputNumberProps, Space } from 'antd';
import { JSONVariableTypeEnum } from 'api/completeApiInterfaces';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { useCallback } from 'react';
import { getDecimalSeparator } from 'utils/getDecimalSeparator';
import { JSONVariableNumberDiff } from '../JSONVariableTypes';

export type JSONVariableInputNumberDiffProps = {
  dataType: JSONVariableTypeEnum.numberDiff;
  value?: JSONVariableNumberDiff;
  onChange?: (value: JSONVariableNumberDiff) => void;
  intlId?: IntlMessageId;
} & Omit<InputNumberProps, 'value' | 'onChange'>;

const JSONVariableInputNumberDiffFormItem = React.forwardRef<HTMLInputElement, JSONVariableInputNumberDiffProps>(
  ({ dataType, value, onChange, intlId, ...restProps }, ref) => {
    const intl = useIntl();

    const handleChange = useCallback(
      (newValue: number | string | undefined) => {
        if (newValue == null || newValue === '') newValue = null;
        if (typeof newValue === 'string') newValue = parseFloat(newValue);
        onChange && onChange({ type: dataType, value: newValue, oldValue: value?.oldValue });
      },
      [dataType, onChange, value?.oldValue]
    );

    const handleOldValueChange = useCallback(
      (oldValue: number | string | undefined) => {
        if (oldValue == null || oldValue === '') oldValue = null;
        if (typeof oldValue === 'string') oldValue = parseFloat(oldValue);
        onChange && onChange({ type: dataType, value: value?.value, oldValue: oldValue });
      },
      [dataType, onChange, value?.value]
    );

    return (
      <Space>
        <Form.Item label={<Fmt id="ProjectSettingsProjectCardDataTree.oldValue" />}>
          <InputNumber
            decimalSeparator={getDecimalSeparator(intl.locale)}
            onChange={handleOldValueChange}
            value={value?.oldValue}
            {...restProps}
            style={{ width: '150px' }}
          />
        </Form.Item>
        <Form.Item label={<Fmt id="ProjectSettingsProjectCardDataTree.newValue" />}>
          <InputNumber
            ref={ref}
            decimalSeparator={getDecimalSeparator(intl.locale)}
            onChange={handleChange}
            value={value?.value}
            {...restProps}
            style={{ width: '150px' }}
          />
        </Form.Item>
        {!!intlId && <Fmt id={intlId} />}
      </Space>
    );
  }
);

export default React.memo(JSONVariableInputNumberDiffFormItem);
