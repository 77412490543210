import { masterApi } from 'api/completeApi';
import { OrganizationAdminReportDto, OrgProjectCardDataDto } from 'api/completeApiInterfaces';
import { useIntl } from 'hooks';
import { useEffect, useState } from 'react';
import { messageError } from 'utils';
import { orderTree } from 'utils/tree/treeHelpers';

export const useOrgProjectCardData = (selectedOrganization: OrganizationAdminReportDto) => {
  const intl = useIntl();

  const [projectCardData, setProjectCardData] = useState<OrgProjectCardDataDto[]>([]);

  useEffect(() => {
    const loadOrgProjectCardData = async () => {
      const selectedOrganizationId = selectedOrganization?.id;
      if (!selectedOrganizationId) return;
      const [err, data] = await masterApi.projects.reports.projectcard.id.get(selectedOrganizationId);
      if (err) {
        messageError(err, intl);
      } else {
        if (data?.data?.cardData) setProjectCardData(orderTree(data.data.cardData));
      }
    };
    void loadOrgProjectCardData();
  }, [selectedOrganization]);

  return [projectCardData, setProjectCardData] as const;
};
