import { Form, Select, TreeSelect } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { DefaultOptionType } from 'antd/lib/select';
import { ProjectTemplateCategoryNodeDto, ProjectTemplateCategoryTreeDto } from 'api/completeApiInterfaces';
import { Fmt } from 'locale';
import React, { FC, useMemo } from 'react';
import { requiredRule } from 'utils/formHelpers';

export type ProjectTemplateDirectoryCategoryFormData = {
  categoryTreeId: Guid;
  categoryNodeId: Guid;
};

type Props = {
  defaults?: Partial<ProjectTemplateDirectoryCategoryFormData>;
  categoryList: ProjectTemplateCategoryTreeDto[];
  categoryNodes: ProjectTemplateCategoryNodeDto[];
};

const mapNodeAsDefaultOption = (
  root: ProjectTemplateCategoryNodeDto,
  nodes: ProjectTemplateCategoryNodeDto[]
): DefaultOptionType => {
  return {
    value: root.id,
    label: root.name,
    children: nodes.filter((node) => node.parentId === root.id).map((node) => mapNodeAsDefaultOption(node, nodes)),
  };
};

const ProjectTemplateDirectoryCategoryForm: FC<Props> = ({ defaults, categoryList, categoryNodes }) => {
  const categoryTreeId = useWatch('categoryTreeId');

  const categoryTreeData = useMemo((): DefaultOptionType[] => {
    const selectedTreeId = categoryTreeId || defaults?.categoryTreeId;
    if (!selectedTreeId) return undefined;

    const filteredNodes = categoryNodes.filter((node) => node.templateCategoryTreeId === selectedTreeId);
    const rootNode = filteredNodes.find((node) => !node.parentId);
    if (!rootNode) return undefined;
    const categoryTree = mapNodeAsDefaultOption(rootNode, filteredNodes);

    return [{ ...categoryTree }];
  }, [categoryNodes, categoryTreeId, defaults]);

  return (
    <>
      <Form.Item
        label={<Fmt id="general.category" />}
        name="categoryTreeId"
        rules={[requiredRule('ProjectTemplateDirectoryCategoryForm.category.required')]}
        initialValue={defaults?.categoryTreeId}
      >
        <Select>
          {categoryList.map((categoryTree) => (
            <Select.Option key={categoryTree.id} value={categoryTree.id}>
              {categoryTree.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={<Fmt id="ProjectTemplateDirectoryCategoryForm.node" />}
        name="categoryNodeId"
        rules={[requiredRule('ProjectTemplateDirectoryCategoryForm.categoryNode.required')]}
        initialValue={defaults?.categoryNodeId}
      >
        <TreeSelect
          showSearch
          placeholder={<Fmt id="DirectorySettingsForm.requiredCategories.placeholder" />}
          allowClear
          treeDefaultExpandAll
          treeData={categoryTreeData}
        />
      </Form.Item>
    </>
  );
};

export default ProjectTemplateDirectoryCategoryForm;
