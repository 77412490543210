import { Alert, Button, Form, Input, ModalProps, Typography } from 'antd';
import { ButtonProps } from 'antd/es/button';
import { useWatch } from 'antd/es/form/Form';
import { to } from 'api/await-to';
import { masterApi } from 'api/completeApi';
import { apiConstraints } from 'api/completeApiConstraints';
import { DirectoryListDto, DirectoryListsExDto, DownloadUrl, JSONVariableTypeEnum } from 'api/completeApiInterfaces';
import { baseMasterApi } from 'api/master/baseMasterApi';
import axios from 'axios';
import { ContentGate } from 'components/ContentGate/ContentGate';
import FilenameErrorMessage from 'components/FilenameErrorMessage';
import { FormModalWrapper } from 'components/forms/FormModalWrapper';
import JSONVariableFormItem from 'components/JSONVariableFormItems/JSONVariableFormItem';
import { JSONVariableHubLinkData } from 'components/JSONVariableFormItems/JSONVariableTypes';
import { FILE_AND_FOLDER_NAME_REGEX, HIDE_BUTTON_PROPS } from 'config/constants';
import { useIntl, useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import { downloadFile, messageError } from 'utils';
import { duplicateNameRule, maxLengthRule, requiredRule } from 'utils/formHelpers';
import { MeetingDocumentDetail } from '../MDProjectApprovalCreateForm/MDProjectApprovalListModal';
import { MAP_CK_IDENTIFICATIONS_ENUM_TO_INTL_MESSAGE_ID } from '../MDProjectCardCreateForm/MDProjectCreateForm';
import { useMdMeetingBackgroundDocumentState } from './useMdMeetingBackgroundDocumentState';

const NAME_VALIDATION_DELAY = 250;

type MDMeetingDocumentRequestFormData = {
  link: JSONVariableHubLinkData;
  documentName: string;
  directoryName: string;
};

type Props = {
  organizationId: Guid;
  meetingDocumentDetail: MeetingDocumentDetail;
  overheadProjectId: Guid;
  onClose: () => void;
} & Omit<ModalProps, 'onOk' | 'title' | 'onCancel'>;

const MDMeetingBackgroundDocumentModal: React.FC<Props> = ({
  organizationId,
  meetingDocumentDetail,
  overheadProjectId,
  onClose,
  ...modalProps
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const formDirNameValue = useWatch('directoryName', form);
  const selectedDirectoryLink = useWatch('link', form) as JSONVariableHubLinkData;
  const [projectDirectories, setProjectDirectories] = useState<DirectoryListDto[]>();

  const usedDirectoryNames = useMemo(() => {
    const selectedDirectoryId = selectedDirectoryLink?.directoryId;
    const parentId = projectDirectories?.find((dir) => dir.id === selectedDirectoryId)?.parentId;
    return projectDirectories?.filter((dir) => dir.parentId === parentId).map((dir) => dir.name);
  }, [projectDirectories, selectedDirectoryLink]);

  const projectApi = useMemo(
    () =>
      overheadProjectId &&
      axios.create({
        baseURL: `${baseMasterApi.defaults.baseURL}/projects/${overheadProjectId}`,
        headers: {
          ...baseMasterApi.defaults.headers,
        },
      }),
    [overheadProjectId]
  );

  const getProjectDirectories = useSameCallback(async () => {
    const [err, resp] = await to(projectApi.get<DirectoryListsExDto>(`/directories/primary/listdirectoriesex`));
    if (err) {
      messageError(err, intl);
      return;
    }
    setProjectDirectories(resp.data.directories);
  });

  useEffect(() => {
    void getProjectDirectories();
  }, [projectApi]);

  const [
    meetingDocumentState,
    meetingDocumentStateError,
    isPreparationError,
    isDocumentNotYetRequested,
    isDocumentProcessing,
    loadMeetingDocumentState,
    setMeetingDocumentState,
  ] = useMdMeetingBackgroundDocumentState(organizationId, meetingDocumentDetail?.id);

  const handleDocumentRequestSubmit = useSameCallback(async (values: MDMeetingDocumentRequestFormData) => {
    if (isDocumentNotYetRequested) {
      const fileNameWithExtension = values.documentName.endsWith('.docx')
        ? values.documentName
        : values.documentName + '.docx';
      const [err, res] = await masterApi.projects.md.approvalmeeting.id.id.minutes.post(
        organizationId,
        meetingDocumentDetail.id,
        {
          minutesDirectoryForNewDocument: values.link.directoryId,
          newDocumentName: fileNameWithExtension,
          meetingDirectoryName: values.directoryName,
        }
      );
      if (err) {
        return err;
      }
      loadMeetingDocumentState();
    } else {
      const [err, res] = await masterApi.projects.md.approvalmeeting.id.id.minutes.post(
        organizationId,
        meetingDocumentDetail.id,
        {
          documentForUpdate: meetingDocumentState.document.id,
        }
      );
      if (err) {
        return err;
      }
      setMeetingDocumentState(res.data);
    }

    return null;
  });

  const downloadDocument = useCallback(async () => {
    overheadProjectId &&
      axios.create({
        baseURL: `${baseMasterApi.defaults.baseURL}/projects/${overheadProjectId}`,
        headers: {
          ...baseMasterApi.defaults.headers,
        },
      }),
      meetingDocumentState?.processed &&
        (await downloadFile(() =>
          to(
            projectApi.get<DownloadUrl>(`/documents/${meetingDocumentState.document.id}/download`, {
              params: { inline: false },
            })
          )
        ));
  }, [projectApi, meetingDocumentState, intl]);

  const okButton = useMemo((): ButtonProps => {
    if (
      isDocumentNotYetRequested ||
      (meetingDocumentState?.processed && !meetingDocumentState?.inQueue && !meetingDocumentState?.error)
    ) {
      return undefined;
    }
    return HIDE_BUTTON_PROPS;
  }, [isDocumentNotYetRequested, meetingDocumentState]);

  const okButtonTextId = isDocumentNotYetRequested
    ? 'general.create'
    : meetingDocumentState?.processed || meetingDocumentState?.error
    ? 'MD.MeetingBackgroundDocumentModal.button.recreate'
    : undefined;

  useDebounce(
    () => {
      void form.validateFields();
    },
    NAME_VALIDATION_DELAY,
    [formDirNameValue, usedDirectoryNames]
  );

  return (
    <FormModalWrapper
      onSubmit={handleDocumentRequestSubmit}
      title={<Fmt id="MD.MeetingBackgroundDocumentModal.title" />}
      okButtonProps={okButton}
      onClose={onClose}
      cancelText={intl.formatMessage({ id: 'general.close' })}
      submitTextId={okButtonTextId}
      form={form}
      {...modalProps}
    >
      <ContentGate
        error={!isDocumentNotYetRequested && meetingDocumentStateError}
        loading={!isDocumentNotYetRequested && !meetingDocumentState}
      >
        {isDocumentNotYetRequested ? (
          <>
            <Typography.Paragraph>
              <Fmt id="MD.MeetingBackgroundDocumentModal.requestIsRequired" />
            </Typography.Paragraph>
            <Form.Item name="link" label={<Fmt id="MD.MeetingBackgroundDocumentModal.directory.label" />}>
              <JSONVariableFormItem
                dataType={JSONVariableTypeEnum.hubLink}
                linkType="directory"
                projectId={overheadProjectId}
              />
            </Form.Item>
            <Form.Item
              name="directoryName"
              label={<Fmt id="MD.MeetingBackgroundDocumentModal.dirName.label" />}
              rules={[
                requiredRule('forms.items.name.rules.required'),
                maxLengthRule('general.maxNameLength', apiConstraints.documentCreateDto.name.maxLength),
                duplicateNameRule('forms.items.name.rules.nameExists', usedDirectoryNames, true),
                {
                  pattern: FILE_AND_FOLDER_NAME_REGEX,
                  message: <FilenameErrorMessage />,
                },
              ]}
              initialValue={moment(meetingDocumentDetail?.approvalDateTime).format('YYYY-MM-DD')}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="documentName"
              label={<Fmt id="MD.MeetingBackgroundDocumentModal.filename.label" />}
              rules={[
                requiredRule('forms.items.name.rules.required'),
                maxLengthRule('general.maxNameLength', apiConstraints.documentCreateDto.name.maxLength),
              ]}
              initialValue={
                !!meetingDocumentDetail &&
                `${intl.formatMessage({
                  id: 'MD.MeetingBackgroundDocumentModal.filename.label.initial.part',
                })} ${moment(meetingDocumentDetail.approvalDateTime).format('YYYY-MM-DD')} ${intl.formatMessage({
                  id: MAP_CK_IDENTIFICATIONS_ENUM_TO_INTL_MESSAGE_ID[meetingDocumentDetail.ckIdentification],
                })}`
              }
            >
              <Input />
            </Form.Item>
          </>
        ) : (
          <ContentGate
            loading={isDocumentProcessing}
            loadingAlternative={intl.formatMessage(
              { id: 'MD.MeetingBackgroundDocumentModal.progress' },
              { progress: meetingDocumentState?.progress }
            )}
          >
            {meetingDocumentState?.processed && (
              <Button onClick={downloadDocument}>
                <Fmt id="MD.MeetingBackgroundDocumentModal.button.download" />
              </Button>
            )}
            {meetingDocumentState?.error &&
              (meetingDocumentState?.inQueue ? (
                <Alert
                  type="warning"
                  message={<Fmt id="MD.MeetingBackgroundDocumentModal.error.generationFailedWithRetry" />}
                />
              ) : (
                <Alert type="error" message={<Fmt id="MD.MeetingBackgroundDocumentModal.error.generationFailed" />} />
              ))}
          </ContentGate>
        )}
      </ContentGate>
    </FormModalWrapper>
  );
};

export default MDMeetingBackgroundDocumentModal;
