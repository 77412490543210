import { Form, Select, SelectProps } from 'antd';
import { AppUserDto, GroupListDto } from 'api/completeApiInterfaces';
import { useCurrentProjectUser, useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FC, useMemo } from 'react';
import { AppUsersFormItem, SelectableUsersSourceEnum, UserAttributes } from '../AppUsersAddFormModal/AppUsersFormItem';
import { AddUserEndpointType } from './ProjectUsersAddFormModal';

export type ProjectUserAddFormData = {
  usersToAddWithAttributes: Record<string, UserAttributes>;
  groups: Guid[];
};

type Props = {
  toAddAppUsers: AppUserDto[];
  existUsersOnly: boolean;
  groupsList: GroupListDto[];
  requestEndpoint: AddUserEndpointType;
};

const ProjectUserAddFormComponent: FC<Props> = ({ toAddAppUsers, groupsList, existUsersOnly, requestEndpoint }) => {
  const intl = useIntl();
  const isCurrentUserProjectAdmin = !!useCurrentProjectUser()?.isAdmin;

  const options: SelectProps['options'] = useMemo(() => {
    if (isCurrentUserProjectAdmin) {
      return groupsList?.map((group) => ({
        label: group.name,
        value: group.id,
      }));
    } else {
      return groupsList
        ?.filter((group) => !group.isAdminGroup)
        .map((group) => ({
          label: group.name,
          value: group.id,
        }));
    }
  }, [groupsList, isCurrentUserProjectAdmin]);

  return (
    <>
      <Form.Item name="usersToAddWithAttributes">
        <AppUsersFormItem
          selectableAppUsers={toAddAppUsers}
          isProjectUsersAdding={requestEndpoint === 'project'}
          selectableUsersToAddSource={SelectableUsersSourceEnum.organization}
        />
      </Form.Item>
      {requestEndpoint === 'project' && (
        <Form.Item label={<Fmt id={'ProjectUserAddForm.addToGroups'} />} name="groups">
          <Select mode="multiple" allowClear options={options} />
        </Form.Item>
      )}
    </>
  );
};

export default ProjectUserAddFormComponent;
