import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import {
  ReportWidgetConfiguration,
  SharedReportWidgetConfiguration,
} from 'components/Reports/contexts/ReportWidgetsContextProvider';
import React, { FunctionComponent, useCallback } from 'react';
import SharedReportWidgetEditForm, { SharedReportWidgetEditFormData } from './SharedReportWidgetEditForm';

type Props = FormModalProps<ReportWidgetConfiguration> & {
  configuration: SharedReportWidgetConfiguration;
};

const SharedReportWidgetEditFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, configuration, ...restProps } = props;

  const handleSubmit: FormSubmitHandler<SharedReportWidgetEditFormData> = useCallback(
    async (values) => {
      const data: SharedReportWidgetConfiguration = {
        ...configuration,
        type: 'shared',
        title: values.name,
      };
      await onSubmit(data);
      return null;
    },
    [configuration, onSubmit]
  );

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId="ReportWidgetEditFormModal.title"
      submitTextId="general.edit"
      submitTextLoadingId={null}
      width={600}
      {...restProps}
    >
      <SharedReportWidgetEditForm configuration={configuration} />
    </FormModalWrapper>
  );
};

export default SharedReportWidgetEditFormModal;
