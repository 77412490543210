import { Checkbox, Form, Input } from 'antd';
import { DirectoryListDto, DirectoryMoveDto } from 'api/completeApiInterfaces';
import { DirectoriesTreeSelectFormItem } from 'components/DirectoriesTreeSelect/DirectoriesTreeSelect';
import DocumentMultipleActionError from 'components/DocumentMultipleActionError';
import FilenameErrorMessage from 'components/FilenameErrorMessage';
import PathDisplay from 'components/PathDisplay';
import { FILE_AND_FOLDER_NAME_REGEX } from 'config/constants';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import { canWriteInDirectory, directoryAncestryIds } from 'pages/AllDocumentsPage/AllDocumentsPage';
import React, { FC, useCallback } from 'react';

import { maxLengthRule, requiredRule } from 'utils/formHelpers';
import { ConnectedDirectory } from 'utils/typeMappings/directories/directoryTypes';
import styles from './DirectoryMoveForm.module.less';

export type DirectoryMoveFormData = DirectoryMoveDto;

type Props = {
  selectedDirectory: DirectoryListDto;
  destinationDirectory: Guid;
  errors: JSX.Element[];
};

const DirectoryMoveForm: FC<Props> = ({ selectedDirectory, destinationDirectory, errors }) => {
  const intl = useIntl();

  const isItemDisabled = useCallback(
    (directory: ConnectedDirectory) => {
      if (!canWriteInDirectory(directory)) {
        return true;
      }

      if (directory.id == selectedDirectory.parentId) {
        return true; // Already in parent directory
      }

      const parentIds = directoryAncestryIds(directory);
      return parentIds.includes(selectedDirectory.id);
    },
    [selectedDirectory]
  );

  return (
    <>
      <div className={styles.movingDirectoryWrap}>
        <div className={styles.movingDirectoryTitle}>{<Fmt id="DirectoryMoveFormModal.moveDirectory" />}</div>
        <PathDisplay path={selectedDirectory.path} className={styles.movingDirectoryPath} />
      </div>
      <Form.Item
        name="destination"
        initialValue={destinationDirectory}
        className={styles.item}
        label={intl.formatMessage({ id: 'DirectoryMoveFormModal.destinationDirectoryId.label' })}
        rules={[requiredRule('DirectoryCreateForm.form.items.name.rules.required')]}
      >
        <DirectoriesTreeSelectFormItem isItemDisabled={isItemDisabled} />
      </Form.Item>
      <Form.Item
        name="name"
        initialValue={selectedDirectory.name}
        className={styles.item}
        label={<Fmt id="DirectoryMoveFormModal.rename" />}
        rules={[
          requiredRule('DirectoryEditForm.form.items.name.rules.required', true),
          maxLengthRule('general.maxNameLength'),
          {
            pattern: FILE_AND_FOLDER_NAME_REGEX,
            message: <FilenameErrorMessage />,
          },
        ]}
      >
        <Input type="text" />
      </Form.Item>
      <Form.Item name="preserveAccessRights" valuePropName="checked" className={styles.item} wrapperCol={{ offset: 6 }}>
        <Checkbox>
          <Fmt id="DirectoryMoveFormModal.preserveAccessRights" />
        </Checkbox>
      </Form.Item>
      <DocumentMultipleActionError errors={errors} titleId="DocumentsMoveFormModal.errorsTitle.missed" />
    </>
  );
};

export default DirectoryMoveForm;
