import { Form, Radio } from 'antd';
import { DocumentLabelsSetModeEnum } from 'api/completeApiInterfaces';
import DocumentMultipleActionError from 'components/DocumentMultipleActionError';
import { LabelsInput } from 'components/LabelsInput/LabelsInput';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FC } from 'react';

import { requiredRule } from 'utils/formHelpers';

export interface BulkEditDocumentsLabelsFormData {
  documentsLabels: Guid[];
  setMode: DocumentLabelsSetModeEnum;
}

type Props = {
  errors: JSX.Element[];
};

const BulkEditDocumentsLabelsForm: FC<Props> = ({ errors }) => {
  const intl = useIntl();

  return (
    <>
      <Form.Item
        name="documentsLabels"
        initialValue={[]}
        label={intl.formatMessage({ id: 'BulkEditDocumentsLabelsForm.labels' })}
        rules={[requiredRule('BulkEditDocumentsLabelsForm.labels.required')]}
      >
        <LabelsInput />
      </Form.Item>
      <Form.Item
        name="setMode"
        initialValue={DocumentLabelsSetModeEnum.addLabels}
        label={intl.formatMessage({ id: 'BulkEditDocumentsLabelsForm.mode' })}
        rules={[requiredRule('BulkEditDocumentsLabelsForm.mode.required')]}
      >
        <Radio.Group>
          <Radio value={DocumentLabelsSetModeEnum.addLabels}>
            <Fmt id="BulkEditDocumentsLabelsForm.mode.add" />
          </Radio>
          <Radio value={DocumentLabelsSetModeEnum.replaceLabels}>
            <Fmt id="BulkEditDocumentsLabelsForm.mode.replace" />
          </Radio>
          <Radio value={DocumentLabelsSetModeEnum.removeLabels}>
            <Fmt id="BulkEditDocumentsLabelsForm.mode.remove" />
          </Radio>
        </Radio.Group>
      </Form.Item>
      <DocumentMultipleActionError errors={errors} titleId="BulkEditDocumentsLabelsForm.impossibleChanges" />
    </>
  );
};

export default BulkEditDocumentsLabelsForm;
