import { projectApi } from 'api/completeApi';
import { OrgProjectCardDataDto, ProjectCardDataDto, ProjectCardDataSetDto } from 'api/completeApiInterfaces';
import { SaveOrCancelButton } from 'components/SaveOrCancelButton/SaveOrCancelButton';
import { useActiveProject, useIntl, useIsMounted } from 'hooks';
import { useActiveProjectProjectCardData } from 'hooks/useActiveProjectProjectCardData';
import produce from 'immer';
import { isEqual } from 'lodash';
import ProjectSettingsProjectCardDataTree from 'pages/ProjectSettingsPage/ProjectSettingsPageGeneral/ProjectSettingsProjectCardDataTree';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { DisableWindowStoreStartPayload } from 'store/models/storeModelinterfaces';

function mapTree<T>(tree: ProjectCardDataDto[], nodeMapper: (node: ProjectCardDataDto) => T): T[] {
  return tree.flatMap((item) => {
    return [nodeMapper(item), ...mapTree(item.children || [], nodeMapper)];
  });
}

export const ProjectSettingsProjectCardData = () => {
  const projectCard = useActiveProject()?.projectCard;
  const projectCardData = useActiveProjectProjectCardData();
  const project = useActiveProject();
  const dispatch = useDispatch<Dispatch>();
  const setActiveProject = dispatch.activeProject.setActiveProject;

  const selectedOrganizationId = project.organization.id;
  const [changedProjectCardData, setChangedProjectCardData] = useState<OrgProjectCardDataDto[]>(projectCardData);

  const intl = useIntl();
  const isDirty = useMemo(() => !isEqual(changedProjectCardData, projectCardData), [
    changedProjectCardData,
    projectCardData,
  ]);

  const disableWindowConfig = useMemo(
    (): DisableWindowStoreStartPayload => ({
      showMask: false,
      message: intl.formatMessage({ id: 'DirectorySettingsForm.permissions.notSaveAlert' }),
    }),

    [intl]
  );

  useEffect(() => {
    if (!isDirty) {
      dispatch.disableWindow.stopPreventing();
    } else {
      dispatch.disableWindow.startPreventing(disableWindowConfig);
    }
  }, [isDirty]);

  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    const requestData: ProjectCardDataSetDto[] = mapTree(changedProjectCardData, (node) => {
      return {
        id: node.id,
        data: node.data,
      };
    });

    const [err] = await projectApi.card.patch({
      ...projectCard,
      projectCardData: requestData,
    });

    if (err) {
      if (isMounted.current) setLoading(false);
      return err;
    }

    const newProject = produce(project, (draft) => {
      if (draft.projectCard.projectCardData) {
        draft.projectCard.projectCardData = changedProjectCardData;
      }
    });

    setActiveProject(newProject);
    if (isMounted.current) setLoading(false);
    return null;
  };

  const cancelChanges = useCallback(() => {
    setChangedProjectCardData(projectCardData);
  }, [projectCardData]);

  return (
    <>
      <ProjectSettingsProjectCardDataTree
        organizationId={selectedOrganizationId}
        projectCardData={changedProjectCardData}
        setProjectCardData={setChangedProjectCardData}
        buttons={
          <SaveOrCancelButton onSave={onSubmit} onCancel={cancelChanges} loading={loading} disabled={!isDirty} />
        }
      />
    </>
  );
};
