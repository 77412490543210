import { JSONVariableTypeEnum, MdProjectDto, MdProjectListDto, MdProjectVariableEnum } from 'api/completeApiInterfaces';
import { JSONVariable } from '../JSONVariableTypes';

type MdProjectVariableMap = {
  [MdProjectVariableEnum.actionCharacter]: JSONVariableTypeEnum.enum;
  [MdProjectVariableEnum.ckApprovalConditions]: JSONVariableTypeEnum.ckApprovalConditions;
  [MdProjectVariableEnum.ckApprovalDate]: JSONVariableTypeEnum.date;
  [MdProjectVariableEnum.ckApproved]: JSONVariableTypeEnum.boolean;
  [MdProjectVariableEnum.ckIdentificaion]: JSONVariableTypeEnum.ckApprovalConditions;
  [MdProjectVariableEnum.ckMeetingBackground]: JSONVariableTypeEnum.hubLink;
  [MdProjectVariableEnum.ckMeetingMinutes]: JSONVariableTypeEnum.hubLink;
  [MdProjectVariableEnum.ckStatement]: JSONVariableTypeEnum.enum;
  [MdProjectVariableEnum.commentProcedure]: JSONVariableTypeEnum.hubLink;
  [MdProjectVariableEnum.constructionRegion]: JSONVariableTypeEnum.string;
  [MdProjectVariableEnum.documentationLink]: JSONVariableTypeEnum.hubLink;
  [MdProjectVariableEnum.expectedRealisationTime]: JSONVariableTypeEnum.interval;
  [MdProjectVariableEnum.hasOpponentsReport]: JSONVariableTypeEnum.boolean;
  [MdProjectVariableEnum.intentionApprovalClause]: JSONVariableTypeEnum.string;
  [MdProjectVariableEnum.intentionUpdated]: JSONVariableTypeEnum.boolean;
  [MdProjectVariableEnum.investorContactPerson]: JSONVariableTypeEnum.string;
  [MdProjectVariableEnum.isProFond]: JSONVariableTypeEnum.number;
  [MdProjectVariableEnum.isUPDChangeRequest]: JSONVariableTypeEnum.boolean;
  [MdProjectVariableEnum.mdContactPerson]: JSONVariableTypeEnum.empty;
  [MdProjectVariableEnum.opponentsReport]: JSONVariableTypeEnum.hubLink;
  [MdProjectVariableEnum.preparationUpdate]: JSONVariableTypeEnum.string;
  [MdProjectVariableEnum.preparedForCkApproval]: JSONVariableTypeEnum.boolean;
  [MdProjectVariableEnum.projectName]: JSONVariableTypeEnum.string;
  [MdProjectVariableEnum.referenceNumber]: JSONVariableTypeEnum.string;
  [MdProjectVariableEnum.stretch]: JSONVariableTypeEnum.string;
  [MdProjectVariableEnum.studyType]: JSONVariableTypeEnum.string;
  [MdProjectVariableEnum.submittedFor]: JSONVariableTypeEnum.enum;
  [MdProjectVariableEnum.totalCost]: JSONVariableTypeEnum.number;
};

type ProjectDataMapped = {
  [K in MdProjectVariableEnum]?: Extract<JSONVariable, { type: MdProjectVariableMap[K] }>;
};

export type MdProjectListWithUnpackedData = MdProjectListDto & Omit<ProjectDataMapped, 'ckIdentificaion'>;
export type MdProjectWithUnpackedData = MdProjectDto & Omit<ProjectDataMapped, 'ckIdentificaion'>;

export const mdProjectListDataUnpack = (mdProject: MdProjectListDto): MdProjectListWithUnpackedData => {
  const mappedProject: MdProjectListWithUnpackedData = {
    ...mdProject,
  };

  mdProject.projectData.forEach(({ variable, data }) => {
    if (data && variable !== MdProjectVariableEnum.ckIdentificaion) {
      (mappedProject as any)[variable] = data;
    }
  });

  return mappedProject;
};

export const mdProjectDataUnpack = (mdProject: MdProjectDto): MdProjectWithUnpackedData => {
  const mappedProject: MdProjectWithUnpackedData = {
    ...mdProject,
  };

  mdProject.projectData.forEach(({ variable, data }) => {
    if (data && variable !== MdProjectVariableEnum.ckIdentificaion) {
      (mappedProject as any)[variable] = data;
    }
  });

  return mappedProject;
};
