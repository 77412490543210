import { Button, Dropdown, Switch } from 'antd';
import { ApiPromise } from 'api/await-to';
import { UploadData } from 'api/project/upload/uploadManager';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { StagedAttachment } from 'components/DiscussionChat/DiscussionInputAttachments/DiscussionInputAttachments.utils';
import DocumentsGridHeader from 'components/DocumentsGridHeader/DocumentsGridHeader';
import { DiscussionAssignAttachmentFormModal } from 'components/forms/DiscussionAssignAttachmentForm/DiscussionAssignAttachmentFormModal';
import {
  DiscussionAttachmentCreateFormData,
  DiscussionRelationOption,
} from 'components/forms/DiscussionAttachmentCreateForm/DiscussionAttachmentCreateForm';
import { DiscussionAttachmentCreateFormModal } from 'components/forms/DiscussionAttachmentCreateForm/DiscussionAttachmentCreateFormModal';
import { DiscussionAttachmentUploadModal } from 'components/forms/DiscussionAttachmentUploadModal/DiscussionAttachmentUploadModal';
import { AddIcon, DeleteIcon, DownloadIcon, FileAddIcon, UploadIcon } from 'components/Icons/HubActionsIcons';
import { ItemUnselectButton } from 'components/ItemUnselectButton/ItemUnselectButton';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useBoolean, useIntl, useIsMounted, useSameCallback, useVisibleState } from 'hooks';
import { Fmt } from 'locale';
import React, { useMemo } from 'react';
import { messageError } from 'utils';
import uuid from 'uuid';

export type AssignAttachmentListData = {
  assignedDocuments: { documentId: Guid; revisionId: Guid; name: string }[];
  discussionRelation?: string | number;
};

type Props<T> = {
  canAddAttachment: boolean;
  onShowDiscardedChange: React.Dispatch<React.SetStateAction<boolean>>;
  createUploadData: (data: DiscussionAttachmentCreateFormData) => UploadData<T>;
  assignAttachment: (data: AssignAttachmentListData) => ApiPromise<T>;
  onResponse: (data: T) => void;
  onDownloadAttachments: () => void;
  downloadAttachmentsDisabled: boolean;
  allFilesSelected: boolean;
  onSelectAllDocuments: () => void;
  onUnselectAll?: () => void;
  selectedItemsCount?: number;
  showDiscarded?: boolean;
  discussionRelationOptions?: DiscussionRelationOption<string | number>[];
};

const AttachmentHeader = <T,>({
  canAddAttachment,
  onShowDiscardedChange,
  createUploadData,
  assignAttachment,
  onResponse,
  onDownloadAttachments,
  downloadAttachmentsDisabled,
  allFilesSelected,
  onSelectAllDocuments,
  onUnselectAll,
  selectedItemsCount,
  showDiscarded,
  discussionRelationOptions,
}: Props<T>): JSX.Element => {
  const [createModalVisible, createModalShow, createModalHide] = useBoolean();
  const [uploadData, uploadVisible, setUploadData, hideUpload] = useVisibleState<DiscussionAttachmentCreateFormData>();
  const [linkModalVisible, linkModalShow, linkModalHide] = useBoolean();
  const intl = useIntl();
  const isMounted = useIsMounted();

  const handleCreateSubmit = useSameCallback((data: DiscussionAttachmentCreateFormData) => {
    setUploadData(data);
    createModalHide();
  });

  const handleDiscussionAssignmentModal = useSameCallback(async (value: AssignAttachmentListData) => {
    const [error, response] = await assignAttachment(value);

    if (!isMounted.current) {
      return;
    }

    if (!!error) {
      messageError(error, intl);
      return;
    }

    onResponse(response.data);
    linkModalHide();
  });

  const stagedAttachments = useMemo((): StagedAttachment[] => {
    if (!uploadData) {
      return [];
    }
    return [
      {
        file: uploadData.file,
        fileUrl: URL.createObjectURL(uploadData.file),
        id: uuid(),
        name: uploadData.name,
      },
    ];
  }, [uploadData]);

  const handleCreateUploadData = (): UploadData<T> => {
    const { onFinish, ...rest } = createUploadData(uploadData);
    return {
      ...rest,
      onFinish: async (response, data) => {
        await onFinish(response, data);
        hideUpload();
      },
    };
  };

  const menu = {
    items: [
      {
        key: 'addNewFile',
        label: <Fmt id="AttachmentHeader.addNewFile" />,
        icon: <UploadIcon />,
        onClick: createModalShow,
      },
      {
        key: 'addDocument',
        label: <Fmt id="AttachmentHeader.addDocument" />,
        icon: <FileAddIcon />,
        onClick: linkModalShow,
      },
    ],
  };

  return (
    <>
      <DocumentsGridHeader
        allFilesSelected={allFilesSelected}
        selectAllDocuments={onSelectAllDocuments}
        order={
          <>
            <Dropdown menu={menu} disabled={!canAddAttachment}>
              <Button type="link" size="small" icon={<AddIcon />}>
                <Fmt id="CommentProcedureCommentsAddAttachment.addAttachment" />
              </Button>
            </Dropdown>
            <Button
              type="link"
              size="small"
              onClick={onDownloadAttachments}
              disabled={downloadAttachmentsDisabled}
              icon={<DownloadIcon />}
            >
              <Fmt id="general.download" />
            </Button>
          </>
        }
        filters={
          <FlowLayout alignRight>
            {!!onUnselectAll && !!selectedItemsCount && (
              <ItemUnselectButton onClick={onUnselectAll} selectedCount={selectedItemsCount} />
            )}
            <CommonHubTooltip title={<Fmt id="CommentProcedureCommentsAddAttachment.discadToggleTooltip" />}>
              <Switch
                checked={showDiscarded}
                checkedChildren={<DeleteIcon />}
                unCheckedChildren={<DeleteIcon />}
                onChange={onShowDiscardedChange}
              />
            </CommonHubTooltip>
          </FlowLayout>
        }
      />

      <DiscussionAttachmentCreateFormModal
        open={createModalVisible}
        onSubmit={handleCreateSubmit}
        onClose={createModalHide}
        discussionRelationOptions={discussionRelationOptions}
      />

      <DiscussionAttachmentUploadModal
        visible={uploadVisible}
        onClose={hideUpload}
        stagedAttachments={stagedAttachments}
        createUploadData={handleCreateUploadData}
      />

      <DiscussionAssignAttachmentFormModal
        intl={intl}
        open={linkModalVisible}
        onSubmit={handleDiscussionAssignmentModal}
        title={<Fmt id="DocumentSelectFormItem.selectDocuments" />}
        onClose={linkModalHide}
        discussionRelationOptions={discussionRelationOptions}
      />
    </>
  );
};

export default React.memo(AttachmentHeader);
