import { Col, Form, Input, Row } from 'antd';
import { apiConstraints } from 'api/completeApiConstraints';
import {
  AccessLevelEnum,
  CommentProcedurePhaseEnum,
  DirectoryContentDto,
  ProjectUserProfileDto,
} from 'api/completeApiInterfaces';
import { DateSelectFormItem, DateSelectOptionType, DateSelectValue } from 'components/DateSelect/DateSelect';
import { DocumentSelectDocumentState } from 'components/DocumentSelect/DocumentSelect';
import { DocumentsSelectMultipleFormItem } from 'components/DocumentSelect/FormItem/DocumentSelectMultipleFormItem';
import { LabelsInput } from 'components/LabelsInput/LabelsInput';
import { MultipleRolesListSelectFormItem } from 'components/MultipleRolesListSelect/MultipleRolesListSelect';
import { useCurrentProjectUser, useIntl } from 'hooks';
import { Fmt, InjectedIntl } from 'locale';
import React, { FC, useCallback, useMemo } from 'react';
import { duplicateNameRule, maxLengthRule, requiredRule } from 'utils/formHelpers';
import { isUserInRole } from 'utils/roles/isUserInRole';
import { ConnectedDirectory } from 'utils/typeMappings/directories/directoryTypes';
import { DisabledWithReason } from 'utils/types';

export const commentProcedureCalendarPresetDeadlines: number[] = [5, 7, 10];

export const commentProcedureDefaultDeadline: DateSelectValue = {
  type: DateSelectOptionType.CalendarDays,
  calendarDays: 7,
  workingDays: 7,
};

export type CommentProcedureAddFormData = {
  name: string;
  description: string;
  processorRoleIds: Guid[];
  submitterRoleIds: Guid[];
  processorUserIds: Guid[];
  submitterUserIds: Guid[];
  labels: Guid[];
  commentProcedureDocuments: DocumentSelectDocumentState[];
  deadline: DateSelectValue;
};

export type CommentProcedureAddFormConfiguration = {
  startDirectoryId?: Guid; // start file selection in this directory
  initialDocuments?: DocumentSelectDocumentState[]; // pre-selected files when creating from selected documents;
};

type Props = CommentProcedureAddFormConfiguration & {
  existingCommentProcedureNames?: string[];
};

// Common for comment procedure and workflow
export const isCommentWorkflowDocumentDisabled = (
  document: DirectoryContentDto,
  directory: ConnectedDirectory,
  currentUser: ProjectUserProfileDto,
  intl: InjectedIntl
): DisabledWithReason => {
  if (!document) {
    return intl.formatMessage({ id: 'AllDocumentsPageFileToolbar.favoriteChanged.linkError.NotFound' });
  }
  const currentAccessLevel = directory.currentAccessLevel;
  if (currentAccessLevel !== AccessLevelEnum.admin && currentAccessLevel !== AccessLevelEnum.write) {
    return intl.formatMessage({ id: 'DocumentSelect.disabledMessage.noWriteAccess' });
  }
  if (document.reservedBy && document.reservedBy.id !== currentUser.id) {
    return intl.formatMessage({ id: 'DocumentSelect.disabledMessage.notUnlocked' });
  }
  if (document.ownedBy && !isUserInRole(currentUser.id, document.ownedBy)) {
    return intl.formatMessage({ id: 'DocumentSelect.disabledMessage.notOwner' });
  }
  if (document.workflowId) {
    return intl.formatMessage({ id: 'DocumentSelect.disabledMessage.documentInWorkflow' });
  }
  if (document.commentProcedureId) {
    return intl.formatMessage({ id: 'DocumentSelect.disabledMessage.documentInProcedure' });
  }
  if (document.esticonObjectLink?.isDilci) {
    return intl.formatMessage({ id: 'DocumentSelect.disabledMessage.partialEsticonDocument' });
  }
  return false;
};

export const isCommentedDocumentDisabled = (
  document: DirectoryContentDto,
  directory: ConnectedDirectory,
  currentUser: ProjectUserProfileDto,
  intl: InjectedIntl,
  commentProcedureId?: Guid
): DisabledWithReason => {
  if (!document) {
    return intl.formatMessage({ id: 'AllDocumentsPageFileToolbar.favoriteChanged.linkError.NotFound' });
  }
  if (commentProcedureId && document.commentProcedureId === commentProcedureId) {
    return intl.formatMessage({ id: 'DocumentSelect.disabledMessage.documentInThisProcedure' });
  }
  return isCommentWorkflowDocumentDisabled(document, directory, currentUser, intl);
};

const CommentProcedureAddForm: FC<Props> = ({ startDirectoryId, initialDocuments, existingCommentProcedureNames }) => {
  const intl = useIntl();
  const currentUser = useCurrentProjectUser();

  const disabledDocuments = useCallback(
    (file: DirectoryContentDto, directory: ConnectedDirectory) =>
      isCommentedDocumentDisabled(file, directory, currentUser, intl),
    [currentUser, intl]
  );

  const rolesRules = useMemo(() => [requiredRule('CommentProcedureAddForm.role.required')], []);

  return (
    <>
      <Form.Item
        name="name"
        label={intl.formatMessage({ id: 'CommentProcedureAddForm.name' })}
        rules={[
          requiredRule('CommentProcedureAddForm.name.required', true),
          maxLengthRule('general.maxNameLength', apiConstraints.commentProcedureCreateDto.name.maxLength),
          duplicateNameRule('forms.items.name.rules.nameExists', existingCommentProcedureNames, true),
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label={intl.formatMessage({ id: 'CommentProcedureAddForm.description' })}
        rules={[
          maxLengthRule('general.maxDescriptionLength', apiConstraints.commentProcedureCreateDto.description.maxLength),
        ]}
      >
        <Input.TextArea rows={3} />
      </Form.Item>

      <Row gutter={20}>
        <Col span={12}>
          <Form.Item
            name="submitterRoleIds"
            initialValue={[]}
            label={intl.formatMessage({ id: 'CommentProcedureAddForm.headOfSubmitters' })}
            rules={rolesRules}
          >
            <MultipleRolesListSelectFormItem />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="processorRoleIds"
            initialValue={[]}
            label={intl.formatMessage({ id: 'CommentProcedureAddForm.headOfProcessors' })}
            rules={rolesRules}
          >
            <MultipleRolesListSelectFormItem />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name="labels" initialValue={[]} label={intl.formatMessage({ id: 'forms.items.labels.label' })}>
        <LabelsInput />
      </Form.Item>

      <Form.Item
        name="commentProcedureDocuments"
        initialValue={initialDocuments || []}
        label={intl.formatMessage({ id: 'CommentProcedureAddForm.commentedDocuments' })}
      >
        <DocumentsSelectMultipleFormItem
          disabledDocuments={disabledDocuments}
          startDirectoryId={startDirectoryId}
          titleId="CommentProcedureAddForm.commentedDocuments.modalTitle"
        />
      </Form.Item>

      <Form.Item
        name="deadline"
        initialValue={commentProcedureDefaultDeadline}
        label={<Fmt id={`CommentProcedureDeadline.for.${CommentProcedurePhaseEnum.Commenting}`} />}
        rules={[requiredRule('forms.items.rules.required')]}
      >
        <DateSelectFormItem calendarDaysPresets={commentProcedureCalendarPresetDeadlines} enableUnlimited />
      </Form.Item>
    </>
  );
};

export default CommentProcedureAddForm;
