import { Form, Input, Select, Typography } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { OrganizationDto } from 'api/completeApiInterfaces';
import { useSharedReportWidgets } from 'components/Reports/useSharedReportWidgets';
import { MAX_ITEM_NAME_LENGTH } from 'config/constants';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FC, useEffect, useMemo } from 'react';
import { maxLengthRule, requiredRule, simpleSelectFilter } from 'utils/formHelpers';

export type SharedReportWidgetCreateFormData = {
  name: string;
  organizationId: Guid;
  sharedReportId: Guid;
};

type Props = {
  organizations: OrganizationDto[];
  defaults: SharedReportWidgetCreateFormData;
};

const SharedReportWidgetCreateForm: FC<Props> = ({ organizations, defaults }) => {
  const intl = useIntl();
  const { sharedWidgetConfigurations, loadSharedConfiguration } = useSharedReportWidgets();

  const organizationId = useWatch('organizationId');

  const selectedOrganizationId = useMemo((): Guid => organizationId || (organizations?.length && organizations[0].id), [
    organizations,
    organizationId,
  ]);

  const sharedReportsOptions = useMemo(
    () =>
      sharedWidgetConfigurations.map((configuration) => (
        <Select.Option key={configuration.id} value={configuration.id}>
          {configuration.title}
        </Select.Option>
      )),
    [sharedWidgetConfigurations]
  );

  useEffect(() => {
    loadSharedConfiguration(selectedOrganizationId);
  }, [selectedOrganizationId]);

  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: 'general.organizations' })}
        name="organizationId"
        initialValue={defaults?.organizationId || (organizations?.length && organizations[0].id)}
        rules={[requiredRule('ReportWidgetCreateFormModalForm.organization.required', true)]}
      >
        <Select showSearch allowClear={false} filterOption={simpleSelectFilter}>
          {organizations?.map((organization) => (
            <Select.Option key={organization.id} value={organization.id}>
              {organization.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {selectedOrganizationId && (
        <>
          <Form.Item
            label={intl.formatMessage({ id: 'ReportWidgetCreateFormModalForm.reportType' })}
            name="sharedReportId"
            initialValue={defaults?.sharedReportId}
            rules={[requiredRule('ReportWidgetCreateFormModalForm.reportType.required', true)]}
          >
            <Select showSearch allowClear={false} filterOption={simpleSelectFilter}>
              {sharedReportsOptions}
            </Select>
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ id: 'forms.items.name.label' })}
            name="name"
            initialValue={defaults?.name}
            rules={[maxLengthRule('general.maxNameLength', MAX_ITEM_NAME_LENGTH)]}
          >
            <Input placeholder={intl.formatMessage({ id: 'ReportWidgetCreateFormModalForm.name.placeholder' })} />
          </Form.Item>
          <Typography.Paragraph type="secondary">
            <Fmt id="ReportWidgetCreateFormModalForm.name.info" />
          </Typography.Paragraph>
        </>
      )}
    </>
  );
};

export default SharedReportWidgetCreateForm;
