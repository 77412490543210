import { DEBUG } from 'config/env';
import { loadSingleModel, useDocumentsLoadState } from 'Forge/ForgeLoader';
import { DEFAULT_AUTODESK_VIEW_OPTIONS, getAutodeskViewOptions } from 'Forge/ForgeViewerOptions';
import { useIntl, useIsMounted } from 'hooks';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react';
import { AutoSizer } from 'react-virtualized';
import { ForgeDisplayMode, IToken, NamedForgeModel } from './Forge';
import styles from './Forge.module.less';
import { displayCompareWarning } from './ForgeCompareWarning';

const formatModelRevisionName = (model: { documentName?: string }, defaultName: string, maxLength = 40) => {
  const name = model?.documentName;
  if (!name) return defaultName;
  if (name.length <= maxLength) return name;
  return name.substr(0, maxLength - 3) + '...';
};

type ForgeProps = {
  namedModels: NamedForgeModel[];
  getForgeToken: () => IToken;
};

export const ForgeCompareDiff: FunctionComponent<ForgeProps> = ({ namedModels, getForgeToken }) => {
  const forgeContainerRef = useRef();
  const viewerRef = useRef<Autodesk.Viewing.GuiViewer3D>();
  const intl = useIntl();
  const lastScalingRef = useRef<string>(null);
  const modelGlobalOffsetRef = useRef<THREE.Vector3>(null);

  const options: Autodesk.Viewing.InitializerOptions = useMemo(
    () => ({
      env: 'AutodeskProduction2',
      api: 'derivativeV2_EU',
      getAccessToken: (sendAccessToken) => {
        const token = getForgeToken();
        if (token) sendAccessToken(token.access_token, token.expires_in);
      },
    }),
    [getForgeToken]
  );

  const isMounted = useIsMounted();

  const modelsToCompare = useRef<Autodesk.Viewing.Model[]>([]);
  const viewOptions = getAutodeskViewOptions(namedModels[0].documentExtension) || DEFAULT_AUTODESK_VIEW_OPTIONS;

  const [loadDocumentsSequentially] = useDocumentsLoadState();

  const initDiffExtension = useCallback(async (loadedModels: Autodesk.Viewing.Model[]) => {
    const config = {
      availableDiffModes: ['overlay', 'sidebyside'],
      primaryModels: [loadedModels[1]],
      diffModels: [loadedModels[0]],
      mimeType: viewOptions.mimeType,
      diffadp: false,
      diffMode: 'sidebyside',
      versionA: formatModelRevisionName(namedModels[1], 'A'),
      versionB: formatModelRevisionName(namedModels[0], 'B'),
      useSplitScreenExtension: true,
    };

    return viewerRef.current.loadExtension('Autodesk.DiffTool', config);
  }, []);

  const start = () => {
    if (viewOptions.sourceFileWarning) {
      displayCompareWarning(namedModels[0].documentExtension, intl);
    }

    window.Autodesk.Viewing.Initializer(options, async () => {
      if (!isMounted.current) return;

      viewerRef.current = new window.Autodesk.Viewing.GuiViewer3D(forgeContainerRef.current);

      // originally OBJECT_TREE_CREATED_EVENT
      viewerRef.current.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, (event: any) => {
        if (event.model.modelIndex === undefined)
          DEBUG && console.error('ForgeViewer GEOMETRY_LOADED_EVENT', 'modelIndex is undefined');

        modelsToCompare.current[event.model.modelIndex] = event.model;

        if (modelsToCompare.current[0] && modelsToCompare.current[1]) {
          void initDiffExtension(modelsToCompare.current);
        }
      });

      viewerRef.current.setSelectionMode(Autodesk.Viewing.SelectionMode.LEAF_OBJECT);
      viewerRef.current.start();

      await loadDocumentsSequentially(viewerRef.current, namedModels, async (model, index) => {
        const { document, model: loadedModel, bubbleNodes } = await loadSingleModel(
          viewerRef.current,
          model,
          ForgeDisplayMode.Mode3d,
          {
            preserveView: false,
            keepCurrentModels: true,
            globalOffset: modelGlobalOffsetRef.current ? modelGlobalOffsetRef.current : undefined,
            applyScaling: lastScalingRef.current ? lastScalingRef.current : undefined,
          }
        );
        (loadedModel as any).modelIndex = index;

        // set values for second loaded model to sync with first one
        if (modelGlobalOffsetRef.current === null) modelGlobalOffsetRef.current = loadedModel.getGlobalOffset();
        if (lastScalingRef.current === null) lastScalingRef.current = loadedModel.getUnitString();
      });
      if (!isMounted.current) return;
    });
  };

  useEffect(() => {
    if (!!window.Autodesk) {
      start();
    } else {
      DEBUG && console.error('ForgeViewer not loaded yet');
    }
    return () => {
      if (viewerRef.current) {
        viewerRef.current?.finish();
        window.Autodesk?.Viewing.shutdown();
      }
    };
  }, []);

  return (
    <div className={styles.ForgeViewer}>
      <AutoSizer>{(size) => <div ref={forgeContainerRef} style={size} />}</AutoSizer>
    </div>
  );
};
