import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useWatch } from 'antd/lib/form/Form';
import { MdProjectDto, MdProjectPhaseEnum, MdProjectVariableEnum } from 'api/completeApiInterfaces';
import { isJSONBooleanValueTrue } from 'components/JSONVariableFormItems/Items/JSONVariableCheckboxFormItem';
import { JSONVariableSelectEnumTyped } from 'components/JSONVariableFormItems/JSONVariableTypes';
import { useSelectorDispatch } from 'hooks';
import { useDirtyStoreReloadCallback } from 'hooks/useSelectorDispatch';
import { default as React, useEffect, useState } from 'react';
import {
  CK_STATEMENT_FOR_APPROVAL_WAY,
  CK_STATEMENT_FOR_INFORMATION_WAY,
  MdProjectDataStatementEnum,
} from '../MdFormItems/MdCkStatementFormItem';
import { MdProjectDataSubmittedForEnum } from '../MdFormItems/MdProposedForFormItem';
import MDProjectCardVariableFields from './MDProjectCardVariableFields';

type Props = {
  mdProject: MdProjectDto;
  canEditAsProposer: boolean;
  canEditAsGuarantor: boolean;
  canEditAsCKOrganizer: boolean;
  overheadProjectId: Guid;
  isPreparedForCKApproval: boolean;
};

const mappedPhaseCommissionItems: Record<MdProjectPhaseEnum, MdProjectVariableEnum[]> = {
  [MdProjectPhaseEnum.other]: [MdProjectVariableEnum.ckApproved, MdProjectVariableEnum.ckApprovalConditions],
  [MdProjectPhaseEnum.init]: [
    MdProjectVariableEnum.ckMeetingBackground,
    MdProjectVariableEnum.ckMeetingMinutes,
    MdProjectVariableEnum.ckApprovalDate,
    MdProjectVariableEnum.ckApproved,
    MdProjectVariableEnum.ckStatement,
    MdProjectVariableEnum.ckApprovalConditions,
  ],
  [MdProjectPhaseEnum.study]: [
    MdProjectVariableEnum.ckMeetingBackground,
    MdProjectVariableEnum.ckMeetingMinutes,
    MdProjectVariableEnum.ckApprovalDate,
    MdProjectVariableEnum.ckApproved,
    MdProjectVariableEnum.ckStatement,
    MdProjectVariableEnum.ckApprovalConditions,
    MdProjectVariableEnum.isUPDChangeRequest,
  ],
  [MdProjectPhaseEnum.intention]: [
    MdProjectVariableEnum.ckMeetingBackground,
    MdProjectVariableEnum.ckMeetingMinutes,
    MdProjectVariableEnum.ckApprovalDate,
    MdProjectVariableEnum.ckApproved,
    MdProjectVariableEnum.ckStatement,
    MdProjectVariableEnum.ckApprovalConditions,
  ],
  [MdProjectPhaseEnum.update]: [
    MdProjectVariableEnum.ckMeetingBackground,
    MdProjectVariableEnum.ckMeetingMinutes,
    MdProjectVariableEnum.ckApprovalDate,
    MdProjectVariableEnum.ckApproved,
    MdProjectVariableEnum.ckStatement,
    MdProjectVariableEnum.ckApprovalConditions,
  ],
};

const MDProjectCardCommission: React.FC<Props> = ({
  mdProject,
  canEditAsProposer,
  canEditAsCKOrganizer,
  canEditAsGuarantor,
  overheadProjectId,
  isPreparedForCKApproval,
}) => {
  const form = useFormInstance();
  const ckApprovedFormValue = useWatch('ckApproved')?.value;
  const submittedForFormValue = useWatch<JSONVariableSelectEnumTyped<MdProjectDataSubmittedForEnum>>('submittedFor')
    ?.value;
  const ckStatementFormValue = useWatch<JSONVariableSelectEnumTyped<MdProjectDataStatementEnum>>('ckStatement')?.value;

  const [canEditCkStatement, setCanEditCkStatement] = useState<boolean>();

  const organizationCalendar = useSelectorDispatch(
    (state) => state.activeCalendar?.activeCalendar?.orgCalendar,
    async (dispatch) => {
      await dispatch.activeCalendar.loadCalendar({ organizationId: mdProject.organization.id });
    }
  );

  useDirtyStoreReloadCallback(
    (state) => state.activeCalendar,
    async (dispatch) => {
      await dispatch.activeCalendar.loadCalendar({ organizationId: mdProject.organization.id });
    }
  );

  useEffect(() => {
    ckApprovedFormValue !== undefined && setCanEditCkStatement(isJSONBooleanValueTrue(ckApprovedFormValue));
    if (
      (CK_STATEMENT_FOR_INFORMATION_WAY.includes(ckStatementFormValue) &&
        submittedForFormValue !== MdProjectDataSubmittedForEnum.forInformation) ||
      (CK_STATEMENT_FOR_APPROVAL_WAY.includes(ckStatementFormValue) &&
        submittedForFormValue !== MdProjectDataSubmittedForEnum.forApproval)
    ) {
      form.setFieldValue(['ckStatement'], undefined);
    }
  }, [ckApprovedFormValue, ckStatementFormValue, form, submittedForFormValue]);

  useEffect(() => {
    if (!canEditCkStatement && canEditCkStatement !== undefined) form.setFieldValue(['ckStatement'], '');
  }, [canEditCkStatement]);

  if (!mdProject) return null;

  return (
    <MDProjectCardVariableFields
      fields={mappedPhaseCommissionItems}
      mdProject={mdProject}
      canEditAsProposer={canEditAsProposer}
      canEditAsGuarantor={canEditAsGuarantor}
      canEditAsCKOrganizer={canEditAsCKOrganizer}
      canEditCkStatement={canEditCkStatement}
      overheadProjectId={overheadProjectId}
      organizationCalendar={organizationCalendar}
      isPreparedForCKApproval={isPreparedForCKApproval}
    />
  );
};

export default MDProjectCardCommission;
