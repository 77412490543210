import { DownOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Button, Dropdown, Tabs, message } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { TransferItem, TransferListProps } from 'antd/lib/transfer';
import { api } from 'api';
import {
  CommentProcedureCommentDiscussionRelationEnum,
  CommentProcedureCommentDto,
  CommentProcedureCommentNoteNoteTypeEnum,
  CommentProcedurePhaseEnum,
  CommentProcedureRoleEnum,
  ServiceError,
} from 'api/completeApiInterfaces';
import { DiscussionChat } from 'components/DiscussionChat/DiscussionChat';
import { AttachmentWarning } from 'components/DiscussionChat/DiscussionInput/DiscussionInput';
import { StagedAttachment } from 'components/DiscussionChat/DiscussionInputAttachments/DiscussionInputAttachments.utils';
import { FlexTabs } from 'components/tabs/FlexTabs/FlexTabs';
import { useDeleteItems, useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import { maxBy } from 'lodash';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { messageError, processApiError } from 'utils';
import { CommentProcedureCommentProps } from '../CommentProcedureCommentsDetails';
import {
  UserGroupForSelection,
  getAllAvailableUsers,
  getProcessorAvailableUsers,
  getSubmitterAvailableUsers,
  getUsersForGroupSelection,
  mapDiscussionNoteData,
} from './CommentProcedureCommentsNotes.utils';

export type CommentAttachmentLinkType = 'CommentMessage' | 'DiscussionAddition' | 'Note';
export type StagedCommentAttachmentUpload = {
  stagedAttachments: StagedAttachment[];
  linkType: CommentAttachmentLinkType;
  attachmentRelation: CommentProcedureCommentDiscussionRelationEnum;
  noteID?: Guid;
};

const CLICK_TRIGGER = ['click' as const];

const handleAddUserFromGroup = (
  isRight: boolean,
  group: UserGroupForSelection,
  value: Guid[],
  setSelectedKeys: React.Dispatch<React.SetStateAction<Guid[]>>
) => {
  const clickedGroupUserIds = group.userIds.filter((userId) => isRight === value.includes(userId));
  setSelectedKeys((selectKeys) => Array.from([...new Set([...selectKeys, ...clickedGroupUserIds])]));
};

export const createGetOriginalUrlForCommentAttachment = (commentProcedureId: Guid, commentDetailId: Guid) => async (
  attachmentId: Guid
) => {
  return await api.project.commentProcedures.comments.attachment.download(
    commentProcedureId,
    commentDetailId,
    attachmentId
  );
};

type Props = CommentProcedureCommentProps & {
  onStagedAttachmentsUpload: (stagedAttachmentUpload: StagedCommentAttachmentUpload) => void;
};

const noteTypeToRelation: Record<
  CommentProcedureCommentNoteNoteTypeEnum,
  CommentProcedureCommentDiscussionRelationEnum
> = {
  [CommentProcedureCommentNoteNoteTypeEnum.generalMessage]: CommentProcedureCommentDiscussionRelationEnum.both,
  [CommentProcedureCommentNoteNoteTypeEnum.internalProcessor]: CommentProcedureCommentDiscussionRelationEnum.processor,
  [CommentProcedureCommentNoteNoteTypeEnum.internalSubmitter]: CommentProcedureCommentDiscussionRelationEnum.submitter,
};

const CommentProcedureCommentsNotes: FunctionComponent<Props> = ({
  intl,
  commentProcedure,
  commentDetailDispatch,
  onStagedAttachmentsUpload,
  commentDetail,
  userIsCommentProcessor,
  userIsCommentSubmitter,
  disableDueToExpiredDeadline,
}) => {
  const canAddProcessorNote = !disableDueToExpiredDeadline && userIsCommentProcessor;
  const canAddSubmitterNote = !disableDueToExpiredDeadline && userIsCommentSubmitter;
  const isCommentProcedureFinished =
    commentProcedure.state === CommentProcedurePhaseEnum.Approved ||
    commentProcedure.state === CommentProcedurePhaseEnum.Closed;

  const [loading, setLoading] = useState(false);

  const addNote = useSameCallback(
    async (
      value: string,
      noteType: CommentProcedureCommentNoteNoteTypeEnum,
      linkedAttachments: Guid[],
      stagedAttachments: StagedAttachment[],
      userNotifications?: Guid[]
    ) => {
      setLoading(true);
      const [err, res] = await api.project.commentProcedures.comments.addNote(commentProcedure.id, commentDetail.id, {
        message: value,
        linkedAttachments,
        userNotifications,
        noteType: noteType,
      });
      setLoading(false);

      if (!err) {
        commentDetailDispatch({ type: 'setDefaultData', data: res.data });

        if (stagedAttachments.length > 0) {
          const noteId = maxBy(
            [
              ...res.data.commentProcedureCommentNotes,
              ...res.data.commentProcedureCommentInternalProcessorNotes,
              ...res.data.commentProcedureCommentInternalSubmitterNotes,
            ],
            (note) => note.createdDate
          ).id;
          onStagedAttachmentsUpload({
            stagedAttachments: Array.from(stagedAttachments),
            linkType: 'Note',
            noteID: noteId,
            attachmentRelation: noteTypeToRelation[noteType],
          });
        }
      } else {
        messageError(err, intl);
      }
    }
  );

  // Notes will handle loading/deleting state themselfs
  const [_deletingNotes, deleteNote] = useDeleteItems<Guid>(
    intl,
    (noteId: Guid) =>
      api.project.commentProcedures.deleteCommentProcedureCommentNote(commentProcedure.id, commentDetail.id, noteId),
    (id: Guid, data: CommentProcedureCommentDto) => commentDetailDispatch({ type: 'setDefaultData', data })
  );

  const editNote = useSameCallback(
    async (noteId: Guid, noteMessage: string, linkedAttachments: Guid[], stagedAttachments: StagedAttachment[]) => {
      const [err, res] = await api.project.commentProcedures.editCommentProcedureCommentNote(
        commentProcedure.id,
        commentDetail.id,
        noteId,
        {
          title: '',
          message: noteMessage,
          linkedAttachments: linkedAttachments,
        }
      );
      if (err) {
        processApiError(err, (error: ServiceError) => {
          message.error(intl.formatMessage({ id: `serviceError.${error.referenceErrorCode}` }));
        });
        return false;
      } else if (res) {
        commentDetailDispatch({ type: 'setDefaultData', data: res.data });
        if (stagedAttachments.length > 0) {
          onStagedAttachmentsUpload({
            stagedAttachments: Array.from(stagedAttachments),
            linkType: 'Note',
            noteID: noteId,
            attachmentRelation: res.data.commentProcedureCommentNotes.find((n) => n.id === noteId).authorRelation,
          });
        }
      }
      return true;
    }
  );

  const allAvailableUsers = useMemo(() => getAllAvailableUsers(commentProcedure), [commentProcedure]);
  const availableProcessorUsers = useMemo(() => getProcessorAvailableUsers(commentProcedure), [commentProcedure]);
  const availableSubmitterUsers = useMemo(() => getSubmitterAvailableUsers(commentProcedure), [commentProcedure]);

  const usersForGroupSelection = useMemo(() => {
    return getUsersForGroupSelection(commentProcedure);
  }, [commentProcedure]);

  const handleRestoreNote = useSameCallback(async (noteId: Guid) => {
    const [err, res] = await api.project.commentProcedures.restoreCommentProcedureCommentNote(
      commentProcedure.id,
      commentDetail.id,
      noteId
    );
    if (err) {
      processApiError(err, (error: ServiceError) => {
        message.error(intl.formatMessage({ id: `serviceError.${error.referenceErrorCode}` }));
      });
      return false;
    } else if (res) {
      commentDetailDispatch({ type: 'setDefaultData', data: res.data });
    }
    return true;
  });

  const getOriginalUrl = useMemo(
    () => createGetOriginalUrlForCommentAttachment(commentProcedure.id, commentDetail.id),
    [commentProcedure.id, commentDetail.id]
  );

  const commonDiscussionNotes = useMemo(() => commentDetail.commentProcedureCommentNotes.map(mapDiscussionNoteData), [
    commentDetail,
  ]);

  const internalProcessorDiscussionNotes = useMemo(
    () => commentDetail.commentProcedureCommentInternalProcessorNotes.map(mapDiscussionNoteData),
    [commentDetail]
  );

  const internalSubmitterDiscussionNotes = useMemo(
    () => commentDetail.commentProcedureCommentInternalSubmitterNotes.map(mapDiscussionNoteData),
    [commentDetail]
  );

  const teams = useMemo(() => usersForGroupSelection.filter((group) => group.mainRoleType === null) || [], [
    usersForGroupSelection,
  ]);
  const roles = useMemo(() => usersForGroupSelection?.filter((group) => group.mainRoleType !== null) || [], [
    usersForGroupSelection,
  ]);

  const validateLinkedAtachments = useCallback(
    (attachmentIds: Guid[], generalMessage: CommentProcedureCommentNoteNoteTypeEnum): AttachmentWarning => {
      const attachmentWarningIds = commentDetail.commentProcedureCommentAttachments
        .filter(
          (attachment) =>
            attachmentIds.some((id) => id === attachment.id) &&
            ((attachment.attachmentRelation === CommentProcedureCommentDiscussionRelationEnum.processor &&
              (generalMessage === CommentProcedureCommentNoteNoteTypeEnum.generalMessage ||
                generalMessage === CommentProcedureCommentNoteNoteTypeEnum.internalSubmitter)) ||
              (attachment.attachmentRelation === CommentProcedureCommentDiscussionRelationEnum.submitter &&
                (generalMessage === CommentProcedureCommentNoteNoteTypeEnum.generalMessage ||
                  generalMessage === CommentProcedureCommentNoteNoteTypeEnum.internalProcessor)))
        )
        .map((attachment) => attachment.id);

      return {
        message: attachmentWarningIds.length > 0 ? 'DiscussionLinkAttachment.warning.publishing' : undefined,
        affectedAttachmentIds: attachmentWarningIds,
      };
    },
    [commentDetail.commentProcedureCommentAttachments]
  );

  const createUserTransferFooter = useCallback(
    (
      value: Guid[],
      setSelectedKeys: React.Dispatch<React.SetStateAction<Guid[]>>,
      availableRoles: CommentProcedureRoleEnum[]
    ) => (footerProps: TransferListProps<TransferItem>) => {
      const filteredRoles =
        roles.filter((role) => availableRoles.some((availableRole) => availableRole === role.mainRoleType)) || [];
      const teamsItems: ItemType[] =
        teams?.map((group) => ({
          key: group.groupName,
          label: group.groupName,
          onClick: () => handleAddUserFromGroup(footerProps.direction === 'right', group, value, setSelectedKeys),
        })) || [];

      const rolesItems: ItemType[] = filteredRoles.map((group) => ({
        key: group.groupName,
        label:
          group.mainRoleType === CommentProcedureRoleEnum.Processor ? (
            <Fmt id="DiscussionNote.Notification.mainProcessor" />
          ) : group.mainRoleType === CommentProcedureRoleEnum.Submitter ? (
            <Fmt id="DiscussionNote.Notification.mainSubmitter" />
          ) : (
            ''
          ),
        onClick: () => handleAddUserFromGroup(footerProps.direction === 'right', group, value, setSelectedKeys),
      }));

      return (
        <>
          {!!teams?.length && (
            <Dropdown menu={{ items: teamsItems }} trigger={CLICK_TRIGGER}>
              <Button type="link">
                <UsergroupAddOutlined />
                <Fmt id="DiscussionNote.Notification.teamDropdownButton" />
                <DownOutlined />
              </Button>
            </Dropdown>
          )}
          {!!filteredRoles?.length && (
            <Dropdown menu={{ items: rolesItems }} trigger={CLICK_TRIGGER}>
              <Button type="link">
                <UsergroupAddOutlined />
                <Fmt id="DiscussionNote.Notification.roleDropdownButton" />
                <DownOutlined />
              </Button>
            </Dropdown>
          )}
        </>
      );
    },
    [teams, roles]
  );

  const commonDiscussionProps = {
    availableAttachments: commentDetail.commentProcedureCommentAttachments,
    getOriginalUrl: getOriginalUrl,
    onEdit: editNote,
    onDelete: deleteNote,
    onRestore: handleRestoreNote,
    addNoteLoading: loading,
  };

  return (
    <FlexTabs>
      <Tabs.TabPane tab={<Fmt id="CommentProcedureCommentDiscussion.tabs.common" />} key="common">
        <DiscussionChat
          padding
          discussionNotes={commonDiscussionNotes}
          disabled={(!canAddProcessorNote && !canAddSubmitterNote) || isCommentProcedureFinished}
          onSend={(message, ...rest) =>
            addNote(message, CommentProcedureCommentNoteNoteTypeEnum.generalMessage, ...rest)
          }
          availableUsers={allAvailableUsers}
          createUserTransferFooter={(...params) =>
            createUserTransferFooter(...params, [
              CommentProcedureRoleEnum.Submitter,
              CommentProcedureRoleEnum.Processor,
            ])
          }
          validateLinkedAttachments={(attachmentIds: Guid[]) =>
            validateLinkedAtachments(attachmentIds, CommentProcedureCommentNoteNoteTypeEnum.generalMessage)
          }
          {...commonDiscussionProps}
        />
      </Tabs.TabPane>
      {userIsCommentSubmitter && (
        <Tabs.TabPane
          tab={<Fmt id="CommentProcedureCommentDiscussion.tabs.internalSubmitter" />}
          key="internalSubmitter"
        >
          <DiscussionChat
            padding
            discussionNotes={internalSubmitterDiscussionNotes}
            disabled={!canAddSubmitterNote || isCommentProcedureFinished}
            onSend={(message, ...rest) =>
              addNote(message, CommentProcedureCommentNoteNoteTypeEnum.internalSubmitter, ...rest)
            }
            availableUsers={availableSubmitterUsers}
            createUserTransferFooter={(...params) =>
              createUserTransferFooter(...params, [CommentProcedureRoleEnum.Submitter])
            }
            validateLinkedAttachments={(attachmentIds: Guid[]) =>
              validateLinkedAtachments(attachmentIds, CommentProcedureCommentNoteNoteTypeEnum.internalSubmitter)
            }
            {...commonDiscussionProps}
          />
        </Tabs.TabPane>
      )}
      {userIsCommentProcessor && (
        <Tabs.TabPane
          tab={<Fmt id="CommentProcedureCommentDiscussion.tabs.internalProcessor" />}
          key="internalProcessor"
        >
          <DiscussionChat
            padding
            discussionNotes={internalProcessorDiscussionNotes}
            disabled={!canAddProcessorNote || isCommentProcedureFinished}
            onSend={(message, ...rest) =>
              addNote(message, CommentProcedureCommentNoteNoteTypeEnum.internalProcessor, ...rest)
            }
            availableUsers={availableProcessorUsers}
            createUserTransferFooter={(...params) =>
              createUserTransferFooter(...params, [CommentProcedureRoleEnum.Processor])
            }
            validateLinkedAttachments={(attachmentIds: Guid[]) =>
              validateLinkedAtachments(attachmentIds, CommentProcedureCommentNoteNoteTypeEnum.internalProcessor)
            }
            {...commonDiscussionProps}
          />
        </Tabs.TabPane>
      )}
    </FlexTabs>
  );
};

export default injectIntl(CommentProcedureCommentsNotes);
