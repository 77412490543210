import { Button, message, Select, Typography } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { masterApi } from 'api/completeApi';
import EmptyStyled from 'components/Empty/EmptyStyled';
import { Margin } from 'components/Margin/Margin';
import KpiConfigurationContextProvider from 'components/Reports/contexts/KpiSettingsContextProvider';
import StackPanel from 'components/StackPanel';
import { useApiData, useIntl, useSelectorDispatch } from 'hooks';
import { useInitialLocalStorage } from 'hooks/useDefaultLocalStorage';
import { SavedRecordTypeEnum } from 'hooks/useSavedRecordsApi';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { checkIsGuid } from 'utils/bcf/xml/utils';
import { combinePaths } from 'utils/urlPaths';
import { ReportDetailProps } from '../../ReportDetail';
import { ReportDetailPanelParams } from '../../ReportDetailPage';
import styles from '../../ReportDetailPage.module.less';
import FinancialOverviewByUnitReport from './FinancialOverviewByUnitReport';

const LOCAL_STORAGE_FINANCIAL_REPORT_SELECTED_UNIT_KEY = 'FinancialOverviewReportSelectedUnit';

type LocalStorageFinancialReportParams = { orgEtiConnId: Guid; esticonFirmId: Guid };

const FinancialOverviewByUnitReportPage: FunctionComponent<ReportDetailProps> = ({
  organizationId,
  basePath,
  addBreadcrumb,
  removeBreadcrumb,
}) => {
  const [selectedUnit, initialSelectedUnit, saveSelectedUnit] = useInitialLocalStorage<
    LocalStorageFinancialReportParams
  >(LOCAL_STORAGE_FINANCIAL_REPORT_SELECTED_UNIT_KEY);
  const [selectedConnOption, setSelectedConnOption] = useState<Guid>(initialSelectedUnit?.orgEtiConnId);
  const [selectedFirmOption, setSelectedFirmOption] = useState<Guid>(initialSelectedUnit?.esticonFirmId);

  const history = useHistory();
  const intl = useIntl();
  const { report } = useParams<ReportDetailPanelParams>() as ReportDetailPanelParams;

  const userProjects = useSelectorDispatch(
    (dispatch) => dispatch.allProjects,
    (dispatch) => dispatch.allProjects.loadData({ reload: false })
  );

  useDirtyStoreReload(
    (state) => state.allProjects,
    (dispatch) => dispatch.allProjects
  );

  const [orgEsticonReport, orgEsticonReportError, orgEsticonReportLoading, loadOrgEsticonReport] = useApiData(
    (ct) => masterApi.projects.reports.firmlist.post({ organizationId: organizationId }, ct),
    {
      fetchCallback: (data) =>
        data.esticonConns?.some((conn) => conn.esticonFirms == null) &&
        message.warning(intl.formatMessage({ id: 'EsticonFirmList.loadingError' })),
    }
  );

  useEffect(() => {
    loadOrgEsticonReport();
  }, [organizationId]);

  const esticonConnsOptions = useMemo(() => {
    return orgEsticonReport?.esticonConns.map(
      (estiConn): DefaultOptionType => ({ value: estiConn.conn.id, label: estiConn.conn.name })
    );
  }, [orgEsticonReport]);
  const esticonFirmsOption = useMemo(() => {
    return (
      orgEsticonReport?.esticonConns
        ?.find((conn) => conn.conn.id === selectedConnOption)
        ?.esticonFirms?.map(
          (estiFirm): DefaultOptionType => ({ value: estiFirm.firm.id, label: estiFirm.firm.nazev })
        ) || []
    );
  }, [orgEsticonReport, selectedConnOption]);

  const selectUnit = useCallback(() => {
    saveSelectedUnit({ orgEtiConnId: selectedConnOption, esticonFirmId: selectedFirmOption });
  }, [selectedConnOption, selectedFirmOption]);

  const returnToUnitSelection = () => {
    setSelectedConnOption(undefined);
    setSelectedFirmOption(undefined);
    saveSelectedUnit({ esticonFirmId: undefined, orgEtiConnId: undefined });
    history.push(basePath);
  };

  useEffect(() => {
    addBreadcrumb({
      key: report,
      title: intl.formatMessage({ id: `Reporting.reportType.name.${report}` }),
      link: basePath,
      onClick: returnToUnitSelection,
    });
  }, []);

  useEffect(() => {
    if (selectedUnit) {
      const combinedPath = combinePaths([basePath, selectedUnit.orgEtiConnId, selectedUnit.esticonFirmId], '');
      addBreadcrumb({
        key: 'financialOverviewUnit',
        title:
          orgEsticonReport?.esticonConns
            ?.find((conn) => conn.conn.id === selectedUnit.orgEtiConnId)
            ?.esticonFirms?.find((firm) => firm.firm.id === selectedUnit.esticonFirmId).firm.nazev ||
          intl.formatMessage({ id: 'general.loading' }),
        link: combinedPath,
      });

      history.replace(combinedPath);
    }
    return () => removeBreadcrumb('financialOverviewUnit');
  }, [selectedUnit, orgEsticonReport]);

  const availableProjectIds = useMemo(
    () => new Set<Guid>(userProjects?.data?.projects.map((project) => project.id) || []),
    [userProjects]
  );

  const handleSelectConnOption = useCallback((connId: Guid) => {
    setSelectedConnOption(connId);
    setSelectedFirmOption(undefined);
  }, []);

  if (!checkIsGuid(selectedUnit?.orgEtiConnId))
    return (
      <EmptyStyled description={<Fmt id="FinancialOverviewByUnitReport.noUnit.title" />}>
        <StackPanel vertical>
          <Margin bottom>
            <Typography.Text>
              <Fmt id="ProjectSettingsPage.ExternApps.Esticon.Form.esticonApp" />
            </Typography.Text>
            <Select
              options={esticonConnsOptions}
              onChange={(value) => handleSelectConnOption(value)}
              size="middle"
              loading={orgEsticonReportLoading}
              className={styles.unitSelect}
            />
          </Margin>
          <Margin bottom>
            <Typography.Text>
              <Fmt id="ProjectSettingsPage.ExternApps.Esticon.Form.Database" />
            </Typography.Text>
            <Select
              options={esticonFirmsOption}
              onChange={setSelectedFirmOption}
              size="middle"
              loading={orgEsticonReportLoading}
              className={styles.unitSelect}
            />
          </Margin>
          <Button type="primary" onClick={selectUnit} disabled={!selectedConnOption || !selectedFirmOption}>
            <Fmt id="general.select" />
          </Button>
        </StackPanel>
      </EmptyStyled>
    );

  return (
    <div className={styles.content}>
      <Typography.Title level={2}>
        <Fmt id="FinancialOverviewByUnitReport.title" />
      </Typography.Title>
      <KpiConfigurationContextProvider
        reportType={SavedRecordTypeEnum.KPIFinancialOverviewEsticonProjectsByUnitConfigurations}
      >
        <FinancialOverviewByUnitReport
          esticonConnId={selectedUnit.orgEtiConnId}
          esticonFirmId={selectedUnit.esticonFirmId}
          organizationId={organizationId}
          availableProjectIds={availableProjectIds}
        />
      </KpiConfigurationContextProvider>
    </div>
  );
};

export default React.memo(FinancialOverviewByUnitReportPage);
