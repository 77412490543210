import { Modal } from 'antd';
import { Fmt } from 'locale';
import React from 'react';
import AuditLogDetailGrid from './AuditLogDetailGrid';

type Props = {
  auditLogId: number;
  visible: boolean;
  onOk: () => void;
};

const AuditLogDetailModal: React.FunctionComponent<Props> = ({ auditLogId, visible, onOk }) => {
  return (
    <Modal centered footer={null} width={850} open={visible} onCancel={onOk} title={<Fmt id="AuditLog.detail.title" />}>
      <AuditLogDetailGrid auditLogId={auditLogId} />
    </Modal>
  );
};

export default AuditLogDetailModal;
