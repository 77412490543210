import {
  ApartmentOutlined,
  CalendarOutlined,
  FieldTimeOutlined,
  GlobalOutlined,
  KeyOutlined,
  PartitionOutlined,
  ProfileOutlined,
  ProjectOutlined,
  SettingOutlined,
  TagOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { api } from 'api';
import { OrganizationAdminReportDto } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { CalendarSettingsFormButton } from 'components/forms/CalendarSettingsForm/CalendarSettingsFormEditButton';
import { ModelProcessingExportButton } from 'components/forms/ModelProcessingExportForm/ModelProcessingExportButton';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { FlagIcon } from 'components/HeaderLocaleDropdown/FlagIcon';
import { WorkflowTemplateIcon } from 'components/Icons/HubEntitiesIcons';
import SwitchCheck from 'components/SwitchCheck';
import { useApiData, useIntl } from 'hooks';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import { EsticonIcon } from 'pages/ProjectSettingsPage/ExternApps/Esticon/EsticonListItem/EsticonIcon';
import styles from 'pages/ProjectSettingsPage/Users/UserDetailPanel/PermissionsTab.module.less';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';

type Props = {
  organization: OrganizationAdminReportDto;
  url: string;
  selectedItemId: string;
};

const OrganizationGeneralTab: FunctionComponent<Props> = ({ organization, url, selectedItemId }) => {
  const intl = useIntl();
  const history = useHistory();

  const [forgeUsage] = useApiData((ct) => api.master.organization.getForgeUsage(ct), {
    autoload: true,
  });

  const getOrganizationForgeUsage = useCallback(
    (organizationId: Guid): number => {
      const numberOfUsage = forgeUsage?.organizationUsage[`${organizationId}`] || 0;
      return numberOfUsage;
    },
    [forgeUsage?.organizationUsage]
  );

  const organizationStorageAreas = useMemo(() => {
    if (!organization?.defaultStorageArea) return '';
    return organization.defaultStorageArea.join(', ');
  }, [organization]);

  if (!organization) return null;

  return (
    <GeneralSettingsContainer itemsLargeGap>
      <GeneralSettingsContainer>
        <GeneralSettingsItem title={intl.formatMessage({ id: 'general.name' })} description={organization.name} />
        <GeneralSettingsItem
          title={intl.formatMessage({ id: 'general.description' })}
          description={organization.description}
        />
        {!!organization.ico && (
          <GeneralSettingsItem
            title={intl.formatMessage({ id: 'OrganizationAddForm.CIN' })}
            description={organization.ico}
          />
        )}
        <GeneralSettingsItem
          title={intl.formatMessage({ id: 'general.storageareas' })}
          description={organizationStorageAreas}
        />
        <GeneralSettingsItem
          icon={<FieldTimeOutlined />}
          title={intl.formatMessage({ id: 'general.defaultTimeZone' })}
          description={organization.defaultTimeZoneId}
        />
        <GeneralSettingsItem
          icon={<GlobalOutlined />}
          title={intl.formatMessage({ id: 'general.defaultLanguage' })}
          description={
            <>
              <span>
                <FlagIcon selectedLocale={organization.defaultLanguage} onlyFlag />
              </span>
              {intl.formatMessage({
                id: `ProjectCreateForm.form.items.language.${organization.defaultLanguage}` as IntlMessageId,
              })}
            </>
          }
        />
        <GeneralSettingsItem
          icon={<CalendarOutlined />}
          title={intl.formatMessage({ id: 'general.workDaysCalendar' })}
          input={<CalendarSettingsFormButton organizationId={organization.id} buttonType="link" />}
        />
      </GeneralSettingsContainer>
      <GeneralSettingsContainer>
        <GeneralSettingsItem
          title={intl.formatMessage({ id: 'general.users' })}
          icon={<UserOutlined />}
          selectable
          selected={selectedItemId === 'users'}
          onClick={() => {
            history.push(`${url}/users`);
          }}
        />
        <GeneralSettingsItem
          icon={<PartitionOutlined />}
          title={intl.formatMessage({ id: 'general.organizationStructure' })}
          selectable
          selected={selectedItemId === 'organizationStructure'}
          onClick={() => {
            history.push(`${url}/organizationStructure`);
          }}
        />
        <GeneralSettingsItem
          title={intl.formatMessage({ id: 'JobPositions.title' })}
          icon={<ApartmentOutlined />}
          selectable
          selected={selectedItemId === 'job'}
          onClick={() => {
            history.push(`${url}/job`);
          }}
        />
        <GeneralSettingsItem
          title={intl.formatMessage({ id: 'general.projects' })}
          icon={<ProjectOutlined />}
          selectable
          selected={selectedItemId === 'projects'}
          onClick={() => {
            history.push(`${url}/projects`);
          }}
        />
        <GeneralSettingsItem
          title={intl.formatMessage({ id: 'ProjectTemplates.title' })}
          icon={<ProjectOutlined />}
          selectable
          selected={selectedItemId === 'projectTemplates'}
          onClick={() => {
            history.push(`${url}/projectTemplates`);
          }}
        />
        {organization.hasSZModule && (
          <GeneralSettingsItem
            title={intl.formatMessage({ id: 'FormProcessTaskTemplate.title' })}
            icon={<ProjectOutlined />}
            selectable
            selected={selectedItemId === 'formProcessTaskTemplates'}
            onClick={() => {
              history.push(`${url}/formProcessTaskTemplates`);
            }}
          />
        )}
        <GeneralSettingsItem
          title={intl.formatMessage({ id: 'OrgProjectCardData.title' })}
          icon={<ProfileOutlined />}
          selectable
          selected={selectedItemId === 'projectCardData'}
          onClick={() => {
            history.push(`${url}/projectCardData`);
          }}
        />
        <GeneralSettingsItem
          title={intl.formatMessage({ id: 'WorkflowTemplates.title' })}
          icon={<WorkflowTemplateIcon />}
          selectable
          selected={selectedItemId === 'workflowTemplates'}
          onClick={() => {
            history.push(`${url}/workflowTemplates`);
          }}
        />
        <GeneralSettingsItem
          title={intl.formatMessage({ id: 'OrganizationSettings.title' })}
          icon={<SettingOutlined />}
          selectable
          selected={selectedItemId === 'organizationSettings'}
          onClick={() => {
            history.push(`${url}/organizationSettings`);
          }}
        />
        <GeneralSettingsItem
          title={intl.formatMessage({ id: 'general.labels' })}
          icon={<TagOutlined />}
          selectable
          selected={selectedItemId === 'organizationLabels'}
          onClick={() => {
            history.push(`${url}/organizationLabels`);
          }}
        />
        <GeneralSettingsItem
          title={intl.formatMessage({ id: 'Building.general.aspeEsticon' })}
          icon={<EsticonIcon />}
          selectable
          selected={selectedItemId === 'esticonDbSetting'}
          onClick={() => {
            history.push(`${url}/esticonDbSetting`);
          }}
        />
        <GeneralSettingsItem
          title={intl.formatMessage({ id: 'OrganizationUserPermissionProfiles.title' })}
          icon={<KeyOutlined />}
          selectable
          selected={selectedItemId === 'userPermissionProfiles'}
          onClick={() => {
            history.push(`${url}/userPermissionProfiles`);
          }}
        />
      </GeneralSettingsContainer>
      {organization.hasMdModule && (
        <GeneralSettingsContainer title={intl.formatMessage({ id: 'MD.Organization.sectionTitle' })}>
          <GeneralSettingsItem
            title={intl.formatMessage({ id: 'MD.Organization.divisions.title' })}
            icon={<KeyOutlined />}
            selectable
            selected={selectedItemId === 'mdDivisions'}
            onClick={() => {
              history.push(`${url}/mdDivisions`);
            }}
          />
          <GeneralSettingsItem
            title={intl.formatMessage({ id: 'MD.Organization.roles.title' })}
            icon={<KeyOutlined />}
            selectable
            selected={selectedItemId === 'mdRoles'}
            onClick={() => {
              history.push(`${url}/mdRoles`);
            }}
          />
        </GeneralSettingsContainer>
      )}
      <GeneralSettingsContainer title={intl.formatMessage({ id: 'ProjectSettingsPageGeneral.exports' })}>
        <GeneralSettingsItem
          title={
            <CommonHubTooltip placement="topLeft" title={<Fmt id={'OrganizationDetail.forgeTooltip'} />}>
              <Fmt id="general.forge" />
            </CommonHubTooltip>
          }
          description={
            <Fmt id={'OrganizationDetail.forgeText'} values={{ count: getOrganizationForgeUsage(organization.id) }} />
          }
          additionalActions={<ModelProcessingExportButton organizationId={organization.id} />}
        />
      </GeneralSettingsContainer>
      <GeneralSettingsContainer title={intl.formatMessage({ id: 'general.permission' })}>
        <GeneralSettingsItem
          title={intl.formatMessage({ id: 'OrganizationGeneralTab.title' })}
          icon={<UserAddOutlined />}
          description={intl.formatMessage({ id: 'OrganizationGeneralTab.description' })}
          input={
            <SwitchCheck
              disabled
              className={styles.switch}
              checked={organization.projectAdminCanInviteNewUser}
              label={<Fmt id={organization.projectAdminCanInviteNewUser ? 'general.on' : 'general.off'} />}
              labelOnLeft
            />
          }
        />
      </GeneralSettingsContainer>
    </GeneralSettingsContainer>
  );
};

export default OrganizationGeneralTab;
