import { masterApi } from 'api/completeApi';
import { MdDivisionDto, OrgUserDto } from 'api/completeApiInterfaces';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import { useIntl, useSameCallback } from 'hooks';
import { uniq } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { messageError } from 'utils';
import { MDDivisionForm, MDDivisionFormData } from './MDDivisionForm';

type Props = FormModalProps<MdDivisionDto[]> & {
  editedDivision?: MdDivisionDto;
  organizationId: Guid;
  organizationUsers: OrgUserDto[];
  validateUniqueName: (name: string) => boolean;
};

const MDDivisionFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, editedDivision, organizationId, organizationUsers, validateUniqueName, ...restProps } = props;
  const [saving, setSaving] = useState<boolean>();
  const intl = useIntl();

  const handleSubmit: FormSubmitHandler<MDDivisionFormData> = useSameCallback(async (values) => {
    setSaving(true);

    if (!!editedDivision) {
      const [err, res] = await masterApi.projects.md.division.patch.patch({
        id: editedDivision.id,
        name: values.name,
        organizationName: values.organizationName,
        description: values.description,
        deleteMembersFromRoles: false,
        mdCkIdentifications: values.ckIdentification,
        isProposerOnly: values.isProposerOnly,
        users: {
          headsOrganizationUserIds: values.headsOrganizationUserIds,
          membersOrganizationUserIds: uniq([
            ...editedDivision.mdUsers
              .map((user) => user.orgUser.id)
              .filter((userId) => !values.headsOrganizationUserIds.some((headId) => userId === headId)),
          ]).filter(Boolean),
        },
      });
      if (err) {
        messageError(err, intl);
      } else {
        onSubmit(res.data);
      }
    } else {
      const [err, res] = await masterApi.projects.md.division.add.post({
        organizationId,
        name: values.name,
        description: values.description,
        mdOrganizationName: values.organizationName,
        headsOrganizationUserIds: values.headsOrganizationUserIds,
        mdCkIdentifications: values.ckIdentification,
        isProposerOnly: values.isProposerOnly,
        membersOrganizationUserIds: uniq(values.users || []).filter(
          (userId) => !values.headsOrganizationUserIds.some((headId) => userId === headId)
        ),
      });
      if (err) {
        messageError(err, intl);
      } else {
        onSubmit(res.data);
      }
    }

    setSaving(false);
    return null;
  });

  const isDivisionEditing = !!editedDivision;

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId={
        isDivisionEditing
          ? 'MD.Organization.divisions.modal.title.edit'
          : 'MD.Organization.divisions.modal.title.create'
      }
      submitTextId={isDivisionEditing ? 'general.edit' : 'general.add'}
      forceLoading={saving}
      width={700}
      {...restProps}
    >
      <MDDivisionForm
        defaults={editedDivision}
        organizationUsers={organizationUsers}
        validateUniqueName={validateUniqueName}
        showUserTransfer={!isDivisionEditing}
      />
    </FormModalWrapper>
  );
};

export default MDDivisionFormModal;
