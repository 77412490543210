import { Button, message, Modal, Typography } from 'antd';
import { api } from 'api';
import {
  AssignmentDto,
  AssignmentSolversPatchDto,
  DemandMsgTypeEnum,
  ProjectUserProfileStatusEnum,
} from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import { DeleteButtonConfirm } from 'components/ActionButtons/DeleteButtonConfirm';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { AddIcon } from 'components/Icons/HubActionsIcons';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { UserProfile } from 'components/UserDisplay/UserDisplay';
import { UserNotificationButton } from 'components/UserNotificationButton/UserNotificationButton';
import { groupSelectUserFooter, UserTransfer } from 'components/UserTransfer/UserTransfer';
import { useBoolean, useDispatchEffect, useIntl, useStoreSelector } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import { uniq } from 'lodash';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { groupOrderedListSelector } from 'store/selectors/groupSelectors';
import { projectUsersListSelector } from 'store/selectors/projectUsersSelectors';
import { messageError, processApiError } from 'utils';
import { textComparer } from 'utils/comparators';

type Props = {
  canEdit: boolean;
  canSetPrimarySolver: boolean;
  canSendUserNotification: boolean;
  assignment: AssignmentDto;
  updateAssignment: (assignment: AssignmentDto | null) => void;
};

export const AssignmentSolversField: FunctionComponent<Props> = ({
  canEdit,
  canSetPrimarySolver,
  canSendUserNotification,
  assignment,
  updateAssignment,
}) => {
  const intl = useIntl();

  const [modalVisible, showModal, hideModal] = useBoolean();
  const [selectedUsers, setSelectedUsers] = useState<Guid[]>([]);
  const [saving, setSaving] = useState(false);
  const [removingSolverId, setRemovingSolverId] = useState<Guid>(null);

  const handleShow = () => {
    setSelectedUsers([]);
    showModal();
  };

  const isRemovable = useMemo(() => {
    return assignment.solvers.length > 1;
  }, [assignment]);

  useDispatchEffect((dispatch) => {
    dispatch.projectUsers.loadData({ reload: false });
    dispatch.groups.loadData({ reload: false });
  }, []);

  useDirtyStoreReload(
    (store) => store.projectUsers,
    (dispatch) => dispatch.projectUsers
  );

  useDirtyStoreReload(
    (store) => store.groups,
    (dispatch) => dispatch.groups
  );

  const allUsers = useSelector(projectUsersListSelector);
  const availableUsers = useMemo(
    () =>
      allUsers?.filter(
        (user) =>
          !assignment.solvers.some((solver) => solver.id === user.id) &&
          user.status === ProjectUserProfileStatusEnum.active
      ) || [],
    [allUsers, assignment.solvers]
  );

  const groups = useStoreSelector(groupOrderedListSelector);

  const handleSaveSolvers = async (solverIds: Guid[], primarySolverId?: Guid) => {
    const data: AssignmentSolversPatchDto = {
      solverIds,
      primarySolverId,
    };

    setSaving(true);
    const [err, resp] = await api.project.assignments.updateSolvers(assignment.id, data);
    setSaving(false);
    setRemovingSolverId(null);

    if (err) {
      const error = processApiError(err);
      if (error.referenceErrorCode === ServiceErrorEnum.AssignmentForbiddenError) {
        message.warn(intl.formatMessage({ id: 'AssignmentSolversField.lostAccessWarning' }));
        updateAssignment(null);
      } else {
        messageError(error, intl);
      }
    } else {
      setSelectedUsers([]);
      hideModal();
      updateAssignment(resp.data);
    }
  };

  const handleRemoveSolver = (userId: Guid) => {
    setRemovingSolverId(userId);
    void handleSaveSolvers(assignment.solvers.map((solver) => solver.id).filter((id) => id !== userId));
  };

  const handleAddSolvers = () => {
    void handleSaveSolvers(uniq([...assignment.solvers.map((solver) => solver.id), ...selectedUsers]));
  };

  const createFooter = useMemo(() => groupSelectUserFooter(groups, availableUsers), [groups, availableUsers]);

  const [primarySolverSavingId, setPrimarySolverSavingId] = useState<Guid | null>(null);
  const setPrimarySolver = async (userId: Guid) => {
    const data: AssignmentSolversPatchDto = {
      primarySolverId: userId,
    };

    setPrimarySolverSavingId(userId);
    const [err, resp] = await api.project.assignments.updateSolvers(assignment.id, data);
    setPrimarySolverSavingId(null);

    if (err) {
      messageError(err, intl);
    } else {
      updateAssignment(resp.data);
    }
  };

  return (
    <GeneralSettingsContainer
      title={
        <FlowLayout growFirst>
          <Fmt id="AssignmentForm.solverIds" />
          {canEdit && (
            <Button type="primary" icon={<AddIcon />} onClick={handleShow}>
              <Fmt id="AssignmentSolversField.addSolvers" />
            </Button>
          )}
        </FlowLayout>
      }
    >
      {assignment.solvers.sort(textComparer.map((solver) => solver.username)).map((solver) => (
        <GeneralSettingsItem
          key={solver.id}
          title={<UserProfile user={solver} />}
          additionalActions={
            <>
              {solver.id === assignment.primarySolver?.id ? (
                <Typography.Text strong>
                  <Fmt id="AssignmentSolversField.primarySolver" />
                </Typography.Text>
              ) : (
                canSetPrimarySolver &&
                canEdit &&
                assignment.solvers.length > 1 && (
                  <Button
                    loading={solver.id === primarySolverSavingId}
                    onClick={() => setPrimarySolver(solver.id)}
                    size="small"
                  >
                    <Fmt id="AssignmentSolversField.makePrimary" />
                  </Button>
                )
              )}
              {!!canSendUserNotification && (
                <UserNotificationButton
                  recipientIds={[solver.id]}
                  objectId={assignment.id}
                  demandMsgType={DemandMsgTypeEnum.AssignmentResolveDemand}
                  intl={intl}
                />
              )}
              {canEdit && (
                <DeleteButtonConfirm
                  tooltip={
                    !isRemovable
                      ? intl.formatMessage({ id: 'AssignmentSolversField.cannotRemoveLastSolver' })
                      : solver.id === assignment.primarySolver?.id
                      ? intl.formatMessage({ id: 'AssignmentSolversField.cannotRemovePrimarySolver' })
                      : intl.formatMessage({ id: 'AssignmentSolversField.removeSolver' })
                  }
                  onConfirm={() => handleRemoveSolver(solver.id)}
                  disabled={!isRemovable || solver.id === assignment.primarySolver?.id}
                  loading={solver.id === removingSolverId}
                />
              )}
            </>
          }
        />
      ))}

      <Modal
        open={modalVisible}
        onCancel={hideModal}
        onOk={handleAddSolvers}
        title={<Fmt id="AssignmentSolversField.addSolvers" />}
        confirmLoading={saving}
        width={700}
      >
        <UserTransfer
          users={availableUsers}
          value={selectedUsers}
          onChange={setSelectedUsers}
          createFooter={createFooter}
        />
      </Modal>
    </GeneralSettingsContainer>
  );
};
