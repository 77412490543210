import {
  FileOutlined,
  FolderOutlined,
  InfoCircleOutlined,
  MoreOutlined,
  NumberOutlined,
  SafetyOutlined,
  StopOutlined,
  StrikethroughOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Tag, Tree } from 'antd';
import { DropDownProps } from 'antd/lib/dropdown';
import { MenuProps } from 'antd/lib/menu';
import { AccessLevelEnum, DocumentRevisionStateEnum } from 'api/completeApiInterfaces';
import classNames from 'classnames';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { FolderItemsCounts } from 'components/FolderItemsCounts/FolderItemsCounts';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { MaskItemSimpleDisplay } from 'components/MaskInput/MaskItemSimpleDisplay';
import { FileSystemTreeNode } from 'components/PrimaryFileInput/CommonFilesInputTypes';
import { Fmt } from 'locale';
import numeral from 'numeral';
import React from 'react';
import styles from './CommonFilesInput.module.less';

const DROPDOWN_TRIGGER: DropDownProps['trigger'] = ['click'];

export const getCommonFilesInputTreeNodes = (
  data: FileSystemTreeNode[],
  removed: boolean,
  getTreeItemFileMenu: (isDuplicate: boolean, isRevision: boolean) => MenuProps,
  handleTreeNodeMenuClick: (e: React.MouseEvent<HTMLElement>, item: FileSystemTreeNode) => void,
  treeItemFolderMenu: MenuProps
) => {
  return data.map((item) => {
    const isFile = item.type === 'file';
    const isRevision = item.type === 'file' && item.isRevision && item.duplicate;
    const isRevisionBlocked =
      item.type === 'file' &&
      item.isRevision &&
      !!item.revisionStatus &&
      item.revisionStatus !== DocumentRevisionStateEnum.Ok;

    const errors = item.type === 'folder' ? item.errors : [];

    const title = (
      <div key={item.key} className={classNames(styles.itemTree)}>
        <div className={classNames(styles.content)}>
          <div className={styles.icons}>
            {item.duplicate && !isRevision && (
              <CommonHubTooltip
                className={styles.alreadyExists}
                title={
                  isFile ? (
                    <Fmt id="CommonFilesInput.duplicateDocuments.tooltip" />
                  ) : (
                    <Fmt id="CommonFilesInput.duplicateFolders.tooltip" />
                  )
                }
              >
                {isFile ? <WarningOutlined /> : <InfoCircleOutlined />}
              </CommonHubTooltip>
            )}
            {isRevision && (
              <CommonHubTooltip className={styles.revision} title={<Fmt id="CommonFilesInput.revision.tooltip" />}>
                <NumberOutlined />
              </CommonHubTooltip>
            )}
            {!!errors?.length && (
              <CommonHubTooltip
                className={styles.errors}
                title={
                  <>
                    {errors.map((error) => (
                      <div key={error.fullPath}>
                        {error.type === 'file' ? <FileOutlined /> : <FolderOutlined />}{' '}
                        <Fmt id={error.messageId} values={{ name: error.name }} />
                      </div>
                    ))}
                  </>
                }
              >
                <WarningOutlined />
              </CommonHubTooltip>
            )}
            {item.type === 'file' && isRevisionBlocked && (
              <CommonHubTooltip
                className={styles.forbidden}
                title={<Fmt id={`RevisionStatus.tooltips.${item.revisionStatus}.text`} />}
              >
                <StopOutlined />
              </CommonHubTooltip>
            )}
            {!!item.accessRight && (
              <CommonHubTooltip
                className={classNames(
                  item.accessRight === AccessLevelEnum.write
                    ? styles.writable
                    : item.accessRight === AccessLevelEnum.admin
                    ? styles.fullAccess
                    : styles.forbidden
                )}
                title={
                  <>
                    <Fmt id="AccessLevel.label" />
                    {': '}
                    <Fmt id={`AccessLevel.tooltips.${item.accessRight}.label`} />
                  </>
                }
              >
                <SafetyOutlined />
              </CommonHubTooltip>
            )}
            {item.notWritable && (
              <CommonHubTooltip
                className={styles.forbidden}
                title={
                  isFile ? (
                    <Fmt id="CommonFilesInput.notWritableDocument.tooltip" />
                  ) : (
                    <Fmt id="CommonFilesInput.notWritableDirectory.tooltip" />
                  )
                }
              >
                <StopOutlined />
              </CommonHubTooltip>
            )}
            {item.invalidName && !item.notWritable && (
              <CommonHubTooltip
                className={styles.forbidden}
                title={
                  isFile ? (
                    <Fmt id="CommonFilesInput.invalidDocumentName.tooltip" />
                  ) : (
                    <Fmt id="CommonFilesInput.invalidDirectoryName.tooltip" />
                  )
                }
              >
                <StopOutlined />
              </CommonHubTooltip>
            )}
            {item.invalidNameByMask && !item.invalidNameByMask.isValid && (
              <CommonHubTooltip
                className={styles.forbidden}
                title={
                  <div>
                    <Fmt id="CommonFilesInput.invalidNameByMask.tooltip" />
                    <FlowLayout wrap>
                      {item.invalidNameByMask.mask?.map((item) => (
                        <Tag key={item.id}>
                          <MaskItemSimpleDisplay item={item} />
                        </Tag>
                      ))}
                    </FlowLayout>
                  </div>
                }
              >
                <StrikethroughOutlined />
              </CommonHubTooltip>
            )}
          </div>
          <div className={classNames(styles.info, item.removed && styles.removed)}>
            <span className={classNames(styles.name)} title={item.title}>
              {item.title}
            </span>
          </div>
          {item.type === 'folder' && (
            <FolderItemsCounts
              className={styles.countInfo}
              filesCount={item.filesCount}
              foldersCount={item.foldersCount}
            />
          )}
          {item.type === 'file' && <span className={styles.size}>{numeral(item.file?.size).format('0.0 b')}</span>}

          <Dropdown
            menu={
              item.type === 'folder'
                ? treeItemFolderMenu
                : getTreeItemFileMenu(item.duplicate && !isRevision, item.duplicate && isRevision)
            }
            placement={'bottomRight'}
            trigger={DROPDOWN_TRIGGER}
          >
            <Button
              type="default"
              size="small"
              shape="circle"
              icon={<MoreOutlined />}
              className={styles.menuMore}
              onClick={(e) => handleTreeNodeMenuClick(e, item)}
            />
          </Dropdown>
        </div>
      </div>
    );

    return (
      <Tree.TreeNode key={item.key} title={<div className={styles.titleWrap}>{title}</div>} isLeaf={isFile}>
        {item.type === 'folder' &&
          item.children?.length &&
          getCommonFilesInputTreeNodes(
            item?.children,
            removed,
            getTreeItemFileMenu,
            handleTreeNodeMenuClick,
            treeItemFolderMenu
          )}
      </Tree.TreeNode>
    );
  });
};
