import { to } from 'api/await-to';
import { projectApi } from 'api/completeApi';
import {
  AccessLevelEnum,
  DirectoryContentDto,
  DirectoryContentsExDto,
  DirectoryCreateDto,
  DirectoryDetailDto,
  DirectoryDownloadDto,
  DirectoryDto,
  DirectoryGroupDto,
  DirectoryGroupPatchDto,
  DirectoryLinkCreateDto,
  DirectoryLinkDto,
  DirectoryLinkListDto,
  DirectoryLinkMoveDto,
  DirectoryLinkPatchDto,
  DirectoryListDto,
  DirectoryMoveDto,
  DirectoryOverviewDto,
  DirectoryPatchDto,
  DirectoryPermissionDto,
  DirectoryPermissionPatchDto,
  DirectoryReportSettingsDto,
  DirectoryRestoreResultDto,
  DirectorySubscriberEnumsWatchDirectoryEnum,
  DirectoryUserDto,
  DirectoryUserPatchDto,
  DiscardedDirectoryDto,
  DMSDownloadDto,
  DocumentsDiscardDto,
  DocumentsMultipleRestoreStrategyEnum,
  DownloadUrl,
  ProjectMetadataExportRequestDto,
} from 'api/completeApiInterfaces';
import { apiResponseToMappedResult, Result } from 'api/errors';
import { CancelToken } from 'axios';
import { arrayMapper, identityMapper, Mapper, objectMapper } from 'utils/mappers';
import { baseProjectApi } from '../baseProjectApi';

export type DirectoryLink = {
  linkId: Guid;
  linkName: string;
  parentDirectoryId: Guid;
  linkedDirectoryId: Guid;
};

const directoryLinkDtoToDirectoryLink: Mapper<DirectoryLinkListDto, DirectoryLink> = (directoryLinkDto) => ({
  linkId: directoryLinkDto.id,
  linkName: directoryLinkDto.name,
  parentDirectoryId: directoryLinkDto.directoryId,
  linkedDirectoryId: directoryLinkDto.linkedDirectoryId,
});

export const directoriesApi = {
  listDirectories: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<DirectoryListDto[]>(`/directories/primary`, { cancelToken })
    ),
  listDirectoriesWithLinks: (
    cancelToken?: CancelToken
  ): Promise<Result<{ directories: DirectoryListDto[]; directoryLinks: DirectoryLink[] }>> =>
    projectApi.directories.primary.listdirectoriesex.get(cancelToken).then((res) =>
      apiResponseToMappedResult(
        res,
        objectMapper({
          directories: identityMapper,
          directoryLinks: arrayMapper(directoryLinkDtoToDirectoryLink),
        })
      )
    ),
  createDirectory: (data: DirectoryCreateDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<DirectoryDto>(`/directories/primary`, data, { cancelToken })
    ),
  getDirectoryById: (directoryId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<DirectoryDetailDto>(`/directories/primary/${directoryId}`, { cancelToken })
    ),
  patchDirectory: (directoryId: Guid, dir: DirectoryPatchDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<void>(`/directories/primary/${directoryId}`, dir, { cancelToken })
    ),
  patchDirectoryLink: (directoryLinkId: Guid, data: DirectoryLinkPatchDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<DirectoryLinkDto>(`/directorylink/${directoryLinkId}`, data, { cancelToken })
    ),
  moveDirectory: (directoryId: Guid, data: DirectoryMoveDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<DirectoryListDto[]>(`/directories/primary/${directoryId}/move`, data, { cancelToken })
    ),
  moveDirectoryLink: (directoryId: Guid, data: DirectoryLinkMoveDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<DirectoryLinkDto>(`/directorylink/${directoryId}/move`, data, { cancelToken })
    ),
  getDirectoryContent: (directoryId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<DirectoryContentDto[]>(`/directories/primary/${directoryId}/content`, { cancelToken })
    ),
  getDirectoryContentEx: (directoryId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<DirectoryContentsExDto>(`/directories/primary/${directoryId}/contentex`, { cancelToken })
    ),
  getDirectoryOverview: (directoryId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<DirectoryOverviewDto>(`/directories/primary/${directoryId}/overview`, { cancelToken })
    ),
  addRequiredCategory: (directoryId: Guid, categoryTreeId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<void>(
        `/directories/primary/${directoryId}/categorytrees/${categoryTreeId}`,
        {},
        { cancelToken }
      )
    ),
  deleteRequiredCategory: (directoryId: Guid, categoryTreeId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.delete<void>(`/directories/primary/${directoryId}/categorytrees/${categoryTreeId}`, {
        cancelToken,
      })
    ),
  getDirectoryDownloadUrl: (directoryId: Guid, directoryDownloadDto: DirectoryDownloadDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<DownloadUrl>(`/directories/primary/${directoryId}/download`, directoryDownloadDto, {
        cancelToken,
      })
    ),

  getDirectoryPermissions: (directoryId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<DirectoryPermissionDto>(`/directories/primary/${directoryId}/permissions`, { cancelToken })
    ),
  setDirectoryPermissions: (directoryId: Guid, data: DirectoryPermissionPatchDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<DirectoryPermissionDto>(`/directories/primary/${directoryId}/permissions`, data, {
        cancelToken,
      })
    ),

  getDirectoryGroupsAccess: (directoryId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<DirectoryGroupDto[]>(`/directories/primary/${directoryId}/groups`, { cancelToken })
    ),
  setDirectoryGroupsAccessLevel: (directoryId: Guid, data: DirectoryGroupPatchDto[], cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<DirectoryGroupDto[]>(`/directories/primary/${directoryId}/groups`, data, { cancelToken })
    ),
  setDirectoryGroupAccessLevel: (
    directoryId: Guid,
    groupId: Guid,
    accessLevel: AccessLevelEnum,
    cancelToken?: CancelToken
  ) =>
    to(
      baseProjectApi.post(`/directories/primary/${directoryId}/groups/${groupId}`, null, {
        params: { accessLevel },
        cancelToken,
      })
    ),
  deleteDirectoryGroupAccessLevel: (directoryId: Guid, groupId: Guid, cancelToken?: CancelToken) =>
    to(baseProjectApi.delete(`/directories/primary/${directoryId}/groups/${groupId}`, { cancelToken })),
  getDirectoryUsersAccess: (directoryId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<DirectoryUserDto[]>(`/directories/primary/${directoryId}/users`, { cancelToken })
    ),
  setDirectoryUsersAccessLevel: (directoryId: Guid, data: DirectoryUserPatchDto[], cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<DirectoryUserDto[]>(`/directories/primary/${directoryId}/users`, data, { cancelToken })
    ),
  setDirectoryUserAccessLevel: (
    directoryId: Guid,
    userId: Guid,
    accessLevel: AccessLevelEnum,
    cancelToken?: CancelToken
  ) =>
    to(
      baseProjectApi.post(`/directories/primary/${directoryId}/users/${userId}`, null, {
        params: { accessLevel },
        cancelToken,
      })
    ),
  deleteDirectoryUserAccessLevel: (directoryId: Guid, userId: Guid, cancelToken?: CancelToken) =>
    to(baseProjectApi.delete(`/directories/primary/${directoryId}/users/${userId}`, { cancelToken })),
  addFavoriteDirectory: (directoryId: Guid, cancelToken?: CancelToken) =>
    to(baseProjectApi.post(`/directories/primary/favorite/${directoryId}`, { cancelToken })),
  removeFavoriteDirectory: (directoryId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.delete<void>(`/directories/primary/favorite/${directoryId}`, { cancelToken })
    ),

  requestAccess: (directoryId: Guid, newAccessLevel: AccessLevelEnum, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<void>(`/directories/primary/${directoryId}/accessrequest`, null, {
        params: { newAccessLevel },
        cancelToken,
      })
    ),
  getDiscardedDirectories: (cancelToken?: CancelToken) =>
    to(
      baseProjectApi.get<DiscardedDirectoryDto[]>(`/directories/discarded`, { cancelToken })
    ),
  discardDirectory: (directoryId: Guid, removeLinks: boolean, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.delete(`/directories/primary/${directoryId}`, {
        params: { removeLinks: removeLinks },
        cancelToken,
      })
    ),
  discardDirectoryLink: (directoryLinkId: Guid, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.delete(`/directorylink/${directoryLinkId}`, {
        cancelToken,
      })
    ),
  restoreDirectory: (directoryId: Guid, strategy: DocumentsMultipleRestoreStrategyEnum, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<DirectoryRestoreResultDto>(`/directories/primary/${directoryId}/restore`, null, {
        params: { strategy },
        cancelToken,
      })
    ),
  discardDocuments: (directoryId: Guid, documentsDiscardDto: DocumentsDiscardDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.patch<void>(`/directories/primary/${directoryId}/discarddocuments`, documentsDiscardDto, {
        cancelToken,
      })
    ),
  setDirectoryWatch: (directoryId: Guid, type: DirectorySubscriberEnumsWatchDirectoryEnum, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.put<void>(`/directories/primary/${directoryId}/watch?type=${type}`, null, { cancelToken })
    ),
  exportDirectoryReport: (directoryId: Guid, settings: DirectoryReportSettingsDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<string>(`/directories/primary/${directoryId}/report`, settings, {
        cancelToken,
        responseType: 'blob',
      })
    ),
  createDirectoryLink: (data: DirectoryLinkCreateDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<void>(`/directoryLink/adddirectoryLink`, data, { cancelToken })
    ),
  getDirectoryDownloadWithSubdirectories: (data: DMSDownloadDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<DownloadUrl>(`/directories/dms/download`, data, { cancelToken })
    ),
  exportDirectoryMetadata: (directoryId: Guid, data: ProjectMetadataExportRequestDto, cancelToken?: CancelToken) =>
    to(
      baseProjectApi.post<string>(`/metadata/export/${directoryId}`, data, { cancelToken, responseType: 'blob' })
    ),
};
