import React, { useCallback, useEffect, useState } from 'react';

type TreeNode = {
  key: string;
  children?: TreeNode[];
};

const getAllTreeKeys = (treeData: TreeNode[]) => {
  const keys: string[] = [];
  if (!treeData) return keys;

  const traverse = (nodes: TreeNode[]) => {
    nodes.forEach((node) => {
      keys.push(node.key);
      if (node.children) {
        traverse(node.children);
      }
    });
  };
  traverse(treeData);
  return keys;
};

export type AutoExpandAll = 'autoExpandAll' | 'noAutoExpandAll' | 'autoExpandAllOnFirstLoad';

export const useTreeExpandedKeys = (treeData: any[], autoExpandAll: AutoExpandAll = 'noAutoExpandAll') => {
  const [expandedNodeKeys, setExpandedNodeKeys] = useState<string[]>([]);

  const handleOnExpand = useCallback((keys) => {
    setExpandedNodeKeys(keys);
  }, []);

  const expandAll = useCallback(() => {
    const keys = getAllTreeKeys(treeData);
    setExpandedNodeKeys(keys);
  }, [treeData]);

  const autoExpandedOnFirstLoadRef = React.useRef(false);

  useEffect(() => {
    if (autoExpandAll === 'autoExpandAll') {
      expandAll();
    }
    if (autoExpandAll === 'autoExpandAllOnFirstLoad' && !autoExpandedOnFirstLoadRef.current && !!treeData?.length) {
      expandAll();
      autoExpandedOnFirstLoadRef.current = true;
    }
  }, [expandAll]);

  return [expandedNodeKeys, handleOnExpand, expandAll] as const;
};
