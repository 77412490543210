import {
  AppstoreOutlined,
  BarsOutlined,
  FilePdfOutlined,
  FilterOutlined,
  FolderOutlined,
  FormOutlined,
  GlobalOutlined,
  PlayCircleOutlined,
  SettingOutlined,
  SolutionOutlined,
  StarOutlined,
} from '@ant-design/icons';
import {
  EstiConProjectStructureCreation,
  ExtendedPermissionEnum,
  ExternalApplicationSettingDto,
  ProjectSettingExternalAppSettingsEnum,
} from 'api/completeApiInterfaces';
import { DeleteIcon } from 'components/Icons/HubActionsIcons';
import {
  ChangeOrderIcon,
  CommentProcedureIcon,
  ConstructionJournalIcon,
  EstimateIcon,
  InvoicingIcon,
  ModelOffIcon,
  ScheduleIcon,
  WorkInProgressIcon,
  WorkflowIcon,
  WorkflowTemplateIcon,
} from 'components/Icons/HubEntitiesIcons';
import SideMenu, { MenuItemType, SubmenuItemType } from 'components/SideMenu/SideMenu';
import { SideMenuKey } from 'components/SideMenuActivator';
import { useActiveProject, useCurrentProjectUser, useIntl, useSelectorDispatch } from 'hooks';
import { useInitialLocalStorage } from 'hooks/useDefaultLocalStorage';
import { useEsticon } from 'hooks/useEsticon';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import { EsticonIcon } from 'pages/ProjectSettingsPage/ExternApps/Esticon/EsticonListItem/EsticonIcon';
import { ADMIN_AREA_PERMISSIONS } from 'pages/ProjectSettingsPage/ProjectSettingsPage';
import React, { FunctionComponent, useMemo } from 'react';
import { InjectedIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { ProjectRouteParams } from 'routes';
import { userHasPermission, userHasSomePermissions } from 'utils/userHasPermission';

type Props = RouteComponentProps<ProjectRouteParams> & {
  sideMenuActiveItem: SideMenuKey | Guid;
  sideMenuCollapsed: boolean;
  setSideMenuCollapsed: (collapsed: boolean) => void;
};

const DEFAULT_OPEN_KEYS = [SideMenuKey.DOCUMENTS];
const LOCAL_STORAGE_OPEN_KEYS_KEY = 'projectMenuOpenKeys';

const externalAppMenuItemMapper = (setting: ExternalApplicationSettingDto, intl: InjectedIntl): SubmenuItemType => {
  switch (setting.type) {
    case ProjectSettingExternalAppSettingsEnum.constructionJournal: {
      const url = 'url' in setting.data && setting.data.url;
      return {
        key: setting.id,
        parentKey: SideMenuKey.EXTERNAL_APPLICATION,
        icon: <ConstructionJournalIcon />,
        label: intl.formatMessage({ id: 'menu.side.constructionJournal' }),
        disabled: !url,
        externalAppUrl: url,
      };
    }
    case ProjectSettingExternalAppSettingsEnum.pageUrl: {
      const url = 'url' in setting.data && setting.data.url;
      const openInNewTab = 'openInNewTab' in setting.data ? setting.data.openInNewTab : true;

      return {
        key: setting.id,
        parentKey: SideMenuKey.EXTERNAL_APPLICATION,
        icon: <GlobalOutlined />,
        label: setting.name,
        disabled: !url,
        externalAppUrl: url,
        openInNewTab,
      };
    }
    case ProjectSettingExternalAppSettingsEnum.document:
      return {
        key: setting.id,
        parentKey: SideMenuKey.EXTERNAL_APPLICATION,
        icon: <FilePdfOutlined />,
        label: setting.name,
        path: `/externalapplication/${setting.id}`,
      };
    case ProjectSettingExternalAppSettingsEnum.textPage:
      return {
        key: setting.id,
        parentKey: SideMenuKey.EXTERNAL_APPLICATION,
        icon: <FormOutlined />,
        label: setting.name,
        path: `/externalapplication/${setting.id}`,
      };
  }
};

export const ProjectModuleSideMenu: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const activeProject = useActiveProject();
  const currentProjectUser = useCurrentProjectUser();
  const { esticon } = useEsticon();
  const isByElements: boolean = esticon?.projectStructureType === EstiConProjectStructureCreation.Elementy;
  const hasStavbaPermission =
    currentProjectUser.isAdmin || userHasPermission(currentProjectUser, ExtendedPermissionEnum.stavba);
  const hasProjectLinkedEsticon = !!activeProject.esticonFirmId && !!activeProject.esticonProjectId;
  const { sideMenuActiveItem, setSideMenuCollapsed, sideMenuCollapsed, match } = props;

  const showSettings =
    currentProjectUser?.isAdmin || userHasSomePermissions(currentProjectUser, ADMIN_AREA_PERMISSIONS);
  const externalApps = useSelectorDispatch(
    (state) => state.externalApplicationsSettings.data?.settings || [],
    (dispatch) => dispatch.externalApplicationsSettings.loadData({ reload: true })
  );

  useDirtyStoreReload(
    (store) => store.externalApplicationsSettings,
    (dispatch) => dispatch.externalApplicationsSettings
  );

  const [openKeys, initialOpenKeys, saveDefaultOpenKeys] = useInitialLocalStorage(
    LOCAL_STORAGE_OPEN_KEYS_KEY,
    DEFAULT_OPEN_KEYS
  );

  const bottomMenuItems = useMemo(() => {
    const items: MenuItemType[] = showSettings
      ? [
          {
            key: SideMenuKey.SETTINGS,
            icon: <SettingOutlined />,
            label: intl.formatMessage({ id: 'general.projectSettings' }),
            path: '/settings/general',
          },
        ]
      : [];
    return items;
  }, [intl, showSettings]);

  const projectMenuTree = useMemo(
    (): MenuItemType[] => [
      {
        key: SideMenuKey.DASHBOARD,
        icon: <AppstoreOutlined />,
        label: intl.formatMessage({ id: 'menu.side.dashboard' }),
        path: '',
      },
      {
        key: SideMenuKey.DOCUMENTS,
        label: intl.formatMessage({ id: 'menu.side.documents' }),
        icon: <FolderOutlined />,
        children: [
          {
            key: SideMenuKey.DOCUMENTS_ALL,
            icon: <BarsOutlined />,
            label: intl.formatMessage({ id: 'menu.side.documents.all' }),
            path: '/directories',
          },
          {
            key: SideMenuKey.DOCUMENTS_FAVORITE,
            icon: <StarOutlined />,
            label: intl.formatMessage({ id: 'menu.side.documents.favorite' }),
            path: '/documents/favorite',
          },
          {
            key: SideMenuKey.DOCUMENTS_FILTERS,
            icon: <FilterOutlined />,
            label: intl.formatMessage({ id: 'menu.side.documents.filters' }),
            path: '/documents/filters',
            isSub: true,
          },
          {
            key: SideMenuKey.DOCUMENTS_DISCARDED,
            icon: <DeleteIcon />,
            label: intl.formatMessage({ id: 'menu.side.documents.discarded' }),
            path: '/documents/discarded',
            isSub: true,
          },
        ],
      },
      {
        key: SideMenuKey.WORKFLOW_GROUP,
        icon: <WorkflowIcon />,
        label: intl.formatMessage({ id: 'menu.side.workflow' }),
        children: [
          {
            key: SideMenuKey.WORKFLOW,
            icon: <PlayCircleOutlined />,
            label: intl.formatMessage({ id: 'menu.side.workflow.instances' }),
            path: '/workflow',
            isSub: true,
          },
          {
            key: SideMenuKey.WORKFLOW_TEMPLATES,
            icon: <WorkflowTemplateIcon />,
            label: intl.formatMessage({ id: 'menu.side.workflow.templates' }),
            path: '/workflow/templates',
            isSub: true,
          },
          activeProject.organization.hasSZModule && {
            key: SideMenuKey.WORKFLOW_PROCESSES,
            icon: <SolutionOutlined />,
            label: intl.formatMessage({ id: 'menu.side.workflow.processes' }),
            path: '/workflow/formprocess',
            isSub: true,
          },
        ].filter(Boolean),
      },
      {
        key: SideMenuKey.COMMENT_PROCEDURES,
        icon: <CommentProcedureIcon />,
        label: intl.formatMessage({ id: 'menu.side.comment.procedure' }),
        path: '/comment',
      },
      {
        key: SideMenuKey.MODEL,
        icon: <ModelOffIcon />,
        label: intl.formatMessage({ id: 'menu.side.model' }),
        path: '/model',
      },
      {
        key: SideMenuKey.CONSTRUCTION,
        icon: <EsticonIcon monochrome />,
        label: intl.formatMessage({ id: 'menu.side.construction' }),
        disabled: !hasStavbaPermission || !hasProjectLinkedEsticon,
        disabledText: <Fmt id="serviceError.ForbiddenError" />,
        children: [
          {
            key: SideMenuKey.CONSTRUCTION_BUDGET,
            icon: <EstimateIcon />,
            label: intl.formatMessage({ id: 'menu.side.budget' }),
            path: '/construction/budget',
            disabled: !hasStavbaPermission || !hasProjectLinkedEsticon,
            parentKey: SideMenuKey.CONSTRUCTION,
          },
          {
            key: SideMenuKey.CONSTRUCTION_ZBV,
            icon: <ChangeOrderIcon />,
            label: intl.formatMessage({ id: 'menu.side.zbv' }),
            path: '/construction/zbv',
            parentKey: SideMenuKey.CONSTRUCTION,
            disabled: isByElements || !hasStavbaPermission || !hasProjectLinkedEsticon,
          },
          {
            key: SideMenuKey.CONSTRUCTION_DRAWING,
            icon: <WorkInProgressIcon />,
            label: intl.formatMessage({ id: 'menu.side.drawing' }),
            path: '/construction/drawing',
            parentKey: SideMenuKey.CONSTRUCTION,
            disabled: isByElements || !hasStavbaPermission || !hasProjectLinkedEsticon,
          },
          {
            key: SideMenuKey.CONSTRUCTION_INVOICING,
            icon: <InvoicingIcon />,
            label: intl.formatMessage({ id: 'menu.side.invoicing' }),
            path: '/construction/invoicing',
            disabled: !hasStavbaPermission || !hasProjectLinkedEsticon,
            parentKey: SideMenuKey.CONSTRUCTION,
          },

          {
            key: SideMenuKey.CONSTRUCTION_SCHEDULE,
            icon: <ScheduleIcon />,
            label: intl.formatMessage({ id: 'menu.side.schedule' }),
            path: '/schedule',
            disabled: !hasStavbaPermission || !hasProjectLinkedEsticon,
            parentKey: SideMenuKey.CONSTRUCTION,
          },
          // {
          //   key: SideMenuKey.CONSTRUCTION_FINANCIAL_PLAN,
          //   icon: <FinancialPlanIcon />,
          //   label: intl.formatMessage({ id: 'menu.side.financialPlan' }),
          //   path: '/financialPlan',
          //   disabled: true,
          //   parentKey: SideMenuKey.CONSTRUCTION,
          // },  TODO: usable, prepared to suzerain command
        ],
      },
      {
        key: SideMenuKey.EXTERNAL_APPLICATION,
        icon: <ConstructionJournalIcon />,
        label: intl.formatMessage({ id: 'menu.side.externalApplications' }),
        disabled: !externalApps.length,
        children: externalApps.map((setting) => externalAppMenuItemMapper(setting, intl)),
      },
    ],
    [intl, hasStavbaPermission, hasProjectLinkedEsticon, isByElements, externalApps]
  );

  return (
    <SideMenu
      menuItemsConfig={projectMenuTree}
      setSideMenuCollapsed={setSideMenuCollapsed}
      sideMenuCollapsed={sideMenuCollapsed}
      bottomMenuItemsConfig={bottomMenuItems}
      sideMenuActiveItem={sideMenuActiveItem}
      defaultOpenKeys={initialOpenKeys}
      onOpenKeysChanged={saveDefaultOpenKeys}
      url={match.url}
    />
  );
};
