import { Form, Input, InputRef } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import Select from 'antd/lib/select';
import { OrgExtendedPermissionEnum } from 'api/completeApiInterfaces';
import { Serializable } from 'components/filters/filterTypes';
import { PersonalReportWidgetConfiguration } from 'components/Reports/contexts/ReportWidgetsContextProvider';
import StackPanel from 'components/StackPanel';
import { MAX_ITEM_NAME_LENGTH } from 'config/constants';
import { useIntl } from 'hooks';
import { RequestCacheApiCallback } from 'hooks/useCachedApiRequest';
import { Fmt } from 'locale';
import { ProjectsInRealisationOverviewReportData } from 'pages/ReportDetailPage/ReportDetails/ProjectsInRealisationOverviewReport/ProjectsInRealizationOverviewReportUtils';
import React, { FC } from 'react';
import { maxLengthRule, requiredRule } from 'utils/formHelpers';
import ProjectsInRealizationConfigurationForm from './ReportWidgetConfigurations/ProjectsInRealizationConfigurationForm';
import { ProjectsInRealizationReportConfigurationColumnFormItem } from './ReportWidgetConfigurations/ProjectsInRealizationReportConfigurationColumnFormItem';
import { ProjectsInRealizationFiltersFormItem } from './ReportWidgetFilterInputs/ProjectsInRealizationFilterFormItem';
import styles from './ReportWidgetForm.module.less';
import {
  getDecimalUnitOptions,
  getLabelAlignOptions,
  ReportWidgetColumnConfiguration,
  ReportWidgetDecimalUnitEnum,
  ReportWidgetLabelAlignEnum,
} from './ReportWidgetForm.utils';

export type ReportWidgetEditFormData = {
  name: string;
  viewDecimalUnit: ReportWidgetDecimalUnitEnum;
  filters: Record<string, Serializable>;
  labelAlign: ReportWidgetLabelAlignEnum;
} & {
  reportType: OrgExtendedPermissionEnum.projectsInRealisationOverview;
  estiConnId: Guid;
  esticonFirmId: Guid;
  reportDate: string;
  view: Guid;
  startYear: number;
  columnConfigurations: ReportWidgetColumnConfiguration<ProjectsInRealisationOverviewReportData>[];
};

type Props = {
  configuration: PersonalReportWidgetConfiguration;
  setRef: (ref: InputRef) => void;
  requestCacheApiData: RequestCacheApiCallback;
};

const ReportWidgetEditForm: FC<Props> = ({ configuration, requestCacheApiData, setRef }) => {
  const intl = useIntl();

  const reportDate = useWatch('reportDate');
  const startYear = useWatch('startYear');
  const esticonFirmId = useWatch('esticonFirmId');
  const estiConnId = useWatch('estiConnId');

  const decimalUnitOptions = getDecimalUnitOptions(intl);
  const labelAlignOptions = getLabelAlignOptions(intl);

  return (
    <>
      <StackPanel>
        <StackPanel vertical className={styles.generalConfig}>
          <Form.Item
            label={intl.formatMessage({ id: 'forms.items.name.label' })}
            name="name"
            rules={[
              requiredRule('ProjectCreateForm.form.items.name.rules.required', true),
              maxLengthRule('general.maxNameLength', MAX_ITEM_NAME_LENGTH),
            ]}
            initialValue={configuration.title}
          >
            <Input
              placeholder={intl.formatMessage({ id: 'ProjectCreateForm.form.items.name.placeholder' })}
              autoFocus
              ref={setRef}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ id: 'ReportWidgetCreateFormModalForm.unit' })}
            name="viewDecimalUnit"
            initialValue={configuration.data.viewDecimalUnit}
            rules={[requiredRule('ReportWidgetCreateFormModalForm.unit.required', true)]}
          >
            <Select options={decimalUnitOptions} />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ id: 'ReportWidgetCreateFormModalForm.alignLabel' })}
            name="labelAlign"
            initialValue={configuration.data.labelAlign}
            rules={[requiredRule('ReportWidgetCreateFormModalForm.alignLabel.required', true)]}
          >
            <Select options={labelAlignOptions} />
          </Form.Item>
          {configuration.data.reportType === OrgExtendedPermissionEnum.projectsInRealisationOverview && (
            <ProjectsInRealizationConfigurationForm
              organizationId={configuration.data.organizationId}
              defaultValues={configuration.data}
            />
          )}
        </StackPanel>
        <StackPanel vertical>
          {configuration.data.reportType === OrgExtendedPermissionEnum.projectsInRealisationOverview && (
            <>
              <Form.Item
                label={<Fmt id="ReportWidgetCreateFormModalForm.filters" />}
                name="filters"
                initialValue={configuration.data.filters}
              >
                <ProjectsInRealizationFiltersFormItem
                  requestCacheApiData={requestCacheApiData}
                  organizationId={configuration.data.organizationId}
                  reportDate={reportDate}
                  startYear={startYear}
                  esticonFirmId={esticonFirmId}
                  estiConnId={estiConnId}
                />
              </Form.Item>
              <Form.Item
                label={<Fmt id="ProjectsInRealizationOverviewReport.reportSelection.columnConfiguration" />}
                name="columnConfigurations"
                rules={[requiredRule('ProjectsInRealizationOverviewReport.reportSelection.required.date', false)]}
                initialValue={configuration.data.columnConfigurations || []}
              >
                <ProjectsInRealizationReportConfigurationColumnFormItem intl={intl} columnCountLimit={6} />
              </Form.Item>
            </>
          )}
        </StackPanel>
      </StackPanel>
    </>
  );
};

export default ReportWidgetEditForm;
