import { CloseOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Tag } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { TransferItem, TransferListProps } from 'antd/lib/transfer';
import { ApiPromise } from 'api/await-to';
import { DownloadUrl, ProjectUserProfileListDto } from 'api/completeApiInterfaces';
import classNames from 'classnames';
import AuthorRelationWrapper from 'components/AuthorRelationWrapper/AuthorRelationWrapper';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { RelativeDateDisplay } from 'components/RelativeDateDisplay/RelativeDateDisplay';
import SpinBoxCenter from 'components/SpinBoxCenter';
import { UserAvatar } from 'components/avatars/UserAvatar/UserAvatar';
import { UserAvatarSize } from 'components/avatars/UserIcon/UserIcon';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useCurrentProjectUser, useIntl } from 'hooks';
import { Fmt } from 'locale';
import moment from 'moment';
import React, { FunctionComponent, ReactNode, useCallback, useMemo, useState } from 'react';
import { AttachmentWarning, DiscussionAttachment, DiscussionInput } from '../DiscussionInput/DiscussionInput';
import DiscussionInputAttachments from '../DiscussionInputAttachments/DiscussionInputAttachments';
import {
  StagedAttachment,
  attachmentMapper,
  getLinkedAttachments,
} from '../DiscussionInputAttachments/DiscussionInputAttachments.utils';
import styles from './DiscussionNote.module.less';

export type DiscussionNoteData = {
  id: Guid;
  title: string;
  message?: string;
  isDiscarded: boolean;
  createdDate: IsoDateTime;
  modifiedDate: IsoDateTime;
  discardedDate: IsoDateTime;
  createdBy: ProjectUserProfileListDto;
  modifiedBy: ProjectUserProfileListDto;
  discardedBy: ProjectUserProfileListDto;
  canUserEdit: boolean;
  attachments: Guid[];
  notifiedUsers?: ProjectUserProfileListDto[];
  authorColors?: string[];
};

type Props = {
  disabled?: boolean;
  note: DiscussionNoteData;
  onEdit?: (
    noteId: Guid,
    message: string,
    linkedAttachments: Guid[],
    stagedAttachments: StagedAttachment[]
  ) => Promise<boolean>;
  onDelete?: (noteId: Guid) => Promise<boolean>;
  onRestore?: (noteId: Guid) => Promise<boolean>;
  availableAttachments?: DiscussionAttachment[];
  getOriginalUrl?: (attachmentId: Guid) => ApiPromise<DownloadUrl>;
  availableUsers: ProjectUserProfileListDto[];
  createUserTransferFooter: (
    value: Guid[],
    setSelectedKeys: React.Dispatch<React.SetStateAction<Guid[]>>
  ) => (footerProps: TransferListProps<TransferItem>) => ReactNode;
  className?: string;
  validateLinkedAttachments?: (attachmentIds: Guid[]) => AttachmentWarning;
};

export const DiscussionNote: FunctionComponent<Props> = ({
  disabled,
  note,
  onEdit,
  onDelete,
  onRestore,
  availableAttachments,
  getOriginalUrl,
  availableUsers,
  createUserTransferFooter,
  className,
  validateLinkedAttachments,
}) => {
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const currentUser = useCurrentProjectUser();
  const intl = useIntl();

  const isMyNote = note.createdBy?.id === currentUser.id;

  const linkedDocuments = useMemo(
    () => getLinkedAttachments(availableAttachments, note.attachments).map(attachmentMapper),
    [availableAttachments, note]
  );

  const defaultAttachments = useMemo(() => linkedDocuments.map((f) => f.id), [linkedDocuments]);

  const onStartEdit = useCallback(() => {
    setEditing(true);
  }, []);

  const handleDelete = useCallback(async () => {
    if (onDelete) {
      setLoading(true);
      await onDelete(note.id);
      setLoading(false);
    }
  }, [note, onDelete]);

  const handleEdit = useCallback(
    async (message: string, linkedAttachments: Guid[], stagedAttachments: StagedAttachment[]) => {
      if (onEdit) {
        setLoading(true);
        const result = await onEdit(note.id, message, linkedAttachments, stagedAttachments);
        if (result) {
          setEditing(false);
        }
        setLoading(false);
      }
    },
    [note, onEdit]
  );

  const handleRestore = useCallback(async () => {
    if (onRestore) {
      setLoading(true);
      await onRestore(note.id);
      setLoading(false);
    }
  }, [note, onRestore]);

  const handleClose = useCallback(() => {
    setEditing(false);
  }, []);

  const noteCreatedBy = note?.createdBy.username;

  const noteModificationInfo = useMemo(() => {
    if (note.isDiscarded) {
      const formattedDate = moment(note.discardedDate)
        .locale(intl.locale)
        .format('lll');
      return (
        <div className={styles.subInfo}>
          <CommonHubTooltip
            title={
              <>
                <div>{note && note.discardedBy?.username}</div>
                <div>{formattedDate}</div>
              </>
            }
          >
            <div>
              <Fmt id="general.deleted" />
            </div>
          </CommonHubTooltip>
        </div>
      );
    } else if (note.modifiedBy) {
      const formattedDate = moment(note.modifiedDate)
        .locale(intl.locale)
        .format('lll');
      return (
        <div className={styles.subInfo}>
          <CommonHubTooltip
            title={
              <>
                <div>{note && note.modifiedBy.username}</div>
                <div>{formattedDate}</div>
              </>
            }
          >
            <div>
              <Fmt id="general.modified" />
            </div>
          </CommonHubTooltip>
        </div>
      );
    } else return null;
  }, [note, intl]);

  const discussionNoteMenuItems = useMemo((): MenuProps => {
    const items: ItemType[] = [
      {
        key: 'edit',
        label: intl.formatMessage({ id: 'DiscussionNote.edit' }),
        icon: <EditOutlined />,
        onClick: onStartEdit,
      },
      {
        key: 'delete',
        label: intl.formatMessage({ id: 'DiscussionNote.delete' }),
        icon: <DeleteOutlined />,
        onClick: handleDelete,
      },
    ];
    return { items };
  }, [handleDelete, intl, onStartEdit]);

  return (
    <div className={classNames(styles.comment, isMyNote && styles.myComment, editing && styles.editing)}>
      <SpinBoxCenter spinning={loading}>
        <div className={styles.messageInnerWrap}>
          <div className={styles.header}>
            {!isMyNote && (
              <FlowLayout>
                <AuthorRelationWrapper appliedColors={note.authorColors}>
                  <UserAvatar user={note.createdBy} size={UserAvatarSize.Small} />
                </AuthorRelationWrapper>
                <div className={styles.author}>{noteCreatedBy}</div>
              </FlowLayout>
            )}
            <div className={styles.subInfo}>
              <RelativeDateDisplay date={note.createdDate} tooltipTranslationKey={'general.createdDate'} />
            </div>
            {noteModificationInfo}
            {!note.isDiscarded && !disabled && (
              <div className={styles.menuContainer}>
                {!editing ? (
                  <Dropdown menu={discussionNoteMenuItems} placement={'bottomRight'}>
                    <Button
                      type="link"
                      size="small"
                      icon={<EllipsisOutlined rotate={90} style={{ cursor: 'pointer' }} />}
                    />
                  </Dropdown>
                ) : (
                  <div className={styles.closeEdit}>
                    <CommonHubTooltip title={<Fmt id="DiscussionNote.closeEditing" />}>
                      <CloseOutlined onClick={handleClose} />
                    </CommonHubTooltip>
                  </div>
                )}
              </div>
            )}
          </div>
          {note.isDiscarded ? (
            <div className={styles.discardedNote}>
              <Fmt id="DiscussionNote.deletedNote" />
              <Button type={'link'} onClick={handleRestore} disabled={disabled}>
                <Fmt id="DiscussionNote.restore" />
              </Button>
            </div>
          ) : editing ? (
            <div className={styles.commentInput}>
              <DiscussionInput
                onSend={handleEdit}
                defaultMessage={note.message}
                defaultAttachments={defaultAttachments}
                disabled={disabled}
                onEscape={handleClose}
                availableUsers={availableUsers}
                createUserTransferFooter={createUserTransferFooter}
                getOriginalUrl={getOriginalUrl}
                availableAttachments={availableAttachments}
                validateLinkedAttachments={validateLinkedAttachments}
              />
            </div>
          ) : (
            <>
              <div className={classNames(styles.message, className)}>
                {!!note.notifiedUsers?.length && (
                  <div className={styles.notifiedUsers}>
                    <Fmt id="DiscussionNote.Notification.noticeWasSentTo" />
                    {note.notifiedUsers.map((user) => (
                      <Tag key={user.id}>{user.username}</Tag>
                    ))}
                  </div>
                )}
                <div>{note.message}</div>
              </div>
              {!!linkedDocuments?.length && (
                <DiscussionInputAttachments attachments={linkedDocuments} getOriginalUrl={getOriginalUrl} />
              )}
            </>
          )}
        </div>
      </SpinBoxCenter>
    </div>
  );
};
