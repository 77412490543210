import { Form, Input, Typography } from 'antd';
import { SharedReportWidgetConfiguration } from 'components/Reports/contexts/ReportWidgetsContextProvider';
import { MAX_ITEM_NAME_LENGTH } from 'config/constants';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FC } from 'react';
import { maxLengthRule } from 'utils/formHelpers';

export type SharedReportWidgetEditFormData = {
  name: string;
};

type Props = {
  configuration: SharedReportWidgetConfiguration;
};

const SharedReportWidgetEditForm: FC<Props> = ({ configuration }) => {
  const intl = useIntl();

  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.name.label' })}
        name="name"
        initialValue={configuration.title}
        rules={[maxLengthRule('general.maxNameLength', MAX_ITEM_NAME_LENGTH)]}
      >
        <Input placeholder={intl.formatMessage({ id: 'ReportWidgetCreateFormModalForm.name.placeholder' })} />
      </Form.Item>
      <Typography.Paragraph type="secondary">
        <Fmt id="ReportWidgetCreateFormModalForm.name.info" />
      </Typography.Paragraph>
    </>
  );
};

export default SharedReportWidgetEditForm;
