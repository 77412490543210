import { InputRef } from 'antd';
import { api } from 'api';
import { DirectoryPatchDto } from 'api/completeApiInterfaces';
import { useFocus } from 'hooks/useFocus';
import { trim } from 'lodash';
import React, { FunctionComponent } from 'react';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import DirectoryEditForm, { DirectoryEditFormData } from './DirectoryEditForm';

type Props = FormModalProps<void> & {
  directoryId: Guid;
  name: string;
  description: string;
  validateUniqueName: (name: string) => boolean;
};

const DirectoryEditFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, name, description, directoryId, validateUniqueName, ...restProps } = props;

  const handleSubmit: FormSubmitHandler<DirectoryEditFormData> = async (values) => {
    const data: DirectoryPatchDto = {
      name: trim(values.name),
      description: values.description,
    };

    const [err] = await api.project.directories.patchDirectory(directoryId, data);
    if (err) return err;

    await onSubmit();
    return null;
  };

  const { setInputRef } = useFocus<InputRef>(restProps.open);

  return (
    <FormModalWrapper onSubmit={handleSubmit} titleId="DirectoryEditFormModal.title" {...restProps}>
      <DirectoryEditForm
        validateUniqueName={validateUniqueName}
        initialData={{ name, description }}
        setRef={setInputRef}
      />
    </FormModalWrapper>
  );
};

export default DirectoryEditFormModal;
