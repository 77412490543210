import { projectApi } from 'api/completeApi';
import { JToken, ProjectMetadataValuesDto } from 'api/completeApiInterfaces';
import { useApiData, useIntl } from 'hooks';
import { Dictionary } from 'lodash';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { messageError } from 'utils';

export const useDirectoryMetadataList = (directoryId: Guid) => {
  const intl = useIntl();

  const [directoryMetadata, metadataError, metadataLoading, loadDirectoryMetadata, setDirectoryMetadata] = useApiData(
    (ct) => projectApi.metadata.directory.id.get(directoryId, ct),
    { autoload: false }
  );

  useEffect(() => {
    loadDirectoryMetadata();
  }, [directoryId]);

  const metadataItems = useMemo(() => directoryMetadata?.values || [], [directoryMetadata]);

  const handleSave = useCallback(
    async (savingValue: Dictionary<JToken>, id: Guid): Promise<boolean | ReactNode> => {
      const data: ProjectMetadataValuesDto = {
        values: metadataItems
          .map((item) => (item.metadataDefinitionId === id ? { ...item, value: savingValue } : item))
          .filter((item) => !!item.value),
      };

      const [err, resp] = await projectApi.metadata.directory.id.patch(directoryId, data);
      if (!!err) {
        messageError(err, intl);
      } else {
        setDirectoryMetadata(resp.data);
      }

      return null;
    },
    [directoryId, intl, metadataItems, setDirectoryMetadata]
  );

  return {
    metadataItems,
    metadataLoading,
    metadataError,
    loadDirectoryMetadata,
    handleSave,
  };
};
