import { CheckCircleOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { useActiveProject } from 'hooks';
import { Fmt } from 'locale';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { matchPath, useLocation } from 'react-router-dom';

export const useNotificationMenuItems = (totalNewMessages: number, totalActiveAssignments: number) => {
  const history = useHistory();
  const location = useLocation();
  const projectId = useActiveProject()?.id;

  const handleMessagesClick = useCallback(() => {
    history.push(
      `${
        projectId && !!matchPath(location.pathname, '/projects/:id') ? `/projects/${projectId}` : ''
      }/messagecenter/messages`
    );
  }, [projectId]);

  const handleTasksClick = useCallback(() => {
    history.push(
      `${
        projectId && !!matchPath(location.pathname, '/projects/:id') ? `/projects/${projectId}` : ''
      }/messagecenter/assignments`
    );
  }, [projectId]);

  const handleSettingsClick = useCallback(() => {
    history.push(
      `${
        projectId && !!matchPath(location.pathname, '/projects/:id') ? `/projects/${projectId}` : ''
      }/messagecenter/settings`
    );
  }, [projectId]);

  const menuItems = useMemo((): ItemType[] => {
    return [
      {
        key: 'groupNotifications',
        label: <Fmt id="MessageCenterPage.title" />,
        disabled: true,
        style: { cursor: 'default' },
      },
      {
        key: 'messages',
        onClick: handleMessagesClick,
        icon: <MailOutlined />,
        label: (
          <>
            <Fmt id="MessageCenterPage.notification.messages" />
            {totalNewMessages > 0 && <>{` (${totalNewMessages})`}</>}
          </>
        ),
      },
      {
        key: 'tasks',
        onClick: handleTasksClick,
        icon: <CheckCircleOutlined />,
        label: (
          <>
            <Fmt id="MessageCenterPage.notification.tasks" />
            {totalActiveAssignments > 0 && <>{` (${totalActiveAssignments})`}</>}
          </>
        ),
      },
      {
        key: 'userSettings',
        onClick: handleSettingsClick,
        icon: <SettingOutlined />,
        label: <Fmt id="MessageCenterPage.notification.settings" />,
      },
    ];
  }, [handleMessagesClick, handleSettingsClick, handleTasksClick, totalActiveAssignments, totalNewMessages]);

  return menuItems;
};
