import { projectApi } from 'api/completeApi';
import { FilterViewPresetDto, FilterViewPresetListDto, FilterViewPresetPatchDto } from 'api/completeApiInterfaces';
import { CommonFilterWithValue } from 'components/filters/filterTypes';
import { FilterPresetData } from 'components/filters/render/FilterPreset/FilterPreset';
import { Fmt } from 'locale';
import React, { FunctionComponent, useMemo } from 'react';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import FilterPresetEditForm, { FilterPresetEditFormData } from './FilterPresetEditForm';

type Props = FormModalProps<FilterViewPresetListDto> & {
  preset: FilterViewPresetDto;
  filters: CommonFilterWithValue[];
};
const FilterPresetEditFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, filters, preset, ...restProps } = props;

  const handleSubmit: FormSubmitHandler<FilterPresetEditFormData> = async (values) => {
    const patchData: FilterViewPresetPatchDto = {
      name: values.presetName,
      filterData: {
        filters: values.filters,
      } as FilterPresetData,
    };
    const [err, res] = await projectApi.projectsetting.filterviewpreset.id.patch(preset.id, patchData);
    if (err) return err;
    onSubmit(res.data);
    return null;
  };

  const defaultFilterValues = useMemo(() => (preset.filterData as FilterPresetData).filters, [preset]);

  return (
    <FormModalWrapper
      title={<Fmt id="FilterPresetEditFormModal.title" />}
      onSubmit={handleSubmit}
      submitTextId="general.save"
      width={800}
      {...restProps}
    >
      <FilterPresetEditForm presetName={preset.name} presetValue={defaultFilterValues} filters={filters} />
    </FormModalWrapper>
  );
};

export default FilterPresetEditFormModal;
