import { ServiceErrorEnum } from 'api/errors';
import DirectoryForbiddenError, {
  DirectoryForbiddenErrorData,
} from 'components/DirectoryForbiddenErrorBox/DirectoryForbiddenError';
import ItemNotFoundErrorBox from 'components/ItemNotFoundErrorBox';
import PathDisplay from 'components/PathDisplay';
import ServiceErrorBox from 'components/ServiceErrorBox';
import SpinBox from 'components/SpinBox';
import { useActiveProject } from 'hooks';
import { Fmt } from 'locale';
import { useLoadCommentProcedure } from 'pages/DocumentDetailPage/features/useLoadCommentProcedure';
import { useLoadDirectory } from 'pages/DocumentDetailPage/features/useLoadDirectory';
import { useLoadDocument } from 'pages/DocumentDetailPage/features/useLoadDocument';
import { useLoadDocumentRevisions } from 'pages/DocumentDetailPage/features/useLoadDocumentRevisions';
import { useLoadLinks } from 'pages/DocumentDetailPage/tabs/links/useLoadLinks';
import React, { createContext, FC } from 'react';

type DocumentDetailContextType = ReturnType<typeof useLoadDocument> &
  ReturnType<typeof useLoadDocumentRevisions> &
  ReturnType<typeof useLoadDirectory> &
  ReturnType<typeof useLoadLinks> &
  ReturnType<typeof useLoadCommentProcedure> & { organizationId: Guid };

export const DocumentDetailContext = createContext<DocumentDetailContextType>(null);

type DocumentDetailContextProviderProps = {
  documentId: Guid;
};

export default DocumentDetailContext;

export const useDocumentDetailContext = () => {
  const context = React.useContext(DocumentDetailContext);
  if (!context) {
    throw new Error('useDocumentDetailContext must be used within a DocumentDetailContextProvider');
  }
  return context;
};

export const DocumentDetailContextProvider: FC<DocumentDetailContextProviderProps> = ({ children, documentId }) => {
  const loadDocumentProps = useLoadDocument(documentId);
  const loadRevisionsProps = useLoadDocumentRevisions(documentId);
  const directoryProps = useLoadDirectory(loadDocumentProps?.document?.directoryId);
  const linksProps = useLoadLinks(documentId);
  const commentProcedureProps = useLoadCommentProcedure(loadDocumentProps?.document?.commentProcedureId);
  const activeProject = useActiveProject();
  const projectId = activeProject?.id;
  const organizationId = activeProject?.organization.id;

  const documentError = loadDocumentProps.documentError;

  if (documentError) {
    if (documentError.referenceErrorCode === ServiceErrorEnum.NotFoundError) {
      return <ItemNotFoundErrorBox error={documentError} projectId={projectId} />;
    } else if (documentError.referenceErrorCode === ServiceErrorEnum.DirectoryInsufficientAccessLevelError) {
      const errorData = documentError.errorData as DirectoryForbiddenErrorData;
      const { directoryId, directoryPath } = errorData || {};
      const additionalMessage = (
        <Fmt
          id="DocumentDetailPage.directoryForbiddenMessage"
          values={{ path: <PathDisplay path={directoryPath} inline /> }}
        />
      );
      return (
        <DirectoryForbiddenError
          showRequestDialog
          selectedDirectoryId={directoryId}
          additionalDescription={additionalMessage}
        />
      );
    } else {
      return <ServiceErrorBox error={documentError} />;
    }
  } else if (!loadDocumentProps.document && !loadRevisionsProps.revisions) {
    return <SpinBox />;
  } else {
    return (
      <DocumentDetailContext.Provider
        value={{
          ...loadDocumentProps,
          ...loadRevisionsProps,
          ...directoryProps,
          ...linksProps,
          ...commentProcedureProps,
          organizationId,
        }}
      >
        {children}
      </DocumentDetailContext.Provider>
    );
  }
};
