import { masterApi } from 'api/completeApi';
import { MdDivisionDto, MdRoleDto, MdRoleEnum, MdUserDto } from 'api/completeApiInterfaces';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import { useIntl, useSameCallback } from 'hooks';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { messageError } from 'utils';
import { MDRoleForm, MDRoleFormData } from './MDRoleForm';

type Props = FormModalProps<MdRoleDto[]> & {
  editedRole?: MdRoleDto;
  usedRoleUsers: MdUserDto[];
  divisions: MdDivisionDto[];
  roleType: MdRoleEnum;
  existingRoleLeaderIds: Guid[];
  headDivisionUsers: MdUserDto[];
};

const MDRoleFormModal: FunctionComponent<Props> = (props) => {
  const {
    onSubmit,
    editedRole,
    divisions,
    roleType,
    existingRoleLeaderIds,
    headDivisionUsers,
    usedRoleUsers,
    ...restProps
  } = props;
  const [saving, setSaving] = useState<boolean>();
  const intl = useIntl();

  const handleSubmit: FormSubmitHandler<MDRoleFormData> = useSameCallback(async (values) => {
    setSaving(true);

    if (!!editedRole) {
      const [err, res] = await masterApi.projects.md.role.patch.patch({
        id: editedRole.id,
        headMdUserId: values.headMdUserId,
        mdUserIds: values.mdUserIds,
        mdCkIdentifications: roleType === MdRoleEnum.ck_organizer ? values.ckIdentification : undefined,
      });
      if (err) {
        messageError(err, intl);
      } else {
        onSubmit(res.data);
      }
    } else {
      const [err, res] = await masterApi.projects.md.role.add.post({
        mdUserIds: values.mdUserIds.filter((id) => id !== values.headMdUserId),
        headMdUserId: values.headMdUserId,
        divisionId: values.divisionId,
        mdRoleType: roleType,
      });
      if (err) {
        messageError(err, intl);
      } else {
        onSubmit(res.data);
      }
    }

    setSaving(false);
    return null;
  });

  const isHeadSuppervisorRole = useMemo(
    () =>
      editedRole?.mdRoleType === MdRoleEnum.supervisor &&
      headDivisionUsers.some((user) => user.id === editedRole.head.id),
    [editedRole, headDivisionUsers]
  );

  const isRoleEditing = !!editedRole;

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId={isRoleEditing ? 'MD.Organization.roles.modal.title.edit' : 'MD.Organization.roles.modal.title.create'}
      submitTextId={isRoleEditing ? 'general.edit' : 'general.add'}
      forceLoading={saving}
      width={700}
      {...restProps}
    >
      <MDRoleForm
        defaults={editedRole}
        divisions={divisions}
        usedRoleUsers={usedRoleUsers}
        existingRoleLeaderIds={existingRoleLeaderIds}
        disableHeadUserEdit={isHeadSuppervisorRole}
        roleType={roleType}
      />
    </FormModalWrapper>
  );
};

export default MDRoleFormModal;
