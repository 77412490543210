import { WarningOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { api } from 'api';
import { RoleDto, ServiceError, UserRemoveStrategyEnum } from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import { RoleAssignFormData, RoleAssignFormModal } from 'components/forms/RoleAssignForm';
import { RoleInProcedureError, RoleUsageError } from 'components/forms/RoleForm/RoleUsageError';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { AddIcon, DeleteIcon } from 'components/Icons/HubActionsIcons';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import ServiceErrorBox from 'components/ServiceErrorBox';
import StackPanel from 'components/StackPanel';
import { useItemsSet } from 'hooks';
import { Fmt, InjectedIntlProps } from 'locale';
import PageContent from 'pages/ProjectSettingsPage/PageContent';
import Panel from 'pages/ProjectSettingsPage/Panel/Panel';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { messageError, processApiError, smartFilter } from 'utils';
import { modalConfirm } from 'utils/modalConfirm';

type Props = InjectedIntlProps & {
  userId: Guid;
  roles: RoleDto[];
  userRolesSet: Set<Guid>;
  error?: ServiceError;
  onChange?: (userId: Guid, role: RoleDto) => void;
};

const RolesTab: FunctionComponent<Props> = ({ intl, userId, roles, userRolesSet, error, onChange }) => {
  const [savingItems, addSavingItem, deleteSavingItem] = useItemsSet<Guid>();
  useEffect(() => () => deleteSavingItem(), [userId]); // after change clear saving indicators
  const [formModalVisible, setFormModalVisible] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();

  const handleSubmit = useCallback(
    async (data: RoleAssignFormData, role: RoleDto) => {
      onChange && onChange(userId, role);
      setFormModalVisible(false);
    },
    [onChange, userId, setFormModalVisible]
  );

  const handleRemove = useCallback(
    async (userId: Guid, roleId: Guid) => {
      addSavingItem(roleId);

      let [err, response] = await api.project.roles.updateRoleById(roleId, {
        name: null,
        removeUser: true,
        strategy: null,
      });
      const error = err && processApiError(err);

      if (error && error.referenceErrorCode === ServiceErrorEnum.RoleInProcedureError) {
        const data = error.errorData as { roleErrors: RoleInProcedureError[] };

        const approve = await modalConfirm({
          title: intl.formatMessage({ id: 'UserDetailPanel.RolesTab.ConfirmRemove.question' }),
          icon: <WarningOutlined />,
          content: <RoleUsageError roleErrors={data.roleErrors} intl={intl} />,
        });

        if (!approve) {
          deleteSavingItem(roleId);
          return;
        }

        [err, response] = await api.project.roles.updateRoleById(roleId, {
          name: null,
          removeUser: true,
          strategy: UserRemoveStrategyEnum.remove,
        });
      }

      if (err) {
        messageError(err, intl);
      } else {
        onChange && onChange(userId, response.data);
      }

      deleteSavingItem(roleId);
    },
    [intl, addSavingItem, deleteSavingItem, onChange]
  );

  const { url } = useRouteMatch();

  if (error) return <ServiceErrorBox error={error} />;

  return (
    <>
      <RoleAssignFormModal
        open={formModalVisible}
        userId={userId}
        roles={roles}
        onSubmit={handleSubmit}
        onClose={() => setFormModalVisible(false)}
        userRolesSet={userRolesSet}
        intl={intl}
      />
      <MasterComponent
        url={url}
        title={intl.formatMessage({ id: 'UserDetailPanel.RolesTab.rolesListTitle' })}
        children={() => (
          <StackPanel vertical>
            <GeneralSettingsItem
              title={<Fmt id="UserDetailPanel.RolesTab.AddRole.title" />}
              description={<Fmt id="UserDetailPanel.RolesTab.AddRole.description" />}
              input={
                <Button icon={<AddIcon />} type="primary" onClick={() => setFormModalVisible(true)}>
                  <Fmt id="UserDetailPanel.RolesTab.AddRole" />
                </Button>
              }
            />

            <PageContent>
              <Panel onSearch={setSearch} searchValue={search}>
                <StackPanel scrollable vertical>
                  <GeneralSettingsContainer>
                    <List<RoleDto>
                      data={roles}
                      search={search}
                      filterItem={(item) => item && smartFilter(item?.name, search)}
                      renderItem={(item) =>
                        userRolesSet.has(item.id) && (
                          <GeneralSettingsItem
                            key={item.id}
                            title={item.name}
                            description={item.description}
                            additionalActions={
                              <Button
                                type="link"
                                danger
                                onClick={() => handleRemove(userId, item.id)}
                                icon={<DeleteIcon />}
                                loading={savingItems.has(item.id)}
                              />
                            }
                          />
                        )
                      }
                      renderEmpty={(total, filtered) => (
                        <ListEmpty filtered={filtered} total={total} onClearSearch={() => setSearch(undefined)} />
                      )}
                    />
                  </GeneralSettingsContainer>
                </StackPanel>
              </Panel>
            </PageContent>
          </StackPanel>
        )}
      />
    </>
  );
};

export default RolesTab;
