import { JSONVariableTypeEnum } from 'api/completeApiInterfaces';
import JSONVariableInputNumberDiffFormItem, {
  JSONVariableInputNumberDiffProps,
} from 'components/JSONVariableFormItems/Items/JSONVariableInputNumberDiffFormItem';
import JSONVariableStringDiffFormItem, {
  JSONVariableStringDiffProps,
} from 'components/JSONVariableFormItems/Items/JSONVariableStringDiffFormItem';

import moment from 'moment';
import React, { FunctionComponent } from 'react';
import JSONVariableCheckboxFormItem, { JSONVariableBooleanProps } from './Items/JSONVariableCheckboxFormItem';
import JSONVariableConditionsFormItem, { JSONVariableCkConditionsProps } from './Items/JSONVariableConditionsFormItem';
import JSONVariableDatePickerFormItem, { JSONVariableDateProps } from './Items/JSONVariableDatePickerFormItem';
import JSONVariableDateRangePickerFormItem, {
  JSONVariableDateRangeProps,
} from './Items/JSONVariableDateRangePickerFormItem';
import JSONVariableInputFormItem, { JSONVariableInputProps } from './Items/JSONVariableInputFormItem';
import JSONVariableInputNumberFormItem, { JSONVariableInputNumberProps } from './Items/JSONVariableInputNumberFormItem';
import JSONVariableLinkFormItem, { JSONVariableLinkProps } from './Items/JSONVariableLinkFormItem';

import JSONVariableBuildingIdentificationFormItem, {
  JSONVariableBuildingIdentificationProps,
} from './Items/JSONVariableBuildingIdentificationFormItem';
import JSONVariableInputInvestorContactPersonFormItem, {
  JSONVariableInputInvestorContactPersonProps,
} from './Items/JSONVariableInputInvestorContactPersonFormItem';
import JSONVariableOrganizationStructureWithUserFormItem, {
  JSONVariableOrganizationStructureWithUserProps,
} from './Items/JSONVariableOrganizationStructureWithUserFormItem';
import JSONVariableProjectLinkSelectorFormItem, {
  JSONVariableProjectLinkProps,
} from './Items/JSONVariableProjectLinkSelectorFormItem';
import JSONVariableSelectFormItem, { JSONVariableSelectEnumProps } from './Items/JSONVariableSelectFormItem';
import JSONVariableTextAreaFormItem, { JSONVariableTextAreaProps } from './Items/JSONVariableTextAreaFormItem';
import { JSONVariable } from './JSONVariableTypes';

type JSONVariableProps = { unit?: string } & (
  | JSONVariableInputProps
  | JSONVariableInputInvestorContactPersonProps
  | JSONVariableTextAreaProps
  | JSONVariableStringDiffProps
  | JSONVariableInputNumberProps
  | JSONVariableInputNumberDiffProps
  | JSONVariableBooleanProps
  | JSONVariableDateProps
  | JSONVariableProjectLinkProps
  | JSONVariableDateRangeProps
  | JSONVariableLinkProps
  | JSONVariableCkConditionsProps
  | JSONVariableSelectEnumProps
  | JSONVariableOrganizationStructureWithUserProps
  | JSONVariableBuildingIdentificationProps
);

export const getDefaultJSONVariableFormValue = (variable: JSONVariableTypeEnum): JSONVariable => {
  switch (variable) {
    case JSONVariableTypeEnum.number:
      return { type: JSONVariableTypeEnum.number, value: null };
    case JSONVariableTypeEnum.numberDiff:
      return { type: JSONVariableTypeEnum.numberDiff, value: null, oldValue: null };
    case JSONVariableTypeEnum.boolean:
      return { type: JSONVariableTypeEnum.boolean, value: 'false' };
    case JSONVariableTypeEnum.date:
      return { type: JSONVariableTypeEnum.date, value: moment().toISOString() };
    case JSONVariableTypeEnum.interval:
      return {
        type: JSONVariableTypeEnum.interval,
        valueFrom: moment()
          .startOf('month')
          .toISOString(),
        valueTo: moment()
          .endOf('month')
          .toISOString(),
      };
    case JSONVariableTypeEnum.link:
      return { type: JSONVariableTypeEnum.link, value: '', label: '' };
    case JSONVariableTypeEnum.enum:
      return { type: JSONVariableTypeEnum.enum, value: '', enumType: '' };
    case JSONVariableTypeEnum.stringDiff:
      return { type: JSONVariableTypeEnum.stringDiff, value: '', oldValue: '' };
    case JSONVariableTypeEnum.orgStructureWithUser:
      return { type: JSONVariableTypeEnum.orgStructureWithUser, valueStructureId: undefined, valueUserId: undefined };
    case JSONVariableTypeEnum.buildingIdentification:
      return { type: JSONVariableTypeEnum.buildingIdentification, value: [] };
    case JSONVariableTypeEnum.string:
    default:
      return { type: JSONVariableTypeEnum.string, value: '' };
  }
};

const JSONVariableFormItem: FunctionComponent<JSONVariableProps> = ({ ...props }) => {
  switch (props.dataType) {
    case JSONVariableTypeEnum.string:
      return props.itemType === 'textarea' ? (
        <JSONVariableTextAreaFormItem {...props} />
      ) : props.itemType === 'investorContactPerson' ? (
        <JSONVariableInputInvestorContactPersonFormItem {...props} />
      ) : (
        <JSONVariableInputFormItem {...props} />
      );
    case JSONVariableTypeEnum.stringDiff:
      return <JSONVariableStringDiffFormItem {...props} />;
    case JSONVariableTypeEnum.number:
      return <JSONVariableInputNumberFormItem {...props} />;
    case JSONVariableTypeEnum.numberDiff:
      return <JSONVariableInputNumberDiffFormItem {...props} />;
    case JSONVariableTypeEnum.boolean:
      return <JSONVariableCheckboxFormItem {...props} />;
    case JSONVariableTypeEnum.date:
      return <JSONVariableDatePickerFormItem {...props} />;
    case JSONVariableTypeEnum.interval:
      return <JSONVariableDateRangePickerFormItem {...props} />;
    case JSONVariableTypeEnum.hubLink:
      return <JSONVariableProjectLinkSelectorFormItem {...props} />;
    case JSONVariableTypeEnum.link:
      return <JSONVariableLinkFormItem {...props} />;
    case JSONVariableTypeEnum.enum:
      return <JSONVariableSelectFormItem {...props} />;
    case JSONVariableTypeEnum.ckApprovalConditions:
      return <JSONVariableConditionsFormItem {...props} />;
    case JSONVariableTypeEnum.orgStructureWithUser:
      return <JSONVariableOrganizationStructureWithUserFormItem {...props} />;
    case JSONVariableTypeEnum.buildingIdentification:
      return <JSONVariableBuildingIdentificationFormItem {...props} />;
  }
  return undefined;
};

export default React.memo(JSONVariableFormItem);
