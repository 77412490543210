import { UploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { masterApi } from 'api/completeApi';
import {
  EntityTypesEnum,
  ExtendedPermissionTemplateDto,
  OrgUserDto,
  ProjectTemplateDto,
  ProjectTemplateListDto,
} from 'api/completeApiInterfaces';
import AuditLogMasterApiEntityModal from 'components/AuditLogsComponents/AuditLogMasterApiEntityModal';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import { NoClickTooltip } from 'components/NoClickTooltip/NoClickTooltip';
import StackPanel from 'components/StackPanel';
import { useApiData, useBoolean, useIntl } from 'hooks';
import { Fmt, InjectedIntlProps } from 'locale';
import Panel from 'pages/ProjectSettingsPage/Panel/Panel';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { messageError } from 'utils';
import ProjectTemplateAddModal from './ProjectTemplateAddModal';
import ProjectTemplateEditModal from './ProjectTemplateEditModal';
import ProjectTemplatesList from './ProjectTemplateList';
import { ProjectTemplateSelectSourceProjectFormData } from './ProjectTemplateSelectSourceProjectForm';
import ProjectTemplateSelectSourceProjectFormModal from './ProjectTemplateSelectSourceProjectFormModal';

type Props = InjectedIntlProps & {
  organizationId: Guid;
  organizationUsers: OrgUserDto[];
  organizationTimezoneId: string;
  organizationPermissionProfiles: ExtendedPermissionTemplateDto[];
  reloadOrganizationUsers: () => void;
};

const ProjectsTemplateListPanel: FunctionComponent<Props> = ({
  organizationId,
  organizationUsers,
  organizationTimezoneId,
  organizationPermissionProfiles,
  reloadOrganizationUsers,
}) => {
  const [search, setSearch] = useState<string>('');
  const [addProjectTemplateModalVisible, showAddProjectTemplateModal, hideAddProjectTemplateModal] = useBoolean(false);
  const [
    selectProjectForTemplateModalVisible,
    showSelectProjectForTemplateModal,
    hideSelectProjectForTemplateModal,
  ] = useBoolean(false);
  const [editProjectTemplateModalVisible, showEditProjectTemplateModal, hideEditProjectTemplateModal] = useBoolean(
    false
  );
  const [projectToEditId, setProjectToEditId] = useState<Guid>(undefined);
  const [projectTemplatePreset, setProjectTemplatePreset] = useState<ProjectTemplateDto>();
  const [toShowActivityTemplate, setToShowActivityTemplate] = useState<ProjectTemplateListDto>();
  const { url } = useRouteMatch();
  const intl = useIntl();

  const [projectTemplates, templatesError, templatesLoading, loadProjectTemplates] = useApiData(
    (ct) => masterApi.projects.tempates.projecttemplatelist.id.get(organizationId, ct),
    { autoload: false }
  );

  useEffect(() => {
    loadProjectTemplates();
  }, [organizationId]);

  const handleProjectTemplateCreateSubmit = useCallback(() => {
    hideAddProjectTemplateModal();
    loadProjectTemplates();
  }, []);

  const handleProjectTemplateCreateClose = useCallback(() => {
    hideAddProjectTemplateModal();
  }, []);

  const handleProjectEditShow = useCallback((editedId: Guid) => {
    setProjectToEditId(editedId);
    showEditProjectTemplateModal();
  }, []);

  const handleProjectTemplateEditSubmit = useCallback(() => {
    setProjectToEditId(undefined);
    loadProjectTemplates();
    hideEditProjectTemplateModal();
  }, []);

  const handleProjectTemplateEditClose = useCallback(() => {
    setProjectToEditId(undefined);
    hideEditProjectTemplateModal();
  }, []);

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const handleTemplateDelete = useCallback(
    async (templateId: Guid) => {
      const [err, res] = await masterApi.projects.tempates.projecttemplate.id.delete(templateId);
      if (err) {
        messageError(err, intl);
      } else {
        loadProjectTemplates();
      }
    },
    [intl, loadProjectTemplates]
  );

  const handleCreateTemplateFromProject = useCallback(
    async (data: ProjectTemplateSelectSourceProjectFormData) => {
      const [err, res] = await masterApi.projects.tempates.templatefromproject.post({
        projectId: data.projectId,
        tryAssignExistingNamedExtendedPermission: data.tryUserPermissionPairig,
      });
      if (err) {
        messageError(err, intl);
      } else {
        setProjectTemplatePreset(res.data);
        hideSelectProjectForTemplateModal();
        showAddProjectTemplateModal();
      }

      return;
    },
    [hideSelectProjectForTemplateModal, intl, showAddProjectTemplateModal]
  );

  const ImportFromTemplateButton = useMemo(
    () => (
      <NoClickTooltip title={<Fmt id="ProjectTemplateSelectSourceProject.title" />}>
        <Button type="link" icon={<UploadOutlined />} onClick={showSelectProjectForTemplateModal} />
      </NoClickTooltip>
    ),
    [showSelectProjectForTemplateModal]
  );

  const hideAuditLog = useCallback(() => {
    setToShowActivityTemplate(undefined);
  }, []);

  return (
    <>
      <MasterComponent
        url={url}
        title={intl.formatMessage({ id: 'ProjectTemplates.title' })}
        children={(onSelect, selectedItemId) => (
          <StackPanel>
            <Panel
              noMargin
              addButtonOnClick={showAddProjectTemplateModal}
              addButtonText={<Fmt id="ProjectTemplates.list.createProjectTemplate" />}
              onSearch={setSearch}
              searchValue={search}
              additionalItems={ImportFromTemplateButton}
            >
              <ContentGate empty={!projectTemplates?.length} loading={templatesLoading} error={templatesError}>
                <ProjectTemplatesList
                  projectTemplates={projectTemplates}
                  search={search}
                  onClearSearch={clearSearch}
                  onEdit={handleProjectEditShow}
                  onDelete={handleTemplateDelete}
                  onShowAuditLog={setToShowActivityTemplate}
                />
              </ContentGate>
              {!!addProjectTemplateModalVisible && (
                <ProjectTemplateAddModal
                  visible={!!addProjectTemplateModalVisible}
                  onSubmit={handleProjectTemplateCreateSubmit}
                  onClose={handleProjectTemplateCreateClose}
                  organizationId={organizationId}
                  organizationUsers={organizationUsers}
                  organizationPermissionProfiles={organizationPermissionProfiles}
                  organizationTimezoneId={organizationTimezoneId}
                  projectTemplatePreset={projectTemplatePreset}
                  reloadOrganizationUsers={reloadOrganizationUsers}
                />
              )}
              {!!editProjectTemplateModalVisible && (
                <ProjectTemplateEditModal
                  visible={!!editProjectTemplateModalVisible}
                  onSubmit={handleProjectTemplateEditSubmit}
                  onClose={handleProjectTemplateEditClose}
                  organizationId={organizationId}
                  templateId={projectToEditId}
                  organizationUsers={organizationUsers}
                  organizationTimezoneId={organizationTimezoneId}
                  reloadOrganizationUsers={reloadOrganizationUsers}
                  organizationPermissionProfiles={organizationPermissionProfiles}
                />
              )}
              {!!selectProjectForTemplateModalVisible && (
                <ProjectTemplateSelectSourceProjectFormModal
                  open={!!selectProjectForTemplateModalVisible}
                  onSubmit={handleCreateTemplateFromProject}
                  onClose={hideSelectProjectForTemplateModal}
                  organizationId={organizationId}
                />
              )}
              {!!toShowActivityTemplate && (
                <AuditLogMasterApiEntityModal
                  visible={!!toShowActivityTemplate}
                  label={
                    <Fmt
                      id="AuditLog.projectTemplate.label"
                      values={{ projectTemplateName: toShowActivityTemplate.name }}
                    />
                  }
                  entityId={toShowActivityTemplate.id}
                  entityType={EntityTypesEnum.projectTemplate}
                  onOk={hideAuditLog}
                  deps={[toShowActivityTemplate]}
                />
              )}
            </Panel>
          </StackPanel>
        )}
      />
    </>
  );
};

export default React.memo(ProjectsTemplateListPanel);
