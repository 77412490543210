import { ExcludedDaySetDto } from 'api/completeApiInterfaces';
import { FormModalWrapper } from 'components/forms/FormModalWrapper';
import { useSameCallback } from 'hooks/useSameCallback';
import { InjectedIntlProps } from 'locale/Fmt';
import React, { FunctionComponent } from 'react';
import { FormModalProps } from '../FormModalProps';
import { getOfficialHolidays } from './CalendarExcludeDayImportUtils';
import { CalendarExcludedDayImportForm, CalendarExcludedDayImportFormData } from './CalendarExcludedDayImportForm';

type Props = FormModalProps<CalendarExcludedDayImportFormData, ExcludedDaySetDto[]> & InjectedIntlProps;
export const CalendarExcludedDayImportFormModal: FunctionComponent<Props> = ({
  onSubmit,
  onClose,
  open,
  ...restProps
}) => {
  const handleSubmit = useSameCallback(async (values: CalendarExcludedDayImportFormData) => {
    if (!values) {
      return null;
    }

    onSubmit && (await onSubmit(values, getOfficialHolidays(values.endDate)));
    return null;
  });

  return (
    <>
      <FormModalWrapper
        onSubmit={handleSubmit}
        onClose={onClose}
        titleId="CalendarExcludedDayImportForm.modal.title"
        open={open}
        layout="vertical"
        {...restProps}
      >
        <CalendarExcludedDayImportForm />
      </FormModalWrapper>
    </>
  );
};
