import { AppUserDto } from 'api/completeApiInterfaces';
import {
  createSingleSelectFilterFunction,
  IOption,
  SELECT_CHECK_FORMAT,
  SELECT_CLEARED_VALUE,
  SELECT_DEFAULT_VALUE,
  SELECT_IS_EMPTY,
  SelectFilter,
  SelectFilterValue,
} from 'components/filters/components/SelectFilter/SelectFilter';
import { CommonFilter, FrontendFilter } from 'components/filters/filterTypes';
import { Fmt, InjectedIntlProps, memoizeWithIntl } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent, useMemo } from 'react';
import { checkObject } from 'utils';

type Props = InjectedIntlProps & {
  value: SelectFilterValue<Guid>;
  onChange: React.Dispatch<React.SetStateAction<SelectFilterValue<Guid>>>;
  mdUserList: AppUserDto[];
  label: IntlMessageId;
};

const MdUserFilterComponent: FunctionComponent<Props> = ({ value, onChange, mdUserList, label }) => {
  const userOptions = useMemo<IOption<Guid>[]>(
    () =>
      mdUserList.map((user) => ({
        id: user.id,
        title: user.username,
      })),
    [mdUserList]
  );

  return <SelectFilter label={<Fmt id={label} />} value={value} onChange={onChange} options={userOptions} />;
};

export const MdUserFilter = memoizeWithIntl(MdUserFilterComponent);

const createCommonMdUserFilter = (
  key: string,
  mdUserList: AppUserDto[],
  label: IntlMessageId
): CommonFilter<SelectFilterValue<Guid>> => ({
  key,
  render: (value, setValue) => <MdUserFilter value={value} onChange={setValue} mdUserList={mdUserList} label={label} />,
  isEmpty: SELECT_IS_EMPTY,
  defaultValue: SELECT_DEFAULT_VALUE([]),
  clearedValue: SELECT_CLEARED_VALUE,
  checkFormat: checkObject(SELECT_CHECK_FORMAT),
});

export const createFrontendMdUserFilter = <T,>(
  key: string,
  valueExtractor: (item: T) => Guid,
  mdUserList: AppUserDto[],
  label: IntlMessageId
): FrontendFilter<T, SelectFilterValue<Guid>> => ({
  ...createCommonMdUserFilter(key, mdUserList, label),
  filter: createSingleSelectFilterFunction(valueExtractor),
});
