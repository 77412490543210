import {
  AudioOutlined,
  CameraFilled,
  CheckOutlined,
  CloseOutlined,
  InboxOutlined,
  LoadingOutlined,
  NumberOutlined,
  PictureOutlined,
  StrikethroughOutlined,
} from '@ant-design/icons';
import { Alert, Button, Switch, Tree, Typography } from 'antd';
import { MultiUploadCheckResponseDto } from 'api/completeApiInterfaces';
import classNames from 'classnames';
import { Capture } from 'components/Capture/Capture';
import DroppedFilesErrorAlert from 'components/DroppedFilesErrorAlert/DroppedFilesErrorAlert';
import { FolderItemsCounts } from 'components/FolderItemsCounts/FolderItemsCounts';
import { NameValidatorByMask } from 'components/forms/DocumentCreateForm/FolderValidationMasksContext';
import { DeleteIcon, UploadIcon } from 'components/Icons/HubActionsIcons';
import { FlowLayout } from 'components/layouts/FlowLayout';
import Modal from 'components/Modal/Modal';
import {
  FilesStructure,
  FileSystemTreeNode,
  TreeCheckData,
  TreeStatistics,
} from 'components/PrimaryFileInput/CommonFilesInputTypes';
import {
  applyTreeCheckData,
  applyTreeCheckValidNamesByMasks,
  calculateTreeStatistics,
  createFileTreeNode,
  filterBlockedRevisions,
  filterDuplicated,
  filterDuplicatedNewDocuments,
  filterDuplicatedRevisions,
  filterInvalidName,
  filterNotWritable,
  filterTreeItems,
  getChecked,
  getHalfChecked,
  getNodesWithBlockedRevision,
  getNodesWithDuplicate,
  getNodesWithError,
  getNodesWithInvalidName,
  getNodesWithInvalidNameByMask,
  getNodesWithNewDocuments,
  getNodesWithNotWritable,
  getNodesWithRevision,
  getTreeNodeKeys,
  mergeTrees,
  onDropHandler,
  removeFromTree,
  replaceTreeBranches,
  setNodesChecked,
  setNodesUnchecked,
  setRevisionStatus,
  treeToStructure,
} from 'components/PrimaryFileInput/CommonFilesInputUtils';
import { getCommonFilesInputTreeNodes } from 'components/PrimaryFileInput/getCommonFilesInputTreeNodes';
import { InvalidItemsGroupResolver } from 'components/PrimaryFileInput/InvalidItemsGroupResolver';
import { SettingsBox } from 'components/SettingsBox/SettingsBox';
import { EMPTY_GUID } from 'config/constants';
import { useBoolean, useCurrentProjectUser, useIntl, useSameCallback } from 'hooks';
import isMobile from 'is-mobile';
import { Fmt } from 'locale';
import moment from 'moment';
import { MenuInfo } from 'rc-menu/lib/interface';
import React, { ChangeEvent, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ignoreRef } from 'utils';
import { v4 as uuid } from 'uuid';
import styles from './CommonFilesInput.module.less';

export type CommonFilesInputProps = {
  onChange?: (value: FilesStructure) => void;
  onValidationChange?: (value: FilesStructure) => void;
  rootPath?: string;
  multiple?: boolean;
  disableTreeStructure?: boolean;
  title: ReactNode;
  checkData?: MultiUploadCheckResponseDto;
  checkLoading?: boolean;
  droppedFiles?: FileSystemTreeNode[];
  nameValidator?: NameValidatorByMask;
};

const CommonFilesInputComponent = ({
  onChange,
  onValidationChange,
  multiple,
  disableTreeStructure,
  title,
  checkData,
  checkLoading,
  droppedFiles,
  nameValidator,
}: CommonFilesInputProps) => {
  const intl = useIntl();
  const [filesTree, setFilesTree] = useState<FileSystemTreeNode[]>([]);
  const [treeLoading, setTreeLoading] = useState<boolean>(false);
  const [isDirty, setDirty, clearDirty] = useBoolean(false);
  const [flatStructure, setFlatStructure] = useState<FilesStructure>({
    files: [],
    revisions: [],
    folders: [],
    inheritPermissions: true,
    hasInvalidNamesByMask: true,
  });
  const [treeStatistics, setTreeStatistics] = useState<TreeStatistics>({
    totalFileCount: 0,
    totalDirectoryCount: 0,
    duplicateFiles: 0,
    duplicateDirectories: 0,
    errorFiles: 0,
    errorDirectories: 0,
    blockedRevisions: 0,
    invalidNamesByMask: 0,
    invalidFolderNamesByMask: 0,
  });
  const [currentTreeItem, setCurrentTreeItem] = useState<FileSystemTreeNode>();
  const currentTreeItemRef = useRef(currentTreeItem);
  const currentUser = useCurrentProjectUser();
  const inputRef = useRef(null);
  const captureVideoInputRef = useRef(null);
  const captureImageInputRef = useRef(null);
  const captureAudioInputRef = useRef(null);

  const [captureVisible, setCaptureVisible, clearCaptureVisible] = useBoolean(false);

  useEffect(() => {
    currentTreeItemRef.current = currentTreeItem;
  }, [currentTreeItem]);

  const triggerChange = useCallback(
    (tree: FileSystemTreeNode[], isDirty?: boolean) => {
      if (isDirty) setDirty();
      const validatedNamesFilesTree = nameValidator ? applyTreeCheckValidNamesByMasks(tree, nameValidator) : tree;
      const possiblyCheckedFilesTree = checkData
        ? applyTreeCheckData(validatedNamesFilesTree, checkData)
        : validatedNamesFilesTree;

      setFilesTree(possiblyCheckedFilesTree);
      const statistics = calculateTreeStatistics(possiblyCheckedFilesTree);
      setTreeStatistics(statistics);

      const [files, revisions, folders] = treeToStructure(tree, false);
      const structure: FilesStructure = {
        files,
        revisions,
        folders,
        inheritPermissions: flatStructure.inheritPermissions,
        hasInvalidNamesByMask: statistics.invalidNamesByMask > 0 || statistics.invalidFolderNamesByMask > 0,
      };
      setFlatStructure(structure);
      onChange && onChange(structure);
    },
    [onChange, flatStructure, checkData, nameValidator]
  );

  useEffect(() => {
    !!droppedFiles?.length && triggerChange(droppedFiles, true);
  }, [droppedFiles]);

  useEffect(() => {
    if (nameValidator) {
      setDirty();
      setFilesTree((filesTree) => applyTreeCheckValidNamesByMasks(filesTree, nameValidator));
    }
  }, [nameValidator]);

  useEffect(() => {
    if (isDirty && flatStructure) {
      const [files, revisions, folders] = treeToStructure(filesTree, true);
      const validationStructure: FilesStructure = {
        files,
        revisions,
        folders,
        inheritPermissions: flatStructure.inheritPermissions,
        hasInvalidNamesByMask: flatStructure.hasInvalidNamesByMask,
      };
      onValidationChange && onValidationChange(validationStructure);
      clearDirty();
    }
  }, [isDirty, flatStructure, filesTree]);

  useEffect(() => {
    if (!checkData) return;
    triggerChange(applyTreeCheckData(filesTree, checkData));
  }, [checkData]);

  const handleTreeNodeMenuClick = useSameCallback(
    (event: React.MouseEvent<Element, MouseEvent>, treeItem: FileSystemTreeNode) => {
      event.stopPropagation();
      setCurrentTreeItem(treeItem);
    }
  );

  const checkedTreeItems = useMemo(() => {
    return {
      checked: getChecked(filesTree),
      halfChecked: getHalfChecked(filesTree),
    } as TreeCheckData;
  }, [filesTree]);

  const checkTreeItems = useCallback(
    (tree: FileSystemTreeNode[], newItems: string[]): FileSystemTreeNode[] => {
      if (!checkedTreeItems) {
        return setNodesChecked(tree, { checked: newItems, halfChecked: [] } as TreeCheckData);
      } else {
        return setNodesChecked(tree, {
          ...checkedTreeItems,
          checked: [...checkedTreeItems.checked, ...newItems],
        });
      }
    },
    [checkedTreeItems]
  );

  const uncheckTreeItems = useCallback(
    (tree: FileSystemTreeNode[], canceledItems: string[]): FileSystemTreeNode[] => {
      if (!checkedTreeItems) {
        return tree;
      }

      return setNodesUnchecked(tree, {
        checked: checkedTreeItems.checked.filter((f) => !canceledItems.includes(f)),
        halfChecked: [],
      });
    },
    [checkedTreeItems]
  );

  const handleItemDuplicateAsNewDocument = useSameCallback((info: MenuInfo) => {
    info.domEvent.stopPropagation();
    triggerChange(replaceTreeBranches(filesTree, setRevisionStatus([currentTreeItemRef.current], false)), true);
  });

  const handleItemDuplicateAsRevision = useSameCallback((info: MenuInfo) => {
    info.domEvent.stopPropagation();
    triggerChange(replaceTreeBranches(filesTree, setRevisionStatus([currentTreeItemRef.current], true)), true);
  });

  const handleItemIncludeDuplicateFolders = useSameCallback((info: MenuInfo) => {
    info.domEvent.stopPropagation();
    triggerChange(
      checkTreeItems(filesTree, getTreeNodeKeys(getNodesWithDuplicate([currentTreeItemRef.current], 'folder')))
    );
  });

  const handleItemExcludeDuplicateFolders = useSameCallback((info: MenuInfo) => {
    info.domEvent.stopPropagation();
    triggerChange(
      uncheckTreeItems(filesTree, getTreeNodeKeys(getNodesWithDuplicate([currentTreeItemRef.current], 'folder')))
    );
  });

  const handleItemIncludeDuplicateFiles = useSameCallback((info: MenuInfo) => {
    info.domEvent.stopPropagation();
    triggerChange(
      checkTreeItems(filesTree, getTreeNodeKeys(getNodesWithDuplicate([currentTreeItemRef.current], 'file')))
    );
  });

  const handleItemExcludeDuplicateFiles = useSameCallback((info: MenuInfo) => {
    info.domEvent.stopPropagation();
    triggerChange(
      uncheckTreeItems(filesTree, getTreeNodeKeys(getNodesWithDuplicate([currentTreeItemRef.current], 'file')))
    );
  });

  const handleItemExcludeNotWritable = useSameCallback((info: MenuInfo) => {
    info.domEvent.stopPropagation();
    triggerChange(uncheckTreeItems(filesTree, getTreeNodeKeys(getNodesWithNotWritable([currentTreeItemRef.current]))));
  });

  const handleItemExcludeWithInvalidName = useSameCallback((info: MenuInfo) => {
    info.domEvent.stopPropagation();
    triggerChange(uncheckTreeItems(filesTree, getTreeNodeKeys(getNodesWithInvalidName([currentTreeItemRef.current]))));
  });

  const handleItemRemove = useSameCallback((info: MenuInfo) => {
    info.domEvent.stopPropagation();
    removeItem([currentTreeItemRef.current.key]);
  });

  const handleTreeItemCheck = useSameCallback((checked: string[], e) => {
    triggerChange(setNodesChecked(filesTree, { checked: checked, halfChecked: [] } as TreeCheckData), true);
  });

  const handleChangePermissions = useSameCallback((permission: boolean) => {
    const structure = { ...flatStructure, inheritPermissions: permission };
    setFlatStructure(structure);
    onChange && onChange(structure);
  });

  const updateTree = useSameCallback((addedTree: FileSystemTreeNode[]) => {
    const tree = mergeTrees(filesTree, addedTree);
    triggerChange(checkTreeItems(tree, getTreeNodeKeys(addedTree)), true);
  });

  const onCaptured = useSameCallback((data: { blob: Blob }) => {
    clearCaptureVisible();

    const dateTimeString = moment().format('YYYY-MM-DD_HH-mm-ss');
    const filenamePrefix = intl.formatMessage({ id: 'CommonFilesInput.record.filenamePrefix' });
    const filename = filenamePrefix + '_' + dateTimeString + '.webm';

    const file: FileSystemTreeNode = {
      file: new File([data.blob], filename, { type: 'video/webm' }),
      removed: false,
      key: uuid(),
      title: filename,
      path: '',
      type: 'file',
      isRevision: false,
      checked: true,
      duplicate: false,
      invalidName: false,
      notWritable: false,
      revisionDocumentId: EMPTY_GUID,
    };

    updateTree([file]);
  });

  const removeItem = useSameCallback((keys: Guid[]) => {
    const tree = removeFromTree(filesTree, keys);
    triggerChange(tree, true);
  });

  const onChangeInput = useSameCallback((event: ChangeEvent<HTMLInputElement>) => {
    const files = event?.currentTarget?.files;
    if (files && files.length > 0) {
      updateTree(Object.values(files).map((f) => createFileTreeNode(f)));
      inputRef.current.value = '';
      if (captureVideoInputRef.current) captureVideoInputRef.current.value = '';
      if (captureImageInputRef.current) captureImageInputRef.current.value = '';
      if (captureAudioInputRef.current) captureAudioInputRef.current.value = '';
    }
  });

  const onDrop = useCallback(
    async (event: React.DragEvent<HTMLElement>) => {
      if (event.dataTransfer) {
        event.preventDefault();
        event.stopPropagation();
        setTreeLoading(true);
        const [tree, rootErrors] = await onDropHandler(event, disableTreeStructure);
        inputRef.current.value = '';
        updateTree(tree);
        setTreeLoading(false);
      }
    },
    [disableTreeStructure]
  );

  const isDragActive = false;

  const onInputClick = useCallback(
    (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => (e.currentTarget.value = ''),
    []
  );

  const removeDuplicatedFolders = useCallback(() => {
    triggerChange(filterDuplicated(filesTree, 'folder'), true);
  }, [filesTree, triggerChange]);

  const removeNotWritable = useCallback(() => {
    triggerChange(filterNotWritable(filesTree), true);
  }, [filesTree, triggerChange]);

  const removeInvalidNames = useCallback(() => {
    triggerChange(filterInvalidName(filesTree), true);
  }, [filesTree, triggerChange]);

  const removeAllWithError = useCallback(() => {
    triggerChange(filterNotWritable(filterInvalidName(filesTree)), true);
  }, [filesTree, triggerChange]);

  const removeAllDuplicateNewDocuments = useCallback(() => {
    triggerChange(filterDuplicatedNewDocuments(filesTree), true);
  }, [filesTree, triggerChange]);

  const removeAllDuplicateRevisions = useCallback(() => {
    triggerChange(filterDuplicatedRevisions(filesTree), true);
  }, [filesTree, triggerChange]);

  const removeAllBlockedRevisions = useCallback(() => {
    triggerChange(filterBlockedRevisions(filesTree), true);
  }, [filesTree, triggerChange]);

  const removeAllDuplicateDirectories = useCallback(() => {
    triggerChange(filterDuplicated(filesTree, 'folder'), true);
  }, [filesTree, triggerChange]);

  const removeAllInvalidNamesByMaskFiles = useCallback(() => {
    triggerChange(
      filterTreeItems(filesTree, (node) => !node.invalidNameByMask || node.invalidNameByMask?.isValid),
      true
    );
  }, [filesTree, triggerChange]);

  const excludeAllInvalidNamesByMaskFiles = useCallback(() => {
    triggerChange(uncheckTreeItems(filesTree, getTreeNodeKeys(getNodesWithInvalidNameByMask(filesTree))));
  }, [triggerChange, uncheckTreeItems, filesTree]);

  const excludeAllWithNotWritable = useCallback(() => {
    triggerChange(uncheckTreeItems(filesTree, getTreeNodeKeys(getNodesWithNotWritable(filesTree))));
  }, [triggerChange, uncheckTreeItems, filesTree]);

  const excludeAllWithInvalidName = useCallback(() => {
    triggerChange(uncheckTreeItems(filesTree, getTreeNodeKeys(getNodesWithInvalidName(filesTree))));
  }, [triggerChange, uncheckTreeItems, filesTree]);

  const excludeAllWithError = useCallback(() => {
    triggerChange(uncheckTreeItems(filesTree, getTreeNodeKeys(getNodesWithError(filesTree))));
  }, [triggerChange, uncheckTreeItems, filesTree]);

  const setAllAsRevision = useCallback(() => {
    triggerChange(replaceTreeBranches(filesTree, setRevisionStatus(filesTree, true)), true);
  }, [triggerChange, filesTree]);

  const includeAllDuplicateDocuments = useCallback(() => {
    triggerChange(checkTreeItems(filesTree, getTreeNodeKeys(getNodesWithNewDocuments(filesTree))));
  }, [triggerChange, checkTreeItems, filesTree]);

  const excludeAllDuplicateDocuments = useCallback(() => {
    triggerChange(uncheckTreeItems(filesTree, getTreeNodeKeys(getNodesWithNewDocuments(filesTree))));
  }, [triggerChange, uncheckTreeItems, filesTree]);

  const includeAllDuplicateDirectories = useCallback(() => {
    triggerChange(checkTreeItems(filesTree, getTreeNodeKeys(getNodesWithDuplicate(filesTree, 'folder'))));
  }, [triggerChange, checkTreeItems, filesTree]);

  const excludeAllDuplicateDirectories = useCallback(() => {
    triggerChange(uncheckTreeItems(filesTree, getTreeNodeKeys(getNodesWithDuplicate(filesTree, 'folder'))));
  }, [triggerChange, uncheckTreeItems, filesTree]);

  const setAllAsNewDocument = useCallback(() => {
    triggerChange(replaceTreeBranches(filesTree, setRevisionStatus(filesTree, false)), true);
  }, [triggerChange, filesTree]);

  const includeAllRevisions = useCallback(() => {
    triggerChange(checkTreeItems(filesTree, getTreeNodeKeys(getNodesWithRevision(filesTree))));
  }, [triggerChange, checkTreeItems, filesTree]);

  const excludeAllRevisions = useCallback(() => {
    triggerChange(uncheckTreeItems(filesTree, getTreeNodeKeys(getNodesWithRevision(filesTree))));
  }, [triggerChange, uncheckTreeItems, filesTree]);

  const excludeAllBlockedRevisions = useCallback(() => {
    triggerChange(uncheckTreeItems(filesTree, getTreeNodeKeys(getNodesWithBlockedRevision(filesTree))));
  }, [triggerChange, uncheckTreeItems, filesTree]);

  const hasAnyNotWritableDirectories = useMemo(() => !!checkData?.notWritableDirectories?.length, [checkData]);
  const notWritableDocuments = useMemo(() => !!checkData?.notWritableDocuments?.length, [checkData]);
  const hasInvalidDirectoryNames = useMemo(() => !!checkData?.invalidNameDirectories?.length, [checkData]);
  const hasBlockedRevisions = useMemo(() => treeStatistics.blockedRevisions > 0, [treeStatistics]);
  const hasInvalidNamesByMask = useMemo(
    () => treeStatistics.invalidNamesByMask > 0 || treeStatistics.invalidFolderNamesByMask > 0,
    [treeStatistics]
  );

  const droppedFilesErrors = useMemo(
    () =>
      flatStructure.folders.flatMap((folder) => folder.uploadErrors.filter((error) => error.code === 'pathTooLong')),
    [flatStructure]
  );

  const treeItemFolderMenu = useMemo(
    () => ({
      items: [
        {
          key: 'duplicateFilesAsRevisions',
          onClick: handleItemDuplicateAsRevision,
          label: <Fmt id="CommonFilesInput.treeItemMenu.duplicateFilesAsRevisions" />,
        },
        {
          key: 'duplicateFilesAsNew',
          onClick: handleItemDuplicateAsNewDocument,
          label: <Fmt id="CommonFilesInput.treeItemMenu.duplicateFilesAsNew" />,
        },
        {
          key: 'includeDuplicateFolders',
          onClick: handleItemIncludeDuplicateFolders,
          label: <Fmt id="CommonFilesInput.treeItemMenu.includeDuplicateFolders" />,
        },
        {
          key: 'excludeDuplicateFolders',
          onClick: handleItemExcludeDuplicateFolders,
          label: <Fmt id="CommonFilesInput.treeItemMenu.excludeDuplicateFolders" />,
        },
        {
          key: 'includeDuplicateFiles',
          onClick: handleItemIncludeDuplicateFiles,
          label: <Fmt id="CommonFilesInput.treeItemMenu.includeDuplicateFiles" />,
        },
        {
          key: 'excludeDuplicateFiles',
          onClick: handleItemExcludeDuplicateFiles,
          label: <Fmt id="CommonFilesInput.treeItemMenu.excludeDuplicateFiles" />,
        },
        {
          key: 'excludeAllNotWritable',
          onClick: handleItemExcludeNotWritable,
          label: <Fmt id="CommonFilesInput.treeItemMenu.excludeAllNotWritable" />,
        },
        {
          key: 'excludeAllInvalid',
          onClick: handleItemExcludeWithInvalidName,
          label: <Fmt id="CommonFilesInput.treeItemMenu.excludeAllInvalid" />,
        },
        { key: 'remove', onClick: handleItemRemove, label: <Fmt id="CommonFilesInput.remove" />, icon: <DeleteIcon /> },
      ],
    }),
    []
  );

  const getTreeItemFileMenu = useCallback(
    (showRevisionButton: boolean, showDocumentButton: boolean) => ({
      items: [
        showDocumentButton && {
          key: 'uploadAsNewDocument',
          onClick: handleItemDuplicateAsNewDocument,
          icon: <UploadIcon />,
          label: <Fmt id="CommonFilesInput.button.uploadAsNewDocument" />,
        },
        showRevisionButton && {
          key: 'uploadAsRevision',
          onClick: handleItemDuplicateAsRevision,
          icon: <NumberOutlined />,
          label: <Fmt id="CommonFilesInput.button.uploadAsRevision" />,
        },
        {
          key: 'remove',
          onClick: handleItemRemove,
          icon: <DeleteIcon />,
          label: <Fmt id="CommonFilesInput.remove" />,
        },
      ].filter(Boolean),
    }),
    []
  );

  const treeNodes = useMemo(
    () =>
      getCommonFilesInputTreeNodes(filesTree, false, getTreeItemFileMenu, handleTreeNodeMenuClick, treeItemFolderMenu),
    [filesTree, getTreeItemFileMenu, handleTreeNodeMenuClick, treeItemFolderMenu]
  );

  const foldersCount = flatStructure.folders.length;
  const revisionsCount = flatStructure.revisions.length;
  const filesCount = flatStructure.files.length + flatStructure.revisions.length;

  return (
    <div>
      {(multiple || filesTree.length !== 1) && (
        <div
          className={classNames(
            styles.dropArea,
            'ant-upload',
            'ant-upload-drag',
            isDragActive && 'ant-upload-drag-hover'
          )}
        >
          <input
            className={styles.input}
            tabIndex={-1}
            autoComplete="off"
            type="file"
            multiple={multiple}
            onChange={onChangeInput}
            onDrop={onDrop}
            onClick={onInputClick}
            ref={inputRef}
          />
          <span className="ant-upload ant-upload-btn" role="button">
            <div className="ant-upload-drag-container">
              <p className="ant-upload-text">
                <InboxOutlined /> {title}
              </p>
              <p className="ant-upload-hint">
                {multiple ? <Fmt id="CommonFilesInput.hint" /> : <Fmt id="SingleFileInput.collapse.hint" />}
              </p>
            </div>
          </span>
        </div>
      )}

      {isMobile() ? (
        <>
          <Button icon={<CameraFilled />} onClick={() => captureVideoInputRef.current?.click()}>
            <Fmt id="CommonFilesInput.recordVideo" />
          </Button>
          <Button icon={<PictureOutlined />} onClick={() => captureImageInputRef.current?.click()}>
            <Fmt id="CommonFilesInput.takePicture" />
          </Button>
          <Button icon={<AudioOutlined />} onClick={() => captureAudioInputRef.current?.click()}>
            <Fmt id="CommonFilesInput.recordAudio" />
          </Button>
          <input
            ref={captureVideoInputRef}
            className={styles.captureInput}
            type="file"
            capture="environment"
            onChange={onChangeInput}
            accept={'video/*'}
          />
          <input
            ref={captureImageInputRef}
            className={styles.captureInput}
            type="file"
            capture="environment"
            onChange={onChangeInput}
            accept={'image/*'}
          />
          <input
            ref={captureAudioInputRef}
            className={styles.captureInput}
            type="file"
            capture="environment"
            onChange={onChangeInput}
            accept={'audio/*'}
          />
        </>
      ) : (
        <>
          <Button icon={<CameraFilled />} onClick={setCaptureVisible}>
            <Fmt id="CommonFilesInput.recordVideo" />
          </Button>
          {captureVisible && (
            <Modal visible onCancel={clearCaptureVisible}>
              <Capture onCaptured={onCaptured} onCancel={clearCaptureVisible} />
            </Modal>
          )}
        </>
      )}

      {foldersCount > 0 && (
        <FlowLayout className={styles.inheritPermissions}>
          <Switch
            checked={flatStructure.inheritPermissions}
            disabled={!currentUser.isAdmin}
            loading={false}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={handleChangePermissions}
          />
          <span onClick={() => handleChangePermissions(!flatStructure.inheritPermissions)}>
            <Fmt id="DocumentCreateMultipleForm.inheritPermission.label" />
          </span>
        </FlowLayout>
      )}

      <SettingsBox className={styles.statisticsContainer}>
        <div className={styles.infoRow}>
          <Fmt id="general.total" />{' '}
          <span className={styles.statistic}>
            <FolderItemsCounts
              filesCount={treeStatistics.totalFileCount}
              foldersCount={treeStatistics.totalDirectoryCount}
              selectedFilesCount={filesCount}
              selectedFoldersCount={foldersCount}
            />
          </span>
        </div>
        {(!!foldersCount || !!filesCount) && (
          <>
            {hasInvalidNamesByMask && (
              <InvalidItemsGroupResolver
                type="forbidden"
                icon={<StrikethroughOutlined />}
                label={<Fmt id="CommonFilesInput.invalidNamesByMasks.label" />}
                items={[
                  {
                    key: 'exclude',
                    label: <Fmt id="CommonFilesInput.button.exclude" />,
                    onClick: excludeAllInvalidNamesByMaskFiles,
                  },
                  {
                    key: 'remove',
                    label: <Fmt id="CommonFilesInput.button.remove" />,
                    onClick: removeAllInvalidNamesByMaskFiles,
                  },
                ]}
              >
                <FolderItemsCounts
                  filesCount={treeStatistics.invalidNamesByMask}
                  foldersCount={treeStatistics.invalidFolderNamesByMask}
                />
              </InvalidItemsGroupResolver>
            )}
            {(treeStatistics.errorDirectories > 0 || treeStatistics.errorFiles > 0) && (
              <InvalidItemsGroupResolver
                type="forbidden"
                label={<Fmt id="CommonFilesInput.statistics.withErrors.tooltip" />}
                info={
                  <>
                    {(hasAnyNotWritableDirectories || notWritableDocuments) && (
                      <Fmt id="CommonFilesInput.notWritable.text" />
                    )}
                  </>
                }
                items={[
                  {
                    key: 'excludeInvalidName',
                    label: <Fmt id="CommonFilesInput.button.excludeInvalidNames" />,
                    onClick: excludeAllWithInvalidName,
                  },
                  {
                    key: 'excludeNotWritable',
                    label: <Fmt id="CommonFilesInput.button.excludeWithoutAccess" />,
                    onClick: excludeAllWithNotWritable,
                  },
                  {
                    key: 'removeInvalid',
                    label: <Fmt id="CommonFilesInput.button.removeInvalidNames" />,
                    onClick: removeInvalidNames,
                  },
                  {
                    key: 'removeWithoutPermission',
                    label: <Fmt id="CommonFilesInput.button.removeWithoutAccess" />,
                    onClick: removeNotWritable,
                  },
                  {
                    key: 'excludeAll',
                    label: <Fmt id="CommonFilesInput.button.exclude" />,
                    onClick: excludeAllWithError,
                  },
                  {
                    key: 'removeAll',
                    label: <Fmt id="CommonFilesInput.button.remove" />,
                    onClick: removeAllWithError,
                  },
                ]}
              >
                <FolderItemsCounts
                  filesCount={treeStatistics.errorFiles > 0 && treeStatistics.errorFiles}
                  foldersCount={treeStatistics.errorDirectories > 0 && treeStatistics.errorDirectories}
                />
              </InvalidItemsGroupResolver>
            )}
            {treeStatistics.duplicateDirectories > 0 && (
              <InvalidItemsGroupResolver
                type="info"
                label={<Fmt id="CommonFilesInput.statistics.duplicateFolders.tooltip" />}
                items={[
                  {
                    key: 'include',
                    label: <Fmt id="CommonFilesInput.button.include" />,
                    onClick: includeAllDuplicateDirectories,
                  },
                  {
                    key: 'exclude',
                    label: <Fmt id="CommonFilesInput.button.exclude" />,
                    onClick: excludeAllDuplicateDirectories,
                  },
                  {
                    key: 'remove',
                    label: <Fmt id="CommonFilesInput.button.remove" />,
                    onClick: removeAllDuplicateDirectories,
                  },
                ]}
                info={<Fmt id="CommonFilesInput.duplicateFolders.text" />}
              >
                <FolderItemsCounts foldersCount={treeStatistics.duplicateDirectories} />
              </InvalidItemsGroupResolver>
            )}
            {treeStatistics.duplicateFiles > 0 && (
              <InvalidItemsGroupResolver
                type="warning"
                label={<Fmt id="CommonFilesInput.statistics.duplicateDocuments.tooltip" />}
                items={[
                  {
                    key: 'asRevisions',
                    label: <Fmt id="CommonFilesInput.button.uploadAllAsRevisions" />,
                    onClick: setAllAsRevision,
                  },
                  {
                    key: 'include',
                    label: <Fmt id="CommonFilesInput.button.include" />,
                    onClick: includeAllDuplicateDocuments,
                  },
                  {
                    key: 'exclude',
                    label: <Fmt id="CommonFilesInput.button.exclude" />,
                    onClick: excludeAllDuplicateDocuments,
                  },
                  {
                    key: 'remove',
                    label: <Fmt id="CommonFilesInput.duplicateDocuments.buttonLabel" />,
                    onClick: removeAllDuplicateNewDocuments,
                  },
                ]}
                info={<Fmt id="CommonFilesInput.duplicateDocuments.text" />}
              >
                <FolderItemsCounts filesCount={treeStatistics.duplicateFiles} />
              </InvalidItemsGroupResolver>
            )}
            {revisionsCount > 0 && (
              <InvalidItemsGroupResolver
                type="revision"
                label={<Fmt id="CommonFilesInput.statistics.newRevisions.tooltip" />}
                items={[
                  {
                    key: 'asNewDocuments',
                    label: <Fmt id="CommonFilesInput.button.uploadAllAsNewDocuments" />,
                    onClick: setAllAsNewDocument,
                  },
                  {
                    key: 'include',
                    label: <Fmt id="CommonFilesInput.button.include" />,
                    onClick: includeAllRevisions,
                  },
                  {
                    key: 'exclude',
                    label: <Fmt id="CommonFilesInput.button.exclude" />,
                    onClick: excludeAllRevisions,
                  },
                  {
                    key: 'remove',
                    label: <Fmt id="CommonFilesInput.button.remove" />,
                    onClick: removeAllDuplicateRevisions,
                  },
                ]}
              >
                <FolderItemsCounts filesCount={revisionsCount} />
              </InvalidItemsGroupResolver>
            )}
            {treeStatistics.blockedRevisions > 0 && (
              <InvalidItemsGroupResolver
                type="forbidden"
                label={<Fmt id="CommonFilesInput.statistics.invalidRevisions.tooltip" />}
                items={[
                  {
                    key: 'exclude',
                    label: <Fmt id="CommonFilesInput.button.exclude" />,
                    onClick: excludeAllBlockedRevisions,
                  },
                  {
                    key: 'remove',
                    label: <Fmt id="CommonFilesInput.button.remove" />,
                    onClick: removeAllBlockedRevisions,
                  },
                ]}
              >
                <FolderItemsCounts filesCount={treeStatistics.blockedRevisions} />
              </InvalidItemsGroupResolver>
            )}
          </>
        )}
      </SettingsBox>

      {treeLoading && (
        <Typography.Text>
          <LoadingOutlined /> <Fmt id="CommonFilesInput.treeLoading.text" />
        </Typography.Text>
      )}

      <Tree
        className={styles.container}
        selectable={false}
        checkable
        showLine
        checkedKeys={checkedTreeItems}
        onCheck={handleTreeItemCheck}
        blockNode
      >
        {treeNodes}
      </Tree>

      <Typography.Text className={styles.checkingMessageLine}>
        {checkLoading && (
          <>
            <LoadingOutlined /> <Fmt id="CommonFilesInput.checkLoading.text" />
          </>
        )}
      </Typography.Text>

      {!!droppedFilesErrors?.length && <DroppedFilesErrorAlert droppedFilesErrors={droppedFilesErrors} />}

      {hasInvalidDirectoryNames && (
        <Alert
          message={
            <>
              <Fmt id="CommonFilesInput.invalidName.text" />{' '}
              <Button size="small" type="link" onClick={removeInvalidNames}>
                <Fmt id="CommonFilesInput.invalidName.buttonLabel" />
              </Button>
            </>
          }
          type="error"
          banner
        />
      )}

      {hasBlockedRevisions && (
        <Alert
          message={
            <>
              <Fmt id="CommonFilesInput.blockedRevisions.text" />{' '}
              <Button size="small" type="link" onClick={removeAllBlockedRevisions}>
                <Fmt id="CommonFilesInput.blockedRevisions.buttonLabel" />
              </Button>
            </>
          }
          type="error"
          banner
        />
      )}

      {!!(flatStructure.folders.length && navigator.userAgent.search('Firefox') >= 0) && (
        <Alert message={<Fmt id="CommonFilesInput.treeLoading.firefoxWarning" />} type="warning" banner />
      )}
    </div>
  );
};

export const CommonFilesInput = ignoreRef(React.memo(CommonFilesInputComponent));
