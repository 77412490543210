import { FormProcessTaskTemplateDto } from 'api/completeApiInterfaces';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import React, { FunctionComponent } from 'react';
import { smartFilter } from 'utils';

type Props = {
  formProcessTaskTemplates: FormProcessTaskTemplateDto[];
  search: string;
  onClearSearch?: () => void;
};

const FormProcessTaskTemplatesList: FunctionComponent<Props> = ({
  formProcessTaskTemplates,
  search,
  onClearSearch,
  children,
}) => {
  return (
    <GeneralSettingsContainer>
      <List<FormProcessTaskTemplateDto>
        data={formProcessTaskTemplates}
        search={search}
        filterItem={(item) => item && smartFilter(item?.templateName, search)}
        renderItem={(item) => (
          <GeneralSettingsItem key={item.id} title={item.templateName} wrap additionalActions={<></>} />
        )}
        renderEmpty={(total, filtered) => <ListEmpty filtered={filtered} total={total} onClearSearch={onClearSearch} />}
      />
      {children}
    </GeneralSettingsContainer>
  );
};

export default FormProcessTaskTemplatesList;
