import { Checkbox, Form, Input, InputRef, Select } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useWatch } from 'antd/lib/form/Form';
import { ProjectUserProfileStatusEnum, RoleDto } from 'api/completeApiInterfaces';
import { UserTransferFormItem } from 'components/UserTransfer/UserTransfer';
import { MAX_ITEM_DESCRIPTION_LENGTH } from 'config/constants';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import { Dictionary } from 'lodash';
import React, { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch, RootState } from 'store';
import { legacyMapDispatchToProps } from 'store/models/storeModelinterfaces';
import { projectUsersListSelector } from 'store/selectors/projectUsersSelectors';
import {
  duplicateNameRuleCallback,
  maxLengthRule,
  requiredRule,
  simpleSelectFilter,
  whitespaceRule,
} from 'utils/formHelpers';

export type RoleFormData = {
  name: string;
  description: string;
  userId: string;
  forceRemove: boolean;
  associateIds: Guid[];
};

const mapStateToProps = (state: RootState) => ({
  usersList: projectUsersListSelector(state),
  usersMapError: state.projectUsers.error,
  usersLoading: state.projectUsers.loading,
  hasDirtyUsers: state.projectUsers.dirty,
});

type PropsFromState = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadUsers: dispatch.projectUsers.loadData,
});

type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

type Props = PropsFromState &
  PropsFromDispatch & {
    validateUniqueName: (name: string) => boolean;
    defaults?: RoleDto;
    error: ReactNode;
    setRef: (ref: InputRef) => void;
  };

const RoleForm: FC<Props> = ({ loadUsers, usersList, defaults, hasDirtyUsers, validateUniqueName, setRef, error }) => {
  const intl = useIntl();
  const form = useFormInstance();
  const userFormValue = useWatch('userId') as Guid;
  const associateIdsFormValue = useWatch('associateIds') as Guid[];
  // TODO: investigate refs
  const usersRef = useRef<Dictionary<string>>(null);
  const [initialized, setInitialized] = useState(false);
  const [selectPlaceholder, setSelectPlaceholder] = useState(undefined);

  const availableUsersToTransfer = useMemo(() => {
    return usersList?.filter((user) => user.id !== userFormValue) || [];
  }, [userFormValue, usersList]);

  useEffect(() => {
    form.setFieldValue(
      'associateIds',
      associateIdsFormValue?.filter((associated) => associated !== userFormValue)
    );
  }, [userFormValue]);

  useEffect(() => {
    loadUsers({ reload: false });
    usersRef.current = {};
    usersList
      ?.filter((user) => user.status !== ProjectUserProfileStatusEnum.suspended)
      .forEach((user) => {
        usersRef.current[user.id] = user.username;
      });

    if (Object.entries(usersRef.current).length > 0) setInitialized(true);

    if (defaults?.user?.status === ProjectUserProfileStatusEnum.suspended) {
      setSelectPlaceholder(intl.formatMessage({ id: 'RoleForm.items.userId.suspended.placeholder' }));
    }

    if (defaults) {
      form.setFieldsValue({
        name: defaults.name,
        description: defaults.description,
        userId:
          defaults.user && defaults.user.status !== ProjectUserProfileStatusEnum.suspended
            ? defaults.user.id
            : undefined,
      });
    }
  }, [defaults, form, intl, loadUsers, usersList]);

  useEffect(() => {
    if (hasDirtyUsers) {
      loadUsers({ reload: false, silent: true });
    }
  }, []);

  const getUsers = useMemo((): ReactNode[] => {
    if (!initialized) return null;
    return Object.entries(usersRef.current).map(([key, user]) => <Select.Option key={key}>{user}</Select.Option>);
  }, [initialized]);

  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.name.label' })}
        name="name"
        rules={[
          requiredRule('forms.items.name.rules.required', true),
          maxLengthRule('general.maxNameLength'),
          duplicateNameRuleCallback(validateUniqueName),
        ]}
        initialValue={defaults?.name}
      >
        <Input placeholder={intl.formatMessage({ id: 'RoleForm.items.name.placeholder' })} autoFocus ref={setRef} />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.description.label' })}
        name="description"
        rules={[
          whitespaceRule('forms.items.name.rules.empty'),
          {
            max: MAX_ITEM_DESCRIPTION_LENGTH,
            message: intl.formatMessage({ id: 'general.maxDescriptionLength' }, { max: MAX_ITEM_DESCRIPTION_LENGTH }),
          },
        ]}
        initialValue={defaults?.description}
      >
        <Input.TextArea rows={2} autoSize={{ minRows: 2 }} />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.userId.label' })}
        name="userId"
        initialValue={defaults?.user?.id}
      >
        <Select
          allowClear
          showSearch
          filterOption={simpleSelectFilter}
          placeholder={selectPlaceholder ? selectPlaceholder : ''}
        >
          {getUsers}
        </Select>
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.associatedIds.label' })}
        name="associateIds"
        initialValue={defaults?.roleAssociates?.map((role) => role.user.id)}
      >
        <UserTransferFormItem users={availableUsersToTransfer} />
      </Form.Item>
      {error && (
        <div className="ant-typography ant-typography-danger">
          <strong>{error}</strong>
        </div>
      )}
      {error && (
        <Form.Item
          label={intl.formatMessage({ id: 'forms.items.forceRemove.label' })}
          name="forceRemove"
          valuePropName="checked"
        >
          <Checkbox>
            <Fmt id="forms.items.forceRemove.description" />
          </Checkbox>
        </Form.Item>
      )}
    </>
  );
};

export default connect(mapStateToProps, legacyMapDispatchToProps(mapDispatchToProps))(RoleForm);
