import { Button } from 'antd';
import { api } from 'api';
import {
  MessageListDto,
  MsgCategoryEnum,
  MsgCategoryTypeEnum,
  MsgCenterListRequestDto,
  MsgCenterResponseDto,
  MsgOrderTypeEnum,
  MsgStatusEnum,
  ProjectListDto,
  SortOrder,
} from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import StackPanel from 'components/StackPanel';
import { DateBackendFilterType, createBackendDateFilter } from 'components/filters/components/DateFilter/DateFilter';
import {
  IOption,
  SELECT_DEFAULT_VALUE,
  SelectFilterValue,
  createBackendSelectFilter,
} from 'components/filters/components/SelectFilter/SelectFilter';
import { BackendFilter, FiltersPersistentKey } from 'components/filters/filterTypes';
import { accumulateFilterValues } from 'components/filters/filterUtils';
import { OrderOption } from 'components/filters/orderTypes';
import { useApiData, useFilters, useStoreSelector } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt, InjectedIntlProps, memoizeWithIntl } from 'locale';
import { Dictionary } from 'lodash';
import moment from 'moment';
import { MessageDetail } from 'pages/MessageCenterPage/messages/MessageDetail';
import React, { FunctionComponent, useEffect, useMemo, useReducer, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { projectsListSelector } from 'store/selectors';
import { DEFAULT_PAGE_SIZE, categoryMap } from '../Constants';
import { MessageGrid, ProjectMessageNames } from '../messages/MessageGrid';

type Props = InjectedIntlProps & {
  defaultProjectId?: Guid;
  messageId?: Guid;
  defaultFromFilter?: string;
  defaultToFilter?: string;
  projectList: ProjectListDto[];
  setMessageState?: (selectedMessages: Guid[], status: MsgStatusEnum) => Promise<boolean>;
};

const AllowedMessagesStates: Partial<Record<MsgStatusEnum, MsgStatusEnum>> = {
  [MsgStatusEnum.new]: MsgStatusEnum.new,
  [MsgStatusEnum.read]: MsgStatusEnum.read,
  [MsgStatusEnum.ignored]: MsgStatusEnum.ignored,
};

type MessagesState = {
  selectedMessageId: Guid;
  messages: MessageListDto[];
  pageCount: number;
  total: number;
  currentPageIndex: number;
  pageSize: number;
};

type SetMessages = {
  type: 'setMessages';
  data: MsgCenterResponseDto;
};

type SelectMessage = {
  type: 'selectMessage';
  messageId: Guid;
};

type SetMessageState = {
  type: 'setMessagesState';
  messages: Guid[];
  state: MsgStatusEnum;
};

type ChangePage = {
  type: 'changePage';
  pageSize: number;
  currentPageIndex: number;
};

type ResetPage = {
  type: 'resetPage';
};

export type MessagesReducerActions = SetMessages | SelectMessage | SetMessageState | ChangePage | ResetPage;

const reducer = (prevState: MessagesState, action: MessagesReducerActions): MessagesState => {
  switch (action.type) {
    case 'setMessages':
      return {
        ...prevState,
        messages: action.data.messages,
        pageCount: Math.ceil(action.data.total / action.data.size),
        total: action.data.total,
      };
    case 'setMessagesState':
      // eslint-disable-next-line no-case-declarations
      const updatedMessages = prevState.messages.map((message) => {
        if (action.messages.some((messageId) => messageId === message.id)) {
          message.status = action.state;
        }
        return message;
      });

      return {
        ...prevState,
        messages: updatedMessages,
      };
    case 'selectMessage':
      return {
        ...prevState,
        selectedMessageId: action.messageId,
      };
    case 'changePage':
      return {
        ...prevState,
        pageSize: action.pageSize,
        currentPageIndex: action.currentPageIndex,
      };
    case 'resetPage':
      return {
        ...prevState,
        currentPageIndex: 0,
      };
  }
};

const getDefaultMessageList = (): MessagesState => {
  return {
    messages: [],
    total: 0,
    pageCount: 1,
    selectedMessageId: null,
    currentPageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  };
};

const startOfDay = (date: IsoDateTime | null) =>
  date
    ? moment(date)
        .startOf('day')
        .toISOString()
    : undefined;
const endOfDay = (date: IsoDateTime | null) =>
  date
    ? moment(date)
        .endOf('day')
        .toISOString()
    : undefined;

const CATEGORY_TYPE_FILTER = 'categoryType';

const ORDER_OPTIONS: OrderOption<MsgOrderTypeEnum>[] = [
  {
    key: MsgOrderTypeEnum.createTime,
    label: <Fmt id="MessageCenterPage.order.createTime.label" />,
    defaultOrder: SortOrder.desc,
  },
  {
    key: MsgOrderTypeEnum.finishTime,
    label: <Fmt id="MessageCenterPage.order.finishTime.label" />,
  },
  {
    key: MsgOrderTypeEnum.status,
    label: <Fmt id="MessageCenterPage.order.status.label" />,
  },
  {
    key: MsgOrderTypeEnum.project,
    label: <Fmt id="MessageCenterPage.order.project.label" />,
  },
  {
    key: MsgOrderTypeEnum.category,
    label: <Fmt id="MessageCenterPage.order.category.label" />,
  },
];

const CREATED_DATE_KEY = 'createDateRange';

const DATE_FILTER_TYPES: DateBackendFilterType<MsgCenterListRequestDto>[] = [
  {
    key: CREATED_DATE_KEY,
    label: <Fmt id="MessageCenterPage.filters.createDateRange.label" />,
    serialize: (request, value) => ({
      ...request,
      createTimeFromFilter: startOfDay(value.from),
      createTimeToFilter: endOfDay(value.to),
    }),
  },
  {
    key: 'finishDateRange',
    label: <Fmt id="MessageCenterPage.filters.finishDateRange.label" />,
    serialize: (request, value) => ({
      ...request,
      finishTimeFromFilter: startOfDay(value.from),
      finishTimeToFilter: endOfDay(value.to),
    }),
  },
];

const MessageCenterMessagesComponent: FunctionComponent<Props> = (props) => {
  const { intl, defaultProjectId, defaultFromFilter, defaultToFilter, setMessageState, projectList } = props;
  const currentAppUser = useStoreSelector((state) => state.currentAppUser.data);
  const { url } = useRouteMatch();

  const categoryTypeOptions = useMemo<IOption<MsgCategoryTypeEnum>[]>(
    () =>
      Object.values(MsgCategoryTypeEnum).map((categoryType) => ({
        id: categoryType,
        title: intl.formatMessage({ id: `MessageCenterPage.message.categoryType.${categoryType}` }),
      })),
    [intl]
  );

  // TODO: rendering of one filter depends on value of another filter ... how to solve?
  const [selectedCategoryTypes, setSelectedCategoryTypes] = useState<MsgCategoryTypeEnum[]>();

  const categoryOptions = useMemo<IOption<MsgCategoryEnum>[]>(() => {
    const allowedCategoryTypes = selectedCategoryTypes?.length
      ? selectedCategoryTypes
      : Object.values(MsgCategoryTypeEnum);
    return allowedCategoryTypes
      .flatMap((categoryType) => categoryMap[categoryType] || [])
      .map((category) => ({
        id: category,
        title: intl.formatMessage({
          id: `MessageCenterPage.message.category.${category}.filter`,
        }),
      }));
  }, [intl, selectedCategoryTypes]);

  const statusTypeOptions = useMemo<IOption<keyof typeof AllowedMessagesStates>[]>(
    () =>
      Object.values(AllowedMessagesStates).map((msgStatus) => ({
        id: msgStatus,
        title: intl.formatMessage({ id: `MessageCenterPage.message.status.${msgStatus}` }),
      })),
    [intl]
  );

  const projectOptions = useMemo<IOption<Guid>[]>(
    () =>
      projectList?.map((project) => ({
        id: project.id,
        title: project.name,
      })) || [],
    [projectList]
  );

  const organizationOptions = useMemo<IOption<Guid>[]>(
    () =>
      (currentAppUser?.organizationUsers?.map((orgUser) => orgUser.organization) || []).map(
        (organization): IOption<Guid> => ({
          id: organization.id,
          title: organization.name,
        })
      ),
    [currentAppUser]
  );

  const filters = useMemo<BackendFilter<MsgCenterListRequestDto>[]>(
    () => [
      createBackendDateFilter(
        'date',
        DATE_FILTER_TYPES,
        {},
        { type: CREATED_DATE_KEY, from: defaultFromFilter, to: defaultToFilter }
      ),
      createBackendSelectFilter(
        CATEGORY_TYPE_FILTER,
        { label: <Fmt id="MessageCenterPage.filters.categoryType.label" />, options: categoryTypeOptions },
        (request, value) => ({
          ...request,
          msgCategoryTypeEnumFilter: value.values,
        })
      ),
      createBackendSelectFilter(
        'category',
        { label: <Fmt id="MessageCenterPage.filters.category.label" />, options: categoryOptions },
        (request, value) => ({
          ...request,
          msgCategoryEnumFilter: value.values,
        })
      ),
      createBackendSelectFilter(
        'status',
        { label: <Fmt id="MessageCenterPage.filters.state.label" />, options: statusTypeOptions },
        (request, value) => ({
          ...request,
          statusFilter: value.values,
        })
      ),
      createBackendSelectFilter(
        'project',
        { label: <Fmt id="MessageCenterPage.filters.project.label" />, options: projectOptions },
        (request, value) => ({
          ...request,
          projectFilter: value.values,
        }),
        defaultProjectId && SELECT_DEFAULT_VALUE([defaultProjectId])
      ),
      createBackendSelectFilter(
        'organization',
        {
          label: <Fmt id="general.organization" />,
          options: organizationOptions,
        },
        (accu, value) => ({
          ...accu,
          projectFilter: (projectList || [])
            .filter((project) =>
              value.values.some(
                (orgId) =>
                  orgId === project.organization.id &&
                  (!accu.projectFilter?.length || accu.projectFilter.some((projectId) => projectId === project.id))
              )
            )
            .map((project) => project.id),
        })
      ),
    ],
    [
      defaultFromFilter,
      defaultToFilter,
      categoryTypeOptions,
      categoryOptions,
      statusTypeOptions,
      projectOptions,
      defaultProjectId,
      organizationOptions,
      projectList,
    ]
  );

  const { filtersWithValues, order, ...filterProps } = useFilters(
    filters,
    ORDER_OPTIONS,
    FiltersPersistentKey.MessageCenterMessages
  );

  const [messagesList, messagesDispatch] = useReducer(reducer, getDefaultMessageList());

  useEffect(() => {
    // workaround: one filter depends on another filter ... TODO: create a new filter with both values
    setSelectedCategoryTypes(
      (filtersWithValues.find((filter) => filter.key === CATEGORY_TYPE_FILTER)?.value as SelectFilterValue<
        MsgCategoryTypeEnum
      >)?.values
    );

    // go back to first page on changing filters
    messagesDispatch({ type: 'resetPage' });
  }, [filtersWithValues]);

  const selectedMessage: MessageListDto = useMemo(
    () => messagesList.messages?.find((m) => messagesList.selectedMessageId === m.id),
    [messagesList.messages, messagesList.selectedMessageId]
  );

  const messageRequestSettings = useMemo<MsgCenterListRequestDto>(
    () =>
      accumulateFilterValues(filtersWithValues, {
        from: messagesList.currentPageIndex * messagesList.pageSize,
        size: messagesList.pageSize,
        order: [{ property: order.key, direction: order.direction }],
      }),
    [filtersWithValues, order, messagesList.currentPageIndex, messagesList.pageSize]
  );

  const [_messages, messagesError, messagesLoading, loadMessages] = useApiData(
    (ct) => api.master.messageCenter.getPagedMessages(messageRequestSettings, ct),
    { fetchCallback: (messages) => messagesDispatch({ type: 'setMessages', data: messages }) }
  );

  useDebounce(loadMessages, 100, [messageRequestSettings]);

  const errorResetButton = useMemo(
    () => (
      <StackPanel padding>
        <Button type="primary" onClick={() => filterProps.clearFilters()}>
          {intl.formatMessage({ id: 'general.clearFilters' })}
        </Button>
      </StackPanel>
    ),
    [filterProps?.clearFilters]
  );

  const projects = useStoreSelector(projectsListSelector);
  useDirtyStoreReload(
    (state) => state.allProjects,
    (dispatch) => dispatch.allProjects
  );

  const projectNames = useMemo(
    (): Dictionary<ProjectMessageNames> =>
      projects?.reduce(
        (acc, project) => ({
          ...acc,
          [project.id]: { name: project.name, organizationName: project.organization.name },
        }),
        {}
      ) || {},
    [projects]
  );

  // render
  return (
    <>
      <MasterComponent
        url={url}
        maxWidth={800}
        title={intl.formatMessage({ id: 'MessageCenterPage.tabs.messages' })}
        children={(onClick, selectedItemId) => (
          <ContentGate
            error={messagesError}
            additionalErrorContent={errorResetButton}
            loading={messagesLoading}
            delay={100}
            overlay
          >
            <MessageGrid
              filters={filtersWithValues}
              order={order}
              orderOptions={ORDER_OPTIONS}
              {...filterProps}
              messageList={messagesList?.messages || []}
              currentPageIndex={messagesList?.currentPageIndex}
              totalMessages={messagesList?.total}
              pageSize={messagesList?.pageSize}
              messagesDispatch={messagesDispatch}
              onClick={onClick}
              selectedItemId={selectedItemId}
              setMessageState={setMessageState}
            />
          </ContentGate>
        )}
      />
      <Switch>
        <Route
          path={`${url}/:itemId`}
          render={(props) => (
            <MasterComponent
              key={props.match.params.itemId}
              url={props.match.url}
              maxWidth={null}
              title={
                !!selectedMessage &&
                intl.formatMessage({ id: `MessageCenterPage.message.category.${selectedMessage.category}` })
              }
              children={() => (
                <StackPanel vertical scrollable>
                  <MessageDetail
                    messageId={props.match.params.itemId}
                    projectNames={projectNames}
                    setMessageState={setMessageState}
                    messagesDispatch={messagesDispatch}
                  />
                </StackPanel>
              )}
            />
          )}
        />
      </Switch>
    </>
  );
};

export const MessageCenterMessages = memoizeWithIntl(MessageCenterMessagesComponent);
