import { Form } from 'antd';
import { DirectoryListDto, DocumentsMultipleMoveStrategyEnum } from 'api/completeApiInterfaces';
import { DirectoriesTreeSelectFormItem } from 'components/DirectoriesTreeSelect/DirectoriesTreeSelect';
import DocumentMultipleActionError from 'components/DocumentMultipleActionError';
import PathDisplay from 'components/PathDisplay';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FC } from 'react';
import { requiredRule } from 'utils/formHelpers';
import styles from './DocumentsMoveForm.module.less';

export type DocumentsMoveFormData = {
  destinationDirectory: Guid;
};

type Props = {
  selectedDocuments: Guid[];
  selectedLinks: Guid[];
  selectedDirectory: DirectoryListDto;
  destinationDirectory: Guid;
  errors: JSX.Element[];
  linkErrors: JSX.Element[];
  strategy: DocumentsMultipleMoveStrategyEnum;
};

const DocumentsMoveForm: FC<Props> = ({
  selectedDirectory,
  destinationDirectory,
  selectedDocuments,
  selectedLinks,
  strategy,
  errors,
  linkErrors,
}) => {
  const intl = useIntl();

  return (
    <>
      <div className={styles.movingDocumentsWrap}>
        <div className={styles.movingDocumentsTitle}>
          {<Fmt id="DocumentsMoveFormModal.moveDocuments" values={{ count: selectedDocuments.length }} />}
        </div>
        <div className={styles.movingDocumentsTitle}>
          {<Fmt id="DocumentsMoveFormModal.moveLinks" values={{ count: selectedLinks.length }} />}
        </div>
        <div className={styles.movingDocumentsTitle}>
          <Fmt id="DocumentsMoveFormModal.moveFromFolder" />
        </div>
        {<PathDisplay path={selectedDirectory.path} className={styles.movingDocumentsPath} />}
      </div>
      <Form.Item
        className={styles.item}
        label={intl.formatMessage({ id: 'DocumentsMoveFormModal.destinationDirectoryId.label' })}
        name="destinationDirectory"
        initialValue={destinationDirectory}
        rules={[requiredRule('DirectoryCreateForm.form.items.name.rules.required')]}
      >
        <DirectoriesTreeSelectFormItem
          isItemDisabled={(item) => selectedDirectory.id === item.id}
          autoFocus
          dropdownStyle={{ maxHeight: 400, overflow: 'auto', maxWidth: 300 }}
          placeholder={intl.formatMessage({ id: 'DirectoryMoveFormModal.destinationDirectoryId.placeholder' })}
        />
      </Form.Item>
      <DocumentMultipleActionError
        errors={errors}
        titleId={
          !!strategy && strategy === DocumentsMultipleMoveStrategyEnum.rename
            ? 'DocumentsMoveFormModal.errorsTitle.duplicate'
            : 'DocumentsMoveFormModal.errorsTitle.missed'
        }
      />
      {!!linkErrors.length && (
        <DocumentMultipleActionError errors={errors} titleId={'DocumentsMoveFormModal.errorsTitle.duplicateLinks'} />
      )}
    </>
  );
};

export default DocumentsMoveForm;
