import { Tree, Typography } from 'antd';
import { JSONVariableTypeEnum, OrgProjectCardDataDto, ProjectCardDataDto } from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import { EditButton } from 'components/ActionButtons';
import { FormModalWrapper } from 'components/forms/FormModalWrapper';
import { HeightMeasureWrap } from 'components/FullHeightWrap/HeightMeasureWrap';
import { JSONVariable } from 'components/JSONVariableFormItems/JSONVariableTypes';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import StackPanel from 'components/StackPanel';
import { useIntl } from 'hooks';
import { useHeight } from 'hooks/useHeight';
import { useTreeExpandedKeys } from 'hooks/useTreeExpandedKeys';
import produce from 'immer';
import { InjectedIntl } from 'locale';
import { formatJsonData } from 'pages/ProjectDashboardPage/ProjectDashboardPage.ProjectAdditionalData';
import Panel from 'pages/ProjectSettingsPage/Panel';
import ProjectCardDataEditForm, {
  ProjectCardDataEditFormData,
} from 'pages/ProjectSettingsPage/ProjectSettingsPageGeneral/ProjectSettings.ProjectCardDataEditForm';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { smartFilter } from 'utils';
import { filterTreeByName, findInTree } from 'utils/tree/treeHelpers';

export type TreeData = {
  key: Guid;
  id: Guid;
  title: React.ReactNode;
  children: TreeData[];
  name: string;
  variable: JSONVariableTypeEnum;
  data?: JSONVariable;
};

type Props = {
  organizationId: Guid;
  setProjectCardData: (data: OrgProjectCardDataDto[]) => void;
  projectCardData: OrgProjectCardDataDto[];
  buttons?: React.ReactNode;
};

export const mapDataToTreeData = (data: ProjectCardDataDto[], intl: InjectedIntl): TreeData[] => {
  if (!data) return [];
  return data.map((item) => {
    const value = item.data ? formatJsonData(item.data, intl) : undefined;
    const isDiffValue =
      item.variable === JSONVariableTypeEnum.stringDiff || item.variable === JSONVariableTypeEnum.numberDiff;

    const childrenDiffValues: TreeData[] = isDiffValue
      ? [
          {
            key: `${item.id}_old`,
            id: `${item.id}_old`,
            name: `old`, // intl.formatMessage({ id: 'ProjectSettingsProjectCardDataTree.oldValue' }),
            variable: item.variable,
            title: (
              <div>
                {intl.formatMessage({ id: 'ProjectSettingsProjectCardDataTree.oldValue' })}
                {!!value && (
                  <>
                    {': '}
                    {/* @ts-ignore */}
                    <strong>{value.oldValue}</strong>
                  </>
                )}
              </div>
            ),
            children: [],
          },
          {
            key: `${item.id}_new`,
            id: `${item.id}_new`,
            name: `new`,
            variable: item.variable,
            title: (
              <div>
                {intl.formatMessage({ id: 'ProjectSettingsProjectCardDataTree.newValue' })}
                {!!value && (
                  <>
                    {': '}
                    {/* @ts-ignore */}
                    <strong>{value.value}</strong>
                  </>
                )}
              </div>
            ),
            children: [],
          },
        ]
      : [];

    return {
      key: item.id,
      id: item.id,
      name: item.name,
      variable: item.variable,
      title: (
        <div>
          {item.name}
          {item.unit ? <Typography.Text italic type="secondary">{` [${item.unit}]`}</Typography.Text> : null}
          {!!value && !isDiffValue && (
            <>
              {': '}
              <strong>{value}</strong>
            </>
          )}
        </div>
      ),
      children: item.children
        ? [...childrenDiffValues, ...mapDataToTreeData(item.children, intl)]
        : [...childrenDiffValues],
    };
  });
};

export const updateTreeNode = (tree: ProjectCardDataDto[], nodeId: Guid, data: JSONVariable) => {
  return produce(tree, (draft) => {
    const node = findInTree(draft, nodeId);
    node.data = { ...node.data, ...data };
  });
};

const ProjectSettingsProjectCardDataTree: FunctionComponent<Props> = ({
  projectCardData,
  setProjectCardData,
  organizationId,
  buttons,
}) => {
  const [search, setSearch] = useState<string>('');
  const { url, params } = useRouteMatch<{ rootId: Guid }>();
  const intl = useIntl();

  const [editingProjectCardData, setEditingProjectCardData] = useState<ProjectCardDataDto | null>(null);

  const updateProjectCardDefinition = useCallback(
    async (values: ProjectCardDataEditFormData): Promise<ApiError> => {
      const updatedData = updateTreeNode(projectCardData, values.id, values.data as JSONVariable);

      if (!updatedData) return null;
      setProjectCardData(updatedData);
      setEditingProjectCardData(null);
      return null;
    },
    [organizationId, projectCardData]
  );

  const onEdit = useCallback(
    async (cardDataId: Guid) => {
      const item = findInTree(projectCardData, cardDataId);
      if (!item) return;
      setEditingProjectCardData(item);
    },
    [projectCardData]
  );

  const [treeData, setTreeData] = useState<TreeData[]>([]);

  useEffect(() => {
    setTreeData(mapDataToTreeData(projectCardData, intl));
  }, [intl, projectCardData]);

  const { tableWrapRef, wrapHeight } = useHeight();
  const filteredTreeData = filterTreeByName(treeData, search);

  const [expandedNodeKeys, handleOnExpand] = useTreeExpandedKeys(treeData, 'autoExpandAllOnFirstLoad');

  const [selectedItemId, onSelect] = useState<string>();

  return (
    <>
      <MasterComponent
        maxWidth={600}
        url={url}
        title={projectCardData?.find((item) => item.id === params.rootId)?.name}
        children={() => (
          <>
            <StackPanel autoHeight vertical>
              <Panel
                panelWidth={null}
                noMargin
                onSearch={setSearch}
                searchValue={search}
                panelContentStyle={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
                additionalItems={buttons}
              >
                <HeightMeasureWrap innerRef={tableWrapRef}>
                  <Tree
                    filterTreeNode={(node) => smartFilter(node.name || '', search)}
                    height={wrapHeight}
                    virtual
                    // draggable
                    onDrop={(info) => {
                      console.log(info);
                      //setTreeData(moveToTree(treeData, info.dragNode?.id, info.node?.id, info.dropPosition));
                    }}
                    selectable={true}
                    checkable={false}
                    selectedKeys={selectedItemId ? [selectedItemId] : []}
                    onSelect={(ids) => onSelect(ids[0] as string)}
                    blockNode
                    treeData={filteredTreeData}
                    focusable
                    expandedKeys={expandedNodeKeys}
                    onExpand={handleOnExpand}
                    titleRender={(node) => (
                      <FlowLayout growFirst>
                        <div>{node.title}</div>
                        {selectedItemId === node.id && (
                          <>
                            {node.variable !== JSONVariableTypeEnum.empty &&
                              node.name !== 'old' &&
                              node.name !== 'new' && <EditButton onClick={() => onEdit(node.id)} />}
                            {/*<DeleteButton onClick={() => deleteLink(node.id)} />*/}
                          </>
                        )}
                      </FlowLayout>
                    )}
                  />
                  {!treeData?.length ||
                    (!filteredTreeData?.length && (
                      <ListEmpty filtered={filteredTreeData?.length || 0} total={treeData?.length || 0} />
                    ))}
                </HeightMeasureWrap>
              </Panel>
            </StackPanel>
          </>
        )}
      />
      {!!editingProjectCardData && (
        <FormModalWrapper
          title={intl.formatMessage({ id: 'OrgProjectCardData.editSupplementData' })}
          open={!!editingProjectCardData}
          onClose={() => setEditingProjectCardData(null)}
          onSubmit={updateProjectCardDefinition}
        >
          <ProjectCardDataEditForm cardData={editingProjectCardData} />
        </FormModalWrapper>
      )}
    </>
  );
};

ProjectSettingsProjectCardDataTree.displayName = 'ProjectSettingsProjectCardDataTree';

export default ProjectSettingsProjectCardDataTree;
