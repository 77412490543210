import { InputRef } from 'antd';
import { api } from 'api';
import { RoleCreateDto, RoleDto, RolePatchDto, UserRemoveStrategyEnum } from 'api/completeApiInterfaces';
import { ApiError, ServiceErrorEnum } from 'api/errors';
import { AxiosResponse } from 'axios';
import { RoleInProcedureError, RoleUsageError } from 'components/forms/RoleForm/RoleUsageError';
import { useFocus } from 'hooks/useFocus';
import { InjectedIntlProps } from 'locale';
import React, { FunctionComponent, ReactNode, useCallback, useState } from 'react';
import { processApiError } from 'utils';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper } from '../FormModalWrapper';
import RoleForm, { RoleFormData } from './RoleForm';

type Props = FormModalProps<RoleDto> &
  InjectedIntlProps & {
    validateUniqueName: (name: string) => boolean;
    defaults?: RoleDto;
  };

const RoleFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, validateUniqueName, onClose, defaults, intl, ...restProps } = props;
  const [error, setError] = useState<ReactNode>(null);

  const handleSubmit = useCallback(
    async (values: RoleFormData) => {
      const data: RoleCreateDto & RolePatchDto = {
        name: values.name,
        description: values.description,
        userId: values.userId,
        removeUser: !values.userId,
        strategy: values.forceRemove ? UserRemoveStrategyEnum.remove : null,
        associateIds: values.associateIds,
      };

      let err: ApiError;
      let resp: AxiosResponse<RoleDto>;

      setError(null);

      if (defaults) {
        // update
        [err, resp] = await api.project.roles.updateRoleById(defaults.id, data);
        const error = err && processApiError(err);

        if (error && error.referenceErrorCode === ServiceErrorEnum.RoleInProcedureError) {
          const data = error.errorData as { roleErrors: RoleInProcedureError[] };
          const errorComponent = (
            <>
              {intl.formatMessage(
                { id: 'UserDetailPanel.RolesTab.ConfirmRemove.question' },
                { count: data.roleErrors.length }
              )}
              <RoleUsageError roleErrors={data.roleErrors} intl={intl} />
            </>
          );
          setError(errorComponent);
          return null;
        }
      } else {
        // create
        [err, resp] = await api.project.roles.createRole(data);
      }

      if (err) return err;
      await onSubmit(resp.data);
      return null;
    },
    [defaults]
  );

  const handleClose = useCallback(() => {
    setError(null);
    onClose();
  }, [setError, onClose]);

  const { setInputRef } = useFocus<InputRef>(restProps.open);

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      onClose={handleClose}
      title={defaults ? defaults.name : null}
      titleId="RoleFormModal.title.create"
      width={800}
      {...restProps}
    >
      <RoleForm validateUniqueName={validateUniqueName} defaults={defaults} error={error} setRef={setInputRef} />
    </FormModalWrapper>
  );
};

export default RoleFormModal;
