import { Form, Input, InputRef } from 'antd';
import FilenameErrorMessage from 'components/FilenameErrorMessage';
import { FILE_AND_FOLDER_NAME_REGEX } from 'config/constants';
import { useIntl } from 'hooks';
import React, { FC } from 'react';
import { duplicateNameRuleCallback, maxLengthRule, requiredRule } from 'utils/formHelpers';

export type DirectoryLinkEditFormData = {
  name: string;
};

type Props = {
  validateUniqueName: (name: string) => boolean;
  initialData: DirectoryLinkEditFormData;
  setRef: (ref: InputRef) => void;
};

const DirectoryLinkEditForm: FC<Props> = ({ validateUniqueName, initialData, setRef }) => {
  const intl = useIntl();

  return (
    <>
      <Form.Item
        name="name"
        initialValue={initialData.name}
        label={intl.formatMessage({ id: 'DirectoryLinkEditForm.form.items.name.label' })}
        rules={[
          requiredRule('DirectoryLinkEditForm.form.items.name.rules.required', true),
          maxLengthRule('general.maxNameLength'),
          {
            pattern: FILE_AND_FOLDER_NAME_REGEX,
            message: <FilenameErrorMessage />,
          },
          duplicateNameRuleCallback(validateUniqueName, 'DirectoryLinkEditForm.form.items.name.rules.nameExists'),
        ]}
      >
        <Input autoFocus ref={setRef} />
      </Form.Item>
    </>
  );
};

export default DirectoryLinkEditForm;
