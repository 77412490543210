import { CheckCircleOutlined } from '@ant-design/icons';
import { Checkbox, Col, Row, Typography } from 'antd';
import { SortOrder } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { DocumentsGridHeaderStyled } from 'components/DocumentsGridHeader/DocumentsGridHeaderStyled';
import { EmptyGate } from 'components/EmptyGate/EmptyGate';
import {
  BooleanOption,
  createFrontendBooleanFilter,
} from 'components/filters/components/RadioFilter/variants/BooleanFilter/BooleanFilter';
import { createFrontendSingleSelectFilter } from 'components/filters/components/SelectFilter/SelectFilter';
import { createFrontendSingleTextFilter } from 'components/filters/components/TextFilter/TextFilter';
import { FiltersPersistentKey, FrontendFilter } from 'components/filters/filterTypes';
import { FrontendOrderOption } from 'components/filters/orderTypes';
import { OrderFilterToolbar } from 'components/filters/render/OrderFilterToolbar/OrderFilterToolbar';
import { MdProjectListWithUnpackedData } from 'components/JSONVariableFormItems/dataUnpackers/mdProjectUnpacker.utils';
import { SettingsBox } from 'components/SettingsBox/SettingsBox';
import StackPanel from 'components/StackPanel';
import { useFrontendFilters, useIntl } from 'hooks';
import { Fmt } from 'locale';
import {
  MAP_MD_ACTION_CHARACTER_TO_MESSAGE_IDS,
  MdProjectDataActionCharacterEnum,
} from 'pages/ReportDetailPage/ReportDetails/MDApprovalProjectsReport/MDApprovalProjectReport.utils';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useDebounce } from 'react-use';
import { AutoSizer, List } from 'react-virtualized';
import { ignoreRef } from 'utils';
import { textComparer } from 'utils/comparators';
import styles from './MdProjectListSelectFormItem.module.less';

const INITIAL_DOCUMENT_LIST_HEIGHT = 200;
const INITITAL_DOCUMENT_ROW_HEIGHT = 35;
const OVERSCAN_ROW_COUNT = 5;

const FILTERS_PERSISTENT_KEY = FiltersPersistentKey.CkMeetinPreparationgProjects;

export const MD_PROJECT_LIST_ORDER_OPTIONS: FrontendOrderOption<MdProjectListWithUnpackedData>[] = [
  {
    key: 'name',
    label: <Fmt id="general.name" />,
    compare: textComparer.map((project) => project.name),
  },
  {
    key: 'actionCharacter',
    defaultOrder: SortOrder.asc,
    label: <Fmt id="MD.ProjectVariableEnum.actionCharacter.name" />,
    compare: (a, b) => {
      const characterA = a.actionCharacter?.value;
      const characterB = b.actionCharacter?.value;
      return characterA === characterB
        ? 0
        : characterA === MdProjectDataActionCharacterEnum.investment
        ? -1
        : characterA === MdProjectDataActionCharacterEnum.nonInvestment
        ? -1
        : 1;
    },
  },
];

type Props = {
  value: Guid[];
  onChange: (value: Guid[]) => void;
  projectsList: MdProjectListWithUnpackedData[];
  disabled?: boolean;
  disabledProjectIds: Set<Guid>;
};

const MdProjectListSelectFormItemComponent: FunctionComponent<Props> = ({
  value,
  onChange,
  projectsList,
  disabledProjectIds,
  disabled,
}) => {
  const intl = useIntl();
  const handleProjectListChange = useCallback(
    (projectId: Guid, checked: boolean) => {
      if (checked) {
        onChange([...(value || []), projectId]);
      } else {
        onChange(value.filter((id) => id !== projectId));
      }
    },
    [value, onChange]
  );

  useDebounce(
    () => {
      const newList = (value && value?.filter((id) => projectsList.some((project) => project.id === id))) || [];
      if (newList.length !== value?.length) {
        onChange(newList);
      }
    },
    200,
    [projectsList]
  );

  const mdProjectFilters = useMemo(
    (): FrontendFilter<MdProjectListWithUnpackedData>[] => [
      createFrontendSingleTextFilter('text', (project) => project.name, {
        label: <Fmt id="general.name" />,
      }),
      createFrontendSingleSelectFilter(
        'actionCharacter',
        {
          label: <Fmt id="MD.ProjectVariableEnum.actionCharacter.name" />,
          options: Object.entries(MAP_MD_ACTION_CHARACTER_TO_MESSAGE_IDS).map(([key, messageId]) => ({
            id: key,
            title: intl.formatMessage({ id: messageId }),
          })),
        },
        (project) => project.actionCharacter?.value
      ),
      createFrontendBooleanFilter(
        'currentState',
        {
          label: <Fmt id="MD.ProjectsCreateApprovalModal.filter.current.title" />,
          title: <Fmt id="MD.ProjectsCreateApprovalModal.filter.current.description" />,
          trueLabel: <Fmt id="MD.ProjectsCreateApprovalModal.filter.current.yes" />,
          falseLabel: <Fmt id="MD.ProjectsCreateApprovalModal.filter.current.no" />,
        },
        (project) => (project.isUpToDate ? BooleanOption.True : BooleanOption.False)
      ),
    ],
    [intl]
  );

  const { orderedItems, ...filterProps } = useFrontendFilters<MdProjectListWithUnpackedData>(
    mdProjectFilters,
    MD_PROJECT_LIST_ORDER_OPTIONS,
    projectsList || [],
    FILTERS_PERSISTENT_KEY
  );

  const renderSelectedProjectRow = useCallback(
    ({ index, key, style }) => {
      const project = orderedItems[index];
      const messageId =
        project.actionCharacter?.value &&
        MAP_MD_ACTION_CHARACTER_TO_MESSAGE_IDS[project.actionCharacter?.value as MdProjectDataActionCharacterEnum];
      const actionCharacterText = messageId && <Fmt id={messageId} />;
      return (
        <Row key={key} className={styles.documentRow} style={style} gutter={6}>
          <Col span={10}>
            <StackPanel>
              <Checkbox
                onChange={(e) => handleProjectListChange(project.id, e.target.checked)}
                checked={value?.some((id) => id === project.id)}
                className={styles.checkItem}
                disabled={disabled || disabledProjectIds.has(project.id)}
              />
              <CommonHubTooltip title={project.name}>
                <Typography.Text ellipsis>{project.name}</Typography.Text>
              </CommonHubTooltip>
            </StackPanel>
          </Col>
          <Col span={10}>
            <CommonHubTooltip title={actionCharacterText}>
              <Typography.Text ellipsis>{actionCharacterText}</Typography.Text>
            </CommonHubTooltip>
          </Col>
          <Col span={4} className={styles.centered}>
            {project.isUpToDate && <CheckCircleOutlined />}
          </Col>
        </Row>
      );
    },
    [handleProjectListChange, orderedItems, value, disabled]
  );

  return (
    <>
      <DocumentsGridHeaderStyled>
        <OrderFilterToolbar alignRight {...filterProps} />
      </DocumentsGridHeaderStyled>
      <SettingsBox>
        <Row key="header" className={styles.documentRow} gutter={6}>
          <Col span={10}>
            <Fmt id="MD.ProjectVariableEnum.projectName.name" />
          </Col>
          <Col span={10}>
            <Fmt id="MD.ProjectVariableEnum.actionCharacter.name" />
          </Col>
          <Col span={4} className={styles.centered}>
            <Fmt id="general.Current" />
          </Col>
        </Row>
      </SettingsBox>
      <StackPanel vertical scrollable className={styles.filesGrid}>
        <EmptyGate empty={!orderedItems?.length}>
          <AutoSizer disableHeight>
            {({ width }) => (
              <List
                height={INITIAL_DOCUMENT_LIST_HEIGHT}
                overscanRowCount={OVERSCAN_ROW_COUNT}
                rowCount={orderedItems.length}
                rowHeight={INITITAL_DOCUMENT_ROW_HEIGHT}
                rowRenderer={renderSelectedProjectRow}
                width={width}
              />
            )}
          </AutoSizer>
        </EmptyGate>
      </StackPanel>
    </>
  );
};

export const MdProjectListSelectFormItem: FunctionComponent<Omit<Props, 'value' | 'onChange'>> = ignoreRef(
  React.memo(MdProjectListSelectFormItemComponent)
);
