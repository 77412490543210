import { Alert, Form, Select } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { DefaultOptionType } from 'antd/lib/select';
import { ProjectBaseListDto } from 'api/completeApiInterfaces';
import { useIntl } from 'hooks';
import React, { FC, useEffect, useMemo } from 'react';
import { smartFilter } from 'utils';

export type MdOverheadProjectSettingsFormData = {
  projectId: Guid;
};

type Props = {
  defaultProjectId: Guid;
  organizationId: Guid;
  projects: ProjectBaseListDto[];
};

const propertyFilter = (input: string, option: DefaultOptionType) => {
  return smartFilter(option.label?.toString() || option.children?.toString(), input);
};

const MdOverheadProjectSettingsFormComponent: FC<Props> = ({ defaultProjectId, projects }) => {
  const intl = useIntl();

  const form = useFormInstance();

  useEffect(() => {
    form.setFieldsValue({ projectId: defaultProjectId });
  }, [defaultProjectId]);

  const projectOptions = useMemo((): DefaultOptionType[] => {
    const sortedProjects = [...projects].sort((p1, p2) => p1.name.localeCompare(p2.name));
    return sortedProjects.map((project) => ({
      label: project.name,
      value: project.id,
    }));
  }, [projects]);

  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: 'MD.OverheadProjectSettingsForm.overheadProject.label' })}
        name="projectId"
        initialValue={defaultProjectId}
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: 'MD.OverheadProjectSettingsForm.overheadProject.label.required' }),
          },
        ]}
      >
        <Select options={projectOptions} showSearch filterOption={propertyFilter} />
      </Form.Item>
      <Alert
        message={intl.formatMessage({ id: 'MD.OverheadProjectSettingsForm.overheadProject.alert' })}
        type="warning"
        showIcon
      />
    </>
  );
};
export const MdOverheadProjectSettingsForm = React.memo(MdOverheadProjectSettingsFormComponent);
