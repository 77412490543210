import {
  CheckCircleOutlined,
  CloudDownloadOutlined,
  DeleteOutlined,
  DeploymentUnitOutlined,
  ExportOutlined,
  EyeOutlined,
  HighlightOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  ProfileOutlined,
  ShareAltOutlined,
  TagsOutlined,
} from '@ant-design/icons';
import { message, Space } from 'antd';
import { api } from 'api';
import { DirectoryContentDto, FavoriteDocumentsResultsDto } from 'api/completeApiInterfaces';
import DocumentCheckboxControl from 'components/DocumentCheckboxControl/DocumentCheckboxControl';
import { FiltersContext } from 'components/filters/FiltersContextProvider';
import { FilterToggleButton } from 'components/filters/render/FilterToggleButton/FilterToggleButton';
import { OrderSelect } from 'components/filters/render/OrderSelect/OrderSelect';
import { isWorkflowDocumentDisabled } from 'components/forms/WorkflowInstantiationForm';
import WorkflowInstantiationFormModal from 'components/forms/WorkflowInstantiationForm/WorkflowInstantiationFormModal';
import { ModelOnIcon, ShortcutIcon, WorkflowIcon } from 'components/Icons/HubEntitiesIcons';
import ShrinkableToolbar, { ToolbarItemProps } from 'components/Toolbar/ShrinkableToolbar';
import Toolbar from 'components/Toolbar/Toolbar';
import { TOOLTIP_MOUSE_ENTER_DELAY } from 'config/constants';
import { useCurrentProjectUser, useIntl } from 'hooks';
import { useFavoriteButton } from 'hooks/useOnOffButton';
import { useWorkflowInstantiation } from 'hooks/useWorkflowInstantiation';
import { uniqBy } from 'lodash';
import { SelectedItemsContext } from 'pages/AllDocumentsPage/AllDocumentsPage.SelectedItemsContextProvider';
import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ConnectedDirectory } from 'utils/typeMappings/directories/directoryTypes';
import { isDocumentLink } from './AllDocumentsPageDocumentsGrid';
import ShowFavoriteSettingResponseModal from './ShowFavoriteSettingResponseModal';

type Props = {
  className?: string;
  selectedFilesIds: Set<Guid>;
  moveDisabled?: boolean;
  discardDisabled?: boolean;
  downloadDisabled?: boolean;
  bulkChangeStateDisabled?: boolean;
  shareDownloadDisabled?: boolean;
  directory: ConnectedDirectory;
  documents?: DirectoryContentDto[];
  onChangeFavorites?: () => void;
  onDocumentsMove: (selectedIds: Set<Guid>) => void;
  onDocumentsDownload: (selectedIds: Set<Guid>) => void;
  onDocumentsDiscard: (selectedIds: Set<Guid>) => void;
  onShareDownload: (selectedIds: Set<Guid>) => void;
  onAppUserShare: (selectedIds: Set<Guid>) => void;
  onDocumentLinksCreate: (selectedIds: Set<Guid>) => void;
  onBulkEditChangeState: (selectedIds: Set<Guid>) => void;
  onBulkEditSetLabels: (selectedIds: Set<Guid>) => void;
  onBulkEditDocumentWatch: (selectedIds: Set<Guid>) => void;
  onCreateAssignment: (selectedIds: Set<Guid>) => void;
  onBulkRemoveFromModels: () => void;
  onBulkAddToModels: () => void;
};

const AllDocumentsPageFileToolbar: FunctionComponent<Props> = ({
  className,
  selectedFilesIds,
  moveDisabled,
  discardDisabled,
  downloadDisabled,
  bulkChangeStateDisabled,
  shareDownloadDisabled,
  directory,
  documents,
  onDocumentsMove,
  onDocumentsDownload,
  onDocumentsDiscard,
  onChangeFavorites,
  onShareDownload,
  onAppUserShare,
  onDocumentLinksCreate,
  onBulkEditChangeState,
  onBulkEditSetLabels,
  onBulkEditDocumentWatch,
  onCreateAssignment,
  onBulkRemoveFromModels,
  onBulkAddToModels,
}) => {
  const intl = useIntl();
  const [areFavorite, setAreFavorite] = useState(false);
  const [favoriteResponse, setFavoriteResponse] = useState<FavoriteDocumentsResultsDto>(undefined);

  const { selectAll, deselectAll, isAllSelected, isAnyItemSelected, totalItemsCount } = useContext(
    SelectedItemsContext
  );

  const { setOrder, order, orderOptions, activeFiltersCount, isFilterActive, toggleFilters } = useContext(
    FiltersContext
  );

  const handleOnChangeFavorites = useCallback(
    (isFavorite: boolean, response: FavoriteDocumentsResultsDto) => {
      setFavoriteResponse(response);
      setAreFavorite(!areFavorite);
      onChangeFavorites?.();
    },
    [onChangeFavorites, areFavorite]
  );

  useEffect(() => {
    setAreFavorite(false);
  }, [selectedFilesIds]);

  const selectedFavoritesIds = useMemo(() => {
    return Array.from(selectedFilesIds);
  }, [selectedFilesIds]);

  const addFavoriteDocuments = useCallback(async () => {
    const selectedDocuments = documents.filter((d) => selectedFavoritesIds.includes(d.id));
    const documentsIds = selectedDocuments.filter((d) => !isDocumentLink(d)).map((d) => d.id);
    const documentLinksIds = selectedDocuments.filter((d) => isDocumentLink(d)).map((d) => d.id);
    return api.project.documents.addFavoriteDocuments(documentsIds, documentLinksIds);
  }, [selectedFavoritesIds]);

  const removeFavoriteDocuments = useCallback(() => {
    const selectedDocuments = documents.filter((d) => selectedFavoritesIds.includes(d.id));
    const documentsIds = selectedDocuments.filter((d) => !isDocumentLink(d)).map((d) => d.id);
    const documentLinksIds = selectedDocuments.filter((d) => isDocumentLink(d)).map((d) => d.id);
    return api.project.documents.removeFavoriteDocuments(documentsIds, documentLinksIds);
  }, [selectedFavoritesIds]);

  const onFavoriteError = useCallback(() => {
    message.error(intl.formatMessage({ id: 'general.error' }));
  }, [intl]);

  const favoriteButton = useFavoriteButton(
    'favorite',
    selectedFavoritesIds[0],
    areFavorite,
    addFavoriteDocuments,
    removeFavoriteDocuments,
    handleOnChangeFavorites,
    onFavoriteError,
    undefined,
    false
  );

  // workflow modal
  const currentUser = useCurrentProjectUser();
  const selectedDocuments = useMemo(
    () =>
      uniqBy(
        documents?.filter((e) => selectedFilesIds.has(e.id)).map((e) => (isDocumentLink(e) ? e.linkedDocument : e)),
        'id'
      ) || [],
    [documents, selectedFilesIds]
  );

  const workflowDisabled = useMemo(
    () =>
      selectedDocuments
        .map((document) => isWorkflowDocumentDisabled(document, directory, currentUser, intl))
        .find(Boolean),
    [selectedDocuments, directory, currentUser, intl]
  );

  const wfInst = useWorkflowInstantiation(
    !!workflowDisabled,
    typeof workflowDisabled === 'string' && workflowDisabled,
    true
  );

  const items: ToolbarItemProps[] = [
    {
      disabled: moveDisabled,
      icon: <ExportOutlined />,
      label: 'general.move',
      onClick: () => onDocumentsMove(selectedFilesIds),
      key: 'move',
    },
    {
      disabled: shareDownloadDisabled,
      icon: <ShareAltOutlined />,
      label: 'general.docMenu.share',
      key: 'shareDropdown',
      subItems: [
        {
          disabled: shareDownloadDisabled,
          icon: <ShareAltOutlined />,
          label: 'general.docMenu.shareDownload',
          onClick: () => onShareDownload(selectedFilesIds),
          key: 'shareDownload',
        },
        {
          disabled: shareDownloadDisabled,
          icon: <DeploymentUnitOutlined />,
          label: 'general.docMenu.shareAppUserDownload',
          onClick: () => onAppUserShare(selectedFilesIds),
          key: 'shareAppUserDownload',
        },
      ],
    },
    {
      disabled: downloadDisabled,
      icon: <CloudDownloadOutlined />,
      label: 'general.docMenu.downloadInZip',
      onClick: () => onDocumentsDownload(selectedFilesIds),
      key: 'downloadInZip',
    },
    {
      disabled: discardDisabled,
      icon: <DeleteOutlined />,
      label: 'AllDocumentsPage.docMenu.discard',
      onClick: () => onDocumentsDiscard(selectedFilesIds),
      key: 'discard',
    },
    {
      disabled: wfInst.isDisabled,
      tooltip: wfInst.shownTitle,
      icon: <WorkflowIcon />,
      label: 'general.docMenu.startWorkflow',
      onClick: wfInst.modalFormShow,
      key: 'fwInstantiation',
    },
    {
      disabled: false, // TODO: add user permissions
      icon: <ShortcutIcon />,
      label: 'general.docMenu.createDocumentLinks',
      onClick: () => onDocumentLinksCreate(selectedFilesIds),
      key: 'createDocumentLinks',
    },
    favoriteButton,
    {
      disabled: false,
      key: 'createAssignments',
      icon: <CheckCircleOutlined />,
      label: 'AllDocumentsPage.docMenu.createAssignment',
      onClick: () => onCreateAssignment(selectedFilesIds),
    },
    {
      icon: <ProfileOutlined />,
      label: 'general.docMenu.bulkEdit',
      key: 'bulkEdit',
      subItems: [
        {
          disabled: bulkChangeStateDisabled,
          icon: <HighlightOutlined />,
          label: 'general.docMenu.bulkEdit.changeState',
          onClick: () => onBulkEditChangeState(selectedFilesIds),
          key: 'bulkEditChangeState',
        },
        {
          disabled: bulkChangeStateDisabled,
          icon: <TagsOutlined />,
          label: 'general.docMenu.bulkEdit.setLabels',
          onClick: () => onBulkEditSetLabels(selectedFilesIds),
          key: 'bulkEditSetLabels',
        },
        {
          icon: <EyeOutlined />,
          label: 'general.docMenu.bulkEdit.documentWatch',
          onClick: () => onBulkEditDocumentWatch(selectedFilesIds),
          key: 'bulkEditDocumentWatch',
        },
        {
          icon: <ModelOnIcon />,
          label: 'general.docMenu.bulkEdit.modelsSetting',
          key: 'modelSetting',
          subItems: [
            {
              icon: <PlusCircleOutlined />,
              label: 'general.docMenu.bulkEdit.modelsSetting.add',
              onClick: () => onBulkAddToModels(),
              key: 'modelSettingAdd',
            },
            {
              icon: <MinusCircleOutlined />,
              label: 'general.docMenu.bulkEdit.modelsSetting.remove',
              onClick: () => onBulkRemoveFromModels(),
              key: 'modelSettingRemove',
            },
          ],
        },
      ],
    },
  ];

  return (
    <>
      <Toolbar
        className={className}
        leftContent={
          <DocumentCheckboxControl
            selectAll={selectAll}
            selectedFilesIds={selectedFilesIds}
            totalItemsCount={totalItemsCount}
            isAnyItemSelected={isAnyItemSelected}
            deselectAll={deselectAll}
            allFilesSelected={isAllSelected}
          />
        }
        rightContent={
          <Space.Compact>
            <OrderSelect setOrder={setOrder} order={order} orderOptions={orderOptions} showTooltip />
            <FilterToggleButton
              onClick={toggleFilters}
              activeFiltersCount={activeFiltersCount}
              isOn={isFilterActive}
              tooltipDelay={TOOLTIP_MOUSE_ENTER_DELAY}
              showTooltip
            />
          </Space.Compact>
        }
      >
        <ShrinkableToolbar items={items} />
      </Toolbar>
      <WorkflowInstantiationFormModal
        intl={intl}
        onSubmit={wfInst.handleSubmit}
        open={wfInst.modalFormVisible}
        onClose={wfInst.modalFormHide}
        availableTemplates={wfInst.availableTemplates}
        initialDocuments={selectedDocuments}
        startDirectoryId={directory.id}
      />

      <ShowFavoriteSettingResponseModal favoriteResponse={favoriteResponse} setFavoriteResponse={setFavoriteResponse} />
    </>
  );
};

export default AllDocumentsPageFileToolbar;
