import { Form, Modal, Typography } from 'antd';
import {
  JToken,
  ProjectTemplateDirectoryDto,
  ProjectTemplateDirectoryMetadataDto,
  ProjectTemplateMetadataDefinitionDto,
} from 'api/completeApiInterfaces';
import { FormModalProps } from 'components/forms/FormModalProps';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import List from 'components/List';
import StackPanel from 'components/StackPanel';
import { Fmt } from 'locale';
import { Dictionary } from 'lodash';
import { renderProjectCardDefaultDataVariableFormItem } from 'pages/OrganizationsSettingPage/ProjectCardData/ProjectCardDataAddForm';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

type Props = FormModalProps<ProjectTemplateDirectoryMetadataDto[]> & {
  editedDirectory: ProjectTemplateDirectoryDto;
  open: boolean;
  directoryMetadataDefinitions: ProjectTemplateMetadataDefinitionDto[];
};

const ProjectTemplateDirectoryMetadataSettingModalFormModal: FunctionComponent<Props> = (props) => {
  const { open, directoryMetadataDefinitions, onSubmit, onClose, editedDirectory } = props;
  const [data, setData] = useState<Record<string, ProjectTemplateDirectoryMetadataDto>>({});
  const [form] = Form.useForm();

  useEffect(() => {
    const data = editedDirectory?.projectTemplateDirectoryMetadatas?.reduce(
      (acc: Record<string, ProjectTemplateDirectoryMetadataDto>, item: ProjectTemplateDirectoryMetadataDto) => {
        acc[item.projectTemplateMetadataDefinitionId] = item;
        return acc;
      },
      {}
    );
    setData(data || {});
  }, [directoryMetadataDefinitions, editedDirectory?.projectTemplateDirectoryMetadatas]);

  const onFinish = (values: Record<string, Dictionary<JToken>>) => {
    const result: ProjectTemplateDirectoryMetadataDto[] = Object.entries(values).map(([dataId, value]) => {
      return {
        id: data[dataId]?.id || uuid(),
        projectTemplateMetadataDefinitionId: dataId,
        metaValue: value,
      };
    });
    onSubmit(result);
  };

  return (
    <Modal
      width={800}
      open={open}
      onCancel={onClose}
      maskClosable={false}
      onOk={() => form.submit()}
      title={<Fmt id="ProjectTemplateMetadataSettingModal.title" />}
    >
      <Form onFinish={onFinish} form={form} layout={'vertical'}>
        <StackPanel vertical scrollable>
          <GeneralSettingsContainer>
            <List<ProjectTemplateMetadataDefinitionDto>
              data={directoryMetadataDefinitions}
              renderItem={(item) => {
                const value = data?.[item.id]?.metaValue;
                return (
                  <GeneralSettingsItem
                    key={item.id}
                    title={
                      <>
                        <Typography.Text strong>{item.displayName}</Typography.Text>
                      </>
                    }
                    wrap
                    input={
                      <Form.Item initialValue={value} name={item.id}>
                        {renderProjectCardDefaultDataVariableFormItem(item.variable)}
                      </Form.Item>
                    }
                  />
                );
              }}
            />
          </GeneralSettingsContainer>
        </StackPanel>
      </Form>
    </Modal>
  );
};

export default ProjectTemplateDirectoryMetadataSettingModalFormModal;
