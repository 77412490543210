import { Alert, Form, Input, InputRef } from 'antd';
import { Margin } from 'components/Margin/Margin';
import { MAX_ITEM_NAME_LENGTH } from 'config/constants';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FC } from 'react';
import { maxLengthRule, requiredRule } from 'utils/formHelpers';
import styles from './DiscussionInputAttachmentNameForm.module.less';

export type DiscussionInputAttachmentNameFormData = {
  name: string;
};

type Props = { imageFile: File; disabled: boolean; setRef: (ref: InputRef) => void };

export const DiscussionInputAttachmentNameForm: FC<Props> = ({ imageFile, disabled, setRef }) => {
  const intl = useIntl();

  return (
    <>
      <img src={imageFile && URL.createObjectURL(imageFile)} className={styles.imagePreview} />
      {disabled ? (
        <Margin bottom>
          <Alert message={<Fmt id="DiscussionLinkAttachmentForm.disabled" />} type="error" />
        </Margin>
      ) : (
        <Form.Item
          label={intl.formatMessage({ id: 'DiscussionLinkAttachmentForm.name' })}
          name="name"
          rules={[
            requiredRule('DiscussionLinkAttachmentForm.name.required', true),
            maxLengthRule('general.maxNameLength', MAX_ITEM_NAME_LENGTH),
          ]}
        >
          <Input autoFocus ref={setRef} />
        </Form.Item>
      )}
    </>
  );
};
