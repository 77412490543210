import { DownloadOutlined, FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';
import { api } from 'api';
import { ProjectsInRealizationExportFormat } from 'api/completeApiInterfaces';
import { DATE_FORMAT_FOR_FILE_EXPORT, DROPDOWN_TRIGGER_CLICK } from 'config/constants';
import { useIntl, useIsMounted } from 'hooks';
import { Fmt } from 'locale';
import { isNumber } from 'lodash';
import moment, { Moment } from 'moment';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { messageError } from 'utils';
import { downloadBlob } from 'utils/downloadFile';
import { ReportViewConfiguration } from './ProjectsInRealizationOverviewReport.reportPresets';

type Props = {
  organizationId: Guid;
  esticonFirmId: Guid;
  reportDate: Moment;
  reportYear: number;
  currentViewConfiguration: ReportViewConfiguration;
  visibleProjectIds: Guid[];
};

const ProjectsInRealisationOverviewReportDownloadButton: FunctionComponent<Props> = ({
  organizationId,
  esticonFirmId,
  reportDate,
  reportYear,
  currentViewConfiguration,
  visibleProjectIds,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>();
  const isMounted = useIsMounted();

  const handleReportDownload = useCallback(
    async (format: ProjectsInRealizationExportFormat) => {
      setLoading(true);
      const [err, resp] = await api.master.organization.reports.getProjectsInRealizationPOHReport(
        organizationId,
        esticonFirmId,
        {
          utcFrom: reportDate.toISOString(),
          processedYear: reportYear,
          exportFormat: format,
          filteredRecords: visibleProjectIds,
          reportTypeName: currentViewConfiguration.name,
          columnConfigurations: currentViewConfiguration.columns.map((column) => ({
            sourceProperty: column.columnKey,
            isFolded: !!column.folded,
            isHidden: !column.visible,
            width: isNumber(column.width) ? column.width : null,
          })),
        }
      );
      if (!isMounted.current) return null;
      if (err) {
        setLoading(false);
        messageError(err, intl);
        return err;
      }

      const exportFileName = intl.formatMessage(
        { id: 'ProjectsInRealizationPohReport.export.fileName' },
        {
          date: moment(Date.now()).format(DATE_FORMAT_FOR_FILE_EXPORT),
          extension: format === ProjectsInRealizationExportFormat.excel ? 'xlsx' : 'pdf',
        }
      );

      if (!!resp.data) {
        const blob = new Blob([resp.data], { type: 'application/ms-excel' });
        downloadBlob(blob, exportFileName);
      }
      setLoading(false);
      return null;
    },
    [currentViewConfiguration, esticonFirmId, intl, organizationId, reportDate, reportYear, visibleProjectIds]
  );

  const downloadMenu: MenuProps = {
    items: [
      {
        key: 'downloadExcel',
        label: <Fmt id="ProjectsInRealizationPohReport.Export.xlsx" />,
        icon: <FileExcelOutlined />,
        onClick: () => handleReportDownload(ProjectsInRealizationExportFormat.excel),
      },
      {
        key: 'downloadPdf',
        label: <Fmt id="ProjectsInRealizationPohReport.Export.pdf" />,
        icon: <FilePdfOutlined />,
        onClick: () => handleReportDownload(ProjectsInRealizationExportFormat.pdf),
      },
    ],
  };

  return (
    <Dropdown menu={downloadMenu} trigger={DROPDOWN_TRIGGER_CLICK}>
      <Button icon={<DownloadOutlined />} loading={loading} type="primary" />
    </Dropdown>
  );
};

export default React.memo(ProjectsInRealisationOverviewReportDownloadButton);
