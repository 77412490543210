import { ProjectCardDocumentationLevelEnum } from 'api/completeApiInterfaces';
import { IntlMessageId } from 'locale/messages/cs';

export const PROJECT_DOCUMENTATION_LEVELS: ProjectCardDocumentationLevelEnum[] = [
  ProjectCardDocumentationLevelEnum.undefined,
  ProjectCardDocumentationLevelEnum.zp,
  ProjectCardDocumentationLevelEnum.dur,
  ProjectCardDocumentationLevelEnum.dusl,
  ProjectCardDocumentationLevelEnum.dusp,
  ProjectCardDocumentationLevelEnum.dsp,
  ProjectCardDocumentationLevelEnum.pdps,
  ProjectCardDocumentationLevelEnum.rds,
  ProjectCardDocumentationLevelEnum.dsps,
  ProjectCardDocumentationLevelEnum.finished,
];
export const PROJECT_DOCUMENTATION_LEVELS_TRANSLATIONS: Record<ProjectCardDocumentationLevelEnum, IntlMessageId> = {
  [ProjectCardDocumentationLevelEnum.undefined]: 'DocumentationLevelFilter.undefined',
  [ProjectCardDocumentationLevelEnum.zp]: 'DocumentationLevelFilter.zp',
  [ProjectCardDocumentationLevelEnum.dur]: 'DocumentationLevelFilter.dur',
  [ProjectCardDocumentationLevelEnum.dusl]: 'DocumentationLevelFilter.dusl',
  [ProjectCardDocumentationLevelEnum.dusp]: 'DocumentationLevelFilter.dusp',
  [ProjectCardDocumentationLevelEnum.dsp]: 'DocumentationLevelFilter.dsp',
  [ProjectCardDocumentationLevelEnum.pdps]: 'DocumentationLevelFilter.pdps',
  [ProjectCardDocumentationLevelEnum.rds]: 'DocumentationLevelFilter.rds',
  [ProjectCardDocumentationLevelEnum.dsps]: 'DocumentationLevelFilter.dsps',
  [ProjectCardDocumentationLevelEnum.finished]: 'DocumentationLevelFilter.finished',
};