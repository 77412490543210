import { CheckOutlined, LoadingOutlined, RocketOutlined } from '@ant-design/icons';

import { Alert, Button, Typography } from 'antd';
import { api, createCancelToken } from 'api';
import { BlobDerivateDto, BlobDerivateStatusEnum, BlobDerivateTypeEnum } from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import { OnDemandDerivateData } from 'api/project/blob/blobApi';
import classNames from 'classnames';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import SpinBox from 'components/SpinBox';
import Forge, { ForgeDisplayMode, ForgeSelectHoverProps, NamedForgeModel } from 'Forge/Forge';
import { ForgeCompareDiff } from 'Forge/ForgeCompareDiff';
import { ForgeLoader } from 'Forge/ForgeLoader';
import { useApiData, useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import { compact, uniq } from 'lodash';
import moment from 'moment';
import React, { FunctionComponent, MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { processPromises } from 'utils';
import styles from './ForgeGroupedViewer.module.less';

type BubbleNode = Autodesk.Viewing.BubbleNode;

const MAX_PROCESSED_MODELS = 2;

const CHECK_PROCESSING_DOCUMENTS_TIMEOUT = 10000;

type OnDemandResult = {
  documentId: Guid;
  documentName: string;
  error?: ApiError;
  blobToken?: string;
  status?: BlobDerivateStatusEnum;
};

export enum ForgeCompareMode {
  None,
  DiffToolExt,
}

export type ForgeDerivateData = {
  urn?: string;
  derivatives?: BubbleNode[];
};

function getTokenExpiration(expires: Date): number {
  if (!expires) return 0;
  const duration = moment.duration(moment().diff(expires));
  return duration.asSeconds();
}

function isTokenExpired(expires: Date) {
  return getTokenExpiration(expires) <= 0;
}

export type ForgeProcessableDocument = { id: Guid; name?: string; ext?: string; primaryFile: { blobToken?: string } };

export type ForgeViewableModel = {
  documentId?: Guid;
  documentName?: string;
  documentExtension?: string;
  blobToken: string;
  forgeDerivate?: BlobDerivateDto & { data?: ForgeDerivateData | OnDemandDerivateData };
};

type Props = ForgeSelectHoverProps & {
  className?: string;
  style?: React.CSSProperties;
  models: ForgeViewableModel[];
  hiddenModels?: Set<Guid>;
  refresh?: () => void;
  compareMode?: ForgeCompareMode;
  viewerRef?: MutableRefObject<Autodesk.Viewing.Viewer3D>;
  onGeometryLoaded?: () => void;
};

export const ForgeGroupedViewer: FunctionComponent<Props> = ({
  className,
  style,
  models,
  refresh,
  hiddenModels,
  compareMode = ForgeCompareMode.None,
  ...forgeProps
}) => {
  const [processingLoading, setProcessingLoading] = useState<boolean>(false);
  const [processingErrors, setProcessingErrors] = useState<OnDemandResult[]>([]);
  const [processingResults, setProcessingResults] = useState<OnDemandResult[]>([]);

  const [token, tokenError, tokenLoading, loadToken] = useApiData(api.project.forge.getToken);

  const [mode, setMode] = useState(ForgeDisplayMode.Mode3d);
  const [enableModeAutodetect, setEnableModeAutodetect] = useState(true);

  const [bubbleIndex, setBubbleIndex] = useState(0);

  const processModels = useSameCallback(async () => {
    if (processingLoading) {
      return;
    }

    setProcessingErrors([]);
    setProcessingResults([]);
    setProcessingLoading(true);

    const modelsOnDemand = models.filter((model) => model?.forgeDerivate?.status === BlobDerivateStatusEnum.OnDemand);

    const work = modelsOnDemand.map((model) => () =>
      api.project.blob.processBlobDerivate(model.blobToken, BlobDerivateTypeEnum.Forge).then(([err, resp]) => {
        if (err) {
          const onDemandError: OnDemandResult = {
            documentId: model.documentId,
            documentName: model.documentName,
            error: err,
          };
          return onDemandError;
        }
        const onDemandResponse: OnDemandResult = {
          documentId: model.documentId,
          documentName: model.documentName,
          blobToken: model.blobToken,
          status: resp.data.derivates.Forge.status,
        };
        return onDemandResponse;
      })
    );

    const results = await processPromises(work, MAX_PROCESSED_MODELS);
    setProcessingErrors(results.filter((result) => !!result.error));
    setProcessingResults(results.filter((result) => !result.error));
  });

  const handleGetForgeToken = useCallback(
    () =>
      ({
        access_token: token.accessToken,
        expires_in: getTokenExpiration(token.expires),
        token_type: 'Bearer',
      } as const),
    [token]
  );

  // TODO: what a mess ... rework when derivates gate is completed
  // TODO: or better yet ... call the API method only when Forge requests it ...
  useEffect(() => {
    if (
      (!token || isTokenExpired(token.expires)) &&
      models.every((model) => model?.forgeDerivate?.status === BlobDerivateStatusEnum.Ok)
    ) {
      loadToken();
    }
  }, [models]);

  const forgeModels = useMemo(
    () =>
      models
        .filter((model) => model?.forgeDerivate?.status === BlobDerivateStatusEnum.Ok)
        .map(
          (model): NamedForgeModel => ({
            ...model,
            urn: (model.forgeDerivate.data as ForgeDerivateData).urn,
          })
        ),
    [models]
  );
  const cancelToken = createCancelToken();

  const documentCheckPromises = useMemo(() => {
    return processingResults
      .filter((result) => result.status !== BlobDerivateStatusEnum.Error && result.status !== BlobDerivateStatusEnum.Ok)
      .map((result) => () =>
        api.project.blob
          .getOnDemandBlobDerivateProcessState(result.blobToken, BlobDerivateTypeEnum.Forge, cancelToken.token)
          .then(([err, resp]) => {
            if (err) {
              const onDemandError: OnDemandResult = {
                documentId: result.documentId,
                documentName: result.documentName,
                error: err,
              };
              return onDemandError;
            }
            const onDemandResponse: OnDemandResult = {
              documentId: result.documentId,
              documentName: result.documentName,
              blobToken: result.blobToken,
              status: resp.data.derivates.Forge.status,
            };
            return onDemandResponse;
          })
      );
  }, [cancelToken.token, processingResults]);

  useEffect(() => {
    if (!processingResults.length) return undefined;
    if (!processingResults.some((result) => result.status === BlobDerivateStatusEnum.Processing)) {
      setProcessingLoading(false);
      !processingErrors.length && refresh && refresh();
      return undefined;
    }
    let checkTimer: NodeJS.Timeout;
    const checkProcessingDocuments = async () => {
      const results = await processPromises(documentCheckPromises, MAX_PROCESSED_MODELS);
      const resultErrors = results.filter((result) => !!result.error);
      const resultOkStatuses = results.filter((result) => result.status === BlobDerivateStatusEnum.Ok);
      if (resultErrors.length || resultOkStatuses.length) {
        setProcessingErrors((prevState) => uniq([...prevState, ...resultErrors]));
        setProcessingResults((prevState) => {
          const modifiedState = prevState.map((processingItem) =>
            resultErrors.some((err) => err.documentId === processingItem.documentId)
              ? null
              : resultOkStatuses.some((okItem) => okItem.documentId === processingItem.documentId)
              ? { ...processingItem, status: BlobDerivateStatusEnum.Ok }
              : processingItem
          );
          return compact(modifiedState);
        });
      }
      checkTimer = setTimeout(checkProcessingDocuments, CHECK_PROCESSING_DOCUMENTS_TIMEOUT);
    };
    checkTimer = setTimeout(checkProcessingDocuments, CHECK_PROCESSING_DOCUMENTS_TIMEOUT);

    return () => {
      clearInterval(checkTimer);
      cancelToken.cancel('Lock for reserved document is no longer required');
    };
  }, [processingResults, processingErrors, cancelToken, documentCheckPromises]);

  const allModelsReady = forgeModels.length === models.length;

  const unviewableModels = useMemo(
    () => models.filter((model) => !model.forgeDerivate || model.forgeDerivate.status === BlobDerivateStatusEnum.Error),
    [models]
  );

  const pickForgeComponent = () => {
    switch (compareMode) {
      case ForgeCompareMode.None:
        return (
          <Forge
            key={`${mode}-${bubbleIndex}`} // big brain ... TODO: rework the Forge component so that it can properly handle changing modes
            getForgeToken={handleGetForgeToken}
            models={forgeModels}
            hiddenModels={hiddenModels}
            mode={mode}
            setMode={setMode}
            enableModeAutodetect={enableModeAutodetect}
            setEnableModeAutodetect={setEnableModeAutodetect}
            bubbleIndex={bubbleIndex}
            setBubbleIndex={setBubbleIndex}
            {...forgeProps}
          />
        );
      case ForgeCompareMode.DiffToolExt:
        return (
          forgeModels.length >= 2 && <ForgeCompareDiff getForgeToken={handleGetForgeToken} namedModels={forgeModels} />
        );
    }
  };

  if (token && allModelsReady) {
    return (
      <ErrorBoundary>
        <div style={style} className={classNames(styles.viewer, className)}>
          <ForgeLoader>{pickForgeComponent()}</ForgeLoader>
        </div>
      </ErrorBoundary>
    );
  }

  if (!!unviewableModels?.length) {
    const multipleModels = models?.length >= 2;
    const confirmedError = unviewableModels.some(
      (model) => model.forgeDerivate?.status === BlobDerivateStatusEnum.Error
    );
    const description = (
      <>
        <div>
          <Fmt
            id={
              multipleModels
                ? 'ModelDetailPage.viewByForgeError.descriptionMultiple'
                : 'ModelDetailPage.viewByForgeError.description'
            }
          />
        </div>
        {multipleModels &&
          unviewableModels.map((model) => (
            <div key={model.documentId}>
              <Typography.Text strong>{model.documentName}</Typography.Text>
            </div>
          ))}
        {!confirmedError && (
          <div>
            <Fmt id="ModelDetailPage.viewByForgeError.pleaseWait" />
          </div>
        )}
      </>
    );
    return (
      <Alert
        message={<Fmt id="ModelDetailPage.viewByForgeError.message" />}
        description={description}
        type="warning"
        showIcon
        banner
      />
    );
  }

  return (
    <div className={classNames(styles.viewer, className)}>
      {tokenError ? (
        <Alert type="error" message={<Fmt id="ForgeViewer.loadingTokenError" />} />
      ) : tokenLoading ? (
        <SpinBox fill spinning={true}>
          <Fmt id="Forge.loading" />
        </SpinBox>
      ) : (
        !allModelsReady && (
          <div className={styles.beforeViewContent}>
            <Button
              type="primary"
              size="large"
              icon={<RocketOutlined />}
              onClick={processModels}
              loading={processingLoading}
            >
              <Fmt id="ForgeViewer.processModel" />
            </Button>
            <div className={styles.infoBox}>
              <Alert message={<Fmt id="ForgeViewer.processModelInfo" />} type="info" showIcon />
            </div>

            {!!processingErrors.length && (
              <div className={styles.infoBox}>
                <Alert
                  message={
                    <>
                      <Fmt id="ForgeViewer.processError" />
                      {processingErrors.map((err) => (
                        <div key={err.documentId}>{`${err.documentName}`}</div>
                      ))}
                    </>
                  }
                  type="error"
                  showIcon
                />
              </div>
            )}
            {!!processingResults.length && (
              <div className={styles.infoBox}>
                <Alert
                  message={
                    <>
                      <Fmt id="ForgeViewer.processing" />
                      {processingResults.map((res) => (
                        <div key={res.documentId}>
                          {`${res.documentName}`}{' '}
                          {res.status === BlobDerivateStatusEnum.Processing ? <LoadingOutlined /> : <CheckOutlined />}
                        </div>
                      ))}
                    </>
                  }
                  type="info"
                  showIcon
                />
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};
