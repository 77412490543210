import { Form, Select, Space, TreeSelect } from 'antd';

import { DefaultOptionType } from 'antd/lib/select';
import { JSONVariableTypeEnum, OrganizationStructureDto } from 'api/completeApiInterfaces';
import { organizationStructureToTreeSelectData } from 'components/forms/OrganizationUserEditForm/OrganizationUserEditForm';
import { useIntl, useStoreSelector } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { organizationStructureOrderedListSelector } from 'store/selectors/organizationStrucureSelectors';
import { JSONVariableOrganizationStructureWithUser } from '../JSONVariableTypes';

export type JSONVariableOrganizationStructureWithUserProps = {
  dataType: JSONVariableTypeEnum.orgStructureWithUser;
  value?: JSONVariableOrganizationStructureWithUser;
  onChange?: (value: JSONVariableOrganizationStructureWithUser) => void;
  disabled?: boolean;
};

export const getStructureById = (
  orgStructureId: Guid,
  orgStructures: OrganizationStructureDto[]
): OrganizationStructureDto | undefined =>
  orgStructures.reduce(
    (foundStructure, structure) =>
      foundStructure ||
      (structure.id === orgStructureId ? structure : getStructureById(orgStructureId, structure.children || [])),
    undefined
  );

const JSONVariableOrganizationStructureWithUserFormItemFormItem: FunctionComponent<JSONVariableOrganizationStructureWithUserProps> = ({
  value,
  onChange,
  disabled,
  dataType,
}) => {
  const intl = useIntl();
  const organizationStructure = useStoreSelector(organizationStructureOrderedListSelector);

  const treeSelectData = useMemo(() => {
    return organizationStructureToTreeSelectData(organizationStructure);
  }, [organizationStructure]);

  const usersOptions = useMemo((): DefaultOptionType[] => {
    if (!value?.valueStructureId) return [];
    const users = getStructureById(value.valueStructureId, organizationStructure)?.organizationUsers || [];
    return users.map((user) => ({ label: user.appUserProfile.username, value: user.id }));
  }, [value?.valueStructureId, organizationStructure]);

  const handleOrgStructureIdCHange = useCallback(
    (formValue: Guid) => {
      onChange && onChange({ type: dataType, valueStructureId: formValue, valueUserId: undefined });
    },
    [onChange, dataType]
  );

  const handleUserIdChange = useCallback(
    (formValue: Guid) => {
      onChange && onChange({ type: dataType, valueStructureId: value.valueStructureId, valueUserId: formValue });
    },
    [onChange, dataType, value]
  );

  return (
    <Space>
      <Form.Item
        label={<Fmt id="general.organizationStructure" />}
        rules={[{ required: true, message: intl.formatMessage({ id: 'forms.items.rules.required' }) }]}
      >
        <TreeSelect
          style={{ minWidth: '200px' }}
          showSearch
          allowClear
          treeData={treeSelectData}
          disabled={disabled}
          value={value?.valueStructureId}
          onChange={handleOrgStructureIdCHange}
          treeDefaultExpandAll
        />
      </Form.Item>
      <Form.Item label={<Fmt id="general.userName" />}>
        <Select
          style={{ minWidth: '200px' }}
          options={usersOptions}
          disabled={disabled}
          value={value?.valueUserId}
          onChange={handleUserIdChange}
        />
      </Form.Item>
    </Space>
  );
};

export default React.memo(JSONVariableOrganizationStructureWithUserFormItemFormItem);
