export enum LanguageEnum {
  cs = 'cs',
  en = 'en',
  de = 'de',
}

export interface LocaleMessagesObject {
  [key: string]: string | LocaleMessagesObject;
}

export interface LocaleFlatMessagesObject {
  [key: string]: string;
}

export type LocaleMap<T> = { [key in LanguageEnum]: T };
