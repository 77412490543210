import { Typography } from 'antd';
import { DatePickerWithHolidays } from 'components/CalendarWithHolidays/DatePickerWithHolidays';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { isDateInThePast } from 'components/forms/AssignmentForm/AssignmentForm';
import { useIntl } from 'hooks';
import { useAssignmentDeadlineFormatter } from 'pages/MessageCenterPage/assignments/useAssignmentDeadlineFormatter';
import React, { FunctionComponent } from 'react';
import { AssignmentData, AssignmentDataAction } from '../AssignmentDetail';

type Props = {
  canEdit: boolean;
  assignment: AssignmentData;
  dispatchAssignment: React.Dispatch<AssignmentDataAction>;
  isAfterDeadline?: boolean;
};

export const AssignmentDeadlineField: FunctionComponent<Props> = ({
  canEdit,
  assignment,
  dispatchAssignment,
  isAfterDeadline,
}) => {
  const intl = useIntl();
  const { asMoment, asFormatedDate, dateFormat } = useAssignmentDeadlineFormatter(assignment);

  const content = !canEdit ? (
    <Typography.Text strong={isAfterDeadline} type={isAfterDeadline ? 'danger' : undefined}>
      {asFormatedDate}
    </Typography.Text>
  ) : (
    <DatePickerWithHolidays
      showToday
      format={dateFormat}
      mode="date"
      value={asMoment}
      onChange={(value) =>
        dispatchAssignment({ type: 'setDeadlineDate', deadlineDate: value?.endOf('day').toISOString() })
      }
      disabledDate={isDateInThePast}
      danger={isAfterDeadline}
    />
  );

  return isAfterDeadline ? (
    <CommonHubTooltip
      title={intl.formatMessage({ id: 'MessageCenterPage.message.category.assignmentDeadlineExpired.filter' })}
    >
      {content}
    </CommonHubTooltip>
  ) : (
    content
  );
};
