import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Space } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { OrgExtendedPermissionValueEnum } from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import DashboardTile from 'components/DashboardTile/DashboardTile';
import EmptySimple from 'components/EmptySimple';
import { AddIcon } from 'components/Icons/HubActionsIcons';
import { useReportWidgetsContext } from 'components/Reports/contexts/ReportWidgetsContextProvider';
import { useCurrentAppUser, useIntl } from 'hooks';
import { Fmt } from 'locale';
import { reportCategoriesMap } from 'pages/ReportsListPage/ReportsList';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import uuid from 'uuid';

type Props = {
  classNames?: string;
};

const DashboardReportTile: FunctionComponent<Props> = ({ classNames, children }) => {
  const {
    orderedWidgets,
    dashboardWidgetsError,
    dashboardWidgetsLoading,
    showReportAddModal,
    showSharedReportAddModal,
    organizations,
    saveDashboardWidget,
  } = useReportWidgetsContext();
  const intl = useIntl();
  const currentAppUser = useCurrentAppUser();

  const showSelectedReports = useMemo(() => {
    const supportedReports = Object.values(reportCategoriesMap).flatMap((mapVal) => mapVal);
    const permissionArrays = currentAppUser.organizationUsers?.flatMap((user) => user.extendedPermissions) || [];
    return permissionArrays.some(
      (perm) =>
        supportedReports.includes(perm.permissionType) && perm.permission !== OrgExtendedPermissionValueEnum.none
    );
  }, [currentAppUser]);

  const handleProjectDocumentationStatusReportAdd = useCallback(async () => {
    await saveDashboardWidget({
      id: uuid(),
      order: 0,
      type: 'projectDocumentationStatistics',
      title: intl.formatMessage({ id: 'ProjectDashboard.Reports.projectDocumentationStatistics.title' }),
    });
  }, [intl, saveDashboardWidget]);

  const handleNotificationOverviewAdd = useCallback(async () => {
    await saveDashboardWidget({
      id: uuid(),
      order: 0,
      type: 'notificationStatistics',
      title: intl.formatMessage({ id: 'ProjectDashboard.Reports.userNotificationStatistics.title' }),
    });
  }, [intl, saveDashboardWidget]);

  const handleAssignmentOverviewAdd = useCallback(async () => {
    await saveDashboardWidget({
      id: uuid(),
      order: 0,
      type: 'assignmentStatistics',
      title: intl.formatMessage({ id: 'ProjectDashboard.Reports.userActiveAssignmentStatistics.title' }),
    });
  }, [intl, saveDashboardWidget]);

  const reportMenu: MenuProps = useMemo(() => {
    const items: ItemType[] = [
      !!showSelectedReports && {
        key: 'addReport',
        label: intl.formatMessage({ id: 'ProjectsListPage.addNewReport' }),
        icon: <AddIcon />,
        disabled: !organizations?.length,
        onClick: () => showReportAddModal(),
      },
      !!showSelectedReports && {
        key: 'addSharedReport',
        label: intl.formatMessage({ id: 'ProjectsListPage.addNewSharedReport' }),
        icon: <AddIcon />,
        disabled: !organizations?.length,
        onClick: showSharedReportAddModal,
      },
      !orderedWidgets.some((widget) => widget.type === 'projectDocumentationStatistics') && {
        key: 'addProjectDocumentationOverviewReport',
        label: intl.formatMessage({ id: 'ProjectsListPage.addDocumentationLevelOverview' }),
        icon: <AddIcon />,
        disabled: !organizations?.length,
        onClick: () => handleProjectDocumentationStatusReportAdd(),
      },
      !orderedWidgets.some((widget) => widget.type === 'notificationStatistics') && {
        key: 'addNotificationOverview',
        label: intl.formatMessage({ id: 'ProjectsListPage.addNotificationOverview' }),
        icon: <AddIcon />,
        disabled: !organizations?.length,
        onClick: () => handleNotificationOverviewAdd(),
      },

      !orderedWidgets.some((widget) => widget.type === 'assignmentStatistics') && {
        key: 'addAssignmentsOverview',
        label: intl.formatMessage({ id: 'ProjectsListPage.addAssignmentsOverview' }),
        icon: <AddIcon />,
        disabled: !organizations?.length,
        onClick: () => handleAssignmentOverviewAdd(),
      },
    ].filter(Boolean);
    return { items };
  }, [intl, organizations?.length, orderedWidgets, showReportAddModal, showSharedReportAddModal]);

  return (
    <DashboardTile title={<Fmt id="ProjectsListPage.reports" />} menu={reportMenu} containerClass={classNames}>
      <ContentGate
        error={dashboardWidgetsError}
        loading={dashboardWidgetsLoading}
        empty={!orderedWidgets?.length}
        emptyAlternative={
          <EmptySimple description={<Fmt id="MainDashboard.noSelectedReports" />}>
            <Dropdown menu={reportMenu}>
              <Button type="primary">
                <Space>
                  <Fmt id="MainDashboard.addFirstReportButton" />
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </EmptySimple>
        }
      >
        {!!orderedWidgets && children}
      </ContentGate>
    </DashboardTile>
  );
};

export default DashboardReportTile;
