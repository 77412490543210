import {
  JSONVariableTypeEnum,
  ProjectMetadataDefinitionCreateDto,
  ProjectMetadataDefinitionDto,
  ProjectMetadataDefinitionMetadataEntityType,
  ProjectMetadataDefinitionPatchDto,
  ServiceError,
} from 'api/completeApiInterfaces';
import { EditButton } from 'components/ActionButtons';
import { DeleteButtonConfirm } from 'components/ActionButtons/DeleteButtonConfirm';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import ServiceErrorBox from 'components/ServiceErrorBox';
import SpinBox from 'components/SpinBox';
import { useBoolean, useIntl } from 'hooks';
import { Fmt } from 'locale';
import { getMetadataVariableTypeText } from 'pages/OrganizationsSettingPage/ProjectTemplate/Tabs/General/MetadataUtils';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { injectIntl } from 'react-intl';
import { smartFilter } from 'utils';
import Panel from '../Panel';
import { MetadataFormModal } from './MetadataFormModal';

type Props = {
  entityType: ProjectMetadataDefinitionMetadataEntityType;
  metadataDefinitions: ProjectMetadataDefinitionDto[];
  metadataLoading: boolean;
  metadataError: ServiceError;
  onAdd: (data: ProjectMetadataDefinitionCreateDto) => Promise<void>;
  onDelete: (definitionId: Guid) => Promise<void>;
  onPatch: (definitionId: Guid, data: ProjectMetadataDefinitionPatchDto) => Promise<void>;
};

const MetadataList: FunctionComponent<Props> = ({
  entityType,
  metadataDefinitions,
  metadataLoading,
  metadataError,
  onAdd,
  onDelete,
  onPatch,
}) => {
  const intl = useIntl();
  const [search, setSearch] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<ProjectMetadataDefinitionDto>();
  const [definitionModalVisible, showDefinitionModal, hideDefinitionModal] = useBoolean(false);

  const getVariableText = useCallback(
    (value: JSONVariableTypeEnum) => {
      return getMetadataVariableTypeText(value, intl);
    },
    [intl]
  );

  const renderContent = () => {
    if (metadataError) return <ServiceErrorBox error={metadataError} />;
    if (metadataLoading) return <SpinBox />;
    if (metadataDefinitions === null) return null;

    return (
      <GeneralSettingsContainer>
        <List<ProjectMetadataDefinitionDto>
          data={metadataDefinitions}
          search={search}
          filterItem={(item) => {
            return smartFilter(item.name, search);
          }}
          renderItem={(item) => (
            <GeneralSettingsItem
              key={item.id}
              title={<span>{item.name}</span>}
              additionalActions={
                <>
                  {onPatch && <EditButton onClick={() => setSelectedItem(item)} />}
                  {onDelete && <DeleteButtonConfirm onConfirm={() => onDelete(item.id)} />}
                </>
              }
              description={getVariableText(item.variable)}
            />
          )}
          renderEmpty={(total, filtered) => (
            <ListEmpty filtered={filtered} total={total} onClearSearch={() => setSearch('')} />
          )}
        />
      </GeneralSettingsContainer>
    );
  };

  const closeModal = useCallback(() => {
    setSelectedItem(undefined);
    hideDefinitionModal();
  }, [hideDefinitionModal]);

  const handleSubmit = useCallback(
    (value: ProjectMetadataDefinitionCreateDto) => {
      !!selectedItem && onPatch(selectedItem.id, value);
      !selectedItem && onAdd({ name: value.name, type: entityType, variable: value.variable as JSONVariableTypeEnum });
      closeModal();
    },
    [closeModal, entityType, onAdd, onPatch, selectedItem]
  );

  return (
    <>
      <Panel
        noMargin
        onSearch={setSearch}
        searchValue={search}
        addButtonOnClick={showDefinitionModal}
        addButtonText={<Fmt id="Metadata.addButton.title" />}
      >
        {renderContent()}
      </Panel>
      <MetadataFormModal
        open={!!selectedItem || definitionModalVisible}
        onSubmit={handleSubmit}
        onClose={closeModal}
        selectedMetadataItem={selectedItem}
        metadataDefinitions={metadataDefinitions}
      />
    </>
  );
};

export default injectIntl(MetadataList);
