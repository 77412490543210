import { Col, Form, Input, InputNumber, Row, TimePicker } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useWatch } from 'antd/lib/form/Form';
import Select, { DefaultOptionType } from 'antd/lib/select';
import { apiConstraints } from 'api/completeApiConstraints';
import {
  MdCkIdentificationEnum,
  MdMeetingApprovalBackgroundListDto,
  MdMeetingBackgroundMdMeetingStatusEnum,
} from 'api/completeApiInterfaces';
import { DatePickerWithHolidays } from 'components/CalendarWithHolidays/DatePickerWithHolidays';
import { DateRangeValue } from 'components/InlineDateRangePicker/InlineDateRangePicker';
import { MdProjectListWithUnpackedData } from 'components/JSONVariableFormItems/dataUnpackers/mdProjectUnpacker.utils';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import moment, { Moment } from 'moment';
import React, { useEffect, useMemo } from 'react';
import { duplicateNameRule, maxLengthRule, requiredRule } from 'utils/formHelpers';
import { MdProjectDataStatementEnum } from '../MdFormItems/MdCkStatementFormItem';
import { MAP_CK_IDENTIFICATIONS_ENUM_TO_INTL_MESSAGE_ID } from '../MDProjectCardCreateForm/MDProjectCreateForm';
import styles from './MDProjectApprovalForm.module.less';
import { MdMeetingAprovalDefault } from './MDProjectApprovalFormModal';
import { MdProjectListSelectFormItem } from './MdProjectListSelectFormItem/MdProjectListSelectFormItem';

export type MDProjectApprovalFormData = {
  meetingNumber: number;
  ckIdentification: MdCkIdentificationEnum;
  note: string;
  referenceCode: string;
  approvalDateTime: Moment;
  approvalTimeRange: DateRangeValue;
  projectIds: Guid[];
};

type Props = {
  projectsList: MdProjectListWithUnpackedData[];
  defaultMeetingBackground: MdMeetingAprovalDefault;
  allowedCkIdentifications: MdCkIdentificationEnum[];
  existingMeetings: MdMeetingApprovalBackgroundListDto;
  disabled: boolean;
};

const TIME_RANGE_FORMAT = 'HH:mm';

const MDProjectApprovalForm: React.FC<Props> = ({
  projectsList,
  defaultMeetingBackground,
  allowedCkIdentifications,
  existingMeetings,
  disabled,
}) => {
  const intl = useIntl();
  const form = useFormInstance();
  const selectedIdentification = useWatch<MDProjectApprovalFormData['ckIdentification']>('ckIdentification');

  const reservedMeetingNumbers = useMemo(
    () =>
      existingMeetings?.mdMeetingApprovalBackgrounds
        .filter(
          (meeting) =>
            meeting.id !== defaultMeetingBackground?.id && meeting.ckIdentification === selectedIdentification
        )
        .map((meeting) => meeting.meetingNumber.toString()) || [],
    [existingMeetings, defaultMeetingBackground, selectedIdentification]
  );

  const projectListForIdentifications = useMemo(() => {
    return projectsList.filter(
      (project) =>
        (project.ckIdentificaion === selectedIdentification &&
          (!project.ckStatement?.value ||
            project.ckStatement?.value === MdProjectDataStatementEnum.returnedForRework ||
            project.ckStatement?.value === MdProjectDataStatementEnum.interrupted)) ||
        (defaultMeetingBackground?.status !== MdMeetingBackgroundMdMeetingStatusEnum.inProcess &&
          defaultMeetingBackground?.mdProjectIds.some((projectId) => project.id === projectId))
    );
  }, [projectsList, selectedIdentification]);

  const disabledProjectIds = useMemo(() => {
    const activeMeetings =
      existingMeetings?.mdMeetingApprovalBackgrounds?.filter(
        (meeting) => meeting.status === MdMeetingBackgroundMdMeetingStatusEnum.inProcess
      ) || [];
    return new Set<Guid>(
      activeMeetings
        .filter((meeting) => meeting.id !== defaultMeetingBackground?.id)
        .flatMap((meeting) => meeting.mdProjectIds) || []
    );
  }, [existingMeetings, defaultMeetingBackground?.id]);

  const ckIdentificationsOptions: DefaultOptionType[] = useMemo(
    () =>
      Object.entries(MAP_CK_IDENTIFICATIONS_ENUM_TO_INTL_MESSAGE_ID)
        .filter(([enumValue]) => allowedCkIdentifications.some((identificaiton) => identificaiton === enumValue))
        .map(([enumValue, intlMessageId]) => ({
          label: intl.formatMessage({ id: intlMessageId }),
          value: enumValue,
        })) || [],
    [intl, allowedCkIdentifications]
  );

  useEffect(() => {
    if (!existingMeetings || defaultMeetingBackground?.meetingNumber) return;
    const numbers = existingMeetings.mdMeetingApprovalBackgrounds
      ?.filter((meeting) => meeting.ckIdentification === selectedIdentification)
      .map((meeting) => meeting.meetingNumber);
    if (!selectedIdentification) {
      form.setFieldValue('meetingNumber', undefined);
      return;
    }
    if (!numbers?.length) {
      form.setFieldValue('meetingNumber', 1);
      return;
    }
    form.setFieldValue('meetingNumber', Math.max(...numbers) + 1);
  }, [selectedIdentification, existingMeetings]);

  return (
    <>
      <Row gutter={8}>
        <Col md={24} lg={12}>
          <Form.Item
            label={<Fmt id="MD.ProjectsCreateApprovalModal.form.meetingNumber" />}
            name="meetingNumber"
            rules={[
              requiredRule('MD.ProjectsCreateApprovalModal.form.meetingNumber.required', false),
              duplicateNameRule('MD.ProjectsCreateApprovalModal.form.meetingNumber.unique', reservedMeetingNumbers),
            ]}
            initialValue={defaultMeetingBackground?.meetingNumber}
          >
            <InputNumber disabled={disabled} min={1} />
          </Form.Item>
        </Col>
        <Col md={24} lg={12}>
          <Form.Item
            label={<Fmt id="MD.Projects.ProjectCreateModal.form.ckIdentification" />}
            name="ckIdentification"
            rules={[requiredRule('MD.Projects.ProjectCreateModal.form.ckIdentification.required', false)]}
            initialValue={defaultMeetingBackground?.ckIdentification}
          >
            <Select options={ckIdentificationsOptions} className={styles.identificationSelect} disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8}>
        <Col md={24} lg={12}>
          <Form.Item
            label={<Fmt id="MD.ProjectsCreateApprovalModal.form.date" />}
            name="approvalDateTime"
            rules={[requiredRule('MD.ProjectsCreateApprovalModal.form.date.required', false)]}
            initialValue={
              defaultMeetingBackground?.approvalDateTime && moment(defaultMeetingBackground?.approvalDateTime)
            }
          >
            <DatePickerWithHolidays disabled={disabled} />
          </Form.Item>
        </Col>
        <Col md={24} lg={12}>
          <Form.Item
            label={<Fmt id="MD.ProjectsCreateApprovalModal.form.timeRange" />}
            name="approvalTimeRange"
            rules={[requiredRule('MD.ProjectsCreateApprovalModal.form.timeRange.required', false)]}
            initialValue={
              defaultMeetingBackground?.startTime &&
              defaultMeetingBackground?.endTime && [
                moment(defaultMeetingBackground.startTime, 'HH:mm'),
                moment(defaultMeetingBackground.endTime, 'HH:mm'),
              ]
            }
          >
            <TimePicker.RangePicker
              format={TIME_RANGE_FORMAT}
              placeholder={['09:00', '11:30']}
              allowClear={false}
              disabled={disabled}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8}>
        <Col md={24} lg={12}>
          <Form.Item
            label={<Fmt id="MD.ProjectsCreateApprovalModal.form.referenceCode" />}
            name="referenceCode"
            rules={[
              requiredRule('MD.ProjectsCreateApprovalModal.form.referenceCode.required', false),
              maxLengthRule(
                'MD.ProjectsCreateApprovalModal.form.referenceCode.maxLength',
                apiConstraints.mdMeetingApprovalBackgroundRequestDto.referenceCode.maxLength
              ),
            ]}
            initialValue={defaultMeetingBackground?.referenceCode}
          >
            <Input disabled={disabled} />
          </Form.Item>
        </Col>
        <Col md={24} lg={12}>
          <Form.Item
            label={<Fmt id="MD.ProjectsCreateApprovalModal.form.note" />}
            name="note"
            rules={[
              maxLengthRule(
                'MD.ProjectsCreateApprovalModal.form.note.maxLength',
                apiConstraints.mdMeetingApprovalBackgroundRequestDto.note.maxLength
              ),
            ]}
            initialValue={defaultMeetingBackground?.note}
          >
            <Input disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label={<Fmt id="MD.ProjectsCreateApprovalModal.from.projectSelection" />}
        name="projectIds"
        rules={[!disabled && requiredRule('MD.ProjectsCreateApprovalModal.from.projectSelection.required', false)]}
        labelCol={{ span: 24 }}
        className={styles.projectSelectionLabel}
        initialValue={defaultMeetingBackground?.mdProjectIds}
      >
        <MdProjectListSelectFormItem
          projectsList={projectListForIdentifications}
          disabled={disabled}
          disabledProjectIds={disabledProjectIds}
        />
      </Form.Item>
    </>
  );
};

export default MDProjectApprovalForm;
