import { JSONVariableTypeEnum, OrgProjectCardDataDto } from 'api/completeApiInterfaces';
import { v4 as uuid } from 'uuid';

export type ProjectCardDataTreeColumn = {
  name: string;
  children?: ProjectCardDataTreeColumn[];
  unit?: string;
  diffValue?: boolean;
  type?: JSONVariableTypeEnum;
};

export const SZ_CAPACITY_DATA_TABLE_MOCK: ProjectCardDataTreeColumn[] = [
  {
    name: 'Stavba',
  },
  {
    name: 'Označení (Skód)',
  },
  {
    name: 'Stádium',
  },
  {
    name: 'Investor',
  },
  {
    name: 'Náklady stavby celkem',
    children: [
      {
        name: 'náklady na přípravu a celkové zabezpečení výstavby celkem',
        unit: 'mil. Kč',
      },
      {
        name: 'náklady na realizaci stavby celkem bez rezervy',
        unit: 'mil. Kč',
      },
      {
        name: 'rezerva',
        unit: 'mil. Kč',
      },
    ],
  },
  {
    name: 'Ukazatele stavby',
    children: [
      {
        name: 'délka stavebně upraveného úseku tratě',
        unit: 'km',
      },
      {
        name: 'délka technologicky upraveného úseku tratě',
        unit: 'km',
      },
      {
        name: 'počet železničních stanic',
        unit: 'ks',
        diffValue: true,
      },
      {
        name: 'počet zastávek',
        unit: 'ks',
        diffValue: true,
      },
      {
        name: 'nejvyšší traťová rychlost (V)',
        unit: 'km/h',
        diffValue: true,
      },
      {
        name: 'nejvyšší traťová rychlost (V130)',
        unit: 'km/h',
        diffValue: true,
      },
      {
        name: 'nejvyšší traťová rychlost (V150)',
        unit: 'km/h',
        diffValue: true,
      },
      {
        name: 'nejvyšší traťová rychlost (Vk)',
        unit: 'km/h',
        diffValue: true,
      },
      {
        name: 'staniční koleje délky nad 780m',
        unit: 'ks',
        diffValue: true,
      },
      {
        name: 'trakční napájecí soustava',
        diffValue: true,
        type: JSONVariableTypeEnum.stringDiff,
      },
    ],
  },
  {
    name: 'Stavebně technické a technologické parametry stavby',
    children: [
      {
        name: 'Železniční svršek',
        children: [
          {
            name: 'délka úpravy koleje - štěrkové lože',
            unit: 'm',
          },
          {
            name: 'délka úpravy koleje - pevná jízdní dráha',
            unit: 'm',
          },
          {
            name: 'počet výhybek ve stavbě celkem',
            unit: 'ks',
            diffValue: true,
          },
          {
            name: 'počet nově dodaných výhybek ve stavbě',
            unit: 'ks',
          },
        ],
      },
      {
        name: 'Železniční spodek',
        children: [
          {
            name: 'sanace zemního tělesa železniční spodek',
            unit: 'm',
          },
          {
            name: 'sanace zemního tělesa železniční spodek',
            unit: 'm2',
          },
          {
            name: 'odvodnění zemního tělesa',
            unit: 'm',
          },
          {
            name: 'zemní práce výkopy',
            unit: 'm3',
          },
          {
            name: 'zemní práce násypy',
            unit: 'm3',
          },
        ],
      },
      {
        name: 'Nástupiště',
        children: [
          {
            name: 'délka nástupních hran',
            unit: 'm',
          },
          {
            name: 'počet nástupních hran',
            unit: 'ks',
            diffValue: true,
          },
        ],
      },
      {
        name: 'Mostní objekty',
        children: [
          {
            name: 'Železniční mosty',
            children: [
              {
                name: 'v obvodu stavby celkem (včetně podchodů)',
                unit: 'ks',
                diffValue: true,
              },
              {
                name: 'rekonstrukce (vyjma podchodů)',
                unit: 'ks',
              },
              {
                name: 'novostavba (vyjma podchodů)',
                unit: 'ks',
              },
              {
                name: 'podchody rekonstrukce',
                unit: 'ks',
              },
              {
                name: 'podchody novostavba',
                unit: 'ks',
              },
              {
                name: 'délka přemostění celkem',
                unit: 'm',
                diffValue: true,
              },
            ],
          },
          {
            name: 'Silniční mosty',
            children: [
              {
                name: 'rekonstrukce (včetně lávek pro pěší)',
                unit: 'ks',
              },
              {
                name: 'novostavba (včetně lávek pro pěší)',
                unit: 'ks',
              },
              {
                name: 'délka přemostění celkem',
                unit: 'm',
                diffValue: true,
              },
            ],
          },
          {
            name: 'Propustky',
            children: [
              {
                name: 'v obvodu stavby celkem',
                unit: 'ks',
                diffValue: true,
              },
              {
                name: 'rekonstrukce',
                unit: 'ks',
              },
              {
                name: 'novostavba',
                unit: 'ks',
              },
            ],
          },
          {
            name: 'Zdi',
            unit: 'm2',
          },
        ],
      },
      {
        name: 'Tunely',
        children: [
          {
            name: 'Jednokolejné tunely',
            children: [
              {
                name: 'v obvodu stavby celkem',
                unit: 'm',
                diffValue: true,
              },
              {
                name: 'v obvodu stavby celkem',
                unit: 'ks',
                diffValue: true,
              },
              {
                name: 'rekonstrukce',
                unit: 'm',
              },
              {
                name: 'rekonstrukce',
                unit: 'ks',
              },
              {
                name: 'novostavba',
                unit: 'm',
              },
              {
                name: 'novostavba',
                unit: 'ks',
              },
            ],
          },
          {
            name: 'Dvojkolejné tunely',
            children: [
              {
                name: 'v obvodu stavby celkem',
                unit: 'm',
                diffValue: true,
              },
              {
                name: 'v obvodu stavby celkem',
                unit: 'ks',
                diffValue: true,
              },
              {
                name: 'rekonstrukce',
                unit: 'm',
              },
              {
                name: 'rekonstrukce',
                unit: 'ks',
              },
              {
                name: 'novostavba',
                unit: 'm',
              },
              {
                name: 'novostavba',
                unit: 'ks',
              },
            ],
          },
        ],
      },
      {
        name: 'Pozemní komunikace',
        children: [
          {
            name: 'Pozemní komunikace dotčené stavbou',
            children: [
              {
                name: 'rekonstrukce',
                unit: 'm2',
              },
              {
                name: 'novostavba',
                unit: 'm2',
              },
            ],
          },
          {
            name: 'Ostatní plochy a vybavení',
            children: [
              {
                name: 'parkovací stání pro veřejnost dotčené stavbou celkem',
                unit: 'm2',
                diffValue: true,
              },
              {
                name: 'nová parkovací stání pro veřejnost',
                unit: 'ks',
              },
              {
                name: 'nové cyklo-parkovací stání pro veřejnost',
                unit: 'ks',
              },
            ],
          },
        ],
      },
      {
        name: 'Protihlukové objekty',
        children: [
          {
            name: 'délka (rozvinutá) protihlukových opatření',
            unit: 'm',
          },
          {
            name: 'délka úseku s novými protihlukovými opatřeními',
            unit: 'km tratě',
          },
          {
            name: 'plocha protihlukových zdí',
            unit: 'm2',
          },
          {
            name: 'individuální protihluková opatření',
            unit: 'ks',
          },
        ],
      },
      {
        name: 'Ostatní inženýrské objekty',
        children: [
          {
            name: 'drobné inženýrské objekty související se stavbou',
            unit: 'počet SO',
          },
        ],
      },
      {
        name: 'Pozemní objekty',
        children: [
          {
            name: 'Budovy osobní nádraží',
            children: [
              {
                name: 'celkový počet v rámci v obvodu stavby',
                unit: 'ks',
                diffValue: true,
              },
              {
                name: 'rekonstrukce objektů ve stavbě',
                unit: 'ks',
              },
              {
                name: 'stavební úprava objektů',
                unit: 'm2',
              },
              {
                name: 'stavební úprava objektů',
                unit: 'm3',
              },
              {
                name: 'novostavba objektů ve stavbě',
                unit: 'ks',
              },
              {
                name: 'novostavba objektů ve stavbě',
                unit: 'm2',
              },
              {
                name: 'novostavba objektů ve stavbě',
                unit: 'm3',
              },
            ],
          },
          {
            name: 'Budovy služební a technologické (provozní)',
            children: [
              {
                name: 'počet stavebně dotčených provozních budov SŽ',
                unit: 'ks',
              },
              {
                name: 'stavební úprava provozních budov SŽ',
                unit: 'm2',
              },
              {
                name: 'stavební úprava provozních budov SŽ',
                unit: 'm3',
              },
              {
                name: 'počet nových provozních budov SŽ',
                unit: 'ks',
              },
              {
                name: 'nové provozní budovy SŽ',
                unit: 'm2',
              },
              {
                name: 'nové provozní budovy SŽ',
                unit: 'm3',
              },
            ],
          },
          {
            name: 'Ostatní budovy',
            children: [
              {
                name: 'počet stavebně dotčených ostatních budov',
                unit: 'ks',
              },
              {
                name: 'stavební úprava ostatních budov',
                unit: 'm2',
              },
              {
                name: 'stavební úprava ostatních budov',
                unit: 'm3',
              },
              {
                name: 'počet nových ostatních budov',
                unit: 'ks',
              },
              {
                name: 'novostavby ostatních budov',
                unit: 'm2',
              },
              {
                name: 'novostavby ostatních budov',
                unit: 'm3',
              },
            ],
          },
          {
            name: 'ostatní pozemní objekty',
            children: [
              {
                name: 'zastřešení nástupišť',
                unit: 'm2',
              },
              {
                name: 'přístřešky pro cestující',
                unit: 'ks',
              },
              {
                name: 'orientačního systému',
                unit: 'm2',
              },
              {
                name: 'drobná architektura (oplocení)',
                unit: 'm',
              },
              {
                name: 'drobná architektura (ostatní)',
                unit: 'ks',
              },
            ],
          },
          {
            name: 'demolice pozemních objektů',
            unit: 'm2',
          },
        ],
      },
      {
        name: 'Trakční a energetická zařízení',
        children: [
          {
            name: 'trakční napájení',
            children: [
              {
                name: 'celková délka elektrizovaného úseku	',
                unit: 'km',
                diffValue: true,
              },
              {
                name: 'rekonstrukce trakčního vedení',
                unit: 'm',
              },
              {
                name: 'novostavba trakčního vedení',
                unit: 'm',
              },
            ],
          },
          {
            name: 'napájecí stanice',
            children: [
              {
                name: 'rekonstrukce',
                unit: 'ks',
              },
              {
                name: 'novostavba',
                unit: 'ks',
              },
            ],
          },
          {
            name: 'ostatní energetická zařízení',
            children: [
              {
                name: 'ohřev výměn',
                unit: 'v.j.',
                diffValue: true,
              },
              {
                name: 'elektrické předtápěcí zařízení',
                unit: 'ks',
              },
              {
                name: 'rozvodny vn, nn, osvětlení a ostatní kabelizace',
                unit: 'počet SO',
              },
            ],
          },
        ],
      },
      {
        name: 'Ostatní územní vlivy',
        children: [
          {
            name: 'z toho	příprava území a kácení',
            unit: 'm2',
          },
          {
            name: 'náhradní výsadba',
            unit: 'm2',
          },
          {
            name: 'zabezpečení veřejných zájmů',
            unit: 'm2',
          },
        ],
      },
      {
        name: 'Železniční zabezpečovací zařízení',
        children: [
          {
            name: 'staniční zabezpečovací zařízení (SZZ)',
            unit: 'v.j.',
          },
          {
            name: 'traťové zabezpečovací zařízení (TZZ)',
            unit: 'km tratě',
          },
          {
            name: 'přejezdové zabezpečovací zařízení (PZZ)',
            children: [
              {
                name: 'přejezd zabezpečený výstražnými kříži',
                unit: 'ks',
                diffValue: true,
              },
              {
                name: 'přejezd zabezpečený PZS',
                unit: 'ks',
                diffValue: true,
              },
              {
                name: 'přejezd zabezpečený PZS se závorami',
                unit: 'ks',
                diffValue: true,
              },
              {
                name: 'rušení přejezdu',
                unit: 'ks',
              },
            ],
          },
          {
            name: 'dálkové ovládání zabezpežovacího zařízení (DOZ)',
            children: [
              {
                name: 'délka dálkově ovládaného úseku',
                unit: 'km',
                diffValue: true,
              },
              {
                name: 'počet dálkově ovládaných dopraven',
                unit: 'ks',
                diffValue: true,
              },
            ],
          },
          {
            name: 'vybavení systémem ETCS',
            unit: 'km tratě',
            diffValue: true,
          },
          {
            name: 'ostatní prvky zabezpečení (např. spádoviště, indikátory horkoběžnosti apod)',
            unit: 'počet SO',
            diffValue: true,
          },
        ],
      },
      {
        name: 'Železniční sdělovací zařízení',
        children: [
          {
            name: 'počet dopraven a zastávek, vybavených informačním systémem nebo rozhlasovým zařízením',
            unit: 'ks',
          },
          {
            name: 'vybavení systémem GSM-R',
            children: [
              {
                name: 'délka úseku',
                unit: 'km',
                diffValue: true,
              },
              {
                name: 'základnová stanice - BTS',
                unit: 'ks',
              },
            ],
          },
        ],
      },
      {
        name: 'Silnoproudá technologie',
        children: [
          {
            name: 'silnoproudá technologie včetně DŘT',
            unit: 'počet SO',
          },
        ],
      },
      {
        name: 'Ostatní technologická zařízení',
        children: [
          {
            name: 'osobní výtahy',
            children: [
              {
                name: 'rekonstrukce',
                unit: 'ks',
              },
              {
                name: 'novostavba',
                unit: 'ks',
              },
            ],
          },
          {
            name: 'eskalátory',
            children: [
              {
                name: 'rekonstrukce',
                unit: 'ks',
              },
              {
                name: 'novostavba',
                unit: 'ks',
              },
            ],
          },
          {
            name: 'ostatní technologická zařízení',
            unit: 'počet SO',
          },
        ],
      },
    ],
  },
];

const BUILDING_DATA_MOCK: ProjectCardDataTreeColumn[] = [
  {
    name: 'Údaje o stavbě',
    children: [
      {
        name: 'Identifikační údaje stavby',
        children: [
          { name: 'Název stavby', children: [] },
          { name: 'Označení stavby', children: [] },
          { name: 'Přiřazená organizační jednotka', children: [] },
          { name: 'Oblastní správce', children: [] },
          { name: 'Aktuální status', children: [] },
          { name: 'Aktivní stádium', children: [] },
        ],
      },
      {
        name: 'Popis stavby',
        children: [
          { name: 'Kraj', children: [] },
          { name: 'TU', children: [] },
          { name: 'Popis stavby', children: [] },
        ],
      },
    ],
  },
  {
    name: 'Personální obsazení stavby',
    children: [
      { name: 'HIS', children: [] },
      // {
      //   name: 'Procesně odpovědné osoby',
      //   children: [
      //     { name: 'Matice odpovědnosti DUR', children: [] },
      //     { name: 'Matice odpovědnosti DSP/DUSP/DUSL', children: [] },
      //     { name: 'Matice odpovědnosti PDPS', children: [] },
      //   ],
      // },
      { name: 'Správce stavby/TDS', children: [] },
      { name: 'Ekonom stavby', children: [] },
      { name: 'PR odpovědnost', children: [] },
    ],
  },
  // {
  //   name: 'Harmonogram',
  //   children: [
  //     { name: 'Harmonogram stavební akce', children: [] },
  //     {
  //       name: 'Podrobný harmonogram stádia',
  //       children: [
  //         { name: 'Stádium 1', children: [] },
  //         { name: 'Stádium 2', children: [] },
  //         { name: 'Stádium 3', children: [] },
  //         { name: 'Realizace stavby', children: [] },
  //       ],
  //     },
  //   ],
  // },
  {
    name: 'Náklady stavby',
    children: [
      {
        name: 'Plánování nákladů',
        children: [
          { name: 'stádium 1', unit: 'Kč', children: [] },
          { name: 'stádium 2', unit: 'Kč', children: [] },
          { name: 'stádium 3', unit: 'Kč', children: [] },
        ],
      },
      { name: 'Čerpání nákladů realizace', unit: 'Kč', children: [] },
      { name: 'Schvalování nákladů', children: [] },
    ],
  },
];

export const CARD_DATA_MOCK = [
  {
    name: 'Základní informace o Stavbě',
    children: BUILDING_DATA_MOCK,
  },
  {
    name: 'Kapacitní údaje',
    children: SZ_CAPACITY_DATA_TABLE_MOCK,
  },
];

export function mapStructureToDto(structure: ProjectCardDataTreeColumn[]): OrgProjectCardDataDto[] {
  return structure.map((item, index) => {
    const id = uuid();
    const name = item.name;
    const hasDiffValue = item?.diffValue;
    const hasChildren = !!item.children?.length;
    const type =
      item.type ||
      (hasChildren
        ? JSONVariableTypeEnum.empty
        : !!item.unit
        ? hasDiffValue
          ? JSONVariableTypeEnum.numberDiff
          : JSONVariableTypeEnum.number
        : hasDiffValue
        ? JSONVariableTypeEnum.stringDiff
        : JSONVariableTypeEnum.string);

    const dataDto: OrgProjectCardDataDto = {
      id,
      name,
      variable: type,
      children: hasChildren ? mapStructureToDto(item.children) : [],
      unit: item.unit || '',
      defaultData: null,
      order: index,
    };

    return dataDto;
  });
}
