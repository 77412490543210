import { masterApi } from 'api/completeApi';
import { ServiceErrorEnum } from 'api/errors';
import { useApiData } from 'hooks';
import { useEffect, useMemo } from 'react';
import { useInterval } from 'react-use';

const DOCUMENT_STATE_REFRESH_INTERVAl = 1500;

export const useMdMeetingBackgroundDocumentState = (organizationId: Guid, meetingId: Guid) => {
  const [
    meetingDocumentState,
    meetingDocumentStateError,
    meetingDocumentStateLoading,
    loadMeetingDocumentState,
    setMeetingDocumentState,
  ] = useApiData((ct) => masterApi.projects.md.approvalmeeting.id.id.minutes.get(organizationId, meetingId, ct));

  useInterval(() => {
    if (!organizationId || !meetingId || meetingDocumentStateError) return;
    !meetingDocumentState?.processed && !meetingDocumentState?.error && loadMeetingDocumentState();
  }, DOCUMENT_STATE_REFRESH_INTERVAl);

  useEffect(() => {
    if (organizationId && meetingId) {
      loadMeetingDocumentState();
    }
  }, [organizationId, meetingId]);

  const isPreparationError = useMemo(
    () =>
      (meetingDocumentStateError &&
        meetingDocumentStateError?.referenceErrorCode !==
          ServiceErrorEnum.MdApprovalMeetingBackgroundMinutesStateNotFoundError) ||
      !!meetingDocumentState?.error,
    [meetingDocumentState, meetingDocumentStateError]
  );

  const isDocumentNotYetRequested = useMemo(
    () =>
      meetingDocumentStateError?.referenceErrorCode ===
      ServiceErrorEnum.MdApprovalMeetingBackgroundMinutesStateNotFoundError,
    [meetingDocumentStateError?.referenceErrorCode]
  );

  const isDocumentProcessing = useMemo(
    () =>
      !isPreparationError &&
      ((meetingDocumentStateLoading && !meetingDocumentState?.processed) ||
        meetingDocumentState?.inQueue ||
        (!meetingDocumentState?.document && meetingDocumentState?.progress < 100)),
    [meetingDocumentState, meetingDocumentStateLoading]
  );

  return [
    meetingDocumentState,
    meetingDocumentStateError,
    isPreparationError,
    isDocumentNotYetRequested,
    isDocumentProcessing,
    loadMeetingDocumentState,
    setMeetingDocumentState,
  ] as const;
};
