import { Form, Input, InputRef } from 'antd';
import DocumentMultipleActionError from 'components/DocumentMultipleActionError';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FC } from 'react';
import { requiredRule } from 'utils/formHelpers';
import styles from './DocumentsDownloadForm.module.less';

export type DocumentsDownloadFormData = {
  archiveName: string;
};

type Props = {
  selectedDocuments: Guid[];
  selectedLinks: Guid[];
  firstDocumentName: string;
  errors: JSX.Element[];
  setRef: (ref: InputRef) => void;
};

const DocumentsDownloadForm: FC<Props> = ({ firstDocumentName, selectedDocuments, selectedLinks, errors, setRef }) => {
  const intl = useIntl();

  return (
    <>
      <div className={styles.downloadDocumentsWrap}>
        <div className={styles.downloadDocumentsTitle}>
          {<Fmt id="DocumentsDownloadForm.downloadDocuments" values={{ count: selectedDocuments.length }} />}
        </div>
        <div className={styles.downloadDocumentsTitle}>
          {<Fmt id="DocumentsDownloadForm.downloadDocumentLinks" values={{ count: selectedLinks.length }} />}
        </div>
      </div>
      <Form.Item
        label={intl.formatMessage({ id: 'DocumentsDownloadForm.nameLabel' })}
        name="archiveName"
        initialValue={firstDocumentName}
        validateFirst
        rules={[requiredRule('DocumentsDownloadForm.form.items.name.rules.required')]}
      >
        <Input autoFocus ref={setRef} />
      </Form.Item>
      {errors.length > 0 && (
        <DocumentMultipleActionError errors={errors} titleId={'DocumentsDownloadForm.errorsTitle.missed'} />
      )}
    </>
  );
};

export default DocumentsDownloadForm;
