import DocumentMultipleActionError from 'components/DocumentMultipleActionError';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FC, ReactNode } from 'react';

type Props = {
  title: ReactNode;
  errors: JSX.Element[];
  errorsTextId: IntlMessageId;
};

const DocumentsDiscardForm: FC<Props> = ({ title, errors, errorsTextId }) => {
  return (
    <>
      <div>{title}</div>
      {errors.length > 0 && <DocumentMultipleActionError errors={errors} titleId={errorsTextId} />}
    </>
  );
};

export default DocumentsDiscardForm;
