import { masterApi } from 'api/completeApi';
import { ProjectTemplateWfTmpListDto } from 'api/completeApiInterfaces';
import { DeleteButton } from 'components/DeleteButton/DeleteButton';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import StackPanel from 'components/StackPanel';
import { useBoolean } from 'hooks';
import { Fmt } from 'locale';
import PageContent from 'pages/ProjectSettingsPage/PageContent';
import Panel from 'pages/ProjectSettingsPage/Panel';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { InjectedIntl } from 'react-intl';
import { smartFilter } from 'utils';
import { textComparer } from 'utils/comparators';
import { ProjectTemplateData, ProjectTemplateDataAction } from '../../ProjectTemplateBuilder';
import ProjectTemplateWorkflowTemplateAddFormModal from './ProjectTemplateWorkflowTemplateAddFormModal';

type Props = {
  intl: InjectedIntl;
  organizationId: Guid;
  projectTemplate: ProjectTemplateData;
  organizationWorkflowList: ProjectTemplateWfTmpListDto[];
  dispatchProjectTemplate: React.Dispatch<ProjectTemplateDataAction>;
  reloadProjectTemplate: () => void;
};

const getProjectWorkflowTemplateName = (workflowTemplate: ProjectTemplateWfTmpListDto) => {
  return workflowTemplate.name.split('##')[0];
};

const ProjectTemplateWorkflowTemplatesTab: FunctionComponent<Props> = ({
  intl,
  organizationId,
  projectTemplate,
  organizationWorkflowList,
  dispatchProjectTemplate,
  reloadProjectTemplate,
}) => {
  const [search, setSearch] = useState<string>();
  const [isTemplateAddModalVisible, showTemplateAddModal, hideTemplateAddModal] = useBoolean(false);

  const handleWorkflowTemplateRemove = useCallback(
    async (workflowTemplateId: Guid) => {
      await masterApi.projects.tempates.wftempate.id.template.id.delete(organizationId, workflowTemplateId);
      await reloadProjectTemplate();
    },
    [organizationId]
  );

  const handleWorkflowTemplateAdd = useCallback(() => {
    hideTemplateAddModal();
    reloadProjectTemplate && reloadProjectTemplate();
  }, [hideTemplateAddModal, reloadProjectTemplate]);

  const availableWorkflowTemplates = useMemo(() => {
    return organizationWorkflowList.filter((wfTmp) => {
      const wfOriginalName = getProjectWorkflowTemplateName(wfTmp);
      return !projectTemplate.wfTmps.some(
        (projectWfTemplate) => getProjectWorkflowTemplateName(projectWfTemplate) === wfOriginalName
      );
    });
  }, [organizationWorkflowList, projectTemplate]);

  const handleClearSearch = () => setSearch(undefined);

  const onCloseModal = () => {
    hideTemplateAddModal();
  };

  const sortedListItems = useMemo(() => {
    return projectTemplate.wfTmps.sort(textComparer.map((wfTmp) => wfTmp.name));
  }, [projectTemplate]);

  return (
    <StackPanel vertical>
      <PageContent title={<Fmt id={'ProjectTemplate.WorkflowTemplateTab.title'} />}>
        <Panel
          onSearch={setSearch}
          searchValue={search}
          addButtonOnClick={showTemplateAddModal}
          addButtonText={<Fmt id="ProjectTemplate.WorkflowTemplateTab.button.add" />}
        >
          <GeneralSettingsContainer>
            <List<ProjectTemplateWfTmpListDto>
              data={sortedListItems}
              search={search}
              filterItem={(item) => item && smartFilter(getProjectWorkflowTemplateName(item), search)}
              renderItem={(item) => (
                <GeneralSettingsItem
                  key={item.id}
                  title={getProjectWorkflowTemplateName(item)}
                  description={item.description}
                  additionalActions={
                    <>
                      <DeleteButton onDelete={() => handleWorkflowTemplateRemove(item.id)} type="link" />
                    </>
                  }
                />
              )}
              renderEmpty={(total, filtered) => (
                <ListEmpty filtered={filtered} total={total} onClearSearch={handleClearSearch} />
              )}
            />
          </GeneralSettingsContainer>
        </Panel>
        {isTemplateAddModalVisible && (
          <ProjectTemplateWorkflowTemplateAddFormModal
            onSubmit={handleWorkflowTemplateAdd}
            onCancel={onCloseModal}
            open={isTemplateAddModalVisible}
            projectTemplate={projectTemplate}
            workflowTemplateList={availableWorkflowTemplates}
          />
        )}
      </PageContent>
    </StackPanel>
  );
};

export default ProjectTemplateWorkflowTemplatesTab;
