import { ClusterOutlined, NodeCollapseOutlined, NodeExpandOutlined } from '@ant-design/icons';
import { Alert, message, Modal, Space, Spin, Typography } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { api } from 'api';
import { projectApi } from 'api/completeApi';
import {
  AccessLevelEnum,
  DirectoryContentDto,
  DirectoryDownloadDto,
  DirectoryDto,
  DirectoryListDto,
  EntityTypesEnum,
  ProjectMetadataDefinitionMetadataEntityType,
  ServiceError,
} from 'api/completeApiInterfaces';
import { DirectoryDownloadDocumentErrorData, ServiceErrorEnum } from 'api/errors';
import Axios from 'axios';
import { AuditLogEntityModal } from 'components/AuditLogsComponents';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { accessLevelMap } from 'components/DirectoryAccessLevel/DirectoryAccessLevel';
import { DOCUMENT_FILTERS, DOCUMENT_ORDER_OPTIONS } from 'components/DocumentCompleteList/DocumentCompleteList';
import { DocumentsHeader } from 'components/DocumentsHeader';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import {
  BooleanOption,
  createFrontendBooleanFilter,
} from 'components/filters/components/RadioFilter/variants/BooleanFilter/BooleanFilter';
import { FiltersContextProvider } from 'components/filters/FiltersContextProvider';
import { FiltersPersistentKey } from 'components/filters/filterTypes';
import { AppUserShareFormModal } from 'components/forms/AppUserShareForm';
import { DirectoryCreateFormModal } from 'components/forms/DirectoryCreateForm';
import DirectoryCreateLinkFormModal from 'components/forms/DirectoryCreateLinkForm/DirectoryCreateLinkFormModal';
import { DirectoryEditFormModal } from 'components/forms/DirectoryEditForm';
import DirectoryLinkDiscardFormModal from 'components/forms/DirectoryLinkDiscardForm/DirectoryLinkDiscardFormModal';
import DirectoryLinkEditFormModal from 'components/forms/DirectoryLinkEditForm/DirectoryLinkEditFormModal';
import DirectoryLinkMoveFormModal from 'components/forms/DirectoryLinkMoveForm/DirectoryLinkMoveFormModal';
import DirectoryMoveFormModal from 'components/forms/DirectoryMoveForm/DirectoryMoveFormModal';
import DirectorySettingsForm, {
  DirectorySettingsFormTabsEnum,
} from 'components/forms/DirectorySettingsForm/DirectorySettingsForm';
import { DirectoryDiscardFormModal } from 'components/forms/DiscardForm';
import { DocumentCreateMultipleFormModal } from 'components/forms/DocumentCreateForm';
import FolderValidationMasksContextProvider from 'components/forms/DocumentCreateForm/FolderValidationMasksContext';
import { DeepShareDownloadFormModal } from 'components/forms/ShareDownloadForm/DeepShareDownloadFormModal';
import {
  CreateShortcutIcon,
  DeleteIcon,
  DirectoryAddIcon,
  DirectoryMoveIcon,
  DownloadZipDeepWithFilesIcon,
  DownloadZipDeepWithoutFilesIcon,
  DownloadZipIcon,
  DownloadZipThisDirOnlyIcon,
  EditIcon,
  ShareAppUsersIcon,
  ShareDownloadIcon,
  ShareIcon,
  UploadIcon,
} from 'components/Icons/HubActionsIcons';
import { ActivityIcon } from 'components/Icons/HubEntitiesIcons';
import { Margin } from 'components/Margin/Margin';
import { FileSystemTreeNode } from 'components/PrimaryFileInput/CommonFilesInputTypes';
import ToolbarSettingsButton from 'components/ToolbarSettingsButton/ToolbarSettingsButton';
import { SIGNAL_R_DIRECTORY_CONNECTION, SIGNAL_R_REDUX_STORE_CONNECTION } from 'config';
import { HIDE_BUTTON_PROPS } from 'config/constants';
import {
  useActiveProject,
  useApiData,
  useBoolean,
  useCancelToken,
  useCurrentProjectUser,
  useSameCallback,
  useStoreSelector,
  useVisibleState,
} from 'hooks';
import { useOnDropProvider } from 'hooks/useOnDropProvider';
import { useDirtyStoreReload, useDispatchEffect } from 'hooks/useSelectorDispatch';
import produce from 'immer';
import { Fmt, InjectedIntl, InjectedIntlProps, memoizeWithIntl } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import { isEqual } from 'lodash';
import { SelectedItemsProvider } from 'pages/AllDocumentsPage/AllDocumentsPage.SelectedItemsContextProvider';
import AllDocumentsPageLayout from 'pages/AllDocumentsPage/AllDocumentsPageLayout';
import { directoryContainsSubdirWithName, useCreateDirectory } from 'pages/AllDocumentsPage/hooks/useCreateDirectory';
import { useCreateLink } from 'pages/AllDocumentsPage/hooks/useCreateLink';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { DirectoryRouteParams } from 'routes';
import { Dispatch } from 'store';
import {
  categoryMapSelector,
  directoryConnectedLinksSelector,
  directoryConnectedMapSelector,
  directoryRootSelector,
} from 'store/selectors';
import { projectUsersListSelector, projectUsersMapSelector } from 'store/selectors/projectUsersSelectors';
import { checkDirectoryWriteAccess, RedirectOption, redirectWithOption, strCompareCI } from 'utils';
import { DirectoryNodeKey } from 'utils/typeMappings/directories/directoryTreeIds';
import {
  ConnectedDirectory,
  ConnectedDirectoryLink,
  DirectoryNode,
  DirectoryNodeDirectoryLink,
  directoryNodeHelpers,
  DirectoryNodeType,
} from 'utils/typeMappings/directories/directoryTypes';
import { DisabledWithReason } from 'utils/types';
import { useProjectUrlPaths } from 'utils/urlPaths';
import AllDocumentPageChangeViewButton, { AllDocumentPageDisplayMode } from './AllDocumentPage.ChangeView.Button';
import AllDocumentPageMetadataGrid, { MetadataGridDocumentDefinitionType } from './AllDocumentPageMetadataGrid';
import DirectoryToolbar from './AllDocumentsPage.DirectoryToolbar';
import DirectoryReportExportFormModal from './AllDocumentsPage.ExportFormModal';
import styles from './AllDocumentsPage.module.less';
import { AllDocumentsPageContent } from './AllDocumentsPageContent';
import AllDocumentsPageDirectoryTree from './AllDocumentsPageDirectoryTree';
import { CommonDirectoryContentDto } from './AllDocumentsPageDocumentsGrid';
import GrantingAccessModal, { ACCESS_REQUEST } from './GrantingAccessModal/GrantingAccessModal';
import { useDirectoryReportExport } from './useDirectoryReportExport';

const { Text } = Typography;

enum DownloadErrorEntityEnum {
  document = 'document',
  link = 'link',
}

interface DocumentsErrors {
  isDirectoryLinkWarning: boolean;
  isDocument: boolean;
  objectId: Guid;
  objectName: string;
  objectPath: string;
}
interface ErrorData {
  documentsErrors: DocumentsErrors[];
}

export const getNodeKeysDeep = (
  node: ConnectedDirectory,
  filterNode?: (directoryNode: ConnectedDirectory) => boolean
): DirectoryNodeKey[] => {
  return [
    directoryNodeHelpers.directoryKey(node.id),
    ...node.children
      .flatMap(directoryNodeHelpers.filterMapDirectory)
      .filter((node) => (filterNode ? filterNode(node) : true))
      .flatMap((value) => getNodeKeysDeep(value, filterNode)),
  ];
};

type Props = RouteComponentProps<DirectoryRouteParams> & InjectedIntlProps;

type State = {
  directoryEditFormModalVisible: boolean;
  moveDirectoryModalVisible: boolean;
  moveDirectoryLinkModalVisible: boolean;
  moveDirectoryDestinationId: Guid;
  moveDirectorySourceId: Guid;
  discardDirectoryModalVisible: boolean;
  multiCreateFormModalFiles: FileSystemTreeNode[];
  moveDirectoryLink: ConnectedDirectoryLink;
  directoryLinkEditFormModalVisible: boolean;
  editDirectoryLink: ConnectedDirectoryLink;
  discardDirectoryLinkModalVisible: boolean;
  discardDirectoryLink: ConnectedDirectoryLink;
};

enum DirectoryChangeTypeEnum {
  'DirectoryAdded' = 0,
  'DirectoryChanged' = 1,
  'DirectoryDiscarded' = 2,
  'DirectoryRestored' = 3,
  'DirectoryContentChanged' = 4,
}

export const isAccessLevelAtLeast = (userAccessLevel: AccessLevelEnum, neededAccessLevel: AccessLevelEnum) => {
  if (!userAccessLevel || !neededAccessLevel) {
    return false;
  }
  return accessLevelMap[userAccessLevel] >= accessLevelMap[neededAccessLevel];
};

export function canCreateDirectory(dir: DirectoryListDto): boolean {
  return isAccessLevelAtLeast(dir?.currentAccessLevel, AccessLevelEnum.write);
}

export function canEditDirectoryMasks(dir: DirectoryListDto | DirectoryDto): boolean {
  return isAccessLevelAtLeast(dir?.currentAccessLevel, AccessLevelEnum.admin);
}

export function canEditDirectoryInfo(dir: DirectoryListDto | DirectoryDto): boolean {
  return isAccessLevelAtLeast(dir?.currentAccessLevel, AccessLevelEnum.write);
}

export function canWriteInDirectory(dir: DirectoryListDto): boolean {
  return isAccessLevelAtLeast(dir?.currentAccessLevel, AccessLevelEnum.write);
}

function directoryDiscardDisabled(dir: ConnectedDirectory, intl: InjectedIntl): DisabledWithReason {
  const discardEnabled = canDiscardDirectory(dir);

  return (
    !discardEnabled &&
    intl.formatMessage({
      id: 'AllDocumentsPage.docMenu.discard.disabled.byRights',
    })
  );
}

function canMoveDirectory(dir: ConnectedDirectory): boolean {
  return (
    isAccessLevelAtLeast(dir?.parent?.currentAccessLevel, AccessLevelEnum.write) &&
    isAccessLevelAtLeast(dir?.accessLevelIncludingChildren, AccessLevelEnum.write)
  );
}

export function canDiscardDirectory(dir: ConnectedDirectory): boolean {
  return (
    isAccessLevelAtLeast(dir?.parent?.currentAccessLevel, AccessLevelEnum.write) &&
    isAccessLevelAtLeast(dir?.accessLevelIncludingChildren, AccessLevelEnum.write)
  );
}

function inChildrenOrDirIsEsticonDir(dir: DirectoryNode): boolean {
  if (!dir || dir.type !== DirectoryNodeType.Directory) return false;
  return dir.directory.isEstiConDir || dir.directory.children.some(inChildrenOrDirIsEsticonDir);
}

export function directoryDeleteDisabledByEsticon(dir: ConnectedDirectory): boolean {
  return inChildrenOrDirIsEsticonDir(directoryNodeHelpers.directoryNode(dir));
}

/** Gets ID of this directory and all its parents */
export function directoryAncestryIds(directory: ConnectedDirectory) {
  const ids: Guid[] = [];
  while (directory) {
    ids.push(directory.id);
    directory = directory.parent;
  }
  return ids;
}
const directoryContainsDirectoryLinkWithName = (directory: ConnectedDirectory, name: string) =>
  directory.children
    .flatMap(directoryNodeHelpers.filterMapDirectoryLink)
    .some((dir) => strCompareCI(dir.linkName, name) === 0);

const FILTERS = [
  ...DOCUMENT_FILTERS,
  createFrontendBooleanFilter(
    'isModel',
    {
      label: <Fmt id="BooleanFilter.isModel" />,
      title: <Fmt id="BooleanFilter.isModel.description" />,
      trueLabel: <Fmt id="BooleanFilter.isModel.yes" />,
      falseLabel: <Fmt id="BooleanFilter.isModel.no" />,
    },
    (document: DirectoryContentDto | CommonDirectoryContentDto) =>
      document.isModel ? BooleanOption.True : BooleanOption.False
  ),
];

const AllDocumentsPageComponent: FunctionComponent<Props> = ({ intl, match }) => {
  const [state, setState] = useState<State>({
    directoryEditFormModalVisible: false,
    moveDirectoryModalVisible: false,
    moveDirectoryLinkModalVisible: false,
    moveDirectoryDestinationId: undefined,
    moveDirectorySourceId: undefined,
    discardDirectoryModalVisible: false,
    multiCreateFormModalFiles: null,
    moveDirectoryLink: undefined,
    directoryLinkEditFormModalVisible: false,
    editDirectoryLink: undefined,
    discardDirectoryLinkModalVisible: false,
    discardDirectoryLink: undefined,
  });
  const { projectId, directoryId, directoryLinkId } = match.params;

  const [auditLogDirectory, auditLogVisible, setAuditLogDirectory, hideAuditLog] = useVisibleState<DirectoryListDto>();
  const [shareDownloadModalVisible, showShareDownloadModal, hideShareDownloadModal] = useBoolean(false);
  const [shareAppUserModalVisible, showShareAppUserModal, hideShareAppUserModal] = useBoolean(false);
  const [directoryNotFound, setDirectoryNotFound] = useState<ServiceError>(undefined);
  const [isLastTargetDirectoryDeleted, setIsLastTargetDirectoryDeleted] = useState<boolean>(false);
  const [lastUsefulTargetDirectory, setLastUsefulTargetDirectory] = useState<ConnectedDirectory>(undefined);
  const [displayMode, setDisplayMode] = useState<AllDocumentPageDisplayMode>('common');
  const [projectMetadataDefinitions, metadataDefinitionsError, metadataDefinitionsLoading] = useApiData(
    (ct) => projectApi.metadata.definitions.get(ct),
    { autoload: true }
  );

  const documentsMetadataDefinitions = useMemo((): MetadataGridDocumentDefinitionType[] => {
    return projectMetadataDefinitions?.definitions
      .filter((definition) => definition.type === ProjectMetadataDefinitionMetadataEntityType.document)
      .map((definition) => ({
        metadataDefinitionId: definition.id,
        definitionName: definition.name,
        variableType: definition.variable,
      }));
  }, [projectMetadataDefinitions]);

  const documentMetadataDisableReason = useMemo((): string => {
    if (metadataDefinitionsError)
      return intl.formatMessage({ id: 'AllDocumentsPage.metadataDefinitions.loading.Error' });
    if (!documentsMetadataDefinitions?.length)
      return intl.formatMessage({ id: 'AllDocumentsPage.metadataDefinitions.noDefinitions.Error' });
    return '';
  }, [documentsMetadataDefinitions?.length, intl, metadataDefinitionsError]);

  const unmountCleanup = useCancelToken('AllDocumentsPage: unmounting', []);
  const dispatch = useDispatch<Dispatch>();

  const ui = useStoreSelector((state) => state.allDocumentsPage);
  const currentUser = useCurrentProjectUser();
  const usersList = useStoreSelector(projectUsersListSelector);
  const usersMap = useStoreSelector(projectUsersMapSelector);
  const categoryMap = useStoreSelector(categoryMapSelector);
  const directoryTree = useStoreSelector(directoryConnectedMapSelector);
  const directoryLinks = useStoreSelector(directoryConnectedLinksSelector);
  const directoryRoot = useStoreSelector(directoryRootSelector);

  const activeProject = useActiveProject();

  useDispatchEffect(
    (dispatch) =>
      !!activeProject?.organization?.id &&
      dispatch.organizationStructureList.loadData({
        reload: true,
        data: activeProject.organization.id,
      }),
    [activeProject?.organization?.id]
  );

  const { createDirectoryUrlPath, createDirectoryTreeUrlPath } = useProjectUrlPaths();

  const selectedDirectoryTreeKey = useMemo(() => {
    if (directoryId) {
      return directoryNodeHelpers.directoryKey(directoryId);
    }
    if (directoryLinkId) {
      return directoryNodeHelpers.directoryLinkKey(directoryLinkId);
    }
    return undefined;
  }, [directoryId, directoryLinkId]);

  useEffect(() => {
    SIGNAL_R_DIRECTORY_CONNECTION.start();

    return () => SIGNAL_R_DIRECTORY_CONNECTION.stop();
  }, [projectId]);

  useEffect(() => {
    const groupName = projectId + '-dc-' + directoryId;
    if (directoryId) {
      SIGNAL_R_DIRECTORY_CONNECTION.joinGroup(groupName);
    }

    return () => {
      if (directoryId) {
        SIGNAL_R_DIRECTORY_CONNECTION.leaveGroup(groupName);
      }
    };
  }, [directoryId, projectId]);

  useEffect(() => {
    if (selectedDirectoryTreeKey) {
      dispatch.allDocumentsPage.setLastDirectoryNodeKey({ directoryNodeKey: selectedDirectoryTreeKey });
    }
  }, [dispatch, selectedDirectoryTreeKey]);

  const nodeKeyToDirectoryNode = useCallback(
    (nodeKey: DirectoryNodeKey | undefined) => {
      return directoryNodeHelpers.directoryNodeFromKey(nodeKey, directoryTree, directoryLinks);
    },
    [directoryTree, directoryLinks]
  );

  const selectedDirectoryTreeNode = useMemo(() => nodeKeyToDirectoryNode(selectedDirectoryTreeKey), [
    selectedDirectoryTreeKey,
    nodeKeyToDirectoryNode,
  ]);

  const selectedTargetDirectory =
    selectedDirectoryTreeNode && directoryNodeHelpers.getTargetDirectory(selectedDirectoryTreeNode);

  // Expand directories up to selected node
  useEffect(() => {
    if (selectedDirectoryTreeNode) {
      const parentDirectory = directoryNodeHelpers.getParentDirectory(selectedDirectoryTreeNode);
      const toExpandIds = directoryAncestryIds(parentDirectory).map(directoryNodeHelpers.directoryKey);
      dispatch.allDocumentsPage.directoryTreeExpandWithSearched({ keys: toExpandIds });
    }
  }, [selectedDirectoryTreeNode, dispatch]);

  const isSelectedDirectory = useMemo(() => {
    return !!selectedDirectoryTreeNode && selectedDirectoryTreeNode.type === DirectoryNodeType.Directory;
  }, [selectedDirectoryTreeNode]);

  const [downloadErrorsModal, showDownloadErrorsModal, hideDownloadErrorsModal] = useBoolean(false);
  const [errorsModalContent, setErrorsModalContent] = useState<DirectoryDownloadDocumentErrorData[]>(undefined);
  const [downloadRequestContainsLinks, setDownloadRequestContainsLinks] = useState<boolean>(false);

  const [downloadZipLoading, setDownloadZipLoading] = useState(false);

  const [permissionsTab, setPermissionsTab] = useState(DirectorySettingsFormTabsEnum.general);
  const [permissionsVisible, setPermissionsVisible] = useState(false);

  useEffect(() => {
    dispatch.directoriesWithLinks.loadData({ reload: false });
    dispatch.projectUsers.loadData({ reload: false });
  }, [dispatch]);

  useDirtyStoreReload(
    (store) => store.projectUsers,
    (dispatch) => dispatch.projectUsers
  );
  useDirtyStoreReload(
    (store) => store.directories,
    (dispatch) => dispatch.directories
  );

  useDirtyStoreReload(
    (store) => store.directoriesWithLinks,
    (dispatch) => dispatch.directoriesWithLinks
  );

  useDirtyStoreReload(
    (store) => store.categories,
    (dispatch) => dispatch.categories
  );

  const replaceSelectedDirectory = useSameCallback((dirId: Guid) => {
    redirectWithOption(createDirectoryUrlPath(dirId), RedirectOption.Replace);
  });

  // Unknown dir parameter => show and select last visited directory or root directory if none is saved
  useEffect(() => {
    if (!selectedDirectoryTreeKey) {
      if (ui.lastSelectedDirectoryKey) {
        const asId = directoryNodeHelpers.directoryNodeKeyToId(ui.lastSelectedDirectoryKey);
        redirectWithOption(createDirectoryTreeUrlPath(asId), RedirectOption.Replace);
      } else if (directoryRoot) {
        replaceSelectedDirectory(directoryRoot.id);
      }
    }
  }, [
    selectedDirectoryTreeKey,
    ui.lastSelectedDirectoryKey,
    directoryRoot,
    createDirectoryTreeUrlPath,
    replaceSelectedDirectory,
  ]);

  const directoryExpandAll = useSameCallback((dirNode: ConnectedDirectory) => {
    if (dirNode) {
      const expandKeys = getNodeKeysDeep(dirNode);
      dispatch.allDocumentsPage.directoryTreeExpand({ keys: expandKeys });
    }
  });

  const directoryCollapseAll = useSameCallback((dirNode: ConnectedDirectory) => {
    if (dirNode) {
      const collapseKeys = getNodeKeysDeep(dirNode);
      dispatch.allDocumentsPage.directoryTreeCollapse({ keys: collapseKeys });
    }
  });

  const onGrantingAccessClose = useSameCallback(() => {
    replaceSelectedDirectory(directoryId);
  });

  const createDirectory = useCreateDirectory(dispatch, selectedTargetDirectory);
  const createLink = useCreateLink(dispatch, selectedTargetDirectory);

  const handleOpenMoveDirectoryModal = (moveDirectorySourceId?: Guid, moveDirectoryDestinationId?: Guid) => {
    setState(
      (state): State => ({
        ...state,
        moveDirectoryModalVisible: true,
        moveDirectorySourceId,
        moveDirectoryDestinationId,
      })
    );
  };

  const handleCloseMoveDirectoryModal = () => {
    setState((state): State => ({ ...state, moveDirectoryModalVisible: false }));
  };

  const handleMoveDirectoryFormSubmit = async (directoryList: DirectoryListDto[]) => {
    dispatch.directories.setData(directoryList); // TODO: direct store setter usage
    setState((state): State => ({ ...state, moveDirectoryModalVisible: false }));
  };

  const handleMoveDirectoryTreeDrop = (selectedNodeKey: DirectoryNodeKey, destinationNodeKey: DirectoryNodeKey) => {
    const selectedNode = nodeKeyToDirectoryNode(selectedNodeKey);
    const targetNode = nodeKeyToDirectoryNode(destinationNodeKey);

    if (!selectedNode || !targetNode) {
      return;
    }

    const targetDir = directoryNodeHelpers.getTargetDirectory(targetNode);
    if (!canWriteInDirectory(targetDir)) {
      message.error(intl.formatMessage({ id: 'serviceError.DestinationDirectoryForMoveForbiddenError' }));
      return;
    }

    if (selectedNode.type === DirectoryNodeType.Directory) {
      const selectedDir = selectedNode.directory;

      if (selectedDir.parentId === targetDir.id) {
        message.error(intl.formatMessage({ id: 'serviceError.DirectoryAlreadyExistsThereError' }));
        return;
      }
      if (!canMoveDirectory(selectedDir)) {
        message.error(intl.formatMessage({ id: 'serviceError.DirectoryMoveForbiddenError' }));
        return;
      }

      handleOpenMoveDirectoryModal(selectedDir.id, targetDir.id);
    } else {
      const selectedLink = selectedNode.directoryLink;
      handleOpenMoveDirectoryLinkModal(selectedLink, targetDir.id);
    }
  };

  const handleOpenDirectoryEditFormModal = () => {
    setState((state): State => ({ ...state, directoryEditFormModalVisible: true }));
  };

  const handleDirectoryDiscard = () => {
    setState((state): State => ({ ...state, discardDirectoryModalVisible: true }));
  };

  const handleDiscardDirectoryFormSubmit = async (_values: unknown, discardDirectory: ConnectedDirectory) => {
    setState((state): State => ({ ...state, discardDirectoryModalVisible: false }));
    await dispatch.directories.loadData({ reload: true });
    replaceSelectedDirectory(discardDirectory?.parentId);
  };

  const handleCloseDiscardDirectoryModal = () => {
    setState((state): State => ({ ...state, discardDirectoryModalVisible: false }));
  };

  const handleCloseDirectoryEditFormModal = () => {
    setState((state): State => ({ ...state, directoryEditFormModalVisible: false }));
  };

  const handleDirectoryEditFormSubmit = async () => {
    await dispatch.directories.loadData({ reload: true });
    setState((state): State => ({ ...state, directoryEditFormModalVisible: false }));
  };

  const handleDirectoryFavoriteChange = (directoryId: Guid, isFavorite: boolean) => {
    dispatch.directories.updateData((directories) =>
      produce(directories, (draft) => {
        const foundDir = draft.find((dir) => dir.id === directoryId);
        if (foundDir) {
          foundDir.isFavorite = isFavorite;
        }
      })
    );
  };

  const toolbarDirectoryLinkMove = () => {
    if (!selectedDirectoryTreeNode || selectedDirectoryTreeNode.type === DirectoryNodeType.Directory) return;
    return handleOpenMoveDirectoryLinkModal(selectedDirectoryTreeNode.directoryLink);
  };

  const handleOpenMoveDirectoryLinkModal = (directoryLink: ConnectedDirectoryLink, defaultDestinationId?: Guid) => {
    setState(
      (state): State => ({
        ...state,
        moveDirectoryLinkModalVisible: true,
        moveDirectoryDestinationId: defaultDestinationId,
        moveDirectoryLink: directoryLink,
      })
    );
  };

  const handleCloseMoveDirectoryLinkModal = () => {
    setState((state): State => ({ ...state, moveDirectoryLinkModalVisible: false }));
  };

  const handleMoveDirectoryLinkFormSubmit = async () => {
    dispatch.directoriesWithLinks.loadData({ reload: true });
    setState((state): State => ({ ...state, moveDirectoryLinkModalVisible: false }));
  };

  const toolbarDirectoryLinkEdit = () => {
    if (!selectedDirectoryTreeNode || selectedDirectoryTreeNode.type === DirectoryNodeType.Directory) return;
    return handleOpenDirectoryLinkEditFormModal(selectedDirectoryTreeNode.directoryLink);
  };

  const handleOpenDirectoryLinkEditFormModal = (directoryLink: ConnectedDirectoryLink) => {
    setState(
      (state): State => ({ ...state, directoryLinkEditFormModalVisible: true, editDirectoryLink: directoryLink })
    );
  };

  const handleCloseDirectoryLinkEditFormModal = () => {
    setState((state): State => ({ ...state, directoryLinkEditFormModalVisible: false }));
  };

  const handleDirectoryLinkEditFormSubmit = async () => {
    await dispatch.directoriesWithLinks.loadData({ reload: true });
    setState((state): State => ({ ...state, directoryLinkEditFormModalVisible: false }));
  };

  const toolbarDirectoryLinkDiscard = () => {
    if (!selectedDirectoryTreeNode || selectedDirectoryTreeNode.type === DirectoryNodeType.Directory) return;
    return handleOpenDiscardDirectoryLinkModal(selectedDirectoryTreeNode.directoryLink);
  };

  const handleOpenDiscardDirectoryLinkModal = (directoryLink: ConnectedDirectoryLink) => {
    setState(
      (state): State => ({ ...state, discardDirectoryLinkModalVisible: true, discardDirectoryLink: directoryLink })
    );
  };

  const handleDiscardDirectoryLinkFormSubmit = async () => {
    await dispatch.directoriesWithLinks.loadData({ reload: true });
    setState((state): State => ({ ...state, discardDirectoryLinkModalVisible: false }));
    replaceSelectedDirectory(state.discardDirectoryLink.parentDirectoryId);
  };

  const handleCloseDiscardDirectoryLinkModal = () => {
    setState((state): State => ({ ...state, discardDirectoryLinkModalVisible: false }));
  };

  const checkUpdateDirectoryUniqueName = (name: string): boolean => {
    // name is equal to original name
    if (strCompareCI(name, selectedTargetDirectory.name) === 0) return true;
    return !directoryContainsSubdirWithName(selectedTargetDirectory.parent, name);
  };

  const checkUpdateDirectoryLinkUniqueName = (name: string): boolean => {
    const directoryLink = selectedDirectoryTreeNode as DirectoryNodeDirectoryLink;
    if (strCompareCI(name, state.editDirectoryLink?.linkName) === 0) return true;
    return !directoryContainsDirectoryLinkWithName(directoryLink.directoryLink.parentDirectory, name);
  };

  const hasWriteAccess = useMemo(() => checkDirectoryWriteAccess(selectedTargetDirectory?.currentAccessLevel), [
    selectedTargetDirectory,
  ]);

  const [onDropFiles, droppedFiles, onCloseFileUpload, fileUploadVisible, onShowFileUpload] = useOnDropProvider(
    hasWriteAccess
  );

  const handleUploadFilesSubmit = useCallback(() => {
    dispatch.directories.loadData({ reload: true });
    onCloseFileUpload();
  }, [dispatch, onCloseFileUpload]);

  const downloadDirectoryInZip = async (
    directoryId: Guid,
    directoryData: DirectoryDownloadDto,
    errorsArray: DirectoryDownloadDocumentErrorData[] = []
  ) => {
    if (downloadZipLoading) return;

    setDownloadZipLoading(true);
    const [err, res] = await api.project.directories.getDirectoryDownloadUrl(
      directoryId,
      directoryData,
      unmountCleanup
    );
    if (Axios.isCancel(err)) return;
    setDownloadZipLoading(false);

    if (err) {
      const incomingErrorsArray: DirectoryDownloadDocumentErrorData[] = [
        ...(err.response.data.errorData as ErrorData)?.documentsErrors,
      ];
      const actualDirectoryData: DirectoryDownloadDto = {
        ...directoryData,
        ignoredDocumentsIds: incomingErrorsArray.filter((d) => d.isDocument).map((d) => d.objectId),
        ignoredDocumentLinksIds: incomingErrorsArray
          .filter((d) => !d.isDocument && !d.isDirectoryLinkWarning)
          .map((d) => d.objectId),
        ignoredDirectoryLinksIds: incomingErrorsArray
          .filter((d) => !d.isDocument && d.isDirectoryLinkWarning)
          .map((d) => d.objectId),
      };
      if (!!actualDirectoryData.ignoredDirectoryLinksIds.length) {
        setDownloadRequestContainsLinks(true);
      }
      if (isEqual(directoryData, actualDirectoryData)) {
        message.error(intl.formatMessage({ id: 'general.docMenu.downloadInZip.downloadError' }));
        return;
      }

      downloadDirectoryInZip(
        directoryId,
        {
          ...actualDirectoryData,
          ignoreDirectoryLink: true,
          ignoredDocumentsIds: incomingErrorsArray.filter((d) => d.isDocument).map((d) => d.objectId),
          ignoredDocumentLinksIds: incomingErrorsArray
            .filter((d) => !d.isDocument && !d.isDirectoryLinkWarning)
            .map((d) => d.objectId),
          ignoredDirectoryLinksIds: incomingErrorsArray
            .filter((d) => !d.isDocument && d.isDirectoryLinkWarning)
            .map((d) => d.objectId),
        },
        incomingErrorsArray
      );
    }
    if (!err) {
      setErrorsModalContent(errorsArray);
      !!errorsArray.length && showDownloadErrorsModal();
      const { url } = res.data;
      window.open(url, '_blank');
    }
  };

  const errorsModalTitle = useMemo(() => {
    return !!errorsModalContent && !!errorsModalContent.filter((error) => !error.isDirectoryLinkWarning).length
      ? intl.formatMessage({ id: `serviceError.DocumentDeepDownloadForbiddenError` })
      : '';
  }, [errorsModalContent, intl]);

  const handleOkDownloadErrorsModal = () => {
    hideDownloadErrorsModal();
    setErrorsModalContent(undefined);
    setDownloadRequestContainsLinks(false);
  };

  const [onOpenExport, onSubmitExport, onCancelExport, exportFormVisible, exporting] = useDirectoryReportExport({
    id: selectedTargetDirectory?.id,
    name: selectedTargetDirectory?.name,
  });

  const renderDownloadErrorsModalContent = useCallback(
    (currentErrorEntity: DownloadErrorEntityEnum, intlMessageId: IntlMessageId) => {
      const isDocument = currentErrorEntity === DownloadErrorEntityEnum.document;
      const errorEntities = errorsModalContent?.filter((er) => er.isDocument == isDocument);
      return !!errorEntities?.length ? (
        <>
          <Margin bottom top>
            <Text strong>
              <Fmt id={intlMessageId} />
            </Text>
          </Margin>
          {errorEntities?.map((error) => (
            <Margin bottom left right key={error.objectId}>
              <CommonHubTooltip placement="topLeft" title={error.objectName}>
                <div className={styles.dirElipsis}>{error.objectName}</div>
              </CommonHubTooltip>
              <CommonHubTooltip placement="topLeft" title={error.objectPath}>
                <div className={styles.dirElipsis}>{error.objectPath}</div>
              </CommonHubTooltip>
            </Margin>
          ))}
        </>
      ) : (
        undefined
      );
    },
    [errorsModalContent]
  );

  const renderDirectoriesMenu = (node: DirectoryNode): ItemType[] => {
    const dir = directoryNodeHelpers.getTargetDirectory(node);
    const createDisabled: boolean = !canCreateDirectory(dir);
    const modifyDisabled: boolean = !canEditDirectoryInfo(dir);
    const moveDisabled: boolean = !canMoveDirectory(dir);
    const discardDisabled: boolean = !canDiscardDirectory(dir);
    const linkDiscardDisabled: boolean = !canEditDirectoryInfo(directoryNodeHelpers.getParentDirectory(node));

    const dirExpandAll = () => {
      directoryExpandAll(dir);
    };
    const dirCollapseAll = () => {
      directoryCollapseAll(dir);
    };

    if (node.type === DirectoryNodeType.DirectoryLink) {
      const existLinkedDirectory = !!node.directoryLink.linkedDirectory;
      const items: ItemType[] = [
        {
          key: 'upload-multiple',
          label: <Fmt id="AllDocumentsPage.docMenu.upload" />,
          icon: <UploadIcon />,
          onClick: onShowFileUpload,
          disabled: !checkDirectoryWriteAccess(dir?.currentAccessLevel),
        },
        {
          type: 'divider',
        },
        {
          key: 'create-directory',
          label: <Fmt id="AllDocumentsPage.docMenu.createDirectory" />,
          icon: <DirectoryAddIcon />,
          onClick: createDirectory.show, // TODO: fix for clicked dir
          disabled: createDisabled,
        },
        {
          key: 'move',
          label: <Fmt id="general.move" />,
          icon: <DirectoryMoveIcon />,
          onClick: () => handleOpenMoveDirectoryLinkModal(node.directoryLink),
          disabled: moveDisabled,
        },
        {
          key: 'edit',
          label: <Fmt id="general.edit" />,
          icon: <EditIcon />,
          onClick: () => handleOpenDirectoryLinkEditFormModal(node.directoryLink),
          disabled: modifyDisabled,
        },
        {
          key: 'delete',
          label: <Fmt id="AllDocumentsPage.docMenu.delete" />,
          icon: <DeleteIcon />,
          onClick: () => handleOpenDiscardDirectoryLinkModal(node.directoryLink),
          disabled: linkDiscardDisabled,
        },
        {
          key: 'download-in-zip',
          label: <Fmt id="general.docMenu.downloadInZip" />,
          icon: <DownloadZipIcon />,
          disabled: !existLinkedDirectory,
          children: [
            {
              key: 'download-this-dir-only',
              label: <Fmt id="general.docMenu.downloadInZip.thisDirOnly" />,
              icon: <DownloadZipThisDirOnlyIcon />,
              onClick: () =>
                downloadDirectoryInZip(dir.id, {
                  name: dir.name,
                  withFiles: true,
                  withSubfolders: false,
                  ignoreDirectoryLink: false,
                }),
            },
            {
              key: 'download-deep-with-files',
              label: <Fmt id="general.docMenu.downloadInZip.deepWithFiles" />,
              icon: <DownloadZipDeepWithFilesIcon />,
              onClick: () =>
                downloadDirectoryInZip(dir.id, {
                  name: dir.name,
                  withFiles: true,
                  withSubfolders: true,
                  ignoreDirectoryLink: false,
                }),
            },
            {
              key: 'download-deep-without-files',
              label: <Fmt id="general.docMenu.downloadInZip.deepWithoutFiles" />,
              icon: <DownloadZipDeepWithoutFilesIcon />,
              onClick: () =>
                downloadDirectoryInZip(dir.id, {
                  name: dir.name,
                  withFiles: false,
                  withSubfolders: true,
                  ignoreDirectoryLink: false,
                }),
            },
          ],
        },
        {
          key: 'share',
          label: <Fmt id="general.docMenu.share" />,
          icon: <ShareIcon />,
          disabled: !existLinkedDirectory,
          children: [
            {
              key: 'share-download',
              label: <Fmt id="general.docMenu.shareDownload" />,
              icon: <ShareDownloadIcon />,
              onClick: showShareDownloadModal, // TODO: fix for clicked dir
            },
            {
              key: 'share-app-user-download',
              label: <Fmt id="general.docMenu.shareAppUserDownload" />,
              icon: <ShareAppUsersIcon />,
              onClick: showShareAppUserModal, // TODO: fix for clicked dir
            },
          ],
        },
        {
          key: 'create-directory-link',
          label: <Fmt id="general.dirMenu.createDirectoryLink" />,
          icon: <CreateShortcutIcon />,
          onClick: createLink.show, // TODO: fix for clicked dir
          disabled: !existLinkedDirectory,
        },
        { type: 'divider' },
        {
          key: 'audit-log',
          label: <Fmt id="general.activity" />,
          icon: <ActivityIcon />,
          onClick: () => setAuditLogDirectory(dir),
          disabled: !existLinkedDirectory,
        },
      ];

      return items;
    }

    const items: ItemType[] = [
      {
        key: 'display-subfolders',
        label: <Fmt id="AllDocumentsPage.docMenu.displaySubfolders" />,
        icon: <ClusterOutlined />,
        children: [
          {
            key: 'expand-all',
            label: <Fmt id="AllDocumentsPage.docMenu.expandAll" />,
            icon: <NodeExpandOutlined />,
            onClick: dirExpandAll,
          },
          {
            key: 'collapse-all',
            label: <Fmt id="AllDocumentsPage.docMenu.collapseAll" />,
            icon: <NodeCollapseOutlined />,
            onClick: dirCollapseAll,
          },
        ],
      },
      {
        type: 'divider',
      },
      {
        key: 'upload-multiple',
        label: <Fmt id="AllDocumentsPage.docMenu.upload" />,
        icon: <UploadIcon />,
        onClick: onShowFileUpload, // TODO: fix for clicked dir
        disabled: !checkDirectoryWriteAccess(dir.currentAccessLevel),
      },
      {
        type: 'divider',
      },
      {
        key: 'create-directory',
        label: <Fmt id="AllDocumentsPage.docMenu.createDirectory" />,
        icon: <DirectoryAddIcon />,
        onClick: createDirectory.show, // TODO: fix for clicked dir
        disabled: createDisabled,
      },
      {
        key: 'move',
        label: <Fmt id="general.move" />,
        icon: <DirectoryMoveIcon />,
        onClick: () => handleOpenMoveDirectoryModal(dir.id),
        disabled: moveDisabled,
      },
      {
        key: 'edit',
        label: <Fmt id="general.edit" />,
        icon: <EditIcon />,
        onClick: handleOpenDirectoryEditFormModal, // TODO: fix for clicked dir
        disabled: modifyDisabled,
      },
      {
        key: 'delete',
        label: <Fmt id="AllDocumentsPage.docMenu.discard" />,
        icon: <DeleteIcon />,
        onClick: handleDirectoryDiscard, // TODO: fix for clicked dir
        disabled: discardDisabled,
      },
      {
        key: 'download-in-zip',
        label: <Fmt id="general.docMenu.downloadInZip" />,
        icon: <DownloadZipIcon />,
        children: [
          {
            key: 'download-this-dir-only',
            label: <Fmt id="general.docMenu.downloadInZip.thisDirOnly" />,
            icon: <DownloadZipThisDirOnlyIcon />,
            onClick: () =>
              downloadDirectoryInZip(dir.id, {
                name: dir.name,
                withFiles: true,
                withSubfolders: false,
                ignoreDirectoryLink: false,
              }),
          },
          {
            key: 'download-deep-with-files',
            label: <Fmt id="general.docMenu.downloadInZip.deepWithFiles" />,
            icon: <DownloadZipDeepWithFilesIcon />,
            onClick: () =>
              downloadDirectoryInZip(dir.id, {
                name: dir.name,
                withFiles: true,
                withSubfolders: true,
                ignoreDirectoryLink: false,
              }),
          },
          {
            key: 'download-deep-without-files',
            label: <Fmt id="general.docMenu.downloadInZip.deepWithoutFiles" />,
            icon: <DownloadZipDeepWithoutFilesIcon />,
            onClick: () =>
              downloadDirectoryInZip(dir.id, {
                name: dir.name,
                withFiles: false,
                withSubfolders: true,
                ignoreDirectoryLink: false,
              }),
          },
        ],
      },
      {
        key: 'share',
        label: <Fmt id="general.docMenu.share" />,
        icon: <ShareIcon />,
        children: [
          {
            key: 'share-download',
            label: <Fmt id="general.docMenu.shareDownload" />,
            icon: <ShareDownloadIcon />,
            onClick: showShareDownloadModal, // TODO: fix for clicked dir
          },
          {
            key: 'share-app-user-download',
            label: <Fmt id="general.docMenu.shareAppUserDownload" />,
            icon: <ShareAppUsersIcon />,
            onClick: showShareAppUserModal, // TODO: fix for clicked dir
          },
        ],
      },
      {
        key: 'create-directory-link',
        label: <Fmt id="general.dirMenu.createDirectoryLink" />,
        icon: <CreateShortcutIcon />,
        onClick: createLink.show, // TODO: fix for clicked dir
      },
      { type: 'divider' },
      {
        key: 'audit-log',
        label: <Fmt id="general.activity" />,
        icon: <ActivityIcon />,
        onClick: () => setAuditLogDirectory(dir),
      },
    ];
    return items;
  };

  const dir = selectedTargetDirectory;

  const createDisabled: boolean = !canCreateDirectory(dir);
  const modifyDisabled: boolean = !canEditDirectoryInfo(dir);
  const discardDisabled: DisabledWithReason = directoryDiscardDisabled(dir, intl);
  const moveDirectoryDisabled: boolean = !canMoveDirectory(dir);

  const canUploadDocuments = selectedTargetDirectory && hasWriteAccess;

  useEffect(() => {
    if (!!Object.keys(directoryTree).length && !Object.keys(directoryTree).includes(selectedTargetDirectory?.id)) {
      setDirectoryNotFound({
        statusCode: 404,
        referenceErrorCode: ServiceErrorEnum.DirectoryNotFoundError,
      });
    } else {
      setDirectoryNotFound(undefined);
    }
  }, [directoryTree, selectedTargetDirectory?.id]);

  const directoryNotFoundDescription = useMemo(() => {
    if (isLastTargetDirectoryDeleted) {
      return (
        <Alert
          showIcon
          type="error"
          description={
            <>
              <p
                dangerouslySetInnerHTML={{
                  __html: intl.formatHTMLMessage(
                    { id: 'AllDocumentPage.deletedTargetDirectory.error' },
                    { name: lastUsefulTargetDirectory?.name }
                  ),
                }}
              />
              {intl.formatMessage(
                { id: 'AllDocumentPage.deletedTargetDirectory.lastPath' },
                { path: lastUsefulTargetDirectory?.path }
              )}
            </>
          }
        />
      );
    }
    return null;
  }, [intl, isLastTargetDirectoryDeleted, lastUsefulTargetDirectory]);

  useEffect(() => {
    if (!!dir) setLastUsefulTargetDirectory(dir);
  }, [dir]);

  const checkDirectoryChangedSignalR = useCallback(
    (fromSignalDirectoryId: Guid, changedById: Guid, eventType: DirectoryChangeTypeEnum) => {
      if (eventType === DirectoryChangeTypeEnum.DirectoryDiscarded && directoryId === fromSignalDirectoryId) {
        setIsLastTargetDirectoryDeleted(true);
      }
    },
    [directoryId]
  );

  useEffect(() => {
    SIGNAL_R_REDUX_STORE_CONNECTION.on('DirectoriesWithLinksChanged', checkDirectoryChangedSignalR);
    return () => {
      SIGNAL_R_REDUX_STORE_CONNECTION.off('DirectoriesWithLinksChanged', checkDirectoryChangedSignalR);
    };
  }, [checkDirectoryChangedSignalR]);

  return (
    <>
      <AllDocumentsPageLayout
        directoryContent={
          <ContentGate
            loading={!selectedTargetDirectory}
            error={directoryNotFound}
            additionalErrorContent={directoryNotFoundDescription}
          >
            <SelectedItemsProvider>
              {permissionsVisible ? (
                <DirectorySettingsForm
                  directory={selectedTargetDirectory}
                  usersList={usersList}
                  usersMap={usersMap}
                  categoryMap={categoryMap}
                  onSubmit={handleDirectoryEditFormSubmit}
                  defaultTab={permissionsTab}
                  setDefaultTab={setPermissionsTab}
                  projectId={projectId}
                />
              ) : (
                <FiltersContextProvider
                  filtersOptions={FILTERS}
                  orderOptions={DOCUMENT_ORDER_OPTIONS}
                  persistentKey={FiltersPersistentKey.DocumentsAll}
                >
                  {displayMode === 'common' ? (
                    <AllDocumentsPageContent
                      directory={selectedTargetDirectory}
                      directoryToolbar={
                        <DirectoryToolbar
                          className={styles.directoryToolbar}
                          selectedDirectory={selectedTargetDirectory}
                          onMultipleUpload={onShowFileUpload}
                          onCreateDirectory={createDirectory.show}
                          onMoveDirectory={handleOpenMoveDirectoryModal}
                          onDirectoryEdit={handleOpenDirectoryEditFormModal}
                          onDirectoryDiscard={handleDirectoryDiscard}
                          onDownloadZip={downloadDirectoryInZip}
                          onFavoriteChange={handleDirectoryFavoriteChange}
                          onShareAppUser={showShareAppUserModal}
                          onShareDownload={showShareDownloadModal}
                          downloadZipLoading={downloadZipLoading}
                          createDisabled={createDisabled}
                          moveDisabled={moveDirectoryDisabled}
                          modifyDisabled={modifyDisabled}
                          discardDisabled={discardDisabled}
                          onCreateDirectoryLink={createLink.show}
                          onEditDirectoryLink={toolbarDirectoryLinkEdit}
                          onDiscardDirectoryLink={toolbarDirectoryLinkDiscard}
                          onMoveDirectoryLink={toolbarDirectoryLinkMove}
                          isSelectedDirectory={isSelectedDirectory}
                          onOpenExport={onOpenExport}
                          setAuditLogDirectory={setAuditLogDirectory}
                        />
                      }
                    />
                  ) : displayMode === 'metadata' ? (
                    <AllDocumentPageMetadataGrid
                      directoryId={selectedTargetDirectory?.id}
                      documentsMetadataDefinitions={documentsMetadataDefinitions}
                    />
                  ) : null}
                </FiltersContextProvider>
              )}
            </SelectedItemsProvider>
          </ContentGate>
        }
        documentsHeader={
          selectedTargetDirectory && (
            <DocumentsHeader
              projectId={projectId}
              directoryPath={selectedTargetDirectory.path}
              directoryId={selectedTargetDirectory.id}
              buttons={
                <Space size="large" align="center">
                  <Spin spinning={metadataDefinitionsLoading}>
                    <AllDocumentPageChangeViewButton
                      disableReason={documentMetadataDisableReason}
                      displayMode={displayMode}
                      setDisplayMode={setDisplayMode}
                    />
                  </Spin>
                  <ToolbarSettingsButton
                    onDisplayDirectorySettings={setPermissionsVisible}
                    displayDirectorySettings={permissionsVisible}
                  />
                </Space>
              }
            />
          )
        }
        directoryTree={
          <AllDocumentsPageDirectoryTree
            contextMenuItems={renderDirectoriesMenu}
            onMoveDirectory={handleMoveDirectoryTreeDrop}
            projectId={projectId}
            selectedTreeKey={selectedDirectoryTreeKey}
          />
        }
        disableDropzone={!canUploadDocuments || fileUploadVisible}
        onDropFiles={onDropFiles}
      />

      {selectedTargetDirectory && (
        <>
          <ErrorBoundary>
            <DirectoryDiscardFormModal
              selectedDirectory={selectedTargetDirectory}
              open={state.discardDirectoryModalVisible}
              onSubmit={handleDiscardDirectoryFormSubmit}
              onClose={handleCloseDiscardDirectoryModal}
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <DirectoryCreateLinkFormModal {...createLink} />
          </ErrorBoundary>
          <ErrorBoundary>
            <FolderValidationMasksContextProvider>
              {fileUploadVisible && (
                <DocumentCreateMultipleFormModal
                  startingDirectoryId={selectedTargetDirectory?.id}
                  open={fileUploadVisible}
                  onSubmit={handleUploadFilesSubmit}
                  onClose={onCloseFileUpload}
                  droppedFiles={droppedFiles}
                  uploadDisabled={!canUploadDocuments}
                />
              )}
            </FolderValidationMasksContextProvider>
          </ErrorBoundary>
          <ErrorBoundary>
            <AuditLogEntityModal
              visible={auditLogVisible}
              label={<Fmt id="AuditLog.directory.label" values={{ path: auditLogDirectory?.path }} />}
              entityId={auditLogDirectory?.id}
              entityType={EntityTypesEnum.directory}
              onOk={hideAuditLog}
              deps={[auditLogDirectory]}
            />
          </ErrorBoundary>
          <AppUserShareFormModal
            directoryId={selectedTargetDirectory.id}
            shareDocument={false}
            open={shareAppUserModalVisible}
            onSubmit={hideShareAppUserModal}
            onClose={hideShareAppUserModal}
          />
          <DeepShareDownloadFormModal
            directoryId={selectedTargetDirectory.id}
            open={shareDownloadModalVisible}
            onSubmit={hideShareDownloadModal}
            onClose={hideShareDownloadModal}
          />
          <DirectoryCreateFormModal {...createDirectory} />
          <DirectoryEditFormModal
            directoryId={selectedTargetDirectory.id}
            name={selectedTargetDirectory.name}
            description={selectedTargetDirectory.description}
            open={state.directoryEditFormModalVisible}
            onSubmit={handleDirectoryEditFormSubmit}
            onClose={handleCloseDirectoryEditFormModal}
            validateUniqueName={checkUpdateDirectoryUniqueName}
          />
          <DirectoryMoveFormModal
            selectedDirectory={
              state.moveDirectorySourceId ? directoryTree[state.moveDirectorySourceId] : selectedTargetDirectory
            }
            destinationDirectory={state.moveDirectoryDestinationId}
            open={state.moveDirectoryModalVisible}
            onSubmit={handleMoveDirectoryFormSubmit}
            onClose={handleCloseMoveDirectoryModal}
            match={match}
          />
          <DirectoryLinkMoveFormModal
            directoryLink={state.moveDirectoryLink}
            defaultDestinationId={state.moveDirectoryDestinationId}
            open={state.moveDirectoryLinkModalVisible}
            onSubmit={handleMoveDirectoryLinkFormSubmit}
            onClose={handleCloseMoveDirectoryLinkModal}
            match={match}
          />
          <DirectoryLinkEditFormModal
            directoryLinkId={state.editDirectoryLink?.linkId}
            name={state.editDirectoryLink?.linkName}
            open={state.directoryLinkEditFormModalVisible}
            onSubmit={handleDirectoryLinkEditFormSubmit}
            onClose={handleCloseDirectoryLinkEditFormModal}
            validateUniqueName={checkUpdateDirectoryLinkUniqueName}
          />
          <DirectoryLinkDiscardFormModal
            directoryLink={state.discardDirectoryLink}
            open={state.discardDirectoryLinkModalVisible}
            onSubmit={handleDiscardDirectoryLinkFormSubmit}
            onClose={handleCloseDiscardDirectoryLinkModal}
          />
          {match.params.action === ACCESS_REQUEST && (
            <GrantingAccessModal
              usersMap={usersMap}
              usersList={usersList}
              selectedDirectory={selectedTargetDirectory}
              currentUser={currentUser}
              action={match.params.action}
              projectId={projectId}
              onClose={onGrantingAccessClose}
            />
          )}
          <DirectoryReportExportFormModal
            onSubmit={onSubmitExport}
            onClose={onCancelExport}
            visible={exportFormVisible}
            loading={exporting}
          />
          <Modal
            title={errorsModalTitle}
            open={downloadErrorsModal}
            cancelButtonProps={HIDE_BUTTON_PROPS}
            onOk={handleOkDownloadErrorsModal}
            onCancel={handleOkDownloadErrorsModal}
            width={800}
          >
            {renderDownloadErrorsModalContent(
              DownloadErrorEntityEnum.document,
              'AllDocumentsPage.downloadErrorsModal.documents'
            )}
            {downloadRequestContainsLinks && (
              <Margin top>
                <Text strong underline>
                  <Fmt id="general.docMenu.downloadInZip.directoryLinkWarning" />
                </Text>
              </Margin>
            )}
          </Modal>
        </>
      )}
    </>
  );
};

export default memoizeWithIntl(AllDocumentsPageComponent);
