import { Form, Input } from 'antd';
import { apiConstraints } from 'api/completeApiConstraints';
import { OrganizationStructureDto } from 'api/completeApiInterfaces';
import { useIntl } from 'hooks';
import React, { FC, useMemo } from 'react';
import { duplicateNameRule, maxLengthRule, requiredRule } from 'utils/formHelpers';

export type OrganizationStructureFormData = {
  id: Guid;
  sign?: string;
  name?: string;
  description?: string;
  order: number;
};

type Props = {
  selectedStructureNode?: OrganizationStructureDto;
  usedNames: string[];
  usedSigns: string[];
};

export const OrganizationStructureForm: FC<Props> = ({ selectedStructureNode, usedNames = [], usedSigns = [] }) => {
  const intl = useIntl();
  const namesToCompare = useMemo(() => {
    return usedNames.filter((name) => name !== selectedStructureNode?.name);
  }, [selectedStructureNode, usedNames]);

  const signsToCompare = useMemo(() => {
    return usedSigns.filter((name) => name !== selectedStructureNode?.sign);
  }, [selectedStructureNode, usedSigns]);

  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: 'general.sign' })}
        name="sign"
        initialValue={selectedStructureNode?.sign}
        rules={[
          requiredRule('forms.items.rules.required', true),
          maxLengthRule('general.maxNameLength', apiConstraints.organizationStructureDto.sign.maxLength),
          duplicateNameRule('forms.items.name.rules.nameExists', namesToCompare, true),
        ]}
      >
        <Input maxLength={apiConstraints.assignmentCreateDto.name.maxLength} />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'general.name' })}
        name="name"
        initialValue={selectedStructureNode?.name}
        rules={[
          requiredRule('forms.items.rules.required', true),
          maxLengthRule('general.maxNameLength', apiConstraints.organizationStructureDto.name.maxLength),
          duplicateNameRule('forms.items.name.rules.nameExists', signsToCompare, true),
        ]}
      >
        <Input maxLength={apiConstraints.assignmentCreateDto.name.maxLength} />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'general.description' })}
        name="description"
        initialValue={selectedStructureNode?.description}
      >
        <Input.TextArea maxLength={apiConstraints.organizationStructureDto.description.maxLength} showCount />
      </Form.Item>
      <Form.Item name="id" initialValue={selectedStructureNode?.id} hidden></Form.Item>
      <Form.Item name="order" initialValue={selectedStructureNode?.order} hidden></Form.Item>
    </>
  );
};
