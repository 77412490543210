import { InputRef } from 'antd';
import { api } from 'api';
import { ApiPromise } from 'api/await-to';
import { DocumentContentDto, DocumentCreateDto, WorkflowStateEnum } from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import { checkUploadAccessDeniedError, injectDocumentIdsToCreateDto } from 'api/project/upload/uploadHelpers';
import { UploadData, UploadFile, UploadFileType, UploadProcessData } from 'api/project/upload/uploadManager';
import { PreferredDirectory } from 'components/DirectoriesTreeSelect/DirectoriesPreferredSelect';
import { NoApplicableNewDocumentStateAlert } from 'components/NoApplicableNewDocumentAlert/NoApplicableNewDocumentStateAlert';
import { useIntl } from 'hooks';
import { useFileUpload } from 'hooks/useFileUpload';
import { useFocus } from 'hooks/useFocus';
import { useNewDocumentState } from 'hooks/useNewDocumentState';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { IntlMessageId } from 'locale/messages/cs';
import { trim } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { v4 as uuid } from 'uuid';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler, parseErrorMessage } from '../FormModalWrapper';
import DocumentCreateForm, { DocumentCreateFormData } from './DocumentCreateForm';

export type CreateDocumentUploadData<T> = (
  data: DocumentCreateFormData
) => {
  createSaveRequest: (data: UploadProcessData<T>) => ApiPromise<T>;
  temporary?: boolean;
};

export const documentCreateFormDataToCreateDto = (data: DocumentCreateFormData): DocumentCreateDto => ({
  directoryId: data.destinationDirectory,
  name: data.name,
  description: data.description,
  ownedById: data.ownedById,
  state: data.state,
  categoryTreeNodes: data.categories.reduce(
    (previousValue, currentValue) => ({ ...previousValue, [currentValue.categoryId]: currentValue.nodeId }),
    {}
  ),
  labels: data.labels || [],
  isModel: false, // Models can only be created using the multi upload form
  secondaryDocumentsAdd: null,
  uploadId: null,
  metaData: undefined,
});

export const createDocumentUploadData: CreateDocumentUploadData<DocumentContentDto> = (data) => {
  const createDto = documentCreateFormDataToCreateDto(data);
  return {
    createSaveRequest: (data) =>
      api.project.documents.createDocument(injectDocumentIdsToCreateDto(createDto, data), data.ctSource.token),
  };
};

type Props<T> = FormModalProps<DocumentCreateFormData, T> & {
  titleId?: IntlMessageId;
  showDestinationDirectory?: boolean;
  directoryId?: Guid;
  preferredDirectories?: PreferredDirectory[];
  initialPrimaryFile?: File;
  validateUniqueName: (name: string) => boolean;
  allowedStates?: WorkflowStateEnum[];
  createUploadData: CreateDocumentUploadData<T>;
  uploadDisabled?: boolean;
  canAddSignedDocument?: boolean;
};

export const DocumentCreateFormModal = <T,>(props: Props<T>): JSX.Element => {
  const {
    open,
    onSubmit,
    onClose,
    directoryId,
    preferredDirectories,
    initialPrimaryFile,
    validateUniqueName,
    allowedStates,
    createUploadData,
    titleId,
    showDestinationDirectory,
    uploadDisabled,
    canAddSignedDocument = true,
  } = props;

  const dispatch = useDispatch<Dispatch>();
  const intl = useIntl();

  useEffect(() => {
    dispatch.categories.loadData({ reload: false });
    dispatch.directories.loadData({ reload: false });
  }, [dispatch]);

  useDirtyStoreReload(
    (store) => store.directories,
    (dispatch) => dispatch.directories
  );

  useDirtyStoreReload(
    (store) => store.categories,
    (dispatch) => dispatch.categories
  );

  useDirtyStoreReload(
    (store) => store.projectDirectoryMasks,
    (dispatch) => dispatch.projectDirectoryMasks
  );

  const { lastUploadManager, error, loading, submitTextId, uploadProgress, resetAll, startUpload } = useFileUpload();

  const handleUploadFinish = useCallback(
    async (values: DocumentCreateFormData, document: T): Promise<void> => {
      await onSubmit(values, document);
      resetAll();
    },
    [resetAll, onSubmit]
  );

  const handleUploadError = useCallback(
    (error: ApiError) => {
      if (!error) return null;
      const accessDeniedError = checkUploadAccessDeniedError(error, intl);
      if (!!accessDeniedError) return accessDeniedError;
      return parseErrorMessage(error);
    },
    [intl]
  );

  const handleSubmit: FormSubmitHandler<DocumentCreateFormData> = useCallback(
    async (_values) => {
      const values = { ..._values, name: trim(_values.name) };

      const secondaryFiles = values.secondaryFiles?.fileList || [];
      const signedDocument = values.signedDocument;

      const uploadData: UploadData<T> = {
        ...createUploadData(values),
        onFinish: async (response) => {
          await handleUploadFinish(values, response);
        },
      };

      const uploadFiles: UploadFile[] = [
        {
          id: uuid(),
          fileName: values.primaryFile.name,
          blob: values.primaryFile,
          fileType: UploadFileType.primaryFile,
        },
        signedDocument && {
          id: uuid(),
          fileName: signedDocument.name,
          blob: signedDocument,
          fileType: UploadFileType.signedDocument,
        },
        ...secondaryFiles.map(
          (file): UploadFile => {
            const blob = file.originFileObj;
            return {
              id: uuid(),
              fileName: blob instanceof File ? blob.name : file.name,
              blob: file.originFileObj,
              fileType: UploadFileType.attachment,
            };
          }
        ),
      ].filter((file) => !!file);

      startUpload(uploadData, uploadFiles);

      return null;
    },
    [handleUploadFinish, createUploadData, startUpload]
  );

  const handleClose = useCallback(() => {
    resetAll();
    onClose();
  }, [resetAll, onClose]);

  const { setInputRef } = useFocus<InputRef>(open);

  const { newDocumentUsefulStates, handleNoUsefulStatusesAlertClose } = useNewDocumentState(onClose);

  return (
    <>
      {open && !newDocumentUsefulStates?.length && (
        <NoApplicableNewDocumentStateAlert
          open={!newDocumentUsefulStates?.length}
          handleAlertClose={handleNoUsefulStatusesAlertClose}
        />
      )}
      <FormModalWrapper
        open={open && !!newDocumentUsefulStates?.length}
        progress={uploadProgress}
        showProgress={lastUploadManager.current !== null}
        errorMessage={handleUploadError(error)}
        onSubmit={handleSubmit}
        submitTextId={submitTextId}
        onSubmitDisabled={uploadDisabled}
        onClose={handleClose}
        forceLoading={loading}
        titleId={titleId || 'DocumentCreateFormModal.title'}
        width={800}
      >
        <DocumentCreateForm
          initialPrimaryFile={initialPrimaryFile}
          validateUniqueName={validateUniqueName}
          showDestinationInput={showDestinationDirectory}
          destinationDirectory={directoryId}
          preferredDirectories={preferredDirectories}
          allowedStates={allowedStates}
          setRef={setInputRef}
          usefulNewDocumentStatuses={newDocumentUsefulStates}
          allowSignedDocumentAttachment={canAddSignedDocument}
        />
      </FormModalWrapper>
    </>
  );
};
