import { DatePicker } from 'antd';

import { RangePickerProps } from 'antd/es/date-picker';
import { JSONVariableTypeEnum } from 'api/completeApiInterfaces';
import { DateRangeValue } from 'components/InlineDateRangePicker/InlineDateRangePicker';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { JSONVariableDateRange } from '../JSONVariableTypes';
import styles from './JSONVariableDateRangePickerFormItem.module.less';

export type JSONVariableDateRangeProps = {
  dataType: JSONVariableTypeEnum.interval;
  value?: JSONVariableDateRange;
  onChange?: (value: JSONVariableDateRange) => void;
} & Omit<RangePickerProps, 'value' | 'onChange'>;

const JSONVariableDateRangePickerFormItem: FunctionComponent<JSONVariableDateRangeProps> = ({
  dataType,
  value,
  onChange,
  ...restProps
}) => {
  const handleChange = useCallback(
    (dates: DateRangeValue) => {
      onChange &&
        onChange({
          type: dataType,
          valueFrom: (dates && dates[0]?.toISOString()) || null,
          valueTo: (dates && dates[1]?.toISOString()) || null,
        });
    },
    [onChange]
  );
  const rangeValue = useMemo(
    (): DateRangeValue =>
      value && [value.valueFrom ? moment(value.valueFrom) : null, value.valueTo ? moment(value.valueTo) : null],
    [value]
  );
  return (
    <DatePicker.RangePicker
      onChange={handleChange}
      value={rangeValue}
      format="L"
      picker="date"
      {...restProps}
      popupClassName={styles.responsiveCalendar}
    />
  );
};

export default React.memo(JSONVariableDateRangePickerFormItem);
