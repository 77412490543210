import { DownOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Space } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { Fmt } from 'locale';
import React, { FunctionComponent, useMemo } from 'react';

type Props = {
  onSave: () => void;
  onCancel: () => void;
  disabled?: boolean;
  saveDisabled?: boolean;
  loading?: boolean;
};

export const SaveOrCancelButton: FunctionComponent<Props> = ({ onSave, onCancel, disabled, saveDisabled, loading }) => {
  const menu = useMemo((): MenuProps => {
    const items: ItemType[] = [
      {
        key: 'cancel',
        onClick: () => onCancel(),
        label: <Fmt id="general.cancelChanges" />,
      },
    ];
    return { items };
  }, [onCancel]);

  return (
    <Space.Compact>
      <Button
        icon={<SaveOutlined />}
        type="primary"
        disabled={disabled || saveDisabled}
        onClick={onSave}
        loading={loading}
      >
        <Fmt id="general.save" />
      </Button>
      <Dropdown menu={menu} disabled={disabled || loading} placement="bottomRight">
        <Button type="primary" icon={<DownOutlined />} />
      </Dropdown>
    </Space.Compact>
  );
};
