import { AccessLevelEnum, DirectoryDto, DocumentContentDto, ProjectUserProfileDto } from 'api/completeApiInterfaces';

export const checkDirectoryWriteAccess = (accessLevel: AccessLevelEnum): boolean =>
  accessLevel === AccessLevelEnum.admin || accessLevel === AccessLevelEnum.write;

export const checkDirectoryReadAccess = (accessLevel: AccessLevelEnum): boolean =>
  accessLevel === AccessLevelEnum.admin ||
  accessLevel === AccessLevelEnum.write ||
  accessLevel === AccessLevelEnum.read;

export const isDocumentNotEditableByMe = (
  document: DocumentContentDto,
  directory: DirectoryDto,
  currentUser: ProjectUserProfileDto
) => {
  return (
    (!!document.reservedDate && currentUser.id !== document.reservedBy.id) ||
    !directory ||
    !checkDirectoryWriteAccess(directory.currentAccessLevel)
  );
};

export const isCreateRevisionDisabled = (
  document: DocumentContentDto,
  directory: DirectoryDto,
  currentUser: ProjectUserProfileDto
) => {
  return isDocumentNotEditableByMe(document, directory, currentUser);
};
