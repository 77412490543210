import { Form, Input } from 'antd';
import { apiConstraints } from 'api/completeApiConstraints';
import { ExtendedPermissionEnum, ExtendedPermissionTemplateDto } from 'api/completeApiInterfaces';
import { useIntl } from 'hooks';
import { PermissionsDrawerFormItem } from 'pages/ProjectSettingsPage/Users/UserDetailPanel/PermissionsDrawerFormItem';
import { AutoSizeType } from 'rc-textarea/lib/ResizableTextArea';
import React, { FC, useMemo } from 'react';
import { duplicateNameRuleCallback, maxLengthRule, requiredRule } from 'utils/formHelpers';

export type UserPermissionProfileFormData = {
  name: string;
  description: string;
  activePermissions: ExtendedPermissionEnum[];
};

const DESCRIPTION_AUTOSIZE: AutoSizeType = { minRows: 2 };

type Props = {
  validateUniqueName: (name: string) => boolean;
  defaults?: ExtendedPermissionTemplateDto;
};

const UserPermissionProfileFormComponent: FC<Props> = ({ validateUniqueName, defaults }) => {
  const intl = useIntl();

  const defaultExtendedPermissions = useMemo(
    () =>
      defaults?.extendedPermissions.settings
        .filter((setting) => setting.permission)
        .map((setting) => setting.permissionType) || [ExtendedPermissionEnum.stavba],
    [defaults]
  );

  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.name.label' })}
        name="name"
        initialValue={defaults?.name}
        rules={[
          requiredRule('forms.items.name.rules.required', true),
          maxLengthRule('general.maxNameLength', apiConstraints.projectTemplateDto.name.maxLength),
          duplicateNameRuleCallback(validateUniqueName),
        ]}
      >
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.description.label' })}
        name="description"
        initialValue={defaults?.description}
        rules={[
          {
            whitespace: true,
            message: intl.formatMessage({ id: 'forms.items.name.rules.empty' }),
          },
          maxLengthRule('general.maxDescriptionLength', apiConstraints.projectTemplateDto.description.maxLength),
        ]}
      >
        <Input.TextArea rows={2} autoSize={DESCRIPTION_AUTOSIZE} />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.userPermissionProfile.label' })}
        name="activePermissions"
        initialValue={defaultExtendedPermissions}
      >
        <PermissionsDrawerFormItem />
      </Form.Item>
    </>
  );
};

export const UserPermissionProfileForm = React.memo(UserPermissionProfileFormComponent);
