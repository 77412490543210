import { Form, Input, InputRef } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { LabelDto, ServiceError } from 'api/completeApiInterfaces';
import classNames from 'classnames';
import ColorPickerInput from 'components/ColorPickerInput';
import DisplayName from 'components/DisplayName';
import Label from 'components/Label';
import ServiceErrorBox from 'components/ServiceErrorBox';
import { useIntl } from 'hooks';
import randomColor from 'randomcolor';
import React, { FC, useEffect, useState } from 'react';
import { duplicateNameRuleCallback, requiredRule } from 'utils/formHelpers';
import styles from './LabelsListItemForm.module.less';

export type LabelCreateFormData = {
  name: string;
  description: string;
  color: string;
};

type Props = {
  defaults?: LabelDto;
  validateUniqueName: (name: string) => boolean;
  setRef: (ref: InputRef) => void;
};

const LabelsListItemForm: FC<Props> = ({ defaults, validateUniqueName, setRef }) => {
  const intl = useIntl();
  const [error, setError] = useState<ServiceError>(null);
  const [name, setName] = useState<string>(defaults ? defaults.name : '');
  const [color, setColor] = useState<string>(defaults ? defaults.color : (randomColor() as string));

  const form = useFormInstance();

  useEffect(() => {
    if (defaults) {
      form.setFieldsValue({ ...defaults });
    }
  }, []);

  return (
    <div className={styles.listItem}>
      {error && <ServiceErrorBox error={error} className={styles.hbox} />}
      <div className={styles.hbox}>
        <Label color={color}>
          {name ? <DisplayName text={name} /> : intl.formatMessage({ id: 'LabelsForm.preview' })}
        </Label>
      </div>
      <Form form={form} layout="vertical" labelAlign="left" className={styles.form}>
        <Form.Item
          label={intl.formatMessage({ id: 'LabelsForm.form.items.color.label' })}
          className={classNames(styles.item)}
          name="color"
          initialValue={color}
        >
          <ColorPickerInput onChange={setColor} />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'forms.items.name.label' })}
          className={classNames(styles.item, styles.name)}
          name="name"
          initialValue={name}
          rules={[requiredRule('forms.items.name.rules.required', true), duplicateNameRuleCallback(validateUniqueName)]}
        >
          <Input
            placeholder={intl.formatMessage({ id: 'LabelsForm.form.items.name.placeholder' })}
            onChange={(e) => setName(e.target.value)}
            maxLength={30}
            autoFocus
            ref={setRef}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'forms.items.description.label' })}
          className={classNames(styles.item, styles.description)}
          name="description"
          initialValue={defaults ? defaults.description : ''}
        >
          <Input
            placeholder={intl.formatMessage({ id: 'LabelsForm.form.items.description.placeholder' })}
            maxLength={1000}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default LabelsListItemForm;
