import { LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { Badge, Button, MenuProps } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { AppUserProfileDto } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { HeaderDropdown } from 'components/HeaderDropdown';
import { useLocaleMenu } from 'components/HeaderLocaleDropdown/useLocaleMenu';
import { NotificationContext } from 'components/HeaderNotificationDropdown/NotificationContextProvider';
import { useNotificationMenuItems } from 'components/HeaderNotificationDropdown/useNotificationMenu';
import { UserAvatarSize, UserIcon } from 'components/avatars/UserIcon/UserIcon';
import { OIDC_PROVIDER } from 'config/env';
import { Fmt } from 'locale';
import { LanguageEnum } from 'locale/messages/interfaces';
import React, { FunctionComponent, useContext, useMemo } from 'react';
import { history } from 'store';
import styles from './HeaderUserDropdown.module.less';

export type HeaderUserDropdownProps = {
  user: AppUserProfileDto;
  className?: string;
  onLocaleChange: (locale: LanguageEnum) => void;
  selectedLocale: LanguageEnum;
};

const HeaderUserDropdown: FunctionComponent<HeaderUserDropdownProps> = ({
  user,
  className,
  onLocaleChange,
  selectedLocale,
}) => {
  const identitySettingsUrl = useMemo(() => {
    const url = new URL(OIDC_PROVIDER);
    url.searchParams.append('returnUrl', window.location.href.split('?')[0]);
    return url.toString();
  }, [window.location.href]);

  const notificationContext = useContext(NotificationContext);

  const totalActiveAssignments =
    notificationContext.totalNewAssignments +
    notificationContext.totalInProgressAssignments +
    notificationContext.totalForApprovalAssignments;

  const notificationsMenu = useNotificationMenuItems(notificationContext?.totalNewMessages, totalActiveAssignments);
  const localeMenu = useLocaleMenu(selectedLocale, onLocaleChange);

  const menu = useMemo((): MenuProps => {
    const items: ItemType[] = [
      {
        key: 'userInfo',
        label: (
          <div className={styles.userInfo}>
            <div className={styles.userIcon}>
              <UserIcon size={UserAvatarSize.ExtraLarge} user={user} />
            </div>
            {(user.firstname || user.lastname) && (
              <div className={styles.username}>
                <strong>
                  {user?.firstname || ''} {user?.lastname || ''}
                </strong>
              </div>
            )}
            <div className={styles.username}>{user.username}</div>
          </div>
        ),
        className: styles.userInfoSection,
      },
      { type: 'divider' },
      ...notificationsMenu,
      { type: 'divider' },
      ...localeMenu,
      { type: 'divider' },
      {
        key: 'settings',
        label: <Fmt id="general.settings.profile" />,
        icon: <SettingOutlined />,
        onClick: () => (window.location.href = identitySettingsUrl),
      },
      {
        key: 'logout',
        label: <Fmt id="general.logout" />,
        icon: <LogoutOutlined />,
        onClick: () => history.push('/out?force'),
      },
    ];
    return { items };
  }, [identitySettingsUrl, localeMenu, notificationsMenu, user]);

  const tooltipTitle = (
    <>
      <Fmt id="general.userName" />
      <div>{user.username}</div>
      <Fmt id="general.messages" />
      <div>{notificationContext?.totalNewMessages}</div>
    </>
  );

  return (
    <HeaderDropdown menu={menu} placement="bottomRight" trigger={['click']} overlayClassName={styles.wrapper}>
      <CommonHubTooltip placement="left" title={tooltipTitle}>
        <Badge size="small" count={notificationContext?.totalNewMessages} title="" className={styles.badge}>
          <Button type="link">
            <UserIcon user={user} size={UserAvatarSize.Medium} />
          </Button>
        </Badge>
      </CommonHubTooltip>
    </HeaderDropdown>
  );
};

export default HeaderUserDropdown;
