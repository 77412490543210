import { message } from 'antd';
import { masterApi } from 'api/completeApi';
import { EntityTypesEnum, MdDivisionDto, MdRoleDto, MdRoleEnum } from 'api/completeApiInterfaces';
import { AuditLogButton, EditButton } from 'components/ActionButtons';
import { DeleteButtonConfirm } from 'components/ActionButtons/DeleteButtonConfirm';
import AuditLogMasterApiEntityModal from 'components/AuditLogsComponents/AuditLogMasterApiEntityModal';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import StackPanel from 'components/StackPanel';
import { useBoolean, useIntl } from 'hooks';
import { Fmt, InjectedIntlProps } from 'locale';
import Panel from 'pages/ProjectSettingsPage/Panel/Panel';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { messageError, smartFilter } from 'utils';
import MDRoleFormModal from './MDRoleFormModal';

type Props = InjectedIntlProps & {
  divisions: MdDivisionDto[];
  roles: MdRoleDto[];
  setRoles: (roles: MdRoleDto[]) => void;
};

type MDRoleParams = {
  roleName: MdRoleEnum;
};

const MDRoleDetailPanel: FunctionComponent<Props> = ({ divisions, roles, setRoles }) => {
  const [search, setSearch] = useState<string>('');
  const [roleModalVisible, showRoleModal, hideRoleModal] = useBoolean(false);
  const [selectedRole, setSelectedRole] = useState<MdRoleDto>();
  const [toShowActivityMdRole, setToShowActivityMdRole] = useState<MdRoleDto>();

  const { url } = useRouteMatch();
  const intl = useIntl();
  const { roleName } = useParams<MDRoleParams>() as { roleName: MdRoleEnum };
  const headDivisionUsers = useMemo(
    () => divisions?.flatMap((division) => division.mdUsers.filter((divisionUser) => divisionUser.isHead)) || [],
    [divisions]
  );

  const selectedRoles = useMemo(() => roles?.filter((role) => role.mdRoleType === roleName) || [], [roles, roleName]);
  const existingRoleLeaderIds = useMemo(() => selectedRoles.map((role) => role.head.id), [selectedRoles]);

  const usedRoleUsers = useMemo(() => {
    if (selectedRoles.some((role) => role.mdRoleType === MdRoleEnum.proposer)) {
      return selectedRoles.flatMap((role) => role.mdUsers);
    }
    return [];
  }, [selectedRoles]);

  const handleRoleDelete = useCallback(
    async (deletedRoleId: Guid) => {
      const [err, res] = await masterApi.projects.md.role.delete.id.delete(deletedRoleId);
      if (err) {
        messageError(err, intl);
      } else {
        void message.success(intl.formatMessage({ id: 'MD.Organization.roleUser.delete.success' }));
        setRoles(res.data);
      }
    },
    [intl, setRoles]
  );

  const handleRoleEdit = useCallback(
    (editedRoleId: Guid) => {
      setSelectedRole(roles.find((role) => role.id === editedRoleId));
      showRoleModal();
    },
    [roles]
  );

  const handleRolesChange = useCallback(
    (updatedRoles: MdRoleDto[]) => {
      setSelectedRole(undefined);
      setRoles(updatedRoles);
      hideRoleModal();
    },
    [hideRoleModal, setRoles]
  );

  const handleRoleModalClose = useCallback(() => {
    setSelectedRole(undefined);
    hideRoleModal();
  }, [hideRoleModal]);

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const availableDivisions = useMemo(
    () => divisions?.filter((division) => (roleName === MdRoleEnum.O910 ? division.name === 'O910' : true)) || [],
    [divisions, roleName]
  );

  const hideAuditLog = useCallback(() => {
    setToShowActivityMdRole(undefined);
  }, []);

  return (
    <>
      <MasterComponent
        url={url}
        title={intl.formatMessage({ id: 'MD.Organization.roleUsers.panel.title' })}
        children={() => (
          <StackPanel>
            <Panel
              noMargin
              addButtonOnClick={showRoleModal}
              addButtonText={<Fmt id="MD.Organization.roleUsers.panel.add" />}
              addButtonDisabled={roleName === MdRoleEnum.supervisor}
              onSearch={setSearch}
              searchValue={search}
            >
              <GeneralSettingsContainer>
                <List<MdRoleDto>
                  data={selectedRoles}
                  search={search}
                  filterItem={(item) => item && smartFilter(item?.head.orgUser.appUserProfile.username, search)}
                  renderItem={(item) => (
                    <GeneralSettingsItem
                      key={item.id}
                      title={item.head.orgUser.appUserProfile.username}
                      wrap
                      description={item.mdUsers.map((user) => user.orgUser.appUserProfile.username).join(', ')}
                      additionalActions={
                        <>
                          <EditButton onClick={() => handleRoleEdit(item.id)} />
                          <DeleteButtonConfirm
                            onConfirm={() => handleRoleDelete(item.id)}
                            disabled={
                              item.mdRoleType === MdRoleEnum.supervisor &&
                              headDivisionUsers.some((divisionUser) => divisionUser.id === item.head.id)
                            }
                            confirmTooltip={<Fmt id="MD.Organization.roles.panel.delete.confirm.tooltip" />}
                          />
                          <AuditLogButton key="auditLogButton" onClick={() => setToShowActivityMdRole(item)} />{' '}
                        </>
                      }
                    />
                  )}
                  renderEmpty={(total, filtered) => (
                    <ListEmpty filtered={filtered} total={total} onClearSearch={clearSearch} />
                  )}
                />
              </GeneralSettingsContainer>
            </Panel>
          </StackPanel>
        )}
      />
      <MDRoleFormModal
        onSubmit={handleRolesChange}
        onClose={handleRoleModalClose}
        editedRole={selectedRole}
        headDivisionUsers={headDivisionUsers}
        open={roleModalVisible}
        divisions={availableDivisions}
        roleType={roleName}
        usedRoleUsers={usedRoleUsers}
        existingRoleLeaderIds={existingRoleLeaderIds}
      />
      {!!toShowActivityMdRole && (
        <AuditLogMasterApiEntityModal
          visible={!!toShowActivityMdRole}
          label={<Fmt id="AuditLog.mdRole.label" />}
          entityId={toShowActivityMdRole.id}
          entityType={EntityTypesEnum.mdRole}
          onOk={hideAuditLog}
          deps={[toShowActivityMdRole]}
        />
      )}
    </>
  );
};

export default React.memo(MDRoleDetailPanel);
