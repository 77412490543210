import { CalculatorOutlined, OrderedListOutlined, RetweetOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { EstiCategoryEnum, WorkflowStateEnum } from 'api/completeApiInterfaces';
import classNames from 'classnames';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { InvoicingIcon, ScheduleIcon } from 'components/Icons/HubEntitiesIcons';
import { useActiveProject } from 'hooks';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { DisabledWithReason } from 'utils/types';
import styles from './EsticonObjectGridLink.module.less';

type EsticonLinkData = {
  icon: ReactNode;
  tooltip: IntlMessageId;
  link: string;
};

// TODO-ST: Add links to esticon grid for supported object types
// TODO: grouped invoice and drawing?

export const checkEsticonObjectDisabled = (documentState: WorkflowStateEnum): boolean =>
  documentState !== WorkflowStateEnum.published && documentState !== WorkflowStateEnum.shared;

type Props = {
  objectType: EstiCategoryEnum;
  esticonObjectId: Guid;
  disabled?: DisabledWithReason;
  className?: string;
  tooltipPlacement?: TooltipPlacement;
  isPartial?: boolean;
};

export const EsticonObjectGridLink: FunctionComponent<Props> = ({
  objectType,
  esticonObjectId,
  disabled,
  className,
  tooltipPlacement,
  isPartial,
}) => {
  const project = useActiveProject();
  const esticonTypeToLinkData: Record<EstiCategoryEnum, EsticonLinkData> = useMemo(
    () => ({
      [EstiCategoryEnum.Stavba]: {
        icon: <OrderedListOutlined />,
        tooltip: 'general.budget',
        link: 'construction/budget/focus/{esticonObjectId}',
      },
      [EstiCategoryEnum.Cerpani]: {
        icon: <CalculatorOutlined />,
        tooltip: isPartial ? 'general.PartialWiP' : 'general.drawing',
        link: 'construction/drawing/focus/{esticonObjectId}',
      },
      [EstiCategoryEnum.CerpaniSdruzeni]: {
        icon: <CalculatorOutlined />,
        tooltip: 'general.drawing',
        link: 'construction/drawing/focus/{esticonObjectId}',
      },
      [EstiCategoryEnum.ZBV]: {
        icon: <RetweetOutlined />,
        tooltip: 'general.ZBV',
        link: 'construction/zbv/focus/{esticonObjectId}',
      },
      [EstiCategoryEnum.Fakturace]: {
        icon: <InvoicingIcon />,
        tooltip: isPartial ? 'general.PartialInvoice' : 'general.invoicing',
        link: 'construction/invoicing/focus/{esticonObjectId}',
      },
      [EstiCategoryEnum.FakturaSdruzeni]: {
        icon: <InvoicingIcon />,
        tooltip: 'general.invoicing',
        link: 'construction/invoicing/focus/{esticonObjectId}',
      },
      [EstiCategoryEnum.Harmonogram]: { icon: <ScheduleIcon />, tooltip: 'general.schedule', link: 'schedule' },
    }),
    [isPartial]
  );
  const linkData = esticonTypeToLinkData[objectType];

  return (
    <CommonHubTooltip
      title={typeof disabled === 'string' ? disabled : <Fmt id={linkData.tooltip} />}
      placement={tooltipPlacement}
    >
      {!disabled && linkData.link ? (
        <Link to={`/projects/${project.id}/${linkData.link.replace('{esticonObjectId}', esticonObjectId)}`}>
          <Button type="ghost" shape="circle" size="small" className={classNames(styles.gridLinkIcon, className)}>
            <span>{linkData.icon}</span>
          </Button>
        </Link>
      ) : (
        <Button
          type="ghost"
          shape="circle"
          size="small"
          className={classNames(styles.gridLinkIcon, className)}
          disabled={!!disabled}
        >
          <span>{linkData.icon}</span>
        </Button>
      )}
    </CommonHubTooltip>
  );
};
