import { message } from 'antd';
import { api } from 'api';
import { ServiceErrorEnum } from 'api/errors';
import { transformError } from 'components/DocumentMultipleActionError';
import {
  DocumentsMultipleActionObstaclesEnum,
  IMultipleDocumentErrorData,
} from 'components/DocumentMultipleActionError/DocumentMultipleActionError';
import { useIntl } from 'hooks';
import { Fmt, InjectedIntlProps } from 'locale';
import { canDiscardDirectory, directoryDeleteDisabledByEsticon } from 'pages/AllDocumentsPage/AllDocumentsPage';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { processApiError } from 'utils';
import { ConnectedDirectory } from 'utils/typeMappings/directories/directoryTypes';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import DocumentsDiscardForm from './DocumentsDiscardForm';

type Props = InjectedIntlProps &
  FormModalProps<void, ConnectedDirectory> & {
    selectedDirectory: ConnectedDirectory;
  };

const DirectoryDiscardFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, onClose, selectedDirectory, ...restProps } = props;
  const [errors, setErrors] = useState<JSX.Element[]>([]);
  const [isLink, setIsLink] = useState<boolean>(false);

  const handleSubmit: FormSubmitHandler = async () => {
    const [err] = await api.project.directories.discardDirectory(selectedDirectory.id, isLink);
    if (err) {
      processApiError(err, (error) => {
        if (error != null && !!error.errorData) {
          if (error.referenceErrorCode === ServiceErrorEnum.DocumentMultipleDiscardObstacleError) {
            const errData = error.errorData as IMultipleDocumentErrorData;
            setErrors(errData.documentsErrors.map(transformError));
            const linkOnly = errData.documentsErrors.every(
              (doc) => doc.documentsObstacles === DocumentsMultipleActionObstaclesEnum.HasDocToDocLink
            );
            setIsLink(linkOnly);
            return null;
          }
        }
        message.error(props.intl.formatMessage({ id: `serviceError.${error.referenceErrorCode}` }));
      });
      return null;
    }
    onSubmit(undefined, selectedDirectory);
    resetDialog();
    return null;
  };

  const handleClose = () => {
    resetDialog();
    onClose();
  };

  const resetDialog = () => {
    setErrors([]);
    setIsLink(false);
  };

  const intl = useIntl();
  const discardDisabled: string = useMemo(
    () =>
      (!canDiscardDirectory(selectedDirectory) &&
        intl.formatMessage({
          id: 'AllDocumentsPage.docMenu.discard.disabled.byRights',
        })) ||
      (directoryDeleteDisabledByEsticon(selectedDirectory) &&
        intl.formatMessage({
          id: 'AllDocumentsPage.docMenu.discard.disabled.byEsticon',
        })) ||
      undefined,
    [selectedDirectory, intl]
  );

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      onClose={handleClose}
      titleId="DiscardDirectory.Modal.title"
      confirmClose={false}
      errorMessage={discardDisabled}
      onSubmitDisabled={!!discardDisabled}
      layout="horizontal"
      // TODO: form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
      {...restProps}
      submitTextId={
        isLink ? 'DocumentsDiscardFormModal.button.DiscardLinks' : 'DocumentsDiscardFormModal.button.Discard'
      }
    >
      <DocumentsDiscardForm
        title={<Fmt id={isLink ? 'DiscardDirectory.Modal.description.links' : 'DiscardDirectory.Modal.description'} />}
        errors={errors}
        errorsTextId={'DocumentsDiscardForm.errorsTitle.missed'}
      />
    </FormModalWrapper>
  );
};

export default injectIntl(DirectoryDiscardFormModal);
