import { apiConstraints } from 'api/completeApiConstraints';
import { CommonEditable } from 'components/CommonEditableName/CommonEditable';
import React, { FunctionComponent } from 'react';

type Props = {
  canEdit: boolean;
  assignment: { name: string };
  onSave?: (name: string) => Promise<void>;
};

export const AssignmentNameField: FunctionComponent<Props> = ({ canEdit, assignment, onSave }) => {
  return (
    <CommonEditable
      value={assignment.name}
      onSave={onSave}
      disabled={!canEdit}
      maxLength={apiConstraints.assignmentPatchDto.name.maxLength}
      showErrorOnEmpty
    />
  );
};
