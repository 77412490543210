import { api } from 'api';
import { RevisionDto } from 'api/completeApiInterfaces';
import { AnnotationData } from 'components/PdfAnnotation/PdfAnnotation';
import { AnnotationModeEnum, PdfAnnotationModal } from 'components/PdfAnnotation/PdfAnnotationModal';
import { useApiData, useBoolean, useIntl } from 'hooks';
import React, { FunctionComponent, ReactNode, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { messageError } from 'utils';

type RevisionData = {
  revision?: RevisionDto;
  parentDocumentId?: Guid;
  highlightedAnnotationId?: Guid;
};

export type AnnotationEditorSettings = {
  annotationMode?: AnnotationModeEnum;
  canAddRevision?: boolean;
  canEditOwnAnnotations?: boolean;
  canEditOthersAnnotations?: boolean;
  canReplyToAnnotations?: boolean;
  additionalButtons?: ReactNode;
};

type ValueType = {
  show: (
    revisionData: RevisionData,
    annotationSettings?: AnnotationEditorSettings,
    workflowNodeId?: Guid,
    workflowId?: Guid
  ) => void;
  hide: () => void;
  setRevisionData: (revision: RevisionDto, parentDocumentId: Guid) => void;
  setEditorSettings: (editorSettings: AnnotationEditorSettings) => void;
  revision: RevisionDto;
  parentDocumentId: Guid;
  hasLicense: boolean;
  pdfTronLicenseKey?: string;
  newDocumentAnnotations: AnnotationData[];
  changedDocumentAnnotations: AnnotationData[];
  setAnnotationModalDisablerDeactivated: (deactivate: boolean) => void;
};

type Props = {};

type WorkflowNodeIdentification = {
  workflowId: Guid;
  workflowNodeId: Guid;
};

export const AnnotationModalContext = createContext<ValueType>(undefined);

const AnnotationModalContextProvider: FunctionComponent<Props> = ({ children }) => {
  const intl = useIntl();
  const [revision, setRevision] = useState<RevisionDto>(undefined);
  const [workflowNodeIdentification, setWorkflowNodeIdentificaiton] = useState<WorkflowNodeIdentification>(undefined);
  const [parentDocumentId, setParentDocumentId] = useState<Guid>(undefined);
  const [highlightedAnnotationId, setHighlightedAnnotationId] = useState<Guid>(undefined);
  const [annotationMode, setAnnotationMode] = useState<AnnotationModeEnum>(
    AnnotationModeEnum.commentProcedureAnnotation
  );
  const [canAddRevision, setCanAddRevision] = useState<boolean>(false);
  const [canEditOwnAnnotations, setCanEditOwnAnnotations] = useState<boolean>(false);
  const [canEditOthersAnnotations, setCanEditOthersAnnotations] = useState<boolean>(false);
  const [canReplyToAnnotations, setCanReplyToAnnotations] = useState<boolean>(false);
  const [additionalButtons, setAdditionalButtons] = useState<ReactNode>(undefined);
  const [isVisible, setIsVisible, hide] = useBoolean(false);
  const [annotationModalDisablerDeactivated, setAnnotationModalDisablerDeactivated] = useState<boolean>(false);

  const [newDocumentAnnotations, setNewDocumentAnnotations] = useState<AnnotationData[]>([]);
  const [changedDocumentAnnotations, setChangedDocumentAnnotations] = useState<AnnotationData[]>([]);

  const [pdfTronLicenseKey, pdfTronLicenseKeyError, _pdfTronLicenseKeyLoading] = useApiData(
    (ct) => api.master.pdfTron.getLicenceKey(ct),
    {
      autoload: true,
    }
  );

  const setEditorSettings = useCallback(
    ({
      annotationMode,
      canAddRevision,
      canEditOwnAnnotations,
      canEditOthersAnnotations,
      canReplyToAnnotations,
      additionalButtons,
    }: AnnotationEditorSettings = {}) => {
      setAnnotationMode(!!annotationMode ? annotationMode : AnnotationModeEnum.commentProcedureAnnotation);
      setCanAddRevision(!!canAddRevision);
      setCanEditOwnAnnotations(!!canEditOwnAnnotations);
      setCanEditOthersAnnotations(!!canEditOthersAnnotations);
      setCanReplyToAnnotations(!!canReplyToAnnotations);
      setAdditionalButtons(!!additionalButtons ? additionalButtons : undefined);
    },
    []
  );

  const show = useCallback(
    (
      { revision, parentDocumentId, highlightedAnnotationId }: RevisionData,
      editorSettings: AnnotationEditorSettings = {},
      workflowNodeId?: Guid,
      workflowId?: Guid
    ) => {
      !!revision && setRevision(revision);
      !!workflowNodeId && setWorkflowNodeIdentificaiton({ workflowId, workflowNodeId });
      !!parentDocumentId && setParentDocumentId(parentDocumentId);
      setHighlightedAnnotationId(!!highlightedAnnotationId ? highlightedAnnotationId : undefined);

      setEditorSettings(editorSettings);
      setIsVisible();
    },
    [setEditorSettings, setIsVisible]
  );

  useEffect(() => {
    if (pdfTronLicenseKeyError) {
      console?.error('NO PDFTron license key');
      messageError(pdfTronLicenseKeyError, intl);
    }
  }, [pdfTronLicenseKeyError, intl]);

  const setRevisionData = useCallback((revision: RevisionDto, parentDocumentId: Guid) => {
    setRevision(revision);
    setParentDocumentId(parentDocumentId);
  }, []);

  const hasLicense = !!pdfTronLicenseKey;

  const value: ValueType = useMemo(() => {
    return {
      show,
      hide,
      isVisible,
      revision,
      setRevisionData,
      setEditorSettings,
      parentDocumentId,
      hasLicense,
      pdfTronLicenseKey,
      newDocumentAnnotations,
      changedDocumentAnnotations,
      setAnnotationModalDisablerDeactivated,
    };
  }, [
    revision,
    isVisible,
    parentDocumentId,
    pdfTronLicenseKey,
    hasLicense,
    newDocumentAnnotations,
    changedDocumentAnnotations,
    setAnnotationModalDisablerDeactivated,
  ]);

  useEffect(() => {
    if (isVisible) {
      setNewDocumentAnnotations([]);
      setChangedDocumentAnnotations([]);
      if (!revision) {
        console?.error('No revision has been set');
      }
    }
  }, [isVisible]);

  return (
    <AnnotationModalContext.Provider value={value}>
      {children}

      {hasLicense && !!revision && (
        <PdfAnnotationModal
          visible={isVisible}
          pdfTronLicenseKey={pdfTronLicenseKey}
          documentId={parentDocumentId}
          revision={revision}
          onClose={hide}
          onAnnotationsChanged={setChangedDocumentAnnotations}
          onNewAnnotationsChanged={setNewDocumentAnnotations}
          annotationMode={annotationMode}
          highlightedAnnotationId={highlightedAnnotationId}
          canAddRevision={canAddRevision}
          canEditOwnAnnotations={canEditOwnAnnotations}
          canEditOthersAnnotations={canEditOthersAnnotations}
          canReplyToAnnotations={canReplyToAnnotations}
          additionalButtons={additionalButtons}
          annotationModalDisablerDeactivated={annotationModalDisablerDeactivated}
          workflowNodeId={workflowNodeIdentification.workflowNodeId}
          workflowId={workflowNodeIdentification.workflowId}
        />
      )}
    </AnnotationModalContext.Provider>
  );
};

export default AnnotationModalContextProvider;
