import { Rule } from 'antd/lib/form';
import { DefaultOptionType } from 'antd/lib/select';
import { WorkflowStateEnum } from 'api/completeApiInterfaces';
import { DocumentSelectDocumentState } from 'components/DocumentSelect/DocumentSelect';
import { MaskItemType } from 'components/MaskInput/MaskInput.types';
import { validateFilenameByMask } from 'components/MaskInput/NameValidatorByMask/validateFilenameByMask';
import { MAX_ITEM_NAME_LENGTH } from 'config/constants';
import { Fmt, FmtProps } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React from 'react';
import { smartFilter } from 'utils/smartSearch';

export const CORRECT_CZ_VIN_STRING_LENGTH = 8;

export const simpleSelectFilter = (input: string, option: DefaultOptionType) =>
  smartFilter(option.label || option.props.children.toString(), input);

export const labelSelectFilter = (input: string, option: DefaultOptionType) =>
  smartFilter(option.props.label.toString(), input);

export const requiredRule: (intlId: IntlMessageId, includeWhitespace?: boolean) => Rule = (
  intlId,
  includeWhitespace
) => {
  const config: Rule = {
    required: true,
    message: React.createElement(Fmt, { id: intlId }),
  };
  if (includeWhitespace) {
    config.whitespace = includeWhitespace;
  }
  return config;
};

export const whitespaceRule: (intlId: IntlMessageId) => Rule = (intlId) => ({
  whitespace: true,
  message: React.createElement(Fmt, { id: intlId }),
});

export const maxLengthRule: (intlId: IntlMessageId, length?: number) => Rule = (
  intlId,
  length = MAX_ITEM_NAME_LENGTH
) => ({
  max: length,
  message: React.createElement(Fmt, { id: intlId, values: { max: length } }),
});

export const minLengthRule: (intlId: IntlMessageId, length?: number) => Rule = (intlId, length = 1) => ({
  min: length,
  message: React.createElement(Fmt, { id: intlId, values: { min: length } }),
});

export const duplicateNameRule: (
  intlId: IntlMessageId,
  reservedNames: string[],
  ignoreCaseSensitivity?: boolean
) => Rule = (intlId, reservedNames, ignoreCaseSensitivity) => ({
  message: React.createElement(Fmt, { id: intlId }),
  validator: (rule, value) => {
    if (
      reservedNames?.some(
        (item) =>
          item?.trim().localeCompare(value?.toString().trim(), undefined, {
            sensitivity: ignoreCaseSensitivity ? 'accent' : 'variant',
          }) === 0
      )
    ) {
      return Promise.reject(intlId);
    } else {
      return Promise.resolve();
    }
  },
});

export const duplicateNameRuleCallback = (
  validateUniqueName: (name: string) => boolean, // true === ok,
  intlId: IntlMessageId = 'forms.items.name.rules.nameExists'
): Rule => ({
  message: React.createElement(Fmt, { id: intlId }),
  validator: (rule, value) => {
    if (validateUniqueName && !validateUniqueName(value?.trim())) {
      return Promise.reject(intlId);
    } else {
      return Promise.resolve();
    }
  },
});

export const includesDiacriticsRule: (intlId: IntlMessageId) => Rule = (intlId) => ({
  message: React.createElement(Fmt, { id: intlId }),
  validator: (rule, value) => {
    if (
      (typeof value === 'string' || value instanceof String) &&
      Array.from(value).length !== Array.from(value.normalize('NFD')).length
    ) {
      return Promise.reject(intlId);
    } else {
      return Promise.resolve();
    }
  },
});

export const justAllowedDocumentStatesRule: (
  intlId: IntlMessageId,
  allowedDocumentStates: WorkflowStateEnum[]
) => Rule = (intlId, allowedDocumentStates) => ({
  message: React.createElement(Fmt, { id: intlId }),
  validator: (rule, value) => {
    const formValue = value as DocumentSelectDocumentState[];
    if (formValue?.some((item) => !!allowedDocumentStates.length && !allowedDocumentStates.includes(item.state))) {
      return Promise.reject(intlId);
    } else {
      return Promise.resolve();
    }
  },
});

export const justByMasksValidDocumentNameRule: (intlId: IntlMessageId, masks: MaskItemType[]) => Rule = (
  intlId,
  masks
) => ({
  message: React.createElement(Fmt, { id: intlId }),
  validator: (rule, value) => {
    const formValue = value as string;

    if (!formValue || !masks?.length || validateFilenameByMask(masks, formValue, {})) {
      return Promise.resolve();
    } else {
      return Promise.reject(intlId);
    }
  },
});

export const justValidGuidRule: (intlId: IntlMessageId) => Rule = (intlId) => ({
  message: React.createElement(Fmt, { id: intlId }),
  validator: (rule, value) => {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    if (!regex.test(value)) {
      return Promise.reject(intlId);
    } else {
      return Promise.resolve();
    }
  },
});

export const getIsValidByControlDigit = (value: string) => {
  let controlSum: number = 0;

  for (let i = 0; i < CORRECT_CZ_VIN_STRING_LENGTH - 1; i++) {
    controlSum += +value[i] * (CORRECT_CZ_VIN_STRING_LENGTH - i);
  }
  const remainder = controlSum % 11;
  const correctEighthDigit = remainder === 0 ? 1 : remainder === 1 ? 0 : 11 - remainder;
  const valueEightDigit = +value[7];
  return valueEightDigit === correctEighthDigit;
};

export const czechValidCinWarningRule: (notValidCinInfo: React.ReactNode) => Rule = (notValidCinInfo) => ({
  warningOnly: true,
  validator: (rule, value) => {
    const warningElement = (fmtProps: FmtProps) =>
      React.createElement('span', null, [notValidCinInfo, React.createElement(Fmt, fmtProps)]);

    const regex = /^[0-9]*$/i;
    const hasDigitsOnly = regex.test(value);
    const hasShorterLength = value.length < CORRECT_CZ_VIN_STRING_LENGTH;
    const hasLongerLength = value.length > CORRECT_CZ_VIN_STRING_LENGTH;

    if (!value) return Promise.resolve();
    if (!hasDigitsOnly) {
      return Promise.reject(warningElement({ id: 'general.justNumbers' }));
    }
    if (hasShorterLength) {
      return Promise.reject(
        warningElement({ id: 'general.minCINlength', values: { min: CORRECT_CZ_VIN_STRING_LENGTH } })
      );
    }
    if (hasLongerLength) {
      return Promise.reject(warningElement({ id: 'general.maxLength', values: { max: CORRECT_CZ_VIN_STRING_LENGTH } }));
    }
    if (hasDigitsOnly && !hasShorterLength && !hasLongerLength) {
      return getIsValidByControlDigit(value)
        ? Promise.resolve()
        : Promise.reject(warningElement({ id: 'general.badCINControlDigit' }));
    } else {
      return Promise.resolve();
    }
  },
});
