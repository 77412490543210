import { api } from 'api';
import { DocumentLinkRemoveStrategyEnum, ModelDocumentsRemoveFromModelDto } from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import { transformError } from 'components/DocumentMultipleActionError';
import {
  DocumentsMultipleActionObstaclesEnum,
  IMultipleDocumentErrorData,
} from 'components/DocumentMultipleActionError/DocumentMultipleActionError';
import DocumentsDiscardForm from 'components/forms/DiscardForm/DocumentsDiscardForm';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import { Fmt } from 'locale';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { processApiError } from 'utils';

type Props = InjectedIntlProps &
  FormModalProps<Guid[]> & {
    selectedModelIds: Guid[];
  };

const BulkRemoveFromModelsModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, onClose, selectedModelIds, ...restProps } = props;
  const [errors, setErrors] = useState<JSX.Element[]>([]);
  const [selectedOkModels, setSelectedOkDocuments] = useState<Guid[]>(null);

  const handleSubmit: FormSubmitHandler = async () => {
    const selected = !!selectedOkModels ? selectedOkModels : selectedModelIds;

    if (!!selected.length) {
      const modelDocumentsRemoveFromModelDto: ModelDocumentsRemoveFromModelDto = {
        documentsToRemove: selected,
      };

      const strategy = !!errors.length ? DocumentLinkRemoveStrategyEnum.remove : undefined;
      const [err] = await api.project.documents.removeModelDocumentsBulkVersion(
        modelDocumentsRemoveFromModelDto,
        strategy
      );
      if (err) {
        processApiError(err, (error) => {
          if (error != null && !!error.errorData) {
            if (error.referenceErrorCode === ServiceErrorEnum.DocumentRemoveFromModelMultipleObstacleError) {
              const errData = error.errorData as IMultipleDocumentErrorData;
              setSelectedOkDocuments(
                selected.filter(
                  (doc) =>
                    !errData.documentsErrors.some(
                      (errDoc) =>
                        errDoc.objectId === doc &&
                        (errDoc.documentsObstacles === DocumentsMultipleActionObstaclesEnum.NotInModel ||
                          errDoc.documentsObstacles === DocumentsMultipleActionObstaclesEnum.AccessDenied)
                    )
                )
              );

              setErrors(errData.documentsErrors.map(transformError));

              return null;
            }
          }
        });
        return null;
      }
      onSubmit(selected);
      resetDialog();
      return null;
    }
    resetDialog();
    onClose();
    return null;
  };

  const handleClose = () => {
    resetDialog();
    onClose();
  };

  const resetDialog = () => {
    setErrors([]);
    setSelectedOkDocuments(null);
  };

  const submitTextId = useMemo(() => {
    if (!selectedOkModels?.length) return 'general.ok';
    if (selectedOkModels?.length && errors.length !== 0) return 'BulkRemoveFromModelsModal.button.RemoveAndDeleteLinks';
    return 'BulkRemoveFromModelsModal.button.Remove';
  }, [selectedOkModels, errors]);

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      onClose={handleClose}
      titleId="BulkRemoveFromModelsModal.title"
      confirmClose={false}
      layout="horizontal"
      {...restProps}
      submitTextId={submitTextId}
    >
      <DocumentsDiscardForm
        title={
          <Fmt
            id="BulkRemoveFromModelsModal.RemoveModels"
            values={{
              count: !!selectedModelIds?.length ? selectedModelIds.length : 0,
            }}
          />
        }
        errors={errors}
        errorsTextId={'BulkRemoveFromModelsModal.errorsTitle.missed'}
      />
    </FormModalWrapper>
  );
};

export default injectIntl(BulkRemoveFromModelsModal);
