import { Alert, Form, Input, Select } from 'antd';
import { apiConstraints } from 'api/completeApiConstraints';
import { JSONVariableTypeEnum, ProjectMetadataDefinitionDto } from 'api/completeApiInterfaces';
import { useIntl } from 'hooks';
import { getMetadataVariableSelectOptions } from 'pages/OrganizationsSettingPage/ProjectTemplate/Tabs/General/MetadataUtils';
import React, { FC, useMemo } from 'react';
import { duplicateNameRule, maxLengthRule, requiredRule } from 'utils/formHelpers';

export type MetadataFormData = {
  name: string;
  variable: JSONVariableTypeEnum;
};

type Props = {
  selectedMetadataItem?: ProjectMetadataDefinitionDto;
  usedItems: ProjectMetadataDefinitionDto[];
};

export const MetadataForm: FC<Props> = ({ selectedMetadataItem: metadataItem, usedItems = [] }) => {
  const intl = useIntl();
  const namesToCompare = useMemo(() => {
    return usedItems.filter((used) => used.name !== metadataItem?.name).map((item) => item.name);
  }, [usedItems]);

  const variableOptions = useMemo(() => {
    return getMetadataVariableSelectOptions(intl);
  }, [intl]);

  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: 'general.name' })}
        name="name"
        initialValue={metadataItem?.name}
        rules={[
          requiredRule('forms.items.rules.required', true),
          maxLengthRule('general.maxNameLength', apiConstraints.projectMetadataDefinitionCreateDto.name.maxLength),
          duplicateNameRule('forms.items.name.rules.nameExists', namesToCompare, true),
        ]}
      >
        <Input maxLength={apiConstraints.assignmentCreateDto.name.maxLength} />
      </Form.Item>
      {!metadataItem?.variable ? (
        <Form.Item
          label={intl.formatMessage({ id: 'general.type' })}
          name="variable"
          rules={[requiredRule('forms.items.rules.required')]}
        >
          <Select options={variableOptions} />
        </Form.Item>
      ) : (
        <Alert message={intl.formatMessage({ id: 'MetadataForm.noEdit.variableType.description' })} type="warning" />
      )}
    </>
  );
};
