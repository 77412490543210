import { Form, InputRef } from 'antd';
import { ProjectMetadataValueDto } from 'api/completeApiInterfaces';
import { JSONVariable } from 'components/JSONVariableFormItems/JSONVariableTypes';
import { Fmt } from 'locale';
import { renderProjectCardDefaultDataVariableFormItem } from 'pages/OrganizationsSettingPage/ProjectCardData/ProjectCardDataAddForm';
import React, { FC } from 'react';

export type MetadataFormData = {
  data: JSONVariable;
};

type Props = {
  metaItemForEdit: ProjectMetadataValueDto;
  setRef: (ref: InputRef) => void;
};

const MetadataEditFormComponent: FC<Props> = ({ metaItemForEdit }) => {
  const valueType = metaItemForEdit.definitionVariable;

  return (
    <Form.Item label={<Fmt id="general.value" />} name="data" initialValue={metaItemForEdit?.value}>
      {renderProjectCardDefaultDataVariableFormItem(valueType)}
    </Form.Item>
  );
};

export const MetadataEditForm = React.memo(MetadataEditFormComponent);
