import { Form, Input } from 'antd';

import { JSONVariableTypeEnum } from 'api/completeApiInterfaces';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback } from 'react';
import { JSONVariableLinkData } from '../JSONVariableTypes';

export type JSONVariableLinkProps = {
  dataType: JSONVariableTypeEnum.link;
  value?: JSONVariableLinkData;
  onChange?: (value: JSONVariableLinkData) => void;
  disabled?: boolean;
};

const JSONVariableLinkFormItem: FunctionComponent<JSONVariableLinkProps> = ({
  value,
  onChange,
  disabled,
  dataType,
}) => {
  const handleLinkNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange({ type: dataType, label: e.target.value, value: value?.value });
    },
    [value, onChange]
  );

  const handleLinkAddressChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange({ type: dataType, label: value.label, value: e.target.value });
    },
    [value, onChange]
  );

  return (
    <>
      <Form.Item hidden noStyle name={['link', 'type']} initialValue={dataType} />
      <Form.Item
        label={<Fmt id="JSONVariableLinkFormItem.label" />}
        name={['link', 'label']}
        initialValue={value?.label}
      >
        <Input disabled={disabled} value={value?.label} onChange={handleLinkNameChange} />
      </Form.Item>
      <Form.Item
        name={['link', 'value']}
        label={<Fmt id="JSONVariableLinkFormItem.url" />}
        initialValue={value?.value}
        rules={[
          {
            pattern: /^https:\/\//,
            message: 'Please enter a valid URL starting on https://',
          },
        ]}
      >
        <Input disabled={disabled} value={value?.value} onChange={handleLinkAddressChange} placeholder="https://" />
      </Form.Item>
    </>
  );
};

export default React.memo(JSONVariableLinkFormItem);
