import { Form, Input, InputRef, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import PrimaryFileInput from 'components/PrimaryFileInput';
import { useIntl } from 'hooks';
import { default as React, FC, ReactNode, useCallback, useState } from 'react';
import { maxLengthRule, requiredRule } from 'utils/formHelpers';

export type DiscussionRelationOption<K extends string | number> = {
  key: K;
  icon: ReactNode;
};

export type DiscussionAttachmentCreateFormData = {
  name: string;
  file: File;
  discussionRelation?: string | number;
};

type Props = {
  initialFile?: File;
  discussionRelationOptions?: DiscussionRelationOption<string | number>[];
  setRef: (ref: InputRef) => void;
};

export const DiscussionAttachmentCreateForm: FC<Props> = ({ discussionRelationOptions, initialFile, setRef }) => {
  const intl = useIntl();
  const [prevFileName, setPrevFileName] = useState<string>('');

  const filename = useWatch('name');
  const form = useFormInstance();

  const handleFileChange = useCallback((file: File) => {
    if (file) {
      const name = filename || '';
      if (name === '' || name === prevFileName || (initialFile && name === initialFile.name)) {
        form.setFieldsValue({ name: file.name });
        setPrevFileName(file.name);
      }
    }
  }, []);

  return (
    <>
      <Form.Item
        name="file"
        initialValue={initialFile}
        rules={[requiredRule('forms.items.primaryFile.rules.required')]}
      >
        <PrimaryFileInput onChange={handleFileChange} />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.name.label' })}
        name="name"
        initialValue={initialFile ? initialFile.name : ''}
        rules={[
          requiredRule('DocumentCreateForm.form.items.name.rules.required', true),
          maxLengthRule('general.maxNameLength'),
        ]}
      >
        <Input
          placeholder={intl.formatMessage({ id: 'DocumentCreateForm.form.items.name.placeholder' })}
          autoFocus
          ref={setRef}
        />
      </Form.Item>
      {discussionRelationOptions?.length && (
        <Form.Item
          label={intl.formatMessage({ id: 'AttachmentCreateFormModal.form.item.relation.label' })}
          name="discussionRelation"
          initialValue={initialFile ? initialFile.name : ''}
          rules={[
            requiredRule('DocumentCreateForm.form.items.relation.rules.required', true),
            maxLengthRule('general.maxNameLength'),
          ]}
        >
          <Select dropdownMatchSelectWidth={false}>
            {discussionRelationOptions.map((option) => (
              <Select.Option key={option.key} value={option.key}>
                {option.icon}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </>
  );
};
