import { InputNumber, InputNumberProps } from 'antd';
import { JSONVariableTypeEnum } from 'api/completeApiInterfaces';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { useCallback } from 'react';
import { getDecimalSeparator } from 'utils/getDecimalSeparator';
import { JSONVariableNumber } from '../JSONVariableTypes';

export type JSONVariableInputNumberProps = {
  dataType: JSONVariableTypeEnum.number;
  value?: JSONVariableNumber;
  onChange?: (value: JSONVariableNumber) => void;
  intlId?: IntlMessageId;
} & Omit<InputNumberProps, 'value' | 'onChange'>;

const JSONVariableInputNumberFormItem = React.forwardRef<HTMLInputElement, JSONVariableInputNumberProps>(
  ({ dataType, value, onChange, intlId, ...restProps }, ref) => {
    const intl = useIntl();

    const handleChange = useCallback(
      (value: number | string) => {
        if (value == null || value === '') value = null;
        if (typeof value === 'string') value = parseFloat(value);
        onChange && onChange({ type: dataType, value: value });
      },
      [dataType, onChange]
    );
    return (
      <>
        <InputNumber
          decimalSeparator={getDecimalSeparator(intl.locale)}
          ref={ref}
          onChange={handleChange}
          value={value?.value}
          {...restProps}
          style={{ width: '150px' }}
        />
        {!!intlId && <Fmt id={intlId} />}
      </>
    );
  }
);

export default React.memo(JSONVariableInputNumberFormItem);
