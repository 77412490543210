import { GroupDto, GroupListDto, ServiceError } from 'api/completeApiInterfaces';
import { GroupFormModal } from 'components/forms/GroupForm';
import ServiceErrorBox from 'components/ServiceErrorBox';
import SpinBox from 'components/SpinBox';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { strCompareCI } from 'utils';
import Panel from '../../Panel';
import GroupsList from './GroupsList';

type Props = {
  groupsMap: Record<Guid, GroupListDto>;
  groupsList: GroupListDto[];
  groupsListLoading: boolean;
  groupsListError: ServiceError;
  selectedId: Guid;
  onSelect?: (groupId: Guid) => void;
  onAdd: (groupId: GroupDto) => void;
  onDelete: (groupId: Guid) => void;
};

const GroupsListPanel: FunctionComponent<Props> = ({
  groupsMap,
  groupsList,
  groupsListLoading,
  groupsListError,
  selectedId,
  onSelect,
  onAdd,
  onDelete,
}) => {
  const [formModalVisible, setFormModalVisible] = useState<boolean>(false);
  const [formModalId, setFormModalId] = useState<string>(undefined);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const showModal = (id?: Guid) => {
    setFormModalId(id);
    setFormModalVisible(true);
  };

  const closeModal = () => {
    setFormModalVisible(false);
    setFormModalId(undefined);
    setEditMode(false);
  };

  const handleSubmit = (data: GroupDto) => {
    onAdd(data);
    closeModal();
  };

  const handleEdit = (id: Guid) => {
    setEditMode(true);
    showModal(id);
  };

  const checkGroupUniqueName = useCallback(
    (name: string) =>
      groupsList
        ? groupsList.findIndex((g) => strCompareCI(g.name, name) === 0 && (editMode ? g.id !== formModalId : true)) ===
          -1
        : true,
    [groupsList, formModalId, editMode]
  );

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const renderList = () => {
    if (groupsListError) return <ServiceErrorBox error={groupsListError} />;
    if (groupsListLoading && !groupsList) return <SpinBox />;
    if (groupsList === null) return null;

    return (
      <GroupsList
        listData={groupsList}
        search={search}
        selectedId={selectedId}
        onSelect={onSelect}
        onEdit={handleEdit}
        onDelete={onDelete}
        onClearSearch={clearSearch}
      />
    );
  };

  return (
    <Panel
      noMargin
      panelWidth="auto"
      onSearch={setSearch}
      searchValue={search}
      addButtonOnClick={showModal}
      addButtonText={<Fmt id="Panel.addGroup.tooltip" />}
    >
      {renderList()}
      <GroupFormModal
        open={formModalVisible}
        onSubmit={handleSubmit}
        onClose={closeModal}
        validateUniqueName={checkGroupUniqueName}
        defaults={editMode ? groupsMap[formModalId] : null}
      />
    </Panel>
  );
};

export default GroupsListPanel;
