import { Middleware } from '@rematch/core';
import { baseMasterApi } from 'api/master/baseMasterApi';
import { baseProjectApi } from 'api/project/baseProjectApi';
import { SIGNAL_R_DIRECTORY_CONNECTION, SIGNAL_R_ESTICON_CONNECTION } from 'config';
import {
  SIGNAL_R_DOCUMENT_CONNECTION,
  SIGNAL_R_MD_CONNECTION,
  SIGNAL_R_REDUX_STORE_CONNECTION,
  SIGNAL_R_WORKFLOW_CONNECTION,
} from 'config/signalRConnection';
import { SILENT_RENEW_ERROR, USER_EXPIRED, USER_FOUND, USER_SIGNED_OUT } from 'redux-oidc';
import { Store } from 'store';
import { history } from 'store/history';
import { UnsafeDispatch } from 'store/models/storeModelinterfaces';

export const accessTokenMiddleware: Middleware<Store, UnsafeDispatch> = (_store) => (next) => (action) => {
  if (action.type === USER_FOUND) {
    const {
      payload: { access_token, token_type }, // eslint-disable-line
    } = action;

    baseMasterApi.defaults.headers.common['Authorization'] = `${token_type} ${access_token}`;
    baseProjectApi.defaults.headers.common['Authorization'] = `${token_type} ${access_token}`;

    SIGNAL_R_DIRECTORY_CONNECTION.setAccessToken(access_token);
    SIGNAL_R_DOCUMENT_CONNECTION.setAccessToken(access_token);
    SIGNAL_R_ESTICON_CONNECTION.setAccessToken(access_token);
    SIGNAL_R_REDUX_STORE_CONNECTION.setAccessToken(access_token);
    SIGNAL_R_WORKFLOW_CONNECTION.setAccessToken(access_token);
    SIGNAL_R_MD_CONNECTION.setAccessToken(access_token);

    void SIGNAL_R_REDUX_STORE_CONNECTION.start();
  } else if (action.type === USER_EXPIRED || action.type === SILENT_RENEW_ERROR) {
    SIGNAL_R_DIRECTORY_CONNECTION.stop();
    SIGNAL_R_DOCUMENT_CONNECTION.stop();
    SIGNAL_R_ESTICON_CONNECTION.stop();
    SIGNAL_R_REDUX_STORE_CONNECTION.stop();
    SIGNAL_R_WORKFLOW_CONNECTION.stop();
    SIGNAL_R_MD_CONNECTION.stop();
  } else if (action.type === USER_SIGNED_OUT) {
    SIGNAL_R_DIRECTORY_CONNECTION.stop();
    SIGNAL_R_DOCUMENT_CONNECTION.stop();
    SIGNAL_R_ESTICON_CONNECTION.stop();
    SIGNAL_R_REDUX_STORE_CONNECTION.stop();
    SIGNAL_R_WORKFLOW_CONNECTION.stop();
    SIGNAL_R_MD_CONNECTION.stop();
    history.push('/out');
  }
  return next(action);
};
