import { Button } from 'antd';
import { masterApi } from 'api/completeApi';
import { OrganizationAdminReportDto, OrgProjectCardDataDto } from 'api/completeApiInterfaces';
import { SaveOrCancelButton } from 'components/SaveOrCancelButton/SaveOrCancelButton';
import WindowDisabler from 'components/WindowDisabler';
import { useIntl, useIsMounted } from 'hooks';
import { Fmt } from 'locale';
import { isEqual } from 'lodash';
import {
  CARD_DATA_MOCK,
  mapStructureToDto,
} from 'pages/OrganizationsSettingPage/ProjectCardData/SZ_CAPACITY_DATA_TABLE';
import { useOrgProjectCardData } from 'pages/OrganizationsSettingPage/ProjectCardData/useOrgProjectCardData';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { DisableWindowStoreStartPayload } from 'store/models/storeModelinterfaces';
import ProjectCardDataListPanel from './ProjectCardDataListPanel';

type Props = {
  selectedOrganization: OrganizationAdminReportDto;
  loadOrganizationsAdminReport?: () => void;
};

export const ProjectCardDataPanel: FC<Props> = ({ selectedOrganization, loadOrganizationsAdminReport }) => {
  const selectedOrganizationId = selectedOrganization.id;
  const intl = useIntl();
  const [projectCardData, setProjectCardData] = useOrgProjectCardData(selectedOrganization);

  const dispatch = useDispatch<Dispatch>();
  const [changedProjectCardData, setChangedProjectCardData] = useState<OrgProjectCardDataDto[]>(projectCardData);

  const importSzDataStructure = useCallback(() => {
    setChangedProjectCardData(mapStructureToDto(CARD_DATA_MOCK));
  }, []);

  useEffect(() => {
    if (projectCardData && !changedProjectCardData?.length) {
      setChangedProjectCardData(projectCardData);
    }
  }, [projectCardData]);

  const isDirty = useMemo(() => !isEqual(changedProjectCardData, projectCardData), [
    changedProjectCardData,
    projectCardData,
  ]);

  const disableWindowConfig = useMemo(
    (): DisableWindowStoreStartPayload => ({
      showMask: false,
      message: intl.formatMessage({ id: 'DirectorySettingsForm.permissions.notSaveAlert' }),
    }),

    [intl]
  );

  useEffect(() => {
    if (!isDirty) {
      dispatch.disableWindow.stopPreventing();
    } else {
      dispatch.disableWindow.startPreventing(disableWindowConfig);
    }
  }, [isDirty]);

  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);

    const [err, data] = await masterApi.projects.reports.projectcard.id.patch(selectedOrganizationId, {
      cardData: changedProjectCardData,
    });

    if (err) {
      if (isMounted.current) setLoading(false);
      return err;
    }

    setProjectCardData(data?.data?.cardData);
    loadOrganizationsAdminReport?.();

    if (isMounted.current) setLoading(false);
    return null;
  };

  const cancelChanges = useCallback(() => {
    setChangedProjectCardData(projectCardData);
  }, [projectCardData]);

  return (
    <>
      <WindowDisabler />
      <ProjectCardDataListPanel
        organizationId={selectedOrganizationId}
        projectCardData={changedProjectCardData}
        setProjectCardData={setChangedProjectCardData}
        isDirty={isDirty}
        buttons={
          <>
            <SaveOrCancelButton onSave={onSubmit} onCancel={cancelChanges} loading={loading} disabled={!isDirty} />
            {!projectCardData?.length && selectedOrganization?.hasSZModule && (
              <Button onClick={importSzDataStructure} type="link">
                <Fmt id="general.import" />
              </Button>
            )}
          </>
        }
      />
    </>
  );
};
