import { DoubleRightOutlined } from '@ant-design/icons';
import { projectApi } from 'api/completeApi';
import { ProjectDto } from 'api/completeApiInterfaces';
import GeneralDateRageSettingsItem from 'components/GeneralDateRageSettingsItem/GeneralDateRageSettingsItem';
import { useIntl } from 'hooks';
import moment, { Moment } from 'moment';
import React, { FC, ReactNode, useCallback } from 'react';
import { processApiError } from 'utils';

type Props = {
  project: ProjectDto;
  setActiveProject: (project: ProjectDto) => void;
  disableEdit?: boolean;
};

function toComparableDates(date: Moment | string): string {
  if (!date) return null;
  return moment(date).format('YYYY-MM-DD');
}

function compareDate(date: Moment | string, date2: Moment | string): boolean {
  return toComparableDates(date) === toComparableDates(date2);
}

export const ProjectCardDatesItem: FC<Props> = ({ project, disableEdit, setActiveProject }) => {
  const intl = useIntl();

  const onSave = useCallback(
    async (startDate: Moment, finishDate: Moment): Promise<boolean | ReactNode> => {
      if (
        !compareDate(startDate, project.projectCard?.startDate) ||
        !compareDate(finishDate, project.projectCard?.finishDate)
      ) {
        const data = {
          ...project.projectCard,
          startDate: toComparableDates(startDate),
          finishDate: toComparableDates(finishDate),
        };
        const [err] = await projectApi.card.patch(data);
        if (!err) {
          setActiveProject({ ...project, projectCard: data });
          return true;
        } else {
          return intl.formatMessage({
            id: `serviceError.${processApiError(err).referenceErrorCode}`,
          });
        }
      }
      return true;
    },
    [setActiveProject, project, intl]
  );

  const validStartDate = project.projectCard?.startDate ? moment(project.projectCard.startDate) : null;
  const validFinishDate = project.projectCard?.finishDate ? moment(project.projectCard.finishDate) : null;
  const value = {
    startDate: validStartDate?.isValid() ? validStartDate : null,
    finishDate: validFinishDate?.isValid() ? validFinishDate : null,
  };

  return (
    <GeneralDateRageSettingsItem
      icon={<DoubleRightOutlined />}
      value={value}
      onSave={onSave}
      headline={intl.formatMessage({ id: 'ProjectDashboard.ProjectCard.duration' })}
      disableEdit={disableEdit}
    />
  );
};
