import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Button, Typography, message } from 'antd';
import { masterApi } from 'api/completeApi';
import {
  EstiConProjectPhase,
  EstiProjectNoteForHubDto,
  OrgExtendedPermissionDto,
  OrgExtendedPermissionValueEnum,
  ProjectsInRealizationPropertyType,
} from 'api/completeApiInterfaces';
import classNames from 'classnames';
import { BudgetGrid, WithId } from 'components/BudgetGrid/BudgetGrid';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { EditIcon } from 'components/Icons/HubActionsIcons';
import { ReportPrecision } from 'components/Reports/forms/ReportWidgetCreateForm/ReportWidgetForm.utils';
import StackPanel from 'components/StackPanel/StackPanel';
import { Serializable } from 'components/filters/filterTypes';
import { FilterToolbar } from 'components/filters/render/FilterToolbar/FilterToolbar';
import { Template } from 'devextreme-react';
import { useApiData, useIntl } from 'hooks';
import { debounce } from 'lodash';
import moment, { Moment } from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { getNumberFormatSettings } from 'utils/buildingGridUtils';
import { ProjectsInRealizationNoteModal } from './ProjectsInRealizationNoteModal';
import {
  SPACE_FOR_SCROLLBAR_COLUMN,
  getRealizationOverviewReportColumns,
} from './ProjectsInRealizationOverviewReport.columns';
import styles from './ProjectsInRealizationOverviewReport.module.less';
import { ReportViewConfiguration } from './ProjectsInRealizationOverviewReport.reportPresets';
import {
  ProjectsInRealisationOverviewReportData,
  createProjectsInRealisationOverviewReportData,
} from './ProjectsInRealizationOverviewReportUtils';
import { ColumnChangeTypeEnum } from './useConfigurableReportView';
import { useProjectsInRealizationFilter } from './useProjectsInRealizationFilter';

const COLUMN_CONFIGURATION_SAVE_DELAY = 200;

type Props = {
  estiConnId: Guid;
  esticonFirmId: Guid;
  organizationId: Guid;
  availableProjectIds: Set<Guid>;
  reportDate: Moment;
  reportYear: number;
  reportViewConfiguration: ReportViewConfiguration;
  reportPermissions: OrgExtendedPermissionDto[];
  onColumnChange: (
    changeType: ColumnChangeTypeEnum,
    columnIndex: number | null,
    nestedIndex: number | null,
    oldValue?: boolean | number,
    newValue?: boolean | number
  ) => void;
  onFiltersChange: (filterPresets: Record<string, Serializable>) => void;
  onSetVisibleReportRows: (visibleIds: Guid[]) => void;
};

const validationRenderer = (cellInfo: {
  value?: string;
  text: string;
  row: { data: ProjectsInRealisationOverviewReportData };
  rowIndex: number;
}) => {
  const isInvalid = cellInfo.row.data.nezatrideno > 0 && cellInfo.rowIndex > 0;
  return isInvalid ? (
    <div className={classNames(styles.cellBorder, styles.validationError)}>{cellInfo.text}</div>
  ) : (
    <div className={styles.cellBorder}>{cellInfo.text}</div>
  );
};

const subsidiesWithTooltip = (cellInfo: {
  value?: string;
  text: string;
  row: { data: ProjectsInRealisationOverviewReportData };
  rowIndex: number;
}) => {
  return cellInfo.row.data.uzivatelskeZatrideniProjektuNazev ? (
    <CommonHubTooltip title={cellInfo.row.data.uzivatelskeZatrideniProjektuNazev}>{cellInfo.text}</CommonHubTooltip>
  ) : (
    cellInfo.text
  );
};

const planValidationRenderer = (cellInfo: {
  value?: string;
  text: string;
  column: { validationValueSource?: keyof ProjectsInRealisationOverviewReportData; dataField: string };
  row: { data: ProjectsInRealisationOverviewReportData };
  rowIndex: number;
}) => {
  const isInvalid =
    (!cellInfo.row.data.isFpRozepsanoVse || !cellInfo.row.data.prebiranaUroven) && cellInfo.rowIndex > 0;
  return isInvalid ? (
    <div className={classNames(styles.cellBorder, styles.validationError)}>{cellInfo.text}</div>
  ) : (
    <div className={styles.cellBorder}>{cellInfo.text}</div>
  );
};

const ProjectsInRealizationOverviewReport: FunctionComponent<Props> = ({
  estiConnId,
  esticonFirmId,
  organizationId,
  reportDate,
  reportYear,
  reportViewConfiguration,
  reportPermissions,
  onColumnChange,
  onFiltersChange,
  onSetVisibleReportRows,
}) => {
  const intl = useIntl();
  const [focusedKey, setFocusedKey] = useState<Guid>();
  const [selectedNoteProjectId, setSelectedNoteProjectId] = useState<Guid>();

  const toggleColumnGroupVisible = useCallback(
    (columnKey: ProjectsInRealizationPropertyType) => {
      const index = reportViewConfiguration.columns.findIndex((column) => column.columnKey === columnKey);
      onColumnChange(ColumnChangeTypeEnum.ToggleFolding, index, undefined);
    },
    [onColumnChange, reportViewConfiguration]
  );

  const handleFocusRowChange = (data: WithId) => setFocusedKey(data?.id);

  const debouncedColumnChange = useMemo(
    () =>
      debounce(
        (
          changeType: ColumnChangeTypeEnum,
          columnIndex: number | null,
          nestedIndex: number | null,
          oldValue?: boolean | number,
          newValue?: boolean | number
        ) => onColumnChange(changeType, columnIndex, nestedIndex, oldValue, newValue),
        COLUMN_CONFIGURATION_SAVE_DELAY
      ),
    [onColumnChange]
  );

  const handleColumnsChange = useCallback(
    (optionType: string, previousValue: any, newValue: any) => {
      const regex = /columns(?:\[(\d+)\])?(?:\.columns\[(\d+)\])?\.?(\w+)?/;
      const match = optionType.match(regex);
      if (!match) {
        return;
      }
      const index = parseInt(match[1], 10);
      const nestedIndex = match[2] ? parseInt(match[2], 10) : null;
      const type = match[3];
      if (type === 'width') {
        debouncedColumnChange(ColumnChangeTypeEnum.Width, index, nestedIndex, previousValue, newValue);
      } else if (type === 'visibleIndex') {
        debouncedColumnChange(ColumnChangeTypeEnum.Position, index, nestedIndex, previousValue, newValue);
      } else if (type === 'visible' && previousValue !== undefined && newValue !== undefined) {
        onColumnChange(ColumnChangeTypeEnum.Visibility, index, nestedIndex, previousValue, newValue);
      }
    },
    [debouncedColumnChange, onColumnChange]
  );

  const noteRenderer = useCallback(
    (cellInfo: { value?: string; row: { data: ProjectsInRealisationOverviewReportData } }) => {
      const hasNoteEditPermission = reportPermissions.some(
        (permission) =>
          permission.property === cellInfo.row.data.idUtvaru &&
          permission.permission === OrgExtendedPermissionValueEnum.write
      );
      if (!!cellInfo.row.data.utvar)
        return (
          <StackPanel className={styles.note}>
            <Typography.Text ellipsis>{cellInfo.row.data.poznamka}</Typography.Text>
            {(hasNoteEditPermission || cellInfo.value) && (
              <Button
                icon={<EditIcon />}
                size="small"
                type="link"
                onClick={() => setSelectedNoteProjectId(cellInfo.row.data.id)}
                className={styles.editButton}
              />
            )}
          </StackPanel>
        );
      return cellInfo.value;
    },
    [reportPermissions]
  );

  const foldableMonthsParentRenderer = useCallback(
    (cellInfo: { column: { caption: string; folded: boolean; columnKey: ProjectsInRealizationPropertyType } }) => {
      const isFolded = !!reportViewConfiguration?.columns?.find(
        (column) => column.columnKey === cellInfo.column.columnKey
      )?.folded;
      return (
        <>
          <Button
            icon={isFolded ? <PlusSquareOutlined /> : <MinusSquareOutlined />}
            type="link"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              toggleColumnGroupVisible(cellInfo.column.columnKey);
            }}
            className={styles.expandColumnButton}
          />
          {cellInfo.column.caption}
        </>
      );
    },
    [toggleColumnGroupVisible, reportViewConfiguration?.columns]
  );

  const [reportSettings, settingsError, settingsLoading, loadReportSettings] = useApiData(
    (ct) => masterApi.projects.admin.orgSettings.id.getpohreportsettings.get(organizationId, ct),
    { autoload: false }
  );

  const [reportData, reportError, reportLoading, loadReport] = useApiData(
    (ct) =>
      masterApi.EsticonReports.org.id.estiConn.id.firms.id.projectsinrealisationpoh.post(
        organizationId,
        estiConnId,
        esticonFirmId,
        { utcFrom: reportDate.utc().toISOString(), processedYear: reportYear },
        ct
      ),
    { autoload: false }
  );
  const [reportNotes, reportNotesError, reportNotesLoading, loadReportNotes, setReportNotes] = useApiData(
    (ct) => masterApi.projects.reports.esticonprojectnotes.id.get(organizationId, ct),
    { autoload: false }
  );

  useEffect(() => {
    loadReportSettings();
    loadReportNotes();
  }, [organizationId]);

  useEffect(() => {
    if (!!esticonFirmId) {
      loadReport();
    }
  }, [esticonFirmId, reportYear, reportDate]);

  const handleReportNotesSave = (savedReports: EstiProjectNoteForHubDto[]) => {
    setReportNotes(savedReports);
    void message.success(intl.formatMessage({ id: 'ProjectsInRealizationOverviewReport.notesSaved' }));
  };

  const reportRowsWithNotes = useMemo(
    () => [
      ...(reportData?.rows.map((row) => ({
        ...row,
        poznamka: [
          { text: row.poznamka, date: row.datumPosledniPoznamky },
          ...(reportNotes
            ?.find((projectNote) => projectNote.esticonProjectId === row.id)
            ?.projectNoteItems.map((note) => ({ text: note.note, date: note.modifiedDate || note.createdDate })) || []),
        ].reduce((newestNote, currentNote) =>
          moment(currentNote.date) > moment(newestNote.date) || !newestNote.text ? currentNote : newestNote
        ).text,
      })) || []),
    ],
    [reportData, reportNotes]
  );

  const {
    clearFilters,
    orderedItems,
    setFilterValue,
    filters: filterValues,
    ...filterProps
  } = useProjectsInRealizationFilter(reportRowsWithNotes);

  useEffect(() => {
    const collectedFilters: Record<string, Serializable> = filterValues.reduce((preset, filter) => {
      return { ...preset, [filter.key]: filter.value };
    }, {});
    onFiltersChange(collectedFilters);
  }, [filterValues, onFiltersChange]);

  useEffect(() => {
    clearFilters();
    Object.entries(reportViewConfiguration?.filters || {}).map((filter) => setFilterValue(filter[0], filter[1]));
  }, [reportViewConfiguration?.id]);

  const projectsInRealizationOverviewByUnitData = useMemo(() => {
    if (!orderedItems) return null;

    return createProjectsInRealisationOverviewReportData(orderedItems, ReportPrecision, intl);
  }, [orderedItems, intl]);

  useEffect(() => {
    onSetVisibleReportRows(
      projectsInRealizationOverviewByUnitData.flatMap((reportRow) => [
        reportRow.id,
        ...reportRow.children?.map((projectRow) => projectRow.id),
      ])
    );
  }, [projectsInRealizationOverviewByUnitData, onSetVisibleReportRows]);

  const selectedEsticonProject = useMemo(
    (): ProjectsInRealisationOverviewReportData =>
      selectedNoteProjectId &&
      projectsInRealizationOverviewByUnitData[0].children.find((project) => project.id === selectedNoteProjectId),
    [selectedNoteProjectId, projectsInRealizationOverviewByUnitData]
  );

  const numberFormats = useMemo(() => getNumberFormatSettings(ReportPrecision), []);

  const handleNoteModalClose = () => {
    setSelectedNoteProjectId(undefined);
    loadReport();
  };

  const getPhaseRowClass = useCallback((data: ProjectsInRealisationOverviewReportData) => {
    if (data?.phase === EstiConProjectPhase.Dokonceno) return 'realization-completed';
    return '';
  }, []);

  const realizationUnitRenderer = useCallback(
    (cellInfo: { value?: string; row: { data: ProjectsInRealisationOverviewReportData } }) => {
      const cellUnit = reportSettings.unitSettings.find((unit) => unit.sign === cellInfo.value);
      return cellUnit ? (
        <div className={styles.cellBorder} style={{ backgroundColor: cellUnit.color }}>
          {cellInfo.value}
        </div>
      ) : (
        <div className={styles.cellBorder}>{cellInfo.value}</div>
      );
    },
    [reportSettings]
  );

  const reportColumns = useMemo(() => {
    const initialColumns = getRealizationOverviewReportColumns(reportYear, intl, numberFormats);
    if (!!reportViewConfiguration?.columns) {
      const newColumns = [
        ...reportViewConfiguration?.columns.map((columnConfiguration) => {
          const columnData = initialColumns.find((data) => data.columnKey === columnConfiguration.columnKey);
          return {
            ...columnData,
            visible: columnConfiguration.visible === undefined ? undefined : columnConfiguration.visible,
            width: columnConfiguration.width,
            columns: columnData.columns?.map((column, columnIndex) => ({
              ...column,
              visible: !!column.parentColumnKey ? !columnConfiguration.folded : true,
              width: columnConfiguration.columns?.[columnIndex]?.width || column.width,
            })),
          };
        }),
        SPACE_FOR_SCROLLBAR_COLUMN,
      ];
      return newColumns;
    }
    return initialColumns;
  }, [reportViewConfiguration?.columns, intl, numberFormats, reportYear]);

  const selectedNoteProjectPermission = useMemo(() => {
    const unitId = reportData?.rows.find((row) => row.id === selectedNoteProjectId)?.idUtvaru;
    return (
      reportPermissions.find((permission) => permission.property === unitId)?.permission ||
      OrgExtendedPermissionValueEnum.none
    );
  }, [selectedNoteProjectId, reportPermissions, reportData]);

  return (
    <>
      <FilterToolbar
        {...filterProps}
        setFilterValue={setFilterValue}
        clearFilters={clearFilters}
        filters={filterValues}
        className={styles.filterToolbar}
      />
      <StackPanel stretch className={styles.reportWrapper}>
        <ContentGate
          loading={(!reportData && reportLoading) || settingsLoading || reportNotesLoading}
          error={reportError || settingsError || reportNotesError}
        >
          {!!reportSettings?.completeRealizationColor && (
            <style
              dangerouslySetInnerHTML={{
                __html: `
                .realization-completed > td:nth-child(n+3) {
                  background-color: ${reportSettings.completeRealizationColor} !important;
                }
              `,
              }}
            />
          )}
          <BudgetGrid
            data={projectsInRealizationOverviewByUnitData}
            columns={reportColumns}
            focusedRowKey={focusedKey}
            onFocusedRowChanged={handleFocusRowChange}
            clearFocusOnSearchChange
            getRowClass={getPhaseRowClass}
            onColumnOptionsChange={handleColumnsChange}
          >
            <Template name="realizationUnit" render={realizationUnitRenderer} />
            <Template name="validation" render={validationRenderer} />
            <Template name="planValidation" render={planValidationRenderer} />
            <Template name="note" render={noteRenderer} />
            <Template name="foldableMonthsParent" render={foldableMonthsParentRenderer} />
            <Template name="subsidiesWithTooltip" render={subsidiesWithTooltip} />
          </BudgetGrid>
          <ProjectsInRealizationNoteModal
            visible={!!selectedNoteProjectId}
            onClose={handleNoteModalClose}
            onSave={handleReportNotesSave}
            orgUserReportPermission={selectedNoteProjectPermission}
            selectedEsticonProject={selectedEsticonProject}
            organizationId={organizationId}
            esticonFirmId={esticonFirmId}
            estiConnId={estiConnId}
            hubProjectNotes={reportNotes}
          />
        </ContentGate>
      </StackPanel>
    </>
  );
};

export default React.memo(ProjectsInRealizationOverviewReport);
