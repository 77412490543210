import { keyBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'store';

export const projectsListSelector = (state: RootState) => state.allProjects.data?.projects;

export const projectsMapSelector = createSelector([projectsListSelector], (projects) => {
  if (projects == null) return null;
  return keyBy(projects, (project) => project.id);
});

export const projectsCardDataSelector = createSelector([projectsListSelector], (projects) => {
  if (!projects) return null;
  return projects.map((project) => {
    return project?.projectCard?.projectCardData;
  });
});

export const projectsCardDataCapacityValuesSelector = createSelector([projectsCardDataSelector], (projectCardData) => {
  if (!projectCardData) return null;
  return projectCardData.map((projectCardData) => {
    return projectCardData;
    // return findInTreeByFunc(projectCardData, (node) => node.name == 'Kapacitní údaje')?.children || [];
  });
});
