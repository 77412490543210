import { api } from 'api';
import {
  DocumentsMultipleRestoreStrategyEnum,
  DocumentsRestoreDto,
  DocumentsRestoreResultDto,
  ServiceError,
} from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import { transformError } from 'components/DocumentMultipleActionError';
import {
  IMultipleDocumentErrorData,
  IMultipleRestoreDuplicateData,
} from 'components/DocumentMultipleActionError/DocumentMultipleActionError';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { processApiError } from 'utils';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import RestoreForm from './RestoreForm';

type Props = InjectedIntlProps &
  FormModalProps<DocumentsRestoreResultDto> & {
    selectedDocuments: Guid[];
  };

const DocumentsRestoreFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, onClose, selectedDocuments, ...restProps } = props;
  const [error, setError] = useState<ServiceError>(null);
  const [selectedOkDocuments, setSelectedOkDocuments] = useState<Guid[]>(null);
  const [strategy, setStrategy] = useState<DocumentsMultipleRestoreStrategyEnum>(null);

  const handleSubmit: FormSubmitHandler<DocumentsRestoreResultDto> = async () => {
    const selected = !!selectedOkDocuments ? selectedOkDocuments : selectedDocuments;
    if (selected.length > 0) {
      const documentRestoreDto: DocumentsRestoreDto = {
        documentsIds: selected,
        strategy: strategy,
      };
      const [err, res] = await api.project.documents.restoreDocuments(documentRestoreDto);
      if (err) {
        processApiError(err, (error: ServiceError) => {
          if (error != null && !!error.errorData) {
            setError(error);
          }
        });
      } else {
        onSubmit(res.data);
        resetDialog();
      }
    } else {
      resetDialog();
      onClose();
    }
    return null;
  };

  const handleClose = () => {
    resetDialog();
    onClose();
  };

  const resetDialog = () => {
    setStrategy(null);
    setError(null);
    setSelectedOkDocuments(null);
  };

  const errors = useMemo((): JSX.Element[] => {
    if (!error) return [];
    switch (error.referenceErrorCode) {
      case ServiceErrorEnum.DocumentMultipleRestoreObstacleError: {
        const selected = !!selectedOkDocuments ? selectedOkDocuments : selectedDocuments;
        const errData = error.errorData as IMultipleDocumentErrorData;
        setSelectedOkDocuments(
          selected.filter((doc) => !errData.documentsErrors.some((errDoc) => errDoc.objectId === doc))
        );
        return errData.documentsErrors.map(transformError);
      }
      case ServiceErrorEnum.DocumentRestoreDuplicateError:
        // eslint-disable-next-line no-case-declarations
        const duplicities = error.errorData as IMultipleRestoreDuplicateData;
        setStrategy(DocumentsMultipleRestoreStrategyEnum.rename);
        return [
          ...(duplicities.documentsErrors ? duplicities.documentsErrors : []).map((dup) => (
            <p
              key={dup.id}
              dangerouslySetInnerHTML={{
                __html: props.intl.formatHTMLMessage(
                  { id: 'DocumentsRestoreRenameInfoModal.dupliciteDocumentError' },
                  { document: dup.name }
                ),
              }}
            />
          )),
          ...(duplicities.directoryErrors ? duplicities.directoryErrors : []).map((dup) => (
            <p
              key={dup.id}
              dangerouslySetInnerHTML={{
                __html: props.intl.formatHTMLMessage(
                  { id: 'DocumentsRestoreRenameInfoModal.dupliciteDirectoryError' },
                  { directory: dup.name }
                ),
              }}
            />
          )),
        ];
    }
    return [];
  }, [error, strategy]);

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      onClose={handleClose}
      titleId="DocumentsRestoreFormModal.title"
      confirmClose={false}
      {...restProps}
      submitTextId={
        strategy === DocumentsMultipleRestoreStrategyEnum.rename
          ? 'DirectoryRestoreFormModal.button.RestoreAndRename'
          : errors.length === 0
          ? 'DirectoryRestoreFormModal.button.Restore'
          : 'DirectoryRestoreFormModal.button.RestoreNext'
      }
    >
      <RestoreForm
        message={props.intl.formatMessage(
          { id: 'DocumentsRestoreForm.RestoreDocuments' },
          { count: !!selectedOkDocuments ? selectedOkDocuments.length : selectedDocuments.length }
        )}
        errorTitleId={
          strategy === null
            ? 'DocumentsRestoreForm.errorsTitle.missed'
            : 'DocumentsRestoreFormModal.errorsTitle.duplicate'
        }
        errors={errors}
      />
    </FormModalWrapper>
  );
};

export default injectIntl(DocumentsRestoreFormModal);
