import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Alert, Button, DatePicker } from 'antd';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { EditIcon } from 'components/Icons/HubActionsIcons';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useIsMounted, useSameCallback } from 'hooks';
import { Moment } from 'moment';
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  value: { startDate: Moment; finishDate: Moment };
  headline: ReactNode;
  onSave?: (startDate: Moment, finishDate: Moment) => Promise<boolean | ReactNode>;
  disableEdit?: boolean;
  loading?: boolean;
  itemId?: Guid;
  icon?: ReactNode;
};

const GeneralDateRageSettingsItem: FunctionComponent<Props> = ({
  disableEdit,
  value,
  onSave,
  headline,
  loading,
  itemId,
  icon,
}) => {
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState<ReactNode>(null);
  const [liveValue, setLiveValue] = useState<{ startDate: Moment | null; finishDate: Moment | null }>(value);

  const isMounted = useIsMounted();

  useEffect(() => {
    setLiveValue(value);
  }, [value]);

  useEffect(() => {
    if (!!itemId) handleCancel();
  }, [itemId]);

  const handleCancel = useCallback(() => {
    setLiveValue(value);
    setError(null);
  }, [value]);

  const handleSave = useSameCallback(async () => {
    if (onSave) {
      setSaving(true);
      const result = await onSave(liveValue.startDate, liveValue.finishDate);
      if (!isMounted) {
        return;
      }
      if (result !== false && result !== true) {
        setError(result);
      } else if (result) {
        setError(null);
        setEditing(false);
      }
      setSaving(false);
    } else {
      setEditing(false);
    }
  });

  const handleInputChange = (value: [Moment, Moment]) => {
    setLiveValue({ startDate: value?.[0] || null, finishDate: value?.[1] || null });
  };

  const isDirty = liveValue !== value;

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancelEditing = () => {
    setLiveValue(value);
    setError(null);
    setEditing(false);
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Escape':
          handleCancelEditing();
          break;
        case 'Enter':
          void handleSave();
          break;
      }
    },
    [handleSave, handleCancelEditing]
  );

  useEffect(() => {
    if (!disableEdit && isDirty) {
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
    return () => {};
  }, [handleKeyDown, disableEdit, isDirty]);

  return (
    <GeneralSettingsItem
      icon={icon}
      disabled={disableEdit}
      title={headline}
      description={
        !editing ? (
          !!value?.startDate &&
          !!value?.finishDate &&
          value?.startDate?.format?.('L') + ' - ' + value?.finishDate?.format?.('L')
        ) : (
          <>
            <FlowLayout growFirst>
              <DatePicker.RangePicker
                format="L"
                allowClear
                allowEmpty={[true, true]}
                mode={['date', 'date']}
                value={[liveValue?.startDate, liveValue?.finishDate]}
                autoFocus
                onChange={handleInputChange}
              />
            </FlowLayout>
            {!!error && <Alert type="error" banner message={error} />}
          </>
        )
      }
      additionalActions={
        <>
          {!editing ? (
            <Button type="link" icon={<EditIcon />} onClick={handleEdit} disabled={disableEdit} />
          ) : (
            <>
              <Button type="link" icon={<SaveOutlined />} onClick={handleSave} disabled={!isDirty} loading={loading} />
              <Button type="link" icon={<CloseOutlined />} onClick={handleCancelEditing} />
            </>
          )}
        </>
      }
    />
  );
};

export default GeneralDateRageSettingsItem;
