import { CloseOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';
import { FrontendFilter, Serializable } from 'components/filters/filterTypes';
import { useCache, useFrontendFilters } from 'hooks';
import { isEqual } from 'lodash';
import React, { Fragment, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { ignoreRef } from 'utils';
import styles from './FiltersFormItem.module.less';

type Props<T> = {
  value: Record<string, Serializable>;
  onChange: (value: Record<string, Serializable>) => void;
  filters: FrontendFilter<T>[];
  filteredData?: T[];
};

export const FiltersFormItemComponent: FunctionComponent<Props<any>> = ({ value, onChange, filters, filteredData }) => {
  const { filters: filtersWithValues, setFilterValue, clearFilters, orderedItems } = useFrontendFilters(
    filters,
    [],
    filteredData || []
  );
  const [isInitialized, setInitialized] = useState<boolean>();

  useEffect(() => {
    if (!!value) {
      Object.entries(value).forEach((filter) => setFilterValue(filter[0], filter[1]));
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    const collectedFilters: Record<string, Serializable> = filtersWithValues.reduce((preset, filter) => {
      return { ...preset, [filter.key]: filter.value };
    }, {});
    if (!isEqual(value, collectedFilters) && isInitialized) {
      onChange(collectedFilters);
    }
  }, [filtersWithValues]);

  const setValueByKey = useCache(
    (key: string) => (data: React.SetStateAction<Serializable>) => setFilterValue(key, data),
    [value, setFilterValue]
  );

  const clearFilterValues = useCallback(() => {
    onChange({});
    clearFilters();
  }, [clearFilters, onChange]);

  return (
    <div className={styles.wrapper}>
      {filtersWithValues.map((filter) => {
        return <Fragment key={filter.key}>{filter?.render?.(filter.value, setValueByKey(filter.key))}</Fragment>;
      })}
      {!!filteredData && (
        <Tag color={orderedItems.length !== filteredData?.length ? 'error' : 'default'} className={styles.itemCounter}>
          {orderedItems.length} / {filteredData?.length || 0}
        </Tag>
      )}
      <Button onClick={clearFilterValues} shape="circle" type="primary" icon={<CloseOutlined />} />
    </div>
  );
};

export const FiltersFormItem = ignoreRef(FiltersFormItemComponent) as FunctionComponent<
  Omit<Props<any>, 'value' | 'onChange'>
>;
