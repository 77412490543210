import { ApiPromise } from 'api/await-to';
import { DownloadUrl } from 'api/completeApiInterfaces';
import { DiscussionAttachment } from 'components/DiscussionChat/DiscussionInput/DiscussionInput';
import { CommonDocument } from 'components/DocumentCompleteList/DocumentCompleteList';
import { useSameCallback } from 'hooks/useSameCallback';
import React, { FunctionComponent, useMemo } from 'react';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper } from '../FormModalWrapper';
import { DiscussionLinkAttachmentForm, DiscussionLinkAttachmentFormData } from './DiscussionLinkAttachmentForm';
import styles from './DiscussionLinkAttachmentFormModal.module.less';

type Props = FormModalProps<DiscussionLinkAttachmentFormData> & {
  availableAttachments: DiscussionAttachment[];
  getOriginalUrl?: (id: Guid) => ApiPromise<DownloadUrl>;
};

export const DiscussionLinkAttachmentFormModal: FunctionComponent<Props> = ({
  onSubmit,
  onClose,
  availableAttachments,
  getOriginalUrl,
  ...restProps
}) => {
  const handleSubmit = useSameCallback(async (values: DiscussionLinkAttachmentFormData) => {
    onSubmit && (await onSubmit(values));
    return null;
  });

  const documents = useMemo(
    () =>
      availableAttachments.map(
        (attachment): CommonDocument => ({
          id: attachment.id,
          name: attachment.name,
          primaryFile: attachment.file,
          labels: [],
          createdBy: undefined,
          createdDate: undefined,
          revision: undefined,
        })
      ),
    [availableAttachments]
  );

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      onClose={onClose}
      titleId="DiscussionNote.linkAttachments.title"
      width={600}
      className={styles.attachmentModal}
      {...restProps}
      submitTextId="DiscussionNote.linkAttachments.link"
    >
      <DiscussionLinkAttachmentForm availableAttachments={documents} getOriginalUrl={getOriginalUrl} />
    </FormModalWrapper>
  );
};
