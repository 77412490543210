import { WarningOutlined } from '@ant-design/icons';
import { Form, Input, InputRef, Select, Typography } from 'antd';
import { apiConstraints } from 'api/completeApiConstraints';
import { WorkflowStateEnum } from 'api/completeApiInterfaces';
import { Margin } from 'components/Margin/Margin';
import RevisionNumberTag from 'components/RevisionNumberTag/RevisionNumberTag';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FC } from 'react';
import { NEW_DOCUMENT_STATES } from 'utils/documentStateUtils';
import styles from './DocumentOnlineEditorRevisionCreateForm.module.less';

export type DocumentOnlineEditorRevisionCreateFormData = {
  state: WorkflowStateEnum;
  description: string;
};

type Props = {
  isOldRevision: boolean;
  setRef: (ref: InputRef) => void;
  allowedStates: WorkflowStateEnum[];
};

const DocumentOnlineEditorRevisionCreateForm: FC<Props> = ({ isOldRevision, allowedStates, setRef }) => {
  const intl = useIntl();

  return (
    <>
      {isOldRevision && (
        <Margin bottom>
          <Typography.Text strong type="danger">
            <WarningOutlined /> <Fmt id="DocumentCreateForm.form.oldRevisionWarning" />
          </Typography.Text>
        </Margin>
      )}
      <Form.Item
        name="state"
        initialValue={null}
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: 'DocumentCreateForm.form.items.state.rules.required' }),
          },
        ]}
        label={intl.formatMessage({ id: 'DocumentCreateForm.form.items.state.label' })}
      >
        <Select
          options={(allowedStates?.length ? allowedStates : NEW_DOCUMENT_STATES).map((state) => ({
            label: (
              <div className={styles.stateOption}>
                <RevisionNumberTag state={state} showTitle />
              </div>
            ),
            key: state,
            value: state,
          }))}
        />
      </Form.Item>
      <Form.Item
        name="description"
        initialValue=""
        preserve
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: 'RevisionCreateForm.form.items.description.rules.required' }),
          },
          {
            max: apiConstraints.prepareAddRevisionRequestDto.description.maxLength,
            message: intl.formatMessage({ id: 'general.maxDescriptionLength' }),
          },
        ]}
        label={intl.formatMessage({ id: 'RevisionCreateForm.form.items.description.label' })}
      >
        <Input.TextArea
          autoFocus
          ref={setRef}
          rows={3}
          autoSize={{ minRows: 3 }}
          placeholder={intl.formatMessage({ id: 'RevisionCreateForm.form.items.description.placeholder' })}
          className={'scrollbar'}
        />
      </Form.Item>
    </>
  );
};

export default DocumentOnlineEditorRevisionCreateForm;
