import {
  JSONVariableTypeEnum,
  MdMeDto,
  MdProjectDataDto,
  MdProjectListDto,
  MdProjectPhaseEnum,
  MdProjectStateEnum,
  MdProjectVariableEnum,
  MdRoleEnum,
} from 'api/completeApiInterfaces';
import { MDCkApprovalCondition } from 'components/JSONVariableFormItems/Items/JSONVariableConditionsFormItem';
import { MAP_CK_IDENTIFICATIONS_ENUM_TO_INTL_MESSAGE_ID } from 'components/Reports/MDProjectCard/MDProjectCardCreateForm/MDProjectCreateForm';
import { InjectedIntl } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import moment from 'moment';

export const MAP_MD_PROJECT_PHASES_TO_MESSAGE_IDS: Record<MdProjectPhaseEnum, IntlMessageId> = {
  [MdProjectPhaseEnum.init]: 'MD.ProjectPhase.init',
  [MdProjectPhaseEnum.intention]: 'MD.ProjectPhase.intention',
  [MdProjectPhaseEnum.other]: 'MD.ProjectPhase.other',
  [MdProjectPhaseEnum.study]: 'MD.ProjectPhase.study',
  [MdProjectPhaseEnum.update]: 'MD.ProjectPhase.update',
};

//TODO:  Zahodit, až bude umět enum vygenerovat BE
//TODO: for Stanicek: to the beat of 1000 BPM, Dude
export enum MdProjectDataActionCharacterEnum {
  investment = 'investment',
  nonInvestment = 'nonInvestment',
  other = 'other',
}

export const MAP_MD_ACTION_CHARACTER_TO_MESSAGE_IDS: Record<MdProjectDataActionCharacterEnum, IntlMessageId> = {
  [MdProjectDataActionCharacterEnum.investment]: 'MD.Project.ActionCharacter.investment',
  [MdProjectDataActionCharacterEnum.nonInvestment]: 'MD.Project.ActionCharacter.nonInvestment',
  [MdProjectDataActionCharacterEnum.other]: 'MD.Project.ActionCharacter.other',
};

export const getCurrentMdProjectStateMessageId = (state: MdProjectStateEnum, previousState: MdProjectStateEnum) => {
  switch (state) {
    case MdProjectStateEnum.entering:
      return previousState === MdProjectStateEnum.entering
        ? 'MD.Projects.materialPhases.new'
        : 'MD.Projects.materialPhases.returnedToProposer';
    case MdProjectStateEnum.divisionSetting:
      return previousState === MdProjectStateEnum.entering
        ? 'MD.Projects.materialPhases.submittedToO910'
        : 'MD.Projects.materialPhases.returnedToO910';
    case MdProjectStateEnum.guarantorSetting:
      return 'MD.Projects.materialPhases.forwardedToDivision';
    case MdProjectStateEnum.projectValidation:
      return 'MD.Projects.materialPhases.forwardedToGuarantor';
    case MdProjectStateEnum.projectProcessing:
      return 'MD.Projects.materialPhases.CKprocessing';
    default:
      return 'MetadataExportImportButton.importFailReason.valueCastError';
  }
};

export type MDGridData = {
  id: Guid;
  key: Guid;
  intentionerId?: Guid;
  actionName: string;
  isprofond: string;
  actionCharacter: string;
  ckIdentificaion: string;
  constructionRegion: string;
  stretch: string;
  totalCost: number;
  expectedRealisationTimeFrom: string;
  expectedRealisationTimeTo: string;
  ckApprovalDate: string;
  ckApprovalConditionsText: string;
  ckApprovalConditions: MDCkApprovalCondition[];
  isUpToDate: boolean;
  projectPhase: string;
  projectState: string;
  projectStateText: string;
  documentationLink: string;
  createdDate: IsoDateTime;
  lastActualizationDate: IsoDateTime;
  isAfterDeadline: boolean;
  preparedForCkApproval: boolean;
  children?: MDGridData[];
};

export const getCkConditionsMetStateIntlId = (conditions: MDCkApprovalCondition[]): IntlMessageId => {
  if (!conditions || !conditions?.length) return null;
  if (!conditions.some((condition) => !condition.ApprovalDone)) return 'MD.MDCkConditionsEnum.conditionsMet';
  if (
    conditions.some(
      (condition) => !condition.ApprovalDone && moment(condition.Deadline).endOf('day') < moment().endOf('day')
    )
  )
    return 'MD.MDCkConditionsEnum.conditionsNotMet';
  return 'MD.MDCkConditionsEnum.withConditions';
};

export const getMDProjectActionCHaracterIntlId = (projectData: MdProjectDataDto[], variable: MdProjectVariableEnum) => {
  const data = projectData?.find((project) => project.variable === variable)?.data || undefined;
  if (!data || !('value' in data)) return null;
  return MAP_MD_ACTION_CHARACTER_TO_MESSAGE_IDS[data.value as MdProjectDataActionCharacterEnum];
};

export const parseListValueToGridValue = (
  projectData: MdProjectDataDto[],
  variable: MdProjectVariableEnum,
  isFrom?: boolean
) => {
  if (!projectData) return undefined;
  const data = projectData?.find((project) => project.variable === variable)?.data || undefined;
  if (!data) return undefined;
  if (variable === MdProjectVariableEnum.expectedRealisationTime && data.type === JSONVariableTypeEnum.interval)
    return isFrom ? (data.valueFrom as string) : (data.valueTo as string);
  if (variable == MdProjectVariableEnum.preparedForCkApproval && data.type === JSONVariableTypeEnum.boolean) {
    return data.value == 'true' ? true : data.value == 'false' ? false : undefined;
  }

  return 'value' in data ? data.value : undefined;
};

export const mapFlatDataToGridData = (partialList: MDGridData[], fullList: MDGridData[]) => {
  const gridData: MDGridData[] = [];
  partialList?.forEach((row) => {
    const childsky = fullList.filter((project) => project.intentionerId === row.id);
    gridData.push({ ...row, children: !!childsky.length ? mapFlatDataToGridData(childsky, fullList) : [] });
  });
  return gridData;
};

export const mapListObjectsToFlatData = (partialList: MdProjectListDto[], intl: InjectedIntl) => {
  const gridFlatData: MDGridData[] = [];

  partialList.forEach((mdProject) => {
    const approvalConditions = parseListValueToGridValue(
      mdProject.projectData,
      MdProjectVariableEnum.ckApprovalConditions
    ) as MDCkApprovalCondition[];

    const isAfterDeadline = approvalConditions?.some(
      (condition) => !condition.ApprovalDone && moment(condition.Deadline) < moment()
    );

    gridFlatData.push({
      id: mdProject.id,
      key: mdProject.id,
      intentionerId: mdProject.intentionerId,
      actionName: mdProject.name,
      createdDate: mdProject.createdDate,
      lastActualizationDate: mdProject.lastUpdateDate,
      projectPhase: intl.formatMessage({ id: MAP_MD_PROJECT_PHASES_TO_MESSAGE_IDS[mdProject.phase] }),
      projectStateText: intl.formatMessage({
        id: getCurrentMdProjectStateMessageId(mdProject.state, mdProject.previousState),
      }),
      projectState: mdProject.state,
      isAfterDeadline: isAfterDeadline,
      isprofond: parseListValueToGridValue(mdProject.projectData, MdProjectVariableEnum.isProFond) as string,
      actionCharacter: !!getMDProjectActionCHaracterIntlId(mdProject.projectData, MdProjectVariableEnum.actionCharacter)
        ? intl.formatMessage({
            id: getMDProjectActionCHaracterIntlId(
              mdProject.projectData,
              MdProjectVariableEnum.actionCharacter
            ) as IntlMessageId,
          })
        : null,
      preparedForCkApproval: parseListValueToGridValue(
        mdProject.projectData,
        MdProjectVariableEnum.preparedForCkApproval
      ) as boolean,
      ckIdentificaion: !!mdProject.ckIdentificaion
        ? intl.formatMessage({
            id: MAP_CK_IDENTIFICATIONS_ENUM_TO_INTL_MESSAGE_ID[mdProject.ckIdentificaion],
          })
        : null,
      constructionRegion: parseListValueToGridValue(
        mdProject.projectData,
        MdProjectVariableEnum.constructionRegion
      ) as string,
      stretch: parseListValueToGridValue(mdProject.projectData, MdProjectVariableEnum.stretch) as string,
      totalCost: +parseListValueToGridValue(mdProject.projectData, MdProjectVariableEnum.totalCost),
      expectedRealisationTimeFrom: parseListValueToGridValue(
        mdProject.projectData,
        MdProjectVariableEnum.expectedRealisationTime,
        true
      ) as string,
      expectedRealisationTimeTo: parseListValueToGridValue(
        mdProject.projectData,
        MdProjectVariableEnum.expectedRealisationTime,
        false
      ) as string,
      ckApprovalDate: parseListValueToGridValue(mdProject.projectData, MdProjectVariableEnum.ckApprovalDate) as string,
      ckApprovalConditionsText: !approvalConditions?.length
        ? null
        : intl.formatMessage({
            id: getCkConditionsMetStateIntlId(approvalConditions),
          }),
      ckApprovalConditions: approvalConditions,
      isUpToDate: mdProject.isUpToDate,
      documentationLink: parseListValueToGridValue(
        mdProject.projectData,
        MdProjectVariableEnum.documentationLink
      ) as string,
    });
  });
  return gridFlatData;
};

const isReturnedProject = (project: MdProjectListDto) => {
  return (
    (project.state === MdProjectStateEnum.entering && !!project.previousState) ||
    (project.state === MdProjectStateEnum.divisionSetting &&
      project.previousState === MdProjectStateEnum.guarantorSetting)
  );
};

export const filterJustMyDivisionVisibleData = (
  projectList: MdProjectListDto[],
  mdCurrentUser: MdMeDto
): MdProjectListDto[] => {
  return projectList.filter(
    (project) =>
      (project.state === MdProjectStateEnum.divisionSetting &&
        mdCurrentUser?.mdRoles.some((role) => role.mdRoleType === MdRoleEnum.O910)) ||
      (mdCurrentUser?.divisions?.some((division) => division.id === project?.processorDivision?.id) &&
        !isReturnedProject(project)) ||
      mdCurrentUser?.divisions?.some((division) => division.id === project?.proposerDivision?.id)
  );
};

export const filterJustMineVisibleData = (
  projectList: MdProjectListDto[],
  mdCurrentUser: MdMeDto
): MdProjectListDto[] => {
  return projectList.filter(
    (project) =>
      mdCurrentUser?.divisions?.some(
        (division) =>
          division.id === project?.proposerDivision?.id &&
          mdCurrentUser?.mdRoles.some(
            (mdRole) =>
              mdRole.division.id === division.id &&
              (mdRole.mdRoleType === MdRoleEnum.proposer || mdRole.mdRoleType === MdRoleEnum.supervisor)
          )
      ) ||
      (mdCurrentUser?.mdRoles.some((mdRole) => mdRole.mdRoleType === MdRoleEnum.O910) &&
        project.state === MdProjectStateEnum.divisionSetting) ||
      (mdCurrentUser?.divisions?.some(
        (division) =>
          division.id === project?.processorDivision?.id &&
          mdCurrentUser?.mdUsers?.some(
            (mdUser) => mdUser.divisionId === project?.processorDivision?.id && mdUser.isHead
          )
      ) &&
        project.state !== MdProjectStateEnum.entering &&
        project.state !== MdProjectStateEnum.divisionSetting) ||
      (mdCurrentUser?.mdRoles.some(
        (mdRole) =>
          (mdRole.mdRoleType === MdRoleEnum.guarantor && mdRole.id === project.guarantorId) ||
          mdRole.mdRoleType === MdRoleEnum.ck_organizer
      ) &&
        (project.state === MdProjectStateEnum.projectValidation ||
          project.state === MdProjectStateEnum.projectProcessing))
  );
};
