import { Typography } from 'antd';
import PathDisplay from 'components/PathDisplay';
import { Fmt } from 'locale';
import React, { FC, ReactNode } from 'react';
import { ConnectedDirectoryLink } from 'utils/typeMappings/directories/directoryTypes';
import styles from './DirectoryLinkDiscard.module.less';

type Props = {
  title: ReactNode;
  directoryLink: ConnectedDirectoryLink;
};

const DirectoryLinkDiscardForm: FC<Props> = ({ title, directoryLink }) => {
  return (
    <>
      <Typography.Text>{title}</Typography.Text>
      {!!directoryLink.linkedDirectory && (
        <div className={styles.discardDirectoryLinkWrap}>
          <div className={styles.discardDirectoryLinkTitle}>
            <Fmt id="DiscardDirectoryLink.Modal.targetFolder" />
          </div>
          <PathDisplay path={directoryLink.linkedDirectory.path} className={styles.discardDirectoryLinkPath} />
        </div>
      )}
    </>
  );
};

export default DirectoryLinkDiscardForm;
