import { InboxOutlined } from '@ant-design/icons';
import { Collapse, Form, Input, InputRef, Select, Upload } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { UploadChangeParam } from 'antd/lib/upload';
import { DocumentAnnotationSaveDto, WorkflowStateEnum } from 'api/completeApiInterfaces';
import { FileAddIcon } from 'components/Icons/HubActionsIcons';
import PrimaryFileInput from 'components/PrimaryFileInput';
import SingleFileInput from 'components/PrimaryFileInput/SingleFileInput';
import RevisionNumberTag from 'components/RevisionNumberTag/RevisionNumberTag';
import { MAX_ITEM_DESCRIPTION_LENGTH, SIGNED_DOCUMENT_ACCEPTED_CONTENT_TYPES } from 'config/constants';
import { useIntl } from 'hooks';
import React, { FC, Fragment } from 'react';
import { maxLengthRule, requiredRule } from 'utils/formHelpers';
import styles from './RevisionCreateForm.module.less';

export type RevisionCreateFormData = {
  state: WorkflowStateEnum;
  annotations?: DocumentAnnotationSaveDto[];
  description: string;
  primaryFile: File;
  signedDocumentFile?: File;
  secondaryFiles?: UploadChangeParam;
  revisionId?: Guid;
};

type Props = {
  initialPrimaryFile?: File;
  allowedStates?: WorkflowStateEnum[];
  setRef: (ref: InputRef) => void;
  allowSignedDocumentAttachment: boolean;
  revisionId?: Guid;
  selectedRevisionNumber?: number;
};

const RevisionCreateForm: FC<Props> = ({
  initialPrimaryFile,
  allowedStates,
  setRef,
  allowSignedDocumentAttachment,
  revisionId,
  selectedRevisionNumber,
}) => {
  const intl = useIntl();
  const form = useFormInstance();
  const primaryFile = useWatch('primaryFile');

  const primaryFileItem = (
    <Form.Item
      name="primaryFile"
      initialValue={initialPrimaryFile}
      rules={!revisionId ? [requiredRule('forms.items.primaryFile.rules.required')] : null}
      noStyle={primaryFile}
    >
      <PrimaryFileInput />
    </Form.Item>
  );

  // render primary file input to init form steps
  if (!primaryFile && !revisionId) {
    const err = form.getFieldError('primaryFile');
    return (
      <>
        <div>{primaryFileItem}</div>
        {err && <div className={styles.primaryFileError}>{err}</div>}
      </>
    );
  }

  return (
    <>
      {!revisionId && primaryFileItem}
      <Form.Item
        label={intl.formatMessage({ id: 'DocumentCreateForm.form.items.state.label' })}
        name="state"
        initialValue={allowedStates?.length === 1 ? allowedStates[0] : null}
        rules={[requiredRule('DocumentCreateForm.form.items.state.rules.required')]}
      >
        <Select>
          {allowedStates.map((state) => (
            <Select.Option key={state}>
              <div className={styles.stateOption}>
                <RevisionNumberTag state={state} showTitle />
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'RevisionCreateForm.form.items.description.label' })}
        name="description"
        initialValue={
          !isNaN(selectedRevisionNumber)
            ? intl.formatMessage(
                { id: 'RevisionCreateForm.form.items.description.initial' },
                { revisionNumber: selectedRevisionNumber }
              )
            : ''
        }
        preserve={true}
        rules={[
          requiredRule('RevisionCreateForm.form.items.description.rules.required', true),
          maxLengthRule('general.maxDescriptionLength', MAX_ITEM_DESCRIPTION_LENGTH),
        ]}
      >
        <Input.TextArea
          autoFocus
          ref={setRef}
          rows={3}
          autoSize={{ minRows: 3 }}
          placeholder={intl.formatMessage({ id: 'RevisionCreateForm.form.items.description.placeholder' })}
          className={'scrollbar'}
        />
      </Form.Item>
      {allowSignedDocumentAttachment && (
        <Collapse>
          <Collapse.Panel
            header={
              <Fragment>
                <FileAddIcon className={styles.secondaryPanelIcon} />
                <span>{intl.formatMessage({ id: 'SignedDocumentInput.collapse.header' })}</span>
              </Fragment>
            }
            key="signedDocument"
          >
            <Form.Item noStyle name="signedDocumentFile">
              <SingleFileInput
                inputText="SignedDocumentInput.collapse.text"
                inputHint="SignedDocumentInput.collapse.hint"
                acceptedContentType={SIGNED_DOCUMENT_ACCEPTED_CONTENT_TYPES}
              />
            </Form.Item>
          </Collapse.Panel>
        </Collapse>
      )}
      <Collapse>
        <Collapse.Panel
          header={
            <Fragment>
              <FileAddIcon className={styles.secondaryPanelIcon} />
              <span>{intl.formatMessage({ id: 'SecondaryFilesInput.collapse.header' })}</span>
            </Fragment>
          }
          key="secondaryFiles"
        >
          <Form.Item noStyle name="secondaryFiles">
            <Upload.Dragger multiple={true} beforeUpload={() => false} customRequest={() => {}}>
              <p className="ant-upload-text">{intl.formatMessage({ id: 'SecondaryFilesInput.collapse.text' })}</p>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Collapse.Panel>
      </Collapse>
      <Form.Item name="revisionId" initialValue={revisionId || ''}>
        <Input type="hidden" />
      </Form.Item>
    </>
  );
};

export default RevisionCreateForm;
