import { OrganizationStructureDto, OrganizationStructureListDto } from 'api/completeApiInterfaces';
import { createSelector } from 'reselect';
import { RootState } from 'store';
import { numberComparer } from 'utils/comparators';

const sortStructureEntities = (structure: OrganizationStructureDto[]): OrganizationStructureDto[] => {
  const levelSort = structure.sort(numberComparer.map((struct) => struct.order));
  levelSort.map((item) => sortStructureEntities(item.children || []));
  return levelSort;
};

export const organizationStructureListSelector = (state: RootState) => state.organizationStructureList.data;

export const organizationStructureOrderedListSelector = createSelector(
  [organizationStructureListSelector],
  (list: OrganizationStructureListDto) =>
    (list && !!list?.organizationStructure?.length && sortStructureEntities(list.organizationStructure)) || []
);
