import { InputRef } from 'antd';
import { api } from 'api';
import { GroupDto } from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import { AxiosResponse } from 'axios';
import { useFocus } from 'hooks/useFocus';
import React, { FunctionComponent } from 'react';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import { GroupForm, GroupFormData } from './GroupForm';

type Props = FormModalProps<GroupDto> & {
  validateUniqueName: (name: string) => boolean;
  defaults?: GroupDto;
};

const GroupFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, validateUniqueName, defaults, ...restProps } = props;

  const handleSubmit: FormSubmitHandler<GroupFormData> = async (values) => {
    let err: ApiError;
    let resp: AxiosResponse<GroupDto>;

    if (defaults) {
      // update
      [err, resp] = await api.project.groups.updateGroupById(defaults.id, values);
      if (err) return err;
    } else {
      // create
      [err, resp] = await api.project.groups.createGroup(values);
      if (err) return err;
    }

    onSubmit(resp.data);
    return null;
  };

  const { setInputRef } = useFocus<InputRef>(restProps.open);

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      title={defaults ? defaults.name : null}
      titleId="GroupFormModal.title.create"
      width="700px"
      {...restProps}
    >
      <GroupForm validateUniqueName={validateUniqueName} defaults={defaults} setRef={setInputRef} />
    </FormModalWrapper>
  );
};

export default GroupFormModal;
