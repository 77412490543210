import { Tree } from 'antd';
import { DataNode } from 'antd/lib/tree';
import { EditableTreeItemContextProvider } from 'components/EditableTree/EditableTreeItemContext';
import EmptyStyled from 'components/Empty/EmptyStyled';
import { FlowLayout } from 'components/layouts/FlowLayout';
import React, { Key, useCallback, useMemo, useState } from 'react';
import { filterTreeByName, findTreeNodeParent, TreeNodeWithName } from 'utils/tree/treeHelpers';

export type EditableTreeProps<T> = {
  treeData: T[];
  search?: string;
  itemButtons?: React.ReactNode;
  onSelect: (id: string) => void;
  selectedItemId?: string;
  height: number;
  draggable?: boolean;
  onMoveNode?: (
    targetParentNodeId: string,
    sourceParentNodeId: string,
    dragNodeId: string,
    targetIndex: number,
    sourceIndex: number,
    haveSameParent: boolean
  ) => void;
  isDirty?: boolean;
  disableDrop?: (dragNode: T, dropNode: T, dropPosition: -1 | 0 | 1) => boolean;
};

export const EditableTree = <T extends TreeNodeWithName & DataNode>({
  treeData,
  search,
  itemButtons,
  onSelect,
  selectedItemId,
  height,
  draggable,
  onMoveNode,
  disableDrop,
}: EditableTreeProps<T>) => {
  const filteredTreeData = filterTreeByName(treeData, search);
  const [editedNodeIds, setEditedNodeIds] = useState<Set<string>>();

  const onDrop = useCallback(
    (info: any) => {
      if (!onMoveNode) return;

      const dragNode = info.dragNode;
      const node = info.node;
      const parentTargetNode = findTreeNodeParent(treeData, node.id);
      const sourceParentNode = findTreeNodeParent(treeData, dragNode.id);

      if (info.dropToGap) {
        const sourceIndex = sourceParentNode
          ? sourceParentNode.children?.findIndex((n) => n.id === dragNode.id)
          : treeData.findIndex((n) => n.id === dragNode.id);
        const haveSameParent = parentTargetNode?.id === sourceParentNode?.id;
        if (!haveSameParent || sourceIndex !== info.dropPosition) {
          setEditedNodeIds(new Set([...(editedNodeIds || []), node.id]));
        }
        onMoveNode(
          parentTargetNode?.id,
          sourceParentNode?.id,
          dragNode.id,
          info.dropPosition,
          sourceIndex,
          haveSameParent
        );
        // if parent id is undefined, it means it's on a root level
      } else {
        const sourceIndex = sourceParentNode
          ? sourceParentNode.children?.findIndex((n) => n.id === dragNode.id)
          : treeData.findIndex((n) => n.id === dragNode.id);
        const haveSameParent = node?.id === sourceParentNode?.id;
        if (!haveSameParent || sourceIndex !== 0) {
          setEditedNodeIds(new Set([...(editedNodeIds || []), node.id]));
        }
        onMoveNode(node?.id, sourceParentNode?.id, dragNode.id, 0, sourceIndex, haveSameParent);
      }
    },
    [treeData, onMoveNode, editedNodeIds]
  );

  const selectedKeys = useMemo(() => (selectedItemId ? [selectedItemId] : []), [selectedItemId]);
  const handleSelect = useCallback(
    (ids: Key[]) => {
      onSelect(ids[0] as string);
    },
    [onSelect]
  );

  const handleAllowDrop = useCallback((info) => !disableDrop?.(info.dragNode, info.dropNode, info.dropPosition), [
    disableDrop,
  ]);

  const titleRender = useCallback(
    (node: T) => (
      <EditableTreeItemContextProvider item={node}>
        <FlowLayout growFirst>
          {node.title}
          {selectedItemId === node.id && <>{itemButtons}</>}
        </FlowLayout>
      </EditableTreeItemContextProvider>
    ),
    [selectedItemId, itemButtons]
  );

  return !treeData?.length ? (
    <EmptyStyled />
  ) : (
    <Tree
      height={height}
      virtual
      draggable={draggable}
      onDrop={onDrop}
      selectable
      allowDrop={handleAllowDrop}
      checkable={false}
      selectedKeys={selectedKeys}
      onSelect={handleSelect}
      blockNode
      treeData={filteredTreeData}
      defaultExpandAll
      titleRender={titleRender}
    />
  );
};
