import { PlusOutlined, SisternodeOutlined, SubnodeOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { JSONVariableTypeEnum, OrgProjectCardDataDto } from 'api/completeApiInterfaces';
import { EditableTree, EditableTreeProps } from 'components/EditableTree/EditableTree';
import { EditableTreeAddNode } from 'components/EditableTree/EditableTree.AddNode';
import { EditableTreeDeleteButton } from 'components/EditableTree/EditableTree.DeleteButton';
import { EditableTreeEditButton } from 'components/EditableTree/EditableTree.EditButton';
import { useDirtyState } from 'components/EditableTree/useDirtyState';
import EmptyStyled from 'components/Empty/EmptyStyled';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import { HeightMeasureWrap } from 'components/FullHeightWrap/HeightMeasureWrap';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import StackPanel from 'components/StackPanel';
import { useIntl } from 'hooks';
import { useHeight } from 'hooks/useHeight';
import produce from 'immer';
import { Fmt, InjectedIntl } from 'locale';
import ProjectCardDataEditForm, {
  ProjectCardDataEditFormData,
} from 'pages/OrganizationsSettingPage/ProjectCardData/ProjectCardDataEditForm';
import Panel from 'pages/ProjectSettingsPage/Panel';
import React, { CSSProperties, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { filterTreeByName, findInTree, findTreeNodeParent, moveInTree } from 'utils/tree/treeHelpers';
import { v4 as uuid } from 'uuid';
import ProjectCardDataAddForm, { ProjectCardDataAddFormData } from './ProjectCardDataAddForm';

const IN_FRONT_STYLE = { zIndex: 100 };
const PANEL_STYLE: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
};
const DISABLE_DROP = (_: ProjectCardTreeData, dropNode: ProjectCardTreeData, position: -1 | 0 | 1) =>
  dropNode.type !== JSONVariableTypeEnum.empty && position === 0;

type ProjectCardTreeData = {
  key: Guid;
  id: Guid;
  title: React.ReactNode;
  children: ProjectCardTreeData[];
  name: string;
  type?: JSONVariableTypeEnum;
};

const mapDataToTreeData = (
  data: OrgProjectCardDataDto[],
  intl: InjectedIntl,
  editedNodeIds: Set<string>,
  newNodeIds: Set<string>
): ProjectCardTreeData[] => {
  if (!data) return [];
  return data.map((item) => {
    return {
      key: item.id,
      id: item.id,
      name: item.name,
      title: (
        <div>
          {newNodeIds?.has(item.id) ? '*' : null}
          {newNodeIds?.has(item.id) || editedNodeIds?.has(item.id) ? <strong>{item.name}</strong> : item.name}
          {item.unit ? <Typography.Text italic type="secondary">{` [${item.unit}]`}</Typography.Text> : null}
        </div>
      ),
      children: item.children ? mapDataToTreeData(item.children, intl, editedNodeIds, newNodeIds) : [],
      type: item.variable,
    };
  });
};

type Props = {
  organizationId: Guid;
  setProjectCardData: (data: OrgProjectCardDataDto[]) => void;
  projectCardData: OrgProjectCardDataDto[];
  buttons?: React.ReactNode;
  isDirty?: boolean;
};

const ProjectCardDataListPanel: FunctionComponent<Props> = ({
  projectCardData,
  setProjectCardData,
  organizationId,
  buttons,
  isDirty,
}) => {
  const intl = useIntl();
  const [search, setSearch] = useState<string>('');
  const { url } = useRouteMatch<{ rootId: Guid }>();
  const [editingProjectCardData, setEditingProjectCardData] = useState<OrgProjectCardDataDto | null>(null);
  const [treeData, setTreeData] = useState<ProjectCardTreeData[]>();
  const [selectedItemId, onSelect] = useState<string>();

  const [addProjectCardMode, setAddProjectCardMode] = useState<null | 'childNode' | 'sisterNode'>(null);

  const { editedNodeIds, setEdited, newNodeIds, setNew } = useDirtyState(isDirty);

  const addProjectCardDefinition = useCallback(
    async (values: ProjectCardDataAddFormData) => {
      const newItem: OrgProjectCardDataDto = {
        id: uuid(),
        name: values.name,
        variable: values.variable,
        defaultData: values.defaultData || {
          type: values.variable,
          value: null,
        },
        children: [],
        unit: values.unit || '',
        order: 1,
      };

      setNew(newItem.id);

      const cardData = produce(projectCardData, (draft) => {
        const selectedNode = findInTree(draft, selectedItemId);
        if (!selectedNode) {
          draft.push(newItem);
          draft.forEach((item, index) => {
            item.order = index + 1;
          });
        } else {
          if (selectedNode.variable === JSONVariableTypeEnum.empty && values.mode === 'childNode') {
            selectedNode?.children?.splice(0, 0, newItem);
            selectedNode?.children.forEach((item, index) => {
              item.order = index + 1;
            });
          } else {
            const parentNode = findTreeNodeParent(draft, selectedItemId);
            const insertionChildrenList = parentNode?.children || draft;
            const insertionIndex = insertionChildrenList.findIndex((item) => item.id === selectedItemId);
            insertionChildrenList.splice(insertionIndex >= 0 ? insertionIndex + 1 : 0, 0, newItem);
            insertionChildrenList.forEach((item, index) => {
              item.order = index + 1;
            });
          }
        }
      });

      setProjectCardData(cardData);
      setAddProjectCardMode(null);
      return null;
    },
    [organizationId, selectedItemId, editingProjectCardData, setProjectCardData, projectCardData, newNodeIds]
  );

  const onEdit = useCallback(
    (cardDataId: Guid) => {
      setEditingProjectCardData(findInTree(projectCardData, cardDataId));
    },
    [projectCardData]
  );

  const onDelete = useCallback(
    (cardDataId: Guid) => {
      const cardData = produce(projectCardData, (draft) => {
        const deletingNodeParent = findTreeNodeParent(draft, cardDataId);
        if (!!deletingNodeParent && !!deletingNodeParent.children) {
          deletingNodeParent.children = deletingNodeParent.children.filter((item) => item.id !== cardDataId);
          deletingNodeParent.children.forEach((item, index) => {
            item.order = index + 1;
          });
        } else {
          if (draft.length > 0) {
            draft.splice(
              draft.findIndex((item) => item.id === cardDataId),
              1
            );
            draft.forEach((item, index) => {
              item.order = index + 1;
            });
          }
        }
      });

      setProjectCardData(cardData);
    },
    [projectCardData, setProjectCardData]
  );

  const updateProjectCardDefinition: FormSubmitHandler<any> = useCallback(
    (values: ProjectCardDataEditFormData) => {
      const cardData = produce(projectCardData, (draft) => {
        const cardDataId = values.id;
        const editingNode = findInTree(draft, cardDataId);
        editingNode.name = values.name;
        editingNode.unit = values.unit;
        setEdited(cardDataId);
      });

      setProjectCardData(cardData);
      setEditingProjectCardData(null);
      return null;
    },
    [projectCardData, setProjectCardData, editedNodeIds]
  );

  const { tableWrapRef, wrapHeight } = useHeight();
  const filteredTreeData = filterTreeByName(treeData, search);

  useEffect(() => {
    setTreeData(mapDataToTreeData(projectCardData, intl, editedNodeIds, newNodeIds));
  }, [projectCardData, editedNodeIds, newNodeIds]);

  const onMove: EditableTreeProps<OrgProjectCardDataDto>['onMoveNode'] = useCallback(
    (targetParentNodeId, sourceParentNodeId, dragNodeId, targetIndex, sourceIndex, haveSameParent) => {
      const newData = moveInTree(
        projectCardData,
        targetParentNodeId,
        sourceParentNodeId,
        dragNodeId,
        targetIndex,
        sourceIndex,
        haveSameParent
      );
      setEdited(dragNodeId);
      setProjectCardData(newData);
    },
    [projectCardData, setProjectCardData, editedNodeIds]
  );

  return (
    <>
      <MasterComponent
        style={IN_FRONT_STYLE}
        maxWidth={600}
        url={url}
        title={intl.formatMessage({ id: 'OrgProjectCardData.title' })}
        children={() => (
          <>
            <StackPanel autoHeight vertical>
              <Panel
                panelWidth={null}
                noMargin
                onSearch={setSearch}
                searchValue={search}
                panelContentStyle={PANEL_STYLE}
                additionalItems={
                  <Space>
                    <Button onClick={() => setAddProjectCardMode('childNode')} type="primary" icon={<PlusOutlined />}>
                      <Fmt id="general.add" />
                    </Button>
                    {buttons}
                  </Space>
                }
              >
                <HeightMeasureWrap innerRef={tableWrapRef}>
                  {!!filteredTreeData?.length ? (
                    <EditableTree
                      height={wrapHeight}
                      treeData={filteredTreeData}
                      search={search}
                      onSelect={onSelect}
                      selectedItemId={selectedItemId}
                      draggable
                      disableDrop={DISABLE_DROP}
                      onMoveNode={onMove}
                      itemButtons={
                        <>
                          <EditableTreeAddNode
                            icon={<SubnodeOutlined />}
                            mode="childNode"
                            onClick={setAddProjectCardMode}
                          />
                          <EditableTreeAddNode
                            icon={<SisternodeOutlined />}
                            mode="sisterNode"
                            onClick={setAddProjectCardMode}
                          />
                          <EditableTreeEditButton onEdit={onEdit} />
                          <EditableTreeDeleteButton onClick={onDelete} />
                        </>
                      }
                    />
                  ) : !!treeData?.length && !filteredTreeData?.length ? (
                    <ListEmpty
                      onClearSearch={() => setSearch('')}
                      filtered={filteredTreeData?.length || 0}
                      total={treeData?.length || 0}
                    />
                  ) : (
                    <EmptyStyled />
                  )}
                </HeightMeasureWrap>
              </Panel>
            </StackPanel>
          </>
        )}
      />
      {!!addProjectCardMode && (
        <FormModalWrapper
          title={intl.formatMessage({ id: 'OrgProjectCardData.addSupplementData' })}
          open={!!addProjectCardMode}
          onClose={() => setAddProjectCardMode(null)}
          onSubmit={addProjectCardDefinition}
        >
          <ProjectCardDataAddForm mode={addProjectCardMode} />
        </FormModalWrapper>
      )}
      {!!editingProjectCardData && (
        <FormModalWrapper
          title={intl.formatMessage({ id: 'OrgProjectCardData.editSupplementData' })}
          open={!!editingProjectCardData}
          onClose={() => {
            setEditingProjectCardData(null);
          }}
          onSubmit={updateProjectCardDefinition}
        >
          <ProjectCardDataEditForm initialCardData={editingProjectCardData} />
        </FormModalWrapper>
      )}
    </>
  );
};

ProjectCardDataListPanel.displayName = 'OrgProjectCardDataListPanel';

export default ProjectCardDataListPanel;
