import { InputRef } from 'antd';
import { OrgExtendedPermissionEnum } from 'api/completeApiInterfaces';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import {
  PersonalReportWidgetConfiguration,
  ReportWidgetConfiguration,
} from 'components/Reports/contexts/ReportWidgetsContextProvider';
import { RequestCacheApiCallback } from 'hooks/useCachedApiRequest';
import { useFocus } from 'hooks/useFocus';
import React, { FunctionComponent, useCallback } from 'react';
import ReportWidgetEditForm, { ReportWidgetEditFormData } from './ReportWidgetEditForm';

type Props = FormModalProps<ReportWidgetConfiguration> & {
  configuration: PersonalReportWidgetConfiguration;
  requestCacheApiData: RequestCacheApiCallback;
};

const ReportWidgetEditFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, configuration, requestCacheApiData, ...restProps } = props;

  const handleSubmit: FormSubmitHandler<ReportWidgetEditFormData> = useCallback(
    async (values) => {
      if (configuration.data.reportType === OrgExtendedPermissionEnum.projectsInRealisationOverview) {
        const data: ReportWidgetConfiguration = {
          id: configuration.id,
          title: values.name,
          order: configuration.order,
          type: 'personal',
          data: {
            organizationId: configuration.data.organizationId,
            reportType: configuration.data.reportType,
            name: values.name,
            reportDate: values.reportDate,
            startYear: values.startYear,
            filters: values.filters,
            estiConnId: values.estiConnId,
            esticonFirmId: values.esticonFirmId,
            labelAlign: values.labelAlign,
            viewDecimalUnit: values.viewDecimalUnit,
            columnConfigurations: values.columnConfigurations,
          },
        };

        await onSubmit(data);
      }
      return null;
    },
    [onSubmit, configuration]
  );

  const { setInputRef } = useFocus<InputRef>(restProps.open);

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId="ReportWidgetEditFormModal.title"
      submitTextId="general.edit"
      submitTextLoadingId={null}
      width={1000}
      {...restProps}
      // TODO: form className={styles.formWrapper}
    >
      <ReportWidgetEditForm
        configuration={configuration}
        setRef={setInputRef}
        requestCacheApiData={requestCacheApiData}
      />
    </FormModalWrapper>
  );
};

export default ReportWidgetEditFormModal;
