import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, Space } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import {
  JSONVariableTypeEnum,
  ProjectMetadataDefinitionMetadataEntityType,
  ProjectTemplateMetadataDefinitionDto,
} from 'api/completeApiInterfaces';
import { DeleteButton } from 'components/ActionButtons';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import EmptySimple from 'components/EmptySimple';
import { Margin } from 'components/Margin/Margin';
import { useSameCallback } from 'hooks/useSameCallback';
import { Fmt, InjectedIntlProps } from 'locale/Fmt';
import { trim } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import uuid from 'uuid';
import { getMetadataVariableSelectOptions } from './MetadataUtils';

type Props = InjectedIntlProps & {
  metadataDefinitions: ProjectTemplateMetadataDefinitionDto[];
  entityType: ProjectMetadataDefinitionMetadataEntityType;
  onSubmit: (data: ProjectTemplateMetadataDefinitionDto[]) => void;
  open: boolean;
  onClose: () => void;
  title: string;
};

export const ProjectTemplateMetadataDefinitionsSettingModal: FunctionComponent<Props> = ({
  onSubmit,
  onClose,
  open,
  metadataDefinitions,
  entityType,
  title,
  intl,
}) => {
  const [metaDef, setMetaDef] = useState<ProjectTemplateMetadataDefinitionDto[]>([]);
  const [restMetaDef, setRestMetaDef] = useState<ProjectTemplateMetadataDefinitionDto[]>([]);

  const variableTypeOptions: DefaultOptionType[] = useMemo(() => {
    return getMetadataVariableSelectOptions(intl);
  }, [intl]);

  useEffect(() => {
    setMetaDef(metadataDefinitions?.filter((def) => def.entityType === entityType) || []);
    setRestMetaDef(metadataDefinitions?.filter((def) => def.entityType !== entityType) || []);
  }, [entityType, metadataDefinitions]);

  const handleSubmit = useSameCallback(async () => {
    onSubmit && onSubmit([...metaDef, ...restMetaDef]);
  });

  const addEmptyEntity = () => {
    setMetaDef([...metaDef, { id: uuid(), entityType, displayName: undefined, variable: undefined }]);
  };

  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, id: Guid) => {
      setMetaDef(metaDef.map((def) => (def.id === id ? { ...def, displayName: trim(e.target.value) } : def)));
    },
    [metaDef]
  );

  const handleVariableChange = useCallback(
    (value: string, id: Guid) => {
      setMetaDef(metaDef.map((def) => (def.id === id ? { ...def, variable: value as JSONVariableTypeEnum } : def)));
    },
    [metaDef]
  );

  const deleteDefinition = useCallback(
    (id: Guid) => {
      setMetaDef(metaDef.filter((def) => def.id !== id));
    },
    [metaDef]
  );

  const disableDescription = useMemo(() => {
    return metaDef?.some((def) => !def.displayName || !def.variable)
      ? intl.formatMessage({ id: 'general.notSet' })
      : '';
  }, [metaDef]);

  const footer = (
    <div>
      <Button onClick={onClose}>
        <Fmt id="general.close" />
      </Button>

      <CommonHubTooltip title={disableDescription}>
        <Button type="primary" onClick={handleSubmit} disabled={!!disableDescription.length}>
          <Fmt id="general.ok" />
        </Button>
      </CommonHubTooltip>
    </div>
  );

  return (
    <Modal title={title} open={open} footer={footer} width={800} onCancel={onClose} maskClosable={false}>
      <Button icon={<PlusOutlined />} size="small" type="primary" onClick={addEmptyEntity}>
        {intl.formatMessage({ id: 'general.addDefinition' })}
      </Button>
      <Margin top>
        {!metaDef?.length ? (
          <EmptySimple />
        ) : (
          <Space direction="vertical">
            {metaDef.map((definition) => (
              <Space key={definition.id}>
                <Form.Item
                  label={intl.formatMessage({ id: 'ProjectTemplateMetadataDefinitionsSettingModal.inputTitle' })}
                >
                  <Input
                    value={definition.displayName}
                    status={!definition?.displayName?.length ? 'error' : undefined}
                    onChange={(e) => handleNameChange(e, definition.id)}
                  />
                </Form.Item>
                <Form.Item
                  label={intl.formatMessage({ id: 'ProjectTemplateMetadataDefinitionsSettingModal.selectTitle' })}
                >
                  <Select
                    options={variableTypeOptions}
                    value={definition.variable}
                    style={{ width: '20rem' }}
                    status={!definition?.variable ? 'error' : undefined}
                    onChange={(value) => handleVariableChange(value, definition.id)}
                  />
                </Form.Item>
                <Form.Item>
                  <DeleteButton onClick={() => deleteDefinition(definition.id)} />
                </Form.Item>
              </Space>
            ))}
          </Space>
        )}
      </Margin>
    </Modal>
  );
};
