import { LockFilled, UnlockOutlined } from '@ant-design/icons';
import { message } from 'antd';
import Button, { ButtonProps } from 'antd/lib/button';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { api } from 'api';
import { DocumentReservationDto, ProjectUserProfileListDto } from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { useSameCallback } from 'hooks';
import { useReserveDocumentTooltip } from 'hooks/useReserveDocumentTooltip';
import { InjectedIntlProps } from 'locale';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { injectIntl } from 'react-intl';
import { messageError, processApiError } from 'utils';

type Props = ButtonProps &
  InjectedIntlProps & {
    documentId: Guid;
    reservedBy?: ProjectUserProfileListDto;
    reservedDate?: string;
    hideTooltip?: boolean;
    tooltipPlacement?: TooltipPlacement;
    disabled: boolean;
    onChanged: (reservation: DocumentReservationDto) => void;
    releaseReservedDate: IsoDateTime;
  };

const ReserveFileButton: FunctionComponent<Props> = ({
  intl,
  documentId,
  reservedBy,
  reservedDate,
  onClick,
  onChanged,
  hideTooltip,
  disabled,
  tooltipPlacement,
  releaseReservedDate,
  ...restProps
}) => {
  const [loading, setLoading] = useState(false);
  const isReserved = !!(reservedDate && reservedBy);
  const reserve = useCallback(() => api.project.documents.reserveDocument(documentId), [documentId]);
  const release = useCallback(() => api.project.documents.releaseDocumentReservation(documentId), [documentId]);

  const handleClick = useSameCallback(async (e: React.MouseEvent<any, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    onClick && onClick(e);
    const [err, res] = !isReserved ? await reserve() : await release();
    if (err) {
      const error = processApiError(err);
      error.referenceErrorCode === ServiceErrorEnum.DocumentReservationReleaseLockError
        ? message.error(
            intl.formatMessage(
              { id: 'serviceError.DocumentReservationReleaseLockError' },
              { user: reservedBy.username }
            )
          )
        : messageError(err, intl);
    } else {
      if (!isReserved && res.data) {
        onChanged(res.data);
      } else {
        onChanged(null);
      }
    }
    setLoading(false);
  });

  const buttonTooltip = useReserveDocumentTooltip(
    isReserved,
    intl,
    reservedBy?.username,
    reservedDate,
    releaseReservedDate
  );

  const icon = isReserved ? <LockFilled /> : <UnlockOutlined />;

  const button = (
    <Button
      loading={loading}
      icon={icon}
      type="default"
      shape="circle"
      disabled={disabled || loading}
      onClick={handleClick}
      {...restProps}
    />
  );

  if (!hideTooltip) {
    return (
      <CommonHubTooltip placement={tooltipPlacement} title={buttonTooltip}>
        {button}
      </CommonHubTooltip>
    );
  }

  return button;
};

export default injectIntl(ReserveFileButton);
