import { masterApi } from 'api/completeApi';
import {
  OrganizationStructureDto,
  OrganizationStructureListDto,
  ProjectMetadataDefinitionPatchDto,
} from 'api/completeApiInterfaces';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper/FormModalWrapper';
import { useIntl } from 'hooks';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { messageError } from 'utils';
import uuid from 'uuid';
import { OrganizationStructureForm, OrganizationStructureFormData } from './OrganizationStructureForm';

type Props = FormModalProps<OrganizationStructureFormData> & {
  onSubmit: (value: ProjectMetadataDefinitionPatchDto) => void;
  onClose: () => void;
  selectedStructureNode: OrganizationStructureDto;
  organizationStructure: OrganizationStructureListDto;
  organizationId: Guid;
  setOrganizationStructure: React.Dispatch<React.SetStateAction<OrganizationStructureListDto>>;
  parentId?: Guid;
};

const addNewNode = (
  parentId: Guid,
  formValue: OrganizationStructureFormData,
  orgStructure: OrganizationStructureDto[]
): OrganizationStructureDto[] => {
  return orgStructure.map((item) =>
    item.id === parentId
      ? {
          ...item,
          children: [
            ...item.children,
            { id: uuid(), sign: formValue.sign, name: formValue.name, description: formValue.description, order: 1 },
          ],
        }
      : { ...item, children: addNewNode(parentId, formValue, item?.children || []) }
  );
};

const editNode = (
  formValue: OrganizationStructureFormData,
  orgStructure: OrganizationStructureDto[]
): OrganizationStructureDto[] => {
  return orgStructure.map((item) =>
    item.id === formValue.id
      ? {
          ...item,
          sign: formValue.sign,
          name: formValue.name,
          description: formValue.description,
        }
      : { ...item, children: editNode(formValue, item?.children || []) }
  );
};

export const OrganizationStructureFormModal: FunctionComponent<Props> = ({
  onSubmit,
  onClose,
  open,
  setOrganizationStructure,
  selectedStructureNode,
  organizationStructure,
  organizationId,
  parentId,
}) => {
  const intl = useIntl();

  const usedStructureNames = useMemo(() => {
    if (!organizationStructure?.organizationStructure.length) return [];
    return organizationStructure?.organizationStructure.flatMap((structure) => structure.name);
  }, [organizationStructure]);

  const usedStructureSigns = useMemo(() => {
    if (!organizationStructure?.organizationStructure.length) return [];
    return organizationStructure?.organizationStructure.flatMap((structure) => structure.sign);
  }, [organizationStructure]);

  const orgStructArray = useMemo(() => {
    return organizationStructure?.organizationStructure || [];
  }, [organizationStructure]);

  const handleSubmit: FormSubmitHandler<OrganizationStructureFormData> = useCallback(
    async (values) => {
      let data: OrganizationStructureDto[];
      if (!!parentId) {
        data = addNewNode(parentId, values, orgStructArray);
      }
      if (!!values.id) {
        data = editNode(values, orgStructArray);
      }
      if (!parentId && !values.id) {
        data = [
          {
            id: uuid(),
            sign: values.sign,
            name: values.name,
            description: values.description,
            order: 1,
          },
        ];
      }

      const [error, resp] = await masterApi.projects.reports.organizationstructure.id.patch(organizationId, {
        organizationStructure: data,
      });

      if (!!error) {
        messageError(error, intl);
        onClose();

        return null;
      }
      setOrganizationStructure(resp.data);
      onClose();
      return null;
    },
    [parentId, organizationId, setOrganizationStructure, onClose, orgStructArray, intl]
  );

  return (
    <FormModalWrapper
      title={intl.formatMessage({ id: 'JobPositions.addPosition' })}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
    >
      <OrganizationStructureForm
        selectedStructureNode={selectedStructureNode}
        usedNames={usedStructureNames}
        usedSigns={usedStructureSigns}
      />
    </FormModalWrapper>
  );
};
