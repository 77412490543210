import { DeleteButton } from 'components/ActionButtons';
import { DefaultEditableTreeNode, useEditableTreeItemContext } from 'components/EditableTree/EditableTreeItemContext';
import React from 'react';

type Props = {
  onClick: (id: string) => void;
};

export const EditableTreeDeleteButton = ({ onClick }: Props) => {
  const { item } = useEditableTreeItemContext<DefaultEditableTreeNode>();

  return <DeleteButton onClick={() => onClick(item.id)} />;
};
