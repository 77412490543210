import { message } from 'antd';
import { ServiceError } from 'api/completeApiInterfaces';
import { EMPTY_GUID } from 'config/constants';
import { useBoolean, useCurrentAppUser, useIntl } from 'hooks';
import { SavedRecordTypeEnum, useSavedRecordsApi } from 'hooks/useSavedRecordsApi';
import { IntlMessageId } from 'locale/messages/cs';
import React, { createContext, FunctionComponent, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';
import {
  KPIConfiguration,
  KpiRule,
  KpiRuleCondition,
  KpiRuleValidation,
} from '../forms/KpiConfigurationForm/KpiConfigurationForm.utils';
import KpiConfigurationFormModal from '../forms/KpiConfigurationForm/KpiConfigurationFormModal';
import { KpiSettingsToolbar } from '../Kpi/KpiSettingsToolbar';

export type KPISavedConfigurations = {
  configurations: KPIConfiguration[];
};

type ValueType = {
  kpiConfigurations: KPIConfiguration[];
  kpiConfigurationsError: ServiceError;
  kpiConfigurationsLoading: boolean;
  saveKpiConfiguration: (kpiConfiguration: KPIConfiguration) => Promise<void>;
  removeKpiConfiguration: (configurationId: Guid) => Promise<void>;
  showKpiModal: (configurationId?: Guid) => void;
  evaluateKpi: (cellInfo: { value?: number; row: { data: any }; column: { dataField: string } }) => void;
  kpiSettingsRender: () => ReactNode;
};

type AllowedReportTypes =
  | SavedRecordTypeEnum.KPIFinancialOverviewConstructionConfigurations
  | SavedRecordTypeEnum.KPIFinancialOverviewEsticonProjectsByUnitConfigurations;

type Props = {
  reportType: AllowedReportTypes;
};

const validateCondition = (rule: KpiRule, cellValue: number) => {
  switch (rule.condition) {
    case KpiRuleCondition.GreaterThan:
      return cellValue > rule.refValue;
    case KpiRuleCondition.GreaterThanOrEqual:
      return cellValue >= rule.refValue;
    case KpiRuleCondition.LessThan:
      return cellValue < rule.refValue;
    case KpiRuleCondition.LessThanOrEqual:
      return cellValue <= rule.refValue;
    default:
      return false;
  }
};

const supportedKpiReportColumns: Record<AllowedReportTypes, Record<string, IntlMessageId>> = {
  [SavedRecordTypeEnum.KPIFinancialOverviewConstructionConfigurations]: {
    coefficient: 'DrawingActualReportDetail.headers.coeficient',
    margin: 'DrawingActualReportDetail.headers.margin',
  },
  [SavedRecordTypeEnum.KPIFinancialOverviewEsticonProjectsByUnitConfigurations]: {
    coefficient: 'FinancialOverviewByUnitReport.column.coefficient',
  },
};

export const KpiConfigurationContext = createContext<ValueType>(undefined);

export const useKpiConfigurationContext = () => {
  const context = useContext(KpiConfigurationContext);
  if (context === undefined) {
    throw new Error('useKpiConfigurationContext must be used within a KpiConfigurationContextProvider');
  }
  return context;
};

const kpiCellPadding = { padding: '5px 8px' };

const KpiConfigurationContextProvider: FunctionComponent<Props> = ({ children, reportType }) => {
  const [kpiModalVisible, showKpiModal, hideKpiModal] = useBoolean(false);
  const [editedKpiConfiguration, setEditedKpiConfiguration] = useState<KPIConfiguration>();
  const [selectedConfigurationId, setSelectedConfigurationId] = useLocalStorage<Guid>(reportType, EMPTY_GUID);

  const currentAppUser = useCurrentAppUser();
  const intl = useIntl();

  const [
    savedKpiConfigurations,
    savedKpiConfigurationsError,
    savedKpiConfigurationsLoading,
    _dashboardWidgetsSaving,
    saveKPISettings,
  ] = useSavedRecordsApi<KPISavedConfigurations>(currentAppUser.id, reportType);

  const saveKpiConfiguration = useCallback(
    async (kpiConfiguration: KPIConfiguration) => {
      const isNewConfiguration = !savedKpiConfigurations?.data?.configurations.some(
        (config) => config.id === kpiConfiguration.id
      );

      await saveKPISettings({
        configurations: isNewConfiguration
          ? [...(savedKpiConfigurations?.data?.configurations || []), kpiConfiguration]
          : [
              ...(savedKpiConfigurations?.data?.configurations || []).map((config) =>
                config.id === kpiConfiguration.id ? kpiConfiguration : config
              ),
            ],
      });
    },
    [saveKPISettings, savedKpiConfigurations?.data?.configurations]
  );

  const removeKpiConfiguration = useCallback(
    async (widgetId: Guid) => {
      await saveKPISettings({
        configurations: (savedKpiConfigurations?.data?.configurations || []).filter((config) => config.id !== widgetId),
      });
      setSelectedConfigurationId(EMPTY_GUID);
    },
    [savedKpiConfigurations, saveKPISettings]
  );

  const orderedKpiKonfigurations = useMemo(
    () => [...(savedKpiConfigurations?.data.configurations || [])].sort((a, b) => a.title.localeCompare(b.title)),
    [savedKpiConfigurations]
  );

  const kpiConfigurationsError = useMemo(
    () => (savedKpiConfigurationsError?.statusCode !== 404 ? savedKpiConfigurationsError : undefined),
    [savedKpiConfigurationsError]
  );

  const handleKpiConfigurationSave = useCallback(
    async (kpiConfiguration: KPIConfiguration) => {
      await saveKpiConfiguration(kpiConfiguration);
      setEditedKpiConfiguration(undefined);
      hideKpiModal();
      void message.success(intl.formatMessage({ id: 'WidgetReportsContext.message.addWidget.success' }));
      setSelectedConfigurationId(kpiConfiguration.id);
    },
    [saveKpiConfiguration, hideKpiModal, intl, setSelectedConfigurationId]
  );

  const availableColumns = useMemo(() => supportedKpiReportColumns[reportType], [reportType]);

  const showKpiConfigurationModal = useCallback(
    (configurationId?: Guid) => {
      const kpiConfiguration = savedKpiConfigurations?.data.configurations.find(
        (configuration) => configuration.id === configurationId
      );
      setEditedKpiConfiguration(kpiConfiguration);
      showKpiModal();
    },
    [savedKpiConfigurations, showKpiModal]
  );

  const handleKpiConfigurationClose = () => {
    setEditedKpiConfiguration(undefined);
    hideKpiModal();
  };

  const evaluateKpi = useCallback(
    (cellInfo: {
      value?: number;
      text: string;
      row: { data: any };
      column: { dataField: string };
      cellElement: HTMLElement;
    }) => {
      const defaultValue = <div style={kpiCellPadding}>{cellInfo.text}</div>;
      if (!cellInfo.value && cellInfo.value !== 0) return defaultValue;
      const configuration = orderedKpiKonfigurations.find((config) => config.id === selectedConfigurationId);
      if (!configuration) return defaultValue;

      const rule = configuration.rules[cellInfo.column.dataField];
      if (!rule) return defaultValue;
      if (
        validateCondition(rule, cellInfo.value)
          ? rule.validation === KpiRuleValidation.Critical
          : rule.validation === KpiRuleValidation.Ok
      ) {
        return <div style={{ ...kpiCellPadding, backgroundColor: '#ff8888' }}>{cellInfo.text}</div>;
      } else {
        return <div style={{ ...kpiCellPadding, backgroundColor: '#88ff88' }}>{cellInfo.text}</div>;
      }
    },
    [orderedKpiKonfigurations, selectedConfigurationId]
  );

  const kpiSettingsRender = useCallback(() => {
    return (
      <KpiSettingsToolbar
        selectedConfigurationId={selectedConfigurationId}
        kpiConfigurations={orderedKpiKonfigurations}
        onConfigSelect={setSelectedConfigurationId}
        onSettingsClick={showKpiConfigurationModal}
        onDeleteClick={removeKpiConfiguration}
      />
    );
  }, [orderedKpiKonfigurations, selectedConfigurationId, setSelectedConfigurationId, showKpiConfigurationModal]);

  const value: ValueType = useMemo(() => {
    return {
      kpiConfigurations: orderedKpiKonfigurations,
      kpiConfigurationsError: kpiConfigurationsError,
      kpiConfigurationsLoading: savedKpiConfigurationsLoading,
      saveKpiConfiguration,
      removeKpiConfiguration,
      showKpiModal: showKpiConfigurationModal,
      evaluateKpi,
      kpiSettingsRender,
    };
  }, [
    evaluateKpi,
    kpiConfigurationsError,
    orderedKpiKonfigurations,
    removeKpiConfiguration,
    saveKpiConfiguration,
    savedKpiConfigurationsLoading,
    showKpiConfigurationModal,
    kpiSettingsRender,
  ]);

  return (
    <KpiConfigurationContext.Provider value={value}>
      {children}
      <KpiConfigurationFormModal
        open={kpiModalVisible}
        onSubmit={handleKpiConfigurationSave}
        onClose={handleKpiConfigurationClose}
        configuration={editedKpiConfiguration}
        availableColumns={availableColumns}
      />
    </KpiConfigurationContext.Provider>
  );
};

export default KpiConfigurationContextProvider;
