import { InputRef, message } from 'antd';
import { api } from 'api';
import { ProjectBaseListDto, ProjectHubDto, ProjectPatchDto } from 'api/completeApiInterfaces';
import { useApiData, useIntl } from 'hooks';
import { useFocus } from 'hooks/useFocus';
import { LanguageEnum } from 'locale/messages/interfaces';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { processApiError } from 'utils';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import ProjectEditForm, { ProjectEditFormData } from './ProjectEditForm';

type Props = FormModalProps<ProjectHubDto> & { projectToEdit: ProjectBaseListDto; organizationId: Guid };

const ProjectEditFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, projectToEdit, organizationId, ...restProps } = props;
  const intl = useIntl();
  const lang: LanguageEnum = useMemo(() => intl.locale, [intl]);

  const [timeZones, timeZonesError, timeZonesLoading, loadTimeZones] = useApiData(
    (ct) => api.master.projects.getTimeZones(lang, ct),
    { autoload: false }
  );

  useEffect(() => {
    loadTimeZones();
  }, [lang]);

  const handleSubmit: FormSubmitHandler<ProjectEditFormData> = async (values) => {
    const data: ProjectPatchDto = {
      name: values.name,
      description: values.description,
      language: values.language,
      timeZoneId: values.timeZoneId,
      orgLabels: values.labels,
    };

    const [err] = await api.master.projects.patchProject(projectToEdit.id, data);
    if (!err) {
      onSubmit(undefined);
    } else {
      await message.error(
        intl.formatMessage({
          id: `serviceError.${processApiError(err).referenceErrorCode}`,
        })
      );
    }
    return null;
  };

  const { setInputRef } = useFocus<InputRef>(restProps.open);

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId="general.editProject"
      submitTextId="general.save"
      submitTextLoadingId={null}
      {...restProps}
    >
      <ProjectEditForm
        defaults={{ language: LanguageEnum[intl.locale] }}
        timeZones={timeZones}
        projectToEdit={projectToEdit}
        organizationId={organizationId}
        setRef={setInputRef}
      />
    </FormModalWrapper>
  );
};

export default ProjectEditFormModal;
