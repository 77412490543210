import { ButtonsSelect, ButtonsSelectOption } from 'components/ButtonsSelect/ButtonsSelect';
import { useIntl } from 'hooks';
import React, { FunctionComponent, useMemo } from 'react';

export type AllDocumentPageDisplayMode = 'common' | 'metadata';

type Props = {
  displayMode: AllDocumentPageDisplayMode;
  setDisplayMode: React.Dispatch<React.SetStateAction<AllDocumentPageDisplayMode>>;
  disableReason: string;
};

const AllDocumentPageChangeViewButton: FunctionComponent<Props> = ({ displayMode, setDisplayMode, disableReason }) => {
  const intl = useIntl();
  const options = useMemo(
    (): ButtonsSelectOption<AllDocumentPageDisplayMode>[] => [
      {
        label: intl.formatMessage({ id: 'AllDocumentPageChangeViewButton.commonView' }),
        key: 'common',
        tooltip: disableReason,
      },
      {
        label: intl.formatMessage({ id: 'AllDocumentPageChangeViewButton.metadata' }),
        key: 'metadata',
        tooltip: disableReason,
      },
    ],
    [disableReason, intl]
  );

  return (
    <ButtonsSelect<AllDocumentPageDisplayMode>
      onChange={setDisplayMode}
      selectedKey={displayMode}
      options={options}
      size="small"
      disabled={!!disableReason}
    />
  );
};

export default React.memo(AllDocumentPageChangeViewButton);
