import { Button, Form, FormListFieldData, FormListOperation, Select, Spin, TreeSelect } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { Rule } from 'antd/lib/form';
import { DocumentCategoryNodeDto, DocumentCategoryTreeDto } from 'api/completeApiInterfaces';
import { DeleteButton } from 'components/ActionButtons';
import DisplayName from 'components/DisplayName';
import { AddIcon } from 'components/Icons/HubActionsIcons';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useBoolean, useIntl } from 'hooks';
import { Fmt } from 'locale';
import { Dictionary } from 'lodash';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import React, { FC, ReactNode, useEffect, useMemo } from 'react';
import { CategoryTrees } from 'store/models/storeModelinterfaces';
import { smartFilter } from 'utils';
import { requiredRule } from 'utils/formHelpers';
import styles from './DocumentCategoryForm.module.less';

export interface ICategoryListItemData {
  categoryId: Guid;
  required: boolean;
  defaultCategoryNodeId: Guid;
}

export type CategoryList = ICategoryListItemData[];

type CategoryListItem = {
  categoryId: Guid;
  required: boolean;
  nodeId: Guid;
};

type Props = {
  categoryList: DocumentCategoryTreeDto[]; // list of all available categories
  categoryTreeNodes?: Record<Guid, Guid>; // object of document categories with their categoryTreeNode
  categories: CategoryList;
  categoryMap: Dictionary<DocumentCategoryTreeDto>;
  categoryTrees: CategoryTrees;
  autoSelectDefault?: boolean;
  selectPlaceholder?: string;
};

const DocumentCategoryForm: FC<Props> = ({
  categoryMap,
  categoryTrees,
  categoryList,
  categories: _categories = [],
  categoryTreeNodes,
  autoSelectDefault,
  selectPlaceholder,
}) => {
  const intl = useIntl();
  const form = useFormInstance<{ categories: CategoryList }>();
  const categories = useWatch<CategoryListItem[]>('categories');
  const [selectCategoryVisible, setSelectCategoryVisible, clearSelectCategoryVisible] = useBoolean(false);

  useEffect(() => {
    if (!categories?.length && !!_categories?.length) {
      // TODO: required categories dont update when directory is changed and categories are no longer required.
      //  It is because we dont want to replace already set categories (by user) when changing directory.
      //  Solution: merge categories by its keys.

      form.setFieldsValue({
        categories: _categories.map((c) => {
          const selectedNodeId = !categoryTreeNodes
            ? autoSelectDefault
              ? c.defaultCategoryNodeId
              : null
            : categoryTreeNodes[c.categoryId] || c.defaultCategoryNodeId;

          return {
            categoryId: c.categoryId,
            nodeId: selectedNodeId,
            required: c.required,
          };
        }),
      });
    }
  }, [_categories, categories]);

  const getCategoryTreeNode = (
    categoryTreeList: DocumentCategoryNodeDto[],
    parentId: Guid = null
  ): DefaultOptionType[] => {
    return categoryTreeList
      .filter((n) => n.parentId === parentId)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((n) => {
        return {
          value: n.id,
          title: <DisplayName>{n.name}</DisplayName>,
          children: getCategoryTreeNode(categoryTreeList, n.id),
        };
      });
  };

  const renderCategoryTreeSelect = (categoryId: Guid, name: (number | string)[], rules: Rule[]) => {
    const tree = categoryTrees[categoryId] || null;

    if (!tree || !tree.map) return <Spin />;
    const list = Object.values(tree.map);

    const categoryState = !!categories
      ? categories.find((c: CategoryListItem) => c.categoryId === categoryId)
      : undefined;
    const required = categoryState ? categoryState.required : false;

    return (
      <Form.Item name={name} rules={rules}>
        <TreeSelect
          className={styles.treeSelectInput}
          showSearch
          allowClear
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          placeholder={
            selectPlaceholder
              ? selectPlaceholder
              : intl.formatMessage({
                  id: 'DocumentCreateForm.form.items.category.treeSelect.placeholder',
                })
          }
          treeDefaultExpandAll
          autoFocus={!required}
          treeNodeFilterProp={null}
          treeData={getCategoryTreeNode(list)}
          filterTreeNode={(input, node) => smartFilter(node.props.title, input)}
        />
      </Form.Item>
    );
  };

  const restCategoryList = useMemo(() => {
    if (!categoryList || !categories) return [];
    return categoryList.filter((category) => !categories?.some((c) => c.categoryId === category.id));
  }, [categories, categoryList]);

  return (
    <Form.Item label={<Fmt id="DocumentCreateForm.section.categories" />}>
      <Form.List
        name={'categories'}
        initialValue={_categories.map((c) => ({
          categoryId: c.categoryId,
          nodeId: c.defaultCategoryNodeId,
          required: c.required,
        }))}
      >
        {(
          fields: FormListFieldData[],
          { add, remove }: FormListOperation,
          meta: { errors: ReactNode[]; warnings: ReactNode[] }
        ) => (
          <>
            <div className={styles.addButton}>
              {selectCategoryVisible ? (
                <Select
                  className={styles.treeSelectInput}
                  showSearch
                  autoFocus
                  defaultOpen
                  defaultActiveFirstOption={false}
                  filterOption={(input, option) => smartFilter(categoryMap[option.value].name, input)}
                  onSelect={(categoryId: Guid) => {
                    add({ categoryId, required: false, nodeId: undefined }, 0);
                  }}
                  onBlur={clearSelectCategoryVisible}
                  options={restCategoryList.map((category) => ({ value: category.id, label: category.name }))}
                />
              ) : restCategoryList.length === 0 ? (
                <Button block disabled>
                  <Fmt id="DocumentCreateForm.form.items.category.add.noMore" />
                </Button>
              ) : (
                <Button block icon={<AddIcon />} onClick={setSelectCategoryVisible}>
                  <Fmt id="DocumentCreateForm.form.items.category.add" />
                </Button>
              )}
            </div>
            {fields.map(({ key, name, ...restField }, i, data) => {
              const selectedCategory = categories?.[name];
              if (!selectedCategory) return null;

              const categoryId = selectedCategory.categoryId;
              const required = selectedCategory.required;

              if (!categoryId || !categoryMap[categoryId]) {
                return null;
              }

              return (
                <Form.Item
                  required
                  key={key}
                  className={styles.itemRow}
                  label={<DisplayName>{categoryMap[categoryId] ? categoryMap[categoryId].name : ''}</DisplayName>}
                >
                  <FlowLayout growFirst style={{ alignItems: 'flex-start' }}>
                    <div>
                      {renderCategoryTreeSelect(
                        categoryId,
                        [name, 'nodeId'],
                        [requiredRule('DocumentCreateForm.form.items.category.rules.required')]
                      )}
                    </div>
                    <DeleteButton disabled={required} onClick={() => remove(name)} />
                  </FlowLayout>
                </Form.Item>
              );
            })}
          </>
        )}
      </Form.List>
    </Form.Item>
  );
};

export default DocumentCategoryForm;
