import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Checkbox, Form, Input, InputRef, Radio, Select, Tooltip } from 'antd';
import { apiConstraints } from 'api/completeApiConstraints';
import { AppUserDto, OrganizationAdminReportDto, ProjectTimezoneListDto } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { DEFAULT_LOCALE, MAX_PROJECT_DESCRIPTION_LENGTH, MAX_PROJECT_NAME_LENGTH } from 'config/constants';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import { LanguageEnum } from 'locale/messages/interfaces';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';
import React, { FC, useMemo } from 'react';
import {
  czechValidCinWarningRule,
  duplicateNameRule,
  maxLengthRule,
  requiredRule,
  simpleSelectFilter,
} from 'utils/formHelpers';
import { AppUsersFormItem, SelectableUsersSourceEnum, UserAttributes } from '../AppUsersAddFormModal/AppUsersFormItem';

export type OrganizationAddFormData = {
  name: string;
  description: string;
  CIN: string;
  allowableStorageAreas: string[];
  defaultLanguage: LanguageEnum;
  defaultTimeZoneId: string;
  projectAdminCanInviteNewUser: boolean;
  usersToAddWithAttributes: Record<string, UserAttributes>;
};

type Props = {
  currentOrganization?: OrganizationAdminReportDto;
  appUsers: AppUserDto[];
  timeZones: ProjectTimezoneListDto;
  organizations: OrganizationAdminReportDto[];
  setRef: (ref: InputRef) => void;
  configStorageAreas?: string[];
  userCanAddOrganization: boolean;
};

export const noAdminInAppUsersItem = (value: Record<string, UserAttributes>) => {
  if (!!!value) return true;
  const atLeastOneAdmin = Object.values(value).some((value) => value.isAdmin);
  if (!atLeastOneAdmin) return true;
  return false;
};

const OrganizationAddForm: FC<Props> = ({
  currentOrganization,
  appUsers,
  timeZones,
  organizations,
  configStorageAreas,
  userCanAddOrganization,
  setRef,
}) => {
  const intl = useIntl();

  const usedNames = useMemo(() => {
    return organizations
      ?.map((organization) => organization.name)
      .filter((organizationName) => organizationName !== currentOrganization?.name);
  }, [currentOrganization?.name, organizations]);

  const isUserSettingPossible = !currentOrganization;

  const notValidCinInfo = useMemo(() => {
    return (
      <Tooltip title={intl.formatMessage({ id: 'OrganizationAddForm.CinWarning.tooltip' })}>
        <InfoCircleOutlined />{' '}
      </Tooltip>
    );
  }, [intl]);

  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: 'OrganizationAddForm.title' })}
        name="name"
        initialValue={currentOrganization?.name}
        rules={[
          requiredRule('forms.items.rules.required', true),
          maxLengthRule('general.maxNameLength', MAX_PROJECT_NAME_LENGTH),
          !!currentOrganization && duplicateNameRule('forms.items.name.rules.nameExists', usedNames, true),
        ]}
      >
        <Input
          placeholder={intl.formatMessage({ id: 'OrganizationAddForm.title.placeholder' })}
          autoFocus
          ref={setRef}
        />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'OrganizationAddForm.description' })}
        name="description"
        initialValue={currentOrganization?.description}
        rules={[
          {
            max: MAX_PROJECT_DESCRIPTION_LENGTH,
            message: intl.formatMessage(
              { id: 'general.maxDescriptionLength' },
              { max: MAX_PROJECT_DESCRIPTION_LENGTH }
            ),
          },
        ]}
      >
        <Input.TextArea
          rows={3}
          autoSize={{ minRows: 3 }}
          placeholder={intl.formatMessage({ id: 'OrganizationAddForm.description.placeholder' })}
        />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'OrganizationAddForm.CIN' })}
        name="CIN"
        initialValue={currentOrganization?.ico}
        rules={[
          czechValidCinWarningRule(notValidCinInfo),
          maxLengthRule('general.maxLength', apiConstraints.organizationCreateDto.ico.maxLength),
        ]}
      >
        <Input allowClear />
      </Form.Item>
      {!!configStorageAreas?.length && userCanAddOrganization && (
        <Form.Item
          label={intl.formatMessage({ id: 'general.storageareas' })}
          name="allowableStorageAreas"
          initialValue={currentOrganization?.defaultStorageArea}
          rules={[{ required: true, message: intl.formatMessage({ id: 'forms.items.rules.required' }) }]}
        >
          <Select mode="multiple" allowClear showArrow>
            {configStorageAreas.map((area) => (
              <Select.Option key={area}>{area}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item
        label={
          <span>
            <Fmt id="OrganizationAddForm.language" />
            <CommonHubTooltip
              title={intl.formatMessage({ id: 'OrganizationAddForm.language.tooltip' })}
              placement="right"
            >
              <QuestionCircleOutlined style={{ paddingLeft: '8px' }} />
            </CommonHubTooltip>
          </span>
        }
        name="defaultLanguage"
        initialValue={currentOrganization?.defaultLanguage || LanguageEnum[DEFAULT_LOCALE] || LanguageEnum.cs}
      >
        <Radio.Group>
          <Radio.Button value={LanguageEnum.cs}>
            {intl.formatMessage({ id: 'ProjectCreateForm.form.items.language.cs' })}
          </Radio.Button>
          <Radio.Button value={LanguageEnum.en}>
            {intl.formatMessage({ id: 'ProjectCreateForm.form.items.language.en' })}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'ProjectCreateForm.form.timeZone' })}
        name="defaultTimeZoneId"
        initialValue={currentOrganization?.defaultTimeZoneId || timeZones.defaultTimeZoneId}
        rules={[requiredRule('forms.items.rules.required')]}
      >
        <Select showSearch allowClear filterOption={simpleSelectFilter}>
          {timeZones?.timeZones.map((tz) => (
            <Select.Option key={tz.id} value={tz.id}>
              {tz.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        wrapperCol={{ offset: 4, span: 18 }}
        name="projectAdminCanInviteNewUser"
        initialValue={!!currentOrganization && currentOrganization?.projectAdminCanInviteNewUser}
        valuePropName="checked"
      >
        <Checkbox>
          <Fmt id="OrganizationAddForm.addUsersAlsoWithInvitation" />
        </Checkbox>
      </Form.Item>
      {isUserSettingPossible && (
        <Form.Item
          label={intl.formatMessage({ id: 'general.users' })}
          name="usersToAddWithAttributes"
          rules={[
            requiredRule('OrganizationAddForm.required'),
            {
              async validator(rule: RuleObject, value: StoreValue, callback: (error?: string) => void) {
                if (noAdminInAppUsersItem(value)) {
                  callback(intl.formatMessage({ id: 'OrganizationAddForm.required' }));
                }
              },
            },
          ]}
        >
          <AppUsersFormItem
            selectableAppUsers={appUsers}
            selectableUsersToAddSource={SelectableUsersSourceEnum.aspeHub}
          />
        </Form.Item>
      )}
    </>
  );
};

export default OrganizationAddForm;
