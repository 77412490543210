import { Form, Select, Typography } from 'antd';
import { masterApi } from 'api/completeApi';
import { MdProjectDto, MdRoleDto, MdRoleEnum, OrgUserClaimDto } from 'api/completeApiInterfaces';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import { Margin } from 'components/Margin/Margin';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { useMemo, useState } from 'react';
import { messageError } from 'utils';
import { requiredRule } from 'utils/formHelpers';
import MDRoleDisplay from '../MdRoleDisplay/MDRoleDisplay';

type Props = FormModalProps<MdProjectDto> & {
  mdProject: MdProjectDto;
  mdRoles: MdRoleDto[];
  extendedUserClaims: OrgUserClaimDto[];
};

export type MDProjectCardGuarantorSetFormData = {
  guarantorId: Guid;
};

const MDSetGuarantorModal: React.FC<Props> = ({
  mdProject,
  mdRoles,
  extendedUserClaims,
  onClose,
  onSubmit,
  ...modalProps
}) => {
  const intl = useIntl();
  const [isLoading, setLoading] = useState(false);

  const handleFormSubmit: FormSubmitHandler<MDProjectCardGuarantorSetFormData> = async (values) => {
    setLoading(true);
    const [err, res] = await masterApi.projects.md.project.setguarantor.post({
      guarantorId: values.guarantorId,
      projectId: mdProject.id,
    });
    if (err) {
      messageError(err, intl);
      setLoading(false);
      return err;
    }
    onSubmit(res.data);
    setLoading(false);
    onClose();
    return null;
  };

  const guarantorOptions = useMemo(() => {
    return mdRoles
      ?.filter(
        (role) => role.division.id === mdProject.processorDivision?.id && role.mdRoleType === MdRoleEnum.guarantor
      )
      .map((role) => ({
        label: role.head.orgUser.appUserProfile.username,
        value: role.id,
      }));
  }, [mdProject, mdRoles]);

  return (
    <FormModalWrapper
      title={<Fmt id="MD.Projects.ProjectGuarantorSetModal.title" />}
      onSubmit={handleFormSubmit}
      okButtonProps={{ loading: isLoading }}
      onClose={onClose}
      width={800}
      {...modalProps}
    >
      <Typography.Paragraph>
        <Fmt id="MD.Projects.ProjectGuarantorSetModal.role.current.label" />
        <Margin top>
          {mdProject.guarantor && <MDRoleDisplay mdRole={mdProject.guarantor} extendedUserClaim={extendedUserClaims} />}
        </Margin>
      </Typography.Paragraph>
      <Form.Item
        label={<Fmt id="MD.Projects.ProjectGuarantorSetModal.role.label" />}
        name="guarantorId"
        rules={[requiredRule('MD.Projects.ProjectGuarantorSetModal.role.required')]}
      >
        <Select options={guarantorOptions} />
      </Form.Item>
    </FormModalWrapper>
  );
};

export default MDSetGuarantorModal;
