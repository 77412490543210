import { Form, Input } from 'antd';
import { apiConstraints } from 'api/completeApiConstraints';
import {
  ProjectTemplateGroupDto,
  ProjectTemplateUserDto,
  ProjectUserProfileListDto,
  ProjectUserProfileStatusEnum,
} from 'api/completeApiInterfaces';
import { UserTransferFormItem } from 'components/UserTransfer/UserTransfer';
import { useIntl } from 'hooks';
import { AutoSizeType } from 'rc-textarea/lib/ResizableTextArea';
import React, { FC, useMemo } from 'react';
import { duplicateNameRuleCallback, maxLengthRule, requiredRule } from 'utils/formHelpers';

export type ProjectTemplateGroupFormData = {
  name: string;
  description: string;
  templaceUserIds: Guid[];
};

const DESCRIPTION_AUTOSIZE: AutoSizeType = { minRows: 2 };

type Props = {
  validateUniqueName: (name: string) => boolean;
  templateUsers: ProjectTemplateUserDto[];
  defaults?: ProjectTemplateGroupDto;
};

const ProjectTemplateGroupFormComponent: FC<Props> = ({ validateUniqueName, templateUsers, defaults }) => {
  const intl = useIntl();

  const trasnferableUsers = useMemo(
    () =>
      templateUsers.map(
        (user): ProjectUserProfileListDto => ({
          ...user.appUserOrganization.appUserProfile,
          id: user.id,
          status: ProjectUserProfileStatusEnum.active,
        })
      ),
    [templateUsers]
  );

  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.name.label' })}
        name="name"
        initialValue={defaults?.name}
        rules={[
          requiredRule('forms.items.name.rules.required', true),
          maxLengthRule('general.maxNameLength', apiConstraints.projectTemplateGroupDto.name.maxLength),
          duplicateNameRuleCallback(validateUniqueName),
        ]}
      >
        <Input placeholder={intl.formatMessage({ id: 'GroupForm.items.name.placeholder' })} autoFocus />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.description.label' })}
        name="description"
        initialValue={defaults?.description}
        rules={[
          {
            whitespace: true,
            message: intl.formatMessage({ id: 'forms.items.name.rules.empty' }),
          },
          maxLengthRule('general.maxDescriptionLength', apiConstraints.projectTemplateGroupDto.description.maxLength),
        ]}
      >
        <Input.TextArea rows={2} autoSize={DESCRIPTION_AUTOSIZE} />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'forms.items.userIds.label' })}
        name="templaceUserIds"
        initialValue={defaults?.templateUserIds || []}
      >
        <UserTransferFormItem users={trasnferableUsers} />
      </Form.Item>
    </>
  );
};

export const ProjectTemplateGroupForm = React.memo(ProjectTemplateGroupFormComponent);
