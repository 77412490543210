import { Spin } from 'antd';
import { api } from 'api';
import { masterApi, projectApi } from 'api/completeApi';
import {
  ExtendedPermissionEnum,
  GroupDto,
  ProjectHubDto,
  ProjectUserProfileDto,
  ProjectUserProfileListDto,
  RoleDto,
} from 'api/completeApiInterfaces';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import StackPanel from 'components/StackPanel';
import { useApiData, useValueRef } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { InjectedIntlProps } from 'locale';
import { Dictionary } from 'lodash';
import GroupsTab from 'pages/ProjectSettingsPage/Users/UserDetailPanel/GroupsTab';
import { PermissionsTab } from 'pages/ProjectSettingsPage/Users/UserDetailPanel/PermissionsTab';
import SessionsTab from 'pages/ProjectSettingsPage/Users/UserDetailPanel/SessionsTab';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { Dispatch } from 'store';
import { immutableSetAdd, immutableSetDelete } from 'utils';
import GeneralTab from './GeneralTab';
import RolesTab from './RolesTab';

type Props = InjectedIntlProps & {
  groupsList: GroupDto[];
  rolesList: RoleDto[];
  currentUser: ProjectUserProfileDto;
  reloadUsers: () => void;
  currentProject: ProjectHubDto;
  projectUsersMap: Dictionary<ProjectUserProfileListDto>;
};

export type UserDetailPanelParams = {
  userId: string;
};

const UserDetailPanel: FunctionComponent<Props> = ({
  intl,
  groupsList,
  rolesList,
  currentUser,
  reloadUsers,
  projectUsersMap,
  currentProject,
}) => {
  const dispatch = useDispatch<Dispatch>();

  useDirtyStoreReload(
    (store) => store.roles,
    (dispatch) => dispatch.roles
  );

  const [permissionProfiles, permissionProfilesError, permissionProfilesLoading, loadPermissionProfiles] = useApiData(
    (ct) => masterApi.projects.tempates.extendedpermissiontemplate.id.get(currentProject.organization.id, ct),
    {
      autoload: true,
    }
  );

  const [
    defaultPermissionProfile,
    defaultPermissionProfileError,
    defaultPermissionProfileLoading,
    loadDefaultPermissionProfile,
    setDefaultPermissionProfile,
  ] = useApiData((ct) => projectApi.projectsetting.projectdefault.get(ct), {
    autoload: true,
  });

  const { url } = useRouteMatch();
  const { userId } = useParams<UserDetailPanelParams>();
  const selectedUser = !!userId && projectUsersMap?.[userId];

  const userIdRef = useValueRef(userId);
  const [userGroupsSet, setUserGroupsSet] = useState<Set<Guid>>(new Set());
  const [userRolesSet, setUserRolesSet] = useState<Set<Guid>>(new Set());
  const [userPermissions, setUserPermissions] = useState<Set<ExtendedPermissionEnum>>(new Set());

  const defaultProjectPermissionSet = useMemo(() => {
    const defaultPermissionsArray = permissionProfiles
      ?.find((profile) => profile.id === defaultPermissionProfile?.defaultExtendedPermissionId)
      ?.extendedPermissions.settings?.filter((setting) => setting.permission)
      ?.map((setting) => setting.permissionType);
    return new Set(defaultPermissionsArray || []);
  }, [permissionProfiles, defaultPermissionProfile]);

  const [userDetail, userDetailError, userDetailLoading, loadUserDetail] = useApiData(
    (ct) => api.project.projectUser.getUserById(userIdRef.current, ct),
    {
      fetchCallback: (data) => {
        setUserGroupsSet(new Set(data.groups));
        setUserRolesSet(new Set(data.roles));
        setUserPermissions(new Set(data.extendedPermissions));
      },
    }
  );
  useEffect(loadUserDetail, [userId]);

  useDirtyStoreReload(
    (store) => store.groups,
    (dispatch) => dispatch.groups
  );

  const handleUserGroupsChange = (userId: Guid, groupId: Guid, checked: boolean) => {
    if (userIdRef.current !== userId) return;
    setUserGroupsSet((s) => (checked ? immutableSetAdd(s, groupId) : immutableSetDelete(s, groupId)));
    dispatch.groups.loadData({ reload: true });
    if (userId === currentUser.id) {
      // Reload currentProjectUser in store because their groups changed
      dispatch.currentProjectUser.loadData({ reload: true });
    }
  };

  const handleUserRolesChange = (userId: Guid, role: RoleDto) => {
    if (userIdRef.current !== userId) return;
    setUserRolesSet((s) => (!!role.user ? immutableSetAdd(s, role.id) : immutableSetDelete(s, role.id)));
    void dispatch.roles.loadData({ reload: true });
    // Reload currentProjectUser in store because their roles might have changed, even when changing roles of another user
    void dispatch.currentProjectUser.loadData({ reload: true });
  };

  const handleUserPermissionsChange = (userId: Guid, permission: ExtendedPermissionEnum, checked: boolean) => {
    if (userIdRef.current !== userId) return;
    setUserPermissions((s) => (checked ? immutableSetAdd(s, permission) : immutableSetDelete(s, permission)));
    if (userId === currentUser.id) {
      // Reload currentProjectUser in store because their permission changed
      void dispatch.currentProjectUser.loadData({ reload: true });
    }
  };

  const handleSetProjectDefaultUserPermissions = useCallback(() => {
    setUserPermissions(defaultProjectPermissionSet);
    void dispatch.currentProjectUser.loadData({ reload: true });
  }, [defaultProjectPermissionSet, dispatch]);

  return (
    <>
      <MasterComponent
        url={url}
        title={selectedUser?.username}
        children={(onSelect, selectedItemId) => (
          <StackPanel vertical scrollable>
            <Spin delay={0} spinning={userDetailLoading}>
              <GeneralTab
                user={userDetail}
                error={userDetailError}
                currentUser={currentUser}
                reloadUsers={reloadUsers}
                url={url}
                selectedItemId={selectedItemId}
                currentProject={currentProject}
              />
            </Spin>
          </StackPanel>
        )}
      />
      <Switch>
        <Route
          path={`${url}/roles`}
          render={(props) => (
            <RolesTab
              intl={intl}
              userId={userId}
              roles={rolesList}
              userRolesSet={userRolesSet}
              error={userDetailError}
              onChange={handleUserRolesChange}
              {...props}
            />
          )}
        />
        <Route
          path={`${url}/groups`}
          render={(props) => (
            <GroupsTab
              intl={intl}
              userId={userId}
              userStatus={selectedUser?.status}
              groups={groupsList}
              userGroupsSet={userGroupsSet}
              currentUser={currentUser}
              error={userDetailError}
              onChange={handleUserGroupsChange}
              {...props}
            />
          )}
        />
        <Route
          path={`${url}/permissions`}
          render={(props) => (
            <PermissionsTab
              intl={intl}
              userId={userId}
              userPermissions={userPermissions}
              error={userDetailError}
              onChange={handleUserPermissionsChange}
              handleSetProjectDefaultUserPermissions={
                !!defaultProjectPermissionSet.size ? handleSetProjectDefaultUserPermissions : undefined
              }
              {...props}
            />
          )}
        />
        <Route path={`${url}/auditLog`} render={(props) => <SessionsTab userId={userId} {...props} />} />
      </Switch>
    </>
  );
};

export default UserDetailPanel;
