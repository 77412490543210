import { masterApi } from 'api/completeApi';
import { EntityTypesEnum, OrgLabelDto } from 'api/completeApiInterfaces';
import { AuditLogButton, EditButton } from 'components/ActionButtons';
import { DeleteButtonConfirm } from 'components/ActionButtons/DeleteButtonConfirm';
import AuditLogMasterApiEntityModal from 'components/AuditLogsComponents/AuditLogMasterApiEntityModal';
import { ContentGate } from 'components/ContentGate/ContentGate';
import DisplayName from 'components/DisplayName';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import Label from 'components/Label/Label';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import StackPanel from 'components/StackPanel';
import { useApiData, useBoolean, useIntl } from 'hooks';
import { useLabelListDeleteItems } from 'hooks/useLabelListDeleteItems';
import { Fmt, InjectedIntlProps } from 'locale';
import Panel from 'pages/ProjectSettingsPage/Panel/Panel';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { smartFilter, strCompareCI } from 'utils';
import OrganizationLabelFormModal from './OrganizationLabelFormModal';

type LabelUsedInEntitiesErrorData = {
  labelError: {
    labelId: Guid;
    projectsCount: number;
  };
};

type Props = InjectedIntlProps & {
  organizationId: Guid;
};

const OrganizationLabelsListPanel: FunctionComponent<Props> = ({ organizationId }) => {
  const [search, setSearch] = useState<string>('');
  const { url } = useRouteMatch();
  const intl = useIntl();
  const [editedLabelId, setEditedLabelId] = useState<Guid>();
  const [labelModalVisible, showLabelModal, hideLabelModal] = useBoolean();
  const [toShowActivityLabel, setToShowActivityLabel] = useState<OrgLabelDto>();

  const [organizationLabels, labelsError, labelsLoading, loadLabels] = useApiData(
    (ct) => masterApi.projects.organization.labels.post([organizationId], ct),
    { autoload: false }
  );
  useEffect(() => {
    organizationId && loadLabels();
  }, [organizationId]);

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const handleEdit = useCallback((labelId: Guid) => {
    setEditedLabelId(labelId);
    showLabelModal();
  }, []);

  const handleModalClose = useCallback(() => {
    setEditedLabelId(undefined);
    hideLabelModal();
  }, [hideLabelModal]);

  const handleModalSubmit = useCallback(() => {
    handleModalClose();
    loadLabels();
  }, [handleModalClose, hideLabelModal]);

  const editedLabel = useMemo(() => organizationLabels?.find((label) => label.id === editedLabelId), [
    editedLabelId,
    organizationLabels,
  ]);

  const [deletingItems, handleDelete] = useLabelListDeleteItems(
    organizationLabels,
    loadLabels,
    (id, conflictStrategy) => masterApi.projects.organization.labels.id.id.delete(organizationId, id, conflictStrategy),
    (name, errorData) => {
      const errors = (errorData as LabelUsedInEntitiesErrorData).labelError;
      return intl.formatMessage(
        { id: 'OrgLabelsList.deleteConfirmModal.description' },
        {
          label: name,
          projectCount: errors.projectsCount,
        }
      );
    }
  );

  const validateUniqueName = useCallback(
    (name: string): boolean =>
      !organizationLabels.some(
        (label) => strCompareCI(label.name, name) === 0 && (!!editedLabelId ? label.id !== editedLabelId : true)
      ),
    [editedLabelId, organizationLabels]
  );

  const hideAuditLog = useCallback(() => {
    setToShowActivityLabel(undefined);
  }, []);

  return (
    <>
      <MasterComponent
        url={url}
        title={intl.formatMessage({ id: 'OrganizationSettings.title' })}
        children={(onSelect, selectedItemId) => (
          <StackPanel>
            <Panel
              noMargin
              panelWidth="auto"
              onSearch={setSearch}
              searchValue={search}
              addButtonOnClick={showLabelModal}
              addButtonText={<Fmt id="Panel.AddLabel.tooltip" />}
            >
              <ContentGate loading={labelsLoading && !organizationLabels} error={labelsError}>
                <GeneralSettingsContainer>
                  <List<OrgLabelDto>
                    data={organizationLabels || []}
                    search={search}
                    filterItem={(item) => smartFilter(item.name, search) || smartFilter(item.description, search)}
                    renderItem={(item) => (
                      <GeneralSettingsItem
                        key={item.id}
                        title={<Label color={item.color}>{<DisplayName text={item.name} />}</Label>}
                        description={item.description}
                        wrap
                        loading={deletingItems.has({ id: item.id })}
                        additionalActions={
                          <>
                            <EditButton onClick={() => handleEdit(item.id)} />
                            <DeleteButtonConfirm onConfirm={() => handleDelete({ id: item.id })} />
                            <AuditLogButton onClick={() => setToShowActivityLabel(item)} />
                          </>
                        }
                      />
                    )}
                    renderEmpty={(total, filtered) => (
                      <ListEmpty filtered={filtered} total={total} onClearSearch={clearSearch} />
                    )}
                  />
                </GeneralSettingsContainer>
              </ContentGate>
              {!!labelModalVisible && (
                <OrganizationLabelFormModal
                  open={labelModalVisible}
                  onSubmit={handleModalSubmit}
                  onClose={handleModalClose}
                  organizationId={organizationId}
                  validateUniqueName={validateUniqueName}
                  editedLabel={editedLabel}
                />
              )}

              {!!toShowActivityLabel && (
                <AuditLogMasterApiEntityModal
                  visible={!!toShowActivityLabel}
                  label={<Fmt id="AuditLog.orgLabel.label" values={{ orgLabelName: toShowActivityLabel.name }} />}
                  entityId={toShowActivityLabel.id}
                  entityType={EntityTypesEnum.orgLabel}
                  onOk={hideAuditLog}
                  deps={[toShowActivityLabel]}
                />
              )}
            </Panel>
          </StackPanel>
        )}
      />
    </>
  );
};

export default React.memo(OrganizationLabelsListPanel);
