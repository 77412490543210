import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Typography } from 'antd';
import classNames from 'classnames';
import { FlowLayout } from 'components/layouts/FlowLayout';
import React, { FC, ReactNode } from 'react';
import styles from './DashboardTile.module.less';

export interface DashboardTileProps {
  menu?: MenuProps;
  title: ReactNode;
  style?: React.CSSProperties;
  containerClass?: string;
  additionalItems?: ReactNode;
}

type Props = DashboardTileProps;

const DashboardTile: FC<Props> = ({ menu, title, children, style, containerClass, additionalItems }) => {
  return (
    <div className={classNames(styles.dashboardTile, containerClass)} style={style}>
      <FlowLayout growFirst className={styles.dashboardTileHeader}>
        <Typography.Text className={styles.dashboardTitle}>{title}</Typography.Text>
        {additionalItems}
        {!!menu && (
          <Dropdown menu={menu} className={styles.dropdown}>
            <Button type="default" shape="circle">
              <EllipsisOutlined rotate={90} />
            </Button>
          </Dropdown>
        )}
      </FlowLayout>
      <div className={styles.projectsTileContent}>{children}</div>
    </div>
  );
};

export default DashboardTile;
