import { ItemType } from 'antd/es/menu/hooks/useItems';
import { api } from 'api';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import { LanguageEnum } from 'locale/messages/interfaces';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { messageError } from 'utils';
import { FlagIcon } from './FlagIcon';
import styles from './LocaleMenu.module.less';

const LOCALES = [LanguageEnum.cs, LanguageEnum.en, LanguageEnum.de];

export const useLocaleMenu = (selectedLocale: LanguageEnum, onLocaleChange: (locale: LanguageEnum) => void) => {
  const dispatch = useDispatch<Dispatch>();
  const intl = useIntl();

  const handleSetLocale = useCallback(
    async (key: LanguageEnum) => {
      onLocaleChange(key);
      const [err, resp] = await api.master.appUsers.setAppUserLang({ language: key });
      if (err) {
        messageError(err, intl);
      } else {
        dispatch.currentAppUser.setData(resp.data);
      }
    },
    [dispatch, intl, onLocaleChange]
  );

  const localeMenuItems = useMemo((): ItemType[] => {
    return [
      {
        key: 'groupLocale',
        label: <Fmt id="general.language" />,
        disabled: true,
        className: styles.groupTitle,
      },

      ...LOCALES.map(
        (locale): ItemType => ({
          key: locale,
          onClick: () => handleSetLocale(locale),
          label: <FlagIcon selectedLocale={locale} />,
          className: selectedLocale === locale ? styles.selected : undefined,
        })
      ),
    ];
  }, [handleSetLocale, selectedLocale]);

  return localeMenuItems;
};
