import { api } from 'api';
import { masterApi } from 'api/completeApi';
import { AppUserDto, OrganizationAdminReportDto, ServiceError } from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import {
  MasterComponent,
  MasterDetailsContainer,
  MasterDetailsViewContextProvider,
} from 'components/MasterDetailsView/MasterDetailsView';
import { useApiData, useIntl } from 'hooks';
import { InjectedIntlProps } from 'locale';
import OrganizationsDetailPanel from 'pages/OrganizationsSettingPage/OrganizationsDetailPanel';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import OrganizationActiveUsersReportModal from './OrganizationActiveUsersReportModal';
import OrganizationsListPanel from './OrganizationsListPanel';

type Props = InjectedIntlProps & {
  organizations: OrganizationAdminReportDto[];
  appUsers: AppUserDto[];
  loadOrganizationsAdminReport: () => void;
  organizationsAdminReportLoading: boolean;
  organizationsAdminReportError: ServiceError;
  userCanAddOrganization: boolean;
};

const Organizations: FunctionComponent<Props> = (props) => {
  const {
    organizations,
    appUsers = [],
    loadOrganizationsAdminReport,
    organizationsAdminReportLoading,
    organizationsAdminReportError,
    userCanAddOrganization,
  } = props;

  const intl = useIntl();
  const { path, url } = useRouteMatch();
  const history = useHistory();

  const [configStorageAreas, configStorageAreasError, configStorageAreasLoading, loadConfigStorageAreas] = useApiData(
    (ct) => masterApi.projects.storageareas.get(ct),
    { autoload: true }
  );
  const [activeUsersReportOrganization, getActiveUsersReportOrganization] = useState<OrganizationAdminReportDto>();

  const removeOrganization = useCallback(
    async (organizationId: Guid) => {
      await api.master.organization.deleteorganization(organizationId);
      loadOrganizationsAdminReport();
      history.replace(url);
    },
    [history, loadOrganizationsAdminReport, url]
  );

  const handleSubmitActiveUsersReport = useCallback(() => {
    getActiveUsersReportOrganization(undefined);
  }, []);

  return (
    <>
      <ContentGate empty={!organizations}>
        <MasterDetailsViewContextProvider>
          <MasterDetailsContainer>
            <MasterComponent
              url={url}
              title={intl.formatMessage({ id: 'general.organizations' })}
              children={(onSelect, selectedId) => (
                <OrganizationsListPanel
                  intl={props.intl}
                  organizations={organizations || []}
                  appUsers={appUsers}
                  loading={organizationsAdminReportLoading}
                  error={organizationsAdminReportError}
                  onRemove={removeOrganization}
                  loadOrganizationsAdminReport={loadOrganizationsAdminReport}
                  selectedId={selectedId}
                  onSelect={onSelect}
                  userCanAddOrganization={userCanAddOrganization}
                  configStorageAreas={configStorageAreas?.storageAreas}
                  getActiveUsersReportOrganization={
                    userCanAddOrganization ? getActiveUsersReportOrganization : undefined
                  }
                />
              )}
            />
            <Switch>
              <Route
                path={`${path}/:organizationId`}
                render={(props) => (
                  <OrganizationsDetailPanel
                    key={props.match.params.organizationId}
                    appUsers={appUsers}
                    organizations={organizations}
                    intl={intl}
                    loadOrganizationsAdminReport={loadOrganizationsAdminReport}
                    isAspeHubAdmin={userCanAddOrganization}
                  />
                )}
              />
            </Switch>
          </MasterDetailsContainer>
        </MasterDetailsViewContextProvider>
      </ContentGate>

      {!!activeUsersReportOrganization && (
        <OrganizationActiveUsersReportModal
          onClose={handleSubmitActiveUsersReport}
          onSubmit={handleSubmitActiveUsersReport}
          open={!!activeUsersReportOrganization}
          activeUsersReportOrganization={activeUsersReportOrganization}
        />
      )}
    </>
  );
};

export default injectIntl(Organizations);
