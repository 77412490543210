import { DatePicker, DatePickerProps } from 'antd';

import { JSONVariableTypeEnum } from 'api/completeApiInterfaces';
import moment, { Moment } from 'moment';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { JSONVariableDate } from '../JSONVariableTypes';

export type JSONVariableDateProps = {
  dataType: JSONVariableTypeEnum.date;
  value?: JSONVariableDate;
  onChange?: (value: JSONVariableDate) => void;
} & Omit<DatePickerProps, 'value' | 'onChange'>;

const JSONVariableDatePickerFormItem: FunctionComponent<JSONVariableDateProps> = ({
  dataType,
  value,
  onChange,
  ...restProps
}) => {
  const handleChange = useCallback(
    (value: Moment) => {
      onChange && onChange({ type: dataType, value: value?.endOf('day').toISOString() });
    },
    [onChange]
  );
  const momentValue = useMemo(() => (value?.value ? moment(value.value) : undefined), [value]);
  return <DatePicker format="L" onChange={handleChange} value={momentValue} {...restProps} picker="date" />;
};

export default React.memo(JSONVariableDatePickerFormItem);
