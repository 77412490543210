import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Modal, ModalProps, Space, Typography } from 'antd';
import { projectApi } from 'api/completeApi';
import { FilterViewPresetDto, FilterViewPresetListDto } from 'api/completeApiInterfaces';
import { UserAvatar } from 'components/avatars/UserAvatar/UserAvatar';
import { CommonFilterWithValue } from 'components/filters/filterTypes';
import FilterPresetEditFormModal from 'components/forms/FilterPresetEditFormModal/FilterPresetEditFormModal';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useCurrentProjectUser, useDeleteItems, useIntl } from 'hooks';
import { Fmt } from 'locale';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { textComparer } from 'utils/comparators';
import styles from './FilterPresetManageModal.module.less';

type Props = {
  savedPresets: FilterViewPresetListDto;
  setSavedPresets: (presetList: FilterViewPresetListDto) => void;
  filters: CommonFilterWithValue[];
} & ModalProps;

export const FilterPresetManageModal: FunctionComponent<Props> = ({
  savedPresets,
  setSavedPresets,
  filters,
  ...modalProps
}) => {
  const [selectedEditPreset, setSelectedEditPreset] = useState<FilterViewPresetDto>();
  const intl = useIntl();
  const currentUser = useCurrentProjectUser();

  const sortedPresets = useMemo(() => {
    const sortedPresets = [...(savedPresets?.filterViewPresets || [])].sort(textComparer.map((preset) => preset.name));
    const userOwned = sortedPresets.filter((preset) => preset.createdBy.id === currentUser.id);
    const shared = sortedPresets.filter((preset) => preset.createdBy.id !== currentUser.id);

    return { userPresets: userOwned, sharedPresets: shared };
  }, [savedPresets]);

  const [deletingItems, handleDeleteSelected] = useDeleteItems<Guid>(
    intl,
    (presetId) => projectApi.projectsetting.filterviewpreset.id.delete(presetId),
    (presetId) =>
      setSavedPresets({ filterViewPresets: savedPresets.filterViewPresets.filter((preset) => preset.id !== presetId) }),
    true
  );

  const handleEditSelected = useCallback(
    (presetId: Guid) => {
      const preset = savedPresets?.filterViewPresets.find((preset) => preset.id === presetId);
      setSelectedEditPreset(preset);
    },
    [savedPresets]
  );

  const handleEditSelectedClose = () => {
    setSelectedEditPreset(undefined);
  };

  const handleEditSelectedSave = (values: FilterViewPresetListDto) => {
    setSelectedEditPreset(undefined);
    setSavedPresets(values);
  };

  const renderPresetRow = (preset: FilterViewPresetDto) => (
    <FlowLayout growFirst key={preset.id} className={styles.listItem}>
      <Typography.Text ellipsis>{preset.name}</Typography.Text>
      <Space>
        <UserAvatar user={preset.createdBy} />
        {moment(preset.modifiedDate || preset.createdDate).format('LLL')}
        {(currentUser.isAdmin || preset.createdBy.id === currentUser.id) && (
          <>
            <Button shape="circle" size="small" icon={<EditOutlined />} onClick={() => handleEditSelected(preset.id)} />
            <Button
              shape="circle"
              size="small"
              icon={<DeleteOutlined />}
              loading={deletingItems.has(preset.id)}
              onClick={() => handleDeleteSelected(preset.id)}
            />
          </>
        )}
      </Space>
    </FlowLayout>
  );

  return (
    <Modal
      title={<Fmt id="FilterPresetManageModal.title" />}
      cancelText={<Fmt id="general.close" />}
      width={800}
      {...modalProps}
    >
      {sortedPresets.userPresets.length > 0 && (
        <>
          <Typography.Title level={4}>
            <Fmt id="FilterPreset.category.mine" />
          </Typography.Title>
          {sortedPresets.userPresets.map(renderPresetRow)}
        </>
      )}
      {sortedPresets.sharedPresets.length > 0 && (
        <>
          <Typography.Title level={4}>
            <Fmt id="FilterPreset.category.shared" />
          </Typography.Title>
          {sortedPresets.sharedPresets.map(renderPresetRow)}
        </>
      )}
      {selectedEditPreset && (
        <FilterPresetEditFormModal
          onSubmit={handleEditSelectedSave}
          onClose={handleEditSelectedClose}
          open={!!selectedEditPreset}
          preset={selectedEditPreset}
          filters={filters}
        />
      )}
    </Modal>
  );
};
