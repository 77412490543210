import { Button } from 'antd';
import { ProjectUserProfileDto, WorkflowTemplateListDto } from 'api/completeApiInterfaces';
import { WorkflowInstantiationIcon } from 'components/Icons/HubEntitiesIcons';
import { useWorkflowInstantiation } from 'hooks/useWorkflowInstantiation';
import { InjectedIntlProps } from 'locale';
import React, { FunctionComponent } from 'react';
import { WorkflowInstantiationFormConfiguration } from './WorkflowInstantiationForm';
import WorkflowInstantiationFormModal from './WorkflowInstantiationFormModal';

type Props = Omit<WorkflowInstantiationFormConfiguration, 'availableTemplates'> &
  InjectedIntlProps & {
    disabled?: boolean;
    title?: string;
    redirect?: boolean;
    defaultTemplates?: WorkflowTemplateListDto[];
  };

export const isEmpty = (array?: unknown[]) => array === undefined || array === null || array.length === 0;

export const availableRolesForWorkflow = (template: WorkflowTemplateListDto, currentUser: ProjectUserProfileDto) => {
  if (isEmpty(currentUser?.roles)) {
    return new Set<Guid>();
  }
  const userSet = new Set(currentUser.roles);
  if (isEmpty(template?.workflowTemplateRoles)) {
    return userSet;
  }
  return new Set(template.workflowTemplateRoles.map((role) => role.id).filter((id) => userSet.has(id)));
};

export const WorkflowInstantiationButton: FunctionComponent<Props> = ({
  disabled,
  title,
  redirect,
  children,
  intl,
  defaultTemplates,
  ...formConfig
}) => {
  const fwInstantiation = useWorkflowInstantiation(disabled, title, redirect);

  return (
    <>
      <Button
        type="link"
        disabled={fwInstantiation.isDisabled}
        title={fwInstantiation.shownTitle}
        onClick={fwInstantiation.modalFormShow}
        icon={<WorkflowInstantiationIcon />}
      >
        {children}
      </Button>
      <WorkflowInstantiationFormModal
        intl={intl}
        onSubmit={fwInstantiation.handleSubmit}
        open={fwInstantiation.modalFormVisible}
        onClose={fwInstantiation.modalFormHide}
        availableTemplates={defaultTemplates || fwInstantiation.availableTemplates}
        {...formConfig}
      />
    </>
  );
};
