import { Checkbox, Form } from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import { masterApi } from 'api/completeApi';
import { MdDivisionDto, MdProjectDto } from 'api/completeApiInterfaces';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { useMemo, useState } from 'react';
import { messageError } from 'utils';
import styles from './MDSetCommentingDivisionsModal.module.less';

type Props = FormModalProps<MdProjectDto> & { mdProject: MdProjectDto; mdDivisions: MdDivisionDto[] };

export type MDProjectCardCommentingDivisionsSetFormData = {
  commentingDivisionIds: Guid[];
};

const MDSetCommentingDivisionsModal: React.FC<Props> = ({
  mdProject,
  mdDivisions,
  onClose,
  onSubmit,
  ...modalProps
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const [isLoading, setLoading] = useState(false);

  const handleFormSubmit: FormSubmitHandler<MDProjectCardCommentingDivisionsSetFormData> = async (values) => {
    setLoading(true);
    const [err, res] = await masterApi.projects.md.project.setcommentingdivisions.post({
      commentingDivisionIds: values.commentingDivisionIds,
      projectId: mdProject.id,
    });
    if (err) {
      messageError(err, intl);
      setLoading(false);
      return err;
    }
    onSubmit(res.data);
    setLoading(false);
    onClose();
    return null;
  };

  const checkedDivisions = useWatch<Guid[]>('commentingDivisionIds', form);

  const requiredDivisions = useMemo(() => {
    return (
      mdDivisions
        ?.filter((division) =>
          division.mdCkIdentifications.some((ckIdentification) => ckIdentification === mdProject.ckIdentificaion)
        )
        .map((division) => division.id) || []
    );
  }, [mdDivisions, mdProject]);

  const divisionOptions = useMemo(() => {
    return (
      mdDivisions?.map((division) => ({
        label: division.name,
        value: division.id,
        disabled:
          requiredDivisions.some((assignedDivisionId) => division.id === assignedDivisionId) &&
          checkedDivisions?.some((checkedDivisionId) => division.id === checkedDivisionId),
      })) || []
    );
  }, [requiredDivisions, mdDivisions, checkedDivisions]);

  const initialDivisions = useMemo(() => {
    return [...mdProject.assignedCommentingDivisionIds, ...requiredDivisions];
  }, [mdProject, requiredDivisions]);

  return (
    <FormModalWrapper
      title={<Fmt id="MD.Projects.ProjectCommentingDivisionsSetModal.title" />}
      onSubmit={handleFormSubmit}
      okButtonProps={{ loading: isLoading }}
      onClose={onClose}
      form={form}
      {...modalProps}
    >
      <Form.Item
        label={<Fmt id="MD.Projects.ProjectCommentingDivisionsSetModal.division.label" />}
        name="commentingDivisionIds"
        initialValue={initialDivisions}
      >
        <Checkbox.Group options={divisionOptions} className={styles.checkboxGrid} />
      </Form.Item>
    </FormModalWrapper>
  );
};

export default MDSetCommentingDivisionsModal;
