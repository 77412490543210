import { Typography } from 'antd';
import { masterApi } from 'api/completeApi';
import { EstiConFirmRowDto, OrgEstiConnDto } from 'api/completeApiInterfaces';
import AccessLevel from 'components/AccessLevel';
import { ContentGate } from 'components/ContentGate/ContentGate';

import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import SpinBoxCenter from 'components/SpinBoxCenter';
import StackPanel from 'components/StackPanel';
import { EMPTY_GUID } from 'config/constants';
import { useApiData, useIntl } from 'hooks';
import { InjectedIntlProps } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import Panel from 'pages/ProjectSettingsPage/Panel';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { messageError } from 'utils';

type Props = InjectedIntlProps & {
  panelWidth?: number | string;
  orgEsticons: OrgEstiConnDto[];
  organizationId: Guid;
  setOrgEsticons: React.Dispatch<React.SetStateAction<OrgEstiConnDto[]>>;
  isAspeHubAdmin: boolean;
};

type AspeEsticonAppPermissionParams = {
  orgEsticonId: string;
};

enum EsticonFirmAccessLevelEnum {
  none = 'none',
  read = 'read',
}

const accessLevelMap: Record<EsticonFirmAccessLevelEnum, number> = {
  [EsticonFirmAccessLevelEnum.none]: 0,
  [EsticonFirmAccessLevelEnum.read]: 1,
};

const accessLevelReverseMap: Record<number, EsticonFirmAccessLevelEnum> = {
  0: EsticonFirmAccessLevelEnum.none,
  1: EsticonFirmAccessLevelEnum.read,
};

const translations: Record<EsticonFirmAccessLevelEnum, IntlMessageId> = {
  [EsticonFirmAccessLevelEnum.none]: 'AccessLevel.tooltips.none',
  [EsticonFirmAccessLevelEnum.read]: 'AccessLevel.tooltips.read',
};

const AspeEsticonPermissionPanel: FunctionComponent<Props> = ({
  organizationId,
  orgEsticons,
  setOrgEsticons,
  isAspeHubAdmin,
}) => {
  const { url } = useRouteMatch();
  const { orgEsticonId } = useParams<AspeEsticonAppPermissionParams>();
  const [currentOrgEsticon, setCurrentOrgEsticon] = useState<OrgEstiConnDto>();
  const [isLoading, setLoading] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    setCurrentOrgEsticon(orgEsticons?.find((item) => item.id === orgEsticonId));
  }, [orgEsticons, orgEsticonId]);

  const [esticonFirms, esticonFirmsError, esticonFirmsLoading, loadEsticonFirms, setEsticonFirms] = useApiData(
    (ct) =>
      masterApi.EsticonReports.org.id.estiConn.id.firms.id.getallfirmss.get(
        orgEsticonId,
        organizationId,
        undefined,
        ct
      ),
    {
      autoload: true,
    }
  );
  const permittedFirmIds = useMemo(() => currentOrgEsticon?.permittedFirmIds || [], [currentOrgEsticon]);

  const handlePermissionChange = useCallback(
    async (estiFirmId: Guid, value: EsticonFirmAccessLevelEnum) => {
      setLoading(true);
      const permittedIds =
        value === EsticonFirmAccessLevelEnum.read
          ? [...permittedFirmIds, estiFirmId]
          : permittedFirmIds.filter((id) => id !== estiFirmId);
      const defaultOrgEsticonId = !permittedIds.length ? EMPTY_GUID : permittedIds[0];

      const [err, resp] = await masterApi.projects.admin.esticonn.patch({
        id: orgEsticonId,
        defaultFirmId: defaultOrgEsticonId,
        permittedFirmIds: permittedIds,
      });
      if (err) {
        messageError(err, intl);
        setLoading(false);
        loadEsticonFirms();
        return null;
      }
      setOrgEsticons(resp.data);
      setLoading(false);
      return null;
    },
    [orgEsticonId, permittedFirmIds, setOrgEsticons]
  );

  useEffect(() => {
    loadEsticonFirms();
  }, [orgEsticonId]);

  const getEsticonFirmSettingsDisplay = useCallback(
    (esticonFirm: EstiConFirmRowDto) => {
      if (!permittedFirmIds.includes(esticonFirm.id) && !isAspeHubAdmin) return null;
      return (
        <SpinBoxCenter spinning={isLoading} flex flexDirection="horizontal">
          <AccessLevel
            accessLevelMap={accessLevelMap}
            accessLevelReverseMap={accessLevelReverseMap}
            translations={translations}
            value={
              permittedFirmIds.includes(esticonFirm.id)
                ? EsticonFirmAccessLevelEnum.read
                : EsticonFirmAccessLevelEnum.none
            }
            disabled={!isAspeHubAdmin}
            onChange={(value) => {
              void handlePermissionChange(esticonFirm.id, value as EsticonFirmAccessLevelEnum);
            }}
          />
        </SpinBoxCenter>
      );
    },
    [handlePermissionChange, isAspeHubAdmin, permittedFirmIds]
  );

  return (
    <MasterComponent
      url={url}
      title={intl.formatMessage({ id: 'AspeEsticonPermissionPanel.title' })}
      children={() => (
        <>
          <ContentGate error={esticonFirmsError} loading={esticonFirmsLoading} empty={!esticonFirms?.length}>
            <Panel
              noMargin
              panelWidth="auto"
              toolbarTitle={
                <Typography.Title level={3}>
                  {intl.formatMessage({ id: 'AspeEsticonPermissionPanel.title' })}
                </Typography.Title>
              }
            >
              <StackPanel vertical scrollable>
                <GeneralSettingsContainer>
                  {esticonFirms?.map((esticonFirm) => (
                    <GeneralSettingsItem
                      key={esticonFirm.id}
                      title={esticonFirm.nazev}
                      additionalActions={getEsticonFirmSettingsDisplay(esticonFirm)}
                      selectable
                      hideCaret
                    ></GeneralSettingsItem>
                  ))}
                </GeneralSettingsContainer>
              </StackPanel>
            </Panel>
          </ContentGate>
        </>
      )}
    />
  );
};

export default AspeEsticonPermissionPanel;
