import { masterApi } from 'api/completeApi';
import { OrganizationAdminReportDto } from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import StackPanel from 'components/StackPanel';
import { useApiData, useIntl } from 'hooks';
import { InjectedIntlProps } from 'locale';
import { SZ_FORM_TEMPLATE } from 'pages/FormProcessTemplate/DefaultFormProcessFormTemplates';
import Panel from 'pages/ProjectSettingsPage/Panel/Panel';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import FormProcessTaskTemplateList from './FormProcessTaskTemplateList';

type Props = InjectedIntlProps & {
  organization: OrganizationAdminReportDto;
};

const FormProcessTaskTemplateListPanel: FunctionComponent<Props> = ({ organization }) => {
  const [search, setSearch] = useState<string>('');

  const { url } = useRouteMatch();
  const intl = useIntl();

  const [
    formProcessTemplates,
    formProcessTemplatesError,
    formProcessTemplatesLoading,
    loadFormProcessTemplates,
  ] = useApiData((ct) => masterApi.projects.formprocesstemplate.tasks.id.get(organization.id, ct), { autoload: false });

  useEffect(() => {
    loadFormProcessTemplates();
  }, [organization.id]);

  // TODO: Remove temporary auto-insert for SZ presentation form after presentation in September 2024
  useEffect(() => {
    if (
      organization.hasSZModule &&
      formProcessTemplates &&
      !formProcessTemplates.some((template) => template.id === SZ_FORM_TEMPLATE.id)
    ) {
      void masterApi.projects.formprocesstemplate.task.add.id.post(organization.id, SZ_FORM_TEMPLATE);
    }
  }, [formProcessTemplates, organization]);

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  return (
    <>
      <MasterComponent
        url={url}
        title={intl.formatMessage({ id: 'FormProcessTaskTemplate.title' })}
        children={(onSelect, selectedItemId) => (
          <StackPanel>
            <Panel noMargin onSearch={setSearch} searchValue={search}>
              <ContentGate
                empty={!formProcessTemplates?.length}
                loading={formProcessTemplatesLoading}
                error={formProcessTemplatesError}
              >
                <FormProcessTaskTemplateList
                  formProcessTaskTemplates={formProcessTemplates}
                  search={search}
                  onClearSearch={clearSearch}
                />
              </ContentGate>
            </Panel>
          </StackPanel>
        )}
      />
    </>
  );
};

export default React.memo(FormProcessTaskTemplateListPanel);
