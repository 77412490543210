import { Empty, Form, Select } from 'antd';
import { DocumentBulkStateCheckResultDto, WorkflowStateEnum } from 'api/completeApiInterfaces';
import DocumentMultipleActionError from 'components/DocumentMultipleActionError';
import RevisionNumberTag from 'components/RevisionNumberTag';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FC, useMemo } from 'react';
import { requiredRule } from 'utils/formHelpers';
import styles from './BulkEditDocumentsStateForm.module.less';

const { Option } = Select;

export interface BulkEditDocumentsStateFormData {
  documentsState: WorkflowStateEnum;
}

type Props = {
  availableDocumentsStates: DocumentBulkStateCheckResultDto;
  errors: JSX.Element[];
};

const BulkEditDocumentsStateForm: FC<Props> = ({ availableDocumentsStates, errors }) => {
  const intl = useIntl();

  const availableStatesSelectOptions = useMemo(() => {
    if (!availableDocumentsStates?.availableStates.length) return null;
    return availableDocumentsStates?.availableStates.map((state) => {
      return (
        <Option key={state}>
          <div className={styles.stateOption}>
            <RevisionNumberTag state={state} showTitle />
          </div>
        </Option>
      );
    });
  }, [availableDocumentsStates]);

  return (
    <>
      {!!availableDocumentsStates?.availableStates.length && (
        <>
          <Form.Item
            name="documentsState"
            label={intl.formatMessage({ id: 'BulkEditDocumentsStateForm.newDocumentsState' })}
            initialValue={availableDocumentsStates.availableStates[0]}
            rules={[requiredRule('BulkEditDocumentsStateForm.newDocumentsState.required')]}
          >
            <Select>{availableStatesSelectOptions}</Select>
          </Form.Item>
          <DocumentMultipleActionError
            errors={errors}
            titleId="BulkEditDocumentsStateForm.newDocumentsState.impossibleChanges"
          />
        </>
      )}
      {!availableDocumentsStates?.availableStates.length && (
        <Empty
          description={
            <span>
              <Fmt id="BulkEditDocumentsStateForm.newDocumentsState.noAvailableStates" />
            </span>
          }
        />
      )}
    </>
  );
};

export default BulkEditDocumentsStateForm;
