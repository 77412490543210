import { projectApi } from 'api/completeApi';
import {
  FilterViewPresetCreateDto,
  FilterViewPresetListDto,
  FilterViewPresetPatchDto,
} from 'api/completeApiInterfaces';
import { CommonFilterWithValue, FiltersPersistentKey } from 'components/filters/filterTypes';
import { FilterPresetData } from 'components/filters/render/FilterPreset/FilterPreset';
import React, { FunctionComponent } from 'react';
import { FormModalProps } from '../FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from '../FormModalWrapper';
import FilterPresetSaveForm, { FilterPresetSaveFormData } from './FilterPresetSaveForm';

type Props = FormModalProps<FilterViewPresetListDto> & {
  filters: CommonFilterWithValue[];
  savedPresets: FilterViewPresetListDto;
  filterKey: FiltersPersistentKey;
};
const FilterPresetSaveFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, savedPresets, filterKey, filters, ...restProps } = props;

  const handleSubmit: FormSubmitHandler<FilterPresetSaveFormData> = async (values) => {
    if (values.overrideFilterPresetId) {
      const patchPresetDto: FilterViewPresetPatchDto = {
        name: values.filterName,
        filterData: {
          filters: filters.reduce((accu, filter) => ({ ...accu, [filter.key]: filter.value }), {}),
        } as FilterPresetData,
      };
      const [err, res] = await projectApi.projectsetting.filterviewpreset.id.patch(
        values.overrideFilterPresetId,
        patchPresetDto
      );
      if (err) return err;
      onSubmit(res.data);
    } else {
      const createPresetDto: FilterViewPresetCreateDto = {
        name: values.filterName,
        viewKey: filterKey,
        filterData: {
          filters: filters.reduce((accu, filter) => ({ ...accu, [filter.key]: filter.value }), {}),
        } as FilterPresetData,
      };
      const [err, res] = await projectApi.projectsetting.filterviewpreset.post(createPresetDto);
      if (err) return err;
      onSubmit(res.data);
    }

    return null;
  };

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId="FilterPresetSaveForm.title"
      submitTextId="general.add"
      width={800}
      {...restProps}
    >
      <FilterPresetSaveForm savedPresets={savedPresets} />
    </FormModalWrapper>
  );
};

export default FilterPresetSaveFormModal;
