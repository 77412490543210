import { PaperClipOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Tag } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { TransferItem, TransferListProps } from 'antd/lib/transfer';
import { ApiPromise } from 'api/await-to';
import { apiConstraints } from 'api/completeApiConstraints';
import { DownloadUrl, FileDto, ProjectUserProfileListDto } from 'api/completeApiInterfaces';
import { processAddCommentAttachmentCapability } from 'components/CommentText/CommentText';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { DiscussionInputAttachmentNameFormModal } from 'components/forms/DiscussionInputAttachmentNameForm/DiscussionInputAttachmentNameFormModal';
import { DiscussionLinkAttachmentFormData } from 'components/forms/DiscussionLinkAttachmentForm/DiscussionLinkAttachmentForm';
import { DiscussionLinkAttachmentFormModal } from 'components/forms/DiscussionLinkAttachmentForm/DiscussionLinkAttachmentFormModal';
import { MAX_COMMENT_TEXT_ATTACHMENT_COUNT } from 'config/constants';
import { useBoolean, useIntl, useItemsSet } from 'hooks';
import { useImagePaste } from 'hooks/useImagePaste';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { ChangeEvent, FunctionComponent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch as StoreDispatch } from 'store';
import { DisableWindowStoreStartPayload } from 'store/models/storeModelinterfaces';
import DiscussionInputAttachments from '../DiscussionInputAttachments/DiscussionInputAttachments';
import {
  attachmentMapper,
  getLinkedAttachments,
  getUnlinkedAttachments,
  limitNewAttachmentLinks,
  StagedAttachment,
} from '../DiscussionInputAttachments/DiscussionInputAttachments.utils';
import { DiscussionNotifyUsersModal } from '../DiscussionNotifyUsers/DiscussionNotifyUsersModal';
import { DiscussionSendButton } from '../DiscussionSendButton/DiscussionSendButton';
import styles from './DiscussionInput.module.less';

export type DiscussionAttachment = {
  name: string;
  createdDate: IsoDateTime;
  file: FileDto;
  id: Guid;
  canEdit: boolean;
};

export type AttachmentWarning = {
  message: IntlMessageId | undefined;
  affectedAttachmentIds: Guid[];
};

type Props = {
  disabled?: boolean;
  onSend?: (
    message: string,
    linkedAttachments: Guid[],
    stagedAttachments: StagedAttachment[],
    userNotifications?: Guid[]
  ) => void;
  defaultMessage?: string;
  defaultAttachments?: Guid[];
  loading?: boolean;
  onEscape?: () => void;
  clearOnSubmit?: boolean;
  availableAttachments?: DiscussionAttachment[];
  availableUsers: ProjectUserProfileListDto[];
  createUserTransferFooter: (
    value: Guid[],
    setSelectedKeys: React.Dispatch<React.SetStateAction<Guid[]>>
  ) => (footerProps: TransferListProps<TransferItem>) => ReactNode;
  getOriginalUrl?: (id: Guid) => ApiPromise<DownloadUrl>;
  validateLinkedAttachments?: (attachmentIds: Guid[]) => AttachmentWarning;
};

const AUTOSIZE_OPTIONS = { minRows: 1, maxRows: 10 };
const messageMaxLengthConstraint = apiConstraints.commentProcedureCommentNoteCreateDto.message.maxLength;

export const DiscussionInput: FunctionComponent<Props> = ({
  disabled,
  onSend,
  defaultMessage,
  defaultAttachments,
  loading,
  onEscape,
  clearOnSubmit,
  availableAttachments,
  availableUsers,
  createUserTransferFooter,
  getOriginalUrl,
  validateLinkedAttachments,
}) => {
  const intl = useIntl();
  const [message, setMessage] = useState(defaultMessage || '');
  const [linkAttachmentModalVisible, showLinkAttachmentModal, hideLinkAttachmentModal] = useBoolean(false);
  const [selectedAttachments, setSelectedAttachments] = useState<Guid[]>(defaultAttachments || []);
  const [selectedAttachmentsErrors, setSelectedAttachmentsErrors] = useState<AttachmentWarning>(undefined);
  const [namingModalVisible, showNamingModal, hideNamingModal] = useBoolean(false);
  const [stagedAttachments, addStagedAttachment, removeStagedAttachment] = useItemsSet<StagedAttachment>();
  const [pastedImageFile, onImagePasteHandler] = useImagePaste(showNamingModal);
  const [
    DiscussionNotifyUsersModalVisible,
    showDiscussionNotifyUsersModal,
    hideDiscussionNotifyUsersModal,
  ] = useBoolean(false);
  const [userNotification, setUserNotification] = useState<Guid[]>([]);

  const dispatch = useDispatch<StoreDispatch>();
  useEffect(() => setMessage(defaultMessage), [defaultMessage]);

  const linkedAttachments = useMemo(
    () => getLinkedAttachments(availableAttachments, selectedAttachments).map(attachmentMapper),
    [availableAttachments, selectedAttachments]
  );

  const unlinkedAttachments = useMemo(() => getUnlinkedAttachments(availableAttachments, selectedAttachments), [
    availableAttachments,
    selectedAttachments,
  ]);

  useEffect(() => {
    if (!validateLinkedAttachments) return;

    setSelectedAttachmentsErrors(validateLinkedAttachments(selectedAttachments));
  }, [selectedAttachments]);

  const handleAttachmentLinkRemove = useCallback((attachmentId: Guid) => {
    setSelectedAttachments((linkedAttachments) => linkedAttachments.filter((f) => f !== attachmentId));
  }, []);

  const handleAttachmentLinkAdd = useCallback((data: DiscussionLinkAttachmentFormData) => {
    const selectedAttachments = Array.from(data.linkedAttachments || []);
    if (selectedAttachments.length) {
      setSelectedAttachments((linkedAttachments) => [
        ...linkedAttachments,
        ...limitNewAttachmentLinks(selectedAttachments, linkedAttachments.length, MAX_COMMENT_TEXT_ATTACHMENT_COUNT),
      ]);
    }
    hideLinkAttachmentModal();
  }, []);

  const handleSetUserNotifications = useCallback((notifiedUsersIds: Guid[]) => {
    setUserNotification(notifiedUsersIds);
    hideDiscussionNotifyUsersModal();
  }, []);

  const onSubmitClick = useCallback(() => {
    onSend && onSend(message, selectedAttachments, Array.from(stagedAttachments), userNotification);

    if (clearOnSubmit) {
      setMessage('');
      setSelectedAttachments([]);
      setUserNotification([]);
      stagedAttachments.forEach((attachment) => removeStagedAttachment(attachment));
    }
  }, [selectedAttachments, stagedAttachments, message, clearOnSubmit, userNotification]);

  const onMessageChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  }, []);

  const isDirty = useMemo(
    () =>
      (message && message.length > 0) ||
      stagedAttachments.size > 0 ||
      (defaultAttachments && selectedAttachments && defaultAttachments.length !== selectedAttachments.length) ||
      !selectedAttachments.every((id) => defaultAttachments?.some((e) => e === id)),
    [message, defaultAttachments, selectedAttachments, stagedAttachments]
  );

  const disableSend = disabled || !message || !isDirty || message.length > messageMaxLengthConstraint;

  const onPressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.ctrlKey === true && !disableSend) {
      e.preventDefault();
      onSubmitClick();
    }
  };

  const handleNameSubmit = useCallback((attachment: StagedAttachment) => {
    addStagedAttachment(attachment);
    hideNamingModal();
  }, []);

  const onKeyDownEscape = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.keyCode === 27) {
        e.preventDefault();
        onEscape && onEscape();
      }
    },
    [onEscape]
  );

  const disableWindowConfig = useMemo(
    (): DisableWindowStoreStartPayload => ({
      showMask: false,
      message: intl.formatMessage({ id: 'CommentProcedureCommentsNote.notSaveAlert' }),
    }),
    [intl]
  );

  useEffect(() => {
    if (isDirty) {
      dispatch.disableWindow.startPreventing(disableWindowConfig);
    } else {
      dispatch.disableWindow.stopPreventing();
    }
  }, [isDirty, disableWindowConfig]);

  useEffect(() => {
    return () => dispatch.disableWindow.stopPreventing();
  }, []);

  const chosenToNotification = useMemo(
    () =>
      userNotification
        .map((userId) => availableUsers.find((user) => user.id === userId))
        .filter((user) => !!user)
        .map((user) => user.username),
    [userNotification, availableUsers]
  );

  const {
    canPasteImage,
    canAddExistingAttachment,
    disabledMessage: addAttachmentDisabledMessage,
  } = processAddCommentAttachmentCapability(
    selectedAttachments.length,
    availableAttachments?.length,
    unlinkedAttachments.length
  );

  const maxLengthError = message?.length > messageMaxLengthConstraint && (
    <Fmt id="general.maxLength" values={{ max: messageMaxLengthConstraint }} />
  );
  const info = message?.length ? <Fmt id="general.charLength" values={{ count: message.length }} /> : null;

  return (
    <div className={styles.container}>
      <div className={styles.input}>
        <Form layout="vertical">
          <Form.Item validateStatus={maxLengthError ? 'error' : undefined} help={maxLengthError || info}>
            <TextArea
              autoSize={AUTOSIZE_OPTIONS}
              placeholder={intl.formatMessage({ id: 'DiscussionInput.placeholder' })}
              className={styles.inputArea}
              disabled={disabled || loading}
              value={message}
              onChange={onMessageChange}
              onPressEnter={onPressEnter}
              onKeyDown={onKeyDownEscape}
              onPaste={onImagePasteHandler}
              autoFocus
              rows={10}
            />
            <div className={styles.header}>
              <Button
                type="default"
                size="small"
                icon={<PaperClipOutlined />}
                onClick={showLinkAttachmentModal}
                disabled={disabled}
              >
                <Fmt id="CommentProcedureLinkAttachment.tooltip.attach" />
              </Button>
              <CommonHubTooltip
                placement="bottom"
                title={<Fmt id="DiscussionNote.Notification.tooltipToAddUserButton" />}
              >
                <Button type="default" size="small" onClick={showDiscussionNotifyUsersModal} disabled={disabled}>
                  <UsergroupAddOutlined />
                </Button>
              </CommonHubTooltip>
              {selectedAttachmentsErrors?.message && (
                <Alert
                  type="warning"
                  message={<Fmt id={selectedAttachmentsErrors.message} />}
                  className={styles.attachmentWarning}
                />
              )}
              <DiscussionSendButton disabled={disableSend} loading={loading} onClick={onSubmitClick} />
            </div>
            {!!chosenToNotification?.length && (
              <div className={styles.notifyUserContainer}>
                <Fmt id="DiscussionNote.Notification.sendNoticeTo" />
                {chosenToNotification.map((user) => (
                  <Tag key={user}>{user}</Tag>
                ))}
              </div>
            )}
            <DiscussionInputAttachments
              attachments={linkedAttachments}
              onAttach={showLinkAttachmentModal}
              onRemove={handleAttachmentLinkRemove}
              onStagedRemove={removeStagedAttachment}
              stagedAttachments={stagedAttachments}
              showAttachOnEmpty
              disableNewLink={!canAddExistingAttachment}
              disableNewLinkMessage={addAttachmentDisabledMessage}
              getOriginalUrl={getOriginalUrl}
              hideAddItemInput
              highlightedAttachments={selectedAttachmentsErrors?.affectedAttachmentIds}
            />
          </Form.Item>
        </Form>
      </div>

      <DiscussionInputAttachmentNameFormModal
        intl={intl}
        imageFile={pastedImageFile}
        open={namingModalVisible}
        onSubmit={handleNameSubmit}
        onClose={hideNamingModal}
        disabled={!canPasteImage}
      />

      <DiscussionLinkAttachmentFormModal
        open={linkAttachmentModalVisible}
        availableAttachments={unlinkedAttachments}
        onSubmit={handleAttachmentLinkAdd}
        onClose={hideLinkAttachmentModal}
        getOriginalUrl={getOriginalUrl}
      />

      <DiscussionNotifyUsersModal
        onSubmit={handleSetUserNotifications}
        onClose={hideDiscussionNotifyUsersModal}
        availableUsers={availableUsers}
        createUserTransferFooter={createUserTransferFooter}
        visible={DiscussionNotifyUsersModalVisible}
        selectedUserIds={userNotification}
      />
    </div>
  );
};
