import { Collapse, Form } from 'antd';
import { Rule } from 'antd/lib/form';
import { useWatch } from 'antd/lib/form/Form';
import {
  CalendarDto,
  JSONVariableTypeEnum,
  MdMeetingApprovalBackgroundDto,
  MdProjectVariableEnum,
} from 'api/completeApiInterfaces';
import { DatePickerWithHolidays } from 'components/CalendarWithHolidays/DatePickerWithHolidays';
import { MdProjectListWithUnpackedData } from 'components/JSONVariableFormItems/dataUnpackers/mdProjectUnpacker.utils';
import JSONVariableCkConditionsFormItem from 'components/JSONVariableFormItems/Items/JSONVariableConditionsFormItem';
import JSONVariableProjectLinkSelectorFormItem from 'components/JSONVariableFormItems/Items/JSONVariableProjectLinkSelectorFormItem';
import {
  JSONVariableCkApprovalConditions,
  JSONVariableLinkData,
  JSONVariableSelectEnumTyped,
} from 'components/JSONVariableFormItems/JSONVariableTypes';
import { Fmt } from 'locale';
import moment, { Moment } from 'moment';
import React, { useMemo } from 'react';
import { requiredRule } from 'utils/formHelpers';
import MdCkStatementFormItem, { MdProjectDataStatementEnum } from '../MdFormItems/MdCkStatementFormItem';
import { MdProjectDataSubmittedForEnum } from '../MdFormItems/MdProposedForFormItem';

export type MDMeetingResultDistributeFormData = {
  meetingDate: Moment;
  ckMeetingResultLink: JSONVariableLinkData;
  mdCkStatementsProjectResults: Record<Guid, JSONVariableSelectEnumTyped<MdProjectDataStatementEnum>>;
  mdCkConditions: Record<Guid, JSONVariableCkApprovalConditions>;
};

type Props = {
  meetingApproval: MdMeetingApprovalBackgroundDto;
  mdProjectList: MdProjectListWithUnpackedData[];
  overheadProjectId: Guid;
  overheadProjectLoading: boolean;
  organizationCalendar: CalendarDto;
  disabled: boolean;
};

const ckConditionValuesRequiredRule: Rule = {
  message: <Fmt id="forms.items.name.rules.required" />,
  validator: (rule, value: JSONVariableCkApprovalConditions) => {
    if (value?.value?.some((condition) => !condition.Condition?.length)) {
      return Promise.reject();
    } else {
      return Promise.resolve();
    }
  },
};

const MDMeetingResultDistributeForm: React.FC<Props> = ({
  meetingApproval,
  mdProjectList,
  overheadProjectId,
  overheadProjectLoading,
  organizationCalendar,
  disabled,
}) => {
  const ckStatements = useWatch<MDMeetingResultDistributeFormData['mdCkStatementsProjectResults']>([
    'mdCkStatementsProjectResults',
  ]);

  const renderMeetingResponseFields = useMemo(() => {
    return meetingApproval.mdProjectIds.map((mdProjectId, index) => {
      const mdProject = mdProjectList.find((project) => project.id === mdProjectId);
      const ckSubmittedForData = mdProject.submittedFor as JSONVariableSelectEnumTyped<MdProjectDataSubmittedForEnum>;

      const ckStatement = ckStatements?.[mdProjectId];

      return (
        <Collapse.Panel header={mdProject.name} key={mdProject.id} forceRender>
          <Form.Item
            name={['mdCkStatementsProjectResults', mdProjectId]}
            label={<Fmt id={`MD.ProjectVariableEnum.${MdProjectVariableEnum.ckStatement}.name`} />}
            rules={[requiredRule('forms.items.rules.required', false)]}
            initialValue={mdProject.ckStatement}
          >
            <MdCkStatementFormItem disabled={disabled} submittedForFormValue={ckSubmittedForData?.value} />
          </Form.Item>
          {ckStatement?.value === MdProjectDataStatementEnum.approvedWithConditions && (
            <Form.Item
              name={['mdCkConditions', mdProjectId]}
              label={<Fmt id={`MD.ProjectVariableEnum.${MdProjectVariableEnum.ckApprovalConditions}.name`} />}
              rules={[ckConditionValuesRequiredRule]}
              initialValue={mdProject.ckApprovalConditions}
            >
              <JSONVariableCkConditionsFormItem
                activeCalendar={organizationCalendar}
                dataType={JSONVariableTypeEnum.ckApprovalConditions}
                canEditAsCKOrganizer={true}
                canEditAsGuarantor={false}
                disabled={disabled}
                hideGuarantorResponse
              />
            </Form.Item>
          )}
        </Collapse.Panel>
      );
    });
  }, [mdProjectList, meetingApproval, organizationCalendar, ckStatements, disabled]);

  return (
    <>
      <Form.Item
        label={<Fmt id="MDMeetingResultModal.item.meetingDate" />}
        name="meetingDate"
        rules={[requiredRule('forms.items.rules.required', false)]}
        initialValue={moment(meetingApproval.approvalDateTime)}
      >
        <DatePickerWithHolidays disabled={disabled} />
      </Form.Item>
      <Form.Item
        label={<Fmt id="MDMeetingResultModal.item.ckMeetingMomentsLink" />}
        name="ckMeetingResultLink"
        rules={[requiredRule('forms.items.rules.required', false)]}
      >
        <JSONVariableProjectLinkSelectorFormItem
          dataType={JSONVariableTypeEnum.hubLink}
          linkType="directory"
          projectId={overheadProjectId}
          loading={overheadProjectLoading}
          disabled={disabled}
        />
      </Form.Item>

      <Collapse>{renderMeetingResponseFields}</Collapse>
    </>
  );
};

export default MDMeetingResultDistributeForm;
