import { Col, message, Row } from 'antd';
import { masterApi } from 'api/completeApi';
import {
  EstiConProjectPhase,
  EstiProjectNoteForHubItemDto,
  EstiProjectNoteForHubPatchDto,
} from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import Modal from 'components/Modal/Modal';
import { useApiData, useIntl, useIsMounted } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { messageError } from 'utils';
import { modalConfirm } from 'utils/modalConfirm';
import styles from './ProjectsInRealizationPairingNotes.module.less';
import ProjectsInRealizationPairingNotesPreviewModal from './ProjectsInRealizationPairingNotesPreviewModal';
import { ProjectsRealizationSavedViewItem } from './ProjectsRealizationPairingNotesViewItem';

type Props = {
  onClose: () => void;
  open: boolean;
  organizationId: Guid;
  estiConnId: Guid;
  esticonFirmId: Guid;
};

const ProjectsInRealizationPairingNotesModal: FunctionComponent<Props> = (props) => {
  const { onClose, open, organizationId, esticonFirmId, estiConnId } = props;
  const [notesToPreview, setNotesToPreview] = useState<EstiProjectNoteForHubItemDto[]>();
  const intl = useIntl();
  const isMounted = useIsMounted();

  const [reportNotes, reportNotesError, reportNotesLoading, loadReportNotes, setReportNotes] = useApiData(
    (ct) => masterApi.projects.reports.esticonprojectnotes.id.get(organizationId, ct),
    { autoload: false }
  );
  const [orgEsticonReport, orgEsticonReportError, orgEsticonReportLoading, loadOrgEsticonReport] = useApiData(
    (ct) => masterApi.projects.reports.firmlist.post({ organizationId: organizationId }, ct),
    {
      fetchCallback: (data) =>
        data.esticonConns?.some((conn) => conn.esticonFirms == null) &&
        message.warning(intl.formatMessage({ id: 'EsticonFirmList.loadingError' })),
    }
  );

  useEffect(() => {
    loadReportNotes();
    loadOrgEsticonReport();
  }, [organizationId]);

  const esticonProjects = useMemo(() => {
    return (
      orgEsticonReport?.esticonConns
        .find((conn) => conn.conn.id === estiConnId)
        ?.esticonFirms?.find((firm) => firm.firm.id === esticonFirmId)?.projects || []
    );
  }, [orgEsticonReport, estiConnId, esticonFirmId]);

  const hideNotesPreview = useCallback(() => {
    setNotesToPreview(undefined);
  }, []);

  const handleSave = useCallback(
    async (updatedNote: EstiProjectNoteForHubPatchDto) => {
      const saveData = async (noteToSave: EstiProjectNoteForHubPatchDto) => {
        const [err, res] = await masterApi.projects.reports.esticonprojectnotes.patch(noteToSave);
        if (!isMounted.current) return;
        if (err) {
          messageError(err, intl);
        } else {
          setReportNotes(res.data);
        }
      };

      const existingNote = reportNotes.find((note) => note.esticonProjectId === updatedNote.esticonProjectId);
      if (!!existingNote) {
        await modalConfirm({
          title: intl.formatMessage({ id: 'ProjectsInRealizationPairingNotesModal.mergeWarning.title' }),
          content: intl.formatMessage({ id: 'ProjectsInRealizationPairingNotesModal.mergeWarning.description' }),
          onOk: async () => {
            await saveData(updatedNote);
          },
          cancelText: intl.formatMessage({ id: 'general.cancel' }),
          okText: intl.formatMessage({ id: 'ProjectsInRealizationPairingNotesModal.mergeWarning.continue' }),
          width: 400,
        });
      } else {
        await saveData(updatedNote);
      }
    },
    [intl, reportNotes]
  );

  const esticonFirmIdsList = useMemo(() => [esticonFirmId], [esticonFirmId]);

  const filteredProjects = useMemo(() => {
    const projects = esticonProjects
      .flatMap((projects) => projects)
      .filter(
        (project) =>
          project.estiProject.phase === EstiConProjectPhase.Dokonceno ||
          project.estiProject.phase === EstiConProjectPhase.Realizace
      );

    return projects;
  }, [esticonProjects]);

  const unpairedNotes = useMemo(() => {
    if (reportNotes && esticonProjects.length) {
      return reportNotes.filter(
        (note) =>
          esticonProjects.some((project) => note.esticonProjectId === project.estiProject.id) &&
          note.projectNoteItems.length > 0
      );
    }

    return [];
  }, [reportNotes, esticonProjects]);

  return (
    <Modal
      title={intl.formatMessage({ id: 'ProjectsInRealizationPairingNotesModal.title' })}
      visible={open}
      onCancel={onClose}
    >
      <ContentGate
        error={reportNotesError || orgEsticonReportError}
        loading={reportNotesLoading || orgEsticonReportLoading}
      >
        <Row className={styles.projectRowHeader}>
          <Col span={3}>
            <Fmt id="ProjectsInRealizationPairingNotesModal.labels.sign" />
          </Col>
          <Col span={8}>
            <Fmt id="ProjectsInRealizationPairingNotesModal.labels.projectName" />
          </Col>
          <Col span={3}>
            <Fmt id="ProjectsInRealizationPairingNotesModal.labels.startDate" />
          </Col>
          <Col span={2}>
            <Fmt id="ProjectsInRealizationPairingNotesModal.labels.noteList" />
          </Col>
          <Col span={4}>
            <Fmt id="ProjectsInRealizationPairingNotesModal.labels.pairTo" />
          </Col>
        </Row>
        {!!unpairedNotes &&
          unpairedNotes.map((note) => (
            <ProjectsRealizationSavedViewItem
              key={note.id}
              note={note}
              availableEsticonProjects={filteredProjects}
              onSave={handleSave}
              onNotesPreview={setNotesToPreview}
            />
          ))}
      </ContentGate>
      <ProjectsInRealizationPairingNotesPreviewModal
        open={!!notesToPreview}
        notesList={notesToPreview}
        onCancel={hideNotesPreview}
      />
    </Modal>
  );
};

export default ProjectsInRealizationPairingNotesModal;
