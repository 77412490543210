import { masterApi } from 'api/completeApi';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { useApiData } from 'hooks';
import React, { FC, useEffect } from 'react';
import AspeEsticonListPanel from './AspeEsticonListPanel';

type Props = {
  selectedOrganizationId: string;
  isAspeHubAdmin: boolean;
};

export const AspeEsticonPanel: FC<Props> = ({ selectedOrganizationId, isAspeHubAdmin }) => {
  const [orgEsticons, orgEsticonsError, orgEsticonsLoading, loadOrgEsticons, setOrgEsticons] = useApiData((ct) =>
    masterApi.projects.admin.esticonn.id.get(selectedOrganizationId, ct)
  );

  useEffect(() => loadOrgEsticons(), [selectedOrganizationId]);

  return (
    <ContentGate error={orgEsticonsError} loading={orgEsticonsLoading}>
      <AspeEsticonListPanel
        selectedOrganizationId={selectedOrganizationId}
        orgEsticons={orgEsticons}
        setOrgEsticons={setOrgEsticons}
        isAspeHubAdmin={isAspeHubAdmin}
      />
    </ContentGate>
  );
};
