import { SisternodeOutlined, SubnodeOutlined } from '@ant-design/icons';
import { Form, Input, Select, Typography } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useWatch } from 'antd/lib/form/Form';
import { DefaultOptionType } from 'antd/lib/select';
import { apiConstraints } from 'api/completeApiConstraints';
import { JSONVariableTypeEnum, JToken, OrgProjectCardDataDto } from 'api/completeApiInterfaces';
import JSONVariableFormItem from 'components/JSONVariableFormItems/JSONVariableFormItem';
import { useIntl, useStoreSelector } from 'hooks';
import { Fmt } from 'locale';
import { Dictionary } from 'lodash';
import React, { FC, useEffect, useMemo } from 'react';
import { maxLengthRule, requiredRule } from 'utils/formHelpers';

export type ProjectCardDataAddFormData = {
  name: string;
  variable: JSONVariableTypeEnum;
  defaultData: Dictionary<JToken>;
  unit?: string;
  mode: null | 'childNode' | 'sisterNode';
};

type Props = {
  defaultItem?: OrgProjectCardDataDto;
  mode: null | 'childNode' | 'sisterNode';
};

const supportedVariableTypes = [
  JSONVariableTypeEnum.empty,
  JSONVariableTypeEnum.string,
  JSONVariableTypeEnum.stringDiff,
  JSONVariableTypeEnum.number,
  JSONVariableTypeEnum.numberDiff,
  // JSONVariableTypeEnum.boolean, // TODO: not supported yet
  JSONVariableTypeEnum.date,
  JSONVariableTypeEnum.interval,
  // SONVariableTypeEnum.link, // TODO: not supported yet
  JSONVariableTypeEnum.buildingIdentification,
];

export const renderProjectCardDefaultDataVariableFormItem = (
  dataVariableType: JSONVariableTypeEnum,
  unit?: string,
  disabled?: boolean
) => {
  switch (dataVariableType) {
    case JSONVariableTypeEnum.empty:
      return (
        <Typography.Text type="secondary">
          <Fmt id={'JSONVariableType.empty'} />
        </Typography.Text>
      );
    case JSONVariableTypeEnum.number:
    case JSONVariableTypeEnum.numberDiff:
    case JSONVariableTypeEnum.boolean:
    case JSONVariableTypeEnum.date:
    case JSONVariableTypeEnum.interval:
    case JSONVariableTypeEnum.link:
    case JSONVariableTypeEnum.orgStructureWithUser:
      return <JSONVariableFormItem dataType={dataVariableType} unit={unit} disabled={disabled} />;
    case JSONVariableTypeEnum.stringDiff:
      return <JSONVariableFormItem dataType={dataVariableType} itemType="input" unit={unit} disabled={disabled} />;
    case JSONVariableTypeEnum.buildingIdentification:
      return <JSONVariableFormItem dataType={dataVariableType} disabled={disabled} />;
    case JSONVariableTypeEnum.string:
    default:
      return (
        <JSONVariableFormItem dataType={JSONVariableTypeEnum.string} itemType="input" unit={unit} disabled={disabled} />
      );
  }
};

const ProjectCardDataAddForm: FC<Props> = ({ defaultItem, mode }) => {
  const intl = useIntl();
  const form = useFormInstance();
  const dataVariableType = useWatch<JSONVariableTypeEnum>('variable');

  const appSettings = useStoreSelector((state) => state.appSettings.data);

  useEffect(() => {
    form.resetFields(['defaultData']);
  }, [dataVariableType]);

  useEffect(() => {
    form.setFieldValue('mode', mode);
  }, [mode]);

  const variableOptions = useMemo((): DefaultOptionType[] => {
    if (!appSettings) return [];
    const usefulVariableTypes = appSettings.useBuildingIdentification
      ? supportedVariableTypes
      : supportedVariableTypes.filter(
          (dataVariableType) => dataVariableType !== JSONVariableTypeEnum.buildingIdentification
        );
    return usefulVariableTypes.map((variable) => ({
      label: intl.formatMessage({ id: `JSONVariableType.${variable}` }),
      value: variable,
    }));
  }, [intl, appSettings]);

  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: 'general.name' })}
        name="name"
        rules={[
          requiredRule('OrgProjectCardData.form.rules.name.required'),
          maxLengthRule(
            'OrgProjectCardData.form.rules.name.maxLength',
            apiConstraints.orgProjectCardDataDto.name.maxLength
          ),
        ]}
        initialValue={defaultItem?.name}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="unit"
        initialValue={defaultItem?.unit}
        label={intl.formatMessage({ id: 'OrgProjectCardData.units' })}
      >
        <Input style={{ width: '100px' }} maxLength={apiConstraints.orgProjectCardDataDto.unit.maxLength} />
      </Form.Item>
      <Form.Item
        name="variable"
        label={intl.formatMessage({ id: 'OrgProjectCardData.form.rules.variableType' })}
        rules={[requiredRule('OrgProjectCardData.form.rules.variableType.required')]}
        initialValue={defaultItem?.variable}
      >
        <Select options={variableOptions} />
      </Form.Item>
      <Form.Item name="mode" initialValue={mode}>
        {mode === 'sisterNode' ? <SisternodeOutlined /> : <SubnodeOutlined />}
      </Form.Item>
      {!!dataVariableType && (
        <Form.Item
          hidden
          name="defaultData"
          label={intl.formatMessage({ id: 'OrgProjectCardData.form.rules.defaultValue' })}
          initialValue={defaultItem?.defaultData}
        >
          {renderProjectCardDefaultDataVariableFormItem(dataVariableType)}
        </Form.Item>
      )}
    </>
  );
};

ProjectCardDataAddForm.displayName = 'ProjectCardDataAddForm';

export default ProjectCardDataAddForm;
