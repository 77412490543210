import { ProjectTemplateRoleDto, ProjectTemplateUserDto } from 'api/completeApiInterfaces';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import React, { FunctionComponent } from 'react';
import uuid from 'uuid';
import ProjectTemplateRoleForm, { ProjectTemplateRoleFormData } from './ProjectTemplateRoleForm';

type Props = FormModalProps<ProjectTemplateRoleDto> & {
  templateUsers: ProjectTemplateUserDto[];
  editedRole?: ProjectTemplateRoleDto;
  validateUniqueName: (name: string) => boolean;
};

const ProjectTemplateRoleFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, templateUsers, editedRole, validateUniqueName, ...restProps } = props;

  const handleSubmit: FormSubmitHandler<ProjectTemplateRoleFormData> = (values) => {
    onSubmit({
      id: editedRole?.id || uuid(),
      name: values.name,
      description: values.description,
      templateUserId: values.userId,
      associateIds: values.associateIds || [],
    });
    return null;
  };

  const isRoleEditing = !!editedRole;

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId={isRoleEditing ? 'ProjectTemplateRoleFormModal.edit.title' : 'ProjectTemplateRoleFormModal.add.title'}
      submitTextId={isRoleEditing ? 'general.edit' : 'general.add'}
      width={700}
      {...restProps}
    >
      <ProjectTemplateRoleForm
        templateUsers={templateUsers}
        defaults={editedRole}
        validateUniqueName={validateUniqueName}
      />
    </FormModalWrapper>
  );
};

export default ProjectTemplateRoleFormModal;
