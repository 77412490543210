import { ProjectSettingExternalAppSettingsEnum } from 'api/completeApiInterfaces';
import React, { FunctionComponent } from 'react';
import ApplicationUrlSetForm, { ApplicationUrlSetFormProps } from './ApplicationUrlSetForm';

const ConstructionJournalSetForm: FunctionComponent<ApplicationUrlSetFormProps> = ({ ...setProps }) => {
  return (
    <ApplicationUrlSetForm externalAppType={ProjectSettingExternalAppSettingsEnum.constructionJournal} {...setProps} />
  );
};
export default ConstructionJournalSetForm;
