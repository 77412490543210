import { api } from 'api';
import { AssignmentDto, AssignmentMessageDto } from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { useActiveProject, useApiData, useStoreSelector } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { AssignmentPreview } from 'pages/MessageCenterPage/assignments/AssignmentPreview/AssignmentPreview';
import React, { FunctionComponent, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { projectsMapSelector } from 'store/selectors';
import { AssignmentDetail } from './AssignmentDetail';
import { AssignmentProjectCheck } from './AssignmentProjectCheck';

type Props = {
  listLoading: boolean;
  assignmentMessages: AssignmentMessageDto[];
  reloadAssignments: () => void;
};

export const AssignmentLoader: FunctionComponent<Props> = ({ listLoading, assignmentMessages, reloadAssignments }) => {
  const { url, params } = useRouteMatch<{ assignmentId: string; projectId: string }>();
  const { assignmentId, projectId } = params;

  const assignmentMessageProject = assignmentMessages.find((assignment) => assignment.assignmentId === assignmentId);
  const assignmentMessageProjectId = assignmentMessageProject?.projectId || projectId;

  const [assignment, assignmentError, assignmentLoading, loadAssignment, setAssignment] = useApiData((ct) =>
    api.master.messageCenter.getAssignment(assignmentMessageProjectId, assignmentId, ct)
  );

  useEffect(() => {
    if (assignmentId && !!assignmentMessageProjectId) {
      loadAssignment();
    }
  }, [assignmentId, assignmentMessageProjectId]);

  const activeProjectId = useActiveProject()?.id;
  const isCorrectProject = assignmentMessageProjectId === activeProjectId;
  const projectsMap = useStoreSelector(projectsMapSelector);
  useDirtyStoreReload(
    (state) => state.allProjects,
    (dispatch) => dispatch.allProjects
  );

  const handleAssignmentUpdated = (assignment: AssignmentDto | null) => {
    if (assignment !== null) {
      setAssignment(assignment);
    } else {
      loadAssignment();
    }
    reloadAssignments();
  };

  const loading = assignmentLoading || (!assignment && listLoading);

  return (
    <>
      {!!assignmentMessageProject && !assignment && !loading ? (
        <AssignmentPreview
          key={url}
          assignment={assignmentMessageProject}
          projectName={projectsMap?.[assignmentMessageProjectId]?.name}
        />
      ) : (
        <ContentGate error={assignmentError} loading={loading} empty={!assignment}>
          <AssignmentProjectCheck assignmentId={assignmentId} projectId={assignmentMessageProjectId}>
            {!!assignment && (
              <AssignmentDetail
                key={url}
                assignment={assignment}
                isCorrectProject={isCorrectProject}
                onAssignmentUpdated={handleAssignmentUpdated}
                projectName={projectsMap?.[assignmentMessageProjectId]?.name}
                setAssignment={setAssignment}
              />
            )}
          </AssignmentProjectCheck>
        </ContentGate>
      )}
    </>
  );
};
