import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { api } from 'api';
import {
  AppUserDto,
  LanguageEnum,
  OrganizationAdminReportDto,
  OrgUserDto,
  ServiceError,
} from 'api/completeApiInterfaces';
import OrganizationUserAddFormModal from 'components/forms/OrganizationUserAddFormModal/OrganizationUserAddFormModal';
import OrganizationUsersEditFormModal from 'components/forms/OrganizationUserEditForm/OrganizationUserEditFormModal';
import GeneralSettingsUsersList from 'components/GeneralSettingsUsersList/GeneralSettingsUsersList';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import ServiceErrorBox from 'components/ServiceErrorBox';
import SpinBox from 'components/SpinBox';
import StackPanel from 'components/StackPanel';
import { useBoolean, useIntl } from 'hooks';
import { Fmt, InjectedIntlProps } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import Panel from 'pages/ProjectSettingsPage/Panel/Panel';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { messageError } from 'utils';
import { ProjectUserProfileListExtended } from '../OrganizationsDetailPanel';
import OrganizationUserPermissionPanel from './OrganizationUserPermissionPanel';
import { OrganizationUserSettingOnProjectsModal } from './OrganizationUserSettingOnProjectsModal';

type Props = InjectedIntlProps & {
  appUsers?: AppUserDto[];
  loading: boolean;
  error: ServiceError;
  titleId?: IntlMessageId;
  reloadUsers?: () => void;
  panelWidth?: number | string;
  organizationUsers?: OrgUserDto[];
  userList: ProjectUserProfileListExtended[];
  selectedOrganization: OrganizationAdminReportDto;
};

export type UserToChangeHisProjectsSettingParams = {
  orgUserId: Guid;
  userName: string;
  language: LanguageEnum;
  lastAccessDate?: IsoDateTime;
};

const OrganizationUsersListPanel: FunctionComponent<Props> = ({
  appUsers,
  loading,
  error,
  reloadUsers,
  organizationUsers,
  userList,
  selectedOrganization,
}) => {
  const [search, setSearch] = useState<string>('');
  const [addUsersFormModalVisible, showAddUsersFormModal, hideAddUsersFormModal] = useBoolean(false);
  const [userToEditUserName, setUserToEditUserName] = useState<string>(undefined);
  const [userToChangeHisProjectsSettingParams, setUserToChangeHisProjectsSettingParams] = useState<
    UserToChangeHisProjectsSettingParams
  >(undefined);

  const { url } = useRouteMatch();
  const intl = useIntl();

  const handleSubmit = () => {
    hideAddUsersFormModal();
    reloadUsers();
  };

  const toEditUser = useMemo(
    () => organizationUsers?.find((user) => user.appUserProfile.username === userToEditUserName),
    [userToEditUserName]
  );

  const removeOrganizationUser = useCallback(
    async (orgUserId: Guid) => {
      const [err] = await api.master.organization.deleteOrganizationUser(orgUserId);
      if (err) {
        messageError(err, intl);
      } else {
        reloadUsers();
      }
    },

    [reloadUsers]
  );

  const handleEditUser = () => {
    setUserToEditUserName(undefined);
    reloadUsers();
  };

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const toAddAppUsers = useMemo(() => {
    const orgUsersUserNames = organizationUsers?.map((orgUser) => orgUser.appUserProfile.username);
    return appUsers?.filter((appUser) => !orgUsersUserNames.includes(appUser.username)) || [];
  }, [appUsers, organizationUsers]);

  const reinviteAppUser = useCallback(
    async (appUserId: Guid) => {
      const [err] = await api.master.organization.reinvite({
        appUserId,
        organizationtId: selectedOrganization.id,
      });
      if (err) {
        messageError(err, intl);
      }
    },
    [intl]
  );

  const changeUserSettingOnProjects = useCallback(
    (orgUserId: Guid) => {
      const userToChangeHisProjectSettingParameters = organizationUsers?.find((user) => user.id === orgUserId)
        .appUserProfile;
      setUserToChangeHisProjectsSettingParams({
        orgUserId,
        userName: userToChangeHisProjectSettingParameters.username,
        language: userToChangeHisProjectSettingParameters.language,
        lastAccessDate: userToChangeHisProjectSettingParameters.lastAccessDate,
      });
    },
    [organizationUsers]
  );

  const renderContent = (onSelect: (itemId: Guid) => void, selectedId: Guid) => {
    if (error) return <ServiceErrorBox error={error} />;
    if (loading && !userList) return <SpinBox />;
    if (userList === null) return null;
    return (
      <GeneralSettingsUsersList
        data={userList}
        search={search}
        selectedId={selectedId}
        onSelect={onSelect}
        onClearSearch={clearSearch}
        onRemove={removeOrganizationUser}
        onEdit={!!organizationUsers ? setUserToEditUserName : undefined}
        onReinvite={reinviteAppUser}
        onChangeUserSettingOnProjects={changeUserSettingOnProjects}
      />
    );
  };

  return (
    <>
      <MasterComponent
        url={url}
        title={intl.formatMessage({ id: 'general.users' })}
        children={(onSelect, selectedItemId) => (
          <StackPanel>
            <Panel
              noMargin
              panelWidth="auto"
              onSearch={setSearch}
              searchValue={search}
              toolbarTitle={
                <Button type="primary" onClick={showAddUsersFormModal} icon={<PlusOutlined />}>
                  <Fmt id="Panel.addUser.tooltip" />
                </Button>
              }
            >
              {!!userToChangeHisProjectsSettingParams && (
                <OrganizationUserSettingOnProjectsModal
                  onClose={() => setUserToChangeHisProjectsSettingParams(undefined)}
                  open={!!userToChangeHisProjectsSettingParams}
                  userToChangeHisProjectsSettingParams={userToChangeHisProjectsSettingParams}
                  organizationId={selectedOrganization.id}
                />
              )}
              {renderContent(onSelect, selectedItemId)}
              <OrganizationUserAddFormModal
                onSubmit={handleSubmit}
                onClose={hideAddUsersFormModal}
                open={addUsersFormModalVisible}
                toAddAppUsers={toAddAppUsers}
                titleId={'AspeHubUsersListPanel.addModal.title'}
                organizationId={selectedOrganization.id}
              />
              <OrganizationUsersEditFormModal
                open={!!userToEditUserName}
                onSubmit={handleEditUser}
                onClose={() => setUserToEditUserName(undefined)}
                toEditUser={toEditUser}
              />
            </Panel>
          </StackPanel>
        )}
      />
      <Switch>
        <Route
          path={`${url}/:userId`}
          render={() => (
            <OrganizationUserPermissionPanel
              organizationUsers={organizationUsers}
              organizationId={selectedOrganization.id}
              intl={intl}
            />
          )}
        />
      </Switch>
    </>
  );
};

export default OrganizationUsersListPanel;
