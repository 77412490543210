import { Form, Input, Select } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useWatch } from 'antd/lib/form/Form';
import { DefaultOptionType } from 'antd/lib/select';
import { FilterViewPresetListDto } from 'api/completeApiInterfaces';
import { Fmt } from 'locale';
import React, { FC, useEffect, useMemo } from 'react';
import { requiredRule } from 'utils/formHelpers';

export type FilterPresetSaveFormData = {
  filterName: string;
  overrideFilterPresetId: Guid;
};

type Props = {
  defaultName?: string;
  savedPresets: FilterViewPresetListDto;
};

const FilterPresetSaveFormComponent: FC<Props> = ({ defaultName, savedPresets }) => {
  const presetOptions = useMemo(
    (): DefaultOptionType[] =>
      savedPresets?.filterViewPresets.map((preset): DefaultOptionType => ({ label: preset.name, value: preset.id })) ||
      [],
    [savedPresets]
  );
  const form = useFormInstance<FilterPresetSaveFormData>();

  const overrideFilterPresetId = useWatch<FilterPresetSaveFormData['overrideFilterPresetId']>('overrideFilterPresetId');

  useEffect(() => {
    overrideFilterPresetId &&
      form.setFieldValue('filterName', presetOptions.find((option) => option.value === overrideFilterPresetId)?.label);
  }, [overrideFilterPresetId]);

  return (
    <>
      <Form.Item
        name="filterName"
        label={<Fmt id="FilterPresetSaveForm.newName" />}
        initialValue={defaultName}
        rules={[requiredRule('forms.items.name.rules.required')]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="overrideFilterPresetId" label={<Fmt id="FilterPresetSaveForm.overridePreset" />}>
        <Select allowClear options={presetOptions} disabled={!presetOptions.length} />
      </Form.Item>
    </>
  );
};

export default FilterPresetSaveFormComponent;
