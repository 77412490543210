import { MaskItemType, MaskMetadata } from 'components/MaskInput/MaskInput.types';
import { convertMaskToRegExp } from 'components/MaskInput/NameValidatorByMask/convertMaskToRegExp';
import { joinRegexExp } from 'components/MaskInput/NameValidatorByMask/joinRegexExp';

const decorateRegex = (regex: RegExp, suffix: string) => {
  const source = regex.source;
  return new RegExp(`${source}${suffix}`, regex.flags);
};

export const joinMasksRegex = (masks: MaskItemType[], folderProps: Record<string, string>) => {
  return joinRegexExp(masks.map((mask) => decorateRegex(convertMaskToRegExp(mask, folderProps), '')));
};

export const validateFilenameByMask = (
  masks: MaskItemType[],
  value: string,
  folderProps: Record<MaskMetadata, string> // check if all folderProps are defined
) => {
  if (!masks?.length) return true;
  const maskRegex = decorateRegex(joinMasksRegex(masks, folderProps), '(.*)$');

  const match = maskRegex.exec(value);
  if (!match) return false;
  const resultLength = match.length === masks.length + 2;
  const allDefined = match.every((m) => m != undefined);
  const lastEmpty = match[match.length - 1] === '';

  return resultLength && allDefined && lastEmpty;
};
