import { ExportOutlined } from '@ant-design/icons';
import { Tree, Typography } from 'antd';
import { ProjectCardDataDto } from 'api/completeApiInterfaces';
import DashboardTile from 'components/DashboardTile/DashboardTile';
import { FilterInput } from 'components/FilterInput/FilterInput';
import { JSONVariable } from 'components/JSONVariableFormItems/JSONVariableTypes';
import { useIntl } from 'hooks';
import { InjectedIntl } from 'locale';
import moment from 'moment';
import { useProjectAdditionalData } from 'pages/ProjectDashboardPage/useProjectAdditionalData';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './ProjectDashboardPage.module.less';

export function formatJsonData(data: JSONVariable, intl: InjectedIntl) {
  const type = data?.type;
  switch (type) {
    case 'number':
      return data?.value == null ? null : (+data?.value).toLocaleString(intl.locale);
    case 'numberDiff':
      return {
        oldValue: data?.oldValue ? (+data?.oldValue).toLocaleString(intl.locale) : '',
        value: data?.value ? (+data?.value).toLocaleString(intl.locale) : '',
      };
    case 'string':
      return data?.value || null;
    case 'stringDiff':
      return {
        oldValue: data?.oldValue ? data?.oldValue : '',
        value: data?.value ? data?.value : '',
      };
    case 'date':
      return !!data?.value ? moment(data?.value)?.format('L') : null;
    case 'link':
      return data?.value ? (
        <Typography.Link target="_blank" href={data?.value} underline>
          <ExportOutlined /> {data?.label || data?.value}
        </Typography.Link>
      ) : null;
    case 'hubLink':
      return data?.value ? <Link to={data?.value}>{data?.label || data?.value}</Link> : null;
    case 'boolean':
      return intl.formatMessage({ id: data?.value === 'true' ? 'general.yes' : 'general.no' });
    case 'interval':
      return data?.valueFrom && data?.valueTo
        ? `${moment(data?.valueFrom)?.format('L')} - ${moment(data?.valueTo)?.format('L')}`
        : null;
    case 'ckApprovalConditions':
      return data?.value?.join(', ');
    case 'buildingIdentification':
      return data?.value?.length ? (
        <div>
          {data?.value.map?.((item) => <div key={item.code}>{`${item.property} (${item.code})`}</div>) ||
            data?.value + ''}
        </div>
      ) : null;
    case 'empty':
      return '';
  }
  return null;
}

type Props = {
  nodeKey?: string;
  projectCardData?: ProjectCardDataDto[];
};

export const ProjectAdditionalData: React.FC<Props> = ({ nodeKey, projectCardData }) => {
  const intl = useIntl();

  const [filteredTreeData, search, setSearch, editCardDataLink, canEditProjectCardData] = useProjectAdditionalData(
    projectCardData,
    nodeKey
  );

  if (!projectCardData?.length) {
    return null;
  }

  return (
    <DashboardTile
      title={<>{nodeKey || intl.formatMessage({ id: 'OrgProjectCardData.title' })}</>}
      menu={
        canEditProjectCardData
          ? {
              items: [editCardDataLink],
            }
          : null
      }
      additionalItems={
        <>
          <FilterInput onSearch={setSearch} value={search} style={{ width: '150px' }} />
        </>
      }
    >
      <Tree
        height={300}
        virtual
        selectable={false}
        checkable={false}
        blockNode
        treeData={filteredTreeData}
        defaultExpandAll
        titleRender={(node) => <div className={styles.defaultCursor}>{node.title}</div>}
      />
    </DashboardTile>
  );
};
