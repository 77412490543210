import React, { createContext, Key, ReactNode, useContext } from 'react';
import { TreeNode } from 'utils/tree/treeHelpers';

export type DefaultEditableTreeNode = TreeNode & { key: Key };

export type EditableTreeItemContextType<T extends DefaultEditableTreeNode> = {
  item?: T;
};

export const EditableTreeItemContext = createContext<EditableTreeItemContextType<any> | null>(null);

export const useEditableTreeItemContext = <T extends DefaultEditableTreeNode>() => {
  const context = useContext(EditableTreeItemContext);
  if (!context) {
    throw new Error('useEditableTreeItemContext must be used within an EditableTreeItemContextProvider');
  }
  return context as EditableTreeItemContextType<T>;
};

type Props<T extends DefaultEditableTreeNode> = {
  item: T;
  children?: ReactNode;
};

export const EditableTreeItemContextProvider = <T extends DefaultEditableTreeNode>({ children, item }: Props<T>) => {
  return <EditableTreeItemContext.Provider value={{ item }}>{children}</EditableTreeItemContext.Provider>;
};
