import { SaveOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { projectApi } from 'api/completeApi';
import { FilterViewPresetListDto } from 'api/completeApiInterfaces';
import { CommonFilterWithValue, FiltersPersistentKey, Serializable } from 'components/filters/filterTypes';
import FilterPresetSaveFormModal from 'components/forms/FilterPresetSaveFormModal/FilterPresetSaveFormModal';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { HIDE_BUTTON_PROPS } from 'config/constants';
import { useApiData, useBoolean, useCurrentProjectUser, useIntl } from 'hooks';
import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { textComparer } from 'utils/comparators';
import { simpleSelectFilter } from 'utils/formHelpers';
import { FilterPresetManageModal } from '../FilterPresetManageModal/FilterPresetManageModal';
import styles from './FilterPreset.module.less';

type Props = {
  filters: CommonFilterWithValue[];
  filterKey: FiltersPersistentKey;
  setFilterValue: (key: string, value: React.SetStateAction<Serializable>) => void;
};

export type FilterPresetData = {
  filters: Record<string, Serializable>;
};

export const FilterPreset: FunctionComponent<Props> = ({ filterKey, filters, setFilterValue }) => {
  const [saveModalVisible, showSaveModal, hideSaveModal] = useBoolean();
  const [managePresetsModalVisible, showManagePresetsModal, hideManagePresetsModal] = useBoolean();
  const intl = useIntl();
  const currentUser = useCurrentProjectUser();

  const [savedPresets, presetsError, presetsLoading, loadSavedPresets, setSavedPresets] = useApiData((ct) =>
    projectApi.projectsetting.filterviewpreset.list.post({ viewKey: filterKey }, ct)
  );

  useEffect(() => {
    filterKey && loadSavedPresets();
  }, [filterKey]);

  const handleSaveModalSubmit = useCallback(
    (presets: FilterViewPresetListDto) => {
      setSavedPresets(presets);
      hideSaveModal();
    },
    [setSavedPresets]
  );

  const presetOptions = useMemo((): DefaultOptionType[] => {
    const sortedPresets = [...(savedPresets?.filterViewPresets || [])].sort(textComparer.map((preset) => preset.name));
    const userOwned = sortedPresets
      .filter((preset) => preset.createdBy.id === currentUser.id)
      .map((preset): DefaultOptionType => ({ label: preset.name, value: preset.id }));
    const shared = sortedPresets
      .filter((preset) => preset.createdBy.id !== currentUser.id)
      .map((preset): DefaultOptionType => ({ label: preset.name, value: preset.id }));

    return [
      userOwned.length > 0 && {
        label: intl.formatMessage({ id: 'FilterPreset.category.mine' }),
        options: userOwned,
      },
      shared.length > 0 && {
        label: intl.formatMessage({ id: 'FilterPreset.category.shared' }),
        options: shared,
      },
    ].filter(Boolean);
  }, [currentUser, savedPresets?.filterViewPresets, intl]);

  const handlePresetSelection = useCallback(
    (presetId: Guid) => {
      const selectedPreset = savedPresets?.filterViewPresets.find((preset) => preset.id === presetId);
      if (selectedPreset) {
        const filterData = selectedPreset.filterData as FilterPresetData;
        Object.entries(filterData.filters).map(([key, value]) => {
          setFilterValue(key, value);
        });
      }
    },
    [savedPresets?.filterViewPresets, setFilterValue]
  );

  if (!filterKey) return null;

  return (
    <FlowLayout className={styles.layout}>
      <Select
        onChange={handlePresetSelection}
        options={presetOptions}
        className={styles.select}
        allowClear
        showSearch
        filterOption={simpleSelectFilter}
      />
      <Button shape="circle" icon={<SaveOutlined />} onClick={showSaveModal} />
      <Button shape="circle" icon={<SettingOutlined />} onClick={showManagePresetsModal} />
      <FilterPresetSaveFormModal
        filters={filters}
        filterKey={filterKey}
        savedPresets={savedPresets}
        open={saveModalVisible}
        onClose={hideSaveModal}
        onSubmit={handleSaveModalSubmit}
      />
      <FilterPresetManageModal
        savedPresets={savedPresets}
        open={managePresetsModalVisible}
        setSavedPresets={setSavedPresets}
        onCancel={hideManagePresetsModal}
        okButtonProps={HIDE_BUTTON_PROPS}
        filters={filters}
      />
    </FlowLayout>
  );
};
