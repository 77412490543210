import { QuestionOutlined } from '@ant-design/icons';
import { projectApi } from 'api/completeApi';
import { apiConstraints } from 'api/completeApiConstraints';
import { ProjectDto } from 'api/completeApiInterfaces';
import GeneralTextSettingsItem from 'components/GeneralTextSettingsItem/GeneralTextSettingsItem';
import { useIntl } from 'hooks';
import React, { FC, ReactNode, useCallback } from 'react';
import { processApiError } from 'utils';

type Props = {
  project: ProjectDto;
  setActiveProject: (project: ProjectDto) => void;
  disableEdit?: boolean;
};

export const ProjectCardMarkingItem: FC<Props> = ({ project, disableEdit, setActiveProject }) => {
  const intl = useIntl();

  const onSave = useCallback(
    async (marking: string): Promise<boolean | ReactNode> => {
      if (marking !== project.projectCard?.marking) {
        const data = { ...project.projectCard, marking };
        const [err] = await projectApi.card.patch(data);
        if (!err) {
          setActiveProject({ ...project, projectCard: data });
          return true;
        } else {
          return intl.formatMessage({
            id: `serviceError.${processApiError(err).referenceErrorCode}`,
          });
        }
      }
      return true;
    },
    [setActiveProject, project, intl]
  );

  return (
    <GeneralTextSettingsItem
      icon={<QuestionOutlined />}
      value={project.projectCard?.marking}
      onSave={onSave}
      headline={intl.formatMessage({ id: 'ProjectDashboard.ProjectCard.marking' })}
      disableEdit={disableEdit}
      maxLength={apiConstraints.projectCardPatchDto.marking.maxLength}
    />
  );
};
