import { ProjectUserProfileListDto, RoleDto } from 'api/completeApiInterfaces';
import { default as classNames, default as classnames } from 'classnames';
import React, { FunctionComponent } from 'react';
import { UserAvatarSize, UserIcon } from '../UserIcon/UserIcon';
import styles from './MultiUserAvatar.module.less';

type Props = {
  size?: UserAvatarSize;
  user?: ProjectUserProfileListDto;
  documentOwner?: RoleDto;
  clickable?: boolean;
};

const UserAvatarWithOwnerComponent: FunctionComponent<Props> = ({
  size = UserAvatarSize.Medium,
  user,
  documentOwner,
  clickable,
}) => {
  return (
    <div className={classnames(styles.avatarContainer, clickable && styles.clickable)}>
      <UserIcon user={user} size={size} />
      {documentOwner && (
        <span
          className={classNames(
            styles.hasOwner,
            (documentOwner?.user || documentOwner?.roleAssociates?.length) && styles.withUser
          )}
        />
      )}
    </div>
  );
};

export const UserAvatarWithOwner = React.memo(UserAvatarWithOwnerComponent);
