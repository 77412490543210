import { Spin } from 'antd';
import { api } from 'api';
import {
  DocumentCategoryNodeDto,
  DocumentCategoryNodeRemoveStrategyEnum,
  DocumentCategoryTreeDto,
} from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import { DocumentCategoryTreeNodeCreateFormModal } from 'components/forms/DocumentCategoryTreeNodeCreateForm';
import { DocumentCategoryTreeNodeDeleteFormModal } from 'components/forms/DocumentCategoryTreeNodeDeleteForm';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import ServiceErrorBox from 'components/ServiceErrorBox';
import SpinBox from 'components/SpinBox';
import StackPanel from 'components/StackPanel';
import { useDeleteItems } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt, InjectedIntlProps } from 'locale';
import { Dictionary } from 'lodash';
import Panel from 'pages/ProjectSettingsPage/Panel/Panel';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import { Dispatch, RootState } from 'store';
import { CategoryTreesStoreModelState } from 'store/models/storeModelinterfaces';
import { categoryMapSelector } from 'store/selectors';
import { filterTree } from 'store/selectors/genericSelectors';
import { messageError, processApiError, strCompareCI } from 'utils';
import CategoryTree, { ROOT_KEY } from './CategoryTree';

type Props = InjectedIntlProps & {
  onAdd: (categoryId: Guid, node: DocumentCategoryNodeDto) => void;
  onDelete?: (categoryId: Guid, nodeId: Guid) => void;
  projectId: Guid;
  categoryTrees: CategoryTreesStoreModelState;
};

type DeleteCategoryNodeType = { categoryId: Guid; nodeId: Guid };

type MatchParams = {
  categoryId: string;
};

export function getIdsInPathToNode(
  parentId: string,
  treeMap: Dictionary<DocumentCategoryNodeDto & { parentId: Guid; name: string }>
) {
  const result: Guid[] = [];
  while (treeMap[parentId] != null) {
    result.push(parentId);
    parentId = treeMap[parentId].parentId;
  }
  return result;
}

const CategoryTreePanel: FunctionComponent<Props> = ({ intl, onAdd, onDelete, projectId, categoryTrees }) => {
  const [formModalVisible, setFormModalVisible] = useState<boolean>(false);
  const [formDeleteModalVisible, setFormDeleteModalVisible] = useState<boolean>(false);
  const [askRemoveStrategy, setAskRemoveStrategy] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [selectedNodeId, setSelectedNodeId] = useState<Guid>(ROOT_KEY);
  const [search, setSearch] = useState<string>('');
  const [removeChildren, setRemoveChildren] = useState<boolean>(false);
  const [expandedKeys, setExpandedKeys] = useState<Record<string, string[]>>({});

  const dispatch = useDispatch<Dispatch>();
  const loadCategoryTree = dispatch.categoryTrees.loadCategoryTree;
  const categoryMap = useSelector<RootState, Dictionary<DocumentCategoryTreeDto>>((state) =>
    categoryMapSelector(state)
  );

  const categoryId = useParams<MatchParams>().categoryId;
  const { url } = useRouteMatch();

  const [treeMap, treeLoading, treeError] = useMemo(() => {
    if (!categoryTrees || !categoryTrees[categoryId]) return [null, false, null];
    const data = categoryTrees[categoryId];
    return [
      data.map as Dictionary<DocumentCategoryNodeDto & { parentId: Guid; name: string }>,
      data.loading,
      data.error,
    ] as const;
  }, [categoryTrees, categoryId]);

  useEffect(() => {
    categoryId && loadCategoryTree({ categoryId, reload: true });
  }, [categoryId]);

  useDirtyStoreReload(
    (store) => store.categories,
    (dispatch) => dispatch.categories
  );

  const category = useMemo(() => {
    return categoryMap?.[categoryId];
  }, [categoryMap, categoryId]);

  const deleteCategoryNodeCb = ({ categoryId, nodeId }: DeleteCategoryNodeType) => {
    const node = treeMap?.[nodeId];
    onDelete(categoryId, nodeId);
    setSelectedNodeId(node.parentId);
    setFormDeleteModalVisible(false);
  };

  const [deletingItems, handleForceDelete, error] = useDeleteItems<{
    categoryId: Guid;
    nodeId: Guid;
    removeStrategy: DocumentCategoryNodeRemoveStrategyEnum;
    removeChildren: boolean;
  }>(
    intl,
    async ({ categoryId, nodeId, removeStrategy, removeChildren }) => {
      setRemoveChildren(removeChildren);
      const result = await api.project.categoryTrees.deleteCategoryNodeById(
        categoryId,
        nodeId,
        removeStrategy,
        removeChildren
      );
      const [err] = result;
      if (err) {
        processApiError(err, (e) => {
          if (e.referenceErrorCode === ServiceErrorEnum.DocumentCategoryNodeRemoveObstacleError) {
            setAskRemoveStrategy(true);
          } else {
            messageError(err, intl);
            setFormDeleteModalVisible(false);
          }
        });
      }
      return result;
    },
    deleteCategoryNodeCb,
    false
  );

  const handleDelete = () => {
    setRemoveChildren(false);
    setAskRemoveStrategy(false);
    setFormDeleteModalVisible(true);
  };

  // set selectedNodeId to ROOT_KEY when category changes
  useEffect(() => {
    if (!treeMap || !category) {
      return;
    }
    const isNodeInCategory = Object.keys(treeMap).find((key) => treeMap[key].id === selectedNodeId) === undefined;
    if (!selectedNodeId || selectedNodeId === 'null' || isNodeInCategory) {
      const roots = Object.keys(treeMap).filter((key) => !treeMap[key].parentId);
      if (roots.length > 0) {
        setSelectedNodeId(roots[0]);
      }
    }
  }, [treeMap, category]);

  useEffect(() => {
    if (!treeMap || !category || expandedKeys[category.id] !== undefined) {
      return;
    }
    setExpandedKeys({ ...expandedKeys, [category.id]: Object.values(treeMap).map((item) => item.id) });
  }, [category]);

  const handleCloseFormDeleteModal = useCallback(() => {
    setFormDeleteModalVisible(false);
  }, []);

  const handleSubmitFormDeleteModal = useCallback(
    (values) => {
      handleForceDelete({
        removeStrategy: values.removeStrategy,
        categoryId: category?.id,
        nodeId: selectedNodeId,
        removeChildren: values.removeChildren,
      });
    },
    [selectedNodeId, category]
  );

  const showModal = () => setFormModalVisible(true);
  const closeModal = () => {
    setFormModalVisible(false);
    setEditMode(false);
  };

  const handleSubmit = (node: DocumentCategoryNodeDto) => {
    if (!category) return;
    onAdd(category.id, node);
    setSelectedNodeId(node.id);
    const expandedPath = getIdsInPathToNode(node.parentId, treeMap);
    const oldKeys = expandedKeys[category.id] != null ? expandedKeys[category.id] : [];
    setExpandedKeys({
      ...expandedKeys,
      [category.id]: Array.from(new Set([...oldKeys, ...expandedPath, node.id])),
    });
    closeModal();
  };

  const handleEdit = () => {
    setEditMode(true);
    showModal();
  };

  const handleSelectNode = (keys: Guid[]) => {
    if (keys.length === 1) setSelectedNodeId(keys[0]);
  };

  const treeData = useMemo(() => filterTree(treeMap, search)[0], [treeMap, search]);

  const selectedNode = useMemo(() => treeMap && treeMap[selectedNodeId], [treeMap, selectedNodeId]);

  const selectedNodeSiblingsList = useMemo(() => {
    if (!treeMap || !selectedNode) return null;
    return Object.values(treeMap).filter((n) => n.parentId === selectedNode.parentId);
  }, [treeMap, selectedNode]);

  const selectedNodeChildrenList = useMemo(
    () => treeMap && Object.values(treeMap).filter((n) => n.parentId === selectedNodeId),
    [treeMap, selectedNodeId]
  );

  const checkNodeUniqueName = useCallback(
    (name: string) => {
      const list = editMode ? selectedNodeSiblingsList : selectedNodeChildrenList;
      if (!list) return true;
      return list.findIndex((n) => strCompareCI(n.name, name) === 0 && n.id !== selectedNodeId) === -1;
    },
    [selectedNodeSiblingsList, selectedNodeChildrenList, selectedNodeId, editMode]
  );

  const renderContent = () => {
    if (treeError) return <ServiceErrorBox error={treeError} />;
    if (treeLoading && !treeData) return <SpinBox />;
    if (treeData === null) return null;
    if (!category) return null;

    const deleting = !!deletingItems.size;

    return (
      <Spin spinning={treeLoading || deleting} delay={500}>
        <CategoryTree
          key={`${category.id}${search}`}
          treeData={treeData}
          onSelect={handleSelectNode}
          selectedKeys={[selectedNodeId]}
          expandedKeys={expandedKeys[category.id]}
          onExpand={(keys) => {
            // TODO: antd4: oveřit klíče expanded
            setExpandedKeys({ ...expandedKeys, [category.id]: keys.map((key) => key.toString()) });
          }}
          defaultExpandAll
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      </Spin>
    );
  };

  return (
    <MasterComponent
      url={url}
      title={category?.name}
      children={() => (
        <StackPanel vertical scrollable>
          <GeneralSettingsContainer itemsLargeGap>
            <GeneralSettingsContainer>
              <GeneralSettingsItem title={<Fmt id="general.name" />} description={category?.name} />
              <GeneralSettingsItem title={<Fmt id="general.description" />} description={category?.description} />
            </GeneralSettingsContainer>
            <GeneralSettingsContainer title={<Fmt id="general.categoriesNodes" />}>
              <Panel
                onSearch={setSearch}
                addButtonOnClick={showModal}
                addButtonText={<Fmt id="Panel.addCategoryNode.tooltip" />}
                noMargin
                panelWidth={null}
              >
                {renderContent()}
                <DocumentCategoryTreeNodeCreateFormModal
                  categoryId={category?.id}
                  parentId={selectedNodeId}
                  open={formModalVisible}
                  validateUniqueName={checkNodeUniqueName}
                  onSubmit={handleSubmit}
                  onClose={closeModal}
                  defaults={editMode ? selectedNode : null}
                />
                <DocumentCategoryTreeNodeDeleteFormModal
                  open={formDeleteModalVisible}
                  error={error}
                  onSubmit={handleSubmitFormDeleteModal}
                  onClose={handleCloseFormDeleteModal}
                  askRemoveStrategy={askRemoveStrategy}
                  removeChildren={removeChildren}
                  projectId={projectId}
                />
              </Panel>
            </GeneralSettingsContainer>
          </GeneralSettingsContainer>
        </StackPanel>
      )}
    />
  );
};

export default injectIntl(CategoryTreePanel);
