import { Collapse } from 'antd';
import { masterApi, projectApi } from 'api/completeApi';
import { AuditLogDto } from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { useActiveProject, useApiData } from 'hooks';
import { InjectedIntlProps } from 'locale';
import React, { useEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';
import styles from './AuditLogDetailGrid.module.less';
import AuditLogRow from './AuditLogRow';
import { AuditLogRowDisplay } from './AuditLogRowDisplay';

type Props = InjectedIntlProps & {
  auditLogId: number;
};

const AuditLogDetailGrid: React.FunctionComponent<Props> = ({ intl, auditLogId }) => {
  const activeProjectId = useActiveProject()?.id;
  const collapsed = useRef<number[]>([]);

  const [logs, logsError, logsLoading, loadLogs] = useApiData(
    (ct) =>
      activeProjectId
        ? projectApi.auditlog.id.auditlogdetail.get(auditLogId, ct)
        : masterApi.auditlog.id.auditlogdetail.get(auditLogId, ct),
    {
      fetchCallback: (data) => {
        let parent = data.auditLogs.find((log) => log.id === auditLogId);
        collapsed.current = [parent.id];
        while (!!parent.parentId) {
          collapsed.current.push(parent.parentId);
          parent = data.auditLogs.find((log) => log.id === parent.parentId);
        }
      },
    }
  );
  useEffect(loadLogs, [auditLogId]);

  const renderLevel = (parentId?: number) => {
    const logsInLevel: AuditLogDto[] = !parentId
      ? logs.auditLogs.filter((log) => !('parentId' in log) || !log.parentId)
      : logs.auditLogs.filter((log) => log.parentId === parentId);

    if (logsInLevel.length === 0) return null;
    const activeLog = logsInLevel.find((alog) => collapsed.current.includes(alog.id));
    const active: number = !parentId ? logsInLevel[0].id : !!activeLog ? activeLog.id : -1;
    return (
      <Collapse defaultActiveKey={active} accordion>
        {logsInLevel.map((log) => (
          <Collapse.Panel
            key={log.id}
            className={log.id === auditLogId ? styles.highlighted : undefined}
            header={<AuditLogRow log={log} displayEntityType={true} isAdmin={false} onDetailClick={() => {}} />}
          >
            <AuditLogRowDisplay log={log} />
            {renderLevel(log.id)}
          </Collapse.Panel>
        ))}
      </Collapse>
    );
  };

  return (
    <ContentGate error={logsError} loading={logsLoading}>
      {logs && <div className={styles.gridBody}>{renderLevel()}</div>}
    </ContentGate>
  );
};

export default injectIntl(AuditLogDetailGrid);
