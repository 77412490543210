import { Form } from 'antd';
import { OrgUserDto, ProjectUserProfileListDto } from 'api/completeApiInterfaces';
import { UserTransferFormItem } from 'components/UserTransfer/UserTransfer';
import { useIntl } from 'hooks';
import React, { FC, useMemo } from 'react';

export type MDDivisionAddUsersFormData = {
  users: Guid[];
};

type Props = {
  organizationUsers: OrgUserDto[];
};

export const MDDivisionAddUserForm: FC<Props> = ({ organizationUsers }) => {
  const intl = useIntl();

  const availableUserList = useMemo(
    () =>
      organizationUsers.map(
        (orgUser): ProjectUserProfileListDto => ({
          id: orgUser.id,
          username: orgUser.appUserProfile.username,
          firstname: orgUser.firstname,
          lastname: orgUser.lastname,
          isAdmin: orgUser.isAdmin,
          status: undefined,
        })
      ) || [],
    [organizationUsers]
  );

  return (
    <Form.Item label={intl.formatMessage({ id: 'general.users' })} name="users" initialValue={[]}>
      <UserTransferFormItem users={availableUserList} />
    </Form.Item>
  );
};
