import { Form } from 'antd';
import { AppUserDto } from 'api/completeApiInterfaces';
import React, { FC } from 'react';
import { AppUsersFormItem, SelectableUsersSourceEnum, UserAttributes } from '../AppUsersAddFormModal/AppUsersFormItem';

export type AppUserAddFormData = {
  usersToAddWithAttributes: Record<string, UserAttributes>;
};

type Props = {
  toAddAppUsers: AppUserDto[];
  isAdminsAdding?: boolean;
};

const OrganizationUserAddFormComponent: FC<Props> = ({ toAddAppUsers = [], isAdminsAdding }) => {
  return (
    <Form.Item requiredMark={null} name="usersToAddWithAttributes">
      <AppUsersFormItem
        selectableAppUsers={toAddAppUsers}
        isAdminsAdding={isAdminsAdding}
        selectableUsersToAddSource={SelectableUsersSourceEnum.aspeHub}
      />
    </Form.Item>
  );
};

export default OrganizationUserAddFormComponent;
